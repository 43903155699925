/* Copyright 2012 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function (root, factory) {
 'use strict';
 if (typeof define === 'function' && define.amd) {
  define('pdfjs-dist/build/pdf.combined', ['exports'], factory);
 } else if (typeof exports !== 'undefined') {
  factory(exports);
 } else {
  factory(root['pdfjsDistBuildPdfCombined'] = {});
 }
}(this, function (exports) {
 'use strict';
 var pdfjsVersion = '1.6.422';
 var pdfjsBuild = 'aabfb77';
 var pdfjsFilePath = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : null;
 var pdfjsLibs = {};
 (function pdfjsWrapper() {
  (function (root, factory) {
   factory(root.pdfjsCoreArithmeticDecoder = {});
  }(this, function (exports) {
   var ArithmeticDecoder = function ArithmeticDecoderClosure() {
    var QeTable = [
     {
      qe: 0x5601,
      nmps: 1,
      nlps: 1,
      switchFlag: 1
     },
     {
      qe: 0x3401,
      nmps: 2,
      nlps: 6,
      switchFlag: 0
     },
     {
      qe: 0x1801,
      nmps: 3,
      nlps: 9,
      switchFlag: 0
     },
     {
      qe: 0x0AC1,
      nmps: 4,
      nlps: 12,
      switchFlag: 0
     },
     {
      qe: 0x0521,
      nmps: 5,
      nlps: 29,
      switchFlag: 0
     },
     {
      qe: 0x0221,
      nmps: 38,
      nlps: 33,
      switchFlag: 0
     },
     {
      qe: 0x5601,
      nmps: 7,
      nlps: 6,
      switchFlag: 1
     },
     {
      qe: 0x5401,
      nmps: 8,
      nlps: 14,
      switchFlag: 0
     },
     {
      qe: 0x4801,
      nmps: 9,
      nlps: 14,
      switchFlag: 0
     },
     {
      qe: 0x3801,
      nmps: 10,
      nlps: 14,
      switchFlag: 0
     },
     {
      qe: 0x3001,
      nmps: 11,
      nlps: 17,
      switchFlag: 0
     },
     {
      qe: 0x2401,
      nmps: 12,
      nlps: 18,
      switchFlag: 0
     },
     {
      qe: 0x1C01,
      nmps: 13,
      nlps: 20,
      switchFlag: 0
     },
     {
      qe: 0x1601,
      nmps: 29,
      nlps: 21,
      switchFlag: 0
     },
     {
      qe: 0x5601,
      nmps: 15,
      nlps: 14,
      switchFlag: 1
     },
     {
      qe: 0x5401,
      nmps: 16,
      nlps: 14,
      switchFlag: 0
     },
     {
      qe: 0x5101,
      nmps: 17,
      nlps: 15,
      switchFlag: 0
     },
     {
      qe: 0x4801,
      nmps: 18,
      nlps: 16,
      switchFlag: 0
     },
     {
      qe: 0x3801,
      nmps: 19,
      nlps: 17,
      switchFlag: 0
     },
     {
      qe: 0x3401,
      nmps: 20,
      nlps: 18,
      switchFlag: 0
     },
     {
      qe: 0x3001,
      nmps: 21,
      nlps: 19,
      switchFlag: 0
     },
     {
      qe: 0x2801,
      nmps: 22,
      nlps: 19,
      switchFlag: 0
     },
     {
      qe: 0x2401,
      nmps: 23,
      nlps: 20,
      switchFlag: 0
     },
     {
      qe: 0x2201,
      nmps: 24,
      nlps: 21,
      switchFlag: 0
     },
     {
      qe: 0x1C01,
      nmps: 25,
      nlps: 22,
      switchFlag: 0
     },
     {
      qe: 0x1801,
      nmps: 26,
      nlps: 23,
      switchFlag: 0
     },
     {
      qe: 0x1601,
      nmps: 27,
      nlps: 24,
      switchFlag: 0
     },
     {
      qe: 0x1401,
      nmps: 28,
      nlps: 25,
      switchFlag: 0
     },
     {
      qe: 0x1201,
      nmps: 29,
      nlps: 26,
      switchFlag: 0
     },
     {
      qe: 0x1101,
      nmps: 30,
      nlps: 27,
      switchFlag: 0
     },
     {
      qe: 0x0AC1,
      nmps: 31,
      nlps: 28,
      switchFlag: 0
     },
     {
      qe: 0x09C1,
      nmps: 32,
      nlps: 29,
      switchFlag: 0
     },
     {
      qe: 0x08A1,
      nmps: 33,
      nlps: 30,
      switchFlag: 0
     },
     {
      qe: 0x0521,
      nmps: 34,
      nlps: 31,
      switchFlag: 0
     },
     {
      qe: 0x0441,
      nmps: 35,
      nlps: 32,
      switchFlag: 0
     },
     {
      qe: 0x02A1,
      nmps: 36,
      nlps: 33,
      switchFlag: 0
     },
     {
      qe: 0x0221,
      nmps: 37,
      nlps: 34,
      switchFlag: 0
     },
     {
      qe: 0x0141,
      nmps: 38,
      nlps: 35,
      switchFlag: 0
     },
     {
      qe: 0x0111,
      nmps: 39,
      nlps: 36,
      switchFlag: 0
     },
     {
      qe: 0x0085,
      nmps: 40,
      nlps: 37,
      switchFlag: 0
     },
     {
      qe: 0x0049,
      nmps: 41,
      nlps: 38,
      switchFlag: 0
     },
     {
      qe: 0x0025,
      nmps: 42,
      nlps: 39,
      switchFlag: 0
     },
     {
      qe: 0x0015,
      nmps: 43,
      nlps: 40,
      switchFlag: 0
     },
     {
      qe: 0x0009,
      nmps: 44,
      nlps: 41,
      switchFlag: 0
     },
     {
      qe: 0x0005,
      nmps: 45,
      nlps: 42,
      switchFlag: 0
     },
     {
      qe: 0x0001,
      nmps: 45,
      nlps: 43,
      switchFlag: 0
     },
     {
      qe: 0x5601,
      nmps: 46,
      nlps: 46,
      switchFlag: 0
     }
    ];
    function ArithmeticDecoder(data, start, end) {
     this.data = data;
     this.bp = start;
     this.dataEnd = end;
     this.chigh = data[start];
     this.clow = 0;
     this.byteIn();
     this.chigh = this.chigh << 7 & 0xFFFF | this.clow >> 9 & 0x7F;
     this.clow = this.clow << 7 & 0xFFFF;
     this.ct -= 7;
     this.a = 0x8000;
    }
    ArithmeticDecoder.prototype = {
     byteIn: function ArithmeticDecoder_byteIn() {
      var data = this.data;
      var bp = this.bp;
      if (data[bp] === 0xFF) {
       var b1 = data[bp + 1];
       if (b1 > 0x8F) {
        this.clow += 0xFF00;
        this.ct = 8;
       } else {
        bp++;
        this.clow += data[bp] << 9;
        this.ct = 7;
        this.bp = bp;
       }
      } else {
       bp++;
       this.clow += bp < this.dataEnd ? data[bp] << 8 : 0xFF00;
       this.ct = 8;
       this.bp = bp;
      }
      if (this.clow > 0xFFFF) {
       this.chigh += this.clow >> 16;
       this.clow &= 0xFFFF;
      }
     },
     readBit: function ArithmeticDecoder_readBit(contexts, pos) {
      var cx_index = contexts[pos] >> 1, cx_mps = contexts[pos] & 1;
      var qeTableIcx = QeTable[cx_index];
      var qeIcx = qeTableIcx.qe;
      var d;
      var a = this.a - qeIcx;
      if (this.chigh < qeIcx) {
       if (a < qeIcx) {
        a = qeIcx;
        d = cx_mps;
        cx_index = qeTableIcx.nmps;
       } else {
        a = qeIcx;
        d = 1 ^ cx_mps;
        if (qeTableIcx.switchFlag === 1) {
         cx_mps = d;
        }
        cx_index = qeTableIcx.nlps;
       }
      } else {
       this.chigh -= qeIcx;
       if ((a & 0x8000) !== 0) {
        this.a = a;
        return cx_mps;
       }
       if (a < qeIcx) {
        d = 1 ^ cx_mps;
        if (qeTableIcx.switchFlag === 1) {
         cx_mps = d;
        }
        cx_index = qeTableIcx.nlps;
       } else {
        d = cx_mps;
        cx_index = qeTableIcx.nmps;
       }
      }
      do {
       if (this.ct === 0) {
        this.byteIn();
       }
       a <<= 1;
       this.chigh = this.chigh << 1 & 0xFFFF | this.clow >> 15 & 1;
       this.clow = this.clow << 1 & 0xFFFF;
       this.ct--;
      } while ((a & 0x8000) === 0);
      this.a = a;
      contexts[pos] = cx_index << 1 | cx_mps;
      return d;
     }
    };
    return ArithmeticDecoder;
   }();
   exports.ArithmeticDecoder = ArithmeticDecoder;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreCharsets = {});
  }(this, function (exports) {
   var ISOAdobeCharset = [
    '.notdef',
    'space',
    'exclam',
    'quotedbl',
    'numbersign',
    'dollar',
    'percent',
    'ampersand',
    'quoteright',
    'parenleft',
    'parenright',
    'asterisk',
    'plus',
    'comma',
    'hyphen',
    'period',
    'slash',
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'colon',
    'semicolon',
    'less',
    'equal',
    'greater',
    'question',
    'at',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'bracketleft',
    'backslash',
    'bracketright',
    'asciicircum',
    'underscore',
    'quoteleft',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'braceleft',
    'bar',
    'braceright',
    'asciitilde',
    'exclamdown',
    'cent',
    'sterling',
    'fraction',
    'yen',
    'florin',
    'section',
    'currency',
    'quotesingle',
    'quotedblleft',
    'guillemotleft',
    'guilsinglleft',
    'guilsinglright',
    'fi',
    'fl',
    'endash',
    'dagger',
    'daggerdbl',
    'periodcentered',
    'paragraph',
    'bullet',
    'quotesinglbase',
    'quotedblbase',
    'quotedblright',
    'guillemotright',
    'ellipsis',
    'perthousand',
    'questiondown',
    'grave',
    'acute',
    'circumflex',
    'tilde',
    'macron',
    'breve',
    'dotaccent',
    'dieresis',
    'ring',
    'cedilla',
    'hungarumlaut',
    'ogonek',
    'caron',
    'emdash',
    'AE',
    'ordfeminine',
    'Lslash',
    'Oslash',
    'OE',
    'ordmasculine',
    'ae',
    'dotlessi',
    'lslash',
    'oslash',
    'oe',
    'germandbls',
    'onesuperior',
    'logicalnot',
    'mu',
    'trademark',
    'Eth',
    'onehalf',
    'plusminus',
    'Thorn',
    'onequarter',
    'divide',
    'brokenbar',
    'degree',
    'thorn',
    'threequarters',
    'twosuperior',
    'registered',
    'minus',
    'eth',
    'multiply',
    'threesuperior',
    'copyright',
    'Aacute',
    'Acircumflex',
    'Adieresis',
    'Agrave',
    'Aring',
    'Atilde',
    'Ccedilla',
    'Eacute',
    'Ecircumflex',
    'Edieresis',
    'Egrave',
    'Iacute',
    'Icircumflex',
    'Idieresis',
    'Igrave',
    'Ntilde',
    'Oacute',
    'Ocircumflex',
    'Odieresis',
    'Ograve',
    'Otilde',
    'Scaron',
    'Uacute',
    'Ucircumflex',
    'Udieresis',
    'Ugrave',
    'Yacute',
    'Ydieresis',
    'Zcaron',
    'aacute',
    'acircumflex',
    'adieresis',
    'agrave',
    'aring',
    'atilde',
    'ccedilla',
    'eacute',
    'ecircumflex',
    'edieresis',
    'egrave',
    'iacute',
    'icircumflex',
    'idieresis',
    'igrave',
    'ntilde',
    'oacute',
    'ocircumflex',
    'odieresis',
    'ograve',
    'otilde',
    'scaron',
    'uacute',
    'ucircumflex',
    'udieresis',
    'ugrave',
    'yacute',
    'ydieresis',
    'zcaron'
   ];
   var ExpertCharset = [
    '.notdef',
    'space',
    'exclamsmall',
    'Hungarumlautsmall',
    'dollaroldstyle',
    'dollarsuperior',
    'ampersandsmall',
    'Acutesmall',
    'parenleftsuperior',
    'parenrightsuperior',
    'twodotenleader',
    'onedotenleader',
    'comma',
    'hyphen',
    'period',
    'fraction',
    'zerooldstyle',
    'oneoldstyle',
    'twooldstyle',
    'threeoldstyle',
    'fouroldstyle',
    'fiveoldstyle',
    'sixoldstyle',
    'sevenoldstyle',
    'eightoldstyle',
    'nineoldstyle',
    'colon',
    'semicolon',
    'commasuperior',
    'threequartersemdash',
    'periodsuperior',
    'questionsmall',
    'asuperior',
    'bsuperior',
    'centsuperior',
    'dsuperior',
    'esuperior',
    'isuperior',
    'lsuperior',
    'msuperior',
    'nsuperior',
    'osuperior',
    'rsuperior',
    'ssuperior',
    'tsuperior',
    'ff',
    'fi',
    'fl',
    'ffi',
    'ffl',
    'parenleftinferior',
    'parenrightinferior',
    'Circumflexsmall',
    'hyphensuperior',
    'Gravesmall',
    'Asmall',
    'Bsmall',
    'Csmall',
    'Dsmall',
    'Esmall',
    'Fsmall',
    'Gsmall',
    'Hsmall',
    'Ismall',
    'Jsmall',
    'Ksmall',
    'Lsmall',
    'Msmall',
    'Nsmall',
    'Osmall',
    'Psmall',
    'Qsmall',
    'Rsmall',
    'Ssmall',
    'Tsmall',
    'Usmall',
    'Vsmall',
    'Wsmall',
    'Xsmall',
    'Ysmall',
    'Zsmall',
    'colonmonetary',
    'onefitted',
    'rupiah',
    'Tildesmall',
    'exclamdownsmall',
    'centoldstyle',
    'Lslashsmall',
    'Scaronsmall',
    'Zcaronsmall',
    'Dieresissmall',
    'Brevesmall',
    'Caronsmall',
    'Dotaccentsmall',
    'Macronsmall',
    'figuredash',
    'hypheninferior',
    'Ogoneksmall',
    'Ringsmall',
    'Cedillasmall',
    'onequarter',
    'onehalf',
    'threequarters',
    'questiondownsmall',
    'oneeighth',
    'threeeighths',
    'fiveeighths',
    'seveneighths',
    'onethird',
    'twothirds',
    'zerosuperior',
    'onesuperior',
    'twosuperior',
    'threesuperior',
    'foursuperior',
    'fivesuperior',
    'sixsuperior',
    'sevensuperior',
    'eightsuperior',
    'ninesuperior',
    'zeroinferior',
    'oneinferior',
    'twoinferior',
    'threeinferior',
    'fourinferior',
    'fiveinferior',
    'sixinferior',
    'seveninferior',
    'eightinferior',
    'nineinferior',
    'centinferior',
    'dollarinferior',
    'periodinferior',
    'commainferior',
    'Agravesmall',
    'Aacutesmall',
    'Acircumflexsmall',
    'Atildesmall',
    'Adieresissmall',
    'Aringsmall',
    'AEsmall',
    'Ccedillasmall',
    'Egravesmall',
    'Eacutesmall',
    'Ecircumflexsmall',
    'Edieresissmall',
    'Igravesmall',
    'Iacutesmall',
    'Icircumflexsmall',
    'Idieresissmall',
    'Ethsmall',
    'Ntildesmall',
    'Ogravesmall',
    'Oacutesmall',
    'Ocircumflexsmall',
    'Otildesmall',
    'Odieresissmall',
    'OEsmall',
    'Oslashsmall',
    'Ugravesmall',
    'Uacutesmall',
    'Ucircumflexsmall',
    'Udieresissmall',
    'Yacutesmall',
    'Thornsmall',
    'Ydieresissmall'
   ];
   var ExpertSubsetCharset = [
    '.notdef',
    'space',
    'dollaroldstyle',
    'dollarsuperior',
    'parenleftsuperior',
    'parenrightsuperior',
    'twodotenleader',
    'onedotenleader',
    'comma',
    'hyphen',
    'period',
    'fraction',
    'zerooldstyle',
    'oneoldstyle',
    'twooldstyle',
    'threeoldstyle',
    'fouroldstyle',
    'fiveoldstyle',
    'sixoldstyle',
    'sevenoldstyle',
    'eightoldstyle',
    'nineoldstyle',
    'colon',
    'semicolon',
    'commasuperior',
    'threequartersemdash',
    'periodsuperior',
    'asuperior',
    'bsuperior',
    'centsuperior',
    'dsuperior',
    'esuperior',
    'isuperior',
    'lsuperior',
    'msuperior',
    'nsuperior',
    'osuperior',
    'rsuperior',
    'ssuperior',
    'tsuperior',
    'ff',
    'fi',
    'fl',
    'ffi',
    'ffl',
    'parenleftinferior',
    'parenrightinferior',
    'hyphensuperior',
    'colonmonetary',
    'onefitted',
    'rupiah',
    'centoldstyle',
    'figuredash',
    'hypheninferior',
    'onequarter',
    'onehalf',
    'threequarters',
    'oneeighth',
    'threeeighths',
    'fiveeighths',
    'seveneighths',
    'onethird',
    'twothirds',
    'zerosuperior',
    'onesuperior',
    'twosuperior',
    'threesuperior',
    'foursuperior',
    'fivesuperior',
    'sixsuperior',
    'sevensuperior',
    'eightsuperior',
    'ninesuperior',
    'zeroinferior',
    'oneinferior',
    'twoinferior',
    'threeinferior',
    'fourinferior',
    'fiveinferior',
    'sixinferior',
    'seveninferior',
    'eightinferior',
    'nineinferior',
    'centinferior',
    'dollarinferior',
    'periodinferior',
    'commainferior'
   ];
   exports.ISOAdobeCharset = ISOAdobeCharset;
   exports.ExpertCharset = ExpertCharset;
   exports.ExpertSubsetCharset = ExpertSubsetCharset;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreEncodings = {});
  }(this, function (exports) {
   var ExpertEncoding = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'space',
    'exclamsmall',
    'Hungarumlautsmall',
    '',
    'dollaroldstyle',
    'dollarsuperior',
    'ampersandsmall',
    'Acutesmall',
    'parenleftsuperior',
    'parenrightsuperior',
    'twodotenleader',
    'onedotenleader',
    'comma',
    'hyphen',
    'period',
    'fraction',
    'zerooldstyle',
    'oneoldstyle',
    'twooldstyle',
    'threeoldstyle',
    'fouroldstyle',
    'fiveoldstyle',
    'sixoldstyle',
    'sevenoldstyle',
    'eightoldstyle',
    'nineoldstyle',
    'colon',
    'semicolon',
    'commasuperior',
    'threequartersemdash',
    'periodsuperior',
    'questionsmall',
    '',
    'asuperior',
    'bsuperior',
    'centsuperior',
    'dsuperior',
    'esuperior',
    '',
    '',
    'isuperior',
    '',
    '',
    'lsuperior',
    'msuperior',
    'nsuperior',
    'osuperior',
    '',
    '',
    'rsuperior',
    'ssuperior',
    'tsuperior',
    '',
    'ff',
    'fi',
    'fl',
    'ffi',
    'ffl',
    'parenleftinferior',
    '',
    'parenrightinferior',
    'Circumflexsmall',
    'hyphensuperior',
    'Gravesmall',
    'Asmall',
    'Bsmall',
    'Csmall',
    'Dsmall',
    'Esmall',
    'Fsmall',
    'Gsmall',
    'Hsmall',
    'Ismall',
    'Jsmall',
    'Ksmall',
    'Lsmall',
    'Msmall',
    'Nsmall',
    'Osmall',
    'Psmall',
    'Qsmall',
    'Rsmall',
    'Ssmall',
    'Tsmall',
    'Usmall',
    'Vsmall',
    'Wsmall',
    'Xsmall',
    'Ysmall',
    'Zsmall',
    'colonmonetary',
    'onefitted',
    'rupiah',
    'Tildesmall',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'exclamdownsmall',
    'centoldstyle',
    'Lslashsmall',
    '',
    '',
    'Scaronsmall',
    'Zcaronsmall',
    'Dieresissmall',
    'Brevesmall',
    'Caronsmall',
    '',
    'Dotaccentsmall',
    '',
    '',
    'Macronsmall',
    '',
    '',
    'figuredash',
    'hypheninferior',
    '',
    '',
    'Ogoneksmall',
    'Ringsmall',
    'Cedillasmall',
    '',
    '',
    '',
    'onequarter',
    'onehalf',
    'threequarters',
    'questiondownsmall',
    'oneeighth',
    'threeeighths',
    'fiveeighths',
    'seveneighths',
    'onethird',
    'twothirds',
    '',
    '',
    'zerosuperior',
    'onesuperior',
    'twosuperior',
    'threesuperior',
    'foursuperior',
    'fivesuperior',
    'sixsuperior',
    'sevensuperior',
    'eightsuperior',
    'ninesuperior',
    'zeroinferior',
    'oneinferior',
    'twoinferior',
    'threeinferior',
    'fourinferior',
    'fiveinferior',
    'sixinferior',
    'seveninferior',
    'eightinferior',
    'nineinferior',
    'centinferior',
    'dollarinferior',
    'periodinferior',
    'commainferior',
    'Agravesmall',
    'Aacutesmall',
    'Acircumflexsmall',
    'Atildesmall',
    'Adieresissmall',
    'Aringsmall',
    'AEsmall',
    'Ccedillasmall',
    'Egravesmall',
    'Eacutesmall',
    'Ecircumflexsmall',
    'Edieresissmall',
    'Igravesmall',
    'Iacutesmall',
    'Icircumflexsmall',
    'Idieresissmall',
    'Ethsmall',
    'Ntildesmall',
    'Ogravesmall',
    'Oacutesmall',
    'Ocircumflexsmall',
    'Otildesmall',
    'Odieresissmall',
    'OEsmall',
    'Oslashsmall',
    'Ugravesmall',
    'Uacutesmall',
    'Ucircumflexsmall',
    'Udieresissmall',
    'Yacutesmall',
    'Thornsmall',
    'Ydieresissmall'
   ];
   var MacExpertEncoding = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'space',
    'exclamsmall',
    'Hungarumlautsmall',
    'centoldstyle',
    'dollaroldstyle',
    'dollarsuperior',
    'ampersandsmall',
    'Acutesmall',
    'parenleftsuperior',
    'parenrightsuperior',
    'twodotenleader',
    'onedotenleader',
    'comma',
    'hyphen',
    'period',
    'fraction',
    'zerooldstyle',
    'oneoldstyle',
    'twooldstyle',
    'threeoldstyle',
    'fouroldstyle',
    'fiveoldstyle',
    'sixoldstyle',
    'sevenoldstyle',
    'eightoldstyle',
    'nineoldstyle',
    'colon',
    'semicolon',
    '',
    'threequartersemdash',
    '',
    'questionsmall',
    '',
    '',
    '',
    '',
    'Ethsmall',
    '',
    '',
    'onequarter',
    'onehalf',
    'threequarters',
    'oneeighth',
    'threeeighths',
    'fiveeighths',
    'seveneighths',
    'onethird',
    'twothirds',
    '',
    '',
    '',
    '',
    '',
    '',
    'ff',
    'fi',
    'fl',
    'ffi',
    'ffl',
    'parenleftinferior',
    '',
    'parenrightinferior',
    'Circumflexsmall',
    'hypheninferior',
    'Gravesmall',
    'Asmall',
    'Bsmall',
    'Csmall',
    'Dsmall',
    'Esmall',
    'Fsmall',
    'Gsmall',
    'Hsmall',
    'Ismall',
    'Jsmall',
    'Ksmall',
    'Lsmall',
    'Msmall',
    'Nsmall',
    'Osmall',
    'Psmall',
    'Qsmall',
    'Rsmall',
    'Ssmall',
    'Tsmall',
    'Usmall',
    'Vsmall',
    'Wsmall',
    'Xsmall',
    'Ysmall',
    'Zsmall',
    'colonmonetary',
    'onefitted',
    'rupiah',
    'Tildesmall',
    '',
    '',
    'asuperior',
    'centsuperior',
    '',
    '',
    '',
    '',
    'Aacutesmall',
    'Agravesmall',
    'Acircumflexsmall',
    'Adieresissmall',
    'Atildesmall',
    'Aringsmall',
    'Ccedillasmall',
    'Eacutesmall',
    'Egravesmall',
    'Ecircumflexsmall',
    'Edieresissmall',
    'Iacutesmall',
    'Igravesmall',
    'Icircumflexsmall',
    'Idieresissmall',
    'Ntildesmall',
    'Oacutesmall',
    'Ogravesmall',
    'Ocircumflexsmall',
    'Odieresissmall',
    'Otildesmall',
    'Uacutesmall',
    'Ugravesmall',
    'Ucircumflexsmall',
    'Udieresissmall',
    '',
    'eightsuperior',
    'fourinferior',
    'threeinferior',
    'sixinferior',
    'eightinferior',
    'seveninferior',
    'Scaronsmall',
    '',
    'centinferior',
    'twoinferior',
    '',
    'Dieresissmall',
    '',
    'Caronsmall',
    'osuperior',
    'fiveinferior',
    '',
    'commainferior',
    'periodinferior',
    'Yacutesmall',
    '',
    'dollarinferior',
    '',
    'Thornsmall',
    '',
    'nineinferior',
    'zeroinferior',
    'Zcaronsmall',
    'AEsmall',
    'Oslashsmall',
    'questiondownsmall',
    'oneinferior',
    'Lslashsmall',
    '',
    '',
    '',
    '',
    '',
    '',
    'Cedillasmall',
    '',
    '',
    '',
    '',
    '',
    'OEsmall',
    'figuredash',
    'hyphensuperior',
    '',
    '',
    '',
    '',
    'exclamdownsmall',
    '',
    'Ydieresissmall',
    '',
    'onesuperior',
    'twosuperior',
    'threesuperior',
    'foursuperior',
    'fivesuperior',
    'sixsuperior',
    'sevensuperior',
    'ninesuperior',
    'zerosuperior',
    '',
    'esuperior',
    'rsuperior',
    'tsuperior',
    '',
    '',
    'isuperior',
    'ssuperior',
    'dsuperior',
    '',
    '',
    '',
    '',
    '',
    'lsuperior',
    'Ogoneksmall',
    'Brevesmall',
    'Macronsmall',
    'bsuperior',
    'nsuperior',
    'msuperior',
    'commasuperior',
    'periodsuperior',
    'Dotaccentsmall',
    'Ringsmall'
   ];
   var MacRomanEncoding = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'space',
    'exclam',
    'quotedbl',
    'numbersign',
    'dollar',
    'percent',
    'ampersand',
    'quotesingle',
    'parenleft',
    'parenright',
    'asterisk',
    'plus',
    'comma',
    'hyphen',
    'period',
    'slash',
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'colon',
    'semicolon',
    'less',
    'equal',
    'greater',
    'question',
    'at',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'bracketleft',
    'backslash',
    'bracketright',
    'asciicircum',
    'underscore',
    'grave',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'braceleft',
    'bar',
    'braceright',
    'asciitilde',
    '',
    'Adieresis',
    'Aring',
    'Ccedilla',
    'Eacute',
    'Ntilde',
    'Odieresis',
    'Udieresis',
    'aacute',
    'agrave',
    'acircumflex',
    'adieresis',
    'atilde',
    'aring',
    'ccedilla',
    'eacute',
    'egrave',
    'ecircumflex',
    'edieresis',
    'iacute',
    'igrave',
    'icircumflex',
    'idieresis',
    'ntilde',
    'oacute',
    'ograve',
    'ocircumflex',
    'odieresis',
    'otilde',
    'uacute',
    'ugrave',
    'ucircumflex',
    'udieresis',
    'dagger',
    'degree',
    'cent',
    'sterling',
    'section',
    'bullet',
    'paragraph',
    'germandbls',
    'registered',
    'copyright',
    'trademark',
    'acute',
    'dieresis',
    'notequal',
    'AE',
    'Oslash',
    'infinity',
    'plusminus',
    'lessequal',
    'greaterequal',
    'yen',
    'mu',
    'partialdiff',
    'summation',
    'product',
    'pi',
    'integral',
    'ordfeminine',
    'ordmasculine',
    'Omega',
    'ae',
    'oslash',
    'questiondown',
    'exclamdown',
    'logicalnot',
    'radical',
    'florin',
    'approxequal',
    'Delta',
    'guillemotleft',
    'guillemotright',
    'ellipsis',
    'space',
    'Agrave',
    'Atilde',
    'Otilde',
    'OE',
    'oe',
    'endash',
    'emdash',
    'quotedblleft',
    'quotedblright',
    'quoteleft',
    'quoteright',
    'divide',
    'lozenge',
    'ydieresis',
    'Ydieresis',
    'fraction',
    'currency',
    'guilsinglleft',
    'guilsinglright',
    'fi',
    'fl',
    'daggerdbl',
    'periodcentered',
    'quotesinglbase',
    'quotedblbase',
    'perthousand',
    'Acircumflex',
    'Ecircumflex',
    'Aacute',
    'Edieresis',
    'Egrave',
    'Iacute',
    'Icircumflex',
    'Idieresis',
    'Igrave',
    'Oacute',
    'Ocircumflex',
    'apple',
    'Ograve',
    'Uacute',
    'Ucircumflex',
    'Ugrave',
    'dotlessi',
    'circumflex',
    'tilde',
    'macron',
    'breve',
    'dotaccent',
    'ring',
    'cedilla',
    'hungarumlaut',
    'ogonek',
    'caron'
   ];
   var StandardEncoding = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'space',
    'exclam',
    'quotedbl',
    'numbersign',
    'dollar',
    'percent',
    'ampersand',
    'quoteright',
    'parenleft',
    'parenright',
    'asterisk',
    'plus',
    'comma',
    'hyphen',
    'period',
    'slash',
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'colon',
    'semicolon',
    'less',
    'equal',
    'greater',
    'question',
    'at',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'bracketleft',
    'backslash',
    'bracketright',
    'asciicircum',
    'underscore',
    'quoteleft',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'braceleft',
    'bar',
    'braceright',
    'asciitilde',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'exclamdown',
    'cent',
    'sterling',
    'fraction',
    'yen',
    'florin',
    'section',
    'currency',
    'quotesingle',
    'quotedblleft',
    'guillemotleft',
    'guilsinglleft',
    'guilsinglright',
    'fi',
    'fl',
    '',
    'endash',
    'dagger',
    'daggerdbl',
    'periodcentered',
    '',
    'paragraph',
    'bullet',
    'quotesinglbase',
    'quotedblbase',
    'quotedblright',
    'guillemotright',
    'ellipsis',
    'perthousand',
    '',
    'questiondown',
    '',
    'grave',
    'acute',
    'circumflex',
    'tilde',
    'macron',
    'breve',
    'dotaccent',
    'dieresis',
    '',
    'ring',
    'cedilla',
    '',
    'hungarumlaut',
    'ogonek',
    'caron',
    'emdash',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'AE',
    '',
    'ordfeminine',
    '',
    '',
    '',
    '',
    'Lslash',
    'Oslash',
    'OE',
    'ordmasculine',
    '',
    '',
    '',
    '',
    '',
    'ae',
    '',
    '',
    '',
    'dotlessi',
    '',
    '',
    'lslash',
    'oslash',
    'oe',
    'germandbls'
   ];
   var WinAnsiEncoding = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'space',
    'exclam',
    'quotedbl',
    'numbersign',
    'dollar',
    'percent',
    'ampersand',
    'quotesingle',
    'parenleft',
    'parenright',
    'asterisk',
    'plus',
    'comma',
    'hyphen',
    'period',
    'slash',
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'colon',
    'semicolon',
    'less',
    'equal',
    'greater',
    'question',
    'at',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'bracketleft',
    'backslash',
    'bracketright',
    'asciicircum',
    'underscore',
    'grave',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'braceleft',
    'bar',
    'braceright',
    'asciitilde',
    'bullet',
    'Euro',
    'bullet',
    'quotesinglbase',
    'florin',
    'quotedblbase',
    'ellipsis',
    'dagger',
    'daggerdbl',
    'circumflex',
    'perthousand',
    'Scaron',
    'guilsinglleft',
    'OE',
    'bullet',
    'Zcaron',
    'bullet',
    'bullet',
    'quoteleft',
    'quoteright',
    'quotedblleft',
    'quotedblright',
    'bullet',
    'endash',
    'emdash',
    'tilde',
    'trademark',
    'scaron',
    'guilsinglright',
    'oe',
    'bullet',
    'zcaron',
    'Ydieresis',
    'space',
    'exclamdown',
    'cent',
    'sterling',
    'currency',
    'yen',
    'brokenbar',
    'section',
    'dieresis',
    'copyright',
    'ordfeminine',
    'guillemotleft',
    'logicalnot',
    'hyphen',
    'registered',
    'macron',
    'degree',
    'plusminus',
    'twosuperior',
    'threesuperior',
    'acute',
    'mu',
    'paragraph',
    'periodcentered',
    'cedilla',
    'onesuperior',
    'ordmasculine',
    'guillemotright',
    'onequarter',
    'onehalf',
    'threequarters',
    'questiondown',
    'Agrave',
    'Aacute',
    'Acircumflex',
    'Atilde',
    'Adieresis',
    'Aring',
    'AE',
    'Ccedilla',
    'Egrave',
    'Eacute',
    'Ecircumflex',
    'Edieresis',
    'Igrave',
    'Iacute',
    'Icircumflex',
    'Idieresis',
    'Eth',
    'Ntilde',
    'Ograve',
    'Oacute',
    'Ocircumflex',
    'Otilde',
    'Odieresis',
    'multiply',
    'Oslash',
    'Ugrave',
    'Uacute',
    'Ucircumflex',
    'Udieresis',
    'Yacute',
    'Thorn',
    'germandbls',
    'agrave',
    'aacute',
    'acircumflex',
    'atilde',
    'adieresis',
    'aring',
    'ae',
    'ccedilla',
    'egrave',
    'eacute',
    'ecircumflex',
    'edieresis',
    'igrave',
    'iacute',
    'icircumflex',
    'idieresis',
    'eth',
    'ntilde',
    'ograve',
    'oacute',
    'ocircumflex',
    'otilde',
    'odieresis',
    'divide',
    'oslash',
    'ugrave',
    'uacute',
    'ucircumflex',
    'udieresis',
    'yacute',
    'thorn',
    'ydieresis'
   ];
   var SymbolSetEncoding = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'space',
    'exclam',
    'universal',
    'numbersign',
    'existential',
    'percent',
    'ampersand',
    'suchthat',
    'parenleft',
    'parenright',
    'asteriskmath',
    'plus',
    'comma',
    'minus',
    'period',
    'slash',
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'colon',
    'semicolon',
    'less',
    'equal',
    'greater',
    'question',
    'congruent',
    'Alpha',
    'Beta',
    'Chi',
    'Delta',
    'Epsilon',
    'Phi',
    'Gamma',
    'Eta',
    'Iota',
    'theta1',
    'Kappa',
    'Lambda',
    'Mu',
    'Nu',
    'Omicron',
    'Pi',
    'Theta',
    'Rho',
    'Sigma',
    'Tau',
    'Upsilon',
    'sigma1',
    'Omega',
    'Xi',
    'Psi',
    'Zeta',
    'bracketleft',
    'therefore',
    'bracketright',
    'perpendicular',
    'underscore',
    'radicalex',
    'alpha',
    'beta',
    'chi',
    'delta',
    'epsilon',
    'phi',
    'gamma',
    'eta',
    'iota',
    'phi1',
    'kappa',
    'lambda',
    'mu',
    'nu',
    'omicron',
    'pi',
    'theta',
    'rho',
    'sigma',
    'tau',
    'upsilon',
    'omega1',
    'omega',
    'xi',
    'psi',
    'zeta',
    'braceleft',
    'bar',
    'braceright',
    'similar',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Euro',
    'Upsilon1',
    'minute',
    'lessequal',
    'fraction',
    'infinity',
    'florin',
    'club',
    'diamond',
    'heart',
    'spade',
    'arrowboth',
    'arrowleft',
    'arrowup',
    'arrowright',
    'arrowdown',
    'degree',
    'plusminus',
    'second',
    'greaterequal',
    'multiply',
    'proportional',
    'partialdiff',
    'bullet',
    'divide',
    'notequal',
    'equivalence',
    'approxequal',
    'ellipsis',
    'arrowvertex',
    'arrowhorizex',
    'carriagereturn',
    'aleph',
    'Ifraktur',
    'Rfraktur',
    'weierstrass',
    'circlemultiply',
    'circleplus',
    'emptyset',
    'intersection',
    'union',
    'propersuperset',
    'reflexsuperset',
    'notsubset',
    'propersubset',
    'reflexsubset',
    'element',
    'notelement',
    'angle',
    'gradient',
    'registerserif',
    'copyrightserif',
    'trademarkserif',
    'product',
    'radical',
    'dotmath',
    'logicalnot',
    'logicaland',
    'logicalor',
    'arrowdblboth',
    'arrowdblleft',
    'arrowdblup',
    'arrowdblright',
    'arrowdbldown',
    'lozenge',
    'angleleft',
    'registersans',
    'copyrightsans',
    'trademarksans',
    'summation',
    'parenlefttp',
    'parenleftex',
    'parenleftbt',
    'bracketlefttp',
    'bracketleftex',
    'bracketleftbt',
    'bracelefttp',
    'braceleftmid',
    'braceleftbt',
    'braceex',
    '',
    'angleright',
    'integral',
    'integraltp',
    'integralex',
    'integralbt',
    'parenrighttp',
    'parenrightex',
    'parenrightbt',
    'bracketrighttp',
    'bracketrightex',
    'bracketrightbt',
    'bracerighttp',
    'bracerightmid',
    'bracerightbt'
   ];
   var ZapfDingbatsEncoding = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'space',
    'a1',
    'a2',
    'a202',
    'a3',
    'a4',
    'a5',
    'a119',
    'a118',
    'a117',
    'a11',
    'a12',
    'a13',
    'a14',
    'a15',
    'a16',
    'a105',
    'a17',
    'a18',
    'a19',
    'a20',
    'a21',
    'a22',
    'a23',
    'a24',
    'a25',
    'a26',
    'a27',
    'a28',
    'a6',
    'a7',
    'a8',
    'a9',
    'a10',
    'a29',
    'a30',
    'a31',
    'a32',
    'a33',
    'a34',
    'a35',
    'a36',
    'a37',
    'a38',
    'a39',
    'a40',
    'a41',
    'a42',
    'a43',
    'a44',
    'a45',
    'a46',
    'a47',
    'a48',
    'a49',
    'a50',
    'a51',
    'a52',
    'a53',
    'a54',
    'a55',
    'a56',
    'a57',
    'a58',
    'a59',
    'a60',
    'a61',
    'a62',
    'a63',
    'a64',
    'a65',
    'a66',
    'a67',
    'a68',
    'a69',
    'a70',
    'a71',
    'a72',
    'a73',
    'a74',
    'a203',
    'a75',
    'a204',
    'a76',
    'a77',
    'a78',
    'a79',
    'a81',
    'a82',
    'a83',
    'a84',
    'a97',
    'a98',
    'a99',
    'a100',
    '',
    'a89',
    'a90',
    'a93',
    'a94',
    'a91',
    'a92',
    'a205',
    'a85',
    'a206',
    'a86',
    'a87',
    'a88',
    'a95',
    'a96',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'a101',
    'a102',
    'a103',
    'a104',
    'a106',
    'a107',
    'a108',
    'a112',
    'a111',
    'a110',
    'a109',
    'a120',
    'a121',
    'a122',
    'a123',
    'a124',
    'a125',
    'a126',
    'a127',
    'a128',
    'a129',
    'a130',
    'a131',
    'a132',
    'a133',
    'a134',
    'a135',
    'a136',
    'a137',
    'a138',
    'a139',
    'a140',
    'a141',
    'a142',
    'a143',
    'a144',
    'a145',
    'a146',
    'a147',
    'a148',
    'a149',
    'a150',
    'a151',
    'a152',
    'a153',
    'a154',
    'a155',
    'a156',
    'a157',
    'a158',
    'a159',
    'a160',
    'a161',
    'a163',
    'a164',
    'a196',
    'a165',
    'a192',
    'a166',
    'a167',
    'a168',
    'a169',
    'a170',
    'a171',
    'a172',
    'a173',
    'a162',
    'a174',
    'a175',
    'a176',
    'a177',
    'a178',
    'a179',
    'a193',
    'a180',
    'a199',
    'a181',
    'a200',
    'a182',
    '',
    'a201',
    'a183',
    'a184',
    'a197',
    'a185',
    'a194',
    'a198',
    'a186',
    'a195',
    'a187',
    'a188',
    'a189',
    'a190',
    'a191'
   ];
   function getEncoding(encodingName) {
    switch (encodingName) {
    case 'WinAnsiEncoding':
     return WinAnsiEncoding;
    case 'StandardEncoding':
     return StandardEncoding;
    case 'MacRomanEncoding':
     return MacRomanEncoding;
    case 'SymbolSetEncoding':
     return SymbolSetEncoding;
    case 'ZapfDingbatsEncoding':
     return ZapfDingbatsEncoding;
    case 'ExpertEncoding':
     return ExpertEncoding;
    case 'MacExpertEncoding':
     return MacExpertEncoding;
    default:
     return null;
    }
   }
   exports.WinAnsiEncoding = WinAnsiEncoding;
   exports.StandardEncoding = StandardEncoding;
   exports.MacRomanEncoding = MacRomanEncoding;
   exports.SymbolSetEncoding = SymbolSetEncoding;
   exports.ZapfDingbatsEncoding = ZapfDingbatsEncoding;
   exports.ExpertEncoding = ExpertEncoding;
   exports.getEncoding = getEncoding;
  }));
  (function (root, factory) {
   factory(root.pdfjsSharedUtil = {});
  }(this, function (exports) {
   var globalScope = typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : this;
   var FONT_IDENTITY_MATRIX = [
    0.001,
    0,
    0,
    0.001,
    0,
    0
   ];
   var TextRenderingMode = {
    FILL: 0,
    STROKE: 1,
    FILL_STROKE: 2,
    INVISIBLE: 3,
    FILL_ADD_TO_PATH: 4,
    STROKE_ADD_TO_PATH: 5,
    FILL_STROKE_ADD_TO_PATH: 6,
    ADD_TO_PATH: 7,
    FILL_STROKE_MASK: 3,
    ADD_TO_PATH_FLAG: 4
   };
   var ImageKind = {
    GRAYSCALE_1BPP: 1,
    RGB_24BPP: 2,
    RGBA_32BPP: 3
   };
   var AnnotationType = {
    TEXT: 1,
    LINK: 2,
    FREETEXT: 3,
    LINE: 4,
    SQUARE: 5,
    CIRCLE: 6,
    POLYGON: 7,
    POLYLINE: 8,
    HIGHLIGHT: 9,
    UNDERLINE: 10,
    SQUIGGLY: 11,
    STRIKEOUT: 12,
    STAMP: 13,
    CARET: 14,
    INK: 15,
    POPUP: 16,
    FILEATTACHMENT: 17,
    SOUND: 18,
    MOVIE: 19,
    WIDGET: 20,
    SCREEN: 21,
    PRINTERMARK: 22,
    TRAPNET: 23,
    WATERMARK: 24,
    THREED: 25,
    REDACT: 26
   };
   var AnnotationFlag = {
    INVISIBLE: 0x01,
    HIDDEN: 0x02,
    PRINT: 0x04,
    NOZOOM: 0x08,
    NOROTATE: 0x10,
    NOVIEW: 0x20,
    READONLY: 0x40,
    LOCKED: 0x80,
    TOGGLENOVIEW: 0x100,
    LOCKEDCONTENTS: 0x200
   };
   var AnnotationFieldFlag = {
    READONLY: 0x0000001,
    REQUIRED: 0x0000002,
    NOEXPORT: 0x0000004,
    MULTILINE: 0x0001000,
    PASSWORD: 0x0002000,
    NOTOGGLETOOFF: 0x0004000,
    RADIO: 0x0008000,
    PUSHBUTTON: 0x0010000,
    COMBO: 0x0020000,
    EDIT: 0x0040000,
    SORT: 0x0080000,
    FILESELECT: 0x0100000,
    MULTISELECT: 0x0200000,
    DONOTSPELLCHECK: 0x0400000,
    DONOTSCROLL: 0x0800000,
    COMB: 0x1000000,
    RICHTEXT: 0x2000000,
    RADIOSINUNISON: 0x2000000,
    COMMITONSELCHANGE: 0x4000000
   };
   var AnnotationBorderStyleType = {
    SOLID: 1,
    DASHED: 2,
    BEVELED: 3,
    INSET: 4,
    UNDERLINE: 5
   };
   var StreamType = {
    UNKNOWN: 0,
    FLATE: 1,
    LZW: 2,
    DCT: 3,
    JPX: 4,
    JBIG: 5,
    A85: 6,
    AHX: 7,
    CCF: 8,
    RL: 9
   };
   var FontType = {
    UNKNOWN: 0,
    TYPE1: 1,
    TYPE1C: 2,
    CIDFONTTYPE0: 3,
    CIDFONTTYPE0C: 4,
    TRUETYPE: 5,
    CIDFONTTYPE2: 6,
    TYPE3: 7,
    OPENTYPE: 8,
    TYPE0: 9,
    MMTYPE1: 10
   };
   var VERBOSITY_LEVELS = {
    errors: 0,
    warnings: 1,
    infos: 5
   };
   var OPS = {
    dependency: 1,
    setLineWidth: 2,
    setLineCap: 3,
    setLineJoin: 4,
    setMiterLimit: 5,
    setDash: 6,
    setRenderingIntent: 7,
    setFlatness: 8,
    setGState: 9,
    save: 10,
    restore: 11,
    transform: 12,
    moveTo: 13,
    lineTo: 14,
    curveTo: 15,
    curveTo2: 16,
    curveTo3: 17,
    closePath: 18,
    rectangle: 19,
    stroke: 20,
    closeStroke: 21,
    fill: 22,
    eoFill: 23,
    fillStroke: 24,
    eoFillStroke: 25,
    closeFillStroke: 26,
    closeEOFillStroke: 27,
    endPath: 28,
    clip: 29,
    eoClip: 30,
    beginText: 31,
    endText: 32,
    setCharSpacing: 33,
    setWordSpacing: 34,
    setHScale: 35,
    setLeading: 36,
    setFont: 37,
    setTextRenderingMode: 38,
    setTextRise: 39,
    moveText: 40,
    setLeadingMoveText: 41,
    setTextMatrix: 42,
    nextLine: 43,
    showText: 44,
    showSpacedText: 45,
    nextLineShowText: 46,
    nextLineSetSpacingShowText: 47,
    setCharWidth: 48,
    setCharWidthAndBounds: 49,
    setStrokeColorSpace: 50,
    setFillColorSpace: 51,
    setStrokeColor: 52,
    setStrokeColorN: 53,
    setFillColor: 54,
    setFillColorN: 55,
    setStrokeGray: 56,
    setFillGray: 57,
    setStrokeRGBColor: 58,
    setFillRGBColor: 59,
    setStrokeCMYKColor: 60,
    setFillCMYKColor: 61,
    shadingFill: 62,
    beginInlineImage: 63,
    beginImageData: 64,
    endInlineImage: 65,
    paintXObject: 66,
    markPoint: 67,
    markPointProps: 68,
    beginMarkedContent: 69,
    beginMarkedContentProps: 70,
    endMarkedContent: 71,
    beginCompat: 72,
    endCompat: 73,
    paintFormXObjectBegin: 74,
    paintFormXObjectEnd: 75,
    beginGroup: 76,
    endGroup: 77,
    beginAnnotations: 78,
    endAnnotations: 79,
    beginAnnotation: 80,
    endAnnotation: 81,
    paintJpegXObject: 82,
    paintImageMaskXObject: 83,
    paintImageMaskXObjectGroup: 84,
    paintImageXObject: 85,
    paintInlineImageXObject: 86,
    paintInlineImageXObjectGroup: 87,
    paintImageXObjectRepeat: 88,
    paintImageMaskXObjectRepeat: 89,
    paintSolidColorImageMask: 90,
    constructPath: 91
   };
   var verbosity = VERBOSITY_LEVELS.warnings;
   function setVerbosityLevel(level) {
    verbosity = level;
   }
   function getVerbosityLevel() {
    return verbosity;
   }
   function info(msg) {
    if (verbosity >= VERBOSITY_LEVELS.infos) {
     console.log('Info: ' + msg);
    }
   }
   function warn(msg) {
    if (verbosity >= VERBOSITY_LEVELS.warnings) {
     console.log('Warning: ' + msg);
    }
   }
   function deprecated(details) {
    console.log('Deprecated API usage: ' + details);
   }
   function error(msg) {
    if (verbosity >= VERBOSITY_LEVELS.errors) {
     console.log('Error: ' + msg);
     console.log(backtrace());
    }
    throw new Error(msg);
   }
   function backtrace() {
    try {
     throw new Error();
    } catch (e) {
     return e.stack ? e.stack.split('\n').slice(2).join('\n') : '';
    }
   }
   function assert(cond, msg) {
    if (!cond) {
     error(msg);
    }
   }
   var UNSUPPORTED_FEATURES = {
    unknown: 'unknown',
    forms: 'forms',
    javaScript: 'javaScript',
    smask: 'smask',
    shadingPattern: 'shadingPattern',
    font: 'font'
   };
   function isSameOrigin(baseUrl, otherUrl) {
    try {
     var base = new URL(baseUrl);
     if (!base.origin || base.origin === 'null') {
      return false;
     }
    } catch (e) {
     return false;
    }
    var other = new URL(otherUrl, base);
    return base.origin === other.origin;
   }
   function isValidProtocol(url) {
    if (!url) {
     return false;
    }
    switch (url.protocol) {
    case 'http:':
    case 'https:':
    case 'ftp:':
    case 'mailto:':
    case 'tel:':
     return true;
    default:
     return false;
    }
   }
   function createValidAbsoluteUrl(url, baseUrl) {
    if (!url) {
     return null;
    }
    try {
     var absoluteUrl = baseUrl ? new URL(url, baseUrl) : new URL(url);
     if (isValidProtocol(absoluteUrl)) {
      return absoluteUrl;
     }
    } catch (ex) {
    }
    return null;
   }
   function shadow(obj, prop, value) {
    Object.defineProperty(obj, prop, {
     value: value,
     enumerable: true,
     configurable: true,
     writable: false
    });
    return value;
   }
   function getLookupTableFactory(initializer) {
    var lookup;
    return function () {
     if (initializer) {
      lookup = Object.create(null);
      initializer(lookup);
      initializer = null;
     }
     return lookup;
    };
   }
   var PasswordResponses = {
    NEED_PASSWORD: 1,
    INCORRECT_PASSWORD: 2
   };
   var PasswordException = function PasswordExceptionClosure() {
    function PasswordException(msg, code) {
     this.name = 'PasswordException';
     this.message = msg;
     this.code = code;
    }
    PasswordException.prototype = new Error();
    PasswordException.constructor = PasswordException;
    return PasswordException;
   }();
   var UnknownErrorException = function UnknownErrorExceptionClosure() {
    function UnknownErrorException(msg, details) {
     this.name = 'UnknownErrorException';
     this.message = msg;
     this.details = details;
    }
    UnknownErrorException.prototype = new Error();
    UnknownErrorException.constructor = UnknownErrorException;
    return UnknownErrorException;
   }();
   var InvalidPDFException = function InvalidPDFExceptionClosure() {
    function InvalidPDFException(msg) {
     this.name = 'InvalidPDFException';
     this.message = msg;
    }
    InvalidPDFException.prototype = new Error();
    InvalidPDFException.constructor = InvalidPDFException;
    return InvalidPDFException;
   }();
   var MissingPDFException = function MissingPDFExceptionClosure() {
    function MissingPDFException(msg) {
     this.name = 'MissingPDFException';
     this.message = msg;
    }
    MissingPDFException.prototype = new Error();
    MissingPDFException.constructor = MissingPDFException;
    return MissingPDFException;
   }();
   var UnexpectedResponseException = function UnexpectedResponseExceptionClosure() {
    function UnexpectedResponseException(msg, status) {
     this.name = 'UnexpectedResponseException';
     this.message = msg;
     this.status = status;
    }
    UnexpectedResponseException.prototype = new Error();
    UnexpectedResponseException.constructor = UnexpectedResponseException;
    return UnexpectedResponseException;
   }();
   var NotImplementedException = function NotImplementedExceptionClosure() {
    function NotImplementedException(msg) {
     this.message = msg;
    }
    NotImplementedException.prototype = new Error();
    NotImplementedException.prototype.name = 'NotImplementedException';
    NotImplementedException.constructor = NotImplementedException;
    return NotImplementedException;
   }();
   var MissingDataException = function MissingDataExceptionClosure() {
    function MissingDataException(begin, end) {
     this.begin = begin;
     this.end = end;
     this.message = 'Missing data [' + begin + ', ' + end + ')';
    }
    MissingDataException.prototype = new Error();
    MissingDataException.prototype.name = 'MissingDataException';
    MissingDataException.constructor = MissingDataException;
    return MissingDataException;
   }();
   var XRefParseException = function XRefParseExceptionClosure() {
    function XRefParseException(msg) {
     this.message = msg;
    }
    XRefParseException.prototype = new Error();
    XRefParseException.prototype.name = 'XRefParseException';
    XRefParseException.constructor = XRefParseException;
    return XRefParseException;
   }();
   var NullCharactersRegExp = /\x00/g;
   function removeNullCharacters(str) {
    if (typeof str !== 'string') {
     warn('The argument for removeNullCharacters must be a string.');
     return str;
    }
    return str.replace(NullCharactersRegExp, '');
   }
   function bytesToString(bytes) {
    assert(bytes !== null && typeof bytes === 'object' && bytes.length !== undefined, 'Invalid argument for bytesToString');
    var length = bytes.length;
    var MAX_ARGUMENT_COUNT = 8192;
    if (length < MAX_ARGUMENT_COUNT) {
     return String.fromCharCode.apply(null, bytes);
    }
    var strBuf = [];
    for (var i = 0; i < length; i += MAX_ARGUMENT_COUNT) {
     var chunkEnd = Math.min(i + MAX_ARGUMENT_COUNT, length);
     var chunk = bytes.subarray(i, chunkEnd);
     strBuf.push(String.fromCharCode.apply(null, chunk));
    }
    return strBuf.join('');
   }
   function stringToBytes(str) {
    assert(typeof str === 'string', 'Invalid argument for stringToBytes');
    var length = str.length;
    var bytes = new Uint8Array(length);
    for (var i = 0; i < length; ++i) {
     bytes[i] = str.charCodeAt(i) & 0xFF;
    }
    return bytes;
   }
   function arrayByteLength(arr) {
    if (arr.length !== undefined) {
     return arr.length;
    }
    assert(arr.byteLength !== undefined);
    return arr.byteLength;
   }
   function arraysToBytes(arr) {
    if (arr.length === 1 && arr[0] instanceof Uint8Array) {
     return arr[0];
    }
    var resultLength = 0;
    var i, ii = arr.length;
    var item, itemLength;
    for (i = 0; i < ii; i++) {
     item = arr[i];
     itemLength = arrayByteLength(item);
     resultLength += itemLength;
    }
    var pos = 0;
    var data = new Uint8Array(resultLength);
    for (i = 0; i < ii; i++) {
     item = arr[i];
     if (!(item instanceof Uint8Array)) {
      if (typeof item === 'string') {
       item = stringToBytes(item);
      } else {
       item = new Uint8Array(item);
      }
     }
     itemLength = item.byteLength;
     data.set(item, pos);
     pos += itemLength;
    }
    return data;
   }
   function string32(value) {
    return String.fromCharCode(value >> 24 & 0xff, value >> 16 & 0xff, value >> 8 & 0xff, value & 0xff);
   }
   function log2(x) {
    var n = 1, i = 0;
    while (x > n) {
     n <<= 1;
     i++;
    }
    return i;
   }
   function readInt8(data, start) {
    return data[start] << 24 >> 24;
   }
   function readUint16(data, offset) {
    return data[offset] << 8 | data[offset + 1];
   }
   function readUint32(data, offset) {
    return (data[offset] << 24 | data[offset + 1] << 16 | data[offset + 2] << 8 | data[offset + 3]) >>> 0;
   }
   function isLittleEndian() {
    var buffer8 = new Uint8Array(2);
    buffer8[0] = 1;
    var buffer16 = new Uint16Array(buffer8.buffer);
    return buffer16[0] === 1;
   }
   function isEvalSupported() {
    try {
     new Function('');
     return true;
    } catch (e) {
     return false;
    }
   }
   var Uint32ArrayView = function Uint32ArrayViewClosure() {
    function Uint32ArrayView(buffer, length) {
     this.buffer = buffer;
     this.byteLength = buffer.length;
     this.length = length === undefined ? this.byteLength >> 2 : length;
     ensureUint32ArrayViewProps(this.length);
    }
    Uint32ArrayView.prototype = Object.create(null);
    var uint32ArrayViewSetters = 0;
    function createUint32ArrayProp(index) {
     return {
      get: function () {
       var buffer = this.buffer, offset = index << 2;
       return (buffer[offset] | buffer[offset + 1] << 8 | buffer[offset + 2] << 16 | buffer[offset + 3] << 24) >>> 0;
      },
      set: function (value) {
       var buffer = this.buffer, offset = index << 2;
       buffer[offset] = value & 255;
       buffer[offset + 1] = value >> 8 & 255;
       buffer[offset + 2] = value >> 16 & 255;
       buffer[offset + 3] = value >>> 24 & 255;
      }
     };
    }
    function ensureUint32ArrayViewProps(length) {
     while (uint32ArrayViewSetters < length) {
      Object.defineProperty(Uint32ArrayView.prototype, uint32ArrayViewSetters, createUint32ArrayProp(uint32ArrayViewSetters));
      uint32ArrayViewSetters++;
     }
    }
    return Uint32ArrayView;
   }();
   exports.Uint32ArrayView = Uint32ArrayView;
   var IDENTITY_MATRIX = [
    1,
    0,
    0,
    1,
    0,
    0
   ];
   var Util = function UtilClosure() {
    function Util() {
    }
    var rgbBuf = [
     'rgb(',
     0,
     ',',
     0,
     ',',
     0,
     ')'
    ];
    Util.makeCssRgb = function Util_makeCssRgb(r, g, b) {
     rgbBuf[1] = r;
     rgbBuf[3] = g;
     rgbBuf[5] = b;
     return rgbBuf.join('');
    };
    Util.transform = function Util_transform(m1, m2) {
     return [
      m1[0] * m2[0] + m1[2] * m2[1],
      m1[1] * m2[0] + m1[3] * m2[1],
      m1[0] * m2[2] + m1[2] * m2[3],
      m1[1] * m2[2] + m1[3] * m2[3],
      m1[0] * m2[4] + m1[2] * m2[5] + m1[4],
      m1[1] * m2[4] + m1[3] * m2[5] + m1[5]
     ];
    };
    Util.applyTransform = function Util_applyTransform(p, m) {
     var xt = p[0] * m[0] + p[1] * m[2] + m[4];
     var yt = p[0] * m[1] + p[1] * m[3] + m[5];
     return [
      xt,
      yt
     ];
    };
    Util.applyInverseTransform = function Util_applyInverseTransform(p, m) {
     var d = m[0] * m[3] - m[1] * m[2];
     var xt = (p[0] * m[3] - p[1] * m[2] + m[2] * m[5] - m[4] * m[3]) / d;
     var yt = (-p[0] * m[1] + p[1] * m[0] + m[4] * m[1] - m[5] * m[0]) / d;
     return [
      xt,
      yt
     ];
    };
    Util.getAxialAlignedBoundingBox = function Util_getAxialAlignedBoundingBox(r, m) {
     var p1 = Util.applyTransform(r, m);
     var p2 = Util.applyTransform(r.slice(2, 4), m);
     var p3 = Util.applyTransform([
      r[0],
      r[3]
     ], m);
     var p4 = Util.applyTransform([
      r[2],
      r[1]
     ], m);
     return [
      Math.min(p1[0], p2[0], p3[0], p4[0]),
      Math.min(p1[1], p2[1], p3[1], p4[1]),
      Math.max(p1[0], p2[0], p3[0], p4[0]),
      Math.max(p1[1], p2[1], p3[1], p4[1])
     ];
    };
    Util.inverseTransform = function Util_inverseTransform(m) {
     var d = m[0] * m[3] - m[1] * m[2];
     return [
      m[3] / d,
      -m[1] / d,
      -m[2] / d,
      m[0] / d,
      (m[2] * m[5] - m[4] * m[3]) / d,
      (m[4] * m[1] - m[5] * m[0]) / d
     ];
    };
    Util.apply3dTransform = function Util_apply3dTransform(m, v) {
     return [
      m[0] * v[0] + m[1] * v[1] + m[2] * v[2],
      m[3] * v[0] + m[4] * v[1] + m[5] * v[2],
      m[6] * v[0] + m[7] * v[1] + m[8] * v[2]
     ];
    };
    Util.singularValueDecompose2dScale = function Util_singularValueDecompose2dScale(m) {
     var transpose = [
      m[0],
      m[2],
      m[1],
      m[3]
     ];
     var a = m[0] * transpose[0] + m[1] * transpose[2];
     var b = m[0] * transpose[1] + m[1] * transpose[3];
     var c = m[2] * transpose[0] + m[3] * transpose[2];
     var d = m[2] * transpose[1] + m[3] * transpose[3];
     var first = (a + d) / 2;
     var second = Math.sqrt((a + d) * (a + d) - 4 * (a * d - c * b)) / 2;
     var sx = first + second || 1;
     var sy = first - second || 1;
     return [
      Math.sqrt(sx),
      Math.sqrt(sy)
     ];
    };
    Util.normalizeRect = function Util_normalizeRect(rect) {
     var r = rect.slice(0);
     if (rect[0] > rect[2]) {
      r[0] = rect[2];
      r[2] = rect[0];
     }
     if (rect[1] > rect[3]) {
      r[1] = rect[3];
      r[3] = rect[1];
     }
     return r;
    };
    Util.intersect = function Util_intersect(rect1, rect2) {
     function compare(a, b) {
      return a - b;
     }
     var orderedX = [
       rect1[0],
       rect1[2],
       rect2[0],
       rect2[2]
      ].sort(compare), orderedY = [
       rect1[1],
       rect1[3],
       rect2[1],
       rect2[3]
      ].sort(compare), result = [];
     rect1 = Util.normalizeRect(rect1);
     rect2 = Util.normalizeRect(rect2);
     if (orderedX[0] === rect1[0] && orderedX[1] === rect2[0] || orderedX[0] === rect2[0] && orderedX[1] === rect1[0]) {
      result[0] = orderedX[1];
      result[2] = orderedX[2];
     } else {
      return false;
     }
     if (orderedY[0] === rect1[1] && orderedY[1] === rect2[1] || orderedY[0] === rect2[1] && orderedY[1] === rect1[1]) {
      result[1] = orderedY[1];
      result[3] = orderedY[2];
     } else {
      return false;
     }
     return result;
    };
    Util.sign = function Util_sign(num) {
     return num < 0 ? -1 : 1;
    };
    var ROMAN_NUMBER_MAP = [
     '',
     'C',
     'CC',
     'CCC',
     'CD',
     'D',
     'DC',
     'DCC',
     'DCCC',
     'CM',
     '',
     'X',
     'XX',
     'XXX',
     'XL',
     'L',
     'LX',
     'LXX',
     'LXXX',
     'XC',
     '',
     'I',
     'II',
     'III',
     'IV',
     'V',
     'VI',
     'VII',
     'VIII',
     'IX'
    ];
    Util.toRoman = function Util_toRoman(number, lowerCase) {
     assert(isInt(number) && number > 0, 'The number should be a positive integer.');
     var pos, romanBuf = [];
     while (number >= 1000) {
      number -= 1000;
      romanBuf.push('M');
     }
     pos = number / 100 | 0;
     number %= 100;
     romanBuf.push(ROMAN_NUMBER_MAP[pos]);
     pos = number / 10 | 0;
     number %= 10;
     romanBuf.push(ROMAN_NUMBER_MAP[10 + pos]);
     romanBuf.push(ROMAN_NUMBER_MAP[20 + number]);
     var romanStr = romanBuf.join('');
     return lowerCase ? romanStr.toLowerCase() : romanStr;
    };
    Util.appendToArray = function Util_appendToArray(arr1, arr2) {
     Array.prototype.push.apply(arr1, arr2);
    };
    Util.prependToArray = function Util_prependToArray(arr1, arr2) {
     Array.prototype.unshift.apply(arr1, arr2);
    };
    Util.extendObj = function extendObj(obj1, obj2) {
     for (var key in obj2) {
      obj1[key] = obj2[key];
     }
    };
    Util.getInheritableProperty = function Util_getInheritableProperty(dict, name, getArray) {
     while (dict && !dict.has(name)) {
      dict = dict.get('Parent');
     }
     if (!dict) {
      return null;
     }
     return getArray ? dict.getArray(name) : dict.get(name);
    };
    Util.inherit = function Util_inherit(sub, base, prototype) {
     sub.prototype = Object.create(base.prototype);
     sub.prototype.constructor = sub;
     for (var prop in prototype) {
      sub.prototype[prop] = prototype[prop];
     }
    };
    Util.loadScript = function Util_loadScript(src, callback) {
     var script = document.createElement('script');
     var loaded = false;
     script.setAttribute('src', src);
     if (callback) {
      script.onload = function () {
       if (!loaded) {
        callback();
       }
       loaded = true;
      };
     }
     document.getElementsByTagName('head')[0].appendChild(script);
    };
    return Util;
   }();
   var PageViewport = function PageViewportClosure() {
    function PageViewport(viewBox, scale, rotation, offsetX, offsetY, dontFlip) {
     this.viewBox = viewBox;
     this.scale = scale;
     this.rotation = rotation;
     this.offsetX = offsetX;
     this.offsetY = offsetY;
     var centerX = (viewBox[2] + viewBox[0]) / 2;
     var centerY = (viewBox[3] + viewBox[1]) / 2;
     var rotateA, rotateB, rotateC, rotateD;
     rotation = rotation % 360;
     rotation = rotation < 0 ? rotation + 360 : rotation;
     switch (rotation) {
     case 180:
      rotateA = -1;
      rotateB = 0;
      rotateC = 0;
      rotateD = 1;
      break;
     case 90:
      rotateA = 0;
      rotateB = 1;
      rotateC = 1;
      rotateD = 0;
      break;
     case 270:
      rotateA = 0;
      rotateB = -1;
      rotateC = -1;
      rotateD = 0;
      break;
     default:
      rotateA = 1;
      rotateB = 0;
      rotateC = 0;
      rotateD = -1;
      break;
     }
     if (dontFlip) {
      rotateC = -rotateC;
      rotateD = -rotateD;
     }
     var offsetCanvasX, offsetCanvasY;
     var width, height;
     if (rotateA === 0) {
      offsetCanvasX = Math.abs(centerY - viewBox[1]) * scale + offsetX;
      offsetCanvasY = Math.abs(centerX - viewBox[0]) * scale + offsetY;
      width = Math.abs(viewBox[3] - viewBox[1]) * scale;
      height = Math.abs(viewBox[2] - viewBox[0]) * scale;
     } else {
      offsetCanvasX = Math.abs(centerX - viewBox[0]) * scale + offsetX;
      offsetCanvasY = Math.abs(centerY - viewBox[1]) * scale + offsetY;
      width = Math.abs(viewBox[2] - viewBox[0]) * scale;
      height = Math.abs(viewBox[3] - viewBox[1]) * scale;
     }
     this.transform = [
      rotateA * scale,
      rotateB * scale,
      rotateC * scale,
      rotateD * scale,
      offsetCanvasX - rotateA * scale * centerX - rotateC * scale * centerY,
      offsetCanvasY - rotateB * scale * centerX - rotateD * scale * centerY
     ];
     this.width = width;
     this.height = height;
     this.fontScale = scale;
    }
    PageViewport.prototype = {
     clone: function PageViewPort_clone(args) {
      args = args || {};
      var scale = 'scale' in args ? args.scale : this.scale;
      var rotation = 'rotation' in args ? args.rotation : this.rotation;
      return new PageViewport(this.viewBox.slice(), scale, rotation, this.offsetX, this.offsetY, args.dontFlip);
     },
     convertToViewportPoint: function PageViewport_convertToViewportPoint(x, y) {
      return Util.applyTransform([
       x,
       y
      ], this.transform);
     },
     convertToViewportRectangle: function PageViewport_convertToViewportRectangle(rect) {
      var tl = Util.applyTransform([
       rect[0],
       rect[1]
      ], this.transform);
      var br = Util.applyTransform([
       rect[2],
       rect[3]
      ], this.transform);
      return [
       tl[0],
       tl[1],
       br[0],
       br[1]
      ];
     },
     convertToPdfPoint: function PageViewport_convertToPdfPoint(x, y) {
      return Util.applyInverseTransform([
       x,
       y
      ], this.transform);
     }
    };
    return PageViewport;
   }();
   var PDFStringTranslateTable = [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0x2D8,
    0x2C7,
    0x2C6,
    0x2D9,
    0x2DD,
    0x2DB,
    0x2DA,
    0x2DC,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0x2022,
    0x2020,
    0x2021,
    0x2026,
    0x2014,
    0x2013,
    0x192,
    0x2044,
    0x2039,
    0x203A,
    0x2212,
    0x2030,
    0x201E,
    0x201C,
    0x201D,
    0x2018,
    0x2019,
    0x201A,
    0x2122,
    0xFB01,
    0xFB02,
    0x141,
    0x152,
    0x160,
    0x178,
    0x17D,
    0x131,
    0x142,
    0x153,
    0x161,
    0x17E,
    0,
    0x20AC
   ];
   function stringToPDFString(str) {
    var i, n = str.length, strBuf = [];
    if (str[0] === '\xFE' && str[1] === '\xFF') {
     for (i = 2; i < n; i += 2) {
      strBuf.push(String.fromCharCode(str.charCodeAt(i) << 8 | str.charCodeAt(i + 1)));
     }
    } else {
     for (i = 0; i < n; ++i) {
      var code = PDFStringTranslateTable[str.charCodeAt(i)];
      strBuf.push(code ? String.fromCharCode(code) : str.charAt(i));
     }
    }
    return strBuf.join('');
   }
   function stringToUTF8String(str) {
    return decodeURIComponent(escape(str));
   }
   function utf8StringToString(str) {
    return unescape(encodeURIComponent(str));
   }
   function isEmptyObj(obj) {
    for (var key in obj) {
     return false;
    }
    return true;
   }
   function isBool(v) {
    return typeof v === 'boolean';
   }
   function isInt(v) {
    return typeof v === 'number' && (v | 0) === v;
   }
   function isNum(v) {
    return typeof v === 'number';
   }
   function isString(v) {
    return typeof v === 'string';
   }
   function isArray(v) {
    return v instanceof Array;
   }
   function isArrayBuffer(v) {
    return typeof v === 'object' && v !== null && v.byteLength !== undefined;
   }
   function isSpace(ch) {
    return ch === 0x20 || ch === 0x09 || ch === 0x0D || ch === 0x0A;
   }
   function createPromiseCapability() {
    var capability = {};
    capability.promise = new Promise(function (resolve, reject) {
     capability.resolve = resolve;
     capability.reject = reject;
    });
    return capability;
   }
   (function PromiseClosure() {
    if (globalScope.Promise) {
     if (typeof globalScope.Promise.all !== 'function') {
      globalScope.Promise.all = function (iterable) {
       var count = 0, results = [], resolve, reject;
       var promise = new globalScope.Promise(function (resolve_, reject_) {
        resolve = resolve_;
        reject = reject_;
       });
       iterable.forEach(function (p, i) {
        count++;
        p.then(function (result) {
         results[i] = result;
         count--;
         if (count === 0) {
          resolve(results);
         }
        }, reject);
       });
       if (count === 0) {
        resolve(results);
       }
       return promise;
      };
     }
     if (typeof globalScope.Promise.resolve !== 'function') {
      globalScope.Promise.resolve = function (value) {
       return new globalScope.Promise(function (resolve) {
        resolve(value);
       });
      };
     }
     if (typeof globalScope.Promise.reject !== 'function') {
      globalScope.Promise.reject = function (reason) {
       return new globalScope.Promise(function (resolve, reject) {
        reject(reason);
       });
      };
     }
     if (typeof globalScope.Promise.prototype.catch !== 'function') {
      globalScope.Promise.prototype.catch = function (onReject) {
       return globalScope.Promise.prototype.then(undefined, onReject);
      };
     }
     return;
    }
    var STATUS_PENDING = 0;
    var STATUS_RESOLVED = 1;
    var STATUS_REJECTED = 2;
    var REJECTION_TIMEOUT = 500;
    var HandlerManager = {
     handlers: [],
     running: false,
     unhandledRejections: [],
     pendingRejectionCheck: false,
     scheduleHandlers: function scheduleHandlers(promise) {
      if (promise._status === STATUS_PENDING) {
       return;
      }
      this.handlers = this.handlers.concat(promise._handlers);
      promise._handlers = [];
      if (this.running) {
       return;
      }
      this.running = true;
      setTimeout(this.runHandlers.bind(this), 0);
     },
     runHandlers: function runHandlers() {
      var RUN_TIMEOUT = 1;
      var timeoutAt = Date.now() + RUN_TIMEOUT;
      while (this.handlers.length > 0) {
       var handler = this.handlers.shift();
       var nextStatus = handler.thisPromise._status;
       var nextValue = handler.thisPromise._value;
       try {
        if (nextStatus === STATUS_RESOLVED) {
         if (typeof handler.onResolve === 'function') {
          nextValue = handler.onResolve(nextValue);
         }
        } else if (typeof handler.onReject === 'function') {
         nextValue = handler.onReject(nextValue);
         nextStatus = STATUS_RESOLVED;
         if (handler.thisPromise._unhandledRejection) {
          this.removeUnhandeledRejection(handler.thisPromise);
         }
        }
       } catch (ex) {
        nextStatus = STATUS_REJECTED;
        nextValue = ex;
       }
       handler.nextPromise._updateStatus(nextStatus, nextValue);
       if (Date.now() >= timeoutAt) {
        break;
       }
      }
      if (this.handlers.length > 0) {
       setTimeout(this.runHandlers.bind(this), 0);
       return;
      }
      this.running = false;
     },
     addUnhandledRejection: function addUnhandledRejection(promise) {
      this.unhandledRejections.push({
       promise: promise,
       time: Date.now()
      });
      this.scheduleRejectionCheck();
     },
     removeUnhandeledRejection: function removeUnhandeledRejection(promise) {
      promise._unhandledRejection = false;
      for (var i = 0; i < this.unhandledRejections.length; i++) {
       if (this.unhandledRejections[i].promise === promise) {
        this.unhandledRejections.splice(i);
        i--;
       }
      }
     },
     scheduleRejectionCheck: function scheduleRejectionCheck() {
      if (this.pendingRejectionCheck) {
       return;
      }
      this.pendingRejectionCheck = true;
      setTimeout(function rejectionCheck() {
       this.pendingRejectionCheck = false;
       var now = Date.now();
       for (var i = 0; i < this.unhandledRejections.length; i++) {
        if (now - this.unhandledRejections[i].time > REJECTION_TIMEOUT) {
         var unhandled = this.unhandledRejections[i].promise._value;
         var msg = 'Unhandled rejection: ' + unhandled;
         if (unhandled.stack) {
          msg += '\n' + unhandled.stack;
         }
         warn(msg);
         this.unhandledRejections.splice(i);
         i--;
        }
       }
       if (this.unhandledRejections.length) {
        this.scheduleRejectionCheck();
       }
      }.bind(this), REJECTION_TIMEOUT);
     }
    };
    var Promise = function Promise(resolver) {
     this._status = STATUS_PENDING;
     this._handlers = [];
     try {
      resolver.call(this, this._resolve.bind(this), this._reject.bind(this));
     } catch (e) {
      this._reject(e);
     }
    };
    Promise.all = function Promise_all(promises) {
     var resolveAll, rejectAll;
     var deferred = new Promise(function (resolve, reject) {
      resolveAll = resolve;
      rejectAll = reject;
     });
     var unresolved = promises.length;
     var results = [];
     if (unresolved === 0) {
      resolveAll(results);
      return deferred;
     }
     function reject(reason) {
      if (deferred._status === STATUS_REJECTED) {
       return;
      }
      results = [];
      rejectAll(reason);
     }
     for (var i = 0, ii = promises.length; i < ii; ++i) {
      var promise = promises[i];
      var resolve = function (i) {
       return function (value) {
        if (deferred._status === STATUS_REJECTED) {
         return;
        }
        results[i] = value;
        unresolved--;
        if (unresolved === 0) {
         resolveAll(results);
        }
       };
      }(i);
      if (Promise.isPromise(promise)) {
       promise.then(resolve, reject);
      } else {
       resolve(promise);
      }
     }
     return deferred;
    };
    Promise.isPromise = function Promise_isPromise(value) {
     return value && typeof value.then === 'function';
    };
    Promise.resolve = function Promise_resolve(value) {
     return new Promise(function (resolve) {
      resolve(value);
     });
    };
    Promise.reject = function Promise_reject(reason) {
     return new Promise(function (resolve, reject) {
      reject(reason);
     });
    };
    Promise.prototype = {
     _status: null,
     _value: null,
     _handlers: null,
     _unhandledRejection: null,
     _updateStatus: function Promise__updateStatus(status, value) {
      if (this._status === STATUS_RESOLVED || this._status === STATUS_REJECTED) {
       return;
      }
      if (status === STATUS_RESOLVED && Promise.isPromise(value)) {
       value.then(this._updateStatus.bind(this, STATUS_RESOLVED), this._updateStatus.bind(this, STATUS_REJECTED));
       return;
      }
      this._status = status;
      this._value = value;
      if (status === STATUS_REJECTED && this._handlers.length === 0) {
       this._unhandledRejection = true;
       HandlerManager.addUnhandledRejection(this);
      }
      HandlerManager.scheduleHandlers(this);
     },
     _resolve: function Promise_resolve(value) {
      this._updateStatus(STATUS_RESOLVED, value);
     },
     _reject: function Promise_reject(reason) {
      this._updateStatus(STATUS_REJECTED, reason);
     },
     then: function Promise_then(onResolve, onReject) {
      var nextPromise = new Promise(function (resolve, reject) {
       this.resolve = resolve;
       this.reject = reject;
      });
      this._handlers.push({
       thisPromise: this,
       onResolve: onResolve,
       onReject: onReject,
       nextPromise: nextPromise
      });
      HandlerManager.scheduleHandlers(this);
      return nextPromise;
     },
     catch: function Promise_catch(onReject) {
      return this.then(undefined, onReject);
     }
    };
    globalScope.Promise = Promise;
   }());
   (function WeakMapClosure() {
    if (globalScope.WeakMap) {
     return;
    }
    var id = 0;
    function WeakMap() {
     this.id = '$weakmap' + id++;
    }
    WeakMap.prototype = {
     has: function (obj) {
      return !!Object.getOwnPropertyDescriptor(obj, this.id);
     },
     get: function (obj, defaultValue) {
      return this.has(obj) ? obj[this.id] : defaultValue;
     },
     set: function (obj, value) {
      Object.defineProperty(obj, this.id, {
       value: value,
       enumerable: false,
       configurable: true
      });
     },
     delete: function (obj) {
      delete obj[this.id];
     }
    };
    globalScope.WeakMap = WeakMap;
   }());
   var StatTimer = function StatTimerClosure() {
    function rpad(str, pad, length) {
     while (str.length < length) {
      str += pad;
     }
     return str;
    }
    function StatTimer() {
     this.started = Object.create(null);
     this.times = [];
     this.enabled = true;
    }
    StatTimer.prototype = {
     time: function StatTimer_time(name) {
      if (!this.enabled) {
       return;
      }
      if (name in this.started) {
       warn('Timer is already running for ' + name);
      }
      this.started[name] = Date.now();
     },
     timeEnd: function StatTimer_timeEnd(name) {
      if (!this.enabled) {
       return;
      }
      if (!(name in this.started)) {
       warn('Timer has not been started for ' + name);
      }
      this.times.push({
       'name': name,
       'start': this.started[name],
       'end': Date.now()
      });
      delete this.started[name];
     },
     toString: function StatTimer_toString() {
      var i, ii;
      var times = this.times;
      var out = '';
      var longest = 0;
      for (i = 0, ii = times.length; i < ii; ++i) {
       var name = times[i]['name'];
       if (name.length > longest) {
        longest = name.length;
       }
      }
      for (i = 0, ii = times.length; i < ii; ++i) {
       var span = times[i];
       var duration = span.end - span.start;
       out += rpad(span['name'], ' ', longest) + ' ' + duration + 'ms\n';
      }
      return out;
     }
    };
    return StatTimer;
   }();
   var createBlob = function createBlob(data, contentType) {
    if (typeof Blob !== 'undefined') {
     return new Blob([data], { type: contentType });
    }
    warn('The "Blob" constructor is not supported.');
   };
   var createObjectURL = function createObjectURLClosure() {
    var digits = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    return function createObjectURL(data, contentType, forceDataSchema) {
     if (!forceDataSchema && typeof URL !== 'undefined' && URL.createObjectURL) {
      var blob = createBlob(data, contentType);
      return URL.createObjectURL(blob);
     }
     var buffer = 'data:' + contentType + ';base64,';
     for (var i = 0, ii = data.length; i < ii; i += 3) {
      var b1 = data[i] & 0xFF;
      var b2 = data[i + 1] & 0xFF;
      var b3 = data[i + 2] & 0xFF;
      var d1 = b1 >> 2, d2 = (b1 & 3) << 4 | b2 >> 4;
      var d3 = i + 1 < ii ? (b2 & 0xF) << 2 | b3 >> 6 : 64;
      var d4 = i + 2 < ii ? b3 & 0x3F : 64;
      buffer += digits[d1] + digits[d2] + digits[d3] + digits[d4];
     }
     return buffer;
    };
   }();
   function MessageHandler(sourceName, targetName, comObj) {
    this.sourceName = sourceName;
    this.targetName = targetName;
    this.comObj = comObj;
    this.callbackIndex = 1;
    this.postMessageTransfers = true;
    var callbacksCapabilities = this.callbacksCapabilities = Object.create(null);
    var ah = this.actionHandler = Object.create(null);
    this._onComObjOnMessage = function messageHandlerComObjOnMessage(event) {
     var data = event.data;
     if (data.targetName !== this.sourceName) {
      return;
     }
     if (data.isReply) {
      var callbackId = data.callbackId;
      if (data.callbackId in callbacksCapabilities) {
       var callback = callbacksCapabilities[callbackId];
       delete callbacksCapabilities[callbackId];
       if ('error' in data) {
        callback.reject(data.error);
       } else {
        callback.resolve(data.data);
       }
      } else {
       error('Cannot resolve callback ' + callbackId);
      }
     } else if (data.action in ah) {
      var action = ah[data.action];
      if (data.callbackId) {
       var sourceName = this.sourceName;
       var targetName = data.sourceName;
       Promise.resolve().then(function () {
        return action[0].call(action[1], data.data);
       }).then(function (result) {
        comObj.postMessage({
         sourceName: sourceName,
         targetName: targetName,
         isReply: true,
         callbackId: data.callbackId,
         data: result
        });
       }, function (reason) {
        if (reason instanceof Error) {
         reason = reason + '';
        }
        comObj.postMessage({
         sourceName: sourceName,
         targetName: targetName,
         isReply: true,
         callbackId: data.callbackId,
         error: reason
        });
       });
      } else {
       action[0].call(action[1], data.data);
      }
     } else {
      error('Unknown action from worker: ' + data.action);
     }
    }.bind(this);
    comObj.addEventListener('message', this._onComObjOnMessage);
   }
   MessageHandler.prototype = {
    on: function messageHandlerOn(actionName, handler, scope) {
     var ah = this.actionHandler;
     if (ah[actionName]) {
      error('There is already an actionName called "' + actionName + '"');
     }
     ah[actionName] = [
      handler,
      scope
     ];
    },
    send: function messageHandlerSend(actionName, data, transfers) {
     var message = {
      sourceName: this.sourceName,
      targetName: this.targetName,
      action: actionName,
      data: data
     };
     this.postMessage(message, transfers);
    },
    sendWithPromise: function messageHandlerSendWithPromise(actionName, data, transfers) {
     var callbackId = this.callbackIndex++;
     var message = {
      sourceName: this.sourceName,
      targetName: this.targetName,
      action: actionName,
      data: data,
      callbackId: callbackId
     };
     var capability = createPromiseCapability();
     this.callbacksCapabilities[callbackId] = capability;
     try {
      this.postMessage(message, transfers);
     } catch (e) {
      capability.reject(e);
     }
     return capability.promise;
    },
    postMessage: function (message, transfers) {
     if (transfers && this.postMessageTransfers) {
      this.comObj.postMessage(message, transfers);
     } else {
      this.comObj.postMessage(message);
     }
    },
    destroy: function () {
     this.comObj.removeEventListener('message', this._onComObjOnMessage);
    }
   };
   function loadJpegStream(id, imageUrl, objs) {
    var img = new Image();
    img.onload = function loadJpegStream_onloadClosure() {
     objs.resolve(id, img);
    };
    img.onerror = function loadJpegStream_onerrorClosure() {
     objs.resolve(id, null);
     warn('Error during JPEG image loading');
    };
    img.src = imageUrl;
   }
   /* Any copyright is dedicated to the Public Domain.
    * http://creativecommons.org/publicdomain/zero/1.0/ */
   (function checkURLConstructor(scope) {
    var hasWorkingUrl = false;
    try {
     if (typeof URL === 'function' && typeof URL.prototype === 'object' && 'origin' in URL.prototype) {
      var u = new URL('b', 'http://a');
      u.pathname = 'c%20d';
      hasWorkingUrl = u.href === 'http://a/c%20d';
     }
    } catch (e) {
    }
    if (hasWorkingUrl) {
     return;
    }
    var relative = Object.create(null);
    relative['ftp'] = 21;
    relative['file'] = 0;
    relative['gopher'] = 70;
    relative['http'] = 80;
    relative['https'] = 443;
    relative['ws'] = 80;
    relative['wss'] = 443;
    var relativePathDotMapping = Object.create(null);
    relativePathDotMapping['%2e'] = '.';
    relativePathDotMapping['.%2e'] = '..';
    relativePathDotMapping['%2e.'] = '..';
    relativePathDotMapping['%2e%2e'] = '..';
    function isRelativeScheme(scheme) {
     return relative[scheme] !== undefined;
    }
    function invalid() {
     clear.call(this);
     this._isInvalid = true;
    }
    function IDNAToASCII(h) {
     if ('' === h) {
      invalid.call(this);
     }
     return h.toLowerCase();
    }
    function percentEscape(c) {
     var unicode = c.charCodeAt(0);
     if (unicode > 0x20 && unicode < 0x7F && [
       0x22,
       0x23,
       0x3C,
       0x3E,
       0x3F,
       0x60
      ].indexOf(unicode) === -1) {
      return c;
     }
     return encodeURIComponent(c);
    }
    function percentEscapeQuery(c) {
     var unicode = c.charCodeAt(0);
     if (unicode > 0x20 && unicode < 0x7F && [
       0x22,
       0x23,
       0x3C,
       0x3E,
       0x60
      ].indexOf(unicode) === -1) {
      return c;
     }
     return encodeURIComponent(c);
    }
    var EOF, ALPHA = /[a-zA-Z]/, ALPHANUMERIC = /[a-zA-Z0-9\+\-\.]/;
    function parse(input, stateOverride, base) {
     function err(message) {
      errors.push(message);
     }
     var state = stateOverride || 'scheme start', cursor = 0, buffer = '', seenAt = false, seenBracket = false, errors = [];
     loop:
      while ((input[cursor - 1] !== EOF || cursor === 0) && !this._isInvalid) {
       var c = input[cursor];
       switch (state) {
       case 'scheme start':
        if (c && ALPHA.test(c)) {
         buffer += c.toLowerCase();
         state = 'scheme';
        } else if (!stateOverride) {
         buffer = '';
         state = 'no scheme';
         continue;
        } else {
         err('Invalid scheme.');
         break loop;
        }
        break;
       case 'scheme':
        if (c && ALPHANUMERIC.test(c)) {
         buffer += c.toLowerCase();
        } else if (':' === c) {
         this._scheme = buffer;
         buffer = '';
         if (stateOverride) {
          break loop;
         }
         if (isRelativeScheme(this._scheme)) {
          this._isRelative = true;
         }
         if ('file' === this._scheme) {
          state = 'relative';
         } else if (this._isRelative && base && base._scheme === this._scheme) {
          state = 'relative or authority';
         } else if (this._isRelative) {
          state = 'authority first slash';
         } else {
          state = 'scheme data';
         }
        } else if (!stateOverride) {
         buffer = '';
         cursor = 0;
         state = 'no scheme';
         continue;
        } else if (EOF === c) {
         break loop;
        } else {
         err('Code point not allowed in scheme: ' + c);
         break loop;
        }
        break;
       case 'scheme data':
        if ('?' === c) {
         this._query = '?';
         state = 'query';
        } else if ('#' === c) {
         this._fragment = '#';
         state = 'fragment';
        } else {
         if (EOF !== c && '\t' !== c && '\n' !== c && '\r' !== c) {
          this._schemeData += percentEscape(c);
         }
        }
        break;
       case 'no scheme':
        if (!base || !isRelativeScheme(base._scheme)) {
         err('Missing scheme.');
         invalid.call(this);
        } else {
         state = 'relative';
         continue;
        }
        break;
       case 'relative or authority':
        if ('/' === c && '/' === input[cursor + 1]) {
         state = 'authority ignore slashes';
        } else {
         err('Expected /, got: ' + c);
         state = 'relative';
         continue;
        }
        break;
       case 'relative':
        this._isRelative = true;
        if ('file' !== this._scheme) {
         this._scheme = base._scheme;
        }
        if (EOF === c) {
         this._host = base._host;
         this._port = base._port;
         this._path = base._path.slice();
         this._query = base._query;
         this._username = base._username;
         this._password = base._password;
         break loop;
        } else if ('/' === c || '\\' === c) {
         if ('\\' === c) {
          err('\\ is an invalid code point.');
         }
         state = 'relative slash';
        } else if ('?' === c) {
         this._host = base._host;
         this._port = base._port;
         this._path = base._path.slice();
         this._query = '?';
         this._username = base._username;
         this._password = base._password;
         state = 'query';
        } else if ('#' === c) {
         this._host = base._host;
         this._port = base._port;
         this._path = base._path.slice();
         this._query = base._query;
         this._fragment = '#';
         this._username = base._username;
         this._password = base._password;
         state = 'fragment';
        } else {
         var nextC = input[cursor + 1];
         var nextNextC = input[cursor + 2];
         if ('file' !== this._scheme || !ALPHA.test(c) || nextC !== ':' && nextC !== '|' || EOF !== nextNextC && '/' !== nextNextC && '\\' !== nextNextC && '?' !== nextNextC && '#' !== nextNextC) {
          this._host = base._host;
          this._port = base._port;
          this._username = base._username;
          this._password = base._password;
          this._path = base._path.slice();
          this._path.pop();
         }
         state = 'relative path';
         continue;
        }
        break;
       case 'relative slash':
        if ('/' === c || '\\' === c) {
         if ('\\' === c) {
          err('\\ is an invalid code point.');
         }
         if ('file' === this._scheme) {
          state = 'file host';
         } else {
          state = 'authority ignore slashes';
         }
        } else {
         if ('file' !== this._scheme) {
          this._host = base._host;
          this._port = base._port;
          this._username = base._username;
          this._password = base._password;
         }
         state = 'relative path';
         continue;
        }
        break;
       case 'authority first slash':
        if ('/' === c) {
         state = 'authority second slash';
        } else {
         err('Expected \'/\', got: ' + c);
         state = 'authority ignore slashes';
         continue;
        }
        break;
       case 'authority second slash':
        state = 'authority ignore slashes';
        if ('/' !== c) {
         err('Expected \'/\', got: ' + c);
         continue;
        }
        break;
       case 'authority ignore slashes':
        if ('/' !== c && '\\' !== c) {
         state = 'authority';
         continue;
        } else {
         err('Expected authority, got: ' + c);
        }
        break;
       case 'authority':
        if ('@' === c) {
         if (seenAt) {
          err('@ already seen.');
          buffer += '%40';
         }
         seenAt = true;
         for (var i = 0; i < buffer.length; i++) {
          var cp = buffer[i];
          if ('\t' === cp || '\n' === cp || '\r' === cp) {
           err('Invalid whitespace in authority.');
           continue;
          }
          if (':' === cp && null === this._password) {
           this._password = '';
           continue;
          }
          var tempC = percentEscape(cp);
          if (null !== this._password) {
           this._password += tempC;
          } else {
           this._username += tempC;
          }
         }
         buffer = '';
        } else if (EOF === c || '/' === c || '\\' === c || '?' === c || '#' === c) {
         cursor -= buffer.length;
         buffer = '';
         state = 'host';
         continue;
        } else {
         buffer += c;
        }
        break;
       case 'file host':
        if (EOF === c || '/' === c || '\\' === c || '?' === c || '#' === c) {
         if (buffer.length === 2 && ALPHA.test(buffer[0]) && (buffer[1] === ':' || buffer[1] === '|')) {
          state = 'relative path';
         } else if (buffer.length === 0) {
          state = 'relative path start';
         } else {
          this._host = IDNAToASCII.call(this, buffer);
          buffer = '';
          state = 'relative path start';
         }
         continue;
        } else if ('\t' === c || '\n' === c || '\r' === c) {
         err('Invalid whitespace in file host.');
        } else {
         buffer += c;
        }
        break;
       case 'host':
       case 'hostname':
        if (':' === c && !seenBracket) {
         this._host = IDNAToASCII.call(this, buffer);
         buffer = '';
         state = 'port';
         if ('hostname' === stateOverride) {
          break loop;
         }
        } else if (EOF === c || '/' === c || '\\' === c || '?' === c || '#' === c) {
         this._host = IDNAToASCII.call(this, buffer);
         buffer = '';
         state = 'relative path start';
         if (stateOverride) {
          break loop;
         }
         continue;
        } else if ('\t' !== c && '\n' !== c && '\r' !== c) {
         if ('[' === c) {
          seenBracket = true;
         } else if (']' === c) {
          seenBracket = false;
         }
         buffer += c;
        } else {
         err('Invalid code point in host/hostname: ' + c);
        }
        break;
       case 'port':
        if (/[0-9]/.test(c)) {
         buffer += c;
        } else if (EOF === c || '/' === c || '\\' === c || '?' === c || '#' === c || stateOverride) {
         if ('' !== buffer) {
          var temp = parseInt(buffer, 10);
          if (temp !== relative[this._scheme]) {
           this._port = temp + '';
          }
          buffer = '';
         }
         if (stateOverride) {
          break loop;
         }
         state = 'relative path start';
         continue;
        } else if ('\t' === c || '\n' === c || '\r' === c) {
         err('Invalid code point in port: ' + c);
        } else {
         invalid.call(this);
        }
        break;
       case 'relative path start':
        if ('\\' === c) {
         err('\'\\\' not allowed in path.');
        }
        state = 'relative path';
        if ('/' !== c && '\\' !== c) {
         continue;
        }
        break;
       case 'relative path':
        if (EOF === c || '/' === c || '\\' === c || !stateOverride && ('?' === c || '#' === c)) {
         if ('\\' === c) {
          err('\\ not allowed in relative path.');
         }
         var tmp;
         if (tmp = relativePathDotMapping[buffer.toLowerCase()]) {
          buffer = tmp;
         }
         if ('..' === buffer) {
          this._path.pop();
          if ('/' !== c && '\\' !== c) {
           this._path.push('');
          }
         } else if ('.' === buffer && '/' !== c && '\\' !== c) {
          this._path.push('');
         } else if ('.' !== buffer) {
          if ('file' === this._scheme && this._path.length === 0 && buffer.length === 2 && ALPHA.test(buffer[0]) && buffer[1] === '|') {
           buffer = buffer[0] + ':';
          }
          this._path.push(buffer);
         }
         buffer = '';
         if ('?' === c) {
          this._query = '?';
          state = 'query';
         } else if ('#' === c) {
          this._fragment = '#';
          state = 'fragment';
         }
        } else if ('\t' !== c && '\n' !== c && '\r' !== c) {
         buffer += percentEscape(c);
        }
        break;
       case 'query':
        if (!stateOverride && '#' === c) {
         this._fragment = '#';
         state = 'fragment';
        } else if (EOF !== c && '\t' !== c && '\n' !== c && '\r' !== c) {
         this._query += percentEscapeQuery(c);
        }
        break;
       case 'fragment':
        if (EOF !== c && '\t' !== c && '\n' !== c && '\r' !== c) {
         this._fragment += c;
        }
        break;
       }
       cursor++;
      }
    }
    function clear() {
     this._scheme = '';
     this._schemeData = '';
     this._username = '';
     this._password = null;
     this._host = '';
     this._port = '';
     this._path = [];
     this._query = '';
     this._fragment = '';
     this._isInvalid = false;
     this._isRelative = false;
    }
    function JURL(url, base)
     {
      if (base !== undefined && !(base instanceof JURL)) {
       base = new JURL(String(base));
      }
      this._url = url;
      clear.call(this);
      var input = url.replace(/^[ \t\r\n\f]+|[ \t\r\n\f]+$/g, '');
      parse.call(this, input, null, base);
     }
    JURL.prototype = {
     toString: function () {
      return this.href;
     },
     get href() {
      if (this._isInvalid) {
       return this._url;
      }
      var authority = '';
      if ('' !== this._username || null !== this._password) {
       authority = this._username + (null !== this._password ? ':' + this._password : '') + '@';
      }
      return this.protocol + (this._isRelative ? '//' + authority + this.host : '') + this.pathname + this._query + this._fragment;
     },
     set href(href) {
      clear.call(this);
      parse.call(this, href);
     },
     get protocol() {
      return this._scheme + ':';
     },
     set protocol(protocol) {
      if (this._isInvalid) {
       return;
      }
      parse.call(this, protocol + ':', 'scheme start');
     },
     get host() {
      return this._isInvalid ? '' : this._port ? this._host + ':' + this._port : this._host;
     },
     set host(host) {
      if (this._isInvalid || !this._isRelative) {
       return;
      }
      parse.call(this, host, 'host');
     },
     get hostname() {
      return this._host;
     },
     set hostname(hostname) {
      if (this._isInvalid || !this._isRelative) {
       return;
      }
      parse.call(this, hostname, 'hostname');
     },
     get port() {
      return this._port;
     },
     set port(port) {
      if (this._isInvalid || !this._isRelative) {
       return;
      }
      parse.call(this, port, 'port');
     },
     get pathname() {
      return this._isInvalid ? '' : this._isRelative ? '/' + this._path.join('/') : this._schemeData;
     },
     set pathname(pathname) {
      if (this._isInvalid || !this._isRelative) {
       return;
      }
      this._path = [];
      parse.call(this, pathname, 'relative path start');
     },
     get search() {
      return this._isInvalid || !this._query || '?' === this._query ? '' : this._query;
     },
     set search(search) {
      if (this._isInvalid || !this._isRelative) {
       return;
      }
      this._query = '?';
      if ('?' === search[0]) {
       search = search.slice(1);
      }
      parse.call(this, search, 'query');
     },
     get hash() {
      return this._isInvalid || !this._fragment || '#' === this._fragment ? '' : this._fragment;
     },
     set hash(hash) {
      if (this._isInvalid) {
       return;
      }
      this._fragment = '#';
      if ('#' === hash[0]) {
       hash = hash.slice(1);
      }
      parse.call(this, hash, 'fragment');
     },
     get origin() {
      var host;
      if (this._isInvalid || !this._scheme) {
       return '';
      }
      switch (this._scheme) {
      case 'data':
      case 'file':
      case 'javascript':
      case 'mailto':
       return 'null';
      }
      host = this.host;
      if (!host) {
       return '';
      }
      return this._scheme + '://' + host;
     }
    };
    var OriginalURL = scope.URL;
    if (OriginalURL) {
     JURL.createObjectURL = function (blob) {
      return OriginalURL.createObjectURL.apply(OriginalURL, arguments);
     };
     JURL.revokeObjectURL = function (url) {
      OriginalURL.revokeObjectURL(url);
     };
    }
    scope.URL = JURL;
   }(globalScope));
   exports.FONT_IDENTITY_MATRIX = FONT_IDENTITY_MATRIX;
   exports.IDENTITY_MATRIX = IDENTITY_MATRIX;
   exports.OPS = OPS;
   exports.VERBOSITY_LEVELS = VERBOSITY_LEVELS;
   exports.UNSUPPORTED_FEATURES = UNSUPPORTED_FEATURES;
   exports.AnnotationBorderStyleType = AnnotationBorderStyleType;
   exports.AnnotationFieldFlag = AnnotationFieldFlag;
   exports.AnnotationFlag = AnnotationFlag;
   exports.AnnotationType = AnnotationType;
   exports.FontType = FontType;
   exports.ImageKind = ImageKind;
   exports.InvalidPDFException = InvalidPDFException;
   exports.MessageHandler = MessageHandler;
   exports.MissingDataException = MissingDataException;
   exports.MissingPDFException = MissingPDFException;
   exports.NotImplementedException = NotImplementedException;
   exports.PageViewport = PageViewport;
   exports.PasswordException = PasswordException;
   exports.PasswordResponses = PasswordResponses;
   exports.StatTimer = StatTimer;
   exports.StreamType = StreamType;
   exports.TextRenderingMode = TextRenderingMode;
   exports.UnexpectedResponseException = UnexpectedResponseException;
   exports.UnknownErrorException = UnknownErrorException;
   exports.Util = Util;
   exports.XRefParseException = XRefParseException;
   exports.arrayByteLength = arrayByteLength;
   exports.arraysToBytes = arraysToBytes;
   exports.assert = assert;
   exports.bytesToString = bytesToString;
   exports.createBlob = createBlob;
   exports.createPromiseCapability = createPromiseCapability;
   exports.createObjectURL = createObjectURL;
   exports.deprecated = deprecated;
   exports.error = error;
   exports.getLookupTableFactory = getLookupTableFactory;
   exports.getVerbosityLevel = getVerbosityLevel;
   exports.globalScope = globalScope;
   exports.info = info;
   exports.isArray = isArray;
   exports.isArrayBuffer = isArrayBuffer;
   exports.isBool = isBool;
   exports.isEmptyObj = isEmptyObj;
   exports.isInt = isInt;
   exports.isNum = isNum;
   exports.isString = isString;
   exports.isSpace = isSpace;
   exports.isSameOrigin = isSameOrigin;
   exports.createValidAbsoluteUrl = createValidAbsoluteUrl;
   exports.isLittleEndian = isLittleEndian;
   exports.isEvalSupported = isEvalSupported;
   exports.loadJpegStream = loadJpegStream;
   exports.log2 = log2;
   exports.readInt8 = readInt8;
   exports.readUint16 = readUint16;
   exports.readUint32 = readUint32;
   exports.removeNullCharacters = removeNullCharacters;
   exports.setVerbosityLevel = setVerbosityLevel;
   exports.shadow = shadow;
   exports.string32 = string32;
   exports.stringToBytes = stringToBytes;
   exports.stringToPDFString = stringToPDFString;
   exports.stringToUTF8String = stringToUTF8String;
   exports.utf8StringToString = utf8StringToString;
   exports.warn = warn;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreBidi = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var warn = sharedUtil.warn;
   var baseTypes = [
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'S',
    'B',
    'S',
    'WS',
    'B',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'B',
    'B',
    'B',
    'S',
    'WS',
    'ON',
    'ON',
    'ET',
    'ET',
    'ET',
    'ON',
    'ON',
    'ON',
    'ON',
    'ON',
    'ES',
    'CS',
    'ES',
    'CS',
    'CS',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'CS',
    'ON',
    'ON',
    'ON',
    'ON',
    'ON',
    'ON',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'ON',
    'ON',
    'ON',
    'ON',
    'ON',
    'ON',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'ON',
    'ON',
    'ON',
    'ON',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'B',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'BN',
    'CS',
    'ON',
    'ET',
    'ET',
    'ET',
    'ET',
    'ON',
    'ON',
    'ON',
    'ON',
    'L',
    'ON',
    'ON',
    'BN',
    'ON',
    'ON',
    'ET',
    'ET',
    'EN',
    'EN',
    'ON',
    'L',
    'ON',
    'ON',
    'ON',
    'EN',
    'L',
    'ON',
    'ON',
    'ON',
    'ON',
    'ON',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'ON',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'ON',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L',
    'L'
   ];
   var arabicTypes = [
    'AN',
    'AN',
    'AN',
    'AN',
    'AN',
    'AN',
    'ON',
    'ON',
    'AL',
    'ET',
    'ET',
    'AL',
    'CS',
    'AL',
    'ON',
    'ON',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'AL',
    'AL',
    '',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'AN',
    'AN',
    'AN',
    'AN',
    'AN',
    'AN',
    'AN',
    'AN',
    'AN',
    'AN',
    'ET',
    'AN',
    'AN',
    'AL',
    'AL',
    'AL',
    'NSM',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'AN',
    'ON',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'AL',
    'AL',
    'NSM',
    'NSM',
    'ON',
    'NSM',
    'NSM',
    'NSM',
    'NSM',
    'AL',
    'AL',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'EN',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL',
    'AL'
   ];
   function isOdd(i) {
    return (i & 1) !== 0;
   }
   function isEven(i) {
    return (i & 1) === 0;
   }
   function findUnequal(arr, start, value) {
    for (var j = start, jj = arr.length; j < jj; ++j) {
     if (arr[j] !== value) {
      return j;
     }
    }
    return j;
   }
   function setValues(arr, start, end, value) {
    for (var j = start; j < end; ++j) {
     arr[j] = value;
    }
   }
   function reverseValues(arr, start, end) {
    for (var i = start, j = end - 1; i < j; ++i, --j) {
     var temp = arr[i];
     arr[i] = arr[j];
     arr[j] = temp;
    }
   }
   function createBidiText(str, isLTR, vertical) {
    return {
     str: str,
     dir: vertical ? 'ttb' : isLTR ? 'ltr' : 'rtl'
    };
   }
   var chars = [];
   var types = [];
   function bidi(str, startLevel, vertical) {
    var isLTR = true;
    var strLength = str.length;
    if (strLength === 0 || vertical) {
     return createBidiText(str, isLTR, vertical);
    }
    chars.length = strLength;
    types.length = strLength;
    var numBidi = 0;
    var i, ii;
    for (i = 0; i < strLength; ++i) {
     chars[i] = str.charAt(i);
     var charCode = str.charCodeAt(i);
     var charType = 'L';
     if (charCode <= 0x00ff) {
      charType = baseTypes[charCode];
     } else if (0x0590 <= charCode && charCode <= 0x05f4) {
      charType = 'R';
     } else if (0x0600 <= charCode && charCode <= 0x06ff) {
      charType = arabicTypes[charCode & 0xff];
      if (!charType) {
       warn('Bidi: invalid Unicode character ' + charCode.toString(16));
      }
     } else if (0x0700 <= charCode && charCode <= 0x08AC) {
      charType = 'AL';
     }
     if (charType === 'R' || charType === 'AL' || charType === 'AN') {
      numBidi++;
     }
     types[i] = charType;
    }
    if (numBidi === 0) {
     isLTR = true;
     return createBidiText(str, isLTR);
    }
    if (startLevel === -1) {
     if (strLength / numBidi < 0.3) {
      isLTR = true;
      startLevel = 0;
     } else {
      isLTR = false;
      startLevel = 1;
     }
    }
    var levels = [];
    for (i = 0; i < strLength; ++i) {
     levels[i] = startLevel;
    }
    var e = isOdd(startLevel) ? 'R' : 'L';
    var sor = e;
    var eor = sor;
    var lastType = sor;
    for (i = 0; i < strLength; ++i) {
     if (types[i] === 'NSM') {
      types[i] = lastType;
     } else {
      lastType = types[i];
     }
    }
    lastType = sor;
    var t;
    for (i = 0; i < strLength; ++i) {
     t = types[i];
     if (t === 'EN') {
      types[i] = lastType === 'AL' ? 'AN' : 'EN';
     } else if (t === 'R' || t === 'L' || t === 'AL') {
      lastType = t;
     }
    }
    for (i = 0; i < strLength; ++i) {
     t = types[i];
     if (t === 'AL') {
      types[i] = 'R';
     }
    }
    for (i = 1; i < strLength - 1; ++i) {
     if (types[i] === 'ES' && types[i - 1] === 'EN' && types[i + 1] === 'EN') {
      types[i] = 'EN';
     }
     if (types[i] === 'CS' && (types[i - 1] === 'EN' || types[i - 1] === 'AN') && types[i + 1] === types[i - 1]) {
      types[i] = types[i - 1];
     }
    }
    for (i = 0; i < strLength; ++i) {
     if (types[i] === 'EN') {
      var j;
      for (j = i - 1; j >= 0; --j) {
       if (types[j] !== 'ET') {
        break;
       }
       types[j] = 'EN';
      }
      for (j = i + 1; j < strLength; ++j) {
       if (types[j] !== 'ET') {
        break;
       }
       types[j] = 'EN';
      }
     }
    }
    for (i = 0; i < strLength; ++i) {
     t = types[i];
     if (t === 'WS' || t === 'ES' || t === 'ET' || t === 'CS') {
      types[i] = 'ON';
     }
    }
    lastType = sor;
    for (i = 0; i < strLength; ++i) {
     t = types[i];
     if (t === 'EN') {
      types[i] = lastType === 'L' ? 'L' : 'EN';
     } else if (t === 'R' || t === 'L') {
      lastType = t;
     }
    }
    for (i = 0; i < strLength; ++i) {
     if (types[i] === 'ON') {
      var end = findUnequal(types, i + 1, 'ON');
      var before = sor;
      if (i > 0) {
       before = types[i - 1];
      }
      var after = eor;
      if (end + 1 < strLength) {
       after = types[end + 1];
      }
      if (before !== 'L') {
       before = 'R';
      }
      if (after !== 'L') {
       after = 'R';
      }
      if (before === after) {
       setValues(types, i, end, before);
      }
      i = end - 1;
     }
    }
    for (i = 0; i < strLength; ++i) {
     if (types[i] === 'ON') {
      types[i] = e;
     }
    }
    for (i = 0; i < strLength; ++i) {
     t = types[i];
     if (isEven(levels[i])) {
      if (t === 'R') {
       levels[i] += 1;
      } else if (t === 'AN' || t === 'EN') {
       levels[i] += 2;
      }
     } else {
      if (t === 'L' || t === 'AN' || t === 'EN') {
       levels[i] += 1;
      }
     }
    }
    var highestLevel = -1;
    var lowestOddLevel = 99;
    var level;
    for (i = 0, ii = levels.length; i < ii; ++i) {
     level = levels[i];
     if (highestLevel < level) {
      highestLevel = level;
     }
     if (lowestOddLevel > level && isOdd(level)) {
      lowestOddLevel = level;
     }
    }
    for (level = highestLevel; level >= lowestOddLevel; --level) {
     var start = -1;
     for (i = 0, ii = levels.length; i < ii; ++i) {
      if (levels[i] < level) {
       if (start >= 0) {
        reverseValues(chars, start, i);
        start = -1;
       }
      } else if (start < 0) {
       start = i;
      }
     }
     if (start >= 0) {
      reverseValues(chars, start, levels.length);
     }
    }
    for (i = 0, ii = chars.length; i < ii; ++i) {
     var ch = chars[i];
     if (ch === '<' || ch === '>') {
      chars[i] = '';
     }
    }
    return createBidiText(chars.join(''), isLTR);
   }
   exports.bidi = bidi;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreCFFParser = {}, root.pdfjsSharedUtil, root.pdfjsCoreCharsets, root.pdfjsCoreEncodings);
  }(this, function (exports, sharedUtil, coreCharsets, coreEncodings) {
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var bytesToString = sharedUtil.bytesToString;
   var warn = sharedUtil.warn;
   var isArray = sharedUtil.isArray;
   var Util = sharedUtil.Util;
   var stringToBytes = sharedUtil.stringToBytes;
   var assert = sharedUtil.assert;
   var ISOAdobeCharset = coreCharsets.ISOAdobeCharset;
   var ExpertCharset = coreCharsets.ExpertCharset;
   var ExpertSubsetCharset = coreCharsets.ExpertSubsetCharset;
   var StandardEncoding = coreEncodings.StandardEncoding;
   var ExpertEncoding = coreEncodings.ExpertEncoding;
   var MAX_SUBR_NESTING = 10;
   var CFFStandardStrings = [
    '.notdef',
    'space',
    'exclam',
    'quotedbl',
    'numbersign',
    'dollar',
    'percent',
    'ampersand',
    'quoteright',
    'parenleft',
    'parenright',
    'asterisk',
    'plus',
    'comma',
    'hyphen',
    'period',
    'slash',
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'colon',
    'semicolon',
    'less',
    'equal',
    'greater',
    'question',
    'at',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'bracketleft',
    'backslash',
    'bracketright',
    'asciicircum',
    'underscore',
    'quoteleft',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'braceleft',
    'bar',
    'braceright',
    'asciitilde',
    'exclamdown',
    'cent',
    'sterling',
    'fraction',
    'yen',
    'florin',
    'section',
    'currency',
    'quotesingle',
    'quotedblleft',
    'guillemotleft',
    'guilsinglleft',
    'guilsinglright',
    'fi',
    'fl',
    'endash',
    'dagger',
    'daggerdbl',
    'periodcentered',
    'paragraph',
    'bullet',
    'quotesinglbase',
    'quotedblbase',
    'quotedblright',
    'guillemotright',
    'ellipsis',
    'perthousand',
    'questiondown',
    'grave',
    'acute',
    'circumflex',
    'tilde',
    'macron',
    'breve',
    'dotaccent',
    'dieresis',
    'ring',
    'cedilla',
    'hungarumlaut',
    'ogonek',
    'caron',
    'emdash',
    'AE',
    'ordfeminine',
    'Lslash',
    'Oslash',
    'OE',
    'ordmasculine',
    'ae',
    'dotlessi',
    'lslash',
    'oslash',
    'oe',
    'germandbls',
    'onesuperior',
    'logicalnot',
    'mu',
    'trademark',
    'Eth',
    'onehalf',
    'plusminus',
    'Thorn',
    'onequarter',
    'divide',
    'brokenbar',
    'degree',
    'thorn',
    'threequarters',
    'twosuperior',
    'registered',
    'minus',
    'eth',
    'multiply',
    'threesuperior',
    'copyright',
    'Aacute',
    'Acircumflex',
    'Adieresis',
    'Agrave',
    'Aring',
    'Atilde',
    'Ccedilla',
    'Eacute',
    'Ecircumflex',
    'Edieresis',
    'Egrave',
    'Iacute',
    'Icircumflex',
    'Idieresis',
    'Igrave',
    'Ntilde',
    'Oacute',
    'Ocircumflex',
    'Odieresis',
    'Ograve',
    'Otilde',
    'Scaron',
    'Uacute',
    'Ucircumflex',
    'Udieresis',
    'Ugrave',
    'Yacute',
    'Ydieresis',
    'Zcaron',
    'aacute',
    'acircumflex',
    'adieresis',
    'agrave',
    'aring',
    'atilde',
    'ccedilla',
    'eacute',
    'ecircumflex',
    'edieresis',
    'egrave',
    'iacute',
    'icircumflex',
    'idieresis',
    'igrave',
    'ntilde',
    'oacute',
    'ocircumflex',
    'odieresis',
    'ograve',
    'otilde',
    'scaron',
    'uacute',
    'ucircumflex',
    'udieresis',
    'ugrave',
    'yacute',
    'ydieresis',
    'zcaron',
    'exclamsmall',
    'Hungarumlautsmall',
    'dollaroldstyle',
    'dollarsuperior',
    'ampersandsmall',
    'Acutesmall',
    'parenleftsuperior',
    'parenrightsuperior',
    'twodotenleader',
    'onedotenleader',
    'zerooldstyle',
    'oneoldstyle',
    'twooldstyle',
    'threeoldstyle',
    'fouroldstyle',
    'fiveoldstyle',
    'sixoldstyle',
    'sevenoldstyle',
    'eightoldstyle',
    'nineoldstyle',
    'commasuperior',
    'threequartersemdash',
    'periodsuperior',
    'questionsmall',
    'asuperior',
    'bsuperior',
    'centsuperior',
    'dsuperior',
    'esuperior',
    'isuperior',
    'lsuperior',
    'msuperior',
    'nsuperior',
    'osuperior',
    'rsuperior',
    'ssuperior',
    'tsuperior',
    'ff',
    'ffi',
    'ffl',
    'parenleftinferior',
    'parenrightinferior',
    'Circumflexsmall',
    'hyphensuperior',
    'Gravesmall',
    'Asmall',
    'Bsmall',
    'Csmall',
    'Dsmall',
    'Esmall',
    'Fsmall',
    'Gsmall',
    'Hsmall',
    'Ismall',
    'Jsmall',
    'Ksmall',
    'Lsmall',
    'Msmall',
    'Nsmall',
    'Osmall',
    'Psmall',
    'Qsmall',
    'Rsmall',
    'Ssmall',
    'Tsmall',
    'Usmall',
    'Vsmall',
    'Wsmall',
    'Xsmall',
    'Ysmall',
    'Zsmall',
    'colonmonetary',
    'onefitted',
    'rupiah',
    'Tildesmall',
    'exclamdownsmall',
    'centoldstyle',
    'Lslashsmall',
    'Scaronsmall',
    'Zcaronsmall',
    'Dieresissmall',
    'Brevesmall',
    'Caronsmall',
    'Dotaccentsmall',
    'Macronsmall',
    'figuredash',
    'hypheninferior',
    'Ogoneksmall',
    'Ringsmall',
    'Cedillasmall',
    'questiondownsmall',
    'oneeighth',
    'threeeighths',
    'fiveeighths',
    'seveneighths',
    'onethird',
    'twothirds',
    'zerosuperior',
    'foursuperior',
    'fivesuperior',
    'sixsuperior',
    'sevensuperior',
    'eightsuperior',
    'ninesuperior',
    'zeroinferior',
    'oneinferior',
    'twoinferior',
    'threeinferior',
    'fourinferior',
    'fiveinferior',
    'sixinferior',
    'seveninferior',
    'eightinferior',
    'nineinferior',
    'centinferior',
    'dollarinferior',
    'periodinferior',
    'commainferior',
    'Agravesmall',
    'Aacutesmall',
    'Acircumflexsmall',
    'Atildesmall',
    'Adieresissmall',
    'Aringsmall',
    'AEsmall',
    'Ccedillasmall',
    'Egravesmall',
    'Eacutesmall',
    'Ecircumflexsmall',
    'Edieresissmall',
    'Igravesmall',
    'Iacutesmall',
    'Icircumflexsmall',
    'Idieresissmall',
    'Ethsmall',
    'Ntildesmall',
    'Ogravesmall',
    'Oacutesmall',
    'Ocircumflexsmall',
    'Otildesmall',
    'Odieresissmall',
    'OEsmall',
    'Oslashsmall',
    'Ugravesmall',
    'Uacutesmall',
    'Ucircumflexsmall',
    'Udieresissmall',
    'Yacutesmall',
    'Thornsmall',
    'Ydieresissmall',
    '001.000',
    '001.001',
    '001.002',
    '001.003',
    'Black',
    'Bold',
    'Book',
    'Light',
    'Medium',
    'Regular',
    'Roman',
    'Semibold'
   ];
   var CFFParser = function CFFParserClosure() {
    var CharstringValidationData = [
     null,
     {
      id: 'hstem',
      min: 2,
      stackClearing: true,
      stem: true
     },
     null,
     {
      id: 'vstem',
      min: 2,
      stackClearing: true,
      stem: true
     },
     {
      id: 'vmoveto',
      min: 1,
      stackClearing: true
     },
     {
      id: 'rlineto',
      min: 2,
      resetStack: true
     },
     {
      id: 'hlineto',
      min: 1,
      resetStack: true
     },
     {
      id: 'vlineto',
      min: 1,
      resetStack: true
     },
     {
      id: 'rrcurveto',
      min: 6,
      resetStack: true
     },
     null,
     {
      id: 'callsubr',
      min: 1,
      undefStack: true
     },
     {
      id: 'return',
      min: 0,
      undefStack: true
     },
     null,
     null,
     {
      id: 'endchar',
      min: 0,
      stackClearing: true
     },
     null,
     null,
     null,
     {
      id: 'hstemhm',
      min: 2,
      stackClearing: true,
      stem: true
     },
     {
      id: 'hintmask',
      min: 0,
      stackClearing: true
     },
     {
      id: 'cntrmask',
      min: 0,
      stackClearing: true
     },
     {
      id: 'rmoveto',
      min: 2,
      stackClearing: true
     },
     {
      id: 'hmoveto',
      min: 1,
      stackClearing: true
     },
     {
      id: 'vstemhm',
      min: 2,
      stackClearing: true,
      stem: true
     },
     {
      id: 'rcurveline',
      min: 8,
      resetStack: true
     },
     {
      id: 'rlinecurve',
      min: 8,
      resetStack: true
     },
     {
      id: 'vvcurveto',
      min: 4,
      resetStack: true
     },
     {
      id: 'hhcurveto',
      min: 4,
      resetStack: true
     },
     null,
     {
      id: 'callgsubr',
      min: 1,
      undefStack: true
     },
     {
      id: 'vhcurveto',
      min: 4,
      resetStack: true
     },
     {
      id: 'hvcurveto',
      min: 4,
      resetStack: true
     }
    ];
    var CharstringValidationData12 = [
     null,
     null,
     null,
     {
      id: 'and',
      min: 2,
      stackDelta: -1
     },
     {
      id: 'or',
      min: 2,
      stackDelta: -1
     },
     {
      id: 'not',
      min: 1,
      stackDelta: 0
     },
     null,
     null,
     null,
     {
      id: 'abs',
      min: 1,
      stackDelta: 0
     },
     {
      id: 'add',
      min: 2,
      stackDelta: -1,
      stackFn: function stack_div(stack, index) {
       stack[index - 2] = stack[index - 2] + stack[index - 1];
      }
     },
     {
      id: 'sub',
      min: 2,
      stackDelta: -1,
      stackFn: function stack_div(stack, index) {
       stack[index - 2] = stack[index - 2] - stack[index - 1];
      }
     },
     {
      id: 'div',
      min: 2,
      stackDelta: -1,
      stackFn: function stack_div(stack, index) {
       stack[index - 2] = stack[index - 2] / stack[index - 1];
      }
     },
     null,
     {
      id: 'neg',
      min: 1,
      stackDelta: 0,
      stackFn: function stack_div(stack, index) {
       stack[index - 1] = -stack[index - 1];
      }
     },
     {
      id: 'eq',
      min: 2,
      stackDelta: -1
     },
     null,
     null,
     {
      id: 'drop',
      min: 1,
      stackDelta: -1
     },
     null,
     {
      id: 'put',
      min: 2,
      stackDelta: -2
     },
     {
      id: 'get',
      min: 1,
      stackDelta: 0
     },
     {
      id: 'ifelse',
      min: 4,
      stackDelta: -3
     },
     {
      id: 'random',
      min: 0,
      stackDelta: 1
     },
     {
      id: 'mul',
      min: 2,
      stackDelta: -1,
      stackFn: function stack_div(stack, index) {
       stack[index - 2] = stack[index - 2] * stack[index - 1];
      }
     },
     null,
     {
      id: 'sqrt',
      min: 1,
      stackDelta: 0
     },
     {
      id: 'dup',
      min: 1,
      stackDelta: 1
     },
     {
      id: 'exch',
      min: 2,
      stackDelta: 0
     },
     {
      id: 'index',
      min: 2,
      stackDelta: 0
     },
     {
      id: 'roll',
      min: 3,
      stackDelta: -2
     },
     null,
     null,
     null,
     {
      id: 'hflex',
      min: 7,
      resetStack: true
     },
     {
      id: 'flex',
      min: 13,
      resetStack: true
     },
     {
      id: 'hflex1',
      min: 9,
      resetStack: true
     },
     {
      id: 'flex1',
      min: 11,
      resetStack: true
     }
    ];
    function CFFParser(file, properties, seacAnalysisEnabled) {
     this.bytes = file.getBytes();
     this.properties = properties;
     this.seacAnalysisEnabled = !!seacAnalysisEnabled;
    }
    CFFParser.prototype = {
     parse: function CFFParser_parse() {
      var properties = this.properties;
      var cff = new CFF();
      this.cff = cff;
      var header = this.parseHeader();
      var nameIndex = this.parseIndex(header.endPos);
      var topDictIndex = this.parseIndex(nameIndex.endPos);
      var stringIndex = this.parseIndex(topDictIndex.endPos);
      var globalSubrIndex = this.parseIndex(stringIndex.endPos);
      var topDictParsed = this.parseDict(topDictIndex.obj.get(0));
      var topDict = this.createDict(CFFTopDict, topDictParsed, cff.strings);
      cff.header = header.obj;
      cff.names = this.parseNameIndex(nameIndex.obj);
      cff.strings = this.parseStringIndex(stringIndex.obj);
      cff.topDict = topDict;
      cff.globalSubrIndex = globalSubrIndex.obj;
      this.parsePrivateDict(cff.topDict);
      cff.isCIDFont = topDict.hasName('ROS');
      var charStringOffset = topDict.getByName('CharStrings');
      var charStringIndex = this.parseIndex(charStringOffset).obj;
      var fontMatrix = topDict.getByName('FontMatrix');
      if (fontMatrix) {
       properties.fontMatrix = fontMatrix;
      }
      var fontBBox = topDict.getByName('FontBBox');
      if (fontBBox) {
       properties.ascent = fontBBox[3];
       properties.descent = fontBBox[1];
       properties.ascentScaled = true;
      }
      var charset, encoding;
      if (cff.isCIDFont) {
       var fdArrayIndex = this.parseIndex(topDict.getByName('FDArray')).obj;
       for (var i = 0, ii = fdArrayIndex.count; i < ii; ++i) {
        var dictRaw = fdArrayIndex.get(i);
        var fontDict = this.createDict(CFFTopDict, this.parseDict(dictRaw), cff.strings);
        this.parsePrivateDict(fontDict);
        cff.fdArray.push(fontDict);
       }
       encoding = null;
       charset = this.parseCharsets(topDict.getByName('charset'), charStringIndex.count, cff.strings, true);
       cff.fdSelect = this.parseFDSelect(topDict.getByName('FDSelect'), charStringIndex.count);
      } else {
       charset = this.parseCharsets(topDict.getByName('charset'), charStringIndex.count, cff.strings, false);
       encoding = this.parseEncoding(topDict.getByName('Encoding'), properties, cff.strings, charset.charset);
      }
      cff.charset = charset;
      cff.encoding = encoding;
      var charStringsAndSeacs = this.parseCharStrings(charStringIndex, topDict.privateDict.subrsIndex, globalSubrIndex.obj, cff.fdSelect, cff.fdArray);
      cff.charStrings = charStringsAndSeacs.charStrings;
      cff.seacs = charStringsAndSeacs.seacs;
      cff.widths = charStringsAndSeacs.widths;
      return cff;
     },
     parseHeader: function CFFParser_parseHeader() {
      var bytes = this.bytes;
      var bytesLength = bytes.length;
      var offset = 0;
      while (offset < bytesLength && bytes[offset] !== 1) {
       ++offset;
      }
      if (offset >= bytesLength) {
       error('Invalid CFF header');
      } else if (offset !== 0) {
       info('cff data is shifted');
       bytes = bytes.subarray(offset);
       this.bytes = bytes;
      }
      var major = bytes[0];
      var minor = bytes[1];
      var hdrSize = bytes[2];
      var offSize = bytes[3];
      var header = new CFFHeader(major, minor, hdrSize, offSize);
      return {
       obj: header,
       endPos: hdrSize
      };
     },
     parseDict: function CFFParser_parseDict(dict) {
      var pos = 0;
      function parseOperand() {
       var value = dict[pos++];
       if (value === 30) {
        return parseFloatOperand();
       } else if (value === 28) {
        value = dict[pos++];
        value = (value << 24 | dict[pos++] << 16) >> 16;
        return value;
       } else if (value === 29) {
        value = dict[pos++];
        value = value << 8 | dict[pos++];
        value = value << 8 | dict[pos++];
        value = value << 8 | dict[pos++];
        return value;
       } else if (value >= 32 && value <= 246) {
        return value - 139;
       } else if (value >= 247 && value <= 250) {
        return (value - 247) * 256 + dict[pos++] + 108;
       } else if (value >= 251 && value <= 254) {
        return -((value - 251) * 256) - dict[pos++] - 108;
       } else {
        warn('CFFParser_parseDict: "' + value + '" is a reserved command.');
        return NaN;
       }
      }
      function parseFloatOperand() {
       var str = '';
       var eof = 15;
       var lookup = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
        'E',
        'E-',
        null,
        '-'
       ];
       var length = dict.length;
       while (pos < length) {
        var b = dict[pos++];
        var b1 = b >> 4;
        var b2 = b & 15;
        if (b1 === eof) {
         break;
        }
        str += lookup[b1];
        if (b2 === eof) {
         break;
        }
        str += lookup[b2];
       }
       return parseFloat(str);
      }
      var operands = [];
      var entries = [];
      pos = 0;
      var end = dict.length;
      while (pos < end) {
       var b = dict[pos];
       if (b <= 21) {
        if (b === 12) {
         b = b << 8 | dict[++pos];
        }
        entries.push([
         b,
         operands
        ]);
        operands = [];
        ++pos;
       } else {
        operands.push(parseOperand());
       }
      }
      return entries;
     },
     parseIndex: function CFFParser_parseIndex(pos) {
      var cffIndex = new CFFIndex();
      var bytes = this.bytes;
      var count = bytes[pos++] << 8 | bytes[pos++];
      var offsets = [];
      var end = pos;
      var i, ii;
      if (count !== 0) {
       var offsetSize = bytes[pos++];
       var startPos = pos + (count + 1) * offsetSize - 1;
       for (i = 0, ii = count + 1; i < ii; ++i) {
        var offset = 0;
        for (var j = 0; j < offsetSize; ++j) {
         offset <<= 8;
         offset += bytes[pos++];
        }
        offsets.push(startPos + offset);
       }
       end = offsets[count];
      }
      for (i = 0, ii = offsets.length - 1; i < ii; ++i) {
       var offsetStart = offsets[i];
       var offsetEnd = offsets[i + 1];
       cffIndex.add(bytes.subarray(offsetStart, offsetEnd));
      }
      return {
       obj: cffIndex,
       endPos: end
      };
     },
     parseNameIndex: function CFFParser_parseNameIndex(index) {
      var names = [];
      for (var i = 0, ii = index.count; i < ii; ++i) {
       var name = index.get(i);
       var length = Math.min(name.length, 127);
       var data = [];
       for (var j = 0; j < length; ++j) {
        var c = name[j];
        if (j === 0 && c === 0) {
         data[j] = c;
         continue;
        }
        if (c < 33 || c > 126 || c === 91 || c === 93 || c === 40 || c === 41 || c === 123 || c === 125 || c === 60 || c === 62 || c === 47 || c === 37 || c === 35)
         {
          data[j] = 95;
          continue;
         }
        data[j] = c;
       }
       names.push(bytesToString(data));
      }
      return names;
     },
     parseStringIndex: function CFFParser_parseStringIndex(index) {
      var strings = new CFFStrings();
      for (var i = 0, ii = index.count; i < ii; ++i) {
       var data = index.get(i);
       strings.add(bytesToString(data));
      }
      return strings;
     },
     createDict: function CFFParser_createDict(Type, dict, strings) {
      var cffDict = new Type(strings);
      for (var i = 0, ii = dict.length; i < ii; ++i) {
       var pair = dict[i];
       var key = pair[0];
       var value = pair[1];
       cffDict.setByKey(key, value);
      }
      return cffDict;
     },
     parseCharString: function CFFParser_parseCharString(state, data, localSubrIndex, globalSubrIndex) {
      if (state.callDepth > MAX_SUBR_NESTING) {
       return false;
      }
      var stackSize = state.stackSize;
      var stack = state.stack;
      var length = data.length;
      for (var j = 0; j < length;) {
       var value = data[j++];
       var validationCommand = null;
       if (value === 12) {
        var q = data[j++];
        if (q === 0) {
         data[j - 2] = 139;
         data[j - 1] = 22;
         stackSize = 0;
        } else {
         validationCommand = CharstringValidationData12[q];
        }
       } else if (value === 28) {
        stack[stackSize] = (data[j] << 24 | data[j + 1] << 16) >> 16;
        j += 2;
        stackSize++;
       } else if (value === 14) {
        if (stackSize >= 4) {
         stackSize -= 4;
         if (this.seacAnalysisEnabled) {
          state.seac = stack.slice(stackSize, stackSize + 4);
          return false;
         }
        }
        validationCommand = CharstringValidationData[value];
       } else if (value >= 32 && value <= 246) {
        stack[stackSize] = value - 139;
        stackSize++;
       } else if (value >= 247 && value <= 254) {
        stack[stackSize] = value < 251 ? (value - 247 << 8) + data[j] + 108 : -(value - 251 << 8) - data[j] - 108;
        j++;
        stackSize++;
       } else if (value === 255) {
        stack[stackSize] = (data[j] << 24 | data[j + 1] << 16 | data[j + 2] << 8 | data[j + 3]) / 65536;
        j += 4;
        stackSize++;
       } else if (value === 19 || value === 20) {
        state.hints += stackSize >> 1;
        j += state.hints + 7 >> 3;
        stackSize %= 2;
        validationCommand = CharstringValidationData[value];
       } else if (value === 10 || value === 29) {
        var subrsIndex;
        if (value === 10) {
         subrsIndex = localSubrIndex;
        } else {
         subrsIndex = globalSubrIndex;
        }
        if (!subrsIndex) {
         validationCommand = CharstringValidationData[value];
         warn('Missing subrsIndex for ' + validationCommand.id);
         return false;
        }
        var bias = 32768;
        if (subrsIndex.count < 1240) {
         bias = 107;
        } else if (subrsIndex.count < 33900) {
         bias = 1131;
        }
        var subrNumber = stack[--stackSize] + bias;
        if (subrNumber < 0 || subrNumber >= subrsIndex.count) {
         validationCommand = CharstringValidationData[value];
         warn('Out of bounds subrIndex for ' + validationCommand.id);
         return false;
        }
        state.stackSize = stackSize;
        state.callDepth++;
        var valid = this.parseCharString(state, subrsIndex.get(subrNumber), localSubrIndex, globalSubrIndex);
        if (!valid) {
         return false;
        }
        state.callDepth--;
        stackSize = state.stackSize;
        continue;
       } else if (value === 11) {
        state.stackSize = stackSize;
        return true;
       } else {
        validationCommand = CharstringValidationData[value];
       }
       if (validationCommand) {
        if (validationCommand.stem) {
         state.hints += stackSize >> 1;
        }
        if ('min' in validationCommand) {
         if (!state.undefStack && stackSize < validationCommand.min) {
          warn('Not enough parameters for ' + validationCommand.id + '; actual: ' + stackSize + ', expected: ' + validationCommand.min);
          return false;
         }
        }
        if (state.firstStackClearing && validationCommand.stackClearing) {
         state.firstStackClearing = false;
         stackSize -= validationCommand.min;
         if (stackSize >= 2 && validationCommand.stem) {
          stackSize %= 2;
         } else if (stackSize > 1) {
          warn('Found too many parameters for stack-clearing command');
         }
         if (stackSize > 0 && stack[stackSize - 1] >= 0) {
          state.width = stack[stackSize - 1];
         }
        }
        if ('stackDelta' in validationCommand) {
         if ('stackFn' in validationCommand) {
          validationCommand.stackFn(stack, stackSize);
         }
         stackSize += validationCommand.stackDelta;
        } else if (validationCommand.stackClearing) {
         stackSize = 0;
        } else if (validationCommand.resetStack) {
         stackSize = 0;
         state.undefStack = false;
        } else if (validationCommand.undefStack) {
         stackSize = 0;
         state.undefStack = true;
         state.firstStackClearing = false;
        }
       }
      }
      state.stackSize = stackSize;
      return true;
     },
     parseCharStrings: function CFFParser_parseCharStrings(charStrings, localSubrIndex, globalSubrIndex, fdSelect, fdArray) {
      var seacs = [];
      var widths = [];
      var count = charStrings.count;
      for (var i = 0; i < count; i++) {
       var charstring = charStrings.get(i);
       var state = {
        callDepth: 0,
        stackSize: 0,
        stack: [],
        undefStack: true,
        hints: 0,
        firstStackClearing: true,
        seac: null,
        width: null
       };
       var valid = true;
       var localSubrToUse = null;
       if (fdSelect && fdArray.length) {
        var fdIndex = fdSelect.getFDIndex(i);
        if (fdIndex === -1) {
         warn('Glyph index is not in fd select.');
         valid = false;
        }
        if (fdIndex >= fdArray.length) {
         warn('Invalid fd index for glyph index.');
         valid = false;
        }
        if (valid) {
         localSubrToUse = fdArray[fdIndex].privateDict.subrsIndex;
        }
       } else if (localSubrIndex) {
        localSubrToUse = localSubrIndex;
       }
       if (valid) {
        valid = this.parseCharString(state, charstring, localSubrToUse, globalSubrIndex);
       }
       if (state.width !== null) {
        widths[i] = state.width;
       }
       if (state.seac !== null) {
        seacs[i] = state.seac;
       }
       if (!valid) {
        charStrings.set(i, new Uint8Array([14]));
       }
      }
      return {
       charStrings: charStrings,
       seacs: seacs,
       widths: widths
      };
     },
     emptyPrivateDictionary: function CFFParser_emptyPrivateDictionary(parentDict) {
      var privateDict = this.createDict(CFFPrivateDict, [], parentDict.strings);
      parentDict.setByKey(18, [
       0,
       0
      ]);
      parentDict.privateDict = privateDict;
     },
     parsePrivateDict: function CFFParser_parsePrivateDict(parentDict) {
      if (!parentDict.hasName('Private')) {
       this.emptyPrivateDictionary(parentDict);
       return;
      }
      var privateOffset = parentDict.getByName('Private');
      if (!isArray(privateOffset) || privateOffset.length !== 2) {
       parentDict.removeByName('Private');
       return;
      }
      var size = privateOffset[0];
      var offset = privateOffset[1];
      if (size === 0 || offset >= this.bytes.length) {
       this.emptyPrivateDictionary(parentDict);
       return;
      }
      var privateDictEnd = offset + size;
      var dictData = this.bytes.subarray(offset, privateDictEnd);
      var dict = this.parseDict(dictData);
      var privateDict = this.createDict(CFFPrivateDict, dict, parentDict.strings);
      parentDict.privateDict = privateDict;
      if (!privateDict.getByName('Subrs')) {
       return;
      }
      var subrsOffset = privateDict.getByName('Subrs');
      var relativeOffset = offset + subrsOffset;
      if (subrsOffset === 0 || relativeOffset >= this.bytes.length) {
       this.emptyPrivateDictionary(parentDict);
       return;
      }
      var subrsIndex = this.parseIndex(relativeOffset);
      privateDict.subrsIndex = subrsIndex.obj;
     },
     parseCharsets: function CFFParser_parseCharsets(pos, length, strings, cid) {
      if (pos === 0) {
       return new CFFCharset(true, CFFCharsetPredefinedTypes.ISO_ADOBE, ISOAdobeCharset);
      } else if (pos === 1) {
       return new CFFCharset(true, CFFCharsetPredefinedTypes.EXPERT, ExpertCharset);
      } else if (pos === 2) {
       return new CFFCharset(true, CFFCharsetPredefinedTypes.EXPERT_SUBSET, ExpertSubsetCharset);
      }
      var bytes = this.bytes;
      var start = pos;
      var format = bytes[pos++];
      var charset = ['.notdef'];
      var id, count, i;
      length -= 1;
      switch (format) {
      case 0:
       for (i = 0; i < length; i++) {
        id = bytes[pos++] << 8 | bytes[pos++];
        charset.push(cid ? id : strings.get(id));
       }
       break;
      case 1:
       while (charset.length <= length) {
        id = bytes[pos++] << 8 | bytes[pos++];
        count = bytes[pos++];
        for (i = 0; i <= count; i++) {
         charset.push(cid ? id++ : strings.get(id++));
        }
       }
       break;
      case 2:
       while (charset.length <= length) {
        id = bytes[pos++] << 8 | bytes[pos++];
        count = bytes[pos++] << 8 | bytes[pos++];
        for (i = 0; i <= count; i++) {
         charset.push(cid ? id++ : strings.get(id++));
        }
       }
       break;
      default:
       error('Unknown charset format');
      }
      var end = pos;
      var raw = bytes.subarray(start, end);
      return new CFFCharset(false, format, charset, raw);
     },
     parseEncoding: function CFFParser_parseEncoding(pos, properties, strings, charset) {
      var encoding = Object.create(null);
      var bytes = this.bytes;
      var predefined = false;
      var hasSupplement = false;
      var format, i, ii;
      var raw = null;
      function readSupplement() {
       var supplementsCount = bytes[pos++];
       for (i = 0; i < supplementsCount; i++) {
        var code = bytes[pos++];
        var sid = (bytes[pos++] << 8) + (bytes[pos++] & 0xff);
        encoding[code] = charset.indexOf(strings.get(sid));
       }
      }
      if (pos === 0 || pos === 1) {
       predefined = true;
       format = pos;
       var baseEncoding = pos ? ExpertEncoding : StandardEncoding;
       for (i = 0, ii = charset.length; i < ii; i++) {
        var index = baseEncoding.indexOf(charset[i]);
        if (index !== -1) {
         encoding[index] = i;
        }
       }
      } else {
       var dataStart = pos;
       format = bytes[pos++];
       switch (format & 0x7f) {
       case 0:
        var glyphsCount = bytes[pos++];
        for (i = 1; i <= glyphsCount; i++) {
         encoding[bytes[pos++]] = i;
        }
        break;
       case 1:
        var rangesCount = bytes[pos++];
        var gid = 1;
        for (i = 0; i < rangesCount; i++) {
         var start = bytes[pos++];
         var left = bytes[pos++];
         for (var j = start; j <= start + left; j++) {
          encoding[j] = gid++;
         }
        }
        break;
       default:
        error('Unknown encoding format: ' + format + ' in CFF');
        break;
       }
       var dataEnd = pos;
       if (format & 0x80) {
        bytes[dataStart] &= 0x7f;
        readSupplement();
        hasSupplement = true;
       }
       raw = bytes.subarray(dataStart, dataEnd);
      }
      format = format & 0x7f;
      return new CFFEncoding(predefined, format, encoding, raw);
     },
     parseFDSelect: function CFFParser_parseFDSelect(pos, length) {
      var start = pos;
      var bytes = this.bytes;
      var format = bytes[pos++];
      var fdSelect = [], rawBytes;
      var i, invalidFirstGID = false;
      switch (format) {
      case 0:
       for (i = 0; i < length; ++i) {
        var id = bytes[pos++];
        fdSelect.push(id);
       }
       rawBytes = bytes.subarray(start, pos);
       break;
      case 3:
       var rangesCount = bytes[pos++] << 8 | bytes[pos++];
       for (i = 0; i < rangesCount; ++i) {
        var first = bytes[pos++] << 8 | bytes[pos++];
        if (i === 0 && first !== 0) {
         warn('parseFDSelect: The first range must have a first GID of 0' + ' -- trying to recover.');
         invalidFirstGID = true;
         first = 0;
        }
        var fdIndex = bytes[pos++];
        var next = bytes[pos] << 8 | bytes[pos + 1];
        for (var j = first; j < next; ++j) {
         fdSelect.push(fdIndex);
        }
       }
       pos += 2;
       rawBytes = bytes.subarray(start, pos);
       if (invalidFirstGID) {
        rawBytes[3] = rawBytes[4] = 0;
       }
       break;
      default:
       error('parseFDSelect: Unknown format "' + format + '".');
       break;
      }
      assert(fdSelect.length === length, 'parseFDSelect: Invalid font data.');
      return new CFFFDSelect(fdSelect, rawBytes);
     }
    };
    return CFFParser;
   }();
   var CFF = function CFFClosure() {
    function CFF() {
     this.header = null;
     this.names = [];
     this.topDict = null;
     this.strings = new CFFStrings();
     this.globalSubrIndex = null;
     this.encoding = null;
     this.charset = null;
     this.charStrings = null;
     this.fdArray = [];
     this.fdSelect = null;
     this.isCIDFont = false;
    }
    return CFF;
   }();
   var CFFHeader = function CFFHeaderClosure() {
    function CFFHeader(major, minor, hdrSize, offSize) {
     this.major = major;
     this.minor = minor;
     this.hdrSize = hdrSize;
     this.offSize = offSize;
    }
    return CFFHeader;
   }();
   var CFFStrings = function CFFStringsClosure() {
    function CFFStrings() {
     this.strings = [];
    }
    CFFStrings.prototype = {
     get: function CFFStrings_get(index) {
      if (index >= 0 && index <= 390) {
       return CFFStandardStrings[index];
      }
      if (index - 391 <= this.strings.length) {
       return this.strings[index - 391];
      }
      return CFFStandardStrings[0];
     },
     add: function CFFStrings_add(value) {
      this.strings.push(value);
     },
     get count() {
      return this.strings.length;
     }
    };
    return CFFStrings;
   }();
   var CFFIndex = function CFFIndexClosure() {
    function CFFIndex() {
     this.objects = [];
     this.length = 0;
    }
    CFFIndex.prototype = {
     add: function CFFIndex_add(data) {
      this.length += data.length;
      this.objects.push(data);
     },
     set: function CFFIndex_set(index, data) {
      this.length += data.length - this.objects[index].length;
      this.objects[index] = data;
     },
     get: function CFFIndex_get(index) {
      return this.objects[index];
     },
     get count() {
      return this.objects.length;
     }
    };
    return CFFIndex;
   }();
   var CFFDict = function CFFDictClosure() {
    function CFFDict(tables, strings) {
     this.keyToNameMap = tables.keyToNameMap;
     this.nameToKeyMap = tables.nameToKeyMap;
     this.defaults = tables.defaults;
     this.types = tables.types;
     this.opcodes = tables.opcodes;
     this.order = tables.order;
     this.strings = strings;
     this.values = Object.create(null);
    }
    CFFDict.prototype = {
     setByKey: function CFFDict_setByKey(key, value) {
      if (!(key in this.keyToNameMap)) {
       return false;
      }
      var valueLength = value.length;
      if (valueLength === 0) {
       return true;
      }
      for (var i = 0; i < valueLength; i++) {
       if (isNaN(value[i])) {
        warn('Invalid CFFDict value: "' + value + '" for key "' + key + '".');
        return true;
       }
      }
      var type = this.types[key];
      if (type === 'num' || type === 'sid' || type === 'offset') {
       value = value[0];
      }
      this.values[key] = value;
      return true;
     },
     setByName: function CFFDict_setByName(name, value) {
      if (!(name in this.nameToKeyMap)) {
       error('Invalid dictionary name "' + name + '"');
      }
      this.values[this.nameToKeyMap[name]] = value;
     },
     hasName: function CFFDict_hasName(name) {
      return this.nameToKeyMap[name] in this.values;
     },
     getByName: function CFFDict_getByName(name) {
      if (!(name in this.nameToKeyMap)) {
       error('Invalid dictionary name "' + name + '"');
      }
      var key = this.nameToKeyMap[name];
      if (!(key in this.values)) {
       return this.defaults[key];
      }
      return this.values[key];
     },
     removeByName: function CFFDict_removeByName(name) {
      delete this.values[this.nameToKeyMap[name]];
     }
    };
    CFFDict.createTables = function CFFDict_createTables(layout) {
     var tables = {
      keyToNameMap: {},
      nameToKeyMap: {},
      defaults: {},
      types: {},
      opcodes: {},
      order: []
     };
     for (var i = 0, ii = layout.length; i < ii; ++i) {
      var entry = layout[i];
      var key = isArray(entry[0]) ? (entry[0][0] << 8) + entry[0][1] : entry[0];
      tables.keyToNameMap[key] = entry[1];
      tables.nameToKeyMap[entry[1]] = key;
      tables.types[key] = entry[2];
      tables.defaults[key] = entry[3];
      tables.opcodes[key] = isArray(entry[0]) ? entry[0] : [entry[0]];
      tables.order.push(key);
     }
     return tables;
    };
    return CFFDict;
   }();
   var CFFTopDict = function CFFTopDictClosure() {
    var layout = [
     [
      [
       12,
       30
      ],
      'ROS',
      [
       'sid',
       'sid',
       'num'
      ],
      null
     ],
     [
      [
       12,
       20
      ],
      'SyntheticBase',
      'num',
      null
     ],
     [
      0,
      'version',
      'sid',
      null
     ],
     [
      1,
      'Notice',
      'sid',
      null
     ],
     [
      [
       12,
       0
      ],
      'Copyright',
      'sid',
      null
     ],
     [
      2,
      'FullName',
      'sid',
      null
     ],
     [
      3,
      'FamilyName',
      'sid',
      null
     ],
     [
      4,
      'Weight',
      'sid',
      null
     ],
     [
      [
       12,
       1
      ],
      'isFixedPitch',
      'num',
      0
     ],
     [
      [
       12,
       2
      ],
      'ItalicAngle',
      'num',
      0
     ],
     [
      [
       12,
       3
      ],
      'UnderlinePosition',
      'num',
      -100
     ],
     [
      [
       12,
       4
      ],
      'UnderlineThickness',
      'num',
      50
     ],
     [
      [
       12,
       5
      ],
      'PaintType',
      'num',
      0
     ],
     [
      [
       12,
       6
      ],
      'CharstringType',
      'num',
      2
     ],
     [
      [
       12,
       7
      ],
      'FontMatrix',
      [
       'num',
       'num',
       'num',
       'num',
       'num',
       'num'
      ],
      [
       0.001,
       0,
       0,
       0.001,
       0,
       0
      ]
     ],
     [
      13,
      'UniqueID',
      'num',
      null
     ],
     [
      5,
      'FontBBox',
      [
       'num',
       'num',
       'num',
       'num'
      ],
      [
       0,
       0,
       0,
       0
      ]
     ],
     [
      [
       12,
       8
      ],
      'StrokeWidth',
      'num',
      0
     ],
     [
      14,
      'XUID',
      'array',
      null
     ],
     [
      15,
      'charset',
      'offset',
      0
     ],
     [
      16,
      'Encoding',
      'offset',
      0
     ],
     [
      17,
      'CharStrings',
      'offset',
      0
     ],
     [
      18,
      'Private',
      [
       'offset',
       'offset'
      ],
      null
     ],
     [
      [
       12,
       21
      ],
      'PostScript',
      'sid',
      null
     ],
     [
      [
       12,
       22
      ],
      'BaseFontName',
      'sid',
      null
     ],
     [
      [
       12,
       23
      ],
      'BaseFontBlend',
      'delta',
      null
     ],
     [
      [
       12,
       31
      ],
      'CIDFontVersion',
      'num',
      0
     ],
     [
      [
       12,
       32
      ],
      'CIDFontRevision',
      'num',
      0
     ],
     [
      [
       12,
       33
      ],
      'CIDFontType',
      'num',
      0
     ],
     [
      [
       12,
       34
      ],
      'CIDCount',
      'num',
      8720
     ],
     [
      [
       12,
       35
      ],
      'UIDBase',
      'num',
      null
     ],
     [
      [
       12,
       37
      ],
      'FDSelect',
      'offset',
      null
     ],
     [
      [
       12,
       36
      ],
      'FDArray',
      'offset',
      null
     ],
     [
      [
       12,
       38
      ],
      'FontName',
      'sid',
      null
     ]
    ];
    var tables = null;
    function CFFTopDict(strings) {
     if (tables === null) {
      tables = CFFDict.createTables(layout);
     }
     CFFDict.call(this, tables, strings);
     this.privateDict = null;
    }
    CFFTopDict.prototype = Object.create(CFFDict.prototype);
    return CFFTopDict;
   }();
   var CFFPrivateDict = function CFFPrivateDictClosure() {
    var layout = [
     [
      6,
      'BlueValues',
      'delta',
      null
     ],
     [
      7,
      'OtherBlues',
      'delta',
      null
     ],
     [
      8,
      'FamilyBlues',
      'delta',
      null
     ],
     [
      9,
      'FamilyOtherBlues',
      'delta',
      null
     ],
     [
      [
       12,
       9
      ],
      'BlueScale',
      'num',
      0.039625
     ],
     [
      [
       12,
       10
      ],
      'BlueShift',
      'num',
      7
     ],
     [
      [
       12,
       11
      ],
      'BlueFuzz',
      'num',
      1
     ],
     [
      10,
      'StdHW',
      'num',
      null
     ],
     [
      11,
      'StdVW',
      'num',
      null
     ],
     [
      [
       12,
       12
      ],
      'StemSnapH',
      'delta',
      null
     ],
     [
      [
       12,
       13
      ],
      'StemSnapV',
      'delta',
      null
     ],
     [
      [
       12,
       14
      ],
      'ForceBold',
      'num',
      0
     ],
     [
      [
       12,
       17
      ],
      'LanguageGroup',
      'num',
      0
     ],
     [
      [
       12,
       18
      ],
      'ExpansionFactor',
      'num',
      0.06
     ],
     [
      [
       12,
       19
      ],
      'initialRandomSeed',
      'num',
      0
     ],
     [
      20,
      'defaultWidthX',
      'num',
      0
     ],
     [
      21,
      'nominalWidthX',
      'num',
      0
     ],
     [
      19,
      'Subrs',
      'offset',
      null
     ]
    ];
    var tables = null;
    function CFFPrivateDict(strings) {
     if (tables === null) {
      tables = CFFDict.createTables(layout);
     }
     CFFDict.call(this, tables, strings);
     this.subrsIndex = null;
    }
    CFFPrivateDict.prototype = Object.create(CFFDict.prototype);
    return CFFPrivateDict;
   }();
   var CFFCharsetPredefinedTypes = {
    ISO_ADOBE: 0,
    EXPERT: 1,
    EXPERT_SUBSET: 2
   };
   var CFFCharset = function CFFCharsetClosure() {
    function CFFCharset(predefined, format, charset, raw) {
     this.predefined = predefined;
     this.format = format;
     this.charset = charset;
     this.raw = raw;
    }
    return CFFCharset;
   }();
   var CFFEncoding = function CFFEncodingClosure() {
    function CFFEncoding(predefined, format, encoding, raw) {
     this.predefined = predefined;
     this.format = format;
     this.encoding = encoding;
     this.raw = raw;
    }
    return CFFEncoding;
   }();
   var CFFFDSelect = function CFFFDSelectClosure() {
    function CFFFDSelect(fdSelect, raw) {
     this.fdSelect = fdSelect;
     this.raw = raw;
    }
    CFFFDSelect.prototype = {
     getFDIndex: function CFFFDSelect_get(glyphIndex) {
      if (glyphIndex < 0 || glyphIndex >= this.fdSelect.length) {
       return -1;
      }
      return this.fdSelect[glyphIndex];
     }
    };
    return CFFFDSelect;
   }();
   var CFFOffsetTracker = function CFFOffsetTrackerClosure() {
    function CFFOffsetTracker() {
     this.offsets = Object.create(null);
    }
    CFFOffsetTracker.prototype = {
     isTracking: function CFFOffsetTracker_isTracking(key) {
      return key in this.offsets;
     },
     track: function CFFOffsetTracker_track(key, location) {
      if (key in this.offsets) {
       error('Already tracking location of ' + key);
      }
      this.offsets[key] = location;
     },
     offset: function CFFOffsetTracker_offset(value) {
      for (var key in this.offsets) {
       this.offsets[key] += value;
      }
     },
     setEntryLocation: function CFFOffsetTracker_setEntryLocation(key, values, output) {
      if (!(key in this.offsets)) {
       error('Not tracking location of ' + key);
      }
      var data = output.data;
      var dataOffset = this.offsets[key];
      var size = 5;
      for (var i = 0, ii = values.length; i < ii; ++i) {
       var offset0 = i * size + dataOffset;
       var offset1 = offset0 + 1;
       var offset2 = offset0 + 2;
       var offset3 = offset0 + 3;
       var offset4 = offset0 + 4;
       if (data[offset0] !== 0x1d || data[offset1] !== 0 || data[offset2] !== 0 || data[offset3] !== 0 || data[offset4] !== 0) {
        error('writing to an offset that is not empty');
       }
       var value = values[i];
       data[offset0] = 0x1d;
       data[offset1] = value >> 24 & 0xFF;
       data[offset2] = value >> 16 & 0xFF;
       data[offset3] = value >> 8 & 0xFF;
       data[offset4] = value & 0xFF;
      }
     }
    };
    return CFFOffsetTracker;
   }();
   var CFFCompiler = function CFFCompilerClosure() {
    function CFFCompiler(cff) {
     this.cff = cff;
    }
    CFFCompiler.prototype = {
     compile: function CFFCompiler_compile() {
      var cff = this.cff;
      var output = {
       data: [],
       length: 0,
       add: function CFFCompiler_add(data) {
        this.data = this.data.concat(data);
        this.length = this.data.length;
       }
      };
      var header = this.compileHeader(cff.header);
      output.add(header);
      var nameIndex = this.compileNameIndex(cff.names);
      output.add(nameIndex);
      if (cff.isCIDFont) {
       if (cff.topDict.hasName('FontMatrix')) {
        var base = cff.topDict.getByName('FontMatrix');
        cff.topDict.removeByName('FontMatrix');
        for (var i = 0, ii = cff.fdArray.length; i < ii; i++) {
         var subDict = cff.fdArray[i];
         var matrix = base.slice(0);
         if (subDict.hasName('FontMatrix')) {
          matrix = Util.transform(matrix, subDict.getByName('FontMatrix'));
         }
         subDict.setByName('FontMatrix', matrix);
        }
       }
      }
      var compiled = this.compileTopDicts([cff.topDict], output.length, cff.isCIDFont);
      output.add(compiled.output);
      var topDictTracker = compiled.trackers[0];
      var stringIndex = this.compileStringIndex(cff.strings.strings);
      output.add(stringIndex);
      var globalSubrIndex = this.compileIndex(cff.globalSubrIndex);
      output.add(globalSubrIndex);
      if (cff.encoding && cff.topDict.hasName('Encoding')) {
       if (cff.encoding.predefined) {
        topDictTracker.setEntryLocation('Encoding', [cff.encoding.format], output);
       } else {
        var encoding = this.compileEncoding(cff.encoding);
        topDictTracker.setEntryLocation('Encoding', [output.length], output);
        output.add(encoding);
       }
      }
      if (cff.charset && cff.topDict.hasName('charset')) {
       if (cff.charset.predefined) {
        topDictTracker.setEntryLocation('charset', [cff.charset.format], output);
       } else {
        var charset = this.compileCharset(cff.charset);
        topDictTracker.setEntryLocation('charset', [output.length], output);
        output.add(charset);
       }
      }
      var charStrings = this.compileCharStrings(cff.charStrings);
      topDictTracker.setEntryLocation('CharStrings', [output.length], output);
      output.add(charStrings);
      if (cff.isCIDFont) {
       topDictTracker.setEntryLocation('FDSelect', [output.length], output);
       var fdSelect = this.compileFDSelect(cff.fdSelect.raw);
       output.add(fdSelect);
       compiled = this.compileTopDicts(cff.fdArray, output.length, true);
       topDictTracker.setEntryLocation('FDArray', [output.length], output);
       output.add(compiled.output);
       var fontDictTrackers = compiled.trackers;
       this.compilePrivateDicts(cff.fdArray, fontDictTrackers, output);
      }
      this.compilePrivateDicts([cff.topDict], [topDictTracker], output);
      output.add([0]);
      return output.data;
     },
     encodeNumber: function CFFCompiler_encodeNumber(value) {
      if (parseFloat(value) === parseInt(value, 10) && !isNaN(value)) {
       return this.encodeInteger(value);
      } else {
       return this.encodeFloat(value);
      }
     },
     encodeFloat: function CFFCompiler_encodeFloat(num) {
      var value = num.toString();
      var m = /\.(\d*?)(?:9{5,20}|0{5,20})\d{0,2}(?:e(.+)|$)/.exec(value);
      if (m) {
       var epsilon = parseFloat('1e' + ((m[2] ? +m[2] : 0) + m[1].length));
       value = (Math.round(num * epsilon) / epsilon).toString();
      }
      var nibbles = '';
      var i, ii;
      for (i = 0, ii = value.length; i < ii; ++i) {
       var a = value[i];
       if (a === 'e') {
        nibbles += value[++i] === '-' ? 'c' : 'b';
       } else if (a === '.') {
        nibbles += 'a';
       } else if (a === '-') {
        nibbles += 'e';
       } else {
        nibbles += a;
       }
      }
      nibbles += nibbles.length & 1 ? 'f' : 'ff';
      var out = [30];
      for (i = 0, ii = nibbles.length; i < ii; i += 2) {
       out.push(parseInt(nibbles.substr(i, 2), 16));
      }
      return out;
     },
     encodeInteger: function CFFCompiler_encodeInteger(value) {
      var code;
      if (value >= -107 && value <= 107) {
       code = [value + 139];
      } else if (value >= 108 && value <= 1131) {
       value = value - 108;
       code = [
        (value >> 8) + 247,
        value & 0xFF
       ];
      } else if (value >= -1131 && value <= -108) {
       value = -value - 108;
       code = [
        (value >> 8) + 251,
        value & 0xFF
       ];
      } else if (value >= -32768 && value <= 32767) {
       code = [
        0x1c,
        value >> 8 & 0xFF,
        value & 0xFF
       ];
      } else {
       code = [
        0x1d,
        value >> 24 & 0xFF,
        value >> 16 & 0xFF,
        value >> 8 & 0xFF,
        value & 0xFF
       ];
      }
      return code;
     },
     compileHeader: function CFFCompiler_compileHeader(header) {
      return [
       header.major,
       header.minor,
       header.hdrSize,
       header.offSize
      ];
     },
     compileNameIndex: function CFFCompiler_compileNameIndex(names) {
      var nameIndex = new CFFIndex();
      for (var i = 0, ii = names.length; i < ii; ++i) {
       nameIndex.add(stringToBytes(names[i]));
      }
      return this.compileIndex(nameIndex);
     },
     compileTopDicts: function CFFCompiler_compileTopDicts(dicts, length, removeCidKeys) {
      var fontDictTrackers = [];
      var fdArrayIndex = new CFFIndex();
      for (var i = 0, ii = dicts.length; i < ii; ++i) {
       var fontDict = dicts[i];
       if (removeCidKeys) {
        fontDict.removeByName('CIDFontVersion');
        fontDict.removeByName('CIDFontRevision');
        fontDict.removeByName('CIDFontType');
        fontDict.removeByName('CIDCount');
        fontDict.removeByName('UIDBase');
       }
       var fontDictTracker = new CFFOffsetTracker();
       var fontDictData = this.compileDict(fontDict, fontDictTracker);
       fontDictTrackers.push(fontDictTracker);
       fdArrayIndex.add(fontDictData);
       fontDictTracker.offset(length);
      }
      fdArrayIndex = this.compileIndex(fdArrayIndex, fontDictTrackers);
      return {
       trackers: fontDictTrackers,
       output: fdArrayIndex
      };
     },
     compilePrivateDicts: function CFFCompiler_compilePrivateDicts(dicts, trackers, output) {
      for (var i = 0, ii = dicts.length; i < ii; ++i) {
       var fontDict = dicts[i];
       assert(fontDict.privateDict && fontDict.hasName('Private'), 'There must be an private dictionary.');
       var privateDict = fontDict.privateDict;
       var privateDictTracker = new CFFOffsetTracker();
       var privateDictData = this.compileDict(privateDict, privateDictTracker);
       var outputLength = output.length;
       privateDictTracker.offset(outputLength);
       if (!privateDictData.length) {
        outputLength = 0;
       }
       trackers[i].setEntryLocation('Private', [
        privateDictData.length,
        outputLength
       ], output);
       output.add(privateDictData);
       if (privateDict.subrsIndex && privateDict.hasName('Subrs')) {
        var subrs = this.compileIndex(privateDict.subrsIndex);
        privateDictTracker.setEntryLocation('Subrs', [privateDictData.length], output);
        output.add(subrs);
       }
      }
     },
     compileDict: function CFFCompiler_compileDict(dict, offsetTracker) {
      var out = [];
      var order = dict.order;
      for (var i = 0; i < order.length; ++i) {
       var key = order[i];
       if (!(key in dict.values)) {
        continue;
       }
       var values = dict.values[key];
       var types = dict.types[key];
       if (!isArray(types)) {
        types = [types];
       }
       if (!isArray(values)) {
        values = [values];
       }
       if (values.length === 0) {
        continue;
       }
       for (var j = 0, jj = types.length; j < jj; ++j) {
        var type = types[j];
        var value = values[j];
        switch (type) {
        case 'num':
        case 'sid':
         out = out.concat(this.encodeNumber(value));
         break;
        case 'offset':
         var name = dict.keyToNameMap[key];
         if (!offsetTracker.isTracking(name)) {
          offsetTracker.track(name, out.length);
         }
         out = out.concat([
          0x1d,
          0,
          0,
          0,
          0
         ]);
         break;
        case 'array':
        case 'delta':
         out = out.concat(this.encodeNumber(value));
         for (var k = 1, kk = values.length; k < kk; ++k) {
          out = out.concat(this.encodeNumber(values[k]));
         }
         break;
        default:
         error('Unknown data type of ' + type);
         break;
        }
       }
       out = out.concat(dict.opcodes[key]);
      }
      return out;
     },
     compileStringIndex: function CFFCompiler_compileStringIndex(strings) {
      var stringIndex = new CFFIndex();
      for (var i = 0, ii = strings.length; i < ii; ++i) {
       stringIndex.add(stringToBytes(strings[i]));
      }
      return this.compileIndex(stringIndex);
     },
     compileGlobalSubrIndex: function CFFCompiler_compileGlobalSubrIndex() {
      var globalSubrIndex = this.cff.globalSubrIndex;
      this.out.writeByteArray(this.compileIndex(globalSubrIndex));
     },
     compileCharStrings: function CFFCompiler_compileCharStrings(charStrings) {
      return this.compileIndex(charStrings);
     },
     compileCharset: function CFFCompiler_compileCharset(charset) {
      return this.compileTypedArray(charset.raw);
     },
     compileEncoding: function CFFCompiler_compileEncoding(encoding) {
      return this.compileTypedArray(encoding.raw);
     },
     compileFDSelect: function CFFCompiler_compileFDSelect(fdSelect) {
      return this.compileTypedArray(fdSelect);
     },
     compileTypedArray: function CFFCompiler_compileTypedArray(data) {
      var out = [];
      for (var i = 0, ii = data.length; i < ii; ++i) {
       out[i] = data[i];
      }
      return out;
     },
     compileIndex: function CFFCompiler_compileIndex(index, trackers) {
      trackers = trackers || [];
      var objects = index.objects;
      var count = objects.length;
      if (count === 0) {
       return [
        0,
        0,
        0
       ];
      }
      var data = [
       count >> 8 & 0xFF,
       count & 0xff
      ];
      var lastOffset = 1, i;
      for (i = 0; i < count; ++i) {
       lastOffset += objects[i].length;
      }
      var offsetSize;
      if (lastOffset < 0x100) {
       offsetSize = 1;
      } else if (lastOffset < 0x10000) {
       offsetSize = 2;
      } else if (lastOffset < 0x1000000) {
       offsetSize = 3;
      } else {
       offsetSize = 4;
      }
      data.push(offsetSize);
      var relativeOffset = 1;
      for (i = 0; i < count + 1; i++) {
       if (offsetSize === 1) {
        data.push(relativeOffset & 0xFF);
       } else if (offsetSize === 2) {
        data.push(relativeOffset >> 8 & 0xFF, relativeOffset & 0xFF);
       } else if (offsetSize === 3) {
        data.push(relativeOffset >> 16 & 0xFF, relativeOffset >> 8 & 0xFF, relativeOffset & 0xFF);
       } else {
        data.push(relativeOffset >>> 24 & 0xFF, relativeOffset >> 16 & 0xFF, relativeOffset >> 8 & 0xFF, relativeOffset & 0xFF);
       }
       if (objects[i]) {
        relativeOffset += objects[i].length;
       }
      }
      for (i = 0; i < count; i++) {
       if (trackers[i]) {
        trackers[i].offset(data.length);
       }
       for (var j = 0, jj = objects[i].length; j < jj; j++) {
        data.push(objects[i][j]);
       }
      }
      return data;
     }
    };
    return CFFCompiler;
   }();
   exports.CFFStandardStrings = CFFStandardStrings;
   exports.CFFParser = CFFParser;
   exports.CFF = CFF;
   exports.CFFHeader = CFFHeader;
   exports.CFFStrings = CFFStrings;
   exports.CFFIndex = CFFIndex;
   exports.CFFCharset = CFFCharset;
   exports.CFFTopDict = CFFTopDict;
   exports.CFFPrivateDict = CFFPrivateDict;
   exports.CFFCompiler = CFFCompiler;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreChunkedStream = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var MissingDataException = sharedUtil.MissingDataException;
   var arrayByteLength = sharedUtil.arrayByteLength;
   var arraysToBytes = sharedUtil.arraysToBytes;
   var assert = sharedUtil.assert;
   var createPromiseCapability = sharedUtil.createPromiseCapability;
   var isInt = sharedUtil.isInt;
   var isEmptyObj = sharedUtil.isEmptyObj;
   var ChunkedStream = function ChunkedStreamClosure() {
    function ChunkedStream(length, chunkSize, manager) {
     this.bytes = new Uint8Array(length);
     this.start = 0;
     this.pos = 0;
     this.end = length;
     this.chunkSize = chunkSize;
     this.loadedChunks = [];
     this.numChunksLoaded = 0;
     this.numChunks = Math.ceil(length / chunkSize);
     this.manager = manager;
     this.progressiveDataLength = 0;
     this.lastSuccessfulEnsureByteChunk = -1;
    }
    ChunkedStream.prototype = {
     getMissingChunks: function ChunkedStream_getMissingChunks() {
      var chunks = [];
      for (var chunk = 0, n = this.numChunks; chunk < n; ++chunk) {
       if (!this.loadedChunks[chunk]) {
        chunks.push(chunk);
       }
      }
      return chunks;
     },
     getBaseStreams: function ChunkedStream_getBaseStreams() {
      return [this];
     },
     allChunksLoaded: function ChunkedStream_allChunksLoaded() {
      return this.numChunksLoaded === this.numChunks;
     },
     onReceiveData: function ChunkedStream_onReceiveData(begin, chunk) {
      var end = begin + chunk.byteLength;
      assert(begin % this.chunkSize === 0, 'Bad begin offset: ' + begin);
      var length = this.bytes.length;
      assert(end % this.chunkSize === 0 || end === length, 'Bad end offset: ' + end);
      this.bytes.set(new Uint8Array(chunk), begin);
      var chunkSize = this.chunkSize;
      var beginChunk = Math.floor(begin / chunkSize);
      var endChunk = Math.floor((end - 1) / chunkSize) + 1;
      var curChunk;
      for (curChunk = beginChunk; curChunk < endChunk; ++curChunk) {
       if (!this.loadedChunks[curChunk]) {
        this.loadedChunks[curChunk] = true;
        ++this.numChunksLoaded;
       }
      }
     },
     onReceiveProgressiveData: function ChunkedStream_onReceiveProgressiveData(data) {
      var position = this.progressiveDataLength;
      var beginChunk = Math.floor(position / this.chunkSize);
      this.bytes.set(new Uint8Array(data), position);
      position += data.byteLength;
      this.progressiveDataLength = position;
      var endChunk = position >= this.end ? this.numChunks : Math.floor(position / this.chunkSize);
      var curChunk;
      for (curChunk = beginChunk; curChunk < endChunk; ++curChunk) {
       if (!this.loadedChunks[curChunk]) {
        this.loadedChunks[curChunk] = true;
        ++this.numChunksLoaded;
       }
      }
     },
     ensureByte: function ChunkedStream_ensureByte(pos) {
      var chunk = Math.floor(pos / this.chunkSize);
      if (chunk === this.lastSuccessfulEnsureByteChunk) {
       return;
      }
      if (!this.loadedChunks[chunk]) {
       throw new MissingDataException(pos, pos + 1);
      }
      this.lastSuccessfulEnsureByteChunk = chunk;
     },
     ensureRange: function ChunkedStream_ensureRange(begin, end) {
      if (begin >= end) {
       return;
      }
      if (end <= this.progressiveDataLength) {
       return;
      }
      var chunkSize = this.chunkSize;
      var beginChunk = Math.floor(begin / chunkSize);
      var endChunk = Math.floor((end - 1) / chunkSize) + 1;
      for (var chunk = beginChunk; chunk < endChunk; ++chunk) {
       if (!this.loadedChunks[chunk]) {
        throw new MissingDataException(begin, end);
       }
      }
     },
     nextEmptyChunk: function ChunkedStream_nextEmptyChunk(beginChunk) {
      var chunk, numChunks = this.numChunks;
      for (var i = 0; i < numChunks; ++i) {
       chunk = (beginChunk + i) % numChunks;
       if (!this.loadedChunks[chunk]) {
        return chunk;
       }
      }
      return null;
     },
     hasChunk: function ChunkedStream_hasChunk(chunk) {
      return !!this.loadedChunks[chunk];
     },
     get length() {
      return this.end - this.start;
     },
     get isEmpty() {
      return this.length === 0;
     },
     getByte: function ChunkedStream_getByte() {
      var pos = this.pos;
      if (pos >= this.end) {
       return -1;
      }
      this.ensureByte(pos);
      return this.bytes[this.pos++];
     },
     getUint16: function ChunkedStream_getUint16() {
      var b0 = this.getByte();
      var b1 = this.getByte();
      if (b0 === -1 || b1 === -1) {
       return -1;
      }
      return (b0 << 8) + b1;
     },
     getInt32: function ChunkedStream_getInt32() {
      var b0 = this.getByte();
      var b1 = this.getByte();
      var b2 = this.getByte();
      var b3 = this.getByte();
      return (b0 << 24) + (b1 << 16) + (b2 << 8) + b3;
     },
     getBytes: function ChunkedStream_getBytes(length) {
      var bytes = this.bytes;
      var pos = this.pos;
      var strEnd = this.end;
      if (!length) {
       this.ensureRange(pos, strEnd);
       return bytes.subarray(pos, strEnd);
      }
      var end = pos + length;
      if (end > strEnd) {
       end = strEnd;
      }
      this.ensureRange(pos, end);
      this.pos = end;
      return bytes.subarray(pos, end);
     },
     peekByte: function ChunkedStream_peekByte() {
      var peekedByte = this.getByte();
      this.pos--;
      return peekedByte;
     },
     peekBytes: function ChunkedStream_peekBytes(length) {
      var bytes = this.getBytes(length);
      this.pos -= bytes.length;
      return bytes;
     },
     getByteRange: function ChunkedStream_getBytes(begin, end) {
      this.ensureRange(begin, end);
      return this.bytes.subarray(begin, end);
     },
     skip: function ChunkedStream_skip(n) {
      if (!n) {
       n = 1;
      }
      this.pos += n;
     },
     reset: function ChunkedStream_reset() {
      this.pos = this.start;
     },
     moveStart: function ChunkedStream_moveStart() {
      this.start = this.pos;
     },
     makeSubStream: function ChunkedStream_makeSubStream(start, length, dict) {
      this.ensureRange(start, start + length);
      function ChunkedStreamSubstream() {
      }
      ChunkedStreamSubstream.prototype = Object.create(this);
      ChunkedStreamSubstream.prototype.getMissingChunks = function () {
       var chunkSize = this.chunkSize;
       var beginChunk = Math.floor(this.start / chunkSize);
       var endChunk = Math.floor((this.end - 1) / chunkSize) + 1;
       var missingChunks = [];
       for (var chunk = beginChunk; chunk < endChunk; ++chunk) {
        if (!this.loadedChunks[chunk]) {
         missingChunks.push(chunk);
        }
       }
       return missingChunks;
      };
      var subStream = new ChunkedStreamSubstream();
      subStream.pos = subStream.start = start;
      subStream.end = start + length || this.end;
      subStream.dict = dict;
      return subStream;
     },
     isStream: true
    };
    return ChunkedStream;
   }();
   var ChunkedStreamManager = function ChunkedStreamManagerClosure() {
    function ChunkedStreamManager(pdfNetworkStream, args) {
     var chunkSize = args.rangeChunkSize;
     var length = args.length;
     this.stream = new ChunkedStream(length, chunkSize, this);
     this.length = length;
     this.chunkSize = chunkSize;
     this.pdfNetworkStream = pdfNetworkStream;
     this.url = args.url;
     this.disableAutoFetch = args.disableAutoFetch;
     this.msgHandler = args.msgHandler;
     this.currRequestId = 0;
     this.chunksNeededByRequest = Object.create(null);
     this.requestsByChunk = Object.create(null);
     this.promisesByRequest = Object.create(null);
     this.progressiveDataLength = 0;
     this.aborted = false;
     this._loadedStreamCapability = createPromiseCapability();
    }
    ChunkedStreamManager.prototype = {
     onLoadedStream: function ChunkedStreamManager_getLoadedStream() {
      return this._loadedStreamCapability.promise;
     },
     sendRequest: function ChunkedStreamManager_sendRequest(begin, end) {
      var rangeReader = this.pdfNetworkStream.getRangeReader(begin, end);
      if (!rangeReader.isStreamingSupported) {
       rangeReader.onProgress = this.onProgress.bind(this);
      }
      var chunks = [], loaded = 0;
      var manager = this;
      var promise = new Promise(function (resolve, reject) {
       var readChunk = function (chunk) {
        try {
         if (!chunk.done) {
          var data = chunk.value;
          chunks.push(data);
          loaded += arrayByteLength(data);
          if (rangeReader.isStreamingSupported) {
           manager.onProgress({ loaded: loaded });
          }
          rangeReader.read().then(readChunk, reject);
          return;
         }
         var chunkData = arraysToBytes(chunks);
         chunks = null;
         resolve(chunkData);
        } catch (e) {
         reject(e);
        }
       };
       rangeReader.read().then(readChunk, reject);
      });
      promise.then(function (data) {
       if (this.aborted) {
        return;
       }
       this.onReceiveData({
        chunk: data,
        begin: begin
       });
      }.bind(this));
     },
     requestAllChunks: function ChunkedStreamManager_requestAllChunks() {
      var missingChunks = this.stream.getMissingChunks();
      this._requestChunks(missingChunks);
      return this._loadedStreamCapability.promise;
     },
     _requestChunks: function ChunkedStreamManager_requestChunks(chunks) {
      var requestId = this.currRequestId++;
      var i, ii;
      var chunksNeeded = Object.create(null);
      this.chunksNeededByRequest[requestId] = chunksNeeded;
      for (i = 0, ii = chunks.length; i < ii; i++) {
       if (!this.stream.hasChunk(chunks[i])) {
        chunksNeeded[chunks[i]] = true;
       }
      }
      if (isEmptyObj(chunksNeeded)) {
       return Promise.resolve();
      }
      var capability = createPromiseCapability();
      this.promisesByRequest[requestId] = capability;
      var chunksToRequest = [];
      for (var chunk in chunksNeeded) {
       chunk = chunk | 0;
       if (!(chunk in this.requestsByChunk)) {
        this.requestsByChunk[chunk] = [];
        chunksToRequest.push(chunk);
       }
       this.requestsByChunk[chunk].push(requestId);
      }
      if (!chunksToRequest.length) {
       return capability.promise;
      }
      var groupedChunksToRequest = this.groupChunks(chunksToRequest);
      for (i = 0; i < groupedChunksToRequest.length; ++i) {
       var groupedChunk = groupedChunksToRequest[i];
       var begin = groupedChunk.beginChunk * this.chunkSize;
       var end = Math.min(groupedChunk.endChunk * this.chunkSize, this.length);
       this.sendRequest(begin, end);
      }
      return capability.promise;
     },
     getStream: function ChunkedStreamManager_getStream() {
      return this.stream;
     },
     requestRange: function ChunkedStreamManager_requestRange(begin, end) {
      end = Math.min(end, this.length);
      var beginChunk = this.getBeginChunk(begin);
      var endChunk = this.getEndChunk(end);
      var chunks = [];
      for (var chunk = beginChunk; chunk < endChunk; ++chunk) {
       chunks.push(chunk);
      }
      return this._requestChunks(chunks);
     },
     requestRanges: function ChunkedStreamManager_requestRanges(ranges) {
      ranges = ranges || [];
      var chunksToRequest = [];
      for (var i = 0; i < ranges.length; i++) {
       var beginChunk = this.getBeginChunk(ranges[i].begin);
       var endChunk = this.getEndChunk(ranges[i].end);
       for (var chunk = beginChunk; chunk < endChunk; ++chunk) {
        if (chunksToRequest.indexOf(chunk) < 0) {
         chunksToRequest.push(chunk);
        }
       }
      }
      chunksToRequest.sort(function (a, b) {
       return a - b;
      });
      return this._requestChunks(chunksToRequest);
     },
     groupChunks: function ChunkedStreamManager_groupChunks(chunks) {
      var groupedChunks = [];
      var beginChunk = -1;
      var prevChunk = -1;
      for (var i = 0; i < chunks.length; ++i) {
       var chunk = chunks[i];
       if (beginChunk < 0) {
        beginChunk = chunk;
       }
       if (prevChunk >= 0 && prevChunk + 1 !== chunk) {
        groupedChunks.push({
         beginChunk: beginChunk,
         endChunk: prevChunk + 1
        });
        beginChunk = chunk;
       }
       if (i + 1 === chunks.length) {
        groupedChunks.push({
         beginChunk: beginChunk,
         endChunk: chunk + 1
        });
       }
       prevChunk = chunk;
      }
      return groupedChunks;
     },
     onProgress: function ChunkedStreamManager_onProgress(args) {
      var bytesLoaded = this.stream.numChunksLoaded * this.chunkSize + args.loaded;
      this.msgHandler.send('DocProgress', {
       loaded: bytesLoaded,
       total: this.length
      });
     },
     onReceiveData: function ChunkedStreamManager_onReceiveData(args) {
      var chunk = args.chunk;
      var isProgressive = args.begin === undefined;
      var begin = isProgressive ? this.progressiveDataLength : args.begin;
      var end = begin + chunk.byteLength;
      var beginChunk = Math.floor(begin / this.chunkSize);
      var endChunk = end < this.length ? Math.floor(end / this.chunkSize) : Math.ceil(end / this.chunkSize);
      if (isProgressive) {
       this.stream.onReceiveProgressiveData(chunk);
       this.progressiveDataLength = end;
      } else {
       this.stream.onReceiveData(begin, chunk);
      }
      if (this.stream.allChunksLoaded()) {
       this._loadedStreamCapability.resolve(this.stream);
      }
      var loadedRequests = [];
      var i, requestId;
      for (chunk = beginChunk; chunk < endChunk; ++chunk) {
       var requestIds = this.requestsByChunk[chunk] || [];
       delete this.requestsByChunk[chunk];
       for (i = 0; i < requestIds.length; ++i) {
        requestId = requestIds[i];
        var chunksNeeded = this.chunksNeededByRequest[requestId];
        if (chunk in chunksNeeded) {
         delete chunksNeeded[chunk];
        }
        if (!isEmptyObj(chunksNeeded)) {
         continue;
        }
        loadedRequests.push(requestId);
       }
      }
      if (!this.disableAutoFetch && isEmptyObj(this.requestsByChunk)) {
       var nextEmptyChunk;
       if (this.stream.numChunksLoaded === 1) {
        var lastChunk = this.stream.numChunks - 1;
        if (!this.stream.hasChunk(lastChunk)) {
         nextEmptyChunk = lastChunk;
        }
       } else {
        nextEmptyChunk = this.stream.nextEmptyChunk(endChunk);
       }
       if (isInt(nextEmptyChunk)) {
        this._requestChunks([nextEmptyChunk]);
       }
      }
      for (i = 0; i < loadedRequests.length; ++i) {
       requestId = loadedRequests[i];
       var capability = this.promisesByRequest[requestId];
       delete this.promisesByRequest[requestId];
       capability.resolve();
      }
      this.msgHandler.send('DocProgress', {
       loaded: this.stream.numChunksLoaded * this.chunkSize,
       total: this.length
      });
     },
     onError: function ChunkedStreamManager_onError(err) {
      this._loadedStreamCapability.reject(err);
     },
     getBeginChunk: function ChunkedStreamManager_getBeginChunk(begin) {
      var chunk = Math.floor(begin / this.chunkSize);
      return chunk;
     },
     getEndChunk: function ChunkedStreamManager_getEndChunk(end) {
      var chunk = Math.floor((end - 1) / this.chunkSize) + 1;
      return chunk;
     },
     abort: function ChunkedStreamManager_abort() {
      this.aborted = true;
      if (this.pdfNetworkStream) {
       this.pdfNetworkStream.cancelAllRequests('abort');
      }
      for (var requestId in this.promisesByRequest) {
       var capability = this.promisesByRequest[requestId];
       capability.reject(new Error('Request was aborted'));
      }
     }
    };
    return ChunkedStreamManager;
   }();
   exports.ChunkedStream = ChunkedStream;
   exports.ChunkedStreamManager = ChunkedStreamManager;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreGlyphList = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var getLookupTableFactory = sharedUtil.getLookupTableFactory;
   var getGlyphsUnicode = getLookupTableFactory(function (t) {
    t['A'] = 0x0041;
    t['AE'] = 0x00C6;
    t['AEacute'] = 0x01FC;
    t['AEmacron'] = 0x01E2;
    t['AEsmall'] = 0xF7E6;
    t['Aacute'] = 0x00C1;
    t['Aacutesmall'] = 0xF7E1;
    t['Abreve'] = 0x0102;
    t['Abreveacute'] = 0x1EAE;
    t['Abrevecyrillic'] = 0x04D0;
    t['Abrevedotbelow'] = 0x1EB6;
    t['Abrevegrave'] = 0x1EB0;
    t['Abrevehookabove'] = 0x1EB2;
    t['Abrevetilde'] = 0x1EB4;
    t['Acaron'] = 0x01CD;
    t['Acircle'] = 0x24B6;
    t['Acircumflex'] = 0x00C2;
    t['Acircumflexacute'] = 0x1EA4;
    t['Acircumflexdotbelow'] = 0x1EAC;
    t['Acircumflexgrave'] = 0x1EA6;
    t['Acircumflexhookabove'] = 0x1EA8;
    t['Acircumflexsmall'] = 0xF7E2;
    t['Acircumflextilde'] = 0x1EAA;
    t['Acute'] = 0xF6C9;
    t['Acutesmall'] = 0xF7B4;
    t['Acyrillic'] = 0x0410;
    t['Adblgrave'] = 0x0200;
    t['Adieresis'] = 0x00C4;
    t['Adieresiscyrillic'] = 0x04D2;
    t['Adieresismacron'] = 0x01DE;
    t['Adieresissmall'] = 0xF7E4;
    t['Adotbelow'] = 0x1EA0;
    t['Adotmacron'] = 0x01E0;
    t['Agrave'] = 0x00C0;
    t['Agravesmall'] = 0xF7E0;
    t['Ahookabove'] = 0x1EA2;
    t['Aiecyrillic'] = 0x04D4;
    t['Ainvertedbreve'] = 0x0202;
    t['Alpha'] = 0x0391;
    t['Alphatonos'] = 0x0386;
    t['Amacron'] = 0x0100;
    t['Amonospace'] = 0xFF21;
    t['Aogonek'] = 0x0104;
    t['Aring'] = 0x00C5;
    t['Aringacute'] = 0x01FA;
    t['Aringbelow'] = 0x1E00;
    t['Aringsmall'] = 0xF7E5;
    t['Asmall'] = 0xF761;
    t['Atilde'] = 0x00C3;
    t['Atildesmall'] = 0xF7E3;
    t['Aybarmenian'] = 0x0531;
    t['B'] = 0x0042;
    t['Bcircle'] = 0x24B7;
    t['Bdotaccent'] = 0x1E02;
    t['Bdotbelow'] = 0x1E04;
    t['Becyrillic'] = 0x0411;
    t['Benarmenian'] = 0x0532;
    t['Beta'] = 0x0392;
    t['Bhook'] = 0x0181;
    t['Blinebelow'] = 0x1E06;
    t['Bmonospace'] = 0xFF22;
    t['Brevesmall'] = 0xF6F4;
    t['Bsmall'] = 0xF762;
    t['Btopbar'] = 0x0182;
    t['C'] = 0x0043;
    t['Caarmenian'] = 0x053E;
    t['Cacute'] = 0x0106;
    t['Caron'] = 0xF6CA;
    t['Caronsmall'] = 0xF6F5;
    t['Ccaron'] = 0x010C;
    t['Ccedilla'] = 0x00C7;
    t['Ccedillaacute'] = 0x1E08;
    t['Ccedillasmall'] = 0xF7E7;
    t['Ccircle'] = 0x24B8;
    t['Ccircumflex'] = 0x0108;
    t['Cdot'] = 0x010A;
    t['Cdotaccent'] = 0x010A;
    t['Cedillasmall'] = 0xF7B8;
    t['Chaarmenian'] = 0x0549;
    t['Cheabkhasiancyrillic'] = 0x04BC;
    t['Checyrillic'] = 0x0427;
    t['Chedescenderabkhasiancyrillic'] = 0x04BE;
    t['Chedescendercyrillic'] = 0x04B6;
    t['Chedieresiscyrillic'] = 0x04F4;
    t['Cheharmenian'] = 0x0543;
    t['Chekhakassiancyrillic'] = 0x04CB;
    t['Cheverticalstrokecyrillic'] = 0x04B8;
    t['Chi'] = 0x03A7;
    t['Chook'] = 0x0187;
    t['Circumflexsmall'] = 0xF6F6;
    t['Cmonospace'] = 0xFF23;
    t['Coarmenian'] = 0x0551;
    t['Csmall'] = 0xF763;
    t['D'] = 0x0044;
    t['DZ'] = 0x01F1;
    t['DZcaron'] = 0x01C4;
    t['Daarmenian'] = 0x0534;
    t['Dafrican'] = 0x0189;
    t['Dcaron'] = 0x010E;
    t['Dcedilla'] = 0x1E10;
    t['Dcircle'] = 0x24B9;
    t['Dcircumflexbelow'] = 0x1E12;
    t['Dcroat'] = 0x0110;
    t['Ddotaccent'] = 0x1E0A;
    t['Ddotbelow'] = 0x1E0C;
    t['Decyrillic'] = 0x0414;
    t['Deicoptic'] = 0x03EE;
    t['Delta'] = 0x2206;
    t['Deltagreek'] = 0x0394;
    t['Dhook'] = 0x018A;
    t['Dieresis'] = 0xF6CB;
    t['DieresisAcute'] = 0xF6CC;
    t['DieresisGrave'] = 0xF6CD;
    t['Dieresissmall'] = 0xF7A8;
    t['Digammagreek'] = 0x03DC;
    t['Djecyrillic'] = 0x0402;
    t['Dlinebelow'] = 0x1E0E;
    t['Dmonospace'] = 0xFF24;
    t['Dotaccentsmall'] = 0xF6F7;
    t['Dslash'] = 0x0110;
    t['Dsmall'] = 0xF764;
    t['Dtopbar'] = 0x018B;
    t['Dz'] = 0x01F2;
    t['Dzcaron'] = 0x01C5;
    t['Dzeabkhasiancyrillic'] = 0x04E0;
    t['Dzecyrillic'] = 0x0405;
    t['Dzhecyrillic'] = 0x040F;
    t['E'] = 0x0045;
    t['Eacute'] = 0x00C9;
    t['Eacutesmall'] = 0xF7E9;
    t['Ebreve'] = 0x0114;
    t['Ecaron'] = 0x011A;
    t['Ecedillabreve'] = 0x1E1C;
    t['Echarmenian'] = 0x0535;
    t['Ecircle'] = 0x24BA;
    t['Ecircumflex'] = 0x00CA;
    t['Ecircumflexacute'] = 0x1EBE;
    t['Ecircumflexbelow'] = 0x1E18;
    t['Ecircumflexdotbelow'] = 0x1EC6;
    t['Ecircumflexgrave'] = 0x1EC0;
    t['Ecircumflexhookabove'] = 0x1EC2;
    t['Ecircumflexsmall'] = 0xF7EA;
    t['Ecircumflextilde'] = 0x1EC4;
    t['Ecyrillic'] = 0x0404;
    t['Edblgrave'] = 0x0204;
    t['Edieresis'] = 0x00CB;
    t['Edieresissmall'] = 0xF7EB;
    t['Edot'] = 0x0116;
    t['Edotaccent'] = 0x0116;
    t['Edotbelow'] = 0x1EB8;
    t['Efcyrillic'] = 0x0424;
    t['Egrave'] = 0x00C8;
    t['Egravesmall'] = 0xF7E8;
    t['Eharmenian'] = 0x0537;
    t['Ehookabove'] = 0x1EBA;
    t['Eightroman'] = 0x2167;
    t['Einvertedbreve'] = 0x0206;
    t['Eiotifiedcyrillic'] = 0x0464;
    t['Elcyrillic'] = 0x041B;
    t['Elevenroman'] = 0x216A;
    t['Emacron'] = 0x0112;
    t['Emacronacute'] = 0x1E16;
    t['Emacrongrave'] = 0x1E14;
    t['Emcyrillic'] = 0x041C;
    t['Emonospace'] = 0xFF25;
    t['Encyrillic'] = 0x041D;
    t['Endescendercyrillic'] = 0x04A2;
    t['Eng'] = 0x014A;
    t['Enghecyrillic'] = 0x04A4;
    t['Enhookcyrillic'] = 0x04C7;
    t['Eogonek'] = 0x0118;
    t['Eopen'] = 0x0190;
    t['Epsilon'] = 0x0395;
    t['Epsilontonos'] = 0x0388;
    t['Ercyrillic'] = 0x0420;
    t['Ereversed'] = 0x018E;
    t['Ereversedcyrillic'] = 0x042D;
    t['Escyrillic'] = 0x0421;
    t['Esdescendercyrillic'] = 0x04AA;
    t['Esh'] = 0x01A9;
    t['Esmall'] = 0xF765;
    t['Eta'] = 0x0397;
    t['Etarmenian'] = 0x0538;
    t['Etatonos'] = 0x0389;
    t['Eth'] = 0x00D0;
    t['Ethsmall'] = 0xF7F0;
    t['Etilde'] = 0x1EBC;
    t['Etildebelow'] = 0x1E1A;
    t['Euro'] = 0x20AC;
    t['Ezh'] = 0x01B7;
    t['Ezhcaron'] = 0x01EE;
    t['Ezhreversed'] = 0x01B8;
    t['F'] = 0x0046;
    t['Fcircle'] = 0x24BB;
    t['Fdotaccent'] = 0x1E1E;
    t['Feharmenian'] = 0x0556;
    t['Feicoptic'] = 0x03E4;
    t['Fhook'] = 0x0191;
    t['Fitacyrillic'] = 0x0472;
    t['Fiveroman'] = 0x2164;
    t['Fmonospace'] = 0xFF26;
    t['Fourroman'] = 0x2163;
    t['Fsmall'] = 0xF766;
    t['G'] = 0x0047;
    t['GBsquare'] = 0x3387;
    t['Gacute'] = 0x01F4;
    t['Gamma'] = 0x0393;
    t['Gammaafrican'] = 0x0194;
    t['Gangiacoptic'] = 0x03EA;
    t['Gbreve'] = 0x011E;
    t['Gcaron'] = 0x01E6;
    t['Gcedilla'] = 0x0122;
    t['Gcircle'] = 0x24BC;
    t['Gcircumflex'] = 0x011C;
    t['Gcommaaccent'] = 0x0122;
    t['Gdot'] = 0x0120;
    t['Gdotaccent'] = 0x0120;
    t['Gecyrillic'] = 0x0413;
    t['Ghadarmenian'] = 0x0542;
    t['Ghemiddlehookcyrillic'] = 0x0494;
    t['Ghestrokecyrillic'] = 0x0492;
    t['Gheupturncyrillic'] = 0x0490;
    t['Ghook'] = 0x0193;
    t['Gimarmenian'] = 0x0533;
    t['Gjecyrillic'] = 0x0403;
    t['Gmacron'] = 0x1E20;
    t['Gmonospace'] = 0xFF27;
    t['Grave'] = 0xF6CE;
    t['Gravesmall'] = 0xF760;
    t['Gsmall'] = 0xF767;
    t['Gsmallhook'] = 0x029B;
    t['Gstroke'] = 0x01E4;
    t['H'] = 0x0048;
    t['H18533'] = 0x25CF;
    t['H18543'] = 0x25AA;
    t['H18551'] = 0x25AB;
    t['H22073'] = 0x25A1;
    t['HPsquare'] = 0x33CB;
    t['Haabkhasiancyrillic'] = 0x04A8;
    t['Hadescendercyrillic'] = 0x04B2;
    t['Hardsigncyrillic'] = 0x042A;
    t['Hbar'] = 0x0126;
    t['Hbrevebelow'] = 0x1E2A;
    t['Hcedilla'] = 0x1E28;
    t['Hcircle'] = 0x24BD;
    t['Hcircumflex'] = 0x0124;
    t['Hdieresis'] = 0x1E26;
    t['Hdotaccent'] = 0x1E22;
    t['Hdotbelow'] = 0x1E24;
    t['Hmonospace'] = 0xFF28;
    t['Hoarmenian'] = 0x0540;
    t['Horicoptic'] = 0x03E8;
    t['Hsmall'] = 0xF768;
    t['Hungarumlaut'] = 0xF6CF;
    t['Hungarumlautsmall'] = 0xF6F8;
    t['Hzsquare'] = 0x3390;
    t['I'] = 0x0049;
    t['IAcyrillic'] = 0x042F;
    t['IJ'] = 0x0132;
    t['IUcyrillic'] = 0x042E;
    t['Iacute'] = 0x00CD;
    t['Iacutesmall'] = 0xF7ED;
    t['Ibreve'] = 0x012C;
    t['Icaron'] = 0x01CF;
    t['Icircle'] = 0x24BE;
    t['Icircumflex'] = 0x00CE;
    t['Icircumflexsmall'] = 0xF7EE;
    t['Icyrillic'] = 0x0406;
    t['Idblgrave'] = 0x0208;
    t['Idieresis'] = 0x00CF;
    t['Idieresisacute'] = 0x1E2E;
    t['Idieresiscyrillic'] = 0x04E4;
    t['Idieresissmall'] = 0xF7EF;
    t['Idot'] = 0x0130;
    t['Idotaccent'] = 0x0130;
    t['Idotbelow'] = 0x1ECA;
    t['Iebrevecyrillic'] = 0x04D6;
    t['Iecyrillic'] = 0x0415;
    t['Ifraktur'] = 0x2111;
    t['Igrave'] = 0x00CC;
    t['Igravesmall'] = 0xF7EC;
    t['Ihookabove'] = 0x1EC8;
    t['Iicyrillic'] = 0x0418;
    t['Iinvertedbreve'] = 0x020A;
    t['Iishortcyrillic'] = 0x0419;
    t['Imacron'] = 0x012A;
    t['Imacroncyrillic'] = 0x04E2;
    t['Imonospace'] = 0xFF29;
    t['Iniarmenian'] = 0x053B;
    t['Iocyrillic'] = 0x0401;
    t['Iogonek'] = 0x012E;
    t['Iota'] = 0x0399;
    t['Iotaafrican'] = 0x0196;
    t['Iotadieresis'] = 0x03AA;
    t['Iotatonos'] = 0x038A;
    t['Ismall'] = 0xF769;
    t['Istroke'] = 0x0197;
    t['Itilde'] = 0x0128;
    t['Itildebelow'] = 0x1E2C;
    t['Izhitsacyrillic'] = 0x0474;
    t['Izhitsadblgravecyrillic'] = 0x0476;
    t['J'] = 0x004A;
    t['Jaarmenian'] = 0x0541;
    t['Jcircle'] = 0x24BF;
    t['Jcircumflex'] = 0x0134;
    t['Jecyrillic'] = 0x0408;
    t['Jheharmenian'] = 0x054B;
    t['Jmonospace'] = 0xFF2A;
    t['Jsmall'] = 0xF76A;
    t['K'] = 0x004B;
    t['KBsquare'] = 0x3385;
    t['KKsquare'] = 0x33CD;
    t['Kabashkircyrillic'] = 0x04A0;
    t['Kacute'] = 0x1E30;
    t['Kacyrillic'] = 0x041A;
    t['Kadescendercyrillic'] = 0x049A;
    t['Kahookcyrillic'] = 0x04C3;
    t['Kappa'] = 0x039A;
    t['Kastrokecyrillic'] = 0x049E;
    t['Kaverticalstrokecyrillic'] = 0x049C;
    t['Kcaron'] = 0x01E8;
    t['Kcedilla'] = 0x0136;
    t['Kcircle'] = 0x24C0;
    t['Kcommaaccent'] = 0x0136;
    t['Kdotbelow'] = 0x1E32;
    t['Keharmenian'] = 0x0554;
    t['Kenarmenian'] = 0x053F;
    t['Khacyrillic'] = 0x0425;
    t['Kheicoptic'] = 0x03E6;
    t['Khook'] = 0x0198;
    t['Kjecyrillic'] = 0x040C;
    t['Klinebelow'] = 0x1E34;
    t['Kmonospace'] = 0xFF2B;
    t['Koppacyrillic'] = 0x0480;
    t['Koppagreek'] = 0x03DE;
    t['Ksicyrillic'] = 0x046E;
    t['Ksmall'] = 0xF76B;
    t['L'] = 0x004C;
    t['LJ'] = 0x01C7;
    t['LL'] = 0xF6BF;
    t['Lacute'] = 0x0139;
    t['Lambda'] = 0x039B;
    t['Lcaron'] = 0x013D;
    t['Lcedilla'] = 0x013B;
    t['Lcircle'] = 0x24C1;
    t['Lcircumflexbelow'] = 0x1E3C;
    t['Lcommaaccent'] = 0x013B;
    t['Ldot'] = 0x013F;
    t['Ldotaccent'] = 0x013F;
    t['Ldotbelow'] = 0x1E36;
    t['Ldotbelowmacron'] = 0x1E38;
    t['Liwnarmenian'] = 0x053C;
    t['Lj'] = 0x01C8;
    t['Ljecyrillic'] = 0x0409;
    t['Llinebelow'] = 0x1E3A;
    t['Lmonospace'] = 0xFF2C;
    t['Lslash'] = 0x0141;
    t['Lslashsmall'] = 0xF6F9;
    t['Lsmall'] = 0xF76C;
    t['M'] = 0x004D;
    t['MBsquare'] = 0x3386;
    t['Macron'] = 0xF6D0;
    t['Macronsmall'] = 0xF7AF;
    t['Macute'] = 0x1E3E;
    t['Mcircle'] = 0x24C2;
    t['Mdotaccent'] = 0x1E40;
    t['Mdotbelow'] = 0x1E42;
    t['Menarmenian'] = 0x0544;
    t['Mmonospace'] = 0xFF2D;
    t['Msmall'] = 0xF76D;
    t['Mturned'] = 0x019C;
    t['Mu'] = 0x039C;
    t['N'] = 0x004E;
    t['NJ'] = 0x01CA;
    t['Nacute'] = 0x0143;
    t['Ncaron'] = 0x0147;
    t['Ncedilla'] = 0x0145;
    t['Ncircle'] = 0x24C3;
    t['Ncircumflexbelow'] = 0x1E4A;
    t['Ncommaaccent'] = 0x0145;
    t['Ndotaccent'] = 0x1E44;
    t['Ndotbelow'] = 0x1E46;
    t['Nhookleft'] = 0x019D;
    t['Nineroman'] = 0x2168;
    t['Nj'] = 0x01CB;
    t['Njecyrillic'] = 0x040A;
    t['Nlinebelow'] = 0x1E48;
    t['Nmonospace'] = 0xFF2E;
    t['Nowarmenian'] = 0x0546;
    t['Nsmall'] = 0xF76E;
    t['Ntilde'] = 0x00D1;
    t['Ntildesmall'] = 0xF7F1;
    t['Nu'] = 0x039D;
    t['O'] = 0x004F;
    t['OE'] = 0x0152;
    t['OEsmall'] = 0xF6FA;
    t['Oacute'] = 0x00D3;
    t['Oacutesmall'] = 0xF7F3;
    t['Obarredcyrillic'] = 0x04E8;
    t['Obarreddieresiscyrillic'] = 0x04EA;
    t['Obreve'] = 0x014E;
    t['Ocaron'] = 0x01D1;
    t['Ocenteredtilde'] = 0x019F;
    t['Ocircle'] = 0x24C4;
    t['Ocircumflex'] = 0x00D4;
    t['Ocircumflexacute'] = 0x1ED0;
    t['Ocircumflexdotbelow'] = 0x1ED8;
    t['Ocircumflexgrave'] = 0x1ED2;
    t['Ocircumflexhookabove'] = 0x1ED4;
    t['Ocircumflexsmall'] = 0xF7F4;
    t['Ocircumflextilde'] = 0x1ED6;
    t['Ocyrillic'] = 0x041E;
    t['Odblacute'] = 0x0150;
    t['Odblgrave'] = 0x020C;
    t['Odieresis'] = 0x00D6;
    t['Odieresiscyrillic'] = 0x04E6;
    t['Odieresissmall'] = 0xF7F6;
    t['Odotbelow'] = 0x1ECC;
    t['Ogoneksmall'] = 0xF6FB;
    t['Ograve'] = 0x00D2;
    t['Ogravesmall'] = 0xF7F2;
    t['Oharmenian'] = 0x0555;
    t['Ohm'] = 0x2126;
    t['Ohookabove'] = 0x1ECE;
    t['Ohorn'] = 0x01A0;
    t['Ohornacute'] = 0x1EDA;
    t['Ohorndotbelow'] = 0x1EE2;
    t['Ohorngrave'] = 0x1EDC;
    t['Ohornhookabove'] = 0x1EDE;
    t['Ohorntilde'] = 0x1EE0;
    t['Ohungarumlaut'] = 0x0150;
    t['Oi'] = 0x01A2;
    t['Oinvertedbreve'] = 0x020E;
    t['Omacron'] = 0x014C;
    t['Omacronacute'] = 0x1E52;
    t['Omacrongrave'] = 0x1E50;
    t['Omega'] = 0x2126;
    t['Omegacyrillic'] = 0x0460;
    t['Omegagreek'] = 0x03A9;
    t['Omegaroundcyrillic'] = 0x047A;
    t['Omegatitlocyrillic'] = 0x047C;
    t['Omegatonos'] = 0x038F;
    t['Omicron'] = 0x039F;
    t['Omicrontonos'] = 0x038C;
    t['Omonospace'] = 0xFF2F;
    t['Oneroman'] = 0x2160;
    t['Oogonek'] = 0x01EA;
    t['Oogonekmacron'] = 0x01EC;
    t['Oopen'] = 0x0186;
    t['Oslash'] = 0x00D8;
    t['Oslashacute'] = 0x01FE;
    t['Oslashsmall'] = 0xF7F8;
    t['Osmall'] = 0xF76F;
    t['Ostrokeacute'] = 0x01FE;
    t['Otcyrillic'] = 0x047E;
    t['Otilde'] = 0x00D5;
    t['Otildeacute'] = 0x1E4C;
    t['Otildedieresis'] = 0x1E4E;
    t['Otildesmall'] = 0xF7F5;
    t['P'] = 0x0050;
    t['Pacute'] = 0x1E54;
    t['Pcircle'] = 0x24C5;
    t['Pdotaccent'] = 0x1E56;
    t['Pecyrillic'] = 0x041F;
    t['Peharmenian'] = 0x054A;
    t['Pemiddlehookcyrillic'] = 0x04A6;
    t['Phi'] = 0x03A6;
    t['Phook'] = 0x01A4;
    t['Pi'] = 0x03A0;
    t['Piwrarmenian'] = 0x0553;
    t['Pmonospace'] = 0xFF30;
    t['Psi'] = 0x03A8;
    t['Psicyrillic'] = 0x0470;
    t['Psmall'] = 0xF770;
    t['Q'] = 0x0051;
    t['Qcircle'] = 0x24C6;
    t['Qmonospace'] = 0xFF31;
    t['Qsmall'] = 0xF771;
    t['R'] = 0x0052;
    t['Raarmenian'] = 0x054C;
    t['Racute'] = 0x0154;
    t['Rcaron'] = 0x0158;
    t['Rcedilla'] = 0x0156;
    t['Rcircle'] = 0x24C7;
    t['Rcommaaccent'] = 0x0156;
    t['Rdblgrave'] = 0x0210;
    t['Rdotaccent'] = 0x1E58;
    t['Rdotbelow'] = 0x1E5A;
    t['Rdotbelowmacron'] = 0x1E5C;
    t['Reharmenian'] = 0x0550;
    t['Rfraktur'] = 0x211C;
    t['Rho'] = 0x03A1;
    t['Ringsmall'] = 0xF6FC;
    t['Rinvertedbreve'] = 0x0212;
    t['Rlinebelow'] = 0x1E5E;
    t['Rmonospace'] = 0xFF32;
    t['Rsmall'] = 0xF772;
    t['Rsmallinverted'] = 0x0281;
    t['Rsmallinvertedsuperior'] = 0x02B6;
    t['S'] = 0x0053;
    t['SF010000'] = 0x250C;
    t['SF020000'] = 0x2514;
    t['SF030000'] = 0x2510;
    t['SF040000'] = 0x2518;
    t['SF050000'] = 0x253C;
    t['SF060000'] = 0x252C;
    t['SF070000'] = 0x2534;
    t['SF080000'] = 0x251C;
    t['SF090000'] = 0x2524;
    t['SF100000'] = 0x2500;
    t['SF110000'] = 0x2502;
    t['SF190000'] = 0x2561;
    t['SF200000'] = 0x2562;
    t['SF210000'] = 0x2556;
    t['SF220000'] = 0x2555;
    t['SF230000'] = 0x2563;
    t['SF240000'] = 0x2551;
    t['SF250000'] = 0x2557;
    t['SF260000'] = 0x255D;
    t['SF270000'] = 0x255C;
    t['SF280000'] = 0x255B;
    t['SF360000'] = 0x255E;
    t['SF370000'] = 0x255F;
    t['SF380000'] = 0x255A;
    t['SF390000'] = 0x2554;
    t['SF400000'] = 0x2569;
    t['SF410000'] = 0x2566;
    t['SF420000'] = 0x2560;
    t['SF430000'] = 0x2550;
    t['SF440000'] = 0x256C;
    t['SF450000'] = 0x2567;
    t['SF460000'] = 0x2568;
    t['SF470000'] = 0x2564;
    t['SF480000'] = 0x2565;
    t['SF490000'] = 0x2559;
    t['SF500000'] = 0x2558;
    t['SF510000'] = 0x2552;
    t['SF520000'] = 0x2553;
    t['SF530000'] = 0x256B;
    t['SF540000'] = 0x256A;
    t['Sacute'] = 0x015A;
    t['Sacutedotaccent'] = 0x1E64;
    t['Sampigreek'] = 0x03E0;
    t['Scaron'] = 0x0160;
    t['Scarondotaccent'] = 0x1E66;
    t['Scaronsmall'] = 0xF6FD;
    t['Scedilla'] = 0x015E;
    t['Schwa'] = 0x018F;
    t['Schwacyrillic'] = 0x04D8;
    t['Schwadieresiscyrillic'] = 0x04DA;
    t['Scircle'] = 0x24C8;
    t['Scircumflex'] = 0x015C;
    t['Scommaaccent'] = 0x0218;
    t['Sdotaccent'] = 0x1E60;
    t['Sdotbelow'] = 0x1E62;
    t['Sdotbelowdotaccent'] = 0x1E68;
    t['Seharmenian'] = 0x054D;
    t['Sevenroman'] = 0x2166;
    t['Shaarmenian'] = 0x0547;
    t['Shacyrillic'] = 0x0428;
    t['Shchacyrillic'] = 0x0429;
    t['Sheicoptic'] = 0x03E2;
    t['Shhacyrillic'] = 0x04BA;
    t['Shimacoptic'] = 0x03EC;
    t['Sigma'] = 0x03A3;
    t['Sixroman'] = 0x2165;
    t['Smonospace'] = 0xFF33;
    t['Softsigncyrillic'] = 0x042C;
    t['Ssmall'] = 0xF773;
    t['Stigmagreek'] = 0x03DA;
    t['T'] = 0x0054;
    t['Tau'] = 0x03A4;
    t['Tbar'] = 0x0166;
    t['Tcaron'] = 0x0164;
    t['Tcedilla'] = 0x0162;
    t['Tcircle'] = 0x24C9;
    t['Tcircumflexbelow'] = 0x1E70;
    t['Tcommaaccent'] = 0x0162;
    t['Tdotaccent'] = 0x1E6A;
    t['Tdotbelow'] = 0x1E6C;
    t['Tecyrillic'] = 0x0422;
    t['Tedescendercyrillic'] = 0x04AC;
    t['Tenroman'] = 0x2169;
    t['Tetsecyrillic'] = 0x04B4;
    t['Theta'] = 0x0398;
    t['Thook'] = 0x01AC;
    t['Thorn'] = 0x00DE;
    t['Thornsmall'] = 0xF7FE;
    t['Threeroman'] = 0x2162;
    t['Tildesmall'] = 0xF6FE;
    t['Tiwnarmenian'] = 0x054F;
    t['Tlinebelow'] = 0x1E6E;
    t['Tmonospace'] = 0xFF34;
    t['Toarmenian'] = 0x0539;
    t['Tonefive'] = 0x01BC;
    t['Tonesix'] = 0x0184;
    t['Tonetwo'] = 0x01A7;
    t['Tretroflexhook'] = 0x01AE;
    t['Tsecyrillic'] = 0x0426;
    t['Tshecyrillic'] = 0x040B;
    t['Tsmall'] = 0xF774;
    t['Twelveroman'] = 0x216B;
    t['Tworoman'] = 0x2161;
    t['U'] = 0x0055;
    t['Uacute'] = 0x00DA;
    t['Uacutesmall'] = 0xF7FA;
    t['Ubreve'] = 0x016C;
    t['Ucaron'] = 0x01D3;
    t['Ucircle'] = 0x24CA;
    t['Ucircumflex'] = 0x00DB;
    t['Ucircumflexbelow'] = 0x1E76;
    t['Ucircumflexsmall'] = 0xF7FB;
    t['Ucyrillic'] = 0x0423;
    t['Udblacute'] = 0x0170;
    t['Udblgrave'] = 0x0214;
    t['Udieresis'] = 0x00DC;
    t['Udieresisacute'] = 0x01D7;
    t['Udieresisbelow'] = 0x1E72;
    t['Udieresiscaron'] = 0x01D9;
    t['Udieresiscyrillic'] = 0x04F0;
    t['Udieresisgrave'] = 0x01DB;
    t['Udieresismacron'] = 0x01D5;
    t['Udieresissmall'] = 0xF7FC;
    t['Udotbelow'] = 0x1EE4;
    t['Ugrave'] = 0x00D9;
    t['Ugravesmall'] = 0xF7F9;
    t['Uhookabove'] = 0x1EE6;
    t['Uhorn'] = 0x01AF;
    t['Uhornacute'] = 0x1EE8;
    t['Uhorndotbelow'] = 0x1EF0;
    t['Uhorngrave'] = 0x1EEA;
    t['Uhornhookabove'] = 0x1EEC;
    t['Uhorntilde'] = 0x1EEE;
    t['Uhungarumlaut'] = 0x0170;
    t['Uhungarumlautcyrillic'] = 0x04F2;
    t['Uinvertedbreve'] = 0x0216;
    t['Ukcyrillic'] = 0x0478;
    t['Umacron'] = 0x016A;
    t['Umacroncyrillic'] = 0x04EE;
    t['Umacrondieresis'] = 0x1E7A;
    t['Umonospace'] = 0xFF35;
    t['Uogonek'] = 0x0172;
    t['Upsilon'] = 0x03A5;
    t['Upsilon1'] = 0x03D2;
    t['Upsilonacutehooksymbolgreek'] = 0x03D3;
    t['Upsilonafrican'] = 0x01B1;
    t['Upsilondieresis'] = 0x03AB;
    t['Upsilondieresishooksymbolgreek'] = 0x03D4;
    t['Upsilonhooksymbol'] = 0x03D2;
    t['Upsilontonos'] = 0x038E;
    t['Uring'] = 0x016E;
    t['Ushortcyrillic'] = 0x040E;
    t['Usmall'] = 0xF775;
    t['Ustraightcyrillic'] = 0x04AE;
    t['Ustraightstrokecyrillic'] = 0x04B0;
    t['Utilde'] = 0x0168;
    t['Utildeacute'] = 0x1E78;
    t['Utildebelow'] = 0x1E74;
    t['V'] = 0x0056;
    t['Vcircle'] = 0x24CB;
    t['Vdotbelow'] = 0x1E7E;
    t['Vecyrillic'] = 0x0412;
    t['Vewarmenian'] = 0x054E;
    t['Vhook'] = 0x01B2;
    t['Vmonospace'] = 0xFF36;
    t['Voarmenian'] = 0x0548;
    t['Vsmall'] = 0xF776;
    t['Vtilde'] = 0x1E7C;
    t['W'] = 0x0057;
    t['Wacute'] = 0x1E82;
    t['Wcircle'] = 0x24CC;
    t['Wcircumflex'] = 0x0174;
    t['Wdieresis'] = 0x1E84;
    t['Wdotaccent'] = 0x1E86;
    t['Wdotbelow'] = 0x1E88;
    t['Wgrave'] = 0x1E80;
    t['Wmonospace'] = 0xFF37;
    t['Wsmall'] = 0xF777;
    t['X'] = 0x0058;
    t['Xcircle'] = 0x24CD;
    t['Xdieresis'] = 0x1E8C;
    t['Xdotaccent'] = 0x1E8A;
    t['Xeharmenian'] = 0x053D;
    t['Xi'] = 0x039E;
    t['Xmonospace'] = 0xFF38;
    t['Xsmall'] = 0xF778;
    t['Y'] = 0x0059;
    t['Yacute'] = 0x00DD;
    t['Yacutesmall'] = 0xF7FD;
    t['Yatcyrillic'] = 0x0462;
    t['Ycircle'] = 0x24CE;
    t['Ycircumflex'] = 0x0176;
    t['Ydieresis'] = 0x0178;
    t['Ydieresissmall'] = 0xF7FF;
    t['Ydotaccent'] = 0x1E8E;
    t['Ydotbelow'] = 0x1EF4;
    t['Yericyrillic'] = 0x042B;
    t['Yerudieresiscyrillic'] = 0x04F8;
    t['Ygrave'] = 0x1EF2;
    t['Yhook'] = 0x01B3;
    t['Yhookabove'] = 0x1EF6;
    t['Yiarmenian'] = 0x0545;
    t['Yicyrillic'] = 0x0407;
    t['Yiwnarmenian'] = 0x0552;
    t['Ymonospace'] = 0xFF39;
    t['Ysmall'] = 0xF779;
    t['Ytilde'] = 0x1EF8;
    t['Yusbigcyrillic'] = 0x046A;
    t['Yusbigiotifiedcyrillic'] = 0x046C;
    t['Yuslittlecyrillic'] = 0x0466;
    t['Yuslittleiotifiedcyrillic'] = 0x0468;
    t['Z'] = 0x005A;
    t['Zaarmenian'] = 0x0536;
    t['Zacute'] = 0x0179;
    t['Zcaron'] = 0x017D;
    t['Zcaronsmall'] = 0xF6FF;
    t['Zcircle'] = 0x24CF;
    t['Zcircumflex'] = 0x1E90;
    t['Zdot'] = 0x017B;
    t['Zdotaccent'] = 0x017B;
    t['Zdotbelow'] = 0x1E92;
    t['Zecyrillic'] = 0x0417;
    t['Zedescendercyrillic'] = 0x0498;
    t['Zedieresiscyrillic'] = 0x04DE;
    t['Zeta'] = 0x0396;
    t['Zhearmenian'] = 0x053A;
    t['Zhebrevecyrillic'] = 0x04C1;
    t['Zhecyrillic'] = 0x0416;
    t['Zhedescendercyrillic'] = 0x0496;
    t['Zhedieresiscyrillic'] = 0x04DC;
    t['Zlinebelow'] = 0x1E94;
    t['Zmonospace'] = 0xFF3A;
    t['Zsmall'] = 0xF77A;
    t['Zstroke'] = 0x01B5;
    t['a'] = 0x0061;
    t['aabengali'] = 0x0986;
    t['aacute'] = 0x00E1;
    t['aadeva'] = 0x0906;
    t['aagujarati'] = 0x0A86;
    t['aagurmukhi'] = 0x0A06;
    t['aamatragurmukhi'] = 0x0A3E;
    t['aarusquare'] = 0x3303;
    t['aavowelsignbengali'] = 0x09BE;
    t['aavowelsigndeva'] = 0x093E;
    t['aavowelsigngujarati'] = 0x0ABE;
    t['abbreviationmarkarmenian'] = 0x055F;
    t['abbreviationsigndeva'] = 0x0970;
    t['abengali'] = 0x0985;
    t['abopomofo'] = 0x311A;
    t['abreve'] = 0x0103;
    t['abreveacute'] = 0x1EAF;
    t['abrevecyrillic'] = 0x04D1;
    t['abrevedotbelow'] = 0x1EB7;
    t['abrevegrave'] = 0x1EB1;
    t['abrevehookabove'] = 0x1EB3;
    t['abrevetilde'] = 0x1EB5;
    t['acaron'] = 0x01CE;
    t['acircle'] = 0x24D0;
    t['acircumflex'] = 0x00E2;
    t['acircumflexacute'] = 0x1EA5;
    t['acircumflexdotbelow'] = 0x1EAD;
    t['acircumflexgrave'] = 0x1EA7;
    t['acircumflexhookabove'] = 0x1EA9;
    t['acircumflextilde'] = 0x1EAB;
    t['acute'] = 0x00B4;
    t['acutebelowcmb'] = 0x0317;
    t['acutecmb'] = 0x0301;
    t['acutecomb'] = 0x0301;
    t['acutedeva'] = 0x0954;
    t['acutelowmod'] = 0x02CF;
    t['acutetonecmb'] = 0x0341;
    t['acyrillic'] = 0x0430;
    t['adblgrave'] = 0x0201;
    t['addakgurmukhi'] = 0x0A71;
    t['adeva'] = 0x0905;
    t['adieresis'] = 0x00E4;
    t['adieresiscyrillic'] = 0x04D3;
    t['adieresismacron'] = 0x01DF;
    t['adotbelow'] = 0x1EA1;
    t['adotmacron'] = 0x01E1;
    t['ae'] = 0x00E6;
    t['aeacute'] = 0x01FD;
    t['aekorean'] = 0x3150;
    t['aemacron'] = 0x01E3;
    t['afii00208'] = 0x2015;
    t['afii08941'] = 0x20A4;
    t['afii10017'] = 0x0410;
    t['afii10018'] = 0x0411;
    t['afii10019'] = 0x0412;
    t['afii10020'] = 0x0413;
    t['afii10021'] = 0x0414;
    t['afii10022'] = 0x0415;
    t['afii10023'] = 0x0401;
    t['afii10024'] = 0x0416;
    t['afii10025'] = 0x0417;
    t['afii10026'] = 0x0418;
    t['afii10027'] = 0x0419;
    t['afii10028'] = 0x041A;
    t['afii10029'] = 0x041B;
    t['afii10030'] = 0x041C;
    t['afii10031'] = 0x041D;
    t['afii10032'] = 0x041E;
    t['afii10033'] = 0x041F;
    t['afii10034'] = 0x0420;
    t['afii10035'] = 0x0421;
    t['afii10036'] = 0x0422;
    t['afii10037'] = 0x0423;
    t['afii10038'] = 0x0424;
    t['afii10039'] = 0x0425;
    t['afii10040'] = 0x0426;
    t['afii10041'] = 0x0427;
    t['afii10042'] = 0x0428;
    t['afii10043'] = 0x0429;
    t['afii10044'] = 0x042A;
    t['afii10045'] = 0x042B;
    t['afii10046'] = 0x042C;
    t['afii10047'] = 0x042D;
    t['afii10048'] = 0x042E;
    t['afii10049'] = 0x042F;
    t['afii10050'] = 0x0490;
    t['afii10051'] = 0x0402;
    t['afii10052'] = 0x0403;
    t['afii10053'] = 0x0404;
    t['afii10054'] = 0x0405;
    t['afii10055'] = 0x0406;
    t['afii10056'] = 0x0407;
    t['afii10057'] = 0x0408;
    t['afii10058'] = 0x0409;
    t['afii10059'] = 0x040A;
    t['afii10060'] = 0x040B;
    t['afii10061'] = 0x040C;
    t['afii10062'] = 0x040E;
    t['afii10063'] = 0xF6C4;
    t['afii10064'] = 0xF6C5;
    t['afii10065'] = 0x0430;
    t['afii10066'] = 0x0431;
    t['afii10067'] = 0x0432;
    t['afii10068'] = 0x0433;
    t['afii10069'] = 0x0434;
    t['afii10070'] = 0x0435;
    t['afii10071'] = 0x0451;
    t['afii10072'] = 0x0436;
    t['afii10073'] = 0x0437;
    t['afii10074'] = 0x0438;
    t['afii10075'] = 0x0439;
    t['afii10076'] = 0x043A;
    t['afii10077'] = 0x043B;
    t['afii10078'] = 0x043C;
    t['afii10079'] = 0x043D;
    t['afii10080'] = 0x043E;
    t['afii10081'] = 0x043F;
    t['afii10082'] = 0x0440;
    t['afii10083'] = 0x0441;
    t['afii10084'] = 0x0442;
    t['afii10085'] = 0x0443;
    t['afii10086'] = 0x0444;
    t['afii10087'] = 0x0445;
    t['afii10088'] = 0x0446;
    t['afii10089'] = 0x0447;
    t['afii10090'] = 0x0448;
    t['afii10091'] = 0x0449;
    t['afii10092'] = 0x044A;
    t['afii10093'] = 0x044B;
    t['afii10094'] = 0x044C;
    t['afii10095'] = 0x044D;
    t['afii10096'] = 0x044E;
    t['afii10097'] = 0x044F;
    t['afii10098'] = 0x0491;
    t['afii10099'] = 0x0452;
    t['afii10100'] = 0x0453;
    t['afii10101'] = 0x0454;
    t['afii10102'] = 0x0455;
    t['afii10103'] = 0x0456;
    t['afii10104'] = 0x0457;
    t['afii10105'] = 0x0458;
    t['afii10106'] = 0x0459;
    t['afii10107'] = 0x045A;
    t['afii10108'] = 0x045B;
    t['afii10109'] = 0x045C;
    t['afii10110'] = 0x045E;
    t['afii10145'] = 0x040F;
    t['afii10146'] = 0x0462;
    t['afii10147'] = 0x0472;
    t['afii10148'] = 0x0474;
    t['afii10192'] = 0xF6C6;
    t['afii10193'] = 0x045F;
    t['afii10194'] = 0x0463;
    t['afii10195'] = 0x0473;
    t['afii10196'] = 0x0475;
    t['afii10831'] = 0xF6C7;
    t['afii10832'] = 0xF6C8;
    t['afii10846'] = 0x04D9;
    t['afii299'] = 0x200E;
    t['afii300'] = 0x200F;
    t['afii301'] = 0x200D;
    t['afii57381'] = 0x066A;
    t['afii57388'] = 0x060C;
    t['afii57392'] = 0x0660;
    t['afii57393'] = 0x0661;
    t['afii57394'] = 0x0662;
    t['afii57395'] = 0x0663;
    t['afii57396'] = 0x0664;
    t['afii57397'] = 0x0665;
    t['afii57398'] = 0x0666;
    t['afii57399'] = 0x0667;
    t['afii57400'] = 0x0668;
    t['afii57401'] = 0x0669;
    t['afii57403'] = 0x061B;
    t['afii57407'] = 0x061F;
    t['afii57409'] = 0x0621;
    t['afii57410'] = 0x0622;
    t['afii57411'] = 0x0623;
    t['afii57412'] = 0x0624;
    t['afii57413'] = 0x0625;
    t['afii57414'] = 0x0626;
    t['afii57415'] = 0x0627;
    t['afii57416'] = 0x0628;
    t['afii57417'] = 0x0629;
    t['afii57418'] = 0x062A;
    t['afii57419'] = 0x062B;
    t['afii57420'] = 0x062C;
    t['afii57421'] = 0x062D;
    t['afii57422'] = 0x062E;
    t['afii57423'] = 0x062F;
    t['afii57424'] = 0x0630;
    t['afii57425'] = 0x0631;
    t['afii57426'] = 0x0632;
    t['afii57427'] = 0x0633;
    t['afii57428'] = 0x0634;
    t['afii57429'] = 0x0635;
    t['afii57430'] = 0x0636;
    t['afii57431'] = 0x0637;
    t['afii57432'] = 0x0638;
    t['afii57433'] = 0x0639;
    t['afii57434'] = 0x063A;
    t['afii57440'] = 0x0640;
    t['afii57441'] = 0x0641;
    t['afii57442'] = 0x0642;
    t['afii57443'] = 0x0643;
    t['afii57444'] = 0x0644;
    t['afii57445'] = 0x0645;
    t['afii57446'] = 0x0646;
    t['afii57448'] = 0x0648;
    t['afii57449'] = 0x0649;
    t['afii57450'] = 0x064A;
    t['afii57451'] = 0x064B;
    t['afii57452'] = 0x064C;
    t['afii57453'] = 0x064D;
    t['afii57454'] = 0x064E;
    t['afii57455'] = 0x064F;
    t['afii57456'] = 0x0650;
    t['afii57457'] = 0x0651;
    t['afii57458'] = 0x0652;
    t['afii57470'] = 0x0647;
    t['afii57505'] = 0x06A4;
    t['afii57506'] = 0x067E;
    t['afii57507'] = 0x0686;
    t['afii57508'] = 0x0698;
    t['afii57509'] = 0x06AF;
    t['afii57511'] = 0x0679;
    t['afii57512'] = 0x0688;
    t['afii57513'] = 0x0691;
    t['afii57514'] = 0x06BA;
    t['afii57519'] = 0x06D2;
    t['afii57534'] = 0x06D5;
    t['afii57636'] = 0x20AA;
    t['afii57645'] = 0x05BE;
    t['afii57658'] = 0x05C3;
    t['afii57664'] = 0x05D0;
    t['afii57665'] = 0x05D1;
    t['afii57666'] = 0x05D2;
    t['afii57667'] = 0x05D3;
    t['afii57668'] = 0x05D4;
    t['afii57669'] = 0x05D5;
    t['afii57670'] = 0x05D6;
    t['afii57671'] = 0x05D7;
    t['afii57672'] = 0x05D8;
    t['afii57673'] = 0x05D9;
    t['afii57674'] = 0x05DA;
    t['afii57675'] = 0x05DB;
    t['afii57676'] = 0x05DC;
    t['afii57677'] = 0x05DD;
    t['afii57678'] = 0x05DE;
    t['afii57679'] = 0x05DF;
    t['afii57680'] = 0x05E0;
    t['afii57681'] = 0x05E1;
    t['afii57682'] = 0x05E2;
    t['afii57683'] = 0x05E3;
    t['afii57684'] = 0x05E4;
    t['afii57685'] = 0x05E5;
    t['afii57686'] = 0x05E6;
    t['afii57687'] = 0x05E7;
    t['afii57688'] = 0x05E8;
    t['afii57689'] = 0x05E9;
    t['afii57690'] = 0x05EA;
    t['afii57694'] = 0xFB2A;
    t['afii57695'] = 0xFB2B;
    t['afii57700'] = 0xFB4B;
    t['afii57705'] = 0xFB1F;
    t['afii57716'] = 0x05F0;
    t['afii57717'] = 0x05F1;
    t['afii57718'] = 0x05F2;
    t['afii57723'] = 0xFB35;
    t['afii57793'] = 0x05B4;
    t['afii57794'] = 0x05B5;
    t['afii57795'] = 0x05B6;
    t['afii57796'] = 0x05BB;
    t['afii57797'] = 0x05B8;
    t['afii57798'] = 0x05B7;
    t['afii57799'] = 0x05B0;
    t['afii57800'] = 0x05B2;
    t['afii57801'] = 0x05B1;
    t['afii57802'] = 0x05B3;
    t['afii57803'] = 0x05C2;
    t['afii57804'] = 0x05C1;
    t['afii57806'] = 0x05B9;
    t['afii57807'] = 0x05BC;
    t['afii57839'] = 0x05BD;
    t['afii57841'] = 0x05BF;
    t['afii57842'] = 0x05C0;
    t['afii57929'] = 0x02BC;
    t['afii61248'] = 0x2105;
    t['afii61289'] = 0x2113;
    t['afii61352'] = 0x2116;
    t['afii61573'] = 0x202C;
    t['afii61574'] = 0x202D;
    t['afii61575'] = 0x202E;
    t['afii61664'] = 0x200C;
    t['afii63167'] = 0x066D;
    t['afii64937'] = 0x02BD;
    t['agrave'] = 0x00E0;
    t['agujarati'] = 0x0A85;
    t['agurmukhi'] = 0x0A05;
    t['ahiragana'] = 0x3042;
    t['ahookabove'] = 0x1EA3;
    t['aibengali'] = 0x0990;
    t['aibopomofo'] = 0x311E;
    t['aideva'] = 0x0910;
    t['aiecyrillic'] = 0x04D5;
    t['aigujarati'] = 0x0A90;
    t['aigurmukhi'] = 0x0A10;
    t['aimatragurmukhi'] = 0x0A48;
    t['ainarabic'] = 0x0639;
    t['ainfinalarabic'] = 0xFECA;
    t['aininitialarabic'] = 0xFECB;
    t['ainmedialarabic'] = 0xFECC;
    t['ainvertedbreve'] = 0x0203;
    t['aivowelsignbengali'] = 0x09C8;
    t['aivowelsigndeva'] = 0x0948;
    t['aivowelsigngujarati'] = 0x0AC8;
    t['akatakana'] = 0x30A2;
    t['akatakanahalfwidth'] = 0xFF71;
    t['akorean'] = 0x314F;
    t['alef'] = 0x05D0;
    t['alefarabic'] = 0x0627;
    t['alefdageshhebrew'] = 0xFB30;
    t['aleffinalarabic'] = 0xFE8E;
    t['alefhamzaabovearabic'] = 0x0623;
    t['alefhamzaabovefinalarabic'] = 0xFE84;
    t['alefhamzabelowarabic'] = 0x0625;
    t['alefhamzabelowfinalarabic'] = 0xFE88;
    t['alefhebrew'] = 0x05D0;
    t['aleflamedhebrew'] = 0xFB4F;
    t['alefmaddaabovearabic'] = 0x0622;
    t['alefmaddaabovefinalarabic'] = 0xFE82;
    t['alefmaksuraarabic'] = 0x0649;
    t['alefmaksurafinalarabic'] = 0xFEF0;
    t['alefmaksurainitialarabic'] = 0xFEF3;
    t['alefmaksuramedialarabic'] = 0xFEF4;
    t['alefpatahhebrew'] = 0xFB2E;
    t['alefqamatshebrew'] = 0xFB2F;
    t['aleph'] = 0x2135;
    t['allequal'] = 0x224C;
    t['alpha'] = 0x03B1;
    t['alphatonos'] = 0x03AC;
    t['amacron'] = 0x0101;
    t['amonospace'] = 0xFF41;
    t['ampersand'] = 0x0026;
    t['ampersandmonospace'] = 0xFF06;
    t['ampersandsmall'] = 0xF726;
    t['amsquare'] = 0x33C2;
    t['anbopomofo'] = 0x3122;
    t['angbopomofo'] = 0x3124;
    t['angbracketleft'] = 0x3008;
    t['angbracketright'] = 0x3009;
    t['angkhankhuthai'] = 0x0E5A;
    t['angle'] = 0x2220;
    t['anglebracketleft'] = 0x3008;
    t['anglebracketleftvertical'] = 0xFE3F;
    t['anglebracketright'] = 0x3009;
    t['anglebracketrightvertical'] = 0xFE40;
    t['angleleft'] = 0x2329;
    t['angleright'] = 0x232A;
    t['angstrom'] = 0x212B;
    t['anoteleia'] = 0x0387;
    t['anudattadeva'] = 0x0952;
    t['anusvarabengali'] = 0x0982;
    t['anusvaradeva'] = 0x0902;
    t['anusvaragujarati'] = 0x0A82;
    t['aogonek'] = 0x0105;
    t['apaatosquare'] = 0x3300;
    t['aparen'] = 0x249C;
    t['apostrophearmenian'] = 0x055A;
    t['apostrophemod'] = 0x02BC;
    t['apple'] = 0xF8FF;
    t['approaches'] = 0x2250;
    t['approxequal'] = 0x2248;
    t['approxequalorimage'] = 0x2252;
    t['approximatelyequal'] = 0x2245;
    t['araeaekorean'] = 0x318E;
    t['araeakorean'] = 0x318D;
    t['arc'] = 0x2312;
    t['arighthalfring'] = 0x1E9A;
    t['aring'] = 0x00E5;
    t['aringacute'] = 0x01FB;
    t['aringbelow'] = 0x1E01;
    t['arrowboth'] = 0x2194;
    t['arrowdashdown'] = 0x21E3;
    t['arrowdashleft'] = 0x21E0;
    t['arrowdashright'] = 0x21E2;
    t['arrowdashup'] = 0x21E1;
    t['arrowdblboth'] = 0x21D4;
    t['arrowdbldown'] = 0x21D3;
    t['arrowdblleft'] = 0x21D0;
    t['arrowdblright'] = 0x21D2;
    t['arrowdblup'] = 0x21D1;
    t['arrowdown'] = 0x2193;
    t['arrowdownleft'] = 0x2199;
    t['arrowdownright'] = 0x2198;
    t['arrowdownwhite'] = 0x21E9;
    t['arrowheaddownmod'] = 0x02C5;
    t['arrowheadleftmod'] = 0x02C2;
    t['arrowheadrightmod'] = 0x02C3;
    t['arrowheadupmod'] = 0x02C4;
    t['arrowhorizex'] = 0xF8E7;
    t['arrowleft'] = 0x2190;
    t['arrowleftdbl'] = 0x21D0;
    t['arrowleftdblstroke'] = 0x21CD;
    t['arrowleftoverright'] = 0x21C6;
    t['arrowleftwhite'] = 0x21E6;
    t['arrowright'] = 0x2192;
    t['arrowrightdblstroke'] = 0x21CF;
    t['arrowrightheavy'] = 0x279E;
    t['arrowrightoverleft'] = 0x21C4;
    t['arrowrightwhite'] = 0x21E8;
    t['arrowtableft'] = 0x21E4;
    t['arrowtabright'] = 0x21E5;
    t['arrowup'] = 0x2191;
    t['arrowupdn'] = 0x2195;
    t['arrowupdnbse'] = 0x21A8;
    t['arrowupdownbase'] = 0x21A8;
    t['arrowupleft'] = 0x2196;
    t['arrowupleftofdown'] = 0x21C5;
    t['arrowupright'] = 0x2197;
    t['arrowupwhite'] = 0x21E7;
    t['arrowvertex'] = 0xF8E6;
    t['asciicircum'] = 0x005E;
    t['asciicircummonospace'] = 0xFF3E;
    t['asciitilde'] = 0x007E;
    t['asciitildemonospace'] = 0xFF5E;
    t['ascript'] = 0x0251;
    t['ascriptturned'] = 0x0252;
    t['asmallhiragana'] = 0x3041;
    t['asmallkatakana'] = 0x30A1;
    t['asmallkatakanahalfwidth'] = 0xFF67;
    t['asterisk'] = 0x002A;
    t['asteriskaltonearabic'] = 0x066D;
    t['asteriskarabic'] = 0x066D;
    t['asteriskmath'] = 0x2217;
    t['asteriskmonospace'] = 0xFF0A;
    t['asterisksmall'] = 0xFE61;
    t['asterism'] = 0x2042;
    t['asuperior'] = 0xF6E9;
    t['asymptoticallyequal'] = 0x2243;
    t['at'] = 0x0040;
    t['atilde'] = 0x00E3;
    t['atmonospace'] = 0xFF20;
    t['atsmall'] = 0xFE6B;
    t['aturned'] = 0x0250;
    t['aubengali'] = 0x0994;
    t['aubopomofo'] = 0x3120;
    t['audeva'] = 0x0914;
    t['augujarati'] = 0x0A94;
    t['augurmukhi'] = 0x0A14;
    t['aulengthmarkbengali'] = 0x09D7;
    t['aumatragurmukhi'] = 0x0A4C;
    t['auvowelsignbengali'] = 0x09CC;
    t['auvowelsigndeva'] = 0x094C;
    t['auvowelsigngujarati'] = 0x0ACC;
    t['avagrahadeva'] = 0x093D;
    t['aybarmenian'] = 0x0561;
    t['ayin'] = 0x05E2;
    t['ayinaltonehebrew'] = 0xFB20;
    t['ayinhebrew'] = 0x05E2;
    t['b'] = 0x0062;
    t['babengali'] = 0x09AC;
    t['backslash'] = 0x005C;
    t['backslashmonospace'] = 0xFF3C;
    t['badeva'] = 0x092C;
    t['bagujarati'] = 0x0AAC;
    t['bagurmukhi'] = 0x0A2C;
    t['bahiragana'] = 0x3070;
    t['bahtthai'] = 0x0E3F;
    t['bakatakana'] = 0x30D0;
    t['bar'] = 0x007C;
    t['barmonospace'] = 0xFF5C;
    t['bbopomofo'] = 0x3105;
    t['bcircle'] = 0x24D1;
    t['bdotaccent'] = 0x1E03;
    t['bdotbelow'] = 0x1E05;
    t['beamedsixteenthnotes'] = 0x266C;
    t['because'] = 0x2235;
    t['becyrillic'] = 0x0431;
    t['beharabic'] = 0x0628;
    t['behfinalarabic'] = 0xFE90;
    t['behinitialarabic'] = 0xFE91;
    t['behiragana'] = 0x3079;
    t['behmedialarabic'] = 0xFE92;
    t['behmeeminitialarabic'] = 0xFC9F;
    t['behmeemisolatedarabic'] = 0xFC08;
    t['behnoonfinalarabic'] = 0xFC6D;
    t['bekatakana'] = 0x30D9;
    t['benarmenian'] = 0x0562;
    t['bet'] = 0x05D1;
    t['beta'] = 0x03B2;
    t['betasymbolgreek'] = 0x03D0;
    t['betdagesh'] = 0xFB31;
    t['betdageshhebrew'] = 0xFB31;
    t['bethebrew'] = 0x05D1;
    t['betrafehebrew'] = 0xFB4C;
    t['bhabengali'] = 0x09AD;
    t['bhadeva'] = 0x092D;
    t['bhagujarati'] = 0x0AAD;
    t['bhagurmukhi'] = 0x0A2D;
    t['bhook'] = 0x0253;
    t['bihiragana'] = 0x3073;
    t['bikatakana'] = 0x30D3;
    t['bilabialclick'] = 0x0298;
    t['bindigurmukhi'] = 0x0A02;
    t['birusquare'] = 0x3331;
    t['blackcircle'] = 0x25CF;
    t['blackdiamond'] = 0x25C6;
    t['blackdownpointingtriangle'] = 0x25BC;
    t['blackleftpointingpointer'] = 0x25C4;
    t['blackleftpointingtriangle'] = 0x25C0;
    t['blacklenticularbracketleft'] = 0x3010;
    t['blacklenticularbracketleftvertical'] = 0xFE3B;
    t['blacklenticularbracketright'] = 0x3011;
    t['blacklenticularbracketrightvertical'] = 0xFE3C;
    t['blacklowerlefttriangle'] = 0x25E3;
    t['blacklowerrighttriangle'] = 0x25E2;
    t['blackrectangle'] = 0x25AC;
    t['blackrightpointingpointer'] = 0x25BA;
    t['blackrightpointingtriangle'] = 0x25B6;
    t['blacksmallsquare'] = 0x25AA;
    t['blacksmilingface'] = 0x263B;
    t['blacksquare'] = 0x25A0;
    t['blackstar'] = 0x2605;
    t['blackupperlefttriangle'] = 0x25E4;
    t['blackupperrighttriangle'] = 0x25E5;
    t['blackuppointingsmalltriangle'] = 0x25B4;
    t['blackuppointingtriangle'] = 0x25B2;
    t['blank'] = 0x2423;
    t['blinebelow'] = 0x1E07;
    t['block'] = 0x2588;
    t['bmonospace'] = 0xFF42;
    t['bobaimaithai'] = 0x0E1A;
    t['bohiragana'] = 0x307C;
    t['bokatakana'] = 0x30DC;
    t['bparen'] = 0x249D;
    t['bqsquare'] = 0x33C3;
    t['braceex'] = 0xF8F4;
    t['braceleft'] = 0x007B;
    t['braceleftbt'] = 0xF8F3;
    t['braceleftmid'] = 0xF8F2;
    t['braceleftmonospace'] = 0xFF5B;
    t['braceleftsmall'] = 0xFE5B;
    t['bracelefttp'] = 0xF8F1;
    t['braceleftvertical'] = 0xFE37;
    t['braceright'] = 0x007D;
    t['bracerightbt'] = 0xF8FE;
    t['bracerightmid'] = 0xF8FD;
    t['bracerightmonospace'] = 0xFF5D;
    t['bracerightsmall'] = 0xFE5C;
    t['bracerighttp'] = 0xF8FC;
    t['bracerightvertical'] = 0xFE38;
    t['bracketleft'] = 0x005B;
    t['bracketleftbt'] = 0xF8F0;
    t['bracketleftex'] = 0xF8EF;
    t['bracketleftmonospace'] = 0xFF3B;
    t['bracketlefttp'] = 0xF8EE;
    t['bracketright'] = 0x005D;
    t['bracketrightbt'] = 0xF8FB;
    t['bracketrightex'] = 0xF8FA;
    t['bracketrightmonospace'] = 0xFF3D;
    t['bracketrighttp'] = 0xF8F9;
    t['breve'] = 0x02D8;
    t['brevebelowcmb'] = 0x032E;
    t['brevecmb'] = 0x0306;
    t['breveinvertedbelowcmb'] = 0x032F;
    t['breveinvertedcmb'] = 0x0311;
    t['breveinverteddoublecmb'] = 0x0361;
    t['bridgebelowcmb'] = 0x032A;
    t['bridgeinvertedbelowcmb'] = 0x033A;
    t['brokenbar'] = 0x00A6;
    t['bstroke'] = 0x0180;
    t['bsuperior'] = 0xF6EA;
    t['btopbar'] = 0x0183;
    t['buhiragana'] = 0x3076;
    t['bukatakana'] = 0x30D6;
    t['bullet'] = 0x2022;
    t['bulletinverse'] = 0x25D8;
    t['bulletoperator'] = 0x2219;
    t['bullseye'] = 0x25CE;
    t['c'] = 0x0063;
    t['caarmenian'] = 0x056E;
    t['cabengali'] = 0x099A;
    t['cacute'] = 0x0107;
    t['cadeva'] = 0x091A;
    t['cagujarati'] = 0x0A9A;
    t['cagurmukhi'] = 0x0A1A;
    t['calsquare'] = 0x3388;
    t['candrabindubengali'] = 0x0981;
    t['candrabinducmb'] = 0x0310;
    t['candrabindudeva'] = 0x0901;
    t['candrabindugujarati'] = 0x0A81;
    t['capslock'] = 0x21EA;
    t['careof'] = 0x2105;
    t['caron'] = 0x02C7;
    t['caronbelowcmb'] = 0x032C;
    t['caroncmb'] = 0x030C;
    t['carriagereturn'] = 0x21B5;
    t['cbopomofo'] = 0x3118;
    t['ccaron'] = 0x010D;
    t['ccedilla'] = 0x00E7;
    t['ccedillaacute'] = 0x1E09;
    t['ccircle'] = 0x24D2;
    t['ccircumflex'] = 0x0109;
    t['ccurl'] = 0x0255;
    t['cdot'] = 0x010B;
    t['cdotaccent'] = 0x010B;
    t['cdsquare'] = 0x33C5;
    t['cedilla'] = 0x00B8;
    t['cedillacmb'] = 0x0327;
    t['cent'] = 0x00A2;
    t['centigrade'] = 0x2103;
    t['centinferior'] = 0xF6DF;
    t['centmonospace'] = 0xFFE0;
    t['centoldstyle'] = 0xF7A2;
    t['centsuperior'] = 0xF6E0;
    t['chaarmenian'] = 0x0579;
    t['chabengali'] = 0x099B;
    t['chadeva'] = 0x091B;
    t['chagujarati'] = 0x0A9B;
    t['chagurmukhi'] = 0x0A1B;
    t['chbopomofo'] = 0x3114;
    t['cheabkhasiancyrillic'] = 0x04BD;
    t['checkmark'] = 0x2713;
    t['checyrillic'] = 0x0447;
    t['chedescenderabkhasiancyrillic'] = 0x04BF;
    t['chedescendercyrillic'] = 0x04B7;
    t['chedieresiscyrillic'] = 0x04F5;
    t['cheharmenian'] = 0x0573;
    t['chekhakassiancyrillic'] = 0x04CC;
    t['cheverticalstrokecyrillic'] = 0x04B9;
    t['chi'] = 0x03C7;
    t['chieuchacirclekorean'] = 0x3277;
    t['chieuchaparenkorean'] = 0x3217;
    t['chieuchcirclekorean'] = 0x3269;
    t['chieuchkorean'] = 0x314A;
    t['chieuchparenkorean'] = 0x3209;
    t['chochangthai'] = 0x0E0A;
    t['chochanthai'] = 0x0E08;
    t['chochingthai'] = 0x0E09;
    t['chochoethai'] = 0x0E0C;
    t['chook'] = 0x0188;
    t['cieucacirclekorean'] = 0x3276;
    t['cieucaparenkorean'] = 0x3216;
    t['cieuccirclekorean'] = 0x3268;
    t['cieuckorean'] = 0x3148;
    t['cieucparenkorean'] = 0x3208;
    t['cieucuparenkorean'] = 0x321C;
    t['circle'] = 0x25CB;
    t['circlecopyrt'] = 0x00A9;
    t['circlemultiply'] = 0x2297;
    t['circleot'] = 0x2299;
    t['circleplus'] = 0x2295;
    t['circlepostalmark'] = 0x3036;
    t['circlewithlefthalfblack'] = 0x25D0;
    t['circlewithrighthalfblack'] = 0x25D1;
    t['circumflex'] = 0x02C6;
    t['circumflexbelowcmb'] = 0x032D;
    t['circumflexcmb'] = 0x0302;
    t['clear'] = 0x2327;
    t['clickalveolar'] = 0x01C2;
    t['clickdental'] = 0x01C0;
    t['clicklateral'] = 0x01C1;
    t['clickretroflex'] = 0x01C3;
    t['club'] = 0x2663;
    t['clubsuitblack'] = 0x2663;
    t['clubsuitwhite'] = 0x2667;
    t['cmcubedsquare'] = 0x33A4;
    t['cmonospace'] = 0xFF43;
    t['cmsquaredsquare'] = 0x33A0;
    t['coarmenian'] = 0x0581;
    t['colon'] = 0x003A;
    t['colonmonetary'] = 0x20A1;
    t['colonmonospace'] = 0xFF1A;
    t['colonsign'] = 0x20A1;
    t['colonsmall'] = 0xFE55;
    t['colontriangularhalfmod'] = 0x02D1;
    t['colontriangularmod'] = 0x02D0;
    t['comma'] = 0x002C;
    t['commaabovecmb'] = 0x0313;
    t['commaaboverightcmb'] = 0x0315;
    t['commaaccent'] = 0xF6C3;
    t['commaarabic'] = 0x060C;
    t['commaarmenian'] = 0x055D;
    t['commainferior'] = 0xF6E1;
    t['commamonospace'] = 0xFF0C;
    t['commareversedabovecmb'] = 0x0314;
    t['commareversedmod'] = 0x02BD;
    t['commasmall'] = 0xFE50;
    t['commasuperior'] = 0xF6E2;
    t['commaturnedabovecmb'] = 0x0312;
    t['commaturnedmod'] = 0x02BB;
    t['compass'] = 0x263C;
    t['congruent'] = 0x2245;
    t['contourintegral'] = 0x222E;
    t['control'] = 0x2303;
    t['controlACK'] = 0x0006;
    t['controlBEL'] = 0x0007;
    t['controlBS'] = 0x0008;
    t['controlCAN'] = 0x0018;
    t['controlCR'] = 0x000D;
    t['controlDC1'] = 0x0011;
    t['controlDC2'] = 0x0012;
    t['controlDC3'] = 0x0013;
    t['controlDC4'] = 0x0014;
    t['controlDEL'] = 0x007F;
    t['controlDLE'] = 0x0010;
    t['controlEM'] = 0x0019;
    t['controlENQ'] = 0x0005;
    t['controlEOT'] = 0x0004;
    t['controlESC'] = 0x001B;
    t['controlETB'] = 0x0017;
    t['controlETX'] = 0x0003;
    t['controlFF'] = 0x000C;
    t['controlFS'] = 0x001C;
    t['controlGS'] = 0x001D;
    t['controlHT'] = 0x0009;
    t['controlLF'] = 0x000A;
    t['controlNAK'] = 0x0015;
    t['controlNULL'] = 0x0000;
    t['controlRS'] = 0x001E;
    t['controlSI'] = 0x000F;
    t['controlSO'] = 0x000E;
    t['controlSOT'] = 0x0002;
    t['controlSTX'] = 0x0001;
    t['controlSUB'] = 0x001A;
    t['controlSYN'] = 0x0016;
    t['controlUS'] = 0x001F;
    t['controlVT'] = 0x000B;
    t['copyright'] = 0x00A9;
    t['copyrightsans'] = 0xF8E9;
    t['copyrightserif'] = 0xF6D9;
    t['cornerbracketleft'] = 0x300C;
    t['cornerbracketlefthalfwidth'] = 0xFF62;
    t['cornerbracketleftvertical'] = 0xFE41;
    t['cornerbracketright'] = 0x300D;
    t['cornerbracketrighthalfwidth'] = 0xFF63;
    t['cornerbracketrightvertical'] = 0xFE42;
    t['corporationsquare'] = 0x337F;
    t['cosquare'] = 0x33C7;
    t['coverkgsquare'] = 0x33C6;
    t['cparen'] = 0x249E;
    t['cruzeiro'] = 0x20A2;
    t['cstretched'] = 0x0297;
    t['curlyand'] = 0x22CF;
    t['curlyor'] = 0x22CE;
    t['currency'] = 0x00A4;
    t['cyrBreve'] = 0xF6D1;
    t['cyrFlex'] = 0xF6D2;
    t['cyrbreve'] = 0xF6D4;
    t['cyrflex'] = 0xF6D5;
    t['d'] = 0x0064;
    t['daarmenian'] = 0x0564;
    t['dabengali'] = 0x09A6;
    t['dadarabic'] = 0x0636;
    t['dadeva'] = 0x0926;
    t['dadfinalarabic'] = 0xFEBE;
    t['dadinitialarabic'] = 0xFEBF;
    t['dadmedialarabic'] = 0xFEC0;
    t['dagesh'] = 0x05BC;
    t['dageshhebrew'] = 0x05BC;
    t['dagger'] = 0x2020;
    t['daggerdbl'] = 0x2021;
    t['dagujarati'] = 0x0AA6;
    t['dagurmukhi'] = 0x0A26;
    t['dahiragana'] = 0x3060;
    t['dakatakana'] = 0x30C0;
    t['dalarabic'] = 0x062F;
    t['dalet'] = 0x05D3;
    t['daletdagesh'] = 0xFB33;
    t['daletdageshhebrew'] = 0xFB33;
    t['dalethebrew'] = 0x05D3;
    t['dalfinalarabic'] = 0xFEAA;
    t['dammaarabic'] = 0x064F;
    t['dammalowarabic'] = 0x064F;
    t['dammatanaltonearabic'] = 0x064C;
    t['dammatanarabic'] = 0x064C;
    t['danda'] = 0x0964;
    t['dargahebrew'] = 0x05A7;
    t['dargalefthebrew'] = 0x05A7;
    t['dasiapneumatacyrilliccmb'] = 0x0485;
    t['dblGrave'] = 0xF6D3;
    t['dblanglebracketleft'] = 0x300A;
    t['dblanglebracketleftvertical'] = 0xFE3D;
    t['dblanglebracketright'] = 0x300B;
    t['dblanglebracketrightvertical'] = 0xFE3E;
    t['dblarchinvertedbelowcmb'] = 0x032B;
    t['dblarrowleft'] = 0x21D4;
    t['dblarrowright'] = 0x21D2;
    t['dbldanda'] = 0x0965;
    t['dblgrave'] = 0xF6D6;
    t['dblgravecmb'] = 0x030F;
    t['dblintegral'] = 0x222C;
    t['dbllowline'] = 0x2017;
    t['dbllowlinecmb'] = 0x0333;
    t['dbloverlinecmb'] = 0x033F;
    t['dblprimemod'] = 0x02BA;
    t['dblverticalbar'] = 0x2016;
    t['dblverticallineabovecmb'] = 0x030E;
    t['dbopomofo'] = 0x3109;
    t['dbsquare'] = 0x33C8;
    t['dcaron'] = 0x010F;
    t['dcedilla'] = 0x1E11;
    t['dcircle'] = 0x24D3;
    t['dcircumflexbelow'] = 0x1E13;
    t['dcroat'] = 0x0111;
    t['ddabengali'] = 0x09A1;
    t['ddadeva'] = 0x0921;
    t['ddagujarati'] = 0x0AA1;
    t['ddagurmukhi'] = 0x0A21;
    t['ddalarabic'] = 0x0688;
    t['ddalfinalarabic'] = 0xFB89;
    t['dddhadeva'] = 0x095C;
    t['ddhabengali'] = 0x09A2;
    t['ddhadeva'] = 0x0922;
    t['ddhagujarati'] = 0x0AA2;
    t['ddhagurmukhi'] = 0x0A22;
    t['ddotaccent'] = 0x1E0B;
    t['ddotbelow'] = 0x1E0D;
    t['decimalseparatorarabic'] = 0x066B;
    t['decimalseparatorpersian'] = 0x066B;
    t['decyrillic'] = 0x0434;
    t['degree'] = 0x00B0;
    t['dehihebrew'] = 0x05AD;
    t['dehiragana'] = 0x3067;
    t['deicoptic'] = 0x03EF;
    t['dekatakana'] = 0x30C7;
    t['deleteleft'] = 0x232B;
    t['deleteright'] = 0x2326;
    t['delta'] = 0x03B4;
    t['deltaturned'] = 0x018D;
    t['denominatorminusonenumeratorbengali'] = 0x09F8;
    t['dezh'] = 0x02A4;
    t['dhabengali'] = 0x09A7;
    t['dhadeva'] = 0x0927;
    t['dhagujarati'] = 0x0AA7;
    t['dhagurmukhi'] = 0x0A27;
    t['dhook'] = 0x0257;
    t['dialytikatonos'] = 0x0385;
    t['dialytikatonoscmb'] = 0x0344;
    t['diamond'] = 0x2666;
    t['diamondsuitwhite'] = 0x2662;
    t['dieresis'] = 0x00A8;
    t['dieresisacute'] = 0xF6D7;
    t['dieresisbelowcmb'] = 0x0324;
    t['dieresiscmb'] = 0x0308;
    t['dieresisgrave'] = 0xF6D8;
    t['dieresistonos'] = 0x0385;
    t['dihiragana'] = 0x3062;
    t['dikatakana'] = 0x30C2;
    t['dittomark'] = 0x3003;
    t['divide'] = 0x00F7;
    t['divides'] = 0x2223;
    t['divisionslash'] = 0x2215;
    t['djecyrillic'] = 0x0452;
    t['dkshade'] = 0x2593;
    t['dlinebelow'] = 0x1E0F;
    t['dlsquare'] = 0x3397;
    t['dmacron'] = 0x0111;
    t['dmonospace'] = 0xFF44;
    t['dnblock'] = 0x2584;
    t['dochadathai'] = 0x0E0E;
    t['dodekthai'] = 0x0E14;
    t['dohiragana'] = 0x3069;
    t['dokatakana'] = 0x30C9;
    t['dollar'] = 0x0024;
    t['dollarinferior'] = 0xF6E3;
    t['dollarmonospace'] = 0xFF04;
    t['dollaroldstyle'] = 0xF724;
    t['dollarsmall'] = 0xFE69;
    t['dollarsuperior'] = 0xF6E4;
    t['dong'] = 0x20AB;
    t['dorusquare'] = 0x3326;
    t['dotaccent'] = 0x02D9;
    t['dotaccentcmb'] = 0x0307;
    t['dotbelowcmb'] = 0x0323;
    t['dotbelowcomb'] = 0x0323;
    t['dotkatakana'] = 0x30FB;
    t['dotlessi'] = 0x0131;
    t['dotlessj'] = 0xF6BE;
    t['dotlessjstrokehook'] = 0x0284;
    t['dotmath'] = 0x22C5;
    t['dottedcircle'] = 0x25CC;
    t['doubleyodpatah'] = 0xFB1F;
    t['doubleyodpatahhebrew'] = 0xFB1F;
    t['downtackbelowcmb'] = 0x031E;
    t['downtackmod'] = 0x02D5;
    t['dparen'] = 0x249F;
    t['dsuperior'] = 0xF6EB;
    t['dtail'] = 0x0256;
    t['dtopbar'] = 0x018C;
    t['duhiragana'] = 0x3065;
    t['dukatakana'] = 0x30C5;
    t['dz'] = 0x01F3;
    t['dzaltone'] = 0x02A3;
    t['dzcaron'] = 0x01C6;
    t['dzcurl'] = 0x02A5;
    t['dzeabkhasiancyrillic'] = 0x04E1;
    t['dzecyrillic'] = 0x0455;
    t['dzhecyrillic'] = 0x045F;
    t['e'] = 0x0065;
    t['eacute'] = 0x00E9;
    t['earth'] = 0x2641;
    t['ebengali'] = 0x098F;
    t['ebopomofo'] = 0x311C;
    t['ebreve'] = 0x0115;
    t['ecandradeva'] = 0x090D;
    t['ecandragujarati'] = 0x0A8D;
    t['ecandravowelsigndeva'] = 0x0945;
    t['ecandravowelsigngujarati'] = 0x0AC5;
    t['ecaron'] = 0x011B;
    t['ecedillabreve'] = 0x1E1D;
    t['echarmenian'] = 0x0565;
    t['echyiwnarmenian'] = 0x0587;
    t['ecircle'] = 0x24D4;
    t['ecircumflex'] = 0x00EA;
    t['ecircumflexacute'] = 0x1EBF;
    t['ecircumflexbelow'] = 0x1E19;
    t['ecircumflexdotbelow'] = 0x1EC7;
    t['ecircumflexgrave'] = 0x1EC1;
    t['ecircumflexhookabove'] = 0x1EC3;
    t['ecircumflextilde'] = 0x1EC5;
    t['ecyrillic'] = 0x0454;
    t['edblgrave'] = 0x0205;
    t['edeva'] = 0x090F;
    t['edieresis'] = 0x00EB;
    t['edot'] = 0x0117;
    t['edotaccent'] = 0x0117;
    t['edotbelow'] = 0x1EB9;
    t['eegurmukhi'] = 0x0A0F;
    t['eematragurmukhi'] = 0x0A47;
    t['efcyrillic'] = 0x0444;
    t['egrave'] = 0x00E8;
    t['egujarati'] = 0x0A8F;
    t['eharmenian'] = 0x0567;
    t['ehbopomofo'] = 0x311D;
    t['ehiragana'] = 0x3048;
    t['ehookabove'] = 0x1EBB;
    t['eibopomofo'] = 0x311F;
    t['eight'] = 0x0038;
    t['eightarabic'] = 0x0668;
    t['eightbengali'] = 0x09EE;
    t['eightcircle'] = 0x2467;
    t['eightcircleinversesansserif'] = 0x2791;
    t['eightdeva'] = 0x096E;
    t['eighteencircle'] = 0x2471;
    t['eighteenparen'] = 0x2485;
    t['eighteenperiod'] = 0x2499;
    t['eightgujarati'] = 0x0AEE;
    t['eightgurmukhi'] = 0x0A6E;
    t['eighthackarabic'] = 0x0668;
    t['eighthangzhou'] = 0x3028;
    t['eighthnotebeamed'] = 0x266B;
    t['eightideographicparen'] = 0x3227;
    t['eightinferior'] = 0x2088;
    t['eightmonospace'] = 0xFF18;
    t['eightoldstyle'] = 0xF738;
    t['eightparen'] = 0x247B;
    t['eightperiod'] = 0x248F;
    t['eightpersian'] = 0x06F8;
    t['eightroman'] = 0x2177;
    t['eightsuperior'] = 0x2078;
    t['eightthai'] = 0x0E58;
    t['einvertedbreve'] = 0x0207;
    t['eiotifiedcyrillic'] = 0x0465;
    t['ekatakana'] = 0x30A8;
    t['ekatakanahalfwidth'] = 0xFF74;
    t['ekonkargurmukhi'] = 0x0A74;
    t['ekorean'] = 0x3154;
    t['elcyrillic'] = 0x043B;
    t['element'] = 0x2208;
    t['elevencircle'] = 0x246A;
    t['elevenparen'] = 0x247E;
    t['elevenperiod'] = 0x2492;
    t['elevenroman'] = 0x217A;
    t['ellipsis'] = 0x2026;
    t['ellipsisvertical'] = 0x22EE;
    t['emacron'] = 0x0113;
    t['emacronacute'] = 0x1E17;
    t['emacrongrave'] = 0x1E15;
    t['emcyrillic'] = 0x043C;
    t['emdash'] = 0x2014;
    t['emdashvertical'] = 0xFE31;
    t['emonospace'] = 0xFF45;
    t['emphasismarkarmenian'] = 0x055B;
    t['emptyset'] = 0x2205;
    t['enbopomofo'] = 0x3123;
    t['encyrillic'] = 0x043D;
    t['endash'] = 0x2013;
    t['endashvertical'] = 0xFE32;
    t['endescendercyrillic'] = 0x04A3;
    t['eng'] = 0x014B;
    t['engbopomofo'] = 0x3125;
    t['enghecyrillic'] = 0x04A5;
    t['enhookcyrillic'] = 0x04C8;
    t['enspace'] = 0x2002;
    t['eogonek'] = 0x0119;
    t['eokorean'] = 0x3153;
    t['eopen'] = 0x025B;
    t['eopenclosed'] = 0x029A;
    t['eopenreversed'] = 0x025C;
    t['eopenreversedclosed'] = 0x025E;
    t['eopenreversedhook'] = 0x025D;
    t['eparen'] = 0x24A0;
    t['epsilon'] = 0x03B5;
    t['epsilontonos'] = 0x03AD;
    t['equal'] = 0x003D;
    t['equalmonospace'] = 0xFF1D;
    t['equalsmall'] = 0xFE66;
    t['equalsuperior'] = 0x207C;
    t['equivalence'] = 0x2261;
    t['erbopomofo'] = 0x3126;
    t['ercyrillic'] = 0x0440;
    t['ereversed'] = 0x0258;
    t['ereversedcyrillic'] = 0x044D;
    t['escyrillic'] = 0x0441;
    t['esdescendercyrillic'] = 0x04AB;
    t['esh'] = 0x0283;
    t['eshcurl'] = 0x0286;
    t['eshortdeva'] = 0x090E;
    t['eshortvowelsigndeva'] = 0x0946;
    t['eshreversedloop'] = 0x01AA;
    t['eshsquatreversed'] = 0x0285;
    t['esmallhiragana'] = 0x3047;
    t['esmallkatakana'] = 0x30A7;
    t['esmallkatakanahalfwidth'] = 0xFF6A;
    t['estimated'] = 0x212E;
    t['esuperior'] = 0xF6EC;
    t['eta'] = 0x03B7;
    t['etarmenian'] = 0x0568;
    t['etatonos'] = 0x03AE;
    t['eth'] = 0x00F0;
    t['etilde'] = 0x1EBD;
    t['etildebelow'] = 0x1E1B;
    t['etnahtafoukhhebrew'] = 0x0591;
    t['etnahtafoukhlefthebrew'] = 0x0591;
    t['etnahtahebrew'] = 0x0591;
    t['etnahtalefthebrew'] = 0x0591;
    t['eturned'] = 0x01DD;
    t['eukorean'] = 0x3161;
    t['euro'] = 0x20AC;
    t['evowelsignbengali'] = 0x09C7;
    t['evowelsigndeva'] = 0x0947;
    t['evowelsigngujarati'] = 0x0AC7;
    t['exclam'] = 0x0021;
    t['exclamarmenian'] = 0x055C;
    t['exclamdbl'] = 0x203C;
    t['exclamdown'] = 0x00A1;
    t['exclamdownsmall'] = 0xF7A1;
    t['exclammonospace'] = 0xFF01;
    t['exclamsmall'] = 0xF721;
    t['existential'] = 0x2203;
    t['ezh'] = 0x0292;
    t['ezhcaron'] = 0x01EF;
    t['ezhcurl'] = 0x0293;
    t['ezhreversed'] = 0x01B9;
    t['ezhtail'] = 0x01BA;
    t['f'] = 0x0066;
    t['fadeva'] = 0x095E;
    t['fagurmukhi'] = 0x0A5E;
    t['fahrenheit'] = 0x2109;
    t['fathaarabic'] = 0x064E;
    t['fathalowarabic'] = 0x064E;
    t['fathatanarabic'] = 0x064B;
    t['fbopomofo'] = 0x3108;
    t['fcircle'] = 0x24D5;
    t['fdotaccent'] = 0x1E1F;
    t['feharabic'] = 0x0641;
    t['feharmenian'] = 0x0586;
    t['fehfinalarabic'] = 0xFED2;
    t['fehinitialarabic'] = 0xFED3;
    t['fehmedialarabic'] = 0xFED4;
    t['feicoptic'] = 0x03E5;
    t['female'] = 0x2640;
    t['ff'] = 0xFB00;
    t['ffi'] = 0xFB03;
    t['ffl'] = 0xFB04;
    t['fi'] = 0xFB01;
    t['fifteencircle'] = 0x246E;
    t['fifteenparen'] = 0x2482;
    t['fifteenperiod'] = 0x2496;
    t['figuredash'] = 0x2012;
    t['filledbox'] = 0x25A0;
    t['filledrect'] = 0x25AC;
    t['finalkaf'] = 0x05DA;
    t['finalkafdagesh'] = 0xFB3A;
    t['finalkafdageshhebrew'] = 0xFB3A;
    t['finalkafhebrew'] = 0x05DA;
    t['finalmem'] = 0x05DD;
    t['finalmemhebrew'] = 0x05DD;
    t['finalnun'] = 0x05DF;
    t['finalnunhebrew'] = 0x05DF;
    t['finalpe'] = 0x05E3;
    t['finalpehebrew'] = 0x05E3;
    t['finaltsadi'] = 0x05E5;
    t['finaltsadihebrew'] = 0x05E5;
    t['firsttonechinese'] = 0x02C9;
    t['fisheye'] = 0x25C9;
    t['fitacyrillic'] = 0x0473;
    t['five'] = 0x0035;
    t['fivearabic'] = 0x0665;
    t['fivebengali'] = 0x09EB;
    t['fivecircle'] = 0x2464;
    t['fivecircleinversesansserif'] = 0x278E;
    t['fivedeva'] = 0x096B;
    t['fiveeighths'] = 0x215D;
    t['fivegujarati'] = 0x0AEB;
    t['fivegurmukhi'] = 0x0A6B;
    t['fivehackarabic'] = 0x0665;
    t['fivehangzhou'] = 0x3025;
    t['fiveideographicparen'] = 0x3224;
    t['fiveinferior'] = 0x2085;
    t['fivemonospace'] = 0xFF15;
    t['fiveoldstyle'] = 0xF735;
    t['fiveparen'] = 0x2478;
    t['fiveperiod'] = 0x248C;
    t['fivepersian'] = 0x06F5;
    t['fiveroman'] = 0x2174;
    t['fivesuperior'] = 0x2075;
    t['fivethai'] = 0x0E55;
    t['fl'] = 0xFB02;
    t['florin'] = 0x0192;
    t['fmonospace'] = 0xFF46;
    t['fmsquare'] = 0x3399;
    t['fofanthai'] = 0x0E1F;
    t['fofathai'] = 0x0E1D;
    t['fongmanthai'] = 0x0E4F;
    t['forall'] = 0x2200;
    t['four'] = 0x0034;
    t['fourarabic'] = 0x0664;
    t['fourbengali'] = 0x09EA;
    t['fourcircle'] = 0x2463;
    t['fourcircleinversesansserif'] = 0x278D;
    t['fourdeva'] = 0x096A;
    t['fourgujarati'] = 0x0AEA;
    t['fourgurmukhi'] = 0x0A6A;
    t['fourhackarabic'] = 0x0664;
    t['fourhangzhou'] = 0x3024;
    t['fourideographicparen'] = 0x3223;
    t['fourinferior'] = 0x2084;
    t['fourmonospace'] = 0xFF14;
    t['fournumeratorbengali'] = 0x09F7;
    t['fouroldstyle'] = 0xF734;
    t['fourparen'] = 0x2477;
    t['fourperiod'] = 0x248B;
    t['fourpersian'] = 0x06F4;
    t['fourroman'] = 0x2173;
    t['foursuperior'] = 0x2074;
    t['fourteencircle'] = 0x246D;
    t['fourteenparen'] = 0x2481;
    t['fourteenperiod'] = 0x2495;
    t['fourthai'] = 0x0E54;
    t['fourthtonechinese'] = 0x02CB;
    t['fparen'] = 0x24A1;
    t['fraction'] = 0x2044;
    t['franc'] = 0x20A3;
    t['g'] = 0x0067;
    t['gabengali'] = 0x0997;
    t['gacute'] = 0x01F5;
    t['gadeva'] = 0x0917;
    t['gafarabic'] = 0x06AF;
    t['gaffinalarabic'] = 0xFB93;
    t['gafinitialarabic'] = 0xFB94;
    t['gafmedialarabic'] = 0xFB95;
    t['gagujarati'] = 0x0A97;
    t['gagurmukhi'] = 0x0A17;
    t['gahiragana'] = 0x304C;
    t['gakatakana'] = 0x30AC;
    t['gamma'] = 0x03B3;
    t['gammalatinsmall'] = 0x0263;
    t['gammasuperior'] = 0x02E0;
    t['gangiacoptic'] = 0x03EB;
    t['gbopomofo'] = 0x310D;
    t['gbreve'] = 0x011F;
    t['gcaron'] = 0x01E7;
    t['gcedilla'] = 0x0123;
    t['gcircle'] = 0x24D6;
    t['gcircumflex'] = 0x011D;
    t['gcommaaccent'] = 0x0123;
    t['gdot'] = 0x0121;
    t['gdotaccent'] = 0x0121;
    t['gecyrillic'] = 0x0433;
    t['gehiragana'] = 0x3052;
    t['gekatakana'] = 0x30B2;
    t['geometricallyequal'] = 0x2251;
    t['gereshaccenthebrew'] = 0x059C;
    t['gereshhebrew'] = 0x05F3;
    t['gereshmuqdamhebrew'] = 0x059D;
    t['germandbls'] = 0x00DF;
    t['gershayimaccenthebrew'] = 0x059E;
    t['gershayimhebrew'] = 0x05F4;
    t['getamark'] = 0x3013;
    t['ghabengali'] = 0x0998;
    t['ghadarmenian'] = 0x0572;
    t['ghadeva'] = 0x0918;
    t['ghagujarati'] = 0x0A98;
    t['ghagurmukhi'] = 0x0A18;
    t['ghainarabic'] = 0x063A;
    t['ghainfinalarabic'] = 0xFECE;
    t['ghaininitialarabic'] = 0xFECF;
    t['ghainmedialarabic'] = 0xFED0;
    t['ghemiddlehookcyrillic'] = 0x0495;
    t['ghestrokecyrillic'] = 0x0493;
    t['gheupturncyrillic'] = 0x0491;
    t['ghhadeva'] = 0x095A;
    t['ghhagurmukhi'] = 0x0A5A;
    t['ghook'] = 0x0260;
    t['ghzsquare'] = 0x3393;
    t['gihiragana'] = 0x304E;
    t['gikatakana'] = 0x30AE;
    t['gimarmenian'] = 0x0563;
    t['gimel'] = 0x05D2;
    t['gimeldagesh'] = 0xFB32;
    t['gimeldageshhebrew'] = 0xFB32;
    t['gimelhebrew'] = 0x05D2;
    t['gjecyrillic'] = 0x0453;
    t['glottalinvertedstroke'] = 0x01BE;
    t['glottalstop'] = 0x0294;
    t['glottalstopinverted'] = 0x0296;
    t['glottalstopmod'] = 0x02C0;
    t['glottalstopreversed'] = 0x0295;
    t['glottalstopreversedmod'] = 0x02C1;
    t['glottalstopreversedsuperior'] = 0x02E4;
    t['glottalstopstroke'] = 0x02A1;
    t['glottalstopstrokereversed'] = 0x02A2;
    t['gmacron'] = 0x1E21;
    t['gmonospace'] = 0xFF47;
    t['gohiragana'] = 0x3054;
    t['gokatakana'] = 0x30B4;
    t['gparen'] = 0x24A2;
    t['gpasquare'] = 0x33AC;
    t['gradient'] = 0x2207;
    t['grave'] = 0x0060;
    t['gravebelowcmb'] = 0x0316;
    t['gravecmb'] = 0x0300;
    t['gravecomb'] = 0x0300;
    t['gravedeva'] = 0x0953;
    t['gravelowmod'] = 0x02CE;
    t['gravemonospace'] = 0xFF40;
    t['gravetonecmb'] = 0x0340;
    t['greater'] = 0x003E;
    t['greaterequal'] = 0x2265;
    t['greaterequalorless'] = 0x22DB;
    t['greatermonospace'] = 0xFF1E;
    t['greaterorequivalent'] = 0x2273;
    t['greaterorless'] = 0x2277;
    t['greateroverequal'] = 0x2267;
    t['greatersmall'] = 0xFE65;
    t['gscript'] = 0x0261;
    t['gstroke'] = 0x01E5;
    t['guhiragana'] = 0x3050;
    t['guillemotleft'] = 0x00AB;
    t['guillemotright'] = 0x00BB;
    t['guilsinglleft'] = 0x2039;
    t['guilsinglright'] = 0x203A;
    t['gukatakana'] = 0x30B0;
    t['guramusquare'] = 0x3318;
    t['gysquare'] = 0x33C9;
    t['h'] = 0x0068;
    t['haabkhasiancyrillic'] = 0x04A9;
    t['haaltonearabic'] = 0x06C1;
    t['habengali'] = 0x09B9;
    t['hadescendercyrillic'] = 0x04B3;
    t['hadeva'] = 0x0939;
    t['hagujarati'] = 0x0AB9;
    t['hagurmukhi'] = 0x0A39;
    t['haharabic'] = 0x062D;
    t['hahfinalarabic'] = 0xFEA2;
    t['hahinitialarabic'] = 0xFEA3;
    t['hahiragana'] = 0x306F;
    t['hahmedialarabic'] = 0xFEA4;
    t['haitusquare'] = 0x332A;
    t['hakatakana'] = 0x30CF;
    t['hakatakanahalfwidth'] = 0xFF8A;
    t['halantgurmukhi'] = 0x0A4D;
    t['hamzaarabic'] = 0x0621;
    t['hamzalowarabic'] = 0x0621;
    t['hangulfiller'] = 0x3164;
    t['hardsigncyrillic'] = 0x044A;
    t['harpoonleftbarbup'] = 0x21BC;
    t['harpoonrightbarbup'] = 0x21C0;
    t['hasquare'] = 0x33CA;
    t['hatafpatah'] = 0x05B2;
    t['hatafpatah16'] = 0x05B2;
    t['hatafpatah23'] = 0x05B2;
    t['hatafpatah2f'] = 0x05B2;
    t['hatafpatahhebrew'] = 0x05B2;
    t['hatafpatahnarrowhebrew'] = 0x05B2;
    t['hatafpatahquarterhebrew'] = 0x05B2;
    t['hatafpatahwidehebrew'] = 0x05B2;
    t['hatafqamats'] = 0x05B3;
    t['hatafqamats1b'] = 0x05B3;
    t['hatafqamats28'] = 0x05B3;
    t['hatafqamats34'] = 0x05B3;
    t['hatafqamatshebrew'] = 0x05B3;
    t['hatafqamatsnarrowhebrew'] = 0x05B3;
    t['hatafqamatsquarterhebrew'] = 0x05B3;
    t['hatafqamatswidehebrew'] = 0x05B3;
    t['hatafsegol'] = 0x05B1;
    t['hatafsegol17'] = 0x05B1;
    t['hatafsegol24'] = 0x05B1;
    t['hatafsegol30'] = 0x05B1;
    t['hatafsegolhebrew'] = 0x05B1;
    t['hatafsegolnarrowhebrew'] = 0x05B1;
    t['hatafsegolquarterhebrew'] = 0x05B1;
    t['hatafsegolwidehebrew'] = 0x05B1;
    t['hbar'] = 0x0127;
    t['hbopomofo'] = 0x310F;
    t['hbrevebelow'] = 0x1E2B;
    t['hcedilla'] = 0x1E29;
    t['hcircle'] = 0x24D7;
    t['hcircumflex'] = 0x0125;
    t['hdieresis'] = 0x1E27;
    t['hdotaccent'] = 0x1E23;
    t['hdotbelow'] = 0x1E25;
    t['he'] = 0x05D4;
    t['heart'] = 0x2665;
    t['heartsuitblack'] = 0x2665;
    t['heartsuitwhite'] = 0x2661;
    t['hedagesh'] = 0xFB34;
    t['hedageshhebrew'] = 0xFB34;
    t['hehaltonearabic'] = 0x06C1;
    t['heharabic'] = 0x0647;
    t['hehebrew'] = 0x05D4;
    t['hehfinalaltonearabic'] = 0xFBA7;
    t['hehfinalalttwoarabic'] = 0xFEEA;
    t['hehfinalarabic'] = 0xFEEA;
    t['hehhamzaabovefinalarabic'] = 0xFBA5;
    t['hehhamzaaboveisolatedarabic'] = 0xFBA4;
    t['hehinitialaltonearabic'] = 0xFBA8;
    t['hehinitialarabic'] = 0xFEEB;
    t['hehiragana'] = 0x3078;
    t['hehmedialaltonearabic'] = 0xFBA9;
    t['hehmedialarabic'] = 0xFEEC;
    t['heiseierasquare'] = 0x337B;
    t['hekatakana'] = 0x30D8;
    t['hekatakanahalfwidth'] = 0xFF8D;
    t['hekutaarusquare'] = 0x3336;
    t['henghook'] = 0x0267;
    t['herutusquare'] = 0x3339;
    t['het'] = 0x05D7;
    t['hethebrew'] = 0x05D7;
    t['hhook'] = 0x0266;
    t['hhooksuperior'] = 0x02B1;
    t['hieuhacirclekorean'] = 0x327B;
    t['hieuhaparenkorean'] = 0x321B;
    t['hieuhcirclekorean'] = 0x326D;
    t['hieuhkorean'] = 0x314E;
    t['hieuhparenkorean'] = 0x320D;
    t['hihiragana'] = 0x3072;
    t['hikatakana'] = 0x30D2;
    t['hikatakanahalfwidth'] = 0xFF8B;
    t['hiriq'] = 0x05B4;
    t['hiriq14'] = 0x05B4;
    t['hiriq21'] = 0x05B4;
    t['hiriq2d'] = 0x05B4;
    t['hiriqhebrew'] = 0x05B4;
    t['hiriqnarrowhebrew'] = 0x05B4;
    t['hiriqquarterhebrew'] = 0x05B4;
    t['hiriqwidehebrew'] = 0x05B4;
    t['hlinebelow'] = 0x1E96;
    t['hmonospace'] = 0xFF48;
    t['hoarmenian'] = 0x0570;
    t['hohipthai'] = 0x0E2B;
    t['hohiragana'] = 0x307B;
    t['hokatakana'] = 0x30DB;
    t['hokatakanahalfwidth'] = 0xFF8E;
    t['holam'] = 0x05B9;
    t['holam19'] = 0x05B9;
    t['holam26'] = 0x05B9;
    t['holam32'] = 0x05B9;
    t['holamhebrew'] = 0x05B9;
    t['holamnarrowhebrew'] = 0x05B9;
    t['holamquarterhebrew'] = 0x05B9;
    t['holamwidehebrew'] = 0x05B9;
    t['honokhukthai'] = 0x0E2E;
    t['hookabovecomb'] = 0x0309;
    t['hookcmb'] = 0x0309;
    t['hookpalatalizedbelowcmb'] = 0x0321;
    t['hookretroflexbelowcmb'] = 0x0322;
    t['hoonsquare'] = 0x3342;
    t['horicoptic'] = 0x03E9;
    t['horizontalbar'] = 0x2015;
    t['horncmb'] = 0x031B;
    t['hotsprings'] = 0x2668;
    t['house'] = 0x2302;
    t['hparen'] = 0x24A3;
    t['hsuperior'] = 0x02B0;
    t['hturned'] = 0x0265;
    t['huhiragana'] = 0x3075;
    t['huiitosquare'] = 0x3333;
    t['hukatakana'] = 0x30D5;
    t['hukatakanahalfwidth'] = 0xFF8C;
    t['hungarumlaut'] = 0x02DD;
    t['hungarumlautcmb'] = 0x030B;
    t['hv'] = 0x0195;
    t['hyphen'] = 0x002D;
    t['hypheninferior'] = 0xF6E5;
    t['hyphenmonospace'] = 0xFF0D;
    t['hyphensmall'] = 0xFE63;
    t['hyphensuperior'] = 0xF6E6;
    t['hyphentwo'] = 0x2010;
    t['i'] = 0x0069;
    t['iacute'] = 0x00ED;
    t['iacyrillic'] = 0x044F;
    t['ibengali'] = 0x0987;
    t['ibopomofo'] = 0x3127;
    t['ibreve'] = 0x012D;
    t['icaron'] = 0x01D0;
    t['icircle'] = 0x24D8;
    t['icircumflex'] = 0x00EE;
    t['icyrillic'] = 0x0456;
    t['idblgrave'] = 0x0209;
    t['ideographearthcircle'] = 0x328F;
    t['ideographfirecircle'] = 0x328B;
    t['ideographicallianceparen'] = 0x323F;
    t['ideographiccallparen'] = 0x323A;
    t['ideographiccentrecircle'] = 0x32A5;
    t['ideographicclose'] = 0x3006;
    t['ideographiccomma'] = 0x3001;
    t['ideographiccommaleft'] = 0xFF64;
    t['ideographiccongratulationparen'] = 0x3237;
    t['ideographiccorrectcircle'] = 0x32A3;
    t['ideographicearthparen'] = 0x322F;
    t['ideographicenterpriseparen'] = 0x323D;
    t['ideographicexcellentcircle'] = 0x329D;
    t['ideographicfestivalparen'] = 0x3240;
    t['ideographicfinancialcircle'] = 0x3296;
    t['ideographicfinancialparen'] = 0x3236;
    t['ideographicfireparen'] = 0x322B;
    t['ideographichaveparen'] = 0x3232;
    t['ideographichighcircle'] = 0x32A4;
    t['ideographiciterationmark'] = 0x3005;
    t['ideographiclaborcircle'] = 0x3298;
    t['ideographiclaborparen'] = 0x3238;
    t['ideographicleftcircle'] = 0x32A7;
    t['ideographiclowcircle'] = 0x32A6;
    t['ideographicmedicinecircle'] = 0x32A9;
    t['ideographicmetalparen'] = 0x322E;
    t['ideographicmoonparen'] = 0x322A;
    t['ideographicnameparen'] = 0x3234;
    t['ideographicperiod'] = 0x3002;
    t['ideographicprintcircle'] = 0x329E;
    t['ideographicreachparen'] = 0x3243;
    t['ideographicrepresentparen'] = 0x3239;
    t['ideographicresourceparen'] = 0x323E;
    t['ideographicrightcircle'] = 0x32A8;
    t['ideographicsecretcircle'] = 0x3299;
    t['ideographicselfparen'] = 0x3242;
    t['ideographicsocietyparen'] = 0x3233;
    t['ideographicspace'] = 0x3000;
    t['ideographicspecialparen'] = 0x3235;
    t['ideographicstockparen'] = 0x3231;
    t['ideographicstudyparen'] = 0x323B;
    t['ideographicsunparen'] = 0x3230;
    t['ideographicsuperviseparen'] = 0x323C;
    t['ideographicwaterparen'] = 0x322C;
    t['ideographicwoodparen'] = 0x322D;
    t['ideographiczero'] = 0x3007;
    t['ideographmetalcircle'] = 0x328E;
    t['ideographmooncircle'] = 0x328A;
    t['ideographnamecircle'] = 0x3294;
    t['ideographsuncircle'] = 0x3290;
    t['ideographwatercircle'] = 0x328C;
    t['ideographwoodcircle'] = 0x328D;
    t['ideva'] = 0x0907;
    t['idieresis'] = 0x00EF;
    t['idieresisacute'] = 0x1E2F;
    t['idieresiscyrillic'] = 0x04E5;
    t['idotbelow'] = 0x1ECB;
    t['iebrevecyrillic'] = 0x04D7;
    t['iecyrillic'] = 0x0435;
    t['ieungacirclekorean'] = 0x3275;
    t['ieungaparenkorean'] = 0x3215;
    t['ieungcirclekorean'] = 0x3267;
    t['ieungkorean'] = 0x3147;
    t['ieungparenkorean'] = 0x3207;
    t['igrave'] = 0x00EC;
    t['igujarati'] = 0x0A87;
    t['igurmukhi'] = 0x0A07;
    t['ihiragana'] = 0x3044;
    t['ihookabove'] = 0x1EC9;
    t['iibengali'] = 0x0988;
    t['iicyrillic'] = 0x0438;
    t['iideva'] = 0x0908;
    t['iigujarati'] = 0x0A88;
    t['iigurmukhi'] = 0x0A08;
    t['iimatragurmukhi'] = 0x0A40;
    t['iinvertedbreve'] = 0x020B;
    t['iishortcyrillic'] = 0x0439;
    t['iivowelsignbengali'] = 0x09C0;
    t['iivowelsigndeva'] = 0x0940;
    t['iivowelsigngujarati'] = 0x0AC0;
    t['ij'] = 0x0133;
    t['ikatakana'] = 0x30A4;
    t['ikatakanahalfwidth'] = 0xFF72;
    t['ikorean'] = 0x3163;
    t['ilde'] = 0x02DC;
    t['iluyhebrew'] = 0x05AC;
    t['imacron'] = 0x012B;
    t['imacroncyrillic'] = 0x04E3;
    t['imageorapproximatelyequal'] = 0x2253;
    t['imatragurmukhi'] = 0x0A3F;
    t['imonospace'] = 0xFF49;
    t['increment'] = 0x2206;
    t['infinity'] = 0x221E;
    t['iniarmenian'] = 0x056B;
    t['integral'] = 0x222B;
    t['integralbottom'] = 0x2321;
    t['integralbt'] = 0x2321;
    t['integralex'] = 0xF8F5;
    t['integraltop'] = 0x2320;
    t['integraltp'] = 0x2320;
    t['intersection'] = 0x2229;
    t['intisquare'] = 0x3305;
    t['invbullet'] = 0x25D8;
    t['invcircle'] = 0x25D9;
    t['invsmileface'] = 0x263B;
    t['iocyrillic'] = 0x0451;
    t['iogonek'] = 0x012F;
    t['iota'] = 0x03B9;
    t['iotadieresis'] = 0x03CA;
    t['iotadieresistonos'] = 0x0390;
    t['iotalatin'] = 0x0269;
    t['iotatonos'] = 0x03AF;
    t['iparen'] = 0x24A4;
    t['irigurmukhi'] = 0x0A72;
    t['ismallhiragana'] = 0x3043;
    t['ismallkatakana'] = 0x30A3;
    t['ismallkatakanahalfwidth'] = 0xFF68;
    t['issharbengali'] = 0x09FA;
    t['istroke'] = 0x0268;
    t['isuperior'] = 0xF6ED;
    t['iterationhiragana'] = 0x309D;
    t['iterationkatakana'] = 0x30FD;
    t['itilde'] = 0x0129;
    t['itildebelow'] = 0x1E2D;
    t['iubopomofo'] = 0x3129;
    t['iucyrillic'] = 0x044E;
    t['ivowelsignbengali'] = 0x09BF;
    t['ivowelsigndeva'] = 0x093F;
    t['ivowelsigngujarati'] = 0x0ABF;
    t['izhitsacyrillic'] = 0x0475;
    t['izhitsadblgravecyrillic'] = 0x0477;
    t['j'] = 0x006A;
    t['jaarmenian'] = 0x0571;
    t['jabengali'] = 0x099C;
    t['jadeva'] = 0x091C;
    t['jagujarati'] = 0x0A9C;
    t['jagurmukhi'] = 0x0A1C;
    t['jbopomofo'] = 0x3110;
    t['jcaron'] = 0x01F0;
    t['jcircle'] = 0x24D9;
    t['jcircumflex'] = 0x0135;
    t['jcrossedtail'] = 0x029D;
    t['jdotlessstroke'] = 0x025F;
    t['jecyrillic'] = 0x0458;
    t['jeemarabic'] = 0x062C;
    t['jeemfinalarabic'] = 0xFE9E;
    t['jeeminitialarabic'] = 0xFE9F;
    t['jeemmedialarabic'] = 0xFEA0;
    t['jeharabic'] = 0x0698;
    t['jehfinalarabic'] = 0xFB8B;
    t['jhabengali'] = 0x099D;
    t['jhadeva'] = 0x091D;
    t['jhagujarati'] = 0x0A9D;
    t['jhagurmukhi'] = 0x0A1D;
    t['jheharmenian'] = 0x057B;
    t['jis'] = 0x3004;
    t['jmonospace'] = 0xFF4A;
    t['jparen'] = 0x24A5;
    t['jsuperior'] = 0x02B2;
    t['k'] = 0x006B;
    t['kabashkircyrillic'] = 0x04A1;
    t['kabengali'] = 0x0995;
    t['kacute'] = 0x1E31;
    t['kacyrillic'] = 0x043A;
    t['kadescendercyrillic'] = 0x049B;
    t['kadeva'] = 0x0915;
    t['kaf'] = 0x05DB;
    t['kafarabic'] = 0x0643;
    t['kafdagesh'] = 0xFB3B;
    t['kafdageshhebrew'] = 0xFB3B;
    t['kaffinalarabic'] = 0xFEDA;
    t['kafhebrew'] = 0x05DB;
    t['kafinitialarabic'] = 0xFEDB;
    t['kafmedialarabic'] = 0xFEDC;
    t['kafrafehebrew'] = 0xFB4D;
    t['kagujarati'] = 0x0A95;
    t['kagurmukhi'] = 0x0A15;
    t['kahiragana'] = 0x304B;
    t['kahookcyrillic'] = 0x04C4;
    t['kakatakana'] = 0x30AB;
    t['kakatakanahalfwidth'] = 0xFF76;
    t['kappa'] = 0x03BA;
    t['kappasymbolgreek'] = 0x03F0;
    t['kapyeounmieumkorean'] = 0x3171;
    t['kapyeounphieuphkorean'] = 0x3184;
    t['kapyeounpieupkorean'] = 0x3178;
    t['kapyeounssangpieupkorean'] = 0x3179;
    t['karoriisquare'] = 0x330D;
    t['kashidaautoarabic'] = 0x0640;
    t['kashidaautonosidebearingarabic'] = 0x0640;
    t['kasmallkatakana'] = 0x30F5;
    t['kasquare'] = 0x3384;
    t['kasraarabic'] = 0x0650;
    t['kasratanarabic'] = 0x064D;
    t['kastrokecyrillic'] = 0x049F;
    t['katahiraprolongmarkhalfwidth'] = 0xFF70;
    t['kaverticalstrokecyrillic'] = 0x049D;
    t['kbopomofo'] = 0x310E;
    t['kcalsquare'] = 0x3389;
    t['kcaron'] = 0x01E9;
    t['kcedilla'] = 0x0137;
    t['kcircle'] = 0x24DA;
    t['kcommaaccent'] = 0x0137;
    t['kdotbelow'] = 0x1E33;
    t['keharmenian'] = 0x0584;
    t['kehiragana'] = 0x3051;
    t['kekatakana'] = 0x30B1;
    t['kekatakanahalfwidth'] = 0xFF79;
    t['kenarmenian'] = 0x056F;
    t['kesmallkatakana'] = 0x30F6;
    t['kgreenlandic'] = 0x0138;
    t['khabengali'] = 0x0996;
    t['khacyrillic'] = 0x0445;
    t['khadeva'] = 0x0916;
    t['khagujarati'] = 0x0A96;
    t['khagurmukhi'] = 0x0A16;
    t['khaharabic'] = 0x062E;
    t['khahfinalarabic'] = 0xFEA6;
    t['khahinitialarabic'] = 0xFEA7;
    t['khahmedialarabic'] = 0xFEA8;
    t['kheicoptic'] = 0x03E7;
    t['khhadeva'] = 0x0959;
    t['khhagurmukhi'] = 0x0A59;
    t['khieukhacirclekorean'] = 0x3278;
    t['khieukhaparenkorean'] = 0x3218;
    t['khieukhcirclekorean'] = 0x326A;
    t['khieukhkorean'] = 0x314B;
    t['khieukhparenkorean'] = 0x320A;
    t['khokhaithai'] = 0x0E02;
    t['khokhonthai'] = 0x0E05;
    t['khokhuatthai'] = 0x0E03;
    t['khokhwaithai'] = 0x0E04;
    t['khomutthai'] = 0x0E5B;
    t['khook'] = 0x0199;
    t['khorakhangthai'] = 0x0E06;
    t['khzsquare'] = 0x3391;
    t['kihiragana'] = 0x304D;
    t['kikatakana'] = 0x30AD;
    t['kikatakanahalfwidth'] = 0xFF77;
    t['kiroguramusquare'] = 0x3315;
    t['kiromeetorusquare'] = 0x3316;
    t['kirosquare'] = 0x3314;
    t['kiyeokacirclekorean'] = 0x326E;
    t['kiyeokaparenkorean'] = 0x320E;
    t['kiyeokcirclekorean'] = 0x3260;
    t['kiyeokkorean'] = 0x3131;
    t['kiyeokparenkorean'] = 0x3200;
    t['kiyeoksioskorean'] = 0x3133;
    t['kjecyrillic'] = 0x045C;
    t['klinebelow'] = 0x1E35;
    t['klsquare'] = 0x3398;
    t['kmcubedsquare'] = 0x33A6;
    t['kmonospace'] = 0xFF4B;
    t['kmsquaredsquare'] = 0x33A2;
    t['kohiragana'] = 0x3053;
    t['kohmsquare'] = 0x33C0;
    t['kokaithai'] = 0x0E01;
    t['kokatakana'] = 0x30B3;
    t['kokatakanahalfwidth'] = 0xFF7A;
    t['kooposquare'] = 0x331E;
    t['koppacyrillic'] = 0x0481;
    t['koreanstandardsymbol'] = 0x327F;
    t['koroniscmb'] = 0x0343;
    t['kparen'] = 0x24A6;
    t['kpasquare'] = 0x33AA;
    t['ksicyrillic'] = 0x046F;
    t['ktsquare'] = 0x33CF;
    t['kturned'] = 0x029E;
    t['kuhiragana'] = 0x304F;
    t['kukatakana'] = 0x30AF;
    t['kukatakanahalfwidth'] = 0xFF78;
    t['kvsquare'] = 0x33B8;
    t['kwsquare'] = 0x33BE;
    t['l'] = 0x006C;
    t['labengali'] = 0x09B2;
    t['lacute'] = 0x013A;
    t['ladeva'] = 0x0932;
    t['lagujarati'] = 0x0AB2;
    t['lagurmukhi'] = 0x0A32;
    t['lakkhangyaothai'] = 0x0E45;
    t['lamaleffinalarabic'] = 0xFEFC;
    t['lamalefhamzaabovefinalarabic'] = 0xFEF8;
    t['lamalefhamzaaboveisolatedarabic'] = 0xFEF7;
    t['lamalefhamzabelowfinalarabic'] = 0xFEFA;
    t['lamalefhamzabelowisolatedarabic'] = 0xFEF9;
    t['lamalefisolatedarabic'] = 0xFEFB;
    t['lamalefmaddaabovefinalarabic'] = 0xFEF6;
    t['lamalefmaddaaboveisolatedarabic'] = 0xFEF5;
    t['lamarabic'] = 0x0644;
    t['lambda'] = 0x03BB;
    t['lambdastroke'] = 0x019B;
    t['lamed'] = 0x05DC;
    t['lameddagesh'] = 0xFB3C;
    t['lameddageshhebrew'] = 0xFB3C;
    t['lamedhebrew'] = 0x05DC;
    t['lamfinalarabic'] = 0xFEDE;
    t['lamhahinitialarabic'] = 0xFCCA;
    t['laminitialarabic'] = 0xFEDF;
    t['lamjeeminitialarabic'] = 0xFCC9;
    t['lamkhahinitialarabic'] = 0xFCCB;
    t['lamlamhehisolatedarabic'] = 0xFDF2;
    t['lammedialarabic'] = 0xFEE0;
    t['lammeemhahinitialarabic'] = 0xFD88;
    t['lammeeminitialarabic'] = 0xFCCC;
    t['largecircle'] = 0x25EF;
    t['lbar'] = 0x019A;
    t['lbelt'] = 0x026C;
    t['lbopomofo'] = 0x310C;
    t['lcaron'] = 0x013E;
    t['lcedilla'] = 0x013C;
    t['lcircle'] = 0x24DB;
    t['lcircumflexbelow'] = 0x1E3D;
    t['lcommaaccent'] = 0x013C;
    t['ldot'] = 0x0140;
    t['ldotaccent'] = 0x0140;
    t['ldotbelow'] = 0x1E37;
    t['ldotbelowmacron'] = 0x1E39;
    t['leftangleabovecmb'] = 0x031A;
    t['lefttackbelowcmb'] = 0x0318;
    t['less'] = 0x003C;
    t['lessequal'] = 0x2264;
    t['lessequalorgreater'] = 0x22DA;
    t['lessmonospace'] = 0xFF1C;
    t['lessorequivalent'] = 0x2272;
    t['lessorgreater'] = 0x2276;
    t['lessoverequal'] = 0x2266;
    t['lesssmall'] = 0xFE64;
    t['lezh'] = 0x026E;
    t['lfblock'] = 0x258C;
    t['lhookretroflex'] = 0x026D;
    t['lira'] = 0x20A4;
    t['liwnarmenian'] = 0x056C;
    t['lj'] = 0x01C9;
    t['ljecyrillic'] = 0x0459;
    t['ll'] = 0xF6C0;
    t['lladeva'] = 0x0933;
    t['llagujarati'] = 0x0AB3;
    t['llinebelow'] = 0x1E3B;
    t['llladeva'] = 0x0934;
    t['llvocalicbengali'] = 0x09E1;
    t['llvocalicdeva'] = 0x0961;
    t['llvocalicvowelsignbengali'] = 0x09E3;
    t['llvocalicvowelsigndeva'] = 0x0963;
    t['lmiddletilde'] = 0x026B;
    t['lmonospace'] = 0xFF4C;
    t['lmsquare'] = 0x33D0;
    t['lochulathai'] = 0x0E2C;
    t['logicaland'] = 0x2227;
    t['logicalnot'] = 0x00AC;
    t['logicalnotreversed'] = 0x2310;
    t['logicalor'] = 0x2228;
    t['lolingthai'] = 0x0E25;
    t['longs'] = 0x017F;
    t['lowlinecenterline'] = 0xFE4E;
    t['lowlinecmb'] = 0x0332;
    t['lowlinedashed'] = 0xFE4D;
    t['lozenge'] = 0x25CA;
    t['lparen'] = 0x24A7;
    t['lslash'] = 0x0142;
    t['lsquare'] = 0x2113;
    t['lsuperior'] = 0xF6EE;
    t['ltshade'] = 0x2591;
    t['luthai'] = 0x0E26;
    t['lvocalicbengali'] = 0x098C;
    t['lvocalicdeva'] = 0x090C;
    t['lvocalicvowelsignbengali'] = 0x09E2;
    t['lvocalicvowelsigndeva'] = 0x0962;
    t['lxsquare'] = 0x33D3;
    t['m'] = 0x006D;
    t['mabengali'] = 0x09AE;
    t['macron'] = 0x00AF;
    t['macronbelowcmb'] = 0x0331;
    t['macroncmb'] = 0x0304;
    t['macronlowmod'] = 0x02CD;
    t['macronmonospace'] = 0xFFE3;
    t['macute'] = 0x1E3F;
    t['madeva'] = 0x092E;
    t['magujarati'] = 0x0AAE;
    t['magurmukhi'] = 0x0A2E;
    t['mahapakhhebrew'] = 0x05A4;
    t['mahapakhlefthebrew'] = 0x05A4;
    t['mahiragana'] = 0x307E;
    t['maichattawalowleftthai'] = 0xF895;
    t['maichattawalowrightthai'] = 0xF894;
    t['maichattawathai'] = 0x0E4B;
    t['maichattawaupperleftthai'] = 0xF893;
    t['maieklowleftthai'] = 0xF88C;
    t['maieklowrightthai'] = 0xF88B;
    t['maiekthai'] = 0x0E48;
    t['maiekupperleftthai'] = 0xF88A;
    t['maihanakatleftthai'] = 0xF884;
    t['maihanakatthai'] = 0x0E31;
    t['maitaikhuleftthai'] = 0xF889;
    t['maitaikhuthai'] = 0x0E47;
    t['maitholowleftthai'] = 0xF88F;
    t['maitholowrightthai'] = 0xF88E;
    t['maithothai'] = 0x0E49;
    t['maithoupperleftthai'] = 0xF88D;
    t['maitrilowleftthai'] = 0xF892;
    t['maitrilowrightthai'] = 0xF891;
    t['maitrithai'] = 0x0E4A;
    t['maitriupperleftthai'] = 0xF890;
    t['maiyamokthai'] = 0x0E46;
    t['makatakana'] = 0x30DE;
    t['makatakanahalfwidth'] = 0xFF8F;
    t['male'] = 0x2642;
    t['mansyonsquare'] = 0x3347;
    t['maqafhebrew'] = 0x05BE;
    t['mars'] = 0x2642;
    t['masoracirclehebrew'] = 0x05AF;
    t['masquare'] = 0x3383;
    t['mbopomofo'] = 0x3107;
    t['mbsquare'] = 0x33D4;
    t['mcircle'] = 0x24DC;
    t['mcubedsquare'] = 0x33A5;
    t['mdotaccent'] = 0x1E41;
    t['mdotbelow'] = 0x1E43;
    t['meemarabic'] = 0x0645;
    t['meemfinalarabic'] = 0xFEE2;
    t['meeminitialarabic'] = 0xFEE3;
    t['meemmedialarabic'] = 0xFEE4;
    t['meemmeeminitialarabic'] = 0xFCD1;
    t['meemmeemisolatedarabic'] = 0xFC48;
    t['meetorusquare'] = 0x334D;
    t['mehiragana'] = 0x3081;
    t['meizierasquare'] = 0x337E;
    t['mekatakana'] = 0x30E1;
    t['mekatakanahalfwidth'] = 0xFF92;
    t['mem'] = 0x05DE;
    t['memdagesh'] = 0xFB3E;
    t['memdageshhebrew'] = 0xFB3E;
    t['memhebrew'] = 0x05DE;
    t['menarmenian'] = 0x0574;
    t['merkhahebrew'] = 0x05A5;
    t['merkhakefulahebrew'] = 0x05A6;
    t['merkhakefulalefthebrew'] = 0x05A6;
    t['merkhalefthebrew'] = 0x05A5;
    t['mhook'] = 0x0271;
    t['mhzsquare'] = 0x3392;
    t['middledotkatakanahalfwidth'] = 0xFF65;
    t['middot'] = 0x00B7;
    t['mieumacirclekorean'] = 0x3272;
    t['mieumaparenkorean'] = 0x3212;
    t['mieumcirclekorean'] = 0x3264;
    t['mieumkorean'] = 0x3141;
    t['mieumpansioskorean'] = 0x3170;
    t['mieumparenkorean'] = 0x3204;
    t['mieumpieupkorean'] = 0x316E;
    t['mieumsioskorean'] = 0x316F;
    t['mihiragana'] = 0x307F;
    t['mikatakana'] = 0x30DF;
    t['mikatakanahalfwidth'] = 0xFF90;
    t['minus'] = 0x2212;
    t['minusbelowcmb'] = 0x0320;
    t['minuscircle'] = 0x2296;
    t['minusmod'] = 0x02D7;
    t['minusplus'] = 0x2213;
    t['minute'] = 0x2032;
    t['miribaarusquare'] = 0x334A;
    t['mirisquare'] = 0x3349;
    t['mlonglegturned'] = 0x0270;
    t['mlsquare'] = 0x3396;
    t['mmcubedsquare'] = 0x33A3;
    t['mmonospace'] = 0xFF4D;
    t['mmsquaredsquare'] = 0x339F;
    t['mohiragana'] = 0x3082;
    t['mohmsquare'] = 0x33C1;
    t['mokatakana'] = 0x30E2;
    t['mokatakanahalfwidth'] = 0xFF93;
    t['molsquare'] = 0x33D6;
    t['momathai'] = 0x0E21;
    t['moverssquare'] = 0x33A7;
    t['moverssquaredsquare'] = 0x33A8;
    t['mparen'] = 0x24A8;
    t['mpasquare'] = 0x33AB;
    t['mssquare'] = 0x33B3;
    t['msuperior'] = 0xF6EF;
    t['mturned'] = 0x026F;
    t['mu'] = 0x00B5;
    t['mu1'] = 0x00B5;
    t['muasquare'] = 0x3382;
    t['muchgreater'] = 0x226B;
    t['muchless'] = 0x226A;
    t['mufsquare'] = 0x338C;
    t['mugreek'] = 0x03BC;
    t['mugsquare'] = 0x338D;
    t['muhiragana'] = 0x3080;
    t['mukatakana'] = 0x30E0;
    t['mukatakanahalfwidth'] = 0xFF91;
    t['mulsquare'] = 0x3395;
    t['multiply'] = 0x00D7;
    t['mumsquare'] = 0x339B;
    t['munahhebrew'] = 0x05A3;
    t['munahlefthebrew'] = 0x05A3;
    t['musicalnote'] = 0x266A;
    t['musicalnotedbl'] = 0x266B;
    t['musicflatsign'] = 0x266D;
    t['musicsharpsign'] = 0x266F;
    t['mussquare'] = 0x33B2;
    t['muvsquare'] = 0x33B6;
    t['muwsquare'] = 0x33BC;
    t['mvmegasquare'] = 0x33B9;
    t['mvsquare'] = 0x33B7;
    t['mwmegasquare'] = 0x33BF;
    t['mwsquare'] = 0x33BD;
    t['n'] = 0x006E;
    t['nabengali'] = 0x09A8;
    t['nabla'] = 0x2207;
    t['nacute'] = 0x0144;
    t['nadeva'] = 0x0928;
    t['nagujarati'] = 0x0AA8;
    t['nagurmukhi'] = 0x0A28;
    t['nahiragana'] = 0x306A;
    t['nakatakana'] = 0x30CA;
    t['nakatakanahalfwidth'] = 0xFF85;
    t['napostrophe'] = 0x0149;
    t['nasquare'] = 0x3381;
    t['nbopomofo'] = 0x310B;
    t['nbspace'] = 0x00A0;
    t['ncaron'] = 0x0148;
    t['ncedilla'] = 0x0146;
    t['ncircle'] = 0x24DD;
    t['ncircumflexbelow'] = 0x1E4B;
    t['ncommaaccent'] = 0x0146;
    t['ndotaccent'] = 0x1E45;
    t['ndotbelow'] = 0x1E47;
    t['nehiragana'] = 0x306D;
    t['nekatakana'] = 0x30CD;
    t['nekatakanahalfwidth'] = 0xFF88;
    t['newsheqelsign'] = 0x20AA;
    t['nfsquare'] = 0x338B;
    t['ngabengali'] = 0x0999;
    t['ngadeva'] = 0x0919;
    t['ngagujarati'] = 0x0A99;
    t['ngagurmukhi'] = 0x0A19;
    t['ngonguthai'] = 0x0E07;
    t['nhiragana'] = 0x3093;
    t['nhookleft'] = 0x0272;
    t['nhookretroflex'] = 0x0273;
    t['nieunacirclekorean'] = 0x326F;
    t['nieunaparenkorean'] = 0x320F;
    t['nieuncieuckorean'] = 0x3135;
    t['nieuncirclekorean'] = 0x3261;
    t['nieunhieuhkorean'] = 0x3136;
    t['nieunkorean'] = 0x3134;
    t['nieunpansioskorean'] = 0x3168;
    t['nieunparenkorean'] = 0x3201;
    t['nieunsioskorean'] = 0x3167;
    t['nieuntikeutkorean'] = 0x3166;
    t['nihiragana'] = 0x306B;
    t['nikatakana'] = 0x30CB;
    t['nikatakanahalfwidth'] = 0xFF86;
    t['nikhahitleftthai'] = 0xF899;
    t['nikhahitthai'] = 0x0E4D;
    t['nine'] = 0x0039;
    t['ninearabic'] = 0x0669;
    t['ninebengali'] = 0x09EF;
    t['ninecircle'] = 0x2468;
    t['ninecircleinversesansserif'] = 0x2792;
    t['ninedeva'] = 0x096F;
    t['ninegujarati'] = 0x0AEF;
    t['ninegurmukhi'] = 0x0A6F;
    t['ninehackarabic'] = 0x0669;
    t['ninehangzhou'] = 0x3029;
    t['nineideographicparen'] = 0x3228;
    t['nineinferior'] = 0x2089;
    t['ninemonospace'] = 0xFF19;
    t['nineoldstyle'] = 0xF739;
    t['nineparen'] = 0x247C;
    t['nineperiod'] = 0x2490;
    t['ninepersian'] = 0x06F9;
    t['nineroman'] = 0x2178;
    t['ninesuperior'] = 0x2079;
    t['nineteencircle'] = 0x2472;
    t['nineteenparen'] = 0x2486;
    t['nineteenperiod'] = 0x249A;
    t['ninethai'] = 0x0E59;
    t['nj'] = 0x01CC;
    t['njecyrillic'] = 0x045A;
    t['nkatakana'] = 0x30F3;
    t['nkatakanahalfwidth'] = 0xFF9D;
    t['nlegrightlong'] = 0x019E;
    t['nlinebelow'] = 0x1E49;
    t['nmonospace'] = 0xFF4E;
    t['nmsquare'] = 0x339A;
    t['nnabengali'] = 0x09A3;
    t['nnadeva'] = 0x0923;
    t['nnagujarati'] = 0x0AA3;
    t['nnagurmukhi'] = 0x0A23;
    t['nnnadeva'] = 0x0929;
    t['nohiragana'] = 0x306E;
    t['nokatakana'] = 0x30CE;
    t['nokatakanahalfwidth'] = 0xFF89;
    t['nonbreakingspace'] = 0x00A0;
    t['nonenthai'] = 0x0E13;
    t['nonuthai'] = 0x0E19;
    t['noonarabic'] = 0x0646;
    t['noonfinalarabic'] = 0xFEE6;
    t['noonghunnaarabic'] = 0x06BA;
    t['noonghunnafinalarabic'] = 0xFB9F;
    t['nooninitialarabic'] = 0xFEE7;
    t['noonjeeminitialarabic'] = 0xFCD2;
    t['noonjeemisolatedarabic'] = 0xFC4B;
    t['noonmedialarabic'] = 0xFEE8;
    t['noonmeeminitialarabic'] = 0xFCD5;
    t['noonmeemisolatedarabic'] = 0xFC4E;
    t['noonnoonfinalarabic'] = 0xFC8D;
    t['notcontains'] = 0x220C;
    t['notelement'] = 0x2209;
    t['notelementof'] = 0x2209;
    t['notequal'] = 0x2260;
    t['notgreater'] = 0x226F;
    t['notgreaternorequal'] = 0x2271;
    t['notgreaternorless'] = 0x2279;
    t['notidentical'] = 0x2262;
    t['notless'] = 0x226E;
    t['notlessnorequal'] = 0x2270;
    t['notparallel'] = 0x2226;
    t['notprecedes'] = 0x2280;
    t['notsubset'] = 0x2284;
    t['notsucceeds'] = 0x2281;
    t['notsuperset'] = 0x2285;
    t['nowarmenian'] = 0x0576;
    t['nparen'] = 0x24A9;
    t['nssquare'] = 0x33B1;
    t['nsuperior'] = 0x207F;
    t['ntilde'] = 0x00F1;
    t['nu'] = 0x03BD;
    t['nuhiragana'] = 0x306C;
    t['nukatakana'] = 0x30CC;
    t['nukatakanahalfwidth'] = 0xFF87;
    t['nuktabengali'] = 0x09BC;
    t['nuktadeva'] = 0x093C;
    t['nuktagujarati'] = 0x0ABC;
    t['nuktagurmukhi'] = 0x0A3C;
    t['numbersign'] = 0x0023;
    t['numbersignmonospace'] = 0xFF03;
    t['numbersignsmall'] = 0xFE5F;
    t['numeralsigngreek'] = 0x0374;
    t['numeralsignlowergreek'] = 0x0375;
    t['numero'] = 0x2116;
    t['nun'] = 0x05E0;
    t['nundagesh'] = 0xFB40;
    t['nundageshhebrew'] = 0xFB40;
    t['nunhebrew'] = 0x05E0;
    t['nvsquare'] = 0x33B5;
    t['nwsquare'] = 0x33BB;
    t['nyabengali'] = 0x099E;
    t['nyadeva'] = 0x091E;
    t['nyagujarati'] = 0x0A9E;
    t['nyagurmukhi'] = 0x0A1E;
    t['o'] = 0x006F;
    t['oacute'] = 0x00F3;
    t['oangthai'] = 0x0E2D;
    t['obarred'] = 0x0275;
    t['obarredcyrillic'] = 0x04E9;
    t['obarreddieresiscyrillic'] = 0x04EB;
    t['obengali'] = 0x0993;
    t['obopomofo'] = 0x311B;
    t['obreve'] = 0x014F;
    t['ocandradeva'] = 0x0911;
    t['ocandragujarati'] = 0x0A91;
    t['ocandravowelsigndeva'] = 0x0949;
    t['ocandravowelsigngujarati'] = 0x0AC9;
    t['ocaron'] = 0x01D2;
    t['ocircle'] = 0x24DE;
    t['ocircumflex'] = 0x00F4;
    t['ocircumflexacute'] = 0x1ED1;
    t['ocircumflexdotbelow'] = 0x1ED9;
    t['ocircumflexgrave'] = 0x1ED3;
    t['ocircumflexhookabove'] = 0x1ED5;
    t['ocircumflextilde'] = 0x1ED7;
    t['ocyrillic'] = 0x043E;
    t['odblacute'] = 0x0151;
    t['odblgrave'] = 0x020D;
    t['odeva'] = 0x0913;
    t['odieresis'] = 0x00F6;
    t['odieresiscyrillic'] = 0x04E7;
    t['odotbelow'] = 0x1ECD;
    t['oe'] = 0x0153;
    t['oekorean'] = 0x315A;
    t['ogonek'] = 0x02DB;
    t['ogonekcmb'] = 0x0328;
    t['ograve'] = 0x00F2;
    t['ogujarati'] = 0x0A93;
    t['oharmenian'] = 0x0585;
    t['ohiragana'] = 0x304A;
    t['ohookabove'] = 0x1ECF;
    t['ohorn'] = 0x01A1;
    t['ohornacute'] = 0x1EDB;
    t['ohorndotbelow'] = 0x1EE3;
    t['ohorngrave'] = 0x1EDD;
    t['ohornhookabove'] = 0x1EDF;
    t['ohorntilde'] = 0x1EE1;
    t['ohungarumlaut'] = 0x0151;
    t['oi'] = 0x01A3;
    t['oinvertedbreve'] = 0x020F;
    t['okatakana'] = 0x30AA;
    t['okatakanahalfwidth'] = 0xFF75;
    t['okorean'] = 0x3157;
    t['olehebrew'] = 0x05AB;
    t['omacron'] = 0x014D;
    t['omacronacute'] = 0x1E53;
    t['omacrongrave'] = 0x1E51;
    t['omdeva'] = 0x0950;
    t['omega'] = 0x03C9;
    t['omega1'] = 0x03D6;
    t['omegacyrillic'] = 0x0461;
    t['omegalatinclosed'] = 0x0277;
    t['omegaroundcyrillic'] = 0x047B;
    t['omegatitlocyrillic'] = 0x047D;
    t['omegatonos'] = 0x03CE;
    t['omgujarati'] = 0x0AD0;
    t['omicron'] = 0x03BF;
    t['omicrontonos'] = 0x03CC;
    t['omonospace'] = 0xFF4F;
    t['one'] = 0x0031;
    t['onearabic'] = 0x0661;
    t['onebengali'] = 0x09E7;
    t['onecircle'] = 0x2460;
    t['onecircleinversesansserif'] = 0x278A;
    t['onedeva'] = 0x0967;
    t['onedotenleader'] = 0x2024;
    t['oneeighth'] = 0x215B;
    t['onefitted'] = 0xF6DC;
    t['onegujarati'] = 0x0AE7;
    t['onegurmukhi'] = 0x0A67;
    t['onehackarabic'] = 0x0661;
    t['onehalf'] = 0x00BD;
    t['onehangzhou'] = 0x3021;
    t['oneideographicparen'] = 0x3220;
    t['oneinferior'] = 0x2081;
    t['onemonospace'] = 0xFF11;
    t['onenumeratorbengali'] = 0x09F4;
    t['oneoldstyle'] = 0xF731;
    t['oneparen'] = 0x2474;
    t['oneperiod'] = 0x2488;
    t['onepersian'] = 0x06F1;
    t['onequarter'] = 0x00BC;
    t['oneroman'] = 0x2170;
    t['onesuperior'] = 0x00B9;
    t['onethai'] = 0x0E51;
    t['onethird'] = 0x2153;
    t['oogonek'] = 0x01EB;
    t['oogonekmacron'] = 0x01ED;
    t['oogurmukhi'] = 0x0A13;
    t['oomatragurmukhi'] = 0x0A4B;
    t['oopen'] = 0x0254;
    t['oparen'] = 0x24AA;
    t['openbullet'] = 0x25E6;
    t['option'] = 0x2325;
    t['ordfeminine'] = 0x00AA;
    t['ordmasculine'] = 0x00BA;
    t['orthogonal'] = 0x221F;
    t['oshortdeva'] = 0x0912;
    t['oshortvowelsigndeva'] = 0x094A;
    t['oslash'] = 0x00F8;
    t['oslashacute'] = 0x01FF;
    t['osmallhiragana'] = 0x3049;
    t['osmallkatakana'] = 0x30A9;
    t['osmallkatakanahalfwidth'] = 0xFF6B;
    t['ostrokeacute'] = 0x01FF;
    t['osuperior'] = 0xF6F0;
    t['otcyrillic'] = 0x047F;
    t['otilde'] = 0x00F5;
    t['otildeacute'] = 0x1E4D;
    t['otildedieresis'] = 0x1E4F;
    t['oubopomofo'] = 0x3121;
    t['overline'] = 0x203E;
    t['overlinecenterline'] = 0xFE4A;
    t['overlinecmb'] = 0x0305;
    t['overlinedashed'] = 0xFE49;
    t['overlinedblwavy'] = 0xFE4C;
    t['overlinewavy'] = 0xFE4B;
    t['overscore'] = 0x00AF;
    t['ovowelsignbengali'] = 0x09CB;
    t['ovowelsigndeva'] = 0x094B;
    t['ovowelsigngujarati'] = 0x0ACB;
    t['p'] = 0x0070;
    t['paampssquare'] = 0x3380;
    t['paasentosquare'] = 0x332B;
    t['pabengali'] = 0x09AA;
    t['pacute'] = 0x1E55;
    t['padeva'] = 0x092A;
    t['pagedown'] = 0x21DF;
    t['pageup'] = 0x21DE;
    t['pagujarati'] = 0x0AAA;
    t['pagurmukhi'] = 0x0A2A;
    t['pahiragana'] = 0x3071;
    t['paiyannoithai'] = 0x0E2F;
    t['pakatakana'] = 0x30D1;
    t['palatalizationcyrilliccmb'] = 0x0484;
    t['palochkacyrillic'] = 0x04C0;
    t['pansioskorean'] = 0x317F;
    t['paragraph'] = 0x00B6;
    t['parallel'] = 0x2225;
    t['parenleft'] = 0x0028;
    t['parenleftaltonearabic'] = 0xFD3E;
    t['parenleftbt'] = 0xF8ED;
    t['parenleftex'] = 0xF8EC;
    t['parenleftinferior'] = 0x208D;
    t['parenleftmonospace'] = 0xFF08;
    t['parenleftsmall'] = 0xFE59;
    t['parenleftsuperior'] = 0x207D;
    t['parenlefttp'] = 0xF8EB;
    t['parenleftvertical'] = 0xFE35;
    t['parenright'] = 0x0029;
    t['parenrightaltonearabic'] = 0xFD3F;
    t['parenrightbt'] = 0xF8F8;
    t['parenrightex'] = 0xF8F7;
    t['parenrightinferior'] = 0x208E;
    t['parenrightmonospace'] = 0xFF09;
    t['parenrightsmall'] = 0xFE5A;
    t['parenrightsuperior'] = 0x207E;
    t['parenrighttp'] = 0xF8F6;
    t['parenrightvertical'] = 0xFE36;
    t['partialdiff'] = 0x2202;
    t['paseqhebrew'] = 0x05C0;
    t['pashtahebrew'] = 0x0599;
    t['pasquare'] = 0x33A9;
    t['patah'] = 0x05B7;
    t['patah11'] = 0x05B7;
    t['patah1d'] = 0x05B7;
    t['patah2a'] = 0x05B7;
    t['patahhebrew'] = 0x05B7;
    t['patahnarrowhebrew'] = 0x05B7;
    t['patahquarterhebrew'] = 0x05B7;
    t['patahwidehebrew'] = 0x05B7;
    t['pazerhebrew'] = 0x05A1;
    t['pbopomofo'] = 0x3106;
    t['pcircle'] = 0x24DF;
    t['pdotaccent'] = 0x1E57;
    t['pe'] = 0x05E4;
    t['pecyrillic'] = 0x043F;
    t['pedagesh'] = 0xFB44;
    t['pedageshhebrew'] = 0xFB44;
    t['peezisquare'] = 0x333B;
    t['pefinaldageshhebrew'] = 0xFB43;
    t['peharabic'] = 0x067E;
    t['peharmenian'] = 0x057A;
    t['pehebrew'] = 0x05E4;
    t['pehfinalarabic'] = 0xFB57;
    t['pehinitialarabic'] = 0xFB58;
    t['pehiragana'] = 0x307A;
    t['pehmedialarabic'] = 0xFB59;
    t['pekatakana'] = 0x30DA;
    t['pemiddlehookcyrillic'] = 0x04A7;
    t['perafehebrew'] = 0xFB4E;
    t['percent'] = 0x0025;
    t['percentarabic'] = 0x066A;
    t['percentmonospace'] = 0xFF05;
    t['percentsmall'] = 0xFE6A;
    t['period'] = 0x002E;
    t['periodarmenian'] = 0x0589;
    t['periodcentered'] = 0x00B7;
    t['periodhalfwidth'] = 0xFF61;
    t['periodinferior'] = 0xF6E7;
    t['periodmonospace'] = 0xFF0E;
    t['periodsmall'] = 0xFE52;
    t['periodsuperior'] = 0xF6E8;
    t['perispomenigreekcmb'] = 0x0342;
    t['perpendicular'] = 0x22A5;
    t['perthousand'] = 0x2030;
    t['peseta'] = 0x20A7;
    t['pfsquare'] = 0x338A;
    t['phabengali'] = 0x09AB;
    t['phadeva'] = 0x092B;
    t['phagujarati'] = 0x0AAB;
    t['phagurmukhi'] = 0x0A2B;
    t['phi'] = 0x03C6;
    t['phi1'] = 0x03D5;
    t['phieuphacirclekorean'] = 0x327A;
    t['phieuphaparenkorean'] = 0x321A;
    t['phieuphcirclekorean'] = 0x326C;
    t['phieuphkorean'] = 0x314D;
    t['phieuphparenkorean'] = 0x320C;
    t['philatin'] = 0x0278;
    t['phinthuthai'] = 0x0E3A;
    t['phisymbolgreek'] = 0x03D5;
    t['phook'] = 0x01A5;
    t['phophanthai'] = 0x0E1E;
    t['phophungthai'] = 0x0E1C;
    t['phosamphaothai'] = 0x0E20;
    t['pi'] = 0x03C0;
    t['pieupacirclekorean'] = 0x3273;
    t['pieupaparenkorean'] = 0x3213;
    t['pieupcieuckorean'] = 0x3176;
    t['pieupcirclekorean'] = 0x3265;
    t['pieupkiyeokkorean'] = 0x3172;
    t['pieupkorean'] = 0x3142;
    t['pieupparenkorean'] = 0x3205;
    t['pieupsioskiyeokkorean'] = 0x3174;
    t['pieupsioskorean'] = 0x3144;
    t['pieupsiostikeutkorean'] = 0x3175;
    t['pieupthieuthkorean'] = 0x3177;
    t['pieuptikeutkorean'] = 0x3173;
    t['pihiragana'] = 0x3074;
    t['pikatakana'] = 0x30D4;
    t['pisymbolgreek'] = 0x03D6;
    t['piwrarmenian'] = 0x0583;
    t['plus'] = 0x002B;
    t['plusbelowcmb'] = 0x031F;
    t['pluscircle'] = 0x2295;
    t['plusminus'] = 0x00B1;
    t['plusmod'] = 0x02D6;
    t['plusmonospace'] = 0xFF0B;
    t['plussmall'] = 0xFE62;
    t['plussuperior'] = 0x207A;
    t['pmonospace'] = 0xFF50;
    t['pmsquare'] = 0x33D8;
    t['pohiragana'] = 0x307D;
    t['pointingindexdownwhite'] = 0x261F;
    t['pointingindexleftwhite'] = 0x261C;
    t['pointingindexrightwhite'] = 0x261E;
    t['pointingindexupwhite'] = 0x261D;
    t['pokatakana'] = 0x30DD;
    t['poplathai'] = 0x0E1B;
    t['postalmark'] = 0x3012;
    t['postalmarkface'] = 0x3020;
    t['pparen'] = 0x24AB;
    t['precedes'] = 0x227A;
    t['prescription'] = 0x211E;
    t['primemod'] = 0x02B9;
    t['primereversed'] = 0x2035;
    t['product'] = 0x220F;
    t['projective'] = 0x2305;
    t['prolongedkana'] = 0x30FC;
    t['propellor'] = 0x2318;
    t['propersubset'] = 0x2282;
    t['propersuperset'] = 0x2283;
    t['proportion'] = 0x2237;
    t['proportional'] = 0x221D;
    t['psi'] = 0x03C8;
    t['psicyrillic'] = 0x0471;
    t['psilipneumatacyrilliccmb'] = 0x0486;
    t['pssquare'] = 0x33B0;
    t['puhiragana'] = 0x3077;
    t['pukatakana'] = 0x30D7;
    t['pvsquare'] = 0x33B4;
    t['pwsquare'] = 0x33BA;
    t['q'] = 0x0071;
    t['qadeva'] = 0x0958;
    t['qadmahebrew'] = 0x05A8;
    t['qafarabic'] = 0x0642;
    t['qaffinalarabic'] = 0xFED6;
    t['qafinitialarabic'] = 0xFED7;
    t['qafmedialarabic'] = 0xFED8;
    t['qamats'] = 0x05B8;
    t['qamats10'] = 0x05B8;
    t['qamats1a'] = 0x05B8;
    t['qamats1c'] = 0x05B8;
    t['qamats27'] = 0x05B8;
    t['qamats29'] = 0x05B8;
    t['qamats33'] = 0x05B8;
    t['qamatsde'] = 0x05B8;
    t['qamatshebrew'] = 0x05B8;
    t['qamatsnarrowhebrew'] = 0x05B8;
    t['qamatsqatanhebrew'] = 0x05B8;
    t['qamatsqatannarrowhebrew'] = 0x05B8;
    t['qamatsqatanquarterhebrew'] = 0x05B8;
    t['qamatsqatanwidehebrew'] = 0x05B8;
    t['qamatsquarterhebrew'] = 0x05B8;
    t['qamatswidehebrew'] = 0x05B8;
    t['qarneyparahebrew'] = 0x059F;
    t['qbopomofo'] = 0x3111;
    t['qcircle'] = 0x24E0;
    t['qhook'] = 0x02A0;
    t['qmonospace'] = 0xFF51;
    t['qof'] = 0x05E7;
    t['qofdagesh'] = 0xFB47;
    t['qofdageshhebrew'] = 0xFB47;
    t['qofhebrew'] = 0x05E7;
    t['qparen'] = 0x24AC;
    t['quarternote'] = 0x2669;
    t['qubuts'] = 0x05BB;
    t['qubuts18'] = 0x05BB;
    t['qubuts25'] = 0x05BB;
    t['qubuts31'] = 0x05BB;
    t['qubutshebrew'] = 0x05BB;
    t['qubutsnarrowhebrew'] = 0x05BB;
    t['qubutsquarterhebrew'] = 0x05BB;
    t['qubutswidehebrew'] = 0x05BB;
    t['question'] = 0x003F;
    t['questionarabic'] = 0x061F;
    t['questionarmenian'] = 0x055E;
    t['questiondown'] = 0x00BF;
    t['questiondownsmall'] = 0xF7BF;
    t['questiongreek'] = 0x037E;
    t['questionmonospace'] = 0xFF1F;
    t['questionsmall'] = 0xF73F;
    t['quotedbl'] = 0x0022;
    t['quotedblbase'] = 0x201E;
    t['quotedblleft'] = 0x201C;
    t['quotedblmonospace'] = 0xFF02;
    t['quotedblprime'] = 0x301E;
    t['quotedblprimereversed'] = 0x301D;
    t['quotedblright'] = 0x201D;
    t['quoteleft'] = 0x2018;
    t['quoteleftreversed'] = 0x201B;
    t['quotereversed'] = 0x201B;
    t['quoteright'] = 0x2019;
    t['quoterightn'] = 0x0149;
    t['quotesinglbase'] = 0x201A;
    t['quotesingle'] = 0x0027;
    t['quotesinglemonospace'] = 0xFF07;
    t['r'] = 0x0072;
    t['raarmenian'] = 0x057C;
    t['rabengali'] = 0x09B0;
    t['racute'] = 0x0155;
    t['radeva'] = 0x0930;
    t['radical'] = 0x221A;
    t['radicalex'] = 0xF8E5;
    t['radoverssquare'] = 0x33AE;
    t['radoverssquaredsquare'] = 0x33AF;
    t['radsquare'] = 0x33AD;
    t['rafe'] = 0x05BF;
    t['rafehebrew'] = 0x05BF;
    t['ragujarati'] = 0x0AB0;
    t['ragurmukhi'] = 0x0A30;
    t['rahiragana'] = 0x3089;
    t['rakatakana'] = 0x30E9;
    t['rakatakanahalfwidth'] = 0xFF97;
    t['ralowerdiagonalbengali'] = 0x09F1;
    t['ramiddlediagonalbengali'] = 0x09F0;
    t['ramshorn'] = 0x0264;
    t['ratio'] = 0x2236;
    t['rbopomofo'] = 0x3116;
    t['rcaron'] = 0x0159;
    t['rcedilla'] = 0x0157;
    t['rcircle'] = 0x24E1;
    t['rcommaaccent'] = 0x0157;
    t['rdblgrave'] = 0x0211;
    t['rdotaccent'] = 0x1E59;
    t['rdotbelow'] = 0x1E5B;
    t['rdotbelowmacron'] = 0x1E5D;
    t['referencemark'] = 0x203B;
    t['reflexsubset'] = 0x2286;
    t['reflexsuperset'] = 0x2287;
    t['registered'] = 0x00AE;
    t['registersans'] = 0xF8E8;
    t['registerserif'] = 0xF6DA;
    t['reharabic'] = 0x0631;
    t['reharmenian'] = 0x0580;
    t['rehfinalarabic'] = 0xFEAE;
    t['rehiragana'] = 0x308C;
    t['rekatakana'] = 0x30EC;
    t['rekatakanahalfwidth'] = 0xFF9A;
    t['resh'] = 0x05E8;
    t['reshdageshhebrew'] = 0xFB48;
    t['reshhebrew'] = 0x05E8;
    t['reversedtilde'] = 0x223D;
    t['reviahebrew'] = 0x0597;
    t['reviamugrashhebrew'] = 0x0597;
    t['revlogicalnot'] = 0x2310;
    t['rfishhook'] = 0x027E;
    t['rfishhookreversed'] = 0x027F;
    t['rhabengali'] = 0x09DD;
    t['rhadeva'] = 0x095D;
    t['rho'] = 0x03C1;
    t['rhook'] = 0x027D;
    t['rhookturned'] = 0x027B;
    t['rhookturnedsuperior'] = 0x02B5;
    t['rhosymbolgreek'] = 0x03F1;
    t['rhotichookmod'] = 0x02DE;
    t['rieulacirclekorean'] = 0x3271;
    t['rieulaparenkorean'] = 0x3211;
    t['rieulcirclekorean'] = 0x3263;
    t['rieulhieuhkorean'] = 0x3140;
    t['rieulkiyeokkorean'] = 0x313A;
    t['rieulkiyeoksioskorean'] = 0x3169;
    t['rieulkorean'] = 0x3139;
    t['rieulmieumkorean'] = 0x313B;
    t['rieulpansioskorean'] = 0x316C;
    t['rieulparenkorean'] = 0x3203;
    t['rieulphieuphkorean'] = 0x313F;
    t['rieulpieupkorean'] = 0x313C;
    t['rieulpieupsioskorean'] = 0x316B;
    t['rieulsioskorean'] = 0x313D;
    t['rieulthieuthkorean'] = 0x313E;
    t['rieultikeutkorean'] = 0x316A;
    t['rieulyeorinhieuhkorean'] = 0x316D;
    t['rightangle'] = 0x221F;
    t['righttackbelowcmb'] = 0x0319;
    t['righttriangle'] = 0x22BF;
    t['rihiragana'] = 0x308A;
    t['rikatakana'] = 0x30EA;
    t['rikatakanahalfwidth'] = 0xFF98;
    t['ring'] = 0x02DA;
    t['ringbelowcmb'] = 0x0325;
    t['ringcmb'] = 0x030A;
    t['ringhalfleft'] = 0x02BF;
    t['ringhalfleftarmenian'] = 0x0559;
    t['ringhalfleftbelowcmb'] = 0x031C;
    t['ringhalfleftcentered'] = 0x02D3;
    t['ringhalfright'] = 0x02BE;
    t['ringhalfrightbelowcmb'] = 0x0339;
    t['ringhalfrightcentered'] = 0x02D2;
    t['rinvertedbreve'] = 0x0213;
    t['rittorusquare'] = 0x3351;
    t['rlinebelow'] = 0x1E5F;
    t['rlongleg'] = 0x027C;
    t['rlonglegturned'] = 0x027A;
    t['rmonospace'] = 0xFF52;
    t['rohiragana'] = 0x308D;
    t['rokatakana'] = 0x30ED;
    t['rokatakanahalfwidth'] = 0xFF9B;
    t['roruathai'] = 0x0E23;
    t['rparen'] = 0x24AD;
    t['rrabengali'] = 0x09DC;
    t['rradeva'] = 0x0931;
    t['rragurmukhi'] = 0x0A5C;
    t['rreharabic'] = 0x0691;
    t['rrehfinalarabic'] = 0xFB8D;
    t['rrvocalicbengali'] = 0x09E0;
    t['rrvocalicdeva'] = 0x0960;
    t['rrvocalicgujarati'] = 0x0AE0;
    t['rrvocalicvowelsignbengali'] = 0x09C4;
    t['rrvocalicvowelsigndeva'] = 0x0944;
    t['rrvocalicvowelsigngujarati'] = 0x0AC4;
    t['rsuperior'] = 0xF6F1;
    t['rtblock'] = 0x2590;
    t['rturned'] = 0x0279;
    t['rturnedsuperior'] = 0x02B4;
    t['ruhiragana'] = 0x308B;
    t['rukatakana'] = 0x30EB;
    t['rukatakanahalfwidth'] = 0xFF99;
    t['rupeemarkbengali'] = 0x09F2;
    t['rupeesignbengali'] = 0x09F3;
    t['rupiah'] = 0xF6DD;
    t['ruthai'] = 0x0E24;
    t['rvocalicbengali'] = 0x098B;
    t['rvocalicdeva'] = 0x090B;
    t['rvocalicgujarati'] = 0x0A8B;
    t['rvocalicvowelsignbengali'] = 0x09C3;
    t['rvocalicvowelsigndeva'] = 0x0943;
    t['rvocalicvowelsigngujarati'] = 0x0AC3;
    t['s'] = 0x0073;
    t['sabengali'] = 0x09B8;
    t['sacute'] = 0x015B;
    t['sacutedotaccent'] = 0x1E65;
    t['sadarabic'] = 0x0635;
    t['sadeva'] = 0x0938;
    t['sadfinalarabic'] = 0xFEBA;
    t['sadinitialarabic'] = 0xFEBB;
    t['sadmedialarabic'] = 0xFEBC;
    t['sagujarati'] = 0x0AB8;
    t['sagurmukhi'] = 0x0A38;
    t['sahiragana'] = 0x3055;
    t['sakatakana'] = 0x30B5;
    t['sakatakanahalfwidth'] = 0xFF7B;
    t['sallallahoualayhewasallamarabic'] = 0xFDFA;
    t['samekh'] = 0x05E1;
    t['samekhdagesh'] = 0xFB41;
    t['samekhdageshhebrew'] = 0xFB41;
    t['samekhhebrew'] = 0x05E1;
    t['saraaathai'] = 0x0E32;
    t['saraaethai'] = 0x0E41;
    t['saraaimaimalaithai'] = 0x0E44;
    t['saraaimaimuanthai'] = 0x0E43;
    t['saraamthai'] = 0x0E33;
    t['saraathai'] = 0x0E30;
    t['saraethai'] = 0x0E40;
    t['saraiileftthai'] = 0xF886;
    t['saraiithai'] = 0x0E35;
    t['saraileftthai'] = 0xF885;
    t['saraithai'] = 0x0E34;
    t['saraothai'] = 0x0E42;
    t['saraueeleftthai'] = 0xF888;
    t['saraueethai'] = 0x0E37;
    t['saraueleftthai'] = 0xF887;
    t['sarauethai'] = 0x0E36;
    t['sarauthai'] = 0x0E38;
    t['sarauuthai'] = 0x0E39;
    t['sbopomofo'] = 0x3119;
    t['scaron'] = 0x0161;
    t['scarondotaccent'] = 0x1E67;
    t['scedilla'] = 0x015F;
    t['schwa'] = 0x0259;
    t['schwacyrillic'] = 0x04D9;
    t['schwadieresiscyrillic'] = 0x04DB;
    t['schwahook'] = 0x025A;
    t['scircle'] = 0x24E2;
    t['scircumflex'] = 0x015D;
    t['scommaaccent'] = 0x0219;
    t['sdotaccent'] = 0x1E61;
    t['sdotbelow'] = 0x1E63;
    t['sdotbelowdotaccent'] = 0x1E69;
    t['seagullbelowcmb'] = 0x033C;
    t['second'] = 0x2033;
    t['secondtonechinese'] = 0x02CA;
    t['section'] = 0x00A7;
    t['seenarabic'] = 0x0633;
    t['seenfinalarabic'] = 0xFEB2;
    t['seeninitialarabic'] = 0xFEB3;
    t['seenmedialarabic'] = 0xFEB4;
    t['segol'] = 0x05B6;
    t['segol13'] = 0x05B6;
    t['segol1f'] = 0x05B6;
    t['segol2c'] = 0x05B6;
    t['segolhebrew'] = 0x05B6;
    t['segolnarrowhebrew'] = 0x05B6;
    t['segolquarterhebrew'] = 0x05B6;
    t['segoltahebrew'] = 0x0592;
    t['segolwidehebrew'] = 0x05B6;
    t['seharmenian'] = 0x057D;
    t['sehiragana'] = 0x305B;
    t['sekatakana'] = 0x30BB;
    t['sekatakanahalfwidth'] = 0xFF7E;
    t['semicolon'] = 0x003B;
    t['semicolonarabic'] = 0x061B;
    t['semicolonmonospace'] = 0xFF1B;
    t['semicolonsmall'] = 0xFE54;
    t['semivoicedmarkkana'] = 0x309C;
    t['semivoicedmarkkanahalfwidth'] = 0xFF9F;
    t['sentisquare'] = 0x3322;
    t['sentosquare'] = 0x3323;
    t['seven'] = 0x0037;
    t['sevenarabic'] = 0x0667;
    t['sevenbengali'] = 0x09ED;
    t['sevencircle'] = 0x2466;
    t['sevencircleinversesansserif'] = 0x2790;
    t['sevendeva'] = 0x096D;
    t['seveneighths'] = 0x215E;
    t['sevengujarati'] = 0x0AED;
    t['sevengurmukhi'] = 0x0A6D;
    t['sevenhackarabic'] = 0x0667;
    t['sevenhangzhou'] = 0x3027;
    t['sevenideographicparen'] = 0x3226;
    t['seveninferior'] = 0x2087;
    t['sevenmonospace'] = 0xFF17;
    t['sevenoldstyle'] = 0xF737;
    t['sevenparen'] = 0x247A;
    t['sevenperiod'] = 0x248E;
    t['sevenpersian'] = 0x06F7;
    t['sevenroman'] = 0x2176;
    t['sevensuperior'] = 0x2077;
    t['seventeencircle'] = 0x2470;
    t['seventeenparen'] = 0x2484;
    t['seventeenperiod'] = 0x2498;
    t['seventhai'] = 0x0E57;
    t['sfthyphen'] = 0x00AD;
    t['shaarmenian'] = 0x0577;
    t['shabengali'] = 0x09B6;
    t['shacyrillic'] = 0x0448;
    t['shaddaarabic'] = 0x0651;
    t['shaddadammaarabic'] = 0xFC61;
    t['shaddadammatanarabic'] = 0xFC5E;
    t['shaddafathaarabic'] = 0xFC60;
    t['shaddakasraarabic'] = 0xFC62;
    t['shaddakasratanarabic'] = 0xFC5F;
    t['shade'] = 0x2592;
    t['shadedark'] = 0x2593;
    t['shadelight'] = 0x2591;
    t['shademedium'] = 0x2592;
    t['shadeva'] = 0x0936;
    t['shagujarati'] = 0x0AB6;
    t['shagurmukhi'] = 0x0A36;
    t['shalshelethebrew'] = 0x0593;
    t['shbopomofo'] = 0x3115;
    t['shchacyrillic'] = 0x0449;
    t['sheenarabic'] = 0x0634;
    t['sheenfinalarabic'] = 0xFEB6;
    t['sheeninitialarabic'] = 0xFEB7;
    t['sheenmedialarabic'] = 0xFEB8;
    t['sheicoptic'] = 0x03E3;
    t['sheqel'] = 0x20AA;
    t['sheqelhebrew'] = 0x20AA;
    t['sheva'] = 0x05B0;
    t['sheva115'] = 0x05B0;
    t['sheva15'] = 0x05B0;
    t['sheva22'] = 0x05B0;
    t['sheva2e'] = 0x05B0;
    t['shevahebrew'] = 0x05B0;
    t['shevanarrowhebrew'] = 0x05B0;
    t['shevaquarterhebrew'] = 0x05B0;
    t['shevawidehebrew'] = 0x05B0;
    t['shhacyrillic'] = 0x04BB;
    t['shimacoptic'] = 0x03ED;
    t['shin'] = 0x05E9;
    t['shindagesh'] = 0xFB49;
    t['shindageshhebrew'] = 0xFB49;
    t['shindageshshindot'] = 0xFB2C;
    t['shindageshshindothebrew'] = 0xFB2C;
    t['shindageshsindot'] = 0xFB2D;
    t['shindageshsindothebrew'] = 0xFB2D;
    t['shindothebrew'] = 0x05C1;
    t['shinhebrew'] = 0x05E9;
    t['shinshindot'] = 0xFB2A;
    t['shinshindothebrew'] = 0xFB2A;
    t['shinsindot'] = 0xFB2B;
    t['shinsindothebrew'] = 0xFB2B;
    t['shook'] = 0x0282;
    t['sigma'] = 0x03C3;
    t['sigma1'] = 0x03C2;
    t['sigmafinal'] = 0x03C2;
    t['sigmalunatesymbolgreek'] = 0x03F2;
    t['sihiragana'] = 0x3057;
    t['sikatakana'] = 0x30B7;
    t['sikatakanahalfwidth'] = 0xFF7C;
    t['siluqhebrew'] = 0x05BD;
    t['siluqlefthebrew'] = 0x05BD;
    t['similar'] = 0x223C;
    t['sindothebrew'] = 0x05C2;
    t['siosacirclekorean'] = 0x3274;
    t['siosaparenkorean'] = 0x3214;
    t['sioscieuckorean'] = 0x317E;
    t['sioscirclekorean'] = 0x3266;
    t['sioskiyeokkorean'] = 0x317A;
    t['sioskorean'] = 0x3145;
    t['siosnieunkorean'] = 0x317B;
    t['siosparenkorean'] = 0x3206;
    t['siospieupkorean'] = 0x317D;
    t['siostikeutkorean'] = 0x317C;
    t['six'] = 0x0036;
    t['sixarabic'] = 0x0666;
    t['sixbengali'] = 0x09EC;
    t['sixcircle'] = 0x2465;
    t['sixcircleinversesansserif'] = 0x278F;
    t['sixdeva'] = 0x096C;
    t['sixgujarati'] = 0x0AEC;
    t['sixgurmukhi'] = 0x0A6C;
    t['sixhackarabic'] = 0x0666;
    t['sixhangzhou'] = 0x3026;
    t['sixideographicparen'] = 0x3225;
    t['sixinferior'] = 0x2086;
    t['sixmonospace'] = 0xFF16;
    t['sixoldstyle'] = 0xF736;
    t['sixparen'] = 0x2479;
    t['sixperiod'] = 0x248D;
    t['sixpersian'] = 0x06F6;
    t['sixroman'] = 0x2175;
    t['sixsuperior'] = 0x2076;
    t['sixteencircle'] = 0x246F;
    t['sixteencurrencydenominatorbengali'] = 0x09F9;
    t['sixteenparen'] = 0x2483;
    t['sixteenperiod'] = 0x2497;
    t['sixthai'] = 0x0E56;
    t['slash'] = 0x002F;
    t['slashmonospace'] = 0xFF0F;
    t['slong'] = 0x017F;
    t['slongdotaccent'] = 0x1E9B;
    t['smileface'] = 0x263A;
    t['smonospace'] = 0xFF53;
    t['sofpasuqhebrew'] = 0x05C3;
    t['softhyphen'] = 0x00AD;
    t['softsigncyrillic'] = 0x044C;
    t['sohiragana'] = 0x305D;
    t['sokatakana'] = 0x30BD;
    t['sokatakanahalfwidth'] = 0xFF7F;
    t['soliduslongoverlaycmb'] = 0x0338;
    t['solidusshortoverlaycmb'] = 0x0337;
    t['sorusithai'] = 0x0E29;
    t['sosalathai'] = 0x0E28;
    t['sosothai'] = 0x0E0B;
    t['sosuathai'] = 0x0E2A;
    t['space'] = 0x0020;
    t['spacehackarabic'] = 0x0020;
    t['spade'] = 0x2660;
    t['spadesuitblack'] = 0x2660;
    t['spadesuitwhite'] = 0x2664;
    t['sparen'] = 0x24AE;
    t['squarebelowcmb'] = 0x033B;
    t['squarecc'] = 0x33C4;
    t['squarecm'] = 0x339D;
    t['squarediagonalcrosshatchfill'] = 0x25A9;
    t['squarehorizontalfill'] = 0x25A4;
    t['squarekg'] = 0x338F;
    t['squarekm'] = 0x339E;
    t['squarekmcapital'] = 0x33CE;
    t['squareln'] = 0x33D1;
    t['squarelog'] = 0x33D2;
    t['squaremg'] = 0x338E;
    t['squaremil'] = 0x33D5;
    t['squaremm'] = 0x339C;
    t['squaremsquared'] = 0x33A1;
    t['squareorthogonalcrosshatchfill'] = 0x25A6;
    t['squareupperlefttolowerrightfill'] = 0x25A7;
    t['squareupperrighttolowerleftfill'] = 0x25A8;
    t['squareverticalfill'] = 0x25A5;
    t['squarewhitewithsmallblack'] = 0x25A3;
    t['srsquare'] = 0x33DB;
    t['ssabengali'] = 0x09B7;
    t['ssadeva'] = 0x0937;
    t['ssagujarati'] = 0x0AB7;
    t['ssangcieuckorean'] = 0x3149;
    t['ssanghieuhkorean'] = 0x3185;
    t['ssangieungkorean'] = 0x3180;
    t['ssangkiyeokkorean'] = 0x3132;
    t['ssangnieunkorean'] = 0x3165;
    t['ssangpieupkorean'] = 0x3143;
    t['ssangsioskorean'] = 0x3146;
    t['ssangtikeutkorean'] = 0x3138;
    t['ssuperior'] = 0xF6F2;
    t['sterling'] = 0x00A3;
    t['sterlingmonospace'] = 0xFFE1;
    t['strokelongoverlaycmb'] = 0x0336;
    t['strokeshortoverlaycmb'] = 0x0335;
    t['subset'] = 0x2282;
    t['subsetnotequal'] = 0x228A;
    t['subsetorequal'] = 0x2286;
    t['succeeds'] = 0x227B;
    t['suchthat'] = 0x220B;
    t['suhiragana'] = 0x3059;
    t['sukatakana'] = 0x30B9;
    t['sukatakanahalfwidth'] = 0xFF7D;
    t['sukunarabic'] = 0x0652;
    t['summation'] = 0x2211;
    t['sun'] = 0x263C;
    t['superset'] = 0x2283;
    t['supersetnotequal'] = 0x228B;
    t['supersetorequal'] = 0x2287;
    t['svsquare'] = 0x33DC;
    t['syouwaerasquare'] = 0x337C;
    t['t'] = 0x0074;
    t['tabengali'] = 0x09A4;
    t['tackdown'] = 0x22A4;
    t['tackleft'] = 0x22A3;
    t['tadeva'] = 0x0924;
    t['tagujarati'] = 0x0AA4;
    t['tagurmukhi'] = 0x0A24;
    t['taharabic'] = 0x0637;
    t['tahfinalarabic'] = 0xFEC2;
    t['tahinitialarabic'] = 0xFEC3;
    t['tahiragana'] = 0x305F;
    t['tahmedialarabic'] = 0xFEC4;
    t['taisyouerasquare'] = 0x337D;
    t['takatakana'] = 0x30BF;
    t['takatakanahalfwidth'] = 0xFF80;
    t['tatweelarabic'] = 0x0640;
    t['tau'] = 0x03C4;
    t['tav'] = 0x05EA;
    t['tavdages'] = 0xFB4A;
    t['tavdagesh'] = 0xFB4A;
    t['tavdageshhebrew'] = 0xFB4A;
    t['tavhebrew'] = 0x05EA;
    t['tbar'] = 0x0167;
    t['tbopomofo'] = 0x310A;
    t['tcaron'] = 0x0165;
    t['tccurl'] = 0x02A8;
    t['tcedilla'] = 0x0163;
    t['tcheharabic'] = 0x0686;
    t['tchehfinalarabic'] = 0xFB7B;
    t['tchehinitialarabic'] = 0xFB7C;
    t['tchehmedialarabic'] = 0xFB7D;
    t['tcircle'] = 0x24E3;
    t['tcircumflexbelow'] = 0x1E71;
    t['tcommaaccent'] = 0x0163;
    t['tdieresis'] = 0x1E97;
    t['tdotaccent'] = 0x1E6B;
    t['tdotbelow'] = 0x1E6D;
    t['tecyrillic'] = 0x0442;
    t['tedescendercyrillic'] = 0x04AD;
    t['teharabic'] = 0x062A;
    t['tehfinalarabic'] = 0xFE96;
    t['tehhahinitialarabic'] = 0xFCA2;
    t['tehhahisolatedarabic'] = 0xFC0C;
    t['tehinitialarabic'] = 0xFE97;
    t['tehiragana'] = 0x3066;
    t['tehjeeminitialarabic'] = 0xFCA1;
    t['tehjeemisolatedarabic'] = 0xFC0B;
    t['tehmarbutaarabic'] = 0x0629;
    t['tehmarbutafinalarabic'] = 0xFE94;
    t['tehmedialarabic'] = 0xFE98;
    t['tehmeeminitialarabic'] = 0xFCA4;
    t['tehmeemisolatedarabic'] = 0xFC0E;
    t['tehnoonfinalarabic'] = 0xFC73;
    t['tekatakana'] = 0x30C6;
    t['tekatakanahalfwidth'] = 0xFF83;
    t['telephone'] = 0x2121;
    t['telephoneblack'] = 0x260E;
    t['telishagedolahebrew'] = 0x05A0;
    t['telishaqetanahebrew'] = 0x05A9;
    t['tencircle'] = 0x2469;
    t['tenideographicparen'] = 0x3229;
    t['tenparen'] = 0x247D;
    t['tenperiod'] = 0x2491;
    t['tenroman'] = 0x2179;
    t['tesh'] = 0x02A7;
    t['tet'] = 0x05D8;
    t['tetdagesh'] = 0xFB38;
    t['tetdageshhebrew'] = 0xFB38;
    t['tethebrew'] = 0x05D8;
    t['tetsecyrillic'] = 0x04B5;
    t['tevirhebrew'] = 0x059B;
    t['tevirlefthebrew'] = 0x059B;
    t['thabengali'] = 0x09A5;
    t['thadeva'] = 0x0925;
    t['thagujarati'] = 0x0AA5;
    t['thagurmukhi'] = 0x0A25;
    t['thalarabic'] = 0x0630;
    t['thalfinalarabic'] = 0xFEAC;
    t['thanthakhatlowleftthai'] = 0xF898;
    t['thanthakhatlowrightthai'] = 0xF897;
    t['thanthakhatthai'] = 0x0E4C;
    t['thanthakhatupperleftthai'] = 0xF896;
    t['theharabic'] = 0x062B;
    t['thehfinalarabic'] = 0xFE9A;
    t['thehinitialarabic'] = 0xFE9B;
    t['thehmedialarabic'] = 0xFE9C;
    t['thereexists'] = 0x2203;
    t['therefore'] = 0x2234;
    t['theta'] = 0x03B8;
    t['theta1'] = 0x03D1;
    t['thetasymbolgreek'] = 0x03D1;
    t['thieuthacirclekorean'] = 0x3279;
    t['thieuthaparenkorean'] = 0x3219;
    t['thieuthcirclekorean'] = 0x326B;
    t['thieuthkorean'] = 0x314C;
    t['thieuthparenkorean'] = 0x320B;
    t['thirteencircle'] = 0x246C;
    t['thirteenparen'] = 0x2480;
    t['thirteenperiod'] = 0x2494;
    t['thonangmonthothai'] = 0x0E11;
    t['thook'] = 0x01AD;
    t['thophuthaothai'] = 0x0E12;
    t['thorn'] = 0x00FE;
    t['thothahanthai'] = 0x0E17;
    t['thothanthai'] = 0x0E10;
    t['thothongthai'] = 0x0E18;
    t['thothungthai'] = 0x0E16;
    t['thousandcyrillic'] = 0x0482;
    t['thousandsseparatorarabic'] = 0x066C;
    t['thousandsseparatorpersian'] = 0x066C;
    t['three'] = 0x0033;
    t['threearabic'] = 0x0663;
    t['threebengali'] = 0x09E9;
    t['threecircle'] = 0x2462;
    t['threecircleinversesansserif'] = 0x278C;
    t['threedeva'] = 0x0969;
    t['threeeighths'] = 0x215C;
    t['threegujarati'] = 0x0AE9;
    t['threegurmukhi'] = 0x0A69;
    t['threehackarabic'] = 0x0663;
    t['threehangzhou'] = 0x3023;
    t['threeideographicparen'] = 0x3222;
    t['threeinferior'] = 0x2083;
    t['threemonospace'] = 0xFF13;
    t['threenumeratorbengali'] = 0x09F6;
    t['threeoldstyle'] = 0xF733;
    t['threeparen'] = 0x2476;
    t['threeperiod'] = 0x248A;
    t['threepersian'] = 0x06F3;
    t['threequarters'] = 0x00BE;
    t['threequartersemdash'] = 0xF6DE;
    t['threeroman'] = 0x2172;
    t['threesuperior'] = 0x00B3;
    t['threethai'] = 0x0E53;
    t['thzsquare'] = 0x3394;
    t['tihiragana'] = 0x3061;
    t['tikatakana'] = 0x30C1;
    t['tikatakanahalfwidth'] = 0xFF81;
    t['tikeutacirclekorean'] = 0x3270;
    t['tikeutaparenkorean'] = 0x3210;
    t['tikeutcirclekorean'] = 0x3262;
    t['tikeutkorean'] = 0x3137;
    t['tikeutparenkorean'] = 0x3202;
    t['tilde'] = 0x02DC;
    t['tildebelowcmb'] = 0x0330;
    t['tildecmb'] = 0x0303;
    t['tildecomb'] = 0x0303;
    t['tildedoublecmb'] = 0x0360;
    t['tildeoperator'] = 0x223C;
    t['tildeoverlaycmb'] = 0x0334;
    t['tildeverticalcmb'] = 0x033E;
    t['timescircle'] = 0x2297;
    t['tipehahebrew'] = 0x0596;
    t['tipehalefthebrew'] = 0x0596;
    t['tippigurmukhi'] = 0x0A70;
    t['titlocyrilliccmb'] = 0x0483;
    t['tiwnarmenian'] = 0x057F;
    t['tlinebelow'] = 0x1E6F;
    t['tmonospace'] = 0xFF54;
    t['toarmenian'] = 0x0569;
    t['tohiragana'] = 0x3068;
    t['tokatakana'] = 0x30C8;
    t['tokatakanahalfwidth'] = 0xFF84;
    t['tonebarextrahighmod'] = 0x02E5;
    t['tonebarextralowmod'] = 0x02E9;
    t['tonebarhighmod'] = 0x02E6;
    t['tonebarlowmod'] = 0x02E8;
    t['tonebarmidmod'] = 0x02E7;
    t['tonefive'] = 0x01BD;
    t['tonesix'] = 0x0185;
    t['tonetwo'] = 0x01A8;
    t['tonos'] = 0x0384;
    t['tonsquare'] = 0x3327;
    t['topatakthai'] = 0x0E0F;
    t['tortoiseshellbracketleft'] = 0x3014;
    t['tortoiseshellbracketleftsmall'] = 0xFE5D;
    t['tortoiseshellbracketleftvertical'] = 0xFE39;
    t['tortoiseshellbracketright'] = 0x3015;
    t['tortoiseshellbracketrightsmall'] = 0xFE5E;
    t['tortoiseshellbracketrightvertical'] = 0xFE3A;
    t['totaothai'] = 0x0E15;
    t['tpalatalhook'] = 0x01AB;
    t['tparen'] = 0x24AF;
    t['trademark'] = 0x2122;
    t['trademarksans'] = 0xF8EA;
    t['trademarkserif'] = 0xF6DB;
    t['tretroflexhook'] = 0x0288;
    t['triagdn'] = 0x25BC;
    t['triaglf'] = 0x25C4;
    t['triagrt'] = 0x25BA;
    t['triagup'] = 0x25B2;
    t['ts'] = 0x02A6;
    t['tsadi'] = 0x05E6;
    t['tsadidagesh'] = 0xFB46;
    t['tsadidageshhebrew'] = 0xFB46;
    t['tsadihebrew'] = 0x05E6;
    t['tsecyrillic'] = 0x0446;
    t['tsere'] = 0x05B5;
    t['tsere12'] = 0x05B5;
    t['tsere1e'] = 0x05B5;
    t['tsere2b'] = 0x05B5;
    t['tserehebrew'] = 0x05B5;
    t['tserenarrowhebrew'] = 0x05B5;
    t['tserequarterhebrew'] = 0x05B5;
    t['tserewidehebrew'] = 0x05B5;
    t['tshecyrillic'] = 0x045B;
    t['tsuperior'] = 0xF6F3;
    t['ttabengali'] = 0x099F;
    t['ttadeva'] = 0x091F;
    t['ttagujarati'] = 0x0A9F;
    t['ttagurmukhi'] = 0x0A1F;
    t['tteharabic'] = 0x0679;
    t['ttehfinalarabic'] = 0xFB67;
    t['ttehinitialarabic'] = 0xFB68;
    t['ttehmedialarabic'] = 0xFB69;
    t['tthabengali'] = 0x09A0;
    t['tthadeva'] = 0x0920;
    t['tthagujarati'] = 0x0AA0;
    t['tthagurmukhi'] = 0x0A20;
    t['tturned'] = 0x0287;
    t['tuhiragana'] = 0x3064;
    t['tukatakana'] = 0x30C4;
    t['tukatakanahalfwidth'] = 0xFF82;
    t['tusmallhiragana'] = 0x3063;
    t['tusmallkatakana'] = 0x30C3;
    t['tusmallkatakanahalfwidth'] = 0xFF6F;
    t['twelvecircle'] = 0x246B;
    t['twelveparen'] = 0x247F;
    t['twelveperiod'] = 0x2493;
    t['twelveroman'] = 0x217B;
    t['twentycircle'] = 0x2473;
    t['twentyhangzhou'] = 0x5344;
    t['twentyparen'] = 0x2487;
    t['twentyperiod'] = 0x249B;
    t['two'] = 0x0032;
    t['twoarabic'] = 0x0662;
    t['twobengali'] = 0x09E8;
    t['twocircle'] = 0x2461;
    t['twocircleinversesansserif'] = 0x278B;
    t['twodeva'] = 0x0968;
    t['twodotenleader'] = 0x2025;
    t['twodotleader'] = 0x2025;
    t['twodotleadervertical'] = 0xFE30;
    t['twogujarati'] = 0x0AE8;
    t['twogurmukhi'] = 0x0A68;
    t['twohackarabic'] = 0x0662;
    t['twohangzhou'] = 0x3022;
    t['twoideographicparen'] = 0x3221;
    t['twoinferior'] = 0x2082;
    t['twomonospace'] = 0xFF12;
    t['twonumeratorbengali'] = 0x09F5;
    t['twooldstyle'] = 0xF732;
    t['twoparen'] = 0x2475;
    t['twoperiod'] = 0x2489;
    t['twopersian'] = 0x06F2;
    t['tworoman'] = 0x2171;
    t['twostroke'] = 0x01BB;
    t['twosuperior'] = 0x00B2;
    t['twothai'] = 0x0E52;
    t['twothirds'] = 0x2154;
    t['u'] = 0x0075;
    t['uacute'] = 0x00FA;
    t['ubar'] = 0x0289;
    t['ubengali'] = 0x0989;
    t['ubopomofo'] = 0x3128;
    t['ubreve'] = 0x016D;
    t['ucaron'] = 0x01D4;
    t['ucircle'] = 0x24E4;
    t['ucircumflex'] = 0x00FB;
    t['ucircumflexbelow'] = 0x1E77;
    t['ucyrillic'] = 0x0443;
    t['udattadeva'] = 0x0951;
    t['udblacute'] = 0x0171;
    t['udblgrave'] = 0x0215;
    t['udeva'] = 0x0909;
    t['udieresis'] = 0x00FC;
    t['udieresisacute'] = 0x01D8;
    t['udieresisbelow'] = 0x1E73;
    t['udieresiscaron'] = 0x01DA;
    t['udieresiscyrillic'] = 0x04F1;
    t['udieresisgrave'] = 0x01DC;
    t['udieresismacron'] = 0x01D6;
    t['udotbelow'] = 0x1EE5;
    t['ugrave'] = 0x00F9;
    t['ugujarati'] = 0x0A89;
    t['ugurmukhi'] = 0x0A09;
    t['uhiragana'] = 0x3046;
    t['uhookabove'] = 0x1EE7;
    t['uhorn'] = 0x01B0;
    t['uhornacute'] = 0x1EE9;
    t['uhorndotbelow'] = 0x1EF1;
    t['uhorngrave'] = 0x1EEB;
    t['uhornhookabove'] = 0x1EED;
    t['uhorntilde'] = 0x1EEF;
    t['uhungarumlaut'] = 0x0171;
    t['uhungarumlautcyrillic'] = 0x04F3;
    t['uinvertedbreve'] = 0x0217;
    t['ukatakana'] = 0x30A6;
    t['ukatakanahalfwidth'] = 0xFF73;
    t['ukcyrillic'] = 0x0479;
    t['ukorean'] = 0x315C;
    t['umacron'] = 0x016B;
    t['umacroncyrillic'] = 0x04EF;
    t['umacrondieresis'] = 0x1E7B;
    t['umatragurmukhi'] = 0x0A41;
    t['umonospace'] = 0xFF55;
    t['underscore'] = 0x005F;
    t['underscoredbl'] = 0x2017;
    t['underscoremonospace'] = 0xFF3F;
    t['underscorevertical'] = 0xFE33;
    t['underscorewavy'] = 0xFE4F;
    t['union'] = 0x222A;
    t['universal'] = 0x2200;
    t['uogonek'] = 0x0173;
    t['uparen'] = 0x24B0;
    t['upblock'] = 0x2580;
    t['upperdothebrew'] = 0x05C4;
    t['upsilon'] = 0x03C5;
    t['upsilondieresis'] = 0x03CB;
    t['upsilondieresistonos'] = 0x03B0;
    t['upsilonlatin'] = 0x028A;
    t['upsilontonos'] = 0x03CD;
    t['uptackbelowcmb'] = 0x031D;
    t['uptackmod'] = 0x02D4;
    t['uragurmukhi'] = 0x0A73;
    t['uring'] = 0x016F;
    t['ushortcyrillic'] = 0x045E;
    t['usmallhiragana'] = 0x3045;
    t['usmallkatakana'] = 0x30A5;
    t['usmallkatakanahalfwidth'] = 0xFF69;
    t['ustraightcyrillic'] = 0x04AF;
    t['ustraightstrokecyrillic'] = 0x04B1;
    t['utilde'] = 0x0169;
    t['utildeacute'] = 0x1E79;
    t['utildebelow'] = 0x1E75;
    t['uubengali'] = 0x098A;
    t['uudeva'] = 0x090A;
    t['uugujarati'] = 0x0A8A;
    t['uugurmukhi'] = 0x0A0A;
    t['uumatragurmukhi'] = 0x0A42;
    t['uuvowelsignbengali'] = 0x09C2;
    t['uuvowelsigndeva'] = 0x0942;
    t['uuvowelsigngujarati'] = 0x0AC2;
    t['uvowelsignbengali'] = 0x09C1;
    t['uvowelsigndeva'] = 0x0941;
    t['uvowelsigngujarati'] = 0x0AC1;
    t['v'] = 0x0076;
    t['vadeva'] = 0x0935;
    t['vagujarati'] = 0x0AB5;
    t['vagurmukhi'] = 0x0A35;
    t['vakatakana'] = 0x30F7;
    t['vav'] = 0x05D5;
    t['vavdagesh'] = 0xFB35;
    t['vavdagesh65'] = 0xFB35;
    t['vavdageshhebrew'] = 0xFB35;
    t['vavhebrew'] = 0x05D5;
    t['vavholam'] = 0xFB4B;
    t['vavholamhebrew'] = 0xFB4B;
    t['vavvavhebrew'] = 0x05F0;
    t['vavyodhebrew'] = 0x05F1;
    t['vcircle'] = 0x24E5;
    t['vdotbelow'] = 0x1E7F;
    t['vecyrillic'] = 0x0432;
    t['veharabic'] = 0x06A4;
    t['vehfinalarabic'] = 0xFB6B;
    t['vehinitialarabic'] = 0xFB6C;
    t['vehmedialarabic'] = 0xFB6D;
    t['vekatakana'] = 0x30F9;
    t['venus'] = 0x2640;
    t['verticalbar'] = 0x007C;
    t['verticallineabovecmb'] = 0x030D;
    t['verticallinebelowcmb'] = 0x0329;
    t['verticallinelowmod'] = 0x02CC;
    t['verticallinemod'] = 0x02C8;
    t['vewarmenian'] = 0x057E;
    t['vhook'] = 0x028B;
    t['vikatakana'] = 0x30F8;
    t['viramabengali'] = 0x09CD;
    t['viramadeva'] = 0x094D;
    t['viramagujarati'] = 0x0ACD;
    t['visargabengali'] = 0x0983;
    t['visargadeva'] = 0x0903;
    t['visargagujarati'] = 0x0A83;
    t['vmonospace'] = 0xFF56;
    t['voarmenian'] = 0x0578;
    t['voicediterationhiragana'] = 0x309E;
    t['voicediterationkatakana'] = 0x30FE;
    t['voicedmarkkana'] = 0x309B;
    t['voicedmarkkanahalfwidth'] = 0xFF9E;
    t['vokatakana'] = 0x30FA;
    t['vparen'] = 0x24B1;
    t['vtilde'] = 0x1E7D;
    t['vturned'] = 0x028C;
    t['vuhiragana'] = 0x3094;
    t['vukatakana'] = 0x30F4;
    t['w'] = 0x0077;
    t['wacute'] = 0x1E83;
    t['waekorean'] = 0x3159;
    t['wahiragana'] = 0x308F;
    t['wakatakana'] = 0x30EF;
    t['wakatakanahalfwidth'] = 0xFF9C;
    t['wakorean'] = 0x3158;
    t['wasmallhiragana'] = 0x308E;
    t['wasmallkatakana'] = 0x30EE;
    t['wattosquare'] = 0x3357;
    t['wavedash'] = 0x301C;
    t['wavyunderscorevertical'] = 0xFE34;
    t['wawarabic'] = 0x0648;
    t['wawfinalarabic'] = 0xFEEE;
    t['wawhamzaabovearabic'] = 0x0624;
    t['wawhamzaabovefinalarabic'] = 0xFE86;
    t['wbsquare'] = 0x33DD;
    t['wcircle'] = 0x24E6;
    t['wcircumflex'] = 0x0175;
    t['wdieresis'] = 0x1E85;
    t['wdotaccent'] = 0x1E87;
    t['wdotbelow'] = 0x1E89;
    t['wehiragana'] = 0x3091;
    t['weierstrass'] = 0x2118;
    t['wekatakana'] = 0x30F1;
    t['wekorean'] = 0x315E;
    t['weokorean'] = 0x315D;
    t['wgrave'] = 0x1E81;
    t['whitebullet'] = 0x25E6;
    t['whitecircle'] = 0x25CB;
    t['whitecircleinverse'] = 0x25D9;
    t['whitecornerbracketleft'] = 0x300E;
    t['whitecornerbracketleftvertical'] = 0xFE43;
    t['whitecornerbracketright'] = 0x300F;
    t['whitecornerbracketrightvertical'] = 0xFE44;
    t['whitediamond'] = 0x25C7;
    t['whitediamondcontainingblacksmalldiamond'] = 0x25C8;
    t['whitedownpointingsmalltriangle'] = 0x25BF;
    t['whitedownpointingtriangle'] = 0x25BD;
    t['whiteleftpointingsmalltriangle'] = 0x25C3;
    t['whiteleftpointingtriangle'] = 0x25C1;
    t['whitelenticularbracketleft'] = 0x3016;
    t['whitelenticularbracketright'] = 0x3017;
    t['whiterightpointingsmalltriangle'] = 0x25B9;
    t['whiterightpointingtriangle'] = 0x25B7;
    t['whitesmallsquare'] = 0x25AB;
    t['whitesmilingface'] = 0x263A;
    t['whitesquare'] = 0x25A1;
    t['whitestar'] = 0x2606;
    t['whitetelephone'] = 0x260F;
    t['whitetortoiseshellbracketleft'] = 0x3018;
    t['whitetortoiseshellbracketright'] = 0x3019;
    t['whiteuppointingsmalltriangle'] = 0x25B5;
    t['whiteuppointingtriangle'] = 0x25B3;
    t['wihiragana'] = 0x3090;
    t['wikatakana'] = 0x30F0;
    t['wikorean'] = 0x315F;
    t['wmonospace'] = 0xFF57;
    t['wohiragana'] = 0x3092;
    t['wokatakana'] = 0x30F2;
    t['wokatakanahalfwidth'] = 0xFF66;
    t['won'] = 0x20A9;
    t['wonmonospace'] = 0xFFE6;
    t['wowaenthai'] = 0x0E27;
    t['wparen'] = 0x24B2;
    t['wring'] = 0x1E98;
    t['wsuperior'] = 0x02B7;
    t['wturned'] = 0x028D;
    t['wynn'] = 0x01BF;
    t['x'] = 0x0078;
    t['xabovecmb'] = 0x033D;
    t['xbopomofo'] = 0x3112;
    t['xcircle'] = 0x24E7;
    t['xdieresis'] = 0x1E8D;
    t['xdotaccent'] = 0x1E8B;
    t['xeharmenian'] = 0x056D;
    t['xi'] = 0x03BE;
    t['xmonospace'] = 0xFF58;
    t['xparen'] = 0x24B3;
    t['xsuperior'] = 0x02E3;
    t['y'] = 0x0079;
    t['yaadosquare'] = 0x334E;
    t['yabengali'] = 0x09AF;
    t['yacute'] = 0x00FD;
    t['yadeva'] = 0x092F;
    t['yaekorean'] = 0x3152;
    t['yagujarati'] = 0x0AAF;
    t['yagurmukhi'] = 0x0A2F;
    t['yahiragana'] = 0x3084;
    t['yakatakana'] = 0x30E4;
    t['yakatakanahalfwidth'] = 0xFF94;
    t['yakorean'] = 0x3151;
    t['yamakkanthai'] = 0x0E4E;
    t['yasmallhiragana'] = 0x3083;
    t['yasmallkatakana'] = 0x30E3;
    t['yasmallkatakanahalfwidth'] = 0xFF6C;
    t['yatcyrillic'] = 0x0463;
    t['ycircle'] = 0x24E8;
    t['ycircumflex'] = 0x0177;
    t['ydieresis'] = 0x00FF;
    t['ydotaccent'] = 0x1E8F;
    t['ydotbelow'] = 0x1EF5;
    t['yeharabic'] = 0x064A;
    t['yehbarreearabic'] = 0x06D2;
    t['yehbarreefinalarabic'] = 0xFBAF;
    t['yehfinalarabic'] = 0xFEF2;
    t['yehhamzaabovearabic'] = 0x0626;
    t['yehhamzaabovefinalarabic'] = 0xFE8A;
    t['yehhamzaaboveinitialarabic'] = 0xFE8B;
    t['yehhamzaabovemedialarabic'] = 0xFE8C;
    t['yehinitialarabic'] = 0xFEF3;
    t['yehmedialarabic'] = 0xFEF4;
    t['yehmeeminitialarabic'] = 0xFCDD;
    t['yehmeemisolatedarabic'] = 0xFC58;
    t['yehnoonfinalarabic'] = 0xFC94;
    t['yehthreedotsbelowarabic'] = 0x06D1;
    t['yekorean'] = 0x3156;
    t['yen'] = 0x00A5;
    t['yenmonospace'] = 0xFFE5;
    t['yeokorean'] = 0x3155;
    t['yeorinhieuhkorean'] = 0x3186;
    t['yerahbenyomohebrew'] = 0x05AA;
    t['yerahbenyomolefthebrew'] = 0x05AA;
    t['yericyrillic'] = 0x044B;
    t['yerudieresiscyrillic'] = 0x04F9;
    t['yesieungkorean'] = 0x3181;
    t['yesieungpansioskorean'] = 0x3183;
    t['yesieungsioskorean'] = 0x3182;
    t['yetivhebrew'] = 0x059A;
    t['ygrave'] = 0x1EF3;
    t['yhook'] = 0x01B4;
    t['yhookabove'] = 0x1EF7;
    t['yiarmenian'] = 0x0575;
    t['yicyrillic'] = 0x0457;
    t['yikorean'] = 0x3162;
    t['yinyang'] = 0x262F;
    t['yiwnarmenian'] = 0x0582;
    t['ymonospace'] = 0xFF59;
    t['yod'] = 0x05D9;
    t['yoddagesh'] = 0xFB39;
    t['yoddageshhebrew'] = 0xFB39;
    t['yodhebrew'] = 0x05D9;
    t['yodyodhebrew'] = 0x05F2;
    t['yodyodpatahhebrew'] = 0xFB1F;
    t['yohiragana'] = 0x3088;
    t['yoikorean'] = 0x3189;
    t['yokatakana'] = 0x30E8;
    t['yokatakanahalfwidth'] = 0xFF96;
    t['yokorean'] = 0x315B;
    t['yosmallhiragana'] = 0x3087;
    t['yosmallkatakana'] = 0x30E7;
    t['yosmallkatakanahalfwidth'] = 0xFF6E;
    t['yotgreek'] = 0x03F3;
    t['yoyaekorean'] = 0x3188;
    t['yoyakorean'] = 0x3187;
    t['yoyakthai'] = 0x0E22;
    t['yoyingthai'] = 0x0E0D;
    t['yparen'] = 0x24B4;
    t['ypogegrammeni'] = 0x037A;
    t['ypogegrammenigreekcmb'] = 0x0345;
    t['yr'] = 0x01A6;
    t['yring'] = 0x1E99;
    t['ysuperior'] = 0x02B8;
    t['ytilde'] = 0x1EF9;
    t['yturned'] = 0x028E;
    t['yuhiragana'] = 0x3086;
    t['yuikorean'] = 0x318C;
    t['yukatakana'] = 0x30E6;
    t['yukatakanahalfwidth'] = 0xFF95;
    t['yukorean'] = 0x3160;
    t['yusbigcyrillic'] = 0x046B;
    t['yusbigiotifiedcyrillic'] = 0x046D;
    t['yuslittlecyrillic'] = 0x0467;
    t['yuslittleiotifiedcyrillic'] = 0x0469;
    t['yusmallhiragana'] = 0x3085;
    t['yusmallkatakana'] = 0x30E5;
    t['yusmallkatakanahalfwidth'] = 0xFF6D;
    t['yuyekorean'] = 0x318B;
    t['yuyeokorean'] = 0x318A;
    t['yyabengali'] = 0x09DF;
    t['yyadeva'] = 0x095F;
    t['z'] = 0x007A;
    t['zaarmenian'] = 0x0566;
    t['zacute'] = 0x017A;
    t['zadeva'] = 0x095B;
    t['zagurmukhi'] = 0x0A5B;
    t['zaharabic'] = 0x0638;
    t['zahfinalarabic'] = 0xFEC6;
    t['zahinitialarabic'] = 0xFEC7;
    t['zahiragana'] = 0x3056;
    t['zahmedialarabic'] = 0xFEC8;
    t['zainarabic'] = 0x0632;
    t['zainfinalarabic'] = 0xFEB0;
    t['zakatakana'] = 0x30B6;
    t['zaqefgadolhebrew'] = 0x0595;
    t['zaqefqatanhebrew'] = 0x0594;
    t['zarqahebrew'] = 0x0598;
    t['zayin'] = 0x05D6;
    t['zayindagesh'] = 0xFB36;
    t['zayindageshhebrew'] = 0xFB36;
    t['zayinhebrew'] = 0x05D6;
    t['zbopomofo'] = 0x3117;
    t['zcaron'] = 0x017E;
    t['zcircle'] = 0x24E9;
    t['zcircumflex'] = 0x1E91;
    t['zcurl'] = 0x0291;
    t['zdot'] = 0x017C;
    t['zdotaccent'] = 0x017C;
    t['zdotbelow'] = 0x1E93;
    t['zecyrillic'] = 0x0437;
    t['zedescendercyrillic'] = 0x0499;
    t['zedieresiscyrillic'] = 0x04DF;
    t['zehiragana'] = 0x305C;
    t['zekatakana'] = 0x30BC;
    t['zero'] = 0x0030;
    t['zeroarabic'] = 0x0660;
    t['zerobengali'] = 0x09E6;
    t['zerodeva'] = 0x0966;
    t['zerogujarati'] = 0x0AE6;
    t['zerogurmukhi'] = 0x0A66;
    t['zerohackarabic'] = 0x0660;
    t['zeroinferior'] = 0x2080;
    t['zeromonospace'] = 0xFF10;
    t['zerooldstyle'] = 0xF730;
    t['zeropersian'] = 0x06F0;
    t['zerosuperior'] = 0x2070;
    t['zerothai'] = 0x0E50;
    t['zerowidthjoiner'] = 0xFEFF;
    t['zerowidthnonjoiner'] = 0x200C;
    t['zerowidthspace'] = 0x200B;
    t['zeta'] = 0x03B6;
    t['zhbopomofo'] = 0x3113;
    t['zhearmenian'] = 0x056A;
    t['zhebrevecyrillic'] = 0x04C2;
    t['zhecyrillic'] = 0x0436;
    t['zhedescendercyrillic'] = 0x0497;
    t['zhedieresiscyrillic'] = 0x04DD;
    t['zihiragana'] = 0x3058;
    t['zikatakana'] = 0x30B8;
    t['zinorhebrew'] = 0x05AE;
    t['zlinebelow'] = 0x1E95;
    t['zmonospace'] = 0xFF5A;
    t['zohiragana'] = 0x305E;
    t['zokatakana'] = 0x30BE;
    t['zparen'] = 0x24B5;
    t['zretroflexhook'] = 0x0290;
    t['zstroke'] = 0x01B6;
    t['zuhiragana'] = 0x305A;
    t['zukatakana'] = 0x30BA;
    t['.notdef'] = 0x0000;
    t['angbracketleftbig'] = 0x2329;
    t['angbracketleftBig'] = 0x2329;
    t['angbracketleftbigg'] = 0x2329;
    t['angbracketleftBigg'] = 0x2329;
    t['angbracketrightBig'] = 0x232A;
    t['angbracketrightbig'] = 0x232A;
    t['angbracketrightBigg'] = 0x232A;
    t['angbracketrightbigg'] = 0x232A;
    t['arrowhookleft'] = 0x21AA;
    t['arrowhookright'] = 0x21A9;
    t['arrowlefttophalf'] = 0x21BC;
    t['arrowleftbothalf'] = 0x21BD;
    t['arrownortheast'] = 0x2197;
    t['arrownorthwest'] = 0x2196;
    t['arrowrighttophalf'] = 0x21C0;
    t['arrowrightbothalf'] = 0x21C1;
    t['arrowsoutheast'] = 0x2198;
    t['arrowsouthwest'] = 0x2199;
    t['backslashbig'] = 0x2216;
    t['backslashBig'] = 0x2216;
    t['backslashBigg'] = 0x2216;
    t['backslashbigg'] = 0x2216;
    t['bardbl'] = 0x2016;
    t['bracehtipdownleft'] = 0xFE37;
    t['bracehtipdownright'] = 0xFE37;
    t['bracehtipupleft'] = 0xFE38;
    t['bracehtipupright'] = 0xFE38;
    t['braceleftBig'] = 0x007B;
    t['braceleftbig'] = 0x007B;
    t['braceleftbigg'] = 0x007B;
    t['braceleftBigg'] = 0x007B;
    t['bracerightBig'] = 0x007D;
    t['bracerightbig'] = 0x007D;
    t['bracerightbigg'] = 0x007D;
    t['bracerightBigg'] = 0x007D;
    t['bracketleftbig'] = 0x005B;
    t['bracketleftBig'] = 0x005B;
    t['bracketleftbigg'] = 0x005B;
    t['bracketleftBigg'] = 0x005B;
    t['bracketrightBig'] = 0x005D;
    t['bracketrightbig'] = 0x005D;
    t['bracketrightbigg'] = 0x005D;
    t['bracketrightBigg'] = 0x005D;
    t['ceilingleftbig'] = 0x2308;
    t['ceilingleftBig'] = 0x2308;
    t['ceilingleftBigg'] = 0x2308;
    t['ceilingleftbigg'] = 0x2308;
    t['ceilingrightbig'] = 0x2309;
    t['ceilingrightBig'] = 0x2309;
    t['ceilingrightbigg'] = 0x2309;
    t['ceilingrightBigg'] = 0x2309;
    t['circledotdisplay'] = 0x2299;
    t['circledottext'] = 0x2299;
    t['circlemultiplydisplay'] = 0x2297;
    t['circlemultiplytext'] = 0x2297;
    t['circleplusdisplay'] = 0x2295;
    t['circleplustext'] = 0x2295;
    t['contintegraldisplay'] = 0x222E;
    t['contintegraltext'] = 0x222E;
    t['coproductdisplay'] = 0x2210;
    t['coproducttext'] = 0x2210;
    t['floorleftBig'] = 0x230A;
    t['floorleftbig'] = 0x230A;
    t['floorleftbigg'] = 0x230A;
    t['floorleftBigg'] = 0x230A;
    t['floorrightbig'] = 0x230B;
    t['floorrightBig'] = 0x230B;
    t['floorrightBigg'] = 0x230B;
    t['floorrightbigg'] = 0x230B;
    t['hatwide'] = 0x0302;
    t['hatwider'] = 0x0302;
    t['hatwidest'] = 0x0302;
    t['intercal'] = 0x1D40;
    t['integraldisplay'] = 0x222B;
    t['integraltext'] = 0x222B;
    t['intersectiondisplay'] = 0x22C2;
    t['intersectiontext'] = 0x22C2;
    t['logicalanddisplay'] = 0x2227;
    t['logicalandtext'] = 0x2227;
    t['logicalordisplay'] = 0x2228;
    t['logicalortext'] = 0x2228;
    t['parenleftBig'] = 0x0028;
    t['parenleftbig'] = 0x0028;
    t['parenleftBigg'] = 0x0028;
    t['parenleftbigg'] = 0x0028;
    t['parenrightBig'] = 0x0029;
    t['parenrightbig'] = 0x0029;
    t['parenrightBigg'] = 0x0029;
    t['parenrightbigg'] = 0x0029;
    t['prime'] = 0x2032;
    t['productdisplay'] = 0x220F;
    t['producttext'] = 0x220F;
    t['radicalbig'] = 0x221A;
    t['radicalBig'] = 0x221A;
    t['radicalBigg'] = 0x221A;
    t['radicalbigg'] = 0x221A;
    t['radicalbt'] = 0x221A;
    t['radicaltp'] = 0x221A;
    t['radicalvertex'] = 0x221A;
    t['slashbig'] = 0x002F;
    t['slashBig'] = 0x002F;
    t['slashBigg'] = 0x002F;
    t['slashbigg'] = 0x002F;
    t['summationdisplay'] = 0x2211;
    t['summationtext'] = 0x2211;
    t['tildewide'] = 0x02DC;
    t['tildewider'] = 0x02DC;
    t['tildewidest'] = 0x02DC;
    t['uniondisplay'] = 0x22C3;
    t['unionmultidisplay'] = 0x228E;
    t['unionmultitext'] = 0x228E;
    t['unionsqdisplay'] = 0x2294;
    t['unionsqtext'] = 0x2294;
    t['uniontext'] = 0x22C3;
    t['vextenddouble'] = 0x2225;
    t['vextendsingle'] = 0x2223;
   });
   var getDingbatsGlyphsUnicode = getLookupTableFactory(function (t) {
    t['space'] = 0x0020;
    t['a1'] = 0x2701;
    t['a2'] = 0x2702;
    t['a202'] = 0x2703;
    t['a3'] = 0x2704;
    t['a4'] = 0x260E;
    t['a5'] = 0x2706;
    t['a119'] = 0x2707;
    t['a118'] = 0x2708;
    t['a117'] = 0x2709;
    t['a11'] = 0x261B;
    t['a12'] = 0x261E;
    t['a13'] = 0x270C;
    t['a14'] = 0x270D;
    t['a15'] = 0x270E;
    t['a16'] = 0x270F;
    t['a105'] = 0x2710;
    t['a17'] = 0x2711;
    t['a18'] = 0x2712;
    t['a19'] = 0x2713;
    t['a20'] = 0x2714;
    t['a21'] = 0x2715;
    t['a22'] = 0x2716;
    t['a23'] = 0x2717;
    t['a24'] = 0x2718;
    t['a25'] = 0x2719;
    t['a26'] = 0x271A;
    t['a27'] = 0x271B;
    t['a28'] = 0x271C;
    t['a6'] = 0x271D;
    t['a7'] = 0x271E;
    t['a8'] = 0x271F;
    t['a9'] = 0x2720;
    t['a10'] = 0x2721;
    t['a29'] = 0x2722;
    t['a30'] = 0x2723;
    t['a31'] = 0x2724;
    t['a32'] = 0x2725;
    t['a33'] = 0x2726;
    t['a34'] = 0x2727;
    t['a35'] = 0x2605;
    t['a36'] = 0x2729;
    t['a37'] = 0x272A;
    t['a38'] = 0x272B;
    t['a39'] = 0x272C;
    t['a40'] = 0x272D;
    t['a41'] = 0x272E;
    t['a42'] = 0x272F;
    t['a43'] = 0x2730;
    t['a44'] = 0x2731;
    t['a45'] = 0x2732;
    t['a46'] = 0x2733;
    t['a47'] = 0x2734;
    t['a48'] = 0x2735;
    t['a49'] = 0x2736;
    t['a50'] = 0x2737;
    t['a51'] = 0x2738;
    t['a52'] = 0x2739;
    t['a53'] = 0x273A;
    t['a54'] = 0x273B;
    t['a55'] = 0x273C;
    t['a56'] = 0x273D;
    t['a57'] = 0x273E;
    t['a58'] = 0x273F;
    t['a59'] = 0x2740;
    t['a60'] = 0x2741;
    t['a61'] = 0x2742;
    t['a62'] = 0x2743;
    t['a63'] = 0x2744;
    t['a64'] = 0x2745;
    t['a65'] = 0x2746;
    t['a66'] = 0x2747;
    t['a67'] = 0x2748;
    t['a68'] = 0x2749;
    t['a69'] = 0x274A;
    t['a70'] = 0x274B;
    t['a71'] = 0x25CF;
    t['a72'] = 0x274D;
    t['a73'] = 0x25A0;
    t['a74'] = 0x274F;
    t['a203'] = 0x2750;
    t['a75'] = 0x2751;
    t['a204'] = 0x2752;
    t['a76'] = 0x25B2;
    t['a77'] = 0x25BC;
    t['a78'] = 0x25C6;
    t['a79'] = 0x2756;
    t['a81'] = 0x25D7;
    t['a82'] = 0x2758;
    t['a83'] = 0x2759;
    t['a84'] = 0x275A;
    t['a97'] = 0x275B;
    t['a98'] = 0x275C;
    t['a99'] = 0x275D;
    t['a100'] = 0x275E;
    t['a101'] = 0x2761;
    t['a102'] = 0x2762;
    t['a103'] = 0x2763;
    t['a104'] = 0x2764;
    t['a106'] = 0x2765;
    t['a107'] = 0x2766;
    t['a108'] = 0x2767;
    t['a112'] = 0x2663;
    t['a111'] = 0x2666;
    t['a110'] = 0x2665;
    t['a109'] = 0x2660;
    t['a120'] = 0x2460;
    t['a121'] = 0x2461;
    t['a122'] = 0x2462;
    t['a123'] = 0x2463;
    t['a124'] = 0x2464;
    t['a125'] = 0x2465;
    t['a126'] = 0x2466;
    t['a127'] = 0x2467;
    t['a128'] = 0x2468;
    t['a129'] = 0x2469;
    t['a130'] = 0x2776;
    t['a131'] = 0x2777;
    t['a132'] = 0x2778;
    t['a133'] = 0x2779;
    t['a134'] = 0x277A;
    t['a135'] = 0x277B;
    t['a136'] = 0x277C;
    t['a137'] = 0x277D;
    t['a138'] = 0x277E;
    t['a139'] = 0x277F;
    t['a140'] = 0x2780;
    t['a141'] = 0x2781;
    t['a142'] = 0x2782;
    t['a143'] = 0x2783;
    t['a144'] = 0x2784;
    t['a145'] = 0x2785;
    t['a146'] = 0x2786;
    t['a147'] = 0x2787;
    t['a148'] = 0x2788;
    t['a149'] = 0x2789;
    t['a150'] = 0x278A;
    t['a151'] = 0x278B;
    t['a152'] = 0x278C;
    t['a153'] = 0x278D;
    t['a154'] = 0x278E;
    t['a155'] = 0x278F;
    t['a156'] = 0x2790;
    t['a157'] = 0x2791;
    t['a158'] = 0x2792;
    t['a159'] = 0x2793;
    t['a160'] = 0x2794;
    t['a161'] = 0x2192;
    t['a163'] = 0x2194;
    t['a164'] = 0x2195;
    t['a196'] = 0x2798;
    t['a165'] = 0x2799;
    t['a192'] = 0x279A;
    t['a166'] = 0x279B;
    t['a167'] = 0x279C;
    t['a168'] = 0x279D;
    t['a169'] = 0x279E;
    t['a170'] = 0x279F;
    t['a171'] = 0x27A0;
    t['a172'] = 0x27A1;
    t['a173'] = 0x27A2;
    t['a162'] = 0x27A3;
    t['a174'] = 0x27A4;
    t['a175'] = 0x27A5;
    t['a176'] = 0x27A6;
    t['a177'] = 0x27A7;
    t['a178'] = 0x27A8;
    t['a179'] = 0x27A9;
    t['a193'] = 0x27AA;
    t['a180'] = 0x27AB;
    t['a199'] = 0x27AC;
    t['a181'] = 0x27AD;
    t['a200'] = 0x27AE;
    t['a182'] = 0x27AF;
    t['a201'] = 0x27B1;
    t['a183'] = 0x27B2;
    t['a184'] = 0x27B3;
    t['a197'] = 0x27B4;
    t['a185'] = 0x27B5;
    t['a194'] = 0x27B6;
    t['a198'] = 0x27B7;
    t['a186'] = 0x27B8;
    t['a195'] = 0x27B9;
    t['a187'] = 0x27BA;
    t['a188'] = 0x27BB;
    t['a189'] = 0x27BC;
    t['a190'] = 0x27BD;
    t['a191'] = 0x27BE;
    t['a89'] = 0x2768;
    t['a90'] = 0x2769;
    t['a93'] = 0x276A;
    t['a94'] = 0x276B;
    t['a91'] = 0x276C;
    t['a92'] = 0x276D;
    t['a205'] = 0x276E;
    t['a85'] = 0x276F;
    t['a206'] = 0x2770;
    t['a86'] = 0x2771;
    t['a87'] = 0x2772;
    t['a88'] = 0x2773;
    t['a95'] = 0x2774;
    t['a96'] = 0x2775;
    t['.notdef'] = 0x0000;
   });
   exports.getGlyphsUnicode = getGlyphsUnicode;
   exports.getDingbatsGlyphsUnicode = getDingbatsGlyphsUnicode;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreJbig2 = {}, root.pdfjsSharedUtil, root.pdfjsCoreArithmeticDecoder);
  }(this, function (exports, sharedUtil, coreArithmeticDecoder) {
   var error = sharedUtil.error;
   var log2 = sharedUtil.log2;
   var readInt8 = sharedUtil.readInt8;
   var readUint16 = sharedUtil.readUint16;
   var readUint32 = sharedUtil.readUint32;
   var shadow = sharedUtil.shadow;
   var ArithmeticDecoder = coreArithmeticDecoder.ArithmeticDecoder;
   var Jbig2Image = function Jbig2ImageClosure() {
    function ContextCache() {
    }
    ContextCache.prototype = {
     getContexts: function (id) {
      if (id in this) {
       return this[id];
      }
      return this[id] = new Int8Array(1 << 16);
     }
    };
    function DecodingContext(data, start, end) {
     this.data = data;
     this.start = start;
     this.end = end;
    }
    DecodingContext.prototype = {
     get decoder() {
      var decoder = new ArithmeticDecoder(this.data, this.start, this.end);
      return shadow(this, 'decoder', decoder);
     },
     get contextCache() {
      var cache = new ContextCache();
      return shadow(this, 'contextCache', cache);
     }
    };
    function decodeInteger(contextCache, procedure, decoder) {
     var contexts = contextCache.getContexts(procedure);
     var prev = 1;
     function readBits(length) {
      var v = 0;
      for (var i = 0; i < length; i++) {
       var bit = decoder.readBit(contexts, prev);
       prev = prev < 256 ? prev << 1 | bit : (prev << 1 | bit) & 511 | 256;
       v = v << 1 | bit;
      }
      return v >>> 0;
     }
     var sign = readBits(1);
     var value = readBits(1) ? readBits(1) ? readBits(1) ? readBits(1) ? readBits(1) ? readBits(32) + 4436 : readBits(12) + 340 : readBits(8) + 84 : readBits(6) + 20 : readBits(4) + 4 : readBits(2);
     return sign === 0 ? value : value > 0 ? -value : null;
    }
    function decodeIAID(contextCache, decoder, codeLength) {
     var contexts = contextCache.getContexts('IAID');
     var prev = 1;
     for (var i = 0; i < codeLength; i++) {
      var bit = decoder.readBit(contexts, prev);
      prev = prev << 1 | bit;
     }
     if (codeLength < 31) {
      return prev & (1 << codeLength) - 1;
     }
     return prev & 0x7FFFFFFF;
    }
    var SegmentTypes = [
     'SymbolDictionary',
     null,
     null,
     null,
     'IntermediateTextRegion',
     null,
     'ImmediateTextRegion',
     'ImmediateLosslessTextRegion',
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     'patternDictionary',
     null,
     null,
     null,
     'IntermediateHalftoneRegion',
     null,
     'ImmediateHalftoneRegion',
     'ImmediateLosslessHalftoneRegion',
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     'IntermediateGenericRegion',
     null,
     'ImmediateGenericRegion',
     'ImmediateLosslessGenericRegion',
     'IntermediateGenericRefinementRegion',
     null,
     'ImmediateGenericRefinementRegion',
     'ImmediateLosslessGenericRefinementRegion',
     null,
     null,
     null,
     null,
     'PageInformation',
     'EndOfPage',
     'EndOfStripe',
     'EndOfFile',
     'Profiles',
     'Tables',
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     null,
     'Extension'
    ];
    var CodingTemplates = [
     [
      {
       x: -1,
       y: -2
      },
      {
       x: 0,
       y: -2
      },
      {
       x: 1,
       y: -2
      },
      {
       x: -2,
       y: -1
      },
      {
       x: -1,
       y: -1
      },
      {
       x: 0,
       y: -1
      },
      {
       x: 1,
       y: -1
      },
      {
       x: 2,
       y: -1
      },
      {
       x: -4,
       y: 0
      },
      {
       x: -3,
       y: 0
      },
      {
       x: -2,
       y: 0
      },
      {
       x: -1,
       y: 0
      }
     ],
     [
      {
       x: -1,
       y: -2
      },
      {
       x: 0,
       y: -2
      },
      {
       x: 1,
       y: -2
      },
      {
       x: 2,
       y: -2
      },
      {
       x: -2,
       y: -1
      },
      {
       x: -1,
       y: -1
      },
      {
       x: 0,
       y: -1
      },
      {
       x: 1,
       y: -1
      },
      {
       x: 2,
       y: -1
      },
      {
       x: -3,
       y: 0
      },
      {
       x: -2,
       y: 0
      },
      {
       x: -1,
       y: 0
      }
     ],
     [
      {
       x: -1,
       y: -2
      },
      {
       x: 0,
       y: -2
      },
      {
       x: 1,
       y: -2
      },
      {
       x: -2,
       y: -1
      },
      {
       x: -1,
       y: -1
      },
      {
       x: 0,
       y: -1
      },
      {
       x: 1,
       y: -1
      },
      {
       x: -2,
       y: 0
      },
      {
       x: -1,
       y: 0
      }
     ],
     [
      {
       x: -3,
       y: -1
      },
      {
       x: -2,
       y: -1
      },
      {
       x: -1,
       y: -1
      },
      {
       x: 0,
       y: -1
      },
      {
       x: 1,
       y: -1
      },
      {
       x: -4,
       y: 0
      },
      {
       x: -3,
       y: 0
      },
      {
       x: -2,
       y: 0
      },
      {
       x: -1,
       y: 0
      }
     ]
    ];
    var RefinementTemplates = [
     {
      coding: [
       {
        x: 0,
        y: -1
       },
       {
        x: 1,
        y: -1
       },
       {
        x: -1,
        y: 0
       }
      ],
      reference: [
       {
        x: 0,
        y: -1
       },
       {
        x: 1,
        y: -1
       },
       {
        x: -1,
        y: 0
       },
       {
        x: 0,
        y: 0
       },
       {
        x: 1,
        y: 0
       },
       {
        x: -1,
        y: 1
       },
       {
        x: 0,
        y: 1
       },
       {
        x: 1,
        y: 1
       }
      ]
     },
     {
      coding: [
       {
        x: -1,
        y: -1
       },
       {
        x: 0,
        y: -1
       },
       {
        x: 1,
        y: -1
       },
       {
        x: -1,
        y: 0
       }
      ],
      reference: [
       {
        x: 0,
        y: -1
       },
       {
        x: -1,
        y: 0
       },
       {
        x: 0,
        y: 0
       },
       {
        x: 1,
        y: 0
       },
       {
        x: 0,
        y: 1
       },
       {
        x: 1,
        y: 1
       }
      ]
     }
    ];
    var ReusedContexts = [
     0x9B25,
     0x0795,
     0x00E5,
     0x0195
    ];
    var RefinementReusedContexts = [
     0x0020,
     0x0008
    ];
    function decodeBitmapTemplate0(width, height, decodingContext) {
     var decoder = decodingContext.decoder;
     var contexts = decodingContext.contextCache.getContexts('GB');
     var contextLabel, i, j, pixel, row, row1, row2, bitmap = [];
     var OLD_PIXEL_MASK = 0x7BF7;
     for (i = 0; i < height; i++) {
      row = bitmap[i] = new Uint8Array(width);
      row1 = i < 1 ? row : bitmap[i - 1];
      row2 = i < 2 ? row : bitmap[i - 2];
      contextLabel = row2[0] << 13 | row2[1] << 12 | row2[2] << 11 | row1[0] << 7 | row1[1] << 6 | row1[2] << 5 | row1[3] << 4;
      for (j = 0; j < width; j++) {
       row[j] = pixel = decoder.readBit(contexts, contextLabel);
       contextLabel = (contextLabel & OLD_PIXEL_MASK) << 1 | (j + 3 < width ? row2[j + 3] << 11 : 0) | (j + 4 < width ? row1[j + 4] << 4 : 0) | pixel;
      }
     }
     return bitmap;
    }
    function decodeBitmap(mmr, width, height, templateIndex, prediction, skip, at, decodingContext) {
     if (mmr) {
      error('JBIG2 error: MMR encoding is not supported');
     }
     if (templateIndex === 0 && !skip && !prediction && at.length === 4 && at[0].x === 3 && at[0].y === -1 && at[1].x === -3 && at[1].y === -1 && at[2].x === 2 && at[2].y === -2 && at[3].x === -2 && at[3].y === -2) {
      return decodeBitmapTemplate0(width, height, decodingContext);
     }
     var useskip = !!skip;
     var template = CodingTemplates[templateIndex].concat(at);
     template.sort(function (a, b) {
      return a.y - b.y || a.x - b.x;
     });
     var templateLength = template.length;
     var templateX = new Int8Array(templateLength);
     var templateY = new Int8Array(templateLength);
     var changingTemplateEntries = [];
     var reuseMask = 0, minX = 0, maxX = 0, minY = 0;
     var c, k;
     for (k = 0; k < templateLength; k++) {
      templateX[k] = template[k].x;
      templateY[k] = template[k].y;
      minX = Math.min(minX, template[k].x);
      maxX = Math.max(maxX, template[k].x);
      minY = Math.min(minY, template[k].y);
      if (k < templateLength - 1 && template[k].y === template[k + 1].y && template[k].x === template[k + 1].x - 1) {
       reuseMask |= 1 << templateLength - 1 - k;
      } else {
       changingTemplateEntries.push(k);
      }
     }
     var changingEntriesLength = changingTemplateEntries.length;
     var changingTemplateX = new Int8Array(changingEntriesLength);
     var changingTemplateY = new Int8Array(changingEntriesLength);
     var changingTemplateBit = new Uint16Array(changingEntriesLength);
     for (c = 0; c < changingEntriesLength; c++) {
      k = changingTemplateEntries[c];
      changingTemplateX[c] = template[k].x;
      changingTemplateY[c] = template[k].y;
      changingTemplateBit[c] = 1 << templateLength - 1 - k;
     }
     var sbb_left = -minX;
     var sbb_top = -minY;
     var sbb_right = width - maxX;
     var pseudoPixelContext = ReusedContexts[templateIndex];
     var row = new Uint8Array(width);
     var bitmap = [];
     var decoder = decodingContext.decoder;
     var contexts = decodingContext.contextCache.getContexts('GB');
     var ltp = 0, j, i0, j0, contextLabel = 0, bit, shift;
     for (var i = 0; i < height; i++) {
      if (prediction) {
       var sltp = decoder.readBit(contexts, pseudoPixelContext);
       ltp ^= sltp;
       if (ltp) {
        bitmap.push(row);
        continue;
       }
      }
      row = new Uint8Array(row);
      bitmap.push(row);
      for (j = 0; j < width; j++) {
       if (useskip && skip[i][j]) {
        row[j] = 0;
        continue;
       }
       if (j >= sbb_left && j < sbb_right && i >= sbb_top) {
        contextLabel = contextLabel << 1 & reuseMask;
        for (k = 0; k < changingEntriesLength; k++) {
         i0 = i + changingTemplateY[k];
         j0 = j + changingTemplateX[k];
         bit = bitmap[i0][j0];
         if (bit) {
          bit = changingTemplateBit[k];
          contextLabel |= bit;
         }
        }
       } else {
        contextLabel = 0;
        shift = templateLength - 1;
        for (k = 0; k < templateLength; k++, shift--) {
         j0 = j + templateX[k];
         if (j0 >= 0 && j0 < width) {
          i0 = i + templateY[k];
          if (i0 >= 0) {
           bit = bitmap[i0][j0];
           if (bit) {
            contextLabel |= bit << shift;
           }
          }
         }
        }
       }
       var pixel = decoder.readBit(contexts, contextLabel);
       row[j] = pixel;
      }
     }
     return bitmap;
    }
    function decodeRefinement(width, height, templateIndex, referenceBitmap, offsetX, offsetY, prediction, at, decodingContext) {
     var codingTemplate = RefinementTemplates[templateIndex].coding;
     if (templateIndex === 0) {
      codingTemplate = codingTemplate.concat([at[0]]);
     }
     var codingTemplateLength = codingTemplate.length;
     var codingTemplateX = new Int32Array(codingTemplateLength);
     var codingTemplateY = new Int32Array(codingTemplateLength);
     var k;
     for (k = 0; k < codingTemplateLength; k++) {
      codingTemplateX[k] = codingTemplate[k].x;
      codingTemplateY[k] = codingTemplate[k].y;
     }
     var referenceTemplate = RefinementTemplates[templateIndex].reference;
     if (templateIndex === 0) {
      referenceTemplate = referenceTemplate.concat([at[1]]);
     }
     var referenceTemplateLength = referenceTemplate.length;
     var referenceTemplateX = new Int32Array(referenceTemplateLength);
     var referenceTemplateY = new Int32Array(referenceTemplateLength);
     for (k = 0; k < referenceTemplateLength; k++) {
      referenceTemplateX[k] = referenceTemplate[k].x;
      referenceTemplateY[k] = referenceTemplate[k].y;
     }
     var referenceWidth = referenceBitmap[0].length;
     var referenceHeight = referenceBitmap.length;
     var pseudoPixelContext = RefinementReusedContexts[templateIndex];
     var bitmap = [];
     var decoder = decodingContext.decoder;
     var contexts = decodingContext.contextCache.getContexts('GR');
     var ltp = 0;
     for (var i = 0; i < height; i++) {
      if (prediction) {
       var sltp = decoder.readBit(contexts, pseudoPixelContext);
       ltp ^= sltp;
       if (ltp) {
        error('JBIG2 error: prediction is not supported');
       }
      }
      var row = new Uint8Array(width);
      bitmap.push(row);
      for (var j = 0; j < width; j++) {
       var i0, j0;
       var contextLabel = 0;
       for (k = 0; k < codingTemplateLength; k++) {
        i0 = i + codingTemplateY[k];
        j0 = j + codingTemplateX[k];
        if (i0 < 0 || j0 < 0 || j0 >= width) {
         contextLabel <<= 1;
        } else
         {
          contextLabel = contextLabel << 1 | bitmap[i0][j0];
         }
       }
       for (k = 0; k < referenceTemplateLength; k++) {
        i0 = i + referenceTemplateY[k] + offsetY;
        j0 = j + referenceTemplateX[k] + offsetX;
        if (i0 < 0 || i0 >= referenceHeight || j0 < 0 || j0 >= referenceWidth) {
         contextLabel <<= 1;
        } else
         {
          contextLabel = contextLabel << 1 | referenceBitmap[i0][j0];
         }
       }
       var pixel = decoder.readBit(contexts, contextLabel);
       row[j] = pixel;
      }
     }
     return bitmap;
    }
    function decodeSymbolDictionary(huffman, refinement, symbols, numberOfNewSymbols, numberOfExportedSymbols, huffmanTables, templateIndex, at, refinementTemplateIndex, refinementAt, decodingContext) {
     if (huffman) {
      error('JBIG2 error: huffman is not supported');
     }
     var newSymbols = [];
     var currentHeight = 0;
     var symbolCodeLength = log2(symbols.length + numberOfNewSymbols);
     var decoder = decodingContext.decoder;
     var contextCache = decodingContext.contextCache;
     while (newSymbols.length < numberOfNewSymbols) {
      var deltaHeight = decodeInteger(contextCache, 'IADH', decoder);
      currentHeight += deltaHeight;
      var currentWidth = 0;
      var totalWidth = 0;
      while (true) {
       var deltaWidth = decodeInteger(contextCache, 'IADW', decoder);
       if (deltaWidth === null) {
        break;
       }
       currentWidth += deltaWidth;
       totalWidth += currentWidth;
       var bitmap;
       if (refinement) {
        var numberOfInstances = decodeInteger(contextCache, 'IAAI', decoder);
        if (numberOfInstances > 1) {
         bitmap = decodeTextRegion(huffman, refinement, currentWidth, currentHeight, 0, numberOfInstances, 1, symbols.concat(newSymbols), symbolCodeLength, 0, 0, 1, 0, huffmanTables, refinementTemplateIndex, refinementAt, decodingContext);
        } else {
         var symbolId = decodeIAID(contextCache, decoder, symbolCodeLength);
         var rdx = decodeInteger(contextCache, 'IARDX', decoder);
         var rdy = decodeInteger(contextCache, 'IARDY', decoder);
         var symbol = symbolId < symbols.length ? symbols[symbolId] : newSymbols[symbolId - symbols.length];
         bitmap = decodeRefinement(currentWidth, currentHeight, refinementTemplateIndex, symbol, rdx, rdy, false, refinementAt, decodingContext);
        }
       } else {
        bitmap = decodeBitmap(false, currentWidth, currentHeight, templateIndex, false, null, at, decodingContext);
       }
       newSymbols.push(bitmap);
      }
     }
     var exportedSymbols = [];
     var flags = [], currentFlag = false;
     var totalSymbolsLength = symbols.length + numberOfNewSymbols;
     while (flags.length < totalSymbolsLength) {
      var runLength = decodeInteger(contextCache, 'IAEX', decoder);
      while (runLength--) {
       flags.push(currentFlag);
      }
      currentFlag = !currentFlag;
     }
     for (var i = 0, ii = symbols.length; i < ii; i++) {
      if (flags[i]) {
       exportedSymbols.push(symbols[i]);
      }
     }
     for (var j = 0; j < numberOfNewSymbols; i++, j++) {
      if (flags[i]) {
       exportedSymbols.push(newSymbols[j]);
      }
     }
     return exportedSymbols;
    }
    function decodeTextRegion(huffman, refinement, width, height, defaultPixelValue, numberOfSymbolInstances, stripSize, inputSymbols, symbolCodeLength, transposed, dsOffset, referenceCorner, combinationOperator, huffmanTables, refinementTemplateIndex, refinementAt, decodingContext) {
     if (huffman) {
      error('JBIG2 error: huffman is not supported');
     }
     var bitmap = [];
     var i, row;
     for (i = 0; i < height; i++) {
      row = new Uint8Array(width);
      if (defaultPixelValue) {
       for (var j = 0; j < width; j++) {
        row[j] = defaultPixelValue;
       }
      }
      bitmap.push(row);
     }
     var decoder = decodingContext.decoder;
     var contextCache = decodingContext.contextCache;
     var stripT = -decodeInteger(contextCache, 'IADT', decoder);
     var firstS = 0;
     i = 0;
     while (i < numberOfSymbolInstances) {
      var deltaT = decodeInteger(contextCache, 'IADT', decoder);
      stripT += deltaT;
      var deltaFirstS = decodeInteger(contextCache, 'IAFS', decoder);
      firstS += deltaFirstS;
      var currentS = firstS;
      do {
       var currentT = stripSize === 1 ? 0 : decodeInteger(contextCache, 'IAIT', decoder);
       var t = stripSize * stripT + currentT;
       var symbolId = decodeIAID(contextCache, decoder, symbolCodeLength);
       var applyRefinement = refinement && decodeInteger(contextCache, 'IARI', decoder);
       var symbolBitmap = inputSymbols[symbolId];
       var symbolWidth = symbolBitmap[0].length;
       var symbolHeight = symbolBitmap.length;
       if (applyRefinement) {
        var rdw = decodeInteger(contextCache, 'IARDW', decoder);
        var rdh = decodeInteger(contextCache, 'IARDH', decoder);
        var rdx = decodeInteger(contextCache, 'IARDX', decoder);
        var rdy = decodeInteger(contextCache, 'IARDY', decoder);
        symbolWidth += rdw;
        symbolHeight += rdh;
        symbolBitmap = decodeRefinement(symbolWidth, symbolHeight, refinementTemplateIndex, symbolBitmap, (rdw >> 1) + rdx, (rdh >> 1) + rdy, false, refinementAt, decodingContext);
       }
       var offsetT = t - (referenceCorner & 1 ? 0 : symbolHeight);
       var offsetS = currentS - (referenceCorner & 2 ? symbolWidth : 0);
       var s2, t2, symbolRow;
       if (transposed) {
        for (s2 = 0; s2 < symbolHeight; s2++) {
         row = bitmap[offsetS + s2];
         if (!row) {
          continue;
         }
         symbolRow = symbolBitmap[s2];
         var maxWidth = Math.min(width - offsetT, symbolWidth);
         switch (combinationOperator) {
         case 0:
          for (t2 = 0; t2 < maxWidth; t2++) {
           row[offsetT + t2] |= symbolRow[t2];
          }
          break;
         case 2:
          for (t2 = 0; t2 < maxWidth; t2++) {
           row[offsetT + t2] ^= symbolRow[t2];
          }
          break;
         default:
          error('JBIG2 error: operator ' + combinationOperator + ' is not supported');
         }
        }
        currentS += symbolHeight - 1;
       } else {
        for (t2 = 0; t2 < symbolHeight; t2++) {
         row = bitmap[offsetT + t2];
         if (!row) {
          continue;
         }
         symbolRow = symbolBitmap[t2];
         switch (combinationOperator) {
         case 0:
          for (s2 = 0; s2 < symbolWidth; s2++) {
           row[offsetS + s2] |= symbolRow[s2];
          }
          break;
         case 2:
          for (s2 = 0; s2 < symbolWidth; s2++) {
           row[offsetS + s2] ^= symbolRow[s2];
          }
          break;
         default:
          error('JBIG2 error: operator ' + combinationOperator + ' is not supported');
         }
        }
        currentS += symbolWidth - 1;
       }
       i++;
       var deltaS = decodeInteger(contextCache, 'IADS', decoder);
       if (deltaS === null) {
        break;
       }
       currentS += deltaS + dsOffset;
      } while (true);
     }
     return bitmap;
    }
    function readSegmentHeader(data, start) {
     var segmentHeader = {};
     segmentHeader.number = readUint32(data, start);
     var flags = data[start + 4];
     var segmentType = flags & 0x3F;
     if (!SegmentTypes[segmentType]) {
      error('JBIG2 error: invalid segment type: ' + segmentType);
     }
     segmentHeader.type = segmentType;
     segmentHeader.typeName = SegmentTypes[segmentType];
     segmentHeader.deferredNonRetain = !!(flags & 0x80);
     var pageAssociationFieldSize = !!(flags & 0x40);
     var referredFlags = data[start + 5];
     var referredToCount = referredFlags >> 5 & 7;
     var retainBits = [referredFlags & 31];
     var position = start + 6;
     if (referredFlags === 7) {
      referredToCount = readUint32(data, position - 1) & 0x1FFFFFFF;
      position += 3;
      var bytes = referredToCount + 7 >> 3;
      retainBits[0] = data[position++];
      while (--bytes > 0) {
       retainBits.push(data[position++]);
      }
     } else if (referredFlags === 5 || referredFlags === 6) {
      error('JBIG2 error: invalid referred-to flags');
     }
     segmentHeader.retainBits = retainBits;
     var referredToSegmentNumberSize = segmentHeader.number <= 256 ? 1 : segmentHeader.number <= 65536 ? 2 : 4;
     var referredTo = [];
     var i, ii;
     for (i = 0; i < referredToCount; i++) {
      var number = referredToSegmentNumberSize === 1 ? data[position] : referredToSegmentNumberSize === 2 ? readUint16(data, position) : readUint32(data, position);
      referredTo.push(number);
      position += referredToSegmentNumberSize;
     }
     segmentHeader.referredTo = referredTo;
     if (!pageAssociationFieldSize) {
      segmentHeader.pageAssociation = data[position++];
     } else {
      segmentHeader.pageAssociation = readUint32(data, position);
      position += 4;
     }
     segmentHeader.length = readUint32(data, position);
     position += 4;
     if (segmentHeader.length === 0xFFFFFFFF) {
      if (segmentType === 38) {
       var genericRegionInfo = readRegionSegmentInformation(data, position);
       var genericRegionSegmentFlags = data[position + RegionSegmentInformationFieldLength];
       var genericRegionMmr = !!(genericRegionSegmentFlags & 1);
       var searchPatternLength = 6;
       var searchPattern = new Uint8Array(searchPatternLength);
       if (!genericRegionMmr) {
        searchPattern[0] = 0xFF;
        searchPattern[1] = 0xAC;
       }
       searchPattern[2] = genericRegionInfo.height >>> 24 & 0xFF;
       searchPattern[3] = genericRegionInfo.height >> 16 & 0xFF;
       searchPattern[4] = genericRegionInfo.height >> 8 & 0xFF;
       searchPattern[5] = genericRegionInfo.height & 0xFF;
       for (i = position, ii = data.length; i < ii; i++) {
        var j = 0;
        while (j < searchPatternLength && searchPattern[j] === data[i + j]) {
         j++;
        }
        if (j === searchPatternLength) {
         segmentHeader.length = i + searchPatternLength;
         break;
        }
       }
       if (segmentHeader.length === 0xFFFFFFFF) {
        error('JBIG2 error: segment end was not found');
       }
      } else {
       error('JBIG2 error: invalid unknown segment length');
      }
     }
     segmentHeader.headerEnd = position;
     return segmentHeader;
    }
    function readSegments(header, data, start, end) {
     var segments = [];
     var position = start;
     while (position < end) {
      var segmentHeader = readSegmentHeader(data, position);
      position = segmentHeader.headerEnd;
      var segment = {
       header: segmentHeader,
       data: data
      };
      if (!header.randomAccess) {
       segment.start = position;
       position += segmentHeader.length;
       segment.end = position;
      }
      segments.push(segment);
      if (segmentHeader.type === 51) {
       break;
      }
     }
     if (header.randomAccess) {
      for (var i = 0, ii = segments.length; i < ii; i++) {
       segments[i].start = position;
       position += segments[i].header.length;
       segments[i].end = position;
      }
     }
     return segments;
    }
    function readRegionSegmentInformation(data, start) {
     return {
      width: readUint32(data, start),
      height: readUint32(data, start + 4),
      x: readUint32(data, start + 8),
      y: readUint32(data, start + 12),
      combinationOperator: data[start + 16] & 7
     };
    }
    var RegionSegmentInformationFieldLength = 17;
    function processSegment(segment, visitor) {
     var header = segment.header;
     var data = segment.data, position = segment.start, end = segment.end;
     var args, at, i, atLength;
     switch (header.type) {
     case 0:
      var dictionary = {};
      var dictionaryFlags = readUint16(data, position);
      dictionary.huffman = !!(dictionaryFlags & 1);
      dictionary.refinement = !!(dictionaryFlags & 2);
      dictionary.huffmanDHSelector = dictionaryFlags >> 2 & 3;
      dictionary.huffmanDWSelector = dictionaryFlags >> 4 & 3;
      dictionary.bitmapSizeSelector = dictionaryFlags >> 6 & 1;
      dictionary.aggregationInstancesSelector = dictionaryFlags >> 7 & 1;
      dictionary.bitmapCodingContextUsed = !!(dictionaryFlags & 256);
      dictionary.bitmapCodingContextRetained = !!(dictionaryFlags & 512);
      dictionary.template = dictionaryFlags >> 10 & 3;
      dictionary.refinementTemplate = dictionaryFlags >> 12 & 1;
      position += 2;
      if (!dictionary.huffman) {
       atLength = dictionary.template === 0 ? 4 : 1;
       at = [];
       for (i = 0; i < atLength; i++) {
        at.push({
         x: readInt8(data, position),
         y: readInt8(data, position + 1)
        });
        position += 2;
       }
       dictionary.at = at;
      }
      if (dictionary.refinement && !dictionary.refinementTemplate) {
       at = [];
       for (i = 0; i < 2; i++) {
        at.push({
         x: readInt8(data, position),
         y: readInt8(data, position + 1)
        });
        position += 2;
       }
       dictionary.refinementAt = at;
      }
      dictionary.numberOfExportedSymbols = readUint32(data, position);
      position += 4;
      dictionary.numberOfNewSymbols = readUint32(data, position);
      position += 4;
      args = [
       dictionary,
       header.number,
       header.referredTo,
       data,
       position,
       end
      ];
      break;
     case 6:
     case 7:
      var textRegion = {};
      textRegion.info = readRegionSegmentInformation(data, position);
      position += RegionSegmentInformationFieldLength;
      var textRegionSegmentFlags = readUint16(data, position);
      position += 2;
      textRegion.huffman = !!(textRegionSegmentFlags & 1);
      textRegion.refinement = !!(textRegionSegmentFlags & 2);
      textRegion.stripSize = 1 << (textRegionSegmentFlags >> 2 & 3);
      textRegion.referenceCorner = textRegionSegmentFlags >> 4 & 3;
      textRegion.transposed = !!(textRegionSegmentFlags & 64);
      textRegion.combinationOperator = textRegionSegmentFlags >> 7 & 3;
      textRegion.defaultPixelValue = textRegionSegmentFlags >> 9 & 1;
      textRegion.dsOffset = textRegionSegmentFlags << 17 >> 27;
      textRegion.refinementTemplate = textRegionSegmentFlags >> 15 & 1;
      if (textRegion.huffman) {
       var textRegionHuffmanFlags = readUint16(data, position);
       position += 2;
       textRegion.huffmanFS = textRegionHuffmanFlags & 3;
       textRegion.huffmanDS = textRegionHuffmanFlags >> 2 & 3;
       textRegion.huffmanDT = textRegionHuffmanFlags >> 4 & 3;
       textRegion.huffmanRefinementDW = textRegionHuffmanFlags >> 6 & 3;
       textRegion.huffmanRefinementDH = textRegionHuffmanFlags >> 8 & 3;
       textRegion.huffmanRefinementDX = textRegionHuffmanFlags >> 10 & 3;
       textRegion.huffmanRefinementDY = textRegionHuffmanFlags >> 12 & 3;
       textRegion.huffmanRefinementSizeSelector = !!(textRegionHuffmanFlags & 14);
      }
      if (textRegion.refinement && !textRegion.refinementTemplate) {
       at = [];
       for (i = 0; i < 2; i++) {
        at.push({
         x: readInt8(data, position),
         y: readInt8(data, position + 1)
        });
        position += 2;
       }
       textRegion.refinementAt = at;
      }
      textRegion.numberOfSymbolInstances = readUint32(data, position);
      position += 4;
      if (textRegion.huffman) {
       error('JBIG2 error: huffman is not supported');
      }
      args = [
       textRegion,
       header.referredTo,
       data,
       position,
       end
      ];
      break;
     case 38:
     case 39:
      var genericRegion = {};
      genericRegion.info = readRegionSegmentInformation(data, position);
      position += RegionSegmentInformationFieldLength;
      var genericRegionSegmentFlags = data[position++];
      genericRegion.mmr = !!(genericRegionSegmentFlags & 1);
      genericRegion.template = genericRegionSegmentFlags >> 1 & 3;
      genericRegion.prediction = !!(genericRegionSegmentFlags & 8);
      if (!genericRegion.mmr) {
       atLength = genericRegion.template === 0 ? 4 : 1;
       at = [];
       for (i = 0; i < atLength; i++) {
        at.push({
         x: readInt8(data, position),
         y: readInt8(data, position + 1)
        });
        position += 2;
       }
       genericRegion.at = at;
      }
      args = [
       genericRegion,
       data,
       position,
       end
      ];
      break;
     case 48:
      var pageInfo = {
       width: readUint32(data, position),
       height: readUint32(data, position + 4),
       resolutionX: readUint32(data, position + 8),
       resolutionY: readUint32(data, position + 12)
      };
      if (pageInfo.height === 0xFFFFFFFF) {
       delete pageInfo.height;
      }
      var pageSegmentFlags = data[position + 16];
      var pageStripingInformation = readUint16(data, position + 17);
      pageInfo.lossless = !!(pageSegmentFlags & 1);
      pageInfo.refinement = !!(pageSegmentFlags & 2);
      pageInfo.defaultPixelValue = pageSegmentFlags >> 2 & 1;
      pageInfo.combinationOperator = pageSegmentFlags >> 3 & 3;
      pageInfo.requiresBuffer = !!(pageSegmentFlags & 32);
      pageInfo.combinationOperatorOverride = !!(pageSegmentFlags & 64);
      args = [pageInfo];
      break;
     case 49:
      break;
     case 50:
      break;
     case 51:
      break;
     case 62:
      break;
     default:
      error('JBIG2 error: segment type ' + header.typeName + '(' + header.type + ') is not implemented');
     }
     var callbackName = 'on' + header.typeName;
     if (callbackName in visitor) {
      visitor[callbackName].apply(visitor, args);
     }
    }
    function processSegments(segments, visitor) {
     for (var i = 0, ii = segments.length; i < ii; i++) {
      processSegment(segments[i], visitor);
     }
    }
    function parseJbig2(data, start, end) {
     var position = start;
     if (data[position] !== 0x97 || data[position + 1] !== 0x4A || data[position + 2] !== 0x42 || data[position + 3] !== 0x32 || data[position + 4] !== 0x0D || data[position + 5] !== 0x0A || data[position + 6] !== 0x1A || data[position + 7] !== 0x0A) {
      error('JBIG2 error: invalid header');
     }
     var header = {};
     position += 8;
     var flags = data[position++];
     header.randomAccess = !(flags & 1);
     if (!(flags & 2)) {
      header.numberOfPages = readUint32(data, position);
      position += 4;
     }
     var segments = readSegments(header, data, position, end);
     error('Not implemented');
    }
    function parseJbig2Chunks(chunks) {
     var visitor = new SimpleSegmentVisitor();
     for (var i = 0, ii = chunks.length; i < ii; i++) {
      var chunk = chunks[i];
      var segments = readSegments({}, chunk.data, chunk.start, chunk.end);
      processSegments(segments, visitor);
     }
     return visitor.buffer;
    }
    function SimpleSegmentVisitor() {
    }
    SimpleSegmentVisitor.prototype = {
     onPageInformation: function SimpleSegmentVisitor_onPageInformation(info) {
      this.currentPageInfo = info;
      var rowSize = info.width + 7 >> 3;
      var buffer = new Uint8Array(rowSize * info.height);
      if (info.defaultPixelValue) {
       for (var i = 0, ii = buffer.length; i < ii; i++) {
        buffer[i] = 0xFF;
       }
      }
      this.buffer = buffer;
     },
     drawBitmap: function SimpleSegmentVisitor_drawBitmap(regionInfo, bitmap) {
      var pageInfo = this.currentPageInfo;
      var width = regionInfo.width, height = regionInfo.height;
      var rowSize = pageInfo.width + 7 >> 3;
      var combinationOperator = pageInfo.combinationOperatorOverride ? regionInfo.combinationOperator : pageInfo.combinationOperator;
      var buffer = this.buffer;
      var mask0 = 128 >> (regionInfo.x & 7);
      var offset0 = regionInfo.y * rowSize + (regionInfo.x >> 3);
      var i, j, mask, offset;
      switch (combinationOperator) {
      case 0:
       for (i = 0; i < height; i++) {
        mask = mask0;
        offset = offset0;
        for (j = 0; j < width; j++) {
         if (bitmap[i][j]) {
          buffer[offset] |= mask;
         }
         mask >>= 1;
         if (!mask) {
          mask = 128;
          offset++;
         }
        }
        offset0 += rowSize;
       }
       break;
      case 2:
       for (i = 0; i < height; i++) {
        mask = mask0;
        offset = offset0;
        for (j = 0; j < width; j++) {
         if (bitmap[i][j]) {
          buffer[offset] ^= mask;
         }
         mask >>= 1;
         if (!mask) {
          mask = 128;
          offset++;
         }
        }
        offset0 += rowSize;
       }
       break;
      default:
       error('JBIG2 error: operator ' + combinationOperator + ' is not supported');
      }
     },
     onImmediateGenericRegion: function SimpleSegmentVisitor_onImmediateGenericRegion(region, data, start, end) {
      var regionInfo = region.info;
      var decodingContext = new DecodingContext(data, start, end);
      var bitmap = decodeBitmap(region.mmr, regionInfo.width, regionInfo.height, region.template, region.prediction, null, region.at, decodingContext);
      this.drawBitmap(regionInfo, bitmap);
     },
     onImmediateLosslessGenericRegion: function SimpleSegmentVisitor_onImmediateLosslessGenericRegion() {
      this.onImmediateGenericRegion.apply(this, arguments);
     },
     onSymbolDictionary: function SimpleSegmentVisitor_onSymbolDictionary(dictionary, currentSegment, referredSegments, data, start, end) {
      var huffmanTables;
      if (dictionary.huffman) {
       error('JBIG2 error: huffman is not supported');
      }
      var symbols = this.symbols;
      if (!symbols) {
       this.symbols = symbols = {};
      }
      var inputSymbols = [];
      for (var i = 0, ii = referredSegments.length; i < ii; i++) {
       inputSymbols = inputSymbols.concat(symbols[referredSegments[i]]);
      }
      var decodingContext = new DecodingContext(data, start, end);
      symbols[currentSegment] = decodeSymbolDictionary(dictionary.huffman, dictionary.refinement, inputSymbols, dictionary.numberOfNewSymbols, dictionary.numberOfExportedSymbols, huffmanTables, dictionary.template, dictionary.at, dictionary.refinementTemplate, dictionary.refinementAt, decodingContext);
     },
     onImmediateTextRegion: function SimpleSegmentVisitor_onImmediateTextRegion(region, referredSegments, data, start, end) {
      var regionInfo = region.info;
      var huffmanTables;
      var symbols = this.symbols;
      var inputSymbols = [];
      for (var i = 0, ii = referredSegments.length; i < ii; i++) {
       inputSymbols = inputSymbols.concat(symbols[referredSegments[i]]);
      }
      var symbolCodeLength = log2(inputSymbols.length);
      var decodingContext = new DecodingContext(data, start, end);
      var bitmap = decodeTextRegion(region.huffman, region.refinement, regionInfo.width, regionInfo.height, region.defaultPixelValue, region.numberOfSymbolInstances, region.stripSize, inputSymbols, symbolCodeLength, region.transposed, region.dsOffset, region.referenceCorner, region.combinationOperator, huffmanTables, region.refinementTemplate, region.refinementAt, decodingContext);
      this.drawBitmap(regionInfo, bitmap);
     },
     onImmediateLosslessTextRegion: function SimpleSegmentVisitor_onImmediateLosslessTextRegion() {
      this.onImmediateTextRegion.apply(this, arguments);
     }
    };
    function Jbig2Image() {
    }
    Jbig2Image.prototype = {
     parseChunks: function Jbig2Image_parseChunks(chunks) {
      return parseJbig2Chunks(chunks);
     }
    };
    return Jbig2Image;
   }();
   exports.Jbig2Image = Jbig2Image;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreJpg = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var error = sharedUtil.error;
   var JpegImage = function JpegImageClosure() {
    var dctZigZag = new Uint8Array([
     0,
     1,
     8,
     16,
     9,
     2,
     3,
     10,
     17,
     24,
     32,
     25,
     18,
     11,
     4,
     5,
     12,
     19,
     26,
     33,
     40,
     48,
     41,
     34,
     27,
     20,
     13,
     6,
     7,
     14,
     21,
     28,
     35,
     42,
     49,
     56,
     57,
     50,
     43,
     36,
     29,
     22,
     15,
     23,
     30,
     37,
     44,
     51,
     58,
     59,
     52,
     45,
     38,
     31,
     39,
     46,
     53,
     60,
     61,
     54,
     47,
     55,
     62,
     63
    ]);
    var dctCos1 = 4017;
    var dctSin1 = 799;
    var dctCos3 = 3406;
    var dctSin3 = 2276;
    var dctCos6 = 1567;
    var dctSin6 = 3784;
    var dctSqrt2 = 5793;
    var dctSqrt1d2 = 2896;
    function JpegImage() {
     this.decodeTransform = null;
     this.colorTransform = -1;
    }
    function buildHuffmanTable(codeLengths, values) {
     var k = 0, code = [], i, j, length = 16;
     while (length > 0 && !codeLengths[length - 1]) {
      length--;
     }
     code.push({
      children: [],
      index: 0
     });
     var p = code[0], q;
     for (i = 0; i < length; i++) {
      for (j = 0; j < codeLengths[i]; j++) {
       p = code.pop();
       p.children[p.index] = values[k];
       while (p.index > 0) {
        p = code.pop();
       }
       p.index++;
       code.push(p);
       while (code.length <= i) {
        code.push(q = {
         children: [],
         index: 0
        });
        p.children[p.index] = q.children;
        p = q;
       }
       k++;
      }
      if (i + 1 < length) {
       code.push(q = {
        children: [],
        index: 0
       });
       p.children[p.index] = q.children;
       p = q;
      }
     }
     return code[0].children;
    }
    function getBlockBufferOffset(component, row, col) {
     return 64 * ((component.blocksPerLine + 1) * row + col);
    }
    function decodeScan(data, offset, frame, components, resetInterval, spectralStart, spectralEnd, successivePrev, successive) {
     var mcusPerLine = frame.mcusPerLine;
     var progressive = frame.progressive;
     var startOffset = offset, bitsData = 0, bitsCount = 0;
     function readBit() {
      if (bitsCount > 0) {
       bitsCount--;
       return bitsData >> bitsCount & 1;
      }
      bitsData = data[offset++];
      if (bitsData === 0xFF) {
       var nextByte = data[offset++];
       if (nextByte) {
        error('JPEG error: unexpected marker ' + (bitsData << 8 | nextByte).toString(16));
       }
      }
      bitsCount = 7;
      return bitsData >>> 7;
     }
     function decodeHuffman(tree) {
      var node = tree;
      while (true) {
       node = node[readBit()];
       if (typeof node === 'number') {
        return node;
       }
       if (typeof node !== 'object') {
        error('JPEG error: invalid huffman sequence');
       }
      }
     }
     function receive(length) {
      var n = 0;
      while (length > 0) {
       n = n << 1 | readBit();
       length--;
      }
      return n;
     }
     function receiveAndExtend(length) {
      if (length === 1) {
       return readBit() === 1 ? 1 : -1;
      }
      var n = receive(length);
      if (n >= 1 << length - 1) {
       return n;
      }
      return n + (-1 << length) + 1;
     }
     function decodeBaseline(component, offset) {
      var t = decodeHuffman(component.huffmanTableDC);
      var diff = t === 0 ? 0 : receiveAndExtend(t);
      component.blockData[offset] = component.pred += diff;
      var k = 1;
      while (k < 64) {
       var rs = decodeHuffman(component.huffmanTableAC);
       var s = rs & 15, r = rs >> 4;
       if (s === 0) {
        if (r < 15) {
         break;
        }
        k += 16;
        continue;
       }
       k += r;
       var z = dctZigZag[k];
       component.blockData[offset + z] = receiveAndExtend(s);
       k++;
      }
     }
     function decodeDCFirst(component, offset) {
      var t = decodeHuffman(component.huffmanTableDC);
      var diff = t === 0 ? 0 : receiveAndExtend(t) << successive;
      component.blockData[offset] = component.pred += diff;
     }
     function decodeDCSuccessive(component, offset) {
      component.blockData[offset] |= readBit() << successive;
     }
     var eobrun = 0;
     function decodeACFirst(component, offset) {
      if (eobrun > 0) {
       eobrun--;
       return;
      }
      var k = spectralStart, e = spectralEnd;
      while (k <= e) {
       var rs = decodeHuffman(component.huffmanTableAC);
       var s = rs & 15, r = rs >> 4;
       if (s === 0) {
        if (r < 15) {
         eobrun = receive(r) + (1 << r) - 1;
         break;
        }
        k += 16;
        continue;
       }
       k += r;
       var z = dctZigZag[k];
       component.blockData[offset + z] = receiveAndExtend(s) * (1 << successive);
       k++;
      }
     }
     var successiveACState = 0, successiveACNextValue;
     function decodeACSuccessive(component, offset) {
      var k = spectralStart;
      var e = spectralEnd;
      var r = 0;
      var s;
      var rs;
      while (k <= e) {
       var z = dctZigZag[k];
       switch (successiveACState) {
       case 0:
        rs = decodeHuffman(component.huffmanTableAC);
        s = rs & 15;
        r = rs >> 4;
        if (s === 0) {
         if (r < 15) {
          eobrun = receive(r) + (1 << r);
          successiveACState = 4;
         } else {
          r = 16;
          successiveACState = 1;
         }
        } else {
         if (s !== 1) {
          error('JPEG error: invalid ACn encoding');
         }
         successiveACNextValue = receiveAndExtend(s);
         successiveACState = r ? 2 : 3;
        }
        continue;
       case 1:
       case 2:
        if (component.blockData[offset + z]) {
         component.blockData[offset + z] += readBit() << successive;
        } else {
         r--;
         if (r === 0) {
          successiveACState = successiveACState === 2 ? 3 : 0;
         }
        }
        break;
       case 3:
        if (component.blockData[offset + z]) {
         component.blockData[offset + z] += readBit() << successive;
        } else {
         component.blockData[offset + z] = successiveACNextValue << successive;
         successiveACState = 0;
        }
        break;
       case 4:
        if (component.blockData[offset + z]) {
         component.blockData[offset + z] += readBit() << successive;
        }
        break;
       }
       k++;
      }
      if (successiveACState === 4) {
       eobrun--;
       if (eobrun === 0) {
        successiveACState = 0;
       }
      }
     }
     function decodeMcu(component, decode, mcu, row, col) {
      var mcuRow = mcu / mcusPerLine | 0;
      var mcuCol = mcu % mcusPerLine;
      var blockRow = mcuRow * component.v + row;
      var blockCol = mcuCol * component.h + col;
      var offset = getBlockBufferOffset(component, blockRow, blockCol);
      decode(component, offset);
     }
     function decodeBlock(component, decode, mcu) {
      var blockRow = mcu / component.blocksPerLine | 0;
      var blockCol = mcu % component.blocksPerLine;
      var offset = getBlockBufferOffset(component, blockRow, blockCol);
      decode(component, offset);
     }
     var componentsLength = components.length;
     var component, i, j, k, n;
     var decodeFn;
     if (progressive) {
      if (spectralStart === 0) {
       decodeFn = successivePrev === 0 ? decodeDCFirst : decodeDCSuccessive;
      } else {
       decodeFn = successivePrev === 0 ? decodeACFirst : decodeACSuccessive;
      }
     } else {
      decodeFn = decodeBaseline;
     }
     var mcu = 0, marker;
     var mcuExpected;
     if (componentsLength === 1) {
      mcuExpected = components[0].blocksPerLine * components[0].blocksPerColumn;
     } else {
      mcuExpected = mcusPerLine * frame.mcusPerColumn;
     }
     if (!resetInterval) {
      resetInterval = mcuExpected;
     }
     var h, v;
     while (mcu < mcuExpected) {
      for (i = 0; i < componentsLength; i++) {
       components[i].pred = 0;
      }
      eobrun = 0;
      if (componentsLength === 1) {
       component = components[0];
       for (n = 0; n < resetInterval; n++) {
        decodeBlock(component, decodeFn, mcu);
        mcu++;
       }
      } else {
       for (n = 0; n < resetInterval; n++) {
        for (i = 0; i < componentsLength; i++) {
         component = components[i];
         h = component.h;
         v = component.v;
         for (j = 0; j < v; j++) {
          for (k = 0; k < h; k++) {
           decodeMcu(component, decodeFn, mcu, j, k);
          }
         }
        }
        mcu++;
       }
      }
      bitsCount = 0;
      marker = data[offset] << 8 | data[offset + 1];
      while (data[offset] === 0x00 && offset < data.length - 1) {
       offset++;
       marker = data[offset] << 8 | data[offset + 1];
      }
      if (marker <= 0xFF00) {
       error('JPEG error: marker was not found');
      }
      if (marker >= 0xFFD0 && marker <= 0xFFD7) {
       offset += 2;
      } else {
       break;
      }
     }
     return offset - startOffset;
    }
    function quantizeAndInverse(component, blockBufferOffset, p) {
     var qt = component.quantizationTable, blockData = component.blockData;
     var v0, v1, v2, v3, v4, v5, v6, v7;
     var p0, p1, p2, p3, p4, p5, p6, p7;
     var t;
     if (!qt) {
      error('JPEG error: missing required Quantization Table.');
     }
     for (var row = 0; row < 64; row += 8) {
      p0 = blockData[blockBufferOffset + row];
      p1 = blockData[blockBufferOffset + row + 1];
      p2 = blockData[blockBufferOffset + row + 2];
      p3 = blockData[blockBufferOffset + row + 3];
      p4 = blockData[blockBufferOffset + row + 4];
      p5 = blockData[blockBufferOffset + row + 5];
      p6 = blockData[blockBufferOffset + row + 6];
      p7 = blockData[blockBufferOffset + row + 7];
      p0 *= qt[row];
      if ((p1 | p2 | p3 | p4 | p5 | p6 | p7) === 0) {
       t = dctSqrt2 * p0 + 512 >> 10;
       p[row] = t;
       p[row + 1] = t;
       p[row + 2] = t;
       p[row + 3] = t;
       p[row + 4] = t;
       p[row + 5] = t;
       p[row + 6] = t;
       p[row + 7] = t;
       continue;
      }
      p1 *= qt[row + 1];
      p2 *= qt[row + 2];
      p3 *= qt[row + 3];
      p4 *= qt[row + 4];
      p5 *= qt[row + 5];
      p6 *= qt[row + 6];
      p7 *= qt[row + 7];
      v0 = dctSqrt2 * p0 + 128 >> 8;
      v1 = dctSqrt2 * p4 + 128 >> 8;
      v2 = p2;
      v3 = p6;
      v4 = dctSqrt1d2 * (p1 - p7) + 128 >> 8;
      v7 = dctSqrt1d2 * (p1 + p7) + 128 >> 8;
      v5 = p3 << 4;
      v6 = p5 << 4;
      v0 = v0 + v1 + 1 >> 1;
      v1 = v0 - v1;
      t = v2 * dctSin6 + v3 * dctCos6 + 128 >> 8;
      v2 = v2 * dctCos6 - v3 * dctSin6 + 128 >> 8;
      v3 = t;
      v4 = v4 + v6 + 1 >> 1;
      v6 = v4 - v6;
      v7 = v7 + v5 + 1 >> 1;
      v5 = v7 - v5;
      v0 = v0 + v3 + 1 >> 1;
      v3 = v0 - v3;
      v1 = v1 + v2 + 1 >> 1;
      v2 = v1 - v2;
      t = v4 * dctSin3 + v7 * dctCos3 + 2048 >> 12;
      v4 = v4 * dctCos3 - v7 * dctSin3 + 2048 >> 12;
      v7 = t;
      t = v5 * dctSin1 + v6 * dctCos1 + 2048 >> 12;
      v5 = v5 * dctCos1 - v6 * dctSin1 + 2048 >> 12;
      v6 = t;
      p[row] = v0 + v7;
      p[row + 7] = v0 - v7;
      p[row + 1] = v1 + v6;
      p[row + 6] = v1 - v6;
      p[row + 2] = v2 + v5;
      p[row + 5] = v2 - v5;
      p[row + 3] = v3 + v4;
      p[row + 4] = v3 - v4;
     }
     for (var col = 0; col < 8; ++col) {
      p0 = p[col];
      p1 = p[col + 8];
      p2 = p[col + 16];
      p3 = p[col + 24];
      p4 = p[col + 32];
      p5 = p[col + 40];
      p6 = p[col + 48];
      p7 = p[col + 56];
      if ((p1 | p2 | p3 | p4 | p5 | p6 | p7) === 0) {
       t = dctSqrt2 * p0 + 8192 >> 14;
       t = t < -2040 ? 0 : t >= 2024 ? 255 : t + 2056 >> 4;
       blockData[blockBufferOffset + col] = t;
       blockData[blockBufferOffset + col + 8] = t;
       blockData[blockBufferOffset + col + 16] = t;
       blockData[blockBufferOffset + col + 24] = t;
       blockData[blockBufferOffset + col + 32] = t;
       blockData[blockBufferOffset + col + 40] = t;
       blockData[blockBufferOffset + col + 48] = t;
       blockData[blockBufferOffset + col + 56] = t;
       continue;
      }
      v0 = dctSqrt2 * p0 + 2048 >> 12;
      v1 = dctSqrt2 * p4 + 2048 >> 12;
      v2 = p2;
      v3 = p6;
      v4 = dctSqrt1d2 * (p1 - p7) + 2048 >> 12;
      v7 = dctSqrt1d2 * (p1 + p7) + 2048 >> 12;
      v5 = p3;
      v6 = p5;
      v0 = (v0 + v1 + 1 >> 1) + 4112;
      v1 = v0 - v1;
      t = v2 * dctSin6 + v3 * dctCos6 + 2048 >> 12;
      v2 = v2 * dctCos6 - v3 * dctSin6 + 2048 >> 12;
      v3 = t;
      v4 = v4 + v6 + 1 >> 1;
      v6 = v4 - v6;
      v7 = v7 + v5 + 1 >> 1;
      v5 = v7 - v5;
      v0 = v0 + v3 + 1 >> 1;
      v3 = v0 - v3;
      v1 = v1 + v2 + 1 >> 1;
      v2 = v1 - v2;
      t = v4 * dctSin3 + v7 * dctCos3 + 2048 >> 12;
      v4 = v4 * dctCos3 - v7 * dctSin3 + 2048 >> 12;
      v7 = t;
      t = v5 * dctSin1 + v6 * dctCos1 + 2048 >> 12;
      v5 = v5 * dctCos1 - v6 * dctSin1 + 2048 >> 12;
      v6 = t;
      p0 = v0 + v7;
      p7 = v0 - v7;
      p1 = v1 + v6;
      p6 = v1 - v6;
      p2 = v2 + v5;
      p5 = v2 - v5;
      p3 = v3 + v4;
      p4 = v3 - v4;
      p0 = p0 < 16 ? 0 : p0 >= 4080 ? 255 : p0 >> 4;
      p1 = p1 < 16 ? 0 : p1 >= 4080 ? 255 : p1 >> 4;
      p2 = p2 < 16 ? 0 : p2 >= 4080 ? 255 : p2 >> 4;
      p3 = p3 < 16 ? 0 : p3 >= 4080 ? 255 : p3 >> 4;
      p4 = p4 < 16 ? 0 : p4 >= 4080 ? 255 : p4 >> 4;
      p5 = p5 < 16 ? 0 : p5 >= 4080 ? 255 : p5 >> 4;
      p6 = p6 < 16 ? 0 : p6 >= 4080 ? 255 : p6 >> 4;
      p7 = p7 < 16 ? 0 : p7 >= 4080 ? 255 : p7 >> 4;
      blockData[blockBufferOffset + col] = p0;
      blockData[blockBufferOffset + col + 8] = p1;
      blockData[blockBufferOffset + col + 16] = p2;
      blockData[blockBufferOffset + col + 24] = p3;
      blockData[blockBufferOffset + col + 32] = p4;
      blockData[blockBufferOffset + col + 40] = p5;
      blockData[blockBufferOffset + col + 48] = p6;
      blockData[blockBufferOffset + col + 56] = p7;
     }
    }
    function buildComponentData(frame, component) {
     var blocksPerLine = component.blocksPerLine;
     var blocksPerColumn = component.blocksPerColumn;
     var computationBuffer = new Int16Array(64);
     for (var blockRow = 0; blockRow < blocksPerColumn; blockRow++) {
      for (var blockCol = 0; blockCol < blocksPerLine; blockCol++) {
       var offset = getBlockBufferOffset(component, blockRow, blockCol);
       quantizeAndInverse(component, offset, computationBuffer);
      }
     }
     return component.blockData;
    }
    function clamp0to255(a) {
     return a <= 0 ? 0 : a >= 255 ? 255 : a;
    }
    JpegImage.prototype = {
     parse: function parse(data) {
      function readUint16() {
       var value = data[offset] << 8 | data[offset + 1];
       offset += 2;
       return value;
      }
      function readDataBlock() {
       var length = readUint16();
       var array = data.subarray(offset, offset + length - 2);
       offset += array.length;
       return array;
      }
      function prepareComponents(frame) {
       var mcusPerLine = Math.ceil(frame.samplesPerLine / 8 / frame.maxH);
       var mcusPerColumn = Math.ceil(frame.scanLines / 8 / frame.maxV);
       for (var i = 0; i < frame.components.length; i++) {
        component = frame.components[i];
        var blocksPerLine = Math.ceil(Math.ceil(frame.samplesPerLine / 8) * component.h / frame.maxH);
        var blocksPerColumn = Math.ceil(Math.ceil(frame.scanLines / 8) * component.v / frame.maxV);
        var blocksPerLineForMcu = mcusPerLine * component.h;
        var blocksPerColumnForMcu = mcusPerColumn * component.v;
        var blocksBufferSize = 64 * blocksPerColumnForMcu * (blocksPerLineForMcu + 1);
        component.blockData = new Int16Array(blocksBufferSize);
        component.blocksPerLine = blocksPerLine;
        component.blocksPerColumn = blocksPerColumn;
       }
       frame.mcusPerLine = mcusPerLine;
       frame.mcusPerColumn = mcusPerColumn;
      }
      var offset = 0;
      var jfif = null;
      var adobe = null;
      var frame, resetInterval;
      var quantizationTables = [];
      var huffmanTablesAC = [], huffmanTablesDC = [];
      var fileMarker = readUint16();
      if (fileMarker !== 0xFFD8) {
       error('JPEG error: SOI not found');
      }
      fileMarker = readUint16();
      while (fileMarker !== 0xFFD9) {
       var i, j, l;
       switch (fileMarker) {
       case 0xFFE0:
       case 0xFFE1:
       case 0xFFE2:
       case 0xFFE3:
       case 0xFFE4:
       case 0xFFE5:
       case 0xFFE6:
       case 0xFFE7:
       case 0xFFE8:
       case 0xFFE9:
       case 0xFFEA:
       case 0xFFEB:
       case 0xFFEC:
       case 0xFFED:
       case 0xFFEE:
       case 0xFFEF:
       case 0xFFFE:
        var appData = readDataBlock();
        if (fileMarker === 0xFFE0) {
         if (appData[0] === 0x4A && appData[1] === 0x46 && appData[2] === 0x49 && appData[3] === 0x46 && appData[4] === 0) {
          jfif = {
           version: {
            major: appData[5],
            minor: appData[6]
           },
           densityUnits: appData[7],
           xDensity: appData[8] << 8 | appData[9],
           yDensity: appData[10] << 8 | appData[11],
           thumbWidth: appData[12],
           thumbHeight: appData[13],
           thumbData: appData.subarray(14, 14 + 3 * appData[12] * appData[13])
          };
         }
        }
        if (fileMarker === 0xFFEE) {
         if (appData[0] === 0x41 && appData[1] === 0x64 && appData[2] === 0x6F && appData[3] === 0x62 && appData[4] === 0x65) {
          adobe = {
           version: appData[5] << 8 | appData[6],
           flags0: appData[7] << 8 | appData[8],
           flags1: appData[9] << 8 | appData[10],
           transformCode: appData[11]
          };
         }
        }
        break;
       case 0xFFDB:
        var quantizationTablesLength = readUint16();
        var quantizationTablesEnd = quantizationTablesLength + offset - 2;
        var z;
        while (offset < quantizationTablesEnd) {
         var quantizationTableSpec = data[offset++];
         var tableData = new Uint16Array(64);
         if (quantizationTableSpec >> 4 === 0) {
          for (j = 0; j < 64; j++) {
           z = dctZigZag[j];
           tableData[z] = data[offset++];
          }
         } else if (quantizationTableSpec >> 4 === 1) {
          for (j = 0; j < 64; j++) {
           z = dctZigZag[j];
           tableData[z] = readUint16();
          }
         } else {
          error('JPEG error: DQT - invalid table spec');
         }
         quantizationTables[quantizationTableSpec & 15] = tableData;
        }
        break;
       case 0xFFC0:
       case 0xFFC1:
       case 0xFFC2:
        if (frame) {
         error('JPEG error: Only single frame JPEGs supported');
        }
        readUint16();
        frame = {};
        frame.extended = fileMarker === 0xFFC1;
        frame.progressive = fileMarker === 0xFFC2;
        frame.precision = data[offset++];
        frame.scanLines = readUint16();
        frame.samplesPerLine = readUint16();
        frame.components = [];
        frame.componentIds = {};
        var componentsCount = data[offset++], componentId;
        var maxH = 0, maxV = 0;
        for (i = 0; i < componentsCount; i++) {
         componentId = data[offset];
         var h = data[offset + 1] >> 4;
         var v = data[offset + 1] & 15;
         if (maxH < h) {
          maxH = h;
         }
         if (maxV < v) {
          maxV = v;
         }
         var qId = data[offset + 2];
         l = frame.components.push({
          h: h,
          v: v,
          quantizationId: qId,
          quantizationTable: null
         });
         frame.componentIds[componentId] = l - 1;
         offset += 3;
        }
        frame.maxH = maxH;
        frame.maxV = maxV;
        prepareComponents(frame);
        break;
       case 0xFFC4:
        var huffmanLength = readUint16();
        for (i = 2; i < huffmanLength;) {
         var huffmanTableSpec = data[offset++];
         var codeLengths = new Uint8Array(16);
         var codeLengthSum = 0;
         for (j = 0; j < 16; j++, offset++) {
          codeLengthSum += codeLengths[j] = data[offset];
         }
         var huffmanValues = new Uint8Array(codeLengthSum);
         for (j = 0; j < codeLengthSum; j++, offset++) {
          huffmanValues[j] = data[offset];
         }
         i += 17 + codeLengthSum;
         (huffmanTableSpec >> 4 === 0 ? huffmanTablesDC : huffmanTablesAC)[huffmanTableSpec & 15] = buildHuffmanTable(codeLengths, huffmanValues);
        }
        break;
       case 0xFFDD:
        readUint16();
        resetInterval = readUint16();
        break;
       case 0xFFDA:
        var scanLength = readUint16();
        var selectorsCount = data[offset++];
        var components = [], component;
        for (i = 0; i < selectorsCount; i++) {
         var componentIndex = frame.componentIds[data[offset++]];
         component = frame.components[componentIndex];
         var tableSpec = data[offset++];
         component.huffmanTableDC = huffmanTablesDC[tableSpec >> 4];
         component.huffmanTableAC = huffmanTablesAC[tableSpec & 15];
         components.push(component);
        }
        var spectralStart = data[offset++];
        var spectralEnd = data[offset++];
        var successiveApproximation = data[offset++];
        var processed = decodeScan(data, offset, frame, components, resetInterval, spectralStart, spectralEnd, successiveApproximation >> 4, successiveApproximation & 15);
        offset += processed;
        break;
       case 0xFFFF:
        if (data[offset] !== 0xFF) {
         offset--;
        }
        break;
       default:
        if (data[offset - 3] === 0xFF && data[offset - 2] >= 0xC0 && data[offset - 2] <= 0xFE) {
         offset -= 3;
         break;
        }
        error('JPEG error: unknown marker ' + fileMarker.toString(16));
       }
       fileMarker = readUint16();
      }
      this.width = frame.samplesPerLine;
      this.height = frame.scanLines;
      this.jfif = jfif;
      this.adobe = adobe;
      this.components = [];
      for (i = 0; i < frame.components.length; i++) {
       component = frame.components[i];
       var quantizationTable = quantizationTables[component.quantizationId];
       if (quantizationTable) {
        component.quantizationTable = quantizationTable;
       }
       this.components.push({
        output: buildComponentData(frame, component),
        scaleX: component.h / frame.maxH,
        scaleY: component.v / frame.maxV,
        blocksPerLine: component.blocksPerLine,
        blocksPerColumn: component.blocksPerColumn
       });
      }
      this.numComponents = this.components.length;
     },
     _getLinearizedBlockData: function getLinearizedBlockData(width, height) {
      var scaleX = this.width / width, scaleY = this.height / height;
      var component, componentScaleX, componentScaleY, blocksPerScanline;
      var x, y, i, j, k;
      var index;
      var offset = 0;
      var output;
      var numComponents = this.components.length;
      var dataLength = width * height * numComponents;
      var data = new Uint8Array(dataLength);
      var xScaleBlockOffset = new Uint32Array(width);
      var mask3LSB = 0xfffffff8;
      for (i = 0; i < numComponents; i++) {
       component = this.components[i];
       componentScaleX = component.scaleX * scaleX;
       componentScaleY = component.scaleY * scaleY;
       offset = i;
       output = component.output;
       blocksPerScanline = component.blocksPerLine + 1 << 3;
       for (x = 0; x < width; x++) {
        j = 0 | x * componentScaleX;
        xScaleBlockOffset[x] = (j & mask3LSB) << 3 | j & 7;
       }
       for (y = 0; y < height; y++) {
        j = 0 | y * componentScaleY;
        index = blocksPerScanline * (j & mask3LSB) | (j & 7) << 3;
        for (x = 0; x < width; x++) {
         data[offset] = output[index + xScaleBlockOffset[x]];
         offset += numComponents;
        }
       }
      }
      var transform = this.decodeTransform;
      if (transform) {
       for (i = 0; i < dataLength;) {
        for (j = 0, k = 0; j < numComponents; j++, i++, k += 2) {
         data[i] = (data[i] * transform[k] >> 8) + transform[k + 1];
        }
       }
      }
      return data;
     },
     _isColorConversionNeeded: function isColorConversionNeeded() {
      if (this.adobe && this.adobe.transformCode) {
       return true;
      } else if (this.numComponents === 3) {
       if (!this.adobe && this.colorTransform === 0) {
        return false;
       }
       return true;
      } else {
       if (!this.adobe && this.colorTransform === 1) {
        return true;
       }
       return false;
      }
     },
     _convertYccToRgb: function convertYccToRgb(data) {
      var Y, Cb, Cr;
      for (var i = 0, length = data.length; i < length; i += 3) {
       Y = data[i];
       Cb = data[i + 1];
       Cr = data[i + 2];
       data[i] = clamp0to255(Y - 179.456 + 1.402 * Cr);
       data[i + 1] = clamp0to255(Y + 135.459 - 0.344 * Cb - 0.714 * Cr);
       data[i + 2] = clamp0to255(Y - 226.816 + 1.772 * Cb);
      }
      return data;
     },
     _convertYcckToRgb: function convertYcckToRgb(data) {
      var Y, Cb, Cr, k;
      var offset = 0;
      for (var i = 0, length = data.length; i < length; i += 4) {
       Y = data[i];
       Cb = data[i + 1];
       Cr = data[i + 2];
       k = data[i + 3];
       var r = -122.67195406894 + Cb * (-6.60635669420364e-5 * Cb + 0.000437130475926232 * Cr - 5.4080610064599e-5 * Y + 0.00048449797120281 * k - 0.154362151871126) + Cr * (-0.000957964378445773 * Cr + 0.000817076911346625 * Y - 0.00477271405408747 * k + 1.53380253221734) + Y * (0.000961250184130688 * Y - 0.00266257332283933 * k + 0.48357088451265) + k * (-0.000336197177618394 * k + 0.484791561490776);
       var g = 107.268039397724 + Cb * (2.19927104525741e-5 * Cb - 0.000640992018297945 * Cr + 0.000659397001245577 * Y + 0.000426105652938837 * k - 0.176491792462875) + Cr * (-0.000778269941513683 * Cr + 0.00130872261408275 * Y + 0.000770482631801132 * k - 0.151051492775562) + Y * (0.00126935368114843 * Y - 0.00265090189010898 * k + 0.25802910206845) + k * (-0.000318913117588328 * k - 0.213742400323665);
       var b = -20.810012546947 + Cb * (-0.000570115196973677 * Cb - 2.63409051004589e-5 * Cr + 0.0020741088115012 * Y - 0.00288260236853442 * k + 0.814272968359295) + Cr * (-1.53496057440975e-5 * Cr - 0.000132689043961446 * Y + 0.000560833691242812 * k - 0.195152027534049) + Y * (0.00174418132927582 * Y - 0.00255243321439347 * k + 0.116935020465145) + k * (-0.000343531996510555 * k + 0.24165260232407);
       data[offset++] = clamp0to255(r);
       data[offset++] = clamp0to255(g);
       data[offset++] = clamp0to255(b);
      }
      return data;
     },
     _convertYcckToCmyk: function convertYcckToCmyk(data) {
      var Y, Cb, Cr;
      for (var i = 0, length = data.length; i < length; i += 4) {
       Y = data[i];
       Cb = data[i + 1];
       Cr = data[i + 2];
       data[i] = clamp0to255(434.456 - Y - 1.402 * Cr);
       data[i + 1] = clamp0to255(119.541 - Y + 0.344 * Cb + 0.714 * Cr);
       data[i + 2] = clamp0to255(481.816 - Y - 1.772 * Cb);
      }
      return data;
     },
     _convertCmykToRgb: function convertCmykToRgb(data) {
      var c, m, y, k;
      var offset = 0;
      var min = -255 * 255 * 255;
      var scale = 1 / 255 / 255;
      for (var i = 0, length = data.length; i < length; i += 4) {
       c = data[i];
       m = data[i + 1];
       y = data[i + 2];
       k = data[i + 3];
       var r = c * (-4.387332384609988 * c + 54.48615194189176 * m + 18.82290502165302 * y + 212.25662451639585 * k - 72734.4411664936) + m * (1.7149763477362134 * m - 5.6096736904047315 * y - 17.873870861415444 * k - 1401.7366389350734) + y * (-2.5217340131683033 * y - 21.248923337353073 * k + 4465.541406466231) - k * (21.86122147463605 * k + 48317.86113160301);
       var g = c * (8.841041422036149 * c + 60.118027045597366 * m + 6.871425592049007 * y + 31.159100130055922 * k - 20220.756542821975) + m * (-15.310361306967817 * m + 17.575251261109482 * y + 131.35250912493976 * k - 48691.05921601825) + y * (4.444339102852739 * y + 9.8632861493405 * k - 6341.191035517494) - k * (20.737325471181034 * k + 47890.15695978492);
       var b = c * (0.8842522430003296 * c + 8.078677503112928 * m + 30.89978309703729 * y - 0.23883238689178934 * k - 3616.812083916688) + m * (10.49593273432072 * m + 63.02378494754052 * y + 50.606957656360734 * k - 28620.90484698408) + y * (0.03296041114873217 * y + 115.60384449646641 * k - 49363.43385999684) - k * (22.33816807309886 * k + 45932.16563550634);
       data[offset++] = r >= 0 ? 255 : r <= min ? 0 : 255 + r * scale | 0;
       data[offset++] = g >= 0 ? 255 : g <= min ? 0 : 255 + g * scale | 0;
       data[offset++] = b >= 0 ? 255 : b <= min ? 0 : 255 + b * scale | 0;
      }
      return data;
     },
     getData: function getData(width, height, forceRGBoutput) {
      if (this.numComponents > 4) {
       error('JPEG error: Unsupported color mode');
      }
      var data = this._getLinearizedBlockData(width, height);
      if (this.numComponents === 1 && forceRGBoutput) {
       var dataLength = data.length;
       var rgbData = new Uint8Array(dataLength * 3);
       var offset = 0;
       for (var i = 0; i < dataLength; i++) {
        var grayColor = data[i];
        rgbData[offset++] = grayColor;
        rgbData[offset++] = grayColor;
        rgbData[offset++] = grayColor;
       }
       return rgbData;
      } else if (this.numComponents === 3 && this._isColorConversionNeeded()) {
       return this._convertYccToRgb(data);
      } else if (this.numComponents === 4) {
       if (this._isColorConversionNeeded()) {
        if (forceRGBoutput) {
         return this._convertYcckToRgb(data);
        } else {
         return this._convertYcckToCmyk(data);
        }
       } else if (forceRGBoutput) {
        return this._convertCmykToRgb(data);
       }
      }
      return data;
     }
    };
    return JpegImage;
   }();
   exports.JpegImage = JpegImage;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreJpx = {}, root.pdfjsSharedUtil, root.pdfjsCoreArithmeticDecoder);
  }(this, function (exports, sharedUtil, coreArithmeticDecoder) {
   var info = sharedUtil.info;
   var warn = sharedUtil.warn;
   var error = sharedUtil.error;
   var log2 = sharedUtil.log2;
   var readUint16 = sharedUtil.readUint16;
   var readUint32 = sharedUtil.readUint32;
   var ArithmeticDecoder = coreArithmeticDecoder.ArithmeticDecoder;
   var JpxImage = function JpxImageClosure() {
    var SubbandsGainLog2 = {
     'LL': 0,
     'LH': 1,
     'HL': 1,
     'HH': 2
    };
    function JpxImage() {
     this.failOnCorruptedImage = false;
    }
    JpxImage.prototype = {
     parse: function JpxImage_parse(data) {
      var head = readUint16(data, 0);
      if (head === 0xFF4F) {
       this.parseCodestream(data, 0, data.length);
       return;
      }
      var position = 0, length = data.length;
      while (position < length) {
       var headerSize = 8;
       var lbox = readUint32(data, position);
       var tbox = readUint32(data, position + 4);
       position += headerSize;
       if (lbox === 1) {
        lbox = readUint32(data, position) * 4294967296 + readUint32(data, position + 4);
        position += 8;
        headerSize += 8;
       }
       if (lbox === 0) {
        lbox = length - position + headerSize;
       }
       if (lbox < headerSize) {
        error('JPX Error: Invalid box field size');
       }
       var dataLength = lbox - headerSize;
       var jumpDataLength = true;
       switch (tbox) {
       case 0x6A703268:
        jumpDataLength = false;
        break;
       case 0x636F6C72:
        var method = data[position];
        if (method === 1) {
         var colorspace = readUint32(data, position + 3);
         switch (colorspace) {
         case 16:
         case 17:
         case 18:
          break;
         default:
          warn('Unknown colorspace ' + colorspace);
          break;
         }
        } else if (method === 2) {
         info('ICC profile not supported');
        }
        break;
       case 0x6A703263:
        this.parseCodestream(data, position, position + dataLength);
        break;
       case 0x6A502020:
        if (0x0d0a870a !== readUint32(data, position)) {
         warn('Invalid JP2 signature');
        }
        break;
       case 0x6A501A1A:
       case 0x66747970:
       case 0x72726571:
       case 0x72657320:
       case 0x69686472:
        break;
       default:
        var headerType = String.fromCharCode(tbox >> 24 & 0xFF, tbox >> 16 & 0xFF, tbox >> 8 & 0xFF, tbox & 0xFF);
        warn('Unsupported header type ' + tbox + ' (' + headerType + ')');
        break;
       }
       if (jumpDataLength) {
        position += dataLength;
       }
      }
     },
     parseImageProperties: function JpxImage_parseImageProperties(stream) {
      var newByte = stream.getByte();
      while (newByte >= 0) {
       var oldByte = newByte;
       newByte = stream.getByte();
       var code = oldByte << 8 | newByte;
       if (code === 0xFF51) {
        stream.skip(4);
        var Xsiz = stream.getInt32() >>> 0;
        var Ysiz = stream.getInt32() >>> 0;
        var XOsiz = stream.getInt32() >>> 0;
        var YOsiz = stream.getInt32() >>> 0;
        stream.skip(16);
        var Csiz = stream.getUint16();
        this.width = Xsiz - XOsiz;
        this.height = Ysiz - YOsiz;
        this.componentsCount = Csiz;
        this.bitsPerComponent = 8;
        return;
       }
      }
      error('JPX Error: No size marker found in JPX stream');
     },
     parseCodestream: function JpxImage_parseCodestream(data, start, end) {
      var context = {};
      var doNotRecover = false;
      try {
       var position = start;
       while (position + 1 < end) {
        var code = readUint16(data, position);
        position += 2;
        var length = 0, j, sqcd, spqcds, spqcdSize, scalarExpounded, tile;
        switch (code) {
        case 0xFF4F:
         context.mainHeader = true;
         break;
        case 0xFFD9:
         break;
        case 0xFF51:
         length = readUint16(data, position);
         var siz = {};
         siz.Xsiz = readUint32(data, position + 4);
         siz.Ysiz = readUint32(data, position + 8);
         siz.XOsiz = readUint32(data, position + 12);
         siz.YOsiz = readUint32(data, position + 16);
         siz.XTsiz = readUint32(data, position + 20);
         siz.YTsiz = readUint32(data, position + 24);
         siz.XTOsiz = readUint32(data, position + 28);
         siz.YTOsiz = readUint32(data, position + 32);
         var componentsCount = readUint16(data, position + 36);
         siz.Csiz = componentsCount;
         var components = [];
         j = position + 38;
         for (var i = 0; i < componentsCount; i++) {
          var component = {
           precision: (data[j] & 0x7F) + 1,
           isSigned: !!(data[j] & 0x80),
           XRsiz: data[j + 1],
           YRsiz: data[j + 1]
          };
          calculateComponentDimensions(component, siz);
          components.push(component);
         }
         context.SIZ = siz;
         context.components = components;
         calculateTileGrids(context, components);
         context.QCC = [];
         context.COC = [];
         break;
        case 0xFF5C:
         length = readUint16(data, position);
         var qcd = {};
         j = position + 2;
         sqcd = data[j++];
         switch (sqcd & 0x1F) {
         case 0:
          spqcdSize = 8;
          scalarExpounded = true;
          break;
         case 1:
          spqcdSize = 16;
          scalarExpounded = false;
          break;
         case 2:
          spqcdSize = 16;
          scalarExpounded = true;
          break;
         default:
          throw new Error('Invalid SQcd value ' + sqcd);
         }
         qcd.noQuantization = spqcdSize === 8;
         qcd.scalarExpounded = scalarExpounded;
         qcd.guardBits = sqcd >> 5;
         spqcds = [];
         while (j < length + position) {
          var spqcd = {};
          if (spqcdSize === 8) {
           spqcd.epsilon = data[j++] >> 3;
           spqcd.mu = 0;
          } else {
           spqcd.epsilon = data[j] >> 3;
           spqcd.mu = (data[j] & 0x7) << 8 | data[j + 1];
           j += 2;
          }
          spqcds.push(spqcd);
         }
         qcd.SPqcds = spqcds;
         if (context.mainHeader) {
          context.QCD = qcd;
         } else {
          context.currentTile.QCD = qcd;
          context.currentTile.QCC = [];
         }
         break;
        case 0xFF5D:
         length = readUint16(data, position);
         var qcc = {};
         j = position + 2;
         var cqcc;
         if (context.SIZ.Csiz < 257) {
          cqcc = data[j++];
         } else {
          cqcc = readUint16(data, j);
          j += 2;
         }
         sqcd = data[j++];
         switch (sqcd & 0x1F) {
         case 0:
          spqcdSize = 8;
          scalarExpounded = true;
          break;
         case 1:
          spqcdSize = 16;
          scalarExpounded = false;
          break;
         case 2:
          spqcdSize = 16;
          scalarExpounded = true;
          break;
         default:
          throw new Error('Invalid SQcd value ' + sqcd);
         }
         qcc.noQuantization = spqcdSize === 8;
         qcc.scalarExpounded = scalarExpounded;
         qcc.guardBits = sqcd >> 5;
         spqcds = [];
         while (j < length + position) {
          spqcd = {};
          if (spqcdSize === 8) {
           spqcd.epsilon = data[j++] >> 3;
           spqcd.mu = 0;
          } else {
           spqcd.epsilon = data[j] >> 3;
           spqcd.mu = (data[j] & 0x7) << 8 | data[j + 1];
           j += 2;
          }
          spqcds.push(spqcd);
         }
         qcc.SPqcds = spqcds;
         if (context.mainHeader) {
          context.QCC[cqcc] = qcc;
         } else {
          context.currentTile.QCC[cqcc] = qcc;
         }
         break;
        case 0xFF52:
         length = readUint16(data, position);
         var cod = {};
         j = position + 2;
         var scod = data[j++];
         cod.entropyCoderWithCustomPrecincts = !!(scod & 1);
         cod.sopMarkerUsed = !!(scod & 2);
         cod.ephMarkerUsed = !!(scod & 4);
         cod.progressionOrder = data[j++];
         cod.layersCount = readUint16(data, j);
         j += 2;
         cod.multipleComponentTransform = data[j++];
         cod.decompositionLevelsCount = data[j++];
         cod.xcb = (data[j++] & 0xF) + 2;
         cod.ycb = (data[j++] & 0xF) + 2;
         var blockStyle = data[j++];
         cod.selectiveArithmeticCodingBypass = !!(blockStyle & 1);
         cod.resetContextProbabilities = !!(blockStyle & 2);
         cod.terminationOnEachCodingPass = !!(blockStyle & 4);
         cod.verticalyStripe = !!(blockStyle & 8);
         cod.predictableTermination = !!(blockStyle & 16);
         cod.segmentationSymbolUsed = !!(blockStyle & 32);
         cod.reversibleTransformation = data[j++];
         if (cod.entropyCoderWithCustomPrecincts) {
          var precinctsSizes = [];
          while (j < length + position) {
           var precinctsSize = data[j++];
           precinctsSizes.push({
            PPx: precinctsSize & 0xF,
            PPy: precinctsSize >> 4
           });
          }
          cod.precinctsSizes = precinctsSizes;
         }
         var unsupported = [];
         if (cod.selectiveArithmeticCodingBypass) {
          unsupported.push('selectiveArithmeticCodingBypass');
         }
         if (cod.resetContextProbabilities) {
          unsupported.push('resetContextProbabilities');
         }
         if (cod.terminationOnEachCodingPass) {
          unsupported.push('terminationOnEachCodingPass');
         }
         if (cod.verticalyStripe) {
          unsupported.push('verticalyStripe');
         }
         if (cod.predictableTermination) {
          unsupported.push('predictableTermination');
         }
         if (unsupported.length > 0) {
          doNotRecover = true;
          throw new Error('Unsupported COD options (' + unsupported.join(', ') + ')');
         }
         if (context.mainHeader) {
          context.COD = cod;
         } else {
          context.currentTile.COD = cod;
          context.currentTile.COC = [];
         }
         break;
        case 0xFF90:
         length = readUint16(data, position);
         tile = {};
         tile.index = readUint16(data, position + 2);
         tile.length = readUint32(data, position + 4);
         tile.dataEnd = tile.length + position - 2;
         tile.partIndex = data[position + 8];
         tile.partsCount = data[position + 9];
         context.mainHeader = false;
         if (tile.partIndex === 0) {
          tile.COD = context.COD;
          tile.COC = context.COC.slice(0);
          tile.QCD = context.QCD;
          tile.QCC = context.QCC.slice(0);
         }
         context.currentTile = tile;
         break;
        case 0xFF93:
         tile = context.currentTile;
         if (tile.partIndex === 0) {
          initializeTile(context, tile.index);
          buildPackets(context);
         }
         length = tile.dataEnd - position;
         parseTilePackets(context, data, position, length);
         break;
        case 0xFF55:
        case 0xFF57:
        case 0xFF58:
        case 0xFF64:
         length = readUint16(data, position);
         break;
        case 0xFF53:
         throw new Error('Codestream code 0xFF53 (COC) is ' + 'not implemented');
        default:
         throw new Error('Unknown codestream code: ' + code.toString(16));
        }
        position += length;
       }
      } catch (e) {
       if (doNotRecover || this.failOnCorruptedImage) {
        error('JPX Error: ' + e.message);
       } else {
        warn('JPX: Trying to recover from: ' + e.message);
       }
      }
      this.tiles = transformComponents(context);
      this.width = context.SIZ.Xsiz - context.SIZ.XOsiz;
      this.height = context.SIZ.Ysiz - context.SIZ.YOsiz;
      this.componentsCount = context.SIZ.Csiz;
     }
    };
    function calculateComponentDimensions(component, siz) {
     component.x0 = Math.ceil(siz.XOsiz / component.XRsiz);
     component.x1 = Math.ceil(siz.Xsiz / component.XRsiz);
     component.y0 = Math.ceil(siz.YOsiz / component.YRsiz);
     component.y1 = Math.ceil(siz.Ysiz / component.YRsiz);
     component.width = component.x1 - component.x0;
     component.height = component.y1 - component.y0;
    }
    function calculateTileGrids(context, components) {
     var siz = context.SIZ;
     var tile, tiles = [];
     var numXtiles = Math.ceil((siz.Xsiz - siz.XTOsiz) / siz.XTsiz);
     var numYtiles = Math.ceil((siz.Ysiz - siz.YTOsiz) / siz.YTsiz);
     for (var q = 0; q < numYtiles; q++) {
      for (var p = 0; p < numXtiles; p++) {
       tile = {};
       tile.tx0 = Math.max(siz.XTOsiz + p * siz.XTsiz, siz.XOsiz);
       tile.ty0 = Math.max(siz.YTOsiz + q * siz.YTsiz, siz.YOsiz);
       tile.tx1 = Math.min(siz.XTOsiz + (p + 1) * siz.XTsiz, siz.Xsiz);
       tile.ty1 = Math.min(siz.YTOsiz + (q + 1) * siz.YTsiz, siz.Ysiz);
       tile.width = tile.tx1 - tile.tx0;
       tile.height = tile.ty1 - tile.ty0;
       tile.components = [];
       tiles.push(tile);
      }
     }
     context.tiles = tiles;
     var componentsCount = siz.Csiz;
     for (var i = 0, ii = componentsCount; i < ii; i++) {
      var component = components[i];
      for (var j = 0, jj = tiles.length; j < jj; j++) {
       var tileComponent = {};
       tile = tiles[j];
       tileComponent.tcx0 = Math.ceil(tile.tx0 / component.XRsiz);
       tileComponent.tcy0 = Math.ceil(tile.ty0 / component.YRsiz);
       tileComponent.tcx1 = Math.ceil(tile.tx1 / component.XRsiz);
       tileComponent.tcy1 = Math.ceil(tile.ty1 / component.YRsiz);
       tileComponent.width = tileComponent.tcx1 - tileComponent.tcx0;
       tileComponent.height = tileComponent.tcy1 - tileComponent.tcy0;
       tile.components[i] = tileComponent;
      }
     }
    }
    function getBlocksDimensions(context, component, r) {
     var codOrCoc = component.codingStyleParameters;
     var result = {};
     if (!codOrCoc.entropyCoderWithCustomPrecincts) {
      result.PPx = 15;
      result.PPy = 15;
     } else {
      result.PPx = codOrCoc.precinctsSizes[r].PPx;
      result.PPy = codOrCoc.precinctsSizes[r].PPy;
     }
     result.xcb_ = r > 0 ? Math.min(codOrCoc.xcb, result.PPx - 1) : Math.min(codOrCoc.xcb, result.PPx);
     result.ycb_ = r > 0 ? Math.min(codOrCoc.ycb, result.PPy - 1) : Math.min(codOrCoc.ycb, result.PPy);
     return result;
    }
    function buildPrecincts(context, resolution, dimensions) {
     var precinctWidth = 1 << dimensions.PPx;
     var precinctHeight = 1 << dimensions.PPy;
     var isZeroRes = resolution.resLevel === 0;
     var precinctWidthInSubband = 1 << dimensions.PPx + (isZeroRes ? 0 : -1);
     var precinctHeightInSubband = 1 << dimensions.PPy + (isZeroRes ? 0 : -1);
     var numprecinctswide = resolution.trx1 > resolution.trx0 ? Math.ceil(resolution.trx1 / precinctWidth) - Math.floor(resolution.trx0 / precinctWidth) : 0;
     var numprecinctshigh = resolution.try1 > resolution.try0 ? Math.ceil(resolution.try1 / precinctHeight) - Math.floor(resolution.try0 / precinctHeight) : 0;
     var numprecincts = numprecinctswide * numprecinctshigh;
     resolution.precinctParameters = {
      precinctWidth: precinctWidth,
      precinctHeight: precinctHeight,
      numprecinctswide: numprecinctswide,
      numprecinctshigh: numprecinctshigh,
      numprecincts: numprecincts,
      precinctWidthInSubband: precinctWidthInSubband,
      precinctHeightInSubband: precinctHeightInSubband
     };
    }
    function buildCodeblocks(context, subband, dimensions) {
     var xcb_ = dimensions.xcb_;
     var ycb_ = dimensions.ycb_;
     var codeblockWidth = 1 << xcb_;
     var codeblockHeight = 1 << ycb_;
     var cbx0 = subband.tbx0 >> xcb_;
     var cby0 = subband.tby0 >> ycb_;
     var cbx1 = subband.tbx1 + codeblockWidth - 1 >> xcb_;
     var cby1 = subband.tby1 + codeblockHeight - 1 >> ycb_;
     var precinctParameters = subband.resolution.precinctParameters;
     var codeblocks = [];
     var precincts = [];
     var i, j, codeblock, precinctNumber;
     for (j = cby0; j < cby1; j++) {
      for (i = cbx0; i < cbx1; i++) {
       codeblock = {
        cbx: i,
        cby: j,
        tbx0: codeblockWidth * i,
        tby0: codeblockHeight * j,
        tbx1: codeblockWidth * (i + 1),
        tby1: codeblockHeight * (j + 1)
       };
       codeblock.tbx0_ = Math.max(subband.tbx0, codeblock.tbx0);
       codeblock.tby0_ = Math.max(subband.tby0, codeblock.tby0);
       codeblock.tbx1_ = Math.min(subband.tbx1, codeblock.tbx1);
       codeblock.tby1_ = Math.min(subband.tby1, codeblock.tby1);
       var pi = Math.floor((codeblock.tbx0_ - subband.tbx0) / precinctParameters.precinctWidthInSubband);
       var pj = Math.floor((codeblock.tby0_ - subband.tby0) / precinctParameters.precinctHeightInSubband);
       precinctNumber = pi + pj * precinctParameters.numprecinctswide;
       codeblock.precinctNumber = precinctNumber;
       codeblock.subbandType = subband.type;
       codeblock.Lblock = 3;
       if (codeblock.tbx1_ <= codeblock.tbx0_ || codeblock.tby1_ <= codeblock.tby0_) {
        continue;
       }
       codeblocks.push(codeblock);
       var precinct = precincts[precinctNumber];
       if (precinct !== undefined) {
        if (i < precinct.cbxMin) {
         precinct.cbxMin = i;
        } else if (i > precinct.cbxMax) {
         precinct.cbxMax = i;
        }
        if (j < precinct.cbyMin) {
         precinct.cbxMin = j;
        } else if (j > precinct.cbyMax) {
         precinct.cbyMax = j;
        }
       } else {
        precincts[precinctNumber] = precinct = {
         cbxMin: i,
         cbyMin: j,
         cbxMax: i,
         cbyMax: j
        };
       }
       codeblock.precinct = precinct;
      }
     }
     subband.codeblockParameters = {
      codeblockWidth: xcb_,
      codeblockHeight: ycb_,
      numcodeblockwide: cbx1 - cbx0 + 1,
      numcodeblockhigh: cby1 - cby0 + 1
     };
     subband.codeblocks = codeblocks;
     subband.precincts = precincts;
    }
    function createPacket(resolution, precinctNumber, layerNumber) {
     var precinctCodeblocks = [];
     var subbands = resolution.subbands;
     for (var i = 0, ii = subbands.length; i < ii; i++) {
      var subband = subbands[i];
      var codeblocks = subband.codeblocks;
      for (var j = 0, jj = codeblocks.length; j < jj; j++) {
       var codeblock = codeblocks[j];
       if (codeblock.precinctNumber !== precinctNumber) {
        continue;
       }
       precinctCodeblocks.push(codeblock);
      }
     }
     return {
      layerNumber: layerNumber,
      codeblocks: precinctCodeblocks
     };
    }
    function LayerResolutionComponentPositionIterator(context) {
     var siz = context.SIZ;
     var tileIndex = context.currentTile.index;
     var tile = context.tiles[tileIndex];
     var layersCount = tile.codingStyleDefaultParameters.layersCount;
     var componentsCount = siz.Csiz;
     var maxDecompositionLevelsCount = 0;
     for (var q = 0; q < componentsCount; q++) {
      maxDecompositionLevelsCount = Math.max(maxDecompositionLevelsCount, tile.components[q].codingStyleParameters.decompositionLevelsCount);
     }
     var l = 0, r = 0, i = 0, k = 0;
     this.nextPacket = function JpxImage_nextPacket() {
      for (; l < layersCount; l++) {
       for (; r <= maxDecompositionLevelsCount; r++) {
        for (; i < componentsCount; i++) {
         var component = tile.components[i];
         if (r > component.codingStyleParameters.decompositionLevelsCount) {
          continue;
         }
         var resolution = component.resolutions[r];
         var numprecincts = resolution.precinctParameters.numprecincts;
         for (; k < numprecincts;) {
          var packet = createPacket(resolution, k, l);
          k++;
          return packet;
         }
         k = 0;
        }
        i = 0;
       }
       r = 0;
      }
      error('JPX Error: Out of packets');
     };
    }
    function ResolutionLayerComponentPositionIterator(context) {
     var siz = context.SIZ;
     var tileIndex = context.currentTile.index;
     var tile = context.tiles[tileIndex];
     var layersCount = tile.codingStyleDefaultParameters.layersCount;
     var componentsCount = siz.Csiz;
     var maxDecompositionLevelsCount = 0;
     for (var q = 0; q < componentsCount; q++) {
      maxDecompositionLevelsCount = Math.max(maxDecompositionLevelsCount, tile.components[q].codingStyleParameters.decompositionLevelsCount);
     }
     var r = 0, l = 0, i = 0, k = 0;
     this.nextPacket = function JpxImage_nextPacket() {
      for (; r <= maxDecompositionLevelsCount; r++) {
       for (; l < layersCount; l++) {
        for (; i < componentsCount; i++) {
         var component = tile.components[i];
         if (r > component.codingStyleParameters.decompositionLevelsCount) {
          continue;
         }
         var resolution = component.resolutions[r];
         var numprecincts = resolution.precinctParameters.numprecincts;
         for (; k < numprecincts;) {
          var packet = createPacket(resolution, k, l);
          k++;
          return packet;
         }
         k = 0;
        }
        i = 0;
       }
       l = 0;
      }
      error('JPX Error: Out of packets');
     };
    }
    function ResolutionPositionComponentLayerIterator(context) {
     var siz = context.SIZ;
     var tileIndex = context.currentTile.index;
     var tile = context.tiles[tileIndex];
     var layersCount = tile.codingStyleDefaultParameters.layersCount;
     var componentsCount = siz.Csiz;
     var l, r, c, p;
     var maxDecompositionLevelsCount = 0;
     for (c = 0; c < componentsCount; c++) {
      var component = tile.components[c];
      maxDecompositionLevelsCount = Math.max(maxDecompositionLevelsCount, component.codingStyleParameters.decompositionLevelsCount);
     }
     var maxNumPrecinctsInLevel = new Int32Array(maxDecompositionLevelsCount + 1);
     for (r = 0; r <= maxDecompositionLevelsCount; ++r) {
      var maxNumPrecincts = 0;
      for (c = 0; c < componentsCount; ++c) {
       var resolutions = tile.components[c].resolutions;
       if (r < resolutions.length) {
        maxNumPrecincts = Math.max(maxNumPrecincts, resolutions[r].precinctParameters.numprecincts);
       }
      }
      maxNumPrecinctsInLevel[r] = maxNumPrecincts;
     }
     l = 0;
     r = 0;
     c = 0;
     p = 0;
     this.nextPacket = function JpxImage_nextPacket() {
      for (; r <= maxDecompositionLevelsCount; r++) {
       for (; p < maxNumPrecinctsInLevel[r]; p++) {
        for (; c < componentsCount; c++) {
         var component = tile.components[c];
         if (r > component.codingStyleParameters.decompositionLevelsCount) {
          continue;
         }
         var resolution = component.resolutions[r];
         var numprecincts = resolution.precinctParameters.numprecincts;
         if (p >= numprecincts) {
          continue;
         }
         for (; l < layersCount;) {
          var packet = createPacket(resolution, p, l);
          l++;
          return packet;
         }
         l = 0;
        }
        c = 0;
       }
       p = 0;
      }
      error('JPX Error: Out of packets');
     };
    }
    function PositionComponentResolutionLayerIterator(context) {
     var siz = context.SIZ;
     var tileIndex = context.currentTile.index;
     var tile = context.tiles[tileIndex];
     var layersCount = tile.codingStyleDefaultParameters.layersCount;
     var componentsCount = siz.Csiz;
     var precinctsSizes = getPrecinctSizesInImageScale(tile);
     var precinctsIterationSizes = precinctsSizes;
     var l = 0, r = 0, c = 0, px = 0, py = 0;
     this.nextPacket = function JpxImage_nextPacket() {
      for (; py < precinctsIterationSizes.maxNumHigh; py++) {
       for (; px < precinctsIterationSizes.maxNumWide; px++) {
        for (; c < componentsCount; c++) {
         var component = tile.components[c];
         var decompositionLevelsCount = component.codingStyleParameters.decompositionLevelsCount;
         for (; r <= decompositionLevelsCount; r++) {
          var resolution = component.resolutions[r];
          var sizeInImageScale = precinctsSizes.components[c].resolutions[r];
          var k = getPrecinctIndexIfExist(px, py, sizeInImageScale, precinctsIterationSizes, resolution);
          if (k === null) {
           continue;
          }
          for (; l < layersCount;) {
           var packet = createPacket(resolution, k, l);
           l++;
           return packet;
          }
          l = 0;
         }
         r = 0;
        }
        c = 0;
       }
       px = 0;
      }
      error('JPX Error: Out of packets');
     };
    }
    function ComponentPositionResolutionLayerIterator(context) {
     var siz = context.SIZ;
     var tileIndex = context.currentTile.index;
     var tile = context.tiles[tileIndex];
     var layersCount = tile.codingStyleDefaultParameters.layersCount;
     var componentsCount = siz.Csiz;
     var precinctsSizes = getPrecinctSizesInImageScale(tile);
     var l = 0, r = 0, c = 0, px = 0, py = 0;
     this.nextPacket = function JpxImage_nextPacket() {
      for (; c < componentsCount; ++c) {
       var component = tile.components[c];
       var precinctsIterationSizes = precinctsSizes.components[c];
       var decompositionLevelsCount = component.codingStyleParameters.decompositionLevelsCount;
       for (; py < precinctsIterationSizes.maxNumHigh; py++) {
        for (; px < precinctsIterationSizes.maxNumWide; px++) {
         for (; r <= decompositionLevelsCount; r++) {
          var resolution = component.resolutions[r];
          var sizeInImageScale = precinctsIterationSizes.resolutions[r];
          var k = getPrecinctIndexIfExist(px, py, sizeInImageScale, precinctsIterationSizes, resolution);
          if (k === null) {
           continue;
          }
          for (; l < layersCount;) {
           var packet = createPacket(resolution, k, l);
           l++;
           return packet;
          }
          l = 0;
         }
         r = 0;
        }
        px = 0;
       }
       py = 0;
      }
      error('JPX Error: Out of packets');
     };
    }
    function getPrecinctIndexIfExist(pxIndex, pyIndex, sizeInImageScale, precinctIterationSizes, resolution) {
     var posX = pxIndex * precinctIterationSizes.minWidth;
     var posY = pyIndex * precinctIterationSizes.minHeight;
     if (posX % sizeInImageScale.width !== 0 || posY % sizeInImageScale.height !== 0) {
      return null;
     }
     var startPrecinctRowIndex = posY / sizeInImageScale.width * resolution.precinctParameters.numprecinctswide;
     return posX / sizeInImageScale.height + startPrecinctRowIndex;
    }
    function getPrecinctSizesInImageScale(tile) {
     var componentsCount = tile.components.length;
     var minWidth = Number.MAX_VALUE;
     var minHeight = Number.MAX_VALUE;
     var maxNumWide = 0;
     var maxNumHigh = 0;
     var sizePerComponent = new Array(componentsCount);
     for (var c = 0; c < componentsCount; c++) {
      var component = tile.components[c];
      var decompositionLevelsCount = component.codingStyleParameters.decompositionLevelsCount;
      var sizePerResolution = new Array(decompositionLevelsCount + 1);
      var minWidthCurrentComponent = Number.MAX_VALUE;
      var minHeightCurrentComponent = Number.MAX_VALUE;
      var maxNumWideCurrentComponent = 0;
      var maxNumHighCurrentComponent = 0;
      var scale = 1;
      for (var r = decompositionLevelsCount; r >= 0; --r) {
       var resolution = component.resolutions[r];
       var widthCurrentResolution = scale * resolution.precinctParameters.precinctWidth;
       var heightCurrentResolution = scale * resolution.precinctParameters.precinctHeight;
       minWidthCurrentComponent = Math.min(minWidthCurrentComponent, widthCurrentResolution);
       minHeightCurrentComponent = Math.min(minHeightCurrentComponent, heightCurrentResolution);
       maxNumWideCurrentComponent = Math.max(maxNumWideCurrentComponent, resolution.precinctParameters.numprecinctswide);
       maxNumHighCurrentComponent = Math.max(maxNumHighCurrentComponent, resolution.precinctParameters.numprecinctshigh);
       sizePerResolution[r] = {
        width: widthCurrentResolution,
        height: heightCurrentResolution
       };
       scale <<= 1;
      }
      minWidth = Math.min(minWidth, minWidthCurrentComponent);
      minHeight = Math.min(minHeight, minHeightCurrentComponent);
      maxNumWide = Math.max(maxNumWide, maxNumWideCurrentComponent);
      maxNumHigh = Math.max(maxNumHigh, maxNumHighCurrentComponent);
      sizePerComponent[c] = {
       resolutions: sizePerResolution,
       minWidth: minWidthCurrentComponent,
       minHeight: minHeightCurrentComponent,
       maxNumWide: maxNumWideCurrentComponent,
       maxNumHigh: maxNumHighCurrentComponent
      };
     }
     return {
      components: sizePerComponent,
      minWidth: minWidth,
      minHeight: minHeight,
      maxNumWide: maxNumWide,
      maxNumHigh: maxNumHigh
     };
    }
    function buildPackets(context) {
     var siz = context.SIZ;
     var tileIndex = context.currentTile.index;
     var tile = context.tiles[tileIndex];
     var componentsCount = siz.Csiz;
     for (var c = 0; c < componentsCount; c++) {
      var component = tile.components[c];
      var decompositionLevelsCount = component.codingStyleParameters.decompositionLevelsCount;
      var resolutions = [];
      var subbands = [];
      for (var r = 0; r <= decompositionLevelsCount; r++) {
       var blocksDimensions = getBlocksDimensions(context, component, r);
       var resolution = {};
       var scale = 1 << decompositionLevelsCount - r;
       resolution.trx0 = Math.ceil(component.tcx0 / scale);
       resolution.try0 = Math.ceil(component.tcy0 / scale);
       resolution.trx1 = Math.ceil(component.tcx1 / scale);
       resolution.try1 = Math.ceil(component.tcy1 / scale);
       resolution.resLevel = r;
       buildPrecincts(context, resolution, blocksDimensions);
       resolutions.push(resolution);
       var subband;
       if (r === 0) {
        subband = {};
        subband.type = 'LL';
        subband.tbx0 = Math.ceil(component.tcx0 / scale);
        subband.tby0 = Math.ceil(component.tcy0 / scale);
        subband.tbx1 = Math.ceil(component.tcx1 / scale);
        subband.tby1 = Math.ceil(component.tcy1 / scale);
        subband.resolution = resolution;
        buildCodeblocks(context, subband, blocksDimensions);
        subbands.push(subband);
        resolution.subbands = [subband];
       } else {
        var bscale = 1 << decompositionLevelsCount - r + 1;
        var resolutionSubbands = [];
        subband = {};
        subband.type = 'HL';
        subband.tbx0 = Math.ceil(component.tcx0 / bscale - 0.5);
        subband.tby0 = Math.ceil(component.tcy0 / bscale);
        subband.tbx1 = Math.ceil(component.tcx1 / bscale - 0.5);
        subband.tby1 = Math.ceil(component.tcy1 / bscale);
        subband.resolution = resolution;
        buildCodeblocks(context, subband, blocksDimensions);
        subbands.push(subband);
        resolutionSubbands.push(subband);
        subband = {};
        subband.type = 'LH';
        subband.tbx0 = Math.ceil(component.tcx0 / bscale);
        subband.tby0 = Math.ceil(component.tcy0 / bscale - 0.5);
        subband.tbx1 = Math.ceil(component.tcx1 / bscale);
        subband.tby1 = Math.ceil(component.tcy1 / bscale - 0.5);
        subband.resolution = resolution;
        buildCodeblocks(context, subband, blocksDimensions);
        subbands.push(subband);
        resolutionSubbands.push(subband);
        subband = {};
        subband.type = 'HH';
        subband.tbx0 = Math.ceil(component.tcx0 / bscale - 0.5);
        subband.tby0 = Math.ceil(component.tcy0 / bscale - 0.5);
        subband.tbx1 = Math.ceil(component.tcx1 / bscale - 0.5);
        subband.tby1 = Math.ceil(component.tcy1 / bscale - 0.5);
        subband.resolution = resolution;
        buildCodeblocks(context, subband, blocksDimensions);
        subbands.push(subband);
        resolutionSubbands.push(subband);
        resolution.subbands = resolutionSubbands;
       }
      }
      component.resolutions = resolutions;
      component.subbands = subbands;
     }
     var progressionOrder = tile.codingStyleDefaultParameters.progressionOrder;
     switch (progressionOrder) {
     case 0:
      tile.packetsIterator = new LayerResolutionComponentPositionIterator(context);
      break;
     case 1:
      tile.packetsIterator = new ResolutionLayerComponentPositionIterator(context);
      break;
     case 2:
      tile.packetsIterator = new ResolutionPositionComponentLayerIterator(context);
      break;
     case 3:
      tile.packetsIterator = new PositionComponentResolutionLayerIterator(context);
      break;
     case 4:
      tile.packetsIterator = new ComponentPositionResolutionLayerIterator(context);
      break;
     default:
      error('JPX Error: Unsupported progression order ' + progressionOrder);
     }
    }
    function parseTilePackets(context, data, offset, dataLength) {
     var position = 0;
     var buffer, bufferSize = 0, skipNextBit = false;
     function readBits(count) {
      while (bufferSize < count) {
       var b = data[offset + position];
       position++;
       if (skipNextBit) {
        buffer = buffer << 7 | b;
        bufferSize += 7;
        skipNextBit = false;
       } else {
        buffer = buffer << 8 | b;
        bufferSize += 8;
       }
       if (b === 0xFF) {
        skipNextBit = true;
       }
      }
      bufferSize -= count;
      return buffer >>> bufferSize & (1 << count) - 1;
     }
     function skipMarkerIfEqual(value) {
      if (data[offset + position - 1] === 0xFF && data[offset + position] === value) {
       skipBytes(1);
       return true;
      } else if (data[offset + position] === 0xFF && data[offset + position + 1] === value) {
       skipBytes(2);
       return true;
      }
      return false;
     }
     function skipBytes(count) {
      position += count;
     }
     function alignToByte() {
      bufferSize = 0;
      if (skipNextBit) {
       position++;
       skipNextBit = false;
      }
     }
     function readCodingpasses() {
      if (readBits(1) === 0) {
       return 1;
      }
      if (readBits(1) === 0) {
       return 2;
      }
      var value = readBits(2);
      if (value < 3) {
       return value + 3;
      }
      value = readBits(5);
      if (value < 31) {
       return value + 6;
      }
      value = readBits(7);
      return value + 37;
     }
     var tileIndex = context.currentTile.index;
     var tile = context.tiles[tileIndex];
     var sopMarkerUsed = context.COD.sopMarkerUsed;
     var ephMarkerUsed = context.COD.ephMarkerUsed;
     var packetsIterator = tile.packetsIterator;
     while (position < dataLength) {
      alignToByte();
      if (sopMarkerUsed && skipMarkerIfEqual(0x91)) {
       skipBytes(4);
      }
      var packet = packetsIterator.nextPacket();
      if (!readBits(1)) {
       continue;
      }
      var layerNumber = packet.layerNumber;
      var queue = [], codeblock;
      for (var i = 0, ii = packet.codeblocks.length; i < ii; i++) {
       codeblock = packet.codeblocks[i];
       var precinct = codeblock.precinct;
       var codeblockColumn = codeblock.cbx - precinct.cbxMin;
       var codeblockRow = codeblock.cby - precinct.cbyMin;
       var codeblockIncluded = false;
       var firstTimeInclusion = false;
       var valueReady;
       if (codeblock['included'] !== undefined) {
        codeblockIncluded = !!readBits(1);
       } else {
        precinct = codeblock.precinct;
        var inclusionTree, zeroBitPlanesTree;
        if (precinct['inclusionTree'] !== undefined) {
         inclusionTree = precinct.inclusionTree;
        } else {
         var width = precinct.cbxMax - precinct.cbxMin + 1;
         var height = precinct.cbyMax - precinct.cbyMin + 1;
         inclusionTree = new InclusionTree(width, height, layerNumber);
         zeroBitPlanesTree = new TagTree(width, height);
         precinct.inclusionTree = inclusionTree;
         precinct.zeroBitPlanesTree = zeroBitPlanesTree;
        }
        if (inclusionTree.reset(codeblockColumn, codeblockRow, layerNumber)) {
         while (true) {
          if (readBits(1)) {
           valueReady = !inclusionTree.nextLevel();
           if (valueReady) {
            codeblock.included = true;
            codeblockIncluded = firstTimeInclusion = true;
            break;
           }
          } else {
           inclusionTree.incrementValue(layerNumber);
           break;
          }
         }
        }
       }
       if (!codeblockIncluded) {
        continue;
       }
       if (firstTimeInclusion) {
        zeroBitPlanesTree = precinct.zeroBitPlanesTree;
        zeroBitPlanesTree.reset(codeblockColumn, codeblockRow);
        while (true) {
         if (readBits(1)) {
          valueReady = !zeroBitPlanesTree.nextLevel();
          if (valueReady) {
           break;
          }
         } else {
          zeroBitPlanesTree.incrementValue();
         }
        }
        codeblock.zeroBitPlanes = zeroBitPlanesTree.value;
       }
       var codingpasses = readCodingpasses();
       while (readBits(1)) {
        codeblock.Lblock++;
       }
       var codingpassesLog2 = log2(codingpasses);
       var bits = (codingpasses < 1 << codingpassesLog2 ? codingpassesLog2 - 1 : codingpassesLog2) + codeblock.Lblock;
       var codedDataLength = readBits(bits);
       queue.push({
        codeblock: codeblock,
        codingpasses: codingpasses,
        dataLength: codedDataLength
       });
      }
      alignToByte();
      if (ephMarkerUsed) {
       skipMarkerIfEqual(0x92);
      }
      while (queue.length > 0) {
       var packetItem = queue.shift();
       codeblock = packetItem.codeblock;
       if (codeblock['data'] === undefined) {
        codeblock.data = [];
       }
       codeblock.data.push({
        data: data,
        start: offset + position,
        end: offset + position + packetItem.dataLength,
        codingpasses: packetItem.codingpasses
       });
       position += packetItem.dataLength;
      }
     }
     return position;
    }
    function copyCoefficients(coefficients, levelWidth, levelHeight, subband, delta, mb, reversible, segmentationSymbolUsed) {
     var x0 = subband.tbx0;
     var y0 = subband.tby0;
     var width = subband.tbx1 - subband.tbx0;
     var codeblocks = subband.codeblocks;
     var right = subband.type.charAt(0) === 'H' ? 1 : 0;
     var bottom = subband.type.charAt(1) === 'H' ? levelWidth : 0;
     for (var i = 0, ii = codeblocks.length; i < ii; ++i) {
      var codeblock = codeblocks[i];
      var blockWidth = codeblock.tbx1_ - codeblock.tbx0_;
      var blockHeight = codeblock.tby1_ - codeblock.tby0_;
      if (blockWidth === 0 || blockHeight === 0) {
       continue;
      }
      if (codeblock['data'] === undefined) {
       continue;
      }
      var bitModel, currentCodingpassType;
      bitModel = new BitModel(blockWidth, blockHeight, codeblock.subbandType, codeblock.zeroBitPlanes, mb);
      currentCodingpassType = 2;
      var data = codeblock.data, totalLength = 0, codingpasses = 0;
      var j, jj, dataItem;
      for (j = 0, jj = data.length; j < jj; j++) {
       dataItem = data[j];
       totalLength += dataItem.end - dataItem.start;
       codingpasses += dataItem.codingpasses;
      }
      var encodedData = new Uint8Array(totalLength);
      var position = 0;
      for (j = 0, jj = data.length; j < jj; j++) {
       dataItem = data[j];
       var chunk = dataItem.data.subarray(dataItem.start, dataItem.end);
       encodedData.set(chunk, position);
       position += chunk.length;
      }
      var decoder = new ArithmeticDecoder(encodedData, 0, totalLength);
      bitModel.setDecoder(decoder);
      for (j = 0; j < codingpasses; j++) {
       switch (currentCodingpassType) {
       case 0:
        bitModel.runSignificancePropagationPass();
        break;
       case 1:
        bitModel.runMagnitudeRefinementPass();
        break;
       case 2:
        bitModel.runCleanupPass();
        if (segmentationSymbolUsed) {
         bitModel.checkSegmentationSymbol();
        }
        break;
       }
       currentCodingpassType = (currentCodingpassType + 1) % 3;
      }
      var offset = codeblock.tbx0_ - x0 + (codeblock.tby0_ - y0) * width;
      var sign = bitModel.coefficentsSign;
      var magnitude = bitModel.coefficentsMagnitude;
      var bitsDecoded = bitModel.bitsDecoded;
      var magnitudeCorrection = reversible ? 0 : 0.5;
      var k, n, nb;
      position = 0;
      var interleave = subband.type !== 'LL';
      for (j = 0; j < blockHeight; j++) {
       var row = offset / width | 0;
       var levelOffset = 2 * row * (levelWidth - width) + right + bottom;
       for (k = 0; k < blockWidth; k++) {
        n = magnitude[position];
        if (n !== 0) {
         n = (n + magnitudeCorrection) * delta;
         if (sign[position] !== 0) {
          n = -n;
         }
         nb = bitsDecoded[position];
         var pos = interleave ? levelOffset + (offset << 1) : offset;
         if (reversible && nb >= mb) {
          coefficients[pos] = n;
         } else {
          coefficients[pos] = n * (1 << mb - nb);
         }
        }
        offset++;
        position++;
       }
       offset += width - blockWidth;
      }
     }
    }
    function transformTile(context, tile, c) {
     var component = tile.components[c];
     var codingStyleParameters = component.codingStyleParameters;
     var quantizationParameters = component.quantizationParameters;
     var decompositionLevelsCount = codingStyleParameters.decompositionLevelsCount;
     var spqcds = quantizationParameters.SPqcds;
     var scalarExpounded = quantizationParameters.scalarExpounded;
     var guardBits = quantizationParameters.guardBits;
     var segmentationSymbolUsed = codingStyleParameters.segmentationSymbolUsed;
     var precision = context.components[c].precision;
     var reversible = codingStyleParameters.reversibleTransformation;
     var transform = reversible ? new ReversibleTransform() : new IrreversibleTransform();
     var subbandCoefficients = [];
     var b = 0;
     for (var i = 0; i <= decompositionLevelsCount; i++) {
      var resolution = component.resolutions[i];
      var width = resolution.trx1 - resolution.trx0;
      var height = resolution.try1 - resolution.try0;
      var coefficients = new Float32Array(width * height);
      for (var j = 0, jj = resolution.subbands.length; j < jj; j++) {
       var mu, epsilon;
       if (!scalarExpounded) {
        mu = spqcds[0].mu;
        epsilon = spqcds[0].epsilon + (i > 0 ? 1 - i : 0);
       } else {
        mu = spqcds[b].mu;
        epsilon = spqcds[b].epsilon;
        b++;
       }
       var subband = resolution.subbands[j];
       var gainLog2 = SubbandsGainLog2[subband.type];
       var delta = reversible ? 1 : Math.pow(2, precision + gainLog2 - epsilon) * (1 + mu / 2048);
       var mb = guardBits + epsilon - 1;
       copyCoefficients(coefficients, width, height, subband, delta, mb, reversible, segmentationSymbolUsed);
      }
      subbandCoefficients.push({
       width: width,
       height: height,
       items: coefficients
      });
     }
     var result = transform.calculate(subbandCoefficients, component.tcx0, component.tcy0);
     return {
      left: component.tcx0,
      top: component.tcy0,
      width: result.width,
      height: result.height,
      items: result.items
     };
    }
    function transformComponents(context) {
     var siz = context.SIZ;
     var components = context.components;
     var componentsCount = siz.Csiz;
     var resultImages = [];
     for (var i = 0, ii = context.tiles.length; i < ii; i++) {
      var tile = context.tiles[i];
      var transformedTiles = [];
      var c;
      for (c = 0; c < componentsCount; c++) {
       transformedTiles[c] = transformTile(context, tile, c);
      }
      var tile0 = transformedTiles[0];
      var out = new Uint8Array(tile0.items.length * componentsCount);
      var result = {
       left: tile0.left,
       top: tile0.top,
       width: tile0.width,
       height: tile0.height,
       items: out
      };
      var shift, offset, max, min, maxK;
      var pos = 0, j, jj, y0, y1, y2, r, g, b, k, val;
      if (tile.codingStyleDefaultParameters.multipleComponentTransform) {
       var fourComponents = componentsCount === 4;
       var y0items = transformedTiles[0].items;
       var y1items = transformedTiles[1].items;
       var y2items = transformedTiles[2].items;
       var y3items = fourComponents ? transformedTiles[3].items : null;
       shift = components[0].precision - 8;
       offset = (128 << shift) + 0.5;
       max = 255 * (1 << shift);
       maxK = max * 0.5;
       min = -maxK;
       var component0 = tile.components[0];
       var alpha01 = componentsCount - 3;
       jj = y0items.length;
       if (!component0.codingStyleParameters.reversibleTransformation) {
        for (j = 0; j < jj; j++, pos += alpha01) {
         y0 = y0items[j] + offset;
         y1 = y1items[j];
         y2 = y2items[j];
         r = y0 + 1.402 * y2;
         g = y0 - 0.34413 * y1 - 0.71414 * y2;
         b = y0 + 1.772 * y1;
         out[pos++] = r <= 0 ? 0 : r >= max ? 255 : r >> shift;
         out[pos++] = g <= 0 ? 0 : g >= max ? 255 : g >> shift;
         out[pos++] = b <= 0 ? 0 : b >= max ? 255 : b >> shift;
        }
       } else {
        for (j = 0; j < jj; j++, pos += alpha01) {
         y0 = y0items[j] + offset;
         y1 = y1items[j];
         y2 = y2items[j];
         g = y0 - (y2 + y1 >> 2);
         r = g + y2;
         b = g + y1;
         out[pos++] = r <= 0 ? 0 : r >= max ? 255 : r >> shift;
         out[pos++] = g <= 0 ? 0 : g >= max ? 255 : g >> shift;
         out[pos++] = b <= 0 ? 0 : b >= max ? 255 : b >> shift;
        }
       }
       if (fourComponents) {
        for (j = 0, pos = 3; j < jj; j++, pos += 4) {
         k = y3items[j];
         out[pos] = k <= min ? 0 : k >= maxK ? 255 : k + offset >> shift;
        }
       }
      } else {
       for (c = 0; c < componentsCount; c++) {
        var items = transformedTiles[c].items;
        shift = components[c].precision - 8;
        offset = (128 << shift) + 0.5;
        max = 127.5 * (1 << shift);
        min = -max;
        for (pos = c, j = 0, jj = items.length; j < jj; j++) {
         val = items[j];
         out[pos] = val <= min ? 0 : val >= max ? 255 : val + offset >> shift;
         pos += componentsCount;
        }
       }
      }
      resultImages.push(result);
     }
     return resultImages;
    }
    function initializeTile(context, tileIndex) {
     var siz = context.SIZ;
     var componentsCount = siz.Csiz;
     var tile = context.tiles[tileIndex];
     for (var c = 0; c < componentsCount; c++) {
      var component = tile.components[c];
      var qcdOrQcc = context.currentTile.QCC[c] !== undefined ? context.currentTile.QCC[c] : context.currentTile.QCD;
      component.quantizationParameters = qcdOrQcc;
      var codOrCoc = context.currentTile.COC[c] !== undefined ? context.currentTile.COC[c] : context.currentTile.COD;
      component.codingStyleParameters = codOrCoc;
     }
     tile.codingStyleDefaultParameters = context.currentTile.COD;
    }
    var TagTree = function TagTreeClosure() {
     function TagTree(width, height) {
      var levelsLength = log2(Math.max(width, height)) + 1;
      this.levels = [];
      for (var i = 0; i < levelsLength; i++) {
       var level = {
        width: width,
        height: height,
        items: []
       };
       this.levels.push(level);
       width = Math.ceil(width / 2);
       height = Math.ceil(height / 2);
      }
     }
     TagTree.prototype = {
      reset: function TagTree_reset(i, j) {
       var currentLevel = 0, value = 0, level;
       while (currentLevel < this.levels.length) {
        level = this.levels[currentLevel];
        var index = i + j * level.width;
        if (level.items[index] !== undefined) {
         value = level.items[index];
         break;
        }
        level.index = index;
        i >>= 1;
        j >>= 1;
        currentLevel++;
       }
       currentLevel--;
       level = this.levels[currentLevel];
       level.items[level.index] = value;
       this.currentLevel = currentLevel;
       delete this.value;
      },
      incrementValue: function TagTree_incrementValue() {
       var level = this.levels[this.currentLevel];
       level.items[level.index]++;
      },
      nextLevel: function TagTree_nextLevel() {
       var currentLevel = this.currentLevel;
       var level = this.levels[currentLevel];
       var value = level.items[level.index];
       currentLevel--;
       if (currentLevel < 0) {
        this.value = value;
        return false;
       }
       this.currentLevel = currentLevel;
       level = this.levels[currentLevel];
       level.items[level.index] = value;
       return true;
      }
     };
     return TagTree;
    }();
    var InclusionTree = function InclusionTreeClosure() {
     function InclusionTree(width, height, defaultValue) {
      var levelsLength = log2(Math.max(width, height)) + 1;
      this.levels = [];
      for (var i = 0; i < levelsLength; i++) {
       var items = new Uint8Array(width * height);
       for (var j = 0, jj = items.length; j < jj; j++) {
        items[j] = defaultValue;
       }
       var level = {
        width: width,
        height: height,
        items: items
       };
       this.levels.push(level);
       width = Math.ceil(width / 2);
       height = Math.ceil(height / 2);
      }
     }
     InclusionTree.prototype = {
      reset: function InclusionTree_reset(i, j, stopValue) {
       var currentLevel = 0;
       while (currentLevel < this.levels.length) {
        var level = this.levels[currentLevel];
        var index = i + j * level.width;
        level.index = index;
        var value = level.items[index];
        if (value === 0xFF) {
         break;
        }
        if (value > stopValue) {
         this.currentLevel = currentLevel;
         this.propagateValues();
         return false;
        }
        i >>= 1;
        j >>= 1;
        currentLevel++;
       }
       this.currentLevel = currentLevel - 1;
       return true;
      },
      incrementValue: function InclusionTree_incrementValue(stopValue) {
       var level = this.levels[this.currentLevel];
       level.items[level.index] = stopValue + 1;
       this.propagateValues();
      },
      propagateValues: function InclusionTree_propagateValues() {
       var levelIndex = this.currentLevel;
       var level = this.levels[levelIndex];
       var currentValue = level.items[level.index];
       while (--levelIndex >= 0) {
        level = this.levels[levelIndex];
        level.items[level.index] = currentValue;
       }
      },
      nextLevel: function InclusionTree_nextLevel() {
       var currentLevel = this.currentLevel;
       var level = this.levels[currentLevel];
       var value = level.items[level.index];
       level.items[level.index] = 0xFF;
       currentLevel--;
       if (currentLevel < 0) {
        return false;
       }
       this.currentLevel = currentLevel;
       level = this.levels[currentLevel];
       level.items[level.index] = value;
       return true;
      }
     };
     return InclusionTree;
    }();
    var BitModel = function BitModelClosure() {
     var UNIFORM_CONTEXT = 17;
     var RUNLENGTH_CONTEXT = 18;
     var LLAndLHContextsLabel = new Uint8Array([
      0,
      5,
      8,
      0,
      3,
      7,
      8,
      0,
      4,
      7,
      8,
      0,
      0,
      0,
      0,
      0,
      1,
      6,
      8,
      0,
      3,
      7,
      8,
      0,
      4,
      7,
      8,
      0,
      0,
      0,
      0,
      0,
      2,
      6,
      8,
      0,
      3,
      7,
      8,
      0,
      4,
      7,
      8,
      0,
      0,
      0,
      0,
      0,
      2,
      6,
      8,
      0,
      3,
      7,
      8,
      0,
      4,
      7,
      8,
      0,
      0,
      0,
      0,
      0,
      2,
      6,
      8,
      0,
      3,
      7,
      8,
      0,
      4,
      7,
      8
     ]);
     var HLContextLabel = new Uint8Array([
      0,
      3,
      4,
      0,
      5,
      7,
      7,
      0,
      8,
      8,
      8,
      0,
      0,
      0,
      0,
      0,
      1,
      3,
      4,
      0,
      6,
      7,
      7,
      0,
      8,
      8,
      8,
      0,
      0,
      0,
      0,
      0,
      2,
      3,
      4,
      0,
      6,
      7,
      7,
      0,
      8,
      8,
      8,
      0,
      0,
      0,
      0,
      0,
      2,
      3,
      4,
      0,
      6,
      7,
      7,
      0,
      8,
      8,
      8,
      0,
      0,
      0,
      0,
      0,
      2,
      3,
      4,
      0,
      6,
      7,
      7,
      0,
      8,
      8,
      8
     ]);
     var HHContextLabel = new Uint8Array([
      0,
      1,
      2,
      0,
      1,
      2,
      2,
      0,
      2,
      2,
      2,
      0,
      0,
      0,
      0,
      0,
      3,
      4,
      5,
      0,
      4,
      5,
      5,
      0,
      5,
      5,
      5,
      0,
      0,
      0,
      0,
      0,
      6,
      7,
      7,
      0,
      7,
      7,
      7,
      0,
      7,
      7,
      7,
      0,
      0,
      0,
      0,
      0,
      8,
      8,
      8,
      0,
      8,
      8,
      8,
      0,
      8,
      8,
      8,
      0,
      0,
      0,
      0,
      0,
      8,
      8,
      8,
      0,
      8,
      8,
      8,
      0,
      8,
      8,
      8
     ]);
     function BitModel(width, height, subband, zeroBitPlanes, mb) {
      this.width = width;
      this.height = height;
      this.contextLabelTable = subband === 'HH' ? HHContextLabel : subband === 'HL' ? HLContextLabel : LLAndLHContextsLabel;
      var coefficientCount = width * height;
      this.neighborsSignificance = new Uint8Array(coefficientCount);
      this.coefficentsSign = new Uint8Array(coefficientCount);
      this.coefficentsMagnitude = mb > 14 ? new Uint32Array(coefficientCount) : mb > 6 ? new Uint16Array(coefficientCount) : new Uint8Array(coefficientCount);
      this.processingFlags = new Uint8Array(coefficientCount);
      var bitsDecoded = new Uint8Array(coefficientCount);
      if (zeroBitPlanes !== 0) {
       for (var i = 0; i < coefficientCount; i++) {
        bitsDecoded[i] = zeroBitPlanes;
       }
      }
      this.bitsDecoded = bitsDecoded;
      this.reset();
     }
     BitModel.prototype = {
      setDecoder: function BitModel_setDecoder(decoder) {
       this.decoder = decoder;
      },
      reset: function BitModel_reset() {
       this.contexts = new Int8Array(19);
       this.contexts[0] = 4 << 1 | 0;
       this.contexts[UNIFORM_CONTEXT] = 46 << 1 | 0;
       this.contexts[RUNLENGTH_CONTEXT] = 3 << 1 | 0;
      },
      setNeighborsSignificance: function BitModel_setNeighborsSignificance(row, column, index) {
       var neighborsSignificance = this.neighborsSignificance;
       var width = this.width, height = this.height;
       var left = column > 0;
       var right = column + 1 < width;
       var i;
       if (row > 0) {
        i = index - width;
        if (left) {
         neighborsSignificance[i - 1] += 0x10;
        }
        if (right) {
         neighborsSignificance[i + 1] += 0x10;
        }
        neighborsSignificance[i] += 0x04;
       }
       if (row + 1 < height) {
        i = index + width;
        if (left) {
         neighborsSignificance[i - 1] += 0x10;
        }
        if (right) {
         neighborsSignificance[i + 1] += 0x10;
        }
        neighborsSignificance[i] += 0x04;
       }
       if (left) {
        neighborsSignificance[index - 1] += 0x01;
       }
       if (right) {
        neighborsSignificance[index + 1] += 0x01;
       }
       neighborsSignificance[index] |= 0x80;
      },
      runSignificancePropagationPass: function BitModel_runSignificancePropagationPass() {
       var decoder = this.decoder;
       var width = this.width, height = this.height;
       var coefficentsMagnitude = this.coefficentsMagnitude;
       var coefficentsSign = this.coefficentsSign;
       var neighborsSignificance = this.neighborsSignificance;
       var processingFlags = this.processingFlags;
       var contexts = this.contexts;
       var labels = this.contextLabelTable;
       var bitsDecoded = this.bitsDecoded;
       var processedInverseMask = ~1;
       var processedMask = 1;
       var firstMagnitudeBitMask = 2;
       for (var i0 = 0; i0 < height; i0 += 4) {
        for (var j = 0; j < width; j++) {
         var index = i0 * width + j;
         for (var i1 = 0; i1 < 4; i1++, index += width) {
          var i = i0 + i1;
          if (i >= height) {
           break;
          }
          processingFlags[index] &= processedInverseMask;
          if (coefficentsMagnitude[index] || !neighborsSignificance[index]) {
           continue;
          }
          var contextLabel = labels[neighborsSignificance[index]];
          var decision = decoder.readBit(contexts, contextLabel);
          if (decision) {
           var sign = this.decodeSignBit(i, j, index);
           coefficentsSign[index] = sign;
           coefficentsMagnitude[index] = 1;
           this.setNeighborsSignificance(i, j, index);
           processingFlags[index] |= firstMagnitudeBitMask;
          }
          bitsDecoded[index]++;
          processingFlags[index] |= processedMask;
         }
        }
       }
      },
      decodeSignBit: function BitModel_decodeSignBit(row, column, index) {
       var width = this.width, height = this.height;
       var coefficentsMagnitude = this.coefficentsMagnitude;
       var coefficentsSign = this.coefficentsSign;
       var contribution, sign0, sign1, significance1;
       var contextLabel, decoded;
       significance1 = column > 0 && coefficentsMagnitude[index - 1] !== 0;
       if (column + 1 < width && coefficentsMagnitude[index + 1] !== 0) {
        sign1 = coefficentsSign[index + 1];
        if (significance1) {
         sign0 = coefficentsSign[index - 1];
         contribution = 1 - sign1 - sign0;
        } else {
         contribution = 1 - sign1 - sign1;
        }
       } else if (significance1) {
        sign0 = coefficentsSign[index - 1];
        contribution = 1 - sign0 - sign0;
       } else {
        contribution = 0;
       }
       var horizontalContribution = 3 * contribution;
       significance1 = row > 0 && coefficentsMagnitude[index - width] !== 0;
       if (row + 1 < height && coefficentsMagnitude[index + width] !== 0) {
        sign1 = coefficentsSign[index + width];
        if (significance1) {
         sign0 = coefficentsSign[index - width];
         contribution = 1 - sign1 - sign0 + horizontalContribution;
        } else {
         contribution = 1 - sign1 - sign1 + horizontalContribution;
        }
       } else if (significance1) {
        sign0 = coefficentsSign[index - width];
        contribution = 1 - sign0 - sign0 + horizontalContribution;
       } else {
        contribution = horizontalContribution;
       }
       if (contribution >= 0) {
        contextLabel = 9 + contribution;
        decoded = this.decoder.readBit(this.contexts, contextLabel);
       } else {
        contextLabel = 9 - contribution;
        decoded = this.decoder.readBit(this.contexts, contextLabel) ^ 1;
       }
       return decoded;
      },
      runMagnitudeRefinementPass: function BitModel_runMagnitudeRefinementPass() {
       var decoder = this.decoder;
       var width = this.width, height = this.height;
       var coefficentsMagnitude = this.coefficentsMagnitude;
       var neighborsSignificance = this.neighborsSignificance;
       var contexts = this.contexts;
       var bitsDecoded = this.bitsDecoded;
       var processingFlags = this.processingFlags;
       var processedMask = 1;
       var firstMagnitudeBitMask = 2;
       var length = width * height;
       var width4 = width * 4;
       for (var index0 = 0, indexNext; index0 < length; index0 = indexNext) {
        indexNext = Math.min(length, index0 + width4);
        for (var j = 0; j < width; j++) {
         for (var index = index0 + j; index < indexNext; index += width) {
          if (!coefficentsMagnitude[index] || (processingFlags[index] & processedMask) !== 0) {
           continue;
          }
          var contextLabel = 16;
          if ((processingFlags[index] & firstMagnitudeBitMask) !== 0) {
           processingFlags[index] ^= firstMagnitudeBitMask;
           var significance = neighborsSignificance[index] & 127;
           contextLabel = significance === 0 ? 15 : 14;
          }
          var bit = decoder.readBit(contexts, contextLabel);
          coefficentsMagnitude[index] = coefficentsMagnitude[index] << 1 | bit;
          bitsDecoded[index]++;
          processingFlags[index] |= processedMask;
         }
        }
       }
      },
      runCleanupPass: function BitModel_runCleanupPass() {
       var decoder = this.decoder;
       var width = this.width, height = this.height;
       var neighborsSignificance = this.neighborsSignificance;
       var coefficentsMagnitude = this.coefficentsMagnitude;
       var coefficentsSign = this.coefficentsSign;
       var contexts = this.contexts;
       var labels = this.contextLabelTable;
       var bitsDecoded = this.bitsDecoded;
       var processingFlags = this.processingFlags;
       var processedMask = 1;
       var firstMagnitudeBitMask = 2;
       var oneRowDown = width;
       var twoRowsDown = width * 2;
       var threeRowsDown = width * 3;
       var iNext;
       for (var i0 = 0; i0 < height; i0 = iNext) {
        iNext = Math.min(i0 + 4, height);
        var indexBase = i0 * width;
        var checkAllEmpty = i0 + 3 < height;
        for (var j = 0; j < width; j++) {
         var index0 = indexBase + j;
         var allEmpty = checkAllEmpty && processingFlags[index0] === 0 && processingFlags[index0 + oneRowDown] === 0 && processingFlags[index0 + twoRowsDown] === 0 && processingFlags[index0 + threeRowsDown] === 0 && neighborsSignificance[index0] === 0 && neighborsSignificance[index0 + oneRowDown] === 0 && neighborsSignificance[index0 + twoRowsDown] === 0 && neighborsSignificance[index0 + threeRowsDown] === 0;
         var i1 = 0, index = index0;
         var i = i0, sign;
         if (allEmpty) {
          var hasSignificantCoefficent = decoder.readBit(contexts, RUNLENGTH_CONTEXT);
          if (!hasSignificantCoefficent) {
           bitsDecoded[index0]++;
           bitsDecoded[index0 + oneRowDown]++;
           bitsDecoded[index0 + twoRowsDown]++;
           bitsDecoded[index0 + threeRowsDown]++;
           continue;
          }
          i1 = decoder.readBit(contexts, UNIFORM_CONTEXT) << 1 | decoder.readBit(contexts, UNIFORM_CONTEXT);
          if (i1 !== 0) {
           i = i0 + i1;
           index += i1 * width;
          }
          sign = this.decodeSignBit(i, j, index);
          coefficentsSign[index] = sign;
          coefficentsMagnitude[index] = 1;
          this.setNeighborsSignificance(i, j, index);
          processingFlags[index] |= firstMagnitudeBitMask;
          index = index0;
          for (var i2 = i0; i2 <= i; i2++, index += width) {
           bitsDecoded[index]++;
          }
          i1++;
         }
         for (i = i0 + i1; i < iNext; i++, index += width) {
          if (coefficentsMagnitude[index] || (processingFlags[index] & processedMask) !== 0) {
           continue;
          }
          var contextLabel = labels[neighborsSignificance[index]];
          var decision = decoder.readBit(contexts, contextLabel);
          if (decision === 1) {
           sign = this.decodeSignBit(i, j, index);
           coefficentsSign[index] = sign;
           coefficentsMagnitude[index] = 1;
           this.setNeighborsSignificance(i, j, index);
           processingFlags[index] |= firstMagnitudeBitMask;
          }
          bitsDecoded[index]++;
         }
        }
       }
      },
      checkSegmentationSymbol: function BitModel_checkSegmentationSymbol() {
       var decoder = this.decoder;
       var contexts = this.contexts;
       var symbol = decoder.readBit(contexts, UNIFORM_CONTEXT) << 3 | decoder.readBit(contexts, UNIFORM_CONTEXT) << 2 | decoder.readBit(contexts, UNIFORM_CONTEXT) << 1 | decoder.readBit(contexts, UNIFORM_CONTEXT);
       if (symbol !== 0xA) {
        error('JPX Error: Invalid segmentation symbol');
       }
      }
     };
     return BitModel;
    }();
    var Transform = function TransformClosure() {
     function Transform() {
     }
     Transform.prototype.calculate = function transformCalculate(subbands, u0, v0) {
      var ll = subbands[0];
      for (var i = 1, ii = subbands.length; i < ii; i++) {
       ll = this.iterate(ll, subbands[i], u0, v0);
      }
      return ll;
     };
     Transform.prototype.extend = function extend(buffer, offset, size) {
      var i1 = offset - 1, j1 = offset + 1;
      var i2 = offset + size - 2, j2 = offset + size;
      buffer[i1--] = buffer[j1++];
      buffer[j2++] = buffer[i2--];
      buffer[i1--] = buffer[j1++];
      buffer[j2++] = buffer[i2--];
      buffer[i1--] = buffer[j1++];
      buffer[j2++] = buffer[i2--];
      buffer[i1] = buffer[j1];
      buffer[j2] = buffer[i2];
     };
     Transform.prototype.iterate = function Transform_iterate(ll, hl_lh_hh, u0, v0) {
      var llWidth = ll.width, llHeight = ll.height, llItems = ll.items;
      var width = hl_lh_hh.width;
      var height = hl_lh_hh.height;
      var items = hl_lh_hh.items;
      var i, j, k, l, u, v;
      for (k = 0, i = 0; i < llHeight; i++) {
       l = i * 2 * width;
       for (j = 0; j < llWidth; j++, k++, l += 2) {
        items[l] = llItems[k];
       }
      }
      llItems = ll.items = null;
      var bufferPadding = 4;
      var rowBuffer = new Float32Array(width + 2 * bufferPadding);
      if (width === 1) {
       if ((u0 & 1) !== 0) {
        for (v = 0, k = 0; v < height; v++, k += width) {
         items[k] *= 0.5;
        }
       }
      } else {
       for (v = 0, k = 0; v < height; v++, k += width) {
        rowBuffer.set(items.subarray(k, k + width), bufferPadding);
        this.extend(rowBuffer, bufferPadding, width);
        this.filter(rowBuffer, bufferPadding, width);
        items.set(rowBuffer.subarray(bufferPadding, bufferPadding + width), k);
       }
      }
      var numBuffers = 16;
      var colBuffers = [];
      for (i = 0; i < numBuffers; i++) {
       colBuffers.push(new Float32Array(height + 2 * bufferPadding));
      }
      var b, currentBuffer = 0;
      ll = bufferPadding + height;
      if (height === 1) {
       if ((v0 & 1) !== 0) {
        for (u = 0; u < width; u++) {
         items[u] *= 0.5;
        }
       }
      } else {
       for (u = 0; u < width; u++) {
        if (currentBuffer === 0) {
         numBuffers = Math.min(width - u, numBuffers);
         for (k = u, l = bufferPadding; l < ll; k += width, l++) {
          for (b = 0; b < numBuffers; b++) {
           colBuffers[b][l] = items[k + b];
          }
         }
         currentBuffer = numBuffers;
        }
        currentBuffer--;
        var buffer = colBuffers[currentBuffer];
        this.extend(buffer, bufferPadding, height);
        this.filter(buffer, bufferPadding, height);
        if (currentBuffer === 0) {
         k = u - numBuffers + 1;
         for (l = bufferPadding; l < ll; k += width, l++) {
          for (b = 0; b < numBuffers; b++) {
           items[k + b] = colBuffers[b][l];
          }
         }
        }
       }
      }
      return {
       width: width,
       height: height,
       items: items
      };
     };
     return Transform;
    }();
    var IrreversibleTransform = function IrreversibleTransformClosure() {
     function IrreversibleTransform() {
      Transform.call(this);
     }
     IrreversibleTransform.prototype = Object.create(Transform.prototype);
     IrreversibleTransform.prototype.filter = function irreversibleTransformFilter(x, offset, length) {
      var len = length >> 1;
      offset = offset | 0;
      var j, n, current, next;
      var alpha = -1.586134342059924;
      var beta = -0.052980118572961;
      var gamma = 0.882911075530934;
      var delta = 0.443506852043971;
      var K = 1.230174104914001;
      var K_ = 1 / K;
      j = offset - 3;
      for (n = len + 4; n--; j += 2) {
       x[j] *= K_;
      }
      j = offset - 2;
      current = delta * x[j - 1];
      for (n = len + 3; n--; j += 2) {
       next = delta * x[j + 1];
       x[j] = K * x[j] - current - next;
       if (n--) {
        j += 2;
        current = delta * x[j + 1];
        x[j] = K * x[j] - current - next;
       } else {
        break;
       }
      }
      j = offset - 1;
      current = gamma * x[j - 1];
      for (n = len + 2; n--; j += 2) {
       next = gamma * x[j + 1];
       x[j] -= current + next;
       if (n--) {
        j += 2;
        current = gamma * x[j + 1];
        x[j] -= current + next;
       } else {
        break;
       }
      }
      j = offset;
      current = beta * x[j - 1];
      for (n = len + 1; n--; j += 2) {
       next = beta * x[j + 1];
       x[j] -= current + next;
       if (n--) {
        j += 2;
        current = beta * x[j + 1];
        x[j] -= current + next;
       } else {
        break;
       }
      }
      if (len !== 0) {
       j = offset + 1;
       current = alpha * x[j - 1];
       for (n = len; n--; j += 2) {
        next = alpha * x[j + 1];
        x[j] -= current + next;
        if (n--) {
         j += 2;
         current = alpha * x[j + 1];
         x[j] -= current + next;
        } else {
         break;
        }
       }
      }
     };
     return IrreversibleTransform;
    }();
    var ReversibleTransform = function ReversibleTransformClosure() {
     function ReversibleTransform() {
      Transform.call(this);
     }
     ReversibleTransform.prototype = Object.create(Transform.prototype);
     ReversibleTransform.prototype.filter = function reversibleTransformFilter(x, offset, length) {
      var len = length >> 1;
      offset = offset | 0;
      var j, n;
      for (j = offset, n = len + 1; n--; j += 2) {
       x[j] -= x[j - 1] + x[j + 1] + 2 >> 2;
      }
      for (j = offset + 1, n = len; n--; j += 2) {
       x[j] += x[j - 1] + x[j + 1] >> 1;
      }
     };
     return ReversibleTransform;
    }();
    return JpxImage;
   }();
   exports.JpxImage = JpxImage;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreMetrics = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var getLookupTableFactory = sharedUtil.getLookupTableFactory;
   var getMetrics = getLookupTableFactory(function (t) {
    t['Courier'] = 600;
    t['Courier-Bold'] = 600;
    t['Courier-BoldOblique'] = 600;
    t['Courier-Oblique'] = 600;
    t['Helvetica'] = getLookupTableFactory(function (t) {
     t['space'] = 278;
     t['exclam'] = 278;
     t['quotedbl'] = 355;
     t['numbersign'] = 556;
     t['dollar'] = 556;
     t['percent'] = 889;
     t['ampersand'] = 667;
     t['quoteright'] = 222;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asterisk'] = 389;
     t['plus'] = 584;
     t['comma'] = 278;
     t['hyphen'] = 333;
     t['period'] = 278;
     t['slash'] = 278;
     t['zero'] = 556;
     t['one'] = 556;
     t['two'] = 556;
     t['three'] = 556;
     t['four'] = 556;
     t['five'] = 556;
     t['six'] = 556;
     t['seven'] = 556;
     t['eight'] = 556;
     t['nine'] = 556;
     t['colon'] = 278;
     t['semicolon'] = 278;
     t['less'] = 584;
     t['equal'] = 584;
     t['greater'] = 584;
     t['question'] = 556;
     t['at'] = 1015;
     t['A'] = 667;
     t['B'] = 667;
     t['C'] = 722;
     t['D'] = 722;
     t['E'] = 667;
     t['F'] = 611;
     t['G'] = 778;
     t['H'] = 722;
     t['I'] = 278;
     t['J'] = 500;
     t['K'] = 667;
     t['L'] = 556;
     t['M'] = 833;
     t['N'] = 722;
     t['O'] = 778;
     t['P'] = 667;
     t['Q'] = 778;
     t['R'] = 722;
     t['S'] = 667;
     t['T'] = 611;
     t['U'] = 722;
     t['V'] = 667;
     t['W'] = 944;
     t['X'] = 667;
     t['Y'] = 667;
     t['Z'] = 611;
     t['bracketleft'] = 278;
     t['backslash'] = 278;
     t['bracketright'] = 278;
     t['asciicircum'] = 469;
     t['underscore'] = 556;
     t['quoteleft'] = 222;
     t['a'] = 556;
     t['b'] = 556;
     t['c'] = 500;
     t['d'] = 556;
     t['e'] = 556;
     t['f'] = 278;
     t['g'] = 556;
     t['h'] = 556;
     t['i'] = 222;
     t['j'] = 222;
     t['k'] = 500;
     t['l'] = 222;
     t['m'] = 833;
     t['n'] = 556;
     t['o'] = 556;
     t['p'] = 556;
     t['q'] = 556;
     t['r'] = 333;
     t['s'] = 500;
     t['t'] = 278;
     t['u'] = 556;
     t['v'] = 500;
     t['w'] = 722;
     t['x'] = 500;
     t['y'] = 500;
     t['z'] = 500;
     t['braceleft'] = 334;
     t['bar'] = 260;
     t['braceright'] = 334;
     t['asciitilde'] = 584;
     t['exclamdown'] = 333;
     t['cent'] = 556;
     t['sterling'] = 556;
     t['fraction'] = 167;
     t['yen'] = 556;
     t['florin'] = 556;
     t['section'] = 556;
     t['currency'] = 556;
     t['quotesingle'] = 191;
     t['quotedblleft'] = 333;
     t['guillemotleft'] = 556;
     t['guilsinglleft'] = 333;
     t['guilsinglright'] = 333;
     t['fi'] = 500;
     t['fl'] = 500;
     t['endash'] = 556;
     t['dagger'] = 556;
     t['daggerdbl'] = 556;
     t['periodcentered'] = 278;
     t['paragraph'] = 537;
     t['bullet'] = 350;
     t['quotesinglbase'] = 222;
     t['quotedblbase'] = 333;
     t['quotedblright'] = 333;
     t['guillemotright'] = 556;
     t['ellipsis'] = 1000;
     t['perthousand'] = 1000;
     t['questiondown'] = 611;
     t['grave'] = 333;
     t['acute'] = 333;
     t['circumflex'] = 333;
     t['tilde'] = 333;
     t['macron'] = 333;
     t['breve'] = 333;
     t['dotaccent'] = 333;
     t['dieresis'] = 333;
     t['ring'] = 333;
     t['cedilla'] = 333;
     t['hungarumlaut'] = 333;
     t['ogonek'] = 333;
     t['caron'] = 333;
     t['emdash'] = 1000;
     t['AE'] = 1000;
     t['ordfeminine'] = 370;
     t['Lslash'] = 556;
     t['Oslash'] = 778;
     t['OE'] = 1000;
     t['ordmasculine'] = 365;
     t['ae'] = 889;
     t['dotlessi'] = 278;
     t['lslash'] = 222;
     t['oslash'] = 611;
     t['oe'] = 944;
     t['germandbls'] = 611;
     t['Idieresis'] = 278;
     t['eacute'] = 556;
     t['abreve'] = 556;
     t['uhungarumlaut'] = 556;
     t['ecaron'] = 556;
     t['Ydieresis'] = 667;
     t['divide'] = 584;
     t['Yacute'] = 667;
     t['Acircumflex'] = 667;
     t['aacute'] = 556;
     t['Ucircumflex'] = 722;
     t['yacute'] = 500;
     t['scommaaccent'] = 500;
     t['ecircumflex'] = 556;
     t['Uring'] = 722;
     t['Udieresis'] = 722;
     t['aogonek'] = 556;
     t['Uacute'] = 722;
     t['uogonek'] = 556;
     t['Edieresis'] = 667;
     t['Dcroat'] = 722;
     t['commaaccent'] = 250;
     t['copyright'] = 737;
     t['Emacron'] = 667;
     t['ccaron'] = 500;
     t['aring'] = 556;
     t['Ncommaaccent'] = 722;
     t['lacute'] = 222;
     t['agrave'] = 556;
     t['Tcommaaccent'] = 611;
     t['Cacute'] = 722;
     t['atilde'] = 556;
     t['Edotaccent'] = 667;
     t['scaron'] = 500;
     t['scedilla'] = 500;
     t['iacute'] = 278;
     t['lozenge'] = 471;
     t['Rcaron'] = 722;
     t['Gcommaaccent'] = 778;
     t['ucircumflex'] = 556;
     t['acircumflex'] = 556;
     t['Amacron'] = 667;
     t['rcaron'] = 333;
     t['ccedilla'] = 500;
     t['Zdotaccent'] = 611;
     t['Thorn'] = 667;
     t['Omacron'] = 778;
     t['Racute'] = 722;
     t['Sacute'] = 667;
     t['dcaron'] = 643;
     t['Umacron'] = 722;
     t['uring'] = 556;
     t['threesuperior'] = 333;
     t['Ograve'] = 778;
     t['Agrave'] = 667;
     t['Abreve'] = 667;
     t['multiply'] = 584;
     t['uacute'] = 556;
     t['Tcaron'] = 611;
     t['partialdiff'] = 476;
     t['ydieresis'] = 500;
     t['Nacute'] = 722;
     t['icircumflex'] = 278;
     t['Ecircumflex'] = 667;
     t['adieresis'] = 556;
     t['edieresis'] = 556;
     t['cacute'] = 500;
     t['nacute'] = 556;
     t['umacron'] = 556;
     t['Ncaron'] = 722;
     t['Iacute'] = 278;
     t['plusminus'] = 584;
     t['brokenbar'] = 260;
     t['registered'] = 737;
     t['Gbreve'] = 778;
     t['Idotaccent'] = 278;
     t['summation'] = 600;
     t['Egrave'] = 667;
     t['racute'] = 333;
     t['omacron'] = 556;
     t['Zacute'] = 611;
     t['Zcaron'] = 611;
     t['greaterequal'] = 549;
     t['Eth'] = 722;
     t['Ccedilla'] = 722;
     t['lcommaaccent'] = 222;
     t['tcaron'] = 317;
     t['eogonek'] = 556;
     t['Uogonek'] = 722;
     t['Aacute'] = 667;
     t['Adieresis'] = 667;
     t['egrave'] = 556;
     t['zacute'] = 500;
     t['iogonek'] = 222;
     t['Oacute'] = 778;
     t['oacute'] = 556;
     t['amacron'] = 556;
     t['sacute'] = 500;
     t['idieresis'] = 278;
     t['Ocircumflex'] = 778;
     t['Ugrave'] = 722;
     t['Delta'] = 612;
     t['thorn'] = 556;
     t['twosuperior'] = 333;
     t['Odieresis'] = 778;
     t['mu'] = 556;
     t['igrave'] = 278;
     t['ohungarumlaut'] = 556;
     t['Eogonek'] = 667;
     t['dcroat'] = 556;
     t['threequarters'] = 834;
     t['Scedilla'] = 667;
     t['lcaron'] = 299;
     t['Kcommaaccent'] = 667;
     t['Lacute'] = 556;
     t['trademark'] = 1000;
     t['edotaccent'] = 556;
     t['Igrave'] = 278;
     t['Imacron'] = 278;
     t['Lcaron'] = 556;
     t['onehalf'] = 834;
     t['lessequal'] = 549;
     t['ocircumflex'] = 556;
     t['ntilde'] = 556;
     t['Uhungarumlaut'] = 722;
     t['Eacute'] = 667;
     t['emacron'] = 556;
     t['gbreve'] = 556;
     t['onequarter'] = 834;
     t['Scaron'] = 667;
     t['Scommaaccent'] = 667;
     t['Ohungarumlaut'] = 778;
     t['degree'] = 400;
     t['ograve'] = 556;
     t['Ccaron'] = 722;
     t['ugrave'] = 556;
     t['radical'] = 453;
     t['Dcaron'] = 722;
     t['rcommaaccent'] = 333;
     t['Ntilde'] = 722;
     t['otilde'] = 556;
     t['Rcommaaccent'] = 722;
     t['Lcommaaccent'] = 556;
     t['Atilde'] = 667;
     t['Aogonek'] = 667;
     t['Aring'] = 667;
     t['Otilde'] = 778;
     t['zdotaccent'] = 500;
     t['Ecaron'] = 667;
     t['Iogonek'] = 278;
     t['kcommaaccent'] = 500;
     t['minus'] = 584;
     t['Icircumflex'] = 278;
     t['ncaron'] = 556;
     t['tcommaaccent'] = 278;
     t['logicalnot'] = 584;
     t['odieresis'] = 556;
     t['udieresis'] = 556;
     t['notequal'] = 549;
     t['gcommaaccent'] = 556;
     t['eth'] = 556;
     t['zcaron'] = 500;
     t['ncommaaccent'] = 556;
     t['onesuperior'] = 333;
     t['imacron'] = 278;
     t['Euro'] = 556;
    });
    t['Helvetica-Bold'] = getLookupTableFactory(function (t) {
     t['space'] = 278;
     t['exclam'] = 333;
     t['quotedbl'] = 474;
     t['numbersign'] = 556;
     t['dollar'] = 556;
     t['percent'] = 889;
     t['ampersand'] = 722;
     t['quoteright'] = 278;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asterisk'] = 389;
     t['plus'] = 584;
     t['comma'] = 278;
     t['hyphen'] = 333;
     t['period'] = 278;
     t['slash'] = 278;
     t['zero'] = 556;
     t['one'] = 556;
     t['two'] = 556;
     t['three'] = 556;
     t['four'] = 556;
     t['five'] = 556;
     t['six'] = 556;
     t['seven'] = 556;
     t['eight'] = 556;
     t['nine'] = 556;
     t['colon'] = 333;
     t['semicolon'] = 333;
     t['less'] = 584;
     t['equal'] = 584;
     t['greater'] = 584;
     t['question'] = 611;
     t['at'] = 975;
     t['A'] = 722;
     t['B'] = 722;
     t['C'] = 722;
     t['D'] = 722;
     t['E'] = 667;
     t['F'] = 611;
     t['G'] = 778;
     t['H'] = 722;
     t['I'] = 278;
     t['J'] = 556;
     t['K'] = 722;
     t['L'] = 611;
     t['M'] = 833;
     t['N'] = 722;
     t['O'] = 778;
     t['P'] = 667;
     t['Q'] = 778;
     t['R'] = 722;
     t['S'] = 667;
     t['T'] = 611;
     t['U'] = 722;
     t['V'] = 667;
     t['W'] = 944;
     t['X'] = 667;
     t['Y'] = 667;
     t['Z'] = 611;
     t['bracketleft'] = 333;
     t['backslash'] = 278;
     t['bracketright'] = 333;
     t['asciicircum'] = 584;
     t['underscore'] = 556;
     t['quoteleft'] = 278;
     t['a'] = 556;
     t['b'] = 611;
     t['c'] = 556;
     t['d'] = 611;
     t['e'] = 556;
     t['f'] = 333;
     t['g'] = 611;
     t['h'] = 611;
     t['i'] = 278;
     t['j'] = 278;
     t['k'] = 556;
     t['l'] = 278;
     t['m'] = 889;
     t['n'] = 611;
     t['o'] = 611;
     t['p'] = 611;
     t['q'] = 611;
     t['r'] = 389;
     t['s'] = 556;
     t['t'] = 333;
     t['u'] = 611;
     t['v'] = 556;
     t['w'] = 778;
     t['x'] = 556;
     t['y'] = 556;
     t['z'] = 500;
     t['braceleft'] = 389;
     t['bar'] = 280;
     t['braceright'] = 389;
     t['asciitilde'] = 584;
     t['exclamdown'] = 333;
     t['cent'] = 556;
     t['sterling'] = 556;
     t['fraction'] = 167;
     t['yen'] = 556;
     t['florin'] = 556;
     t['section'] = 556;
     t['currency'] = 556;
     t['quotesingle'] = 238;
     t['quotedblleft'] = 500;
     t['guillemotleft'] = 556;
     t['guilsinglleft'] = 333;
     t['guilsinglright'] = 333;
     t['fi'] = 611;
     t['fl'] = 611;
     t['endash'] = 556;
     t['dagger'] = 556;
     t['daggerdbl'] = 556;
     t['periodcentered'] = 278;
     t['paragraph'] = 556;
     t['bullet'] = 350;
     t['quotesinglbase'] = 278;
     t['quotedblbase'] = 500;
     t['quotedblright'] = 500;
     t['guillemotright'] = 556;
     t['ellipsis'] = 1000;
     t['perthousand'] = 1000;
     t['questiondown'] = 611;
     t['grave'] = 333;
     t['acute'] = 333;
     t['circumflex'] = 333;
     t['tilde'] = 333;
     t['macron'] = 333;
     t['breve'] = 333;
     t['dotaccent'] = 333;
     t['dieresis'] = 333;
     t['ring'] = 333;
     t['cedilla'] = 333;
     t['hungarumlaut'] = 333;
     t['ogonek'] = 333;
     t['caron'] = 333;
     t['emdash'] = 1000;
     t['AE'] = 1000;
     t['ordfeminine'] = 370;
     t['Lslash'] = 611;
     t['Oslash'] = 778;
     t['OE'] = 1000;
     t['ordmasculine'] = 365;
     t['ae'] = 889;
     t['dotlessi'] = 278;
     t['lslash'] = 278;
     t['oslash'] = 611;
     t['oe'] = 944;
     t['germandbls'] = 611;
     t['Idieresis'] = 278;
     t['eacute'] = 556;
     t['abreve'] = 556;
     t['uhungarumlaut'] = 611;
     t['ecaron'] = 556;
     t['Ydieresis'] = 667;
     t['divide'] = 584;
     t['Yacute'] = 667;
     t['Acircumflex'] = 722;
     t['aacute'] = 556;
     t['Ucircumflex'] = 722;
     t['yacute'] = 556;
     t['scommaaccent'] = 556;
     t['ecircumflex'] = 556;
     t['Uring'] = 722;
     t['Udieresis'] = 722;
     t['aogonek'] = 556;
     t['Uacute'] = 722;
     t['uogonek'] = 611;
     t['Edieresis'] = 667;
     t['Dcroat'] = 722;
     t['commaaccent'] = 250;
     t['copyright'] = 737;
     t['Emacron'] = 667;
     t['ccaron'] = 556;
     t['aring'] = 556;
     t['Ncommaaccent'] = 722;
     t['lacute'] = 278;
     t['agrave'] = 556;
     t['Tcommaaccent'] = 611;
     t['Cacute'] = 722;
     t['atilde'] = 556;
     t['Edotaccent'] = 667;
     t['scaron'] = 556;
     t['scedilla'] = 556;
     t['iacute'] = 278;
     t['lozenge'] = 494;
     t['Rcaron'] = 722;
     t['Gcommaaccent'] = 778;
     t['ucircumflex'] = 611;
     t['acircumflex'] = 556;
     t['Amacron'] = 722;
     t['rcaron'] = 389;
     t['ccedilla'] = 556;
     t['Zdotaccent'] = 611;
     t['Thorn'] = 667;
     t['Omacron'] = 778;
     t['Racute'] = 722;
     t['Sacute'] = 667;
     t['dcaron'] = 743;
     t['Umacron'] = 722;
     t['uring'] = 611;
     t['threesuperior'] = 333;
     t['Ograve'] = 778;
     t['Agrave'] = 722;
     t['Abreve'] = 722;
     t['multiply'] = 584;
     t['uacute'] = 611;
     t['Tcaron'] = 611;
     t['partialdiff'] = 494;
     t['ydieresis'] = 556;
     t['Nacute'] = 722;
     t['icircumflex'] = 278;
     t['Ecircumflex'] = 667;
     t['adieresis'] = 556;
     t['edieresis'] = 556;
     t['cacute'] = 556;
     t['nacute'] = 611;
     t['umacron'] = 611;
     t['Ncaron'] = 722;
     t['Iacute'] = 278;
     t['plusminus'] = 584;
     t['brokenbar'] = 280;
     t['registered'] = 737;
     t['Gbreve'] = 778;
     t['Idotaccent'] = 278;
     t['summation'] = 600;
     t['Egrave'] = 667;
     t['racute'] = 389;
     t['omacron'] = 611;
     t['Zacute'] = 611;
     t['Zcaron'] = 611;
     t['greaterequal'] = 549;
     t['Eth'] = 722;
     t['Ccedilla'] = 722;
     t['lcommaaccent'] = 278;
     t['tcaron'] = 389;
     t['eogonek'] = 556;
     t['Uogonek'] = 722;
     t['Aacute'] = 722;
     t['Adieresis'] = 722;
     t['egrave'] = 556;
     t['zacute'] = 500;
     t['iogonek'] = 278;
     t['Oacute'] = 778;
     t['oacute'] = 611;
     t['amacron'] = 556;
     t['sacute'] = 556;
     t['idieresis'] = 278;
     t['Ocircumflex'] = 778;
     t['Ugrave'] = 722;
     t['Delta'] = 612;
     t['thorn'] = 611;
     t['twosuperior'] = 333;
     t['Odieresis'] = 778;
     t['mu'] = 611;
     t['igrave'] = 278;
     t['ohungarumlaut'] = 611;
     t['Eogonek'] = 667;
     t['dcroat'] = 611;
     t['threequarters'] = 834;
     t['Scedilla'] = 667;
     t['lcaron'] = 400;
     t['Kcommaaccent'] = 722;
     t['Lacute'] = 611;
     t['trademark'] = 1000;
     t['edotaccent'] = 556;
     t['Igrave'] = 278;
     t['Imacron'] = 278;
     t['Lcaron'] = 611;
     t['onehalf'] = 834;
     t['lessequal'] = 549;
     t['ocircumflex'] = 611;
     t['ntilde'] = 611;
     t['Uhungarumlaut'] = 722;
     t['Eacute'] = 667;
     t['emacron'] = 556;
     t['gbreve'] = 611;
     t['onequarter'] = 834;
     t['Scaron'] = 667;
     t['Scommaaccent'] = 667;
     t['Ohungarumlaut'] = 778;
     t['degree'] = 400;
     t['ograve'] = 611;
     t['Ccaron'] = 722;
     t['ugrave'] = 611;
     t['radical'] = 549;
     t['Dcaron'] = 722;
     t['rcommaaccent'] = 389;
     t['Ntilde'] = 722;
     t['otilde'] = 611;
     t['Rcommaaccent'] = 722;
     t['Lcommaaccent'] = 611;
     t['Atilde'] = 722;
     t['Aogonek'] = 722;
     t['Aring'] = 722;
     t['Otilde'] = 778;
     t['zdotaccent'] = 500;
     t['Ecaron'] = 667;
     t['Iogonek'] = 278;
     t['kcommaaccent'] = 556;
     t['minus'] = 584;
     t['Icircumflex'] = 278;
     t['ncaron'] = 611;
     t['tcommaaccent'] = 333;
     t['logicalnot'] = 584;
     t['odieresis'] = 611;
     t['udieresis'] = 611;
     t['notequal'] = 549;
     t['gcommaaccent'] = 611;
     t['eth'] = 611;
     t['zcaron'] = 500;
     t['ncommaaccent'] = 611;
     t['onesuperior'] = 333;
     t['imacron'] = 278;
     t['Euro'] = 556;
    });
    t['Helvetica-BoldOblique'] = getLookupTableFactory(function (t) {
     t['space'] = 278;
     t['exclam'] = 333;
     t['quotedbl'] = 474;
     t['numbersign'] = 556;
     t['dollar'] = 556;
     t['percent'] = 889;
     t['ampersand'] = 722;
     t['quoteright'] = 278;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asterisk'] = 389;
     t['plus'] = 584;
     t['comma'] = 278;
     t['hyphen'] = 333;
     t['period'] = 278;
     t['slash'] = 278;
     t['zero'] = 556;
     t['one'] = 556;
     t['two'] = 556;
     t['three'] = 556;
     t['four'] = 556;
     t['five'] = 556;
     t['six'] = 556;
     t['seven'] = 556;
     t['eight'] = 556;
     t['nine'] = 556;
     t['colon'] = 333;
     t['semicolon'] = 333;
     t['less'] = 584;
     t['equal'] = 584;
     t['greater'] = 584;
     t['question'] = 611;
     t['at'] = 975;
     t['A'] = 722;
     t['B'] = 722;
     t['C'] = 722;
     t['D'] = 722;
     t['E'] = 667;
     t['F'] = 611;
     t['G'] = 778;
     t['H'] = 722;
     t['I'] = 278;
     t['J'] = 556;
     t['K'] = 722;
     t['L'] = 611;
     t['M'] = 833;
     t['N'] = 722;
     t['O'] = 778;
     t['P'] = 667;
     t['Q'] = 778;
     t['R'] = 722;
     t['S'] = 667;
     t['T'] = 611;
     t['U'] = 722;
     t['V'] = 667;
     t['W'] = 944;
     t['X'] = 667;
     t['Y'] = 667;
     t['Z'] = 611;
     t['bracketleft'] = 333;
     t['backslash'] = 278;
     t['bracketright'] = 333;
     t['asciicircum'] = 584;
     t['underscore'] = 556;
     t['quoteleft'] = 278;
     t['a'] = 556;
     t['b'] = 611;
     t['c'] = 556;
     t['d'] = 611;
     t['e'] = 556;
     t['f'] = 333;
     t['g'] = 611;
     t['h'] = 611;
     t['i'] = 278;
     t['j'] = 278;
     t['k'] = 556;
     t['l'] = 278;
     t['m'] = 889;
     t['n'] = 611;
     t['o'] = 611;
     t['p'] = 611;
     t['q'] = 611;
     t['r'] = 389;
     t['s'] = 556;
     t['t'] = 333;
     t['u'] = 611;
     t['v'] = 556;
     t['w'] = 778;
     t['x'] = 556;
     t['y'] = 556;
     t['z'] = 500;
     t['braceleft'] = 389;
     t['bar'] = 280;
     t['braceright'] = 389;
     t['asciitilde'] = 584;
     t['exclamdown'] = 333;
     t['cent'] = 556;
     t['sterling'] = 556;
     t['fraction'] = 167;
     t['yen'] = 556;
     t['florin'] = 556;
     t['section'] = 556;
     t['currency'] = 556;
     t['quotesingle'] = 238;
     t['quotedblleft'] = 500;
     t['guillemotleft'] = 556;
     t['guilsinglleft'] = 333;
     t['guilsinglright'] = 333;
     t['fi'] = 611;
     t['fl'] = 611;
     t['endash'] = 556;
     t['dagger'] = 556;
     t['daggerdbl'] = 556;
     t['periodcentered'] = 278;
     t['paragraph'] = 556;
     t['bullet'] = 350;
     t['quotesinglbase'] = 278;
     t['quotedblbase'] = 500;
     t['quotedblright'] = 500;
     t['guillemotright'] = 556;
     t['ellipsis'] = 1000;
     t['perthousand'] = 1000;
     t['questiondown'] = 611;
     t['grave'] = 333;
     t['acute'] = 333;
     t['circumflex'] = 333;
     t['tilde'] = 333;
     t['macron'] = 333;
     t['breve'] = 333;
     t['dotaccent'] = 333;
     t['dieresis'] = 333;
     t['ring'] = 333;
     t['cedilla'] = 333;
     t['hungarumlaut'] = 333;
     t['ogonek'] = 333;
     t['caron'] = 333;
     t['emdash'] = 1000;
     t['AE'] = 1000;
     t['ordfeminine'] = 370;
     t['Lslash'] = 611;
     t['Oslash'] = 778;
     t['OE'] = 1000;
     t['ordmasculine'] = 365;
     t['ae'] = 889;
     t['dotlessi'] = 278;
     t['lslash'] = 278;
     t['oslash'] = 611;
     t['oe'] = 944;
     t['germandbls'] = 611;
     t['Idieresis'] = 278;
     t['eacute'] = 556;
     t['abreve'] = 556;
     t['uhungarumlaut'] = 611;
     t['ecaron'] = 556;
     t['Ydieresis'] = 667;
     t['divide'] = 584;
     t['Yacute'] = 667;
     t['Acircumflex'] = 722;
     t['aacute'] = 556;
     t['Ucircumflex'] = 722;
     t['yacute'] = 556;
     t['scommaaccent'] = 556;
     t['ecircumflex'] = 556;
     t['Uring'] = 722;
     t['Udieresis'] = 722;
     t['aogonek'] = 556;
     t['Uacute'] = 722;
     t['uogonek'] = 611;
     t['Edieresis'] = 667;
     t['Dcroat'] = 722;
     t['commaaccent'] = 250;
     t['copyright'] = 737;
     t['Emacron'] = 667;
     t['ccaron'] = 556;
     t['aring'] = 556;
     t['Ncommaaccent'] = 722;
     t['lacute'] = 278;
     t['agrave'] = 556;
     t['Tcommaaccent'] = 611;
     t['Cacute'] = 722;
     t['atilde'] = 556;
     t['Edotaccent'] = 667;
     t['scaron'] = 556;
     t['scedilla'] = 556;
     t['iacute'] = 278;
     t['lozenge'] = 494;
     t['Rcaron'] = 722;
     t['Gcommaaccent'] = 778;
     t['ucircumflex'] = 611;
     t['acircumflex'] = 556;
     t['Amacron'] = 722;
     t['rcaron'] = 389;
     t['ccedilla'] = 556;
     t['Zdotaccent'] = 611;
     t['Thorn'] = 667;
     t['Omacron'] = 778;
     t['Racute'] = 722;
     t['Sacute'] = 667;
     t['dcaron'] = 743;
     t['Umacron'] = 722;
     t['uring'] = 611;
     t['threesuperior'] = 333;
     t['Ograve'] = 778;
     t['Agrave'] = 722;
     t['Abreve'] = 722;
     t['multiply'] = 584;
     t['uacute'] = 611;
     t['Tcaron'] = 611;
     t['partialdiff'] = 494;
     t['ydieresis'] = 556;
     t['Nacute'] = 722;
     t['icircumflex'] = 278;
     t['Ecircumflex'] = 667;
     t['adieresis'] = 556;
     t['edieresis'] = 556;
     t['cacute'] = 556;
     t['nacute'] = 611;
     t['umacron'] = 611;
     t['Ncaron'] = 722;
     t['Iacute'] = 278;
     t['plusminus'] = 584;
     t['brokenbar'] = 280;
     t['registered'] = 737;
     t['Gbreve'] = 778;
     t['Idotaccent'] = 278;
     t['summation'] = 600;
     t['Egrave'] = 667;
     t['racute'] = 389;
     t['omacron'] = 611;
     t['Zacute'] = 611;
     t['Zcaron'] = 611;
     t['greaterequal'] = 549;
     t['Eth'] = 722;
     t['Ccedilla'] = 722;
     t['lcommaaccent'] = 278;
     t['tcaron'] = 389;
     t['eogonek'] = 556;
     t['Uogonek'] = 722;
     t['Aacute'] = 722;
     t['Adieresis'] = 722;
     t['egrave'] = 556;
     t['zacute'] = 500;
     t['iogonek'] = 278;
     t['Oacute'] = 778;
     t['oacute'] = 611;
     t['amacron'] = 556;
     t['sacute'] = 556;
     t['idieresis'] = 278;
     t['Ocircumflex'] = 778;
     t['Ugrave'] = 722;
     t['Delta'] = 612;
     t['thorn'] = 611;
     t['twosuperior'] = 333;
     t['Odieresis'] = 778;
     t['mu'] = 611;
     t['igrave'] = 278;
     t['ohungarumlaut'] = 611;
     t['Eogonek'] = 667;
     t['dcroat'] = 611;
     t['threequarters'] = 834;
     t['Scedilla'] = 667;
     t['lcaron'] = 400;
     t['Kcommaaccent'] = 722;
     t['Lacute'] = 611;
     t['trademark'] = 1000;
     t['edotaccent'] = 556;
     t['Igrave'] = 278;
     t['Imacron'] = 278;
     t['Lcaron'] = 611;
     t['onehalf'] = 834;
     t['lessequal'] = 549;
     t['ocircumflex'] = 611;
     t['ntilde'] = 611;
     t['Uhungarumlaut'] = 722;
     t['Eacute'] = 667;
     t['emacron'] = 556;
     t['gbreve'] = 611;
     t['onequarter'] = 834;
     t['Scaron'] = 667;
     t['Scommaaccent'] = 667;
     t['Ohungarumlaut'] = 778;
     t['degree'] = 400;
     t['ograve'] = 611;
     t['Ccaron'] = 722;
     t['ugrave'] = 611;
     t['radical'] = 549;
     t['Dcaron'] = 722;
     t['rcommaaccent'] = 389;
     t['Ntilde'] = 722;
     t['otilde'] = 611;
     t['Rcommaaccent'] = 722;
     t['Lcommaaccent'] = 611;
     t['Atilde'] = 722;
     t['Aogonek'] = 722;
     t['Aring'] = 722;
     t['Otilde'] = 778;
     t['zdotaccent'] = 500;
     t['Ecaron'] = 667;
     t['Iogonek'] = 278;
     t['kcommaaccent'] = 556;
     t['minus'] = 584;
     t['Icircumflex'] = 278;
     t['ncaron'] = 611;
     t['tcommaaccent'] = 333;
     t['logicalnot'] = 584;
     t['odieresis'] = 611;
     t['udieresis'] = 611;
     t['notequal'] = 549;
     t['gcommaaccent'] = 611;
     t['eth'] = 611;
     t['zcaron'] = 500;
     t['ncommaaccent'] = 611;
     t['onesuperior'] = 333;
     t['imacron'] = 278;
     t['Euro'] = 556;
    });
    t['Helvetica-Oblique'] = getLookupTableFactory(function (t) {
     t['space'] = 278;
     t['exclam'] = 278;
     t['quotedbl'] = 355;
     t['numbersign'] = 556;
     t['dollar'] = 556;
     t['percent'] = 889;
     t['ampersand'] = 667;
     t['quoteright'] = 222;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asterisk'] = 389;
     t['plus'] = 584;
     t['comma'] = 278;
     t['hyphen'] = 333;
     t['period'] = 278;
     t['slash'] = 278;
     t['zero'] = 556;
     t['one'] = 556;
     t['two'] = 556;
     t['three'] = 556;
     t['four'] = 556;
     t['five'] = 556;
     t['six'] = 556;
     t['seven'] = 556;
     t['eight'] = 556;
     t['nine'] = 556;
     t['colon'] = 278;
     t['semicolon'] = 278;
     t['less'] = 584;
     t['equal'] = 584;
     t['greater'] = 584;
     t['question'] = 556;
     t['at'] = 1015;
     t['A'] = 667;
     t['B'] = 667;
     t['C'] = 722;
     t['D'] = 722;
     t['E'] = 667;
     t['F'] = 611;
     t['G'] = 778;
     t['H'] = 722;
     t['I'] = 278;
     t['J'] = 500;
     t['K'] = 667;
     t['L'] = 556;
     t['M'] = 833;
     t['N'] = 722;
     t['O'] = 778;
     t['P'] = 667;
     t['Q'] = 778;
     t['R'] = 722;
     t['S'] = 667;
     t['T'] = 611;
     t['U'] = 722;
     t['V'] = 667;
     t['W'] = 944;
     t['X'] = 667;
     t['Y'] = 667;
     t['Z'] = 611;
     t['bracketleft'] = 278;
     t['backslash'] = 278;
     t['bracketright'] = 278;
     t['asciicircum'] = 469;
     t['underscore'] = 556;
     t['quoteleft'] = 222;
     t['a'] = 556;
     t['b'] = 556;
     t['c'] = 500;
     t['d'] = 556;
     t['e'] = 556;
     t['f'] = 278;
     t['g'] = 556;
     t['h'] = 556;
     t['i'] = 222;
     t['j'] = 222;
     t['k'] = 500;
     t['l'] = 222;
     t['m'] = 833;
     t['n'] = 556;
     t['o'] = 556;
     t['p'] = 556;
     t['q'] = 556;
     t['r'] = 333;
     t['s'] = 500;
     t['t'] = 278;
     t['u'] = 556;
     t['v'] = 500;
     t['w'] = 722;
     t['x'] = 500;
     t['y'] = 500;
     t['z'] = 500;
     t['braceleft'] = 334;
     t['bar'] = 260;
     t['braceright'] = 334;
     t['asciitilde'] = 584;
     t['exclamdown'] = 333;
     t['cent'] = 556;
     t['sterling'] = 556;
     t['fraction'] = 167;
     t['yen'] = 556;
     t['florin'] = 556;
     t['section'] = 556;
     t['currency'] = 556;
     t['quotesingle'] = 191;
     t['quotedblleft'] = 333;
     t['guillemotleft'] = 556;
     t['guilsinglleft'] = 333;
     t['guilsinglright'] = 333;
     t['fi'] = 500;
     t['fl'] = 500;
     t['endash'] = 556;
     t['dagger'] = 556;
     t['daggerdbl'] = 556;
     t['periodcentered'] = 278;
     t['paragraph'] = 537;
     t['bullet'] = 350;
     t['quotesinglbase'] = 222;
     t['quotedblbase'] = 333;
     t['quotedblright'] = 333;
     t['guillemotright'] = 556;
     t['ellipsis'] = 1000;
     t['perthousand'] = 1000;
     t['questiondown'] = 611;
     t['grave'] = 333;
     t['acute'] = 333;
     t['circumflex'] = 333;
     t['tilde'] = 333;
     t['macron'] = 333;
     t['breve'] = 333;
     t['dotaccent'] = 333;
     t['dieresis'] = 333;
     t['ring'] = 333;
     t['cedilla'] = 333;
     t['hungarumlaut'] = 333;
     t['ogonek'] = 333;
     t['caron'] = 333;
     t['emdash'] = 1000;
     t['AE'] = 1000;
     t['ordfeminine'] = 370;
     t['Lslash'] = 556;
     t['Oslash'] = 778;
     t['OE'] = 1000;
     t['ordmasculine'] = 365;
     t['ae'] = 889;
     t['dotlessi'] = 278;
     t['lslash'] = 222;
     t['oslash'] = 611;
     t['oe'] = 944;
     t['germandbls'] = 611;
     t['Idieresis'] = 278;
     t['eacute'] = 556;
     t['abreve'] = 556;
     t['uhungarumlaut'] = 556;
     t['ecaron'] = 556;
     t['Ydieresis'] = 667;
     t['divide'] = 584;
     t['Yacute'] = 667;
     t['Acircumflex'] = 667;
     t['aacute'] = 556;
     t['Ucircumflex'] = 722;
     t['yacute'] = 500;
     t['scommaaccent'] = 500;
     t['ecircumflex'] = 556;
     t['Uring'] = 722;
     t['Udieresis'] = 722;
     t['aogonek'] = 556;
     t['Uacute'] = 722;
     t['uogonek'] = 556;
     t['Edieresis'] = 667;
     t['Dcroat'] = 722;
     t['commaaccent'] = 250;
     t['copyright'] = 737;
     t['Emacron'] = 667;
     t['ccaron'] = 500;
     t['aring'] = 556;
     t['Ncommaaccent'] = 722;
     t['lacute'] = 222;
     t['agrave'] = 556;
     t['Tcommaaccent'] = 611;
     t['Cacute'] = 722;
     t['atilde'] = 556;
     t['Edotaccent'] = 667;
     t['scaron'] = 500;
     t['scedilla'] = 500;
     t['iacute'] = 278;
     t['lozenge'] = 471;
     t['Rcaron'] = 722;
     t['Gcommaaccent'] = 778;
     t['ucircumflex'] = 556;
     t['acircumflex'] = 556;
     t['Amacron'] = 667;
     t['rcaron'] = 333;
     t['ccedilla'] = 500;
     t['Zdotaccent'] = 611;
     t['Thorn'] = 667;
     t['Omacron'] = 778;
     t['Racute'] = 722;
     t['Sacute'] = 667;
     t['dcaron'] = 643;
     t['Umacron'] = 722;
     t['uring'] = 556;
     t['threesuperior'] = 333;
     t['Ograve'] = 778;
     t['Agrave'] = 667;
     t['Abreve'] = 667;
     t['multiply'] = 584;
     t['uacute'] = 556;
     t['Tcaron'] = 611;
     t['partialdiff'] = 476;
     t['ydieresis'] = 500;
     t['Nacute'] = 722;
     t['icircumflex'] = 278;
     t['Ecircumflex'] = 667;
     t['adieresis'] = 556;
     t['edieresis'] = 556;
     t['cacute'] = 500;
     t['nacute'] = 556;
     t['umacron'] = 556;
     t['Ncaron'] = 722;
     t['Iacute'] = 278;
     t['plusminus'] = 584;
     t['brokenbar'] = 260;
     t['registered'] = 737;
     t['Gbreve'] = 778;
     t['Idotaccent'] = 278;
     t['summation'] = 600;
     t['Egrave'] = 667;
     t['racute'] = 333;
     t['omacron'] = 556;
     t['Zacute'] = 611;
     t['Zcaron'] = 611;
     t['greaterequal'] = 549;
     t['Eth'] = 722;
     t['Ccedilla'] = 722;
     t['lcommaaccent'] = 222;
     t['tcaron'] = 317;
     t['eogonek'] = 556;
     t['Uogonek'] = 722;
     t['Aacute'] = 667;
     t['Adieresis'] = 667;
     t['egrave'] = 556;
     t['zacute'] = 500;
     t['iogonek'] = 222;
     t['Oacute'] = 778;
     t['oacute'] = 556;
     t['amacron'] = 556;
     t['sacute'] = 500;
     t['idieresis'] = 278;
     t['Ocircumflex'] = 778;
     t['Ugrave'] = 722;
     t['Delta'] = 612;
     t['thorn'] = 556;
     t['twosuperior'] = 333;
     t['Odieresis'] = 778;
     t['mu'] = 556;
     t['igrave'] = 278;
     t['ohungarumlaut'] = 556;
     t['Eogonek'] = 667;
     t['dcroat'] = 556;
     t['threequarters'] = 834;
     t['Scedilla'] = 667;
     t['lcaron'] = 299;
     t['Kcommaaccent'] = 667;
     t['Lacute'] = 556;
     t['trademark'] = 1000;
     t['edotaccent'] = 556;
     t['Igrave'] = 278;
     t['Imacron'] = 278;
     t['Lcaron'] = 556;
     t['onehalf'] = 834;
     t['lessequal'] = 549;
     t['ocircumflex'] = 556;
     t['ntilde'] = 556;
     t['Uhungarumlaut'] = 722;
     t['Eacute'] = 667;
     t['emacron'] = 556;
     t['gbreve'] = 556;
     t['onequarter'] = 834;
     t['Scaron'] = 667;
     t['Scommaaccent'] = 667;
     t['Ohungarumlaut'] = 778;
     t['degree'] = 400;
     t['ograve'] = 556;
     t['Ccaron'] = 722;
     t['ugrave'] = 556;
     t['radical'] = 453;
     t['Dcaron'] = 722;
     t['rcommaaccent'] = 333;
     t['Ntilde'] = 722;
     t['otilde'] = 556;
     t['Rcommaaccent'] = 722;
     t['Lcommaaccent'] = 556;
     t['Atilde'] = 667;
     t['Aogonek'] = 667;
     t['Aring'] = 667;
     t['Otilde'] = 778;
     t['zdotaccent'] = 500;
     t['Ecaron'] = 667;
     t['Iogonek'] = 278;
     t['kcommaaccent'] = 500;
     t['minus'] = 584;
     t['Icircumflex'] = 278;
     t['ncaron'] = 556;
     t['tcommaaccent'] = 278;
     t['logicalnot'] = 584;
     t['odieresis'] = 556;
     t['udieresis'] = 556;
     t['notequal'] = 549;
     t['gcommaaccent'] = 556;
     t['eth'] = 556;
     t['zcaron'] = 500;
     t['ncommaaccent'] = 556;
     t['onesuperior'] = 333;
     t['imacron'] = 278;
     t['Euro'] = 556;
    });
    t['Symbol'] = getLookupTableFactory(function (t) {
     t['space'] = 250;
     t['exclam'] = 333;
     t['universal'] = 713;
     t['numbersign'] = 500;
     t['existential'] = 549;
     t['percent'] = 833;
     t['ampersand'] = 778;
     t['suchthat'] = 439;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asteriskmath'] = 500;
     t['plus'] = 549;
     t['comma'] = 250;
     t['minus'] = 549;
     t['period'] = 250;
     t['slash'] = 278;
     t['zero'] = 500;
     t['one'] = 500;
     t['two'] = 500;
     t['three'] = 500;
     t['four'] = 500;
     t['five'] = 500;
     t['six'] = 500;
     t['seven'] = 500;
     t['eight'] = 500;
     t['nine'] = 500;
     t['colon'] = 278;
     t['semicolon'] = 278;
     t['less'] = 549;
     t['equal'] = 549;
     t['greater'] = 549;
     t['question'] = 444;
     t['congruent'] = 549;
     t['Alpha'] = 722;
     t['Beta'] = 667;
     t['Chi'] = 722;
     t['Delta'] = 612;
     t['Epsilon'] = 611;
     t['Phi'] = 763;
     t['Gamma'] = 603;
     t['Eta'] = 722;
     t['Iota'] = 333;
     t['theta1'] = 631;
     t['Kappa'] = 722;
     t['Lambda'] = 686;
     t['Mu'] = 889;
     t['Nu'] = 722;
     t['Omicron'] = 722;
     t['Pi'] = 768;
     t['Theta'] = 741;
     t['Rho'] = 556;
     t['Sigma'] = 592;
     t['Tau'] = 611;
     t['Upsilon'] = 690;
     t['sigma1'] = 439;
     t['Omega'] = 768;
     t['Xi'] = 645;
     t['Psi'] = 795;
     t['Zeta'] = 611;
     t['bracketleft'] = 333;
     t['therefore'] = 863;
     t['bracketright'] = 333;
     t['perpendicular'] = 658;
     t['underscore'] = 500;
     t['radicalex'] = 500;
     t['alpha'] = 631;
     t['beta'] = 549;
     t['chi'] = 549;
     t['delta'] = 494;
     t['epsilon'] = 439;
     t['phi'] = 521;
     t['gamma'] = 411;
     t['eta'] = 603;
     t['iota'] = 329;
     t['phi1'] = 603;
     t['kappa'] = 549;
     t['lambda'] = 549;
     t['mu'] = 576;
     t['nu'] = 521;
     t['omicron'] = 549;
     t['pi'] = 549;
     t['theta'] = 521;
     t['rho'] = 549;
     t['sigma'] = 603;
     t['tau'] = 439;
     t['upsilon'] = 576;
     t['omega1'] = 713;
     t['omega'] = 686;
     t['xi'] = 493;
     t['psi'] = 686;
     t['zeta'] = 494;
     t['braceleft'] = 480;
     t['bar'] = 200;
     t['braceright'] = 480;
     t['similar'] = 549;
     t['Euro'] = 750;
     t['Upsilon1'] = 620;
     t['minute'] = 247;
     t['lessequal'] = 549;
     t['fraction'] = 167;
     t['infinity'] = 713;
     t['florin'] = 500;
     t['club'] = 753;
     t['diamond'] = 753;
     t['heart'] = 753;
     t['spade'] = 753;
     t['arrowboth'] = 1042;
     t['arrowleft'] = 987;
     t['arrowup'] = 603;
     t['arrowright'] = 987;
     t['arrowdown'] = 603;
     t['degree'] = 400;
     t['plusminus'] = 549;
     t['second'] = 411;
     t['greaterequal'] = 549;
     t['multiply'] = 549;
     t['proportional'] = 713;
     t['partialdiff'] = 494;
     t['bullet'] = 460;
     t['divide'] = 549;
     t['notequal'] = 549;
     t['equivalence'] = 549;
     t['approxequal'] = 549;
     t['ellipsis'] = 1000;
     t['arrowvertex'] = 603;
     t['arrowhorizex'] = 1000;
     t['carriagereturn'] = 658;
     t['aleph'] = 823;
     t['Ifraktur'] = 686;
     t['Rfraktur'] = 795;
     t['weierstrass'] = 987;
     t['circlemultiply'] = 768;
     t['circleplus'] = 768;
     t['emptyset'] = 823;
     t['intersection'] = 768;
     t['union'] = 768;
     t['propersuperset'] = 713;
     t['reflexsuperset'] = 713;
     t['notsubset'] = 713;
     t['propersubset'] = 713;
     t['reflexsubset'] = 713;
     t['element'] = 713;
     t['notelement'] = 713;
     t['angle'] = 768;
     t['gradient'] = 713;
     t['registerserif'] = 790;
     t['copyrightserif'] = 790;
     t['trademarkserif'] = 890;
     t['product'] = 823;
     t['radical'] = 549;
     t['dotmath'] = 250;
     t['logicalnot'] = 713;
     t['logicaland'] = 603;
     t['logicalor'] = 603;
     t['arrowdblboth'] = 1042;
     t['arrowdblleft'] = 987;
     t['arrowdblup'] = 603;
     t['arrowdblright'] = 987;
     t['arrowdbldown'] = 603;
     t['lozenge'] = 494;
     t['angleleft'] = 329;
     t['registersans'] = 790;
     t['copyrightsans'] = 790;
     t['trademarksans'] = 786;
     t['summation'] = 713;
     t['parenlefttp'] = 384;
     t['parenleftex'] = 384;
     t['parenleftbt'] = 384;
     t['bracketlefttp'] = 384;
     t['bracketleftex'] = 384;
     t['bracketleftbt'] = 384;
     t['bracelefttp'] = 494;
     t['braceleftmid'] = 494;
     t['braceleftbt'] = 494;
     t['braceex'] = 494;
     t['angleright'] = 329;
     t['integral'] = 274;
     t['integraltp'] = 686;
     t['integralex'] = 686;
     t['integralbt'] = 686;
     t['parenrighttp'] = 384;
     t['parenrightex'] = 384;
     t['parenrightbt'] = 384;
     t['bracketrighttp'] = 384;
     t['bracketrightex'] = 384;
     t['bracketrightbt'] = 384;
     t['bracerighttp'] = 494;
     t['bracerightmid'] = 494;
     t['bracerightbt'] = 494;
     t['apple'] = 790;
    });
    t['Times-Roman'] = getLookupTableFactory(function (t) {
     t['space'] = 250;
     t['exclam'] = 333;
     t['quotedbl'] = 408;
     t['numbersign'] = 500;
     t['dollar'] = 500;
     t['percent'] = 833;
     t['ampersand'] = 778;
     t['quoteright'] = 333;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asterisk'] = 500;
     t['plus'] = 564;
     t['comma'] = 250;
     t['hyphen'] = 333;
     t['period'] = 250;
     t['slash'] = 278;
     t['zero'] = 500;
     t['one'] = 500;
     t['two'] = 500;
     t['three'] = 500;
     t['four'] = 500;
     t['five'] = 500;
     t['six'] = 500;
     t['seven'] = 500;
     t['eight'] = 500;
     t['nine'] = 500;
     t['colon'] = 278;
     t['semicolon'] = 278;
     t['less'] = 564;
     t['equal'] = 564;
     t['greater'] = 564;
     t['question'] = 444;
     t['at'] = 921;
     t['A'] = 722;
     t['B'] = 667;
     t['C'] = 667;
     t['D'] = 722;
     t['E'] = 611;
     t['F'] = 556;
     t['G'] = 722;
     t['H'] = 722;
     t['I'] = 333;
     t['J'] = 389;
     t['K'] = 722;
     t['L'] = 611;
     t['M'] = 889;
     t['N'] = 722;
     t['O'] = 722;
     t['P'] = 556;
     t['Q'] = 722;
     t['R'] = 667;
     t['S'] = 556;
     t['T'] = 611;
     t['U'] = 722;
     t['V'] = 722;
     t['W'] = 944;
     t['X'] = 722;
     t['Y'] = 722;
     t['Z'] = 611;
     t['bracketleft'] = 333;
     t['backslash'] = 278;
     t['bracketright'] = 333;
     t['asciicircum'] = 469;
     t['underscore'] = 500;
     t['quoteleft'] = 333;
     t['a'] = 444;
     t['b'] = 500;
     t['c'] = 444;
     t['d'] = 500;
     t['e'] = 444;
     t['f'] = 333;
     t['g'] = 500;
     t['h'] = 500;
     t['i'] = 278;
     t['j'] = 278;
     t['k'] = 500;
     t['l'] = 278;
     t['m'] = 778;
     t['n'] = 500;
     t['o'] = 500;
     t['p'] = 500;
     t['q'] = 500;
     t['r'] = 333;
     t['s'] = 389;
     t['t'] = 278;
     t['u'] = 500;
     t['v'] = 500;
     t['w'] = 722;
     t['x'] = 500;
     t['y'] = 500;
     t['z'] = 444;
     t['braceleft'] = 480;
     t['bar'] = 200;
     t['braceright'] = 480;
     t['asciitilde'] = 541;
     t['exclamdown'] = 333;
     t['cent'] = 500;
     t['sterling'] = 500;
     t['fraction'] = 167;
     t['yen'] = 500;
     t['florin'] = 500;
     t['section'] = 500;
     t['currency'] = 500;
     t['quotesingle'] = 180;
     t['quotedblleft'] = 444;
     t['guillemotleft'] = 500;
     t['guilsinglleft'] = 333;
     t['guilsinglright'] = 333;
     t['fi'] = 556;
     t['fl'] = 556;
     t['endash'] = 500;
     t['dagger'] = 500;
     t['daggerdbl'] = 500;
     t['periodcentered'] = 250;
     t['paragraph'] = 453;
     t['bullet'] = 350;
     t['quotesinglbase'] = 333;
     t['quotedblbase'] = 444;
     t['quotedblright'] = 444;
     t['guillemotright'] = 500;
     t['ellipsis'] = 1000;
     t['perthousand'] = 1000;
     t['questiondown'] = 444;
     t['grave'] = 333;
     t['acute'] = 333;
     t['circumflex'] = 333;
     t['tilde'] = 333;
     t['macron'] = 333;
     t['breve'] = 333;
     t['dotaccent'] = 333;
     t['dieresis'] = 333;
     t['ring'] = 333;
     t['cedilla'] = 333;
     t['hungarumlaut'] = 333;
     t['ogonek'] = 333;
     t['caron'] = 333;
     t['emdash'] = 1000;
     t['AE'] = 889;
     t['ordfeminine'] = 276;
     t['Lslash'] = 611;
     t['Oslash'] = 722;
     t['OE'] = 889;
     t['ordmasculine'] = 310;
     t['ae'] = 667;
     t['dotlessi'] = 278;
     t['lslash'] = 278;
     t['oslash'] = 500;
     t['oe'] = 722;
     t['germandbls'] = 500;
     t['Idieresis'] = 333;
     t['eacute'] = 444;
     t['abreve'] = 444;
     t['uhungarumlaut'] = 500;
     t['ecaron'] = 444;
     t['Ydieresis'] = 722;
     t['divide'] = 564;
     t['Yacute'] = 722;
     t['Acircumflex'] = 722;
     t['aacute'] = 444;
     t['Ucircumflex'] = 722;
     t['yacute'] = 500;
     t['scommaaccent'] = 389;
     t['ecircumflex'] = 444;
     t['Uring'] = 722;
     t['Udieresis'] = 722;
     t['aogonek'] = 444;
     t['Uacute'] = 722;
     t['uogonek'] = 500;
     t['Edieresis'] = 611;
     t['Dcroat'] = 722;
     t['commaaccent'] = 250;
     t['copyright'] = 760;
     t['Emacron'] = 611;
     t['ccaron'] = 444;
     t['aring'] = 444;
     t['Ncommaaccent'] = 722;
     t['lacute'] = 278;
     t['agrave'] = 444;
     t['Tcommaaccent'] = 611;
     t['Cacute'] = 667;
     t['atilde'] = 444;
     t['Edotaccent'] = 611;
     t['scaron'] = 389;
     t['scedilla'] = 389;
     t['iacute'] = 278;
     t['lozenge'] = 471;
     t['Rcaron'] = 667;
     t['Gcommaaccent'] = 722;
     t['ucircumflex'] = 500;
     t['acircumflex'] = 444;
     t['Amacron'] = 722;
     t['rcaron'] = 333;
     t['ccedilla'] = 444;
     t['Zdotaccent'] = 611;
     t['Thorn'] = 556;
     t['Omacron'] = 722;
     t['Racute'] = 667;
     t['Sacute'] = 556;
     t['dcaron'] = 588;
     t['Umacron'] = 722;
     t['uring'] = 500;
     t['threesuperior'] = 300;
     t['Ograve'] = 722;
     t['Agrave'] = 722;
     t['Abreve'] = 722;
     t['multiply'] = 564;
     t['uacute'] = 500;
     t['Tcaron'] = 611;
     t['partialdiff'] = 476;
     t['ydieresis'] = 500;
     t['Nacute'] = 722;
     t['icircumflex'] = 278;
     t['Ecircumflex'] = 611;
     t['adieresis'] = 444;
     t['edieresis'] = 444;
     t['cacute'] = 444;
     t['nacute'] = 500;
     t['umacron'] = 500;
     t['Ncaron'] = 722;
     t['Iacute'] = 333;
     t['plusminus'] = 564;
     t['brokenbar'] = 200;
     t['registered'] = 760;
     t['Gbreve'] = 722;
     t['Idotaccent'] = 333;
     t['summation'] = 600;
     t['Egrave'] = 611;
     t['racute'] = 333;
     t['omacron'] = 500;
     t['Zacute'] = 611;
     t['Zcaron'] = 611;
     t['greaterequal'] = 549;
     t['Eth'] = 722;
     t['Ccedilla'] = 667;
     t['lcommaaccent'] = 278;
     t['tcaron'] = 326;
     t['eogonek'] = 444;
     t['Uogonek'] = 722;
     t['Aacute'] = 722;
     t['Adieresis'] = 722;
     t['egrave'] = 444;
     t['zacute'] = 444;
     t['iogonek'] = 278;
     t['Oacute'] = 722;
     t['oacute'] = 500;
     t['amacron'] = 444;
     t['sacute'] = 389;
     t['idieresis'] = 278;
     t['Ocircumflex'] = 722;
     t['Ugrave'] = 722;
     t['Delta'] = 612;
     t['thorn'] = 500;
     t['twosuperior'] = 300;
     t['Odieresis'] = 722;
     t['mu'] = 500;
     t['igrave'] = 278;
     t['ohungarumlaut'] = 500;
     t['Eogonek'] = 611;
     t['dcroat'] = 500;
     t['threequarters'] = 750;
     t['Scedilla'] = 556;
     t['lcaron'] = 344;
     t['Kcommaaccent'] = 722;
     t['Lacute'] = 611;
     t['trademark'] = 980;
     t['edotaccent'] = 444;
     t['Igrave'] = 333;
     t['Imacron'] = 333;
     t['Lcaron'] = 611;
     t['onehalf'] = 750;
     t['lessequal'] = 549;
     t['ocircumflex'] = 500;
     t['ntilde'] = 500;
     t['Uhungarumlaut'] = 722;
     t['Eacute'] = 611;
     t['emacron'] = 444;
     t['gbreve'] = 500;
     t['onequarter'] = 750;
     t['Scaron'] = 556;
     t['Scommaaccent'] = 556;
     t['Ohungarumlaut'] = 722;
     t['degree'] = 400;
     t['ograve'] = 500;
     t['Ccaron'] = 667;
     t['ugrave'] = 500;
     t['radical'] = 453;
     t['Dcaron'] = 722;
     t['rcommaaccent'] = 333;
     t['Ntilde'] = 722;
     t['otilde'] = 500;
     t['Rcommaaccent'] = 667;
     t['Lcommaaccent'] = 611;
     t['Atilde'] = 722;
     t['Aogonek'] = 722;
     t['Aring'] = 722;
     t['Otilde'] = 722;
     t['zdotaccent'] = 444;
     t['Ecaron'] = 611;
     t['Iogonek'] = 333;
     t['kcommaaccent'] = 500;
     t['minus'] = 564;
     t['Icircumflex'] = 333;
     t['ncaron'] = 500;
     t['tcommaaccent'] = 278;
     t['logicalnot'] = 564;
     t['odieresis'] = 500;
     t['udieresis'] = 500;
     t['notequal'] = 549;
     t['gcommaaccent'] = 500;
     t['eth'] = 500;
     t['zcaron'] = 444;
     t['ncommaaccent'] = 500;
     t['onesuperior'] = 300;
     t['imacron'] = 278;
     t['Euro'] = 500;
    });
    t['Times-Bold'] = getLookupTableFactory(function (t) {
     t['space'] = 250;
     t['exclam'] = 333;
     t['quotedbl'] = 555;
     t['numbersign'] = 500;
     t['dollar'] = 500;
     t['percent'] = 1000;
     t['ampersand'] = 833;
     t['quoteright'] = 333;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asterisk'] = 500;
     t['plus'] = 570;
     t['comma'] = 250;
     t['hyphen'] = 333;
     t['period'] = 250;
     t['slash'] = 278;
     t['zero'] = 500;
     t['one'] = 500;
     t['two'] = 500;
     t['three'] = 500;
     t['four'] = 500;
     t['five'] = 500;
     t['six'] = 500;
     t['seven'] = 500;
     t['eight'] = 500;
     t['nine'] = 500;
     t['colon'] = 333;
     t['semicolon'] = 333;
     t['less'] = 570;
     t['equal'] = 570;
     t['greater'] = 570;
     t['question'] = 500;
     t['at'] = 930;
     t['A'] = 722;
     t['B'] = 667;
     t['C'] = 722;
     t['D'] = 722;
     t['E'] = 667;
     t['F'] = 611;
     t['G'] = 778;
     t['H'] = 778;
     t['I'] = 389;
     t['J'] = 500;
     t['K'] = 778;
     t['L'] = 667;
     t['M'] = 944;
     t['N'] = 722;
     t['O'] = 778;
     t['P'] = 611;
     t['Q'] = 778;
     t['R'] = 722;
     t['S'] = 556;
     t['T'] = 667;
     t['U'] = 722;
     t['V'] = 722;
     t['W'] = 1000;
     t['X'] = 722;
     t['Y'] = 722;
     t['Z'] = 667;
     t['bracketleft'] = 333;
     t['backslash'] = 278;
     t['bracketright'] = 333;
     t['asciicircum'] = 581;
     t['underscore'] = 500;
     t['quoteleft'] = 333;
     t['a'] = 500;
     t['b'] = 556;
     t['c'] = 444;
     t['d'] = 556;
     t['e'] = 444;
     t['f'] = 333;
     t['g'] = 500;
     t['h'] = 556;
     t['i'] = 278;
     t['j'] = 333;
     t['k'] = 556;
     t['l'] = 278;
     t['m'] = 833;
     t['n'] = 556;
     t['o'] = 500;
     t['p'] = 556;
     t['q'] = 556;
     t['r'] = 444;
     t['s'] = 389;
     t['t'] = 333;
     t['u'] = 556;
     t['v'] = 500;
     t['w'] = 722;
     t['x'] = 500;
     t['y'] = 500;
     t['z'] = 444;
     t['braceleft'] = 394;
     t['bar'] = 220;
     t['braceright'] = 394;
     t['asciitilde'] = 520;
     t['exclamdown'] = 333;
     t['cent'] = 500;
     t['sterling'] = 500;
     t['fraction'] = 167;
     t['yen'] = 500;
     t['florin'] = 500;
     t['section'] = 500;
     t['currency'] = 500;
     t['quotesingle'] = 278;
     t['quotedblleft'] = 500;
     t['guillemotleft'] = 500;
     t['guilsinglleft'] = 333;
     t['guilsinglright'] = 333;
     t['fi'] = 556;
     t['fl'] = 556;
     t['endash'] = 500;
     t['dagger'] = 500;
     t['daggerdbl'] = 500;
     t['periodcentered'] = 250;
     t['paragraph'] = 540;
     t['bullet'] = 350;
     t['quotesinglbase'] = 333;
     t['quotedblbase'] = 500;
     t['quotedblright'] = 500;
     t['guillemotright'] = 500;
     t['ellipsis'] = 1000;
     t['perthousand'] = 1000;
     t['questiondown'] = 500;
     t['grave'] = 333;
     t['acute'] = 333;
     t['circumflex'] = 333;
     t['tilde'] = 333;
     t['macron'] = 333;
     t['breve'] = 333;
     t['dotaccent'] = 333;
     t['dieresis'] = 333;
     t['ring'] = 333;
     t['cedilla'] = 333;
     t['hungarumlaut'] = 333;
     t['ogonek'] = 333;
     t['caron'] = 333;
     t['emdash'] = 1000;
     t['AE'] = 1000;
     t['ordfeminine'] = 300;
     t['Lslash'] = 667;
     t['Oslash'] = 778;
     t['OE'] = 1000;
     t['ordmasculine'] = 330;
     t['ae'] = 722;
     t['dotlessi'] = 278;
     t['lslash'] = 278;
     t['oslash'] = 500;
     t['oe'] = 722;
     t['germandbls'] = 556;
     t['Idieresis'] = 389;
     t['eacute'] = 444;
     t['abreve'] = 500;
     t['uhungarumlaut'] = 556;
     t['ecaron'] = 444;
     t['Ydieresis'] = 722;
     t['divide'] = 570;
     t['Yacute'] = 722;
     t['Acircumflex'] = 722;
     t['aacute'] = 500;
     t['Ucircumflex'] = 722;
     t['yacute'] = 500;
     t['scommaaccent'] = 389;
     t['ecircumflex'] = 444;
     t['Uring'] = 722;
     t['Udieresis'] = 722;
     t['aogonek'] = 500;
     t['Uacute'] = 722;
     t['uogonek'] = 556;
     t['Edieresis'] = 667;
     t['Dcroat'] = 722;
     t['commaaccent'] = 250;
     t['copyright'] = 747;
     t['Emacron'] = 667;
     t['ccaron'] = 444;
     t['aring'] = 500;
     t['Ncommaaccent'] = 722;
     t['lacute'] = 278;
     t['agrave'] = 500;
     t['Tcommaaccent'] = 667;
     t['Cacute'] = 722;
     t['atilde'] = 500;
     t['Edotaccent'] = 667;
     t['scaron'] = 389;
     t['scedilla'] = 389;
     t['iacute'] = 278;
     t['lozenge'] = 494;
     t['Rcaron'] = 722;
     t['Gcommaaccent'] = 778;
     t['ucircumflex'] = 556;
     t['acircumflex'] = 500;
     t['Amacron'] = 722;
     t['rcaron'] = 444;
     t['ccedilla'] = 444;
     t['Zdotaccent'] = 667;
     t['Thorn'] = 611;
     t['Omacron'] = 778;
     t['Racute'] = 722;
     t['Sacute'] = 556;
     t['dcaron'] = 672;
     t['Umacron'] = 722;
     t['uring'] = 556;
     t['threesuperior'] = 300;
     t['Ograve'] = 778;
     t['Agrave'] = 722;
     t['Abreve'] = 722;
     t['multiply'] = 570;
     t['uacute'] = 556;
     t['Tcaron'] = 667;
     t['partialdiff'] = 494;
     t['ydieresis'] = 500;
     t['Nacute'] = 722;
     t['icircumflex'] = 278;
     t['Ecircumflex'] = 667;
     t['adieresis'] = 500;
     t['edieresis'] = 444;
     t['cacute'] = 444;
     t['nacute'] = 556;
     t['umacron'] = 556;
     t['Ncaron'] = 722;
     t['Iacute'] = 389;
     t['plusminus'] = 570;
     t['brokenbar'] = 220;
     t['registered'] = 747;
     t['Gbreve'] = 778;
     t['Idotaccent'] = 389;
     t['summation'] = 600;
     t['Egrave'] = 667;
     t['racute'] = 444;
     t['omacron'] = 500;
     t['Zacute'] = 667;
     t['Zcaron'] = 667;
     t['greaterequal'] = 549;
     t['Eth'] = 722;
     t['Ccedilla'] = 722;
     t['lcommaaccent'] = 278;
     t['tcaron'] = 416;
     t['eogonek'] = 444;
     t['Uogonek'] = 722;
     t['Aacute'] = 722;
     t['Adieresis'] = 722;
     t['egrave'] = 444;
     t['zacute'] = 444;
     t['iogonek'] = 278;
     t['Oacute'] = 778;
     t['oacute'] = 500;
     t['amacron'] = 500;
     t['sacute'] = 389;
     t['idieresis'] = 278;
     t['Ocircumflex'] = 778;
     t['Ugrave'] = 722;
     t['Delta'] = 612;
     t['thorn'] = 556;
     t['twosuperior'] = 300;
     t['Odieresis'] = 778;
     t['mu'] = 556;
     t['igrave'] = 278;
     t['ohungarumlaut'] = 500;
     t['Eogonek'] = 667;
     t['dcroat'] = 556;
     t['threequarters'] = 750;
     t['Scedilla'] = 556;
     t['lcaron'] = 394;
     t['Kcommaaccent'] = 778;
     t['Lacute'] = 667;
     t['trademark'] = 1000;
     t['edotaccent'] = 444;
     t['Igrave'] = 389;
     t['Imacron'] = 389;
     t['Lcaron'] = 667;
     t['onehalf'] = 750;
     t['lessequal'] = 549;
     t['ocircumflex'] = 500;
     t['ntilde'] = 556;
     t['Uhungarumlaut'] = 722;
     t['Eacute'] = 667;
     t['emacron'] = 444;
     t['gbreve'] = 500;
     t['onequarter'] = 750;
     t['Scaron'] = 556;
     t['Scommaaccent'] = 556;
     t['Ohungarumlaut'] = 778;
     t['degree'] = 400;
     t['ograve'] = 500;
     t['Ccaron'] = 722;
     t['ugrave'] = 556;
     t['radical'] = 549;
     t['Dcaron'] = 722;
     t['rcommaaccent'] = 444;
     t['Ntilde'] = 722;
     t['otilde'] = 500;
     t['Rcommaaccent'] = 722;
     t['Lcommaaccent'] = 667;
     t['Atilde'] = 722;
     t['Aogonek'] = 722;
     t['Aring'] = 722;
     t['Otilde'] = 778;
     t['zdotaccent'] = 444;
     t['Ecaron'] = 667;
     t['Iogonek'] = 389;
     t['kcommaaccent'] = 556;
     t['minus'] = 570;
     t['Icircumflex'] = 389;
     t['ncaron'] = 556;
     t['tcommaaccent'] = 333;
     t['logicalnot'] = 570;
     t['odieresis'] = 500;
     t['udieresis'] = 556;
     t['notequal'] = 549;
     t['gcommaaccent'] = 500;
     t['eth'] = 500;
     t['zcaron'] = 444;
     t['ncommaaccent'] = 556;
     t['onesuperior'] = 300;
     t['imacron'] = 278;
     t['Euro'] = 500;
    });
    t['Times-BoldItalic'] = getLookupTableFactory(function (t) {
     t['space'] = 250;
     t['exclam'] = 389;
     t['quotedbl'] = 555;
     t['numbersign'] = 500;
     t['dollar'] = 500;
     t['percent'] = 833;
     t['ampersand'] = 778;
     t['quoteright'] = 333;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asterisk'] = 500;
     t['plus'] = 570;
     t['comma'] = 250;
     t['hyphen'] = 333;
     t['period'] = 250;
     t['slash'] = 278;
     t['zero'] = 500;
     t['one'] = 500;
     t['two'] = 500;
     t['three'] = 500;
     t['four'] = 500;
     t['five'] = 500;
     t['six'] = 500;
     t['seven'] = 500;
     t['eight'] = 500;
     t['nine'] = 500;
     t['colon'] = 333;
     t['semicolon'] = 333;
     t['less'] = 570;
     t['equal'] = 570;
     t['greater'] = 570;
     t['question'] = 500;
     t['at'] = 832;
     t['A'] = 667;
     t['B'] = 667;
     t['C'] = 667;
     t['D'] = 722;
     t['E'] = 667;
     t['F'] = 667;
     t['G'] = 722;
     t['H'] = 778;
     t['I'] = 389;
     t['J'] = 500;
     t['K'] = 667;
     t['L'] = 611;
     t['M'] = 889;
     t['N'] = 722;
     t['O'] = 722;
     t['P'] = 611;
     t['Q'] = 722;
     t['R'] = 667;
     t['S'] = 556;
     t['T'] = 611;
     t['U'] = 722;
     t['V'] = 667;
     t['W'] = 889;
     t['X'] = 667;
     t['Y'] = 611;
     t['Z'] = 611;
     t['bracketleft'] = 333;
     t['backslash'] = 278;
     t['bracketright'] = 333;
     t['asciicircum'] = 570;
     t['underscore'] = 500;
     t['quoteleft'] = 333;
     t['a'] = 500;
     t['b'] = 500;
     t['c'] = 444;
     t['d'] = 500;
     t['e'] = 444;
     t['f'] = 333;
     t['g'] = 500;
     t['h'] = 556;
     t['i'] = 278;
     t['j'] = 278;
     t['k'] = 500;
     t['l'] = 278;
     t['m'] = 778;
     t['n'] = 556;
     t['o'] = 500;
     t['p'] = 500;
     t['q'] = 500;
     t['r'] = 389;
     t['s'] = 389;
     t['t'] = 278;
     t['u'] = 556;
     t['v'] = 444;
     t['w'] = 667;
     t['x'] = 500;
     t['y'] = 444;
     t['z'] = 389;
     t['braceleft'] = 348;
     t['bar'] = 220;
     t['braceright'] = 348;
     t['asciitilde'] = 570;
     t['exclamdown'] = 389;
     t['cent'] = 500;
     t['sterling'] = 500;
     t['fraction'] = 167;
     t['yen'] = 500;
     t['florin'] = 500;
     t['section'] = 500;
     t['currency'] = 500;
     t['quotesingle'] = 278;
     t['quotedblleft'] = 500;
     t['guillemotleft'] = 500;
     t['guilsinglleft'] = 333;
     t['guilsinglright'] = 333;
     t['fi'] = 556;
     t['fl'] = 556;
     t['endash'] = 500;
     t['dagger'] = 500;
     t['daggerdbl'] = 500;
     t['periodcentered'] = 250;
     t['paragraph'] = 500;
     t['bullet'] = 350;
     t['quotesinglbase'] = 333;
     t['quotedblbase'] = 500;
     t['quotedblright'] = 500;
     t['guillemotright'] = 500;
     t['ellipsis'] = 1000;
     t['perthousand'] = 1000;
     t['questiondown'] = 500;
     t['grave'] = 333;
     t['acute'] = 333;
     t['circumflex'] = 333;
     t['tilde'] = 333;
     t['macron'] = 333;
     t['breve'] = 333;
     t['dotaccent'] = 333;
     t['dieresis'] = 333;
     t['ring'] = 333;
     t['cedilla'] = 333;
     t['hungarumlaut'] = 333;
     t['ogonek'] = 333;
     t['caron'] = 333;
     t['emdash'] = 1000;
     t['AE'] = 944;
     t['ordfeminine'] = 266;
     t['Lslash'] = 611;
     t['Oslash'] = 722;
     t['OE'] = 944;
     t['ordmasculine'] = 300;
     t['ae'] = 722;
     t['dotlessi'] = 278;
     t['lslash'] = 278;
     t['oslash'] = 500;
     t['oe'] = 722;
     t['germandbls'] = 500;
     t['Idieresis'] = 389;
     t['eacute'] = 444;
     t['abreve'] = 500;
     t['uhungarumlaut'] = 556;
     t['ecaron'] = 444;
     t['Ydieresis'] = 611;
     t['divide'] = 570;
     t['Yacute'] = 611;
     t['Acircumflex'] = 667;
     t['aacute'] = 500;
     t['Ucircumflex'] = 722;
     t['yacute'] = 444;
     t['scommaaccent'] = 389;
     t['ecircumflex'] = 444;
     t['Uring'] = 722;
     t['Udieresis'] = 722;
     t['aogonek'] = 500;
     t['Uacute'] = 722;
     t['uogonek'] = 556;
     t['Edieresis'] = 667;
     t['Dcroat'] = 722;
     t['commaaccent'] = 250;
     t['copyright'] = 747;
     t['Emacron'] = 667;
     t['ccaron'] = 444;
     t['aring'] = 500;
     t['Ncommaaccent'] = 722;
     t['lacute'] = 278;
     t['agrave'] = 500;
     t['Tcommaaccent'] = 611;
     t['Cacute'] = 667;
     t['atilde'] = 500;
     t['Edotaccent'] = 667;
     t['scaron'] = 389;
     t['scedilla'] = 389;
     t['iacute'] = 278;
     t['lozenge'] = 494;
     t['Rcaron'] = 667;
     t['Gcommaaccent'] = 722;
     t['ucircumflex'] = 556;
     t['acircumflex'] = 500;
     t['Amacron'] = 667;
     t['rcaron'] = 389;
     t['ccedilla'] = 444;
     t['Zdotaccent'] = 611;
     t['Thorn'] = 611;
     t['Omacron'] = 722;
     t['Racute'] = 667;
     t['Sacute'] = 556;
     t['dcaron'] = 608;
     t['Umacron'] = 722;
     t['uring'] = 556;
     t['threesuperior'] = 300;
     t['Ograve'] = 722;
     t['Agrave'] = 667;
     t['Abreve'] = 667;
     t['multiply'] = 570;
     t['uacute'] = 556;
     t['Tcaron'] = 611;
     t['partialdiff'] = 494;
     t['ydieresis'] = 444;
     t['Nacute'] = 722;
     t['icircumflex'] = 278;
     t['Ecircumflex'] = 667;
     t['adieresis'] = 500;
     t['edieresis'] = 444;
     t['cacute'] = 444;
     t['nacute'] = 556;
     t['umacron'] = 556;
     t['Ncaron'] = 722;
     t['Iacute'] = 389;
     t['plusminus'] = 570;
     t['brokenbar'] = 220;
     t['registered'] = 747;
     t['Gbreve'] = 722;
     t['Idotaccent'] = 389;
     t['summation'] = 600;
     t['Egrave'] = 667;
     t['racute'] = 389;
     t['omacron'] = 500;
     t['Zacute'] = 611;
     t['Zcaron'] = 611;
     t['greaterequal'] = 549;
     t['Eth'] = 722;
     t['Ccedilla'] = 667;
     t['lcommaaccent'] = 278;
     t['tcaron'] = 366;
     t['eogonek'] = 444;
     t['Uogonek'] = 722;
     t['Aacute'] = 667;
     t['Adieresis'] = 667;
     t['egrave'] = 444;
     t['zacute'] = 389;
     t['iogonek'] = 278;
     t['Oacute'] = 722;
     t['oacute'] = 500;
     t['amacron'] = 500;
     t['sacute'] = 389;
     t['idieresis'] = 278;
     t['Ocircumflex'] = 722;
     t['Ugrave'] = 722;
     t['Delta'] = 612;
     t['thorn'] = 500;
     t['twosuperior'] = 300;
     t['Odieresis'] = 722;
     t['mu'] = 576;
     t['igrave'] = 278;
     t['ohungarumlaut'] = 500;
     t['Eogonek'] = 667;
     t['dcroat'] = 500;
     t['threequarters'] = 750;
     t['Scedilla'] = 556;
     t['lcaron'] = 382;
     t['Kcommaaccent'] = 667;
     t['Lacute'] = 611;
     t['trademark'] = 1000;
     t['edotaccent'] = 444;
     t['Igrave'] = 389;
     t['Imacron'] = 389;
     t['Lcaron'] = 611;
     t['onehalf'] = 750;
     t['lessequal'] = 549;
     t['ocircumflex'] = 500;
     t['ntilde'] = 556;
     t['Uhungarumlaut'] = 722;
     t['Eacute'] = 667;
     t['emacron'] = 444;
     t['gbreve'] = 500;
     t['onequarter'] = 750;
     t['Scaron'] = 556;
     t['Scommaaccent'] = 556;
     t['Ohungarumlaut'] = 722;
     t['degree'] = 400;
     t['ograve'] = 500;
     t['Ccaron'] = 667;
     t['ugrave'] = 556;
     t['radical'] = 549;
     t['Dcaron'] = 722;
     t['rcommaaccent'] = 389;
     t['Ntilde'] = 722;
     t['otilde'] = 500;
     t['Rcommaaccent'] = 667;
     t['Lcommaaccent'] = 611;
     t['Atilde'] = 667;
     t['Aogonek'] = 667;
     t['Aring'] = 667;
     t['Otilde'] = 722;
     t['zdotaccent'] = 389;
     t['Ecaron'] = 667;
     t['Iogonek'] = 389;
     t['kcommaaccent'] = 500;
     t['minus'] = 606;
     t['Icircumflex'] = 389;
     t['ncaron'] = 556;
     t['tcommaaccent'] = 278;
     t['logicalnot'] = 606;
     t['odieresis'] = 500;
     t['udieresis'] = 556;
     t['notequal'] = 549;
     t['gcommaaccent'] = 500;
     t['eth'] = 500;
     t['zcaron'] = 389;
     t['ncommaaccent'] = 556;
     t['onesuperior'] = 300;
     t['imacron'] = 278;
     t['Euro'] = 500;
    });
    t['Times-Italic'] = getLookupTableFactory(function (t) {
     t['space'] = 250;
     t['exclam'] = 333;
     t['quotedbl'] = 420;
     t['numbersign'] = 500;
     t['dollar'] = 500;
     t['percent'] = 833;
     t['ampersand'] = 778;
     t['quoteright'] = 333;
     t['parenleft'] = 333;
     t['parenright'] = 333;
     t['asterisk'] = 500;
     t['plus'] = 675;
     t['comma'] = 250;
     t['hyphen'] = 333;
     t['period'] = 250;
     t['slash'] = 278;
     t['zero'] = 500;
     t['one'] = 500;
     t['two'] = 500;
     t['three'] = 500;
     t['four'] = 500;
     t['five'] = 500;
     t['six'] = 500;
     t['seven'] = 500;
     t['eight'] = 500;
     t['nine'] = 500;
     t['colon'] = 333;
     t['semicolon'] = 333;
     t['less'] = 675;
     t['equal'] = 675;
     t['greater'] = 675;
     t['question'] = 500;
     t['at'] = 920;
     t['A'] = 611;
     t['B'] = 611;
     t['C'] = 667;
     t['D'] = 722;
     t['E'] = 611;
     t['F'] = 611;
     t['G'] = 722;
     t['H'] = 722;
     t['I'] = 333;
     t['J'] = 444;
     t['K'] = 667;
     t['L'] = 556;
     t['M'] = 833;
     t['N'] = 667;
     t['O'] = 722;
     t['P'] = 611;
     t['Q'] = 722;
     t['R'] = 611;
     t['S'] = 500;
     t['T'] = 556;
     t['U'] = 722;
     t['V'] = 611;
     t['W'] = 833;
     t['X'] = 611;
     t['Y'] = 556;
     t['Z'] = 556;
     t['bracketleft'] = 389;
     t['backslash'] = 278;
     t['bracketright'] = 389;
     t['asciicircum'] = 422;
     t['underscore'] = 500;
     t['quoteleft'] = 333;
     t['a'] = 500;
     t['b'] = 500;
     t['c'] = 444;
     t['d'] = 500;
     t['e'] = 444;
     t['f'] = 278;
     t['g'] = 500;
     t['h'] = 500;
     t['i'] = 278;
     t['j'] = 278;
     t['k'] = 444;
     t['l'] = 278;
     t['m'] = 722;
     t['n'] = 500;
     t['o'] = 500;
     t['p'] = 500;
     t['q'] = 500;
     t['r'] = 389;
     t['s'] = 389;
     t['t'] = 278;
     t['u'] = 500;
     t['v'] = 444;
     t['w'] = 667;
     t['x'] = 444;
     t['y'] = 444;
     t['z'] = 389;
     t['braceleft'] = 400;
     t['bar'] = 275;
     t['braceright'] = 400;
     t['asciitilde'] = 541;
     t['exclamdown'] = 389;
     t['cent'] = 500;
     t['sterling'] = 500;
     t['fraction'] = 167;
     t['yen'] = 500;
     t['florin'] = 500;
     t['section'] = 500;
     t['currency'] = 500;
     t['quotesingle'] = 214;
     t['quotedblleft'] = 556;
     t['guillemotleft'] = 500;
     t['guilsinglleft'] = 333;
     t['guilsinglright'] = 333;
     t['fi'] = 500;
     t['fl'] = 500;
     t['endash'] = 500;
     t['dagger'] = 500;
     t['daggerdbl'] = 500;
     t['periodcentered'] = 250;
     t['paragraph'] = 523;
     t['bullet'] = 350;
     t['quotesinglbase'] = 333;
     t['quotedblbase'] = 556;
     t['quotedblright'] = 556;
     t['guillemotright'] = 500;
     t['ellipsis'] = 889;
     t['perthousand'] = 1000;
     t['questiondown'] = 500;
     t['grave'] = 333;
     t['acute'] = 333;
     t['circumflex'] = 333;
     t['tilde'] = 333;
     t['macron'] = 333;
     t['breve'] = 333;
     t['dotaccent'] = 333;
     t['dieresis'] = 333;
     t['ring'] = 333;
     t['cedilla'] = 333;
     t['hungarumlaut'] = 333;
     t['ogonek'] = 333;
     t['caron'] = 333;
     t['emdash'] = 889;
     t['AE'] = 889;
     t['ordfeminine'] = 276;
     t['Lslash'] = 556;
     t['Oslash'] = 722;
     t['OE'] = 944;
     t['ordmasculine'] = 310;
     t['ae'] = 667;
     t['dotlessi'] = 278;
     t['lslash'] = 278;
     t['oslash'] = 500;
     t['oe'] = 667;
     t['germandbls'] = 500;
     t['Idieresis'] = 333;
     t['eacute'] = 444;
     t['abreve'] = 500;
     t['uhungarumlaut'] = 500;
     t['ecaron'] = 444;
     t['Ydieresis'] = 556;
     t['divide'] = 675;
     t['Yacute'] = 556;
     t['Acircumflex'] = 611;
     t['aacute'] = 500;
     t['Ucircumflex'] = 722;
     t['yacute'] = 444;
     t['scommaaccent'] = 389;
     t['ecircumflex'] = 444;
     t['Uring'] = 722;
     t['Udieresis'] = 722;
     t['aogonek'] = 500;
     t['Uacute'] = 722;
     t['uogonek'] = 500;
     t['Edieresis'] = 611;
     t['Dcroat'] = 722;
     t['commaaccent'] = 250;
     t['copyright'] = 760;
     t['Emacron'] = 611;
     t['ccaron'] = 444;
     t['aring'] = 500;
     t['Ncommaaccent'] = 667;
     t['lacute'] = 278;
     t['agrave'] = 500;
     t['Tcommaaccent'] = 556;
     t['Cacute'] = 667;
     t['atilde'] = 500;
     t['Edotaccent'] = 611;
     t['scaron'] = 389;
     t['scedilla'] = 389;
     t['iacute'] = 278;
     t['lozenge'] = 471;
     t['Rcaron'] = 611;
     t['Gcommaaccent'] = 722;
     t['ucircumflex'] = 500;
     t['acircumflex'] = 500;
     t['Amacron'] = 611;
     t['rcaron'] = 389;
     t['ccedilla'] = 444;
     t['Zdotaccent'] = 556;
     t['Thorn'] = 611;
     t['Omacron'] = 722;
     t['Racute'] = 611;
     t['Sacute'] = 500;
     t['dcaron'] = 544;
     t['Umacron'] = 722;
     t['uring'] = 500;
     t['threesuperior'] = 300;
     t['Ograve'] = 722;
     t['Agrave'] = 611;
     t['Abreve'] = 611;
     t['multiply'] = 675;
     t['uacute'] = 500;
     t['Tcaron'] = 556;
     t['partialdiff'] = 476;
     t['ydieresis'] = 444;
     t['Nacute'] = 667;
     t['icircumflex'] = 278;
     t['Ecircumflex'] = 611;
     t['adieresis'] = 500;
     t['edieresis'] = 444;
     t['cacute'] = 444;
     t['nacute'] = 500;
     t['umacron'] = 500;
     t['Ncaron'] = 667;
     t['Iacute'] = 333;
     t['plusminus'] = 675;
     t['brokenbar'] = 275;
     t['registered'] = 760;
     t['Gbreve'] = 722;
     t['Idotaccent'] = 333;
     t['summation'] = 600;
     t['Egrave'] = 611;
     t['racute'] = 389;
     t['omacron'] = 500;
     t['Zacute'] = 556;
     t['Zcaron'] = 556;
     t['greaterequal'] = 549;
     t['Eth'] = 722;
     t['Ccedilla'] = 667;
     t['lcommaaccent'] = 278;
     t['tcaron'] = 300;
     t['eogonek'] = 444;
     t['Uogonek'] = 722;
     t['Aacute'] = 611;
     t['Adieresis'] = 611;
     t['egrave'] = 444;
     t['zacute'] = 389;
     t['iogonek'] = 278;
     t['Oacute'] = 722;
     t['oacute'] = 500;
     t['amacron'] = 500;
     t['sacute'] = 389;
     t['idieresis'] = 278;
     t['Ocircumflex'] = 722;
     t['Ugrave'] = 722;
     t['Delta'] = 612;
     t['thorn'] = 500;
     t['twosuperior'] = 300;
     t['Odieresis'] = 722;
     t['mu'] = 500;
     t['igrave'] = 278;
     t['ohungarumlaut'] = 500;
     t['Eogonek'] = 611;
     t['dcroat'] = 500;
     t['threequarters'] = 750;
     t['Scedilla'] = 500;
     t['lcaron'] = 300;
     t['Kcommaaccent'] = 667;
     t['Lacute'] = 556;
     t['trademark'] = 980;
     t['edotaccent'] = 444;
     t['Igrave'] = 333;
     t['Imacron'] = 333;
     t['Lcaron'] = 611;
     t['onehalf'] = 750;
     t['lessequal'] = 549;
     t['ocircumflex'] = 500;
     t['ntilde'] = 500;
     t['Uhungarumlaut'] = 722;
     t['Eacute'] = 611;
     t['emacron'] = 444;
     t['gbreve'] = 500;
     t['onequarter'] = 750;
     t['Scaron'] = 500;
     t['Scommaaccent'] = 500;
     t['Ohungarumlaut'] = 722;
     t['degree'] = 400;
     t['ograve'] = 500;
     t['Ccaron'] = 667;
     t['ugrave'] = 500;
     t['radical'] = 453;
     t['Dcaron'] = 722;
     t['rcommaaccent'] = 389;
     t['Ntilde'] = 667;
     t['otilde'] = 500;
     t['Rcommaaccent'] = 611;
     t['Lcommaaccent'] = 556;
     t['Atilde'] = 611;
     t['Aogonek'] = 611;
     t['Aring'] = 611;
     t['Otilde'] = 722;
     t['zdotaccent'] = 389;
     t['Ecaron'] = 611;
     t['Iogonek'] = 333;
     t['kcommaaccent'] = 444;
     t['minus'] = 675;
     t['Icircumflex'] = 333;
     t['ncaron'] = 500;
     t['tcommaaccent'] = 278;
     t['logicalnot'] = 675;
     t['odieresis'] = 500;
     t['udieresis'] = 500;
     t['notequal'] = 549;
     t['gcommaaccent'] = 500;
     t['eth'] = 500;
     t['zcaron'] = 389;
     t['ncommaaccent'] = 500;
     t['onesuperior'] = 300;
     t['imacron'] = 278;
     t['Euro'] = 500;
    });
    t['ZapfDingbats'] = getLookupTableFactory(function (t) {
     t['space'] = 278;
     t['a1'] = 974;
     t['a2'] = 961;
     t['a202'] = 974;
     t['a3'] = 980;
     t['a4'] = 719;
     t['a5'] = 789;
     t['a119'] = 790;
     t['a118'] = 791;
     t['a117'] = 690;
     t['a11'] = 960;
     t['a12'] = 939;
     t['a13'] = 549;
     t['a14'] = 855;
     t['a15'] = 911;
     t['a16'] = 933;
     t['a105'] = 911;
     t['a17'] = 945;
     t['a18'] = 974;
     t['a19'] = 755;
     t['a20'] = 846;
     t['a21'] = 762;
     t['a22'] = 761;
     t['a23'] = 571;
     t['a24'] = 677;
     t['a25'] = 763;
     t['a26'] = 760;
     t['a27'] = 759;
     t['a28'] = 754;
     t['a6'] = 494;
     t['a7'] = 552;
     t['a8'] = 537;
     t['a9'] = 577;
     t['a10'] = 692;
     t['a29'] = 786;
     t['a30'] = 788;
     t['a31'] = 788;
     t['a32'] = 790;
     t['a33'] = 793;
     t['a34'] = 794;
     t['a35'] = 816;
     t['a36'] = 823;
     t['a37'] = 789;
     t['a38'] = 841;
     t['a39'] = 823;
     t['a40'] = 833;
     t['a41'] = 816;
     t['a42'] = 831;
     t['a43'] = 923;
     t['a44'] = 744;
     t['a45'] = 723;
     t['a46'] = 749;
     t['a47'] = 790;
     t['a48'] = 792;
     t['a49'] = 695;
     t['a50'] = 776;
     t['a51'] = 768;
     t['a52'] = 792;
     t['a53'] = 759;
     t['a54'] = 707;
     t['a55'] = 708;
     t['a56'] = 682;
     t['a57'] = 701;
     t['a58'] = 826;
     t['a59'] = 815;
     t['a60'] = 789;
     t['a61'] = 789;
     t['a62'] = 707;
     t['a63'] = 687;
     t['a64'] = 696;
     t['a65'] = 689;
     t['a66'] = 786;
     t['a67'] = 787;
     t['a68'] = 713;
     t['a69'] = 791;
     t['a70'] = 785;
     t['a71'] = 791;
     t['a72'] = 873;
     t['a73'] = 761;
     t['a74'] = 762;
     t['a203'] = 762;
     t['a75'] = 759;
     t['a204'] = 759;
     t['a76'] = 892;
     t['a77'] = 892;
     t['a78'] = 788;
     t['a79'] = 784;
     t['a81'] = 438;
     t['a82'] = 138;
     t['a83'] = 277;
     t['a84'] = 415;
     t['a97'] = 392;
     t['a98'] = 392;
     t['a99'] = 668;
     t['a100'] = 668;
     t['a89'] = 390;
     t['a90'] = 390;
     t['a93'] = 317;
     t['a94'] = 317;
     t['a91'] = 276;
     t['a92'] = 276;
     t['a205'] = 509;
     t['a85'] = 509;
     t['a206'] = 410;
     t['a86'] = 410;
     t['a87'] = 234;
     t['a88'] = 234;
     t['a95'] = 334;
     t['a96'] = 334;
     t['a101'] = 732;
     t['a102'] = 544;
     t['a103'] = 544;
     t['a104'] = 910;
     t['a106'] = 667;
     t['a107'] = 760;
     t['a108'] = 760;
     t['a112'] = 776;
     t['a111'] = 595;
     t['a110'] = 694;
     t['a109'] = 626;
     t['a120'] = 788;
     t['a121'] = 788;
     t['a122'] = 788;
     t['a123'] = 788;
     t['a124'] = 788;
     t['a125'] = 788;
     t['a126'] = 788;
     t['a127'] = 788;
     t['a128'] = 788;
     t['a129'] = 788;
     t['a130'] = 788;
     t['a131'] = 788;
     t['a132'] = 788;
     t['a133'] = 788;
     t['a134'] = 788;
     t['a135'] = 788;
     t['a136'] = 788;
     t['a137'] = 788;
     t['a138'] = 788;
     t['a139'] = 788;
     t['a140'] = 788;
     t['a141'] = 788;
     t['a142'] = 788;
     t['a143'] = 788;
     t['a144'] = 788;
     t['a145'] = 788;
     t['a146'] = 788;
     t['a147'] = 788;
     t['a148'] = 788;
     t['a149'] = 788;
     t['a150'] = 788;
     t['a151'] = 788;
     t['a152'] = 788;
     t['a153'] = 788;
     t['a154'] = 788;
     t['a155'] = 788;
     t['a156'] = 788;
     t['a157'] = 788;
     t['a158'] = 788;
     t['a159'] = 788;
     t['a160'] = 894;
     t['a161'] = 838;
     t['a163'] = 1016;
     t['a164'] = 458;
     t['a196'] = 748;
     t['a165'] = 924;
     t['a192'] = 748;
     t['a166'] = 918;
     t['a167'] = 927;
     t['a168'] = 928;
     t['a169'] = 928;
     t['a170'] = 834;
     t['a171'] = 873;
     t['a172'] = 828;
     t['a173'] = 924;
     t['a162'] = 924;
     t['a174'] = 917;
     t['a175'] = 930;
     t['a176'] = 931;
     t['a177'] = 463;
     t['a178'] = 883;
     t['a179'] = 836;
     t['a193'] = 836;
     t['a180'] = 867;
     t['a199'] = 867;
     t['a181'] = 696;
     t['a200'] = 696;
     t['a182'] = 874;
     t['a201'] = 874;
     t['a183'] = 760;
     t['a184'] = 946;
     t['a197'] = 771;
     t['a185'] = 865;
     t['a194'] = 771;
     t['a198'] = 888;
     t['a186'] = 967;
     t['a195'] = 888;
     t['a187'] = 831;
     t['a188'] = 873;
     t['a189'] = 927;
     t['a190'] = 970;
     t['a191'] = 918;
    });
   });
   exports.getMetrics = getMetrics;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreMurmurHash3 = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var Uint32ArrayView = sharedUtil.Uint32ArrayView;
   var MurmurHash3_64 = function MurmurHash3_64Closure(seed) {
    var MASK_HIGH = 0xffff0000;
    var MASK_LOW = 0xffff;
    function MurmurHash3_64(seed) {
     var SEED = 0xc3d2e1f0;
     this.h1 = seed ? seed & 0xffffffff : SEED;
     this.h2 = seed ? seed & 0xffffffff : SEED;
    }
    var alwaysUseUint32ArrayView = false;
    try {
     new Uint32Array(new Uint8Array(5).buffer, 0, 1);
    } catch (e) {
     alwaysUseUint32ArrayView = true;
    }
    MurmurHash3_64.prototype = {
     update: function MurmurHash3_64_update(input) {
      var useUint32ArrayView = alwaysUseUint32ArrayView;
      var i;
      if (typeof input === 'string') {
       var data = new Uint8Array(input.length * 2);
       var length = 0;
       for (i = 0; i < input.length; i++) {
        var code = input.charCodeAt(i);
        if (code <= 0xff) {
         data[length++] = code;
        } else {
         data[length++] = code >>> 8;
         data[length++] = code & 0xff;
        }
       }
      } else if (input instanceof Uint8Array) {
       data = input;
       length = data.length;
      } else if (typeof input === 'object' && 'length' in input) {
       data = input;
       length = data.length;
       useUint32ArrayView = true;
      } else {
       throw new Error('Wrong data format in MurmurHash3_64_update. ' + 'Input must be a string or array.');
      }
      var blockCounts = length >> 2;
      var tailLength = length - blockCounts * 4;
      var dataUint32 = useUint32ArrayView ? new Uint32ArrayView(data, blockCounts) : new Uint32Array(data.buffer, 0, blockCounts);
      var k1 = 0;
      var k2 = 0;
      var h1 = this.h1;
      var h2 = this.h2;
      var C1 = 0xcc9e2d51;
      var C2 = 0x1b873593;
      var C1_LOW = C1 & MASK_LOW;
      var C2_LOW = C2 & MASK_LOW;
      for (i = 0; i < blockCounts; i++) {
       if (i & 1) {
        k1 = dataUint32[i];
        k1 = k1 * C1 & MASK_HIGH | k1 * C1_LOW & MASK_LOW;
        k1 = k1 << 15 | k1 >>> 17;
        k1 = k1 * C2 & MASK_HIGH | k1 * C2_LOW & MASK_LOW;
        h1 ^= k1;
        h1 = h1 << 13 | h1 >>> 19;
        h1 = h1 * 5 + 0xe6546b64;
       } else {
        k2 = dataUint32[i];
        k2 = k2 * C1 & MASK_HIGH | k2 * C1_LOW & MASK_LOW;
        k2 = k2 << 15 | k2 >>> 17;
        k2 = k2 * C2 & MASK_HIGH | k2 * C2_LOW & MASK_LOW;
        h2 ^= k2;
        h2 = h2 << 13 | h2 >>> 19;
        h2 = h2 * 5 + 0xe6546b64;
       }
      }
      k1 = 0;
      switch (tailLength) {
      case 3:
       k1 ^= data[blockCounts * 4 + 2] << 16;
      case 2:
       k1 ^= data[blockCounts * 4 + 1] << 8;
      case 1:
       k1 ^= data[blockCounts * 4];
       k1 = k1 * C1 & MASK_HIGH | k1 * C1_LOW & MASK_LOW;
       k1 = k1 << 15 | k1 >>> 17;
       k1 = k1 * C2 & MASK_HIGH | k1 * C2_LOW & MASK_LOW;
       if (blockCounts & 1) {
        h1 ^= k1;
       } else {
        h2 ^= k1;
       }
      }
      this.h1 = h1;
      this.h2 = h2;
      return this;
     },
     hexdigest: function MurmurHash3_64_hexdigest() {
      var h1 = this.h1;
      var h2 = this.h2;
      h1 ^= h2 >>> 1;
      h1 = h1 * 0xed558ccd & MASK_HIGH | h1 * 0x8ccd & MASK_LOW;
      h2 = h2 * 0xff51afd7 & MASK_HIGH | ((h2 << 16 | h1 >>> 16) * 0xafd7ed55 & MASK_HIGH) >>> 16;
      h1 ^= h2 >>> 1;
      h1 = h1 * 0x1a85ec53 & MASK_HIGH | h1 * 0xec53 & MASK_LOW;
      h2 = h2 * 0xc4ceb9fe & MASK_HIGH | ((h2 << 16 | h1 >>> 16) * 0xb9fe1a85 & MASK_HIGH) >>> 16;
      h1 ^= h2 >>> 1;
      for (var i = 0, arr = [
         h1,
         h2
        ], str = ''; i < arr.length; i++) {
       var hex = (arr[i] >>> 0).toString(16);
       while (hex.length < 8) {
        hex = '0' + hex;
       }
       str += hex;
      }
      return str;
     }
    };
    return MurmurHash3_64;
   }();
   exports.MurmurHash3_64 = MurmurHash3_64;
  }));
  (function (root, factory) {
   factory(root.pdfjsCorePrimitives = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var isArray = sharedUtil.isArray;
   var Name = function NameClosure() {
    function Name(name) {
     this.name = name;
    }
    Name.prototype = {};
    var nameCache = Object.create(null);
    Name.get = function Name_get(name) {
     var nameValue = nameCache[name];
     return nameValue ? nameValue : nameCache[name] = new Name(name);
    };
    return Name;
   }();
   var Cmd = function CmdClosure() {
    function Cmd(cmd) {
     this.cmd = cmd;
    }
    Cmd.prototype = {};
    var cmdCache = Object.create(null);
    Cmd.get = function Cmd_get(cmd) {
     var cmdValue = cmdCache[cmd];
     return cmdValue ? cmdValue : cmdCache[cmd] = new Cmd(cmd);
    };
    return Cmd;
   }();
   var Dict = function DictClosure() {
    var nonSerializable = function nonSerializableClosure() {
     return nonSerializable;
    };
    function Dict(xref) {
     this.map = Object.create(null);
     this.xref = xref;
     this.objId = null;
     this.suppressEncryption = false;
     this.__nonSerializable__ = nonSerializable;
    }
    Dict.prototype = {
     assignXref: function Dict_assignXref(newXref) {
      this.xref = newXref;
     },
     get: function Dict_get(key1, key2, key3) {
      var value;
      var xref = this.xref, suppressEncryption = this.suppressEncryption;
      if (typeof (value = this.map[key1]) !== 'undefined' || key1 in this.map || typeof key2 === 'undefined') {
       return xref ? xref.fetchIfRef(value, suppressEncryption) : value;
      }
      if (typeof (value = this.map[key2]) !== 'undefined' || key2 in this.map || typeof key3 === 'undefined') {
       return xref ? xref.fetchIfRef(value, suppressEncryption) : value;
      }
      value = this.map[key3] || null;
      return xref ? xref.fetchIfRef(value, suppressEncryption) : value;
     },
     getAsync: function Dict_getAsync(key1, key2, key3) {
      var value;
      var xref = this.xref, suppressEncryption = this.suppressEncryption;
      if (typeof (value = this.map[key1]) !== 'undefined' || key1 in this.map || typeof key2 === 'undefined') {
       if (xref) {
        return xref.fetchIfRefAsync(value, suppressEncryption);
       }
       return Promise.resolve(value);
      }
      if (typeof (value = this.map[key2]) !== 'undefined' || key2 in this.map || typeof key3 === 'undefined') {
       if (xref) {
        return xref.fetchIfRefAsync(value, suppressEncryption);
       }
       return Promise.resolve(value);
      }
      value = this.map[key3] || null;
      if (xref) {
       return xref.fetchIfRefAsync(value, suppressEncryption);
      }
      return Promise.resolve(value);
     },
     getArray: function Dict_getArray(key1, key2, key3) {
      var value = this.get(key1, key2, key3);
      var xref = this.xref, suppressEncryption = this.suppressEncryption;
      if (!isArray(value) || !xref) {
       return value;
      }
      value = value.slice();
      for (var i = 0, ii = value.length; i < ii; i++) {
       if (!isRef(value[i])) {
        continue;
       }
       value[i] = xref.fetch(value[i], suppressEncryption);
      }
      return value;
     },
     getRaw: function Dict_getRaw(key) {
      return this.map[key];
     },
     getKeys: function Dict_getKeys() {
      return Object.keys(this.map);
     },
     set: function Dict_set(key, value) {
      this.map[key] = value;
     },
     has: function Dict_has(key) {
      return key in this.map;
     },
     forEach: function Dict_forEach(callback) {
      for (var key in this.map) {
       callback(key, this.get(key));
      }
     }
    };
    Dict.empty = new Dict(null);
    Dict.merge = function Dict_merge(xref, dictArray) {
     var mergedDict = new Dict(xref);
     for (var i = 0, ii = dictArray.length; i < ii; i++) {
      var dict = dictArray[i];
      if (!isDict(dict)) {
       continue;
      }
      for (var keyName in dict.map) {
       if (mergedDict.map[keyName]) {
        continue;
       }
       mergedDict.map[keyName] = dict.map[keyName];
      }
     }
     return mergedDict;
    };
    return Dict;
   }();
   var Ref = function RefClosure() {
    function Ref(num, gen) {
     this.num = num;
     this.gen = gen;
    }
    Ref.prototype = {
     toString: function Ref_toString() {
      var str = this.num + 'R';
      if (this.gen !== 0) {
       str += this.gen;
      }
      return str;
     }
    };
    return Ref;
   }();
   var RefSet = function RefSetClosure() {
    function RefSet() {
     this.dict = Object.create(null);
    }
    RefSet.prototype = {
     has: function RefSet_has(ref) {
      return ref.toString() in this.dict;
     },
     put: function RefSet_put(ref) {
      this.dict[ref.toString()] = true;
     },
     remove: function RefSet_remove(ref) {
      delete this.dict[ref.toString()];
     }
    };
    return RefSet;
   }();
   var RefSetCache = function RefSetCacheClosure() {
    function RefSetCache() {
     this.dict = Object.create(null);
    }
    RefSetCache.prototype = {
     get: function RefSetCache_get(ref) {
      return this.dict[ref.toString()];
     },
     has: function RefSetCache_has(ref) {
      return ref.toString() in this.dict;
     },
     put: function RefSetCache_put(ref, obj) {
      this.dict[ref.toString()] = obj;
     },
     putAlias: function RefSetCache_putAlias(ref, aliasRef) {
      this.dict[ref.toString()] = this.get(aliasRef);
     },
     forEach: function RefSetCache_forEach(fn, thisArg) {
      for (var i in this.dict) {
       fn.call(thisArg, this.dict[i]);
      }
     },
     clear: function RefSetCache_clear() {
      this.dict = Object.create(null);
     }
    };
    return RefSetCache;
   }();
   function isName(v, name) {
    return v instanceof Name && (name === undefined || v.name === name);
   }
   function isCmd(v, cmd) {
    return v instanceof Cmd && (cmd === undefined || v.cmd === cmd);
   }
   function isDict(v, type) {
    return v instanceof Dict && (type === undefined || isName(v.get('Type'), type));
   }
   function isRef(v) {
    return v instanceof Ref;
   }
   function isRefsEqual(v1, v2) {
    return v1.num === v2.num && v1.gen === v2.gen;
   }
   function isStream(v) {
    return typeof v === 'object' && v !== null && v.getBytes !== undefined;
   }
   exports.Cmd = Cmd;
   exports.Dict = Dict;
   exports.Name = Name;
   exports.Ref = Ref;
   exports.RefSet = RefSet;
   exports.RefSetCache = RefSetCache;
   exports.isCmd = isCmd;
   exports.isDict = isDict;
   exports.isName = isName;
   exports.isRef = isRef;
   exports.isRefsEqual = isRefsEqual;
   exports.isStream = isStream;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreStandardFonts = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var getLookupTableFactory = sharedUtil.getLookupTableFactory;
   var getStdFontMap = getLookupTableFactory(function (t) {
    t['ArialNarrow'] = 'Helvetica';
    t['ArialNarrow-Bold'] = 'Helvetica-Bold';
    t['ArialNarrow-BoldItalic'] = 'Helvetica-BoldOblique';
    t['ArialNarrow-Italic'] = 'Helvetica-Oblique';
    t['ArialBlack'] = 'Helvetica';
    t['ArialBlack-Bold'] = 'Helvetica-Bold';
    t['ArialBlack-BoldItalic'] = 'Helvetica-BoldOblique';
    t['ArialBlack-Italic'] = 'Helvetica-Oblique';
    t['Arial-Black'] = 'Helvetica';
    t['Arial-Black-Bold'] = 'Helvetica-Bold';
    t['Arial-Black-BoldItalic'] = 'Helvetica-BoldOblique';
    t['Arial-Black-Italic'] = 'Helvetica-Oblique';
    t['Arial'] = 'Helvetica';
    t['Arial-Bold'] = 'Helvetica-Bold';
    t['Arial-BoldItalic'] = 'Helvetica-BoldOblique';
    t['Arial-Italic'] = 'Helvetica-Oblique';
    t['Arial-BoldItalicMT'] = 'Helvetica-BoldOblique';
    t['Arial-BoldMT'] = 'Helvetica-Bold';
    t['Arial-ItalicMT'] = 'Helvetica-Oblique';
    t['ArialMT'] = 'Helvetica';
    t['Courier-Bold'] = 'Courier-Bold';
    t['Courier-BoldItalic'] = 'Courier-BoldOblique';
    t['Courier-Italic'] = 'Courier-Oblique';
    t['CourierNew'] = 'Courier';
    t['CourierNew-Bold'] = 'Courier-Bold';
    t['CourierNew-BoldItalic'] = 'Courier-BoldOblique';
    t['CourierNew-Italic'] = 'Courier-Oblique';
    t['CourierNewPS-BoldItalicMT'] = 'Courier-BoldOblique';
    t['CourierNewPS-BoldMT'] = 'Courier-Bold';
    t['CourierNewPS-ItalicMT'] = 'Courier-Oblique';
    t['CourierNewPSMT'] = 'Courier';
    t['Helvetica'] = 'Helvetica';
    t['Helvetica-Bold'] = 'Helvetica-Bold';
    t['Helvetica-BoldItalic'] = 'Helvetica-BoldOblique';
    t['Helvetica-BoldOblique'] = 'Helvetica-BoldOblique';
    t['Helvetica-Italic'] = 'Helvetica-Oblique';
    t['Helvetica-Oblique'] = 'Helvetica-Oblique';
    t['Symbol-Bold'] = 'Symbol';
    t['Symbol-BoldItalic'] = 'Symbol';
    t['Symbol-Italic'] = 'Symbol';
    t['TimesNewRoman'] = 'Times-Roman';
    t['TimesNewRoman-Bold'] = 'Times-Bold';
    t['TimesNewRoman-BoldItalic'] = 'Times-BoldItalic';
    t['TimesNewRoman-Italic'] = 'Times-Italic';
    t['TimesNewRomanPS'] = 'Times-Roman';
    t['TimesNewRomanPS-Bold'] = 'Times-Bold';
    t['TimesNewRomanPS-BoldItalic'] = 'Times-BoldItalic';
    t['TimesNewRomanPS-BoldItalicMT'] = 'Times-BoldItalic';
    t['TimesNewRomanPS-BoldMT'] = 'Times-Bold';
    t['TimesNewRomanPS-Italic'] = 'Times-Italic';
    t['TimesNewRomanPS-ItalicMT'] = 'Times-Italic';
    t['TimesNewRomanPSMT'] = 'Times-Roman';
    t['TimesNewRomanPSMT-Bold'] = 'Times-Bold';
    t['TimesNewRomanPSMT-BoldItalic'] = 'Times-BoldItalic';
    t['TimesNewRomanPSMT-Italic'] = 'Times-Italic';
   });
   var getNonStdFontMap = getLookupTableFactory(function (t) {
    t['CenturyGothic'] = 'Helvetica';
    t['CenturyGothic-Bold'] = 'Helvetica-Bold';
    t['CenturyGothic-BoldItalic'] = 'Helvetica-BoldOblique';
    t['CenturyGothic-Italic'] = 'Helvetica-Oblique';
    t['ComicSansMS'] = 'Comic Sans MS';
    t['ComicSansMS-Bold'] = 'Comic Sans MS-Bold';
    t['ComicSansMS-BoldItalic'] = 'Comic Sans MS-BoldItalic';
    t['ComicSansMS-Italic'] = 'Comic Sans MS-Italic';
    t['LucidaConsole'] = 'Courier';
    t['LucidaConsole-Bold'] = 'Courier-Bold';
    t['LucidaConsole-BoldItalic'] = 'Courier-BoldOblique';
    t['LucidaConsole-Italic'] = 'Courier-Oblique';
    t['MS-Gothic'] = 'MS Gothic';
    t['MS-Gothic-Bold'] = 'MS Gothic-Bold';
    t['MS-Gothic-BoldItalic'] = 'MS Gothic-BoldItalic';
    t['MS-Gothic-Italic'] = 'MS Gothic-Italic';
    t['MS-Mincho'] = 'MS Mincho';
    t['MS-Mincho-Bold'] = 'MS Mincho-Bold';
    t['MS-Mincho-BoldItalic'] = 'MS Mincho-BoldItalic';
    t['MS-Mincho-Italic'] = 'MS Mincho-Italic';
    t['MS-PGothic'] = 'MS PGothic';
    t['MS-PGothic-Bold'] = 'MS PGothic-Bold';
    t['MS-PGothic-BoldItalic'] = 'MS PGothic-BoldItalic';
    t['MS-PGothic-Italic'] = 'MS PGothic-Italic';
    t['MS-PMincho'] = 'MS PMincho';
    t['MS-PMincho-Bold'] = 'MS PMincho-Bold';
    t['MS-PMincho-BoldItalic'] = 'MS PMincho-BoldItalic';
    t['MS-PMincho-Italic'] = 'MS PMincho-Italic';
    t['NuptialScript'] = 'Times-Italic';
    t['Wingdings'] = 'ZapfDingbats';
   });
   var getSerifFonts = getLookupTableFactory(function (t) {
    t['Adobe Jenson'] = true;
    t['Adobe Text'] = true;
    t['Albertus'] = true;
    t['Aldus'] = true;
    t['Alexandria'] = true;
    t['Algerian'] = true;
    t['American Typewriter'] = true;
    t['Antiqua'] = true;
    t['Apex'] = true;
    t['Arno'] = true;
    t['Aster'] = true;
    t['Aurora'] = true;
    t['Baskerville'] = true;
    t['Bell'] = true;
    t['Bembo'] = true;
    t['Bembo Schoolbook'] = true;
    t['Benguiat'] = true;
    t['Berkeley Old Style'] = true;
    t['Bernhard Modern'] = true;
    t['Berthold City'] = true;
    t['Bodoni'] = true;
    t['Bauer Bodoni'] = true;
    t['Book Antiqua'] = true;
    t['Bookman'] = true;
    t['Bordeaux Roman'] = true;
    t['Californian FB'] = true;
    t['Calisto'] = true;
    t['Calvert'] = true;
    t['Capitals'] = true;
    t['Cambria'] = true;
    t['Cartier'] = true;
    t['Caslon'] = true;
    t['Catull'] = true;
    t['Centaur'] = true;
    t['Century Old Style'] = true;
    t['Century Schoolbook'] = true;
    t['Chaparral'] = true;
    t['Charis SIL'] = true;
    t['Cheltenham'] = true;
    t['Cholla Slab'] = true;
    t['Clarendon'] = true;
    t['Clearface'] = true;
    t['Cochin'] = true;
    t['Colonna'] = true;
    t['Computer Modern'] = true;
    t['Concrete Roman'] = true;
    t['Constantia'] = true;
    t['Cooper Black'] = true;
    t['Corona'] = true;
    t['Ecotype'] = true;
    t['Egyptienne'] = true;
    t['Elephant'] = true;
    t['Excelsior'] = true;
    t['Fairfield'] = true;
    t['FF Scala'] = true;
    t['Folkard'] = true;
    t['Footlight'] = true;
    t['FreeSerif'] = true;
    t['Friz Quadrata'] = true;
    t['Garamond'] = true;
    t['Gentium'] = true;
    t['Georgia'] = true;
    t['Gloucester'] = true;
    t['Goudy Old Style'] = true;
    t['Goudy Schoolbook'] = true;
    t['Goudy Pro Font'] = true;
    t['Granjon'] = true;
    t['Guardian Egyptian'] = true;
    t['Heather'] = true;
    t['Hercules'] = true;
    t['High Tower Text'] = true;
    t['Hiroshige'] = true;
    t['Hoefler Text'] = true;
    t['Humana Serif'] = true;
    t['Imprint'] = true;
    t['Ionic No. 5'] = true;
    t['Janson'] = true;
    t['Joanna'] = true;
    t['Korinna'] = true;
    t['Lexicon'] = true;
    t['Liberation Serif'] = true;
    t['Linux Libertine'] = true;
    t['Literaturnaya'] = true;
    t['Lucida'] = true;
    t['Lucida Bright'] = true;
    t['Melior'] = true;
    t['Memphis'] = true;
    t['Miller'] = true;
    t['Minion'] = true;
    t['Modern'] = true;
    t['Mona Lisa'] = true;
    t['Mrs Eaves'] = true;
    t['MS Serif'] = true;
    t['Museo Slab'] = true;
    t['New York'] = true;
    t['Nimbus Roman'] = true;
    t['NPS Rawlinson Roadway'] = true;
    t['NuptialScript'] = true;
    t['Palatino'] = true;
    t['Perpetua'] = true;
    t['Plantin'] = true;
    t['Plantin Schoolbook'] = true;
    t['Playbill'] = true;
    t['Poor Richard'] = true;
    t['Rawlinson Roadway'] = true;
    t['Renault'] = true;
    t['Requiem'] = true;
    t['Rockwell'] = true;
    t['Roman'] = true;
    t['Rotis Serif'] = true;
    t['Sabon'] = true;
    t['Scala'] = true;
    t['Seagull'] = true;
    t['Sistina'] = true;
    t['Souvenir'] = true;
    t['STIX'] = true;
    t['Stone Informal'] = true;
    t['Stone Serif'] = true;
    t['Sylfaen'] = true;
    t['Times'] = true;
    t['Trajan'] = true;
    t['Trinité'] = true;
    t['Trump Mediaeval'] = true;
    t['Utopia'] = true;
    t['Vale Type'] = true;
    t['Bitstream Vera'] = true;
    t['Vera Serif'] = true;
    t['Versailles'] = true;
    t['Wanted'] = true;
    t['Weiss'] = true;
    t['Wide Latin'] = true;
    t['Windsor'] = true;
    t['XITS'] = true;
   });
   var getSymbolsFonts = getLookupTableFactory(function (t) {
    t['Dingbats'] = true;
    t['Symbol'] = true;
    t['ZapfDingbats'] = true;
   });
   var getGlyphMapForStandardFonts = getLookupTableFactory(function (t) {
    t[2] = 10;
    t[3] = 32;
    t[4] = 33;
    t[5] = 34;
    t[6] = 35;
    t[7] = 36;
    t[8] = 37;
    t[9] = 38;
    t[10] = 39;
    t[11] = 40;
    t[12] = 41;
    t[13] = 42;
    t[14] = 43;
    t[15] = 44;
    t[16] = 45;
    t[17] = 46;
    t[18] = 47;
    t[19] = 48;
    t[20] = 49;
    t[21] = 50;
    t[22] = 51;
    t[23] = 52;
    t[24] = 53;
    t[25] = 54;
    t[26] = 55;
    t[27] = 56;
    t[28] = 57;
    t[29] = 58;
    t[30] = 894;
    t[31] = 60;
    t[32] = 61;
    t[33] = 62;
    t[34] = 63;
    t[35] = 64;
    t[36] = 65;
    t[37] = 66;
    t[38] = 67;
    t[39] = 68;
    t[40] = 69;
    t[41] = 70;
    t[42] = 71;
    t[43] = 72;
    t[44] = 73;
    t[45] = 74;
    t[46] = 75;
    t[47] = 76;
    t[48] = 77;
    t[49] = 78;
    t[50] = 79;
    t[51] = 80;
    t[52] = 81;
    t[53] = 82;
    t[54] = 83;
    t[55] = 84;
    t[56] = 85;
    t[57] = 86;
    t[58] = 87;
    t[59] = 88;
    t[60] = 89;
    t[61] = 90;
    t[62] = 91;
    t[63] = 92;
    t[64] = 93;
    t[65] = 94;
    t[66] = 95;
    t[67] = 96;
    t[68] = 97;
    t[69] = 98;
    t[70] = 99;
    t[71] = 100;
    t[72] = 101;
    t[73] = 102;
    t[74] = 103;
    t[75] = 104;
    t[76] = 105;
    t[77] = 106;
    t[78] = 107;
    t[79] = 108;
    t[80] = 109;
    t[81] = 110;
    t[82] = 111;
    t[83] = 112;
    t[84] = 113;
    t[85] = 114;
    t[86] = 115;
    t[87] = 116;
    t[88] = 117;
    t[89] = 118;
    t[90] = 119;
    t[91] = 120;
    t[92] = 121;
    t[93] = 122;
    t[94] = 123;
    t[95] = 124;
    t[96] = 125;
    t[97] = 126;
    t[98] = 196;
    t[99] = 197;
    t[100] = 199;
    t[101] = 201;
    t[102] = 209;
    t[103] = 214;
    t[104] = 220;
    t[105] = 225;
    t[106] = 224;
    t[107] = 226;
    t[108] = 228;
    t[109] = 227;
    t[110] = 229;
    t[111] = 231;
    t[112] = 233;
    t[113] = 232;
    t[114] = 234;
    t[115] = 235;
    t[116] = 237;
    t[117] = 236;
    t[118] = 238;
    t[119] = 239;
    t[120] = 241;
    t[121] = 243;
    t[122] = 242;
    t[123] = 244;
    t[124] = 246;
    t[125] = 245;
    t[126] = 250;
    t[127] = 249;
    t[128] = 251;
    t[129] = 252;
    t[130] = 8224;
    t[131] = 176;
    t[132] = 162;
    t[133] = 163;
    t[134] = 167;
    t[135] = 8226;
    t[136] = 182;
    t[137] = 223;
    t[138] = 174;
    t[139] = 169;
    t[140] = 8482;
    t[141] = 180;
    t[142] = 168;
    t[143] = 8800;
    t[144] = 198;
    t[145] = 216;
    t[146] = 8734;
    t[147] = 177;
    t[148] = 8804;
    t[149] = 8805;
    t[150] = 165;
    t[151] = 181;
    t[152] = 8706;
    t[153] = 8721;
    t[154] = 8719;
    t[156] = 8747;
    t[157] = 170;
    t[158] = 186;
    t[159] = 8486;
    t[160] = 230;
    t[161] = 248;
    t[162] = 191;
    t[163] = 161;
    t[164] = 172;
    t[165] = 8730;
    t[166] = 402;
    t[167] = 8776;
    t[168] = 8710;
    t[169] = 171;
    t[170] = 187;
    t[171] = 8230;
    t[210] = 218;
    t[223] = 711;
    t[224] = 321;
    t[225] = 322;
    t[227] = 353;
    t[229] = 382;
    t[234] = 253;
    t[252] = 263;
    t[253] = 268;
    t[254] = 269;
    t[258] = 258;
    t[260] = 260;
    t[261] = 261;
    t[265] = 280;
    t[266] = 281;
    t[268] = 283;
    t[269] = 313;
    t[275] = 323;
    t[276] = 324;
    t[278] = 328;
    t[284] = 345;
    t[285] = 346;
    t[286] = 347;
    t[292] = 367;
    t[295] = 377;
    t[296] = 378;
    t[298] = 380;
    t[305] = 963;
    t[306] = 964;
    t[307] = 966;
    t[308] = 8215;
    t[309] = 8252;
    t[310] = 8319;
    t[311] = 8359;
    t[312] = 8592;
    t[313] = 8593;
    t[337] = 9552;
    t[493] = 1039;
    t[494] = 1040;
    t[705] = 1524;
    t[706] = 8362;
    t[710] = 64288;
    t[711] = 64298;
    t[759] = 1617;
    t[761] = 1776;
    t[763] = 1778;
    t[775] = 1652;
    t[777] = 1764;
    t[778] = 1780;
    t[779] = 1781;
    t[780] = 1782;
    t[782] = 771;
    t[783] = 64726;
    t[786] = 8363;
    t[788] = 8532;
    t[790] = 768;
    t[791] = 769;
    t[792] = 768;
    t[795] = 803;
    t[797] = 64336;
    t[798] = 64337;
    t[799] = 64342;
    t[800] = 64343;
    t[801] = 64344;
    t[802] = 64345;
    t[803] = 64362;
    t[804] = 64363;
    t[805] = 64364;
    t[2424] = 7821;
    t[2425] = 7822;
    t[2426] = 7823;
    t[2427] = 7824;
    t[2428] = 7825;
    t[2429] = 7826;
    t[2430] = 7827;
    t[2433] = 7682;
    t[2678] = 8045;
    t[2679] = 8046;
    t[2830] = 1552;
    t[2838] = 686;
    t[2840] = 751;
    t[2842] = 753;
    t[2843] = 754;
    t[2844] = 755;
    t[2846] = 757;
    t[2856] = 767;
    t[2857] = 848;
    t[2858] = 849;
    t[2862] = 853;
    t[2863] = 854;
    t[2864] = 855;
    t[2865] = 861;
    t[2866] = 862;
    t[2906] = 7460;
    t[2908] = 7462;
    t[2909] = 7463;
    t[2910] = 7464;
    t[2912] = 7466;
    t[2913] = 7467;
    t[2914] = 7468;
    t[2916] = 7470;
    t[2917] = 7471;
    t[2918] = 7472;
    t[2920] = 7474;
    t[2921] = 7475;
    t[2922] = 7476;
    t[2924] = 7478;
    t[2925] = 7479;
    t[2926] = 7480;
    t[2928] = 7482;
    t[2929] = 7483;
    t[2930] = 7484;
    t[2932] = 7486;
    t[2933] = 7487;
    t[2934] = 7488;
    t[2936] = 7490;
    t[2937] = 7491;
    t[2938] = 7492;
    t[2940] = 7494;
    t[2941] = 7495;
    t[2942] = 7496;
    t[2944] = 7498;
    t[2946] = 7500;
    t[2948] = 7502;
    t[2950] = 7504;
    t[2951] = 7505;
    t[2952] = 7506;
    t[2954] = 7508;
    t[2955] = 7509;
    t[2956] = 7510;
    t[2958] = 7512;
    t[2959] = 7513;
    t[2960] = 7514;
    t[2962] = 7516;
    t[2963] = 7517;
    t[2964] = 7518;
    t[2966] = 7520;
    t[2967] = 7521;
    t[2968] = 7522;
    t[2970] = 7524;
    t[2971] = 7525;
    t[2972] = 7526;
    t[2974] = 7528;
    t[2975] = 7529;
    t[2976] = 7530;
    t[2978] = 1537;
    t[2979] = 1538;
    t[2980] = 1539;
    t[2982] = 1549;
    t[2983] = 1551;
    t[2984] = 1552;
    t[2986] = 1554;
    t[2987] = 1555;
    t[2988] = 1556;
    t[2990] = 1623;
    t[2991] = 1624;
    t[2995] = 1775;
    t[2999] = 1791;
    t[3002] = 64290;
    t[3003] = 64291;
    t[3004] = 64292;
    t[3006] = 64294;
    t[3007] = 64295;
    t[3008] = 64296;
    t[3011] = 1900;
    t[3014] = 8223;
    t[3015] = 8244;
    t[3017] = 7532;
    t[3018] = 7533;
    t[3019] = 7534;
    t[3075] = 7590;
    t[3076] = 7591;
    t[3079] = 7594;
    t[3080] = 7595;
    t[3083] = 7598;
    t[3084] = 7599;
    t[3087] = 7602;
    t[3088] = 7603;
    t[3091] = 7606;
    t[3092] = 7607;
    t[3095] = 7610;
    t[3096] = 7611;
    t[3099] = 7614;
    t[3100] = 7615;
    t[3103] = 7618;
    t[3104] = 7619;
    t[3107] = 8337;
    t[3108] = 8338;
    t[3116] = 1884;
    t[3119] = 1885;
    t[3120] = 1885;
    t[3123] = 1886;
    t[3124] = 1886;
    t[3127] = 1887;
    t[3128] = 1887;
    t[3131] = 1888;
    t[3132] = 1888;
    t[3135] = 1889;
    t[3136] = 1889;
    t[3139] = 1890;
    t[3140] = 1890;
    t[3143] = 1891;
    t[3144] = 1891;
    t[3147] = 1892;
    t[3148] = 1892;
    t[3153] = 580;
    t[3154] = 581;
    t[3157] = 584;
    t[3158] = 585;
    t[3161] = 588;
    t[3162] = 589;
    t[3165] = 891;
    t[3166] = 892;
    t[3169] = 1274;
    t[3170] = 1275;
    t[3173] = 1278;
    t[3174] = 1279;
    t[3181] = 7622;
    t[3182] = 7623;
    t[3282] = 11799;
    t[3316] = 578;
    t[3379] = 42785;
    t[3393] = 1159;
    t[3416] = 8377;
   });
   var getSupplementalGlyphMapForArialBlack = getLookupTableFactory(function (t) {
    t[227] = 322;
    t[264] = 261;
    t[291] = 346;
   });
   exports.getStdFontMap = getStdFontMap;
   exports.getNonStdFontMap = getNonStdFontMap;
   exports.getSerifFonts = getSerifFonts;
   exports.getSymbolsFonts = getSymbolsFonts;
   exports.getGlyphMapForStandardFonts = getGlyphMapForStandardFonts;
   exports.getSupplementalGlyphMapForArialBlack = getSupplementalGlyphMapForArialBlack;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreUnicode = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var getLookupTableFactory = sharedUtil.getLookupTableFactory;
   var getSpecialPUASymbols = getLookupTableFactory(function (t) {
    t[63721] = 0x00A9;
    t[63193] = 0x00A9;
    t[63720] = 0x00AE;
    t[63194] = 0x00AE;
    t[63722] = 0x2122;
    t[63195] = 0x2122;
    t[63729] = 0x23A7;
    t[63730] = 0x23A8;
    t[63731] = 0x23A9;
    t[63740] = 0x23AB;
    t[63741] = 0x23AC;
    t[63742] = 0x23AD;
    t[63726] = 0x23A1;
    t[63727] = 0x23A2;
    t[63728] = 0x23A3;
    t[63737] = 0x23A4;
    t[63738] = 0x23A5;
    t[63739] = 0x23A6;
    t[63723] = 0x239B;
    t[63724] = 0x239C;
    t[63725] = 0x239D;
    t[63734] = 0x239E;
    t[63735] = 0x239F;
    t[63736] = 0x23A0;
   });
   function mapSpecialUnicodeValues(code) {
    if (code >= 0xFFF0 && code <= 0xFFFF) {
     return 0;
    } else if (code >= 0xF600 && code <= 0xF8FF) {
     return getSpecialPUASymbols()[code] || code;
    }
    return code;
   }
   function getUnicodeForGlyph(name, glyphsUnicodeMap) {
    var unicode = glyphsUnicodeMap[name];
    if (unicode !== undefined) {
     return unicode;
    }
    if (!name) {
     return -1;
    }
    if (name[0] === 'u') {
     var nameLen = name.length, hexStr;
     if (nameLen === 7 && name[1] === 'n' && name[2] === 'i') {
      hexStr = name.substr(3);
     } else if (nameLen >= 5 && nameLen <= 7) {
      hexStr = name.substr(1);
     } else {
      return -1;
     }
     if (hexStr === hexStr.toUpperCase()) {
      unicode = parseInt(hexStr, 16);
      if (unicode >= 0) {
       return unicode;
      }
     }
    }
    return -1;
   }
   var UnicodeRanges = [
    {
     'begin': 0x0000,
     'end': 0x007F
    },
    {
     'begin': 0x0080,
     'end': 0x00FF
    },
    {
     'begin': 0x0100,
     'end': 0x017F
    },
    {
     'begin': 0x0180,
     'end': 0x024F
    },
    {
     'begin': 0x0250,
     'end': 0x02AF
    },
    {
     'begin': 0x02B0,
     'end': 0x02FF
    },
    {
     'begin': 0x0300,
     'end': 0x036F
    },
    {
     'begin': 0x0370,
     'end': 0x03FF
    },
    {
     'begin': 0x2C80,
     'end': 0x2CFF
    },
    {
     'begin': 0x0400,
     'end': 0x04FF
    },
    {
     'begin': 0x0530,
     'end': 0x058F
    },
    {
     'begin': 0x0590,
     'end': 0x05FF
    },
    {
     'begin': 0xA500,
     'end': 0xA63F
    },
    {
     'begin': 0x0600,
     'end': 0x06FF
    },
    {
     'begin': 0x07C0,
     'end': 0x07FF
    },
    {
     'begin': 0x0900,
     'end': 0x097F
    },
    {
     'begin': 0x0980,
     'end': 0x09FF
    },
    {
     'begin': 0x0A00,
     'end': 0x0A7F
    },
    {
     'begin': 0x0A80,
     'end': 0x0AFF
    },
    {
     'begin': 0x0B00,
     'end': 0x0B7F
    },
    {
     'begin': 0x0B80,
     'end': 0x0BFF
    },
    {
     'begin': 0x0C00,
     'end': 0x0C7F
    },
    {
     'begin': 0x0C80,
     'end': 0x0CFF
    },
    {
     'begin': 0x0D00,
     'end': 0x0D7F
    },
    {
     'begin': 0x0E00,
     'end': 0x0E7F
    },
    {
     'begin': 0x0E80,
     'end': 0x0EFF
    },
    {
     'begin': 0x10A0,
     'end': 0x10FF
    },
    {
     'begin': 0x1B00,
     'end': 0x1B7F
    },
    {
     'begin': 0x1100,
     'end': 0x11FF
    },
    {
     'begin': 0x1E00,
     'end': 0x1EFF
    },
    {
     'begin': 0x1F00,
     'end': 0x1FFF
    },
    {
     'begin': 0x2000,
     'end': 0x206F
    },
    {
     'begin': 0x2070,
     'end': 0x209F
    },
    {
     'begin': 0x20A0,
     'end': 0x20CF
    },
    {
     'begin': 0x20D0,
     'end': 0x20FF
    },
    {
     'begin': 0x2100,
     'end': 0x214F
    },
    {
     'begin': 0x2150,
     'end': 0x218F
    },
    {
     'begin': 0x2190,
     'end': 0x21FF
    },
    {
     'begin': 0x2200,
     'end': 0x22FF
    },
    {
     'begin': 0x2300,
     'end': 0x23FF
    },
    {
     'begin': 0x2400,
     'end': 0x243F
    },
    {
     'begin': 0x2440,
     'end': 0x245F
    },
    {
     'begin': 0x2460,
     'end': 0x24FF
    },
    {
     'begin': 0x2500,
     'end': 0x257F
    },
    {
     'begin': 0x2580,
     'end': 0x259F
    },
    {
     'begin': 0x25A0,
     'end': 0x25FF
    },
    {
     'begin': 0x2600,
     'end': 0x26FF
    },
    {
     'begin': 0x2700,
     'end': 0x27BF
    },
    {
     'begin': 0x3000,
     'end': 0x303F
    },
    {
     'begin': 0x3040,
     'end': 0x309F
    },
    {
     'begin': 0x30A0,
     'end': 0x30FF
    },
    {
     'begin': 0x3100,
     'end': 0x312F
    },
    {
     'begin': 0x3130,
     'end': 0x318F
    },
    {
     'begin': 0xA840,
     'end': 0xA87F
    },
    {
     'begin': 0x3200,
     'end': 0x32FF
    },
    {
     'begin': 0x3300,
     'end': 0x33FF
    },
    {
     'begin': 0xAC00,
     'end': 0xD7AF
    },
    {
     'begin': 0xD800,
     'end': 0xDFFF
    },
    {
     'begin': 0x10900,
     'end': 0x1091F
    },
    {
     'begin': 0x4E00,
     'end': 0x9FFF
    },
    {
     'begin': 0xE000,
     'end': 0xF8FF
    },
    {
     'begin': 0x31C0,
     'end': 0x31EF
    },
    {
     'begin': 0xFB00,
     'end': 0xFB4F
    },
    {
     'begin': 0xFB50,
     'end': 0xFDFF
    },
    {
     'begin': 0xFE20,
     'end': 0xFE2F
    },
    {
     'begin': 0xFE10,
     'end': 0xFE1F
    },
    {
     'begin': 0xFE50,
     'end': 0xFE6F
    },
    {
     'begin': 0xFE70,
     'end': 0xFEFF
    },
    {
     'begin': 0xFF00,
     'end': 0xFFEF
    },
    {
     'begin': 0xFFF0,
     'end': 0xFFFF
    },
    {
     'begin': 0x0F00,
     'end': 0x0FFF
    },
    {
     'begin': 0x0700,
     'end': 0x074F
    },
    {
     'begin': 0x0780,
     'end': 0x07BF
    },
    {
     'begin': 0x0D80,
     'end': 0x0DFF
    },
    {
     'begin': 0x1000,
     'end': 0x109F
    },
    {
     'begin': 0x1200,
     'end': 0x137F
    },
    {
     'begin': 0x13A0,
     'end': 0x13FF
    },
    {
     'begin': 0x1400,
     'end': 0x167F
    },
    {
     'begin': 0x1680,
     'end': 0x169F
    },
    {
     'begin': 0x16A0,
     'end': 0x16FF
    },
    {
     'begin': 0x1780,
     'end': 0x17FF
    },
    {
     'begin': 0x1800,
     'end': 0x18AF
    },
    {
     'begin': 0x2800,
     'end': 0x28FF
    },
    {
     'begin': 0xA000,
     'end': 0xA48F
    },
    {
     'begin': 0x1700,
     'end': 0x171F
    },
    {
     'begin': 0x10300,
     'end': 0x1032F
    },
    {
     'begin': 0x10330,
     'end': 0x1034F
    },
    {
     'begin': 0x10400,
     'end': 0x1044F
    },
    {
     'begin': 0x1D000,
     'end': 0x1D0FF
    },
    {
     'begin': 0x1D400,
     'end': 0x1D7FF
    },
    {
     'begin': 0xFF000,
     'end': 0xFFFFD
    },
    {
     'begin': 0xFE00,
     'end': 0xFE0F
    },
    {
     'begin': 0xE0000,
     'end': 0xE007F
    },
    {
     'begin': 0x1900,
     'end': 0x194F
    },
    {
     'begin': 0x1950,
     'end': 0x197F
    },
    {
     'begin': 0x1980,
     'end': 0x19DF
    },
    {
     'begin': 0x1A00,
     'end': 0x1A1F
    },
    {
     'begin': 0x2C00,
     'end': 0x2C5F
    },
    {
     'begin': 0x2D30,
     'end': 0x2D7F
    },
    {
     'begin': 0x4DC0,
     'end': 0x4DFF
    },
    {
     'begin': 0xA800,
     'end': 0xA82F
    },
    {
     'begin': 0x10000,
     'end': 0x1007F
    },
    {
     'begin': 0x10140,
     'end': 0x1018F
    },
    {
     'begin': 0x10380,
     'end': 0x1039F
    },
    {
     'begin': 0x103A0,
     'end': 0x103DF
    },
    {
     'begin': 0x10450,
     'end': 0x1047F
    },
    {
     'begin': 0x10480,
     'end': 0x104AF
    },
    {
     'begin': 0x10800,
     'end': 0x1083F
    },
    {
     'begin': 0x10A00,
     'end': 0x10A5F
    },
    {
     'begin': 0x1D300,
     'end': 0x1D35F
    },
    {
     'begin': 0x12000,
     'end': 0x123FF
    },
    {
     'begin': 0x1D360,
     'end': 0x1D37F
    },
    {
     'begin': 0x1B80,
     'end': 0x1BBF
    },
    {
     'begin': 0x1C00,
     'end': 0x1C4F
    },
    {
     'begin': 0x1C50,
     'end': 0x1C7F
    },
    {
     'begin': 0xA880,
     'end': 0xA8DF
    },
    {
     'begin': 0xA900,
     'end': 0xA92F
    },
    {
     'begin': 0xA930,
     'end': 0xA95F
    },
    {
     'begin': 0xAA00,
     'end': 0xAA5F
    },
    {
     'begin': 0x10190,
     'end': 0x101CF
    },
    {
     'begin': 0x101D0,
     'end': 0x101FF
    },
    {
     'begin': 0x102A0,
     'end': 0x102DF
    },
    {
     'begin': 0x1F030,
     'end': 0x1F09F
    }
   ];
   function getUnicodeRangeFor(value) {
    for (var i = 0, ii = UnicodeRanges.length; i < ii; i++) {
     var range = UnicodeRanges[i];
     if (value >= range.begin && value < range.end) {
      return i;
     }
    }
    return -1;
   }
   function isRTLRangeFor(value) {
    var range = UnicodeRanges[13];
    if (value >= range.begin && value < range.end) {
     return true;
    }
    range = UnicodeRanges[11];
    if (value >= range.begin && value < range.end) {
     return true;
    }
    return false;
   }
   var getNormalizedUnicodes = getLookupTableFactory(function (t) {
    t['\u00A8'] = '\u0020\u0308';
    t['\u00AF'] = '\u0020\u0304';
    t['\u00B4'] = '\u0020\u0301';
    t['\u00B5'] = '\u03BC';
    t['\u00B8'] = '\u0020\u0327';
    t['\u0132'] = '\u0049\u004A';
    t['\u0133'] = '\u0069\u006A';
    t['\u013F'] = '\u004C\u00B7';
    t['\u0140'] = '\u006C\u00B7';
    t['\u0149'] = '\u02BC\u006E';
    t['\u017F'] = '\u0073';
    t['\u01C4'] = '\u0044\u017D';
    t['\u01C5'] = '\u0044\u017E';
    t['\u01C6'] = '\u0064\u017E';
    t['\u01C7'] = '\u004C\u004A';
    t['\u01C8'] = '\u004C\u006A';
    t['\u01C9'] = '\u006C\u006A';
    t['\u01CA'] = '\u004E\u004A';
    t['\u01CB'] = '\u004E\u006A';
    t['\u01CC'] = '\u006E\u006A';
    t['\u01F1'] = '\u0044\u005A';
    t['\u01F2'] = '\u0044\u007A';
    t['\u01F3'] = '\u0064\u007A';
    t['\u02D8'] = '\u0020\u0306';
    t['\u02D9'] = '\u0020\u0307';
    t['\u02DA'] = '\u0020\u030A';
    t['\u02DB'] = '\u0020\u0328';
    t['\u02DC'] = '\u0020\u0303';
    t['\u02DD'] = '\u0020\u030B';
    t['\u037A'] = '\u0020\u0345';
    t['\u0384'] = '\u0020\u0301';
    t['\u03D0'] = '\u03B2';
    t['\u03D1'] = '\u03B8';
    t['\u03D2'] = '\u03A5';
    t['\u03D5'] = '\u03C6';
    t['\u03D6'] = '\u03C0';
    t['\u03F0'] = '\u03BA';
    t['\u03F1'] = '\u03C1';
    t['\u03F2'] = '\u03C2';
    t['\u03F4'] = '\u0398';
    t['\u03F5'] = '\u03B5';
    t['\u03F9'] = '\u03A3';
    t['\u0587'] = '\u0565\u0582';
    t['\u0675'] = '\u0627\u0674';
    t['\u0676'] = '\u0648\u0674';
    t['\u0677'] = '\u06C7\u0674';
    t['\u0678'] = '\u064A\u0674';
    t['\u0E33'] = '\u0E4D\u0E32';
    t['\u0EB3'] = '\u0ECD\u0EB2';
    t['\u0EDC'] = '\u0EAB\u0E99';
    t['\u0EDD'] = '\u0EAB\u0EA1';
    t['\u0F77'] = '\u0FB2\u0F81';
    t['\u0F79'] = '\u0FB3\u0F81';
    t['\u1E9A'] = '\u0061\u02BE';
    t['\u1FBD'] = '\u0020\u0313';
    t['\u1FBF'] = '\u0020\u0313';
    t['\u1FC0'] = '\u0020\u0342';
    t['\u1FFE'] = '\u0020\u0314';
    t['\u2002'] = '\u0020';
    t['\u2003'] = '\u0020';
    t['\u2004'] = '\u0020';
    t['\u2005'] = '\u0020';
    t['\u2006'] = '\u0020';
    t['\u2008'] = '\u0020';
    t['\u2009'] = '\u0020';
    t['\u200A'] = '\u0020';
    t['\u2017'] = '\u0020\u0333';
    t['\u2024'] = '\u002E';
    t['\u2025'] = '\u002E\u002E';
    t['\u2026'] = '\u002E\u002E\u002E';
    t['\u2033'] = '\u2032\u2032';
    t['\u2034'] = '\u2032\u2032\u2032';
    t['\u2036'] = '\u2035\u2035';
    t['\u2037'] = '\u2035\u2035\u2035';
    t['\u203C'] = '\u0021\u0021';
    t['\u203E'] = '\u0020\u0305';
    t['\u2047'] = '\u003F\u003F';
    t['\u2048'] = '\u003F\u0021';
    t['\u2049'] = '\u0021\u003F';
    t['\u2057'] = '\u2032\u2032\u2032\u2032';
    t['\u205F'] = '\u0020';
    t['\u20A8'] = '\u0052\u0073';
    t['\u2100'] = '\u0061\u002F\u0063';
    t['\u2101'] = '\u0061\u002F\u0073';
    t['\u2103'] = '\u00B0\u0043';
    t['\u2105'] = '\u0063\u002F\u006F';
    t['\u2106'] = '\u0063\u002F\u0075';
    t['\u2107'] = '\u0190';
    t['\u2109'] = '\u00B0\u0046';
    t['\u2116'] = '\u004E\u006F';
    t['\u2121'] = '\u0054\u0045\u004C';
    t['\u2135'] = '\u05D0';
    t['\u2136'] = '\u05D1';
    t['\u2137'] = '\u05D2';
    t['\u2138'] = '\u05D3';
    t['\u213B'] = '\u0046\u0041\u0058';
    t['\u2160'] = '\u0049';
    t['\u2161'] = '\u0049\u0049';
    t['\u2162'] = '\u0049\u0049\u0049';
    t['\u2163'] = '\u0049\u0056';
    t['\u2164'] = '\u0056';
    t['\u2165'] = '\u0056\u0049';
    t['\u2166'] = '\u0056\u0049\u0049';
    t['\u2167'] = '\u0056\u0049\u0049\u0049';
    t['\u2168'] = '\u0049\u0058';
    t['\u2169'] = '\u0058';
    t['\u216A'] = '\u0058\u0049';
    t['\u216B'] = '\u0058\u0049\u0049';
    t['\u216C'] = '\u004C';
    t['\u216D'] = '\u0043';
    t['\u216E'] = '\u0044';
    t['\u216F'] = '\u004D';
    t['\u2170'] = '\u0069';
    t['\u2171'] = '\u0069\u0069';
    t['\u2172'] = '\u0069\u0069\u0069';
    t['\u2173'] = '\u0069\u0076';
    t['\u2174'] = '\u0076';
    t['\u2175'] = '\u0076\u0069';
    t['\u2176'] = '\u0076\u0069\u0069';
    t['\u2177'] = '\u0076\u0069\u0069\u0069';
    t['\u2178'] = '\u0069\u0078';
    t['\u2179'] = '\u0078';
    t['\u217A'] = '\u0078\u0069';
    t['\u217B'] = '\u0078\u0069\u0069';
    t['\u217C'] = '\u006C';
    t['\u217D'] = '\u0063';
    t['\u217E'] = '\u0064';
    t['\u217F'] = '\u006D';
    t['\u222C'] = '\u222B\u222B';
    t['\u222D'] = '\u222B\u222B\u222B';
    t['\u222F'] = '\u222E\u222E';
    t['\u2230'] = '\u222E\u222E\u222E';
    t['\u2474'] = '\u0028\u0031\u0029';
    t['\u2475'] = '\u0028\u0032\u0029';
    t['\u2476'] = '\u0028\u0033\u0029';
    t['\u2477'] = '\u0028\u0034\u0029';
    t['\u2478'] = '\u0028\u0035\u0029';
    t['\u2479'] = '\u0028\u0036\u0029';
    t['\u247A'] = '\u0028\u0037\u0029';
    t['\u247B'] = '\u0028\u0038\u0029';
    t['\u247C'] = '\u0028\u0039\u0029';
    t['\u247D'] = '\u0028\u0031\u0030\u0029';
    t['\u247E'] = '\u0028\u0031\u0031\u0029';
    t['\u247F'] = '\u0028\u0031\u0032\u0029';
    t['\u2480'] = '\u0028\u0031\u0033\u0029';
    t['\u2481'] = '\u0028\u0031\u0034\u0029';
    t['\u2482'] = '\u0028\u0031\u0035\u0029';
    t['\u2483'] = '\u0028\u0031\u0036\u0029';
    t['\u2484'] = '\u0028\u0031\u0037\u0029';
    t['\u2485'] = '\u0028\u0031\u0038\u0029';
    t['\u2486'] = '\u0028\u0031\u0039\u0029';
    t['\u2487'] = '\u0028\u0032\u0030\u0029';
    t['\u2488'] = '\u0031\u002E';
    t['\u2489'] = '\u0032\u002E';
    t['\u248A'] = '\u0033\u002E';
    t['\u248B'] = '\u0034\u002E';
    t['\u248C'] = '\u0035\u002E';
    t['\u248D'] = '\u0036\u002E';
    t['\u248E'] = '\u0037\u002E';
    t['\u248F'] = '\u0038\u002E';
    t['\u2490'] = '\u0039\u002E';
    t['\u2491'] = '\u0031\u0030\u002E';
    t['\u2492'] = '\u0031\u0031\u002E';
    t['\u2493'] = '\u0031\u0032\u002E';
    t['\u2494'] = '\u0031\u0033\u002E';
    t['\u2495'] = '\u0031\u0034\u002E';
    t['\u2496'] = '\u0031\u0035\u002E';
    t['\u2497'] = '\u0031\u0036\u002E';
    t['\u2498'] = '\u0031\u0037\u002E';
    t['\u2499'] = '\u0031\u0038\u002E';
    t['\u249A'] = '\u0031\u0039\u002E';
    t['\u249B'] = '\u0032\u0030\u002E';
    t['\u249C'] = '\u0028\u0061\u0029';
    t['\u249D'] = '\u0028\u0062\u0029';
    t['\u249E'] = '\u0028\u0063\u0029';
    t['\u249F'] = '\u0028\u0064\u0029';
    t['\u24A0'] = '\u0028\u0065\u0029';
    t['\u24A1'] = '\u0028\u0066\u0029';
    t['\u24A2'] = '\u0028\u0067\u0029';
    t['\u24A3'] = '\u0028\u0068\u0029';
    t['\u24A4'] = '\u0028\u0069\u0029';
    t['\u24A5'] = '\u0028\u006A\u0029';
    t['\u24A6'] = '\u0028\u006B\u0029';
    t['\u24A7'] = '\u0028\u006C\u0029';
    t['\u24A8'] = '\u0028\u006D\u0029';
    t['\u24A9'] = '\u0028\u006E\u0029';
    t['\u24AA'] = '\u0028\u006F\u0029';
    t['\u24AB'] = '\u0028\u0070\u0029';
    t['\u24AC'] = '\u0028\u0071\u0029';
    t['\u24AD'] = '\u0028\u0072\u0029';
    t['\u24AE'] = '\u0028\u0073\u0029';
    t['\u24AF'] = '\u0028\u0074\u0029';
    t['\u24B0'] = '\u0028\u0075\u0029';
    t['\u24B1'] = '\u0028\u0076\u0029';
    t['\u24B2'] = '\u0028\u0077\u0029';
    t['\u24B3'] = '\u0028\u0078\u0029';
    t['\u24B4'] = '\u0028\u0079\u0029';
    t['\u24B5'] = '\u0028\u007A\u0029';
    t['\u2A0C'] = '\u222B\u222B\u222B\u222B';
    t['\u2A74'] = '\u003A\u003A\u003D';
    t['\u2A75'] = '\u003D\u003D';
    t['\u2A76'] = '\u003D\u003D\u003D';
    t['\u2E9F'] = '\u6BCD';
    t['\u2EF3'] = '\u9F9F';
    t['\u2F00'] = '\u4E00';
    t['\u2F01'] = '\u4E28';
    t['\u2F02'] = '\u4E36';
    t['\u2F03'] = '\u4E3F';
    t['\u2F04'] = '\u4E59';
    t['\u2F05'] = '\u4E85';
    t['\u2F06'] = '\u4E8C';
    t['\u2F07'] = '\u4EA0';
    t['\u2F08'] = '\u4EBA';
    t['\u2F09'] = '\u513F';
    t['\u2F0A'] = '\u5165';
    t['\u2F0B'] = '\u516B';
    t['\u2F0C'] = '\u5182';
    t['\u2F0D'] = '\u5196';
    t['\u2F0E'] = '\u51AB';
    t['\u2F0F'] = '\u51E0';
    t['\u2F10'] = '\u51F5';
    t['\u2F11'] = '\u5200';
    t['\u2F12'] = '\u529B';
    t['\u2F13'] = '\u52F9';
    t['\u2F14'] = '\u5315';
    t['\u2F15'] = '\u531A';
    t['\u2F16'] = '\u5338';
    t['\u2F17'] = '\u5341';
    t['\u2F18'] = '\u535C';
    t['\u2F19'] = '\u5369';
    t['\u2F1A'] = '\u5382';
    t['\u2F1B'] = '\u53B6';
    t['\u2F1C'] = '\u53C8';
    t['\u2F1D'] = '\u53E3';
    t['\u2F1E'] = '\u56D7';
    t['\u2F1F'] = '\u571F';
    t['\u2F20'] = '\u58EB';
    t['\u2F21'] = '\u5902';
    t['\u2F22'] = '\u590A';
    t['\u2F23'] = '\u5915';
    t['\u2F24'] = '\u5927';
    t['\u2F25'] = '\u5973';
    t['\u2F26'] = '\u5B50';
    t['\u2F27'] = '\u5B80';
    t['\u2F28'] = '\u5BF8';
    t['\u2F29'] = '\u5C0F';
    t['\u2F2A'] = '\u5C22';
    t['\u2F2B'] = '\u5C38';
    t['\u2F2C'] = '\u5C6E';
    t['\u2F2D'] = '\u5C71';
    t['\u2F2E'] = '\u5DDB';
    t['\u2F2F'] = '\u5DE5';
    t['\u2F30'] = '\u5DF1';
    t['\u2F31'] = '\u5DFE';
    t['\u2F32'] = '\u5E72';
    t['\u2F33'] = '\u5E7A';
    t['\u2F34'] = '\u5E7F';
    t['\u2F35'] = '\u5EF4';
    t['\u2F36'] = '\u5EFE';
    t['\u2F37'] = '\u5F0B';
    t['\u2F38'] = '\u5F13';
    t['\u2F39'] = '\u5F50';
    t['\u2F3A'] = '\u5F61';
    t['\u2F3B'] = '\u5F73';
    t['\u2F3C'] = '\u5FC3';
    t['\u2F3D'] = '\u6208';
    t['\u2F3E'] = '\u6236';
    t['\u2F3F'] = '\u624B';
    t['\u2F40'] = '\u652F';
    t['\u2F41'] = '\u6534';
    t['\u2F42'] = '\u6587';
    t['\u2F43'] = '\u6597';
    t['\u2F44'] = '\u65A4';
    t['\u2F45'] = '\u65B9';
    t['\u2F46'] = '\u65E0';
    t['\u2F47'] = '\u65E5';
    t['\u2F48'] = '\u66F0';
    t['\u2F49'] = '\u6708';
    t['\u2F4A'] = '\u6728';
    t['\u2F4B'] = '\u6B20';
    t['\u2F4C'] = '\u6B62';
    t['\u2F4D'] = '\u6B79';
    t['\u2F4E'] = '\u6BB3';
    t['\u2F4F'] = '\u6BCB';
    t['\u2F50'] = '\u6BD4';
    t['\u2F51'] = '\u6BDB';
    t['\u2F52'] = '\u6C0F';
    t['\u2F53'] = '\u6C14';
    t['\u2F54'] = '\u6C34';
    t['\u2F55'] = '\u706B';
    t['\u2F56'] = '\u722A';
    t['\u2F57'] = '\u7236';
    t['\u2F58'] = '\u723B';
    t['\u2F59'] = '\u723F';
    t['\u2F5A'] = '\u7247';
    t['\u2F5B'] = '\u7259';
    t['\u2F5C'] = '\u725B';
    t['\u2F5D'] = '\u72AC';
    t['\u2F5E'] = '\u7384';
    t['\u2F5F'] = '\u7389';
    t['\u2F60'] = '\u74DC';
    t['\u2F61'] = '\u74E6';
    t['\u2F62'] = '\u7518';
    t['\u2F63'] = '\u751F';
    t['\u2F64'] = '\u7528';
    t['\u2F65'] = '\u7530';
    t['\u2F66'] = '\u758B';
    t['\u2F67'] = '\u7592';
    t['\u2F68'] = '\u7676';
    t['\u2F69'] = '\u767D';
    t['\u2F6A'] = '\u76AE';
    t['\u2F6B'] = '\u76BF';
    t['\u2F6C'] = '\u76EE';
    t['\u2F6D'] = '\u77DB';
    t['\u2F6E'] = '\u77E2';
    t['\u2F6F'] = '\u77F3';
    t['\u2F70'] = '\u793A';
    t['\u2F71'] = '\u79B8';
    t['\u2F72'] = '\u79BE';
    t['\u2F73'] = '\u7A74';
    t['\u2F74'] = '\u7ACB';
    t['\u2F75'] = '\u7AF9';
    t['\u2F76'] = '\u7C73';
    t['\u2F77'] = '\u7CF8';
    t['\u2F78'] = '\u7F36';
    t['\u2F79'] = '\u7F51';
    t['\u2F7A'] = '\u7F8A';
    t['\u2F7B'] = '\u7FBD';
    t['\u2F7C'] = '\u8001';
    t['\u2F7D'] = '\u800C';
    t['\u2F7E'] = '\u8012';
    t['\u2F7F'] = '\u8033';
    t['\u2F80'] = '\u807F';
    t['\u2F81'] = '\u8089';
    t['\u2F82'] = '\u81E3';
    t['\u2F83'] = '\u81EA';
    t['\u2F84'] = '\u81F3';
    t['\u2F85'] = '\u81FC';
    t['\u2F86'] = '\u820C';
    t['\u2F87'] = '\u821B';
    t['\u2F88'] = '\u821F';
    t['\u2F89'] = '\u826E';
    t['\u2F8A'] = '\u8272';
    t['\u2F8B'] = '\u8278';
    t['\u2F8C'] = '\u864D';
    t['\u2F8D'] = '\u866B';
    t['\u2F8E'] = '\u8840';
    t['\u2F8F'] = '\u884C';
    t['\u2F90'] = '\u8863';
    t['\u2F91'] = '\u897E';
    t['\u2F92'] = '\u898B';
    t['\u2F93'] = '\u89D2';
    t['\u2F94'] = '\u8A00';
    t['\u2F95'] = '\u8C37';
    t['\u2F96'] = '\u8C46';
    t['\u2F97'] = '\u8C55';
    t['\u2F98'] = '\u8C78';
    t['\u2F99'] = '\u8C9D';
    t['\u2F9A'] = '\u8D64';
    t['\u2F9B'] = '\u8D70';
    t['\u2F9C'] = '\u8DB3';
    t['\u2F9D'] = '\u8EAB';
    t['\u2F9E'] = '\u8ECA';
    t['\u2F9F'] = '\u8F9B';
    t['\u2FA0'] = '\u8FB0';
    t['\u2FA1'] = '\u8FB5';
    t['\u2FA2'] = '\u9091';
    t['\u2FA3'] = '\u9149';
    t['\u2FA4'] = '\u91C6';
    t['\u2FA5'] = '\u91CC';
    t['\u2FA6'] = '\u91D1';
    t['\u2FA7'] = '\u9577';
    t['\u2FA8'] = '\u9580';
    t['\u2FA9'] = '\u961C';
    t['\u2FAA'] = '\u96B6';
    t['\u2FAB'] = '\u96B9';
    t['\u2FAC'] = '\u96E8';
    t['\u2FAD'] = '\u9751';
    t['\u2FAE'] = '\u975E';
    t['\u2FAF'] = '\u9762';
    t['\u2FB0'] = '\u9769';
    t['\u2FB1'] = '\u97CB';
    t['\u2FB2'] = '\u97ED';
    t['\u2FB3'] = '\u97F3';
    t['\u2FB4'] = '\u9801';
    t['\u2FB5'] = '\u98A8';
    t['\u2FB6'] = '\u98DB';
    t['\u2FB7'] = '\u98DF';
    t['\u2FB8'] = '\u9996';
    t['\u2FB9'] = '\u9999';
    t['\u2FBA'] = '\u99AC';
    t['\u2FBB'] = '\u9AA8';
    t['\u2FBC'] = '\u9AD8';
    t['\u2FBD'] = '\u9ADF';
    t['\u2FBE'] = '\u9B25';
    t['\u2FBF'] = '\u9B2F';
    t['\u2FC0'] = '\u9B32';
    t['\u2FC1'] = '\u9B3C';
    t['\u2FC2'] = '\u9B5A';
    t['\u2FC3'] = '\u9CE5';
    t['\u2FC4'] = '\u9E75';
    t['\u2FC5'] = '\u9E7F';
    t['\u2FC6'] = '\u9EA5';
    t['\u2FC7'] = '\u9EBB';
    t['\u2FC8'] = '\u9EC3';
    t['\u2FC9'] = '\u9ECD';
    t['\u2FCA'] = '\u9ED1';
    t['\u2FCB'] = '\u9EF9';
    t['\u2FCC'] = '\u9EFD';
    t['\u2FCD'] = '\u9F0E';
    t['\u2FCE'] = '\u9F13';
    t['\u2FCF'] = '\u9F20';
    t['\u2FD0'] = '\u9F3B';
    t['\u2FD1'] = '\u9F4A';
    t['\u2FD2'] = '\u9F52';
    t['\u2FD3'] = '\u9F8D';
    t['\u2FD4'] = '\u9F9C';
    t['\u2FD5'] = '\u9FA0';
    t['\u3036'] = '\u3012';
    t['\u3038'] = '\u5341';
    t['\u3039'] = '\u5344';
    t['\u303A'] = '\u5345';
    t['\u309B'] = '\u0020\u3099';
    t['\u309C'] = '\u0020\u309A';
    t['\u3131'] = '\u1100';
    t['\u3132'] = '\u1101';
    t['\u3133'] = '\u11AA';
    t['\u3134'] = '\u1102';
    t['\u3135'] = '\u11AC';
    t['\u3136'] = '\u11AD';
    t['\u3137'] = '\u1103';
    t['\u3138'] = '\u1104';
    t['\u3139'] = '\u1105';
    t['\u313A'] = '\u11B0';
    t['\u313B'] = '\u11B1';
    t['\u313C'] = '\u11B2';
    t['\u313D'] = '\u11B3';
    t['\u313E'] = '\u11B4';
    t['\u313F'] = '\u11B5';
    t['\u3140'] = '\u111A';
    t['\u3141'] = '\u1106';
    t['\u3142'] = '\u1107';
    t['\u3143'] = '\u1108';
    t['\u3144'] = '\u1121';
    t['\u3145'] = '\u1109';
    t['\u3146'] = '\u110A';
    t['\u3147'] = '\u110B';
    t['\u3148'] = '\u110C';
    t['\u3149'] = '\u110D';
    t['\u314A'] = '\u110E';
    t['\u314B'] = '\u110F';
    t['\u314C'] = '\u1110';
    t['\u314D'] = '\u1111';
    t['\u314E'] = '\u1112';
    t['\u314F'] = '\u1161';
    t['\u3150'] = '\u1162';
    t['\u3151'] = '\u1163';
    t['\u3152'] = '\u1164';
    t['\u3153'] = '\u1165';
    t['\u3154'] = '\u1166';
    t['\u3155'] = '\u1167';
    t['\u3156'] = '\u1168';
    t['\u3157'] = '\u1169';
    t['\u3158'] = '\u116A';
    t['\u3159'] = '\u116B';
    t['\u315A'] = '\u116C';
    t['\u315B'] = '\u116D';
    t['\u315C'] = '\u116E';
    t['\u315D'] = '\u116F';
    t['\u315E'] = '\u1170';
    t['\u315F'] = '\u1171';
    t['\u3160'] = '\u1172';
    t['\u3161'] = '\u1173';
    t['\u3162'] = '\u1174';
    t['\u3163'] = '\u1175';
    t['\u3164'] = '\u1160';
    t['\u3165'] = '\u1114';
    t['\u3166'] = '\u1115';
    t['\u3167'] = '\u11C7';
    t['\u3168'] = '\u11C8';
    t['\u3169'] = '\u11CC';
    t['\u316A'] = '\u11CE';
    t['\u316B'] = '\u11D3';
    t['\u316C'] = '\u11D7';
    t['\u316D'] = '\u11D9';
    t['\u316E'] = '\u111C';
    t['\u316F'] = '\u11DD';
    t['\u3170'] = '\u11DF';
    t['\u3171'] = '\u111D';
    t['\u3172'] = '\u111E';
    t['\u3173'] = '\u1120';
    t['\u3174'] = '\u1122';
    t['\u3175'] = '\u1123';
    t['\u3176'] = '\u1127';
    t['\u3177'] = '\u1129';
    t['\u3178'] = '\u112B';
    t['\u3179'] = '\u112C';
    t['\u317A'] = '\u112D';
    t['\u317B'] = '\u112E';
    t['\u317C'] = '\u112F';
    t['\u317D'] = '\u1132';
    t['\u317E'] = '\u1136';
    t['\u317F'] = '\u1140';
    t['\u3180'] = '\u1147';
    t['\u3181'] = '\u114C';
    t['\u3182'] = '\u11F1';
    t['\u3183'] = '\u11F2';
    t['\u3184'] = '\u1157';
    t['\u3185'] = '\u1158';
    t['\u3186'] = '\u1159';
    t['\u3187'] = '\u1184';
    t['\u3188'] = '\u1185';
    t['\u3189'] = '\u1188';
    t['\u318A'] = '\u1191';
    t['\u318B'] = '\u1192';
    t['\u318C'] = '\u1194';
    t['\u318D'] = '\u119E';
    t['\u318E'] = '\u11A1';
    t['\u3200'] = '\u0028\u1100\u0029';
    t['\u3201'] = '\u0028\u1102\u0029';
    t['\u3202'] = '\u0028\u1103\u0029';
    t['\u3203'] = '\u0028\u1105\u0029';
    t['\u3204'] = '\u0028\u1106\u0029';
    t['\u3205'] = '\u0028\u1107\u0029';
    t['\u3206'] = '\u0028\u1109\u0029';
    t['\u3207'] = '\u0028\u110B\u0029';
    t['\u3208'] = '\u0028\u110C\u0029';
    t['\u3209'] = '\u0028\u110E\u0029';
    t['\u320A'] = '\u0028\u110F\u0029';
    t['\u320B'] = '\u0028\u1110\u0029';
    t['\u320C'] = '\u0028\u1111\u0029';
    t['\u320D'] = '\u0028\u1112\u0029';
    t['\u320E'] = '\u0028\u1100\u1161\u0029';
    t['\u320F'] = '\u0028\u1102\u1161\u0029';
    t['\u3210'] = '\u0028\u1103\u1161\u0029';
    t['\u3211'] = '\u0028\u1105\u1161\u0029';
    t['\u3212'] = '\u0028\u1106\u1161\u0029';
    t['\u3213'] = '\u0028\u1107\u1161\u0029';
    t['\u3214'] = '\u0028\u1109\u1161\u0029';
    t['\u3215'] = '\u0028\u110B\u1161\u0029';
    t['\u3216'] = '\u0028\u110C\u1161\u0029';
    t['\u3217'] = '\u0028\u110E\u1161\u0029';
    t['\u3218'] = '\u0028\u110F\u1161\u0029';
    t['\u3219'] = '\u0028\u1110\u1161\u0029';
    t['\u321A'] = '\u0028\u1111\u1161\u0029';
    t['\u321B'] = '\u0028\u1112\u1161\u0029';
    t['\u321C'] = '\u0028\u110C\u116E\u0029';
    t['\u321D'] = '\u0028\u110B\u1169\u110C\u1165\u11AB\u0029';
    t['\u321E'] = '\u0028\u110B\u1169\u1112\u116E\u0029';
    t['\u3220'] = '\u0028\u4E00\u0029';
    t['\u3221'] = '\u0028\u4E8C\u0029';
    t['\u3222'] = '\u0028\u4E09\u0029';
    t['\u3223'] = '\u0028\u56DB\u0029';
    t['\u3224'] = '\u0028\u4E94\u0029';
    t['\u3225'] = '\u0028\u516D\u0029';
    t['\u3226'] = '\u0028\u4E03\u0029';
    t['\u3227'] = '\u0028\u516B\u0029';
    t['\u3228'] = '\u0028\u4E5D\u0029';
    t['\u3229'] = '\u0028\u5341\u0029';
    t['\u322A'] = '\u0028\u6708\u0029';
    t['\u322B'] = '\u0028\u706B\u0029';
    t['\u322C'] = '\u0028\u6C34\u0029';
    t['\u322D'] = '\u0028\u6728\u0029';
    t['\u322E'] = '\u0028\u91D1\u0029';
    t['\u322F'] = '\u0028\u571F\u0029';
    t['\u3230'] = '\u0028\u65E5\u0029';
    t['\u3231'] = '\u0028\u682A\u0029';
    t['\u3232'] = '\u0028\u6709\u0029';
    t['\u3233'] = '\u0028\u793E\u0029';
    t['\u3234'] = '\u0028\u540D\u0029';
    t['\u3235'] = '\u0028\u7279\u0029';
    t['\u3236'] = '\u0028\u8CA1\u0029';
    t['\u3237'] = '\u0028\u795D\u0029';
    t['\u3238'] = '\u0028\u52B4\u0029';
    t['\u3239'] = '\u0028\u4EE3\u0029';
    t['\u323A'] = '\u0028\u547C\u0029';
    t['\u323B'] = '\u0028\u5B66\u0029';
    t['\u323C'] = '\u0028\u76E3\u0029';
    t['\u323D'] = '\u0028\u4F01\u0029';
    t['\u323E'] = '\u0028\u8CC7\u0029';
    t['\u323F'] = '\u0028\u5354\u0029';
    t['\u3240'] = '\u0028\u796D\u0029';
    t['\u3241'] = '\u0028\u4F11\u0029';
    t['\u3242'] = '\u0028\u81EA\u0029';
    t['\u3243'] = '\u0028\u81F3\u0029';
    t['\u32C0'] = '\u0031\u6708';
    t['\u32C1'] = '\u0032\u6708';
    t['\u32C2'] = '\u0033\u6708';
    t['\u32C3'] = '\u0034\u6708';
    t['\u32C4'] = '\u0035\u6708';
    t['\u32C5'] = '\u0036\u6708';
    t['\u32C6'] = '\u0037\u6708';
    t['\u32C7'] = '\u0038\u6708';
    t['\u32C8'] = '\u0039\u6708';
    t['\u32C9'] = '\u0031\u0030\u6708';
    t['\u32CA'] = '\u0031\u0031\u6708';
    t['\u32CB'] = '\u0031\u0032\u6708';
    t['\u3358'] = '\u0030\u70B9';
    t['\u3359'] = '\u0031\u70B9';
    t['\u335A'] = '\u0032\u70B9';
    t['\u335B'] = '\u0033\u70B9';
    t['\u335C'] = '\u0034\u70B9';
    t['\u335D'] = '\u0035\u70B9';
    t['\u335E'] = '\u0036\u70B9';
    t['\u335F'] = '\u0037\u70B9';
    t['\u3360'] = '\u0038\u70B9';
    t['\u3361'] = '\u0039\u70B9';
    t['\u3362'] = '\u0031\u0030\u70B9';
    t['\u3363'] = '\u0031\u0031\u70B9';
    t['\u3364'] = '\u0031\u0032\u70B9';
    t['\u3365'] = '\u0031\u0033\u70B9';
    t['\u3366'] = '\u0031\u0034\u70B9';
    t['\u3367'] = '\u0031\u0035\u70B9';
    t['\u3368'] = '\u0031\u0036\u70B9';
    t['\u3369'] = '\u0031\u0037\u70B9';
    t['\u336A'] = '\u0031\u0038\u70B9';
    t['\u336B'] = '\u0031\u0039\u70B9';
    t['\u336C'] = '\u0032\u0030\u70B9';
    t['\u336D'] = '\u0032\u0031\u70B9';
    t['\u336E'] = '\u0032\u0032\u70B9';
    t['\u336F'] = '\u0032\u0033\u70B9';
    t['\u3370'] = '\u0032\u0034\u70B9';
    t['\u33E0'] = '\u0031\u65E5';
    t['\u33E1'] = '\u0032\u65E5';
    t['\u33E2'] = '\u0033\u65E5';
    t['\u33E3'] = '\u0034\u65E5';
    t['\u33E4'] = '\u0035\u65E5';
    t['\u33E5'] = '\u0036\u65E5';
    t['\u33E6'] = '\u0037\u65E5';
    t['\u33E7'] = '\u0038\u65E5';
    t['\u33E8'] = '\u0039\u65E5';
    t['\u33E9'] = '\u0031\u0030\u65E5';
    t['\u33EA'] = '\u0031\u0031\u65E5';
    t['\u33EB'] = '\u0031\u0032\u65E5';
    t['\u33EC'] = '\u0031\u0033\u65E5';
    t['\u33ED'] = '\u0031\u0034\u65E5';
    t['\u33EE'] = '\u0031\u0035\u65E5';
    t['\u33EF'] = '\u0031\u0036\u65E5';
    t['\u33F0'] = '\u0031\u0037\u65E5';
    t['\u33F1'] = '\u0031\u0038\u65E5';
    t['\u33F2'] = '\u0031\u0039\u65E5';
    t['\u33F3'] = '\u0032\u0030\u65E5';
    t['\u33F4'] = '\u0032\u0031\u65E5';
    t['\u33F5'] = '\u0032\u0032\u65E5';
    t['\u33F6'] = '\u0032\u0033\u65E5';
    t['\u33F7'] = '\u0032\u0034\u65E5';
    t['\u33F8'] = '\u0032\u0035\u65E5';
    t['\u33F9'] = '\u0032\u0036\u65E5';
    t['\u33FA'] = '\u0032\u0037\u65E5';
    t['\u33FB'] = '\u0032\u0038\u65E5';
    t['\u33FC'] = '\u0032\u0039\u65E5';
    t['\u33FD'] = '\u0033\u0030\u65E5';
    t['\u33FE'] = '\u0033\u0031\u65E5';
    t['\uFB00'] = '\u0066\u0066';
    t['\uFB01'] = '\u0066\u0069';
    t['\uFB02'] = '\u0066\u006C';
    t['\uFB03'] = '\u0066\u0066\u0069';
    t['\uFB04'] = '\u0066\u0066\u006C';
    t['\uFB05'] = '\u017F\u0074';
    t['\uFB06'] = '\u0073\u0074';
    t['\uFB13'] = '\u0574\u0576';
    t['\uFB14'] = '\u0574\u0565';
    t['\uFB15'] = '\u0574\u056B';
    t['\uFB16'] = '\u057E\u0576';
    t['\uFB17'] = '\u0574\u056D';
    t['\uFB4F'] = '\u05D0\u05DC';
    t['\uFB50'] = '\u0671';
    t['\uFB51'] = '\u0671';
    t['\uFB52'] = '\u067B';
    t['\uFB53'] = '\u067B';
    t['\uFB54'] = '\u067B';
    t['\uFB55'] = '\u067B';
    t['\uFB56'] = '\u067E';
    t['\uFB57'] = '\u067E';
    t['\uFB58'] = '\u067E';
    t['\uFB59'] = '\u067E';
    t['\uFB5A'] = '\u0680';
    t['\uFB5B'] = '\u0680';
    t['\uFB5C'] = '\u0680';
    t['\uFB5D'] = '\u0680';
    t['\uFB5E'] = '\u067A';
    t['\uFB5F'] = '\u067A';
    t['\uFB60'] = '\u067A';
    t['\uFB61'] = '\u067A';
    t['\uFB62'] = '\u067F';
    t['\uFB63'] = '\u067F';
    t['\uFB64'] = '\u067F';
    t['\uFB65'] = '\u067F';
    t['\uFB66'] = '\u0679';
    t['\uFB67'] = '\u0679';
    t['\uFB68'] = '\u0679';
    t['\uFB69'] = '\u0679';
    t['\uFB6A'] = '\u06A4';
    t['\uFB6B'] = '\u06A4';
    t['\uFB6C'] = '\u06A4';
    t['\uFB6D'] = '\u06A4';
    t['\uFB6E'] = '\u06A6';
    t['\uFB6F'] = '\u06A6';
    t['\uFB70'] = '\u06A6';
    t['\uFB71'] = '\u06A6';
    t['\uFB72'] = '\u0684';
    t['\uFB73'] = '\u0684';
    t['\uFB74'] = '\u0684';
    t['\uFB75'] = '\u0684';
    t['\uFB76'] = '\u0683';
    t['\uFB77'] = '\u0683';
    t['\uFB78'] = '\u0683';
    t['\uFB79'] = '\u0683';
    t['\uFB7A'] = '\u0686';
    t['\uFB7B'] = '\u0686';
    t['\uFB7C'] = '\u0686';
    t['\uFB7D'] = '\u0686';
    t['\uFB7E'] = '\u0687';
    t['\uFB7F'] = '\u0687';
    t['\uFB80'] = '\u0687';
    t['\uFB81'] = '\u0687';
    t['\uFB82'] = '\u068D';
    t['\uFB83'] = '\u068D';
    t['\uFB84'] = '\u068C';
    t['\uFB85'] = '\u068C';
    t['\uFB86'] = '\u068E';
    t['\uFB87'] = '\u068E';
    t['\uFB88'] = '\u0688';
    t['\uFB89'] = '\u0688';
    t['\uFB8A'] = '\u0698';
    t['\uFB8B'] = '\u0698';
    t['\uFB8C'] = '\u0691';
    t['\uFB8D'] = '\u0691';
    t['\uFB8E'] = '\u06A9';
    t['\uFB8F'] = '\u06A9';
    t['\uFB90'] = '\u06A9';
    t['\uFB91'] = '\u06A9';
    t['\uFB92'] = '\u06AF';
    t['\uFB93'] = '\u06AF';
    t['\uFB94'] = '\u06AF';
    t['\uFB95'] = '\u06AF';
    t['\uFB96'] = '\u06B3';
    t['\uFB97'] = '\u06B3';
    t['\uFB98'] = '\u06B3';
    t['\uFB99'] = '\u06B3';
    t['\uFB9A'] = '\u06B1';
    t['\uFB9B'] = '\u06B1';
    t['\uFB9C'] = '\u06B1';
    t['\uFB9D'] = '\u06B1';
    t['\uFB9E'] = '\u06BA';
    t['\uFB9F'] = '\u06BA';
    t['\uFBA0'] = '\u06BB';
    t['\uFBA1'] = '\u06BB';
    t['\uFBA2'] = '\u06BB';
    t['\uFBA3'] = '\u06BB';
    t['\uFBA4'] = '\u06C0';
    t['\uFBA5'] = '\u06C0';
    t['\uFBA6'] = '\u06C1';
    t['\uFBA7'] = '\u06C1';
    t['\uFBA8'] = '\u06C1';
    t['\uFBA9'] = '\u06C1';
    t['\uFBAA'] = '\u06BE';
    t['\uFBAB'] = '\u06BE';
    t['\uFBAC'] = '\u06BE';
    t['\uFBAD'] = '\u06BE';
    t['\uFBAE'] = '\u06D2';
    t['\uFBAF'] = '\u06D2';
    t['\uFBB0'] = '\u06D3';
    t['\uFBB1'] = '\u06D3';
    t['\uFBD3'] = '\u06AD';
    t['\uFBD4'] = '\u06AD';
    t['\uFBD5'] = '\u06AD';
    t['\uFBD6'] = '\u06AD';
    t['\uFBD7'] = '\u06C7';
    t['\uFBD8'] = '\u06C7';
    t['\uFBD9'] = '\u06C6';
    t['\uFBDA'] = '\u06C6';
    t['\uFBDB'] = '\u06C8';
    t['\uFBDC'] = '\u06C8';
    t['\uFBDD'] = '\u0677';
    t['\uFBDE'] = '\u06CB';
    t['\uFBDF'] = '\u06CB';
    t['\uFBE0'] = '\u06C5';
    t['\uFBE1'] = '\u06C5';
    t['\uFBE2'] = '\u06C9';
    t['\uFBE3'] = '\u06C9';
    t['\uFBE4'] = '\u06D0';
    t['\uFBE5'] = '\u06D0';
    t['\uFBE6'] = '\u06D0';
    t['\uFBE7'] = '\u06D0';
    t['\uFBE8'] = '\u0649';
    t['\uFBE9'] = '\u0649';
    t['\uFBEA'] = '\u0626\u0627';
    t['\uFBEB'] = '\u0626\u0627';
    t['\uFBEC'] = '\u0626\u06D5';
    t['\uFBED'] = '\u0626\u06D5';
    t['\uFBEE'] = '\u0626\u0648';
    t['\uFBEF'] = '\u0626\u0648';
    t['\uFBF0'] = '\u0626\u06C7';
    t['\uFBF1'] = '\u0626\u06C7';
    t['\uFBF2'] = '\u0626\u06C6';
    t['\uFBF3'] = '\u0626\u06C6';
    t['\uFBF4'] = '\u0626\u06C8';
    t['\uFBF5'] = '\u0626\u06C8';
    t['\uFBF6'] = '\u0626\u06D0';
    t['\uFBF7'] = '\u0626\u06D0';
    t['\uFBF8'] = '\u0626\u06D0';
    t['\uFBF9'] = '\u0626\u0649';
    t['\uFBFA'] = '\u0626\u0649';
    t['\uFBFB'] = '\u0626\u0649';
    t['\uFBFC'] = '\u06CC';
    t['\uFBFD'] = '\u06CC';
    t['\uFBFE'] = '\u06CC';
    t['\uFBFF'] = '\u06CC';
    t['\uFC00'] = '\u0626\u062C';
    t['\uFC01'] = '\u0626\u062D';
    t['\uFC02'] = '\u0626\u0645';
    t['\uFC03'] = '\u0626\u0649';
    t['\uFC04'] = '\u0626\u064A';
    t['\uFC05'] = '\u0628\u062C';
    t['\uFC06'] = '\u0628\u062D';
    t['\uFC07'] = '\u0628\u062E';
    t['\uFC08'] = '\u0628\u0645';
    t['\uFC09'] = '\u0628\u0649';
    t['\uFC0A'] = '\u0628\u064A';
    t['\uFC0B'] = '\u062A\u062C';
    t['\uFC0C'] = '\u062A\u062D';
    t['\uFC0D'] = '\u062A\u062E';
    t['\uFC0E'] = '\u062A\u0645';
    t['\uFC0F'] = '\u062A\u0649';
    t['\uFC10'] = '\u062A\u064A';
    t['\uFC11'] = '\u062B\u062C';
    t['\uFC12'] = '\u062B\u0645';
    t['\uFC13'] = '\u062B\u0649';
    t['\uFC14'] = '\u062B\u064A';
    t['\uFC15'] = '\u062C\u062D';
    t['\uFC16'] = '\u062C\u0645';
    t['\uFC17'] = '\u062D\u062C';
    t['\uFC18'] = '\u062D\u0645';
    t['\uFC19'] = '\u062E\u062C';
    t['\uFC1A'] = '\u062E\u062D';
    t['\uFC1B'] = '\u062E\u0645';
    t['\uFC1C'] = '\u0633\u062C';
    t['\uFC1D'] = '\u0633\u062D';
    t['\uFC1E'] = '\u0633\u062E';
    t['\uFC1F'] = '\u0633\u0645';
    t['\uFC20'] = '\u0635\u062D';
    t['\uFC21'] = '\u0635\u0645';
    t['\uFC22'] = '\u0636\u062C';
    t['\uFC23'] = '\u0636\u062D';
    t['\uFC24'] = '\u0636\u062E';
    t['\uFC25'] = '\u0636\u0645';
    t['\uFC26'] = '\u0637\u062D';
    t['\uFC27'] = '\u0637\u0645';
    t['\uFC28'] = '\u0638\u0645';
    t['\uFC29'] = '\u0639\u062C';
    t['\uFC2A'] = '\u0639\u0645';
    t['\uFC2B'] = '\u063A\u062C';
    t['\uFC2C'] = '\u063A\u0645';
    t['\uFC2D'] = '\u0641\u062C';
    t['\uFC2E'] = '\u0641\u062D';
    t['\uFC2F'] = '\u0641\u062E';
    t['\uFC30'] = '\u0641\u0645';
    t['\uFC31'] = '\u0641\u0649';
    t['\uFC32'] = '\u0641\u064A';
    t['\uFC33'] = '\u0642\u062D';
    t['\uFC34'] = '\u0642\u0645';
    t['\uFC35'] = '\u0642\u0649';
    t['\uFC36'] = '\u0642\u064A';
    t['\uFC37'] = '\u0643\u0627';
    t['\uFC38'] = '\u0643\u062C';
    t['\uFC39'] = '\u0643\u062D';
    t['\uFC3A'] = '\u0643\u062E';
    t['\uFC3B'] = '\u0643\u0644';
    t['\uFC3C'] = '\u0643\u0645';
    t['\uFC3D'] = '\u0643\u0649';
    t['\uFC3E'] = '\u0643\u064A';
    t['\uFC3F'] = '\u0644\u062C';
    t['\uFC40'] = '\u0644\u062D';
    t['\uFC41'] = '\u0644\u062E';
    t['\uFC42'] = '\u0644\u0645';
    t['\uFC43'] = '\u0644\u0649';
    t['\uFC44'] = '\u0644\u064A';
    t['\uFC45'] = '\u0645\u062C';
    t['\uFC46'] = '\u0645\u062D';
    t['\uFC47'] = '\u0645\u062E';
    t['\uFC48'] = '\u0645\u0645';
    t['\uFC49'] = '\u0645\u0649';
    t['\uFC4A'] = '\u0645\u064A';
    t['\uFC4B'] = '\u0646\u062C';
    t['\uFC4C'] = '\u0646\u062D';
    t['\uFC4D'] = '\u0646\u062E';
    t['\uFC4E'] = '\u0646\u0645';
    t['\uFC4F'] = '\u0646\u0649';
    t['\uFC50'] = '\u0646\u064A';
    t['\uFC51'] = '\u0647\u062C';
    t['\uFC52'] = '\u0647\u0645';
    t['\uFC53'] = '\u0647\u0649';
    t['\uFC54'] = '\u0647\u064A';
    t['\uFC55'] = '\u064A\u062C';
    t['\uFC56'] = '\u064A\u062D';
    t['\uFC57'] = '\u064A\u062E';
    t['\uFC58'] = '\u064A\u0645';
    t['\uFC59'] = '\u064A\u0649';
    t['\uFC5A'] = '\u064A\u064A';
    t['\uFC5B'] = '\u0630\u0670';
    t['\uFC5C'] = '\u0631\u0670';
    t['\uFC5D'] = '\u0649\u0670';
    t['\uFC5E'] = '\u0020\u064C\u0651';
    t['\uFC5F'] = '\u0020\u064D\u0651';
    t['\uFC60'] = '\u0020\u064E\u0651';
    t['\uFC61'] = '\u0020\u064F\u0651';
    t['\uFC62'] = '\u0020\u0650\u0651';
    t['\uFC63'] = '\u0020\u0651\u0670';
    t['\uFC64'] = '\u0626\u0631';
    t['\uFC65'] = '\u0626\u0632';
    t['\uFC66'] = '\u0626\u0645';
    t['\uFC67'] = '\u0626\u0646';
    t['\uFC68'] = '\u0626\u0649';
    t['\uFC69'] = '\u0626\u064A';
    t['\uFC6A'] = '\u0628\u0631';
    t['\uFC6B'] = '\u0628\u0632';
    t['\uFC6C'] = '\u0628\u0645';
    t['\uFC6D'] = '\u0628\u0646';
    t['\uFC6E'] = '\u0628\u0649';
    t['\uFC6F'] = '\u0628\u064A';
    t['\uFC70'] = '\u062A\u0631';
    t['\uFC71'] = '\u062A\u0632';
    t['\uFC72'] = '\u062A\u0645';
    t['\uFC73'] = '\u062A\u0646';
    t['\uFC74'] = '\u062A\u0649';
    t['\uFC75'] = '\u062A\u064A';
    t['\uFC76'] = '\u062B\u0631';
    t['\uFC77'] = '\u062B\u0632';
    t['\uFC78'] = '\u062B\u0645';
    t['\uFC79'] = '\u062B\u0646';
    t['\uFC7A'] = '\u062B\u0649';
    t['\uFC7B'] = '\u062B\u064A';
    t['\uFC7C'] = '\u0641\u0649';
    t['\uFC7D'] = '\u0641\u064A';
    t['\uFC7E'] = '\u0642\u0649';
    t['\uFC7F'] = '\u0642\u064A';
    t['\uFC80'] = '\u0643\u0627';
    t['\uFC81'] = '\u0643\u0644';
    t['\uFC82'] = '\u0643\u0645';
    t['\uFC83'] = '\u0643\u0649';
    t['\uFC84'] = '\u0643\u064A';
    t['\uFC85'] = '\u0644\u0645';
    t['\uFC86'] = '\u0644\u0649';
    t['\uFC87'] = '\u0644\u064A';
    t['\uFC88'] = '\u0645\u0627';
    t['\uFC89'] = '\u0645\u0645';
    t['\uFC8A'] = '\u0646\u0631';
    t['\uFC8B'] = '\u0646\u0632';
    t['\uFC8C'] = '\u0646\u0645';
    t['\uFC8D'] = '\u0646\u0646';
    t['\uFC8E'] = '\u0646\u0649';
    t['\uFC8F'] = '\u0646\u064A';
    t['\uFC90'] = '\u0649\u0670';
    t['\uFC91'] = '\u064A\u0631';
    t['\uFC92'] = '\u064A\u0632';
    t['\uFC93'] = '\u064A\u0645';
    t['\uFC94'] = '\u064A\u0646';
    t['\uFC95'] = '\u064A\u0649';
    t['\uFC96'] = '\u064A\u064A';
    t['\uFC97'] = '\u0626\u062C';
    t['\uFC98'] = '\u0626\u062D';
    t['\uFC99'] = '\u0626\u062E';
    t['\uFC9A'] = '\u0626\u0645';
    t['\uFC9B'] = '\u0626\u0647';
    t['\uFC9C'] = '\u0628\u062C';
    t['\uFC9D'] = '\u0628\u062D';
    t['\uFC9E'] = '\u0628\u062E';
    t['\uFC9F'] = '\u0628\u0645';
    t['\uFCA0'] = '\u0628\u0647';
    t['\uFCA1'] = '\u062A\u062C';
    t['\uFCA2'] = '\u062A\u062D';
    t['\uFCA3'] = '\u062A\u062E';
    t['\uFCA4'] = '\u062A\u0645';
    t['\uFCA5'] = '\u062A\u0647';
    t['\uFCA6'] = '\u062B\u0645';
    t['\uFCA7'] = '\u062C\u062D';
    t['\uFCA8'] = '\u062C\u0645';
    t['\uFCA9'] = '\u062D\u062C';
    t['\uFCAA'] = '\u062D\u0645';
    t['\uFCAB'] = '\u062E\u062C';
    t['\uFCAC'] = '\u062E\u0645';
    t['\uFCAD'] = '\u0633\u062C';
    t['\uFCAE'] = '\u0633\u062D';
    t['\uFCAF'] = '\u0633\u062E';
    t['\uFCB0'] = '\u0633\u0645';
    t['\uFCB1'] = '\u0635\u062D';
    t['\uFCB2'] = '\u0635\u062E';
    t['\uFCB3'] = '\u0635\u0645';
    t['\uFCB4'] = '\u0636\u062C';
    t['\uFCB5'] = '\u0636\u062D';
    t['\uFCB6'] = '\u0636\u062E';
    t['\uFCB7'] = '\u0636\u0645';
    t['\uFCB8'] = '\u0637\u062D';
    t['\uFCB9'] = '\u0638\u0645';
    t['\uFCBA'] = '\u0639\u062C';
    t['\uFCBB'] = '\u0639\u0645';
    t['\uFCBC'] = '\u063A\u062C';
    t['\uFCBD'] = '\u063A\u0645';
    t['\uFCBE'] = '\u0641\u062C';
    t['\uFCBF'] = '\u0641\u062D';
    t['\uFCC0'] = '\u0641\u062E';
    t['\uFCC1'] = '\u0641\u0645';
    t['\uFCC2'] = '\u0642\u062D';
    t['\uFCC3'] = '\u0642\u0645';
    t['\uFCC4'] = '\u0643\u062C';
    t['\uFCC5'] = '\u0643\u062D';
    t['\uFCC6'] = '\u0643\u062E';
    t['\uFCC7'] = '\u0643\u0644';
    t['\uFCC8'] = '\u0643\u0645';
    t['\uFCC9'] = '\u0644\u062C';
    t['\uFCCA'] = '\u0644\u062D';
    t['\uFCCB'] = '\u0644\u062E';
    t['\uFCCC'] = '\u0644\u0645';
    t['\uFCCD'] = '\u0644\u0647';
    t['\uFCCE'] = '\u0645\u062C';
    t['\uFCCF'] = '\u0645\u062D';
    t['\uFCD0'] = '\u0645\u062E';
    t['\uFCD1'] = '\u0645\u0645';
    t['\uFCD2'] = '\u0646\u062C';
    t['\uFCD3'] = '\u0646\u062D';
    t['\uFCD4'] = '\u0646\u062E';
    t['\uFCD5'] = '\u0646\u0645';
    t['\uFCD6'] = '\u0646\u0647';
    t['\uFCD7'] = '\u0647\u062C';
    t['\uFCD8'] = '\u0647\u0645';
    t['\uFCD9'] = '\u0647\u0670';
    t['\uFCDA'] = '\u064A\u062C';
    t['\uFCDB'] = '\u064A\u062D';
    t['\uFCDC'] = '\u064A\u062E';
    t['\uFCDD'] = '\u064A\u0645';
    t['\uFCDE'] = '\u064A\u0647';
    t['\uFCDF'] = '\u0626\u0645';
    t['\uFCE0'] = '\u0626\u0647';
    t['\uFCE1'] = '\u0628\u0645';
    t['\uFCE2'] = '\u0628\u0647';
    t['\uFCE3'] = '\u062A\u0645';
    t['\uFCE4'] = '\u062A\u0647';
    t['\uFCE5'] = '\u062B\u0645';
    t['\uFCE6'] = '\u062B\u0647';
    t['\uFCE7'] = '\u0633\u0645';
    t['\uFCE8'] = '\u0633\u0647';
    t['\uFCE9'] = '\u0634\u0645';
    t['\uFCEA'] = '\u0634\u0647';
    t['\uFCEB'] = '\u0643\u0644';
    t['\uFCEC'] = '\u0643\u0645';
    t['\uFCED'] = '\u0644\u0645';
    t['\uFCEE'] = '\u0646\u0645';
    t['\uFCEF'] = '\u0646\u0647';
    t['\uFCF0'] = '\u064A\u0645';
    t['\uFCF1'] = '\u064A\u0647';
    t['\uFCF2'] = '\u0640\u064E\u0651';
    t['\uFCF3'] = '\u0640\u064F\u0651';
    t['\uFCF4'] = '\u0640\u0650\u0651';
    t['\uFCF5'] = '\u0637\u0649';
    t['\uFCF6'] = '\u0637\u064A';
    t['\uFCF7'] = '\u0639\u0649';
    t['\uFCF8'] = '\u0639\u064A';
    t['\uFCF9'] = '\u063A\u0649';
    t['\uFCFA'] = '\u063A\u064A';
    t['\uFCFB'] = '\u0633\u0649';
    t['\uFCFC'] = '\u0633\u064A';
    t['\uFCFD'] = '\u0634\u0649';
    t['\uFCFE'] = '\u0634\u064A';
    t['\uFCFF'] = '\u062D\u0649';
    t['\uFD00'] = '\u062D\u064A';
    t['\uFD01'] = '\u062C\u0649';
    t['\uFD02'] = '\u062C\u064A';
    t['\uFD03'] = '\u062E\u0649';
    t['\uFD04'] = '\u062E\u064A';
    t['\uFD05'] = '\u0635\u0649';
    t['\uFD06'] = '\u0635\u064A';
    t['\uFD07'] = '\u0636\u0649';
    t['\uFD08'] = '\u0636\u064A';
    t['\uFD09'] = '\u0634\u062C';
    t['\uFD0A'] = '\u0634\u062D';
    t['\uFD0B'] = '\u0634\u062E';
    t['\uFD0C'] = '\u0634\u0645';
    t['\uFD0D'] = '\u0634\u0631';
    t['\uFD0E'] = '\u0633\u0631';
    t['\uFD0F'] = '\u0635\u0631';
    t['\uFD10'] = '\u0636\u0631';
    t['\uFD11'] = '\u0637\u0649';
    t['\uFD12'] = '\u0637\u064A';
    t['\uFD13'] = '\u0639\u0649';
    t['\uFD14'] = '\u0639\u064A';
    t['\uFD15'] = '\u063A\u0649';
    t['\uFD16'] = '\u063A\u064A';
    t['\uFD17'] = '\u0633\u0649';
    t['\uFD18'] = '\u0633\u064A';
    t['\uFD19'] = '\u0634\u0649';
    t['\uFD1A'] = '\u0634\u064A';
    t['\uFD1B'] = '\u062D\u0649';
    t['\uFD1C'] = '\u062D\u064A';
    t['\uFD1D'] = '\u062C\u0649';
    t['\uFD1E'] = '\u062C\u064A';
    t['\uFD1F'] = '\u062E\u0649';
    t['\uFD20'] = '\u062E\u064A';
    t['\uFD21'] = '\u0635\u0649';
    t['\uFD22'] = '\u0635\u064A';
    t['\uFD23'] = '\u0636\u0649';
    t['\uFD24'] = '\u0636\u064A';
    t['\uFD25'] = '\u0634\u062C';
    t['\uFD26'] = '\u0634\u062D';
    t['\uFD27'] = '\u0634\u062E';
    t['\uFD28'] = '\u0634\u0645';
    t['\uFD29'] = '\u0634\u0631';
    t['\uFD2A'] = '\u0633\u0631';
    t['\uFD2B'] = '\u0635\u0631';
    t['\uFD2C'] = '\u0636\u0631';
    t['\uFD2D'] = '\u0634\u062C';
    t['\uFD2E'] = '\u0634\u062D';
    t['\uFD2F'] = '\u0634\u062E';
    t['\uFD30'] = '\u0634\u0645';
    t['\uFD31'] = '\u0633\u0647';
    t['\uFD32'] = '\u0634\u0647';
    t['\uFD33'] = '\u0637\u0645';
    t['\uFD34'] = '\u0633\u062C';
    t['\uFD35'] = '\u0633\u062D';
    t['\uFD36'] = '\u0633\u062E';
    t['\uFD37'] = '\u0634\u062C';
    t['\uFD38'] = '\u0634\u062D';
    t['\uFD39'] = '\u0634\u062E';
    t['\uFD3A'] = '\u0637\u0645';
    t['\uFD3B'] = '\u0638\u0645';
    t['\uFD3C'] = '\u0627\u064B';
    t['\uFD3D'] = '\u0627\u064B';
    t['\uFD50'] = '\u062A\u062C\u0645';
    t['\uFD51'] = '\u062A\u062D\u062C';
    t['\uFD52'] = '\u062A\u062D\u062C';
    t['\uFD53'] = '\u062A\u062D\u0645';
    t['\uFD54'] = '\u062A\u062E\u0645';
    t['\uFD55'] = '\u062A\u0645\u062C';
    t['\uFD56'] = '\u062A\u0645\u062D';
    t['\uFD57'] = '\u062A\u0645\u062E';
    t['\uFD58'] = '\u062C\u0645\u062D';
    t['\uFD59'] = '\u062C\u0645\u062D';
    t['\uFD5A'] = '\u062D\u0645\u064A';
    t['\uFD5B'] = '\u062D\u0645\u0649';
    t['\uFD5C'] = '\u0633\u062D\u062C';
    t['\uFD5D'] = '\u0633\u062C\u062D';
    t['\uFD5E'] = '\u0633\u062C\u0649';
    t['\uFD5F'] = '\u0633\u0645\u062D';
    t['\uFD60'] = '\u0633\u0645\u062D';
    t['\uFD61'] = '\u0633\u0645\u062C';
    t['\uFD62'] = '\u0633\u0645\u0645';
    t['\uFD63'] = '\u0633\u0645\u0645';
    t['\uFD64'] = '\u0635\u062D\u062D';
    t['\uFD65'] = '\u0635\u062D\u062D';
    t['\uFD66'] = '\u0635\u0645\u0645';
    t['\uFD67'] = '\u0634\u062D\u0645';
    t['\uFD68'] = '\u0634\u062D\u0645';
    t['\uFD69'] = '\u0634\u062C\u064A';
    t['\uFD6A'] = '\u0634\u0645\u062E';
    t['\uFD6B'] = '\u0634\u0645\u062E';
    t['\uFD6C'] = '\u0634\u0645\u0645';
    t['\uFD6D'] = '\u0634\u0645\u0645';
    t['\uFD6E'] = '\u0636\u062D\u0649';
    t['\uFD6F'] = '\u0636\u062E\u0645';
    t['\uFD70'] = '\u0636\u062E\u0645';
    t['\uFD71'] = '\u0637\u0645\u062D';
    t['\uFD72'] = '\u0637\u0645\u062D';
    t['\uFD73'] = '\u0637\u0645\u0645';
    t['\uFD74'] = '\u0637\u0645\u064A';
    t['\uFD75'] = '\u0639\u062C\u0645';
    t['\uFD76'] = '\u0639\u0645\u0645';
    t['\uFD77'] = '\u0639\u0645\u0645';
    t['\uFD78'] = '\u0639\u0645\u0649';
    t['\uFD79'] = '\u063A\u0645\u0645';
    t['\uFD7A'] = '\u063A\u0645\u064A';
    t['\uFD7B'] = '\u063A\u0645\u0649';
    t['\uFD7C'] = '\u0641\u062E\u0645';
    t['\uFD7D'] = '\u0641\u062E\u0645';
    t['\uFD7E'] = '\u0642\u0645\u062D';
    t['\uFD7F'] = '\u0642\u0645\u0645';
    t['\uFD80'] = '\u0644\u062D\u0645';
    t['\uFD81'] = '\u0644\u062D\u064A';
    t['\uFD82'] = '\u0644\u062D\u0649';
    t['\uFD83'] = '\u0644\u062C\u062C';
    t['\uFD84'] = '\u0644\u062C\u062C';
    t['\uFD85'] = '\u0644\u062E\u0645';
    t['\uFD86'] = '\u0644\u062E\u0645';
    t['\uFD87'] = '\u0644\u0645\u062D';
    t['\uFD88'] = '\u0644\u0645\u062D';
    t['\uFD89'] = '\u0645\u062D\u062C';
    t['\uFD8A'] = '\u0645\u062D\u0645';
    t['\uFD8B'] = '\u0645\u062D\u064A';
    t['\uFD8C'] = '\u0645\u062C\u062D';
    t['\uFD8D'] = '\u0645\u062C\u0645';
    t['\uFD8E'] = '\u0645\u062E\u062C';
    t['\uFD8F'] = '\u0645\u062E\u0645';
    t['\uFD92'] = '\u0645\u062C\u062E';
    t['\uFD93'] = '\u0647\u0645\u062C';
    t['\uFD94'] = '\u0647\u0645\u0645';
    t['\uFD95'] = '\u0646\u062D\u0645';
    t['\uFD96'] = '\u0646\u062D\u0649';
    t['\uFD97'] = '\u0646\u062C\u0645';
    t['\uFD98'] = '\u0646\u062C\u0645';
    t['\uFD99'] = '\u0646\u062C\u0649';
    t['\uFD9A'] = '\u0646\u0645\u064A';
    t['\uFD9B'] = '\u0646\u0645\u0649';
    t['\uFD9C'] = '\u064A\u0645\u0645';
    t['\uFD9D'] = '\u064A\u0645\u0645';
    t['\uFD9E'] = '\u0628\u062E\u064A';
    t['\uFD9F'] = '\u062A\u062C\u064A';
    t['\uFDA0'] = '\u062A\u062C\u0649';
    t['\uFDA1'] = '\u062A\u062E\u064A';
    t['\uFDA2'] = '\u062A\u062E\u0649';
    t['\uFDA3'] = '\u062A\u0645\u064A';
    t['\uFDA4'] = '\u062A\u0645\u0649';
    t['\uFDA5'] = '\u062C\u0645\u064A';
    t['\uFDA6'] = '\u062C\u062D\u0649';
    t['\uFDA7'] = '\u062C\u0645\u0649';
    t['\uFDA8'] = '\u0633\u062E\u0649';
    t['\uFDA9'] = '\u0635\u062D\u064A';
    t['\uFDAA'] = '\u0634\u062D\u064A';
    t['\uFDAB'] = '\u0636\u062D\u064A';
    t['\uFDAC'] = '\u0644\u062C\u064A';
    t['\uFDAD'] = '\u0644\u0645\u064A';
    t['\uFDAE'] = '\u064A\u062D\u064A';
    t['\uFDAF'] = '\u064A\u062C\u064A';
    t['\uFDB0'] = '\u064A\u0645\u064A';
    t['\uFDB1'] = '\u0645\u0645\u064A';
    t['\uFDB2'] = '\u0642\u0645\u064A';
    t['\uFDB3'] = '\u0646\u062D\u064A';
    t['\uFDB4'] = '\u0642\u0645\u062D';
    t['\uFDB5'] = '\u0644\u062D\u0645';
    t['\uFDB6'] = '\u0639\u0645\u064A';
    t['\uFDB7'] = '\u0643\u0645\u064A';
    t['\uFDB8'] = '\u0646\u062C\u062D';
    t['\uFDB9'] = '\u0645\u062E\u064A';
    t['\uFDBA'] = '\u0644\u062C\u0645';
    t['\uFDBB'] = '\u0643\u0645\u0645';
    t['\uFDBC'] = '\u0644\u062C\u0645';
    t['\uFDBD'] = '\u0646\u062C\u062D';
    t['\uFDBE'] = '\u062C\u062D\u064A';
    t['\uFDBF'] = '\u062D\u062C\u064A';
    t['\uFDC0'] = '\u0645\u062C\u064A';
    t['\uFDC1'] = '\u0641\u0645\u064A';
    t['\uFDC2'] = '\u0628\u062D\u064A';
    t['\uFDC3'] = '\u0643\u0645\u0645';
    t['\uFDC4'] = '\u0639\u062C\u0645';
    t['\uFDC5'] = '\u0635\u0645\u0645';
    t['\uFDC6'] = '\u0633\u062E\u064A';
    t['\uFDC7'] = '\u0646\u062C\u064A';
    t['\uFE49'] = '\u203E';
    t['\uFE4A'] = '\u203E';
    t['\uFE4B'] = '\u203E';
    t['\uFE4C'] = '\u203E';
    t['\uFE4D'] = '\u005F';
    t['\uFE4E'] = '\u005F';
    t['\uFE4F'] = '\u005F';
    t['\uFE80'] = '\u0621';
    t['\uFE81'] = '\u0622';
    t['\uFE82'] = '\u0622';
    t['\uFE83'] = '\u0623';
    t['\uFE84'] = '\u0623';
    t['\uFE85'] = '\u0624';
    t['\uFE86'] = '\u0624';
    t['\uFE87'] = '\u0625';
    t['\uFE88'] = '\u0625';
    t['\uFE89'] = '\u0626';
    t['\uFE8A'] = '\u0626';
    t['\uFE8B'] = '\u0626';
    t['\uFE8C'] = '\u0626';
    t['\uFE8D'] = '\u0627';
    t['\uFE8E'] = '\u0627';
    t['\uFE8F'] = '\u0628';
    t['\uFE90'] = '\u0628';
    t['\uFE91'] = '\u0628';
    t['\uFE92'] = '\u0628';
    t['\uFE93'] = '\u0629';
    t['\uFE94'] = '\u0629';
    t['\uFE95'] = '\u062A';
    t['\uFE96'] = '\u062A';
    t['\uFE97'] = '\u062A';
    t['\uFE98'] = '\u062A';
    t['\uFE99'] = '\u062B';
    t['\uFE9A'] = '\u062B';
    t['\uFE9B'] = '\u062B';
    t['\uFE9C'] = '\u062B';
    t['\uFE9D'] = '\u062C';
    t['\uFE9E'] = '\u062C';
    t['\uFE9F'] = '\u062C';
    t['\uFEA0'] = '\u062C';
    t['\uFEA1'] = '\u062D';
    t['\uFEA2'] = '\u062D';
    t['\uFEA3'] = '\u062D';
    t['\uFEA4'] = '\u062D';
    t['\uFEA5'] = '\u062E';
    t['\uFEA6'] = '\u062E';
    t['\uFEA7'] = '\u062E';
    t['\uFEA8'] = '\u062E';
    t['\uFEA9'] = '\u062F';
    t['\uFEAA'] = '\u062F';
    t['\uFEAB'] = '\u0630';
    t['\uFEAC'] = '\u0630';
    t['\uFEAD'] = '\u0631';
    t['\uFEAE'] = '\u0631';
    t['\uFEAF'] = '\u0632';
    t['\uFEB0'] = '\u0632';
    t['\uFEB1'] = '\u0633';
    t['\uFEB2'] = '\u0633';
    t['\uFEB3'] = '\u0633';
    t['\uFEB4'] = '\u0633';
    t['\uFEB5'] = '\u0634';
    t['\uFEB6'] = '\u0634';
    t['\uFEB7'] = '\u0634';
    t['\uFEB8'] = '\u0634';
    t['\uFEB9'] = '\u0635';
    t['\uFEBA'] = '\u0635';
    t['\uFEBB'] = '\u0635';
    t['\uFEBC'] = '\u0635';
    t['\uFEBD'] = '\u0636';
    t['\uFEBE'] = '\u0636';
    t['\uFEBF'] = '\u0636';
    t['\uFEC0'] = '\u0636';
    t['\uFEC1'] = '\u0637';
    t['\uFEC2'] = '\u0637';
    t['\uFEC3'] = '\u0637';
    t['\uFEC4'] = '\u0637';
    t['\uFEC5'] = '\u0638';
    t['\uFEC6'] = '\u0638';
    t['\uFEC7'] = '\u0638';
    t['\uFEC8'] = '\u0638';
    t['\uFEC9'] = '\u0639';
    t['\uFECA'] = '\u0639';
    t['\uFECB'] = '\u0639';
    t['\uFECC'] = '\u0639';
    t['\uFECD'] = '\u063A';
    t['\uFECE'] = '\u063A';
    t['\uFECF'] = '\u063A';
    t['\uFED0'] = '\u063A';
    t['\uFED1'] = '\u0641';
    t['\uFED2'] = '\u0641';
    t['\uFED3'] = '\u0641';
    t['\uFED4'] = '\u0641';
    t['\uFED5'] = '\u0642';
    t['\uFED6'] = '\u0642';
    t['\uFED7'] = '\u0642';
    t['\uFED8'] = '\u0642';
    t['\uFED9'] = '\u0643';
    t['\uFEDA'] = '\u0643';
    t['\uFEDB'] = '\u0643';
    t['\uFEDC'] = '\u0643';
    t['\uFEDD'] = '\u0644';
    t['\uFEDE'] = '\u0644';
    t['\uFEDF'] = '\u0644';
    t['\uFEE0'] = '\u0644';
    t['\uFEE1'] = '\u0645';
    t['\uFEE2'] = '\u0645';
    t['\uFEE3'] = '\u0645';
    t['\uFEE4'] = '\u0645';
    t['\uFEE5'] = '\u0646';
    t['\uFEE6'] = '\u0646';
    t['\uFEE7'] = '\u0646';
    t['\uFEE8'] = '\u0646';
    t['\uFEE9'] = '\u0647';
    t['\uFEEA'] = '\u0647';
    t['\uFEEB'] = '\u0647';
    t['\uFEEC'] = '\u0647';
    t['\uFEED'] = '\u0648';
    t['\uFEEE'] = '\u0648';
    t['\uFEEF'] = '\u0649';
    t['\uFEF0'] = '\u0649';
    t['\uFEF1'] = '\u064A';
    t['\uFEF2'] = '\u064A';
    t['\uFEF3'] = '\u064A';
    t['\uFEF4'] = '\u064A';
    t['\uFEF5'] = '\u0644\u0622';
    t['\uFEF6'] = '\u0644\u0622';
    t['\uFEF7'] = '\u0644\u0623';
    t['\uFEF8'] = '\u0644\u0623';
    t['\uFEF9'] = '\u0644\u0625';
    t['\uFEFA'] = '\u0644\u0625';
    t['\uFEFB'] = '\u0644\u0627';
    t['\uFEFC'] = '\u0644\u0627';
   });
   function reverseIfRtl(chars) {
    var charsLength = chars.length;
    if (charsLength <= 1 || !isRTLRangeFor(chars.charCodeAt(0))) {
     return chars;
    }
    var s = '';
    for (var ii = charsLength - 1; ii >= 0; ii--) {
     s += chars[ii];
    }
    return s;
   }
   exports.mapSpecialUnicodeValues = mapSpecialUnicodeValues;
   exports.reverseIfRtl = reverseIfRtl;
   exports.getUnicodeRangeFor = getUnicodeRangeFor;
   exports.getNormalizedUnicodes = getNormalizedUnicodes;
   exports.getUnicodeForGlyph = getUnicodeForGlyph;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayDOMUtils = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var removeNullCharacters = sharedUtil.removeNullCharacters;
   var warn = sharedUtil.warn;
   var deprecated = sharedUtil.deprecated;
   var createValidAbsoluteUrl = sharedUtil.createValidAbsoluteUrl;
   var CustomStyle = function CustomStyleClosure() {
    var prefixes = [
     'ms',
     'Moz',
     'Webkit',
     'O'
    ];
    var _cache = Object.create(null);
    function CustomStyle() {
    }
    CustomStyle.getProp = function get(propName, element) {
     if (arguments.length === 1 && typeof _cache[propName] === 'string') {
      return _cache[propName];
     }
     element = element || document.documentElement;
     var style = element.style, prefixed, uPropName;
     if (typeof style[propName] === 'string') {
      return _cache[propName] = propName;
     }
     uPropName = propName.charAt(0).toUpperCase() + propName.slice(1);
     for (var i = 0, l = prefixes.length; i < l; i++) {
      prefixed = prefixes[i] + uPropName;
      if (typeof style[prefixed] === 'string') {
       return _cache[propName] = prefixed;
      }
     }
     return _cache[propName] = 'undefined';
    };
    CustomStyle.setProp = function set(propName, element, str) {
     var prop = this.getProp(propName);
     if (prop !== 'undefined') {
      element.style[prop] = str;
     }
    };
    return CustomStyle;
   }();
   var hasCanvasTypedArrays;
   hasCanvasTypedArrays = function hasCanvasTypedArrays() {
    var canvas = document.createElement('canvas');
    canvas.width = canvas.height = 1;
    var ctx = canvas.getContext('2d');
    var imageData = ctx.createImageData(1, 1);
    return typeof imageData.data.buffer !== 'undefined';
   };
   var LinkTarget = {
    NONE: 0,
    SELF: 1,
    BLANK: 2,
    PARENT: 3,
    TOP: 4
   };
   var LinkTargetStringMap = [
    '',
    '_self',
    '_blank',
    '_parent',
    '_top'
   ];
   function addLinkAttributes(link, params) {
    var url = params && params.url;
    link.href = link.title = url ? removeNullCharacters(url) : '';
    if (url) {
     var target = params.target;
     if (typeof target === 'undefined') {
      target = getDefaultSetting('externalLinkTarget');
     }
     link.target = LinkTargetStringMap[target];
     var rel = params.rel;
     if (typeof rel === 'undefined') {
      rel = getDefaultSetting('externalLinkRel');
     }
     link.rel = rel;
    }
   }
   function getFilenameFromUrl(url) {
    var anchor = url.indexOf('#');
    var query = url.indexOf('?');
    var end = Math.min(anchor > 0 ? anchor : url.length, query > 0 ? query : url.length);
    return url.substring(url.lastIndexOf('/', end) + 1, end);
   }
   function getDefaultSetting(id) {
    var globalSettings = sharedUtil.globalScope.PDFJS;
    switch (id) {
    case 'pdfBug':
     return globalSettings ? globalSettings.pdfBug : false;
    case 'disableAutoFetch':
     return globalSettings ? globalSettings.disableAutoFetch : false;
    case 'disableStream':
     return globalSettings ? globalSettings.disableStream : false;
    case 'disableRange':
     return globalSettings ? globalSettings.disableRange : false;
    case 'disableFontFace':
     return globalSettings ? globalSettings.disableFontFace : false;
    case 'disableCreateObjectURL':
     return globalSettings ? globalSettings.disableCreateObjectURL : false;
    case 'disableWebGL':
     return globalSettings ? globalSettings.disableWebGL : true;
    case 'cMapUrl':
     return globalSettings ? globalSettings.cMapUrl : null;
    case 'cMapPacked':
     return globalSettings ? globalSettings.cMapPacked : false;
    case 'postMessageTransfers':
     return globalSettings ? globalSettings.postMessageTransfers : true;
    case 'workerSrc':
     return globalSettings ? globalSettings.workerSrc : null;
    case 'disableWorker':
     return globalSettings ? globalSettings.disableWorker : false;
    case 'maxImageSize':
     return globalSettings ? globalSettings.maxImageSize : -1;
    case 'imageResourcesPath':
     return globalSettings ? globalSettings.imageResourcesPath : '';
    case 'isEvalSupported':
     return globalSettings ? globalSettings.isEvalSupported : true;
    case 'externalLinkTarget':
     if (!globalSettings) {
      return LinkTarget.NONE;
     }
     switch (globalSettings.externalLinkTarget) {
     case LinkTarget.NONE:
     case LinkTarget.SELF:
     case LinkTarget.BLANK:
     case LinkTarget.PARENT:
     case LinkTarget.TOP:
      return globalSettings.externalLinkTarget;
     }
     warn('PDFJS.externalLinkTarget is invalid: ' + globalSettings.externalLinkTarget);
     globalSettings.externalLinkTarget = LinkTarget.NONE;
     return LinkTarget.NONE;
    case 'externalLinkRel':
     return globalSettings ? globalSettings.externalLinkRel : 'noreferrer';
    case 'enableStats':
     return !!(globalSettings && globalSettings.enableStats);
    default:
     throw new Error('Unknown default setting: ' + id);
    }
   }
   function isExternalLinkTargetSet() {
    var externalLinkTarget = getDefaultSetting('externalLinkTarget');
    switch (externalLinkTarget) {
    case LinkTarget.NONE:
     return false;
    case LinkTarget.SELF:
    case LinkTarget.BLANK:
    case LinkTarget.PARENT:
    case LinkTarget.TOP:
     return true;
    }
   }
   function isValidUrl(url, allowRelative) {
    deprecated('isValidUrl(), please use createValidAbsoluteUrl() instead.');
    var baseUrl = allowRelative ? 'http://example.com' : null;
    return createValidAbsoluteUrl(url, baseUrl) !== null;
   }
   exports.CustomStyle = CustomStyle;
   exports.addLinkAttributes = addLinkAttributes;
   exports.isExternalLinkTargetSet = isExternalLinkTargetSet;
   exports.isValidUrl = isValidUrl;
   exports.getFilenameFromUrl = getFilenameFromUrl;
   exports.LinkTarget = LinkTarget;
   exports.hasCanvasTypedArrays = hasCanvasTypedArrays;
   exports.getDefaultSetting = getDefaultSetting;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayFontLoader = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var assert = sharedUtil.assert;
   var bytesToString = sharedUtil.bytesToString;
   var string32 = sharedUtil.string32;
   var shadow = sharedUtil.shadow;
   var warn = sharedUtil.warn;
   function FontLoader(docId) {
    this.docId = docId;
    this.styleElement = null;
    this.nativeFontFaces = [];
    this.loadTestFontId = 0;
    this.loadingContext = {
     requests: [],
     nextRequestId: 0
    };
   }
   FontLoader.prototype = {
    insertRule: function fontLoaderInsertRule(rule) {
     var styleElement = this.styleElement;
     if (!styleElement) {
      styleElement = this.styleElement = document.createElement('style');
      styleElement.id = 'PDFJS_FONT_STYLE_TAG_' + this.docId;
      document.documentElement.getElementsByTagName('head')[0].appendChild(styleElement);
     }
     var styleSheet = styleElement.sheet;
     styleSheet.insertRule(rule, styleSheet.cssRules.length);
    },
    clear: function fontLoaderClear() {
     var styleElement = this.styleElement;
     if (styleElement) {
      styleElement.parentNode.removeChild(styleElement);
      styleElement = this.styleElement = null;
     }
     this.nativeFontFaces.forEach(function (nativeFontFace) {
      document.fonts.delete(nativeFontFace);
     });
     this.nativeFontFaces.length = 0;
    }
   };
   var getLoadTestFont = function () {
    return atob('T1RUTwALAIAAAwAwQ0ZGIDHtZg4AAAOYAAAAgUZGVE1lkzZwAAAEHAAAABxHREVGABQAFQ' + 'AABDgAAAAeT1MvMlYNYwkAAAEgAAAAYGNtYXABDQLUAAACNAAAAUJoZWFk/xVFDQAAALwA' + 'AAA2aGhlYQdkA+oAAAD0AAAAJGhtdHgD6AAAAAAEWAAAAAZtYXhwAAJQAAAAARgAAAAGbm' + 'FtZVjmdH4AAAGAAAAAsXBvc3T/hgAzAAADeAAAACAAAQAAAAEAALZRFsRfDzz1AAsD6AAA' + 'AADOBOTLAAAAAM4KHDwAAAAAA+gDIQAAAAgAAgAAAAAAAAABAAADIQAAAFoD6AAAAAAD6A' + 'ABAAAAAAAAAAAAAAAAAAAAAQAAUAAAAgAAAAQD6AH0AAUAAAKKArwAAACMAooCvAAAAeAA' + 'MQECAAACAAYJAAAAAAAAAAAAAQAAAAAAAAAAAAAAAFBmRWQAwAAuAC4DIP84AFoDIQAAAA' + 'AAAQAAAAAAAAAAACAAIAABAAAADgCuAAEAAAAAAAAAAQAAAAEAAAAAAAEAAQAAAAEAAAAA' + 'AAIAAQAAAAEAAAAAAAMAAQAAAAEAAAAAAAQAAQAAAAEAAAAAAAUAAQAAAAEAAAAAAAYAAQ' + 'AAAAMAAQQJAAAAAgABAAMAAQQJAAEAAgABAAMAAQQJAAIAAgABAAMAAQQJAAMAAgABAAMA' + 'AQQJAAQAAgABAAMAAQQJAAUAAgABAAMAAQQJAAYAAgABWABYAAAAAAAAAwAAAAMAAAAcAA' + 'EAAAAAADwAAwABAAAAHAAEACAAAAAEAAQAAQAAAC7//wAAAC7////TAAEAAAAAAAABBgAA' + 'AQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAAAAAA' + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMAAA' + 'AAAAD/gwAyAAAAAQAAAAAAAAAAAAAAAAAAAAABAAQEAAEBAQJYAAEBASH4DwD4GwHEAvgc' + 'A/gXBIwMAYuL+nz5tQXkD5j3CBLnEQACAQEBIVhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWF' + 'hYWFhYWFhYAAABAQAADwACAQEEE/t3Dov6fAH6fAT+fPp8+nwHDosMCvm1Cvm1DAz6fBQA' + 'AAAAAAABAAAAAMmJbzEAAAAAzgTjFQAAAADOBOQpAAEAAAAAAAAADAAUAAQAAAABAAAAAg' + 'ABAAAAAAAAAAAD6AAAAAAAAA==');
   };
   Object.defineProperty(FontLoader.prototype, 'loadTestFont', {
    get: function () {
     return shadow(this, 'loadTestFont', getLoadTestFont());
    },
    configurable: true
   });
   FontLoader.prototype.addNativeFontFace = function fontLoader_addNativeFontFace(nativeFontFace) {
    this.nativeFontFaces.push(nativeFontFace);
    document.fonts.add(nativeFontFace);
   };
   FontLoader.prototype.bind = function fontLoaderBind(fonts, callback) {
    var rules = [];
    var fontsToLoad = [];
    var fontLoadPromises = [];
    var getNativeFontPromise = function (nativeFontFace) {
     return nativeFontFace.loaded.catch(function (e) {
      warn('Failed to load font "' + nativeFontFace.family + '": ' + e);
     });
    };
    var isFontLoadingAPISupported = FontLoader.isFontLoadingAPISupported && !FontLoader.isSyncFontLoadingSupported;
    for (var i = 0, ii = fonts.length; i < ii; i++) {
     var font = fonts[i];
     if (font.attached || font.loading === false) {
      continue;
     }
     font.attached = true;
     if (isFontLoadingAPISupported) {
      var nativeFontFace = font.createNativeFontFace();
      if (nativeFontFace) {
       this.addNativeFontFace(nativeFontFace);
       fontLoadPromises.push(getNativeFontPromise(nativeFontFace));
      }
     } else {
      var rule = font.createFontFaceRule();
      if (rule) {
       this.insertRule(rule);
       rules.push(rule);
       fontsToLoad.push(font);
      }
     }
    }
    var request = this.queueLoadingCallback(callback);
    if (isFontLoadingAPISupported) {
     Promise.all(fontLoadPromises).then(function () {
      request.complete();
     });
    } else if (rules.length > 0 && !FontLoader.isSyncFontLoadingSupported) {
     this.prepareFontLoadEvent(rules, fontsToLoad, request);
    } else {
     request.complete();
    }
   };
   FontLoader.prototype.queueLoadingCallback = function FontLoader_queueLoadingCallback(callback) {
    function LoadLoader_completeRequest() {
     assert(!request.end, 'completeRequest() cannot be called twice');
     request.end = Date.now();
     while (context.requests.length > 0 && context.requests[0].end) {
      var otherRequest = context.requests.shift();
      setTimeout(otherRequest.callback, 0);
     }
    }
    var context = this.loadingContext;
    var requestId = 'pdfjs-font-loading-' + context.nextRequestId++;
    var request = {
     id: requestId,
     complete: LoadLoader_completeRequest,
     callback: callback,
     started: Date.now()
    };
    context.requests.push(request);
    return request;
   };
   FontLoader.prototype.prepareFontLoadEvent = function fontLoaderPrepareFontLoadEvent(rules, fonts, request) {
    function int32(data, offset) {
     return data.charCodeAt(offset) << 24 | data.charCodeAt(offset + 1) << 16 | data.charCodeAt(offset + 2) << 8 | data.charCodeAt(offset + 3) & 0xff;
    }
    function spliceString(s, offset, remove, insert) {
     var chunk1 = s.substr(0, offset);
     var chunk2 = s.substr(offset + remove);
     return chunk1 + insert + chunk2;
    }
    var i, ii;
    var canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = 1;
    var ctx = canvas.getContext('2d');
    var called = 0;
    function isFontReady(name, callback) {
     called++;
     if (called > 30) {
      warn('Load test font never loaded.');
      callback();
      return;
     }
     ctx.font = '30px ' + name;
     ctx.fillText('.', 0, 20);
     var imageData = ctx.getImageData(0, 0, 1, 1);
     if (imageData.data[3] > 0) {
      callback();
      return;
     }
     setTimeout(isFontReady.bind(null, name, callback));
    }
    var loadTestFontId = 'lt' + Date.now() + this.loadTestFontId++;
    var data = this.loadTestFont;
    var COMMENT_OFFSET = 976;
    data = spliceString(data, COMMENT_OFFSET, loadTestFontId.length, loadTestFontId);
    var CFF_CHECKSUM_OFFSET = 16;
    var XXXX_VALUE = 0x58585858;
    var checksum = int32(data, CFF_CHECKSUM_OFFSET);
    for (i = 0, ii = loadTestFontId.length - 3; i < ii; i += 4) {
     checksum = checksum - XXXX_VALUE + int32(loadTestFontId, i) | 0;
    }
    if (i < loadTestFontId.length) {
     checksum = checksum - XXXX_VALUE + int32(loadTestFontId + 'XXX', i) | 0;
    }
    data = spliceString(data, CFF_CHECKSUM_OFFSET, 4, string32(checksum));
    var url = 'url(data:font/opentype;base64,' + btoa(data) + ');';
    var rule = '@font-face { font-family:"' + loadTestFontId + '";src:' + url + '}';
    this.insertRule(rule);
    var names = [];
    for (i = 0, ii = fonts.length; i < ii; i++) {
     names.push(fonts[i].loadedName);
    }
    names.push(loadTestFontId);
    var div = document.createElement('div');
    div.setAttribute('style', 'visibility: hidden;' + 'width: 10px; height: 10px;' + 'position: absolute; top: 0px; left: 0px;');
    for (i = 0, ii = names.length; i < ii; ++i) {
     var span = document.createElement('span');
     span.textContent = 'Hi';
     span.style.fontFamily = names[i];
     div.appendChild(span);
    }
    document.body.appendChild(div);
    isFontReady(loadTestFontId, function () {
     document.body.removeChild(div);
     request.complete();
    });
   };
   FontLoader.isFontLoadingAPISupported = typeof document !== 'undefined' && !!document.fonts;
   var isSyncFontLoadingSupported = function isSyncFontLoadingSupported() {
    if (typeof navigator === 'undefined') {
     return true;
    }
    var supported = false;
    var m = /Mozilla\/5.0.*?rv:(\d+).*? Gecko/.exec(navigator.userAgent);
    if (m && m[1] >= 14) {
     supported = true;
    }
    return supported;
   };
   Object.defineProperty(FontLoader, 'isSyncFontLoadingSupported', {
    get: function () {
     return shadow(FontLoader, 'isSyncFontLoadingSupported', isSyncFontLoadingSupported());
    },
    enumerable: true,
    configurable: true
   });
   var IsEvalSupportedCached = {
    get value() {
     return shadow(this, 'value', sharedUtil.isEvalSupported());
    }
   };
   var FontFaceObject = function FontFaceObjectClosure() {
    function FontFaceObject(translatedData, options) {
     this.compiledGlyphs = Object.create(null);
     for (var i in translatedData) {
      this[i] = translatedData[i];
     }
     this.options = options;
    }
    FontFaceObject.prototype = {
     createNativeFontFace: function FontFaceObject_createNativeFontFace() {
      if (!this.data) {
       return null;
      }
      if (this.options.disableFontFace) {
       this.disableFontFace = true;
       return null;
      }
      var nativeFontFace = new FontFace(this.loadedName, this.data, {});
      if (this.options.fontRegistry) {
       this.options.fontRegistry.registerFont(this);
      }
      return nativeFontFace;
     },
     createFontFaceRule: function FontFaceObject_createFontFaceRule() {
      if (!this.data) {
       return null;
      }
      if (this.options.disableFontFace) {
       this.disableFontFace = true;
       return null;
      }
      var data = bytesToString(new Uint8Array(this.data));
      var fontName = this.loadedName;
      var url = 'url(data:' + this.mimetype + ';base64,' + btoa(data) + ');';
      var rule = '@font-face { font-family:"' + fontName + '";src:' + url + '}';
      if (this.options.fontRegistry) {
       this.options.fontRegistry.registerFont(this, url);
      }
      return rule;
     },
     getPathGenerator: function FontFaceObject_getPathGenerator(objs, character) {
      if (!(character in this.compiledGlyphs)) {
       var cmds = objs.get(this.loadedName + '_path_' + character);
       var current, i, len;
       if (this.options.isEvalSupported && IsEvalSupportedCached.value) {
        var args, js = '';
        for (i = 0, len = cmds.length; i < len; i++) {
         current = cmds[i];
         if (current.args !== undefined) {
          args = current.args.join(',');
         } else {
          args = '';
         }
         js += 'c.' + current.cmd + '(' + args + ');\n';
        }
        this.compiledGlyphs[character] = new Function('c', 'size', js);
       } else {
        this.compiledGlyphs[character] = function (c, size) {
         for (i = 0, len = cmds.length; i < len; i++) {
          current = cmds[i];
          if (current.cmd === 'scale') {
           current.args = [
            size,
            -size
           ];
          }
          c[current.cmd].apply(c, current.args);
         }
        };
       }
      }
      return this.compiledGlyphs[character];
     }
    };
    return FontFaceObject;
   }();
   exports.FontFaceObject = FontFaceObject;
   exports.FontLoader = FontLoader;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayMetadata = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var error = sharedUtil.error;
   function fixMetadata(meta) {
    return meta.replace(/>\\376\\377([^<]+)/g, function (all, codes) {
     var bytes = codes.replace(/\\([0-3])([0-7])([0-7])/g, function (code, d1, d2, d3) {
      return String.fromCharCode(d1 * 64 + d2 * 8 + d3 * 1);
     });
     var chars = '';
     for (var i = 0; i < bytes.length; i += 2) {
      var code = bytes.charCodeAt(i) * 256 + bytes.charCodeAt(i + 1);
      chars += code >= 32 && code < 127 && code !== 60 && code !== 62 && code !== 38 ? String.fromCharCode(code) : '&#x' + (0x10000 + code).toString(16).substring(1) + ';';
     }
     return '>' + chars;
    });
   }
   function Metadata(meta) {
    if (typeof meta === 'string') {
     meta = fixMetadata(meta);
     var parser = new DOMParser();
     meta = parser.parseFromString(meta, 'application/xml');
    } else if (!(meta instanceof Document)) {
     error('Metadata: Invalid metadata object');
    }
    this.metaDocument = meta;
    this.metadata = Object.create(null);
    this.parse();
   }
   Metadata.prototype = {
    parse: function Metadata_parse() {
     var doc = this.metaDocument;
     var rdf = doc.documentElement;
     if (rdf.nodeName.toLowerCase() !== 'rdf:rdf') {
      rdf = rdf.firstChild;
      while (rdf && rdf.nodeName.toLowerCase() !== 'rdf:rdf') {
       rdf = rdf.nextSibling;
      }
     }
     var nodeName = rdf ? rdf.nodeName.toLowerCase() : null;
     if (!rdf || nodeName !== 'rdf:rdf' || !rdf.hasChildNodes()) {
      return;
     }
     var children = rdf.childNodes, desc, entry, name, i, ii, length, iLength;
     for (i = 0, length = children.length; i < length; i++) {
      desc = children[i];
      if (desc.nodeName.toLowerCase() !== 'rdf:description') {
       continue;
      }
      for (ii = 0, iLength = desc.childNodes.length; ii < iLength; ii++) {
       if (desc.childNodes[ii].nodeName.toLowerCase() !== '#text') {
        entry = desc.childNodes[ii];
        name = entry.nodeName.toLowerCase();
        this.metadata[name] = entry.textContent.trim();
       }
      }
     }
    },
    get: function Metadata_get(name) {
     return this.metadata[name] || null;
    },
    has: function Metadata_has(name) {
     return typeof this.metadata[name] !== 'undefined';
    }
   };
   exports.Metadata = Metadata;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplaySVG = {}, root.pdfjsSharedUtil);
  }(this, function (exports, sharedUtil) {
   var FONT_IDENTITY_MATRIX = sharedUtil.FONT_IDENTITY_MATRIX;
   var IDENTITY_MATRIX = sharedUtil.IDENTITY_MATRIX;
   var ImageKind = sharedUtil.ImageKind;
   var OPS = sharedUtil.OPS;
   var Util = sharedUtil.Util;
   var isNum = sharedUtil.isNum;
   var isArray = sharedUtil.isArray;
   var warn = sharedUtil.warn;
   var createObjectURL = sharedUtil.createObjectURL;
   var SVG_DEFAULTS = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fillColor: '#000000'
   };
   var convertImgDataToPng = function convertImgDataToPngClosure() {
    var PNG_HEADER = new Uint8Array([
     0x89,
     0x50,
     0x4e,
     0x47,
     0x0d,
     0x0a,
     0x1a,
     0x0a
    ]);
    var CHUNK_WRAPPER_SIZE = 12;
    var crcTable = new Int32Array(256);
    for (var i = 0; i < 256; i++) {
     var c = i;
     for (var h = 0; h < 8; h++) {
      if (c & 1) {
       c = 0xedB88320 ^ c >> 1 & 0x7fffffff;
      } else {
       c = c >> 1 & 0x7fffffff;
      }
     }
     crcTable[i] = c;
    }
    function crc32(data, start, end) {
     var crc = -1;
     for (var i = start; i < end; i++) {
      var a = (crc ^ data[i]) & 0xff;
      var b = crcTable[a];
      crc = crc >>> 8 ^ b;
     }
     return crc ^ -1;
    }
    function writePngChunk(type, body, data, offset) {
     var p = offset;
     var len = body.length;
     data[p] = len >> 24 & 0xff;
     data[p + 1] = len >> 16 & 0xff;
     data[p + 2] = len >> 8 & 0xff;
     data[p + 3] = len & 0xff;
     p += 4;
     data[p] = type.charCodeAt(0) & 0xff;
     data[p + 1] = type.charCodeAt(1) & 0xff;
     data[p + 2] = type.charCodeAt(2) & 0xff;
     data[p + 3] = type.charCodeAt(3) & 0xff;
     p += 4;
     data.set(body, p);
     p += body.length;
     var crc = crc32(data, offset + 4, p);
     data[p] = crc >> 24 & 0xff;
     data[p + 1] = crc >> 16 & 0xff;
     data[p + 2] = crc >> 8 & 0xff;
     data[p + 3] = crc & 0xff;
    }
    function adler32(data, start, end) {
     var a = 1;
     var b = 0;
     for (var i = start; i < end; ++i) {
      a = (a + (data[i] & 0xff)) % 65521;
      b = (b + a) % 65521;
     }
     return b << 16 | a;
    }
    function encode(imgData, kind, forceDataSchema) {
     var width = imgData.width;
     var height = imgData.height;
     var bitDepth, colorType, lineSize;
     var bytes = imgData.data;
     switch (kind) {
     case ImageKind.GRAYSCALE_1BPP:
      colorType = 0;
      bitDepth = 1;
      lineSize = width + 7 >> 3;
      break;
     case ImageKind.RGB_24BPP:
      colorType = 2;
      bitDepth = 8;
      lineSize = width * 3;
      break;
     case ImageKind.RGBA_32BPP:
      colorType = 6;
      bitDepth = 8;
      lineSize = width * 4;
      break;
     default:
      throw new Error('invalid format');
     }
     var literals = new Uint8Array((1 + lineSize) * height);
     var offsetLiterals = 0, offsetBytes = 0;
     var y, i;
     for (y = 0; y < height; ++y) {
      literals[offsetLiterals++] = 0;
      literals.set(bytes.subarray(offsetBytes, offsetBytes + lineSize), offsetLiterals);
      offsetBytes += lineSize;
      offsetLiterals += lineSize;
     }
     if (kind === ImageKind.GRAYSCALE_1BPP) {
      offsetLiterals = 0;
      for (y = 0; y < height; y++) {
       offsetLiterals++;
       for (i = 0; i < lineSize; i++) {
        literals[offsetLiterals++] ^= 0xFF;
       }
      }
     }
     var ihdr = new Uint8Array([
      width >> 24 & 0xff,
      width >> 16 & 0xff,
      width >> 8 & 0xff,
      width & 0xff,
      height >> 24 & 0xff,
      height >> 16 & 0xff,
      height >> 8 & 0xff,
      height & 0xff,
      bitDepth,
      colorType,
      0x00,
      0x00,
      0x00
     ]);
     var len = literals.length;
     var maxBlockLength = 0xFFFF;
     var deflateBlocks = Math.ceil(len / maxBlockLength);
     var idat = new Uint8Array(2 + len + deflateBlocks * 5 + 4);
     var pi = 0;
     idat[pi++] = 0x78;
     idat[pi++] = 0x9c;
     var pos = 0;
     while (len > maxBlockLength) {
      idat[pi++] = 0x00;
      idat[pi++] = 0xff;
      idat[pi++] = 0xff;
      idat[pi++] = 0x00;
      idat[pi++] = 0x00;
      idat.set(literals.subarray(pos, pos + maxBlockLength), pi);
      pi += maxBlockLength;
      pos += maxBlockLength;
      len -= maxBlockLength;
     }
     idat[pi++] = 0x01;
     idat[pi++] = len & 0xff;
     idat[pi++] = len >> 8 & 0xff;
     idat[pi++] = ~len & 0xffff & 0xff;
     idat[pi++] = (~len & 0xffff) >> 8 & 0xff;
     idat.set(literals.subarray(pos), pi);
     pi += literals.length - pos;
     var adler = adler32(literals, 0, literals.length);
     idat[pi++] = adler >> 24 & 0xff;
     idat[pi++] = adler >> 16 & 0xff;
     idat[pi++] = adler >> 8 & 0xff;
     idat[pi++] = adler & 0xff;
     var pngLength = PNG_HEADER.length + CHUNK_WRAPPER_SIZE * 3 + ihdr.length + idat.length;
     var data = new Uint8Array(pngLength);
     var offset = 0;
     data.set(PNG_HEADER, offset);
     offset += PNG_HEADER.length;
     writePngChunk('IHDR', ihdr, data, offset);
     offset += CHUNK_WRAPPER_SIZE + ihdr.length;
     writePngChunk('IDATA', idat, data, offset);
     offset += CHUNK_WRAPPER_SIZE + idat.length;
     writePngChunk('IEND', new Uint8Array(0), data, offset);
     return createObjectURL(data, 'image/png', forceDataSchema);
    }
    return function convertImgDataToPng(imgData, forceDataSchema) {
     var kind = imgData.kind === undefined ? ImageKind.GRAYSCALE_1BPP : imgData.kind;
     return encode(imgData, kind, forceDataSchema);
    };
   }();
   var SVGExtraState = function SVGExtraStateClosure() {
    function SVGExtraState() {
     this.fontSizeScale = 1;
     this.fontWeight = SVG_DEFAULTS.fontWeight;
     this.fontSize = 0;
     this.textMatrix = IDENTITY_MATRIX;
     this.fontMatrix = FONT_IDENTITY_MATRIX;
     this.leading = 0;
     this.x = 0;
     this.y = 0;
     this.lineX = 0;
     this.lineY = 0;
     this.charSpacing = 0;
     this.wordSpacing = 0;
     this.textHScale = 1;
     this.textRise = 0;
     this.fillColor = SVG_DEFAULTS.fillColor;
     this.strokeColor = '#000000';
     this.fillAlpha = 1;
     this.strokeAlpha = 1;
     this.lineWidth = 1;
     this.lineJoin = '';
     this.lineCap = '';
     this.miterLimit = 0;
     this.dashArray = [];
     this.dashPhase = 0;
     this.dependencies = [];
     this.activeClipUrl = null;
     this.clipGroup = null;
     this.maskId = '';
    }
    SVGExtraState.prototype = {
     clone: function SVGExtraState_clone() {
      return Object.create(this);
     },
     setCurrentPoint: function SVGExtraState_setCurrentPoint(x, y) {
      this.x = x;
      this.y = y;
     }
    };
    return SVGExtraState;
   }();
   var SVGGraphics = function SVGGraphicsClosure() {
    function opListToTree(opList) {
     var opTree = [];
     var tmp = [];
     var opListLen = opList.length;
     for (var x = 0; x < opListLen; x++) {
      if (opList[x].fn === 'save') {
       opTree.push({
        'fnId': 92,
        'fn': 'group',
        'items': []
       });
       tmp.push(opTree);
       opTree = opTree[opTree.length - 1].items;
       continue;
      }
      if (opList[x].fn === 'restore') {
       opTree = tmp.pop();
      } else {
       opTree.push(opList[x]);
      }
     }
     return opTree;
    }
    function pf(value) {
     if (value === (value | 0)) {
      return value.toString();
     }
     var s = value.toFixed(10);
     var i = s.length - 1;
     if (s[i] !== '0') {
      return s;
     }
     do {
      i--;
     } while (s[i] === '0');
     return s.substr(0, s[i] === '.' ? i : i + 1);
    }
    function pm(m) {
     if (m[4] === 0 && m[5] === 0) {
      if (m[1] === 0 && m[2] === 0) {
       if (m[0] === 1 && m[3] === 1) {
        return '';
       }
       return 'scale(' + pf(m[0]) + ' ' + pf(m[3]) + ')';
      }
      if (m[0] === m[3] && m[1] === -m[2]) {
       var a = Math.acos(m[0]) * 180 / Math.PI;
       return 'rotate(' + pf(a) + ')';
      }
     } else {
      if (m[0] === 1 && m[1] === 0 && m[2] === 0 && m[3] === 1) {
       return 'translate(' + pf(m[4]) + ' ' + pf(m[5]) + ')';
      }
     }
     return 'matrix(' + pf(m[0]) + ' ' + pf(m[1]) + ' ' + pf(m[2]) + ' ' + pf(m[3]) + ' ' + pf(m[4]) + ' ' + pf(m[5]) + ')';
    }
    function SVGGraphics(commonObjs, objs, forceDataSchema) {
     this.current = new SVGExtraState();
     this.transformMatrix = IDENTITY_MATRIX;
     this.transformStack = [];
     this.extraStack = [];
     this.commonObjs = commonObjs;
     this.objs = objs;
     this.pendingEOFill = false;
     this.embedFonts = false;
     this.embeddedFonts = Object.create(null);
     this.cssStyle = null;
     this.forceDataSchema = !!forceDataSchema;
    }
    var NS = 'http://www.w3.org/2000/svg';
    var XML_NS = 'http://www.w3.org/XML/1998/namespace';
    var XLINK_NS = 'http://www.w3.org/1999/xlink';
    var LINE_CAP_STYLES = [
     'butt',
     'round',
     'square'
    ];
    var LINE_JOIN_STYLES = [
     'miter',
     'round',
     'bevel'
    ];
    var clipCount = 0;
    var maskCount = 0;
    SVGGraphics.prototype = {
     save: function SVGGraphics_save() {
      this.transformStack.push(this.transformMatrix);
      var old = this.current;
      this.extraStack.push(old);
      this.current = old.clone();
     },
     restore: function SVGGraphics_restore() {
      this.transformMatrix = this.transformStack.pop();
      this.current = this.extraStack.pop();
      this.tgrp = null;
     },
     group: function SVGGraphics_group(items) {
      this.save();
      this.executeOpTree(items);
      this.restore();
     },
     loadDependencies: function SVGGraphics_loadDependencies(operatorList) {
      var fnArray = operatorList.fnArray;
      var fnArrayLen = fnArray.length;
      var argsArray = operatorList.argsArray;
      var self = this;
      for (var i = 0; i < fnArrayLen; i++) {
       if (OPS.dependency === fnArray[i]) {
        var deps = argsArray[i];
        for (var n = 0, nn = deps.length; n < nn; n++) {
         var obj = deps[n];
         var common = obj.substring(0, 2) === 'g_';
         var promise;
         if (common) {
          promise = new Promise(function (resolve) {
           self.commonObjs.get(obj, resolve);
          });
         } else {
          promise = new Promise(function (resolve) {
           self.objs.get(obj, resolve);
          });
         }
         this.current.dependencies.push(promise);
        }
       }
      }
      return Promise.all(this.current.dependencies);
     },
     transform: function SVGGraphics_transform(a, b, c, d, e, f) {
      var transformMatrix = [
       a,
       b,
       c,
       d,
       e,
       f
      ];
      this.transformMatrix = Util.transform(this.transformMatrix, transformMatrix);
      this.tgrp = null;
     },
     getSVG: function SVGGraphics_getSVG(operatorList, viewport) {
      this.viewport = viewport;
      var svgElement = this._initialize(viewport);
      return this.loadDependencies(operatorList).then(function () {
       this.transformMatrix = IDENTITY_MATRIX;
       var opTree = this.convertOpList(operatorList);
       this.executeOpTree(opTree);
       return svgElement;
      }.bind(this));
     },
     convertOpList: function SVGGraphics_convertOpList(operatorList) {
      var argsArray = operatorList.argsArray;
      var fnArray = operatorList.fnArray;
      var fnArrayLen = fnArray.length;
      var REVOPS = [];
      var opList = [];
      for (var op in OPS) {
       REVOPS[OPS[op]] = op;
      }
      for (var x = 0; x < fnArrayLen; x++) {
       var fnId = fnArray[x];
       opList.push({
        'fnId': fnId,
        'fn': REVOPS[fnId],
        'args': argsArray[x]
       });
      }
      return opListToTree(opList);
     },
     executeOpTree: function SVGGraphics_executeOpTree(opTree) {
      var opTreeLen = opTree.length;
      for (var x = 0; x < opTreeLen; x++) {
       var fn = opTree[x].fn;
       var fnId = opTree[x].fnId;
       var args = opTree[x].args;
       switch (fnId | 0) {
       case OPS.beginText:
        this.beginText();
        break;
       case OPS.setLeading:
        this.setLeading(args);
        break;
       case OPS.setLeadingMoveText:
        this.setLeadingMoveText(args[0], args[1]);
        break;
       case OPS.setFont:
        this.setFont(args);
        break;
       case OPS.showText:
        this.showText(args[0]);
        break;
       case OPS.showSpacedText:
        this.showText(args[0]);
        break;
       case OPS.endText:
        this.endText();
        break;
       case OPS.moveText:
        this.moveText(args[0], args[1]);
        break;
       case OPS.setCharSpacing:
        this.setCharSpacing(args[0]);
        break;
       case OPS.setWordSpacing:
        this.setWordSpacing(args[0]);
        break;
       case OPS.setHScale:
        this.setHScale(args[0]);
        break;
       case OPS.setTextMatrix:
        this.setTextMatrix(args[0], args[1], args[2], args[3], args[4], args[5]);
        break;
       case OPS.setLineWidth:
        this.setLineWidth(args[0]);
        break;
       case OPS.setLineJoin:
        this.setLineJoin(args[0]);
        break;
       case OPS.setLineCap:
        this.setLineCap(args[0]);
        break;
       case OPS.setMiterLimit:
        this.setMiterLimit(args[0]);
        break;
       case OPS.setFillRGBColor:
        this.setFillRGBColor(args[0], args[1], args[2]);
        break;
       case OPS.setStrokeRGBColor:
        this.setStrokeRGBColor(args[0], args[1], args[2]);
        break;
       case OPS.setDash:
        this.setDash(args[0], args[1]);
        break;
       case OPS.setGState:
        this.setGState(args[0]);
        break;
       case OPS.fill:
        this.fill();
        break;
       case OPS.eoFill:
        this.eoFill();
        break;
       case OPS.stroke:
        this.stroke();
        break;
       case OPS.fillStroke:
        this.fillStroke();
        break;
       case OPS.eoFillStroke:
        this.eoFillStroke();
        break;
       case OPS.clip:
        this.clip('nonzero');
        break;
       case OPS.eoClip:
        this.clip('evenodd');
        break;
       case OPS.paintSolidColorImageMask:
        this.paintSolidColorImageMask();
        break;
       case OPS.paintJpegXObject:
        this.paintJpegXObject(args[0], args[1], args[2]);
        break;
       case OPS.paintImageXObject:
        this.paintImageXObject(args[0]);
        break;
       case OPS.paintInlineImageXObject:
        this.paintInlineImageXObject(args[0]);
        break;
       case OPS.paintImageMaskXObject:
        this.paintImageMaskXObject(args[0]);
        break;
       case OPS.paintFormXObjectBegin:
        this.paintFormXObjectBegin(args[0], args[1]);
        break;
       case OPS.paintFormXObjectEnd:
        this.paintFormXObjectEnd();
        break;
       case OPS.closePath:
        this.closePath();
        break;
       case OPS.closeStroke:
        this.closeStroke();
        break;
       case OPS.closeFillStroke:
        this.closeFillStroke();
        break;
       case OPS.nextLine:
        this.nextLine();
        break;
       case OPS.transform:
        this.transform(args[0], args[1], args[2], args[3], args[4], args[5]);
        break;
       case OPS.constructPath:
        this.constructPath(args[0], args[1]);
        break;
       case OPS.endPath:
        this.endPath();
        break;
       case 92:
        this.group(opTree[x].items);
        break;
       default:
        warn('Unimplemented operator ' + fn);
        break;
       }
      }
     },
     setWordSpacing: function SVGGraphics_setWordSpacing(wordSpacing) {
      this.current.wordSpacing = wordSpacing;
     },
     setCharSpacing: function SVGGraphics_setCharSpacing(charSpacing) {
      this.current.charSpacing = charSpacing;
     },
     nextLine: function SVGGraphics_nextLine() {
      this.moveText(0, this.current.leading);
     },
     setTextMatrix: function SVGGraphics_setTextMatrix(a, b, c, d, e, f) {
      var current = this.current;
      this.current.textMatrix = this.current.lineMatrix = [
       a,
       b,
       c,
       d,
       e,
       f
      ];
      this.current.x = this.current.lineX = 0;
      this.current.y = this.current.lineY = 0;
      current.xcoords = [];
      current.tspan = document.createElementNS(NS, 'svg:tspan');
      current.tspan.setAttributeNS(null, 'font-family', current.fontFamily);
      current.tspan.setAttributeNS(null, 'font-size', pf(current.fontSize) + 'px');
      current.tspan.setAttributeNS(null, 'y', pf(-current.y));
      current.txtElement = document.createElementNS(NS, 'svg:text');
      current.txtElement.appendChild(current.tspan);
     },
     beginText: function SVGGraphics_beginText() {
      this.current.x = this.current.lineX = 0;
      this.current.y = this.current.lineY = 0;
      this.current.textMatrix = IDENTITY_MATRIX;
      this.current.lineMatrix = IDENTITY_MATRIX;
      this.current.tspan = document.createElementNS(NS, 'svg:tspan');
      this.current.txtElement = document.createElementNS(NS, 'svg:text');
      this.current.txtgrp = document.createElementNS(NS, 'svg:g');
      this.current.xcoords = [];
     },
     moveText: function SVGGraphics_moveText(x, y) {
      var current = this.current;
      this.current.x = this.current.lineX += x;
      this.current.y = this.current.lineY += y;
      current.xcoords = [];
      current.tspan = document.createElementNS(NS, 'svg:tspan');
      current.tspan.setAttributeNS(null, 'font-family', current.fontFamily);
      current.tspan.setAttributeNS(null, 'font-size', pf(current.fontSize) + 'px');
      current.tspan.setAttributeNS(null, 'y', pf(-current.y));
     },
     showText: function SVGGraphics_showText(glyphs) {
      var current = this.current;
      var font = current.font;
      var fontSize = current.fontSize;
      if (fontSize === 0) {
       return;
      }
      var charSpacing = current.charSpacing;
      var wordSpacing = current.wordSpacing;
      var fontDirection = current.fontDirection;
      var textHScale = current.textHScale * fontDirection;
      var glyphsLength = glyphs.length;
      var vertical = font.vertical;
      var widthAdvanceScale = fontSize * current.fontMatrix[0];
      var x = 0, i;
      for (i = 0; i < glyphsLength; ++i) {
       var glyph = glyphs[i];
       if (glyph === null) {
        x += fontDirection * wordSpacing;
        continue;
       } else if (isNum(glyph)) {
        x += -glyph * fontSize * 0.001;
        continue;
       }
       current.xcoords.push(current.x + x * textHScale);
       var width = glyph.width;
       var character = glyph.fontChar;
       var charWidth = width * widthAdvanceScale + charSpacing * fontDirection;
       x += charWidth;
       current.tspan.textContent += character;
      }
      if (vertical) {
       current.y -= x * textHScale;
      } else {
       current.x += x * textHScale;
      }
      current.tspan.setAttributeNS(null, 'x', current.xcoords.map(pf).join(' '));
      current.tspan.setAttributeNS(null, 'y', pf(-current.y));
      current.tspan.setAttributeNS(null, 'font-family', current.fontFamily);
      current.tspan.setAttributeNS(null, 'font-size', pf(current.fontSize) + 'px');
      if (current.fontStyle !== SVG_DEFAULTS.fontStyle) {
       current.tspan.setAttributeNS(null, 'font-style', current.fontStyle);
      }
      if (current.fontWeight !== SVG_DEFAULTS.fontWeight) {
       current.tspan.setAttributeNS(null, 'font-weight', current.fontWeight);
      }
      if (current.fillColor !== SVG_DEFAULTS.fillColor) {
       current.tspan.setAttributeNS(null, 'fill', current.fillColor);
      }
      current.txtElement.setAttributeNS(null, 'transform', pm(current.textMatrix) + ' scale(1, -1)');
      current.txtElement.setAttributeNS(XML_NS, 'xml:space', 'preserve');
      current.txtElement.appendChild(current.tspan);
      current.txtgrp.appendChild(current.txtElement);
      this._ensureTransformGroup().appendChild(current.txtElement);
     },
     setLeadingMoveText: function SVGGraphics_setLeadingMoveText(x, y) {
      this.setLeading(-y);
      this.moveText(x, y);
     },
     addFontStyle: function SVGGraphics_addFontStyle(fontObj) {
      if (!this.cssStyle) {
       this.cssStyle = document.createElementNS(NS, 'svg:style');
       this.cssStyle.setAttributeNS(null, 'type', 'text/css');
       this.defs.appendChild(this.cssStyle);
      }
      var url = createObjectURL(fontObj.data, fontObj.mimetype, this.forceDataSchema);
      this.cssStyle.textContent += '@font-face { font-family: "' + fontObj.loadedName + '";' + ' src: url(' + url + '); }\n';
     },
     setFont: function SVGGraphics_setFont(details) {
      var current = this.current;
      var fontObj = this.commonObjs.get(details[0]);
      var size = details[1];
      this.current.font = fontObj;
      if (this.embedFonts && fontObj.data && !this.embeddedFonts[fontObj.loadedName]) {
       this.addFontStyle(fontObj);
       this.embeddedFonts[fontObj.loadedName] = fontObj;
      }
      current.fontMatrix = fontObj.fontMatrix ? fontObj.fontMatrix : FONT_IDENTITY_MATRIX;
      var bold = fontObj.black ? fontObj.bold ? 'bolder' : 'bold' : fontObj.bold ? 'bold' : 'normal';
      var italic = fontObj.italic ? 'italic' : 'normal';
      if (size < 0) {
       size = -size;
       current.fontDirection = -1;
      } else {
       current.fontDirection = 1;
      }
      current.fontSize = size;
      current.fontFamily = fontObj.loadedName;
      current.fontWeight = bold;
      current.fontStyle = italic;
      current.tspan = document.createElementNS(NS, 'svg:tspan');
      current.tspan.setAttributeNS(null, 'y', pf(-current.y));
      current.xcoords = [];
     },
     endText: function SVGGraphics_endText() {
     },
     setLineWidth: function SVGGraphics_setLineWidth(width) {
      this.current.lineWidth = width;
     },
     setLineCap: function SVGGraphics_setLineCap(style) {
      this.current.lineCap = LINE_CAP_STYLES[style];
     },
     setLineJoin: function SVGGraphics_setLineJoin(style) {
      this.current.lineJoin = LINE_JOIN_STYLES[style];
     },
     setMiterLimit: function SVGGraphics_setMiterLimit(limit) {
      this.current.miterLimit = limit;
     },
     setStrokeRGBColor: function SVGGraphics_setStrokeRGBColor(r, g, b) {
      var color = Util.makeCssRgb(r, g, b);
      this.current.strokeColor = color;
     },
     setFillRGBColor: function SVGGraphics_setFillRGBColor(r, g, b) {
      var color = Util.makeCssRgb(r, g, b);
      this.current.fillColor = color;
      this.current.tspan = document.createElementNS(NS, 'svg:tspan');
      this.current.xcoords = [];
     },
     setDash: function SVGGraphics_setDash(dashArray, dashPhase) {
      this.current.dashArray = dashArray;
      this.current.dashPhase = dashPhase;
     },
     constructPath: function SVGGraphics_constructPath(ops, args) {
      var current = this.current;
      var x = current.x, y = current.y;
      current.path = document.createElementNS(NS, 'svg:path');
      var d = [];
      var opLength = ops.length;
      for (var i = 0, j = 0; i < opLength; i++) {
       switch (ops[i] | 0) {
       case OPS.rectangle:
        x = args[j++];
        y = args[j++];
        var width = args[j++];
        var height = args[j++];
        var xw = x + width;
        var yh = y + height;
        d.push('M', pf(x), pf(y), 'L', pf(xw), pf(y), 'L', pf(xw), pf(yh), 'L', pf(x), pf(yh), 'Z');
        break;
       case OPS.moveTo:
        x = args[j++];
        y = args[j++];
        d.push('M', pf(x), pf(y));
        break;
       case OPS.lineTo:
        x = args[j++];
        y = args[j++];
        d.push('L', pf(x), pf(y));
        break;
       case OPS.curveTo:
        x = args[j + 4];
        y = args[j + 5];
        d.push('C', pf(args[j]), pf(args[j + 1]), pf(args[j + 2]), pf(args[j + 3]), pf(x), pf(y));
        j += 6;
        break;
       case OPS.curveTo2:
        x = args[j + 2];
        y = args[j + 3];
        d.push('C', pf(x), pf(y), pf(args[j]), pf(args[j + 1]), pf(args[j + 2]), pf(args[j + 3]));
        j += 4;
        break;
       case OPS.curveTo3:
        x = args[j + 2];
        y = args[j + 3];
        d.push('C', pf(args[j]), pf(args[j + 1]), pf(x), pf(y), pf(x), pf(y));
        j += 4;
        break;
       case OPS.closePath:
        d.push('Z');
        break;
       }
      }
      current.path.setAttributeNS(null, 'd', d.join(' '));
      current.path.setAttributeNS(null, 'stroke-miterlimit', pf(current.miterLimit));
      current.path.setAttributeNS(null, 'stroke-linecap', current.lineCap);
      current.path.setAttributeNS(null, 'stroke-linejoin', current.lineJoin);
      current.path.setAttributeNS(null, 'stroke-width', pf(current.lineWidth) + 'px');
      current.path.setAttributeNS(null, 'stroke-dasharray', current.dashArray.map(pf).join(' '));
      current.path.setAttributeNS(null, 'stroke-dashoffset', pf(current.dashPhase) + 'px');
      current.path.setAttributeNS(null, 'fill', 'none');
      this._ensureTransformGroup().appendChild(current.path);
      current.element = current.path;
      current.setCurrentPoint(x, y);
     },
     endPath: function SVGGraphics_endPath() {
     },
     clip: function SVGGraphics_clip(type) {
      var current = this.current;
      var clipId = 'clippath' + clipCount;
      clipCount++;
      var clipPath = document.createElementNS(NS, 'svg:clipPath');
      clipPath.setAttributeNS(null, 'id', clipId);
      clipPath.setAttributeNS(null, 'transform', pm(this.transformMatrix));
      var clipElement = current.element.cloneNode();
      if (type === 'evenodd') {
       clipElement.setAttributeNS(null, 'clip-rule', 'evenodd');
      } else {
       clipElement.setAttributeNS(null, 'clip-rule', 'nonzero');
      }
      clipPath.appendChild(clipElement);
      this.defs.appendChild(clipPath);
      if (current.activeClipUrl) {
       current.clipGroup = null;
       this.extraStack.forEach(function (prev) {
        prev.clipGroup = null;
       });
      }
      current.activeClipUrl = 'url(#' + clipId + ')';
      this.tgrp = null;
     },
     closePath: function SVGGraphics_closePath() {
      var current = this.current;
      var d = current.path.getAttributeNS(null, 'd');
      d += 'Z';
      current.path.setAttributeNS(null, 'd', d);
     },
     setLeading: function SVGGraphics_setLeading(leading) {
      this.current.leading = -leading;
     },
     setTextRise: function SVGGraphics_setTextRise(textRise) {
      this.current.textRise = textRise;
     },
     setHScale: function SVGGraphics_setHScale(scale) {
      this.current.textHScale = scale / 100;
     },
     setGState: function SVGGraphics_setGState(states) {
      for (var i = 0, ii = states.length; i < ii; i++) {
       var state = states[i];
       var key = state[0];
       var value = state[1];
       switch (key) {
       case 'LW':
        this.setLineWidth(value);
        break;
       case 'LC':
        this.setLineCap(value);
        break;
       case 'LJ':
        this.setLineJoin(value);
        break;
       case 'ML':
        this.setMiterLimit(value);
        break;
       case 'D':
        this.setDash(value[0], value[1]);
        break;
       case 'Font':
        this.setFont(value);
        break;
       default:
        warn('Unimplemented graphic state ' + key);
        break;
       }
      }
     },
     fill: function SVGGraphics_fill() {
      var current = this.current;
      current.element.setAttributeNS(null, 'fill', current.fillColor);
     },
     stroke: function SVGGraphics_stroke() {
      var current = this.current;
      current.element.setAttributeNS(null, 'stroke', current.strokeColor);
      current.element.setAttributeNS(null, 'fill', 'none');
     },
     eoFill: function SVGGraphics_eoFill() {
      var current = this.current;
      current.element.setAttributeNS(null, 'fill', current.fillColor);
      current.element.setAttributeNS(null, 'fill-rule', 'evenodd');
     },
     fillStroke: function SVGGraphics_fillStroke() {
      this.stroke();
      this.fill();
     },
     eoFillStroke: function SVGGraphics_eoFillStroke() {
      this.current.element.setAttributeNS(null, 'fill-rule', 'evenodd');
      this.fillStroke();
     },
     closeStroke: function SVGGraphics_closeStroke() {
      this.closePath();
      this.stroke();
     },
     closeFillStroke: function SVGGraphics_closeFillStroke() {
      this.closePath();
      this.fillStroke();
     },
     paintSolidColorImageMask: function SVGGraphics_paintSolidColorImageMask() {
      var current = this.current;
      var rect = document.createElementNS(NS, 'svg:rect');
      rect.setAttributeNS(null, 'x', '0');
      rect.setAttributeNS(null, 'y', '0');
      rect.setAttributeNS(null, 'width', '1px');
      rect.setAttributeNS(null, 'height', '1px');
      rect.setAttributeNS(null, 'fill', current.fillColor);
      this._ensureTransformGroup().appendChild(rect);
     },
     paintJpegXObject: function SVGGraphics_paintJpegXObject(objId, w, h) {
      var imgObj = this.objs.get(objId);
      var imgEl = document.createElementNS(NS, 'svg:image');
      imgEl.setAttributeNS(XLINK_NS, 'xlink:href', imgObj.src);
      imgEl.setAttributeNS(null, 'width', imgObj.width + 'px');
      imgEl.setAttributeNS(null, 'height', imgObj.height + 'px');
      imgEl.setAttributeNS(null, 'x', '0');
      imgEl.setAttributeNS(null, 'y', pf(-h));
      imgEl.setAttributeNS(null, 'transform', 'scale(' + pf(1 / w) + ' ' + pf(-1 / h) + ')');
      this._ensureTransformGroup().appendChild(imgEl);
     },
     paintImageXObject: function SVGGraphics_paintImageXObject(objId) {
      var imgData = this.objs.get(objId);
      if (!imgData) {
       warn('Dependent image isn\'t ready yet');
       return;
      }
      this.paintInlineImageXObject(imgData);
     },
     paintInlineImageXObject: function SVGGraphics_paintInlineImageXObject(imgData, mask) {
      var width = imgData.width;
      var height = imgData.height;
      var imgSrc = convertImgDataToPng(imgData, this.forceDataSchema);
      var cliprect = document.createElementNS(NS, 'svg:rect');
      cliprect.setAttributeNS(null, 'x', '0');
      cliprect.setAttributeNS(null, 'y', '0');
      cliprect.setAttributeNS(null, 'width', pf(width));
      cliprect.setAttributeNS(null, 'height', pf(height));
      this.current.element = cliprect;
      this.clip('nonzero');
      var imgEl = document.createElementNS(NS, 'svg:image');
      imgEl.setAttributeNS(XLINK_NS, 'xlink:href', imgSrc);
      imgEl.setAttributeNS(null, 'x', '0');
      imgEl.setAttributeNS(null, 'y', pf(-height));
      imgEl.setAttributeNS(null, 'width', pf(width) + 'px');
      imgEl.setAttributeNS(null, 'height', pf(height) + 'px');
      imgEl.setAttributeNS(null, 'transform', 'scale(' + pf(1 / width) + ' ' + pf(-1 / height) + ')');
      if (mask) {
       mask.appendChild(imgEl);
      } else {
       this._ensureTransformGroup().appendChild(imgEl);
      }
     },
     paintImageMaskXObject: function SVGGraphics_paintImageMaskXObject(imgData) {
      var current = this.current;
      var width = imgData.width;
      var height = imgData.height;
      var fillColor = current.fillColor;
      current.maskId = 'mask' + maskCount++;
      var mask = document.createElementNS(NS, 'svg:mask');
      mask.setAttributeNS(null, 'id', current.maskId);
      var rect = document.createElementNS(NS, 'svg:rect');
      rect.setAttributeNS(null, 'x', '0');
      rect.setAttributeNS(null, 'y', '0');
      rect.setAttributeNS(null, 'width', pf(width));
      rect.setAttributeNS(null, 'height', pf(height));
      rect.setAttributeNS(null, 'fill', fillColor);
      rect.setAttributeNS(null, 'mask', 'url(#' + current.maskId + ')');
      this.defs.appendChild(mask);
      this._ensureTransformGroup().appendChild(rect);
      this.paintInlineImageXObject(imgData, mask);
     },
     paintFormXObjectBegin: function SVGGraphics_paintFormXObjectBegin(matrix, bbox) {
      if (isArray(matrix) && matrix.length === 6) {
       this.transform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
      }
      if (isArray(bbox) && bbox.length === 4) {
       var width = bbox[2] - bbox[0];
       var height = bbox[3] - bbox[1];
       var cliprect = document.createElementNS(NS, 'svg:rect');
       cliprect.setAttributeNS(null, 'x', bbox[0]);
       cliprect.setAttributeNS(null, 'y', bbox[1]);
       cliprect.setAttributeNS(null, 'width', pf(width));
       cliprect.setAttributeNS(null, 'height', pf(height));
       this.current.element = cliprect;
       this.clip('nonzero');
       this.endPath();
      }
     },
     paintFormXObjectEnd: function SVGGraphics_paintFormXObjectEnd() {
     },
     _initialize: function SVGGraphics_initialize(viewport) {
      var svg = document.createElementNS(NS, 'svg:svg');
      svg.setAttributeNS(null, 'version', '1.1');
      svg.setAttributeNS(null, 'width', viewport.width + 'px');
      svg.setAttributeNS(null, 'height', viewport.height + 'px');
      svg.setAttributeNS(null, 'preserveAspectRatio', 'none');
      svg.setAttributeNS(null, 'viewBox', '0 0 ' + viewport.width + ' ' + viewport.height);
      var definitions = document.createElementNS(NS, 'svg:defs');
      svg.appendChild(definitions);
      this.defs = definitions;
      var rootGroup = document.createElementNS(NS, 'svg:g');
      rootGroup.setAttributeNS(null, 'transform', pm(viewport.transform));
      svg.appendChild(rootGroup);
      this.svg = rootGroup;
      return svg;
     },
     _ensureClipGroup: function SVGGraphics_ensureClipGroup() {
      if (!this.current.clipGroup) {
       var clipGroup = document.createElementNS(NS, 'svg:g');
       clipGroup.setAttributeNS(null, 'clip-path', this.current.activeClipUrl);
       this.svg.appendChild(clipGroup);
       this.current.clipGroup = clipGroup;
      }
      return this.current.clipGroup;
     },
     _ensureTransformGroup: function SVGGraphics_ensureTransformGroup() {
      if (!this.tgrp) {
       this.tgrp = document.createElementNS(NS, 'svg:g');
       this.tgrp.setAttributeNS(null, 'transform', pm(this.transformMatrix));
       if (this.current.activeClipUrl) {
        this._ensureClipGroup().appendChild(this.tgrp);
       } else {
        this.svg.appendChild(this.tgrp);
       }
      }
      return this.tgrp;
     }
    };
    return SVGGraphics;
   }();
   exports.SVGGraphics = SVGGraphics;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreStream = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreJbig2, root.pdfjsCoreJpg, root.pdfjsCoreJpx);
  }(this, function (exports, sharedUtil, corePrimitives, coreJbig2, coreJpg, coreJpx) {
   var Util = sharedUtil.Util;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isInt = sharedUtil.isInt;
   var isArray = sharedUtil.isArray;
   var createObjectURL = sharedUtil.createObjectURL;
   var shadow = sharedUtil.shadow;
   var warn = sharedUtil.warn;
   var isSpace = sharedUtil.isSpace;
   var Dict = corePrimitives.Dict;
   var isDict = corePrimitives.isDict;
   var isStream = corePrimitives.isStream;
   var Jbig2Image = coreJbig2.Jbig2Image;
   var JpegImage = coreJpg.JpegImage;
   var JpxImage = coreJpx.JpxImage;
   var Stream = function StreamClosure() {
    function Stream(arrayBuffer, start, length, dict) {
     this.bytes = arrayBuffer instanceof Uint8Array ? arrayBuffer : new Uint8Array(arrayBuffer);
     this.start = start || 0;
     this.pos = this.start;
     this.end = start + length || this.bytes.length;
     this.dict = dict;
    }
    Stream.prototype = {
     get length() {
      return this.end - this.start;
     },
     get isEmpty() {
      return this.length === 0;
     },
     getByte: function Stream_getByte() {
      if (this.pos >= this.end) {
       return -1;
      }
      return this.bytes[this.pos++];
     },
     getUint16: function Stream_getUint16() {
      var b0 = this.getByte();
      var b1 = this.getByte();
      if (b0 === -1 || b1 === -1) {
       return -1;
      }
      return (b0 << 8) + b1;
     },
     getInt32: function Stream_getInt32() {
      var b0 = this.getByte();
      var b1 = this.getByte();
      var b2 = this.getByte();
      var b3 = this.getByte();
      return (b0 << 24) + (b1 << 16) + (b2 << 8) + b3;
     },
     getBytes: function Stream_getBytes(length) {
      var bytes = this.bytes;
      var pos = this.pos;
      var strEnd = this.end;
      if (!length) {
       return bytes.subarray(pos, strEnd);
      }
      var end = pos + length;
      if (end > strEnd) {
       end = strEnd;
      }
      this.pos = end;
      return bytes.subarray(pos, end);
     },
     peekByte: function Stream_peekByte() {
      var peekedByte = this.getByte();
      this.pos--;
      return peekedByte;
     },
     peekBytes: function Stream_peekBytes(length) {
      var bytes = this.getBytes(length);
      this.pos -= bytes.length;
      return bytes;
     },
     skip: function Stream_skip(n) {
      if (!n) {
       n = 1;
      }
      this.pos += n;
     },
     reset: function Stream_reset() {
      this.pos = this.start;
     },
     moveStart: function Stream_moveStart() {
      this.start = this.pos;
     },
     makeSubStream: function Stream_makeSubStream(start, length, dict) {
      return new Stream(this.bytes.buffer, start, length, dict);
     },
     isStream: true
    };
    return Stream;
   }();
   var StringStream = function StringStreamClosure() {
    function StringStream(str) {
     var length = str.length;
     var bytes = new Uint8Array(length);
     for (var n = 0; n < length; ++n) {
      bytes[n] = str.charCodeAt(n);
     }
     Stream.call(this, bytes);
    }
    StringStream.prototype = Stream.prototype;
    return StringStream;
   }();
   var DecodeStream = function DecodeStreamClosure() {
    var emptyBuffer = new Uint8Array(0);
    function DecodeStream(maybeMinBufferLength) {
     this.pos = 0;
     this.bufferLength = 0;
     this.eof = false;
     this.buffer = emptyBuffer;
     this.minBufferLength = 512;
     if (maybeMinBufferLength) {
      while (this.minBufferLength < maybeMinBufferLength) {
       this.minBufferLength *= 2;
      }
     }
    }
    DecodeStream.prototype = {
     get isEmpty() {
      while (!this.eof && this.bufferLength === 0) {
       this.readBlock();
      }
      return this.bufferLength === 0;
     },
     ensureBuffer: function DecodeStream_ensureBuffer(requested) {
      var buffer = this.buffer;
      if (requested <= buffer.byteLength) {
       return buffer;
      }
      var size = this.minBufferLength;
      while (size < requested) {
       size *= 2;
      }
      var buffer2 = new Uint8Array(size);
      buffer2.set(buffer);
      return this.buffer = buffer2;
     },
     getByte: function DecodeStream_getByte() {
      var pos = this.pos;
      while (this.bufferLength <= pos) {
       if (this.eof) {
        return -1;
       }
       this.readBlock();
      }
      return this.buffer[this.pos++];
     },
     getUint16: function DecodeStream_getUint16() {
      var b0 = this.getByte();
      var b1 = this.getByte();
      if (b0 === -1 || b1 === -1) {
       return -1;
      }
      return (b0 << 8) + b1;
     },
     getInt32: function DecodeStream_getInt32() {
      var b0 = this.getByte();
      var b1 = this.getByte();
      var b2 = this.getByte();
      var b3 = this.getByte();
      return (b0 << 24) + (b1 << 16) + (b2 << 8) + b3;
     },
     getBytes: function DecodeStream_getBytes(length) {
      var end, pos = this.pos;
      if (length) {
       this.ensureBuffer(pos + length);
       end = pos + length;
       while (!this.eof && this.bufferLength < end) {
        this.readBlock();
       }
       var bufEnd = this.bufferLength;
       if (end > bufEnd) {
        end = bufEnd;
       }
      } else {
       while (!this.eof) {
        this.readBlock();
       }
       end = this.bufferLength;
      }
      this.pos = end;
      return this.buffer.subarray(pos, end);
     },
     peekByte: function DecodeStream_peekByte() {
      var peekedByte = this.getByte();
      this.pos--;
      return peekedByte;
     },
     peekBytes: function DecodeStream_peekBytes(length) {
      var bytes = this.getBytes(length);
      this.pos -= bytes.length;
      return bytes;
     },
     makeSubStream: function DecodeStream_makeSubStream(start, length, dict) {
      var end = start + length;
      while (this.bufferLength <= end && !this.eof) {
       this.readBlock();
      }
      return new Stream(this.buffer, start, length, dict);
     },
     skip: function DecodeStream_skip(n) {
      if (!n) {
       n = 1;
      }
      this.pos += n;
     },
     reset: function DecodeStream_reset() {
      this.pos = 0;
     },
     getBaseStreams: function DecodeStream_getBaseStreams() {
      if (this.str && this.str.getBaseStreams) {
       return this.str.getBaseStreams();
      }
      return [];
     }
    };
    return DecodeStream;
   }();
   var StreamsSequenceStream = function StreamsSequenceStreamClosure() {
    function StreamsSequenceStream(streams) {
     this.streams = streams;
     DecodeStream.call(this, null);
    }
    StreamsSequenceStream.prototype = Object.create(DecodeStream.prototype);
    StreamsSequenceStream.prototype.readBlock = function streamSequenceStreamReadBlock() {
     var streams = this.streams;
     if (streams.length === 0) {
      this.eof = true;
      return;
     }
     var stream = streams.shift();
     var chunk = stream.getBytes();
     var bufferLength = this.bufferLength;
     var newLength = bufferLength + chunk.length;
     var buffer = this.ensureBuffer(newLength);
     buffer.set(chunk, bufferLength);
     this.bufferLength = newLength;
    };
    StreamsSequenceStream.prototype.getBaseStreams = function StreamsSequenceStream_getBaseStreams() {
     var baseStreams = [];
     for (var i = 0, ii = this.streams.length; i < ii; i++) {
      var stream = this.streams[i];
      if (stream.getBaseStreams) {
       Util.appendToArray(baseStreams, stream.getBaseStreams());
      }
     }
     return baseStreams;
    };
    return StreamsSequenceStream;
   }();
   var FlateStream = function FlateStreamClosure() {
    var codeLenCodeMap = new Int32Array([
     16,
     17,
     18,
     0,
     8,
     7,
     9,
     6,
     10,
     5,
     11,
     4,
     12,
     3,
     13,
     2,
     14,
     1,
     15
    ]);
    var lengthDecode = new Int32Array([
     0x00003,
     0x00004,
     0x00005,
     0x00006,
     0x00007,
     0x00008,
     0x00009,
     0x0000a,
     0x1000b,
     0x1000d,
     0x1000f,
     0x10011,
     0x20013,
     0x20017,
     0x2001b,
     0x2001f,
     0x30023,
     0x3002b,
     0x30033,
     0x3003b,
     0x40043,
     0x40053,
     0x40063,
     0x40073,
     0x50083,
     0x500a3,
     0x500c3,
     0x500e3,
     0x00102,
     0x00102,
     0x00102
    ]);
    var distDecode = new Int32Array([
     0x00001,
     0x00002,
     0x00003,
     0x00004,
     0x10005,
     0x10007,
     0x20009,
     0x2000d,
     0x30011,
     0x30019,
     0x40021,
     0x40031,
     0x50041,
     0x50061,
     0x60081,
     0x600c1,
     0x70101,
     0x70181,
     0x80201,
     0x80301,
     0x90401,
     0x90601,
     0xa0801,
     0xa0c01,
     0xb1001,
     0xb1801,
     0xc2001,
     0xc3001,
     0xd4001,
     0xd6001
    ]);
    var fixedLitCodeTab = [
     new Int32Array([
      0x70100,
      0x80050,
      0x80010,
      0x80118,
      0x70110,
      0x80070,
      0x80030,
      0x900c0,
      0x70108,
      0x80060,
      0x80020,
      0x900a0,
      0x80000,
      0x80080,
      0x80040,
      0x900e0,
      0x70104,
      0x80058,
      0x80018,
      0x90090,
      0x70114,
      0x80078,
      0x80038,
      0x900d0,
      0x7010c,
      0x80068,
      0x80028,
      0x900b0,
      0x80008,
      0x80088,
      0x80048,
      0x900f0,
      0x70102,
      0x80054,
      0x80014,
      0x8011c,
      0x70112,
      0x80074,
      0x80034,
      0x900c8,
      0x7010a,
      0x80064,
      0x80024,
      0x900a8,
      0x80004,
      0x80084,
      0x80044,
      0x900e8,
      0x70106,
      0x8005c,
      0x8001c,
      0x90098,
      0x70116,
      0x8007c,
      0x8003c,
      0x900d8,
      0x7010e,
      0x8006c,
      0x8002c,
      0x900b8,
      0x8000c,
      0x8008c,
      0x8004c,
      0x900f8,
      0x70101,
      0x80052,
      0x80012,
      0x8011a,
      0x70111,
      0x80072,
      0x80032,
      0x900c4,
      0x70109,
      0x80062,
      0x80022,
      0x900a4,
      0x80002,
      0x80082,
      0x80042,
      0x900e4,
      0x70105,
      0x8005a,
      0x8001a,
      0x90094,
      0x70115,
      0x8007a,
      0x8003a,
      0x900d4,
      0x7010d,
      0x8006a,
      0x8002a,
      0x900b4,
      0x8000a,
      0x8008a,
      0x8004a,
      0x900f4,
      0x70103,
      0x80056,
      0x80016,
      0x8011e,
      0x70113,
      0x80076,
      0x80036,
      0x900cc,
      0x7010b,
      0x80066,
      0x80026,
      0x900ac,
      0x80006,
      0x80086,
      0x80046,
      0x900ec,
      0x70107,
      0x8005e,
      0x8001e,
      0x9009c,
      0x70117,
      0x8007e,
      0x8003e,
      0x900dc,
      0x7010f,
      0x8006e,
      0x8002e,
      0x900bc,
      0x8000e,
      0x8008e,
      0x8004e,
      0x900fc,
      0x70100,
      0x80051,
      0x80011,
      0x80119,
      0x70110,
      0x80071,
      0x80031,
      0x900c2,
      0x70108,
      0x80061,
      0x80021,
      0x900a2,
      0x80001,
      0x80081,
      0x80041,
      0x900e2,
      0x70104,
      0x80059,
      0x80019,
      0x90092,
      0x70114,
      0x80079,
      0x80039,
      0x900d2,
      0x7010c,
      0x80069,
      0x80029,
      0x900b2,
      0x80009,
      0x80089,
      0x80049,
      0x900f2,
      0x70102,
      0x80055,
      0x80015,
      0x8011d,
      0x70112,
      0x80075,
      0x80035,
      0x900ca,
      0x7010a,
      0x80065,
      0x80025,
      0x900aa,
      0x80005,
      0x80085,
      0x80045,
      0x900ea,
      0x70106,
      0x8005d,
      0x8001d,
      0x9009a,
      0x70116,
      0x8007d,
      0x8003d,
      0x900da,
      0x7010e,
      0x8006d,
      0x8002d,
      0x900ba,
      0x8000d,
      0x8008d,
      0x8004d,
      0x900fa,
      0x70101,
      0x80053,
      0x80013,
      0x8011b,
      0x70111,
      0x80073,
      0x80033,
      0x900c6,
      0x70109,
      0x80063,
      0x80023,
      0x900a6,
      0x80003,
      0x80083,
      0x80043,
      0x900e6,
      0x70105,
      0x8005b,
      0x8001b,
      0x90096,
      0x70115,
      0x8007b,
      0x8003b,
      0x900d6,
      0x7010d,
      0x8006b,
      0x8002b,
      0x900b6,
      0x8000b,
      0x8008b,
      0x8004b,
      0x900f6,
      0x70103,
      0x80057,
      0x80017,
      0x8011f,
      0x70113,
      0x80077,
      0x80037,
      0x900ce,
      0x7010b,
      0x80067,
      0x80027,
      0x900ae,
      0x80007,
      0x80087,
      0x80047,
      0x900ee,
      0x70107,
      0x8005f,
      0x8001f,
      0x9009e,
      0x70117,
      0x8007f,
      0x8003f,
      0x900de,
      0x7010f,
      0x8006f,
      0x8002f,
      0x900be,
      0x8000f,
      0x8008f,
      0x8004f,
      0x900fe,
      0x70100,
      0x80050,
      0x80010,
      0x80118,
      0x70110,
      0x80070,
      0x80030,
      0x900c1,
      0x70108,
      0x80060,
      0x80020,
      0x900a1,
      0x80000,
      0x80080,
      0x80040,
      0x900e1,
      0x70104,
      0x80058,
      0x80018,
      0x90091,
      0x70114,
      0x80078,
      0x80038,
      0x900d1,
      0x7010c,
      0x80068,
      0x80028,
      0x900b1,
      0x80008,
      0x80088,
      0x80048,
      0x900f1,
      0x70102,
      0x80054,
      0x80014,
      0x8011c,
      0x70112,
      0x80074,
      0x80034,
      0x900c9,
      0x7010a,
      0x80064,
      0x80024,
      0x900a9,
      0x80004,
      0x80084,
      0x80044,
      0x900e9,
      0x70106,
      0x8005c,
      0x8001c,
      0x90099,
      0x70116,
      0x8007c,
      0x8003c,
      0x900d9,
      0x7010e,
      0x8006c,
      0x8002c,
      0x900b9,
      0x8000c,
      0x8008c,
      0x8004c,
      0x900f9,
      0x70101,
      0x80052,
      0x80012,
      0x8011a,
      0x70111,
      0x80072,
      0x80032,
      0x900c5,
      0x70109,
      0x80062,
      0x80022,
      0x900a5,
      0x80002,
      0x80082,
      0x80042,
      0x900e5,
      0x70105,
      0x8005a,
      0x8001a,
      0x90095,
      0x70115,
      0x8007a,
      0x8003a,
      0x900d5,
      0x7010d,
      0x8006a,
      0x8002a,
      0x900b5,
      0x8000a,
      0x8008a,
      0x8004a,
      0x900f5,
      0x70103,
      0x80056,
      0x80016,
      0x8011e,
      0x70113,
      0x80076,
      0x80036,
      0x900cd,
      0x7010b,
      0x80066,
      0x80026,
      0x900ad,
      0x80006,
      0x80086,
      0x80046,
      0x900ed,
      0x70107,
      0x8005e,
      0x8001e,
      0x9009d,
      0x70117,
      0x8007e,
      0x8003e,
      0x900dd,
      0x7010f,
      0x8006e,
      0x8002e,
      0x900bd,
      0x8000e,
      0x8008e,
      0x8004e,
      0x900fd,
      0x70100,
      0x80051,
      0x80011,
      0x80119,
      0x70110,
      0x80071,
      0x80031,
      0x900c3,
      0x70108,
      0x80061,
      0x80021,
      0x900a3,
      0x80001,
      0x80081,
      0x80041,
      0x900e3,
      0x70104,
      0x80059,
      0x80019,
      0x90093,
      0x70114,
      0x80079,
      0x80039,
      0x900d3,
      0x7010c,
      0x80069,
      0x80029,
      0x900b3,
      0x80009,
      0x80089,
      0x80049,
      0x900f3,
      0x70102,
      0x80055,
      0x80015,
      0x8011d,
      0x70112,
      0x80075,
      0x80035,
      0x900cb,
      0x7010a,
      0x80065,
      0x80025,
      0x900ab,
      0x80005,
      0x80085,
      0x80045,
      0x900eb,
      0x70106,
      0x8005d,
      0x8001d,
      0x9009b,
      0x70116,
      0x8007d,
      0x8003d,
      0x900db,
      0x7010e,
      0x8006d,
      0x8002d,
      0x900bb,
      0x8000d,
      0x8008d,
      0x8004d,
      0x900fb,
      0x70101,
      0x80053,
      0x80013,
      0x8011b,
      0x70111,
      0x80073,
      0x80033,
      0x900c7,
      0x70109,
      0x80063,
      0x80023,
      0x900a7,
      0x80003,
      0x80083,
      0x80043,
      0x900e7,
      0x70105,
      0x8005b,
      0x8001b,
      0x90097,
      0x70115,
      0x8007b,
      0x8003b,
      0x900d7,
      0x7010d,
      0x8006b,
      0x8002b,
      0x900b7,
      0x8000b,
      0x8008b,
      0x8004b,
      0x900f7,
      0x70103,
      0x80057,
      0x80017,
      0x8011f,
      0x70113,
      0x80077,
      0x80037,
      0x900cf,
      0x7010b,
      0x80067,
      0x80027,
      0x900af,
      0x80007,
      0x80087,
      0x80047,
      0x900ef,
      0x70107,
      0x8005f,
      0x8001f,
      0x9009f,
      0x70117,
      0x8007f,
      0x8003f,
      0x900df,
      0x7010f,
      0x8006f,
      0x8002f,
      0x900bf,
      0x8000f,
      0x8008f,
      0x8004f,
      0x900ff
     ]),
     9
    ];
    var fixedDistCodeTab = [
     new Int32Array([
      0x50000,
      0x50010,
      0x50008,
      0x50018,
      0x50004,
      0x50014,
      0x5000c,
      0x5001c,
      0x50002,
      0x50012,
      0x5000a,
      0x5001a,
      0x50006,
      0x50016,
      0x5000e,
      0x00000,
      0x50001,
      0x50011,
      0x50009,
      0x50019,
      0x50005,
      0x50015,
      0x5000d,
      0x5001d,
      0x50003,
      0x50013,
      0x5000b,
      0x5001b,
      0x50007,
      0x50017,
      0x5000f,
      0x00000
     ]),
     5
    ];
    function FlateStream(str, maybeLength) {
     this.str = str;
     this.dict = str.dict;
     var cmf = str.getByte();
     var flg = str.getByte();
     if (cmf === -1 || flg === -1) {
      error('Invalid header in flate stream: ' + cmf + ', ' + flg);
     }
     if ((cmf & 0x0f) !== 0x08) {
      error('Unknown compression method in flate stream: ' + cmf + ', ' + flg);
     }
     if (((cmf << 8) + flg) % 31 !== 0) {
      error('Bad FCHECK in flate stream: ' + cmf + ', ' + flg);
     }
     if (flg & 0x20) {
      error('FDICT bit set in flate stream: ' + cmf + ', ' + flg);
     }
     this.codeSize = 0;
     this.codeBuf = 0;
     DecodeStream.call(this, maybeLength);
    }
    FlateStream.prototype = Object.create(DecodeStream.prototype);
    FlateStream.prototype.getBits = function FlateStream_getBits(bits) {
     var str = this.str;
     var codeSize = this.codeSize;
     var codeBuf = this.codeBuf;
     var b;
     while (codeSize < bits) {
      if ((b = str.getByte()) === -1) {
       error('Bad encoding in flate stream');
      }
      codeBuf |= b << codeSize;
      codeSize += 8;
     }
     b = codeBuf & (1 << bits) - 1;
     this.codeBuf = codeBuf >> bits;
     this.codeSize = codeSize -= bits;
     return b;
    };
    FlateStream.prototype.getCode = function FlateStream_getCode(table) {
     var str = this.str;
     var codes = table[0];
     var maxLen = table[1];
     var codeSize = this.codeSize;
     var codeBuf = this.codeBuf;
     var b;
     while (codeSize < maxLen) {
      if ((b = str.getByte()) === -1) {
       break;
      }
      codeBuf |= b << codeSize;
      codeSize += 8;
     }
     var code = codes[codeBuf & (1 << maxLen) - 1];
     var codeLen = code >> 16;
     var codeVal = code & 0xffff;
     if (codeLen < 1 || codeSize < codeLen) {
      error('Bad encoding in flate stream');
     }
     this.codeBuf = codeBuf >> codeLen;
     this.codeSize = codeSize - codeLen;
     return codeVal;
    };
    FlateStream.prototype.generateHuffmanTable = function flateStreamGenerateHuffmanTable(lengths) {
     var n = lengths.length;
     var maxLen = 0;
     var i;
     for (i = 0; i < n; ++i) {
      if (lengths[i] > maxLen) {
       maxLen = lengths[i];
      }
     }
     var size = 1 << maxLen;
     var codes = new Int32Array(size);
     for (var len = 1, code = 0, skip = 2; len <= maxLen; ++len, code <<= 1, skip <<= 1) {
      for (var val = 0; val < n; ++val) {
       if (lengths[val] === len) {
        var code2 = 0;
        var t = code;
        for (i = 0; i < len; ++i) {
         code2 = code2 << 1 | t & 1;
         t >>= 1;
        }
        for (i = code2; i < size; i += skip) {
         codes[i] = len << 16 | val;
        }
        ++code;
       }
      }
     }
     return [
      codes,
      maxLen
     ];
    };
    FlateStream.prototype.readBlock = function FlateStream_readBlock() {
     var buffer, len;
     var str = this.str;
     var hdr = this.getBits(3);
     if (hdr & 1) {
      this.eof = true;
     }
     hdr >>= 1;
     if (hdr === 0) {
      var b;
      if ((b = str.getByte()) === -1) {
       error('Bad block header in flate stream');
      }
      var blockLen = b;
      if ((b = str.getByte()) === -1) {
       error('Bad block header in flate stream');
      }
      blockLen |= b << 8;
      if ((b = str.getByte()) === -1) {
       error('Bad block header in flate stream');
      }
      var check = b;
      if ((b = str.getByte()) === -1) {
       error('Bad block header in flate stream');
      }
      check |= b << 8;
      if (check !== (~blockLen & 0xffff) && (blockLen !== 0 || check !== 0)) {
       error('Bad uncompressed block length in flate stream');
      }
      this.codeBuf = 0;
      this.codeSize = 0;
      var bufferLength = this.bufferLength;
      buffer = this.ensureBuffer(bufferLength + blockLen);
      var end = bufferLength + blockLen;
      this.bufferLength = end;
      if (blockLen === 0) {
       if (str.peekByte() === -1) {
        this.eof = true;
       }
      } else {
       for (var n = bufferLength; n < end; ++n) {
        if ((b = str.getByte()) === -1) {
         this.eof = true;
         break;
        }
        buffer[n] = b;
       }
      }
      return;
     }
     var litCodeTable;
     var distCodeTable;
     if (hdr === 1) {
      litCodeTable = fixedLitCodeTab;
      distCodeTable = fixedDistCodeTab;
     } else if (hdr === 2) {
      var numLitCodes = this.getBits(5) + 257;
      var numDistCodes = this.getBits(5) + 1;
      var numCodeLenCodes = this.getBits(4) + 4;
      var codeLenCodeLengths = new Uint8Array(codeLenCodeMap.length);
      var i;
      for (i = 0; i < numCodeLenCodes; ++i) {
       codeLenCodeLengths[codeLenCodeMap[i]] = this.getBits(3);
      }
      var codeLenCodeTab = this.generateHuffmanTable(codeLenCodeLengths);
      len = 0;
      i = 0;
      var codes = numLitCodes + numDistCodes;
      var codeLengths = new Uint8Array(codes);
      var bitsLength, bitsOffset, what;
      while (i < codes) {
       var code = this.getCode(codeLenCodeTab);
       if (code === 16) {
        bitsLength = 2;
        bitsOffset = 3;
        what = len;
       } else if (code === 17) {
        bitsLength = 3;
        bitsOffset = 3;
        what = len = 0;
       } else if (code === 18) {
        bitsLength = 7;
        bitsOffset = 11;
        what = len = 0;
       } else {
        codeLengths[i++] = len = code;
        continue;
       }
       var repeatLength = this.getBits(bitsLength) + bitsOffset;
       while (repeatLength-- > 0) {
        codeLengths[i++] = what;
       }
      }
      litCodeTable = this.generateHuffmanTable(codeLengths.subarray(0, numLitCodes));
      distCodeTable = this.generateHuffmanTable(codeLengths.subarray(numLitCodes, codes));
     } else {
      error('Unknown block type in flate stream');
     }
     buffer = this.buffer;
     var limit = buffer ? buffer.length : 0;
     var pos = this.bufferLength;
     while (true) {
      var code1 = this.getCode(litCodeTable);
      if (code1 < 256) {
       if (pos + 1 >= limit) {
        buffer = this.ensureBuffer(pos + 1);
        limit = buffer.length;
       }
       buffer[pos++] = code1;
       continue;
      }
      if (code1 === 256) {
       this.bufferLength = pos;
       return;
      }
      code1 -= 257;
      code1 = lengthDecode[code1];
      var code2 = code1 >> 16;
      if (code2 > 0) {
       code2 = this.getBits(code2);
      }
      len = (code1 & 0xffff) + code2;
      code1 = this.getCode(distCodeTable);
      code1 = distDecode[code1];
      code2 = code1 >> 16;
      if (code2 > 0) {
       code2 = this.getBits(code2);
      }
      var dist = (code1 & 0xffff) + code2;
      if (pos + len >= limit) {
       buffer = this.ensureBuffer(pos + len);
       limit = buffer.length;
      }
      for (var k = 0; k < len; ++k, ++pos) {
       buffer[pos] = buffer[pos - dist];
      }
     }
    };
    return FlateStream;
   }();
   var PredictorStream = function PredictorStreamClosure() {
    function PredictorStream(str, maybeLength, params) {
     if (!isDict(params)) {
      return str;
     }
     var predictor = this.predictor = params.get('Predictor') || 1;
     if (predictor <= 1) {
      return str;
     }
     if (predictor !== 2 && (predictor < 10 || predictor > 15)) {
      error('Unsupported predictor: ' + predictor);
     }
     if (predictor === 2) {
      this.readBlock = this.readBlockTiff;
     } else {
      this.readBlock = this.readBlockPng;
     }
     this.str = str;
     this.dict = str.dict;
     var colors = this.colors = params.get('Colors') || 1;
     var bits = this.bits = params.get('BitsPerComponent') || 8;
     var columns = this.columns = params.get('Columns') || 1;
     this.pixBytes = colors * bits + 7 >> 3;
     this.rowBytes = columns * colors * bits + 7 >> 3;
     DecodeStream.call(this, maybeLength);
     return this;
    }
    PredictorStream.prototype = Object.create(DecodeStream.prototype);
    PredictorStream.prototype.readBlockTiff = function predictorStreamReadBlockTiff() {
     var rowBytes = this.rowBytes;
     var bufferLength = this.bufferLength;
     var buffer = this.ensureBuffer(bufferLength + rowBytes);
     var bits = this.bits;
     var colors = this.colors;
     var rawBytes = this.str.getBytes(rowBytes);
     this.eof = !rawBytes.length;
     if (this.eof) {
      return;
     }
     var inbuf = 0, outbuf = 0;
     var inbits = 0, outbits = 0;
     var pos = bufferLength;
     var i;
     if (bits === 1) {
      for (i = 0; i < rowBytes; ++i) {
       var c = rawBytes[i];
       inbuf = inbuf << 8 | c;
       buffer[pos++] = (c ^ inbuf >> colors) & 0xFF;
       inbuf &= 0xFFFF;
      }
     } else if (bits === 8) {
      for (i = 0; i < colors; ++i) {
       buffer[pos++] = rawBytes[i];
      }
      for (; i < rowBytes; ++i) {
       buffer[pos] = buffer[pos - colors] + rawBytes[i];
       pos++;
      }
     } else {
      var compArray = new Uint8Array(colors + 1);
      var bitMask = (1 << bits) - 1;
      var j = 0, k = bufferLength;
      var columns = this.columns;
      for (i = 0; i < columns; ++i) {
       for (var kk = 0; kk < colors; ++kk) {
        if (inbits < bits) {
         inbuf = inbuf << 8 | rawBytes[j++] & 0xFF;
         inbits += 8;
        }
        compArray[kk] = compArray[kk] + (inbuf >> inbits - bits) & bitMask;
        inbits -= bits;
        outbuf = outbuf << bits | compArray[kk];
        outbits += bits;
        if (outbits >= 8) {
         buffer[k++] = outbuf >> outbits - 8 & 0xFF;
         outbits -= 8;
        }
       }
      }
      if (outbits > 0) {
       buffer[k++] = (outbuf << 8 - outbits) + (inbuf & (1 << 8 - outbits) - 1);
      }
     }
     this.bufferLength += rowBytes;
    };
    PredictorStream.prototype.readBlockPng = function predictorStreamReadBlockPng() {
     var rowBytes = this.rowBytes;
     var pixBytes = this.pixBytes;
     var predictor = this.str.getByte();
     var rawBytes = this.str.getBytes(rowBytes);
     this.eof = !rawBytes.length;
     if (this.eof) {
      return;
     }
     var bufferLength = this.bufferLength;
     var buffer = this.ensureBuffer(bufferLength + rowBytes);
     var prevRow = buffer.subarray(bufferLength - rowBytes, bufferLength);
     if (prevRow.length === 0) {
      prevRow = new Uint8Array(rowBytes);
     }
     var i, j = bufferLength, up, c;
     switch (predictor) {
     case 0:
      for (i = 0; i < rowBytes; ++i) {
       buffer[j++] = rawBytes[i];
      }
      break;
     case 1:
      for (i = 0; i < pixBytes; ++i) {
       buffer[j++] = rawBytes[i];
      }
      for (; i < rowBytes; ++i) {
       buffer[j] = buffer[j - pixBytes] + rawBytes[i] & 0xFF;
       j++;
      }
      break;
     case 2:
      for (i = 0; i < rowBytes; ++i) {
       buffer[j++] = prevRow[i] + rawBytes[i] & 0xFF;
      }
      break;
     case 3:
      for (i = 0; i < pixBytes; ++i) {
       buffer[j++] = (prevRow[i] >> 1) + rawBytes[i];
      }
      for (; i < rowBytes; ++i) {
       buffer[j] = (prevRow[i] + buffer[j - pixBytes] >> 1) + rawBytes[i] & 0xFF;
       j++;
      }
      break;
     case 4:
      for (i = 0; i < pixBytes; ++i) {
       up = prevRow[i];
       c = rawBytes[i];
       buffer[j++] = up + c;
      }
      for (; i < rowBytes; ++i) {
       up = prevRow[i];
       var upLeft = prevRow[i - pixBytes];
       var left = buffer[j - pixBytes];
       var p = left + up - upLeft;
       var pa = p - left;
       if (pa < 0) {
        pa = -pa;
       }
       var pb = p - up;
       if (pb < 0) {
        pb = -pb;
       }
       var pc = p - upLeft;
       if (pc < 0) {
        pc = -pc;
       }
       c = rawBytes[i];
       if (pa <= pb && pa <= pc) {
        buffer[j++] = left + c;
       } else if (pb <= pc) {
        buffer[j++] = up + c;
       } else {
        buffer[j++] = upLeft + c;
       }
      }
      break;
     default:
      error('Unsupported predictor: ' + predictor);
     }
     this.bufferLength += rowBytes;
    };
    return PredictorStream;
   }();
   var JpegStream = function JpegStreamClosure() {
    function JpegStream(stream, maybeLength, dict, params) {
     var ch;
     while ((ch = stream.getByte()) !== -1) {
      if (ch === 0xFF) {
       stream.skip(-1);
       break;
      }
     }
     this.stream = stream;
     this.maybeLength = maybeLength;
     this.dict = dict;
     this.params = params;
     DecodeStream.call(this, maybeLength);
    }
    JpegStream.prototype = Object.create(DecodeStream.prototype);
    Object.defineProperty(JpegStream.prototype, 'bytes', {
     get: function JpegStream_bytes() {
      return shadow(this, 'bytes', this.stream.getBytes(this.maybeLength));
     },
     configurable: true
    });
    JpegStream.prototype.ensureBuffer = function JpegStream_ensureBuffer(req) {
     if (this.bufferLength) {
      return;
     }
     var jpegImage = new JpegImage();
     var decodeArr = this.dict.getArray('Decode', 'D');
     if (this.forceRGB && isArray(decodeArr)) {
      var bitsPerComponent = this.dict.get('BitsPerComponent') || 8;
      var decodeArrLength = decodeArr.length;
      var transform = new Int32Array(decodeArrLength);
      var transformNeeded = false;
      var maxValue = (1 << bitsPerComponent) - 1;
      for (var i = 0; i < decodeArrLength; i += 2) {
       transform[i] = (decodeArr[i + 1] - decodeArr[i]) * 256 | 0;
       transform[i + 1] = decodeArr[i] * maxValue | 0;
       if (transform[i] !== 256 || transform[i + 1] !== 0) {
        transformNeeded = true;
       }
      }
      if (transformNeeded) {
       jpegImage.decodeTransform = transform;
      }
     }
     if (isDict(this.params)) {
      var colorTransform = this.params.get('ColorTransform');
      if (isInt(colorTransform)) {
       jpegImage.colorTransform = colorTransform;
      }
     }
     jpegImage.parse(this.bytes);
     var data = jpegImage.getData(this.drawWidth, this.drawHeight, this.forceRGB);
     this.buffer = data;
     this.bufferLength = data.length;
     this.eof = true;
    };
    JpegStream.prototype.getBytes = function JpegStream_getBytes(length) {
     this.ensureBuffer();
     return this.buffer;
    };
    JpegStream.prototype.getIR = function JpegStream_getIR(forceDataSchema) {
     return createObjectURL(this.bytes, 'image/jpeg', forceDataSchema);
    };
    return JpegStream;
   }();
   var JpxStream = function JpxStreamClosure() {
    function JpxStream(stream, maybeLength, dict, params) {
     this.stream = stream;
     this.maybeLength = maybeLength;
     this.dict = dict;
     this.params = params;
     DecodeStream.call(this, maybeLength);
    }
    JpxStream.prototype = Object.create(DecodeStream.prototype);
    Object.defineProperty(JpxStream.prototype, 'bytes', {
     get: function JpxStream_bytes() {
      return shadow(this, 'bytes', this.stream.getBytes(this.maybeLength));
     },
     configurable: true
    });
    JpxStream.prototype.ensureBuffer = function JpxStream_ensureBuffer(req) {
     if (this.bufferLength) {
      return;
     }
     var jpxImage = new JpxImage();
     jpxImage.parse(this.bytes);
     var width = jpxImage.width;
     var height = jpxImage.height;
     var componentsCount = jpxImage.componentsCount;
     var tileCount = jpxImage.tiles.length;
     if (tileCount === 1) {
      this.buffer = jpxImage.tiles[0].items;
     } else {
      var data = new Uint8Array(width * height * componentsCount);
      for (var k = 0; k < tileCount; k++) {
       var tileComponents = jpxImage.tiles[k];
       var tileWidth = tileComponents.width;
       var tileHeight = tileComponents.height;
       var tileLeft = tileComponents.left;
       var tileTop = tileComponents.top;
       var src = tileComponents.items;
       var srcPosition = 0;
       var dataPosition = (width * tileTop + tileLeft) * componentsCount;
       var imgRowSize = width * componentsCount;
       var tileRowSize = tileWidth * componentsCount;
       for (var j = 0; j < tileHeight; j++) {
        var rowBytes = src.subarray(srcPosition, srcPosition + tileRowSize);
        data.set(rowBytes, dataPosition);
        srcPosition += tileRowSize;
        dataPosition += imgRowSize;
       }
      }
      this.buffer = data;
     }
     this.bufferLength = this.buffer.length;
     this.eof = true;
    };
    return JpxStream;
   }();
   var Jbig2Stream = function Jbig2StreamClosure() {
    function Jbig2Stream(stream, maybeLength, dict, params) {
     this.stream = stream;
     this.maybeLength = maybeLength;
     this.dict = dict;
     this.params = params;
     DecodeStream.call(this, maybeLength);
    }
    Jbig2Stream.prototype = Object.create(DecodeStream.prototype);
    Object.defineProperty(Jbig2Stream.prototype, 'bytes', {
     get: function Jbig2Stream_bytes() {
      return shadow(this, 'bytes', this.stream.getBytes(this.maybeLength));
     },
     configurable: true
    });
    Jbig2Stream.prototype.ensureBuffer = function Jbig2Stream_ensureBuffer(req) {
     if (this.bufferLength) {
      return;
     }
     var jbig2Image = new Jbig2Image();
     var chunks = [];
     if (isDict(this.params)) {
      var globalsStream = this.params.get('JBIG2Globals');
      if (isStream(globalsStream)) {
       var globals = globalsStream.getBytes();
       chunks.push({
        data: globals,
        start: 0,
        end: globals.length
       });
      }
     }
     chunks.push({
      data: this.bytes,
      start: 0,
      end: this.bytes.length
     });
     var data = jbig2Image.parseChunks(chunks);
     var dataLength = data.length;
     for (var i = 0; i < dataLength; i++) {
      data[i] ^= 0xFF;
     }
     this.buffer = data;
     this.bufferLength = dataLength;
     this.eof = true;
    };
    return Jbig2Stream;
   }();
   var DecryptStream = function DecryptStreamClosure() {
    function DecryptStream(str, maybeLength, decrypt) {
     this.str = str;
     this.dict = str.dict;
     this.decrypt = decrypt;
     this.nextChunk = null;
     this.initialized = false;
     DecodeStream.call(this, maybeLength);
    }
    var chunkSize = 512;
    DecryptStream.prototype = Object.create(DecodeStream.prototype);
    DecryptStream.prototype.readBlock = function DecryptStream_readBlock() {
     var chunk;
     if (this.initialized) {
      chunk = this.nextChunk;
     } else {
      chunk = this.str.getBytes(chunkSize);
      this.initialized = true;
     }
     if (!chunk || chunk.length === 0) {
      this.eof = true;
      return;
     }
     this.nextChunk = this.str.getBytes(chunkSize);
     var hasMoreData = this.nextChunk && this.nextChunk.length > 0;
     var decrypt = this.decrypt;
     chunk = decrypt(chunk, !hasMoreData);
     var bufferLength = this.bufferLength;
     var i, n = chunk.length;
     var buffer = this.ensureBuffer(bufferLength + n);
     for (i = 0; i < n; i++) {
      buffer[bufferLength++] = chunk[i];
     }
     this.bufferLength = bufferLength;
    };
    return DecryptStream;
   }();
   var Ascii85Stream = function Ascii85StreamClosure() {
    function Ascii85Stream(str, maybeLength) {
     this.str = str;
     this.dict = str.dict;
     this.input = new Uint8Array(5);
     if (maybeLength) {
      maybeLength = 0.8 * maybeLength;
     }
     DecodeStream.call(this, maybeLength);
    }
    Ascii85Stream.prototype = Object.create(DecodeStream.prototype);
    Ascii85Stream.prototype.readBlock = function Ascii85Stream_readBlock() {
     var TILDA_CHAR = 0x7E;
     var Z_LOWER_CHAR = 0x7A;
     var EOF = -1;
     var str = this.str;
     var c = str.getByte();
     while (isSpace(c)) {
      c = str.getByte();
     }
     if (c === EOF || c === TILDA_CHAR) {
      this.eof = true;
      return;
     }
     var bufferLength = this.bufferLength, buffer;
     var i;
     if (c === Z_LOWER_CHAR) {
      buffer = this.ensureBuffer(bufferLength + 4);
      for (i = 0; i < 4; ++i) {
       buffer[bufferLength + i] = 0;
      }
      this.bufferLength += 4;
     } else {
      var input = this.input;
      input[0] = c;
      for (i = 1; i < 5; ++i) {
       c = str.getByte();
       while (isSpace(c)) {
        c = str.getByte();
       }
       input[i] = c;
       if (c === EOF || c === TILDA_CHAR) {
        break;
       }
      }
      buffer = this.ensureBuffer(bufferLength + i - 1);
      this.bufferLength += i - 1;
      if (i < 5) {
       for (; i < 5; ++i) {
        input[i] = 0x21 + 84;
       }
       this.eof = true;
      }
      var t = 0;
      for (i = 0; i < 5; ++i) {
       t = t * 85 + (input[i] - 0x21);
      }
      for (i = 3; i >= 0; --i) {
       buffer[bufferLength + i] = t & 0xFF;
       t >>= 8;
      }
     }
    };
    return Ascii85Stream;
   }();
   var AsciiHexStream = function AsciiHexStreamClosure() {
    function AsciiHexStream(str, maybeLength) {
     this.str = str;
     this.dict = str.dict;
     this.firstDigit = -1;
     if (maybeLength) {
      maybeLength = 0.5 * maybeLength;
     }
     DecodeStream.call(this, maybeLength);
    }
    AsciiHexStream.prototype = Object.create(DecodeStream.prototype);
    AsciiHexStream.prototype.readBlock = function AsciiHexStream_readBlock() {
     var UPSTREAM_BLOCK_SIZE = 8000;
     var bytes = this.str.getBytes(UPSTREAM_BLOCK_SIZE);
     if (!bytes.length) {
      this.eof = true;
      return;
     }
     var maxDecodeLength = bytes.length + 1 >> 1;
     var buffer = this.ensureBuffer(this.bufferLength + maxDecodeLength);
     var bufferLength = this.bufferLength;
     var firstDigit = this.firstDigit;
     for (var i = 0, ii = bytes.length; i < ii; i++) {
      var ch = bytes[i], digit;
      if (ch >= 0x30 && ch <= 0x39) {
       digit = ch & 0x0F;
      } else if (ch >= 0x41 && ch <= 0x46 || ch >= 0x61 && ch <= 0x66) {
       digit = (ch & 0x0F) + 9;
      } else if (ch === 0x3E) {
       this.eof = true;
       break;
      } else {
       continue;
      }
      if (firstDigit < 0) {
       firstDigit = digit;
      } else {
       buffer[bufferLength++] = firstDigit << 4 | digit;
       firstDigit = -1;
      }
     }
     if (firstDigit >= 0 && this.eof) {
      buffer[bufferLength++] = firstDigit << 4;
      firstDigit = -1;
     }
     this.firstDigit = firstDigit;
     this.bufferLength = bufferLength;
    };
    return AsciiHexStream;
   }();
   var RunLengthStream = function RunLengthStreamClosure() {
    function RunLengthStream(str, maybeLength) {
     this.str = str;
     this.dict = str.dict;
     DecodeStream.call(this, maybeLength);
    }
    RunLengthStream.prototype = Object.create(DecodeStream.prototype);
    RunLengthStream.prototype.readBlock = function RunLengthStream_readBlock() {
     var repeatHeader = this.str.getBytes(2);
     if (!repeatHeader || repeatHeader.length < 2 || repeatHeader[0] === 128) {
      this.eof = true;
      return;
     }
     var buffer;
     var bufferLength = this.bufferLength;
     var n = repeatHeader[0];
     if (n < 128) {
      buffer = this.ensureBuffer(bufferLength + n + 1);
      buffer[bufferLength++] = repeatHeader[1];
      if (n > 0) {
       var source = this.str.getBytes(n);
       buffer.set(source, bufferLength);
       bufferLength += n;
      }
     } else {
      n = 257 - n;
      var b = repeatHeader[1];
      buffer = this.ensureBuffer(bufferLength + n + 1);
      for (var i = 0; i < n; i++) {
       buffer[bufferLength++] = b;
      }
     }
     this.bufferLength = bufferLength;
    };
    return RunLengthStream;
   }();
   var CCITTFaxStream = function CCITTFaxStreamClosure() {
    var ccittEOL = -2;
    var ccittEOF = -1;
    var twoDimPass = 0;
    var twoDimHoriz = 1;
    var twoDimVert0 = 2;
    var twoDimVertR1 = 3;
    var twoDimVertL1 = 4;
    var twoDimVertR2 = 5;
    var twoDimVertL2 = 6;
    var twoDimVertR3 = 7;
    var twoDimVertL3 = 8;
    var twoDimTable = [
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      7,
      twoDimVertL3
     ],
     [
      7,
      twoDimVertR3
     ],
     [
      6,
      twoDimVertL2
     ],
     [
      6,
      twoDimVertL2
     ],
     [
      6,
      twoDimVertR2
     ],
     [
      6,
      twoDimVertR2
     ],
     [
      4,
      twoDimPass
     ],
     [
      4,
      twoDimPass
     ],
     [
      4,
      twoDimPass
     ],
     [
      4,
      twoDimPass
     ],
     [
      4,
      twoDimPass
     ],
     [
      4,
      twoDimPass
     ],
     [
      4,
      twoDimPass
     ],
     [
      4,
      twoDimPass
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimHoriz
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertL1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      3,
      twoDimVertR1
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ],
     [
      1,
      twoDimVert0
     ]
    ];
    var whiteTable1 = [
     [
      -1,
      -1
     ],
     [
      12,
      ccittEOL
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      11,
      1792
     ],
     [
      11,
      1792
     ],
     [
      12,
      1984
     ],
     [
      12,
      2048
     ],
     [
      12,
      2112
     ],
     [
      12,
      2176
     ],
     [
      12,
      2240
     ],
     [
      12,
      2304
     ],
     [
      11,
      1856
     ],
     [
      11,
      1856
     ],
     [
      11,
      1920
     ],
     [
      11,
      1920
     ],
     [
      12,
      2368
     ],
     [
      12,
      2432
     ],
     [
      12,
      2496
     ],
     [
      12,
      2560
     ]
    ];
    var whiteTable2 = [
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      8,
      29
     ],
     [
      8,
      29
     ],
     [
      8,
      30
     ],
     [
      8,
      30
     ],
     [
      8,
      45
     ],
     [
      8,
      45
     ],
     [
      8,
      46
     ],
     [
      8,
      46
     ],
     [
      7,
      22
     ],
     [
      7,
      22
     ],
     [
      7,
      22
     ],
     [
      7,
      22
     ],
     [
      7,
      23
     ],
     [
      7,
      23
     ],
     [
      7,
      23
     ],
     [
      7,
      23
     ],
     [
      8,
      47
     ],
     [
      8,
      47
     ],
     [
      8,
      48
     ],
     [
      8,
      48
     ],
     [
      6,
      13
     ],
     [
      6,
      13
     ],
     [
      6,
      13
     ],
     [
      6,
      13
     ],
     [
      6,
      13
     ],
     [
      6,
      13
     ],
     [
      6,
      13
     ],
     [
      6,
      13
     ],
     [
      7,
      20
     ],
     [
      7,
      20
     ],
     [
      7,
      20
     ],
     [
      7,
      20
     ],
     [
      8,
      33
     ],
     [
      8,
      33
     ],
     [
      8,
      34
     ],
     [
      8,
      34
     ],
     [
      8,
      35
     ],
     [
      8,
      35
     ],
     [
      8,
      36
     ],
     [
      8,
      36
     ],
     [
      8,
      37
     ],
     [
      8,
      37
     ],
     [
      8,
      38
     ],
     [
      8,
      38
     ],
     [
      7,
      19
     ],
     [
      7,
      19
     ],
     [
      7,
      19
     ],
     [
      7,
      19
     ],
     [
      8,
      31
     ],
     [
      8,
      31
     ],
     [
      8,
      32
     ],
     [
      8,
      32
     ],
     [
      6,
      1
     ],
     [
      6,
      1
     ],
     [
      6,
      1
     ],
     [
      6,
      1
     ],
     [
      6,
      1
     ],
     [
      6,
      1
     ],
     [
      6,
      1
     ],
     [
      6,
      1
     ],
     [
      6,
      12
     ],
     [
      6,
      12
     ],
     [
      6,
      12
     ],
     [
      6,
      12
     ],
     [
      6,
      12
     ],
     [
      6,
      12
     ],
     [
      6,
      12
     ],
     [
      6,
      12
     ],
     [
      8,
      53
     ],
     [
      8,
      53
     ],
     [
      8,
      54
     ],
     [
      8,
      54
     ],
     [
      7,
      26
     ],
     [
      7,
      26
     ],
     [
      7,
      26
     ],
     [
      7,
      26
     ],
     [
      8,
      39
     ],
     [
      8,
      39
     ],
     [
      8,
      40
     ],
     [
      8,
      40
     ],
     [
      8,
      41
     ],
     [
      8,
      41
     ],
     [
      8,
      42
     ],
     [
      8,
      42
     ],
     [
      8,
      43
     ],
     [
      8,
      43
     ],
     [
      8,
      44
     ],
     [
      8,
      44
     ],
     [
      7,
      21
     ],
     [
      7,
      21
     ],
     [
      7,
      21
     ],
     [
      7,
      21
     ],
     [
      7,
      28
     ],
     [
      7,
      28
     ],
     [
      7,
      28
     ],
     [
      7,
      28
     ],
     [
      8,
      61
     ],
     [
      8,
      61
     ],
     [
      8,
      62
     ],
     [
      8,
      62
     ],
     [
      8,
      63
     ],
     [
      8,
      63
     ],
     [
      8,
      0
     ],
     [
      8,
      0
     ],
     [
      8,
      320
     ],
     [
      8,
      320
     ],
     [
      8,
      384
     ],
     [
      8,
      384
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      10
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      5,
      11
     ],
     [
      7,
      27
     ],
     [
      7,
      27
     ],
     [
      7,
      27
     ],
     [
      7,
      27
     ],
     [
      8,
      59
     ],
     [
      8,
      59
     ],
     [
      8,
      60
     ],
     [
      8,
      60
     ],
     [
      9,
      1472
     ],
     [
      9,
      1536
     ],
     [
      9,
      1600
     ],
     [
      9,
      1728
     ],
     [
      7,
      18
     ],
     [
      7,
      18
     ],
     [
      7,
      18
     ],
     [
      7,
      18
     ],
     [
      7,
      24
     ],
     [
      7,
      24
     ],
     [
      7,
      24
     ],
     [
      7,
      24
     ],
     [
      8,
      49
     ],
     [
      8,
      49
     ],
     [
      8,
      50
     ],
     [
      8,
      50
     ],
     [
      8,
      51
     ],
     [
      8,
      51
     ],
     [
      8,
      52
     ],
     [
      8,
      52
     ],
     [
      7,
      25
     ],
     [
      7,
      25
     ],
     [
      7,
      25
     ],
     [
      7,
      25
     ],
     [
      8,
      55
     ],
     [
      8,
      55
     ],
     [
      8,
      56
     ],
     [
      8,
      56
     ],
     [
      8,
      57
     ],
     [
      8,
      57
     ],
     [
      8,
      58
     ],
     [
      8,
      58
     ],
     [
      6,
      192
     ],
     [
      6,
      192
     ],
     [
      6,
      192
     ],
     [
      6,
      192
     ],
     [
      6,
      192
     ],
     [
      6,
      192
     ],
     [
      6,
      192
     ],
     [
      6,
      192
     ],
     [
      6,
      1664
     ],
     [
      6,
      1664
     ],
     [
      6,
      1664
     ],
     [
      6,
      1664
     ],
     [
      6,
      1664
     ],
     [
      6,
      1664
     ],
     [
      6,
      1664
     ],
     [
      6,
      1664
     ],
     [
      8,
      448
     ],
     [
      8,
      448
     ],
     [
      8,
      512
     ],
     [
      8,
      512
     ],
     [
      9,
      704
     ],
     [
      9,
      768
     ],
     [
      8,
      640
     ],
     [
      8,
      640
     ],
     [
      8,
      576
     ],
     [
      8,
      576
     ],
     [
      9,
      832
     ],
     [
      9,
      896
     ],
     [
      9,
      960
     ],
     [
      9,
      1024
     ],
     [
      9,
      1088
     ],
     [
      9,
      1152
     ],
     [
      9,
      1216
     ],
     [
      9,
      1280
     ],
     [
      9,
      1344
     ],
     [
      9,
      1408
     ],
     [
      7,
      256
     ],
     [
      7,
      256
     ],
     [
      7,
      256
     ],
     [
      7,
      256
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      2
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      4,
      3
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      128
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      8
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      5,
      9
     ],
     [
      6,
      16
     ],
     [
      6,
      16
     ],
     [
      6,
      16
     ],
     [
      6,
      16
     ],
     [
      6,
      16
     ],
     [
      6,
      16
     ],
     [
      6,
      16
     ],
     [
      6,
      16
     ],
     [
      6,
      17
     ],
     [
      6,
      17
     ],
     [
      6,
      17
     ],
     [
      6,
      17
     ],
     [
      6,
      17
     ],
     [
      6,
      17
     ],
     [
      6,
      17
     ],
     [
      6,
      17
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      4
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      6,
      14
     ],
     [
      6,
      14
     ],
     [
      6,
      14
     ],
     [
      6,
      14
     ],
     [
      6,
      14
     ],
     [
      6,
      14
     ],
     [
      6,
      14
     ],
     [
      6,
      14
     ],
     [
      6,
      15
     ],
     [
      6,
      15
     ],
     [
      6,
      15
     ],
     [
      6,
      15
     ],
     [
      6,
      15
     ],
     [
      6,
      15
     ],
     [
      6,
      15
     ],
     [
      6,
      15
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      5,
      64
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ],
     [
      4,
      7
     ]
    ];
    var blackTable1 = [
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      12,
      ccittEOL
     ],
     [
      12,
      ccittEOL
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      11,
      1792
     ],
     [
      11,
      1792
     ],
     [
      11,
      1792
     ],
     [
      11,
      1792
     ],
     [
      12,
      1984
     ],
     [
      12,
      1984
     ],
     [
      12,
      2048
     ],
     [
      12,
      2048
     ],
     [
      12,
      2112
     ],
     [
      12,
      2112
     ],
     [
      12,
      2176
     ],
     [
      12,
      2176
     ],
     [
      12,
      2240
     ],
     [
      12,
      2240
     ],
     [
      12,
      2304
     ],
     [
      12,
      2304
     ],
     [
      11,
      1856
     ],
     [
      11,
      1856
     ],
     [
      11,
      1856
     ],
     [
      11,
      1856
     ],
     [
      11,
      1920
     ],
     [
      11,
      1920
     ],
     [
      11,
      1920
     ],
     [
      11,
      1920
     ],
     [
      12,
      2368
     ],
     [
      12,
      2368
     ],
     [
      12,
      2432
     ],
     [
      12,
      2432
     ],
     [
      12,
      2496
     ],
     [
      12,
      2496
     ],
     [
      12,
      2560
     ],
     [
      12,
      2560
     ],
     [
      10,
      18
     ],
     [
      10,
      18
     ],
     [
      10,
      18
     ],
     [
      10,
      18
     ],
     [
      10,
      18
     ],
     [
      10,
      18
     ],
     [
      10,
      18
     ],
     [
      10,
      18
     ],
     [
      12,
      52
     ],
     [
      12,
      52
     ],
     [
      13,
      640
     ],
     [
      13,
      704
     ],
     [
      13,
      768
     ],
     [
      13,
      832
     ],
     [
      12,
      55
     ],
     [
      12,
      55
     ],
     [
      12,
      56
     ],
     [
      12,
      56
     ],
     [
      13,
      1280
     ],
     [
      13,
      1344
     ],
     [
      13,
      1408
     ],
     [
      13,
      1472
     ],
     [
      12,
      59
     ],
     [
      12,
      59
     ],
     [
      12,
      60
     ],
     [
      12,
      60
     ],
     [
      13,
      1536
     ],
     [
      13,
      1600
     ],
     [
      11,
      24
     ],
     [
      11,
      24
     ],
     [
      11,
      24
     ],
     [
      11,
      24
     ],
     [
      11,
      25
     ],
     [
      11,
      25
     ],
     [
      11,
      25
     ],
     [
      11,
      25
     ],
     [
      13,
      1664
     ],
     [
      13,
      1728
     ],
     [
      12,
      320
     ],
     [
      12,
      320
     ],
     [
      12,
      384
     ],
     [
      12,
      384
     ],
     [
      12,
      448
     ],
     [
      12,
      448
     ],
     [
      13,
      512
     ],
     [
      13,
      576
     ],
     [
      12,
      53
     ],
     [
      12,
      53
     ],
     [
      12,
      54
     ],
     [
      12,
      54
     ],
     [
      13,
      896
     ],
     [
      13,
      960
     ],
     [
      13,
      1024
     ],
     [
      13,
      1088
     ],
     [
      13,
      1152
     ],
     [
      13,
      1216
     ],
     [
      10,
      64
     ],
     [
      10,
      64
     ],
     [
      10,
      64
     ],
     [
      10,
      64
     ],
     [
      10,
      64
     ],
     [
      10,
      64
     ],
     [
      10,
      64
     ],
     [
      10,
      64
     ]
    ];
    var blackTable2 = [
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      8,
      13
     ],
     [
      11,
      23
     ],
     [
      11,
      23
     ],
     [
      12,
      50
     ],
     [
      12,
      51
     ],
     [
      12,
      44
     ],
     [
      12,
      45
     ],
     [
      12,
      46
     ],
     [
      12,
      47
     ],
     [
      12,
      57
     ],
     [
      12,
      58
     ],
     [
      12,
      61
     ],
     [
      12,
      256
     ],
     [
      10,
      16
     ],
     [
      10,
      16
     ],
     [
      10,
      16
     ],
     [
      10,
      16
     ],
     [
      10,
      17
     ],
     [
      10,
      17
     ],
     [
      10,
      17
     ],
     [
      10,
      17
     ],
     [
      12,
      48
     ],
     [
      12,
      49
     ],
     [
      12,
      62
     ],
     [
      12,
      63
     ],
     [
      12,
      30
     ],
     [
      12,
      31
     ],
     [
      12,
      32
     ],
     [
      12,
      33
     ],
     [
      12,
      40
     ],
     [
      12,
      41
     ],
     [
      11,
      22
     ],
     [
      11,
      22
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      8,
      14
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      10
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      7,
      11
     ],
     [
      9,
      15
     ],
     [
      9,
      15
     ],
     [
      9,
      15
     ],
     [
      9,
      15
     ],
     [
      9,
      15
     ],
     [
      9,
      15
     ],
     [
      9,
      15
     ],
     [
      9,
      15
     ],
     [
      12,
      128
     ],
     [
      12,
      192
     ],
     [
      12,
      26
     ],
     [
      12,
      27
     ],
     [
      12,
      28
     ],
     [
      12,
      29
     ],
     [
      11,
      19
     ],
     [
      11,
      19
     ],
     [
      11,
      20
     ],
     [
      11,
      20
     ],
     [
      12,
      34
     ],
     [
      12,
      35
     ],
     [
      12,
      36
     ],
     [
      12,
      37
     ],
     [
      12,
      38
     ],
     [
      12,
      39
     ],
     [
      11,
      21
     ],
     [
      11,
      21
     ],
     [
      12,
      42
     ],
     [
      12,
      43
     ],
     [
      10,
      0
     ],
     [
      10,
      0
     ],
     [
      10,
      0
     ],
     [
      10,
      0
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ],
     [
      7,
      12
     ]
    ];
    var blackTable3 = [
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      -1,
      -1
     ],
     [
      6,
      9
     ],
     [
      6,
      8
     ],
     [
      5,
      7
     ],
     [
      5,
      7
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      6
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      4,
      5
     ],
     [
      3,
      1
     ],
     [
      3,
      1
     ],
     [
      3,
      1
     ],
     [
      3,
      1
     ],
     [
      3,
      1
     ],
     [
      3,
      1
     ],
     [
      3,
      1
     ],
     [
      3,
      1
     ],
     [
      3,
      4
     ],
     [
      3,
      4
     ],
     [
      3,
      4
     ],
     [
      3,
      4
     ],
     [
      3,
      4
     ],
     [
      3,
      4
     ],
     [
      3,
      4
     ],
     [
      3,
      4
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      3
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ],
     [
      2,
      2
     ]
    ];
    function CCITTFaxStream(str, maybeLength, params) {
     this.str = str;
     this.dict = str.dict;
     params = params || Dict.empty;
     this.encoding = params.get('K') || 0;
     this.eoline = params.get('EndOfLine') || false;
     this.byteAlign = params.get('EncodedByteAlign') || false;
     this.columns = params.get('Columns') || 1728;
     this.rows = params.get('Rows') || 0;
     var eoblock = params.get('EndOfBlock');
     if (eoblock === null || eoblock === undefined) {
      eoblock = true;
     }
     this.eoblock = eoblock;
     this.black = params.get('BlackIs1') || false;
     this.codingLine = new Uint32Array(this.columns + 1);
     this.refLine = new Uint32Array(this.columns + 2);
     this.codingLine[0] = this.columns;
     this.codingPos = 0;
     this.row = 0;
     this.nextLine2D = this.encoding < 0;
     this.inputBits = 0;
     this.inputBuf = 0;
     this.outputBits = 0;
     var code1;
     while ((code1 = this.lookBits(12)) === 0) {
      this.eatBits(1);
     }
     if (code1 === 1) {
      this.eatBits(12);
     }
     if (this.encoding > 0) {
      this.nextLine2D = !this.lookBits(1);
      this.eatBits(1);
     }
     DecodeStream.call(this, maybeLength);
    }
    CCITTFaxStream.prototype = Object.create(DecodeStream.prototype);
    CCITTFaxStream.prototype.readBlock = function CCITTFaxStream_readBlock() {
     while (!this.eof) {
      var c = this.lookChar();
      this.ensureBuffer(this.bufferLength + 1);
      this.buffer[this.bufferLength++] = c;
     }
    };
    CCITTFaxStream.prototype.addPixels = function ccittFaxStreamAddPixels(a1, blackPixels) {
     var codingLine = this.codingLine;
     var codingPos = this.codingPos;
     if (a1 > codingLine[codingPos]) {
      if (a1 > this.columns) {
       info('row is wrong length');
       this.err = true;
       a1 = this.columns;
      }
      if (codingPos & 1 ^ blackPixels) {
       ++codingPos;
      }
      codingLine[codingPos] = a1;
     }
     this.codingPos = codingPos;
    };
    CCITTFaxStream.prototype.addPixelsNeg = function ccittFaxStreamAddPixelsNeg(a1, blackPixels) {
     var codingLine = this.codingLine;
     var codingPos = this.codingPos;
     if (a1 > codingLine[codingPos]) {
      if (a1 > this.columns) {
       info('row is wrong length');
       this.err = true;
       a1 = this.columns;
      }
      if (codingPos & 1 ^ blackPixels) {
       ++codingPos;
      }
      codingLine[codingPos] = a1;
     } else if (a1 < codingLine[codingPos]) {
      if (a1 < 0) {
       info('invalid code');
       this.err = true;
       a1 = 0;
      }
      while (codingPos > 0 && a1 < codingLine[codingPos - 1]) {
       --codingPos;
      }
      codingLine[codingPos] = a1;
     }
     this.codingPos = codingPos;
    };
    CCITTFaxStream.prototype.lookChar = function CCITTFaxStream_lookChar() {
     var refLine = this.refLine;
     var codingLine = this.codingLine;
     var columns = this.columns;
     var refPos, blackPixels, bits, i;
     if (this.outputBits === 0) {
      if (this.eof) {
       return null;
      }
      this.err = false;
      var code1, code2, code3;
      if (this.nextLine2D) {
       for (i = 0; codingLine[i] < columns; ++i) {
        refLine[i] = codingLine[i];
       }
       refLine[i++] = columns;
       refLine[i] = columns;
       codingLine[0] = 0;
       this.codingPos = 0;
       refPos = 0;
       blackPixels = 0;
       while (codingLine[this.codingPos] < columns) {
        code1 = this.getTwoDimCode();
        switch (code1) {
        case twoDimPass:
         this.addPixels(refLine[refPos + 1], blackPixels);
         if (refLine[refPos + 1] < columns) {
          refPos += 2;
         }
         break;
        case twoDimHoriz:
         code1 = code2 = 0;
         if (blackPixels) {
          do {
           code1 += code3 = this.getBlackCode();
          } while (code3 >= 64);
          do {
           code2 += code3 = this.getWhiteCode();
          } while (code3 >= 64);
         } else {
          do {
           code1 += code3 = this.getWhiteCode();
          } while (code3 >= 64);
          do {
           code2 += code3 = this.getBlackCode();
          } while (code3 >= 64);
         }
         this.addPixels(codingLine[this.codingPos] + code1, blackPixels);
         if (codingLine[this.codingPos] < columns) {
          this.addPixels(codingLine[this.codingPos] + code2, blackPixels ^ 1);
         }
         while (refLine[refPos] <= codingLine[this.codingPos] && refLine[refPos] < columns) {
          refPos += 2;
         }
         break;
        case twoDimVertR3:
         this.addPixels(refLine[refPos] + 3, blackPixels);
         blackPixels ^= 1;
         if (codingLine[this.codingPos] < columns) {
          ++refPos;
          while (refLine[refPos] <= codingLine[this.codingPos] && refLine[refPos] < columns) {
           refPos += 2;
          }
         }
         break;
        case twoDimVertR2:
         this.addPixels(refLine[refPos] + 2, blackPixels);
         blackPixels ^= 1;
         if (codingLine[this.codingPos] < columns) {
          ++refPos;
          while (refLine[refPos] <= codingLine[this.codingPos] && refLine[refPos] < columns) {
           refPos += 2;
          }
         }
         break;
        case twoDimVertR1:
         this.addPixels(refLine[refPos] + 1, blackPixels);
         blackPixels ^= 1;
         if (codingLine[this.codingPos] < columns) {
          ++refPos;
          while (refLine[refPos] <= codingLine[this.codingPos] && refLine[refPos] < columns) {
           refPos += 2;
          }
         }
         break;
        case twoDimVert0:
         this.addPixels(refLine[refPos], blackPixels);
         blackPixels ^= 1;
         if (codingLine[this.codingPos] < columns) {
          ++refPos;
          while (refLine[refPos] <= codingLine[this.codingPos] && refLine[refPos] < columns) {
           refPos += 2;
          }
         }
         break;
        case twoDimVertL3:
         this.addPixelsNeg(refLine[refPos] - 3, blackPixels);
         blackPixels ^= 1;
         if (codingLine[this.codingPos] < columns) {
          if (refPos > 0) {
           --refPos;
          } else {
           ++refPos;
          }
          while (refLine[refPos] <= codingLine[this.codingPos] && refLine[refPos] < columns) {
           refPos += 2;
          }
         }
         break;
        case twoDimVertL2:
         this.addPixelsNeg(refLine[refPos] - 2, blackPixels);
         blackPixels ^= 1;
         if (codingLine[this.codingPos] < columns) {
          if (refPos > 0) {
           --refPos;
          } else {
           ++refPos;
          }
          while (refLine[refPos] <= codingLine[this.codingPos] && refLine[refPos] < columns) {
           refPos += 2;
          }
         }
         break;
        case twoDimVertL1:
         this.addPixelsNeg(refLine[refPos] - 1, blackPixels);
         blackPixels ^= 1;
         if (codingLine[this.codingPos] < columns) {
          if (refPos > 0) {
           --refPos;
          } else {
           ++refPos;
          }
          while (refLine[refPos] <= codingLine[this.codingPos] && refLine[refPos] < columns) {
           refPos += 2;
          }
         }
         break;
        case ccittEOF:
         this.addPixels(columns, 0);
         this.eof = true;
         break;
        default:
         info('bad 2d code');
         this.addPixels(columns, 0);
         this.err = true;
        }
       }
      } else {
       codingLine[0] = 0;
       this.codingPos = 0;
       blackPixels = 0;
       while (codingLine[this.codingPos] < columns) {
        code1 = 0;
        if (blackPixels) {
         do {
          code1 += code3 = this.getBlackCode();
         } while (code3 >= 64);
        } else {
         do {
          code1 += code3 = this.getWhiteCode();
         } while (code3 >= 64);
        }
        this.addPixels(codingLine[this.codingPos] + code1, blackPixels);
        blackPixels ^= 1;
       }
      }
      var gotEOL = false;
      if (this.byteAlign) {
       this.inputBits &= ~7;
      }
      if (!this.eoblock && this.row === this.rows - 1) {
       this.eof = true;
      } else {
       code1 = this.lookBits(12);
       if (this.eoline) {
        while (code1 !== ccittEOF && code1 !== 1) {
         this.eatBits(1);
         code1 = this.lookBits(12);
        }
       } else {
        while (code1 === 0) {
         this.eatBits(1);
         code1 = this.lookBits(12);
        }
       }
       if (code1 === 1) {
        this.eatBits(12);
        gotEOL = true;
       } else if (code1 === ccittEOF) {
        this.eof = true;
       }
      }
      if (!this.eof && this.encoding > 0) {
       this.nextLine2D = !this.lookBits(1);
       this.eatBits(1);
      }
      if (this.eoblock && gotEOL && this.byteAlign) {
       code1 = this.lookBits(12);
       if (code1 === 1) {
        this.eatBits(12);
        if (this.encoding > 0) {
         this.lookBits(1);
         this.eatBits(1);
        }
        if (this.encoding >= 0) {
         for (i = 0; i < 4; ++i) {
          code1 = this.lookBits(12);
          if (code1 !== 1) {
           info('bad rtc code: ' + code1);
          }
          this.eatBits(12);
          if (this.encoding > 0) {
           this.lookBits(1);
           this.eatBits(1);
          }
         }
        }
        this.eof = true;
       }
      } else if (this.err && this.eoline) {
       while (true) {
        code1 = this.lookBits(13);
        if (code1 === ccittEOF) {
         this.eof = true;
         return null;
        }
        if (code1 >> 1 === 1) {
         break;
        }
        this.eatBits(1);
       }
       this.eatBits(12);
       if (this.encoding > 0) {
        this.eatBits(1);
        this.nextLine2D = !(code1 & 1);
       }
      }
      if (codingLine[0] > 0) {
       this.outputBits = codingLine[this.codingPos = 0];
      } else {
       this.outputBits = codingLine[this.codingPos = 1];
      }
      this.row++;
     }
     var c;
     if (this.outputBits >= 8) {
      c = this.codingPos & 1 ? 0 : 0xFF;
      this.outputBits -= 8;
      if (this.outputBits === 0 && codingLine[this.codingPos] < columns) {
       this.codingPos++;
       this.outputBits = codingLine[this.codingPos] - codingLine[this.codingPos - 1];
      }
     } else {
      bits = 8;
      c = 0;
      do {
       if (this.outputBits > bits) {
        c <<= bits;
        if (!(this.codingPos & 1)) {
         c |= 0xFF >> 8 - bits;
        }
        this.outputBits -= bits;
        bits = 0;
       } else {
        c <<= this.outputBits;
        if (!(this.codingPos & 1)) {
         c |= 0xFF >> 8 - this.outputBits;
        }
        bits -= this.outputBits;
        this.outputBits = 0;
        if (codingLine[this.codingPos] < columns) {
         this.codingPos++;
         this.outputBits = codingLine[this.codingPos] - codingLine[this.codingPos - 1];
        } else if (bits > 0) {
         c <<= bits;
         bits = 0;
        }
       }
      } while (bits);
     }
     if (this.black) {
      c ^= 0xFF;
     }
     return c;
    };
    CCITTFaxStream.prototype.findTableCode = function ccittFaxStreamFindTableCode(start, end, table, limit) {
     var limitValue = limit || 0;
     for (var i = start; i <= end; ++i) {
      var code = this.lookBits(i);
      if (code === ccittEOF) {
       return [
        true,
        1,
        false
       ];
      }
      if (i < end) {
       code <<= end - i;
      }
      if (!limitValue || code >= limitValue) {
       var p = table[code - limitValue];
       if (p[0] === i) {
        this.eatBits(i);
        return [
         true,
         p[1],
         true
        ];
       }
      }
     }
     return [
      false,
      0,
      false
     ];
    };
    CCITTFaxStream.prototype.getTwoDimCode = function ccittFaxStreamGetTwoDimCode() {
     var code = 0;
     var p;
     if (this.eoblock) {
      code = this.lookBits(7);
      p = twoDimTable[code];
      if (p && p[0] > 0) {
       this.eatBits(p[0]);
       return p[1];
      }
     } else {
      var result = this.findTableCode(1, 7, twoDimTable);
      if (result[0] && result[2]) {
       return result[1];
      }
     }
     info('Bad two dim code');
     return ccittEOF;
    };
    CCITTFaxStream.prototype.getWhiteCode = function ccittFaxStreamGetWhiteCode() {
     var code = 0;
     var p;
     if (this.eoblock) {
      code = this.lookBits(12);
      if (code === ccittEOF) {
       return 1;
      }
      if (code >> 5 === 0) {
       p = whiteTable1[code];
      } else {
       p = whiteTable2[code >> 3];
      }
      if (p[0] > 0) {
       this.eatBits(p[0]);
       return p[1];
      }
     } else {
      var result = this.findTableCode(1, 9, whiteTable2);
      if (result[0]) {
       return result[1];
      }
      result = this.findTableCode(11, 12, whiteTable1);
      if (result[0]) {
       return result[1];
      }
     }
     info('bad white code');
     this.eatBits(1);
     return 1;
    };
    CCITTFaxStream.prototype.getBlackCode = function ccittFaxStreamGetBlackCode() {
     var code, p;
     if (this.eoblock) {
      code = this.lookBits(13);
      if (code === ccittEOF) {
       return 1;
      }
      if (code >> 7 === 0) {
       p = blackTable1[code];
      } else if (code >> 9 === 0 && code >> 7 !== 0) {
       p = blackTable2[(code >> 1) - 64];
      } else {
       p = blackTable3[code >> 7];
      }
      if (p[0] > 0) {
       this.eatBits(p[0]);
       return p[1];
      }
     } else {
      var result = this.findTableCode(2, 6, blackTable3);
      if (result[0]) {
       return result[1];
      }
      result = this.findTableCode(7, 12, blackTable2, 64);
      if (result[0]) {
       return result[1];
      }
      result = this.findTableCode(10, 13, blackTable1);
      if (result[0]) {
       return result[1];
      }
     }
     info('bad black code');
     this.eatBits(1);
     return 1;
    };
    CCITTFaxStream.prototype.lookBits = function CCITTFaxStream_lookBits(n) {
     var c;
     while (this.inputBits < n) {
      if ((c = this.str.getByte()) === -1) {
       if (this.inputBits === 0) {
        return ccittEOF;
       }
       return this.inputBuf << n - this.inputBits & 0xFFFF >> 16 - n;
      }
      this.inputBuf = this.inputBuf << 8 | c;
      this.inputBits += 8;
     }
     return this.inputBuf >> this.inputBits - n & 0xFFFF >> 16 - n;
    };
    CCITTFaxStream.prototype.eatBits = function CCITTFaxStream_eatBits(n) {
     if ((this.inputBits -= n) < 0) {
      this.inputBits = 0;
     }
    };
    return CCITTFaxStream;
   }();
   var LZWStream = function LZWStreamClosure() {
    function LZWStream(str, maybeLength, earlyChange) {
     this.str = str;
     this.dict = str.dict;
     this.cachedData = 0;
     this.bitsCached = 0;
     var maxLzwDictionarySize = 4096;
     var lzwState = {
      earlyChange: earlyChange,
      codeLength: 9,
      nextCode: 258,
      dictionaryValues: new Uint8Array(maxLzwDictionarySize),
      dictionaryLengths: new Uint16Array(maxLzwDictionarySize),
      dictionaryPrevCodes: new Uint16Array(maxLzwDictionarySize),
      currentSequence: new Uint8Array(maxLzwDictionarySize),
      currentSequenceLength: 0
     };
     for (var i = 0; i < 256; ++i) {
      lzwState.dictionaryValues[i] = i;
      lzwState.dictionaryLengths[i] = 1;
     }
     this.lzwState = lzwState;
     DecodeStream.call(this, maybeLength);
    }
    LZWStream.prototype = Object.create(DecodeStream.prototype);
    LZWStream.prototype.readBits = function LZWStream_readBits(n) {
     var bitsCached = this.bitsCached;
     var cachedData = this.cachedData;
     while (bitsCached < n) {
      var c = this.str.getByte();
      if (c === -1) {
       this.eof = true;
       return null;
      }
      cachedData = cachedData << 8 | c;
      bitsCached += 8;
     }
     this.bitsCached = bitsCached -= n;
     this.cachedData = cachedData;
     this.lastCode = null;
     return cachedData >>> bitsCached & (1 << n) - 1;
    };
    LZWStream.prototype.readBlock = function LZWStream_readBlock() {
     var blockSize = 512;
     var estimatedDecodedSize = blockSize * 2, decodedSizeDelta = blockSize;
     var i, j, q;
     var lzwState = this.lzwState;
     if (!lzwState) {
      return;
     }
     var earlyChange = lzwState.earlyChange;
     var nextCode = lzwState.nextCode;
     var dictionaryValues = lzwState.dictionaryValues;
     var dictionaryLengths = lzwState.dictionaryLengths;
     var dictionaryPrevCodes = lzwState.dictionaryPrevCodes;
     var codeLength = lzwState.codeLength;
     var prevCode = lzwState.prevCode;
     var currentSequence = lzwState.currentSequence;
     var currentSequenceLength = lzwState.currentSequenceLength;
     var decodedLength = 0;
     var currentBufferLength = this.bufferLength;
     var buffer = this.ensureBuffer(this.bufferLength + estimatedDecodedSize);
     for (i = 0; i < blockSize; i++) {
      var code = this.readBits(codeLength);
      var hasPrev = currentSequenceLength > 0;
      if (code < 256) {
       currentSequence[0] = code;
       currentSequenceLength = 1;
      } else if (code >= 258) {
       if (code < nextCode) {
        currentSequenceLength = dictionaryLengths[code];
        for (j = currentSequenceLength - 1, q = code; j >= 0; j--) {
         currentSequence[j] = dictionaryValues[q];
         q = dictionaryPrevCodes[q];
        }
       } else {
        currentSequence[currentSequenceLength++] = currentSequence[0];
       }
      } else if (code === 256) {
       codeLength = 9;
       nextCode = 258;
       currentSequenceLength = 0;
       continue;
      } else {
       this.eof = true;
       delete this.lzwState;
       break;
      }
      if (hasPrev) {
       dictionaryPrevCodes[nextCode] = prevCode;
       dictionaryLengths[nextCode] = dictionaryLengths[prevCode] + 1;
       dictionaryValues[nextCode] = currentSequence[0];
       nextCode++;
       codeLength = nextCode + earlyChange & nextCode + earlyChange - 1 ? codeLength : Math.min(Math.log(nextCode + earlyChange) / 0.6931471805599453 + 1, 12) | 0;
      }
      prevCode = code;
      decodedLength += currentSequenceLength;
      if (estimatedDecodedSize < decodedLength) {
       do {
        estimatedDecodedSize += decodedSizeDelta;
       } while (estimatedDecodedSize < decodedLength);
       buffer = this.ensureBuffer(this.bufferLength + estimatedDecodedSize);
      }
      for (j = 0; j < currentSequenceLength; j++) {
       buffer[currentBufferLength++] = currentSequence[j];
      }
     }
     lzwState.nextCode = nextCode;
     lzwState.codeLength = codeLength;
     lzwState.prevCode = prevCode;
     lzwState.currentSequenceLength = currentSequenceLength;
     this.bufferLength = currentBufferLength;
    };
    return LZWStream;
   }();
   var NullStream = function NullStreamClosure() {
    function NullStream() {
     Stream.call(this, new Uint8Array(0));
    }
    NullStream.prototype = Stream.prototype;
    return NullStream;
   }();
   exports.Ascii85Stream = Ascii85Stream;
   exports.AsciiHexStream = AsciiHexStream;
   exports.CCITTFaxStream = CCITTFaxStream;
   exports.DecryptStream = DecryptStream;
   exports.DecodeStream = DecodeStream;
   exports.FlateStream = FlateStream;
   exports.Jbig2Stream = Jbig2Stream;
   exports.JpegStream = JpegStream;
   exports.JpxStream = JpxStream;
   exports.NullStream = NullStream;
   exports.PredictorStream = PredictorStream;
   exports.RunLengthStream = RunLengthStream;
   exports.Stream = Stream;
   exports.StreamsSequenceStream = StreamsSequenceStream;
   exports.StringStream = StringStream;
   exports.LZWStream = LZWStream;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayAnnotationLayer = {}, root.pdfjsSharedUtil, root.pdfjsDisplayDOMUtils);
  }(this, function (exports, sharedUtil, displayDOMUtils) {
   var AnnotationBorderStyleType = sharedUtil.AnnotationBorderStyleType;
   var AnnotationType = sharedUtil.AnnotationType;
   var Util = sharedUtil.Util;
   var addLinkAttributes = displayDOMUtils.addLinkAttributes;
   var LinkTarget = displayDOMUtils.LinkTarget;
   var getFilenameFromUrl = displayDOMUtils.getFilenameFromUrl;
   var warn = sharedUtil.warn;
   var CustomStyle = displayDOMUtils.CustomStyle;
   var getDefaultSetting = displayDOMUtils.getDefaultSetting;
   function AnnotationElementFactory() {
   }
   AnnotationElementFactory.prototype = {
    create: function AnnotationElementFactory_create(parameters) {
     var subtype = parameters.data.annotationType;
     switch (subtype) {
     case AnnotationType.LINK:
      return new LinkAnnotationElement(parameters);
     case AnnotationType.TEXT:
      return new TextAnnotationElement(parameters);
     case AnnotationType.WIDGET:
      var fieldType = parameters.data.fieldType;
      switch (fieldType) {
      case 'Tx':
       return new TextWidgetAnnotationElement(parameters);
      case 'Btn':
       if (parameters.data.radioButton) {
        return new RadioButtonWidgetAnnotationElement(parameters);
       } else if (parameters.data.checkBox) {
        return new CheckboxWidgetAnnotationElement(parameters);
       } else {
        warn('Unimplemented button widget annotation: pushbutton');
       }
       break;
      case 'Ch':
       return new ChoiceWidgetAnnotationElement(parameters);
      }
      return new WidgetAnnotationElement(parameters);
     case AnnotationType.POPUP:
      return new PopupAnnotationElement(parameters);
     case AnnotationType.HIGHLIGHT:
      return new HighlightAnnotationElement(parameters);
     case AnnotationType.UNDERLINE:
      return new UnderlineAnnotationElement(parameters);
     case AnnotationType.SQUIGGLY:
      return new SquigglyAnnotationElement(parameters);
     case AnnotationType.STRIKEOUT:
      return new StrikeOutAnnotationElement(parameters);
     case AnnotationType.FILEATTACHMENT:
      return new FileAttachmentAnnotationElement(parameters);
     default:
      return new AnnotationElement(parameters);
     }
    }
   };
   var AnnotationElement = function AnnotationElementClosure() {
    function AnnotationElement(parameters, isRenderable) {
     this.isRenderable = isRenderable || false;
     this.data = parameters.data;
     this.layer = parameters.layer;
     this.page = parameters.page;
     this.viewport = parameters.viewport;
     this.linkService = parameters.linkService;
     this.downloadManager = parameters.downloadManager;
     this.imageResourcesPath = parameters.imageResourcesPath;
     this.renderInteractiveForms = parameters.renderInteractiveForms;
     if (isRenderable) {
      this.container = this._createContainer();
     }
    }
    AnnotationElement.prototype = {
     _createContainer: function AnnotationElement_createContainer() {
      var data = this.data, page = this.page, viewport = this.viewport;
      var container = document.createElement('section');
      var width = data.rect[2] - data.rect[0];
      var height = data.rect[3] - data.rect[1];
      container.setAttribute('data-annotation-id', data.id);
      var rect = Util.normalizeRect([
       data.rect[0],
       page.view[3] - data.rect[1] + page.view[1],
       data.rect[2],
       page.view[3] - data.rect[3] + page.view[1]
      ]);
      CustomStyle.setProp('transform', container, 'matrix(' + viewport.transform.join(',') + ')');
      CustomStyle.setProp('transformOrigin', container, -rect[0] + 'px ' + -rect[1] + 'px');
      if (data.borderStyle.width > 0) {
       container.style.borderWidth = data.borderStyle.width + 'px';
       if (data.borderStyle.style !== AnnotationBorderStyleType.UNDERLINE) {
        width = width - 2 * data.borderStyle.width;
        height = height - 2 * data.borderStyle.width;
       }
       var horizontalRadius = data.borderStyle.horizontalCornerRadius;
       var verticalRadius = data.borderStyle.verticalCornerRadius;
       if (horizontalRadius > 0 || verticalRadius > 0) {
        var radius = horizontalRadius + 'px / ' + verticalRadius + 'px';
        CustomStyle.setProp('borderRadius', container, radius);
       }
       switch (data.borderStyle.style) {
       case AnnotationBorderStyleType.SOLID:
        container.style.borderStyle = 'solid';
        break;
       case AnnotationBorderStyleType.DASHED:
        container.style.borderStyle = 'dashed';
        break;
       case AnnotationBorderStyleType.BEVELED:
        warn('Unimplemented border style: beveled');
        break;
       case AnnotationBorderStyleType.INSET:
        warn('Unimplemented border style: inset');
        break;
       case AnnotationBorderStyleType.UNDERLINE:
        container.style.borderBottomStyle = 'solid';
        break;
       default:
        break;
       }
       if (data.color) {
        container.style.borderColor = Util.makeCssRgb(data.color[0] | 0, data.color[1] | 0, data.color[2] | 0);
       } else {
        container.style.borderWidth = 0;
       }
      }
      container.style.left = rect[0] + 'px';
      container.style.top = rect[1] + 'px';
      container.style.width = width + 'px';
      container.style.height = height + 'px';
      return container;
     },
     _createPopup: function AnnotationElement_createPopup(container, trigger, data) {
      if (!trigger) {
       trigger = document.createElement('div');
       trigger.style.height = container.style.height;
       trigger.style.width = container.style.width;
       container.appendChild(trigger);
      }
      var popupElement = new PopupElement({
       container: container,
       trigger: trigger,
       color: data.color,
       title: data.title,
       contents: data.contents,
       hideWrapper: true
      });
      var popup = popupElement.render();
      popup.style.left = container.style.width;
      container.appendChild(popup);
     },
     render: function AnnotationElement_render() {
      throw new Error('Abstract method AnnotationElement.render called');
     }
    };
    return AnnotationElement;
   }();
   var LinkAnnotationElement = function LinkAnnotationElementClosure() {
    function LinkAnnotationElement(parameters) {
     AnnotationElement.call(this, parameters, true);
    }
    Util.inherit(LinkAnnotationElement, AnnotationElement, {
     render: function LinkAnnotationElement_render() {
      this.container.className = 'linkAnnotation';
      var link = document.createElement('a');
      addLinkAttributes(link, {
       url: this.data.url,
       target: this.data.newWindow ? LinkTarget.BLANK : undefined
      });
      if (!this.data.url) {
       if (this.data.action) {
        this._bindNamedAction(link, this.data.action);
       } else {
        this._bindLink(link, this.data.dest);
       }
      }
      this.container.appendChild(link);
      return this.container;
     },
     _bindLink: function LinkAnnotationElement_bindLink(link, destination) {
      var self = this;
      link.href = this.linkService.getDestinationHash(destination);
      link.onclick = function () {
       if (destination) {
        self.linkService.navigateTo(destination);
       }
       return false;
      };
      if (destination) {
       link.className = 'internalLink';
      }
     },
     _bindNamedAction: function LinkAnnotationElement_bindNamedAction(link, action) {
      var self = this;
      link.href = this.linkService.getAnchorUrl('');
      link.onclick = function () {
       self.linkService.executeNamedAction(action);
       return false;
      };
      link.className = 'internalLink';
     }
    });
    return LinkAnnotationElement;
   }();
   var TextAnnotationElement = function TextAnnotationElementClosure() {
    function TextAnnotationElement(parameters) {
     var isRenderable = !!(parameters.data.hasPopup || parameters.data.title || parameters.data.contents);
     AnnotationElement.call(this, parameters, isRenderable);
    }
    Util.inherit(TextAnnotationElement, AnnotationElement, {
     render: function TextAnnotationElement_render() {
      this.container.className = 'textAnnotation';
      var image = document.createElement('img');
      image.style.height = this.container.style.height;
      image.style.width = this.container.style.width;
      image.src = this.imageResourcesPath + 'annotation-' + this.data.name.toLowerCase() + '.svg';
      image.alt = '[{{type}} Annotation]';
      image.dataset.l10nId = 'text_annotation_type';
      image.dataset.l10nArgs = JSON.stringify({ type: this.data.name });
      if (!this.data.hasPopup) {
       this._createPopup(this.container, image, this.data);
      }
      this.container.appendChild(image);
      return this.container;
     }
    });
    return TextAnnotationElement;
   }();
   var WidgetAnnotationElement = function WidgetAnnotationElementClosure() {
    function WidgetAnnotationElement(parameters, isRenderable) {
     AnnotationElement.call(this, parameters, isRenderable);
    }
    Util.inherit(WidgetAnnotationElement, AnnotationElement, {
     render: function WidgetAnnotationElement_render() {
      return this.container;
     }
    });
    return WidgetAnnotationElement;
   }();
   var TextWidgetAnnotationElement = function TextWidgetAnnotationElementClosure() {
    var TEXT_ALIGNMENT = [
     'left',
     'center',
     'right'
    ];
    function TextWidgetAnnotationElement(parameters) {
     var isRenderable = parameters.renderInteractiveForms || !parameters.data.hasAppearance && !!parameters.data.fieldValue;
     WidgetAnnotationElement.call(this, parameters, isRenderable);
    }
    Util.inherit(TextWidgetAnnotationElement, WidgetAnnotationElement, {
     render: function TextWidgetAnnotationElement_render() {
      this.container.className = 'textWidgetAnnotation';
      var element = null;
      if (this.renderInteractiveForms) {
       if (this.data.multiLine) {
        element = document.createElement('textarea');
        element.textContent = this.data.fieldValue;
       } else {
        element = document.createElement('input');
        element.type = 'text';
        element.setAttribute('value', this.data.fieldValue);
       }
       element.disabled = this.data.readOnly;
       if (this.data.maxLen !== null) {
        element.maxLength = this.data.maxLen;
       }
       if (this.data.comb) {
        var fieldWidth = this.data.rect[2] - this.data.rect[0];
        var combWidth = fieldWidth / this.data.maxLen;
        element.classList.add('comb');
        element.style.letterSpacing = 'calc(' + combWidth + 'px - 1ch)';
       }
      } else {
       element = document.createElement('div');
       element.textContent = this.data.fieldValue;
       element.style.verticalAlign = 'middle';
       element.style.display = 'table-cell';
       var font = null;
       if (this.data.fontRefName) {
        font = this.page.commonObjs.getData(this.data.fontRefName);
       }
       this._setTextStyle(element, font);
      }
      if (this.data.textAlignment !== null) {
       element.style.textAlign = TEXT_ALIGNMENT[this.data.textAlignment];
      }
      this.container.appendChild(element);
      return this.container;
     },
     _setTextStyle: function TextWidgetAnnotationElement_setTextStyle(element, font) {
      var style = element.style;
      style.fontSize = this.data.fontSize + 'px';
      style.direction = this.data.fontDirection < 0 ? 'rtl' : 'ltr';
      if (!font) {
       return;
      }
      style.fontWeight = font.black ? font.bold ? '900' : 'bold' : font.bold ? 'bold' : 'normal';
      style.fontStyle = font.italic ? 'italic' : 'normal';
      var fontFamily = font.loadedName ? '"' + font.loadedName + '", ' : '';
      var fallbackName = font.fallbackName || 'Helvetica, sans-serif';
      style.fontFamily = fontFamily + fallbackName;
     }
    });
    return TextWidgetAnnotationElement;
   }();
   var CheckboxWidgetAnnotationElement = function CheckboxWidgetAnnotationElementClosure() {
    function CheckboxWidgetAnnotationElement(parameters) {
     WidgetAnnotationElement.call(this, parameters, parameters.renderInteractiveForms);
    }
    Util.inherit(CheckboxWidgetAnnotationElement, WidgetAnnotationElement, {
     render: function CheckboxWidgetAnnotationElement_render() {
      this.container.className = 'buttonWidgetAnnotation checkBox';
      var element = document.createElement('input');
      element.disabled = this.data.readOnly;
      element.type = 'checkbox';
      if (this.data.fieldValue && this.data.fieldValue !== 'Off') {
       element.setAttribute('checked', true);
      }
      this.container.appendChild(element);
      return this.container;
     }
    });
    return CheckboxWidgetAnnotationElement;
   }();
   var RadioButtonWidgetAnnotationElement = function RadioButtonWidgetAnnotationElementClosure() {
    function RadioButtonWidgetAnnotationElement(parameters) {
     WidgetAnnotationElement.call(this, parameters, parameters.renderInteractiveForms);
    }
    Util.inherit(RadioButtonWidgetAnnotationElement, WidgetAnnotationElement, {
     render: function RadioButtonWidgetAnnotationElement_render() {
      this.container.className = 'buttonWidgetAnnotation radioButton';
      var element = document.createElement('input');
      element.disabled = this.data.readOnly;
      element.type = 'radio';
      element.name = this.data.fieldName;
      if (this.data.fieldValue === this.data.buttonValue) {
       element.setAttribute('checked', true);
      }
      this.container.appendChild(element);
      return this.container;
     }
    });
    return RadioButtonWidgetAnnotationElement;
   }();
   var ChoiceWidgetAnnotationElement = function ChoiceWidgetAnnotationElementClosure() {
    function ChoiceWidgetAnnotationElement(parameters) {
     WidgetAnnotationElement.call(this, parameters, parameters.renderInteractiveForms);
    }
    Util.inherit(ChoiceWidgetAnnotationElement, WidgetAnnotationElement, {
     render: function ChoiceWidgetAnnotationElement_render() {
      this.container.className = 'choiceWidgetAnnotation';
      var selectElement = document.createElement('select');
      selectElement.disabled = this.data.readOnly;
      if (!this.data.combo) {
       selectElement.size = this.data.options.length;
       if (this.data.multiSelect) {
        selectElement.multiple = true;
       }
      }
      for (var i = 0, ii = this.data.options.length; i < ii; i++) {
       var option = this.data.options[i];
       var optionElement = document.createElement('option');
       optionElement.textContent = option.displayValue;
       optionElement.value = option.exportValue;
       if (this.data.fieldValue.indexOf(option.displayValue) >= 0) {
        optionElement.setAttribute('selected', true);
       }
       selectElement.appendChild(optionElement);
      }
      this.container.appendChild(selectElement);
      return this.container;
     }
    });
    return ChoiceWidgetAnnotationElement;
   }();
   var PopupAnnotationElement = function PopupAnnotationElementClosure() {
    function PopupAnnotationElement(parameters) {
     var isRenderable = !!(parameters.data.title || parameters.data.contents);
     AnnotationElement.call(this, parameters, isRenderable);
    }
    Util.inherit(PopupAnnotationElement, AnnotationElement, {
     render: function PopupAnnotationElement_render() {
      this.container.className = 'popupAnnotation';
      var selector = '[data-annotation-id="' + this.data.parentId + '"]';
      var parentElement = this.layer.querySelector(selector);
      if (!parentElement) {
       return this.container;
      }
      var popup = new PopupElement({
       container: this.container,
       trigger: parentElement,
       color: this.data.color,
       title: this.data.title,
       contents: this.data.contents
      });
      var parentLeft = parseFloat(parentElement.style.left);
      var parentWidth = parseFloat(parentElement.style.width);
      CustomStyle.setProp('transformOrigin', this.container, -(parentLeft + parentWidth) + 'px -' + parentElement.style.top);
      this.container.style.left = parentLeft + parentWidth + 'px';
      this.container.appendChild(popup.render());
      return this.container;
     }
    });
    return PopupAnnotationElement;
   }();
   var PopupElement = function PopupElementClosure() {
    var BACKGROUND_ENLIGHT = 0.7;
    function PopupElement(parameters) {
     this.container = parameters.container;
     this.trigger = parameters.trigger;
     this.color = parameters.color;
     this.title = parameters.title;
     this.contents = parameters.contents;
     this.hideWrapper = parameters.hideWrapper || false;
     this.pinned = false;
    }
    PopupElement.prototype = {
     render: function PopupElement_render() {
      var wrapper = document.createElement('div');
      wrapper.className = 'popupWrapper';
      this.hideElement = this.hideWrapper ? wrapper : this.container;
      this.hideElement.setAttribute('hidden', true);
      var popup = document.createElement('div');
      popup.className = 'popup';
      var color = this.color;
      if (color) {
       var r = BACKGROUND_ENLIGHT * (255 - color[0]) + color[0];
       var g = BACKGROUND_ENLIGHT * (255 - color[1]) + color[1];
       var b = BACKGROUND_ENLIGHT * (255 - color[2]) + color[2];
       popup.style.backgroundColor = Util.makeCssRgb(r | 0, g | 0, b | 0);
      }
      var contents = this._formatContents(this.contents);
      var title = document.createElement('h1');
      title.textContent = this.title;
      this.trigger.addEventListener('click', this._toggle.bind(this));
      this.trigger.addEventListener('mouseover', this._show.bind(this, false));
      this.trigger.addEventListener('mouseout', this._hide.bind(this, false));
      popup.addEventListener('click', this._hide.bind(this, true));
      popup.appendChild(title);
      popup.appendChild(contents);
      wrapper.appendChild(popup);
      return wrapper;
     },
     _formatContents: function PopupElement_formatContents(contents) {
      var p = document.createElement('p');
      var lines = contents.split(/(?:\r\n?|\n)/);
      for (var i = 0, ii = lines.length; i < ii; ++i) {
       var line = lines[i];
       p.appendChild(document.createTextNode(line));
       if (i < ii - 1) {
        p.appendChild(document.createElement('br'));
       }
      }
      return p;
     },
     _toggle: function PopupElement_toggle() {
      if (this.pinned) {
       this._hide(true);
      } else {
       this._show(true);
      }
     },
     _show: function PopupElement_show(pin) {
      if (pin) {
       this.pinned = true;
      }
      if (this.hideElement.hasAttribute('hidden')) {
       this.hideElement.removeAttribute('hidden');
       this.container.style.zIndex += 1;
      }
     },
     _hide: function PopupElement_hide(unpin) {
      if (unpin) {
       this.pinned = false;
      }
      if (!this.hideElement.hasAttribute('hidden') && !this.pinned) {
       this.hideElement.setAttribute('hidden', true);
       this.container.style.zIndex -= 1;
      }
     }
    };
    return PopupElement;
   }();
   var HighlightAnnotationElement = function HighlightAnnotationElementClosure() {
    function HighlightAnnotationElement(parameters) {
     var isRenderable = !!(parameters.data.hasPopup || parameters.data.title || parameters.data.contents);
     AnnotationElement.call(this, parameters, isRenderable);
    }
    Util.inherit(HighlightAnnotationElement, AnnotationElement, {
     render: function HighlightAnnotationElement_render() {
      this.container.className = 'highlightAnnotation';
      if (!this.data.hasPopup) {
       this._createPopup(this.container, null, this.data);
      }
      return this.container;
     }
    });
    return HighlightAnnotationElement;
   }();
   var UnderlineAnnotationElement = function UnderlineAnnotationElementClosure() {
    function UnderlineAnnotationElement(parameters) {
     var isRenderable = !!(parameters.data.hasPopup || parameters.data.title || parameters.data.contents);
     AnnotationElement.call(this, parameters, isRenderable);
    }
    Util.inherit(UnderlineAnnotationElement, AnnotationElement, {
     render: function UnderlineAnnotationElement_render() {
      this.container.className = 'underlineAnnotation';
      if (!this.data.hasPopup) {
       this._createPopup(this.container, null, this.data);
      }
      return this.container;
     }
    });
    return UnderlineAnnotationElement;
   }();
   var SquigglyAnnotationElement = function SquigglyAnnotationElementClosure() {
    function SquigglyAnnotationElement(parameters) {
     var isRenderable = !!(parameters.data.hasPopup || parameters.data.title || parameters.data.contents);
     AnnotationElement.call(this, parameters, isRenderable);
    }
    Util.inherit(SquigglyAnnotationElement, AnnotationElement, {
     render: function SquigglyAnnotationElement_render() {
      this.container.className = 'squigglyAnnotation';
      if (!this.data.hasPopup) {
       this._createPopup(this.container, null, this.data);
      }
      return this.container;
     }
    });
    return SquigglyAnnotationElement;
   }();
   var StrikeOutAnnotationElement = function StrikeOutAnnotationElementClosure() {
    function StrikeOutAnnotationElement(parameters) {
     var isRenderable = !!(parameters.data.hasPopup || parameters.data.title || parameters.data.contents);
     AnnotationElement.call(this, parameters, isRenderable);
    }
    Util.inherit(StrikeOutAnnotationElement, AnnotationElement, {
     render: function StrikeOutAnnotationElement_render() {
      this.container.className = 'strikeoutAnnotation';
      if (!this.data.hasPopup) {
       this._createPopup(this.container, null, this.data);
      }
      return this.container;
     }
    });
    return StrikeOutAnnotationElement;
   }();
   var FileAttachmentAnnotationElement = function FileAttachmentAnnotationElementClosure() {
    function FileAttachmentAnnotationElement(parameters) {
     AnnotationElement.call(this, parameters, true);
     this.filename = getFilenameFromUrl(parameters.data.file.filename);
     this.content = parameters.data.file.content;
    }
    Util.inherit(FileAttachmentAnnotationElement, AnnotationElement, {
     render: function FileAttachmentAnnotationElement_render() {
      this.container.className = 'fileAttachmentAnnotation';
      var trigger = document.createElement('div');
      trigger.style.height = this.container.style.height;
      trigger.style.width = this.container.style.width;
      trigger.addEventListener('dblclick', this._download.bind(this));
      if (!this.data.hasPopup && (this.data.title || this.data.contents)) {
       this._createPopup(this.container, trigger, this.data);
      }
      this.container.appendChild(trigger);
      return this.container;
     },
     _download: function FileAttachmentAnnotationElement_download() {
      if (!this.downloadManager) {
       warn('Download cannot be started due to unavailable download manager');
       return;
      }
      this.downloadManager.downloadData(this.content, this.filename, '');
     }
    });
    return FileAttachmentAnnotationElement;
   }();
   var AnnotationLayer = function AnnotationLayerClosure() {
    return {
     render: function AnnotationLayer_render(parameters) {
      var annotationElementFactory = new AnnotationElementFactory();
      for (var i = 0, ii = parameters.annotations.length; i < ii; i++) {
       var data = parameters.annotations[i];
       if (!data) {
        continue;
       }
       var properties = {
        data: data,
        layer: parameters.div,
        page: parameters.page,
        viewport: parameters.viewport,
        linkService: parameters.linkService,
        downloadManager: parameters.downloadManager,
        imageResourcesPath: parameters.imageResourcesPath || getDefaultSetting('imageResourcesPath'),
        renderInteractiveForms: parameters.renderInteractiveForms || false
       };
       var element = annotationElementFactory.create(properties);
       if (element.isRenderable) {
        parameters.div.appendChild(element.render());
       }
      }
     },
     update: function AnnotationLayer_update(parameters) {
      for (var i = 0, ii = parameters.annotations.length; i < ii; i++) {
       var data = parameters.annotations[i];
       var element = parameters.div.querySelector('[data-annotation-id="' + data.id + '"]');
       if (element) {
        CustomStyle.setProp('transform', element, 'matrix(' + parameters.viewport.transform.join(',') + ')');
       }
      }
      parameters.div.removeAttribute('hidden');
     }
    };
   }();
   exports.AnnotationLayer = AnnotationLayer;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayTextLayer = {}, root.pdfjsSharedUtil, root.pdfjsDisplayDOMUtils);
  }(this, function (exports, sharedUtil, displayDOMUtils) {
   var Util = sharedUtil.Util;
   var createPromiseCapability = sharedUtil.createPromiseCapability;
   var CustomStyle = displayDOMUtils.CustomStyle;
   var getDefaultSetting = displayDOMUtils.getDefaultSetting;
   var renderTextLayer = function renderTextLayerClosure() {
    var MAX_TEXT_DIVS_TO_RENDER = 100000;
    var NonWhitespaceRegexp = /\S/;
    function isAllWhitespace(str) {
     return !NonWhitespaceRegexp.test(str);
    }
    var styleBuf = [
     'left: ',
     0,
     'px; top: ',
     0,
     'px; font-size: ',
     0,
     'px; font-family: ',
     '',
     ';'
    ];
    function appendText(task, geom, styles) {
     var textDiv = document.createElement('div');
     var textDivProperties = {
      style: null,
      angle: 0,
      canvasWidth: 0,
      isWhitespace: false,
      originalTransform: null,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      scale: 1
     };
     task._textDivs.push(textDiv);
     if (isAllWhitespace(geom.str)) {
      textDivProperties.isWhitespace = true;
      task._textDivProperties.set(textDiv, textDivProperties);
      return;
     }
     var tx = Util.transform(task._viewport.transform, geom.transform);
     var angle = Math.atan2(tx[1], tx[0]);
     var style = styles[geom.fontName];
     if (style.vertical) {
      angle += Math.PI / 2;
     }
     var fontHeight = Math.sqrt(tx[2] * tx[2] + tx[3] * tx[3]);
     var fontAscent = fontHeight;
     if (style.ascent) {
      fontAscent = style.ascent * fontAscent;
     } else if (style.descent) {
      fontAscent = (1 + style.descent) * fontAscent;
     }
     var left;
     var top;
     if (angle === 0) {
      left = tx[4];
      top = tx[5] - fontAscent;
     } else {
      left = tx[4] + fontAscent * Math.sin(angle);
      top = tx[5] - fontAscent * Math.cos(angle);
     }
     styleBuf[1] = left;
     styleBuf[3] = top;
     styleBuf[5] = fontHeight;
     styleBuf[7] = style.fontFamily;
     textDivProperties.style = styleBuf.join('');
     textDiv.setAttribute('style', textDivProperties.style);
     textDiv.textContent = geom.str;
     if (getDefaultSetting('pdfBug')) {
      textDiv.dataset.fontName = geom.fontName;
     }
     if (angle !== 0) {
      textDivProperties.angle = angle * (180 / Math.PI);
     }
     if (geom.str.length > 1) {
      if (style.vertical) {
       textDivProperties.canvasWidth = geom.height * task._viewport.scale;
      } else {
       textDivProperties.canvasWidth = geom.width * task._viewport.scale;
      }
     }
     task._textDivProperties.set(textDiv, textDivProperties);
     if (task._enhanceTextSelection) {
      var angleCos = 1, angleSin = 0;
      if (angle !== 0) {
       angleCos = Math.cos(angle);
       angleSin = Math.sin(angle);
      }
      var divWidth = (style.vertical ? geom.height : geom.width) * task._viewport.scale;
      var divHeight = fontHeight;
      var m, b;
      if (angle !== 0) {
       m = [
        angleCos,
        angleSin,
        -angleSin,
        angleCos,
        left,
        top
       ];
       b = Util.getAxialAlignedBoundingBox([
        0,
        0,
        divWidth,
        divHeight
       ], m);
      } else {
       b = [
        left,
        top,
        left + divWidth,
        top + divHeight
       ];
      }
      task._bounds.push({
       left: b[0],
       top: b[1],
       right: b[2],
       bottom: b[3],
       div: textDiv,
       size: [
        divWidth,
        divHeight
       ],
       m: m
      });
     }
    }
    function render(task) {
     if (task._canceled) {
      return;
     }
     var textLayerFrag = task._container;
     var textDivs = task._textDivs;
     var capability = task._capability;
     var textDivsLength = textDivs.length;
     if (textDivsLength > MAX_TEXT_DIVS_TO_RENDER) {
      task._renderingDone = true;
      capability.resolve();
      return;
     }
     var canvas = document.createElement('canvas');
     var ctx = canvas.getContext('2d', { alpha: false });
     var lastFontSize;
     var lastFontFamily;
     for (var i = 0; i < textDivsLength; i++) {
      var textDiv = textDivs[i];
      var textDivProperties = task._textDivProperties.get(textDiv);
      if (textDivProperties.isWhitespace) {
       continue;
      }
      var fontSize = textDiv.style.fontSize;
      var fontFamily = textDiv.style.fontFamily;
      if (fontSize !== lastFontSize || fontFamily !== lastFontFamily) {
       ctx.font = fontSize + ' ' + fontFamily;
       lastFontSize = fontSize;
       lastFontFamily = fontFamily;
      }
      var width = ctx.measureText(textDiv.textContent).width;
      textLayerFrag.appendChild(textDiv);
      var transform = '';
      if (textDivProperties.canvasWidth !== 0 && width > 0) {
       textDivProperties.scale = textDivProperties.canvasWidth / width;
       transform = 'scaleX(' + textDivProperties.scale + ')';
      }
      if (textDivProperties.angle !== 0) {
       transform = 'rotate(' + textDivProperties.angle + 'deg) ' + transform;
      }
      if (transform !== '') {
       textDivProperties.originalTransform = transform;
       CustomStyle.setProp('transform', textDiv, transform);
      }
      task._textDivProperties.set(textDiv, textDivProperties);
     }
     task._renderingDone = true;
     capability.resolve();
    }
    function expand(task) {
     var bounds = task._bounds;
     var viewport = task._viewport;
     var expanded = expandBounds(viewport.width, viewport.height, bounds);
     for (var i = 0; i < expanded.length; i++) {
      var div = bounds[i].div;
      var divProperties = task._textDivProperties.get(div);
      if (divProperties.angle === 0) {
       divProperties.paddingLeft = bounds[i].left - expanded[i].left;
       divProperties.paddingTop = bounds[i].top - expanded[i].top;
       divProperties.paddingRight = expanded[i].right - bounds[i].right;
       divProperties.paddingBottom = expanded[i].bottom - bounds[i].bottom;
       task._textDivProperties.set(div, divProperties);
       continue;
      }
      var e = expanded[i], b = bounds[i];
      var m = b.m, c = m[0], s = m[1];
      var points = [
       [
        0,
        0
       ],
       [
        0,
        b.size[1]
       ],
       [
        b.size[0],
        0
       ],
       b.size
      ];
      var ts = new Float64Array(64);
      points.forEach(function (p, i) {
       var t = Util.applyTransform(p, m);
       ts[i + 0] = c && (e.left - t[0]) / c;
       ts[i + 4] = s && (e.top - t[1]) / s;
       ts[i + 8] = c && (e.right - t[0]) / c;
       ts[i + 12] = s && (e.bottom - t[1]) / s;
       ts[i + 16] = s && (e.left - t[0]) / -s;
       ts[i + 20] = c && (e.top - t[1]) / c;
       ts[i + 24] = s && (e.right - t[0]) / -s;
       ts[i + 28] = c && (e.bottom - t[1]) / c;
       ts[i + 32] = c && (e.left - t[0]) / -c;
       ts[i + 36] = s && (e.top - t[1]) / -s;
       ts[i + 40] = c && (e.right - t[0]) / -c;
       ts[i + 44] = s && (e.bottom - t[1]) / -s;
       ts[i + 48] = s && (e.left - t[0]) / s;
       ts[i + 52] = c && (e.top - t[1]) / -c;
       ts[i + 56] = s && (e.right - t[0]) / s;
       ts[i + 60] = c && (e.bottom - t[1]) / -c;
      });
      var findPositiveMin = function (ts, offset, count) {
       var result = 0;
       for (var i = 0; i < count; i++) {
        var t = ts[offset++];
        if (t > 0) {
         result = result ? Math.min(t, result) : t;
        }
       }
       return result;
      };
      var boxScale = 1 + Math.min(Math.abs(c), Math.abs(s));
      divProperties.paddingLeft = findPositiveMin(ts, 32, 16) / boxScale;
      divProperties.paddingTop = findPositiveMin(ts, 48, 16) / boxScale;
      divProperties.paddingRight = findPositiveMin(ts, 0, 16) / boxScale;
      divProperties.paddingBottom = findPositiveMin(ts, 16, 16) / boxScale;
      task._textDivProperties.set(div, divProperties);
     }
    }
    function expandBounds(width, height, boxes) {
     var bounds = boxes.map(function (box, i) {
      return {
       x1: box.left,
       y1: box.top,
       x2: box.right,
       y2: box.bottom,
       index: i,
       x1New: undefined,
       x2New: undefined
      };
     });
     expandBoundsLTR(width, bounds);
     var expanded = new Array(boxes.length);
     bounds.forEach(function (b) {
      var i = b.index;
      expanded[i] = {
       left: b.x1New,
       top: 0,
       right: b.x2New,
       bottom: 0
      };
     });
     boxes.map(function (box, i) {
      var e = expanded[i], b = bounds[i];
      b.x1 = box.top;
      b.y1 = width - e.right;
      b.x2 = box.bottom;
      b.y2 = width - e.left;
      b.index = i;
      b.x1New = undefined;
      b.x2New = undefined;
     });
     expandBoundsLTR(height, bounds);
     bounds.forEach(function (b) {
      var i = b.index;
      expanded[i].top = b.x1New;
      expanded[i].bottom = b.x2New;
     });
     return expanded;
    }
    function expandBoundsLTR(width, bounds) {
     bounds.sort(function (a, b) {
      return a.x1 - b.x1 || a.index - b.index;
     });
     var fakeBoundary = {
      x1: -Infinity,
      y1: -Infinity,
      x2: 0,
      y2: Infinity,
      index: -1,
      x1New: 0,
      x2New: 0
     };
     var horizon = [{
       start: -Infinity,
       end: Infinity,
       boundary: fakeBoundary
      }];
     bounds.forEach(function (boundary) {
      var i = 0;
      while (i < horizon.length && horizon[i].end <= boundary.y1) {
       i++;
      }
      var j = horizon.length - 1;
      while (j >= 0 && horizon[j].start >= boundary.y2) {
       j--;
      }
      var horizonPart, affectedBoundary;
      var q, k, maxXNew = -Infinity;
      for (q = i; q <= j; q++) {
       horizonPart = horizon[q];
       affectedBoundary = horizonPart.boundary;
       var xNew;
       if (affectedBoundary.x2 > boundary.x1) {
        xNew = affectedBoundary.index > boundary.index ? affectedBoundary.x1New : boundary.x1;
       } else if (affectedBoundary.x2New === undefined) {
        xNew = (affectedBoundary.x2 + boundary.x1) / 2;
       } else {
        xNew = affectedBoundary.x2New;
       }
       if (xNew > maxXNew) {
        maxXNew = xNew;
       }
      }
      boundary.x1New = maxXNew;
      for (q = i; q <= j; q++) {
       horizonPart = horizon[q];
       affectedBoundary = horizonPart.boundary;
       if (affectedBoundary.x2New === undefined) {
        if (affectedBoundary.x2 > boundary.x1) {
         if (affectedBoundary.index > boundary.index) {
          affectedBoundary.x2New = affectedBoundary.x2;
         }
        } else {
         affectedBoundary.x2New = maxXNew;
        }
       } else if (affectedBoundary.x2New > maxXNew) {
        affectedBoundary.x2New = Math.max(maxXNew, affectedBoundary.x2);
       }
      }
      var changedHorizon = [], lastBoundary = null;
      for (q = i; q <= j; q++) {
       horizonPart = horizon[q];
       affectedBoundary = horizonPart.boundary;
       var useBoundary = affectedBoundary.x2 > boundary.x2 ? affectedBoundary : boundary;
       if (lastBoundary === useBoundary) {
        changedHorizon[changedHorizon.length - 1].end = horizonPart.end;
       } else {
        changedHorizon.push({
         start: horizonPart.start,
         end: horizonPart.end,
         boundary: useBoundary
        });
        lastBoundary = useBoundary;
       }
      }
      if (horizon[i].start < boundary.y1) {
       changedHorizon[0].start = boundary.y1;
       changedHorizon.unshift({
        start: horizon[i].start,
        end: boundary.y1,
        boundary: horizon[i].boundary
       });
      }
      if (boundary.y2 < horizon[j].end) {
       changedHorizon[changedHorizon.length - 1].end = boundary.y2;
       changedHorizon.push({
        start: boundary.y2,
        end: horizon[j].end,
        boundary: horizon[j].boundary
       });
      }
      for (q = i; q <= j; q++) {
       horizonPart = horizon[q];
       affectedBoundary = horizonPart.boundary;
       if (affectedBoundary.x2New !== undefined) {
        continue;
       }
       var used = false;
       for (k = i - 1; !used && k >= 0 && horizon[k].start >= affectedBoundary.y1; k--) {
        used = horizon[k].boundary === affectedBoundary;
       }
       for (k = j + 1; !used && k < horizon.length && horizon[k].end <= affectedBoundary.y2; k++) {
        used = horizon[k].boundary === affectedBoundary;
       }
       for (k = 0; !used && k < changedHorizon.length; k++) {
        used = changedHorizon[k].boundary === affectedBoundary;
       }
       if (!used) {
        affectedBoundary.x2New = maxXNew;
       }
      }
      Array.prototype.splice.apply(horizon, [
       i,
       j - i + 1
      ].concat(changedHorizon));
     });
     horizon.forEach(function (horizonPart) {
      var affectedBoundary = horizonPart.boundary;
      if (affectedBoundary.x2New === undefined) {
       affectedBoundary.x2New = Math.max(width, affectedBoundary.x2);
      }
     });
    }
    function TextLayerRenderTask(textContent, container, viewport, textDivs, enhanceTextSelection) {
     this._textContent = textContent;
     this._container = container;
     this._viewport = viewport;
     this._textDivs = textDivs || [];
     this._textDivProperties = new WeakMap();
     this._renderingDone = false;
     this._canceled = false;
     this._capability = createPromiseCapability();
     this._renderTimer = null;
     this._bounds = [];
     this._enhanceTextSelection = !!enhanceTextSelection;
    }
    TextLayerRenderTask.prototype = {
     get promise() {
      return this._capability.promise;
     },
     cancel: function TextLayer_cancel() {
      this._canceled = true;
      if (this._renderTimer !== null) {
       clearTimeout(this._renderTimer);
       this._renderTimer = null;
      }
      this._capability.reject('canceled');
     },
     _render: function TextLayer_render(timeout) {
      var textItems = this._textContent.items;
      var textStyles = this._textContent.styles;
      for (var i = 0, len = textItems.length; i < len; i++) {
       appendText(this, textItems[i], textStyles);
      }
      if (!timeout) {
       render(this);
      } else {
       var self = this;
       this._renderTimer = setTimeout(function () {
        render(self);
        self._renderTimer = null;
       }, timeout);
      }
     },
     expandTextDivs: function TextLayer_expandTextDivs(expandDivs) {
      if (!this._enhanceTextSelection || !this._renderingDone) {
       return;
      }
      if (this._bounds !== null) {
       expand(this);
       this._bounds = null;
      }
      for (var i = 0, ii = this._textDivs.length; i < ii; i++) {
       var div = this._textDivs[i];
       var divProperties = this._textDivProperties.get(div);
       if (divProperties.isWhitespace) {
        continue;
       }
       if (expandDivs) {
        var transform = '', padding = '';
        if (divProperties.scale !== 1) {
         transform = 'scaleX(' + divProperties.scale + ')';
        }
        if (divProperties.angle !== 0) {
         transform = 'rotate(' + divProperties.angle + 'deg) ' + transform;
        }
        if (divProperties.paddingLeft !== 0) {
         padding += ' padding-left: ' + divProperties.paddingLeft / divProperties.scale + 'px;';
         transform += ' translateX(' + -divProperties.paddingLeft / divProperties.scale + 'px)';
        }
        if (divProperties.paddingTop !== 0) {
         padding += ' padding-top: ' + divProperties.paddingTop + 'px;';
         transform += ' translateY(' + -divProperties.paddingTop + 'px)';
        }
        if (divProperties.paddingRight !== 0) {
         padding += ' padding-right: ' + divProperties.paddingRight / divProperties.scale + 'px;';
        }
        if (divProperties.paddingBottom !== 0) {
         padding += ' padding-bottom: ' + divProperties.paddingBottom + 'px;';
        }
        if (padding !== '') {
         div.setAttribute('style', divProperties.style + padding);
        }
        if (transform !== '') {
         CustomStyle.setProp('transform', div, transform);
        }
       } else {
        div.style.padding = 0;
        CustomStyle.setProp('transform', div, divProperties.originalTransform || '');
       }
      }
     }
    };
    function renderTextLayer(renderParameters) {
     var task = new TextLayerRenderTask(renderParameters.textContent, renderParameters.container, renderParameters.viewport, renderParameters.textDivs, renderParameters.enhanceTextSelection);
     task._render(renderParameters.timeout);
     return task;
    }
    return renderTextLayer;
   }();
   exports.renderTextLayer = renderTextLayer;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayWebGL = {}, root.pdfjsSharedUtil, root.pdfjsDisplayDOMUtils);
  }(this, function (exports, sharedUtil, displayDOMUtils) {
   var shadow = sharedUtil.shadow;
   var getDefaultSetting = displayDOMUtils.getDefaultSetting;
   var WebGLUtils = function WebGLUtilsClosure() {
    function loadShader(gl, code, shaderType) {
     var shader = gl.createShader(shaderType);
     gl.shaderSource(shader, code);
     gl.compileShader(shader);
     var compiled = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
     if (!compiled) {
      var errorMsg = gl.getShaderInfoLog(shader);
      throw new Error('Error during shader compilation: ' + errorMsg);
     }
     return shader;
    }
    function createVertexShader(gl, code) {
     return loadShader(gl, code, gl.VERTEX_SHADER);
    }
    function createFragmentShader(gl, code) {
     return loadShader(gl, code, gl.FRAGMENT_SHADER);
    }
    function createProgram(gl, shaders) {
     var program = gl.createProgram();
     for (var i = 0, ii = shaders.length; i < ii; ++i) {
      gl.attachShader(program, shaders[i]);
     }
     gl.linkProgram(program);
     var linked = gl.getProgramParameter(program, gl.LINK_STATUS);
     if (!linked) {
      var errorMsg = gl.getProgramInfoLog(program);
      throw new Error('Error during program linking: ' + errorMsg);
     }
     return program;
    }
    function createTexture(gl, image, textureId) {
     gl.activeTexture(textureId);
     var texture = gl.createTexture();
     gl.bindTexture(gl.TEXTURE_2D, texture);
     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
     gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
     return texture;
    }
    var currentGL, currentCanvas;
    function generateGL() {
     if (currentGL) {
      return;
     }
     currentCanvas = document.createElement('canvas');
     currentGL = currentCanvas.getContext('webgl', { premultipliedalpha: false });
    }
    var smaskVertexShaderCode = '\
  attribute vec2 a_position;                                    \
  attribute vec2 a_texCoord;                                    \
                                                                \
  uniform vec2 u_resolution;                                    \
                                                                \
  varying vec2 v_texCoord;                                      \
                                                                \
  void main() {                                                 \
    vec2 clipSpace = (a_position / u_resolution) * 2.0 - 1.0;   \
    gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);          \
                                                                \
    v_texCoord = a_texCoord;                                    \
  }                                                             ';
    var smaskFragmentShaderCode = '\
  precision mediump float;                                      \
                                                                \
  uniform vec4 u_backdrop;                                      \
  uniform int u_subtype;                                        \
  uniform sampler2D u_image;                                    \
  uniform sampler2D u_mask;                                     \
                                                                \
  varying vec2 v_texCoord;                                      \
                                                                \
  void main() {                                                 \
    vec4 imageColor = texture2D(u_image, v_texCoord);           \
    vec4 maskColor = texture2D(u_mask, v_texCoord);             \
    if (u_backdrop.a > 0.0) {                                   \
      maskColor.rgb = maskColor.rgb * maskColor.a +             \
                      u_backdrop.rgb * (1.0 - maskColor.a);     \
    }                                                           \
    float lum;                                                  \
    if (u_subtype == 0) {                                       \
      lum = maskColor.a;                                        \
    } else {                                                    \
      lum = maskColor.r * 0.3 + maskColor.g * 0.59 +            \
            maskColor.b * 0.11;                                 \
    }                                                           \
    imageColor.a *= lum;                                        \
    imageColor.rgb *= imageColor.a;                             \
    gl_FragColor = imageColor;                                  \
  }                                                             ';
    var smaskCache = null;
    function initSmaskGL() {
     var canvas, gl;
     generateGL();
     canvas = currentCanvas;
     currentCanvas = null;
     gl = currentGL;
     currentGL = null;
     var vertexShader = createVertexShader(gl, smaskVertexShaderCode);
     var fragmentShader = createFragmentShader(gl, smaskFragmentShaderCode);
     var program = createProgram(gl, [
      vertexShader,
      fragmentShader
     ]);
     gl.useProgram(program);
     var cache = {};
     cache.gl = gl;
     cache.canvas = canvas;
     cache.resolutionLocation = gl.getUniformLocation(program, 'u_resolution');
     cache.positionLocation = gl.getAttribLocation(program, 'a_position');
     cache.backdropLocation = gl.getUniformLocation(program, 'u_backdrop');
     cache.subtypeLocation = gl.getUniformLocation(program, 'u_subtype');
     var texCoordLocation = gl.getAttribLocation(program, 'a_texCoord');
     var texLayerLocation = gl.getUniformLocation(program, 'u_image');
     var texMaskLocation = gl.getUniformLocation(program, 'u_mask');
     var texCoordBuffer = gl.createBuffer();
     gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
     gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
      0.0,
      0.0,
      1.0,
      0.0,
      0.0,
      1.0,
      0.0,
      1.0,
      1.0,
      0.0,
      1.0,
      1.0
     ]), gl.STATIC_DRAW);
     gl.enableVertexAttribArray(texCoordLocation);
     gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);
     gl.uniform1i(texLayerLocation, 0);
     gl.uniform1i(texMaskLocation, 1);
     smaskCache = cache;
    }
    function composeSMask(layer, mask, properties) {
     var width = layer.width, height = layer.height;
     if (!smaskCache) {
      initSmaskGL();
     }
     var cache = smaskCache, canvas = cache.canvas, gl = cache.gl;
     canvas.width = width;
     canvas.height = height;
     gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
     gl.uniform2f(cache.resolutionLocation, width, height);
     if (properties.backdrop) {
      gl.uniform4f(cache.resolutionLocation, properties.backdrop[0], properties.backdrop[1], properties.backdrop[2], 1);
     } else {
      gl.uniform4f(cache.resolutionLocation, 0, 0, 0, 0);
     }
     gl.uniform1i(cache.subtypeLocation, properties.subtype === 'Luminosity' ? 1 : 0);
     var texture = createTexture(gl, layer, gl.TEXTURE0);
     var maskTexture = createTexture(gl, mask, gl.TEXTURE1);
     var buffer = gl.createBuffer();
     gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
     gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
      0,
      0,
      width,
      0,
      0,
      height,
      0,
      height,
      width,
      0,
      width,
      height
     ]), gl.STATIC_DRAW);
     gl.enableVertexAttribArray(cache.positionLocation);
     gl.vertexAttribPointer(cache.positionLocation, 2, gl.FLOAT, false, 0, 0);
     gl.clearColor(0, 0, 0, 0);
     gl.enable(gl.BLEND);
     gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
     gl.clear(gl.COLOR_BUFFER_BIT);
     gl.drawArrays(gl.TRIANGLES, 0, 6);
     gl.flush();
     gl.deleteTexture(texture);
     gl.deleteTexture(maskTexture);
     gl.deleteBuffer(buffer);
     return canvas;
    }
    var figuresVertexShaderCode = '\
  attribute vec2 a_position;                                    \
  attribute vec3 a_color;                                       \
                                                                \
  uniform vec2 u_resolution;                                    \
  uniform vec2 u_scale;                                         \
  uniform vec2 u_offset;                                        \
                                                                \
  varying vec4 v_color;                                         \
                                                                \
  void main() {                                                 \
    vec2 position = (a_position + u_offset) * u_scale;          \
    vec2 clipSpace = (position / u_resolution) * 2.0 - 1.0;     \
    gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);          \
                                                                \
    v_color = vec4(a_color / 255.0, 1.0);                       \
  }                                                             ';
    var figuresFragmentShaderCode = '\
  precision mediump float;                                      \
                                                                \
  varying vec4 v_color;                                         \
                                                                \
  void main() {                                                 \
    gl_FragColor = v_color;                                     \
  }                                                             ';
    var figuresCache = null;
    function initFiguresGL() {
     var canvas, gl;
     generateGL();
     canvas = currentCanvas;
     currentCanvas = null;
     gl = currentGL;
     currentGL = null;
     var vertexShader = createVertexShader(gl, figuresVertexShaderCode);
     var fragmentShader = createFragmentShader(gl, figuresFragmentShaderCode);
     var program = createProgram(gl, [
      vertexShader,
      fragmentShader
     ]);
     gl.useProgram(program);
     var cache = {};
     cache.gl = gl;
     cache.canvas = canvas;
     cache.resolutionLocation = gl.getUniformLocation(program, 'u_resolution');
     cache.scaleLocation = gl.getUniformLocation(program, 'u_scale');
     cache.offsetLocation = gl.getUniformLocation(program, 'u_offset');
     cache.positionLocation = gl.getAttribLocation(program, 'a_position');
     cache.colorLocation = gl.getAttribLocation(program, 'a_color');
     figuresCache = cache;
    }
    function drawFigures(width, height, backgroundColor, figures, context) {
     if (!figuresCache) {
      initFiguresGL();
     }
     var cache = figuresCache, canvas = cache.canvas, gl = cache.gl;
     canvas.width = width;
     canvas.height = height;
     gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
     gl.uniform2f(cache.resolutionLocation, width, height);
     var count = 0;
     var i, ii, rows;
     for (i = 0, ii = figures.length; i < ii; i++) {
      switch (figures[i].type) {
      case 'lattice':
       rows = figures[i].coords.length / figures[i].verticesPerRow | 0;
       count += (rows - 1) * (figures[i].verticesPerRow - 1) * 6;
       break;
      case 'triangles':
       count += figures[i].coords.length;
       break;
      }
     }
     var coords = new Float32Array(count * 2);
     var colors = new Uint8Array(count * 3);
     var coordsMap = context.coords, colorsMap = context.colors;
     var pIndex = 0, cIndex = 0;
     for (i = 0, ii = figures.length; i < ii; i++) {
      var figure = figures[i], ps = figure.coords, cs = figure.colors;
      switch (figure.type) {
      case 'lattice':
       var cols = figure.verticesPerRow;
       rows = ps.length / cols | 0;
       for (var row = 1; row < rows; row++) {
        var offset = row * cols + 1;
        for (var col = 1; col < cols; col++, offset++) {
         coords[pIndex] = coordsMap[ps[offset - cols - 1]];
         coords[pIndex + 1] = coordsMap[ps[offset - cols - 1] + 1];
         coords[pIndex + 2] = coordsMap[ps[offset - cols]];
         coords[pIndex + 3] = coordsMap[ps[offset - cols] + 1];
         coords[pIndex + 4] = coordsMap[ps[offset - 1]];
         coords[pIndex + 5] = coordsMap[ps[offset - 1] + 1];
         colors[cIndex] = colorsMap[cs[offset - cols - 1]];
         colors[cIndex + 1] = colorsMap[cs[offset - cols - 1] + 1];
         colors[cIndex + 2] = colorsMap[cs[offset - cols - 1] + 2];
         colors[cIndex + 3] = colorsMap[cs[offset - cols]];
         colors[cIndex + 4] = colorsMap[cs[offset - cols] + 1];
         colors[cIndex + 5] = colorsMap[cs[offset - cols] + 2];
         colors[cIndex + 6] = colorsMap[cs[offset - 1]];
         colors[cIndex + 7] = colorsMap[cs[offset - 1] + 1];
         colors[cIndex + 8] = colorsMap[cs[offset - 1] + 2];
         coords[pIndex + 6] = coords[pIndex + 2];
         coords[pIndex + 7] = coords[pIndex + 3];
         coords[pIndex + 8] = coords[pIndex + 4];
         coords[pIndex + 9] = coords[pIndex + 5];
         coords[pIndex + 10] = coordsMap[ps[offset]];
         coords[pIndex + 11] = coordsMap[ps[offset] + 1];
         colors[cIndex + 9] = colors[cIndex + 3];
         colors[cIndex + 10] = colors[cIndex + 4];
         colors[cIndex + 11] = colors[cIndex + 5];
         colors[cIndex + 12] = colors[cIndex + 6];
         colors[cIndex + 13] = colors[cIndex + 7];
         colors[cIndex + 14] = colors[cIndex + 8];
         colors[cIndex + 15] = colorsMap[cs[offset]];
         colors[cIndex + 16] = colorsMap[cs[offset] + 1];
         colors[cIndex + 17] = colorsMap[cs[offset] + 2];
         pIndex += 12;
         cIndex += 18;
        }
       }
       break;
      case 'triangles':
       for (var j = 0, jj = ps.length; j < jj; j++) {
        coords[pIndex] = coordsMap[ps[j]];
        coords[pIndex + 1] = coordsMap[ps[j] + 1];
        colors[cIndex] = colorsMap[cs[j]];
        colors[cIndex + 1] = colorsMap[cs[j] + 1];
        colors[cIndex + 2] = colorsMap[cs[j] + 2];
        pIndex += 2;
        cIndex += 3;
       }
       break;
      }
     }
     if (backgroundColor) {
      gl.clearColor(backgroundColor[0] / 255, backgroundColor[1] / 255, backgroundColor[2] / 255, 1.0);
     } else {
      gl.clearColor(0, 0, 0, 0);
     }
     gl.clear(gl.COLOR_BUFFER_BIT);
     var coordsBuffer = gl.createBuffer();
     gl.bindBuffer(gl.ARRAY_BUFFER, coordsBuffer);
     gl.bufferData(gl.ARRAY_BUFFER, coords, gl.STATIC_DRAW);
     gl.enableVertexAttribArray(cache.positionLocation);
     gl.vertexAttribPointer(cache.positionLocation, 2, gl.FLOAT, false, 0, 0);
     var colorsBuffer = gl.createBuffer();
     gl.bindBuffer(gl.ARRAY_BUFFER, colorsBuffer);
     gl.bufferData(gl.ARRAY_BUFFER, colors, gl.STATIC_DRAW);
     gl.enableVertexAttribArray(cache.colorLocation);
     gl.vertexAttribPointer(cache.colorLocation, 3, gl.UNSIGNED_BYTE, false, 0, 0);
     gl.uniform2f(cache.scaleLocation, context.scaleX, context.scaleY);
     gl.uniform2f(cache.offsetLocation, context.offsetX, context.offsetY);
     gl.drawArrays(gl.TRIANGLES, 0, count);
     gl.flush();
     gl.deleteBuffer(coordsBuffer);
     gl.deleteBuffer(colorsBuffer);
     return canvas;
    }
    function cleanup() {
     if (smaskCache && smaskCache.canvas) {
      smaskCache.canvas.width = 0;
      smaskCache.canvas.height = 0;
     }
     if (figuresCache && figuresCache.canvas) {
      figuresCache.canvas.width = 0;
      figuresCache.canvas.height = 0;
     }
     smaskCache = null;
     figuresCache = null;
    }
    return {
     get isEnabled() {
      if (getDefaultSetting('disableWebGL')) {
       return false;
      }
      var enabled = false;
      try {
       generateGL();
       enabled = !!currentGL;
      } catch (e) {
      }
      return shadow(this, 'isEnabled', enabled);
     },
     composeSMask: composeSMask,
     drawFigures: drawFigures,
     clear: cleanup
    };
   }();
   exports.WebGLUtils = WebGLUtils;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreCrypto = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreStream);
  }(this, function (exports, sharedUtil, corePrimitives, coreStream) {
   var PasswordException = sharedUtil.PasswordException;
   var PasswordResponses = sharedUtil.PasswordResponses;
   var bytesToString = sharedUtil.bytesToString;
   var warn = sharedUtil.warn;
   var error = sharedUtil.error;
   var assert = sharedUtil.assert;
   var isInt = sharedUtil.isInt;
   var stringToBytes = sharedUtil.stringToBytes;
   var utf8StringToString = sharedUtil.utf8StringToString;
   var Name = corePrimitives.Name;
   var isName = corePrimitives.isName;
   var isDict = corePrimitives.isDict;
   var DecryptStream = coreStream.DecryptStream;
   var ARCFourCipher = function ARCFourCipherClosure() {
    function ARCFourCipher(key) {
     this.a = 0;
     this.b = 0;
     var s = new Uint8Array(256);
     var i, j = 0, tmp, keyLength = key.length;
     for (i = 0; i < 256; ++i) {
      s[i] = i;
     }
     for (i = 0; i < 256; ++i) {
      tmp = s[i];
      j = j + tmp + key[i % keyLength] & 0xFF;
      s[i] = s[j];
      s[j] = tmp;
     }
     this.s = s;
    }
    ARCFourCipher.prototype = {
     encryptBlock: function ARCFourCipher_encryptBlock(data) {
      var i, n = data.length, tmp, tmp2;
      var a = this.a, b = this.b, s = this.s;
      var output = new Uint8Array(n);
      for (i = 0; i < n; ++i) {
       a = a + 1 & 0xFF;
       tmp = s[a];
       b = b + tmp & 0xFF;
       tmp2 = s[b];
       s[a] = tmp2;
       s[b] = tmp;
       output[i] = data[i] ^ s[tmp + tmp2 & 0xFF];
      }
      this.a = a;
      this.b = b;
      return output;
     }
    };
    ARCFourCipher.prototype.decryptBlock = ARCFourCipher.prototype.encryptBlock;
    return ARCFourCipher;
   }();
   var calculateMD5 = function calculateMD5Closure() {
    var r = new Uint8Array([
     7,
     12,
     17,
     22,
     7,
     12,
     17,
     22,
     7,
     12,
     17,
     22,
     7,
     12,
     17,
     22,
     5,
     9,
     14,
     20,
     5,
     9,
     14,
     20,
     5,
     9,
     14,
     20,
     5,
     9,
     14,
     20,
     4,
     11,
     16,
     23,
     4,
     11,
     16,
     23,
     4,
     11,
     16,
     23,
     4,
     11,
     16,
     23,
     6,
     10,
     15,
     21,
     6,
     10,
     15,
     21,
     6,
     10,
     15,
     21,
     6,
     10,
     15,
     21
    ]);
    var k = new Int32Array([
     -680876936,
     -389564586,
     606105819,
     -1044525330,
     -176418897,
     1200080426,
     -1473231341,
     -45705983,
     1770035416,
     -1958414417,
     -42063,
     -1990404162,
     1804603682,
     -40341101,
     -1502002290,
     1236535329,
     -165796510,
     -1069501632,
     643717713,
     -373897302,
     -701558691,
     38016083,
     -660478335,
     -405537848,
     568446438,
     -1019803690,
     -187363961,
     1163531501,
     -1444681467,
     -51403784,
     1735328473,
     -1926607734,
     -378558,
     -2022574463,
     1839030562,
     -35309556,
     -1530992060,
     1272893353,
     -155497632,
     -1094730640,
     681279174,
     -358537222,
     -722521979,
     76029189,
     -640364487,
     -421815835,
     530742520,
     -995338651,
     -198630844,
     1126891415,
     -1416354905,
     -57434055,
     1700485571,
     -1894986606,
     -1051523,
     -2054922799,
     1873313359,
     -30611744,
     -1560198380,
     1309151649,
     -145523070,
     -1120210379,
     718787259,
     -343485551
    ]);
    function hash(data, offset, length) {
     var h0 = 1732584193, h1 = -271733879, h2 = -1732584194, h3 = 271733878;
     var paddedLength = length + 72 & ~63;
     var padded = new Uint8Array(paddedLength);
     var i, j, n;
     for (i = 0; i < length; ++i) {
      padded[i] = data[offset++];
     }
     padded[i++] = 0x80;
     n = paddedLength - 8;
     while (i < n) {
      padded[i++] = 0;
     }
     padded[i++] = length << 3 & 0xFF;
     padded[i++] = length >> 5 & 0xFF;
     padded[i++] = length >> 13 & 0xFF;
     padded[i++] = length >> 21 & 0xFF;
     padded[i++] = length >>> 29 & 0xFF;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     var w = new Int32Array(16);
     for (i = 0; i < paddedLength;) {
      for (j = 0; j < 16; ++j, i += 4) {
       w[j] = padded[i] | padded[i + 1] << 8 | padded[i + 2] << 16 | padded[i + 3] << 24;
      }
      var a = h0, b = h1, c = h2, d = h3, f, g;
      for (j = 0; j < 64; ++j) {
       if (j < 16) {
        f = b & c | ~b & d;
        g = j;
       } else if (j < 32) {
        f = d & b | ~d & c;
        g = 5 * j + 1 & 15;
       } else if (j < 48) {
        f = b ^ c ^ d;
        g = 3 * j + 5 & 15;
       } else {
        f = c ^ (b | ~d);
        g = 7 * j & 15;
       }
       var tmp = d, rotateArg = a + f + k[j] + w[g] | 0, rotate = r[j];
       d = c;
       c = b;
       b = b + (rotateArg << rotate | rotateArg >>> 32 - rotate) | 0;
       a = tmp;
      }
      h0 = h0 + a | 0;
      h1 = h1 + b | 0;
      h2 = h2 + c | 0;
      h3 = h3 + d | 0;
     }
     return new Uint8Array([
      h0 & 0xFF,
      h0 >> 8 & 0xFF,
      h0 >> 16 & 0xFF,
      h0 >>> 24 & 0xFF,
      h1 & 0xFF,
      h1 >> 8 & 0xFF,
      h1 >> 16 & 0xFF,
      h1 >>> 24 & 0xFF,
      h2 & 0xFF,
      h2 >> 8 & 0xFF,
      h2 >> 16 & 0xFF,
      h2 >>> 24 & 0xFF,
      h3 & 0xFF,
      h3 >> 8 & 0xFF,
      h3 >> 16 & 0xFF,
      h3 >>> 24 & 0xFF
     ]);
    }
    return hash;
   }();
   var Word64 = function Word64Closure() {
    function Word64(highInteger, lowInteger) {
     this.high = highInteger | 0;
     this.low = lowInteger | 0;
    }
    Word64.prototype = {
     and: function Word64_and(word) {
      this.high &= word.high;
      this.low &= word.low;
     },
     xor: function Word64_xor(word) {
      this.high ^= word.high;
      this.low ^= word.low;
     },
     or: function Word64_or(word) {
      this.high |= word.high;
      this.low |= word.low;
     },
     shiftRight: function Word64_shiftRight(places) {
      if (places >= 32) {
       this.low = this.high >>> places - 32 | 0;
       this.high = 0;
      } else {
       this.low = this.low >>> places | this.high << 32 - places;
       this.high = this.high >>> places | 0;
      }
     },
     shiftLeft: function Word64_shiftLeft(places) {
      if (places >= 32) {
       this.high = this.low << places - 32;
       this.low = 0;
      } else {
       this.high = this.high << places | this.low >>> 32 - places;
       this.low = this.low << places;
      }
     },
     rotateRight: function Word64_rotateRight(places) {
      var low, high;
      if (places & 32) {
       high = this.low;
       low = this.high;
      } else {
       low = this.low;
       high = this.high;
      }
      places &= 31;
      this.low = low >>> places | high << 32 - places;
      this.high = high >>> places | low << 32 - places;
     },
     not: function Word64_not() {
      this.high = ~this.high;
      this.low = ~this.low;
     },
     add: function Word64_add(word) {
      var lowAdd = (this.low >>> 0) + (word.low >>> 0);
      var highAdd = (this.high >>> 0) + (word.high >>> 0);
      if (lowAdd > 0xFFFFFFFF) {
       highAdd += 1;
      }
      this.low = lowAdd | 0;
      this.high = highAdd | 0;
     },
     copyTo: function Word64_copyTo(bytes, offset) {
      bytes[offset] = this.high >>> 24 & 0xFF;
      bytes[offset + 1] = this.high >> 16 & 0xFF;
      bytes[offset + 2] = this.high >> 8 & 0xFF;
      bytes[offset + 3] = this.high & 0xFF;
      bytes[offset + 4] = this.low >>> 24 & 0xFF;
      bytes[offset + 5] = this.low >> 16 & 0xFF;
      bytes[offset + 6] = this.low >> 8 & 0xFF;
      bytes[offset + 7] = this.low & 0xFF;
     },
     assign: function Word64_assign(word) {
      this.high = word.high;
      this.low = word.low;
     }
    };
    return Word64;
   }();
   var calculateSHA256 = function calculateSHA256Closure() {
    function rotr(x, n) {
     return x >>> n | x << 32 - n;
    }
    function ch(x, y, z) {
     return x & y ^ ~x & z;
    }
    function maj(x, y, z) {
     return x & y ^ x & z ^ y & z;
    }
    function sigma(x) {
     return rotr(x, 2) ^ rotr(x, 13) ^ rotr(x, 22);
    }
    function sigmaPrime(x) {
     return rotr(x, 6) ^ rotr(x, 11) ^ rotr(x, 25);
    }
    function littleSigma(x) {
     return rotr(x, 7) ^ rotr(x, 18) ^ x >>> 3;
    }
    function littleSigmaPrime(x) {
     return rotr(x, 17) ^ rotr(x, 19) ^ x >>> 10;
    }
    var k = [
     0x428a2f98,
     0x71374491,
     0xb5c0fbcf,
     0xe9b5dba5,
     0x3956c25b,
     0x59f111f1,
     0x923f82a4,
     0xab1c5ed5,
     0xd807aa98,
     0x12835b01,
     0x243185be,
     0x550c7dc3,
     0x72be5d74,
     0x80deb1fe,
     0x9bdc06a7,
     0xc19bf174,
     0xe49b69c1,
     0xefbe4786,
     0x0fc19dc6,
     0x240ca1cc,
     0x2de92c6f,
     0x4a7484aa,
     0x5cb0a9dc,
     0x76f988da,
     0x983e5152,
     0xa831c66d,
     0xb00327c8,
     0xbf597fc7,
     0xc6e00bf3,
     0xd5a79147,
     0x06ca6351,
     0x14292967,
     0x27b70a85,
     0x2e1b2138,
     0x4d2c6dfc,
     0x53380d13,
     0x650a7354,
     0x766a0abb,
     0x81c2c92e,
     0x92722c85,
     0xa2bfe8a1,
     0xa81a664b,
     0xc24b8b70,
     0xc76c51a3,
     0xd192e819,
     0xd6990624,
     0xf40e3585,
     0x106aa070,
     0x19a4c116,
     0x1e376c08,
     0x2748774c,
     0x34b0bcb5,
     0x391c0cb3,
     0x4ed8aa4a,
     0x5b9cca4f,
     0x682e6ff3,
     0x748f82ee,
     0x78a5636f,
     0x84c87814,
     0x8cc70208,
     0x90befffa,
     0xa4506ceb,
     0xbef9a3f7,
     0xc67178f2
    ];
    function hash(data, offset, length) {
     var h0 = 0x6a09e667, h1 = 0xbb67ae85, h2 = 0x3c6ef372, h3 = 0xa54ff53a, h4 = 0x510e527f, h5 = 0x9b05688c, h6 = 0x1f83d9ab, h7 = 0x5be0cd19;
     var paddedLength = Math.ceil((length + 9) / 64) * 64;
     var padded = new Uint8Array(paddedLength);
     var i, j, n;
     for (i = 0; i < length; ++i) {
      padded[i] = data[offset++];
     }
     padded[i++] = 0x80;
     n = paddedLength - 8;
     while (i < n) {
      padded[i++] = 0;
     }
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = length >>> 29 & 0xFF;
     padded[i++] = length >> 21 & 0xFF;
     padded[i++] = length >> 13 & 0xFF;
     padded[i++] = length >> 5 & 0xFF;
     padded[i++] = length << 3 & 0xFF;
     var w = new Uint32Array(64);
     for (i = 0; i < paddedLength;) {
      for (j = 0; j < 16; ++j) {
       w[j] = padded[i] << 24 | padded[i + 1] << 16 | padded[i + 2] << 8 | padded[i + 3];
       i += 4;
      }
      for (j = 16; j < 64; ++j) {
       w[j] = littleSigmaPrime(w[j - 2]) + w[j - 7] + littleSigma(w[j - 15]) + w[j - 16] | 0;
      }
      var a = h0, b = h1, c = h2, d = h3, e = h4, f = h5, g = h6, h = h7, t1, t2;
      for (j = 0; j < 64; ++j) {
       t1 = h + sigmaPrime(e) + ch(e, f, g) + k[j] + w[j];
       t2 = sigma(a) + maj(a, b, c);
       h = g;
       g = f;
       f = e;
       e = d + t1 | 0;
       d = c;
       c = b;
       b = a;
       a = t1 + t2 | 0;
      }
      h0 = h0 + a | 0;
      h1 = h1 + b | 0;
      h2 = h2 + c | 0;
      h3 = h3 + d | 0;
      h4 = h4 + e | 0;
      h5 = h5 + f | 0;
      h6 = h6 + g | 0;
      h7 = h7 + h | 0;
     }
     return new Uint8Array([
      h0 >> 24 & 0xFF,
      h0 >> 16 & 0xFF,
      h0 >> 8 & 0xFF,
      h0 & 0xFF,
      h1 >> 24 & 0xFF,
      h1 >> 16 & 0xFF,
      h1 >> 8 & 0xFF,
      h1 & 0xFF,
      h2 >> 24 & 0xFF,
      h2 >> 16 & 0xFF,
      h2 >> 8 & 0xFF,
      h2 & 0xFF,
      h3 >> 24 & 0xFF,
      h3 >> 16 & 0xFF,
      h3 >> 8 & 0xFF,
      h3 & 0xFF,
      h4 >> 24 & 0xFF,
      h4 >> 16 & 0xFF,
      h4 >> 8 & 0xFF,
      h4 & 0xFF,
      h5 >> 24 & 0xFF,
      h5 >> 16 & 0xFF,
      h5 >> 8 & 0xFF,
      h5 & 0xFF,
      h6 >> 24 & 0xFF,
      h6 >> 16 & 0xFF,
      h6 >> 8 & 0xFF,
      h6 & 0xFF,
      h7 >> 24 & 0xFF,
      h7 >> 16 & 0xFF,
      h7 >> 8 & 0xFF,
      h7 & 0xFF
     ]);
    }
    return hash;
   }();
   var calculateSHA512 = function calculateSHA512Closure() {
    function ch(result, x, y, z, tmp) {
     result.assign(x);
     result.and(y);
     tmp.assign(x);
     tmp.not();
     tmp.and(z);
     result.xor(tmp);
    }
    function maj(result, x, y, z, tmp) {
     result.assign(x);
     result.and(y);
     tmp.assign(x);
     tmp.and(z);
     result.xor(tmp);
     tmp.assign(y);
     tmp.and(z);
     result.xor(tmp);
    }
    function sigma(result, x, tmp) {
     result.assign(x);
     result.rotateRight(28);
     tmp.assign(x);
     tmp.rotateRight(34);
     result.xor(tmp);
     tmp.assign(x);
     tmp.rotateRight(39);
     result.xor(tmp);
    }
    function sigmaPrime(result, x, tmp) {
     result.assign(x);
     result.rotateRight(14);
     tmp.assign(x);
     tmp.rotateRight(18);
     result.xor(tmp);
     tmp.assign(x);
     tmp.rotateRight(41);
     result.xor(tmp);
    }
    function littleSigma(result, x, tmp) {
     result.assign(x);
     result.rotateRight(1);
     tmp.assign(x);
     tmp.rotateRight(8);
     result.xor(tmp);
     tmp.assign(x);
     tmp.shiftRight(7);
     result.xor(tmp);
    }
    function littleSigmaPrime(result, x, tmp) {
     result.assign(x);
     result.rotateRight(19);
     tmp.assign(x);
     tmp.rotateRight(61);
     result.xor(tmp);
     tmp.assign(x);
     tmp.shiftRight(6);
     result.xor(tmp);
    }
    var k = [
     new Word64(0x428a2f98, 0xd728ae22),
     new Word64(0x71374491, 0x23ef65cd),
     new Word64(0xb5c0fbcf, 0xec4d3b2f),
     new Word64(0xe9b5dba5, 0x8189dbbc),
     new Word64(0x3956c25b, 0xf348b538),
     new Word64(0x59f111f1, 0xb605d019),
     new Word64(0x923f82a4, 0xaf194f9b),
     new Word64(0xab1c5ed5, 0xda6d8118),
     new Word64(0xd807aa98, 0xa3030242),
     new Word64(0x12835b01, 0x45706fbe),
     new Word64(0x243185be, 0x4ee4b28c),
     new Word64(0x550c7dc3, 0xd5ffb4e2),
     new Word64(0x72be5d74, 0xf27b896f),
     new Word64(0x80deb1fe, 0x3b1696b1),
     new Word64(0x9bdc06a7, 0x25c71235),
     new Word64(0xc19bf174, 0xcf692694),
     new Word64(0xe49b69c1, 0x9ef14ad2),
     new Word64(0xefbe4786, 0x384f25e3),
     new Word64(0x0fc19dc6, 0x8b8cd5b5),
     new Word64(0x240ca1cc, 0x77ac9c65),
     new Word64(0x2de92c6f, 0x592b0275),
     new Word64(0x4a7484aa, 0x6ea6e483),
     new Word64(0x5cb0a9dc, 0xbd41fbd4),
     new Word64(0x76f988da, 0x831153b5),
     new Word64(0x983e5152, 0xee66dfab),
     new Word64(0xa831c66d, 0x2db43210),
     new Word64(0xb00327c8, 0x98fb213f),
     new Word64(0xbf597fc7, 0xbeef0ee4),
     new Word64(0xc6e00bf3, 0x3da88fc2),
     new Word64(0xd5a79147, 0x930aa725),
     new Word64(0x06ca6351, 0xe003826f),
     new Word64(0x14292967, 0x0a0e6e70),
     new Word64(0x27b70a85, 0x46d22ffc),
     new Word64(0x2e1b2138, 0x5c26c926),
     new Word64(0x4d2c6dfc, 0x5ac42aed),
     new Word64(0x53380d13, 0x9d95b3df),
     new Word64(0x650a7354, 0x8baf63de),
     new Word64(0x766a0abb, 0x3c77b2a8),
     new Word64(0x81c2c92e, 0x47edaee6),
     new Word64(0x92722c85, 0x1482353b),
     new Word64(0xa2bfe8a1, 0x4cf10364),
     new Word64(0xa81a664b, 0xbc423001),
     new Word64(0xc24b8b70, 0xd0f89791),
     new Word64(0xc76c51a3, 0x0654be30),
     new Word64(0xd192e819, 0xd6ef5218),
     new Word64(0xd6990624, 0x5565a910),
     new Word64(0xf40e3585, 0x5771202a),
     new Word64(0x106aa070, 0x32bbd1b8),
     new Word64(0x19a4c116, 0xb8d2d0c8),
     new Word64(0x1e376c08, 0x5141ab53),
     new Word64(0x2748774c, 0xdf8eeb99),
     new Word64(0x34b0bcb5, 0xe19b48a8),
     new Word64(0x391c0cb3, 0xc5c95a63),
     new Word64(0x4ed8aa4a, 0xe3418acb),
     new Word64(0x5b9cca4f, 0x7763e373),
     new Word64(0x682e6ff3, 0xd6b2b8a3),
     new Word64(0x748f82ee, 0x5defb2fc),
     new Word64(0x78a5636f, 0x43172f60),
     new Word64(0x84c87814, 0xa1f0ab72),
     new Word64(0x8cc70208, 0x1a6439ec),
     new Word64(0x90befffa, 0x23631e28),
     new Word64(0xa4506ceb, 0xde82bde9),
     new Word64(0xbef9a3f7, 0xb2c67915),
     new Word64(0xc67178f2, 0xe372532b),
     new Word64(0xca273ece, 0xea26619c),
     new Word64(0xd186b8c7, 0x21c0c207),
     new Word64(0xeada7dd6, 0xcde0eb1e),
     new Word64(0xf57d4f7f, 0xee6ed178),
     new Word64(0x06f067aa, 0x72176fba),
     new Word64(0x0a637dc5, 0xa2c898a6),
     new Word64(0x113f9804, 0xbef90dae),
     new Word64(0x1b710b35, 0x131c471b),
     new Word64(0x28db77f5, 0x23047d84),
     new Word64(0x32caab7b, 0x40c72493),
     new Word64(0x3c9ebe0a, 0x15c9bebc),
     new Word64(0x431d67c4, 0x9c100d4c),
     new Word64(0x4cc5d4be, 0xcb3e42b6),
     new Word64(0x597f299c, 0xfc657e2a),
     new Word64(0x5fcb6fab, 0x3ad6faec),
     new Word64(0x6c44198c, 0x4a475817)
    ];
    function hash(data, offset, length, mode384) {
     mode384 = !!mode384;
     var h0, h1, h2, h3, h4, h5, h6, h7;
     if (!mode384) {
      h0 = new Word64(0x6a09e667, 0xf3bcc908);
      h1 = new Word64(0xbb67ae85, 0x84caa73b);
      h2 = new Word64(0x3c6ef372, 0xfe94f82b);
      h3 = new Word64(0xa54ff53a, 0x5f1d36f1);
      h4 = new Word64(0x510e527f, 0xade682d1);
      h5 = new Word64(0x9b05688c, 0x2b3e6c1f);
      h6 = new Word64(0x1f83d9ab, 0xfb41bd6b);
      h7 = new Word64(0x5be0cd19, 0x137e2179);
     } else {
      h0 = new Word64(0xcbbb9d5d, 0xc1059ed8);
      h1 = new Word64(0x629a292a, 0x367cd507);
      h2 = new Word64(0x9159015a, 0x3070dd17);
      h3 = new Word64(0x152fecd8, 0xf70e5939);
      h4 = new Word64(0x67332667, 0xffc00b31);
      h5 = new Word64(0x8eb44a87, 0x68581511);
      h6 = new Word64(0xdb0c2e0d, 0x64f98fa7);
      h7 = new Word64(0x47b5481d, 0xbefa4fa4);
     }
     var paddedLength = Math.ceil((length + 17) / 128) * 128;
     var padded = new Uint8Array(paddedLength);
     var i, j, n;
     for (i = 0; i < length; ++i) {
      padded[i] = data[offset++];
     }
     padded[i++] = 0x80;
     n = paddedLength - 16;
     while (i < n) {
      padded[i++] = 0;
     }
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = 0;
     padded[i++] = length >>> 29 & 0xFF;
     padded[i++] = length >> 21 & 0xFF;
     padded[i++] = length >> 13 & 0xFF;
     padded[i++] = length >> 5 & 0xFF;
     padded[i++] = length << 3 & 0xFF;
     var w = new Array(80);
     for (i = 0; i < 80; i++) {
      w[i] = new Word64(0, 0);
     }
     var a = new Word64(0, 0), b = new Word64(0, 0), c = new Word64(0, 0);
     var d = new Word64(0, 0), e = new Word64(0, 0), f = new Word64(0, 0);
     var g = new Word64(0, 0), h = new Word64(0, 0);
     var t1 = new Word64(0, 0), t2 = new Word64(0, 0);
     var tmp1 = new Word64(0, 0), tmp2 = new Word64(0, 0), tmp3;
     for (i = 0; i < paddedLength;) {
      for (j = 0; j < 16; ++j) {
       w[j].high = padded[i] << 24 | padded[i + 1] << 16 | padded[i + 2] << 8 | padded[i + 3];
       w[j].low = padded[i + 4] << 24 | padded[i + 5] << 16 | padded[i + 6] << 8 | padded[i + 7];
       i += 8;
      }
      for (j = 16; j < 80; ++j) {
       tmp3 = w[j];
       littleSigmaPrime(tmp3, w[j - 2], tmp2);
       tmp3.add(w[j - 7]);
       littleSigma(tmp1, w[j - 15], tmp2);
       tmp3.add(tmp1);
       tmp3.add(w[j - 16]);
      }
      a.assign(h0);
      b.assign(h1);
      c.assign(h2);
      d.assign(h3);
      e.assign(h4);
      f.assign(h5);
      g.assign(h6);
      h.assign(h7);
      for (j = 0; j < 80; ++j) {
       t1.assign(h);
       sigmaPrime(tmp1, e, tmp2);
       t1.add(tmp1);
       ch(tmp1, e, f, g, tmp2);
       t1.add(tmp1);
       t1.add(k[j]);
       t1.add(w[j]);
       sigma(t2, a, tmp2);
       maj(tmp1, a, b, c, tmp2);
       t2.add(tmp1);
       tmp3 = h;
       h = g;
       g = f;
       f = e;
       d.add(t1);
       e = d;
       d = c;
       c = b;
       b = a;
       tmp3.assign(t1);
       tmp3.add(t2);
       a = tmp3;
      }
      h0.add(a);
      h1.add(b);
      h2.add(c);
      h3.add(d);
      h4.add(e);
      h5.add(f);
      h6.add(g);
      h7.add(h);
     }
     var result;
     if (!mode384) {
      result = new Uint8Array(64);
      h0.copyTo(result, 0);
      h1.copyTo(result, 8);
      h2.copyTo(result, 16);
      h3.copyTo(result, 24);
      h4.copyTo(result, 32);
      h5.copyTo(result, 40);
      h6.copyTo(result, 48);
      h7.copyTo(result, 56);
     } else {
      result = new Uint8Array(48);
      h0.copyTo(result, 0);
      h1.copyTo(result, 8);
      h2.copyTo(result, 16);
      h3.copyTo(result, 24);
      h4.copyTo(result, 32);
      h5.copyTo(result, 40);
     }
     return result;
    }
    return hash;
   }();
   var calculateSHA384 = function calculateSHA384Closure() {
    function hash(data, offset, length) {
     return calculateSHA512(data, offset, length, true);
    }
    return hash;
   }();
   var NullCipher = function NullCipherClosure() {
    function NullCipher() {
    }
    NullCipher.prototype = {
     decryptBlock: function NullCipher_decryptBlock(data) {
      return data;
     }
    };
    return NullCipher;
   }();
   var AES128Cipher = function AES128CipherClosure() {
    var rcon = new Uint8Array([
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d
    ]);
    var s = new Uint8Array([
     0x63,
     0x7c,
     0x77,
     0x7b,
     0xf2,
     0x6b,
     0x6f,
     0xc5,
     0x30,
     0x01,
     0x67,
     0x2b,
     0xfe,
     0xd7,
     0xab,
     0x76,
     0xca,
     0x82,
     0xc9,
     0x7d,
     0xfa,
     0x59,
     0x47,
     0xf0,
     0xad,
     0xd4,
     0xa2,
     0xaf,
     0x9c,
     0xa4,
     0x72,
     0xc0,
     0xb7,
     0xfd,
     0x93,
     0x26,
     0x36,
     0x3f,
     0xf7,
     0xcc,
     0x34,
     0xa5,
     0xe5,
     0xf1,
     0x71,
     0xd8,
     0x31,
     0x15,
     0x04,
     0xc7,
     0x23,
     0xc3,
     0x18,
     0x96,
     0x05,
     0x9a,
     0x07,
     0x12,
     0x80,
     0xe2,
     0xeb,
     0x27,
     0xb2,
     0x75,
     0x09,
     0x83,
     0x2c,
     0x1a,
     0x1b,
     0x6e,
     0x5a,
     0xa0,
     0x52,
     0x3b,
     0xd6,
     0xb3,
     0x29,
     0xe3,
     0x2f,
     0x84,
     0x53,
     0xd1,
     0x00,
     0xed,
     0x20,
     0xfc,
     0xb1,
     0x5b,
     0x6a,
     0xcb,
     0xbe,
     0x39,
     0x4a,
     0x4c,
     0x58,
     0xcf,
     0xd0,
     0xef,
     0xaa,
     0xfb,
     0x43,
     0x4d,
     0x33,
     0x85,
     0x45,
     0xf9,
     0x02,
     0x7f,
     0x50,
     0x3c,
     0x9f,
     0xa8,
     0x51,
     0xa3,
     0x40,
     0x8f,
     0x92,
     0x9d,
     0x38,
     0xf5,
     0xbc,
     0xb6,
     0xda,
     0x21,
     0x10,
     0xff,
     0xf3,
     0xd2,
     0xcd,
     0x0c,
     0x13,
     0xec,
     0x5f,
     0x97,
     0x44,
     0x17,
     0xc4,
     0xa7,
     0x7e,
     0x3d,
     0x64,
     0x5d,
     0x19,
     0x73,
     0x60,
     0x81,
     0x4f,
     0xdc,
     0x22,
     0x2a,
     0x90,
     0x88,
     0x46,
     0xee,
     0xb8,
     0x14,
     0xde,
     0x5e,
     0x0b,
     0xdb,
     0xe0,
     0x32,
     0x3a,
     0x0a,
     0x49,
     0x06,
     0x24,
     0x5c,
     0xc2,
     0xd3,
     0xac,
     0x62,
     0x91,
     0x95,
     0xe4,
     0x79,
     0xe7,
     0xc8,
     0x37,
     0x6d,
     0x8d,
     0xd5,
     0x4e,
     0xa9,
     0x6c,
     0x56,
     0xf4,
     0xea,
     0x65,
     0x7a,
     0xae,
     0x08,
     0xba,
     0x78,
     0x25,
     0x2e,
     0x1c,
     0xa6,
     0xb4,
     0xc6,
     0xe8,
     0xdd,
     0x74,
     0x1f,
     0x4b,
     0xbd,
     0x8b,
     0x8a,
     0x70,
     0x3e,
     0xb5,
     0x66,
     0x48,
     0x03,
     0xf6,
     0x0e,
     0x61,
     0x35,
     0x57,
     0xb9,
     0x86,
     0xc1,
     0x1d,
     0x9e,
     0xe1,
     0xf8,
     0x98,
     0x11,
     0x69,
     0xd9,
     0x8e,
     0x94,
     0x9b,
     0x1e,
     0x87,
     0xe9,
     0xce,
     0x55,
     0x28,
     0xdf,
     0x8c,
     0xa1,
     0x89,
     0x0d,
     0xbf,
     0xe6,
     0x42,
     0x68,
     0x41,
     0x99,
     0x2d,
     0x0f,
     0xb0,
     0x54,
     0xbb,
     0x16
    ]);
    var inv_s = new Uint8Array([
     0x52,
     0x09,
     0x6a,
     0xd5,
     0x30,
     0x36,
     0xa5,
     0x38,
     0xbf,
     0x40,
     0xa3,
     0x9e,
     0x81,
     0xf3,
     0xd7,
     0xfb,
     0x7c,
     0xe3,
     0x39,
     0x82,
     0x9b,
     0x2f,
     0xff,
     0x87,
     0x34,
     0x8e,
     0x43,
     0x44,
     0xc4,
     0xde,
     0xe9,
     0xcb,
     0x54,
     0x7b,
     0x94,
     0x32,
     0xa6,
     0xc2,
     0x23,
     0x3d,
     0xee,
     0x4c,
     0x95,
     0x0b,
     0x42,
     0xfa,
     0xc3,
     0x4e,
     0x08,
     0x2e,
     0xa1,
     0x66,
     0x28,
     0xd9,
     0x24,
     0xb2,
     0x76,
     0x5b,
     0xa2,
     0x49,
     0x6d,
     0x8b,
     0xd1,
     0x25,
     0x72,
     0xf8,
     0xf6,
     0x64,
     0x86,
     0x68,
     0x98,
     0x16,
     0xd4,
     0xa4,
     0x5c,
     0xcc,
     0x5d,
     0x65,
     0xb6,
     0x92,
     0x6c,
     0x70,
     0x48,
     0x50,
     0xfd,
     0xed,
     0xb9,
     0xda,
     0x5e,
     0x15,
     0x46,
     0x57,
     0xa7,
     0x8d,
     0x9d,
     0x84,
     0x90,
     0xd8,
     0xab,
     0x00,
     0x8c,
     0xbc,
     0xd3,
     0x0a,
     0xf7,
     0xe4,
     0x58,
     0x05,
     0xb8,
     0xb3,
     0x45,
     0x06,
     0xd0,
     0x2c,
     0x1e,
     0x8f,
     0xca,
     0x3f,
     0x0f,
     0x02,
     0xc1,
     0xaf,
     0xbd,
     0x03,
     0x01,
     0x13,
     0x8a,
     0x6b,
     0x3a,
     0x91,
     0x11,
     0x41,
     0x4f,
     0x67,
     0xdc,
     0xea,
     0x97,
     0xf2,
     0xcf,
     0xce,
     0xf0,
     0xb4,
     0xe6,
     0x73,
     0x96,
     0xac,
     0x74,
     0x22,
     0xe7,
     0xad,
     0x35,
     0x85,
     0xe2,
     0xf9,
     0x37,
     0xe8,
     0x1c,
     0x75,
     0xdf,
     0x6e,
     0x47,
     0xf1,
     0x1a,
     0x71,
     0x1d,
     0x29,
     0xc5,
     0x89,
     0x6f,
     0xb7,
     0x62,
     0x0e,
     0xaa,
     0x18,
     0xbe,
     0x1b,
     0xfc,
     0x56,
     0x3e,
     0x4b,
     0xc6,
     0xd2,
     0x79,
     0x20,
     0x9a,
     0xdb,
     0xc0,
     0xfe,
     0x78,
     0xcd,
     0x5a,
     0xf4,
     0x1f,
     0xdd,
     0xa8,
     0x33,
     0x88,
     0x07,
     0xc7,
     0x31,
     0xb1,
     0x12,
     0x10,
     0x59,
     0x27,
     0x80,
     0xec,
     0x5f,
     0x60,
     0x51,
     0x7f,
     0xa9,
     0x19,
     0xb5,
     0x4a,
     0x0d,
     0x2d,
     0xe5,
     0x7a,
     0x9f,
     0x93,
     0xc9,
     0x9c,
     0xef,
     0xa0,
     0xe0,
     0x3b,
     0x4d,
     0xae,
     0x2a,
     0xf5,
     0xb0,
     0xc8,
     0xeb,
     0xbb,
     0x3c,
     0x83,
     0x53,
     0x99,
     0x61,
     0x17,
     0x2b,
     0x04,
     0x7e,
     0xba,
     0x77,
     0xd6,
     0x26,
     0xe1,
     0x69,
     0x14,
     0x63,
     0x55,
     0x21,
     0x0c,
     0x7d
    ]);
    var mixCol = new Uint8Array(256);
    for (var i = 0; i < 256; i++) {
     if (i < 128) {
      mixCol[i] = i << 1;
     } else {
      mixCol[i] = i << 1 ^ 0x1b;
     }
    }
    var mix = new Uint32Array([
     0x00000000,
     0x0e090d0b,
     0x1c121a16,
     0x121b171d,
     0x3824342c,
     0x362d3927,
     0x24362e3a,
     0x2a3f2331,
     0x70486858,
     0x7e416553,
     0x6c5a724e,
     0x62537f45,
     0x486c5c74,
     0x4665517f,
     0x547e4662,
     0x5a774b69,
     0xe090d0b0,
     0xee99ddbb,
     0xfc82caa6,
     0xf28bc7ad,
     0xd8b4e49c,
     0xd6bde997,
     0xc4a6fe8a,
     0xcaaff381,
     0x90d8b8e8,
     0x9ed1b5e3,
     0x8ccaa2fe,
     0x82c3aff5,
     0xa8fc8cc4,
     0xa6f581cf,
     0xb4ee96d2,
     0xbae79bd9,
     0xdb3bbb7b,
     0xd532b670,
     0xc729a16d,
     0xc920ac66,
     0xe31f8f57,
     0xed16825c,
     0xff0d9541,
     0xf104984a,
     0xab73d323,
     0xa57ade28,
     0xb761c935,
     0xb968c43e,
     0x9357e70f,
     0x9d5eea04,
     0x8f45fd19,
     0x814cf012,
     0x3bab6bcb,
     0x35a266c0,
     0x27b971dd,
     0x29b07cd6,
     0x038f5fe7,
     0x0d8652ec,
     0x1f9d45f1,
     0x119448fa,
     0x4be30393,
     0x45ea0e98,
     0x57f11985,
     0x59f8148e,
     0x73c737bf,
     0x7dce3ab4,
     0x6fd52da9,
     0x61dc20a2,
     0xad766df6,
     0xa37f60fd,
     0xb16477e0,
     0xbf6d7aeb,
     0x955259da,
     0x9b5b54d1,
     0x894043cc,
     0x87494ec7,
     0xdd3e05ae,
     0xd33708a5,
     0xc12c1fb8,
     0xcf2512b3,
     0xe51a3182,
     0xeb133c89,
     0xf9082b94,
     0xf701269f,
     0x4de6bd46,
     0x43efb04d,
     0x51f4a750,
     0x5ffdaa5b,
     0x75c2896a,
     0x7bcb8461,
     0x69d0937c,
     0x67d99e77,
     0x3daed51e,
     0x33a7d815,
     0x21bccf08,
     0x2fb5c203,
     0x058ae132,
     0x0b83ec39,
     0x1998fb24,
     0x1791f62f,
     0x764dd68d,
     0x7844db86,
     0x6a5fcc9b,
     0x6456c190,
     0x4e69e2a1,
     0x4060efaa,
     0x527bf8b7,
     0x5c72f5bc,
     0x0605bed5,
     0x080cb3de,
     0x1a17a4c3,
     0x141ea9c8,
     0x3e218af9,
     0x302887f2,
     0x223390ef,
     0x2c3a9de4,
     0x96dd063d,
     0x98d40b36,
     0x8acf1c2b,
     0x84c61120,
     0xaef93211,
     0xa0f03f1a,
     0xb2eb2807,
     0xbce2250c,
     0xe6956e65,
     0xe89c636e,
     0xfa877473,
     0xf48e7978,
     0xdeb15a49,
     0xd0b85742,
     0xc2a3405f,
     0xccaa4d54,
     0x41ecdaf7,
     0x4fe5d7fc,
     0x5dfec0e1,
     0x53f7cdea,
     0x79c8eedb,
     0x77c1e3d0,
     0x65daf4cd,
     0x6bd3f9c6,
     0x31a4b2af,
     0x3fadbfa4,
     0x2db6a8b9,
     0x23bfa5b2,
     0x09808683,
     0x07898b88,
     0x15929c95,
     0x1b9b919e,
     0xa17c0a47,
     0xaf75074c,
     0xbd6e1051,
     0xb3671d5a,
     0x99583e6b,
     0x97513360,
     0x854a247d,
     0x8b432976,
     0xd134621f,
     0xdf3d6f14,
     0xcd267809,
     0xc32f7502,
     0xe9105633,
     0xe7195b38,
     0xf5024c25,
     0xfb0b412e,
     0x9ad7618c,
     0x94de6c87,
     0x86c57b9a,
     0x88cc7691,
     0xa2f355a0,
     0xacfa58ab,
     0xbee14fb6,
     0xb0e842bd,
     0xea9f09d4,
     0xe49604df,
     0xf68d13c2,
     0xf8841ec9,
     0xd2bb3df8,
     0xdcb230f3,
     0xcea927ee,
     0xc0a02ae5,
     0x7a47b13c,
     0x744ebc37,
     0x6655ab2a,
     0x685ca621,
     0x42638510,
     0x4c6a881b,
     0x5e719f06,
     0x5078920d,
     0x0a0fd964,
     0x0406d46f,
     0x161dc372,
     0x1814ce79,
     0x322bed48,
     0x3c22e043,
     0x2e39f75e,
     0x2030fa55,
     0xec9ab701,
     0xe293ba0a,
     0xf088ad17,
     0xfe81a01c,
     0xd4be832d,
     0xdab78e26,
     0xc8ac993b,
     0xc6a59430,
     0x9cd2df59,
     0x92dbd252,
     0x80c0c54f,
     0x8ec9c844,
     0xa4f6eb75,
     0xaaffe67e,
     0xb8e4f163,
     0xb6edfc68,
     0x0c0a67b1,
     0x02036aba,
     0x10187da7,
     0x1e1170ac,
     0x342e539d,
     0x3a275e96,
     0x283c498b,
     0x26354480,
     0x7c420fe9,
     0x724b02e2,
     0x605015ff,
     0x6e5918f4,
     0x44663bc5,
     0x4a6f36ce,
     0x587421d3,
     0x567d2cd8,
     0x37a10c7a,
     0x39a80171,
     0x2bb3166c,
     0x25ba1b67,
     0x0f853856,
     0x018c355d,
     0x13972240,
     0x1d9e2f4b,
     0x47e96422,
     0x49e06929,
     0x5bfb7e34,
     0x55f2733f,
     0x7fcd500e,
     0x71c45d05,
     0x63df4a18,
     0x6dd64713,
     0xd731dcca,
     0xd938d1c1,
     0xcb23c6dc,
     0xc52acbd7,
     0xef15e8e6,
     0xe11ce5ed,
     0xf307f2f0,
     0xfd0efffb,
     0xa779b492,
     0xa970b999,
     0xbb6bae84,
     0xb562a38f,
     0x9f5d80be,
     0x91548db5,
     0x834f9aa8,
     0x8d4697a3
    ]);
    function expandKey128(cipherKey) {
     var b = 176, result = new Uint8Array(b);
     result.set(cipherKey);
     for (var j = 16, i = 1; j < b; ++i) {
      var t1 = result[j - 3], t2 = result[j - 2], t3 = result[j - 1], t4 = result[j - 4];
      t1 = s[t1];
      t2 = s[t2];
      t3 = s[t3];
      t4 = s[t4];
      t1 = t1 ^ rcon[i];
      for (var n = 0; n < 4; ++n) {
       result[j] = t1 ^= result[j - 16];
       j++;
       result[j] = t2 ^= result[j - 16];
       j++;
       result[j] = t3 ^= result[j - 16];
       j++;
       result[j] = t4 ^= result[j - 16];
       j++;
      }
     }
     return result;
    }
    function decrypt128(input, key) {
     var state = new Uint8Array(16);
     state.set(input);
     var i, j, k;
     var t, u, v;
     for (j = 0, k = 160; j < 16; ++j, ++k) {
      state[j] ^= key[k];
     }
     for (i = 9; i >= 1; --i) {
      t = state[13];
      state[13] = state[9];
      state[9] = state[5];
      state[5] = state[1];
      state[1] = t;
      t = state[14];
      u = state[10];
      state[14] = state[6];
      state[10] = state[2];
      state[6] = t;
      state[2] = u;
      t = state[15];
      u = state[11];
      v = state[7];
      state[15] = state[3];
      state[11] = t;
      state[7] = u;
      state[3] = v;
      for (j = 0; j < 16; ++j) {
       state[j] = inv_s[state[j]];
      }
      for (j = 0, k = i * 16; j < 16; ++j, ++k) {
       state[j] ^= key[k];
      }
      for (j = 0; j < 16; j += 4) {
       var s0 = mix[state[j]], s1 = mix[state[j + 1]], s2 = mix[state[j + 2]], s3 = mix[state[j + 3]];
       t = s0 ^ s1 >>> 8 ^ s1 << 24 ^ s2 >>> 16 ^ s2 << 16 ^ s3 >>> 24 ^ s3 << 8;
       state[j] = t >>> 24 & 0xFF;
       state[j + 1] = t >> 16 & 0xFF;
       state[j + 2] = t >> 8 & 0xFF;
       state[j + 3] = t & 0xFF;
      }
     }
     t = state[13];
     state[13] = state[9];
     state[9] = state[5];
     state[5] = state[1];
     state[1] = t;
     t = state[14];
     u = state[10];
     state[14] = state[6];
     state[10] = state[2];
     state[6] = t;
     state[2] = u;
     t = state[15];
     u = state[11];
     v = state[7];
     state[15] = state[3];
     state[11] = t;
     state[7] = u;
     state[3] = v;
     for (j = 0; j < 16; ++j) {
      state[j] = inv_s[state[j]];
      state[j] ^= key[j];
     }
     return state;
    }
    function encrypt128(input, key) {
     var t, u, v, k;
     var state = new Uint8Array(16);
     state.set(input);
     for (j = 0; j < 16; ++j) {
      state[j] ^= key[j];
     }
     for (i = 1; i < 10; i++) {
      for (j = 0; j < 16; ++j) {
       state[j] = s[state[j]];
      }
      v = state[1];
      state[1] = state[5];
      state[5] = state[9];
      state[9] = state[13];
      state[13] = v;
      v = state[2];
      u = state[6];
      state[2] = state[10];
      state[6] = state[14];
      state[10] = v;
      state[14] = u;
      v = state[3];
      u = state[7];
      t = state[11];
      state[3] = state[15];
      state[7] = v;
      state[11] = u;
      state[15] = t;
      for (var j = 0; j < 16; j += 4) {
       var s0 = state[j + 0], s1 = state[j + 1];
       var s2 = state[j + 2], s3 = state[j + 3];
       t = s0 ^ s1 ^ s2 ^ s3;
       state[j + 0] ^= t ^ mixCol[s0 ^ s1];
       state[j + 1] ^= t ^ mixCol[s1 ^ s2];
       state[j + 2] ^= t ^ mixCol[s2 ^ s3];
       state[j + 3] ^= t ^ mixCol[s3 ^ s0];
      }
      for (j = 0, k = i * 16; j < 16; ++j, ++k) {
       state[j] ^= key[k];
      }
     }
     for (j = 0; j < 16; ++j) {
      state[j] = s[state[j]];
     }
     v = state[1];
     state[1] = state[5];
     state[5] = state[9];
     state[9] = state[13];
     state[13] = v;
     v = state[2];
     u = state[6];
     state[2] = state[10];
     state[6] = state[14];
     state[10] = v;
     state[14] = u;
     v = state[3];
     u = state[7];
     t = state[11];
     state[3] = state[15];
     state[7] = v;
     state[11] = u;
     state[15] = t;
     for (j = 0, k = 160; j < 16; ++j, ++k) {
      state[j] ^= key[k];
     }
     return state;
    }
    function AES128Cipher(key) {
     this.key = expandKey128(key);
     this.buffer = new Uint8Array(16);
     this.bufferPosition = 0;
    }
    function decryptBlock2(data, finalize) {
     var i, j, ii, sourceLength = data.length, buffer = this.buffer, bufferLength = this.bufferPosition, result = [], iv = this.iv;
     for (i = 0; i < sourceLength; ++i) {
      buffer[bufferLength] = data[i];
      ++bufferLength;
      if (bufferLength < 16) {
       continue;
      }
      var plain = decrypt128(buffer, this.key);
      for (j = 0; j < 16; ++j) {
       plain[j] ^= iv[j];
      }
      iv = buffer;
      result.push(plain);
      buffer = new Uint8Array(16);
      bufferLength = 0;
     }
     this.buffer = buffer;
     this.bufferLength = bufferLength;
     this.iv = iv;
     if (result.length === 0) {
      return new Uint8Array([]);
     }
     var outputLength = 16 * result.length;
     if (finalize) {
      var lastBlock = result[result.length - 1];
      var psLen = lastBlock[15];
      if (psLen <= 16) {
       for (i = 15, ii = 16 - psLen; i >= ii; --i) {
        if (lastBlock[i] !== psLen) {
         psLen = 0;
         break;
        }
       }
       outputLength -= psLen;
       result[result.length - 1] = lastBlock.subarray(0, 16 - psLen);
      }
     }
     var output = new Uint8Array(outputLength);
     for (i = 0, j = 0, ii = result.length; i < ii; ++i, j += 16) {
      output.set(result[i], j);
     }
     return output;
    }
    AES128Cipher.prototype = {
     decryptBlock: function AES128Cipher_decryptBlock(data, finalize) {
      var i, sourceLength = data.length;
      var buffer = this.buffer, bufferLength = this.bufferPosition;
      for (i = 0; bufferLength < 16 && i < sourceLength; ++i, ++bufferLength) {
       buffer[bufferLength] = data[i];
      }
      if (bufferLength < 16) {
       this.bufferLength = bufferLength;
       return new Uint8Array([]);
      }
      this.iv = buffer;
      this.buffer = new Uint8Array(16);
      this.bufferLength = 0;
      this.decryptBlock = decryptBlock2;
      return this.decryptBlock(data.subarray(16), finalize);
     },
     encrypt: function AES128Cipher_encrypt(data, iv) {
      var i, j, ii, sourceLength = data.length, buffer = this.buffer, bufferLength = this.bufferPosition, result = [];
      if (!iv) {
       iv = new Uint8Array(16);
      }
      for (i = 0; i < sourceLength; ++i) {
       buffer[bufferLength] = data[i];
       ++bufferLength;
       if (bufferLength < 16) {
        continue;
       }
       for (j = 0; j < 16; ++j) {
        buffer[j] ^= iv[j];
       }
       var cipher = encrypt128(buffer, this.key);
       iv = cipher;
       result.push(cipher);
       buffer = new Uint8Array(16);
       bufferLength = 0;
      }
      this.buffer = buffer;
      this.bufferLength = bufferLength;
      this.iv = iv;
      if (result.length === 0) {
       return new Uint8Array([]);
      }
      var outputLength = 16 * result.length;
      var output = new Uint8Array(outputLength);
      for (i = 0, j = 0, ii = result.length; i < ii; ++i, j += 16) {
       output.set(result[i], j);
      }
      return output;
     }
    };
    return AES128Cipher;
   }();
   var AES256Cipher = function AES256CipherClosure() {
    var rcon = new Uint8Array([
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d,
     0x01,
     0x02,
     0x04,
     0x08,
     0x10,
     0x20,
     0x40,
     0x80,
     0x1b,
     0x36,
     0x6c,
     0xd8,
     0xab,
     0x4d,
     0x9a,
     0x2f,
     0x5e,
     0xbc,
     0x63,
     0xc6,
     0x97,
     0x35,
     0x6a,
     0xd4,
     0xb3,
     0x7d,
     0xfa,
     0xef,
     0xc5,
     0x91,
     0x39,
     0x72,
     0xe4,
     0xd3,
     0xbd,
     0x61,
     0xc2,
     0x9f,
     0x25,
     0x4a,
     0x94,
     0x33,
     0x66,
     0xcc,
     0x83,
     0x1d,
     0x3a,
     0x74,
     0xe8,
     0xcb,
     0x8d
    ]);
    var s = new Uint8Array([
     0x63,
     0x7c,
     0x77,
     0x7b,
     0xf2,
     0x6b,
     0x6f,
     0xc5,
     0x30,
     0x01,
     0x67,
     0x2b,
     0xfe,
     0xd7,
     0xab,
     0x76,
     0xca,
     0x82,
     0xc9,
     0x7d,
     0xfa,
     0x59,
     0x47,
     0xf0,
     0xad,
     0xd4,
     0xa2,
     0xaf,
     0x9c,
     0xa4,
     0x72,
     0xc0,
     0xb7,
     0xfd,
     0x93,
     0x26,
     0x36,
     0x3f,
     0xf7,
     0xcc,
     0x34,
     0xa5,
     0xe5,
     0xf1,
     0x71,
     0xd8,
     0x31,
     0x15,
     0x04,
     0xc7,
     0x23,
     0xc3,
     0x18,
     0x96,
     0x05,
     0x9a,
     0x07,
     0x12,
     0x80,
     0xe2,
     0xeb,
     0x27,
     0xb2,
     0x75,
     0x09,
     0x83,
     0x2c,
     0x1a,
     0x1b,
     0x6e,
     0x5a,
     0xa0,
     0x52,
     0x3b,
     0xd6,
     0xb3,
     0x29,
     0xe3,
     0x2f,
     0x84,
     0x53,
     0xd1,
     0x00,
     0xed,
     0x20,
     0xfc,
     0xb1,
     0x5b,
     0x6a,
     0xcb,
     0xbe,
     0x39,
     0x4a,
     0x4c,
     0x58,
     0xcf,
     0xd0,
     0xef,
     0xaa,
     0xfb,
     0x43,
     0x4d,
     0x33,
     0x85,
     0x45,
     0xf9,
     0x02,
     0x7f,
     0x50,
     0x3c,
     0x9f,
     0xa8,
     0x51,
     0xa3,
     0x40,
     0x8f,
     0x92,
     0x9d,
     0x38,
     0xf5,
     0xbc,
     0xb6,
     0xda,
     0x21,
     0x10,
     0xff,
     0xf3,
     0xd2,
     0xcd,
     0x0c,
     0x13,
     0xec,
     0x5f,
     0x97,
     0x44,
     0x17,
     0xc4,
     0xa7,
     0x7e,
     0x3d,
     0x64,
     0x5d,
     0x19,
     0x73,
     0x60,
     0x81,
     0x4f,
     0xdc,
     0x22,
     0x2a,
     0x90,
     0x88,
     0x46,
     0xee,
     0xb8,
     0x14,
     0xde,
     0x5e,
     0x0b,
     0xdb,
     0xe0,
     0x32,
     0x3a,
     0x0a,
     0x49,
     0x06,
     0x24,
     0x5c,
     0xc2,
     0xd3,
     0xac,
     0x62,
     0x91,
     0x95,
     0xe4,
     0x79,
     0xe7,
     0xc8,
     0x37,
     0x6d,
     0x8d,
     0xd5,
     0x4e,
     0xa9,
     0x6c,
     0x56,
     0xf4,
     0xea,
     0x65,
     0x7a,
     0xae,
     0x08,
     0xba,
     0x78,
     0x25,
     0x2e,
     0x1c,
     0xa6,
     0xb4,
     0xc6,
     0xe8,
     0xdd,
     0x74,
     0x1f,
     0x4b,
     0xbd,
     0x8b,
     0x8a,
     0x70,
     0x3e,
     0xb5,
     0x66,
     0x48,
     0x03,
     0xf6,
     0x0e,
     0x61,
     0x35,
     0x57,
     0xb9,
     0x86,
     0xc1,
     0x1d,
     0x9e,
     0xe1,
     0xf8,
     0x98,
     0x11,
     0x69,
     0xd9,
     0x8e,
     0x94,
     0x9b,
     0x1e,
     0x87,
     0xe9,
     0xce,
     0x55,
     0x28,
     0xdf,
     0x8c,
     0xa1,
     0x89,
     0x0d,
     0xbf,
     0xe6,
     0x42,
     0x68,
     0x41,
     0x99,
     0x2d,
     0x0f,
     0xb0,
     0x54,
     0xbb,
     0x16
    ]);
    var inv_s = new Uint8Array([
     0x52,
     0x09,
     0x6a,
     0xd5,
     0x30,
     0x36,
     0xa5,
     0x38,
     0xbf,
     0x40,
     0xa3,
     0x9e,
     0x81,
     0xf3,
     0xd7,
     0xfb,
     0x7c,
     0xe3,
     0x39,
     0x82,
     0x9b,
     0x2f,
     0xff,
     0x87,
     0x34,
     0x8e,
     0x43,
     0x44,
     0xc4,
     0xde,
     0xe9,
     0xcb,
     0x54,
     0x7b,
     0x94,
     0x32,
     0xa6,
     0xc2,
     0x23,
     0x3d,
     0xee,
     0x4c,
     0x95,
     0x0b,
     0x42,
     0xfa,
     0xc3,
     0x4e,
     0x08,
     0x2e,
     0xa1,
     0x66,
     0x28,
     0xd9,
     0x24,
     0xb2,
     0x76,
     0x5b,
     0xa2,
     0x49,
     0x6d,
     0x8b,
     0xd1,
     0x25,
     0x72,
     0xf8,
     0xf6,
     0x64,
     0x86,
     0x68,
     0x98,
     0x16,
     0xd4,
     0xa4,
     0x5c,
     0xcc,
     0x5d,
     0x65,
     0xb6,
     0x92,
     0x6c,
     0x70,
     0x48,
     0x50,
     0xfd,
     0xed,
     0xb9,
     0xda,
     0x5e,
     0x15,
     0x46,
     0x57,
     0xa7,
     0x8d,
     0x9d,
     0x84,
     0x90,
     0xd8,
     0xab,
     0x00,
     0x8c,
     0xbc,
     0xd3,
     0x0a,
     0xf7,
     0xe4,
     0x58,
     0x05,
     0xb8,
     0xb3,
     0x45,
     0x06,
     0xd0,
     0x2c,
     0x1e,
     0x8f,
     0xca,
     0x3f,
     0x0f,
     0x02,
     0xc1,
     0xaf,
     0xbd,
     0x03,
     0x01,
     0x13,
     0x8a,
     0x6b,
     0x3a,
     0x91,
     0x11,
     0x41,
     0x4f,
     0x67,
     0xdc,
     0xea,
     0x97,
     0xf2,
     0xcf,
     0xce,
     0xf0,
     0xb4,
     0xe6,
     0x73,
     0x96,
     0xac,
     0x74,
     0x22,
     0xe7,
     0xad,
     0x35,
     0x85,
     0xe2,
     0xf9,
     0x37,
     0xe8,
     0x1c,
     0x75,
     0xdf,
     0x6e,
     0x47,
     0xf1,
     0x1a,
     0x71,
     0x1d,
     0x29,
     0xc5,
     0x89,
     0x6f,
     0xb7,
     0x62,
     0x0e,
     0xaa,
     0x18,
     0xbe,
     0x1b,
     0xfc,
     0x56,
     0x3e,
     0x4b,
     0xc6,
     0xd2,
     0x79,
     0x20,
     0x9a,
     0xdb,
     0xc0,
     0xfe,
     0x78,
     0xcd,
     0x5a,
     0xf4,
     0x1f,
     0xdd,
     0xa8,
     0x33,
     0x88,
     0x07,
     0xc7,
     0x31,
     0xb1,
     0x12,
     0x10,
     0x59,
     0x27,
     0x80,
     0xec,
     0x5f,
     0x60,
     0x51,
     0x7f,
     0xa9,
     0x19,
     0xb5,
     0x4a,
     0x0d,
     0x2d,
     0xe5,
     0x7a,
     0x9f,
     0x93,
     0xc9,
     0x9c,
     0xef,
     0xa0,
     0xe0,
     0x3b,
     0x4d,
     0xae,
     0x2a,
     0xf5,
     0xb0,
     0xc8,
     0xeb,
     0xbb,
     0x3c,
     0x83,
     0x53,
     0x99,
     0x61,
     0x17,
     0x2b,
     0x04,
     0x7e,
     0xba,
     0x77,
     0xd6,
     0x26,
     0xe1,
     0x69,
     0x14,
     0x63,
     0x55,
     0x21,
     0x0c,
     0x7d
    ]);
    var mixCol = new Uint8Array(256);
    for (var i = 0; i < 256; i++) {
     if (i < 128) {
      mixCol[i] = i << 1;
     } else {
      mixCol[i] = i << 1 ^ 0x1b;
     }
    }
    var mix = new Uint32Array([
     0x00000000,
     0x0e090d0b,
     0x1c121a16,
     0x121b171d,
     0x3824342c,
     0x362d3927,
     0x24362e3a,
     0x2a3f2331,
     0x70486858,
     0x7e416553,
     0x6c5a724e,
     0x62537f45,
     0x486c5c74,
     0x4665517f,
     0x547e4662,
     0x5a774b69,
     0xe090d0b0,
     0xee99ddbb,
     0xfc82caa6,
     0xf28bc7ad,
     0xd8b4e49c,
     0xd6bde997,
     0xc4a6fe8a,
     0xcaaff381,
     0x90d8b8e8,
     0x9ed1b5e3,
     0x8ccaa2fe,
     0x82c3aff5,
     0xa8fc8cc4,
     0xa6f581cf,
     0xb4ee96d2,
     0xbae79bd9,
     0xdb3bbb7b,
     0xd532b670,
     0xc729a16d,
     0xc920ac66,
     0xe31f8f57,
     0xed16825c,
     0xff0d9541,
     0xf104984a,
     0xab73d323,
     0xa57ade28,
     0xb761c935,
     0xb968c43e,
     0x9357e70f,
     0x9d5eea04,
     0x8f45fd19,
     0x814cf012,
     0x3bab6bcb,
     0x35a266c0,
     0x27b971dd,
     0x29b07cd6,
     0x038f5fe7,
     0x0d8652ec,
     0x1f9d45f1,
     0x119448fa,
     0x4be30393,
     0x45ea0e98,
     0x57f11985,
     0x59f8148e,
     0x73c737bf,
     0x7dce3ab4,
     0x6fd52da9,
     0x61dc20a2,
     0xad766df6,
     0xa37f60fd,
     0xb16477e0,
     0xbf6d7aeb,
     0x955259da,
     0x9b5b54d1,
     0x894043cc,
     0x87494ec7,
     0xdd3e05ae,
     0xd33708a5,
     0xc12c1fb8,
     0xcf2512b3,
     0xe51a3182,
     0xeb133c89,
     0xf9082b94,
     0xf701269f,
     0x4de6bd46,
     0x43efb04d,
     0x51f4a750,
     0x5ffdaa5b,
     0x75c2896a,
     0x7bcb8461,
     0x69d0937c,
     0x67d99e77,
     0x3daed51e,
     0x33a7d815,
     0x21bccf08,
     0x2fb5c203,
     0x058ae132,
     0x0b83ec39,
     0x1998fb24,
     0x1791f62f,
     0x764dd68d,
     0x7844db86,
     0x6a5fcc9b,
     0x6456c190,
     0x4e69e2a1,
     0x4060efaa,
     0x527bf8b7,
     0x5c72f5bc,
     0x0605bed5,
     0x080cb3de,
     0x1a17a4c3,
     0x141ea9c8,
     0x3e218af9,
     0x302887f2,
     0x223390ef,
     0x2c3a9de4,
     0x96dd063d,
     0x98d40b36,
     0x8acf1c2b,
     0x84c61120,
     0xaef93211,
     0xa0f03f1a,
     0xb2eb2807,
     0xbce2250c,
     0xe6956e65,
     0xe89c636e,
     0xfa877473,
     0xf48e7978,
     0xdeb15a49,
     0xd0b85742,
     0xc2a3405f,
     0xccaa4d54,
     0x41ecdaf7,
     0x4fe5d7fc,
     0x5dfec0e1,
     0x53f7cdea,
     0x79c8eedb,
     0x77c1e3d0,
     0x65daf4cd,
     0x6bd3f9c6,
     0x31a4b2af,
     0x3fadbfa4,
     0x2db6a8b9,
     0x23bfa5b2,
     0x09808683,
     0x07898b88,
     0x15929c95,
     0x1b9b919e,
     0xa17c0a47,
     0xaf75074c,
     0xbd6e1051,
     0xb3671d5a,
     0x99583e6b,
     0x97513360,
     0x854a247d,
     0x8b432976,
     0xd134621f,
     0xdf3d6f14,
     0xcd267809,
     0xc32f7502,
     0xe9105633,
     0xe7195b38,
     0xf5024c25,
     0xfb0b412e,
     0x9ad7618c,
     0x94de6c87,
     0x86c57b9a,
     0x88cc7691,
     0xa2f355a0,
     0xacfa58ab,
     0xbee14fb6,
     0xb0e842bd,
     0xea9f09d4,
     0xe49604df,
     0xf68d13c2,
     0xf8841ec9,
     0xd2bb3df8,
     0xdcb230f3,
     0xcea927ee,
     0xc0a02ae5,
     0x7a47b13c,
     0x744ebc37,
     0x6655ab2a,
     0x685ca621,
     0x42638510,
     0x4c6a881b,
     0x5e719f06,
     0x5078920d,
     0x0a0fd964,
     0x0406d46f,
     0x161dc372,
     0x1814ce79,
     0x322bed48,
     0x3c22e043,
     0x2e39f75e,
     0x2030fa55,
     0xec9ab701,
     0xe293ba0a,
     0xf088ad17,
     0xfe81a01c,
     0xd4be832d,
     0xdab78e26,
     0xc8ac993b,
     0xc6a59430,
     0x9cd2df59,
     0x92dbd252,
     0x80c0c54f,
     0x8ec9c844,
     0xa4f6eb75,
     0xaaffe67e,
     0xb8e4f163,
     0xb6edfc68,
     0x0c0a67b1,
     0x02036aba,
     0x10187da7,
     0x1e1170ac,
     0x342e539d,
     0x3a275e96,
     0x283c498b,
     0x26354480,
     0x7c420fe9,
     0x724b02e2,
     0x605015ff,
     0x6e5918f4,
     0x44663bc5,
     0x4a6f36ce,
     0x587421d3,
     0x567d2cd8,
     0x37a10c7a,
     0x39a80171,
     0x2bb3166c,
     0x25ba1b67,
     0x0f853856,
     0x018c355d,
     0x13972240,
     0x1d9e2f4b,
     0x47e96422,
     0x49e06929,
     0x5bfb7e34,
     0x55f2733f,
     0x7fcd500e,
     0x71c45d05,
     0x63df4a18,
     0x6dd64713,
     0xd731dcca,
     0xd938d1c1,
     0xcb23c6dc,
     0xc52acbd7,
     0xef15e8e6,
     0xe11ce5ed,
     0xf307f2f0,
     0xfd0efffb,
     0xa779b492,
     0xa970b999,
     0xbb6bae84,
     0xb562a38f,
     0x9f5d80be,
     0x91548db5,
     0x834f9aa8,
     0x8d4697a3
    ]);
    function expandKey256(cipherKey) {
     var b = 240, result = new Uint8Array(b);
     var r = 1;
     result.set(cipherKey);
     for (var j = 32, i = 1; j < b; ++i) {
      if (j % 32 === 16) {
       t1 = s[t1];
       t2 = s[t2];
       t3 = s[t3];
       t4 = s[t4];
      } else if (j % 32 === 0) {
       var t1 = result[j - 3], t2 = result[j - 2], t3 = result[j - 1], t4 = result[j - 4];
       t1 = s[t1];
       t2 = s[t2];
       t3 = s[t3];
       t4 = s[t4];
       t1 = t1 ^ r;
       if ((r <<= 1) >= 256) {
        r = (r ^ 0x1b) & 0xFF;
       }
      }
      for (var n = 0; n < 4; ++n) {
       result[j] = t1 ^= result[j - 32];
       j++;
       result[j] = t2 ^= result[j - 32];
       j++;
       result[j] = t3 ^= result[j - 32];
       j++;
       result[j] = t4 ^= result[j - 32];
       j++;
      }
     }
     return result;
    }
    function decrypt256(input, key) {
     var state = new Uint8Array(16);
     state.set(input);
     var i, j, k;
     var t, u, v;
     for (j = 0, k = 224; j < 16; ++j, ++k) {
      state[j] ^= key[k];
     }
     for (i = 13; i >= 1; --i) {
      t = state[13];
      state[13] = state[9];
      state[9] = state[5];
      state[5] = state[1];
      state[1] = t;
      t = state[14];
      u = state[10];
      state[14] = state[6];
      state[10] = state[2];
      state[6] = t;
      state[2] = u;
      t = state[15];
      u = state[11];
      v = state[7];
      state[15] = state[3];
      state[11] = t;
      state[7] = u;
      state[3] = v;
      for (j = 0; j < 16; ++j) {
       state[j] = inv_s[state[j]];
      }
      for (j = 0, k = i * 16; j < 16; ++j, ++k) {
       state[j] ^= key[k];
      }
      for (j = 0; j < 16; j += 4) {
       var s0 = mix[state[j]], s1 = mix[state[j + 1]], s2 = mix[state[j + 2]], s3 = mix[state[j + 3]];
       t = s0 ^ s1 >>> 8 ^ s1 << 24 ^ s2 >>> 16 ^ s2 << 16 ^ s3 >>> 24 ^ s3 << 8;
       state[j] = t >>> 24 & 0xFF;
       state[j + 1] = t >> 16 & 0xFF;
       state[j + 2] = t >> 8 & 0xFF;
       state[j + 3] = t & 0xFF;
      }
     }
     t = state[13];
     state[13] = state[9];
     state[9] = state[5];
     state[5] = state[1];
     state[1] = t;
     t = state[14];
     u = state[10];
     state[14] = state[6];
     state[10] = state[2];
     state[6] = t;
     state[2] = u;
     t = state[15];
     u = state[11];
     v = state[7];
     state[15] = state[3];
     state[11] = t;
     state[7] = u;
     state[3] = v;
     for (j = 0; j < 16; ++j) {
      state[j] = inv_s[state[j]];
      state[j] ^= key[j];
     }
     return state;
    }
    function encrypt256(input, key) {
     var t, u, v, k;
     var state = new Uint8Array(16);
     state.set(input);
     for (j = 0; j < 16; ++j) {
      state[j] ^= key[j];
     }
     for (i = 1; i < 14; i++) {
      for (j = 0; j < 16; ++j) {
       state[j] = s[state[j]];
      }
      v = state[1];
      state[1] = state[5];
      state[5] = state[9];
      state[9] = state[13];
      state[13] = v;
      v = state[2];
      u = state[6];
      state[2] = state[10];
      state[6] = state[14];
      state[10] = v;
      state[14] = u;
      v = state[3];
      u = state[7];
      t = state[11];
      state[3] = state[15];
      state[7] = v;
      state[11] = u;
      state[15] = t;
      for (var j = 0; j < 16; j += 4) {
       var s0 = state[j + 0], s1 = state[j + 1];
       var s2 = state[j + 2], s3 = state[j + 3];
       t = s0 ^ s1 ^ s2 ^ s3;
       state[j + 0] ^= t ^ mixCol[s0 ^ s1];
       state[j + 1] ^= t ^ mixCol[s1 ^ s2];
       state[j + 2] ^= t ^ mixCol[s2 ^ s3];
       state[j + 3] ^= t ^ mixCol[s3 ^ s0];
      }
      for (j = 0, k = i * 16; j < 16; ++j, ++k) {
       state[j] ^= key[k];
      }
     }
     for (j = 0; j < 16; ++j) {
      state[j] = s[state[j]];
     }
     v = state[1];
     state[1] = state[5];
     state[5] = state[9];
     state[9] = state[13];
     state[13] = v;
     v = state[2];
     u = state[6];
     state[2] = state[10];
     state[6] = state[14];
     state[10] = v;
     state[14] = u;
     v = state[3];
     u = state[7];
     t = state[11];
     state[3] = state[15];
     state[7] = v;
     state[11] = u;
     state[15] = t;
     for (j = 0, k = 224; j < 16; ++j, ++k) {
      state[j] ^= key[k];
     }
     return state;
    }
    function AES256Cipher(key) {
     this.key = expandKey256(key);
     this.buffer = new Uint8Array(16);
     this.bufferPosition = 0;
    }
    function decryptBlock2(data, finalize) {
     var i, j, ii, sourceLength = data.length, buffer = this.buffer, bufferLength = this.bufferPosition, result = [], iv = this.iv;
     for (i = 0; i < sourceLength; ++i) {
      buffer[bufferLength] = data[i];
      ++bufferLength;
      if (bufferLength < 16) {
       continue;
      }
      var plain = decrypt256(buffer, this.key);
      for (j = 0; j < 16; ++j) {
       plain[j] ^= iv[j];
      }
      iv = buffer;
      result.push(plain);
      buffer = new Uint8Array(16);
      bufferLength = 0;
     }
     this.buffer = buffer;
     this.bufferLength = bufferLength;
     this.iv = iv;
     if (result.length === 0) {
      return new Uint8Array([]);
     }
     var outputLength = 16 * result.length;
     if (finalize) {
      var lastBlock = result[result.length - 1];
      var psLen = lastBlock[15];
      if (psLen <= 16) {
       for (i = 15, ii = 16 - psLen; i >= ii; --i) {
        if (lastBlock[i] !== psLen) {
         psLen = 0;
         break;
        }
       }
       outputLength -= psLen;
       result[result.length - 1] = lastBlock.subarray(0, 16 - psLen);
      }
     }
     var output = new Uint8Array(outputLength);
     for (i = 0, j = 0, ii = result.length; i < ii; ++i, j += 16) {
      output.set(result[i], j);
     }
     return output;
    }
    AES256Cipher.prototype = {
     decryptBlock: function AES256Cipher_decryptBlock(data, finalize, iv) {
      var i, sourceLength = data.length;
      var buffer = this.buffer, bufferLength = this.bufferPosition;
      if (iv) {
       this.iv = iv;
      } else {
       for (i = 0; bufferLength < 16 && i < sourceLength; ++i, ++bufferLength) {
        buffer[bufferLength] = data[i];
       }
       if (bufferLength < 16) {
        this.bufferLength = bufferLength;
        return new Uint8Array([]);
       }
       this.iv = buffer;
       data = data.subarray(16);
      }
      this.buffer = new Uint8Array(16);
      this.bufferLength = 0;
      this.decryptBlock = decryptBlock2;
      return this.decryptBlock(data, finalize);
     },
     encrypt: function AES256Cipher_encrypt(data, iv) {
      var i, j, ii, sourceLength = data.length, buffer = this.buffer, bufferLength = this.bufferPosition, result = [];
      if (!iv) {
       iv = new Uint8Array(16);
      }
      for (i = 0; i < sourceLength; ++i) {
       buffer[bufferLength] = data[i];
       ++bufferLength;
       if (bufferLength < 16) {
        continue;
       }
       for (j = 0; j < 16; ++j) {
        buffer[j] ^= iv[j];
       }
       var cipher = encrypt256(buffer, this.key);
       this.iv = cipher;
       result.push(cipher);
       buffer = new Uint8Array(16);
       bufferLength = 0;
      }
      this.buffer = buffer;
      this.bufferLength = bufferLength;
      this.iv = iv;
      if (result.length === 0) {
       return new Uint8Array([]);
      }
      var outputLength = 16 * result.length;
      var output = new Uint8Array(outputLength);
      for (i = 0, j = 0, ii = result.length; i < ii; ++i, j += 16) {
       output.set(result[i], j);
      }
      return output;
     }
    };
    return AES256Cipher;
   }();
   var PDF17 = function PDF17Closure() {
    function compareByteArrays(array1, array2) {
     if (array1.length !== array2.length) {
      return false;
     }
     for (var i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
       return false;
      }
     }
     return true;
    }
    function PDF17() {
    }
    PDF17.prototype = {
     checkOwnerPassword: function PDF17_checkOwnerPassword(password, ownerValidationSalt, userBytes, ownerPassword) {
      var hashData = new Uint8Array(password.length + 56);
      hashData.set(password, 0);
      hashData.set(ownerValidationSalt, password.length);
      hashData.set(userBytes, password.length + ownerValidationSalt.length);
      var result = calculateSHA256(hashData, 0, hashData.length);
      return compareByteArrays(result, ownerPassword);
     },
     checkUserPassword: function PDF17_checkUserPassword(password, userValidationSalt, userPassword) {
      var hashData = new Uint8Array(password.length + 8);
      hashData.set(password, 0);
      hashData.set(userValidationSalt, password.length);
      var result = calculateSHA256(hashData, 0, hashData.length);
      return compareByteArrays(result, userPassword);
     },
     getOwnerKey: function PDF17_getOwnerKey(password, ownerKeySalt, userBytes, ownerEncryption) {
      var hashData = new Uint8Array(password.length + 56);
      hashData.set(password, 0);
      hashData.set(ownerKeySalt, password.length);
      hashData.set(userBytes, password.length + ownerKeySalt.length);
      var key = calculateSHA256(hashData, 0, hashData.length);
      var cipher = new AES256Cipher(key);
      return cipher.decryptBlock(ownerEncryption, false, new Uint8Array(16));
     },
     getUserKey: function PDF17_getUserKey(password, userKeySalt, userEncryption) {
      var hashData = new Uint8Array(password.length + 8);
      hashData.set(password, 0);
      hashData.set(userKeySalt, password.length);
      var key = calculateSHA256(hashData, 0, hashData.length);
      var cipher = new AES256Cipher(key);
      return cipher.decryptBlock(userEncryption, false, new Uint8Array(16));
     }
    };
    return PDF17;
   }();
   var PDF20 = function PDF20Closure() {
    function concatArrays(array1, array2) {
     var t = new Uint8Array(array1.length + array2.length);
     t.set(array1, 0);
     t.set(array2, array1.length);
     return t;
    }
    function calculatePDF20Hash(password, input, userBytes) {
     var k = calculateSHA256(input, 0, input.length).subarray(0, 32);
     var e = [0];
     var i = 0;
     while (i < 64 || e[e.length - 1] > i - 32) {
      var arrayLength = password.length + k.length + userBytes.length;
      var k1 = new Uint8Array(arrayLength * 64);
      var array = concatArrays(password, k);
      array = concatArrays(array, userBytes);
      for (var j = 0, pos = 0; j < 64; j++, pos += arrayLength) {
       k1.set(array, pos);
      }
      var cipher = new AES128Cipher(k.subarray(0, 16));
      e = cipher.encrypt(k1, k.subarray(16, 32));
      var remainder = 0;
      for (var z = 0; z < 16; z++) {
       remainder *= 256 % 3;
       remainder %= 3;
       remainder += (e[z] >>> 0) % 3;
       remainder %= 3;
      }
      if (remainder === 0) {
       k = calculateSHA256(e, 0, e.length);
      } else if (remainder === 1) {
       k = calculateSHA384(e, 0, e.length);
      } else if (remainder === 2) {
       k = calculateSHA512(e, 0, e.length);
      }
      i++;
     }
     return k.subarray(0, 32);
    }
    function PDF20() {
    }
    function compareByteArrays(array1, array2) {
     if (array1.length !== array2.length) {
      return false;
     }
     for (var i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
       return false;
      }
     }
     return true;
    }
    PDF20.prototype = {
     hash: function PDF20_hash(password, concatBytes, userBytes) {
      return calculatePDF20Hash(password, concatBytes, userBytes);
     },
     checkOwnerPassword: function PDF20_checkOwnerPassword(password, ownerValidationSalt, userBytes, ownerPassword) {
      var hashData = new Uint8Array(password.length + 56);
      hashData.set(password, 0);
      hashData.set(ownerValidationSalt, password.length);
      hashData.set(userBytes, password.length + ownerValidationSalt.length);
      var result = calculatePDF20Hash(password, hashData, userBytes);
      return compareByteArrays(result, ownerPassword);
     },
     checkUserPassword: function PDF20_checkUserPassword(password, userValidationSalt, userPassword) {
      var hashData = new Uint8Array(password.length + 8);
      hashData.set(password, 0);
      hashData.set(userValidationSalt, password.length);
      var result = calculatePDF20Hash(password, hashData, []);
      return compareByteArrays(result, userPassword);
     },
     getOwnerKey: function PDF20_getOwnerKey(password, ownerKeySalt, userBytes, ownerEncryption) {
      var hashData = new Uint8Array(password.length + 56);
      hashData.set(password, 0);
      hashData.set(ownerKeySalt, password.length);
      hashData.set(userBytes, password.length + ownerKeySalt.length);
      var key = calculatePDF20Hash(password, hashData, userBytes);
      var cipher = new AES256Cipher(key);
      return cipher.decryptBlock(ownerEncryption, false, new Uint8Array(16));
     },
     getUserKey: function PDF20_getUserKey(password, userKeySalt, userEncryption) {
      var hashData = new Uint8Array(password.length + 8);
      hashData.set(password, 0);
      hashData.set(userKeySalt, password.length);
      var key = calculatePDF20Hash(password, hashData, []);
      var cipher = new AES256Cipher(key);
      return cipher.decryptBlock(userEncryption, false, new Uint8Array(16));
     }
    };
    return PDF20;
   }();
   var CipherTransform = function CipherTransformClosure() {
    function CipherTransform(stringCipherConstructor, streamCipherConstructor) {
     this.StringCipherConstructor = stringCipherConstructor;
     this.StreamCipherConstructor = streamCipherConstructor;
    }
    CipherTransform.prototype = {
     createStream: function CipherTransform_createStream(stream, length) {
      var cipher = new this.StreamCipherConstructor();
      return new DecryptStream(stream, length, function cipherTransformDecryptStream(data, finalize) {
       return cipher.decryptBlock(data, finalize);
      });
     },
     decryptString: function CipherTransform_decryptString(s) {
      var cipher = new this.StringCipherConstructor();
      var data = stringToBytes(s);
      data = cipher.decryptBlock(data, true);
      return bytesToString(data);
     }
    };
    return CipherTransform;
   }();
   var CipherTransformFactory = function CipherTransformFactoryClosure() {
    var defaultPasswordBytes = new Uint8Array([
     0x28,
     0xBF,
     0x4E,
     0x5E,
     0x4E,
     0x75,
     0x8A,
     0x41,
     0x64,
     0x00,
     0x4E,
     0x56,
     0xFF,
     0xFA,
     0x01,
     0x08,
     0x2E,
     0x2E,
     0x00,
     0xB6,
     0xD0,
     0x68,
     0x3E,
     0x80,
     0x2F,
     0x0C,
     0xA9,
     0xFE,
     0x64,
     0x53,
     0x69,
     0x7A
    ]);
    function createEncryptionKey20(revision, password, ownerPassword, ownerValidationSalt, ownerKeySalt, uBytes, userPassword, userValidationSalt, userKeySalt, ownerEncryption, userEncryption, perms) {
     if (password) {
      var passwordLength = Math.min(127, password.length);
      password = password.subarray(0, passwordLength);
     } else {
      password = [];
     }
     var pdfAlgorithm;
     if (revision === 6) {
      pdfAlgorithm = new PDF20();
     } else {
      pdfAlgorithm = new PDF17();
     }
     if (pdfAlgorithm.checkUserPassword(password, userValidationSalt, userPassword)) {
      return pdfAlgorithm.getUserKey(password, userKeySalt, userEncryption);
     } else if (password.length && pdfAlgorithm.checkOwnerPassword(password, ownerValidationSalt, uBytes, ownerPassword)) {
      return pdfAlgorithm.getOwnerKey(password, ownerKeySalt, uBytes, ownerEncryption);
     }
     return null;
    }
    function prepareKeyData(fileId, password, ownerPassword, userPassword, flags, revision, keyLength, encryptMetadata) {
     var hashDataSize = 40 + ownerPassword.length + fileId.length;
     var hashData = new Uint8Array(hashDataSize), i = 0, j, n;
     if (password) {
      n = Math.min(32, password.length);
      for (; i < n; ++i) {
       hashData[i] = password[i];
      }
     }
     j = 0;
     while (i < 32) {
      hashData[i++] = defaultPasswordBytes[j++];
     }
     for (j = 0, n = ownerPassword.length; j < n; ++j) {
      hashData[i++] = ownerPassword[j];
     }
     hashData[i++] = flags & 0xFF;
     hashData[i++] = flags >> 8 & 0xFF;
     hashData[i++] = flags >> 16 & 0xFF;
     hashData[i++] = flags >>> 24 & 0xFF;
     for (j = 0, n = fileId.length; j < n; ++j) {
      hashData[i++] = fileId[j];
     }
     if (revision >= 4 && !encryptMetadata) {
      hashData[i++] = 0xFF;
      hashData[i++] = 0xFF;
      hashData[i++] = 0xFF;
      hashData[i++] = 0xFF;
     }
     var hash = calculateMD5(hashData, 0, i);
     var keyLengthInBytes = keyLength >> 3;
     if (revision >= 3) {
      for (j = 0; j < 50; ++j) {
       hash = calculateMD5(hash, 0, keyLengthInBytes);
      }
     }
     var encryptionKey = hash.subarray(0, keyLengthInBytes);
     var cipher, checkData;
     if (revision >= 3) {
      for (i = 0; i < 32; ++i) {
       hashData[i] = defaultPasswordBytes[i];
      }
      for (j = 0, n = fileId.length; j < n; ++j) {
       hashData[i++] = fileId[j];
      }
      cipher = new ARCFourCipher(encryptionKey);
      checkData = cipher.encryptBlock(calculateMD5(hashData, 0, i));
      n = encryptionKey.length;
      var derivedKey = new Uint8Array(n), k;
      for (j = 1; j <= 19; ++j) {
       for (k = 0; k < n; ++k) {
        derivedKey[k] = encryptionKey[k] ^ j;
       }
       cipher = new ARCFourCipher(derivedKey);
       checkData = cipher.encryptBlock(checkData);
      }
      for (j = 0, n = checkData.length; j < n; ++j) {
       if (userPassword[j] !== checkData[j]) {
        return null;
       }
      }
     } else {
      cipher = new ARCFourCipher(encryptionKey);
      checkData = cipher.encryptBlock(defaultPasswordBytes);
      for (j = 0, n = checkData.length; j < n; ++j) {
       if (userPassword[j] !== checkData[j]) {
        return null;
       }
      }
     }
     return encryptionKey;
    }
    function decodeUserPassword(password, ownerPassword, revision, keyLength) {
     var hashData = new Uint8Array(32), i = 0, j, n;
     n = Math.min(32, password.length);
     for (; i < n; ++i) {
      hashData[i] = password[i];
     }
     j = 0;
     while (i < 32) {
      hashData[i++] = defaultPasswordBytes[j++];
     }
     var hash = calculateMD5(hashData, 0, i);
     var keyLengthInBytes = keyLength >> 3;
     if (revision >= 3) {
      for (j = 0; j < 50; ++j) {
       hash = calculateMD5(hash, 0, hash.length);
      }
     }
     var cipher, userPassword;
     if (revision >= 3) {
      userPassword = ownerPassword;
      var derivedKey = new Uint8Array(keyLengthInBytes), k;
      for (j = 19; j >= 0; j--) {
       for (k = 0; k < keyLengthInBytes; ++k) {
        derivedKey[k] = hash[k] ^ j;
       }
       cipher = new ARCFourCipher(derivedKey);
       userPassword = cipher.encryptBlock(userPassword);
      }
     } else {
      cipher = new ARCFourCipher(hash.subarray(0, keyLengthInBytes));
      userPassword = cipher.encryptBlock(ownerPassword);
     }
     return userPassword;
    }
    var identityName = Name.get('Identity');
    function CipherTransformFactory(dict, fileId, password) {
     var filter = dict.get('Filter');
     if (!isName(filter, 'Standard')) {
      error('unknown encryption method');
     }
     this.dict = dict;
     var algorithm = dict.get('V');
     if (!isInt(algorithm) || algorithm !== 1 && algorithm !== 2 && algorithm !== 4 && algorithm !== 5) {
      error('unsupported encryption algorithm');
     }
     this.algorithm = algorithm;
     var keyLength = dict.get('Length');
     if (!keyLength) {
      if (algorithm <= 3) {
       keyLength = 40;
      } else {
       var cfDict = dict.get('CF');
       var streamCryptoName = dict.get('StmF');
       if (isDict(cfDict) && isName(streamCryptoName)) {
        cfDict.suppressEncryption = true;
        var handlerDict = cfDict.get(streamCryptoName.name);
        keyLength = handlerDict && handlerDict.get('Length') || 128;
        if (keyLength < 40) {
         keyLength <<= 3;
        }
       }
      }
     }
     if (!isInt(keyLength) || keyLength < 40 || keyLength % 8 !== 0) {
      error('invalid key length');
     }
     var ownerPassword = stringToBytes(dict.get('O')).subarray(0, 32);
     var userPassword = stringToBytes(dict.get('U')).subarray(0, 32);
     var flags = dict.get('P');
     var revision = dict.get('R');
     var encryptMetadata = (algorithm === 4 || algorithm === 5) && dict.get('EncryptMetadata') !== false;
     this.encryptMetadata = encryptMetadata;
     var fileIdBytes = stringToBytes(fileId);
     var passwordBytes;
     if (password) {
      if (revision === 6) {
       try {
        password = utf8StringToString(password);
       } catch (ex) {
        warn('CipherTransformFactory: ' + 'Unable to convert UTF8 encoded password.');
       }
      }
      passwordBytes = stringToBytes(password);
     }
     var encryptionKey;
     if (algorithm !== 5) {
      encryptionKey = prepareKeyData(fileIdBytes, passwordBytes, ownerPassword, userPassword, flags, revision, keyLength, encryptMetadata);
     } else {
      var ownerValidationSalt = stringToBytes(dict.get('O')).subarray(32, 40);
      var ownerKeySalt = stringToBytes(dict.get('O')).subarray(40, 48);
      var uBytes = stringToBytes(dict.get('U')).subarray(0, 48);
      var userValidationSalt = stringToBytes(dict.get('U')).subarray(32, 40);
      var userKeySalt = stringToBytes(dict.get('U')).subarray(40, 48);
      var ownerEncryption = stringToBytes(dict.get('OE'));
      var userEncryption = stringToBytes(dict.get('UE'));
      var perms = stringToBytes(dict.get('Perms'));
      encryptionKey = createEncryptionKey20(revision, passwordBytes, ownerPassword, ownerValidationSalt, ownerKeySalt, uBytes, userPassword, userValidationSalt, userKeySalt, ownerEncryption, userEncryption, perms);
     }
     if (!encryptionKey && !password) {
      throw new PasswordException('No password given', PasswordResponses.NEED_PASSWORD);
     } else if (!encryptionKey && password) {
      var decodedPassword = decodeUserPassword(passwordBytes, ownerPassword, revision, keyLength);
      encryptionKey = prepareKeyData(fileIdBytes, decodedPassword, ownerPassword, userPassword, flags, revision, keyLength, encryptMetadata);
     }
     if (!encryptionKey) {
      throw new PasswordException('Incorrect Password', PasswordResponses.INCORRECT_PASSWORD);
     }
     this.encryptionKey = encryptionKey;
     if (algorithm >= 4) {
      var cf = dict.get('CF');
      if (isDict(cf)) {
       cf.suppressEncryption = true;
      }
      this.cf = cf;
      this.stmf = dict.get('StmF') || identityName;
      this.strf = dict.get('StrF') || identityName;
      this.eff = dict.get('EFF') || this.stmf;
     }
    }
    function buildObjectKey(num, gen, encryptionKey, isAes) {
     var key = new Uint8Array(encryptionKey.length + 9), i, n;
     for (i = 0, n = encryptionKey.length; i < n; ++i) {
      key[i] = encryptionKey[i];
     }
     key[i++] = num & 0xFF;
     key[i++] = num >> 8 & 0xFF;
     key[i++] = num >> 16 & 0xFF;
     key[i++] = gen & 0xFF;
     key[i++] = gen >> 8 & 0xFF;
     if (isAes) {
      key[i++] = 0x73;
      key[i++] = 0x41;
      key[i++] = 0x6C;
      key[i++] = 0x54;
     }
     var hash = calculateMD5(key, 0, i);
     return hash.subarray(0, Math.min(encryptionKey.length + 5, 16));
    }
    function buildCipherConstructor(cf, name, num, gen, key) {
     assert(isName(name), 'Invalid crypt filter name.');
     var cryptFilter = cf.get(name.name);
     var cfm;
     if (cryptFilter !== null && cryptFilter !== undefined) {
      cfm = cryptFilter.get('CFM');
     }
     if (!cfm || cfm.name === 'None') {
      return function cipherTransformFactoryBuildCipherConstructorNone() {
       return new NullCipher();
      };
     }
     if (cfm.name === 'V2') {
      return function cipherTransformFactoryBuildCipherConstructorV2() {
       return new ARCFourCipher(buildObjectKey(num, gen, key, false));
      };
     }
     if (cfm.name === 'AESV2') {
      return function cipherTransformFactoryBuildCipherConstructorAESV2() {
       return new AES128Cipher(buildObjectKey(num, gen, key, true));
      };
     }
     if (cfm.name === 'AESV3') {
      return function cipherTransformFactoryBuildCipherConstructorAESV3() {
       return new AES256Cipher(key);
      };
     }
     error('Unknown crypto method');
    }
    CipherTransformFactory.prototype = {
     createCipherTransform: function CipherTransformFactory_createCipherTransform(num, gen) {
      if (this.algorithm === 4 || this.algorithm === 5) {
       return new CipherTransform(buildCipherConstructor(this.cf, this.stmf, num, gen, this.encryptionKey), buildCipherConstructor(this.cf, this.strf, num, gen, this.encryptionKey));
      }
      var key = buildObjectKey(num, gen, this.encryptionKey, false);
      var cipherConstructor = function buildCipherCipherConstructor() {
       return new ARCFourCipher(key);
      };
      return new CipherTransform(cipherConstructor, cipherConstructor);
     }
    };
    return CipherTransformFactory;
   }();
   exports.AES128Cipher = AES128Cipher;
   exports.AES256Cipher = AES256Cipher;
   exports.ARCFourCipher = ARCFourCipher;
   exports.CipherTransformFactory = CipherTransformFactory;
   exports.PDF17 = PDF17;
   exports.PDF20 = PDF20;
   exports.calculateMD5 = calculateMD5;
   exports.calculateSHA256 = calculateSHA256;
   exports.calculateSHA384 = calculateSHA384;
   exports.calculateSHA512 = calculateSHA512;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreFontRenderer = {}, root.pdfjsSharedUtil, root.pdfjsCoreStream, root.pdfjsCoreGlyphList, root.pdfjsCoreEncodings, root.pdfjsCoreCFFParser);
  }(this, function (exports, sharedUtil, coreStream, coreGlyphList, coreEncodings, coreCFFParser) {
   var Util = sharedUtil.Util;
   var bytesToString = sharedUtil.bytesToString;
   var error = sharedUtil.error;
   var Stream = coreStream.Stream;
   var getGlyphsUnicode = coreGlyphList.getGlyphsUnicode;
   var StandardEncoding = coreEncodings.StandardEncoding;
   var CFFParser = coreCFFParser.CFFParser;
   var FontRendererFactory = function FontRendererFactoryClosure() {
    function getLong(data, offset) {
     return data[offset] << 24 | data[offset + 1] << 16 | data[offset + 2] << 8 | data[offset + 3];
    }
    function getUshort(data, offset) {
     return data[offset] << 8 | data[offset + 1];
    }
    function parseCmap(data, start, end) {
     var offset = getUshort(data, start + 2) === 1 ? getLong(data, start + 8) : getLong(data, start + 16);
     var format = getUshort(data, start + offset);
     var length, ranges, p, i;
     if (format === 4) {
      length = getUshort(data, start + offset + 2);
      var segCount = getUshort(data, start + offset + 6) >> 1;
      p = start + offset + 14;
      ranges = [];
      for (i = 0; i < segCount; i++, p += 2) {
       ranges[i] = { end: getUshort(data, p) };
      }
      p += 2;
      for (i = 0; i < segCount; i++, p += 2) {
       ranges[i].start = getUshort(data, p);
      }
      for (i = 0; i < segCount; i++, p += 2) {
       ranges[i].idDelta = getUshort(data, p);
      }
      for (i = 0; i < segCount; i++, p += 2) {
       var idOffset = getUshort(data, p);
       if (idOffset === 0) {
        continue;
       }
       ranges[i].ids = [];
       for (var j = 0, jj = ranges[i].end - ranges[i].start + 1; j < jj; j++) {
        ranges[i].ids[j] = getUshort(data, p + idOffset);
        idOffset += 2;
       }
      }
      return ranges;
     } else if (format === 12) {
      length = getLong(data, start + offset + 4);
      var groups = getLong(data, start + offset + 12);
      p = start + offset + 16;
      ranges = [];
      for (i = 0; i < groups; i++) {
       ranges.push({
        start: getLong(data, p),
        end: getLong(data, p + 4),
        idDelta: getLong(data, p + 8) - getLong(data, p)
       });
       p += 12;
      }
      return ranges;
     }
     error('not supported cmap: ' + format);
    }
    function parseCff(data, start, end, seacAnalysisEnabled) {
     var properties = {};
     var parser = new CFFParser(new Stream(data, start, end - start), properties, seacAnalysisEnabled);
     var cff = parser.parse();
     return {
      glyphs: cff.charStrings.objects,
      subrs: cff.topDict.privateDict && cff.topDict.privateDict.subrsIndex && cff.topDict.privateDict.subrsIndex.objects,
      gsubrs: cff.globalSubrIndex && cff.globalSubrIndex.objects
     };
    }
    function parseGlyfTable(glyf, loca, isGlyphLocationsLong) {
     var itemSize, itemDecode;
     if (isGlyphLocationsLong) {
      itemSize = 4;
      itemDecode = function fontItemDecodeLong(data, offset) {
       return data[offset] << 24 | data[offset + 1] << 16 | data[offset + 2] << 8 | data[offset + 3];
      };
     } else {
      itemSize = 2;
      itemDecode = function fontItemDecode(data, offset) {
       return data[offset] << 9 | data[offset + 1] << 1;
      };
     }
     var glyphs = [];
     var startOffset = itemDecode(loca, 0);
     for (var j = itemSize; j < loca.length; j += itemSize) {
      var endOffset = itemDecode(loca, j);
      glyphs.push(glyf.subarray(startOffset, endOffset));
      startOffset = endOffset;
     }
     return glyphs;
    }
    function lookupCmap(ranges, unicode) {
     var code = unicode.charCodeAt(0), gid = 0;
     var l = 0, r = ranges.length - 1;
     while (l < r) {
      var c = l + r + 1 >> 1;
      if (code < ranges[c].start) {
       r = c - 1;
      } else {
       l = c;
      }
     }
     if (ranges[l].start <= code && code <= ranges[l].end) {
      gid = ranges[l].idDelta + (ranges[l].ids ? ranges[l].ids[code - ranges[l].start] : code) & 0xFFFF;
     }
     return {
      charCode: code,
      glyphId: gid
     };
    }
    function compileGlyf(code, cmds, font) {
     function moveTo(x, y) {
      cmds.push({
       cmd: 'moveTo',
       args: [
        x,
        y
       ]
      });
     }
     function lineTo(x, y) {
      cmds.push({
       cmd: 'lineTo',
       args: [
        x,
        y
       ]
      });
     }
     function quadraticCurveTo(xa, ya, x, y) {
      cmds.push({
       cmd: 'quadraticCurveTo',
       args: [
        xa,
        ya,
        x,
        y
       ]
      });
     }
     var i = 0;
     var numberOfContours = (code[i] << 24 | code[i + 1] << 16) >> 16;
     var flags;
     var x = 0, y = 0;
     i += 10;
     if (numberOfContours < 0) {
      do {
       flags = code[i] << 8 | code[i + 1];
       var glyphIndex = code[i + 2] << 8 | code[i + 3];
       i += 4;
       var arg1, arg2;
       if (flags & 0x01) {
        arg1 = (code[i] << 24 | code[i + 1] << 16) >> 16;
        arg2 = (code[i + 2] << 24 | code[i + 3] << 16) >> 16;
        i += 4;
       } else {
        arg1 = code[i++];
        arg2 = code[i++];
       }
       if (flags & 0x02) {
        x = arg1;
        y = arg2;
       } else {
        x = 0;
        y = 0;
       }
       var scaleX = 1, scaleY = 1, scale01 = 0, scale10 = 0;
       if (flags & 0x08) {
        scaleX = scaleY = (code[i] << 24 | code[i + 1] << 16) / 1073741824;
        i += 2;
       } else if (flags & 0x40) {
        scaleX = (code[i] << 24 | code[i + 1] << 16) / 1073741824;
        scaleY = (code[i + 2] << 24 | code[i + 3] << 16) / 1073741824;
        i += 4;
       } else if (flags & 0x80) {
        scaleX = (code[i] << 24 | code[i + 1] << 16) / 1073741824;
        scale01 = (code[i + 2] << 24 | code[i + 3] << 16) / 1073741824;
        scale10 = (code[i + 4] << 24 | code[i + 5] << 16) / 1073741824;
        scaleY = (code[i + 6] << 24 | code[i + 7] << 16) / 1073741824;
        i += 8;
       }
       var subglyph = font.glyphs[glyphIndex];
       if (subglyph) {
        cmds.push({ cmd: 'save' });
        cmds.push({
         cmd: 'transform',
         args: [
          scaleX,
          scale01,
          scale10,
          scaleY,
          x,
          y
         ]
        });
        compileGlyf(subglyph, cmds, font);
        cmds.push({ cmd: 'restore' });
       }
      } while (flags & 0x20);
     } else {
      var endPtsOfContours = [];
      var j, jj;
      for (j = 0; j < numberOfContours; j++) {
       endPtsOfContours.push(code[i] << 8 | code[i + 1]);
       i += 2;
      }
      var instructionLength = code[i] << 8 | code[i + 1];
      i += 2 + instructionLength;
      var numberOfPoints = endPtsOfContours[endPtsOfContours.length - 1] + 1;
      var points = [];
      while (points.length < numberOfPoints) {
       flags = code[i++];
       var repeat = 1;
       if (flags & 0x08) {
        repeat += code[i++];
       }
       while (repeat-- > 0) {
        points.push({ flags: flags });
       }
      }
      for (j = 0; j < numberOfPoints; j++) {
       switch (points[j].flags & 0x12) {
       case 0x00:
        x += (code[i] << 24 | code[i + 1] << 16) >> 16;
        i += 2;
        break;
       case 0x02:
        x -= code[i++];
        break;
       case 0x12:
        x += code[i++];
        break;
       }
       points[j].x = x;
      }
      for (j = 0; j < numberOfPoints; j++) {
       switch (points[j].flags & 0x24) {
       case 0x00:
        y += (code[i] << 24 | code[i + 1] << 16) >> 16;
        i += 2;
        break;
       case 0x04:
        y -= code[i++];
        break;
       case 0x24:
        y += code[i++];
        break;
       }
       points[j].y = y;
      }
      var startPoint = 0;
      for (i = 0; i < numberOfContours; i++) {
       var endPoint = endPtsOfContours[i];
       var contour = points.slice(startPoint, endPoint + 1);
       if (contour[0].flags & 1) {
        contour.push(contour[0]);
       } else if (contour[contour.length - 1].flags & 1) {
        contour.unshift(contour[contour.length - 1]);
       } else {
        var p = {
         flags: 1,
         x: (contour[0].x + contour[contour.length - 1].x) / 2,
         y: (contour[0].y + contour[contour.length - 1].y) / 2
        };
        contour.unshift(p);
        contour.push(p);
       }
       moveTo(contour[0].x, contour[0].y);
       for (j = 1, jj = contour.length; j < jj; j++) {
        if (contour[j].flags & 1) {
         lineTo(contour[j].x, contour[j].y);
        } else if (contour[j + 1].flags & 1) {
         quadraticCurveTo(contour[j].x, contour[j].y, contour[j + 1].x, contour[j + 1].y);
         j++;
        } else {
         quadraticCurveTo(contour[j].x, contour[j].y, (contour[j].x + contour[j + 1].x) / 2, (contour[j].y + contour[j + 1].y) / 2);
        }
       }
       startPoint = endPoint + 1;
      }
     }
    }
    function compileCharString(code, cmds, font) {
     var stack = [];
     var x = 0, y = 0;
     var stems = 0;
     function moveTo(x, y) {
      cmds.push({
       cmd: 'moveTo',
       args: [
        x,
        y
       ]
      });
     }
     function lineTo(x, y) {
      cmds.push({
       cmd: 'lineTo',
       args: [
        x,
        y
       ]
      });
     }
     function bezierCurveTo(x1, y1, x2, y2, x, y) {
      cmds.push({
       cmd: 'bezierCurveTo',
       args: [
        x1,
        y1,
        x2,
        y2,
        x,
        y
       ]
      });
     }
     function parse(code) {
      var i = 0;
      while (i < code.length) {
       var stackClean = false;
       var v = code[i++];
       var xa, xb, ya, yb, y1, y2, y3, n, subrCode;
       switch (v) {
       case 1:
        stems += stack.length >> 1;
        stackClean = true;
        break;
       case 3:
        stems += stack.length >> 1;
        stackClean = true;
        break;
       case 4:
        y += stack.pop();
        moveTo(x, y);
        stackClean = true;
        break;
       case 5:
        while (stack.length > 0) {
         x += stack.shift();
         y += stack.shift();
         lineTo(x, y);
        }
        break;
       case 6:
        while (stack.length > 0) {
         x += stack.shift();
         lineTo(x, y);
         if (stack.length === 0) {
          break;
         }
         y += stack.shift();
         lineTo(x, y);
        }
        break;
       case 7:
        while (stack.length > 0) {
         y += stack.shift();
         lineTo(x, y);
         if (stack.length === 0) {
          break;
         }
         x += stack.shift();
         lineTo(x, y);
        }
        break;
       case 8:
        while (stack.length > 0) {
         xa = x + stack.shift();
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb + stack.shift();
         y = yb + stack.shift();
         bezierCurveTo(xa, ya, xb, yb, x, y);
        }
        break;
       case 10:
        n = stack.pop() + font.subrsBias;
        subrCode = font.subrs[n];
        if (subrCode) {
         parse(subrCode);
        }
        break;
       case 11:
        return;
       case 12:
        v = code[i++];
        switch (v) {
        case 34:
         xa = x + stack.shift();
         xb = xa + stack.shift();
         y1 = y + stack.shift();
         x = xb + stack.shift();
         bezierCurveTo(xa, y, xb, y1, x, y1);
         xa = x + stack.shift();
         xb = xa + stack.shift();
         x = xb + stack.shift();
         bezierCurveTo(xa, y1, xb, y, x, y);
         break;
        case 35:
         xa = x + stack.shift();
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb + stack.shift();
         y = yb + stack.shift();
         bezierCurveTo(xa, ya, xb, yb, x, y);
         xa = x + stack.shift();
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb + stack.shift();
         y = yb + stack.shift();
         bezierCurveTo(xa, ya, xb, yb, x, y);
         stack.pop();
         break;
        case 36:
         xa = x + stack.shift();
         y1 = y + stack.shift();
         xb = xa + stack.shift();
         y2 = y1 + stack.shift();
         x = xb + stack.shift();
         bezierCurveTo(xa, y1, xb, y2, x, y2);
         xa = x + stack.shift();
         xb = xa + stack.shift();
         y3 = y2 + stack.shift();
         x = xb + stack.shift();
         bezierCurveTo(xa, y2, xb, y3, x, y);
         break;
        case 37:
         var x0 = x, y0 = y;
         xa = x + stack.shift();
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb + stack.shift();
         y = yb + stack.shift();
         bezierCurveTo(xa, ya, xb, yb, x, y);
         xa = x + stack.shift();
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb;
         y = yb;
         if (Math.abs(x - x0) > Math.abs(y - y0)) {
          x += stack.shift();
         } else {
          y += stack.shift();
         }
         bezierCurveTo(xa, ya, xb, yb, x, y);
         break;
        default:
         error('unknown operator: 12 ' + v);
        }
        break;
       case 14:
        if (stack.length >= 4) {
         var achar = stack.pop();
         var bchar = stack.pop();
         y = stack.pop();
         x = stack.pop();
         cmds.push({ cmd: 'save' });
         cmds.push({
          cmd: 'translate',
          args: [
           x,
           y
          ]
         });
         var cmap = lookupCmap(font.cmap, String.fromCharCode(font.glyphNameMap[StandardEncoding[achar]]));
         compileCharString(font.glyphs[cmap.glyphId], cmds, font);
         cmds.push({ cmd: 'restore' });
         cmap = lookupCmap(font.cmap, String.fromCharCode(font.glyphNameMap[StandardEncoding[bchar]]));
         compileCharString(font.glyphs[cmap.glyphId], cmds, font);
        }
        return;
       case 18:
        stems += stack.length >> 1;
        stackClean = true;
        break;
       case 19:
        stems += stack.length >> 1;
        i += stems + 7 >> 3;
        stackClean = true;
        break;
       case 20:
        stems += stack.length >> 1;
        i += stems + 7 >> 3;
        stackClean = true;
        break;
       case 21:
        y += stack.pop();
        x += stack.pop();
        moveTo(x, y);
        stackClean = true;
        break;
       case 22:
        x += stack.pop();
        moveTo(x, y);
        stackClean = true;
        break;
       case 23:
        stems += stack.length >> 1;
        stackClean = true;
        break;
       case 24:
        while (stack.length > 2) {
         xa = x + stack.shift();
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb + stack.shift();
         y = yb + stack.shift();
         bezierCurveTo(xa, ya, xb, yb, x, y);
        }
        x += stack.shift();
        y += stack.shift();
        lineTo(x, y);
        break;
       case 25:
        while (stack.length > 6) {
         x += stack.shift();
         y += stack.shift();
         lineTo(x, y);
        }
        xa = x + stack.shift();
        ya = y + stack.shift();
        xb = xa + stack.shift();
        yb = ya + stack.shift();
        x = xb + stack.shift();
        y = yb + stack.shift();
        bezierCurveTo(xa, ya, xb, yb, x, y);
        break;
       case 26:
        if (stack.length % 2) {
         x += stack.shift();
        }
        while (stack.length > 0) {
         xa = x;
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb;
         y = yb + stack.shift();
         bezierCurveTo(xa, ya, xb, yb, x, y);
        }
        break;
       case 27:
        if (stack.length % 2) {
         y += stack.shift();
        }
        while (stack.length > 0) {
         xa = x + stack.shift();
         ya = y;
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb + stack.shift();
         y = yb;
         bezierCurveTo(xa, ya, xb, yb, x, y);
        }
        break;
       case 28:
        stack.push((code[i] << 24 | code[i + 1] << 16) >> 16);
        i += 2;
        break;
       case 29:
        n = stack.pop() + font.gsubrsBias;
        subrCode = font.gsubrs[n];
        if (subrCode) {
         parse(subrCode);
        }
        break;
       case 30:
        while (stack.length > 0) {
         xa = x;
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb + stack.shift();
         y = yb + (stack.length === 1 ? stack.shift() : 0);
         bezierCurveTo(xa, ya, xb, yb, x, y);
         if (stack.length === 0) {
          break;
         }
         xa = x + stack.shift();
         ya = y;
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         y = yb + stack.shift();
         x = xb + (stack.length === 1 ? stack.shift() : 0);
         bezierCurveTo(xa, ya, xb, yb, x, y);
        }
        break;
       case 31:
        while (stack.length > 0) {
         xa = x + stack.shift();
         ya = y;
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         y = yb + stack.shift();
         x = xb + (stack.length === 1 ? stack.shift() : 0);
         bezierCurveTo(xa, ya, xb, yb, x, y);
         if (stack.length === 0) {
          break;
         }
         xa = x;
         ya = y + stack.shift();
         xb = xa + stack.shift();
         yb = ya + stack.shift();
         x = xb + stack.shift();
         y = yb + (stack.length === 1 ? stack.shift() : 0);
         bezierCurveTo(xa, ya, xb, yb, x, y);
        }
        break;
       default:
        if (v < 32) {
         error('unknown operator: ' + v);
        }
        if (v < 247) {
         stack.push(v - 139);
        } else if (v < 251) {
         stack.push((v - 247) * 256 + code[i++] + 108);
        } else if (v < 255) {
         stack.push(-(v - 251) * 256 - code[i++] - 108);
        } else {
         stack.push((code[i] << 24 | code[i + 1] << 16 | code[i + 2] << 8 | code[i + 3]) / 65536);
         i += 4;
        }
        break;
       }
       if (stackClean) {
        stack.length = 0;
       }
      }
     }
     parse(code);
    }
    var noop = '';
    function CompiledFont(fontMatrix) {
     this.compiledGlyphs = Object.create(null);
     this.compiledCharCodeToGlyphId = Object.create(null);
     this.fontMatrix = fontMatrix;
    }
    CompiledFont.prototype = {
     getPathJs: function (unicode) {
      var cmap = lookupCmap(this.cmap, unicode);
      var fn = this.compiledGlyphs[cmap.glyphId];
      if (!fn) {
       fn = this.compileGlyph(this.glyphs[cmap.glyphId]);
       this.compiledGlyphs[cmap.glyphId] = fn;
      }
      if (this.compiledCharCodeToGlyphId[cmap.charCode] === undefined) {
       this.compiledCharCodeToGlyphId[cmap.charCode] = cmap.glyphId;
      }
      return fn;
     },
     compileGlyph: function (code) {
      if (!code || code.length === 0 || code[0] === 14) {
       return noop;
      }
      var cmds = [];
      cmds.push({ cmd: 'save' });
      cmds.push({
       cmd: 'transform',
       args: this.fontMatrix.slice()
      });
      cmds.push({
       cmd: 'scale',
       args: [
        'size',
        '-size'
       ]
      });
      this.compileGlyphImpl(code, cmds);
      cmds.push({ cmd: 'restore' });
      return cmds;
     },
     compileGlyphImpl: function () {
      error('Children classes should implement this.');
     },
     hasBuiltPath: function (unicode) {
      var cmap = lookupCmap(this.cmap, unicode);
      return this.compiledGlyphs[cmap.glyphId] !== undefined && this.compiledCharCodeToGlyphId[cmap.charCode] !== undefined;
     }
    };
    function TrueTypeCompiled(glyphs, cmap, fontMatrix) {
     fontMatrix = fontMatrix || [
      0.000488,
      0,
      0,
      0.000488,
      0,
      0
     ];
     CompiledFont.call(this, fontMatrix);
     this.glyphs = glyphs;
     this.cmap = cmap;
    }
    Util.inherit(TrueTypeCompiled, CompiledFont, {
     compileGlyphImpl: function (code, cmds) {
      compileGlyf(code, cmds, this);
     }
    });
    function Type2Compiled(cffInfo, cmap, fontMatrix, glyphNameMap) {
     fontMatrix = fontMatrix || [
      0.001,
      0,
      0,
      0.001,
      0,
      0
     ];
     CompiledFont.call(this, fontMatrix);
     this.glyphs = cffInfo.glyphs;
     this.gsubrs = cffInfo.gsubrs || [];
     this.subrs = cffInfo.subrs || [];
     this.cmap = cmap;
     this.glyphNameMap = glyphNameMap || getGlyphsUnicode();
     this.gsubrsBias = this.gsubrs.length < 1240 ? 107 : this.gsubrs.length < 33900 ? 1131 : 32768;
     this.subrsBias = this.subrs.length < 1240 ? 107 : this.subrs.length < 33900 ? 1131 : 32768;
    }
    Util.inherit(Type2Compiled, CompiledFont, {
     compileGlyphImpl: function (code, cmds) {
      compileCharString(code, cmds, this);
     }
    });
    return {
     create: function FontRendererFactory_create(font, seacAnalysisEnabled) {
      var data = new Uint8Array(font.data);
      var cmap, glyf, loca, cff, indexToLocFormat, unitsPerEm;
      var numTables = getUshort(data, 4);
      for (var i = 0, p = 12; i < numTables; i++, p += 16) {
       var tag = bytesToString(data.subarray(p, p + 4));
       var offset = getLong(data, p + 8);
       var length = getLong(data, p + 12);
       switch (tag) {
       case 'cmap':
        cmap = parseCmap(data, offset, offset + length);
        break;
       case 'glyf':
        glyf = data.subarray(offset, offset + length);
        break;
       case 'loca':
        loca = data.subarray(offset, offset + length);
        break;
       case 'head':
        unitsPerEm = getUshort(data, offset + 18);
        indexToLocFormat = getUshort(data, offset + 50);
        break;
       case 'CFF ':
        cff = parseCff(data, offset, offset + length, seacAnalysisEnabled);
        break;
       }
      }
      if (glyf) {
       var fontMatrix = !unitsPerEm ? font.fontMatrix : [
        1 / unitsPerEm,
        0,
        0,
        1 / unitsPerEm,
        0,
        0
       ];
       return new TrueTypeCompiled(parseGlyfTable(glyf, loca, indexToLocFormat), cmap, fontMatrix);
      } else {
       return new Type2Compiled(cff, cmap, font.fontMatrix, font.glyphNameMap);
      }
     }
    };
   }();
   exports.FontRendererFactory = FontRendererFactory;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreParser = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreStream);
  }(this, function (exports, sharedUtil, corePrimitives, coreStream) {
   var MissingDataException = sharedUtil.MissingDataException;
   var StreamType = sharedUtil.StreamType;
   var assert = sharedUtil.assert;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var isInt = sharedUtil.isInt;
   var isNum = sharedUtil.isNum;
   var isString = sharedUtil.isString;
   var warn = sharedUtil.warn;
   var Cmd = corePrimitives.Cmd;
   var Dict = corePrimitives.Dict;
   var Name = corePrimitives.Name;
   var Ref = corePrimitives.Ref;
   var isCmd = corePrimitives.isCmd;
   var isDict = corePrimitives.isDict;
   var isName = corePrimitives.isName;
   var Ascii85Stream = coreStream.Ascii85Stream;
   var AsciiHexStream = coreStream.AsciiHexStream;
   var CCITTFaxStream = coreStream.CCITTFaxStream;
   var FlateStream = coreStream.FlateStream;
   var Jbig2Stream = coreStream.Jbig2Stream;
   var JpegStream = coreStream.JpegStream;
   var JpxStream = coreStream.JpxStream;
   var LZWStream = coreStream.LZWStream;
   var NullStream = coreStream.NullStream;
   var PredictorStream = coreStream.PredictorStream;
   var RunLengthStream = coreStream.RunLengthStream;
   var EOF = {};
   function isEOF(v) {
    return v === EOF;
   }
   var MAX_LENGTH_TO_CACHE = 1000;
   var Parser = function ParserClosure() {
    function Parser(lexer, allowStreams, xref, recoveryMode) {
     this.lexer = lexer;
     this.allowStreams = allowStreams;
     this.xref = xref;
     this.recoveryMode = recoveryMode || false;
     this.imageCache = Object.create(null);
     this.refill();
    }
    Parser.prototype = {
     refill: function Parser_refill() {
      this.buf1 = this.lexer.getObj();
      this.buf2 = this.lexer.getObj();
     },
     shift: function Parser_shift() {
      if (isCmd(this.buf2, 'ID')) {
       this.buf1 = this.buf2;
       this.buf2 = null;
      } else {
       this.buf1 = this.buf2;
       this.buf2 = this.lexer.getObj();
      }
     },
     tryShift: function Parser_tryShift() {
      try {
       this.shift();
       return true;
      } catch (e) {
       if (e instanceof MissingDataException) {
        throw e;
       }
       return false;
      }
     },
     getObj: function Parser_getObj(cipherTransform) {
      var buf1 = this.buf1;
      this.shift();
      if (buf1 instanceof Cmd) {
       switch (buf1.cmd) {
       case 'BI':
        return this.makeInlineImage(cipherTransform);
       case '[':
        var array = [];
        while (!isCmd(this.buf1, ']') && !isEOF(this.buf1)) {
         array.push(this.getObj(cipherTransform));
        }
        if (isEOF(this.buf1)) {
         if (!this.recoveryMode) {
          error('End of file inside array');
         }
         return array;
        }
        this.shift();
        return array;
       case '<<':
        var dict = new Dict(this.xref);
        while (!isCmd(this.buf1, '>>') && !isEOF(this.buf1)) {
         if (!isName(this.buf1)) {
          info('Malformed dictionary: key must be a name object');
          this.shift();
          continue;
         }
         var key = this.buf1.name;
         this.shift();
         if (isEOF(this.buf1)) {
          break;
         }
         dict.set(key, this.getObj(cipherTransform));
        }
        if (isEOF(this.buf1)) {
         if (!this.recoveryMode) {
          error('End of file inside dictionary');
         }
         return dict;
        }
        if (isCmd(this.buf2, 'stream')) {
         return this.allowStreams ? this.makeStream(dict, cipherTransform) : dict;
        }
        this.shift();
        return dict;
       default:
        return buf1;
       }
      }
      if (isInt(buf1)) {
       var num = buf1;
       if (isInt(this.buf1) && isCmd(this.buf2, 'R')) {
        var ref = new Ref(num, this.buf1);
        this.shift();
        this.shift();
        return ref;
       }
       return num;
      }
      if (isString(buf1)) {
       var str = buf1;
       if (cipherTransform) {
        str = cipherTransform.decryptString(str);
       }
       return str;
      }
      return buf1;
     },
     findDefaultInlineStreamEnd: function Parser_findDefaultInlineStreamEnd(stream) {
      var E = 0x45, I = 0x49, SPACE = 0x20, LF = 0xA, CR = 0xD;
      var startPos = stream.pos, state = 0, ch, i, n, followingBytes;
      while ((ch = stream.getByte()) !== -1) {
       if (state === 0) {
        state = ch === E ? 1 : 0;
       } else if (state === 1) {
        state = ch === I ? 2 : 0;
       } else {
        assert(state === 2);
        if (ch === SPACE || ch === LF || ch === CR) {
         n = 5;
         followingBytes = stream.peekBytes(n);
         for (i = 0; i < n; i++) {
          ch = followingBytes[i];
          if (ch !== LF && ch !== CR && (ch < SPACE || ch > 0x7F)) {
           state = 0;
           break;
          }
         }
         if (state === 2) {
          break;
         }
        } else
         {
          state = 0;
         }
       }
      }
      return stream.pos - 4 - startPos;
     },
     findDCTDecodeInlineStreamEnd: function Parser_findDCTDecodeInlineStreamEnd(stream) {
      var startPos = stream.pos, foundEOI = false, b, markerLength, length;
      while ((b = stream.getByte()) !== -1) {
       if (b !== 0xFF) {
        continue;
       }
       switch (stream.getByte()) {
       case 0x00:
        break;
       case 0xFF:
        stream.skip(-1);
        break;
       case 0xD9:
        foundEOI = true;
        break;
       case 0xC0:
       case 0xC1:
       case 0xC2:
       case 0xC3:
       case 0xC5:
       case 0xC6:
       case 0xC7:
       case 0xC9:
       case 0xCA:
       case 0xCB:
       case 0xCD:
       case 0xCE:
       case 0xCF:
       case 0xC4:
       case 0xCC:
       case 0xDA:
       case 0xDB:
       case 0xDC:
       case 0xDD:
       case 0xDE:
       case 0xDF:
       case 0xE0:
       case 0xE1:
       case 0xE2:
       case 0xE3:
       case 0xE4:
       case 0xE5:
       case 0xE6:
       case 0xE7:
       case 0xE8:
       case 0xE9:
       case 0xEA:
       case 0xEB:
       case 0xEC:
       case 0xED:
       case 0xEE:
       case 0xEF:
       case 0xFE:
        markerLength = stream.getUint16();
        if (markerLength > 2) {
         stream.skip(markerLength - 2);
        } else
         {
          stream.skip(-2);
         }
        break;
       }
       if (foundEOI) {
        break;
       }
      }
      length = stream.pos - startPos;
      if (b === -1) {
       warn('Inline DCTDecode image stream: ' + 'EOI marker not found, searching for /EI/ instead.');
       stream.skip(-length);
       return this.findDefaultInlineStreamEnd(stream);
      }
      this.inlineStreamSkipEI(stream);
      return length;
     },
     findASCII85DecodeInlineStreamEnd: function Parser_findASCII85DecodeInlineStreamEnd(stream) {
      var TILDE = 0x7E, GT = 0x3E;
      var startPos = stream.pos, ch, length;
      while ((ch = stream.getByte()) !== -1) {
       if (ch === TILDE && stream.peekByte() === GT) {
        stream.skip();
        break;
       }
      }
      length = stream.pos - startPos;
      if (ch === -1) {
       warn('Inline ASCII85Decode image stream: ' + 'EOD marker not found, searching for /EI/ instead.');
       stream.skip(-length);
       return this.findDefaultInlineStreamEnd(stream);
      }
      this.inlineStreamSkipEI(stream);
      return length;
     },
     findASCIIHexDecodeInlineStreamEnd: function Parser_findASCIIHexDecodeInlineStreamEnd(stream) {
      var GT = 0x3E;
      var startPos = stream.pos, ch, length;
      while ((ch = stream.getByte()) !== -1) {
       if (ch === GT) {
        break;
       }
      }
      length = stream.pos - startPos;
      if (ch === -1) {
       warn('Inline ASCIIHexDecode image stream: ' + 'EOD marker not found, searching for /EI/ instead.');
       stream.skip(-length);
       return this.findDefaultInlineStreamEnd(stream);
      }
      this.inlineStreamSkipEI(stream);
      return length;
     },
     inlineStreamSkipEI: function Parser_inlineStreamSkipEI(stream) {
      var E = 0x45, I = 0x49;
      var state = 0, ch;
      while ((ch = stream.getByte()) !== -1) {
       if (state === 0) {
        state = ch === E ? 1 : 0;
       } else if (state === 1) {
        state = ch === I ? 2 : 0;
       } else if (state === 2) {
        break;
       }
      }
     },
     makeInlineImage: function Parser_makeInlineImage(cipherTransform) {
      var lexer = this.lexer;
      var stream = lexer.stream;
      var dict = new Dict(this.xref);
      while (!isCmd(this.buf1, 'ID') && !isEOF(this.buf1)) {
       if (!isName(this.buf1)) {
        error('Dictionary key must be a name object');
       }
       var key = this.buf1.name;
       this.shift();
       if (isEOF(this.buf1)) {
        break;
       }
       dict.set(key, this.getObj(cipherTransform));
      }
      var filter = dict.get('Filter', 'F'), filterName;
      if (isName(filter)) {
       filterName = filter.name;
      } else if (isArray(filter)) {
       var filterZero = this.xref.fetchIfRef(filter[0]);
       if (isName(filterZero)) {
        filterName = filterZero.name;
       }
      }
      var startPos = stream.pos, length, i, ii;
      if (filterName === 'DCTDecode' || filterName === 'DCT') {
       length = this.findDCTDecodeInlineStreamEnd(stream);
      } else if (filterName === 'ASCII85Decide' || filterName === 'A85') {
       length = this.findASCII85DecodeInlineStreamEnd(stream);
      } else if (filterName === 'ASCIIHexDecode' || filterName === 'AHx') {
       length = this.findASCIIHexDecodeInlineStreamEnd(stream);
      } else {
       length = this.findDefaultInlineStreamEnd(stream);
      }
      var imageStream = stream.makeSubStream(startPos, length, dict);
      var adler32;
      if (length < MAX_LENGTH_TO_CACHE) {
       var imageBytes = imageStream.getBytes();
       imageStream.reset();
       var a = 1;
       var b = 0;
       for (i = 0, ii = imageBytes.length; i < ii; ++i) {
        a += imageBytes[i] & 0xff;
        b += a;
       }
       adler32 = b % 65521 << 16 | a % 65521;
       if (this.imageCache.adler32 === adler32) {
        this.buf2 = Cmd.get('EI');
        this.shift();
        this.imageCache[adler32].reset();
        return this.imageCache[adler32];
       }
      }
      if (cipherTransform) {
       imageStream = cipherTransform.createStream(imageStream, length);
      }
      imageStream = this.filter(imageStream, dict, length);
      imageStream.dict = dict;
      if (adler32 !== undefined) {
       imageStream.cacheKey = 'inline_' + length + '_' + adler32;
       this.imageCache[adler32] = imageStream;
      }
      this.buf2 = Cmd.get('EI');
      this.shift();
      return imageStream;
     },
     makeStream: function Parser_makeStream(dict, cipherTransform) {
      var lexer = this.lexer;
      var stream = lexer.stream;
      lexer.skipToNextLine();
      var pos = stream.pos - 1;
      var length = dict.get('Length');
      if (!isInt(length)) {
       info('Bad ' + length + ' attribute in stream');
       length = 0;
      }
      stream.pos = pos + length;
      lexer.nextChar();
      if (this.tryShift() && isCmd(this.buf2, 'endstream')) {
       this.shift();
      } else
       {
        stream.pos = pos;
        var SCAN_BLOCK_SIZE = 2048;
        var ENDSTREAM_SIGNATURE_LENGTH = 9;
        var ENDSTREAM_SIGNATURE = [
         0x65,
         0x6E,
         0x64,
         0x73,
         0x74,
         0x72,
         0x65,
         0x61,
         0x6D
        ];
        var skipped = 0, found = false, i, j;
        while (stream.pos < stream.end) {
         var scanBytes = stream.peekBytes(SCAN_BLOCK_SIZE);
         var scanLength = scanBytes.length - ENDSTREAM_SIGNATURE_LENGTH;
         if (scanLength <= 0) {
          break;
         }
         found = false;
         i = 0;
         while (i < scanLength) {
          j = 0;
          while (j < ENDSTREAM_SIGNATURE_LENGTH && scanBytes[i + j] === ENDSTREAM_SIGNATURE[j]) {
           j++;
          }
          if (j >= ENDSTREAM_SIGNATURE_LENGTH) {
           found = true;
           break;
          }
          i++;
         }
         if (found) {
          skipped += i;
          stream.pos += i;
          break;
         }
         skipped += scanLength;
         stream.pos += scanLength;
        }
        if (!found) {
         error('Missing endstream');
        }
        length = skipped;
        lexer.nextChar();
        this.shift();
        this.shift();
       }
      this.shift();
      stream = stream.makeSubStream(pos, length, dict);
      if (cipherTransform) {
       stream = cipherTransform.createStream(stream, length);
      }
      stream = this.filter(stream, dict, length);
      stream.dict = dict;
      return stream;
     },
     filter: function Parser_filter(stream, dict, length) {
      var filter = dict.get('Filter', 'F');
      var params = dict.get('DecodeParms', 'DP');
      if (isName(filter)) {
       if (isArray(params)) {
        params = this.xref.fetchIfRef(params[0]);
       }
       return this.makeFilter(stream, filter.name, length, params);
      }
      var maybeLength = length;
      if (isArray(filter)) {
       var filterArray = filter;
       var paramsArray = params;
       for (var i = 0, ii = filterArray.length; i < ii; ++i) {
        filter = this.xref.fetchIfRef(filterArray[i]);
        if (!isName(filter)) {
         error('Bad filter name: ' + filter);
        }
        params = null;
        if (isArray(paramsArray) && i in paramsArray) {
         params = this.xref.fetchIfRef(paramsArray[i]);
        }
        stream = this.makeFilter(stream, filter.name, maybeLength, params);
        maybeLength = null;
       }
      }
      return stream;
     },
     makeFilter: function Parser_makeFilter(stream, name, maybeLength, params) {
      if (maybeLength === 0) {
       warn('Empty "' + name + '" stream.');
       return new NullStream(stream);
      }
      try {
       var xrefStreamStats = this.xref.stats.streamTypes;
       if (name === 'FlateDecode' || name === 'Fl') {
        xrefStreamStats[StreamType.FLATE] = true;
        if (params) {
         return new PredictorStream(new FlateStream(stream, maybeLength), maybeLength, params);
        }
        return new FlateStream(stream, maybeLength);
       }
       if (name === 'LZWDecode' || name === 'LZW') {
        xrefStreamStats[StreamType.LZW] = true;
        var earlyChange = 1;
        if (params) {
         if (params.has('EarlyChange')) {
          earlyChange = params.get('EarlyChange');
         }
         return new PredictorStream(new LZWStream(stream, maybeLength, earlyChange), maybeLength, params);
        }
        return new LZWStream(stream, maybeLength, earlyChange);
       }
       if (name === 'DCTDecode' || name === 'DCT') {
        xrefStreamStats[StreamType.DCT] = true;
        return new JpegStream(stream, maybeLength, stream.dict, params);
       }
       if (name === 'JPXDecode' || name === 'JPX') {
        xrefStreamStats[StreamType.JPX] = true;
        return new JpxStream(stream, maybeLength, stream.dict, params);
       }
       if (name === 'ASCII85Decode' || name === 'A85') {
        xrefStreamStats[StreamType.A85] = true;
        return new Ascii85Stream(stream, maybeLength);
       }
       if (name === 'ASCIIHexDecode' || name === 'AHx') {
        xrefStreamStats[StreamType.AHX] = true;
        return new AsciiHexStream(stream, maybeLength);
       }
       if (name === 'CCITTFaxDecode' || name === 'CCF') {
        xrefStreamStats[StreamType.CCF] = true;
        return new CCITTFaxStream(stream, maybeLength, params);
       }
       if (name === 'RunLengthDecode' || name === 'RL') {
        xrefStreamStats[StreamType.RL] = true;
        return new RunLengthStream(stream, maybeLength);
       }
       if (name === 'JBIG2Decode') {
        xrefStreamStats[StreamType.JBIG] = true;
        return new Jbig2Stream(stream, maybeLength, stream.dict, params);
       }
       warn('filter "' + name + '" not supported yet');
       return stream;
      } catch (ex) {
       if (ex instanceof MissingDataException) {
        throw ex;
       }
       warn('Invalid stream: \"' + ex + '\"');
       return new NullStream(stream);
      }
     }
    };
    return Parser;
   }();
   var Lexer = function LexerClosure() {
    function Lexer(stream, knownCommands) {
     this.stream = stream;
     this.nextChar();
     this.strBuf = [];
     this.knownCommands = knownCommands;
    }
    var specialChars = [
     1,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     1,
     1,
     0,
     1,
     1,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     1,
     0,
     0,
     0,
     0,
     2,
     0,
     0,
     2,
     2,
     0,
     0,
     0,
     0,
     0,
     2,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     2,
     0,
     2,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     2,
     0,
     2,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     2,
     0,
     2,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0,
     0
    ];
    function toHexDigit(ch) {
     if (ch >= 0x30 && ch <= 0x39) {
      return ch & 0x0F;
     }
     if (ch >= 0x41 && ch <= 0x46 || ch >= 0x61 && ch <= 0x66) {
      return (ch & 0x0F) + 9;
     }
     return -1;
    }
    Lexer.prototype = {
     nextChar: function Lexer_nextChar() {
      return this.currentChar = this.stream.getByte();
     },
     peekChar: function Lexer_peekChar() {
      return this.stream.peekByte();
     },
     getNumber: function Lexer_getNumber() {
      var ch = this.currentChar;
      var eNotation = false;
      var divideBy = 0;
      var sign = 1;
      if (ch === 0x2D) {
       sign = -1;
       ch = this.nextChar();
       if (ch === 0x2D) {
        ch = this.nextChar();
       }
      } else if (ch === 0x2B) {
       ch = this.nextChar();
      }
      if (ch === 0x2E) {
       divideBy = 10;
       ch = this.nextChar();
      }
      if (ch < 0x30 || ch > 0x39) {
       error('Invalid number: ' + String.fromCharCode(ch));
       return 0;
      }
      var baseValue = ch - 0x30;
      var powerValue = 0;
      var powerValueSign = 1;
      while ((ch = this.nextChar()) >= 0) {
       if (0x30 <= ch && ch <= 0x39) {
        var currentDigit = ch - 0x30;
        if (eNotation) {
         powerValue = powerValue * 10 + currentDigit;
        } else {
         if (divideBy !== 0) {
          divideBy *= 10;
         }
         baseValue = baseValue * 10 + currentDigit;
        }
       } else if (ch === 0x2E) {
        if (divideBy === 0) {
         divideBy = 1;
        } else {
         break;
        }
       } else if (ch === 0x2D) {
        warn('Badly formatted number');
       } else if (ch === 0x45 || ch === 0x65) {
        ch = this.peekChar();
        if (ch === 0x2B || ch === 0x2D) {
         powerValueSign = ch === 0x2D ? -1 : 1;
         this.nextChar();
        } else if (ch < 0x30 || ch > 0x39) {
         break;
        }
        eNotation = true;
       } else {
        break;
       }
      }
      if (divideBy !== 0) {
       baseValue /= divideBy;
      }
      if (eNotation) {
       baseValue *= Math.pow(10, powerValueSign * powerValue);
      }
      return sign * baseValue;
     },
     getString: function Lexer_getString() {
      var numParen = 1;
      var done = false;
      var strBuf = this.strBuf;
      strBuf.length = 0;
      var ch = this.nextChar();
      while (true) {
       var charBuffered = false;
       switch (ch | 0) {
       case -1:
        warn('Unterminated string');
        done = true;
        break;
       case 0x28:
        ++numParen;
        strBuf.push('(');
        break;
       case 0x29:
        if (--numParen === 0) {
         this.nextChar();
         done = true;
        } else {
         strBuf.push(')');
        }
        break;
       case 0x5C:
        ch = this.nextChar();
        switch (ch) {
        case -1:
         warn('Unterminated string');
         done = true;
         break;
        case 0x6E:
         strBuf.push('\n');
         break;
        case 0x72:
         strBuf.push('\r');
         break;
        case 0x74:
         strBuf.push('\t');
         break;
        case 0x62:
         strBuf.push('\b');
         break;
        case 0x66:
         strBuf.push('\f');
         break;
        case 0x5C:
        case 0x28:
        case 0x29:
         strBuf.push(String.fromCharCode(ch));
         break;
        case 0x30:
        case 0x31:
        case 0x32:
        case 0x33:
        case 0x34:
        case 0x35:
        case 0x36:
        case 0x37:
         var x = ch & 0x0F;
         ch = this.nextChar();
         charBuffered = true;
         if (ch >= 0x30 && ch <= 0x37) {
          x = (x << 3) + (ch & 0x0F);
          ch = this.nextChar();
          if (ch >= 0x30 && ch <= 0x37) {
           charBuffered = false;
           x = (x << 3) + (ch & 0x0F);
          }
         }
         strBuf.push(String.fromCharCode(x));
         break;
        case 0x0D:
         if (this.peekChar() === 0x0A) {
          this.nextChar();
         }
         break;
        case 0x0A:
         break;
        default:
         strBuf.push(String.fromCharCode(ch));
         break;
        }
        break;
       default:
        strBuf.push(String.fromCharCode(ch));
        break;
       }
       if (done) {
        break;
       }
       if (!charBuffered) {
        ch = this.nextChar();
       }
      }
      return strBuf.join('');
     },
     getName: function Lexer_getName() {
      var ch, previousCh;
      var strBuf = this.strBuf;
      strBuf.length = 0;
      while ((ch = this.nextChar()) >= 0 && !specialChars[ch]) {
       if (ch === 0x23) {
        ch = this.nextChar();
        if (specialChars[ch]) {
         warn('Lexer_getName: ' + 'NUMBER SIGN (#) should be followed by a hexadecimal number.');
         strBuf.push('#');
         break;
        }
        var x = toHexDigit(ch);
        if (x !== -1) {
         previousCh = ch;
         ch = this.nextChar();
         var x2 = toHexDigit(ch);
         if (x2 === -1) {
          warn('Lexer_getName: Illegal digit (' + String.fromCharCode(ch) + ') in hexadecimal number.');
          strBuf.push('#', String.fromCharCode(previousCh));
          if (specialChars[ch]) {
           break;
          }
          strBuf.push(String.fromCharCode(ch));
          continue;
         }
         strBuf.push(String.fromCharCode(x << 4 | x2));
        } else {
         strBuf.push('#', String.fromCharCode(ch));
        }
       } else {
        strBuf.push(String.fromCharCode(ch));
       }
      }
      if (strBuf.length > 127) {
       warn('name token is longer than allowed by the spec: ' + strBuf.length);
      }
      return Name.get(strBuf.join(''));
     },
     getHexString: function Lexer_getHexString() {
      var strBuf = this.strBuf;
      strBuf.length = 0;
      var ch = this.currentChar;
      var isFirstHex = true;
      var firstDigit;
      var secondDigit;
      while (true) {
       if (ch < 0) {
        warn('Unterminated hex string');
        break;
       } else if (ch === 0x3E) {
        this.nextChar();
        break;
       } else if (specialChars[ch] === 1) {
        ch = this.nextChar();
        continue;
       } else {
        if (isFirstHex) {
         firstDigit = toHexDigit(ch);
         if (firstDigit === -1) {
          warn('Ignoring invalid character "' + ch + '" in hex string');
          ch = this.nextChar();
          continue;
         }
        } else {
         secondDigit = toHexDigit(ch);
         if (secondDigit === -1) {
          warn('Ignoring invalid character "' + ch + '" in hex string');
          ch = this.nextChar();
          continue;
         }
         strBuf.push(String.fromCharCode(firstDigit << 4 | secondDigit));
        }
        isFirstHex = !isFirstHex;
        ch = this.nextChar();
       }
      }
      return strBuf.join('');
     },
     getObj: function Lexer_getObj() {
      var comment = false;
      var ch = this.currentChar;
      while (true) {
       if (ch < 0) {
        return EOF;
       }
       if (comment) {
        if (ch === 0x0A || ch === 0x0D) {
         comment = false;
        }
       } else if (ch === 0x25) {
        comment = true;
       } else if (specialChars[ch] !== 1) {
        break;
       }
       ch = this.nextChar();
      }
      switch (ch | 0) {
      case 0x30:
      case 0x31:
      case 0x32:
      case 0x33:
      case 0x34:
      case 0x35:
      case 0x36:
      case 0x37:
      case 0x38:
      case 0x39:
      case 0x2B:
      case 0x2D:
      case 0x2E:
       return this.getNumber();
      case 0x28:
       return this.getString();
      case 0x2F:
       return this.getName();
      case 0x5B:
       this.nextChar();
       return Cmd.get('[');
      case 0x5D:
       this.nextChar();
       return Cmd.get(']');
      case 0x3C:
       ch = this.nextChar();
       if (ch === 0x3C) {
        this.nextChar();
        return Cmd.get('<<');
       }
       return this.getHexString();
      case 0x3E:
       ch = this.nextChar();
       if (ch === 0x3E) {
        this.nextChar();
        return Cmd.get('>>');
       }
       return Cmd.get('>');
      case 0x7B:
       this.nextChar();
       return Cmd.get('{');
      case 0x7D:
       this.nextChar();
       return Cmd.get('}');
      case 0x29:
       error('Illegal character: ' + ch);
       break;
      }
      var str = String.fromCharCode(ch);
      var knownCommands = this.knownCommands;
      var knownCommandFound = knownCommands && knownCommands[str] !== undefined;
      while ((ch = this.nextChar()) >= 0 && !specialChars[ch]) {
       var possibleCommand = str + String.fromCharCode(ch);
       if (knownCommandFound && knownCommands[possibleCommand] === undefined) {
        break;
       }
       if (str.length === 128) {
        error('Command token too long: ' + str.length);
       }
       str = possibleCommand;
       knownCommandFound = knownCommands && knownCommands[str] !== undefined;
      }
      if (str === 'true') {
       return true;
      }
      if (str === 'false') {
       return false;
      }
      if (str === 'null') {
       return null;
      }
      return Cmd.get(str);
     },
     skipToNextLine: function Lexer_skipToNextLine() {
      var ch = this.currentChar;
      while (ch >= 0) {
       if (ch === 0x0D) {
        ch = this.nextChar();
        if (ch === 0x0A) {
         this.nextChar();
        }
        break;
       } else if (ch === 0x0A) {
        this.nextChar();
        break;
       }
       ch = this.nextChar();
      }
     }
    };
    return Lexer;
   }();
   var Linearization = {
    create: function LinearizationCreate(stream) {
     function getInt(name, allowZeroValue) {
      var obj = linDict.get(name);
      if (isInt(obj) && (allowZeroValue ? obj >= 0 : obj > 0)) {
       return obj;
      }
      throw new Error('The "' + name + '" parameter in the linearization ' + 'dictionary is invalid.');
     }
     function getHints() {
      var hints = linDict.get('H'), hintsLength, item;
      if (isArray(hints) && ((hintsLength = hints.length) === 2 || hintsLength === 4)) {
       for (var index = 0; index < hintsLength; index++) {
        if (!(isInt(item = hints[index]) && item > 0)) {
         throw new Error('Hint (' + index + ') in the linearization dictionary is invalid.');
        }
       }
       return hints;
      }
      throw new Error('Hint array in the linearization dictionary is invalid.');
     }
     var parser = new Parser(new Lexer(stream), false, null);
     var obj1 = parser.getObj();
     var obj2 = parser.getObj();
     var obj3 = parser.getObj();
     var linDict = parser.getObj();
     var obj, length;
     if (!(isInt(obj1) && isInt(obj2) && isCmd(obj3, 'obj') && isDict(linDict) && isNum(obj = linDict.get('Linearized')) && obj > 0)) {
      return null;
     } else if ((length = getInt('L')) !== stream.length) {
      throw new Error('The "L" parameter in the linearization dictionary ' + 'does not equal the stream length.');
     }
     return {
      length: length,
      hints: getHints(),
      objectNumberFirst: getInt('O'),
      endFirst: getInt('E'),
      numPages: getInt('N'),
      mainXRefEntriesOffset: getInt('T'),
      pageFirst: linDict.has('P') ? getInt('P', true) : 0
     };
    }
   };
   exports.EOF = EOF;
   exports.Lexer = Lexer;
   exports.Linearization = Linearization;
   exports.Parser = Parser;
   exports.isEOF = isEOF;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreType1Parser = {}, root.pdfjsSharedUtil, root.pdfjsCoreStream, root.pdfjsCoreEncodings);
  }(this, function (exports, sharedUtil, coreStream, coreEncodings) {
   var warn = sharedUtil.warn;
   var isSpace = sharedUtil.isSpace;
   var Stream = coreStream.Stream;
   var getEncoding = coreEncodings.getEncoding;
   var HINTING_ENABLED = false;
   var Type1CharString = function Type1CharStringClosure() {
    var COMMAND_MAP = {
     'hstem': [1],
     'vstem': [3],
     'vmoveto': [4],
     'rlineto': [5],
     'hlineto': [6],
     'vlineto': [7],
     'rrcurveto': [8],
     'callsubr': [10],
     'flex': [
      12,
      35
     ],
     'drop': [
      12,
      18
     ],
     'endchar': [14],
     'rmoveto': [21],
     'hmoveto': [22],
     'vhcurveto': [30],
     'hvcurveto': [31]
    };
    function Type1CharString() {
     this.width = 0;
     this.lsb = 0;
     this.flexing = false;
     this.output = [];
     this.stack = [];
    }
    Type1CharString.prototype = {
     convert: function Type1CharString_convert(encoded, subrs, seacAnalysisEnabled) {
      var count = encoded.length;
      var error = false;
      var wx, sbx, subrNumber;
      for (var i = 0; i < count; i++) {
       var value = encoded[i];
       if (value < 32) {
        if (value === 12) {
         value = (value << 8) + encoded[++i];
        }
        switch (value) {
        case 1:
         if (!HINTING_ENABLED) {
          this.stack = [];
          break;
         }
         error = this.executeCommand(2, COMMAND_MAP.hstem);
         break;
        case 3:
         if (!HINTING_ENABLED) {
          this.stack = [];
          break;
         }
         error = this.executeCommand(2, COMMAND_MAP.vstem);
         break;
        case 4:
         if (this.flexing) {
          if (this.stack.length < 1) {
           error = true;
           break;
          }
          var dy = this.stack.pop();
          this.stack.push(0, dy);
          break;
         }
         error = this.executeCommand(1, COMMAND_MAP.vmoveto);
         break;
        case 5:
         error = this.executeCommand(2, COMMAND_MAP.rlineto);
         break;
        case 6:
         error = this.executeCommand(1, COMMAND_MAP.hlineto);
         break;
        case 7:
         error = this.executeCommand(1, COMMAND_MAP.vlineto);
         break;
        case 8:
         error = this.executeCommand(6, COMMAND_MAP.rrcurveto);
         break;
        case 9:
         this.stack = [];
         break;
        case 10:
         if (this.stack.length < 1) {
          error = true;
          break;
         }
         subrNumber = this.stack.pop();
         error = this.convert(subrs[subrNumber], subrs, seacAnalysisEnabled);
         break;
        case 11:
         return error;
        case 13:
         if (this.stack.length < 2) {
          error = true;
          break;
         }
         wx = this.stack.pop();
         sbx = this.stack.pop();
         this.lsb = sbx;
         this.width = wx;
         this.stack.push(wx, sbx);
         error = this.executeCommand(2, COMMAND_MAP.hmoveto);
         break;
        case 14:
         this.output.push(COMMAND_MAP.endchar[0]);
         break;
        case 21:
         if (this.flexing) {
          break;
         }
         error = this.executeCommand(2, COMMAND_MAP.rmoveto);
         break;
        case 22:
         if (this.flexing) {
          this.stack.push(0);
          break;
         }
         error = this.executeCommand(1, COMMAND_MAP.hmoveto);
         break;
        case 30:
         error = this.executeCommand(4, COMMAND_MAP.vhcurveto);
         break;
        case 31:
         error = this.executeCommand(4, COMMAND_MAP.hvcurveto);
         break;
        case (12 << 8) + 0:
         this.stack = [];
         break;
        case (12 << 8) + 1:
         if (!HINTING_ENABLED) {
          this.stack = [];
          break;
         }
         error = this.executeCommand(2, COMMAND_MAP.vstem);
         break;
        case (12 << 8) + 2:
         if (!HINTING_ENABLED) {
          this.stack = [];
          break;
         }
         error = this.executeCommand(2, COMMAND_MAP.hstem);
         break;
        case (12 << 8) + 6:
         if (seacAnalysisEnabled) {
          this.seac = this.stack.splice(-4, 4);
          error = this.executeCommand(0, COMMAND_MAP.endchar);
         } else {
          error = this.executeCommand(4, COMMAND_MAP.endchar);
         }
         break;
        case (12 << 8) + 7:
         if (this.stack.length < 4) {
          error = true;
          break;
         }
         var wy = this.stack.pop();
         wx = this.stack.pop();
         var sby = this.stack.pop();
         sbx = this.stack.pop();
         this.lsb = sbx;
         this.width = wx;
         this.stack.push(wx, sbx, sby);
         error = this.executeCommand(3, COMMAND_MAP.rmoveto);
         break;
        case (12 << 8) + 12:
         if (this.stack.length < 2) {
          error = true;
          break;
         }
         var num2 = this.stack.pop();
         var num1 = this.stack.pop();
         this.stack.push(num1 / num2);
         break;
        case (12 << 8) + 16:
         if (this.stack.length < 2) {
          error = true;
          break;
         }
         subrNumber = this.stack.pop();
         var numArgs = this.stack.pop();
         if (subrNumber === 0 && numArgs === 3) {
          var flexArgs = this.stack.splice(this.stack.length - 17, 17);
          this.stack.push(flexArgs[2] + flexArgs[0], flexArgs[3] + flexArgs[1], flexArgs[4], flexArgs[5], flexArgs[6], flexArgs[7], flexArgs[8], flexArgs[9], flexArgs[10], flexArgs[11], flexArgs[12], flexArgs[13], flexArgs[14]);
          error = this.executeCommand(13, COMMAND_MAP.flex, true);
          this.flexing = false;
          this.stack.push(flexArgs[15], flexArgs[16]);
         } else if (subrNumber === 1 && numArgs === 0) {
          this.flexing = true;
         }
         break;
        case (12 << 8) + 17:
         break;
        case (12 << 8) + 33:
         this.stack = [];
         break;
        default:
         warn('Unknown type 1 charstring command of "' + value + '"');
         break;
        }
        if (error) {
         break;
        }
        continue;
       } else if (value <= 246) {
        value = value - 139;
       } else if (value <= 250) {
        value = (value - 247) * 256 + encoded[++i] + 108;
       } else if (value <= 254) {
        value = -((value - 251) * 256) - encoded[++i] - 108;
       } else {
        value = (encoded[++i] & 0xff) << 24 | (encoded[++i] & 0xff) << 16 | (encoded[++i] & 0xff) << 8 | (encoded[++i] & 0xff) << 0;
       }
       this.stack.push(value);
      }
      return error;
     },
     executeCommand: function (howManyArgs, command, keepStack) {
      var stackLength = this.stack.length;
      if (howManyArgs > stackLength) {
       return true;
      }
      var start = stackLength - howManyArgs;
      for (var i = start; i < stackLength; i++) {
       var value = this.stack[i];
       if (value === (value | 0)) {
        this.output.push(28, value >> 8 & 0xff, value & 0xff);
       } else {
        value = 65536 * value | 0;
        this.output.push(255, value >> 24 & 0xFF, value >> 16 & 0xFF, value >> 8 & 0xFF, value & 0xFF);
       }
      }
      this.output.push.apply(this.output, command);
      if (keepStack) {
       this.stack.splice(start, howManyArgs);
      } else {
       this.stack.length = 0;
      }
      return false;
     }
    };
    return Type1CharString;
   }();
   var Type1Parser = function Type1ParserClosure() {
    var EEXEC_ENCRYPT_KEY = 55665;
    var CHAR_STRS_ENCRYPT_KEY = 4330;
    function isHexDigit(code) {
     return code >= 48 && code <= 57 || code >= 65 && code <= 70 || code >= 97 && code <= 102;
    }
    function decrypt(data, key, discardNumber) {
     if (discardNumber >= data.length) {
      return new Uint8Array(0);
     }
     var r = key | 0, c1 = 52845, c2 = 22719, i, j;
     for (i = 0; i < discardNumber; i++) {
      r = (data[i] + r) * c1 + c2 & (1 << 16) - 1;
     }
     var count = data.length - discardNumber;
     var decrypted = new Uint8Array(count);
     for (i = discardNumber, j = 0; j < count; i++, j++) {
      var value = data[i];
      decrypted[j] = value ^ r >> 8;
      r = (value + r) * c1 + c2 & (1 << 16) - 1;
     }
     return decrypted;
    }
    function decryptAscii(data, key, discardNumber) {
     var r = key | 0, c1 = 52845, c2 = 22719;
     var count = data.length, maybeLength = count >>> 1;
     var decrypted = new Uint8Array(maybeLength);
     var i, j;
     for (i = 0, j = 0; i < count; i++) {
      var digit1 = data[i];
      if (!isHexDigit(digit1)) {
       continue;
      }
      i++;
      var digit2;
      while (i < count && !isHexDigit(digit2 = data[i])) {
       i++;
      }
      if (i < count) {
       var value = parseInt(String.fromCharCode(digit1, digit2), 16);
       decrypted[j++] = value ^ r >> 8;
       r = (value + r) * c1 + c2 & (1 << 16) - 1;
      }
     }
     return Array.prototype.slice.call(decrypted, discardNumber, j);
    }
    function isSpecial(c) {
     return c === 0x2F || c === 0x5B || c === 0x5D || c === 0x7B || c === 0x7D || c === 0x28 || c === 0x29;
    }
    function Type1Parser(stream, encrypted, seacAnalysisEnabled) {
     if (encrypted) {
      var data = stream.getBytes();
      var isBinary = !(isHexDigit(data[0]) && isHexDigit(data[1]) && isHexDigit(data[2]) && isHexDigit(data[3]));
      stream = new Stream(isBinary ? decrypt(data, EEXEC_ENCRYPT_KEY, 4) : decryptAscii(data, EEXEC_ENCRYPT_KEY, 4));
     }
     this.seacAnalysisEnabled = !!seacAnalysisEnabled;
     this.stream = stream;
     this.nextChar();
    }
    Type1Parser.prototype = {
     readNumberArray: function Type1Parser_readNumberArray() {
      this.getToken();
      var array = [];
      while (true) {
       var token = this.getToken();
       if (token === null || token === ']' || token === '}') {
        break;
       }
       array.push(parseFloat(token || 0));
      }
      return array;
     },
     readNumber: function Type1Parser_readNumber() {
      var token = this.getToken();
      return parseFloat(token || 0);
     },
     readInt: function Type1Parser_readInt() {
      var token = this.getToken();
      return parseInt(token || 0, 10) | 0;
     },
     readBoolean: function Type1Parser_readBoolean() {
      var token = this.getToken();
      return token === 'true' ? 1 : 0;
     },
     nextChar: function Type1_nextChar() {
      return this.currentChar = this.stream.getByte();
     },
     getToken: function Type1Parser_getToken() {
      var comment = false;
      var ch = this.currentChar;
      while (true) {
       if (ch === -1) {
        return null;
       }
       if (comment) {
        if (ch === 0x0A || ch === 0x0D) {
         comment = false;
        }
       } else if (ch === 0x25) {
        comment = true;
       } else if (!isSpace(ch)) {
        break;
       }
       ch = this.nextChar();
      }
      if (isSpecial(ch)) {
       this.nextChar();
       return String.fromCharCode(ch);
      }
      var token = '';
      do {
       token += String.fromCharCode(ch);
       ch = this.nextChar();
      } while (ch >= 0 && !isSpace(ch) && !isSpecial(ch));
      return token;
     },
     extractFontProgram: function Type1Parser_extractFontProgram() {
      var stream = this.stream;
      var subrs = [], charstrings = [];
      var privateData = Object.create(null);
      privateData['lenIV'] = 4;
      var program = {
       subrs: [],
       charstrings: [],
       properties: { 'privateData': privateData }
      };
      var token, length, data, lenIV, encoded;
      while ((token = this.getToken()) !== null) {
       if (token !== '/') {
        continue;
       }
       token = this.getToken();
       switch (token) {
       case 'CharStrings':
        this.getToken();
        this.getToken();
        this.getToken();
        this.getToken();
        while (true) {
         token = this.getToken();
         if (token === null || token === 'end') {
          break;
         }
         if (token !== '/') {
          continue;
         }
         var glyph = this.getToken();
         length = this.readInt();
         this.getToken();
         data = stream.makeSubStream(stream.pos, length);
         lenIV = program.properties.privateData['lenIV'];
         encoded = decrypt(data.getBytes(), CHAR_STRS_ENCRYPT_KEY, lenIV);
         stream.skip(length);
         this.nextChar();
         token = this.getToken();
         if (token === 'noaccess') {
          this.getToken();
         }
         charstrings.push({
          glyph: glyph,
          encoded: encoded
         });
        }
        break;
       case 'Subrs':
        var num = this.readInt();
        this.getToken();
        while ((token = this.getToken()) === 'dup') {
         var index = this.readInt();
         length = this.readInt();
         this.getToken();
         data = stream.makeSubStream(stream.pos, length);
         lenIV = program.properties.privateData['lenIV'];
         encoded = decrypt(data.getBytes(), CHAR_STRS_ENCRYPT_KEY, lenIV);
         stream.skip(length);
         this.nextChar();
         token = this.getToken();
         if (token === 'noaccess') {
          this.getToken();
         }
         subrs[index] = encoded;
        }
        break;
       case 'BlueValues':
       case 'OtherBlues':
       case 'FamilyBlues':
       case 'FamilyOtherBlues':
        var blueArray = this.readNumberArray();
        if (blueArray.length > 0 && blueArray.length % 2 === 0 && HINTING_ENABLED) {
         program.properties.privateData[token] = blueArray;
        }
        break;
       case 'StemSnapH':
       case 'StemSnapV':
        program.properties.privateData[token] = this.readNumberArray();
        break;
       case 'StdHW':
       case 'StdVW':
        program.properties.privateData[token] = this.readNumberArray()[0];
        break;
       case 'BlueShift':
       case 'lenIV':
       case 'BlueFuzz':
       case 'BlueScale':
       case 'LanguageGroup':
       case 'ExpansionFactor':
        program.properties.privateData[token] = this.readNumber();
        break;
       case 'ForceBold':
        program.properties.privateData[token] = this.readBoolean();
        break;
       }
      }
      for (var i = 0; i < charstrings.length; i++) {
       glyph = charstrings[i].glyph;
       encoded = charstrings[i].encoded;
       var charString = new Type1CharString();
       var error = charString.convert(encoded, subrs, this.seacAnalysisEnabled);
       var output = charString.output;
       if (error) {
        output = [14];
       }
       program.charstrings.push({
        glyphName: glyph,
        charstring: output,
        width: charString.width,
        lsb: charString.lsb,
        seac: charString.seac
       });
      }
      return program;
     },
     extractFontHeader: function Type1Parser_extractFontHeader(properties) {
      var token;
      while ((token = this.getToken()) !== null) {
       if (token !== '/') {
        continue;
       }
       token = this.getToken();
       switch (token) {
       case 'FontMatrix':
        var matrix = this.readNumberArray();
        properties.fontMatrix = matrix;
        break;
       case 'Encoding':
        var encodingArg = this.getToken();
        var encoding;
        if (!/^\d+$/.test(encodingArg)) {
         encoding = getEncoding(encodingArg);
        } else {
         encoding = [];
         var size = parseInt(encodingArg, 10) | 0;
         this.getToken();
         for (var j = 0; j < size; j++) {
          token = this.getToken();
          while (token !== 'dup' && token !== 'def') {
           token = this.getToken();
           if (token === null) {
            return;
           }
          }
          if (token === 'def') {
           break;
          }
          var index = this.readInt();
          this.getToken();
          var glyph = this.getToken();
          encoding[index] = glyph;
          this.getToken();
         }
        }
        properties.builtInEncoding = encoding;
        break;
       case 'FontBBox':
        var fontBBox = this.readNumberArray();
        properties.ascent = fontBBox[3];
        properties.descent = fontBBox[1];
        properties.ascentScaled = true;
        break;
       }
      }
     }
    };
    return Type1Parser;
   }();
   exports.Type1Parser = Type1Parser;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayPatternHelper = {}, root.pdfjsSharedUtil, root.pdfjsDisplayWebGL);
  }(this, function (exports, sharedUtil, displayWebGL) {
   var Util = sharedUtil.Util;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var error = sharedUtil.error;
   var WebGLUtils = displayWebGL.WebGLUtils;
   var ShadingIRs = {};
   ShadingIRs.RadialAxial = {
    fromIR: function RadialAxial_fromIR(raw) {
     var type = raw[1];
     var colorStops = raw[2];
     var p0 = raw[3];
     var p1 = raw[4];
     var r0 = raw[5];
     var r1 = raw[6];
     return {
      type: 'Pattern',
      getPattern: function RadialAxial_getPattern(ctx) {
       var grad;
       if (type === 'axial') {
        grad = ctx.createLinearGradient(p0[0], p0[1], p1[0], p1[1]);
       } else if (type === 'radial') {
        grad = ctx.createRadialGradient(p0[0], p0[1], r0, p1[0], p1[1], r1);
       }
       for (var i = 0, ii = colorStops.length; i < ii; ++i) {
        var c = colorStops[i];
        grad.addColorStop(c[0], c[1]);
       }
       return grad;
      }
     };
    }
   };
   var createMeshCanvas = function createMeshCanvasClosure() {
    function drawTriangle(data, context, p1, p2, p3, c1, c2, c3) {
     var coords = context.coords, colors = context.colors;
     var bytes = data.data, rowSize = data.width * 4;
     var tmp;
     if (coords[p1 + 1] > coords[p2 + 1]) {
      tmp = p1;
      p1 = p2;
      p2 = tmp;
      tmp = c1;
      c1 = c2;
      c2 = tmp;
     }
     if (coords[p2 + 1] > coords[p3 + 1]) {
      tmp = p2;
      p2 = p3;
      p3 = tmp;
      tmp = c2;
      c2 = c3;
      c3 = tmp;
     }
     if (coords[p1 + 1] > coords[p2 + 1]) {
      tmp = p1;
      p1 = p2;
      p2 = tmp;
      tmp = c1;
      c1 = c2;
      c2 = tmp;
     }
     var x1 = (coords[p1] + context.offsetX) * context.scaleX;
     var y1 = (coords[p1 + 1] + context.offsetY) * context.scaleY;
     var x2 = (coords[p2] + context.offsetX) * context.scaleX;
     var y2 = (coords[p2 + 1] + context.offsetY) * context.scaleY;
     var x3 = (coords[p3] + context.offsetX) * context.scaleX;
     var y3 = (coords[p3 + 1] + context.offsetY) * context.scaleY;
     if (y1 >= y3) {
      return;
     }
     var c1r = colors[c1], c1g = colors[c1 + 1], c1b = colors[c1 + 2];
     var c2r = colors[c2], c2g = colors[c2 + 1], c2b = colors[c2 + 2];
     var c3r = colors[c3], c3g = colors[c3 + 1], c3b = colors[c3 + 2];
     var minY = Math.round(y1), maxY = Math.round(y3);
     var xa, car, cag, cab;
     var xb, cbr, cbg, cbb;
     var k;
     for (var y = minY; y <= maxY; y++) {
      if (y < y2) {
       k = y < y1 ? 0 : y1 === y2 ? 1 : (y1 - y) / (y1 - y2);
       xa = x1 - (x1 - x2) * k;
       car = c1r - (c1r - c2r) * k;
       cag = c1g - (c1g - c2g) * k;
       cab = c1b - (c1b - c2b) * k;
      } else {
       k = y > y3 ? 1 : y2 === y3 ? 0 : (y2 - y) / (y2 - y3);
       xa = x2 - (x2 - x3) * k;
       car = c2r - (c2r - c3r) * k;
       cag = c2g - (c2g - c3g) * k;
       cab = c2b - (c2b - c3b) * k;
      }
      k = y < y1 ? 0 : y > y3 ? 1 : (y1 - y) / (y1 - y3);
      xb = x1 - (x1 - x3) * k;
      cbr = c1r - (c1r - c3r) * k;
      cbg = c1g - (c1g - c3g) * k;
      cbb = c1b - (c1b - c3b) * k;
      var x1_ = Math.round(Math.min(xa, xb));
      var x2_ = Math.round(Math.max(xa, xb));
      var j = rowSize * y + x1_ * 4;
      for (var x = x1_; x <= x2_; x++) {
       k = (xa - x) / (xa - xb);
       k = k < 0 ? 0 : k > 1 ? 1 : k;
       bytes[j++] = car - (car - cbr) * k | 0;
       bytes[j++] = cag - (cag - cbg) * k | 0;
       bytes[j++] = cab - (cab - cbb) * k | 0;
       bytes[j++] = 255;
      }
     }
    }
    function drawFigure(data, figure, context) {
     var ps = figure.coords;
     var cs = figure.colors;
     var i, ii;
     switch (figure.type) {
     case 'lattice':
      var verticesPerRow = figure.verticesPerRow;
      var rows = Math.floor(ps.length / verticesPerRow) - 1;
      var cols = verticesPerRow - 1;
      for (i = 0; i < rows; i++) {
       var q = i * verticesPerRow;
       for (var j = 0; j < cols; j++, q++) {
        drawTriangle(data, context, ps[q], ps[q + 1], ps[q + verticesPerRow], cs[q], cs[q + 1], cs[q + verticesPerRow]);
        drawTriangle(data, context, ps[q + verticesPerRow + 1], ps[q + 1], ps[q + verticesPerRow], cs[q + verticesPerRow + 1], cs[q + 1], cs[q + verticesPerRow]);
       }
      }
      break;
     case 'triangles':
      for (i = 0, ii = ps.length; i < ii; i += 3) {
       drawTriangle(data, context, ps[i], ps[i + 1], ps[i + 2], cs[i], cs[i + 1], cs[i + 2]);
      }
      break;
     default:
      error('illigal figure');
      break;
     }
    }
    function createMeshCanvas(bounds, combinesScale, coords, colors, figures, backgroundColor, cachedCanvases) {
     var EXPECTED_SCALE = 1.1;
     var MAX_PATTERN_SIZE = 3000;
     var BORDER_SIZE = 2;
     var offsetX = Math.floor(bounds[0]);
     var offsetY = Math.floor(bounds[1]);
     var boundsWidth = Math.ceil(bounds[2]) - offsetX;
     var boundsHeight = Math.ceil(bounds[3]) - offsetY;
     var width = Math.min(Math.ceil(Math.abs(boundsWidth * combinesScale[0] * EXPECTED_SCALE)), MAX_PATTERN_SIZE);
     var height = Math.min(Math.ceil(Math.abs(boundsHeight * combinesScale[1] * EXPECTED_SCALE)), MAX_PATTERN_SIZE);
     var scaleX = boundsWidth / width;
     var scaleY = boundsHeight / height;
     var context = {
      coords: coords,
      colors: colors,
      offsetX: -offsetX,
      offsetY: -offsetY,
      scaleX: 1 / scaleX,
      scaleY: 1 / scaleY
     };
     var paddedWidth = width + BORDER_SIZE * 2;
     var paddedHeight = height + BORDER_SIZE * 2;
     var canvas, tmpCanvas, i, ii;
     if (WebGLUtils.isEnabled) {
      canvas = WebGLUtils.drawFigures(width, height, backgroundColor, figures, context);
      tmpCanvas = cachedCanvases.getCanvas('mesh', paddedWidth, paddedHeight, false);
      tmpCanvas.context.drawImage(canvas, BORDER_SIZE, BORDER_SIZE);
      canvas = tmpCanvas.canvas;
     } else {
      tmpCanvas = cachedCanvases.getCanvas('mesh', paddedWidth, paddedHeight, false);
      var tmpCtx = tmpCanvas.context;
      var data = tmpCtx.createImageData(width, height);
      if (backgroundColor) {
       var bytes = data.data;
       for (i = 0, ii = bytes.length; i < ii; i += 4) {
        bytes[i] = backgroundColor[0];
        bytes[i + 1] = backgroundColor[1];
        bytes[i + 2] = backgroundColor[2];
        bytes[i + 3] = 255;
       }
      }
      for (i = 0; i < figures.length; i++) {
       drawFigure(data, figures[i], context);
      }
      tmpCtx.putImageData(data, BORDER_SIZE, BORDER_SIZE);
      canvas = tmpCanvas.canvas;
     }
     return {
      canvas: canvas,
      offsetX: offsetX - BORDER_SIZE * scaleX,
      offsetY: offsetY - BORDER_SIZE * scaleY,
      scaleX: scaleX,
      scaleY: scaleY
     };
    }
    return createMeshCanvas;
   }();
   ShadingIRs.Mesh = {
    fromIR: function Mesh_fromIR(raw) {
     var coords = raw[2];
     var colors = raw[3];
     var figures = raw[4];
     var bounds = raw[5];
     var matrix = raw[6];
     var background = raw[8];
     return {
      type: 'Pattern',
      getPattern: function Mesh_getPattern(ctx, owner, shadingFill) {
       var scale;
       if (shadingFill) {
        scale = Util.singularValueDecompose2dScale(ctx.mozCurrentTransform);
       } else {
        scale = Util.singularValueDecompose2dScale(owner.baseTransform);
        if (matrix) {
         var matrixScale = Util.singularValueDecompose2dScale(matrix);
         scale = [
          scale[0] * matrixScale[0],
          scale[1] * matrixScale[1]
         ];
        }
       }
       var temporaryPatternCanvas = createMeshCanvas(bounds, scale, coords, colors, figures, shadingFill ? null : background, owner.cachedCanvases);
       if (!shadingFill) {
        ctx.setTransform.apply(ctx, owner.baseTransform);
        if (matrix) {
         ctx.transform.apply(ctx, matrix);
        }
       }
       ctx.translate(temporaryPatternCanvas.offsetX, temporaryPatternCanvas.offsetY);
       ctx.scale(temporaryPatternCanvas.scaleX, temporaryPatternCanvas.scaleY);
       return ctx.createPattern(temporaryPatternCanvas.canvas, 'no-repeat');
      }
     };
    }
   };
   ShadingIRs.Dummy = {
    fromIR: function Dummy_fromIR() {
     return {
      type: 'Pattern',
      getPattern: function Dummy_fromIR_getPattern() {
       return 'hotpink';
      }
     };
    }
   };
   function getShadingPatternFromIR(raw) {
    var shadingIR = ShadingIRs[raw[0]];
    if (!shadingIR) {
     error('Unknown IR type: ' + raw[0]);
    }
    return shadingIR.fromIR(raw);
   }
   var TilingPattern = function TilingPatternClosure() {
    var PaintType = {
     COLORED: 1,
     UNCOLORED: 2
    };
    var MAX_PATTERN_SIZE = 3000;
    function TilingPattern(IR, color, ctx, canvasGraphicsFactory, baseTransform) {
     this.operatorList = IR[2];
     this.matrix = IR[3] || [
      1,
      0,
      0,
      1,
      0,
      0
     ];
     this.bbox = IR[4];
     this.xstep = IR[5];
     this.ystep = IR[6];
     this.paintType = IR[7];
     this.tilingType = IR[8];
     this.color = color;
     this.canvasGraphicsFactory = canvasGraphicsFactory;
     this.baseTransform = baseTransform;
     this.type = 'Pattern';
     this.ctx = ctx;
    }
    TilingPattern.prototype = {
     createPatternCanvas: function TilinPattern_createPatternCanvas(owner) {
      var operatorList = this.operatorList;
      var bbox = this.bbox;
      var xstep = this.xstep;
      var ystep = this.ystep;
      var paintType = this.paintType;
      var tilingType = this.tilingType;
      var color = this.color;
      var canvasGraphicsFactory = this.canvasGraphicsFactory;
      info('TilingType: ' + tilingType);
      var x0 = bbox[0], y0 = bbox[1], x1 = bbox[2], y1 = bbox[3];
      var topLeft = [
       x0,
       y0
      ];
      var botRight = [
       x0 + xstep,
       y0 + ystep
      ];
      var width = botRight[0] - topLeft[0];
      var height = botRight[1] - topLeft[1];
      var matrixScale = Util.singularValueDecompose2dScale(this.matrix);
      var curMatrixScale = Util.singularValueDecompose2dScale(this.baseTransform);
      var combinedScale = [
       matrixScale[0] * curMatrixScale[0],
       matrixScale[1] * curMatrixScale[1]
      ];
      width = Math.min(Math.ceil(Math.abs(width * combinedScale[0])), MAX_PATTERN_SIZE);
      height = Math.min(Math.ceil(Math.abs(height * combinedScale[1])), MAX_PATTERN_SIZE);
      var tmpCanvas = owner.cachedCanvases.getCanvas('pattern', width, height, true);
      var tmpCtx = tmpCanvas.context;
      var graphics = canvasGraphicsFactory.createCanvasGraphics(tmpCtx);
      graphics.groupLevel = owner.groupLevel;
      this.setFillAndStrokeStyleToContext(tmpCtx, paintType, color);
      this.setScale(width, height, xstep, ystep);
      this.transformToScale(graphics);
      var tmpTranslate = [
       1,
       0,
       0,
       1,
       -topLeft[0],
       -topLeft[1]
      ];
      graphics.transform.apply(graphics, tmpTranslate);
      this.clipBbox(graphics, bbox, x0, y0, x1, y1);
      graphics.executeOperatorList(operatorList);
      return tmpCanvas.canvas;
     },
     setScale: function TilingPattern_setScale(width, height, xstep, ystep) {
      this.scale = [
       width / xstep,
       height / ystep
      ];
     },
     transformToScale: function TilingPattern_transformToScale(graphics) {
      var scale = this.scale;
      var tmpScale = [
       scale[0],
       0,
       0,
       scale[1],
       0,
       0
      ];
      graphics.transform.apply(graphics, tmpScale);
     },
     scaleToContext: function TilingPattern_scaleToContext() {
      var scale = this.scale;
      this.ctx.scale(1 / scale[0], 1 / scale[1]);
     },
     clipBbox: function clipBbox(graphics, bbox, x0, y0, x1, y1) {
      if (bbox && isArray(bbox) && bbox.length === 4) {
       var bboxWidth = x1 - x0;
       var bboxHeight = y1 - y0;
       graphics.ctx.rect(x0, y0, bboxWidth, bboxHeight);
       graphics.clip();
       graphics.endPath();
      }
     },
     setFillAndStrokeStyleToContext: function setFillAndStrokeStyleToContext(context, paintType, color) {
      switch (paintType) {
      case PaintType.COLORED:
       var ctx = this.ctx;
       context.fillStyle = ctx.fillStyle;
       context.strokeStyle = ctx.strokeStyle;
       break;
      case PaintType.UNCOLORED:
       var cssColor = Util.makeCssRgb(color[0], color[1], color[2]);
       context.fillStyle = cssColor;
       context.strokeStyle = cssColor;
       break;
      default:
       error('Unsupported paint type: ' + paintType);
      }
     },
     getPattern: function TilingPattern_getPattern(ctx, owner) {
      var temporaryPatternCanvas = this.createPatternCanvas(owner);
      ctx = this.ctx;
      ctx.setTransform.apply(ctx, this.baseTransform);
      ctx.transform.apply(ctx, this.matrix);
      this.scaleToContext();
      return ctx.createPattern(temporaryPatternCanvas, 'repeat');
     }
    };
    return TilingPattern;
   }();
   exports.getShadingPatternFromIR = getShadingPatternFromIR;
   exports.TilingPattern = TilingPattern;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreCMap = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreStream, root.pdfjsCoreParser);
  }(this, function (exports, sharedUtil, corePrimitives, coreStream, coreParser) {
   var Util = sharedUtil.Util;
   var assert = sharedUtil.assert;
   var warn = sharedUtil.warn;
   var error = sharedUtil.error;
   var isInt = sharedUtil.isInt;
   var isString = sharedUtil.isString;
   var MissingDataException = sharedUtil.MissingDataException;
   var isName = corePrimitives.isName;
   var isCmd = corePrimitives.isCmd;
   var isStream = corePrimitives.isStream;
   var StringStream = coreStream.StringStream;
   var Lexer = coreParser.Lexer;
   var isEOF = coreParser.isEOF;
   var BUILT_IN_CMAPS = [
    'Adobe-GB1-UCS2',
    'Adobe-CNS1-UCS2',
    'Adobe-Japan1-UCS2',
    'Adobe-Korea1-UCS2',
    '78-EUC-H',
    '78-EUC-V',
    '78-H',
    '78-RKSJ-H',
    '78-RKSJ-V',
    '78-V',
    '78ms-RKSJ-H',
    '78ms-RKSJ-V',
    '83pv-RKSJ-H',
    '90ms-RKSJ-H',
    '90ms-RKSJ-V',
    '90msp-RKSJ-H',
    '90msp-RKSJ-V',
    '90pv-RKSJ-H',
    '90pv-RKSJ-V',
    'Add-H',
    'Add-RKSJ-H',
    'Add-RKSJ-V',
    'Add-V',
    'Adobe-CNS1-0',
    'Adobe-CNS1-1',
    'Adobe-CNS1-2',
    'Adobe-CNS1-3',
    'Adobe-CNS1-4',
    'Adobe-CNS1-5',
    'Adobe-CNS1-6',
    'Adobe-GB1-0',
    'Adobe-GB1-1',
    'Adobe-GB1-2',
    'Adobe-GB1-3',
    'Adobe-GB1-4',
    'Adobe-GB1-5',
    'Adobe-Japan1-0',
    'Adobe-Japan1-1',
    'Adobe-Japan1-2',
    'Adobe-Japan1-3',
    'Adobe-Japan1-4',
    'Adobe-Japan1-5',
    'Adobe-Japan1-6',
    'Adobe-Korea1-0',
    'Adobe-Korea1-1',
    'Adobe-Korea1-2',
    'B5-H',
    'B5-V',
    'B5pc-H',
    'B5pc-V',
    'CNS-EUC-H',
    'CNS-EUC-V',
    'CNS1-H',
    'CNS1-V',
    'CNS2-H',
    'CNS2-V',
    'ETHK-B5-H',
    'ETHK-B5-V',
    'ETen-B5-H',
    'ETen-B5-V',
    'ETenms-B5-H',
    'ETenms-B5-V',
    'EUC-H',
    'EUC-V',
    'Ext-H',
    'Ext-RKSJ-H',
    'Ext-RKSJ-V',
    'Ext-V',
    'GB-EUC-H',
    'GB-EUC-V',
    'GB-H',
    'GB-V',
    'GBK-EUC-H',
    'GBK-EUC-V',
    'GBK2K-H',
    'GBK2K-V',
    'GBKp-EUC-H',
    'GBKp-EUC-V',
    'GBT-EUC-H',
    'GBT-EUC-V',
    'GBT-H',
    'GBT-V',
    'GBTpc-EUC-H',
    'GBTpc-EUC-V',
    'GBpc-EUC-H',
    'GBpc-EUC-V',
    'H',
    'HKdla-B5-H',
    'HKdla-B5-V',
    'HKdlb-B5-H',
    'HKdlb-B5-V',
    'HKgccs-B5-H',
    'HKgccs-B5-V',
    'HKm314-B5-H',
    'HKm314-B5-V',
    'HKm471-B5-H',
    'HKm471-B5-V',
    'HKscs-B5-H',
    'HKscs-B5-V',
    'Hankaku',
    'Hiragana',
    'KSC-EUC-H',
    'KSC-EUC-V',
    'KSC-H',
    'KSC-Johab-H',
    'KSC-Johab-V',
    'KSC-V',
    'KSCms-UHC-H',
    'KSCms-UHC-HW-H',
    'KSCms-UHC-HW-V',
    'KSCms-UHC-V',
    'KSCpc-EUC-H',
    'KSCpc-EUC-V',
    'Katakana',
    'NWP-H',
    'NWP-V',
    'RKSJ-H',
    'RKSJ-V',
    'Roman',
    'UniCNS-UCS2-H',
    'UniCNS-UCS2-V',
    'UniCNS-UTF16-H',
    'UniCNS-UTF16-V',
    'UniCNS-UTF32-H',
    'UniCNS-UTF32-V',
    'UniCNS-UTF8-H',
    'UniCNS-UTF8-V',
    'UniGB-UCS2-H',
    'UniGB-UCS2-V',
    'UniGB-UTF16-H',
    'UniGB-UTF16-V',
    'UniGB-UTF32-H',
    'UniGB-UTF32-V',
    'UniGB-UTF8-H',
    'UniGB-UTF8-V',
    'UniJIS-UCS2-H',
    'UniJIS-UCS2-HW-H',
    'UniJIS-UCS2-HW-V',
    'UniJIS-UCS2-V',
    'UniJIS-UTF16-H',
    'UniJIS-UTF16-V',
    'UniJIS-UTF32-H',
    'UniJIS-UTF32-V',
    'UniJIS-UTF8-H',
    'UniJIS-UTF8-V',
    'UniJIS2004-UTF16-H',
    'UniJIS2004-UTF16-V',
    'UniJIS2004-UTF32-H',
    'UniJIS2004-UTF32-V',
    'UniJIS2004-UTF8-H',
    'UniJIS2004-UTF8-V',
    'UniJISPro-UCS2-HW-V',
    'UniJISPro-UCS2-V',
    'UniJISPro-UTF8-V',
    'UniJISX0213-UTF32-H',
    'UniJISX0213-UTF32-V',
    'UniJISX02132004-UTF32-H',
    'UniJISX02132004-UTF32-V',
    'UniKS-UCS2-H',
    'UniKS-UCS2-V',
    'UniKS-UTF16-H',
    'UniKS-UTF16-V',
    'UniKS-UTF32-H',
    'UniKS-UTF32-V',
    'UniKS-UTF8-H',
    'UniKS-UTF8-V',
    'V',
    'WP-Symbol'
   ];
   var CMap = function CMapClosure() {
    function CMap(builtInCMap) {
     this.codespaceRanges = [
      [],
      [],
      [],
      []
     ];
     this.numCodespaceRanges = 0;
     this._map = [];
     this.name = '';
     this.vertical = false;
     this.useCMap = null;
     this.builtInCMap = builtInCMap;
    }
    CMap.prototype = {
     addCodespaceRange: function (n, low, high) {
      this.codespaceRanges[n - 1].push(low, high);
      this.numCodespaceRanges++;
     },
     mapCidRange: function (low, high, dstLow) {
      while (low <= high) {
       this._map[low++] = dstLow++;
      }
     },
     mapBfRange: function (low, high, dstLow) {
      var lastByte = dstLow.length - 1;
      while (low <= high) {
       this._map[low++] = dstLow;
       dstLow = dstLow.substr(0, lastByte) + String.fromCharCode(dstLow.charCodeAt(lastByte) + 1);
      }
     },
     mapBfRangeToArray: function (low, high, array) {
      var i = 0, ii = array.length;
      while (low <= high && i < ii) {
       this._map[low] = array[i++];
       ++low;
      }
     },
     mapOne: function (src, dst) {
      this._map[src] = dst;
     },
     lookup: function (code) {
      return this._map[code];
     },
     contains: function (code) {
      return this._map[code] !== undefined;
     },
     forEach: function (callback) {
      var map = this._map;
      var length = map.length;
      var i;
      if (length <= 0x10000) {
       for (i = 0; i < length; i++) {
        if (map[i] !== undefined) {
         callback(i, map[i]);
        }
       }
      } else {
       for (i in this._map) {
        callback(i, map[i]);
       }
      }
     },
     charCodeOf: function (value) {
      return this._map.indexOf(value);
     },
     getMap: function () {
      return this._map;
     },
     readCharCode: function (str, offset, out) {
      var c = 0;
      var codespaceRanges = this.codespaceRanges;
      var codespaceRangesLen = this.codespaceRanges.length;
      for (var n = 0; n < codespaceRangesLen; n++) {
       c = (c << 8 | str.charCodeAt(offset + n)) >>> 0;
       var codespaceRange = codespaceRanges[n];
       for (var k = 0, kk = codespaceRange.length; k < kk;) {
        var low = codespaceRange[k++];
        var high = codespaceRange[k++];
        if (c >= low && c <= high) {
         out.charcode = c;
         out.length = n + 1;
         return;
        }
       }
      }
      out.charcode = 0;
      out.length = 1;
     },
     get length() {
      return this._map.length;
     },
     get isIdentityCMap() {
      if (!(this.name === 'Identity-H' || this.name === 'Identity-V')) {
       return false;
      }
      if (this._map.length !== 0x10000) {
       return false;
      }
      for (var i = 0; i < 0x10000; i++) {
       if (this._map[i] !== i) {
        return false;
       }
      }
      return true;
     }
    };
    return CMap;
   }();
   var IdentityCMap = function IdentityCMapClosure() {
    function IdentityCMap(vertical, n) {
     CMap.call(this);
     this.vertical = vertical;
     this.addCodespaceRange(n, 0, 0xffff);
    }
    Util.inherit(IdentityCMap, CMap, {});
    IdentityCMap.prototype = {
     addCodespaceRange: CMap.prototype.addCodespaceRange,
     mapCidRange: function (low, high, dstLow) {
      error('should not call mapCidRange');
     },
     mapBfRange: function (low, high, dstLow) {
      error('should not call mapBfRange');
     },
     mapBfRangeToArray: function (low, high, array) {
      error('should not call mapBfRangeToArray');
     },
     mapOne: function (src, dst) {
      error('should not call mapCidOne');
     },
     lookup: function (code) {
      return isInt(code) && code <= 0xffff ? code : undefined;
     },
     contains: function (code) {
      return isInt(code) && code <= 0xffff;
     },
     forEach: function (callback) {
      for (var i = 0; i <= 0xffff; i++) {
       callback(i, i);
      }
     },
     charCodeOf: function (value) {
      return isInt(value) && value <= 0xffff ? value : -1;
     },
     getMap: function () {
      var map = new Array(0x10000);
      for (var i = 0; i <= 0xffff; i++) {
       map[i] = i;
      }
      return map;
     },
     readCharCode: CMap.prototype.readCharCode,
     get length() {
      return 0x10000;
     },
     get isIdentityCMap() {
      error('should not access .isIdentityCMap');
     }
    };
    return IdentityCMap;
   }();
   var BinaryCMapReader = function BinaryCMapReaderClosure() {
    function fetchBinaryData(url) {
     return new Promise(function (resolve, reject) {
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'arraybuffer';
      request.onreadystatechange = function () {
       if (request.readyState === XMLHttpRequest.DONE) {
        if (!request.response || request.status !== 200 && request.status !== 0) {
         reject(new Error('Unable to get binary cMap at: ' + url));
        } else {
         resolve(new Uint8Array(request.response));
        }
       }
      };
      request.send(null);
     });
    }
    function hexToInt(a, size) {
     var n = 0;
     for (var i = 0; i <= size; i++) {
      n = n << 8 | a[i];
     }
     return n >>> 0;
    }
    function hexToStr(a, size) {
     if (size === 1) {
      return String.fromCharCode(a[0], a[1]);
     }
     if (size === 3) {
      return String.fromCharCode(a[0], a[1], a[2], a[3]);
     }
     return String.fromCharCode.apply(null, a.subarray(0, size + 1));
    }
    function addHex(a, b, size) {
     var c = 0;
     for (var i = size; i >= 0; i--) {
      c += a[i] + b[i];
      a[i] = c & 255;
      c >>= 8;
     }
    }
    function incHex(a, size) {
     var c = 1;
     for (var i = size; i >= 0 && c > 0; i--) {
      c += a[i];
      a[i] = c & 255;
      c >>= 8;
     }
    }
    var MAX_NUM_SIZE = 16;
    var MAX_ENCODED_NUM_SIZE = 19;
    function BinaryCMapStream(data) {
     this.buffer = data;
     this.pos = 0;
     this.end = data.length;
     this.tmpBuf = new Uint8Array(MAX_ENCODED_NUM_SIZE);
    }
    BinaryCMapStream.prototype = {
     readByte: function () {
      if (this.pos >= this.end) {
       return -1;
      }
      return this.buffer[this.pos++];
     },
     readNumber: function () {
      var n = 0;
      var last;
      do {
       var b = this.readByte();
       if (b < 0) {
        error('unexpected EOF in bcmap');
       }
       last = !(b & 0x80);
       n = n << 7 | b & 0x7F;
      } while (!last);
      return n;
     },
     readSigned: function () {
      var n = this.readNumber();
      return n & 1 ? ~(n >>> 1) : n >>> 1;
     },
     readHex: function (num, size) {
      num.set(this.buffer.subarray(this.pos, this.pos + size + 1));
      this.pos += size + 1;
     },
     readHexNumber: function (num, size) {
      var last;
      var stack = this.tmpBuf, sp = 0;
      do {
       var b = this.readByte();
       if (b < 0) {
        error('unexpected EOF in bcmap');
       }
       last = !(b & 0x80);
       stack[sp++] = b & 0x7F;
      } while (!last);
      var i = size, buffer = 0, bufferSize = 0;
      while (i >= 0) {
       while (bufferSize < 8 && stack.length > 0) {
        buffer = stack[--sp] << bufferSize | buffer;
        bufferSize += 7;
       }
       num[i] = buffer & 255;
       i--;
       buffer >>= 8;
       bufferSize -= 8;
      }
     },
     readHexSigned: function (num, size) {
      this.readHexNumber(num, size);
      var sign = num[size] & 1 ? 255 : 0;
      var c = 0;
      for (var i = 0; i <= size; i++) {
       c = (c & 1) << 8 | num[i];
       num[i] = c >> 1 ^ sign;
      }
     },
     readString: function () {
      var len = this.readNumber();
      var s = '';
      for (var i = 0; i < len; i++) {
       s += String.fromCharCode(this.readNumber());
      }
      return s;
     }
    };
    function processBinaryCMap(url, cMap, extend) {
     return fetchBinaryData(url).then(function (data) {
      var stream = new BinaryCMapStream(data);
      var header = stream.readByte();
      cMap.vertical = !!(header & 1);
      var useCMap = null;
      var start = new Uint8Array(MAX_NUM_SIZE);
      var end = new Uint8Array(MAX_NUM_SIZE);
      var char = new Uint8Array(MAX_NUM_SIZE);
      var charCode = new Uint8Array(MAX_NUM_SIZE);
      var tmp = new Uint8Array(MAX_NUM_SIZE);
      var code;
      var b;
      while ((b = stream.readByte()) >= 0) {
       var type = b >> 5;
       if (type === 7) {
        switch (b & 0x1F) {
        case 0:
         stream.readString();
         break;
        case 1:
         useCMap = stream.readString();
         break;
        }
        continue;
       }
       var sequence = !!(b & 0x10);
       var dataSize = b & 15;
       assert(dataSize + 1 <= MAX_NUM_SIZE);
       var ucs2DataSize = 1;
       var subitemsCount = stream.readNumber();
       var i;
       switch (type) {
       case 0:
        stream.readHex(start, dataSize);
        stream.readHexNumber(end, dataSize);
        addHex(end, start, dataSize);
        cMap.addCodespaceRange(dataSize + 1, hexToInt(start, dataSize), hexToInt(end, dataSize));
        for (i = 1; i < subitemsCount; i++) {
         incHex(end, dataSize);
         stream.readHexNumber(start, dataSize);
         addHex(start, end, dataSize);
         stream.readHexNumber(end, dataSize);
         addHex(end, start, dataSize);
         cMap.addCodespaceRange(dataSize + 1, hexToInt(start, dataSize), hexToInt(end, dataSize));
        }
        break;
       case 1:
        stream.readHex(start, dataSize);
        stream.readHexNumber(end, dataSize);
        addHex(end, start, dataSize);
        code = stream.readNumber();
        for (i = 1; i < subitemsCount; i++) {
         incHex(end, dataSize);
         stream.readHexNumber(start, dataSize);
         addHex(start, end, dataSize);
         stream.readHexNumber(end, dataSize);
         addHex(end, start, dataSize);
         code = stream.readNumber();
        }
        break;
       case 2:
        stream.readHex(char, dataSize);
        code = stream.readNumber();
        cMap.mapOne(hexToInt(char, dataSize), code);
        for (i = 1; i < subitemsCount; i++) {
         incHex(char, dataSize);
         if (!sequence) {
          stream.readHexNumber(tmp, dataSize);
          addHex(char, tmp, dataSize);
         }
         code = stream.readSigned() + (code + 1);
         cMap.mapOne(hexToInt(char, dataSize), code);
        }
        break;
       case 3:
        stream.readHex(start, dataSize);
        stream.readHexNumber(end, dataSize);
        addHex(end, start, dataSize);
        code = stream.readNumber();
        cMap.mapCidRange(hexToInt(start, dataSize), hexToInt(end, dataSize), code);
        for (i = 1; i < subitemsCount; i++) {
         incHex(end, dataSize);
         if (!sequence) {
          stream.readHexNumber(start, dataSize);
          addHex(start, end, dataSize);
         } else {
          start.set(end);
         }
         stream.readHexNumber(end, dataSize);
         addHex(end, start, dataSize);
         code = stream.readNumber();
         cMap.mapCidRange(hexToInt(start, dataSize), hexToInt(end, dataSize), code);
        }
        break;
       case 4:
        stream.readHex(char, ucs2DataSize);
        stream.readHex(charCode, dataSize);
        cMap.mapOne(hexToInt(char, ucs2DataSize), hexToStr(charCode, dataSize));
        for (i = 1; i < subitemsCount; i++) {
         incHex(char, ucs2DataSize);
         if (!sequence) {
          stream.readHexNumber(tmp, ucs2DataSize);
          addHex(char, tmp, ucs2DataSize);
         }
         incHex(charCode, dataSize);
         stream.readHexSigned(tmp, dataSize);
         addHex(charCode, tmp, dataSize);
         cMap.mapOne(hexToInt(char, ucs2DataSize), hexToStr(charCode, dataSize));
        }
        break;
       case 5:
        stream.readHex(start, ucs2DataSize);
        stream.readHexNumber(end, ucs2DataSize);
        addHex(end, start, ucs2DataSize);
        stream.readHex(charCode, dataSize);
        cMap.mapBfRange(hexToInt(start, ucs2DataSize), hexToInt(end, ucs2DataSize), hexToStr(charCode, dataSize));
        for (i = 1; i < subitemsCount; i++) {
         incHex(end, ucs2DataSize);
         if (!sequence) {
          stream.readHexNumber(start, ucs2DataSize);
          addHex(start, end, ucs2DataSize);
         } else {
          start.set(end);
         }
         stream.readHexNumber(end, ucs2DataSize);
         addHex(end, start, ucs2DataSize);
         stream.readHex(charCode, dataSize);
         cMap.mapBfRange(hexToInt(start, ucs2DataSize), hexToInt(end, ucs2DataSize), hexToStr(charCode, dataSize));
        }
        break;
       default:
        error('Unknown type: ' + type);
        break;
       }
      }
      if (useCMap) {
       return extend(useCMap);
      }
      return cMap;
     });
    }
    function BinaryCMapReader() {
    }
    BinaryCMapReader.prototype = { read: processBinaryCMap };
    return BinaryCMapReader;
   }();
   var CMapFactory = function CMapFactoryClosure() {
    function strToInt(str) {
     var a = 0;
     for (var i = 0; i < str.length; i++) {
      a = a << 8 | str.charCodeAt(i);
     }
     return a >>> 0;
    }
    function expectString(obj) {
     if (!isString(obj)) {
      error('Malformed CMap: expected string.');
     }
    }
    function expectInt(obj) {
     if (!isInt(obj)) {
      error('Malformed CMap: expected int.');
     }
    }
    function parseBfChar(cMap, lexer) {
     while (true) {
      var obj = lexer.getObj();
      if (isEOF(obj)) {
       break;
      }
      if (isCmd(obj, 'endbfchar')) {
       return;
      }
      expectString(obj);
      var src = strToInt(obj);
      obj = lexer.getObj();
      expectString(obj);
      var dst = obj;
      cMap.mapOne(src, dst);
     }
    }
    function parseBfRange(cMap, lexer) {
     while (true) {
      var obj = lexer.getObj();
      if (isEOF(obj)) {
       break;
      }
      if (isCmd(obj, 'endbfrange')) {
       return;
      }
      expectString(obj);
      var low = strToInt(obj);
      obj = lexer.getObj();
      expectString(obj);
      var high = strToInt(obj);
      obj = lexer.getObj();
      if (isInt(obj) || isString(obj)) {
       var dstLow = isInt(obj) ? String.fromCharCode(obj) : obj;
       cMap.mapBfRange(low, high, dstLow);
      } else if (isCmd(obj, '[')) {
       obj = lexer.getObj();
       var array = [];
       while (!isCmd(obj, ']') && !isEOF(obj)) {
        array.push(obj);
        obj = lexer.getObj();
       }
       cMap.mapBfRangeToArray(low, high, array);
      } else {
       break;
      }
     }
     error('Invalid bf range.');
    }
    function parseCidChar(cMap, lexer) {
     while (true) {
      var obj = lexer.getObj();
      if (isEOF(obj)) {
       break;
      }
      if (isCmd(obj, 'endcidchar')) {
       return;
      }
      expectString(obj);
      var src = strToInt(obj);
      obj = lexer.getObj();
      expectInt(obj);
      var dst = obj;
      cMap.mapOne(src, dst);
     }
    }
    function parseCidRange(cMap, lexer) {
     while (true) {
      var obj = lexer.getObj();
      if (isEOF(obj)) {
       break;
      }
      if (isCmd(obj, 'endcidrange')) {
       return;
      }
      expectString(obj);
      var low = strToInt(obj);
      obj = lexer.getObj();
      expectString(obj);
      var high = strToInt(obj);
      obj = lexer.getObj();
      expectInt(obj);
      var dstLow = obj;
      cMap.mapCidRange(low, high, dstLow);
     }
    }
    function parseCodespaceRange(cMap, lexer) {
     while (true) {
      var obj = lexer.getObj();
      if (isEOF(obj)) {
       break;
      }
      if (isCmd(obj, 'endcodespacerange')) {
       return;
      }
      if (!isString(obj)) {
       break;
      }
      var low = strToInt(obj);
      obj = lexer.getObj();
      if (!isString(obj)) {
       break;
      }
      var high = strToInt(obj);
      cMap.addCodespaceRange(obj.length, low, high);
     }
     error('Invalid codespace range.');
    }
    function parseWMode(cMap, lexer) {
     var obj = lexer.getObj();
     if (isInt(obj)) {
      cMap.vertical = !!obj;
     }
    }
    function parseCMapName(cMap, lexer) {
     var obj = lexer.getObj();
     if (isName(obj) && isString(obj.name)) {
      cMap.name = obj.name;
     }
    }
    function parseCMap(cMap, lexer, builtInCMapParams, useCMap) {
     var previous;
     var embededUseCMap;
     objLoop:
      while (true) {
       try {
        var obj = lexer.getObj();
        if (isEOF(obj)) {
         break;
        } else if (isName(obj)) {
         if (obj.name === 'WMode') {
          parseWMode(cMap, lexer);
         } else if (obj.name === 'CMapName') {
          parseCMapName(cMap, lexer);
         }
         previous = obj;
        } else if (isCmd(obj)) {
         switch (obj.cmd) {
         case 'endcmap':
          break objLoop;
         case 'usecmap':
          if (isName(previous)) {
           embededUseCMap = previous.name;
          }
          break;
         case 'begincodespacerange':
          parseCodespaceRange(cMap, lexer);
          break;
         case 'beginbfchar':
          parseBfChar(cMap, lexer);
          break;
         case 'begincidchar':
          parseCidChar(cMap, lexer);
          break;
         case 'beginbfrange':
          parseBfRange(cMap, lexer);
          break;
         case 'begincidrange':
          parseCidRange(cMap, lexer);
          break;
         }
        }
       } catch (ex) {
        if (ex instanceof MissingDataException) {
         throw ex;
        }
        warn('Invalid cMap data: ' + ex);
        continue;
       }
      }
     if (!useCMap && embededUseCMap) {
      useCMap = embededUseCMap;
     }
     if (useCMap) {
      return extendCMap(cMap, builtInCMapParams, useCMap);
     }
     return Promise.resolve(cMap);
    }
    function extendCMap(cMap, builtInCMapParams, useCMap) {
     return createBuiltInCMap(useCMap, builtInCMapParams).then(function (newCMap) {
      cMap.useCMap = newCMap;
      if (cMap.numCodespaceRanges === 0) {
       var useCodespaceRanges = cMap.useCMap.codespaceRanges;
       for (var i = 0; i < useCodespaceRanges.length; i++) {
        cMap.codespaceRanges[i] = useCodespaceRanges[i].slice();
       }
       cMap.numCodespaceRanges = cMap.useCMap.numCodespaceRanges;
      }
      cMap.useCMap.forEach(function (key, value) {
       if (!cMap.contains(key)) {
        cMap.mapOne(key, cMap.useCMap.lookup(key));
       }
      });
      return cMap;
     });
    }
    function parseBinaryCMap(name, builtInCMapParams) {
     var url = builtInCMapParams.url + name + '.bcmap';
     var cMap = new CMap(true);
     return new BinaryCMapReader().read(url, cMap, function (useCMap) {
      return extendCMap(cMap, builtInCMapParams, useCMap);
     });
    }
    function createBuiltInCMap(name, builtInCMapParams) {
     if (name === 'Identity-H') {
      return Promise.resolve(new IdentityCMap(false, 2));
     } else if (name === 'Identity-V') {
      return Promise.resolve(new IdentityCMap(true, 2));
     }
     if (BUILT_IN_CMAPS.indexOf(name) === -1) {
      return Promise.reject(new Error('Unknown cMap name: ' + name));
     }
     assert(builtInCMapParams, 'built-in cMap parameters are not provided');
     if (builtInCMapParams.packed) {
      return parseBinaryCMap(name, builtInCMapParams);
     }
     return new Promise(function (resolve, reject) {
      var url = builtInCMapParams.url + name;
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
       if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status === 200 || request.status === 0) {
         var cMap = new CMap(true);
         var lexer = new Lexer(new StringStream(request.responseText));
         parseCMap(cMap, lexer, builtInCMapParams, null).then(function (parsedCMap) {
          resolve(parsedCMap);
         });
        } else {
         reject(new Error('Unable to get cMap at: ' + url));
        }
       }
      };
      request.open('GET', url, true);
      request.send(null);
     });
    }
    return {
     create: function (encoding, builtInCMapParams, useCMap) {
      if (isName(encoding)) {
       return createBuiltInCMap(encoding.name, builtInCMapParams);
      } else if (isStream(encoding)) {
       var cMap = new CMap();
       var lexer = new Lexer(encoding);
       return parseCMap(cMap, lexer, builtInCMapParams, useCMap).then(function (parsedCMap) {
        if (parsedCMap.isIdentityCMap) {
         return createBuiltInCMap(parsedCMap.name, builtInCMapParams);
        }
        return parsedCMap;
       });
      }
      return Promise.reject(new Error('Encoding required.'));
     }
    };
   }();
   exports.CMap = CMap;
   exports.CMapFactory = CMapFactory;
   exports.IdentityCMap = IdentityCMap;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreFonts = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreStream, root.pdfjsCoreGlyphList, root.pdfjsCoreFontRenderer, root.pdfjsCoreEncodings, root.pdfjsCoreStandardFonts, root.pdfjsCoreUnicode, root.pdfjsCoreType1Parser, root.pdfjsCoreCFFParser);
  }(this, function (exports, sharedUtil, corePrimitives, coreStream, coreGlyphList, coreFontRenderer, coreEncodings, coreStandardFonts, coreUnicode, coreType1Parser, coreCFFParser) {
   var FONT_IDENTITY_MATRIX = sharedUtil.FONT_IDENTITY_MATRIX;
   var FontType = sharedUtil.FontType;
   var assert = sharedUtil.assert;
   var bytesToString = sharedUtil.bytesToString;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var isInt = sharedUtil.isInt;
   var isNum = sharedUtil.isNum;
   var readUint32 = sharedUtil.readUint32;
   var shadow = sharedUtil.shadow;
   var string32 = sharedUtil.string32;
   var warn = sharedUtil.warn;
   var MissingDataException = sharedUtil.MissingDataException;
   var isSpace = sharedUtil.isSpace;
   var Stream = coreStream.Stream;
   var getGlyphsUnicode = coreGlyphList.getGlyphsUnicode;
   var getDingbatsGlyphsUnicode = coreGlyphList.getDingbatsGlyphsUnicode;
   var FontRendererFactory = coreFontRenderer.FontRendererFactory;
   var StandardEncoding = coreEncodings.StandardEncoding;
   var MacRomanEncoding = coreEncodings.MacRomanEncoding;
   var SymbolSetEncoding = coreEncodings.SymbolSetEncoding;
   var ZapfDingbatsEncoding = coreEncodings.ZapfDingbatsEncoding;
   var getEncoding = coreEncodings.getEncoding;
   var getStdFontMap = coreStandardFonts.getStdFontMap;
   var getNonStdFontMap = coreStandardFonts.getNonStdFontMap;
   var getGlyphMapForStandardFonts = coreStandardFonts.getGlyphMapForStandardFonts;
   var getSupplementalGlyphMapForArialBlack = coreStandardFonts.getSupplementalGlyphMapForArialBlack;
   var getUnicodeRangeFor = coreUnicode.getUnicodeRangeFor;
   var mapSpecialUnicodeValues = coreUnicode.mapSpecialUnicodeValues;
   var getUnicodeForGlyph = coreUnicode.getUnicodeForGlyph;
   var Type1Parser = coreType1Parser.Type1Parser;
   var CFFStandardStrings = coreCFFParser.CFFStandardStrings;
   var CFFParser = coreCFFParser.CFFParser;
   var CFFCompiler = coreCFFParser.CFFCompiler;
   var CFF = coreCFFParser.CFF;
   var CFFHeader = coreCFFParser.CFFHeader;
   var CFFTopDict = coreCFFParser.CFFTopDict;
   var CFFPrivateDict = coreCFFParser.CFFPrivateDict;
   var CFFStrings = coreCFFParser.CFFStrings;
   var CFFIndex = coreCFFParser.CFFIndex;
   var CFFCharset = coreCFFParser.CFFCharset;
   var PRIVATE_USE_OFFSET_START = 0xE000;
   var PRIVATE_USE_OFFSET_END = 0xF8FF;
   var SKIP_PRIVATE_USE_RANGE_F000_TO_F01F = false;
   var PDF_GLYPH_SPACE_UNITS = 1000;
   var SEAC_ANALYSIS_ENABLED = false;
   var FontFlags = {
    FixedPitch: 1,
    Serif: 2,
    Symbolic: 4,
    Script: 8,
    Nonsymbolic: 32,
    Italic: 64,
    AllCap: 65536,
    SmallCap: 131072,
    ForceBold: 262144
   };
   var MacStandardGlyphOrdering = [
    '.notdef',
    '.null',
    'nonmarkingreturn',
    'space',
    'exclam',
    'quotedbl',
    'numbersign',
    'dollar',
    'percent',
    'ampersand',
    'quotesingle',
    'parenleft',
    'parenright',
    'asterisk',
    'plus',
    'comma',
    'hyphen',
    'period',
    'slash',
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'colon',
    'semicolon',
    'less',
    'equal',
    'greater',
    'question',
    'at',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'bracketleft',
    'backslash',
    'bracketright',
    'asciicircum',
    'underscore',
    'grave',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'braceleft',
    'bar',
    'braceright',
    'asciitilde',
    'Adieresis',
    'Aring',
    'Ccedilla',
    'Eacute',
    'Ntilde',
    'Odieresis',
    'Udieresis',
    'aacute',
    'agrave',
    'acircumflex',
    'adieresis',
    'atilde',
    'aring',
    'ccedilla',
    'eacute',
    'egrave',
    'ecircumflex',
    'edieresis',
    'iacute',
    'igrave',
    'icircumflex',
    'idieresis',
    'ntilde',
    'oacute',
    'ograve',
    'ocircumflex',
    'odieresis',
    'otilde',
    'uacute',
    'ugrave',
    'ucircumflex',
    'udieresis',
    'dagger',
    'degree',
    'cent',
    'sterling',
    'section',
    'bullet',
    'paragraph',
    'germandbls',
    'registered',
    'copyright',
    'trademark',
    'acute',
    'dieresis',
    'notequal',
    'AE',
    'Oslash',
    'infinity',
    'plusminus',
    'lessequal',
    'greaterequal',
    'yen',
    'mu',
    'partialdiff',
    'summation',
    'product',
    'pi',
    'integral',
    'ordfeminine',
    'ordmasculine',
    'Omega',
    'ae',
    'oslash',
    'questiondown',
    'exclamdown',
    'logicalnot',
    'radical',
    'florin',
    'approxequal',
    'Delta',
    'guillemotleft',
    'guillemotright',
    'ellipsis',
    'nonbreakingspace',
    'Agrave',
    'Atilde',
    'Otilde',
    'OE',
    'oe',
    'endash',
    'emdash',
    'quotedblleft',
    'quotedblright',
    'quoteleft',
    'quoteright',
    'divide',
    'lozenge',
    'ydieresis',
    'Ydieresis',
    'fraction',
    'currency',
    'guilsinglleft',
    'guilsinglright',
    'fi',
    'fl',
    'daggerdbl',
    'periodcentered',
    'quotesinglbase',
    'quotedblbase',
    'perthousand',
    'Acircumflex',
    'Ecircumflex',
    'Aacute',
    'Edieresis',
    'Egrave',
    'Iacute',
    'Icircumflex',
    'Idieresis',
    'Igrave',
    'Oacute',
    'Ocircumflex',
    'apple',
    'Ograve',
    'Uacute',
    'Ucircumflex',
    'Ugrave',
    'dotlessi',
    'circumflex',
    'tilde',
    'macron',
    'breve',
    'dotaccent',
    'ring',
    'cedilla',
    'hungarumlaut',
    'ogonek',
    'caron',
    'Lslash',
    'lslash',
    'Scaron',
    'scaron',
    'Zcaron',
    'zcaron',
    'brokenbar',
    'Eth',
    'eth',
    'Yacute',
    'yacute',
    'Thorn',
    'thorn',
    'minus',
    'multiply',
    'onesuperior',
    'twosuperior',
    'threesuperior',
    'onehalf',
    'onequarter',
    'threequarters',
    'franc',
    'Gbreve',
    'gbreve',
    'Idotaccent',
    'Scedilla',
    'scedilla',
    'Cacute',
    'cacute',
    'Ccaron',
    'ccaron',
    'dcroat'
   ];
   function adjustWidths(properties) {
    if (!properties.fontMatrix) {
     return;
    }
    if (properties.fontMatrix[0] === FONT_IDENTITY_MATRIX[0]) {
     return;
    }
    var scale = 0.001 / properties.fontMatrix[0];
    var glyphsWidths = properties.widths;
    for (var glyph in glyphsWidths) {
     glyphsWidths[glyph] *= scale;
    }
    properties.defaultWidth *= scale;
   }
   function adjustToUnicode(properties, builtInEncoding) {
    if (properties.hasIncludedToUnicodeMap) {
     return;
    }
    if (properties.hasEncoding) {
     return;
    }
    if (builtInEncoding === properties.defaultEncoding) {
     return;
    }
    if (properties.toUnicode instanceof IdentityToUnicodeMap) {
     return;
    }
    var toUnicode = [], glyphsUnicodeMap = getGlyphsUnicode();
    for (var charCode in builtInEncoding) {
     var glyphName = builtInEncoding[charCode];
     var unicode = getUnicodeForGlyph(glyphName, glyphsUnicodeMap);
     if (unicode !== -1) {
      toUnicode[charCode] = String.fromCharCode(unicode);
     }
    }
    properties.toUnicode.amend(toUnicode);
   }
   function getFontType(type, subtype) {
    switch (type) {
    case 'Type1':
     return subtype === 'Type1C' ? FontType.TYPE1C : FontType.TYPE1;
    case 'CIDFontType0':
     return subtype === 'CIDFontType0C' ? FontType.CIDFONTTYPE0C : FontType.CIDFONTTYPE0;
    case 'OpenType':
     return FontType.OPENTYPE;
    case 'TrueType':
     return FontType.TRUETYPE;
    case 'CIDFontType2':
     return FontType.CIDFONTTYPE2;
    case 'MMType1':
     return FontType.MMTYPE1;
    case 'Type0':
     return FontType.TYPE0;
    default:
     return FontType.UNKNOWN;
    }
   }
   function recoverGlyphName(name, glyphsUnicodeMap) {
    if (glyphsUnicodeMap[name] !== undefined) {
     return name;
    }
    var unicode = getUnicodeForGlyph(name, glyphsUnicodeMap);
    if (unicode !== -1) {
     for (var key in glyphsUnicodeMap) {
      if (glyphsUnicodeMap[key] === unicode) {
       return key;
      }
     }
    }
    info('Unable to recover a standard glyph name for: ' + name);
    return name;
   }
   var Glyph = function GlyphClosure() {
    function Glyph(fontChar, unicode, accent, width, vmetric, operatorListId, isSpace, isInFont) {
     this.fontChar = fontChar;
     this.unicode = unicode;
     this.accent = accent;
     this.width = width;
     this.vmetric = vmetric;
     this.operatorListId = operatorListId;
     this.isSpace = isSpace;
     this.isInFont = isInFont;
    }
    Glyph.prototype.matchesForCache = function (fontChar, unicode, accent, width, vmetric, operatorListId, isSpace, isInFont) {
     return this.fontChar === fontChar && this.unicode === unicode && this.accent === accent && this.width === width && this.vmetric === vmetric && this.operatorListId === operatorListId && this.isSpace === isSpace && this.isInFont === isInFont;
    };
    return Glyph;
   }();
   var ToUnicodeMap = function ToUnicodeMapClosure() {
    function ToUnicodeMap(cmap) {
     this._map = cmap;
    }
    ToUnicodeMap.prototype = {
     get length() {
      return this._map.length;
     },
     forEach: function (callback) {
      for (var charCode in this._map) {
       callback(charCode, this._map[charCode].charCodeAt(0));
      }
     },
     has: function (i) {
      return this._map[i] !== undefined;
     },
     get: function (i) {
      return this._map[i];
     },
     charCodeOf: function (v) {
      return this._map.indexOf(v);
     },
     amend: function (map) {
      for (var charCode in map) {
       this._map[charCode] = map[charCode];
      }
     }
    };
    return ToUnicodeMap;
   }();
   var IdentityToUnicodeMap = function IdentityToUnicodeMapClosure() {
    function IdentityToUnicodeMap(firstChar, lastChar) {
     this.firstChar = firstChar;
     this.lastChar = lastChar;
    }
    IdentityToUnicodeMap.prototype = {
     get length() {
      return this.lastChar + 1 - this.firstChar;
     },
     forEach: function (callback) {
      for (var i = this.firstChar, ii = this.lastChar; i <= ii; i++) {
       callback(i, i);
      }
     },
     has: function (i) {
      return this.firstChar <= i && i <= this.lastChar;
     },
     get: function (i) {
      if (this.firstChar <= i && i <= this.lastChar) {
       return String.fromCharCode(i);
      }
      return undefined;
     },
     charCodeOf: function (v) {
      return isInt(v) && v >= this.firstChar && v <= this.lastChar ? v : -1;
     },
     amend: function (map) {
      error('Should not call amend()');
     }
    };
    return IdentityToUnicodeMap;
   }();
   var OpenTypeFileBuilder = function OpenTypeFileBuilderClosure() {
    function writeInt16(dest, offset, num) {
     dest[offset] = num >> 8 & 0xFF;
     dest[offset + 1] = num & 0xFF;
    }
    function writeInt32(dest, offset, num) {
     dest[offset] = num >> 24 & 0xFF;
     dest[offset + 1] = num >> 16 & 0xFF;
     dest[offset + 2] = num >> 8 & 0xFF;
     dest[offset + 3] = num & 0xFF;
    }
    function writeData(dest, offset, data) {
     var i, ii;
     if (data instanceof Uint8Array) {
      dest.set(data, offset);
     } else if (typeof data === 'string') {
      for (i = 0, ii = data.length; i < ii; i++) {
       dest[offset++] = data.charCodeAt(i) & 0xFF;
      }
     } else {
      for (i = 0, ii = data.length; i < ii; i++) {
       dest[offset++] = data[i] & 0xFF;
      }
     }
    }
    function OpenTypeFileBuilder(sfnt) {
     this.sfnt = sfnt;
     this.tables = Object.create(null);
    }
    OpenTypeFileBuilder.getSearchParams = function OpenTypeFileBuilder_getSearchParams(entriesCount, entrySize) {
     var maxPower2 = 1, log2 = 0;
     while ((maxPower2 ^ entriesCount) > maxPower2) {
      maxPower2 <<= 1;
      log2++;
     }
     var searchRange = maxPower2 * entrySize;
     return {
      range: searchRange,
      entry: log2,
      rangeShift: entrySize * entriesCount - searchRange
     };
    };
    var OTF_HEADER_SIZE = 12;
    var OTF_TABLE_ENTRY_SIZE = 16;
    OpenTypeFileBuilder.prototype = {
     toArray: function OpenTypeFileBuilder_toArray() {
      var sfnt = this.sfnt;
      var tables = this.tables;
      var tablesNames = Object.keys(tables);
      tablesNames.sort();
      var numTables = tablesNames.length;
      var i, j, jj, table, tableName;
      var offset = OTF_HEADER_SIZE + numTables * OTF_TABLE_ENTRY_SIZE;
      var tableOffsets = [offset];
      for (i = 0; i < numTables; i++) {
       table = tables[tablesNames[i]];
       var paddedLength = (table.length + 3 & ~3) >>> 0;
       offset += paddedLength;
       tableOffsets.push(offset);
      }
      var file = new Uint8Array(offset);
      for (i = 0; i < numTables; i++) {
       table = tables[tablesNames[i]];
       writeData(file, tableOffsets[i], table);
      }
      if (sfnt === 'true') {
       sfnt = string32(0x00010000);
      }
      file[0] = sfnt.charCodeAt(0) & 0xFF;
      file[1] = sfnt.charCodeAt(1) & 0xFF;
      file[2] = sfnt.charCodeAt(2) & 0xFF;
      file[3] = sfnt.charCodeAt(3) & 0xFF;
      writeInt16(file, 4, numTables);
      var searchParams = OpenTypeFileBuilder.getSearchParams(numTables, 16);
      writeInt16(file, 6, searchParams.range);
      writeInt16(file, 8, searchParams.entry);
      writeInt16(file, 10, searchParams.rangeShift);
      offset = OTF_HEADER_SIZE;
      for (i = 0; i < numTables; i++) {
       tableName = tablesNames[i];
       file[offset] = tableName.charCodeAt(0) & 0xFF;
       file[offset + 1] = tableName.charCodeAt(1) & 0xFF;
       file[offset + 2] = tableName.charCodeAt(2) & 0xFF;
       file[offset + 3] = tableName.charCodeAt(3) & 0xFF;
       var checksum = 0;
       for (j = tableOffsets[i], jj = tableOffsets[i + 1]; j < jj; j += 4) {
        var quad = readUint32(file, j);
        checksum = checksum + quad >>> 0;
       }
       writeInt32(file, offset + 4, checksum);
       writeInt32(file, offset + 8, tableOffsets[i]);
       writeInt32(file, offset + 12, tables[tableName].length);
       offset += OTF_TABLE_ENTRY_SIZE;
      }
      return file;
     },
     addTable: function OpenTypeFileBuilder_addTable(tag, data) {
      if (tag in this.tables) {
       throw new Error('Table ' + tag + ' already exists');
      }
      this.tables[tag] = data;
     }
    };
    return OpenTypeFileBuilder;
   }();
   var ProblematicCharRanges = new Int32Array([
    0x0000,
    0x0020,
    0x007F,
    0x00A1,
    0x00AD,
    0x00AE,
    0x0600,
    0x0780,
    0x08A0,
    0x10A0,
    0x1780,
    0x1800,
    0x1C00,
    0x1C50,
    0x2000,
    0x2010,
    0x2011,
    0x2012,
    0x2028,
    0x2030,
    0x205F,
    0x2070,
    0x25CC,
    0x25CD,
    0x3000,
    0x3001,
    0xAA60,
    0xAA80,
    0xFFF0,
    0x10000
   ]);
   var Font = function FontClosure() {
    function Font(name, file, properties) {
     var charCode, glyphName, unicode;
     this.name = name;
     this.loadedName = properties.loadedName;
     this.isType3Font = properties.isType3Font;
     this.sizes = [];
     this.missingFile = false;
     this.glyphCache = Object.create(null);
     var names = name.split('+');
     names = names.length > 1 ? names[1] : names[0];
     names = names.split(/[-,_]/g)[0];
     this.isSerifFont = !!(properties.flags & FontFlags.Serif);
     this.isSymbolicFont = !!(properties.flags & FontFlags.Symbolic);
     this.isMonospace = !!(properties.flags & FontFlags.FixedPitch);
     var type = properties.type;
     var subtype = properties.subtype;
     this.type = type;
     this.fallbackName = this.isMonospace ? 'monospace' : this.isSerifFont ? 'serif' : 'sans-serif';
     this.differences = properties.differences;
     this.widths = properties.widths;
     this.defaultWidth = properties.defaultWidth;
     this.composite = properties.composite;
     this.wideChars = properties.wideChars;
     this.cMap = properties.cMap;
     this.ascent = properties.ascent / PDF_GLYPH_SPACE_UNITS;
     this.descent = properties.descent / PDF_GLYPH_SPACE_UNITS;
     this.fontMatrix = properties.fontMatrix;
     this.bbox = properties.bbox;
     this.toUnicode = properties.toUnicode;
     this.toFontChar = [];
     if (properties.type === 'Type3') {
      for (charCode = 0; charCode < 256; charCode++) {
       this.toFontChar[charCode] = this.differences[charCode] || properties.defaultEncoding[charCode];
      }
      this.fontType = FontType.TYPE3;
      return;
     }
     this.cidEncoding = properties.cidEncoding;
     this.vertical = properties.vertical;
     if (this.vertical) {
      this.vmetrics = properties.vmetrics;
      this.defaultVMetrics = properties.defaultVMetrics;
     }
     var glyphsUnicodeMap;
     if (!file || file.isEmpty) {
      if (file) {
       warn('Font file is empty in "' + name + '" (' + this.loadedName + ')');
      }
      this.missingFile = true;
      var fontName = name.replace(/[,_]/g, '-');
      var stdFontMap = getStdFontMap(), nonStdFontMap = getNonStdFontMap();
      var isStandardFont = !!stdFontMap[fontName] || !!(nonStdFontMap[fontName] && stdFontMap[nonStdFontMap[fontName]]);
      fontName = stdFontMap[fontName] || nonStdFontMap[fontName] || fontName;
      this.bold = fontName.search(/bold/gi) !== -1;
      this.italic = fontName.search(/oblique/gi) !== -1 || fontName.search(/italic/gi) !== -1;
      this.black = name.search(/Black/g) !== -1;
      this.remeasure = Object.keys(this.widths).length > 0;
      if (isStandardFont && type === 'CIDFontType2' && properties.cidEncoding.indexOf('Identity-') === 0) {
       var GlyphMapForStandardFonts = getGlyphMapForStandardFonts();
       var map = [];
       for (charCode in GlyphMapForStandardFonts) {
        map[+charCode] = GlyphMapForStandardFonts[charCode];
       }
       if (/Arial-?Black/i.test(name)) {
        var SupplementalGlyphMapForArialBlack = getSupplementalGlyphMapForArialBlack();
        for (charCode in SupplementalGlyphMapForArialBlack) {
         map[+charCode] = SupplementalGlyphMapForArialBlack[charCode];
        }
       }
       var isIdentityUnicode = this.toUnicode instanceof IdentityToUnicodeMap;
       if (!isIdentityUnicode) {
        this.toUnicode.forEach(function (charCode, unicodeCharCode) {
         map[+charCode] = unicodeCharCode;
        });
       }
       this.toFontChar = map;
       this.toUnicode = new ToUnicodeMap(map);
      } else if (/Symbol/i.test(fontName)) {
       this.toFontChar = buildToFontChar(SymbolSetEncoding, getGlyphsUnicode(), properties.differences);
      } else if (/Dingbats/i.test(fontName)) {
       if (/Wingdings/i.test(name)) {
        warn('Non-embedded Wingdings font, falling back to ZapfDingbats.');
       }
       this.toFontChar = buildToFontChar(ZapfDingbatsEncoding, getDingbatsGlyphsUnicode(), properties.differences);
      } else if (isStandardFont) {
       this.toFontChar = buildToFontChar(properties.defaultEncoding, getGlyphsUnicode(), properties.differences);
      } else {
       glyphsUnicodeMap = getGlyphsUnicode();
       this.toUnicode.forEach(function (charCode, unicodeCharCode) {
        if (!this.composite) {
         glyphName = properties.differences[charCode] || properties.defaultEncoding[charCode];
         unicode = getUnicodeForGlyph(glyphName, glyphsUnicodeMap);
         if (unicode !== -1) {
          unicodeCharCode = unicode;
         }
        }
        this.toFontChar[charCode] = unicodeCharCode;
       }.bind(this));
      }
      this.loadedName = fontName.split('-')[0];
      this.loading = false;
      this.fontType = getFontType(type, subtype);
      return;
     }
     if (subtype === 'Type1C') {
      if (type !== 'Type1' && type !== 'MMType1') {
       if (isTrueTypeFile(file)) {
        subtype = 'TrueType';
       } else {
        type = 'Type1';
       }
      } else if (isOpenTypeFile(file)) {
       type = subtype = 'OpenType';
      }
     }
     if (subtype === 'CIDFontType0C' && type !== 'CIDFontType0') {
      type = 'CIDFontType0';
     }
     if (subtype === 'OpenType') {
      type = 'OpenType';
     }
     if (type === 'CIDFontType0') {
      if (isType1File(file)) {
       subtype = 'CIDFontType0';
      } else if (isOpenTypeFile(file)) {
       type = subtype = 'OpenType';
      } else {
       subtype = 'CIDFontType0C';
      }
     }
     var data;
     switch (type) {
     case 'MMType1':
      info('MMType1 font (' + name + '), falling back to Type1.');
     case 'Type1':
     case 'CIDFontType0':
      this.mimetype = 'font/opentype';
      var cff = subtype === 'Type1C' || subtype === 'CIDFontType0C' ? new CFFFont(file, properties) : new Type1Font(name, file, properties);
      adjustWidths(properties);
      data = this.convert(name, cff, properties);
      break;
     case 'OpenType':
     case 'TrueType':
     case 'CIDFontType2':
      this.mimetype = 'font/opentype';
      data = this.checkAndRepair(name, file, properties);
      if (this.isOpenType) {
       adjustWidths(properties);
       type = 'OpenType';
      }
      break;
     default:
      error('Font ' + type + ' is not supported');
      break;
     }
     this.data = data;
     this.fontType = getFontType(type, subtype);
     this.fontMatrix = properties.fontMatrix;
     this.widths = properties.widths;
     this.defaultWidth = properties.defaultWidth;
     this.toUnicode = properties.toUnicode;
     this.encoding = properties.baseEncoding;
     this.seacMap = properties.seacMap;
     this.loading = true;
    }
    Font.getFontID = function () {
     var ID = 1;
     return function Font_getFontID() {
      return String(ID++);
     };
    }();
    function int16(b0, b1) {
     return (b0 << 8) + b1;
    }
    function signedInt16(b0, b1) {
     var value = (b0 << 8) + b1;
     return value & 1 << 15 ? value - 0x10000 : value;
    }
    function int32(b0, b1, b2, b3) {
     return (b0 << 24) + (b1 << 16) + (b2 << 8) + b3;
    }
    function string16(value) {
     return String.fromCharCode(value >> 8 & 0xff, value & 0xff);
    }
    function safeString16(value) {
     value = value > 0x7FFF ? 0x7FFF : value < -0x8000 ? -0x8000 : value;
     return String.fromCharCode(value >> 8 & 0xff, value & 0xff);
    }
    function isTrueTypeFile(file) {
     var header = file.peekBytes(4);
     return readUint32(header, 0) === 0x00010000;
    }
    function isOpenTypeFile(file) {
     var header = file.peekBytes(4);
     return bytesToString(header) === 'OTTO';
    }
    function isType1File(file) {
     var header = file.peekBytes(2);
     if (header[0] === 0x25 && header[1] === 0x21) {
      return true;
     }
     if (header[0] === 0x80 && header[1] === 0x01) {
      return true;
     }
     return false;
    }
    function buildToFontChar(encoding, glyphsUnicodeMap, differences) {
     var toFontChar = [], unicode;
     for (var i = 0, ii = encoding.length; i < ii; i++) {
      unicode = getUnicodeForGlyph(encoding[i], glyphsUnicodeMap);
      if (unicode !== -1) {
       toFontChar[i] = unicode;
      }
     }
     for (var charCode in differences) {
      unicode = getUnicodeForGlyph(differences[charCode], glyphsUnicodeMap);
      if (unicode !== -1) {
       toFontChar[+charCode] = unicode;
      }
     }
     return toFontChar;
    }
    function isProblematicUnicodeLocation(code) {
     var i = 0, j = ProblematicCharRanges.length - 1;
     while (i < j) {
      var c = i + j + 1 >> 1;
      if (code < ProblematicCharRanges[c]) {
       j = c - 1;
      } else {
       i = c;
      }
     }
     return !(i & 1);
    }
    function adjustMapping(charCodeToGlyphId, properties) {
     var toUnicode = properties.toUnicode;
     var isSymbolic = !!(properties.flags & FontFlags.Symbolic);
     var isIdentityUnicode = properties.toUnicode instanceof IdentityToUnicodeMap;
     var newMap = Object.create(null);
     var toFontChar = [];
     var usedFontCharCodes = [];
     var nextAvailableFontCharCode = PRIVATE_USE_OFFSET_START;
     for (var originalCharCode in charCodeToGlyphId) {
      originalCharCode |= 0;
      var glyphId = charCodeToGlyphId[originalCharCode];
      var fontCharCode = originalCharCode;
      var hasUnicodeValue = false;
      if (!isIdentityUnicode && toUnicode.has(originalCharCode)) {
       hasUnicodeValue = true;
       var unicode = toUnicode.get(fontCharCode);
       if (unicode.length === 1) {
        fontCharCode = unicode.charCodeAt(0);
       }
      }
      if ((usedFontCharCodes[fontCharCode] !== undefined || isProblematicUnicodeLocation(fontCharCode) || isSymbolic && !hasUnicodeValue) && nextAvailableFontCharCode <= PRIVATE_USE_OFFSET_END) {
       do {
        fontCharCode = nextAvailableFontCharCode++;
        if (SKIP_PRIVATE_USE_RANGE_F000_TO_F01F && fontCharCode === 0xF000) {
         fontCharCode = 0xF020;
         nextAvailableFontCharCode = fontCharCode + 1;
        }
       } while (usedFontCharCodes[fontCharCode] !== undefined && nextAvailableFontCharCode <= PRIVATE_USE_OFFSET_END);
      }
      newMap[fontCharCode] = glyphId;
      toFontChar[originalCharCode] = fontCharCode;
      usedFontCharCodes[fontCharCode] = true;
     }
     return {
      toFontChar: toFontChar,
      charCodeToGlyphId: newMap,
      nextAvailableFontCharCode: nextAvailableFontCharCode
     };
    }
    function getRanges(glyphs, numGlyphs) {
     var codes = [];
     for (var charCode in glyphs) {
      if (glyphs[charCode] >= numGlyphs) {
       continue;
      }
      codes.push({
       fontCharCode: charCode | 0,
       glyphId: glyphs[charCode]
      });
     }
     codes.sort(function fontGetRangesSort(a, b) {
      return a.fontCharCode - b.fontCharCode;
     });
     var ranges = [];
     var length = codes.length;
     for (var n = 0; n < length;) {
      var start = codes[n].fontCharCode;
      var codeIndices = [codes[n].glyphId];
      ++n;
      var end = start;
      while (n < length && end + 1 === codes[n].fontCharCode) {
       codeIndices.push(codes[n].glyphId);
       ++end;
       ++n;
       if (end === 0xFFFF) {
        break;
       }
      }
      ranges.push([
       start,
       end,
       codeIndices
      ]);
     }
     return ranges;
    }
    function createCmapTable(glyphs, numGlyphs) {
     var ranges = getRanges(glyphs, numGlyphs);
     var numTables = ranges[ranges.length - 1][1] > 0xFFFF ? 2 : 1;
     var cmap = '\x00\x00' + string16(numTables) + '\x00\x03' + '\x00\x01' + string32(4 + numTables * 8);
     var i, ii, j, jj;
     for (i = ranges.length - 1; i >= 0; --i) {
      if (ranges[i][0] <= 0xFFFF) {
       break;
      }
     }
     var bmpLength = i + 1;
     if (ranges[i][0] < 0xFFFF && ranges[i][1] === 0xFFFF) {
      ranges[i][1] = 0xFFFE;
     }
     var trailingRangesCount = ranges[i][1] < 0xFFFF ? 1 : 0;
     var segCount = bmpLength + trailingRangesCount;
     var searchParams = OpenTypeFileBuilder.getSearchParams(segCount, 2);
     var startCount = '';
     var endCount = '';
     var idDeltas = '';
     var idRangeOffsets = '';
     var glyphsIds = '';
     var bias = 0;
     var range, start, end, codes;
     for (i = 0, ii = bmpLength; i < ii; i++) {
      range = ranges[i];
      start = range[0];
      end = range[1];
      startCount += string16(start);
      endCount += string16(end);
      codes = range[2];
      var contiguous = true;
      for (j = 1, jj = codes.length; j < jj; ++j) {
       if (codes[j] !== codes[j - 1] + 1) {
        contiguous = false;
        break;
       }
      }
      if (!contiguous) {
       var offset = (segCount - i) * 2 + bias * 2;
       bias += end - start + 1;
       idDeltas += string16(0);
       idRangeOffsets += string16(offset);
       for (j = 0, jj = codes.length; j < jj; ++j) {
        glyphsIds += string16(codes[j]);
       }
      } else {
       var startCode = codes[0];
       idDeltas += string16(startCode - start & 0xFFFF);
       idRangeOffsets += string16(0);
      }
     }
     if (trailingRangesCount > 0) {
      endCount += '\xFF\xFF';
      startCount += '\xFF\xFF';
      idDeltas += '\x00\x01';
      idRangeOffsets += '\x00\x00';
     }
     var format314 = '\x00\x00' + string16(2 * segCount) + string16(searchParams.range) + string16(searchParams.entry) + string16(searchParams.rangeShift) + endCount + '\x00\x00' + startCount + idDeltas + idRangeOffsets + glyphsIds;
     var format31012 = '';
     var header31012 = '';
     if (numTables > 1) {
      cmap += '\x00\x03' + '\x00\x0A' + string32(4 + numTables * 8 + 4 + format314.length);
      format31012 = '';
      for (i = 0, ii = ranges.length; i < ii; i++) {
       range = ranges[i];
       start = range[0];
       codes = range[2];
       var code = codes[0];
       for (j = 1, jj = codes.length; j < jj; ++j) {
        if (codes[j] !== codes[j - 1] + 1) {
         end = range[0] + j - 1;
         format31012 += string32(start) + string32(end) + string32(code);
         start = end + 1;
         code = codes[j];
        }
       }
       format31012 += string32(start) + string32(range[1]) + string32(code);
      }
      header31012 = '\x00\x0C' + '\x00\x00' + string32(format31012.length + 16) + '\x00\x00\x00\x00' + string32(format31012.length / 12);
     }
     return cmap + '\x00\x04' + string16(format314.length + 4) + format314 + header31012 + format31012;
    }
    function validateOS2Table(os2) {
     var stream = new Stream(os2.data);
     var version = stream.getUint16();
     stream.getBytes(60);
     var selection = stream.getUint16();
     if (version < 4 && selection & 0x0300) {
      return false;
     }
     var firstChar = stream.getUint16();
     var lastChar = stream.getUint16();
     if (firstChar > lastChar) {
      return false;
     }
     stream.getBytes(6);
     var usWinAscent = stream.getUint16();
     if (usWinAscent === 0) {
      return false;
     }
     os2.data[8] = os2.data[9] = 0;
     return true;
    }
    function createOS2Table(properties, charstrings, override) {
     override = override || {
      unitsPerEm: 0,
      yMax: 0,
      yMin: 0,
      ascent: 0,
      descent: 0
     };
     var ulUnicodeRange1 = 0;
     var ulUnicodeRange2 = 0;
     var ulUnicodeRange3 = 0;
     var ulUnicodeRange4 = 0;
     var firstCharIndex = null;
     var lastCharIndex = 0;
     if (charstrings) {
      for (var code in charstrings) {
       code |= 0;
       if (firstCharIndex > code || !firstCharIndex) {
        firstCharIndex = code;
       }
       if (lastCharIndex < code) {
        lastCharIndex = code;
       }
       var position = getUnicodeRangeFor(code);
       if (position < 32) {
        ulUnicodeRange1 |= 1 << position;
       } else if (position < 64) {
        ulUnicodeRange2 |= 1 << position - 32;
       } else if (position < 96) {
        ulUnicodeRange3 |= 1 << position - 64;
       } else if (position < 123) {
        ulUnicodeRange4 |= 1 << position - 96;
       } else {
        error('Unicode ranges Bits > 123 are reserved for internal usage');
       }
      }
     } else {
      firstCharIndex = 0;
      lastCharIndex = 255;
     }
     var bbox = properties.bbox || [
      0,
      0,
      0,
      0
     ];
     var unitsPerEm = override.unitsPerEm || 1 / (properties.fontMatrix || FONT_IDENTITY_MATRIX)[0];
     var scale = properties.ascentScaled ? 1.0 : unitsPerEm / PDF_GLYPH_SPACE_UNITS;
     var typoAscent = override.ascent || Math.round(scale * (properties.ascent || bbox[3]));
     var typoDescent = override.descent || Math.round(scale * (properties.descent || bbox[1]));
     if (typoDescent > 0 && properties.descent > 0 && bbox[1] < 0) {
      typoDescent = -typoDescent;
     }
     var winAscent = override.yMax || typoAscent;
     var winDescent = -override.yMin || -typoDescent;
     return '\x00\x03' + '\x02\x24' + '\x01\xF4' + '\x00\x05' + '\x00\x00' + '\x02\x8A' + '\x02\xBB' + '\x00\x00' + '\x00\x8C' + '\x02\x8A' + '\x02\xBB' + '\x00\x00' + '\x01\xDF' + '\x00\x31' + '\x01\x02' + '\x00\x00' + '\x00\x00\x06' + String.fromCharCode(properties.fixedPitch ? 0x09 : 0x00) + '\x00\x00\x00\x00\x00\x00' + string32(ulUnicodeRange1) + string32(ulUnicodeRange2) + string32(ulUnicodeRange3) + string32(ulUnicodeRange4) + '\x2A\x32\x31\x2A' + string16(properties.italicAngle ? 1 : 0) + string16(firstCharIndex || properties.firstChar) + string16(lastCharIndex || properties.lastChar) + string16(typoAscent) + string16(typoDescent) + '\x00\x64' + string16(winAscent) + string16(winDescent) + '\x00\x00\x00\x00' + '\x00\x00\x00\x00' + string16(properties.xHeight) + string16(properties.capHeight) + string16(0) + string16(firstCharIndex || properties.firstChar) + '\x00\x03';
    }
    function createPostTable(properties) {
     var angle = Math.floor(properties.italicAngle * Math.pow(2, 16));
     return '\x00\x03\x00\x00' + string32(angle) + '\x00\x00' + '\x00\x00' + string32(properties.fixedPitch) + '\x00\x00\x00\x00' + '\x00\x00\x00\x00' + '\x00\x00\x00\x00' + '\x00\x00\x00\x00';
    }
    function createNameTable(name, proto) {
     if (!proto) {
      proto = [
       [],
       []
      ];
     }
     var strings = [
      proto[0][0] || 'Original licence',
      proto[0][1] || name,
      proto[0][2] || 'Unknown',
      proto[0][3] || 'uniqueID',
      proto[0][4] || name,
      proto[0][5] || 'Version 0.11',
      proto[0][6] || '',
      proto[0][7] || 'Unknown',
      proto[0][8] || 'Unknown',
      proto[0][9] || 'Unknown'
     ];
     var stringsUnicode = [];
     var i, ii, j, jj, str;
     for (i = 0, ii = strings.length; i < ii; i++) {
      str = proto[1][i] || strings[i];
      var strBufUnicode = [];
      for (j = 0, jj = str.length; j < jj; j++) {
       strBufUnicode.push(string16(str.charCodeAt(j)));
      }
      stringsUnicode.push(strBufUnicode.join(''));
     }
     var names = [
      strings,
      stringsUnicode
     ];
     var platforms = [
      '\x00\x01',
      '\x00\x03'
     ];
     var encodings = [
      '\x00\x00',
      '\x00\x01'
     ];
     var languages = [
      '\x00\x00',
      '\x04\x09'
     ];
     var namesRecordCount = strings.length * platforms.length;
     var nameTable = '\x00\x00' + string16(namesRecordCount) + string16(namesRecordCount * 12 + 6);
     var strOffset = 0;
     for (i = 0, ii = platforms.length; i < ii; i++) {
      var strs = names[i];
      for (j = 0, jj = strs.length; j < jj; j++) {
       str = strs[j];
       var nameRecord = platforms[i] + encodings[i] + languages[i] + string16(j) + string16(str.length) + string16(strOffset);
       nameTable += nameRecord;
       strOffset += str.length;
      }
     }
     nameTable += strings.join('') + stringsUnicode.join('');
     return nameTable;
    }
    Font.prototype = {
     name: null,
     font: null,
     mimetype: null,
     encoding: null,
     get renderer() {
      var renderer = FontRendererFactory.create(this, SEAC_ANALYSIS_ENABLED);
      return shadow(this, 'renderer', renderer);
     },
     exportData: function Font_exportData() {
      var data = {};
      for (var i in this) {
       if (this.hasOwnProperty(i)) {
        data[i] = this[i];
       }
      }
      return data;
     },
     checkAndRepair: function Font_checkAndRepair(name, font, properties) {
      function readTableEntry(file) {
       var tag = bytesToString(file.getBytes(4));
       var checksum = file.getInt32() >>> 0;
       var offset = file.getInt32() >>> 0;
       var length = file.getInt32() >>> 0;
       var previousPosition = file.pos;
       file.pos = file.start ? file.start : 0;
       file.skip(offset);
       var data = file.getBytes(length);
       file.pos = previousPosition;
       if (tag === 'head') {
        data[8] = data[9] = data[10] = data[11] = 0;
        data[17] |= 0x20;
       }
       return {
        tag: tag,
        checksum: checksum,
        length: length,
        offset: offset,
        data: data
       };
      }
      function readOpenTypeHeader(ttf) {
       return {
        version: bytesToString(ttf.getBytes(4)),
        numTables: ttf.getUint16(),
        searchRange: ttf.getUint16(),
        entrySelector: ttf.getUint16(),
        rangeShift: ttf.getUint16()
       };
      }
      function readCmapTable(cmap, font, isSymbolicFont, hasEncoding) {
       if (!cmap) {
        warn('No cmap table available.');
        return {
         platformId: -1,
         encodingId: -1,
         mappings: [],
         hasShortCmap: false
        };
       }
       var segment;
       var start = (font.start ? font.start : 0) + cmap.offset;
       font.pos = start;
       var version = font.getUint16();
       var numTables = font.getUint16();
       var potentialTable;
       var canBreak = false;
       for (var i = 0; i < numTables; i++) {
        var platformId = font.getUint16();
        var encodingId = font.getUint16();
        var offset = font.getInt32() >>> 0;
        var useTable = false;
        if (platformId === 0 && encodingId === 0) {
         useTable = true;
        } else if (platformId === 1 && encodingId === 0) {
         useTable = true;
        } else if (platformId === 3 && encodingId === 1 && (!isSymbolicFont && hasEncoding || !potentialTable)) {
         useTable = true;
         if (!isSymbolicFont) {
          canBreak = true;
         }
        } else if (isSymbolicFont && platformId === 3 && encodingId === 0) {
         useTable = true;
         canBreak = true;
        }
        if (useTable) {
         potentialTable = {
          platformId: platformId,
          encodingId: encodingId,
          offset: offset
         };
        }
        if (canBreak) {
         break;
        }
       }
       if (potentialTable) {
        font.pos = start + potentialTable.offset;
       }
       if (!potentialTable || font.peekByte() === -1) {
        warn('Could not find a preferred cmap table.');
        return {
         platformId: -1,
         encodingId: -1,
         mappings: [],
         hasShortCmap: false
        };
       }
       var format = font.getUint16();
       var length = font.getUint16();
       var language = font.getUint16();
       var hasShortCmap = false;
       var mappings = [];
       var j, glyphId;
       if (format === 0) {
        for (j = 0; j < 256; j++) {
         var index = font.getByte();
         if (!index) {
          continue;
         }
         mappings.push({
          charCode: j,
          glyphId: index
         });
        }
        hasShortCmap = true;
       } else if (format === 4) {
        var segCount = font.getUint16() >> 1;
        font.getBytes(6);
        var segIndex, segments = [];
        for (segIndex = 0; segIndex < segCount; segIndex++) {
         segments.push({ end: font.getUint16() });
        }
        font.getUint16();
        for (segIndex = 0; segIndex < segCount; segIndex++) {
         segments[segIndex].start = font.getUint16();
        }
        for (segIndex = 0; segIndex < segCount; segIndex++) {
         segments[segIndex].delta = font.getUint16();
        }
        var offsetsCount = 0;
        for (segIndex = 0; segIndex < segCount; segIndex++) {
         segment = segments[segIndex];
         var rangeOffset = font.getUint16();
         if (!rangeOffset) {
          segment.offsetIndex = -1;
          continue;
         }
         var offsetIndex = (rangeOffset >> 1) - (segCount - segIndex);
         segment.offsetIndex = offsetIndex;
         offsetsCount = Math.max(offsetsCount, offsetIndex + segment.end - segment.start + 1);
        }
        var offsets = [];
        for (j = 0; j < offsetsCount; j++) {
         offsets.push(font.getUint16());
        }
        for (segIndex = 0; segIndex < segCount; segIndex++) {
         segment = segments[segIndex];
         start = segment.start;
         var end = segment.end;
         var delta = segment.delta;
         offsetIndex = segment.offsetIndex;
         for (j = start; j <= end; j++) {
          if (j === 0xFFFF) {
           continue;
          }
          glyphId = offsetIndex < 0 ? j : offsets[offsetIndex + j - start];
          glyphId = glyphId + delta & 0xFFFF;
          if (glyphId === 0) {
           continue;
          }
          mappings.push({
           charCode: j,
           glyphId: glyphId
          });
         }
        }
       } else if (format === 6) {
        var firstCode = font.getUint16();
        var entryCount = font.getUint16();
        for (j = 0; j < entryCount; j++) {
         glyphId = font.getUint16();
         var charCode = firstCode + j;
         mappings.push({
          charCode: charCode,
          glyphId: glyphId
         });
        }
       } else {
        warn('cmap table has unsupported format: ' + format);
        return {
         platformId: -1,
         encodingId: -1,
         mappings: [],
         hasShortCmap: false
        };
       }
       mappings.sort(function (a, b) {
        return a.charCode - b.charCode;
       });
       for (i = 1; i < mappings.length; i++) {
        if (mappings[i - 1].charCode === mappings[i].charCode) {
         mappings.splice(i, 1);
         i--;
        }
       }
       return {
        platformId: potentialTable.platformId,
        encodingId: potentialTable.encodingId,
        mappings: mappings,
        hasShortCmap: hasShortCmap
       };
      }
      function sanitizeMetrics(font, header, metrics, numGlyphs) {
       if (!header) {
        if (metrics) {
         metrics.data = null;
        }
        return;
       }
       font.pos = (font.start ? font.start : 0) + header.offset;
       font.pos += header.length - 2;
       var numOfMetrics = font.getUint16();
       if (numOfMetrics > numGlyphs) {
        info('The numOfMetrics (' + numOfMetrics + ') should not be ' + 'greater than the numGlyphs (' + numGlyphs + ')');
        numOfMetrics = numGlyphs;
        header.data[34] = (numOfMetrics & 0xff00) >> 8;
        header.data[35] = numOfMetrics & 0x00ff;
       }
       var numOfSidebearings = numGlyphs - numOfMetrics;
       var numMissing = numOfSidebearings - (metrics.length - numOfMetrics * 4 >> 1);
       if (numMissing > 0) {
        var entries = new Uint8Array(metrics.length + numMissing * 2);
        entries.set(metrics.data);
        metrics.data = entries;
       }
      }
      function sanitizeGlyph(source, sourceStart, sourceEnd, dest, destStart, hintsValid) {
       if (sourceEnd - sourceStart <= 12) {
        return 0;
       }
       var glyf = source.subarray(sourceStart, sourceEnd);
       var contoursCount = glyf[0] << 8 | glyf[1];
       if (contoursCount & 0x8000) {
        dest.set(glyf, destStart);
        return glyf.length;
       }
       var i, j = 10, flagsCount = 0;
       for (i = 0; i < contoursCount; i++) {
        var endPoint = glyf[j] << 8 | glyf[j + 1];
        flagsCount = endPoint + 1;
        j += 2;
       }
       var instructionsStart = j;
       var instructionsLength = glyf[j] << 8 | glyf[j + 1];
       j += 2 + instructionsLength;
       var instructionsEnd = j;
       var coordinatesLength = 0;
       for (i = 0; i < flagsCount; i++) {
        var flag = glyf[j++];
        if (flag & 0xC0) {
         glyf[j - 1] = flag & 0x3F;
        }
        var xyLength = (flag & 2 ? 1 : flag & 16 ? 0 : 2) + (flag & 4 ? 1 : flag & 32 ? 0 : 2);
        coordinatesLength += xyLength;
        if (flag & 8) {
         var repeat = glyf[j++];
         i += repeat;
         coordinatesLength += repeat * xyLength;
        }
       }
       if (coordinatesLength === 0) {
        return 0;
       }
       var glyphDataLength = j + coordinatesLength;
       if (glyphDataLength > glyf.length) {
        return 0;
       }
       if (!hintsValid && instructionsLength > 0) {
        dest.set(glyf.subarray(0, instructionsStart), destStart);
        dest.set([
         0,
         0
        ], destStart + instructionsStart);
        dest.set(glyf.subarray(instructionsEnd, glyphDataLength), destStart + instructionsStart + 2);
        glyphDataLength -= instructionsLength;
        if (glyf.length - glyphDataLength > 3) {
         glyphDataLength = glyphDataLength + 3 & ~3;
        }
        return glyphDataLength;
       }
       if (glyf.length - glyphDataLength > 3) {
        glyphDataLength = glyphDataLength + 3 & ~3;
        dest.set(glyf.subarray(0, glyphDataLength), destStart);
        return glyphDataLength;
       }
       dest.set(glyf, destStart);
       return glyf.length;
      }
      function sanitizeHead(head, numGlyphs, locaLength) {
       var data = head.data;
       var version = int32(data[0], data[1], data[2], data[3]);
       if (version >> 16 !== 1) {
        info('Attempting to fix invalid version in head table: ' + version);
        data[0] = 0;
        data[1] = 1;
        data[2] = 0;
        data[3] = 0;
       }
       var indexToLocFormat = int16(data[50], data[51]);
       if (indexToLocFormat < 0 || indexToLocFormat > 1) {
        info('Attempting to fix invalid indexToLocFormat in head table: ' + indexToLocFormat);
        var numGlyphsPlusOne = numGlyphs + 1;
        if (locaLength === numGlyphsPlusOne << 1) {
         data[50] = 0;
         data[51] = 0;
        } else if (locaLength === numGlyphsPlusOne << 2) {
         data[50] = 0;
         data[51] = 1;
        } else {
         warn('Could not fix indexToLocFormat: ' + indexToLocFormat);
        }
       }
      }
      function sanitizeGlyphLocations(loca, glyf, numGlyphs, isGlyphLocationsLong, hintsValid, dupFirstEntry) {
       var itemSize, itemDecode, itemEncode;
       if (isGlyphLocationsLong) {
        itemSize = 4;
        itemDecode = function fontItemDecodeLong(data, offset) {
         return data[offset] << 24 | data[offset + 1] << 16 | data[offset + 2] << 8 | data[offset + 3];
        };
        itemEncode = function fontItemEncodeLong(data, offset, value) {
         data[offset] = value >>> 24 & 0xFF;
         data[offset + 1] = value >> 16 & 0xFF;
         data[offset + 2] = value >> 8 & 0xFF;
         data[offset + 3] = value & 0xFF;
        };
       } else {
        itemSize = 2;
        itemDecode = function fontItemDecode(data, offset) {
         return data[offset] << 9 | data[offset + 1] << 1;
        };
        itemEncode = function fontItemEncode(data, offset, value) {
         data[offset] = value >> 9 & 0xFF;
         data[offset + 1] = value >> 1 & 0xFF;
        };
       }
       var locaData = loca.data;
       var locaDataSize = itemSize * (1 + numGlyphs);
       if (locaData.length !== locaDataSize) {
        locaData = new Uint8Array(locaDataSize);
        locaData.set(loca.data.subarray(0, locaDataSize));
        loca.data = locaData;
       }
       var oldGlyfData = glyf.data;
       var oldGlyfDataLength = oldGlyfData.length;
       var newGlyfData = new Uint8Array(oldGlyfDataLength);
       var startOffset = itemDecode(locaData, 0);
       var writeOffset = 0;
       var missingGlyphData = Object.create(null);
       itemEncode(locaData, 0, writeOffset);
       var i, j;
       for (i = 0, j = itemSize; i < numGlyphs; i++, j += itemSize) {
        var endOffset = itemDecode(locaData, j);
        if (endOffset > oldGlyfDataLength && (oldGlyfDataLength + 3 & ~3) === endOffset) {
         endOffset = oldGlyfDataLength;
        }
        if (endOffset > oldGlyfDataLength) {
         itemEncode(locaData, j, writeOffset);
         startOffset = endOffset;
         continue;
        }
        if (startOffset === endOffset) {
         missingGlyphData[i] = true;
        }
        var newLength = sanitizeGlyph(oldGlyfData, startOffset, endOffset, newGlyfData, writeOffset, hintsValid);
        writeOffset += newLength;
        itemEncode(locaData, j, writeOffset);
        startOffset = endOffset;
       }
       if (writeOffset === 0) {
        var simpleGlyph = new Uint8Array([
         0,
         1,
         0,
         0,
         0,
         0,
         0,
         0,
         0,
         0,
         0,
         0,
         0,
         0,
         49,
         0
        ]);
        for (i = 0, j = itemSize; i < numGlyphs; i++, j += itemSize) {
         itemEncode(locaData, j, simpleGlyph.length);
        }
        glyf.data = simpleGlyph;
        return missingGlyphData;
       }
       if (dupFirstEntry) {
        var firstEntryLength = itemDecode(locaData, itemSize);
        if (newGlyfData.length > firstEntryLength + writeOffset) {
         glyf.data = newGlyfData.subarray(0, firstEntryLength + writeOffset);
        } else {
         glyf.data = new Uint8Array(firstEntryLength + writeOffset);
         glyf.data.set(newGlyfData.subarray(0, writeOffset));
        }
        glyf.data.set(newGlyfData.subarray(0, firstEntryLength), writeOffset);
        itemEncode(loca.data, locaData.length - itemSize, writeOffset + firstEntryLength);
       } else {
        glyf.data = newGlyfData.subarray(0, writeOffset);
       }
       return missingGlyphData;
      }
      function readPostScriptTable(post, properties, maxpNumGlyphs) {
       var start = (font.start ? font.start : 0) + post.offset;
       font.pos = start;
       var length = post.length, end = start + length;
       var version = font.getInt32();
       font.getBytes(28);
       var glyphNames;
       var valid = true;
       var i;
       switch (version) {
       case 0x00010000:
        glyphNames = MacStandardGlyphOrdering;
        break;
       case 0x00020000:
        var numGlyphs = font.getUint16();
        if (numGlyphs !== maxpNumGlyphs) {
         valid = false;
         break;
        }
        var glyphNameIndexes = [];
        for (i = 0; i < numGlyphs; ++i) {
         var index = font.getUint16();
         if (index >= 32768) {
          valid = false;
          break;
         }
         glyphNameIndexes.push(index);
        }
        if (!valid) {
         break;
        }
        var customNames = [];
        var strBuf = [];
        while (font.pos < end) {
         var stringLength = font.getByte();
         strBuf.length = stringLength;
         for (i = 0; i < stringLength; ++i) {
          strBuf[i] = String.fromCharCode(font.getByte());
         }
         customNames.push(strBuf.join(''));
        }
        glyphNames = [];
        for (i = 0; i < numGlyphs; ++i) {
         var j = glyphNameIndexes[i];
         if (j < 258) {
          glyphNames.push(MacStandardGlyphOrdering[j]);
          continue;
         }
         glyphNames.push(customNames[j - 258]);
        }
        break;
       case 0x00030000:
        break;
       default:
        warn('Unknown/unsupported post table version ' + version);
        valid = false;
        if (properties.defaultEncoding) {
         glyphNames = properties.defaultEncoding;
        }
        break;
       }
       properties.glyphNames = glyphNames;
       return valid;
      }
      function readNameTable(nameTable) {
       var start = (font.start ? font.start : 0) + nameTable.offset;
       font.pos = start;
       var names = [
        [],
        []
       ];
       var length = nameTable.length, end = start + length;
       var format = font.getUint16();
       var FORMAT_0_HEADER_LENGTH = 6;
       if (format !== 0 || length < FORMAT_0_HEADER_LENGTH) {
        return names;
       }
       var numRecords = font.getUint16();
       var stringsStart = font.getUint16();
       var records = [];
       var NAME_RECORD_LENGTH = 12;
       var i, ii;
       for (i = 0; i < numRecords && font.pos + NAME_RECORD_LENGTH <= end; i++) {
        var r = {
         platform: font.getUint16(),
         encoding: font.getUint16(),
         language: font.getUint16(),
         name: font.getUint16(),
         length: font.getUint16(),
         offset: font.getUint16()
        };
        if (r.platform === 1 && r.encoding === 0 && r.language === 0 || r.platform === 3 && r.encoding === 1 && r.language === 0x409) {
         records.push(r);
        }
       }
       for (i = 0, ii = records.length; i < ii; i++) {
        var record = records[i];
        if (record.length <= 0) {
         continue;
        }
        var pos = start + stringsStart + record.offset;
        if (pos + record.length > end) {
         continue;
        }
        font.pos = pos;
        var nameIndex = record.name;
        if (record.encoding) {
         var str = '';
         for (var j = 0, jj = record.length; j < jj; j += 2) {
          str += String.fromCharCode(font.getUint16());
         }
         names[1][nameIndex] = str;
        } else {
         names[0][nameIndex] = bytesToString(font.getBytes(record.length));
        }
       }
       return names;
      }
      var TTOpsStackDeltas = [
       0,
       0,
       0,
       0,
       0,
       0,
       0,
       0,
       -2,
       -2,
       -2,
       -2,
       0,
       0,
       -2,
       -5,
       -1,
       -1,
       -1,
       -1,
       -1,
       -1,
       -1,
       -1,
       0,
       0,
       -1,
       0,
       -1,
       -1,
       -1,
       -1,
       1,
       -1,
       -999,
       0,
       1,
       0,
       -1,
       -2,
       0,
       -1,
       -2,
       -1,
       -1,
       0,
       -1,
       -1,
       0,
       0,
       -999,
       -999,
       -1,
       -1,
       -1,
       -1,
       -2,
       -999,
       -2,
       -2,
       -999,
       0,
       -2,
       -2,
       0,
       0,
       -2,
       0,
       -2,
       0,
       0,
       0,
       -2,
       -1,
       -1,
       1,
       1,
       0,
       0,
       -1,
       -1,
       -1,
       -1,
       -1,
       -1,
       -1,
       0,
       0,
       -1,
       0,
       -1,
       -1,
       0,
       -999,
       -1,
       -1,
       -1,
       -1,
       -1,
       -1,
       0,
       0,
       0,
       0,
       0,
       0,
       0,
       0,
       0,
       0,
       0,
       0,
       -2,
       -999,
       -999,
       -999,
       -999,
       -999,
       -1,
       -1,
       -2,
       -2,
       0,
       0,
       0,
       0,
       -1,
       -1,
       -999,
       -2,
       -2,
       0,
       0,
       -1,
       -2,
       -2,
       0,
       0,
       0,
       -1,
       -1,
       -1,
       -2
      ];
      function sanitizeTTProgram(table, ttContext) {
       var data = table.data;
       var i = 0, j, n, b, funcId, pc, lastEndf = 0, lastDeff = 0;
       var stack = [];
       var callstack = [];
       var functionsCalled = [];
       var tooComplexToFollowFunctions = ttContext.tooComplexToFollowFunctions;
       var inFDEF = false, ifLevel = 0, inELSE = 0;
       for (var ii = data.length; i < ii;) {
        var op = data[i++];
        if (op === 0x40) {
         n = data[i++];
         if (inFDEF || inELSE) {
          i += n;
         } else {
          for (j = 0; j < n; j++) {
           stack.push(data[i++]);
          }
         }
        } else if (op === 0x41) {
         n = data[i++];
         if (inFDEF || inELSE) {
          i += n * 2;
         } else {
          for (j = 0; j < n; j++) {
           b = data[i++];
           stack.push(b << 8 | data[i++]);
          }
         }
        } else if ((op & 0xF8) === 0xB0) {
         n = op - 0xB0 + 1;
         if (inFDEF || inELSE) {
          i += n;
         } else {
          for (j = 0; j < n; j++) {
           stack.push(data[i++]);
          }
         }
        } else if ((op & 0xF8) === 0xB8) {
         n = op - 0xB8 + 1;
         if (inFDEF || inELSE) {
          i += n * 2;
         } else {
          for (j = 0; j < n; j++) {
           b = data[i++];
           stack.push(b << 8 | data[i++]);
          }
         }
        } else if (op === 0x2B && !tooComplexToFollowFunctions) {
         if (!inFDEF && !inELSE) {
          funcId = stack[stack.length - 1];
          ttContext.functionsUsed[funcId] = true;
          if (funcId in ttContext.functionsStackDeltas) {
           stack.length += ttContext.functionsStackDeltas[funcId];
          } else if (funcId in ttContext.functionsDefined && functionsCalled.indexOf(funcId) < 0) {
           callstack.push({
            data: data,
            i: i,
            stackTop: stack.length - 1
           });
           functionsCalled.push(funcId);
           pc = ttContext.functionsDefined[funcId];
           if (!pc) {
            warn('TT: CALL non-existent function');
            ttContext.hintsValid = false;
            return;
           }
           data = pc.data;
           i = pc.i;
          }
         }
        } else if (op === 0x2C && !tooComplexToFollowFunctions) {
         if (inFDEF || inELSE) {
          warn('TT: nested FDEFs not allowed');
          tooComplexToFollowFunctions = true;
         }
         inFDEF = true;
         lastDeff = i;
         funcId = stack.pop();
         ttContext.functionsDefined[funcId] = {
          data: data,
          i: i
         };
        } else if (op === 0x2D) {
         if (inFDEF) {
          inFDEF = false;
          lastEndf = i;
         } else {
          pc = callstack.pop();
          if (!pc) {
           warn('TT: ENDF bad stack');
           ttContext.hintsValid = false;
           return;
          }
          funcId = functionsCalled.pop();
          data = pc.data;
          i = pc.i;
          ttContext.functionsStackDeltas[funcId] = stack.length - pc.stackTop;
         }
        } else if (op === 0x89) {
         if (inFDEF || inELSE) {
          warn('TT: nested IDEFs not allowed');
          tooComplexToFollowFunctions = true;
         }
         inFDEF = true;
         lastDeff = i;
        } else if (op === 0x58) {
         ++ifLevel;
        } else if (op === 0x1B) {
         inELSE = ifLevel;
        } else if (op === 0x59) {
         if (inELSE === ifLevel) {
          inELSE = 0;
         }
         --ifLevel;
        } else if (op === 0x1C) {
         if (!inFDEF && !inELSE) {
          var offset = stack[stack.length - 1];
          if (offset > 0) {
           i += offset - 1;
          }
         }
        }
        if (!inFDEF && !inELSE) {
         var stackDelta = op <= 0x8E ? TTOpsStackDeltas[op] : op >= 0xC0 && op <= 0xDF ? -1 : op >= 0xE0 ? -2 : 0;
         if (op >= 0x71 && op <= 0x75) {
          n = stack.pop();
          if (n === n) {
           stackDelta = -n * 2;
          }
         }
         while (stackDelta < 0 && stack.length > 0) {
          stack.pop();
          stackDelta++;
         }
         while (stackDelta > 0) {
          stack.push(NaN);
          stackDelta--;
         }
        }
       }
       ttContext.tooComplexToFollowFunctions = tooComplexToFollowFunctions;
       var content = [data];
       if (i > data.length) {
        content.push(new Uint8Array(i - data.length));
       }
       if (lastDeff > lastEndf) {
        warn('TT: complementing a missing function tail');
        content.push(new Uint8Array([
         0x22,
         0x2D
        ]));
       }
       foldTTTable(table, content);
      }
      function checkInvalidFunctions(ttContext, maxFunctionDefs) {
       if (ttContext.tooComplexToFollowFunctions) {
        return;
       }
       if (ttContext.functionsDefined.length > maxFunctionDefs) {
        warn('TT: more functions defined than expected');
        ttContext.hintsValid = false;
        return;
       }
       for (var j = 0, jj = ttContext.functionsUsed.length; j < jj; j++) {
        if (j > maxFunctionDefs) {
         warn('TT: invalid function id: ' + j);
         ttContext.hintsValid = false;
         return;
        }
        if (ttContext.functionsUsed[j] && !ttContext.functionsDefined[j]) {
         warn('TT: undefined function: ' + j);
         ttContext.hintsValid = false;
         return;
        }
       }
      }
      function foldTTTable(table, content) {
       if (content.length > 1) {
        var newLength = 0;
        var j, jj;
        for (j = 0, jj = content.length; j < jj; j++) {
         newLength += content[j].length;
        }
        newLength = newLength + 3 & ~3;
        var result = new Uint8Array(newLength);
        var pos = 0;
        for (j = 0, jj = content.length; j < jj; j++) {
         result.set(content[j], pos);
         pos += content[j].length;
        }
        table.data = result;
        table.length = newLength;
       }
      }
      function sanitizeTTPrograms(fpgm, prep, cvt, maxFunctionDefs) {
       var ttContext = {
        functionsDefined: [],
        functionsUsed: [],
        functionsStackDeltas: [],
        tooComplexToFollowFunctions: false,
        hintsValid: true
       };
       if (fpgm) {
        sanitizeTTProgram(fpgm, ttContext);
       }
       if (prep) {
        sanitizeTTProgram(prep, ttContext);
       }
       if (fpgm) {
        checkInvalidFunctions(ttContext, maxFunctionDefs);
       }
       if (cvt && cvt.length & 1) {
        var cvtData = new Uint8Array(cvt.length + 1);
        cvtData.set(cvt.data);
        cvt.data = cvtData;
       }
       return ttContext.hintsValid;
      }
      font = new Stream(new Uint8Array(font.getBytes()));
      var VALID_TABLES = [
       'OS/2',
       'cmap',
       'head',
       'hhea',
       'hmtx',
       'maxp',
       'name',
       'post',
       'loca',
       'glyf',
       'fpgm',
       'prep',
       'cvt ',
       'CFF '
      ];
      var header = readOpenTypeHeader(font);
      var numTables = header.numTables;
      var cff, cffFile;
      var tables = Object.create(null);
      tables['OS/2'] = null;
      tables['cmap'] = null;
      tables['head'] = null;
      tables['hhea'] = null;
      tables['hmtx'] = null;
      tables['maxp'] = null;
      tables['name'] = null;
      tables['post'] = null;
      var table;
      for (var i = 0; i < numTables; i++) {
       table = readTableEntry(font);
       if (VALID_TABLES.indexOf(table.tag) < 0) {
        continue;
       }
       if (table.length === 0) {
        continue;
       }
       tables[table.tag] = table;
      }
      var isTrueType = !tables['CFF '];
      if (!isTrueType) {
       if (header.version === 'OTTO' && properties.type !== 'CIDFontType2' || !tables['head'] || !tables['hhea'] || !tables['maxp'] || !tables['post']) {
        cffFile = new Stream(tables['CFF '].data);
        cff = new CFFFont(cffFile, properties);
        adjustWidths(properties);
        return this.convert(name, cff, properties);
       }
       delete tables['glyf'];
       delete tables['loca'];
       delete tables['fpgm'];
       delete tables['prep'];
       delete tables['cvt '];
       this.isOpenType = true;
      } else {
       if (!tables['loca']) {
        error('Required "loca" table is not found');
       }
       if (!tables['glyf']) {
        warn('Required "glyf" table is not found -- trying to recover.');
        tables['glyf'] = {
         tag: 'glyf',
         data: new Uint8Array(0)
        };
       }
       this.isOpenType = false;
      }
      if (!tables['maxp']) {
       error('Required "maxp" table is not found');
      }
      font.pos = (font.start || 0) + tables['maxp'].offset;
      var version = font.getInt32();
      var numGlyphs = font.getUint16();
      var maxFunctionDefs = 0;
      if (version >= 0x00010000 && tables['maxp'].length >= 22) {
       font.pos += 8;
       var maxZones = font.getUint16();
       if (maxZones > 2) {
        tables['maxp'].data[14] = 0;
        tables['maxp'].data[15] = 2;
       }
       font.pos += 4;
       maxFunctionDefs = font.getUint16();
      }
      var dupFirstEntry = false;
      if (properties.type === 'CIDFontType2' && properties.toUnicode && properties.toUnicode.get(0) > '\u0000') {
       dupFirstEntry = true;
       numGlyphs++;
       tables['maxp'].data[4] = numGlyphs >> 8;
       tables['maxp'].data[5] = numGlyphs & 255;
      }
      var hintsValid = sanitizeTTPrograms(tables['fpgm'], tables['prep'], tables['cvt '], maxFunctionDefs);
      if (!hintsValid) {
       delete tables['fpgm'];
       delete tables['prep'];
       delete tables['cvt '];
      }
      sanitizeMetrics(font, tables['hhea'], tables['hmtx'], numGlyphs);
      if (!tables['head']) {
       error('Required "head" table is not found');
      }
      sanitizeHead(tables['head'], numGlyphs, isTrueType ? tables['loca'].length : 0);
      var missingGlyphs = Object.create(null);
      if (isTrueType) {
       var isGlyphLocationsLong = int16(tables['head'].data[50], tables['head'].data[51]);
       missingGlyphs = sanitizeGlyphLocations(tables['loca'], tables['glyf'], numGlyphs, isGlyphLocationsLong, hintsValid, dupFirstEntry);
      }
      if (!tables['hhea']) {
       error('Required "hhea" table is not found');
      }
      if (tables['hhea'].data[10] === 0 && tables['hhea'].data[11] === 0) {
       tables['hhea'].data[10] = 0xFF;
       tables['hhea'].data[11] = 0xFF;
      }
      var metricsOverride = {
       unitsPerEm: int16(tables['head'].data[18], tables['head'].data[19]),
       yMax: int16(tables['head'].data[42], tables['head'].data[43]),
       yMin: signedInt16(tables['head'].data[38], tables['head'].data[39]),
       ascent: int16(tables['hhea'].data[4], tables['hhea'].data[5]),
       descent: signedInt16(tables['hhea'].data[6], tables['hhea'].data[7])
      };
      this.ascent = metricsOverride.ascent / metricsOverride.unitsPerEm;
      this.descent = metricsOverride.descent / metricsOverride.unitsPerEm;
      if (tables['post']) {
       var valid = readPostScriptTable(tables['post'], properties, numGlyphs);
       if (!valid) {
        tables['post'] = null;
       }
      }
      var charCodeToGlyphId = [], charCode;
      var toUnicode = properties.toUnicode, widths = properties.widths;
      var skipToUnicode = toUnicode instanceof IdentityToUnicodeMap || toUnicode.length === 0x10000;
      function hasGlyph(glyphId, charCode, widthCode) {
       if (!missingGlyphs[glyphId]) {
        return true;
       }
       if (!skipToUnicode && charCode >= 0 && toUnicode.has(charCode)) {
        return true;
       }
       if (widths && widthCode >= 0 && isNum(widths[widthCode])) {
        return true;
       }
       return false;
      }
      if (properties.type === 'CIDFontType2') {
       var cidToGidMap = properties.cidToGidMap || [];
       var isCidToGidMapEmpty = cidToGidMap.length === 0;
       properties.cMap.forEach(function (charCode, cid) {
        assert(cid <= 0xffff, 'Max size of CID is 65,535');
        var glyphId = -1;
        if (isCidToGidMapEmpty) {
         glyphId = cid;
        } else if (cidToGidMap[cid] !== undefined) {
         glyphId = cidToGidMap[cid];
        }
        if (glyphId >= 0 && glyphId < numGlyphs && hasGlyph(glyphId, charCode, cid)) {
         charCodeToGlyphId[charCode] = glyphId;
        }
       });
       if (dupFirstEntry && (isCidToGidMapEmpty || !charCodeToGlyphId[0])) {
        charCodeToGlyphId[0] = numGlyphs - 1;
       }
      } else {
       var cmapTable = readCmapTable(tables['cmap'], font, this.isSymbolicFont, properties.hasEncoding);
       var cmapPlatformId = cmapTable.platformId;
       var cmapEncodingId = cmapTable.encodingId;
       var cmapMappings = cmapTable.mappings;
       var cmapMappingsLength = cmapMappings.length;
       if (properties.hasEncoding && (cmapPlatformId === 3 && cmapEncodingId === 1 || cmapPlatformId === 1 && cmapEncodingId === 0) || cmapPlatformId === -1 && cmapEncodingId === -1 && !!getEncoding(properties.baseEncodingName)) {
        var baseEncoding = [];
        if (properties.baseEncodingName === 'MacRomanEncoding' || properties.baseEncodingName === 'WinAnsiEncoding') {
         baseEncoding = getEncoding(properties.baseEncodingName);
        }
        var glyphsUnicodeMap = getGlyphsUnicode();
        for (charCode = 0; charCode < 256; charCode++) {
         var glyphName, standardGlyphName;
         if (this.differences && charCode in this.differences) {
          glyphName = this.differences[charCode];
         } else if (charCode in baseEncoding && baseEncoding[charCode] !== '') {
          glyphName = baseEncoding[charCode];
         } else {
          glyphName = StandardEncoding[charCode];
         }
         if (!glyphName) {
          continue;
         }
         standardGlyphName = recoverGlyphName(glyphName, glyphsUnicodeMap);
         var unicodeOrCharCode, isUnicode = false;
         if (cmapPlatformId === 3 && cmapEncodingId === 1) {
          unicodeOrCharCode = glyphsUnicodeMap[standardGlyphName];
          isUnicode = true;
         } else if (cmapPlatformId === 1 && cmapEncodingId === 0) {
          unicodeOrCharCode = MacRomanEncoding.indexOf(standardGlyphName);
         }
         var found = false;
         for (i = 0; i < cmapMappingsLength; ++i) {
          if (cmapMappings[i].charCode !== unicodeOrCharCode) {
           continue;
          }
          var code = isUnicode ? charCode : unicodeOrCharCode;
          if (hasGlyph(cmapMappings[i].glyphId, code, -1)) {
           charCodeToGlyphId[charCode] = cmapMappings[i].glyphId;
           found = true;
           break;
          }
         }
         if (!found && properties.glyphNames) {
          var glyphId = properties.glyphNames.indexOf(glyphName);
          if (glyphId === -1 && standardGlyphName !== glyphName) {
           glyphId = properties.glyphNames.indexOf(standardGlyphName);
          }
          if (glyphId > 0 && hasGlyph(glyphId, -1, -1)) {
           charCodeToGlyphId[charCode] = glyphId;
           found = true;
          }
         }
         if (!found) {
          charCodeToGlyphId[charCode] = 0;
         }
        }
       } else if (cmapPlatformId === 0 && cmapEncodingId === 0) {
        for (i = 0; i < cmapMappingsLength; ++i) {
         charCodeToGlyphId[cmapMappings[i].charCode] = cmapMappings[i].glyphId;
        }
       } else {
        for (i = 0; i < cmapMappingsLength; ++i) {
         charCode = cmapMappings[i].charCode & 0xFF;
         charCodeToGlyphId[charCode] = cmapMappings[i].glyphId;
        }
       }
      }
      if (charCodeToGlyphId.length === 0) {
       charCodeToGlyphId[0] = 0;
      }
      var newMapping = adjustMapping(charCodeToGlyphId, properties);
      this.toFontChar = newMapping.toFontChar;
      tables['cmap'] = {
       tag: 'cmap',
       data: createCmapTable(newMapping.charCodeToGlyphId, numGlyphs)
      };
      if (!tables['OS/2'] || !validateOS2Table(tables['OS/2'])) {
       tables['OS/2'] = {
        tag: 'OS/2',
        data: createOS2Table(properties, newMapping.charCodeToGlyphId, metricsOverride)
       };
      }
      if (!tables['post']) {
       tables['post'] = {
        tag: 'post',
        data: createPostTable(properties)
       };
      }
      if (!isTrueType) {
       try {
        cffFile = new Stream(tables['CFF '].data);
        var parser = new CFFParser(cffFile, properties, SEAC_ANALYSIS_ENABLED);
        cff = parser.parse();
        var compiler = new CFFCompiler(cff);
        tables['CFF '].data = compiler.compile();
       } catch (e) {
        warn('Failed to compile font ' + properties.loadedName);
       }
      }
      if (!tables['name']) {
       tables['name'] = {
        tag: 'name',
        data: createNameTable(this.name)
       };
      } else {
       var namePrototype = readNameTable(tables['name']);
       tables['name'].data = createNameTable(name, namePrototype);
      }
      var builder = new OpenTypeFileBuilder(header.version);
      for (var tableTag in tables) {
       builder.addTable(tableTag, tables[tableTag].data);
      }
      return builder.toArray();
     },
     convert: function Font_convert(fontName, font, properties) {
      properties.fixedPitch = false;
      if (properties.builtInEncoding) {
       adjustToUnicode(properties, properties.builtInEncoding);
      }
      var mapping = font.getGlyphMapping(properties);
      var newMapping = adjustMapping(mapping, properties);
      this.toFontChar = newMapping.toFontChar;
      var numGlyphs = font.numGlyphs;
      function getCharCodes(charCodeToGlyphId, glyphId) {
       var charCodes = null;
       for (var charCode in charCodeToGlyphId) {
        if (glyphId === charCodeToGlyphId[charCode]) {
         if (!charCodes) {
          charCodes = [];
         }
         charCodes.push(charCode | 0);
        }
       }
       return charCodes;
      }
      function createCharCode(charCodeToGlyphId, glyphId) {
       for (var charCode in charCodeToGlyphId) {
        if (glyphId === charCodeToGlyphId[charCode]) {
         return charCode | 0;
        }
       }
       newMapping.charCodeToGlyphId[newMapping.nextAvailableFontCharCode] = glyphId;
       return newMapping.nextAvailableFontCharCode++;
      }
      var seacs = font.seacs;
      if (SEAC_ANALYSIS_ENABLED && seacs && seacs.length) {
       var matrix = properties.fontMatrix || FONT_IDENTITY_MATRIX;
       var charset = font.getCharset();
       var seacMap = Object.create(null);
       for (var glyphId in seacs) {
        glyphId |= 0;
        var seac = seacs[glyphId];
        var baseGlyphName = StandardEncoding[seac[2]];
        var accentGlyphName = StandardEncoding[seac[3]];
        var baseGlyphId = charset.indexOf(baseGlyphName);
        var accentGlyphId = charset.indexOf(accentGlyphName);
        if (baseGlyphId < 0 || accentGlyphId < 0) {
         continue;
        }
        var accentOffset = {
         x: seac[0] * matrix[0] + seac[1] * matrix[2] + matrix[4],
         y: seac[0] * matrix[1] + seac[1] * matrix[3] + matrix[5]
        };
        var charCodes = getCharCodes(mapping, glyphId);
        if (!charCodes) {
         continue;
        }
        for (var i = 0, ii = charCodes.length; i < ii; i++) {
         var charCode = charCodes[i];
         var charCodeToGlyphId = newMapping.charCodeToGlyphId;
         var baseFontCharCode = createCharCode(charCodeToGlyphId, baseGlyphId);
         var accentFontCharCode = createCharCode(charCodeToGlyphId, accentGlyphId);
         seacMap[charCode] = {
          baseFontCharCode: baseFontCharCode,
          accentFontCharCode: accentFontCharCode,
          accentOffset: accentOffset
         };
        }
       }
       properties.seacMap = seacMap;
      }
      var unitsPerEm = 1 / (properties.fontMatrix || FONT_IDENTITY_MATRIX)[0];
      var builder = new OpenTypeFileBuilder('\x4F\x54\x54\x4F');
      builder.addTable('CFF ', font.data);
      builder.addTable('OS/2', createOS2Table(properties, newMapping.charCodeToGlyphId));
      builder.addTable('cmap', createCmapTable(newMapping.charCodeToGlyphId, numGlyphs));
      builder.addTable('head', '\x00\x01\x00\x00' + '\x00\x00\x10\x00' + '\x00\x00\x00\x00' + '\x5F\x0F\x3C\xF5' + '\x00\x00' + safeString16(unitsPerEm) + '\x00\x00\x00\x00\x9e\x0b\x7e\x27' + '\x00\x00\x00\x00\x9e\x0b\x7e\x27' + '\x00\x00' + safeString16(properties.descent) + '\x0F\xFF' + safeString16(properties.ascent) + string16(properties.italicAngle ? 2 : 0) + '\x00\x11' + '\x00\x00' + '\x00\x00' + '\x00\x00');
      builder.addTable('hhea', '\x00\x01\x00\x00' + safeString16(properties.ascent) + safeString16(properties.descent) + '\x00\x00' + '\xFF\xFF' + '\x00\x00' + '\x00\x00' + '\x00\x00' + safeString16(properties.capHeight) + safeString16(Math.tan(properties.italicAngle) * properties.xHeight) + '\x00\x00' + '\x00\x00' + '\x00\x00' + '\x00\x00' + '\x00\x00' + '\x00\x00' + string16(numGlyphs));
      builder.addTable('hmtx', function fontFieldsHmtx() {
       var charstrings = font.charstrings;
       var cffWidths = font.cff ? font.cff.widths : null;
       var hmtx = '\x00\x00\x00\x00';
       for (var i = 1, ii = numGlyphs; i < ii; i++) {
        var width = 0;
        if (charstrings) {
         var charstring = charstrings[i - 1];
         width = 'width' in charstring ? charstring.width : 0;
        } else if (cffWidths) {
         width = Math.ceil(cffWidths[i] || 0);
        }
        hmtx += string16(width) + string16(0);
       }
       return hmtx;
      }());
      builder.addTable('maxp', '\x00\x00\x50\x00' + string16(numGlyphs));
      builder.addTable('name', createNameTable(fontName));
      builder.addTable('post', createPostTable(properties));
      return builder.toArray();
     },
     get spaceWidth() {
      if ('_shadowWidth' in this) {
       return this._shadowWidth;
      }
      var possibleSpaceReplacements = [
       'space',
       'minus',
       'one',
       'i',
       'I'
      ];
      var width;
      for (var i = 0, ii = possibleSpaceReplacements.length; i < ii; i++) {
       var glyphName = possibleSpaceReplacements[i];
       if (glyphName in this.widths) {
        width = this.widths[glyphName];
        break;
       }
       var glyphsUnicodeMap = getGlyphsUnicode();
       var glyphUnicode = glyphsUnicodeMap[glyphName];
       var charcode = 0;
       if (this.composite) {
        if (this.cMap.contains(glyphUnicode)) {
         charcode = this.cMap.lookup(glyphUnicode);
        }
       }
       if (!charcode && this.toUnicode) {
        charcode = this.toUnicode.charCodeOf(glyphUnicode);
       }
       if (charcode <= 0) {
        charcode = glyphUnicode;
       }
       width = this.widths[charcode];
       if (width) {
        break;
       }
      }
      width = width || this.defaultWidth;
      this._shadowWidth = width;
      return width;
     },
     charToGlyph: function Font_charToGlyph(charcode, isSpace) {
      var fontCharCode, width, operatorListId;
      var widthCode = charcode;
      if (this.cMap && this.cMap.contains(charcode)) {
       widthCode = this.cMap.lookup(charcode);
      }
      width = this.widths[widthCode];
      width = isNum(width) ? width : this.defaultWidth;
      var vmetric = this.vmetrics && this.vmetrics[widthCode];
      var unicode = this.toUnicode.get(charcode) || charcode;
      if (typeof unicode === 'number') {
       unicode = String.fromCharCode(unicode);
      }
      var isInFont = charcode in this.toFontChar;
      fontCharCode = this.toFontChar[charcode] || charcode;
      if (this.missingFile) {
       fontCharCode = mapSpecialUnicodeValues(fontCharCode);
      }
      if (this.isType3Font) {
       operatorListId = fontCharCode;
      }
      var accent = null;
      if (this.seacMap && this.seacMap[charcode]) {
       isInFont = true;
       var seac = this.seacMap[charcode];
       fontCharCode = seac.baseFontCharCode;
       accent = {
        fontChar: String.fromCharCode(seac.accentFontCharCode),
        offset: seac.accentOffset
       };
      }
      var fontChar = String.fromCharCode(fontCharCode);
      var glyph = this.glyphCache[charcode];
      if (!glyph || !glyph.matchesForCache(fontChar, unicode, accent, width, vmetric, operatorListId, isSpace, isInFont)) {
       glyph = new Glyph(fontChar, unicode, accent, width, vmetric, operatorListId, isSpace, isInFont);
       this.glyphCache[charcode] = glyph;
      }
      return glyph;
     },
     charsToGlyphs: function Font_charsToGlyphs(chars) {
      var charsCache = this.charsCache;
      var glyphs, glyph, charcode;
      if (charsCache) {
       glyphs = charsCache[chars];
       if (glyphs) {
        return glyphs;
       }
      }
      if (!charsCache) {
       charsCache = this.charsCache = Object.create(null);
      }
      glyphs = [];
      var charsCacheKey = chars;
      var i = 0, ii;
      if (this.cMap) {
       var c = Object.create(null);
       while (i < chars.length) {
        this.cMap.readCharCode(chars, i, c);
        charcode = c.charcode;
        var length = c.length;
        i += length;
        var isSpace = length === 1 && chars.charCodeAt(i - 1) === 0x20;
        glyph = this.charToGlyph(charcode, isSpace);
        glyphs.push(glyph);
       }
      } else {
       for (i = 0, ii = chars.length; i < ii; ++i) {
        charcode = chars.charCodeAt(i);
        glyph = this.charToGlyph(charcode, charcode === 0x20);
        glyphs.push(glyph);
       }
      }
      return charsCache[charsCacheKey] = glyphs;
     }
    };
    return Font;
   }();
   var ErrorFont = function ErrorFontClosure() {
    function ErrorFont(error) {
     this.error = error;
     this.loadedName = 'g_font_error';
     this.loading = false;
    }
    ErrorFont.prototype = {
     charsToGlyphs: function ErrorFont_charsToGlyphs() {
      return [];
     },
     exportData: function ErrorFont_exportData() {
      return { error: this.error };
     }
    };
    return ErrorFont;
   }();
   function type1FontGlyphMapping(properties, builtInEncoding, glyphNames) {
    var charCodeToGlyphId = Object.create(null);
    var glyphId, charCode, baseEncoding;
    var isSymbolicFont = !!(properties.flags & FontFlags.Symbolic);
    if (properties.baseEncodingName) {
     baseEncoding = getEncoding(properties.baseEncodingName);
     for (charCode = 0; charCode < baseEncoding.length; charCode++) {
      glyphId = glyphNames.indexOf(baseEncoding[charCode]);
      if (glyphId >= 0) {
       charCodeToGlyphId[charCode] = glyphId;
      } else {
       charCodeToGlyphId[charCode] = 0;
      }
     }
    } else if (isSymbolicFont) {
     for (charCode in builtInEncoding) {
      charCodeToGlyphId[charCode] = builtInEncoding[charCode];
     }
    } else {
     baseEncoding = StandardEncoding;
     for (charCode = 0; charCode < baseEncoding.length; charCode++) {
      glyphId = glyphNames.indexOf(baseEncoding[charCode]);
      if (glyphId >= 0) {
       charCodeToGlyphId[charCode] = glyphId;
      } else {
       charCodeToGlyphId[charCode] = 0;
      }
     }
    }
    var differences = properties.differences, glyphsUnicodeMap;
    if (differences) {
     for (charCode in differences) {
      var glyphName = differences[charCode];
      glyphId = glyphNames.indexOf(glyphName);
      if (glyphId === -1) {
       if (!glyphsUnicodeMap) {
        glyphsUnicodeMap = getGlyphsUnicode();
       }
       var standardGlyphName = recoverGlyphName(glyphName, glyphsUnicodeMap);
       if (standardGlyphName !== glyphName) {
        glyphId = glyphNames.indexOf(standardGlyphName);
       }
      }
      if (glyphId >= 0) {
       charCodeToGlyphId[charCode] = glyphId;
      } else {
       charCodeToGlyphId[charCode] = 0;
      }
     }
    }
    return charCodeToGlyphId;
   }
   var Type1Font = function Type1FontClosure() {
    function findBlock(streamBytes, signature, startIndex) {
     var streamBytesLength = streamBytes.length;
     var signatureLength = signature.length;
     var scanLength = streamBytesLength - signatureLength;
     var i = startIndex, j, found = false;
     while (i < scanLength) {
      j = 0;
      while (j < signatureLength && streamBytes[i + j] === signature[j]) {
       j++;
      }
      if (j >= signatureLength) {
       i += j;
       while (i < streamBytesLength && isSpace(streamBytes[i])) {
        i++;
       }
       found = true;
       break;
      }
      i++;
     }
     return {
      found: found,
      length: i
     };
    }
    function getHeaderBlock(stream, suggestedLength) {
     var EEXEC_SIGNATURE = [
      0x65,
      0x65,
      0x78,
      0x65,
      0x63
     ];
     var streamStartPos = stream.pos;
     var headerBytes, headerBytesLength, block;
     try {
      headerBytes = stream.getBytes(suggestedLength);
      headerBytesLength = headerBytes.length;
     } catch (ex) {
      if (ex instanceof MissingDataException) {
       throw ex;
      }
     }
     if (headerBytesLength === suggestedLength) {
      block = findBlock(headerBytes, EEXEC_SIGNATURE, suggestedLength - 2 * EEXEC_SIGNATURE.length);
      if (block.found && block.length === suggestedLength) {
       return {
        stream: new Stream(headerBytes),
        length: suggestedLength
       };
      }
     }
     warn('Invalid "Length1" property in Type1 font -- trying to recover.');
     stream.pos = streamStartPos;
     var SCAN_BLOCK_LENGTH = 2048;
     var actualLength;
     while (true) {
      var scanBytes = stream.peekBytes(SCAN_BLOCK_LENGTH);
      block = findBlock(scanBytes, EEXEC_SIGNATURE, 0);
      if (block.length === 0) {
       break;
      }
      stream.pos += block.length;
      if (block.found) {
       actualLength = stream.pos - streamStartPos;
       break;
      }
     }
     stream.pos = streamStartPos;
     if (actualLength) {
      return {
       stream: new Stream(stream.getBytes(actualLength)),
       length: actualLength
      };
     }
     warn('Unable to recover "Length1" property in Type1 font -- using as is.');
     return {
      stream: new Stream(stream.getBytes(suggestedLength)),
      length: suggestedLength
     };
    }
    function getEexecBlock(stream, suggestedLength) {
     var eexecBytes = stream.getBytes();
     return {
      stream: new Stream(eexecBytes),
      length: eexecBytes.length
     };
    }
    function Type1Font(name, file, properties) {
     var PFB_HEADER_SIZE = 6;
     var headerBlockLength = properties.length1;
     var eexecBlockLength = properties.length2;
     var pfbHeader = file.peekBytes(PFB_HEADER_SIZE);
     var pfbHeaderPresent = pfbHeader[0] === 0x80 && pfbHeader[1] === 0x01;
     if (pfbHeaderPresent) {
      file.skip(PFB_HEADER_SIZE);
      headerBlockLength = pfbHeader[5] << 24 | pfbHeader[4] << 16 | pfbHeader[3] << 8 | pfbHeader[2];
     }
     var headerBlock = getHeaderBlock(file, headerBlockLength);
     headerBlockLength = headerBlock.length;
     var headerBlockParser = new Type1Parser(headerBlock.stream, false, SEAC_ANALYSIS_ENABLED);
     headerBlockParser.extractFontHeader(properties);
     if (pfbHeaderPresent) {
      pfbHeader = file.getBytes(PFB_HEADER_SIZE);
      eexecBlockLength = pfbHeader[5] << 24 | pfbHeader[4] << 16 | pfbHeader[3] << 8 | pfbHeader[2];
     }
     var eexecBlock = getEexecBlock(file, eexecBlockLength);
     eexecBlockLength = eexecBlock.length;
     var eexecBlockParser = new Type1Parser(eexecBlock.stream, true, SEAC_ANALYSIS_ENABLED);
     var data = eexecBlockParser.extractFontProgram();
     for (var info in data.properties) {
      properties[info] = data.properties[info];
     }
     var charstrings = data.charstrings;
     var type2Charstrings = this.getType2Charstrings(charstrings);
     var subrs = this.getType2Subrs(data.subrs);
     this.charstrings = charstrings;
     this.data = this.wrap(name, type2Charstrings, this.charstrings, subrs, properties);
     this.seacs = this.getSeacs(data.charstrings);
    }
    Type1Font.prototype = {
     get numGlyphs() {
      return this.charstrings.length + 1;
     },
     getCharset: function Type1Font_getCharset() {
      var charset = ['.notdef'];
      var charstrings = this.charstrings;
      for (var glyphId = 0; glyphId < charstrings.length; glyphId++) {
       charset.push(charstrings[glyphId].glyphName);
      }
      return charset;
     },
     getGlyphMapping: function Type1Font_getGlyphMapping(properties) {
      var charstrings = this.charstrings;
      var glyphNames = ['.notdef'], glyphId;
      for (glyphId = 0; glyphId < charstrings.length; glyphId++) {
       glyphNames.push(charstrings[glyphId].glyphName);
      }
      var encoding = properties.builtInEncoding;
      if (encoding) {
       var builtInEncoding = Object.create(null);
       for (var charCode in encoding) {
        glyphId = glyphNames.indexOf(encoding[charCode]);
        if (glyphId >= 0) {
         builtInEncoding[charCode] = glyphId;
        }
       }
      }
      return type1FontGlyphMapping(properties, builtInEncoding, glyphNames);
     },
     getSeacs: function Type1Font_getSeacs(charstrings) {
      var i, ii;
      var seacMap = [];
      for (i = 0, ii = charstrings.length; i < ii; i++) {
       var charstring = charstrings[i];
       if (charstring.seac) {
        seacMap[i + 1] = charstring.seac;
       }
      }
      return seacMap;
     },
     getType2Charstrings: function Type1Font_getType2Charstrings(type1Charstrings) {
      var type2Charstrings = [];
      for (var i = 0, ii = type1Charstrings.length; i < ii; i++) {
       type2Charstrings.push(type1Charstrings[i].charstring);
      }
      return type2Charstrings;
     },
     getType2Subrs: function Type1Font_getType2Subrs(type1Subrs) {
      var bias = 0;
      var count = type1Subrs.length;
      if (count < 1133) {
       bias = 107;
      } else if (count < 33769) {
       bias = 1131;
      } else {
       bias = 32768;
      }
      var type2Subrs = [];
      var i;
      for (i = 0; i < bias; i++) {
       type2Subrs.push([0x0B]);
      }
      for (i = 0; i < count; i++) {
       type2Subrs.push(type1Subrs[i]);
      }
      return type2Subrs;
     },
     wrap: function Type1Font_wrap(name, glyphs, charstrings, subrs, properties) {
      var cff = new CFF();
      cff.header = new CFFHeader(1, 0, 4, 4);
      cff.names = [name];
      var topDict = new CFFTopDict();
      topDict.setByName('version', 391);
      topDict.setByName('Notice', 392);
      topDict.setByName('FullName', 393);
      topDict.setByName('FamilyName', 394);
      topDict.setByName('Weight', 395);
      topDict.setByName('Encoding', null);
      topDict.setByName('FontMatrix', properties.fontMatrix);
      topDict.setByName('FontBBox', properties.bbox);
      topDict.setByName('charset', null);
      topDict.setByName('CharStrings', null);
      topDict.setByName('Private', null);
      cff.topDict = topDict;
      var strings = new CFFStrings();
      strings.add('Version 0.11');
      strings.add('See original notice');
      strings.add(name);
      strings.add(name);
      strings.add('Medium');
      cff.strings = strings;
      cff.globalSubrIndex = new CFFIndex();
      var count = glyphs.length;
      var charsetArray = [0];
      var i, ii;
      for (i = 0; i < count; i++) {
       var index = CFFStandardStrings.indexOf(charstrings[i].glyphName);
       if (index === -1) {
        index = 0;
       }
       charsetArray.push(index >> 8 & 0xff, index & 0xff);
      }
      cff.charset = new CFFCharset(false, 0, [], charsetArray);
      var charStringsIndex = new CFFIndex();
      charStringsIndex.add([
       0x8B,
       0x0E
      ]);
      for (i = 0; i < count; i++) {
       var glyph = glyphs[i];
       if (glyph.length === 0) {
        charStringsIndex.add([
         0x8B,
         0x0E
        ]);
        continue;
       }
       charStringsIndex.add(glyph);
      }
      cff.charStrings = charStringsIndex;
      var privateDict = new CFFPrivateDict();
      privateDict.setByName('Subrs', null);
      var fields = [
       'BlueValues',
       'OtherBlues',
       'FamilyBlues',
       'FamilyOtherBlues',
       'StemSnapH',
       'StemSnapV',
       'BlueShift',
       'BlueFuzz',
       'BlueScale',
       'LanguageGroup',
       'ExpansionFactor',
       'ForceBold',
       'StdHW',
       'StdVW'
      ];
      for (i = 0, ii = fields.length; i < ii; i++) {
       var field = fields[i];
       if (!(field in properties.privateData)) {
        continue;
       }
       var value = properties.privateData[field];
       if (isArray(value)) {
        for (var j = value.length - 1; j > 0; j--) {
         value[j] -= value[j - 1];
        }
       }
       privateDict.setByName(field, value);
      }
      cff.topDict.privateDict = privateDict;
      var subrIndex = new CFFIndex();
      for (i = 0, ii = subrs.length; i < ii; i++) {
       subrIndex.add(subrs[i]);
      }
      privateDict.subrsIndex = subrIndex;
      var compiler = new CFFCompiler(cff);
      return compiler.compile();
     }
    };
    return Type1Font;
   }();
   var CFFFont = function CFFFontClosure() {
    function CFFFont(file, properties) {
     this.properties = properties;
     var parser = new CFFParser(file, properties, SEAC_ANALYSIS_ENABLED);
     this.cff = parser.parse();
     var compiler = new CFFCompiler(this.cff);
     this.seacs = this.cff.seacs;
     try {
      this.data = compiler.compile();
     } catch (e) {
      warn('Failed to compile font ' + properties.loadedName);
      this.data = file;
     }
    }
    CFFFont.prototype = {
     get numGlyphs() {
      return this.cff.charStrings.count;
     },
     getCharset: function CFFFont_getCharset() {
      return this.cff.charset.charset;
     },
     getGlyphMapping: function CFFFont_getGlyphMapping() {
      var cff = this.cff;
      var properties = this.properties;
      var charsets = cff.charset.charset;
      var charCodeToGlyphId;
      var glyphId;
      if (properties.composite) {
       charCodeToGlyphId = Object.create(null);
       if (cff.isCIDFont) {
        for (glyphId = 0; glyphId < charsets.length; glyphId++) {
         var cid = charsets[glyphId];
         var charCode = properties.cMap.charCodeOf(cid);
         charCodeToGlyphId[charCode] = glyphId;
        }
       } else {
        for (glyphId = 0; glyphId < cff.charStrings.count; glyphId++) {
         charCodeToGlyphId[glyphId] = glyphId;
        }
       }
       return charCodeToGlyphId;
      }
      var encoding = cff.encoding ? cff.encoding.encoding : null;
      charCodeToGlyphId = type1FontGlyphMapping(properties, encoding, charsets);
      return charCodeToGlyphId;
     }
    };
    return CFFFont;
   }();
   (function checkSeacSupport() {
    if (typeof navigator !== 'undefined' && /Windows/.test(navigator.userAgent)) {
     SEAC_ANALYSIS_ENABLED = true;
    }
   }());
   (function checkChromeWindows() {
    if (typeof navigator !== 'undefined' && /Windows.*Chrome/.test(navigator.userAgent)) {
     SKIP_PRIVATE_USE_RANGE_F000_TO_F01F = true;
    }
   }());
   exports.ErrorFont = ErrorFont;
   exports.Font = Font;
   exports.FontFlags = FontFlags;
   exports.IdentityToUnicodeMap = IdentityToUnicodeMap;
   exports.ToUnicodeMap = ToUnicodeMap;
   exports.getFontType = getFontType;
  }));
  (function (root, factory) {
   factory(root.pdfjsCorePsParser = {}, root.pdfjsSharedUtil, root.pdfjsCoreParser);
  }(this, function (exports, sharedUtil, coreParser) {
   var error = sharedUtil.error;
   var isSpace = sharedUtil.isSpace;
   var EOF = coreParser.EOF;
   var PostScriptParser = function PostScriptParserClosure() {
    function PostScriptParser(lexer) {
     this.lexer = lexer;
     this.operators = [];
     this.token = null;
     this.prev = null;
    }
    PostScriptParser.prototype = {
     nextToken: function PostScriptParser_nextToken() {
      this.prev = this.token;
      this.token = this.lexer.getToken();
     },
     accept: function PostScriptParser_accept(type) {
      if (this.token.type === type) {
       this.nextToken();
       return true;
      }
      return false;
     },
     expect: function PostScriptParser_expect(type) {
      if (this.accept(type)) {
       return true;
      }
      error('Unexpected symbol: found ' + this.token.type + ' expected ' + type + '.');
     },
     parse: function PostScriptParser_parse() {
      this.nextToken();
      this.expect(PostScriptTokenTypes.LBRACE);
      this.parseBlock();
      this.expect(PostScriptTokenTypes.RBRACE);
      return this.operators;
     },
     parseBlock: function PostScriptParser_parseBlock() {
      while (true) {
       if (this.accept(PostScriptTokenTypes.NUMBER)) {
        this.operators.push(this.prev.value);
       } else if (this.accept(PostScriptTokenTypes.OPERATOR)) {
        this.operators.push(this.prev.value);
       } else if (this.accept(PostScriptTokenTypes.LBRACE)) {
        this.parseCondition();
       } else {
        return;
       }
      }
     },
     parseCondition: function PostScriptParser_parseCondition() {
      var conditionLocation = this.operators.length;
      this.operators.push(null, null);
      this.parseBlock();
      this.expect(PostScriptTokenTypes.RBRACE);
      if (this.accept(PostScriptTokenTypes.IF)) {
       this.operators[conditionLocation] = this.operators.length;
       this.operators[conditionLocation + 1] = 'jz';
      } else if (this.accept(PostScriptTokenTypes.LBRACE)) {
       var jumpLocation = this.operators.length;
       this.operators.push(null, null);
       var endOfTrue = this.operators.length;
       this.parseBlock();
       this.expect(PostScriptTokenTypes.RBRACE);
       this.expect(PostScriptTokenTypes.IFELSE);
       this.operators[jumpLocation] = this.operators.length;
       this.operators[jumpLocation + 1] = 'j';
       this.operators[conditionLocation] = endOfTrue;
       this.operators[conditionLocation + 1] = 'jz';
      } else {
       error('PS Function: error parsing conditional.');
      }
     }
    };
    return PostScriptParser;
   }();
   var PostScriptTokenTypes = {
    LBRACE: 0,
    RBRACE: 1,
    NUMBER: 2,
    OPERATOR: 3,
    IF: 4,
    IFELSE: 5
   };
   var PostScriptToken = function PostScriptTokenClosure() {
    function PostScriptToken(type, value) {
     this.type = type;
     this.value = value;
    }
    var opCache = Object.create(null);
    PostScriptToken.getOperator = function PostScriptToken_getOperator(op) {
     var opValue = opCache[op];
     if (opValue) {
      return opValue;
     }
     return opCache[op] = new PostScriptToken(PostScriptTokenTypes.OPERATOR, op);
    };
    PostScriptToken.LBRACE = new PostScriptToken(PostScriptTokenTypes.LBRACE, '{');
    PostScriptToken.RBRACE = new PostScriptToken(PostScriptTokenTypes.RBRACE, '}');
    PostScriptToken.IF = new PostScriptToken(PostScriptTokenTypes.IF, 'IF');
    PostScriptToken.IFELSE = new PostScriptToken(PostScriptTokenTypes.IFELSE, 'IFELSE');
    return PostScriptToken;
   }();
   var PostScriptLexer = function PostScriptLexerClosure() {
    function PostScriptLexer(stream) {
     this.stream = stream;
     this.nextChar();
     this.strBuf = [];
    }
    PostScriptLexer.prototype = {
     nextChar: function PostScriptLexer_nextChar() {
      return this.currentChar = this.stream.getByte();
     },
     getToken: function PostScriptLexer_getToken() {
      var comment = false;
      var ch = this.currentChar;
      while (true) {
       if (ch < 0) {
        return EOF;
       }
       if (comment) {
        if (ch === 0x0A || ch === 0x0D) {
         comment = false;
        }
       } else if (ch === 0x25) {
        comment = true;
       } else if (!isSpace(ch)) {
        break;
       }
       ch = this.nextChar();
      }
      switch (ch | 0) {
      case 0x30:
      case 0x31:
      case 0x32:
      case 0x33:
      case 0x34:
      case 0x35:
      case 0x36:
      case 0x37:
      case 0x38:
      case 0x39:
      case 0x2B:
      case 0x2D:
      case 0x2E:
       return new PostScriptToken(PostScriptTokenTypes.NUMBER, this.getNumber());
      case 0x7B:
       this.nextChar();
       return PostScriptToken.LBRACE;
      case 0x7D:
       this.nextChar();
       return PostScriptToken.RBRACE;
      }
      var strBuf = this.strBuf;
      strBuf.length = 0;
      strBuf[0] = String.fromCharCode(ch);
      while ((ch = this.nextChar()) >= 0 && (ch >= 0x41 && ch <= 0x5A || ch >= 0x61 && ch <= 0x7A)) {
       strBuf.push(String.fromCharCode(ch));
      }
      var str = strBuf.join('');
      switch (str.toLowerCase()) {
      case 'if':
       return PostScriptToken.IF;
      case 'ifelse':
       return PostScriptToken.IFELSE;
      default:
       return PostScriptToken.getOperator(str);
      }
     },
     getNumber: function PostScriptLexer_getNumber() {
      var ch = this.currentChar;
      var strBuf = this.strBuf;
      strBuf.length = 0;
      strBuf[0] = String.fromCharCode(ch);
      while ((ch = this.nextChar()) >= 0) {
       if (ch >= 0x30 && ch <= 0x39 || ch === 0x2D || ch === 0x2E) {
        strBuf.push(String.fromCharCode(ch));
       } else {
        break;
       }
      }
      var value = parseFloat(strBuf.join(''));
      if (isNaN(value)) {
       error('Invalid floating point number: ' + value);
      }
      return value;
     }
    };
    return PostScriptLexer;
   }();
   exports.PostScriptLexer = PostScriptLexer;
   exports.PostScriptParser = PostScriptParser;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayCanvas = {}, root.pdfjsSharedUtil, root.pdfjsDisplayDOMUtils, root.pdfjsDisplayPatternHelper, root.pdfjsDisplayWebGL);
  }(this, function (exports, sharedUtil, displayDOMUtils, displayPatternHelper, displayWebGL) {
   var FONT_IDENTITY_MATRIX = sharedUtil.FONT_IDENTITY_MATRIX;
   var IDENTITY_MATRIX = sharedUtil.IDENTITY_MATRIX;
   var ImageKind = sharedUtil.ImageKind;
   var OPS = sharedUtil.OPS;
   var TextRenderingMode = sharedUtil.TextRenderingMode;
   var Uint32ArrayView = sharedUtil.Uint32ArrayView;
   var Util = sharedUtil.Util;
   var assert = sharedUtil.assert;
   var info = sharedUtil.info;
   var isNum = sharedUtil.isNum;
   var isArray = sharedUtil.isArray;
   var isLittleEndian = sharedUtil.isLittleEndian;
   var error = sharedUtil.error;
   var shadow = sharedUtil.shadow;
   var warn = sharedUtil.warn;
   var TilingPattern = displayPatternHelper.TilingPattern;
   var getShadingPatternFromIR = displayPatternHelper.getShadingPatternFromIR;
   var WebGLUtils = displayWebGL.WebGLUtils;
   var hasCanvasTypedArrays = displayDOMUtils.hasCanvasTypedArrays;
   var MIN_FONT_SIZE = 16;
   var MAX_FONT_SIZE = 100;
   var MAX_GROUP_SIZE = 4096;
   var MIN_WIDTH_FACTOR = 0.65;
   var COMPILE_TYPE3_GLYPHS = true;
   var MAX_SIZE_TO_COMPILE = 1000;
   var FULL_CHUNK_HEIGHT = 16;
   var HasCanvasTypedArraysCached = {
    get value() {
     return shadow(HasCanvasTypedArraysCached, 'value', hasCanvasTypedArrays());
    }
   };
   var IsLittleEndianCached = {
    get value() {
     return shadow(IsLittleEndianCached, 'value', isLittleEndian());
    }
   };
   function createScratchCanvas(width, height) {
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
   }
   function addContextCurrentTransform(ctx) {
    if (!ctx.mozCurrentTransform) {
     ctx._originalSave = ctx.save;
     ctx._originalRestore = ctx.restore;
     ctx._originalRotate = ctx.rotate;
     ctx._originalScale = ctx.scale;
     ctx._originalTranslate = ctx.translate;
     ctx._originalTransform = ctx.transform;
     ctx._originalSetTransform = ctx.setTransform;
     ctx._transformMatrix = ctx._transformMatrix || [
      1,
      0,
      0,
      1,
      0,
      0
     ];
     ctx._transformStack = [];
     Object.defineProperty(ctx, 'mozCurrentTransform', {
      get: function getCurrentTransform() {
       return this._transformMatrix;
      }
     });
     Object.defineProperty(ctx, 'mozCurrentTransformInverse', {
      get: function getCurrentTransformInverse() {
       var m = this._transformMatrix;
       var a = m[0], b = m[1], c = m[2], d = m[3], e = m[4], f = m[5];
       var ad_bc = a * d - b * c;
       var bc_ad = b * c - a * d;
       return [
        d / ad_bc,
        b / bc_ad,
        c / bc_ad,
        a / ad_bc,
        (d * e - c * f) / bc_ad,
        (b * e - a * f) / ad_bc
       ];
      }
     });
     ctx.save = function ctxSave() {
      var old = this._transformMatrix;
      this._transformStack.push(old);
      this._transformMatrix = old.slice(0, 6);
      this._originalSave();
     };
     ctx.restore = function ctxRestore() {
      var prev = this._transformStack.pop();
      if (prev) {
       this._transformMatrix = prev;
       this._originalRestore();
      }
     };
     ctx.translate = function ctxTranslate(x, y) {
      var m = this._transformMatrix;
      m[4] = m[0] * x + m[2] * y + m[4];
      m[5] = m[1] * x + m[3] * y + m[5];
      this._originalTranslate(x, y);
     };
     ctx.scale = function ctxScale(x, y) {
      var m = this._transformMatrix;
      m[0] = m[0] * x;
      m[1] = m[1] * x;
      m[2] = m[2] * y;
      m[3] = m[3] * y;
      this._originalScale(x, y);
     };
     ctx.transform = function ctxTransform(a, b, c, d, e, f) {
      var m = this._transformMatrix;
      this._transformMatrix = [
       m[0] * a + m[2] * b,
       m[1] * a + m[3] * b,
       m[0] * c + m[2] * d,
       m[1] * c + m[3] * d,
       m[0] * e + m[2] * f + m[4],
       m[1] * e + m[3] * f + m[5]
      ];
      ctx._originalTransform(a, b, c, d, e, f);
     };
     ctx.setTransform = function ctxSetTransform(a, b, c, d, e, f) {
      this._transformMatrix = [
       a,
       b,
       c,
       d,
       e,
       f
      ];
      ctx._originalSetTransform(a, b, c, d, e, f);
     };
     ctx.rotate = function ctxRotate(angle) {
      var cosValue = Math.cos(angle);
      var sinValue = Math.sin(angle);
      var m = this._transformMatrix;
      this._transformMatrix = [
       m[0] * cosValue + m[2] * sinValue,
       m[1] * cosValue + m[3] * sinValue,
       m[0] * -sinValue + m[2] * cosValue,
       m[1] * -sinValue + m[3] * cosValue,
       m[4],
       m[5]
      ];
      this._originalRotate(angle);
     };
    }
   }
   var CachedCanvases = function CachedCanvasesClosure() {
    function CachedCanvases() {
     this.cache = Object.create(null);
    }
    CachedCanvases.prototype = {
     getCanvas: function CachedCanvases_getCanvas(id, width, height, trackTransform) {
      var canvasEntry;
      if (this.cache[id] !== undefined) {
       canvasEntry = this.cache[id];
       canvasEntry.canvas.width = width;
       canvasEntry.canvas.height = height;
       canvasEntry.context.setTransform(1, 0, 0, 1, 0, 0);
      } else {
       var canvas = createScratchCanvas(width, height);
       var ctx = canvas.getContext('2d');
       if (trackTransform) {
        addContextCurrentTransform(ctx);
       }
       this.cache[id] = canvasEntry = {
        canvas: canvas,
        context: ctx
       };
      }
      return canvasEntry;
     },
     clear: function () {
      for (var id in this.cache) {
       var canvasEntry = this.cache[id];
       canvasEntry.canvas.width = 0;
       canvasEntry.canvas.height = 0;
       delete this.cache[id];
      }
     }
    };
    return CachedCanvases;
   }();
   function compileType3Glyph(imgData) {
    var POINT_TO_PROCESS_LIMIT = 1000;
    var width = imgData.width, height = imgData.height;
    var i, j, j0, width1 = width + 1;
    var points = new Uint8Array(width1 * (height + 1));
    var POINT_TYPES = new Uint8Array([
     0,
     2,
     4,
     0,
     1,
     0,
     5,
     4,
     8,
     10,
     0,
     8,
     0,
     2,
     1,
     0
    ]);
    var lineSize = width + 7 & ~7, data0 = imgData.data;
    var data = new Uint8Array(lineSize * height), pos = 0, ii;
    for (i = 0, ii = data0.length; i < ii; i++) {
     var mask = 128, elem = data0[i];
     while (mask > 0) {
      data[pos++] = elem & mask ? 0 : 255;
      mask >>= 1;
     }
    }
    var count = 0;
    pos = 0;
    if (data[pos] !== 0) {
     points[0] = 1;
     ++count;
    }
    for (j = 1; j < width; j++) {
     if (data[pos] !== data[pos + 1]) {
      points[j] = data[pos] ? 2 : 1;
      ++count;
     }
     pos++;
    }
    if (data[pos] !== 0) {
     points[j] = 2;
     ++count;
    }
    for (i = 1; i < height; i++) {
     pos = i * lineSize;
     j0 = i * width1;
     if (data[pos - lineSize] !== data[pos]) {
      points[j0] = data[pos] ? 1 : 8;
      ++count;
     }
     var sum = (data[pos] ? 4 : 0) + (data[pos - lineSize] ? 8 : 0);
     for (j = 1; j < width; j++) {
      sum = (sum >> 2) + (data[pos + 1] ? 4 : 0) + (data[pos - lineSize + 1] ? 8 : 0);
      if (POINT_TYPES[sum]) {
       points[j0 + j] = POINT_TYPES[sum];
       ++count;
      }
      pos++;
     }
     if (data[pos - lineSize] !== data[pos]) {
      points[j0 + j] = data[pos] ? 2 : 4;
      ++count;
     }
     if (count > POINT_TO_PROCESS_LIMIT) {
      return null;
     }
    }
    pos = lineSize * (height - 1);
    j0 = i * width1;
    if (data[pos] !== 0) {
     points[j0] = 8;
     ++count;
    }
    for (j = 1; j < width; j++) {
     if (data[pos] !== data[pos + 1]) {
      points[j0 + j] = data[pos] ? 4 : 8;
      ++count;
     }
     pos++;
    }
    if (data[pos] !== 0) {
     points[j0 + j] = 4;
     ++count;
    }
    if (count > POINT_TO_PROCESS_LIMIT) {
     return null;
    }
    var steps = new Int32Array([
     0,
     width1,
     -1,
     0,
     -width1,
     0,
     0,
     0,
     1
    ]);
    var outlines = [];
    for (i = 0; count && i <= height; i++) {
     var p = i * width1;
     var end = p + width;
     while (p < end && !points[p]) {
      p++;
     }
     if (p === end) {
      continue;
     }
     var coords = [
      p % width1,
      i
     ];
     var type = points[p], p0 = p, pp;
     do {
      var step = steps[type];
      do {
       p += step;
      } while (!points[p]);
      pp = points[p];
      if (pp !== 5 && pp !== 10) {
       type = pp;
       points[p] = 0;
      } else {
       type = pp & 0x33 * type >> 4;
       points[p] &= type >> 2 | type << 2;
      }
      coords.push(p % width1);
      coords.push(p / width1 | 0);
      --count;
     } while (p0 !== p);
     outlines.push(coords);
     --i;
    }
    var drawOutline = function (c) {
     c.save();
     c.scale(1 / width, -1 / height);
     c.translate(0, -height);
     c.beginPath();
     for (var i = 0, ii = outlines.length; i < ii; i++) {
      var o = outlines[i];
      c.moveTo(o[0], o[1]);
      for (var j = 2, jj = o.length; j < jj; j += 2) {
       c.lineTo(o[j], o[j + 1]);
      }
     }
     c.fill();
     c.beginPath();
     c.restore();
    };
    return drawOutline;
   }
   var CanvasExtraState = function CanvasExtraStateClosure() {
    function CanvasExtraState(old) {
     this.alphaIsShape = false;
     this.fontSize = 0;
     this.fontSizeScale = 1;
     this.textMatrix = IDENTITY_MATRIX;
     this.textMatrixScale = 1;
     this.fontMatrix = FONT_IDENTITY_MATRIX;
     this.leading = 0;
     this.x = 0;
     this.y = 0;
     this.lineX = 0;
     this.lineY = 0;
     this.charSpacing = 0;
     this.wordSpacing = 0;
     this.textHScale = 1;
     this.textRenderingMode = TextRenderingMode.FILL;
     this.textRise = 0;
     this.fillColor = '#000000';
     this.strokeColor = '#000000';
     this.patternFill = false;
     this.fillAlpha = 1;
     this.strokeAlpha = 1;
     this.lineWidth = 1;
     this.activeSMask = null;
     this.resumeSMaskCtx = null;
     this.old = old;
    }
    CanvasExtraState.prototype = {
     clone: function CanvasExtraState_clone() {
      return Object.create(this);
     },
     setCurrentPoint: function CanvasExtraState_setCurrentPoint(x, y) {
      this.x = x;
      this.y = y;
     }
    };
    return CanvasExtraState;
   }();
   var CanvasGraphics = function CanvasGraphicsClosure() {
    var EXECUTION_TIME = 15;
    var EXECUTION_STEPS = 10;
    function CanvasGraphics(canvasCtx, commonObjs, objs, imageLayer) {
     this.ctx = canvasCtx;
     this.current = new CanvasExtraState();
     this.stateStack = [];
     this.pendingClip = null;
     this.pendingEOFill = false;
     this.res = null;
     this.xobjs = null;
     this.commonObjs = commonObjs;
     this.objs = objs;
     this.imageLayer = imageLayer;
     this.groupStack = [];
     this.processingType3 = null;
     this.baseTransform = null;
     this.baseTransformStack = [];
     this.groupLevel = 0;
     this.smaskStack = [];
     this.smaskCounter = 0;
     this.tempSMask = null;
     this.cachedCanvases = new CachedCanvases();
     if (canvasCtx) {
      addContextCurrentTransform(canvasCtx);
     }
     this.cachedGetSinglePixelWidth = null;
    }
    function putBinaryImageData(ctx, imgData) {
     if (typeof ImageData !== 'undefined' && imgData instanceof ImageData) {
      ctx.putImageData(imgData, 0, 0);
      return;
     }
     var height = imgData.height, width = imgData.width;
     var partialChunkHeight = height % FULL_CHUNK_HEIGHT;
     var fullChunks = (height - partialChunkHeight) / FULL_CHUNK_HEIGHT;
     var totalChunks = partialChunkHeight === 0 ? fullChunks : fullChunks + 1;
     var chunkImgData = ctx.createImageData(width, FULL_CHUNK_HEIGHT);
     var srcPos = 0, destPos;
     var src = imgData.data;
     var dest = chunkImgData.data;
     var i, j, thisChunkHeight, elemsInThisChunk;
     if (imgData.kind === ImageKind.GRAYSCALE_1BPP) {
      var srcLength = src.byteLength;
      var dest32 = HasCanvasTypedArraysCached.value ? new Uint32Array(dest.buffer) : new Uint32ArrayView(dest);
      var dest32DataLength = dest32.length;
      var fullSrcDiff = width + 7 >> 3;
      var white = 0xFFFFFFFF;
      var black = IsLittleEndianCached.value || !HasCanvasTypedArraysCached.value ? 0xFF000000 : 0x000000FF;
      for (i = 0; i < totalChunks; i++) {
       thisChunkHeight = i < fullChunks ? FULL_CHUNK_HEIGHT : partialChunkHeight;
       destPos = 0;
       for (j = 0; j < thisChunkHeight; j++) {
        var srcDiff = srcLength - srcPos;
        var k = 0;
        var kEnd = srcDiff > fullSrcDiff ? width : srcDiff * 8 - 7;
        var kEndUnrolled = kEnd & ~7;
        var mask = 0;
        var srcByte = 0;
        for (; k < kEndUnrolled; k += 8) {
         srcByte = src[srcPos++];
         dest32[destPos++] = srcByte & 128 ? white : black;
         dest32[destPos++] = srcByte & 64 ? white : black;
         dest32[destPos++] = srcByte & 32 ? white : black;
         dest32[destPos++] = srcByte & 16 ? white : black;
         dest32[destPos++] = srcByte & 8 ? white : black;
         dest32[destPos++] = srcByte & 4 ? white : black;
         dest32[destPos++] = srcByte & 2 ? white : black;
         dest32[destPos++] = srcByte & 1 ? white : black;
        }
        for (; k < kEnd; k++) {
         if (mask === 0) {
          srcByte = src[srcPos++];
          mask = 128;
         }
         dest32[destPos++] = srcByte & mask ? white : black;
         mask >>= 1;
        }
       }
       while (destPos < dest32DataLength) {
        dest32[destPos++] = 0;
       }
       ctx.putImageData(chunkImgData, 0, i * FULL_CHUNK_HEIGHT);
      }
     } else if (imgData.kind === ImageKind.RGBA_32BPP) {
      j = 0;
      elemsInThisChunk = width * FULL_CHUNK_HEIGHT * 4;
      for (i = 0; i < fullChunks; i++) {
       dest.set(src.subarray(srcPos, srcPos + elemsInThisChunk));
       srcPos += elemsInThisChunk;
       ctx.putImageData(chunkImgData, 0, j);
       j += FULL_CHUNK_HEIGHT;
      }
      if (i < totalChunks) {
       elemsInThisChunk = width * partialChunkHeight * 4;
       dest.set(src.subarray(srcPos, srcPos + elemsInThisChunk));
       ctx.putImageData(chunkImgData, 0, j);
      }
     } else if (imgData.kind === ImageKind.RGB_24BPP) {
      thisChunkHeight = FULL_CHUNK_HEIGHT;
      elemsInThisChunk = width * thisChunkHeight;
      for (i = 0; i < totalChunks; i++) {
       if (i >= fullChunks) {
        thisChunkHeight = partialChunkHeight;
        elemsInThisChunk = width * thisChunkHeight;
       }
       destPos = 0;
       for (j = elemsInThisChunk; j--;) {
        dest[destPos++] = src[srcPos++];
        dest[destPos++] = src[srcPos++];
        dest[destPos++] = src[srcPos++];
        dest[destPos++] = 255;
       }
       ctx.putImageData(chunkImgData, 0, i * FULL_CHUNK_HEIGHT);
      }
     } else {
      error('bad image kind: ' + imgData.kind);
     }
    }
    function putBinaryImageMask(ctx, imgData) {
     var height = imgData.height, width = imgData.width;
     var partialChunkHeight = height % FULL_CHUNK_HEIGHT;
     var fullChunks = (height - partialChunkHeight) / FULL_CHUNK_HEIGHT;
     var totalChunks = partialChunkHeight === 0 ? fullChunks : fullChunks + 1;
     var chunkImgData = ctx.createImageData(width, FULL_CHUNK_HEIGHT);
     var srcPos = 0;
     var src = imgData.data;
     var dest = chunkImgData.data;
     for (var i = 0; i < totalChunks; i++) {
      var thisChunkHeight = i < fullChunks ? FULL_CHUNK_HEIGHT : partialChunkHeight;
      var destPos = 3;
      for (var j = 0; j < thisChunkHeight; j++) {
       var mask = 0;
       for (var k = 0; k < width; k++) {
        if (!mask) {
         var elem = src[srcPos++];
         mask = 128;
        }
        dest[destPos] = elem & mask ? 0 : 255;
        destPos += 4;
        mask >>= 1;
       }
      }
      ctx.putImageData(chunkImgData, 0, i * FULL_CHUNK_HEIGHT);
     }
    }
    function copyCtxState(sourceCtx, destCtx) {
     var properties = [
      'strokeStyle',
      'fillStyle',
      'fillRule',
      'globalAlpha',
      'lineWidth',
      'lineCap',
      'lineJoin',
      'miterLimit',
      'globalCompositeOperation',
      'font'
     ];
     for (var i = 0, ii = properties.length; i < ii; i++) {
      var property = properties[i];
      if (sourceCtx[property] !== undefined) {
       destCtx[property] = sourceCtx[property];
      }
     }
     if (sourceCtx.setLineDash !== undefined) {
      destCtx.setLineDash(sourceCtx.getLineDash());
      destCtx.lineDashOffset = sourceCtx.lineDashOffset;
     }
    }
    function composeSMaskBackdrop(bytes, r0, g0, b0) {
     var length = bytes.length;
     for (var i = 3; i < length; i += 4) {
      var alpha = bytes[i];
      if (alpha === 0) {
       bytes[i - 3] = r0;
       bytes[i - 2] = g0;
       bytes[i - 1] = b0;
      } else if (alpha < 255) {
       var alpha_ = 255 - alpha;
       bytes[i - 3] = bytes[i - 3] * alpha + r0 * alpha_ >> 8;
       bytes[i - 2] = bytes[i - 2] * alpha + g0 * alpha_ >> 8;
       bytes[i - 1] = bytes[i - 1] * alpha + b0 * alpha_ >> 8;
      }
     }
    }
    function composeSMaskAlpha(maskData, layerData, transferMap) {
     var length = maskData.length;
     var scale = 1 / 255;
     for (var i = 3; i < length; i += 4) {
      var alpha = transferMap ? transferMap[maskData[i]] : maskData[i];
      layerData[i] = layerData[i] * alpha * scale | 0;
     }
    }
    function composeSMaskLuminosity(maskData, layerData, transferMap) {
     var length = maskData.length;
     for (var i = 3; i < length; i += 4) {
      var y = maskData[i - 3] * 77 + maskData[i - 2] * 152 + maskData[i - 1] * 28;
      layerData[i] = transferMap ? layerData[i] * transferMap[y >> 8] >> 8 : layerData[i] * y >> 16;
     }
    }
    function genericComposeSMask(maskCtx, layerCtx, width, height, subtype, backdrop, transferMap) {
     var hasBackdrop = !!backdrop;
     var r0 = hasBackdrop ? backdrop[0] : 0;
     var g0 = hasBackdrop ? backdrop[1] : 0;
     var b0 = hasBackdrop ? backdrop[2] : 0;
     var composeFn;
     if (subtype === 'Luminosity') {
      composeFn = composeSMaskLuminosity;
     } else {
      composeFn = composeSMaskAlpha;
     }
     var PIXELS_TO_PROCESS = 1048576;
     var chunkSize = Math.min(height, Math.ceil(PIXELS_TO_PROCESS / width));
     for (var row = 0; row < height; row += chunkSize) {
      var chunkHeight = Math.min(chunkSize, height - row);
      var maskData = maskCtx.getImageData(0, row, width, chunkHeight);
      var layerData = layerCtx.getImageData(0, row, width, chunkHeight);
      if (hasBackdrop) {
       composeSMaskBackdrop(maskData.data, r0, g0, b0);
      }
      composeFn(maskData.data, layerData.data, transferMap);
      maskCtx.putImageData(layerData, 0, row);
     }
    }
    function composeSMask(ctx, smask, layerCtx) {
     var mask = smask.canvas;
     var maskCtx = smask.context;
     ctx.setTransform(smask.scaleX, 0, 0, smask.scaleY, smask.offsetX, smask.offsetY);
     var backdrop = smask.backdrop || null;
     if (!smask.transferMap && WebGLUtils.isEnabled) {
      var composed = WebGLUtils.composeSMask(layerCtx.canvas, mask, {
       subtype: smask.subtype,
       backdrop: backdrop
      });
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.drawImage(composed, smask.offsetX, smask.offsetY);
      return;
     }
     genericComposeSMask(maskCtx, layerCtx, mask.width, mask.height, smask.subtype, backdrop, smask.transferMap);
     ctx.drawImage(mask, 0, 0);
    }
    var LINE_CAP_STYLES = [
     'butt',
     'round',
     'square'
    ];
    var LINE_JOIN_STYLES = [
     'miter',
     'round',
     'bevel'
    ];
    var NORMAL_CLIP = {};
    var EO_CLIP = {};
    CanvasGraphics.prototype = {
     beginDrawing: function CanvasGraphics_beginDrawing(transform, viewport, transparency) {
      var width = this.ctx.canvas.width;
      var height = this.ctx.canvas.height;
      this.ctx.save();
      this.ctx.fillStyle = 'rgb(255, 255, 255)';
      this.ctx.fillRect(0, 0, width, height);
      this.ctx.restore();
      if (transparency) {
       var transparentCanvas = this.cachedCanvases.getCanvas('transparent', width, height, true);
       this.compositeCtx = this.ctx;
       this.transparentCanvas = transparentCanvas.canvas;
       this.ctx = transparentCanvas.context;
       this.ctx.save();
       this.ctx.transform.apply(this.ctx, this.compositeCtx.mozCurrentTransform);
      }
      this.ctx.save();
      if (transform) {
       this.ctx.transform.apply(this.ctx, transform);
      }
      this.ctx.transform.apply(this.ctx, viewport.transform);
      this.baseTransform = this.ctx.mozCurrentTransform.slice();
      if (this.imageLayer) {
       this.imageLayer.beginLayout();
      }
     },
     executeOperatorList: function CanvasGraphics_executeOperatorList(operatorList, executionStartIdx, continueCallback, stepper) {
      var argsArray = operatorList.argsArray;
      var fnArray = operatorList.fnArray;
      var i = executionStartIdx || 0;
      var argsArrayLen = argsArray.length;
      if (argsArrayLen === i) {
       return i;
      }
      var chunkOperations = argsArrayLen - i > EXECUTION_STEPS && typeof continueCallback === 'function';
      var endTime = chunkOperations ? Date.now() + EXECUTION_TIME : 0;
      var steps = 0;
      var commonObjs = this.commonObjs;
      var objs = this.objs;
      var fnId;
      while (true) {
       if (stepper !== undefined && i === stepper.nextBreakPoint) {
        stepper.breakIt(i, continueCallback);
        return i;
       }
       fnId = fnArray[i];
       if (fnId !== OPS.dependency) {
        this[fnId].apply(this, argsArray[i]);
       } else {
        var deps = argsArray[i];
        for (var n = 0, nn = deps.length; n < nn; n++) {
         var depObjId = deps[n];
         var common = depObjId[0] === 'g' && depObjId[1] === '_';
         var objsPool = common ? commonObjs : objs;
         if (!objsPool.isResolved(depObjId)) {
          objsPool.get(depObjId, continueCallback);
          return i;
         }
        }
       }
       i++;
       if (i === argsArrayLen) {
        return i;
       }
       if (chunkOperations && ++steps > EXECUTION_STEPS) {
        if (Date.now() > endTime) {
         continueCallback();
         return i;
        }
        steps = 0;
       }
      }
     },
     endDrawing: function CanvasGraphics_endDrawing() {
      if (this.current.activeSMask !== null) {
       this.endSMaskGroup();
      }
      this.ctx.restore();
      if (this.transparentCanvas) {
       this.ctx = this.compositeCtx;
       this.ctx.save();
       this.ctx.setTransform(1, 0, 0, 1, 0, 0);
       this.ctx.drawImage(this.transparentCanvas, 0, 0);
       this.ctx.restore();
       this.transparentCanvas = null;
      }
      this.cachedCanvases.clear();
      WebGLUtils.clear();
      if (this.imageLayer) {
       this.imageLayer.endLayout();
      }
     },
     setLineWidth: function CanvasGraphics_setLineWidth(width) {
      this.current.lineWidth = width;
      this.ctx.lineWidth = width;
     },
     setLineCap: function CanvasGraphics_setLineCap(style) {
      this.ctx.lineCap = LINE_CAP_STYLES[style];
     },
     setLineJoin: function CanvasGraphics_setLineJoin(style) {
      this.ctx.lineJoin = LINE_JOIN_STYLES[style];
     },
     setMiterLimit: function CanvasGraphics_setMiterLimit(limit) {
      this.ctx.miterLimit = limit;
     },
     setDash: function CanvasGraphics_setDash(dashArray, dashPhase) {
      var ctx = this.ctx;
      if (ctx.setLineDash !== undefined) {
       ctx.setLineDash(dashArray);
       ctx.lineDashOffset = dashPhase;
      }
     },
     setRenderingIntent: function CanvasGraphics_setRenderingIntent(intent) {
     },
     setFlatness: function CanvasGraphics_setFlatness(flatness) {
     },
     setGState: function CanvasGraphics_setGState(states) {
      for (var i = 0, ii = states.length; i < ii; i++) {
       var state = states[i];
       var key = state[0];
       var value = state[1];
       switch (key) {
       case 'LW':
        this.setLineWidth(value);
        break;
       case 'LC':
        this.setLineCap(value);
        break;
       case 'LJ':
        this.setLineJoin(value);
        break;
       case 'ML':
        this.setMiterLimit(value);
        break;
       case 'D':
        this.setDash(value[0], value[1]);
        break;
       case 'RI':
        this.setRenderingIntent(value);
        break;
       case 'FL':
        this.setFlatness(value);
        break;
       case 'Font':
        this.setFont(value[0], value[1]);
        break;
       case 'CA':
        this.current.strokeAlpha = state[1];
        break;
       case 'ca':
        this.current.fillAlpha = state[1];
        this.ctx.globalAlpha = state[1];
        break;
       case 'BM':
        if (value && value.name && value.name !== 'Normal') {
         var mode = value.name.replace(/([A-Z])/g, function (c) {
          return '-' + c.toLowerCase();
         }).substring(1);
         this.ctx.globalCompositeOperation = mode;
         if (this.ctx.globalCompositeOperation !== mode) {
          warn('globalCompositeOperation "' + mode + '" is not supported');
         }
        } else {
         this.ctx.globalCompositeOperation = 'source-over';
        }
        break;
       case 'SMask':
        if (this.current.activeSMask) {
         if (this.stateStack.length > 0 && this.stateStack[this.stateStack.length - 1].activeSMask === this.current.activeSMask) {
          this.suspendSMaskGroup();
         } else {
          this.endSMaskGroup();
         }
        }
        this.current.activeSMask = value ? this.tempSMask : null;
        if (this.current.activeSMask) {
         this.beginSMaskGroup();
        }
        this.tempSMask = null;
        break;
       }
      }
     },
     beginSMaskGroup: function CanvasGraphics_beginSMaskGroup() {
      var activeSMask = this.current.activeSMask;
      var drawnWidth = activeSMask.canvas.width;
      var drawnHeight = activeSMask.canvas.height;
      var cacheId = 'smaskGroupAt' + this.groupLevel;
      var scratchCanvas = this.cachedCanvases.getCanvas(cacheId, drawnWidth, drawnHeight, true);
      var currentCtx = this.ctx;
      var currentTransform = currentCtx.mozCurrentTransform;
      this.ctx.save();
      var groupCtx = scratchCanvas.context;
      groupCtx.scale(1 / activeSMask.scaleX, 1 / activeSMask.scaleY);
      groupCtx.translate(-activeSMask.offsetX, -activeSMask.offsetY);
      groupCtx.transform.apply(groupCtx, currentTransform);
      activeSMask.startTransformInverse = groupCtx.mozCurrentTransformInverse;
      copyCtxState(currentCtx, groupCtx);
      this.ctx = groupCtx;
      this.setGState([
       [
        'BM',
        'Normal'
       ],
       [
        'ca',
        1
       ],
       [
        'CA',
        1
       ]
      ]);
      this.groupStack.push(currentCtx);
      this.groupLevel++;
     },
     suspendSMaskGroup: function CanvasGraphics_endSMaskGroup() {
      var groupCtx = this.ctx;
      this.groupLevel--;
      this.ctx = this.groupStack.pop();
      composeSMask(this.ctx, this.current.activeSMask, groupCtx);
      this.ctx.restore();
      this.ctx.save();
      copyCtxState(groupCtx, this.ctx);
      this.current.resumeSMaskCtx = groupCtx;
      var deltaTransform = Util.transform(this.current.activeSMask.startTransformInverse, groupCtx.mozCurrentTransform);
      this.ctx.transform.apply(this.ctx, deltaTransform);
      groupCtx.save();
      groupCtx.setTransform(1, 0, 0, 1, 0, 0);
      groupCtx.clearRect(0, 0, groupCtx.canvas.width, groupCtx.canvas.height);
      groupCtx.restore();
     },
     resumeSMaskGroup: function CanvasGraphics_endSMaskGroup() {
      var groupCtx = this.current.resumeSMaskCtx;
      var currentCtx = this.ctx;
      this.ctx = groupCtx;
      this.groupStack.push(currentCtx);
      this.groupLevel++;
     },
     endSMaskGroup: function CanvasGraphics_endSMaskGroup() {
      var groupCtx = this.ctx;
      this.groupLevel--;
      this.ctx = this.groupStack.pop();
      composeSMask(this.ctx, this.current.activeSMask, groupCtx);
      this.ctx.restore();
      copyCtxState(groupCtx, this.ctx);
      var deltaTransform = Util.transform(this.current.activeSMask.startTransformInverse, groupCtx.mozCurrentTransform);
      this.ctx.transform.apply(this.ctx, deltaTransform);
     },
     save: function CanvasGraphics_save() {
      this.ctx.save();
      var old = this.current;
      this.stateStack.push(old);
      this.current = old.clone();
      this.current.resumeSMaskCtx = null;
     },
     restore: function CanvasGraphics_restore() {
      if (this.current.resumeSMaskCtx) {
       this.resumeSMaskGroup();
      }
      if (this.current.activeSMask !== null && (this.stateStack.length === 0 || this.stateStack[this.stateStack.length - 1].activeSMask !== this.current.activeSMask)) {
       this.endSMaskGroup();
      }
      if (this.stateStack.length !== 0) {
       this.current = this.stateStack.pop();
       this.ctx.restore();
       this.pendingClip = null;
       this.cachedGetSinglePixelWidth = null;
      }
     },
     transform: function CanvasGraphics_transform(a, b, c, d, e, f) {
      this.ctx.transform(a, b, c, d, e, f);
      this.cachedGetSinglePixelWidth = null;
     },
     constructPath: function CanvasGraphics_constructPath(ops, args) {
      var ctx = this.ctx;
      var current = this.current;
      var x = current.x, y = current.y;
      for (var i = 0, j = 0, ii = ops.length; i < ii; i++) {
       switch (ops[i] | 0) {
       case OPS.rectangle:
        x = args[j++];
        y = args[j++];
        var width = args[j++];
        var height = args[j++];
        if (width === 0) {
         width = this.getSinglePixelWidth();
        }
        if (height === 0) {
         height = this.getSinglePixelWidth();
        }
        var xw = x + width;
        var yh = y + height;
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(xw, y);
        this.ctx.lineTo(xw, yh);
        this.ctx.lineTo(x, yh);
        this.ctx.lineTo(x, y);
        this.ctx.closePath();
        break;
       case OPS.moveTo:
        x = args[j++];
        y = args[j++];
        ctx.moveTo(x, y);
        break;
       case OPS.lineTo:
        x = args[j++];
        y = args[j++];
        ctx.lineTo(x, y);
        break;
       case OPS.curveTo:
        x = args[j + 4];
        y = args[j + 5];
        ctx.bezierCurveTo(args[j], args[j + 1], args[j + 2], args[j + 3], x, y);
        j += 6;
        break;
       case OPS.curveTo2:
        ctx.bezierCurveTo(x, y, args[j], args[j + 1], args[j + 2], args[j + 3]);
        x = args[j + 2];
        y = args[j + 3];
        j += 4;
        break;
       case OPS.curveTo3:
        x = args[j + 2];
        y = args[j + 3];
        ctx.bezierCurveTo(args[j], args[j + 1], x, y, x, y);
        j += 4;
        break;
       case OPS.closePath:
        ctx.closePath();
        break;
       }
      }
      current.setCurrentPoint(x, y);
     },
     closePath: function CanvasGraphics_closePath() {
      this.ctx.closePath();
     },
     stroke: function CanvasGraphics_stroke(consumePath) {
      consumePath = typeof consumePath !== 'undefined' ? consumePath : true;
      var ctx = this.ctx;
      var strokeColor = this.current.strokeColor;
      ctx.lineWidth = Math.max(this.getSinglePixelWidth() * MIN_WIDTH_FACTOR, this.current.lineWidth);
      ctx.globalAlpha = this.current.strokeAlpha;
      if (strokeColor && strokeColor.hasOwnProperty('type') && strokeColor.type === 'Pattern') {
       ctx.save();
       ctx.strokeStyle = strokeColor.getPattern(ctx, this);
       ctx.stroke();
       ctx.restore();
      } else {
       ctx.stroke();
      }
      if (consumePath) {
       this.consumePath();
      }
      ctx.globalAlpha = this.current.fillAlpha;
     },
     closeStroke: function CanvasGraphics_closeStroke() {
      this.closePath();
      this.stroke();
     },
     fill: function CanvasGraphics_fill(consumePath) {
      consumePath = typeof consumePath !== 'undefined' ? consumePath : true;
      var ctx = this.ctx;
      var fillColor = this.current.fillColor;
      var isPatternFill = this.current.patternFill;
      var needRestore = false;
      if (isPatternFill) {
       ctx.save();
       if (this.baseTransform) {
        ctx.setTransform.apply(ctx, this.baseTransform);
       }
       ctx.fillStyle = fillColor.getPattern(ctx, this);
       needRestore = true;
      }
      if (this.pendingEOFill) {
       if (ctx.mozFillRule !== undefined) {
        ctx.mozFillRule = 'evenodd';
        ctx.fill();
        ctx.mozFillRule = 'nonzero';
       } else {
        ctx.fill('evenodd');
       }
       this.pendingEOFill = false;
      } else {
       ctx.fill();
      }
      if (needRestore) {
       ctx.restore();
      }
      if (consumePath) {
       this.consumePath();
      }
     },
     eoFill: function CanvasGraphics_eoFill() {
      this.pendingEOFill = true;
      this.fill();
     },
     fillStroke: function CanvasGraphics_fillStroke() {
      this.fill(false);
      this.stroke(false);
      this.consumePath();
     },
     eoFillStroke: function CanvasGraphics_eoFillStroke() {
      this.pendingEOFill = true;
      this.fillStroke();
     },
     closeFillStroke: function CanvasGraphics_closeFillStroke() {
      this.closePath();
      this.fillStroke();
     },
     closeEOFillStroke: function CanvasGraphics_closeEOFillStroke() {
      this.pendingEOFill = true;
      this.closePath();
      this.fillStroke();
     },
     endPath: function CanvasGraphics_endPath() {
      this.consumePath();
     },
     clip: function CanvasGraphics_clip() {
      this.pendingClip = NORMAL_CLIP;
     },
     eoClip: function CanvasGraphics_eoClip() {
      this.pendingClip = EO_CLIP;
     },
     beginText: function CanvasGraphics_beginText() {
      this.current.textMatrix = IDENTITY_MATRIX;
      this.current.textMatrixScale = 1;
      this.current.x = this.current.lineX = 0;
      this.current.y = this.current.lineY = 0;
     },
     endText: function CanvasGraphics_endText() {
      var paths = this.pendingTextPaths;
      var ctx = this.ctx;
      if (paths === undefined) {
       ctx.beginPath();
       return;
      }
      ctx.save();
      ctx.beginPath();
      for (var i = 0; i < paths.length; i++) {
       var path = paths[i];
       ctx.setTransform.apply(ctx, path.transform);
       ctx.translate(path.x, path.y);
       path.addToPath(ctx, path.fontSize);
      }
      ctx.restore();
      ctx.clip();
      ctx.beginPath();
      delete this.pendingTextPaths;
     },
     setCharSpacing: function CanvasGraphics_setCharSpacing(spacing) {
      this.current.charSpacing = spacing;
     },
     setWordSpacing: function CanvasGraphics_setWordSpacing(spacing) {
      this.current.wordSpacing = spacing;
     },
     setHScale: function CanvasGraphics_setHScale(scale) {
      this.current.textHScale = scale / 100;
     },
     setLeading: function CanvasGraphics_setLeading(leading) {
      this.current.leading = -leading;
     },
     setFont: function CanvasGraphics_setFont(fontRefName, size) {
      var fontObj = this.commonObjs.get(fontRefName);
      var current = this.current;
      if (!fontObj) {
       error('Can\'t find font for ' + fontRefName);
      }
      current.fontMatrix = fontObj.fontMatrix ? fontObj.fontMatrix : FONT_IDENTITY_MATRIX;
      if (current.fontMatrix[0] === 0 || current.fontMatrix[3] === 0) {
       warn('Invalid font matrix for font ' + fontRefName);
      }
      if (size < 0) {
       size = -size;
       current.fontDirection = -1;
      } else {
       current.fontDirection = 1;
      }
      this.current.font = fontObj;
      this.current.fontSize = size;
      if (fontObj.isType3Font) {
       return;
      }
      var name = fontObj.loadedName || 'sans-serif';
      var bold = fontObj.black ? '900' : fontObj.bold ? 'bold' : 'normal';
      var italic = fontObj.italic ? 'italic' : 'normal';
      var typeface = '"' + name + '", ' + fontObj.fallbackName;
      var browserFontSize = size < MIN_FONT_SIZE ? MIN_FONT_SIZE : size > MAX_FONT_SIZE ? MAX_FONT_SIZE : size;
      this.current.fontSizeScale = size / browserFontSize;
      var rule = italic + ' ' + bold + ' ' + browserFontSize + 'px ' + typeface;
      this.ctx.font = rule;
     },
     setTextRenderingMode: function CanvasGraphics_setTextRenderingMode(mode) {
      this.current.textRenderingMode = mode;
     },
     setTextRise: function CanvasGraphics_setTextRise(rise) {
      this.current.textRise = rise;
     },
     moveText: function CanvasGraphics_moveText(x, y) {
      this.current.x = this.current.lineX += x;
      this.current.y = this.current.lineY += y;
     },
     setLeadingMoveText: function CanvasGraphics_setLeadingMoveText(x, y) {
      this.setLeading(-y);
      this.moveText(x, y);
     },
     setTextMatrix: function CanvasGraphics_setTextMatrix(a, b, c, d, e, f) {
      this.current.textMatrix = [
       a,
       b,
       c,
       d,
       e,
       f
      ];
      this.current.textMatrixScale = Math.sqrt(a * a + b * b);
      this.current.x = this.current.lineX = 0;
      this.current.y = this.current.lineY = 0;
     },
     nextLine: function CanvasGraphics_nextLine() {
      this.moveText(0, this.current.leading);
     },
     paintChar: function CanvasGraphics_paintChar(character, x, y) {
      var ctx = this.ctx;
      var current = this.current;
      var font = current.font;
      var textRenderingMode = current.textRenderingMode;
      var fontSize = current.fontSize / current.fontSizeScale;
      var fillStrokeMode = textRenderingMode & TextRenderingMode.FILL_STROKE_MASK;
      var isAddToPathSet = !!(textRenderingMode & TextRenderingMode.ADD_TO_PATH_FLAG);
      var addToPath;
      if (font.disableFontFace || isAddToPathSet) {
       addToPath = font.getPathGenerator(this.commonObjs, character);
      }
      if (font.disableFontFace) {
       ctx.save();
       ctx.translate(x, y);
       ctx.beginPath();
       addToPath(ctx, fontSize);
       if (fillStrokeMode === TextRenderingMode.FILL || fillStrokeMode === TextRenderingMode.FILL_STROKE) {
        ctx.fill();
       }
       if (fillStrokeMode === TextRenderingMode.STROKE || fillStrokeMode === TextRenderingMode.FILL_STROKE) {
        ctx.stroke();
       }
       ctx.restore();
      } else {
       if (fillStrokeMode === TextRenderingMode.FILL || fillStrokeMode === TextRenderingMode.FILL_STROKE) {
        ctx.fillText(character, x, y);
       }
       if (fillStrokeMode === TextRenderingMode.STROKE || fillStrokeMode === TextRenderingMode.FILL_STROKE) {
        ctx.strokeText(character, x, y);
       }
      }
      if (isAddToPathSet) {
       var paths = this.pendingTextPaths || (this.pendingTextPaths = []);
       paths.push({
        transform: ctx.mozCurrentTransform,
        x: x,
        y: y,
        fontSize: fontSize,
        addToPath: addToPath
       });
      }
     },
     get isFontSubpixelAAEnabled() {
      var ctx = document.createElement('canvas').getContext('2d');
      ctx.scale(1.5, 1);
      ctx.fillText('I', 0, 10);
      var data = ctx.getImageData(0, 0, 10, 10).data;
      var enabled = false;
      for (var i = 3; i < data.length; i += 4) {
       if (data[i] > 0 && data[i] < 255) {
        enabled = true;
        break;
       }
      }
      return shadow(this, 'isFontSubpixelAAEnabled', enabled);
     },
     showText: function CanvasGraphics_showText(glyphs) {
      var current = this.current;
      var font = current.font;
      if (font.isType3Font) {
       return this.showType3Text(glyphs);
      }
      var fontSize = current.fontSize;
      if (fontSize === 0) {
       return;
      }
      var ctx = this.ctx;
      var fontSizeScale = current.fontSizeScale;
      var charSpacing = current.charSpacing;
      var wordSpacing = current.wordSpacing;
      var fontDirection = current.fontDirection;
      var textHScale = current.textHScale * fontDirection;
      var glyphsLength = glyphs.length;
      var vertical = font.vertical;
      var spacingDir = vertical ? 1 : -1;
      var defaultVMetrics = font.defaultVMetrics;
      var widthAdvanceScale = fontSize * current.fontMatrix[0];
      var simpleFillText = current.textRenderingMode === TextRenderingMode.FILL && !font.disableFontFace;
      ctx.save();
      ctx.transform.apply(ctx, current.textMatrix);
      ctx.translate(current.x, current.y + current.textRise);
      if (current.patternFill) {
       ctx.fillStyle = current.fillColor.getPattern(ctx, this);
      }
      if (fontDirection > 0) {
       ctx.scale(textHScale, -1);
      } else {
       ctx.scale(textHScale, 1);
      }
      var lineWidth = current.lineWidth;
      var scale = current.textMatrixScale;
      if (scale === 0 || lineWidth === 0) {
       var fillStrokeMode = current.textRenderingMode & TextRenderingMode.FILL_STROKE_MASK;
       if (fillStrokeMode === TextRenderingMode.STROKE || fillStrokeMode === TextRenderingMode.FILL_STROKE) {
        this.cachedGetSinglePixelWidth = null;
        lineWidth = this.getSinglePixelWidth() * MIN_WIDTH_FACTOR;
       }
      } else {
       lineWidth /= scale;
      }
      if (fontSizeScale !== 1.0) {
       ctx.scale(fontSizeScale, fontSizeScale);
       lineWidth /= fontSizeScale;
      }
      ctx.lineWidth = lineWidth;
      var x = 0, i;
      for (i = 0; i < glyphsLength; ++i) {
       var glyph = glyphs[i];
       if (isNum(glyph)) {
        x += spacingDir * glyph * fontSize / 1000;
        continue;
       }
       var restoreNeeded = false;
       var spacing = (glyph.isSpace ? wordSpacing : 0) + charSpacing;
       var character = glyph.fontChar;
       var accent = glyph.accent;
       var scaledX, scaledY, scaledAccentX, scaledAccentY;
       var width = glyph.width;
       if (vertical) {
        var vmetric, vx, vy;
        vmetric = glyph.vmetric || defaultVMetrics;
        vx = glyph.vmetric ? vmetric[1] : width * 0.5;
        vx = -vx * widthAdvanceScale;
        vy = vmetric[2] * widthAdvanceScale;
        width = vmetric ? -vmetric[0] : width;
        scaledX = vx / fontSizeScale;
        scaledY = (x + vy) / fontSizeScale;
       } else {
        scaledX = x / fontSizeScale;
        scaledY = 0;
       }
       if (font.remeasure && width > 0) {
        var measuredWidth = ctx.measureText(character).width * 1000 / fontSize * fontSizeScale;
        if (width < measuredWidth && this.isFontSubpixelAAEnabled) {
         var characterScaleX = width / measuredWidth;
         restoreNeeded = true;
         ctx.save();
         ctx.scale(characterScaleX, 1);
         scaledX /= characterScaleX;
        } else if (width !== measuredWidth) {
         scaledX += (width - measuredWidth) / 2000 * fontSize / fontSizeScale;
        }
       }
       if (glyph.isInFont || font.missingFile) {
        if (simpleFillText && !accent) {
         ctx.fillText(character, scaledX, scaledY);
        } else {
         this.paintChar(character, scaledX, scaledY);
         if (accent) {
          scaledAccentX = scaledX + accent.offset.x / fontSizeScale;
          scaledAccentY = scaledY - accent.offset.y / fontSizeScale;
          this.paintChar(accent.fontChar, scaledAccentX, scaledAccentY);
         }
        }
       }
       var charWidth = width * widthAdvanceScale + spacing * fontDirection;
       x += charWidth;
       if (restoreNeeded) {
        ctx.restore();
       }
      }
      if (vertical) {
       current.y -= x * textHScale;
      } else {
       current.x += x * textHScale;
      }
      ctx.restore();
     },
     showType3Text: function CanvasGraphics_showType3Text(glyphs) {
      var ctx = this.ctx;
      var current = this.current;
      var font = current.font;
      var fontSize = current.fontSize;
      var fontDirection = current.fontDirection;
      var spacingDir = font.vertical ? 1 : -1;
      var charSpacing = current.charSpacing;
      var wordSpacing = current.wordSpacing;
      var textHScale = current.textHScale * fontDirection;
      var fontMatrix = current.fontMatrix || FONT_IDENTITY_MATRIX;
      var glyphsLength = glyphs.length;
      var isTextInvisible = current.textRenderingMode === TextRenderingMode.INVISIBLE;
      var i, glyph, width, spacingLength;
      if (isTextInvisible || fontSize === 0) {
       return;
      }
      this.cachedGetSinglePixelWidth = null;
      ctx.save();
      ctx.transform.apply(ctx, current.textMatrix);
      ctx.translate(current.x, current.y);
      ctx.scale(textHScale, fontDirection);
      for (i = 0; i < glyphsLength; ++i) {
       glyph = glyphs[i];
       if (isNum(glyph)) {
        spacingLength = spacingDir * glyph * fontSize / 1000;
        this.ctx.translate(spacingLength, 0);
        current.x += spacingLength * textHScale;
        continue;
       }
       var spacing = (glyph.isSpace ? wordSpacing : 0) + charSpacing;
       var operatorList = font.charProcOperatorList[glyph.operatorListId];
       if (!operatorList) {
        warn('Type3 character \"' + glyph.operatorListId + '\" is not available');
        continue;
       }
       this.processingType3 = glyph;
       this.save();
       ctx.scale(fontSize, fontSize);
       ctx.transform.apply(ctx, fontMatrix);
       this.executeOperatorList(operatorList);
       this.restore();
       var transformed = Util.applyTransform([
        glyph.width,
        0
       ], fontMatrix);
       width = transformed[0] * fontSize + spacing;
       ctx.translate(width, 0);
       current.x += width * textHScale;
      }
      ctx.restore();
      this.processingType3 = null;
     },
     setCharWidth: function CanvasGraphics_setCharWidth(xWidth, yWidth) {
     },
     setCharWidthAndBounds: function CanvasGraphics_setCharWidthAndBounds(xWidth, yWidth, llx, lly, urx, ury) {
      this.ctx.rect(llx, lly, urx - llx, ury - lly);
      this.clip();
      this.endPath();
     },
     getColorN_Pattern: function CanvasGraphics_getColorN_Pattern(IR) {
      var pattern;
      if (IR[0] === 'TilingPattern') {
       var color = IR[1];
       var baseTransform = this.baseTransform || this.ctx.mozCurrentTransform.slice();
       var self = this;
       var canvasGraphicsFactory = {
        createCanvasGraphics: function (ctx) {
         return new CanvasGraphics(ctx, self.commonObjs, self.objs);
        }
       };
       pattern = new TilingPattern(IR, color, this.ctx, canvasGraphicsFactory, baseTransform);
      } else {
       pattern = getShadingPatternFromIR(IR);
      }
      return pattern;
     },
     setStrokeColorN: function CanvasGraphics_setStrokeColorN()
      {
       this.current.strokeColor = this.getColorN_Pattern(arguments);
      },
     setFillColorN: function CanvasGraphics_setFillColorN()
      {
       this.current.fillColor = this.getColorN_Pattern(arguments);
       this.current.patternFill = true;
      },
     setStrokeRGBColor: function CanvasGraphics_setStrokeRGBColor(r, g, b) {
      var color = Util.makeCssRgb(r, g, b);
      this.ctx.strokeStyle = color;
      this.current.strokeColor = color;
     },
     setFillRGBColor: function CanvasGraphics_setFillRGBColor(r, g, b) {
      var color = Util.makeCssRgb(r, g, b);
      this.ctx.fillStyle = color;
      this.current.fillColor = color;
      this.current.patternFill = false;
     },
     shadingFill: function CanvasGraphics_shadingFill(patternIR) {
      var ctx = this.ctx;
      this.save();
      var pattern = getShadingPatternFromIR(patternIR);
      ctx.fillStyle = pattern.getPattern(ctx, this, true);
      var inv = ctx.mozCurrentTransformInverse;
      if (inv) {
       var canvas = ctx.canvas;
       var width = canvas.width;
       var height = canvas.height;
       var bl = Util.applyTransform([
        0,
        0
       ], inv);
       var br = Util.applyTransform([
        0,
        height
       ], inv);
       var ul = Util.applyTransform([
        width,
        0
       ], inv);
       var ur = Util.applyTransform([
        width,
        height
       ], inv);
       var x0 = Math.min(bl[0], br[0], ul[0], ur[0]);
       var y0 = Math.min(bl[1], br[1], ul[1], ur[1]);
       var x1 = Math.max(bl[0], br[0], ul[0], ur[0]);
       var y1 = Math.max(bl[1], br[1], ul[1], ur[1]);
       this.ctx.fillRect(x0, y0, x1 - x0, y1 - y0);
      } else {
       this.ctx.fillRect(-1e10, -1e10, 2e10, 2e10);
      }
      this.restore();
     },
     beginInlineImage: function CanvasGraphics_beginInlineImage() {
      error('Should not call beginInlineImage');
     },
     beginImageData: function CanvasGraphics_beginImageData() {
      error('Should not call beginImageData');
     },
     paintFormXObjectBegin: function CanvasGraphics_paintFormXObjectBegin(matrix, bbox) {
      this.save();
      this.baseTransformStack.push(this.baseTransform);
      if (isArray(matrix) && matrix.length === 6) {
       this.transform.apply(this, matrix);
      }
      this.baseTransform = this.ctx.mozCurrentTransform;
      if (isArray(bbox) && bbox.length === 4) {
       var width = bbox[2] - bbox[0];
       var height = bbox[3] - bbox[1];
       this.ctx.rect(bbox[0], bbox[1], width, height);
       this.clip();
       this.endPath();
      }
     },
     paintFormXObjectEnd: function CanvasGraphics_paintFormXObjectEnd() {
      this.restore();
      this.baseTransform = this.baseTransformStack.pop();
     },
     beginGroup: function CanvasGraphics_beginGroup(group) {
      this.save();
      var currentCtx = this.ctx;
      if (!group.isolated) {
       info('TODO: Support non-isolated groups.');
      }
      if (group.knockout) {
       warn('Knockout groups not supported.');
      }
      var currentTransform = currentCtx.mozCurrentTransform;
      if (group.matrix) {
       currentCtx.transform.apply(currentCtx, group.matrix);
      }
      assert(group.bbox, 'Bounding box is required.');
      var bounds = Util.getAxialAlignedBoundingBox(group.bbox, currentCtx.mozCurrentTransform);
      var canvasBounds = [
       0,
       0,
       currentCtx.canvas.width,
       currentCtx.canvas.height
      ];
      bounds = Util.intersect(bounds, canvasBounds) || [
       0,
       0,
       0,
       0
      ];
      var offsetX = Math.floor(bounds[0]);
      var offsetY = Math.floor(bounds[1]);
      var drawnWidth = Math.max(Math.ceil(bounds[2]) - offsetX, 1);
      var drawnHeight = Math.max(Math.ceil(bounds[3]) - offsetY, 1);
      var scaleX = 1, scaleY = 1;
      if (drawnWidth > MAX_GROUP_SIZE) {
       scaleX = drawnWidth / MAX_GROUP_SIZE;
       drawnWidth = MAX_GROUP_SIZE;
      }
      if (drawnHeight > MAX_GROUP_SIZE) {
       scaleY = drawnHeight / MAX_GROUP_SIZE;
       drawnHeight = MAX_GROUP_SIZE;
      }
      var cacheId = 'groupAt' + this.groupLevel;
      if (group.smask) {
       cacheId += '_smask_' + this.smaskCounter++ % 2;
      }
      var scratchCanvas = this.cachedCanvases.getCanvas(cacheId, drawnWidth, drawnHeight, true);
      var groupCtx = scratchCanvas.context;
      groupCtx.scale(1 / scaleX, 1 / scaleY);
      groupCtx.translate(-offsetX, -offsetY);
      groupCtx.transform.apply(groupCtx, currentTransform);
      if (group.smask) {
       this.smaskStack.push({
        canvas: scratchCanvas.canvas,
        context: groupCtx,
        offsetX: offsetX,
        offsetY: offsetY,
        scaleX: scaleX,
        scaleY: scaleY,
        subtype: group.smask.subtype,
        backdrop: group.smask.backdrop,
        transferMap: group.smask.transferMap || null,
        startTransformInverse: null
       });
      } else
       {
        currentCtx.setTransform(1, 0, 0, 1, 0, 0);
        currentCtx.translate(offsetX, offsetY);
        currentCtx.scale(scaleX, scaleY);
       }
      copyCtxState(currentCtx, groupCtx);
      this.ctx = groupCtx;
      this.setGState([
       [
        'BM',
        'Normal'
       ],
       [
        'ca',
        1
       ],
       [
        'CA',
        1
       ]
      ]);
      this.groupStack.push(currentCtx);
      this.groupLevel++;
      this.current.activeSMask = null;
     },
     endGroup: function CanvasGraphics_endGroup(group) {
      this.groupLevel--;
      var groupCtx = this.ctx;
      this.ctx = this.groupStack.pop();
      if (this.ctx.imageSmoothingEnabled !== undefined) {
       this.ctx.imageSmoothingEnabled = false;
      } else {
       this.ctx.mozImageSmoothingEnabled = false;
      }
      if (group.smask) {
       this.tempSMask = this.smaskStack.pop();
      } else {
       this.ctx.drawImage(groupCtx.canvas, 0, 0);
      }
      this.restore();
     },
     beginAnnotations: function CanvasGraphics_beginAnnotations() {
      this.save();
      this.current = new CanvasExtraState();
      if (this.baseTransform) {
       this.ctx.setTransform.apply(this.ctx, this.baseTransform);
      }
     },
     endAnnotations: function CanvasGraphics_endAnnotations() {
      this.restore();
     },
     beginAnnotation: function CanvasGraphics_beginAnnotation(rect, transform, matrix) {
      this.save();
      if (isArray(rect) && rect.length === 4) {
       var width = rect[2] - rect[0];
       var height = rect[3] - rect[1];
       this.ctx.rect(rect[0], rect[1], width, height);
       this.clip();
       this.endPath();
      }
      this.transform.apply(this, transform);
      this.transform.apply(this, matrix);
     },
     endAnnotation: function CanvasGraphics_endAnnotation() {
      this.restore();
     },
     paintJpegXObject: function CanvasGraphics_paintJpegXObject(objId, w, h) {
      var domImage = this.objs.get(objId);
      if (!domImage) {
       warn('Dependent image isn\'t ready yet');
       return;
      }
      this.save();
      var ctx = this.ctx;
      ctx.scale(1 / w, -1 / h);
      ctx.drawImage(domImage, 0, 0, domImage.width, domImage.height, 0, -h, w, h);
      if (this.imageLayer) {
       var currentTransform = ctx.mozCurrentTransformInverse;
       var position = this.getCanvasPosition(0, 0);
       this.imageLayer.appendImage({
        objId: objId,
        left: position[0],
        top: position[1],
        width: w / currentTransform[0],
        height: h / currentTransform[3]
       });
      }
      this.restore();
     },
     paintImageMaskXObject: function CanvasGraphics_paintImageMaskXObject(img) {
      var ctx = this.ctx;
      var width = img.width, height = img.height;
      var fillColor = this.current.fillColor;
      var isPatternFill = this.current.patternFill;
      var glyph = this.processingType3;
      if (COMPILE_TYPE3_GLYPHS && glyph && glyph.compiled === undefined) {
       if (width <= MAX_SIZE_TO_COMPILE && height <= MAX_SIZE_TO_COMPILE) {
        glyph.compiled = compileType3Glyph({
         data: img.data,
         width: width,
         height: height
        });
       } else {
        glyph.compiled = null;
       }
      }
      if (glyph && glyph.compiled) {
       glyph.compiled(ctx);
       return;
      }
      var maskCanvas = this.cachedCanvases.getCanvas('maskCanvas', width, height);
      var maskCtx = maskCanvas.context;
      maskCtx.save();
      putBinaryImageMask(maskCtx, img);
      maskCtx.globalCompositeOperation = 'source-in';
      maskCtx.fillStyle = isPatternFill ? fillColor.getPattern(maskCtx, this) : fillColor;
      maskCtx.fillRect(0, 0, width, height);
      maskCtx.restore();
      this.paintInlineImageXObject(maskCanvas.canvas);
     },
     paintImageMaskXObjectRepeat: function CanvasGraphics_paintImageMaskXObjectRepeat(imgData, scaleX, scaleY, positions) {
      var width = imgData.width;
      var height = imgData.height;
      var fillColor = this.current.fillColor;
      var isPatternFill = this.current.patternFill;
      var maskCanvas = this.cachedCanvases.getCanvas('maskCanvas', width, height);
      var maskCtx = maskCanvas.context;
      maskCtx.save();
      putBinaryImageMask(maskCtx, imgData);
      maskCtx.globalCompositeOperation = 'source-in';
      maskCtx.fillStyle = isPatternFill ? fillColor.getPattern(maskCtx, this) : fillColor;
      maskCtx.fillRect(0, 0, width, height);
      maskCtx.restore();
      var ctx = this.ctx;
      for (var i = 0, ii = positions.length; i < ii; i += 2) {
       ctx.save();
       ctx.transform(scaleX, 0, 0, scaleY, positions[i], positions[i + 1]);
       ctx.scale(1, -1);
       ctx.drawImage(maskCanvas.canvas, 0, 0, width, height, 0, -1, 1, 1);
       ctx.restore();
      }
     },
     paintImageMaskXObjectGroup: function CanvasGraphics_paintImageMaskXObjectGroup(images) {
      var ctx = this.ctx;
      var fillColor = this.current.fillColor;
      var isPatternFill = this.current.patternFill;
      for (var i = 0, ii = images.length; i < ii; i++) {
       var image = images[i];
       var width = image.width, height = image.height;
       var maskCanvas = this.cachedCanvases.getCanvas('maskCanvas', width, height);
       var maskCtx = maskCanvas.context;
       maskCtx.save();
       putBinaryImageMask(maskCtx, image);
       maskCtx.globalCompositeOperation = 'source-in';
       maskCtx.fillStyle = isPatternFill ? fillColor.getPattern(maskCtx, this) : fillColor;
       maskCtx.fillRect(0, 0, width, height);
       maskCtx.restore();
       ctx.save();
       ctx.transform.apply(ctx, image.transform);
       ctx.scale(1, -1);
       ctx.drawImage(maskCanvas.canvas, 0, 0, width, height, 0, -1, 1, 1);
       ctx.restore();
      }
     },
     paintImageXObject: function CanvasGraphics_paintImageXObject(objId) {
      var imgData = this.objs.get(objId);
      if (!imgData) {
       warn('Dependent image isn\'t ready yet');
       return;
      }
      this.paintInlineImageXObject(imgData);
     },
     paintImageXObjectRepeat: function CanvasGraphics_paintImageXObjectRepeat(objId, scaleX, scaleY, positions) {
      var imgData = this.objs.get(objId);
      if (!imgData) {
       warn('Dependent image isn\'t ready yet');
       return;
      }
      var width = imgData.width;
      var height = imgData.height;
      var map = [];
      for (var i = 0, ii = positions.length; i < ii; i += 2) {
       map.push({
        transform: [
         scaleX,
         0,
         0,
         scaleY,
         positions[i],
         positions[i + 1]
        ],
        x: 0,
        y: 0,
        w: width,
        h: height
       });
      }
      this.paintInlineImageXObjectGroup(imgData, map);
     },
     paintInlineImageXObject: function CanvasGraphics_paintInlineImageXObject(imgData) {
      var width = imgData.width;
      var height = imgData.height;
      var ctx = this.ctx;
      this.save();
      ctx.scale(1 / width, -1 / height);
      var currentTransform = ctx.mozCurrentTransformInverse;
      var a = currentTransform[0], b = currentTransform[1];
      var widthScale = Math.max(Math.sqrt(a * a + b * b), 1);
      var c = currentTransform[2], d = currentTransform[3];
      var heightScale = Math.max(Math.sqrt(c * c + d * d), 1);
      var imgToPaint, tmpCanvas;
      if (imgData instanceof HTMLElement || !imgData.data) {
       imgToPaint = imgData;
      } else {
       tmpCanvas = this.cachedCanvases.getCanvas('inlineImage', width, height);
       var tmpCtx = tmpCanvas.context;
       putBinaryImageData(tmpCtx, imgData);
       imgToPaint = tmpCanvas.canvas;
      }
      var paintWidth = width, paintHeight = height;
      var tmpCanvasId = 'prescale1';
      while (widthScale > 2 && paintWidth > 1 || heightScale > 2 && paintHeight > 1) {
       var newWidth = paintWidth, newHeight = paintHeight;
       if (widthScale > 2 && paintWidth > 1) {
        newWidth = Math.ceil(paintWidth / 2);
        widthScale /= paintWidth / newWidth;
       }
       if (heightScale > 2 && paintHeight > 1) {
        newHeight = Math.ceil(paintHeight / 2);
        heightScale /= paintHeight / newHeight;
       }
       tmpCanvas = this.cachedCanvases.getCanvas(tmpCanvasId, newWidth, newHeight);
       tmpCtx = tmpCanvas.context;
       tmpCtx.clearRect(0, 0, newWidth, newHeight);
       tmpCtx.drawImage(imgToPaint, 0, 0, paintWidth, paintHeight, 0, 0, newWidth, newHeight);
       imgToPaint = tmpCanvas.canvas;
       paintWidth = newWidth;
       paintHeight = newHeight;
       tmpCanvasId = tmpCanvasId === 'prescale1' ? 'prescale2' : 'prescale1';
      }
      ctx.drawImage(imgToPaint, 0, 0, paintWidth, paintHeight, 0, -height, width, height);
      if (this.imageLayer) {
       var position = this.getCanvasPosition(0, -height);
       this.imageLayer.appendImage({
        imgData: imgData,
        left: position[0],
        top: position[1],
        width: width / currentTransform[0],
        height: height / currentTransform[3]
       });
      }
      this.restore();
     },
     paintInlineImageXObjectGroup: function CanvasGraphics_paintInlineImageXObjectGroup(imgData, map) {
      var ctx = this.ctx;
      var w = imgData.width;
      var h = imgData.height;
      var tmpCanvas = this.cachedCanvases.getCanvas('inlineImage', w, h);
      var tmpCtx = tmpCanvas.context;
      putBinaryImageData(tmpCtx, imgData);
      for (var i = 0, ii = map.length; i < ii; i++) {
       var entry = map[i];
       ctx.save();
       ctx.transform.apply(ctx, entry.transform);
       ctx.scale(1, -1);
       ctx.drawImage(tmpCanvas.canvas, entry.x, entry.y, entry.w, entry.h, 0, -1, 1, 1);
       if (this.imageLayer) {
        var position = this.getCanvasPosition(entry.x, entry.y);
        this.imageLayer.appendImage({
         imgData: imgData,
         left: position[0],
         top: position[1],
         width: w,
         height: h
        });
       }
       ctx.restore();
      }
     },
     paintSolidColorImageMask: function CanvasGraphics_paintSolidColorImageMask() {
      this.ctx.fillRect(0, 0, 1, 1);
     },
     paintXObject: function CanvasGraphics_paintXObject() {
      warn('Unsupported \'paintXObject\' command.');
     },
     markPoint: function CanvasGraphics_markPoint(tag) {
     },
     markPointProps: function CanvasGraphics_markPointProps(tag, properties) {
     },
     beginMarkedContent: function CanvasGraphics_beginMarkedContent(tag) {
     },
     beginMarkedContentProps: function CanvasGraphics_beginMarkedContentProps(tag, properties) {
     },
     endMarkedContent: function CanvasGraphics_endMarkedContent() {
     },
     beginCompat: function CanvasGraphics_beginCompat() {
     },
     endCompat: function CanvasGraphics_endCompat() {
     },
     consumePath: function CanvasGraphics_consumePath() {
      var ctx = this.ctx;
      if (this.pendingClip) {
       if (this.pendingClip === EO_CLIP) {
        if (ctx.mozFillRule !== undefined) {
         ctx.mozFillRule = 'evenodd';
         ctx.clip();
         ctx.mozFillRule = 'nonzero';
        } else {
         ctx.clip('evenodd');
        }
       } else {
        ctx.clip();
       }
       this.pendingClip = null;
      }
      ctx.beginPath();
     },
     getSinglePixelWidth: function CanvasGraphics_getSinglePixelWidth(scale) {
      if (this.cachedGetSinglePixelWidth === null) {
       this.ctx.save();
       var inverse = this.ctx.mozCurrentTransformInverse;
       this.ctx.restore();
       this.cachedGetSinglePixelWidth = Math.sqrt(Math.max(inverse[0] * inverse[0] + inverse[1] * inverse[1], inverse[2] * inverse[2] + inverse[3] * inverse[3]));
      }
      return this.cachedGetSinglePixelWidth;
     },
     getCanvasPosition: function CanvasGraphics_getCanvasPosition(x, y) {
      var transform = this.ctx.mozCurrentTransform;
      return [
       transform[0] * x + transform[2] * y + transform[4],
       transform[1] * x + transform[3] * y + transform[5]
      ];
     }
    };
    for (var op in OPS) {
     CanvasGraphics.prototype[OPS[op]] = CanvasGraphics.prototype[op];
    }
    return CanvasGraphics;
   }();
   exports.CanvasGraphics = CanvasGraphics;
   exports.createScratchCanvas = createScratchCanvas;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreFunction = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCorePsParser);
  }(this, function (exports, sharedUtil, corePrimitives, corePsParser) {
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var isBool = sharedUtil.isBool;
   var isDict = corePrimitives.isDict;
   var isStream = corePrimitives.isStream;
   var PostScriptLexer = corePsParser.PostScriptLexer;
   var PostScriptParser = corePsParser.PostScriptParser;
   var PDFFunction = function PDFFunctionClosure() {
    var CONSTRUCT_SAMPLED = 0;
    var CONSTRUCT_INTERPOLATED = 2;
    var CONSTRUCT_STICHED = 3;
    var CONSTRUCT_POSTSCRIPT = 4;
    return {
     getSampleArray: function PDFFunction_getSampleArray(size, outputSize, bps, str) {
      var i, ii;
      var length = 1;
      for (i = 0, ii = size.length; i < ii; i++) {
       length *= size[i];
      }
      length *= outputSize;
      var array = new Array(length);
      var codeSize = 0;
      var codeBuf = 0;
      var sampleMul = 1.0 / (Math.pow(2.0, bps) - 1);
      var strBytes = str.getBytes((length * bps + 7) / 8);
      var strIdx = 0;
      for (i = 0; i < length; i++) {
       while (codeSize < bps) {
        codeBuf <<= 8;
        codeBuf |= strBytes[strIdx++];
        codeSize += 8;
       }
       codeSize -= bps;
       array[i] = (codeBuf >> codeSize) * sampleMul;
       codeBuf &= (1 << codeSize) - 1;
      }
      return array;
     },
     getIR: function PDFFunction_getIR(xref, fn) {
      var dict = fn.dict;
      if (!dict) {
       dict = fn;
      }
      var types = [
       this.constructSampled,
       null,
       this.constructInterpolated,
       this.constructStiched,
       this.constructPostScript
      ];
      var typeNum = dict.get('FunctionType');
      var typeFn = types[typeNum];
      if (!typeFn) {
       error('Unknown type of function');
      }
      return typeFn.call(this, fn, dict, xref);
     },
     fromIR: function PDFFunction_fromIR(IR) {
      var type = IR[0];
      switch (type) {
      case CONSTRUCT_SAMPLED:
       return this.constructSampledFromIR(IR);
      case CONSTRUCT_INTERPOLATED:
       return this.constructInterpolatedFromIR(IR);
      case CONSTRUCT_STICHED:
       return this.constructStichedFromIR(IR);
      default:
       return this.constructPostScriptFromIR(IR);
      }
     },
     parse: function PDFFunction_parse(xref, fn) {
      var IR = this.getIR(xref, fn);
      return this.fromIR(IR);
     },
     parseArray: function PDFFunction_parseArray(xref, fnObj) {
      if (!isArray(fnObj)) {
       return this.parse(xref, fnObj);
      }
      var fnArray = [];
      for (var j = 0, jj = fnObj.length; j < jj; j++) {
       var obj = xref.fetchIfRef(fnObj[j]);
       fnArray.push(PDFFunction.parse(xref, obj));
      }
      return function (src, srcOffset, dest, destOffset) {
       for (var i = 0, ii = fnArray.length; i < ii; i++) {
        fnArray[i](src, srcOffset, dest, destOffset + i);
       }
      };
     },
     constructSampled: function PDFFunction_constructSampled(str, dict) {
      function toMultiArray(arr) {
       var inputLength = arr.length;
       var out = [];
       var index = 0;
       for (var i = 0; i < inputLength; i += 2) {
        out[index] = [
         arr[i],
         arr[i + 1]
        ];
        ++index;
       }
       return out;
      }
      var domain = dict.getArray('Domain');
      var range = dict.getArray('Range');
      if (!domain || !range) {
       error('No domain or range');
      }
      var inputSize = domain.length / 2;
      var outputSize = range.length / 2;
      domain = toMultiArray(domain);
      range = toMultiArray(range);
      var size = dict.get('Size');
      var bps = dict.get('BitsPerSample');
      var order = dict.get('Order') || 1;
      if (order !== 1) {
       info('No support for cubic spline interpolation: ' + order);
      }
      var encode = dict.getArray('Encode');
      if (!encode) {
       encode = [];
       for (var i = 0; i < inputSize; ++i) {
        encode.push(0);
        encode.push(size[i] - 1);
       }
      }
      encode = toMultiArray(encode);
      var decode = dict.getArray('Decode');
      if (!decode) {
       decode = range;
      } else {
       decode = toMultiArray(decode);
      }
      var samples = this.getSampleArray(size, outputSize, bps, str);
      return [
       CONSTRUCT_SAMPLED,
       inputSize,
       domain,
       encode,
       decode,
       samples,
       size,
       outputSize,
       Math.pow(2, bps) - 1,
       range
      ];
     },
     constructSampledFromIR: function PDFFunction_constructSampledFromIR(IR) {
      function interpolate(x, xmin, xmax, ymin, ymax) {
       return ymin + (x - xmin) * ((ymax - ymin) / (xmax - xmin));
      }
      return function constructSampledFromIRResult(src, srcOffset, dest, destOffset) {
       var m = IR[1];
       var domain = IR[2];
       var encode = IR[3];
       var decode = IR[4];
       var samples = IR[5];
       var size = IR[6];
       var n = IR[7];
       var range = IR[9];
       var cubeVertices = 1 << m;
       var cubeN = new Float64Array(cubeVertices);
       var cubeVertex = new Uint32Array(cubeVertices);
       var i, j;
       for (j = 0; j < cubeVertices; j++) {
        cubeN[j] = 1;
       }
       var k = n, pos = 1;
       for (i = 0; i < m; ++i) {
        var domain_2i = domain[i][0];
        var domain_2i_1 = domain[i][1];
        var xi = Math.min(Math.max(src[srcOffset + i], domain_2i), domain_2i_1);
        var e = interpolate(xi, domain_2i, domain_2i_1, encode[i][0], encode[i][1]);
        var size_i = size[i];
        e = Math.min(Math.max(e, 0), size_i - 1);
        var e0 = e < size_i - 1 ? Math.floor(e) : e - 1;
        var n0 = e0 + 1 - e;
        var n1 = e - e0;
        var offset0 = e0 * k;
        var offset1 = offset0 + k;
        for (j = 0; j < cubeVertices; j++) {
         if (j & pos) {
          cubeN[j] *= n1;
          cubeVertex[j] += offset1;
         } else {
          cubeN[j] *= n0;
          cubeVertex[j] += offset0;
         }
        }
        k *= size_i;
        pos <<= 1;
       }
       for (j = 0; j < n; ++j) {
        var rj = 0;
        for (i = 0; i < cubeVertices; i++) {
         rj += samples[cubeVertex[i] + j] * cubeN[i];
        }
        rj = interpolate(rj, 0, 1, decode[j][0], decode[j][1]);
        dest[destOffset + j] = Math.min(Math.max(rj, range[j][0]), range[j][1]);
       }
      };
     },
     constructInterpolated: function PDFFunction_constructInterpolated(str, dict) {
      var c0 = dict.getArray('C0') || [0];
      var c1 = dict.getArray('C1') || [1];
      var n = dict.get('N');
      if (!isArray(c0) || !isArray(c1)) {
       error('Illegal dictionary for interpolated function');
      }
      var length = c0.length;
      var diff = [];
      for (var i = 0; i < length; ++i) {
       diff.push(c1[i] - c0[i]);
      }
      return [
       CONSTRUCT_INTERPOLATED,
       c0,
       diff,
       n
      ];
     },
     constructInterpolatedFromIR: function PDFFunction_constructInterpolatedFromIR(IR) {
      var c0 = IR[1];
      var diff = IR[2];
      var n = IR[3];
      var length = diff.length;
      return function constructInterpolatedFromIRResult(src, srcOffset, dest, destOffset) {
       var x = n === 1 ? src[srcOffset] : Math.pow(src[srcOffset], n);
       for (var j = 0; j < length; ++j) {
        dest[destOffset + j] = c0[j] + x * diff[j];
       }
      };
     },
     constructStiched: function PDFFunction_constructStiched(fn, dict, xref) {
      var domain = dict.getArray('Domain');
      if (!domain) {
       error('No domain');
      }
      var inputSize = domain.length / 2;
      if (inputSize !== 1) {
       error('Bad domain for stiched function');
      }
      var fnRefs = dict.get('Functions');
      var fns = [];
      for (var i = 0, ii = fnRefs.length; i < ii; ++i) {
       fns.push(PDFFunction.getIR(xref, xref.fetchIfRef(fnRefs[i])));
      }
      var bounds = dict.getArray('Bounds');
      var encode = dict.getArray('Encode');
      return [
       CONSTRUCT_STICHED,
       domain,
       bounds,
       encode,
       fns
      ];
     },
     constructStichedFromIR: function PDFFunction_constructStichedFromIR(IR) {
      var domain = IR[1];
      var bounds = IR[2];
      var encode = IR[3];
      var fnsIR = IR[4];
      var fns = [];
      var tmpBuf = new Float32Array(1);
      for (var i = 0, ii = fnsIR.length; i < ii; i++) {
       fns.push(PDFFunction.fromIR(fnsIR[i]));
      }
      return function constructStichedFromIRResult(src, srcOffset, dest, destOffset) {
       var clip = function constructStichedFromIRClip(v, min, max) {
        if (v > max) {
         v = max;
        } else if (v < min) {
         v = min;
        }
        return v;
       };
       var v = clip(src[srcOffset], domain[0], domain[1]);
       for (var i = 0, ii = bounds.length; i < ii; ++i) {
        if (v < bounds[i]) {
         break;
        }
       }
       var dmin = domain[0];
       if (i > 0) {
        dmin = bounds[i - 1];
       }
       var dmax = domain[1];
       if (i < bounds.length) {
        dmax = bounds[i];
       }
       var rmin = encode[2 * i];
       var rmax = encode[2 * i + 1];
       tmpBuf[0] = dmin === dmax ? rmin : rmin + (v - dmin) * (rmax - rmin) / (dmax - dmin);
       fns[i](tmpBuf, 0, dest, destOffset);
      };
     },
     constructPostScript: function PDFFunction_constructPostScript(fn, dict, xref) {
      var domain = dict.getArray('Domain');
      var range = dict.getArray('Range');
      if (!domain) {
       error('No domain.');
      }
      if (!range) {
       error('No range.');
      }
      var lexer = new PostScriptLexer(fn);
      var parser = new PostScriptParser(lexer);
      var code = parser.parse();
      return [
       CONSTRUCT_POSTSCRIPT,
       domain,
       range,
       code
      ];
     },
     constructPostScriptFromIR: function PDFFunction_constructPostScriptFromIR(IR) {
      var domain = IR[1];
      var range = IR[2];
      var code = IR[3];
      var compiled = new PostScriptCompiler().compile(code, domain, range);
      if (compiled) {
       return new Function('src', 'srcOffset', 'dest', 'destOffset', compiled);
      }
      info('Unable to compile PS function');
      var numOutputs = range.length >> 1;
      var numInputs = domain.length >> 1;
      var evaluator = new PostScriptEvaluator(code);
      var cache = Object.create(null);
      var MAX_CACHE_SIZE = 2048 * 4;
      var cache_available = MAX_CACHE_SIZE;
      var tmpBuf = new Float32Array(numInputs);
      return function constructPostScriptFromIRResult(src, srcOffset, dest, destOffset) {
       var i, value;
       var key = '';
       var input = tmpBuf;
       for (i = 0; i < numInputs; i++) {
        value = src[srcOffset + i];
        input[i] = value;
        key += value + '_';
       }
       var cachedValue = cache[key];
       if (cachedValue !== undefined) {
        dest.set(cachedValue, destOffset);
        return;
       }
       var output = new Float32Array(numOutputs);
       var stack = evaluator.execute(input);
       var stackIndex = stack.length - numOutputs;
       for (i = 0; i < numOutputs; i++) {
        value = stack[stackIndex + i];
        var bound = range[i * 2];
        if (value < bound) {
         value = bound;
        } else {
         bound = range[i * 2 + 1];
         if (value > bound) {
          value = bound;
         }
        }
        output[i] = value;
       }
       if (cache_available > 0) {
        cache_available--;
        cache[key] = output;
       }
       dest.set(output, destOffset);
      };
     }
    };
   }();
   function isPDFFunction(v) {
    var fnDict;
    if (typeof v !== 'object') {
     return false;
    } else if (isDict(v)) {
     fnDict = v;
    } else if (isStream(v)) {
     fnDict = v.dict;
    } else {
     return false;
    }
    return fnDict.has('FunctionType');
   }
   var PostScriptStack = function PostScriptStackClosure() {
    var MAX_STACK_SIZE = 100;
    function PostScriptStack(initialStack) {
     this.stack = !initialStack ? [] : Array.prototype.slice.call(initialStack, 0);
    }
    PostScriptStack.prototype = {
     push: function PostScriptStack_push(value) {
      if (this.stack.length >= MAX_STACK_SIZE) {
       error('PostScript function stack overflow.');
      }
      this.stack.push(value);
     },
     pop: function PostScriptStack_pop() {
      if (this.stack.length <= 0) {
       error('PostScript function stack underflow.');
      }
      return this.stack.pop();
     },
     copy: function PostScriptStack_copy(n) {
      if (this.stack.length + n >= MAX_STACK_SIZE) {
       error('PostScript function stack overflow.');
      }
      var stack = this.stack;
      for (var i = stack.length - n, j = n - 1; j >= 0; j--, i++) {
       stack.push(stack[i]);
      }
     },
     index: function PostScriptStack_index(n) {
      this.push(this.stack[this.stack.length - n - 1]);
     },
     roll: function PostScriptStack_roll(n, p) {
      var stack = this.stack;
      var l = stack.length - n;
      var r = stack.length - 1, c = l + (p - Math.floor(p / n) * n), i, j, t;
      for (i = l, j = r; i < j; i++, j--) {
       t = stack[i];
       stack[i] = stack[j];
       stack[j] = t;
      }
      for (i = l, j = c - 1; i < j; i++, j--) {
       t = stack[i];
       stack[i] = stack[j];
       stack[j] = t;
      }
      for (i = c, j = r; i < j; i++, j--) {
       t = stack[i];
       stack[i] = stack[j];
       stack[j] = t;
      }
     }
    };
    return PostScriptStack;
   }();
   var PostScriptEvaluator = function PostScriptEvaluatorClosure() {
    function PostScriptEvaluator(operators) {
     this.operators = operators;
    }
    PostScriptEvaluator.prototype = {
     execute: function PostScriptEvaluator_execute(initialStack) {
      var stack = new PostScriptStack(initialStack);
      var counter = 0;
      var operators = this.operators;
      var length = operators.length;
      var operator, a, b;
      while (counter < length) {
       operator = operators[counter++];
       if (typeof operator === 'number') {
        stack.push(operator);
        continue;
       }
       switch (operator) {
       case 'jz':
        b = stack.pop();
        a = stack.pop();
        if (!a) {
         counter = b;
        }
        break;
       case 'j':
        a = stack.pop();
        counter = a;
        break;
       case 'abs':
        a = stack.pop();
        stack.push(Math.abs(a));
        break;
       case 'add':
        b = stack.pop();
        a = stack.pop();
        stack.push(a + b);
        break;
       case 'and':
        b = stack.pop();
        a = stack.pop();
        if (isBool(a) && isBool(b)) {
         stack.push(a && b);
        } else {
         stack.push(a & b);
        }
        break;
       case 'atan':
        a = stack.pop();
        stack.push(Math.atan(a));
        break;
       case 'bitshift':
        b = stack.pop();
        a = stack.pop();
        if (a > 0) {
         stack.push(a << b);
        } else {
         stack.push(a >> b);
        }
        break;
       case 'ceiling':
        a = stack.pop();
        stack.push(Math.ceil(a));
        break;
       case 'copy':
        a = stack.pop();
        stack.copy(a);
        break;
       case 'cos':
        a = stack.pop();
        stack.push(Math.cos(a));
        break;
       case 'cvi':
        a = stack.pop() | 0;
        stack.push(a);
        break;
       case 'cvr':
        break;
       case 'div':
        b = stack.pop();
        a = stack.pop();
        stack.push(a / b);
        break;
       case 'dup':
        stack.copy(1);
        break;
       case 'eq':
        b = stack.pop();
        a = stack.pop();
        stack.push(a === b);
        break;
       case 'exch':
        stack.roll(2, 1);
        break;
       case 'exp':
        b = stack.pop();
        a = stack.pop();
        stack.push(Math.pow(a, b));
        break;
       case 'false':
        stack.push(false);
        break;
       case 'floor':
        a = stack.pop();
        stack.push(Math.floor(a));
        break;
       case 'ge':
        b = stack.pop();
        a = stack.pop();
        stack.push(a >= b);
        break;
       case 'gt':
        b = stack.pop();
        a = stack.pop();
        stack.push(a > b);
        break;
       case 'idiv':
        b = stack.pop();
        a = stack.pop();
        stack.push(a / b | 0);
        break;
       case 'index':
        a = stack.pop();
        stack.index(a);
        break;
       case 'le':
        b = stack.pop();
        a = stack.pop();
        stack.push(a <= b);
        break;
       case 'ln':
        a = stack.pop();
        stack.push(Math.log(a));
        break;
       case 'log':
        a = stack.pop();
        stack.push(Math.log(a) / Math.LN10);
        break;
       case 'lt':
        b = stack.pop();
        a = stack.pop();
        stack.push(a < b);
        break;
       case 'mod':
        b = stack.pop();
        a = stack.pop();
        stack.push(a % b);
        break;
       case 'mul':
        b = stack.pop();
        a = stack.pop();
        stack.push(a * b);
        break;
       case 'ne':
        b = stack.pop();
        a = stack.pop();
        stack.push(a !== b);
        break;
       case 'neg':
        a = stack.pop();
        stack.push(-a);
        break;
       case 'not':
        a = stack.pop();
        if (isBool(a)) {
         stack.push(!a);
        } else {
         stack.push(~a);
        }
        break;
       case 'or':
        b = stack.pop();
        a = stack.pop();
        if (isBool(a) && isBool(b)) {
         stack.push(a || b);
        } else {
         stack.push(a | b);
        }
        break;
       case 'pop':
        stack.pop();
        break;
       case 'roll':
        b = stack.pop();
        a = stack.pop();
        stack.roll(a, b);
        break;
       case 'round':
        a = stack.pop();
        stack.push(Math.round(a));
        break;
       case 'sin':
        a = stack.pop();
        stack.push(Math.sin(a));
        break;
       case 'sqrt':
        a = stack.pop();
        stack.push(Math.sqrt(a));
        break;
       case 'sub':
        b = stack.pop();
        a = stack.pop();
        stack.push(a - b);
        break;
       case 'true':
        stack.push(true);
        break;
       case 'truncate':
        a = stack.pop();
        a = a < 0 ? Math.ceil(a) : Math.floor(a);
        stack.push(a);
        break;
       case 'xor':
        b = stack.pop();
        a = stack.pop();
        if (isBool(a) && isBool(b)) {
         stack.push(a !== b);
        } else {
         stack.push(a ^ b);
        }
        break;
       default:
        error('Unknown operator ' + operator);
        break;
       }
      }
      return stack.stack;
     }
    };
    return PostScriptEvaluator;
   }();
   var PostScriptCompiler = function PostScriptCompilerClosure() {
    function AstNode(type) {
     this.type = type;
    }
    AstNode.prototype.visit = function (visitor) {
     throw new Error('abstract method');
    };
    function AstArgument(index, min, max) {
     AstNode.call(this, 'args');
     this.index = index;
     this.min = min;
     this.max = max;
    }
    AstArgument.prototype = Object.create(AstNode.prototype);
    AstArgument.prototype.visit = function (visitor) {
     visitor.visitArgument(this);
    };
    function AstLiteral(number) {
     AstNode.call(this, 'literal');
     this.number = number;
     this.min = number;
     this.max = number;
    }
    AstLiteral.prototype = Object.create(AstNode.prototype);
    AstLiteral.prototype.visit = function (visitor) {
     visitor.visitLiteral(this);
    };
    function AstBinaryOperation(op, arg1, arg2, min, max) {
     AstNode.call(this, 'binary');
     this.op = op;
     this.arg1 = arg1;
     this.arg2 = arg2;
     this.min = min;
     this.max = max;
    }
    AstBinaryOperation.prototype = Object.create(AstNode.prototype);
    AstBinaryOperation.prototype.visit = function (visitor) {
     visitor.visitBinaryOperation(this);
    };
    function AstMin(arg, max) {
     AstNode.call(this, 'max');
     this.arg = arg;
     this.min = arg.min;
     this.max = max;
    }
    AstMin.prototype = Object.create(AstNode.prototype);
    AstMin.prototype.visit = function (visitor) {
     visitor.visitMin(this);
    };
    function AstVariable(index, min, max) {
     AstNode.call(this, 'var');
     this.index = index;
     this.min = min;
     this.max = max;
    }
    AstVariable.prototype = Object.create(AstNode.prototype);
    AstVariable.prototype.visit = function (visitor) {
     visitor.visitVariable(this);
    };
    function AstVariableDefinition(variable, arg) {
     AstNode.call(this, 'definition');
     this.variable = variable;
     this.arg = arg;
    }
    AstVariableDefinition.prototype = Object.create(AstNode.prototype);
    AstVariableDefinition.prototype.visit = function (visitor) {
     visitor.visitVariableDefinition(this);
    };
    function ExpressionBuilderVisitor() {
     this.parts = [];
    }
    ExpressionBuilderVisitor.prototype = {
     visitArgument: function (arg) {
      this.parts.push('Math.max(', arg.min, ', Math.min(', arg.max, ', src[srcOffset + ', arg.index, ']))');
     },
     visitVariable: function (variable) {
      this.parts.push('v', variable.index);
     },
     visitLiteral: function (literal) {
      this.parts.push(literal.number);
     },
     visitBinaryOperation: function (operation) {
      this.parts.push('(');
      operation.arg1.visit(this);
      this.parts.push(' ', operation.op, ' ');
      operation.arg2.visit(this);
      this.parts.push(')');
     },
     visitVariableDefinition: function (definition) {
      this.parts.push('var ');
      definition.variable.visit(this);
      this.parts.push(' = ');
      definition.arg.visit(this);
      this.parts.push(';');
     },
     visitMin: function (max) {
      this.parts.push('Math.min(');
      max.arg.visit(this);
      this.parts.push(', ', max.max, ')');
     },
     toString: function () {
      return this.parts.join('');
     }
    };
    function buildAddOperation(num1, num2) {
     if (num2.type === 'literal' && num2.number === 0) {
      return num1;
     }
     if (num1.type === 'literal' && num1.number === 0) {
      return num2;
     }
     if (num2.type === 'literal' && num1.type === 'literal') {
      return new AstLiteral(num1.number + num2.number);
     }
     return new AstBinaryOperation('+', num1, num2, num1.min + num2.min, num1.max + num2.max);
    }
    function buildMulOperation(num1, num2) {
     if (num2.type === 'literal') {
      if (num2.number === 0) {
       return new AstLiteral(0);
      } else if (num2.number === 1) {
       return num1;
      } else if (num1.type === 'literal') {
       return new AstLiteral(num1.number * num2.number);
      }
     }
     if (num1.type === 'literal') {
      if (num1.number === 0) {
       return new AstLiteral(0);
      } else if (num1.number === 1) {
       return num2;
      }
     }
     var min = Math.min(num1.min * num2.min, num1.min * num2.max, num1.max * num2.min, num1.max * num2.max);
     var max = Math.max(num1.min * num2.min, num1.min * num2.max, num1.max * num2.min, num1.max * num2.max);
     return new AstBinaryOperation('*', num1, num2, min, max);
    }
    function buildSubOperation(num1, num2) {
     if (num2.type === 'literal') {
      if (num2.number === 0) {
       return num1;
      } else if (num1.type === 'literal') {
       return new AstLiteral(num1.number - num2.number);
      }
     }
     if (num2.type === 'binary' && num2.op === '-' && num1.type === 'literal' && num1.number === 1 && num2.arg1.type === 'literal' && num2.arg1.number === 1) {
      return num2.arg2;
     }
     return new AstBinaryOperation('-', num1, num2, num1.min - num2.max, num1.max - num2.min);
    }
    function buildMinOperation(num1, max) {
     if (num1.min >= max) {
      return new AstLiteral(max);
     } else if (num1.max <= max) {
      return num1;
     }
     return new AstMin(num1, max);
    }
    function PostScriptCompiler() {
    }
    PostScriptCompiler.prototype = {
     compile: function PostScriptCompiler_compile(code, domain, range) {
      var stack = [];
      var i, ii;
      var instructions = [];
      var inputSize = domain.length >> 1, outputSize = range.length >> 1;
      var lastRegister = 0;
      var n, j;
      var num1, num2, ast1, ast2, tmpVar, item;
      for (i = 0; i < inputSize; i++) {
       stack.push(new AstArgument(i, domain[i * 2], domain[i * 2 + 1]));
      }
      for (i = 0, ii = code.length; i < ii; i++) {
       item = code[i];
       if (typeof item === 'number') {
        stack.push(new AstLiteral(item));
        continue;
       }
       switch (item) {
       case 'add':
        if (stack.length < 2) {
         return null;
        }
        num2 = stack.pop();
        num1 = stack.pop();
        stack.push(buildAddOperation(num1, num2));
        break;
       case 'cvr':
        if (stack.length < 1) {
         return null;
        }
        break;
       case 'mul':
        if (stack.length < 2) {
         return null;
        }
        num2 = stack.pop();
        num1 = stack.pop();
        stack.push(buildMulOperation(num1, num2));
        break;
       case 'sub':
        if (stack.length < 2) {
         return null;
        }
        num2 = stack.pop();
        num1 = stack.pop();
        stack.push(buildSubOperation(num1, num2));
        break;
       case 'exch':
        if (stack.length < 2) {
         return null;
        }
        ast1 = stack.pop();
        ast2 = stack.pop();
        stack.push(ast1, ast2);
        break;
       case 'pop':
        if (stack.length < 1) {
         return null;
        }
        stack.pop();
        break;
       case 'index':
        if (stack.length < 1) {
         return null;
        }
        num1 = stack.pop();
        if (num1.type !== 'literal') {
         return null;
        }
        n = num1.number;
        if (n < 0 || (n | 0) !== n || stack.length < n) {
         return null;
        }
        ast1 = stack[stack.length - n - 1];
        if (ast1.type === 'literal' || ast1.type === 'var') {
         stack.push(ast1);
         break;
        }
        tmpVar = new AstVariable(lastRegister++, ast1.min, ast1.max);
        stack[stack.length - n - 1] = tmpVar;
        stack.push(tmpVar);
        instructions.push(new AstVariableDefinition(tmpVar, ast1));
        break;
       case 'dup':
        if (stack.length < 1) {
         return null;
        }
        if (typeof code[i + 1] === 'number' && code[i + 2] === 'gt' && code[i + 3] === i + 7 && code[i + 4] === 'jz' && code[i + 5] === 'pop' && code[i + 6] === code[i + 1]) {
         num1 = stack.pop();
         stack.push(buildMinOperation(num1, code[i + 1]));
         i += 6;
         break;
        }
        ast1 = stack[stack.length - 1];
        if (ast1.type === 'literal' || ast1.type === 'var') {
         stack.push(ast1);
         break;
        }
        tmpVar = new AstVariable(lastRegister++, ast1.min, ast1.max);
        stack[stack.length - 1] = tmpVar;
        stack.push(tmpVar);
        instructions.push(new AstVariableDefinition(tmpVar, ast1));
        break;
       case 'roll':
        if (stack.length < 2) {
         return null;
        }
        num2 = stack.pop();
        num1 = stack.pop();
        if (num2.type !== 'literal' || num1.type !== 'literal') {
         return null;
        }
        j = num2.number;
        n = num1.number;
        if (n <= 0 || (n | 0) !== n || (j | 0) !== j || stack.length < n) {
         return null;
        }
        j = (j % n + n) % n;
        if (j === 0) {
         break;
        }
        Array.prototype.push.apply(stack, stack.splice(stack.length - n, n - j));
        break;
       default:
        return null;
       }
      }
      if (stack.length !== outputSize) {
       return null;
      }
      var result = [];
      instructions.forEach(function (instruction) {
       var statementBuilder = new ExpressionBuilderVisitor();
       instruction.visit(statementBuilder);
       result.push(statementBuilder.toString());
      });
      stack.forEach(function (expr, i) {
       var statementBuilder = new ExpressionBuilderVisitor();
       expr.visit(statementBuilder);
       var min = range[i * 2], max = range[i * 2 + 1];
       var out = [statementBuilder.toString()];
       if (min > expr.min) {
        out.unshift('Math.max(', min, ', ');
        out.push(')');
       }
       if (max < expr.max) {
        out.unshift('Math.min(', max, ', ');
        out.push(')');
       }
       out.unshift('dest[destOffset + ', i, '] = ');
       out.push(';');
       result.push(out.join(''));
      });
      return result.join('\n');
     }
    };
    return PostScriptCompiler;
   }();
   exports.isPDFFunction = isPDFFunction;
   exports.PDFFunction = PDFFunction;
   exports.PostScriptEvaluator = PostScriptEvaluator;
   exports.PostScriptCompiler = PostScriptCompiler;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayAPI = {}, root.pdfjsSharedUtil, root.pdfjsDisplayFontLoader, root.pdfjsDisplayCanvas, root.pdfjsDisplayMetadata, root.pdfjsDisplayDOMUtils);
  }(this, function (exports, sharedUtil, displayFontLoader, displayCanvas, displayMetadata, displayDOMUtils, amdRequire) {
   var InvalidPDFException = sharedUtil.InvalidPDFException;
   var MessageHandler = sharedUtil.MessageHandler;
   var MissingPDFException = sharedUtil.MissingPDFException;
   var PageViewport = sharedUtil.PageViewport;
   var PasswordResponses = sharedUtil.PasswordResponses;
   var PasswordException = sharedUtil.PasswordException;
   var StatTimer = sharedUtil.StatTimer;
   var UnexpectedResponseException = sharedUtil.UnexpectedResponseException;
   var UnknownErrorException = sharedUtil.UnknownErrorException;
   var Util = sharedUtil.Util;
   var createPromiseCapability = sharedUtil.createPromiseCapability;
   var error = sharedUtil.error;
   var deprecated = sharedUtil.deprecated;
   var getVerbosityLevel = sharedUtil.getVerbosityLevel;
   var info = sharedUtil.info;
   var isInt = sharedUtil.isInt;
   var isArray = sharedUtil.isArray;
   var isArrayBuffer = sharedUtil.isArrayBuffer;
   var isSameOrigin = sharedUtil.isSameOrigin;
   var loadJpegStream = sharedUtil.loadJpegStream;
   var stringToBytes = sharedUtil.stringToBytes;
   var globalScope = sharedUtil.globalScope;
   var warn = sharedUtil.warn;
   var FontFaceObject = displayFontLoader.FontFaceObject;
   var FontLoader = displayFontLoader.FontLoader;
   var CanvasGraphics = displayCanvas.CanvasGraphics;
   var createScratchCanvas = displayCanvas.createScratchCanvas;
   var Metadata = displayMetadata.Metadata;
   var getDefaultSetting = displayDOMUtils.getDefaultSetting;
   var DEFAULT_RANGE_CHUNK_SIZE = 65536;
   var isWorkerDisabled = false;
   var workerSrc;
   var isPostMessageTransfersDisabled = false;
   var fakeWorkerFilesLoader = null;
   var useRequireEnsure = false;
   function getDocument(src, pdfDataRangeTransport, passwordCallback, progressCallback) {
    var task = new PDFDocumentLoadingTask();
    if (arguments.length > 1) {
     deprecated('getDocument is called with pdfDataRangeTransport, ' + 'passwordCallback or progressCallback argument');
    }
    if (pdfDataRangeTransport) {
     if (!(pdfDataRangeTransport instanceof PDFDataRangeTransport)) {
      pdfDataRangeTransport = Object.create(pdfDataRangeTransport);
      pdfDataRangeTransport.length = src.length;
      pdfDataRangeTransport.initialData = src.initialData;
      if (!pdfDataRangeTransport.abort) {
       pdfDataRangeTransport.abort = function () {
       };
      }
     }
     src = Object.create(src);
     src.range = pdfDataRangeTransport;
    }
    task.onPassword = passwordCallback || null;
    task.onProgress = progressCallback || null;
    var source;
    if (typeof src === 'string') {
     source = { url: src };
    } else if (isArrayBuffer(src)) {
     source = { data: src };
    } else if (src instanceof PDFDataRangeTransport) {
     source = { range: src };
    } else {
     if (typeof src !== 'object') {
      error('Invalid parameter in getDocument, need either Uint8Array, ' + 'string or a parameter object');
     }
     if (!src.url && !src.data && !src.range) {
      error('Invalid parameter object: need either .data, .range or .url');
     }
     source = src;
    }
    var params = {};
    var rangeTransport = null;
    var worker = null;
    for (var key in source) {
     if (key === 'url' && typeof window !== 'undefined') {
      params[key] = new URL(source[key], window.location).href;
      continue;
     } else if (key === 'range') {
      rangeTransport = source[key];
      continue;
     } else if (key === 'worker') {
      worker = source[key];
      continue;
     } else if (key === 'data' && !(source[key] instanceof Uint8Array)) {
      var pdfBytes = source[key];
      if (typeof pdfBytes === 'string') {
       params[key] = stringToBytes(pdfBytes);
      } else if (typeof pdfBytes === 'object' && pdfBytes !== null && !isNaN(pdfBytes.length)) {
       params[key] = new Uint8Array(pdfBytes);
      } else if (isArrayBuffer(pdfBytes)) {
       params[key] = new Uint8Array(pdfBytes);
      } else {
       error('Invalid PDF binary data: either typed array, string or ' + 'array-like object is expected in the data property.');
      }
      continue;
     }
     params[key] = source[key];
    }
    params.rangeChunkSize = params.rangeChunkSize || DEFAULT_RANGE_CHUNK_SIZE;
    if (!worker) {
     worker = new PDFWorker();
     task._worker = worker;
    }
    var docId = task.docId;
    worker.promise.then(function () {
     if (task.destroyed) {
      throw new Error('Loading aborted');
     }
     return _fetchDocument(worker, params, rangeTransport, docId).then(function (workerId) {
      if (task.destroyed) {
       throw new Error('Loading aborted');
      }
      var messageHandler = new MessageHandler(docId, workerId, worker.port);
      var transport = new WorkerTransport(messageHandler, task, rangeTransport);
      task._transport = transport;
      messageHandler.send('Ready', null);
     });
    }).catch(task._capability.reject);
    return task;
   }
   function _fetchDocument(worker, source, pdfDataRangeTransport, docId) {
    if (worker.destroyed) {
     return Promise.reject(new Error('Worker was destroyed'));
    }
    source.disableAutoFetch = getDefaultSetting('disableAutoFetch');
    source.disableStream = getDefaultSetting('disableStream');
    source.chunkedViewerLoading = !!pdfDataRangeTransport;
    if (pdfDataRangeTransport) {
     source.length = pdfDataRangeTransport.length;
     source.initialData = pdfDataRangeTransport.initialData;
    }
    return worker.messageHandler.sendWithPromise('GetDocRequest', {
     docId: docId,
     source: source,
     disableRange: getDefaultSetting('disableRange'),
     maxImageSize: getDefaultSetting('maxImageSize'),
     cMapUrl: getDefaultSetting('cMapUrl'),
     cMapPacked: getDefaultSetting('cMapPacked'),
     disableFontFace: getDefaultSetting('disableFontFace'),
     disableCreateObjectURL: getDefaultSetting('disableCreateObjectURL'),
     postMessageTransfers: getDefaultSetting('postMessageTransfers') && !isPostMessageTransfersDisabled,
     docBaseUrl: source.docBaseUrl
    }).then(function (workerId) {
     if (worker.destroyed) {
      throw new Error('Worker was destroyed');
     }
     return workerId;
    });
   }
   var PDFDocumentLoadingTask = function PDFDocumentLoadingTaskClosure() {
    var nextDocumentId = 0;
    function PDFDocumentLoadingTask() {
     this._capability = createPromiseCapability();
     this._transport = null;
     this._worker = null;
     this.docId = 'd' + nextDocumentId++;
     this.destroyed = false;
     this.onPassword = null;
     this.onProgress = null;
     this.onUnsupportedFeature = null;
    }
    PDFDocumentLoadingTask.prototype = {
     get promise() {
      return this._capability.promise;
     },
     destroy: function () {
      this.destroyed = true;
      var transportDestroyed = !this._transport ? Promise.resolve() : this._transport.destroy();
      return transportDestroyed.then(function () {
       this._transport = null;
       if (this._worker) {
        this._worker.destroy();
        this._worker = null;
       }
      }.bind(this));
     },
     then: function PDFDocumentLoadingTask_then(onFulfilled, onRejected) {
      return this.promise.then.apply(this.promise, arguments);
     }
    };
    return PDFDocumentLoadingTask;
   }();
   var PDFDataRangeTransport = function pdfDataRangeTransportClosure() {
    function PDFDataRangeTransport(length, initialData) {
     this.length = length;
     this.initialData = initialData;
     this._rangeListeners = [];
     this._progressListeners = [];
     this._progressiveReadListeners = [];
     this._readyCapability = createPromiseCapability();
    }
    PDFDataRangeTransport.prototype = {
     addRangeListener: function PDFDataRangeTransport_addRangeListener(listener) {
      this._rangeListeners.push(listener);
     },
     addProgressListener: function PDFDataRangeTransport_addProgressListener(listener) {
      this._progressListeners.push(listener);
     },
     addProgressiveReadListener: function PDFDataRangeTransport_addProgressiveReadListener(listener) {
      this._progressiveReadListeners.push(listener);
     },
     onDataRange: function PDFDataRangeTransport_onDataRange(begin, chunk) {
      var listeners = this._rangeListeners;
      for (var i = 0, n = listeners.length; i < n; ++i) {
       listeners[i](begin, chunk);
      }
     },
     onDataProgress: function PDFDataRangeTransport_onDataProgress(loaded) {
      this._readyCapability.promise.then(function () {
       var listeners = this._progressListeners;
       for (var i = 0, n = listeners.length; i < n; ++i) {
        listeners[i](loaded);
       }
      }.bind(this));
     },
     onDataProgressiveRead: function PDFDataRangeTransport_onDataProgress(chunk) {
      this._readyCapability.promise.then(function () {
       var listeners = this._progressiveReadListeners;
       for (var i = 0, n = listeners.length; i < n; ++i) {
        listeners[i](chunk);
       }
      }.bind(this));
     },
     transportReady: function PDFDataRangeTransport_transportReady() {
      this._readyCapability.resolve();
     },
     requestDataRange: function PDFDataRangeTransport_requestDataRange(begin, end) {
      throw new Error('Abstract method PDFDataRangeTransport.requestDataRange');
     },
     abort: function PDFDataRangeTransport_abort() {
     }
    };
    return PDFDataRangeTransport;
   }();
   var PDFDocumentProxy = function PDFDocumentProxyClosure() {
    function PDFDocumentProxy(pdfInfo, transport, loadingTask) {
     this.pdfInfo = pdfInfo;
     this.transport = transport;
     this.loadingTask = loadingTask;
    }
    PDFDocumentProxy.prototype = {
     get numPages() {
      return this.pdfInfo.numPages;
     },
     get fingerprint() {
      return this.pdfInfo.fingerprint;
     },
     getPage: function PDFDocumentProxy_getPage(pageNumber) {
      return this.transport.getPage(pageNumber);
     },
     getPageIndex: function PDFDocumentProxy_getPageIndex(ref) {
      return this.transport.getPageIndex(ref);
     },
     getDestinations: function PDFDocumentProxy_getDestinations() {
      return this.transport.getDestinations();
     },
     getDestination: function PDFDocumentProxy_getDestination(id) {
      return this.transport.getDestination(id);
     },
     getPageLabels: function PDFDocumentProxy_getPageLabels() {
      return this.transport.getPageLabels();
     },
     getAttachments: function PDFDocumentProxy_getAttachments() {
      return this.transport.getAttachments();
     },
     getJavaScript: function PDFDocumentProxy_getJavaScript() {
      return this.transport.getJavaScript();
     },
     getOutline: function PDFDocumentProxy_getOutline() {
      return this.transport.getOutline();
     },
     getMetadata: function PDFDocumentProxy_getMetadata() {
      return this.transport.getMetadata();
     },
     getData: function PDFDocumentProxy_getData() {
      return this.transport.getData();
     },
     getDownloadInfo: function PDFDocumentProxy_getDownloadInfo() {
      return this.transport.downloadInfoCapability.promise;
     },
     getStats: function PDFDocumentProxy_getStats() {
      return this.transport.getStats();
     },
     cleanup: function PDFDocumentProxy_cleanup() {
      this.transport.startCleanup();
     },
     destroy: function PDFDocumentProxy_destroy() {
      return this.loadingTask.destroy();
     }
    };
    return PDFDocumentProxy;
   }();
   var PDFPageProxy = function PDFPageProxyClosure() {
    function PDFPageProxy(pageIndex, pageInfo, transport) {
     this.pageIndex = pageIndex;
     this.pageInfo = pageInfo;
     this.transport = transport;
     this.stats = new StatTimer();
     this.stats.enabled = getDefaultSetting('enableStats');
     this.commonObjs = transport.commonObjs;
     this.objs = new PDFObjects();
     this.cleanupAfterRender = false;
     this.pendingCleanup = false;
     this.intentStates = Object.create(null);
     this.destroyed = false;
    }
    PDFPageProxy.prototype = {
     get pageNumber() {
      return this.pageIndex + 1;
     },
     get rotate() {
      return this.pageInfo.rotate;
     },
     get ref() {
      return this.pageInfo.ref;
     },
     get userUnit() {
      return this.pageInfo.userUnit;
     },
     get view() {
      return this.pageInfo.view;
     },
     getViewport: function PDFPageProxy_getViewport(scale, rotate) {
      if (arguments.length < 2) {
       rotate = this.rotate;
      }
      return new PageViewport(this.view, scale, rotate, 0, 0);
     },
     getAnnotations: function PDFPageProxy_getAnnotations(params) {
      var intent = params && params.intent || null;
      if (!this.annotationsPromise || this.annotationsIntent !== intent) {
       this.annotationsPromise = this.transport.getAnnotations(this.pageIndex, intent);
       this.annotationsIntent = intent;
      }
      return this.annotationsPromise;
     },
     render: function PDFPageProxy_render(params) {
      var stats = this.stats;
      stats.time('Overall');
      this.pendingCleanup = false;
      var renderingIntent = params.intent === 'print' ? 'print' : 'display';
      var renderInteractiveForms = params.renderInteractiveForms === true ? true : false;
      if (!this.intentStates[renderingIntent]) {
       this.intentStates[renderingIntent] = Object.create(null);
      }
      var intentState = this.intentStates[renderingIntent];
      if (!intentState.displayReadyCapability) {
       intentState.receivingOperatorList = true;
       intentState.displayReadyCapability = createPromiseCapability();
       intentState.operatorList = {
        fnArray: [],
        argsArray: [],
        lastChunk: false
       };
       this.stats.time('Page Request');
       this.transport.messageHandler.send('RenderPageRequest', {
        pageIndex: this.pageNumber - 1,
        intent: renderingIntent,
        renderInteractiveForms: renderInteractiveForms
       });
      }
      var internalRenderTask = new InternalRenderTask(complete, params, this.objs, this.commonObjs, intentState.operatorList, this.pageNumber);
      internalRenderTask.useRequestAnimationFrame = renderingIntent !== 'print';
      if (!intentState.renderTasks) {
       intentState.renderTasks = [];
      }
      intentState.renderTasks.push(internalRenderTask);
      var renderTask = internalRenderTask.task;
      if (params.continueCallback) {
       deprecated('render is used with continueCallback parameter');
       renderTask.onContinue = params.continueCallback;
      }
      var self = this;
      intentState.displayReadyCapability.promise.then(function pageDisplayReadyPromise(transparency) {
       if (self.pendingCleanup) {
        complete();
        return;
       }
       stats.time('Rendering');
       internalRenderTask.initializeGraphics(transparency);
       internalRenderTask.operatorListChanged();
      }, function pageDisplayReadPromiseError(reason) {
       complete(reason);
      });
      function complete(error) {
       var i = intentState.renderTasks.indexOf(internalRenderTask);
       if (i >= 0) {
        intentState.renderTasks.splice(i, 1);
       }
       if (self.cleanupAfterRender) {
        self.pendingCleanup = true;
       }
       self._tryCleanup();
       if (error) {
        internalRenderTask.capability.reject(error);
       } else {
        internalRenderTask.capability.resolve();
       }
       stats.timeEnd('Rendering');
       stats.timeEnd('Overall');
      }
      return renderTask;
     },
     getOperatorList: function PDFPageProxy_getOperatorList() {
      function operatorListChanged() {
       if (intentState.operatorList.lastChunk) {
        intentState.opListReadCapability.resolve(intentState.operatorList);
        var i = intentState.renderTasks.indexOf(opListTask);
        if (i >= 0) {
         intentState.renderTasks.splice(i, 1);
        }
       }
      }
      var renderingIntent = 'oplist';
      if (!this.intentStates[renderingIntent]) {
       this.intentStates[renderingIntent] = Object.create(null);
      }
      var intentState = this.intentStates[renderingIntent];
      var opListTask;
      if (!intentState.opListReadCapability) {
       opListTask = {};
       opListTask.operatorListChanged = operatorListChanged;
       intentState.receivingOperatorList = true;
       intentState.opListReadCapability = createPromiseCapability();
       intentState.renderTasks = [];
       intentState.renderTasks.push(opListTask);
       intentState.operatorList = {
        fnArray: [],
        argsArray: [],
        lastChunk: false
       };
       this.transport.messageHandler.send('RenderPageRequest', {
        pageIndex: this.pageIndex,
        intent: renderingIntent
       });
      }
      return intentState.opListReadCapability.promise;
     },
     getTextContent: function PDFPageProxy_getTextContent(params) {
      return this.transport.messageHandler.sendWithPromise('GetTextContent', {
       pageIndex: this.pageNumber - 1,
       normalizeWhitespace: params && params.normalizeWhitespace === true ? true : false,
       combineTextItems: params && params.disableCombineTextItems === true ? false : true
      });
     },
     _destroy: function PDFPageProxy_destroy() {
      this.destroyed = true;
      this.transport.pageCache[this.pageIndex] = null;
      var waitOn = [];
      Object.keys(this.intentStates).forEach(function (intent) {
       if (intent === 'oplist') {
        return;
       }
       var intentState = this.intentStates[intent];
       intentState.renderTasks.forEach(function (renderTask) {
        var renderCompleted = renderTask.capability.promise.catch(function () {
        });
        waitOn.push(renderCompleted);
        renderTask.cancel();
       });
      }, this);
      this.objs.clear();
      this.annotationsPromise = null;
      this.pendingCleanup = false;
      return Promise.all(waitOn);
     },
     destroy: function () {
      deprecated('page destroy method, use cleanup() instead');
      this.cleanup();
     },
     cleanup: function PDFPageProxy_cleanup() {
      this.pendingCleanup = true;
      this._tryCleanup();
     },
     _tryCleanup: function PDFPageProxy_tryCleanup() {
      if (!this.pendingCleanup || Object.keys(this.intentStates).some(function (intent) {
        var intentState = this.intentStates[intent];
        return intentState.renderTasks.length !== 0 || intentState.receivingOperatorList;
       }, this)) {
       return;
      }
      Object.keys(this.intentStates).forEach(function (intent) {
       delete this.intentStates[intent];
      }, this);
      this.objs.clear();
      this.annotationsPromise = null;
      this.pendingCleanup = false;
     },
     _startRenderPage: function PDFPageProxy_startRenderPage(transparency, intent) {
      var intentState = this.intentStates[intent];
      if (intentState.displayReadyCapability) {
       intentState.displayReadyCapability.resolve(transparency);
      }
     },
     _renderPageChunk: function PDFPageProxy_renderPageChunk(operatorListChunk, intent) {
      var intentState = this.intentStates[intent];
      var i, ii;
      for (i = 0, ii = operatorListChunk.length; i < ii; i++) {
       intentState.operatorList.fnArray.push(operatorListChunk.fnArray[i]);
       intentState.operatorList.argsArray.push(operatorListChunk.argsArray[i]);
      }
      intentState.operatorList.lastChunk = operatorListChunk.lastChunk;
      for (i = 0; i < intentState.renderTasks.length; i++) {
       intentState.renderTasks[i].operatorListChanged();
      }
      if (operatorListChunk.lastChunk) {
       intentState.receivingOperatorList = false;
       this._tryCleanup();
      }
     }
    };
    return PDFPageProxy;
   }();
   var PDFWorker = function PDFWorkerClosure() {
    var nextFakeWorkerId = 0;
    function getWorkerSrc() {
     if (typeof workerSrc !== 'undefined') {
      return workerSrc;
     }
     if (getDefaultSetting('workerSrc')) {
      return getDefaultSetting('workerSrc');
     }
     if (pdfjsFilePath) {
      return pdfjsFilePath.replace(/\.js$/i, '.worker.js');
     }
     error('No PDFJS.workerSrc specified');
    }
    var fakeWorkerFilesLoadedCapability;
    function setupFakeWorkerGlobal() {
     var WorkerMessageHandler;
     if (fakeWorkerFilesLoadedCapability) {
      return fakeWorkerFilesLoadedCapability.promise;
     }
     fakeWorkerFilesLoadedCapability = createPromiseCapability();
     WorkerMessageHandler = pdfjsLibs.pdfjsCoreWorker.WorkerMessageHandler;
     fakeWorkerFilesLoadedCapability.resolve(WorkerMessageHandler);
     return fakeWorkerFilesLoadedCapability.promise;
    }
    function FakeWorkerPort(defer) {
     this._listeners = [];
     this._defer = defer;
     this._deferred = Promise.resolve(undefined);
    }
    FakeWorkerPort.prototype = {
     postMessage: function (obj, transfers) {
      function cloneValue(value) {
       if (typeof value !== 'object' || value === null) {
        return value;
       }
       if (cloned.has(value)) {
        return cloned.get(value);
       }
       var result;
       var buffer;
       if ((buffer = value.buffer) && isArrayBuffer(buffer)) {
        var transferable = transfers && transfers.indexOf(buffer) >= 0;
        if (value === buffer) {
         result = value;
        } else if (transferable) {
         result = new value.constructor(buffer, value.byteOffset, value.byteLength);
        } else {
         result = new value.constructor(value);
        }
        cloned.set(value, result);
        return result;
       }
       result = isArray(value) ? [] : {};
       cloned.set(value, result);
       for (var i in value) {
        var desc, p = value;
        while (!(desc = Object.getOwnPropertyDescriptor(p, i))) {
         p = Object.getPrototypeOf(p);
        }
        if (typeof desc.value === 'undefined' || typeof desc.value === 'function') {
         continue;
        }
        result[i] = cloneValue(desc.value);
       }
       return result;
      }
      if (!this._defer) {
       this._listeners.forEach(function (listener) {
        listener.call(this, { data: obj });
       }, this);
       return;
      }
      var cloned = new WeakMap();
      var e = { data: cloneValue(obj) };
      this._deferred.then(function () {
       this._listeners.forEach(function (listener) {
        listener.call(this, e);
       }, this);
      }.bind(this));
     },
     addEventListener: function (name, listener) {
      this._listeners.push(listener);
     },
     removeEventListener: function (name, listener) {
      var i = this._listeners.indexOf(listener);
      this._listeners.splice(i, 1);
     },
     terminate: function () {
      this._listeners = [];
     }
    };
    function createCDNWrapper(url) {
     var wrapper = 'importScripts(\'' + url + '\');';
     return URL.createObjectURL(new Blob([wrapper]));
    }
    function PDFWorker(name) {
     this.name = name;
     this.destroyed = false;
     this._readyCapability = createPromiseCapability();
     this._port = null;
     this._webWorker = null;
     this._messageHandler = null;
     this._initialize();
    }
    PDFWorker.prototype = {
     get promise() {
      return this._readyCapability.promise;
     },
     get port() {
      return this._port;
     },
     get messageHandler() {
      return this._messageHandler;
     },
     _initialize: function PDFWorker_initialize() {
      this._setupFakeWorker();
     },
     _setupFakeWorker: function PDFWorker_setupFakeWorker() {
      if (!isWorkerDisabled && !getDefaultSetting('disableWorker')) {
       warn('Setting up fake worker.');
       isWorkerDisabled = true;
      }
      setupFakeWorkerGlobal().then(function (WorkerMessageHandler) {
       if (this.destroyed) {
        this._readyCapability.reject(new Error('Worker was destroyed'));
        return;
       }
       var isTypedArraysPresent = Uint8Array !== Float32Array;
       var port = new FakeWorkerPort(isTypedArraysPresent);
       this._port = port;
       var id = 'fake' + nextFakeWorkerId++;
       var workerHandler = new MessageHandler(id + '_worker', id, port);
       WorkerMessageHandler.setup(workerHandler, port);
       var messageHandler = new MessageHandler(id, id + '_worker', port);
       this._messageHandler = messageHandler;
       this._readyCapability.resolve();
      }.bind(this));
     },
     destroy: function PDFWorker_destroy() {
      this.destroyed = true;
      if (this._webWorker) {
       this._webWorker.terminate();
       this._webWorker = null;
      }
      this._port = null;
      if (this._messageHandler) {
       this._messageHandler.destroy();
       this._messageHandler = null;
      }
     }
    };
    return PDFWorker;
   }();
   var WorkerTransport = function WorkerTransportClosure() {
    function WorkerTransport(messageHandler, loadingTask, pdfDataRangeTransport) {
     this.messageHandler = messageHandler;
     this.loadingTask = loadingTask;
     this.pdfDataRangeTransport = pdfDataRangeTransport;
     this.commonObjs = new PDFObjects();
     this.fontLoader = new FontLoader(loadingTask.docId);
     this.destroyed = false;
     this.destroyCapability = null;
     this._passwordCapability = null;
     this.pageCache = [];
     this.pagePromises = [];
     this.downloadInfoCapability = createPromiseCapability();
     this.setupMessageHandler();
    }
    WorkerTransport.prototype = {
     destroy: function WorkerTransport_destroy() {
      if (this.destroyCapability) {
       return this.destroyCapability.promise;
      }
      this.destroyed = true;
      this.destroyCapability = createPromiseCapability();
      if (this._passwordCapability) {
       this._passwordCapability.reject(new Error('Worker was destroyed during onPassword callback'));
      }
      var waitOn = [];
      this.pageCache.forEach(function (page) {
       if (page) {
        waitOn.push(page._destroy());
       }
      });
      this.pageCache = [];
      this.pagePromises = [];
      var self = this;
      var terminated = this.messageHandler.sendWithPromise('Terminate', null);
      waitOn.push(terminated);
      Promise.all(waitOn).then(function () {
       self.fontLoader.clear();
       if (self.pdfDataRangeTransport) {
        self.pdfDataRangeTransport.abort();
        self.pdfDataRangeTransport = null;
       }
       if (self.messageHandler) {
        self.messageHandler.destroy();
        self.messageHandler = null;
       }
       self.destroyCapability.resolve();
      }, this.destroyCapability.reject);
      return this.destroyCapability.promise;
     },
     setupMessageHandler: function WorkerTransport_setupMessageHandler() {
      var messageHandler = this.messageHandler;
      var loadingTask = this.loadingTask;
      var pdfDataRangeTransport = this.pdfDataRangeTransport;
      if (pdfDataRangeTransport) {
       pdfDataRangeTransport.addRangeListener(function (begin, chunk) {
        messageHandler.send('OnDataRange', {
         begin: begin,
         chunk: chunk
        });
       });
       pdfDataRangeTransport.addProgressListener(function (loaded) {
        messageHandler.send('OnDataProgress', { loaded: loaded });
       });
       pdfDataRangeTransport.addProgressiveReadListener(function (chunk) {
        messageHandler.send('OnDataRange', { chunk: chunk });
       });
       messageHandler.on('RequestDataRange', function transportDataRange(data) {
        pdfDataRangeTransport.requestDataRange(data.begin, data.end);
       }, this);
      }
      messageHandler.on('GetDoc', function transportDoc(data) {
       var pdfInfo = data.pdfInfo;
       this.numPages = data.pdfInfo.numPages;
       var loadingTask = this.loadingTask;
       var pdfDocument = new PDFDocumentProxy(pdfInfo, this, loadingTask);
       this.pdfDocument = pdfDocument;
       loadingTask._capability.resolve(pdfDocument);
      }, this);
      messageHandler.on('PasswordRequest', function transportPasswordRequest(exception) {
       this._passwordCapability = createPromiseCapability();
       if (loadingTask.onPassword) {
        var updatePassword = function (password) {
         this._passwordCapability.resolve({ password: password });
        }.bind(this);
        loadingTask.onPassword(updatePassword, exception.code);
       } else {
        this._passwordCapability.reject(new PasswordException(exception.message, exception.code));
       }
       return this._passwordCapability.promise;
      }, this);
      messageHandler.on('PasswordException', function transportPasswordException(exception) {
       loadingTask._capability.reject(new PasswordException(exception.message, exception.code));
      }, this);
      messageHandler.on('InvalidPDF', function transportInvalidPDF(exception) {
       this.loadingTask._capability.reject(new InvalidPDFException(exception.message));
      }, this);
      messageHandler.on('MissingPDF', function transportMissingPDF(exception) {
       this.loadingTask._capability.reject(new MissingPDFException(exception.message));
      }, this);
      messageHandler.on('UnexpectedResponse', function transportUnexpectedResponse(exception) {
       this.loadingTask._capability.reject(new UnexpectedResponseException(exception.message, exception.status));
      }, this);
      messageHandler.on('UnknownError', function transportUnknownError(exception) {
       this.loadingTask._capability.reject(new UnknownErrorException(exception.message, exception.details));
      }, this);
      messageHandler.on('DataLoaded', function transportPage(data) {
       this.downloadInfoCapability.resolve(data);
      }, this);
      messageHandler.on('PDFManagerReady', function transportPage(data) {
       if (this.pdfDataRangeTransport) {
        this.pdfDataRangeTransport.transportReady();
       }
      }, this);
      messageHandler.on('StartRenderPage', function transportRender(data) {
       if (this.destroyed) {
        return;
       }
       var page = this.pageCache[data.pageIndex];
       page.stats.timeEnd('Page Request');
       page._startRenderPage(data.transparency, data.intent);
      }, this);
      messageHandler.on('RenderPageChunk', function transportRender(data) {
       if (this.destroyed) {
        return;
       }
       var page = this.pageCache[data.pageIndex];
       page._renderPageChunk(data.operatorList, data.intent);
      }, this);
      messageHandler.on('commonobj', function transportObj(data) {
       if (this.destroyed) {
        return;
       }
       var id = data[0];
       var type = data[1];
       if (this.commonObjs.hasData(id)) {
        return;
       }
       switch (type) {
       case 'Font':
        var exportedData = data[2];
        if ('error' in exportedData) {
         var exportedError = exportedData.error;
         warn('Error during font loading: ' + exportedError);
         this.commonObjs.resolve(id, exportedError);
         break;
        }
        var fontRegistry = null;
        if (getDefaultSetting('pdfBug') && globalScope.FontInspector && globalScope['FontInspector'].enabled) {
         fontRegistry = {
          registerFont: function (font, url) {
           globalScope['FontInspector'].fontAdded(font, url);
          }
         };
        }
        var font = new FontFaceObject(exportedData, {
         isEvalSuported: getDefaultSetting('isEvalSupported'),
         disableFontFace: getDefaultSetting('disableFontFace'),
         fontRegistry: fontRegistry
        });
        this.fontLoader.bind([font], function fontReady(fontObjs) {
         this.commonObjs.resolve(id, font);
        }.bind(this));
        break;
       case 'FontPath':
        this.commonObjs.resolve(id, data[2]);
        break;
       default:
        error('Got unknown common object type ' + type);
       }
      }, this);
      messageHandler.on('obj', function transportObj(data) {
       if (this.destroyed) {
        return;
       }
       var id = data[0];
       var pageIndex = data[1];
       var type = data[2];
       var pageProxy = this.pageCache[pageIndex];
       var imageData;
       if (pageProxy.objs.hasData(id)) {
        return;
       }
       switch (type) {
       case 'JpegStream':
        imageData = data[3];
        loadJpegStream(id, imageData, pageProxy.objs);
        break;
       case 'Image':
        imageData = data[3];
        pageProxy.objs.resolve(id, imageData);
        var MAX_IMAGE_SIZE_TO_STORE = 8000000;
        if (imageData && 'data' in imageData && imageData.data.length > MAX_IMAGE_SIZE_TO_STORE) {
         pageProxy.cleanupAfterRender = true;
        }
        break;
       default:
        error('Got unknown object type ' + type);
       }
      }, this);
      messageHandler.on('DocProgress', function transportDocProgress(data) {
       if (this.destroyed) {
        return;
       }
       var loadingTask = this.loadingTask;
       if (loadingTask.onProgress) {
        loadingTask.onProgress({
         loaded: data.loaded,
         total: data.total
        });
       }
      }, this);
      messageHandler.on('PageError', function transportError(data) {
       if (this.destroyed) {
        return;
       }
       var page = this.pageCache[data.pageNum - 1];
       var intentState = page.intentStates[data.intent];
       if (intentState.displayReadyCapability) {
        intentState.displayReadyCapability.reject(data.error);
       } else {
        error(data.error);
       }
       if (intentState.operatorList) {
        intentState.operatorList.lastChunk = true;
        for (var i = 0; i < intentState.renderTasks.length; i++) {
         intentState.renderTasks[i].operatorListChanged();
        }
       }
      }, this);
      messageHandler.on('UnsupportedFeature', function transportUnsupportedFeature(data) {
       if (this.destroyed) {
        return;
       }
       var featureId = data.featureId;
       var loadingTask = this.loadingTask;
       if (loadingTask.onUnsupportedFeature) {
        loadingTask.onUnsupportedFeature(featureId);
       }
       _UnsupportedManager.notify(featureId);
      }, this);
      messageHandler.on('JpegDecode', function (data) {
       if (this.destroyed) {
        return Promise.reject(new Error('Worker was destroyed'));
       }
       var imageUrl = data[0];
       var components = data[1];
       if (components !== 3 && components !== 1) {
        return Promise.reject(new Error('Only 3 components or 1 component can be returned'));
       }
       return new Promise(function (resolve, reject) {
        var img = new Image();
        img.onload = function () {
         var width = img.width;
         var height = img.height;
         var size = width * height;
         var rgbaLength = size * 4;
         var buf = new Uint8Array(size * components);
         var tmpCanvas = createScratchCanvas(width, height);
         var tmpCtx = tmpCanvas.getContext('2d');
         tmpCtx.drawImage(img, 0, 0);
         var data = tmpCtx.getImageData(0, 0, width, height).data;
         var i, j;
         if (components === 3) {
          for (i = 0, j = 0; i < rgbaLength; i += 4, j += 3) {
           buf[j] = data[i];
           buf[j + 1] = data[i + 1];
           buf[j + 2] = data[i + 2];
          }
         } else if (components === 1) {
          for (i = 0, j = 0; i < rgbaLength; i += 4, j++) {
           buf[j] = data[i];
          }
         }
         resolve({
          data: buf,
          width: width,
          height: height
         });
        };
        img.onerror = function () {
         reject(new Error('JpegDecode failed to load image'));
        };
        img.src = imageUrl;
       });
      }, this);
     },
     getData: function WorkerTransport_getData() {
      return this.messageHandler.sendWithPromise('GetData', null);
     },
     getPage: function WorkerTransport_getPage(pageNumber, capability) {
      if (!isInt(pageNumber) || pageNumber <= 0 || pageNumber > this.numPages) {
       return Promise.reject(new Error('Invalid page request'));
      }
      var pageIndex = pageNumber - 1;
      if (pageIndex in this.pagePromises) {
       return this.pagePromises[pageIndex];
      }
      var promise = this.messageHandler.sendWithPromise('GetPage', { pageIndex: pageIndex }).then(function (pageInfo) {
       if (this.destroyed) {
        throw new Error('Transport destroyed');
       }
       var page = new PDFPageProxy(pageIndex, pageInfo, this);
       this.pageCache[pageIndex] = page;
       return page;
      }.bind(this));
      this.pagePromises[pageIndex] = promise;
      return promise;
     },
     getPageIndex: function WorkerTransport_getPageIndexByRef(ref) {
      return this.messageHandler.sendWithPromise('GetPageIndex', { ref: ref }).catch(function (reason) {
       return Promise.reject(new Error(reason));
      });
     },
     getAnnotations: function WorkerTransport_getAnnotations(pageIndex, intent) {
      return this.messageHandler.sendWithPromise('GetAnnotations', {
       pageIndex: pageIndex,
       intent: intent
      });
     },
     getDestinations: function WorkerTransport_getDestinations() {
      return this.messageHandler.sendWithPromise('GetDestinations', null);
     },
     getDestination: function WorkerTransport_getDestination(id) {
      return this.messageHandler.sendWithPromise('GetDestination', { id: id });
     },
     getPageLabels: function WorkerTransport_getPageLabels() {
      return this.messageHandler.sendWithPromise('GetPageLabels', null);
     },
     getAttachments: function WorkerTransport_getAttachments() {
      return this.messageHandler.sendWithPromise('GetAttachments', null);
     },
     getJavaScript: function WorkerTransport_getJavaScript() {
      return this.messageHandler.sendWithPromise('GetJavaScript', null);
     },
     getOutline: function WorkerTransport_getOutline() {
      return this.messageHandler.sendWithPromise('GetOutline', null);
     },
     getMetadata: function WorkerTransport_getMetadata() {
      return this.messageHandler.sendWithPromise('GetMetadata', null).then(function transportMetadata(results) {
       return {
        info: results[0],
        metadata: results[1] ? new Metadata(results[1]) : null
       };
      });
     },
     getStats: function WorkerTransport_getStats() {
      return this.messageHandler.sendWithPromise('GetStats', null);
     },
     startCleanup: function WorkerTransport_startCleanup() {
      this.messageHandler.sendWithPromise('Cleanup', null).then(function endCleanup() {
       for (var i = 0, ii = this.pageCache.length; i < ii; i++) {
        var page = this.pageCache[i];
        if (page) {
         page.cleanup();
        }
       }
       this.commonObjs.clear();
       this.fontLoader.clear();
      }.bind(this));
     }
    };
    return WorkerTransport;
   }();
   var PDFObjects = function PDFObjectsClosure() {
    function PDFObjects() {
     this.objs = Object.create(null);
    }
    PDFObjects.prototype = {
     ensureObj: function PDFObjects_ensureObj(objId) {
      if (this.objs[objId]) {
       return this.objs[objId];
      }
      var obj = {
       capability: createPromiseCapability(),
       data: null,
       resolved: false
      };
      this.objs[objId] = obj;
      return obj;
     },
     get: function PDFObjects_get(objId, callback) {
      if (callback) {
       this.ensureObj(objId).capability.promise.then(callback);
       return null;
      }
      var obj = this.objs[objId];
      if (!obj || !obj.resolved) {
       error('Requesting object that isn\'t resolved yet ' + objId);
      }
      return obj.data;
     },
     resolve: function PDFObjects_resolve(objId, data) {
      var obj = this.ensureObj(objId);
      obj.resolved = true;
      obj.data = data;
      obj.capability.resolve(data);
     },
     isResolved: function PDFObjects_isResolved(objId) {
      var objs = this.objs;
      if (!objs[objId]) {
       return false;
      } else {
       return objs[objId].resolved;
      }
     },
     hasData: function PDFObjects_hasData(objId) {
      return this.isResolved(objId);
     },
     getData: function PDFObjects_getData(objId) {
      var objs = this.objs;
      if (!objs[objId] || !objs[objId].resolved) {
       return null;
      } else {
       return objs[objId].data;
      }
     },
     clear: function PDFObjects_clear() {
      this.objs = Object.create(null);
     }
    };
    return PDFObjects;
   }();
   var RenderTask = function RenderTaskClosure() {
    function RenderTask(internalRenderTask) {
     this._internalRenderTask = internalRenderTask;
     this.onContinue = null;
    }
    RenderTask.prototype = {
     get promise() {
      return this._internalRenderTask.capability.promise;
     },
     cancel: function RenderTask_cancel() {
      this._internalRenderTask.cancel();
     },
     then: function RenderTask_then(onFulfilled, onRejected) {
      return this.promise.then.apply(this.promise, arguments);
     }
    };
    return RenderTask;
   }();
   var InternalRenderTask = function InternalRenderTaskClosure() {
    function InternalRenderTask(callback, params, objs, commonObjs, operatorList, pageNumber) {
     this.callback = callback;
     this.params = params;
     this.objs = objs;
     this.commonObjs = commonObjs;
     this.operatorListIdx = null;
     this.operatorList = operatorList;
     this.pageNumber = pageNumber;
     this.running = false;
     this.graphicsReadyCallback = null;
     this.graphicsReady = false;
     this.useRequestAnimationFrame = false;
     this.cancelled = false;
     this.capability = createPromiseCapability();
     this.task = new RenderTask(this);
     this._continueBound = this._continue.bind(this);
     this._scheduleNextBound = this._scheduleNext.bind(this);
     this._nextBound = this._next.bind(this);
    }
    InternalRenderTask.prototype = {
     initializeGraphics: function InternalRenderTask_initializeGraphics(transparency) {
      if (this.cancelled) {
       return;
      }
      if (getDefaultSetting('pdfBug') && globalScope.StepperManager && globalScope.StepperManager.enabled) {
       this.stepper = globalScope.StepperManager.create(this.pageNumber - 1);
       this.stepper.init(this.operatorList);
       this.stepper.nextBreakPoint = this.stepper.getNextBreakPoint();
      }
      var params = this.params;
      this.gfx = new CanvasGraphics(params.canvasContext, this.commonObjs, this.objs, params.imageLayer);
      this.gfx.beginDrawing(params.transform, params.viewport, transparency);
      this.operatorListIdx = 0;
      this.graphicsReady = true;
      if (this.graphicsReadyCallback) {
       this.graphicsReadyCallback();
      }
     },
     cancel: function InternalRenderTask_cancel() {
      this.running = false;
      this.cancelled = true;
      this.callback('cancelled');
     },
     operatorListChanged: function InternalRenderTask_operatorListChanged() {
      if (!this.graphicsReady) {
       if (!this.graphicsReadyCallback) {
        this.graphicsReadyCallback = this._continueBound;
       }
       return;
      }
      if (this.stepper) {
       this.stepper.updateOperatorList(this.operatorList);
      }
      if (this.running) {
       return;
      }
      this._continue();
     },
     _continue: function InternalRenderTask__continue() {
      this.running = true;
      if (this.cancelled) {
       return;
      }
      if (this.task.onContinue) {
       this.task.onContinue.call(this.task, this._scheduleNextBound);
      } else {
       this._scheduleNext();
      }
     },
     _scheduleNext: function InternalRenderTask__scheduleNext() {
      if (this.useRequestAnimationFrame && typeof window !== 'undefined') {
       window.requestAnimationFrame(this._nextBound);
      } else {
       Promise.resolve(undefined).then(this._nextBound);
      }
     },
     _next: function InternalRenderTask__next() {
      if (this.cancelled) {
       return;
      }
      this.operatorListIdx = this.gfx.executeOperatorList(this.operatorList, this.operatorListIdx, this._continueBound, this.stepper);
      if (this.operatorListIdx === this.operatorList.argsArray.length) {
       this.running = false;
       if (this.operatorList.lastChunk) {
        this.gfx.endDrawing();
        this.callback();
       }
      }
     }
    };
    return InternalRenderTask;
   }();
   var _UnsupportedManager = function UnsupportedManagerClosure() {
    var listeners = [];
    return {
     listen: function (cb) {
      deprecated('Global UnsupportedManager.listen is used: ' + ' use PDFDocumentLoadingTask.onUnsupportedFeature instead');
      listeners.push(cb);
     },
     notify: function (featureId) {
      for (var i = 0, ii = listeners.length; i < ii; i++) {
       listeners[i](featureId);
      }
     }
    };
   }();
   if (typeof pdfjsVersion !== 'undefined') {
    exports.version = pdfjsVersion;
   }
   if (typeof pdfjsBuild !== 'undefined') {
    exports.build = pdfjsBuild;
   }
   exports.getDocument = getDocument;
   exports.PDFDataRangeTransport = PDFDataRangeTransport;
   exports.PDFWorker = PDFWorker;
   exports.PDFDocumentProxy = PDFDocumentProxy;
   exports.PDFPageProxy = PDFPageProxy;
   exports._UnsupportedManager = _UnsupportedManager;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreColorSpace = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreFunction);
  }(this, function (exports, sharedUtil, corePrimitives, coreFunction) {
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var isString = sharedUtil.isString;
   var shadow = sharedUtil.shadow;
   var warn = sharedUtil.warn;
   var isDict = corePrimitives.isDict;
   var isName = corePrimitives.isName;
   var isStream = corePrimitives.isStream;
   var PDFFunction = coreFunction.PDFFunction;
   var ColorSpace = function ColorSpaceClosure() {
    function resizeRgbImage(src, bpc, w1, h1, w2, h2, alpha01, dest) {
     var COMPONENTS = 3;
     alpha01 = alpha01 !== 1 ? 0 : alpha01;
     var xRatio = w1 / w2;
     var yRatio = h1 / h2;
     var i, j, py, newIndex = 0, oldIndex;
     var xScaled = new Uint16Array(w2);
     var w1Scanline = w1 * COMPONENTS;
     for (i = 0; i < w2; i++) {
      xScaled[i] = Math.floor(i * xRatio) * COMPONENTS;
     }
     for (i = 0; i < h2; i++) {
      py = Math.floor(i * yRatio) * w1Scanline;
      for (j = 0; j < w2; j++) {
       oldIndex = py + xScaled[j];
       dest[newIndex++] = src[oldIndex++];
       dest[newIndex++] = src[oldIndex++];
       dest[newIndex++] = src[oldIndex++];
       newIndex += alpha01;
      }
     }
    }
    function ColorSpace() {
     error('should not call ColorSpace constructor');
    }
    ColorSpace.prototype = {
     getRgb: function ColorSpace_getRgb(src, srcOffset) {
      var rgb = new Uint8Array(3);
      this.getRgbItem(src, srcOffset, rgb, 0);
      return rgb;
     },
     getRgbItem: function ColorSpace_getRgbItem(src, srcOffset, dest, destOffset) {
      error('Should not call ColorSpace.getRgbItem');
     },
     getRgbBuffer: function ColorSpace_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      error('Should not call ColorSpace.getRgbBuffer');
     },
     getOutputLength: function ColorSpace_getOutputLength(inputLength, alpha01) {
      error('Should not call ColorSpace.getOutputLength');
     },
     isPassthrough: function ColorSpace_isPassthrough(bits) {
      return false;
     },
     fillRgb: function ColorSpace_fillRgb(dest, originalWidth, originalHeight, width, height, actualHeight, bpc, comps, alpha01) {
      var count = originalWidth * originalHeight;
      var rgbBuf = null;
      var numComponentColors = 1 << bpc;
      var needsResizing = originalHeight !== height || originalWidth !== width;
      var i, ii;
      if (this.isPassthrough(bpc)) {
       rgbBuf = comps;
      } else if (this.numComps === 1 && count > numComponentColors && this.name !== 'DeviceGray' && this.name !== 'DeviceRGB') {
       var allColors = bpc <= 8 ? new Uint8Array(numComponentColors) : new Uint16Array(numComponentColors);
       var key;
       for (i = 0; i < numComponentColors; i++) {
        allColors[i] = i;
       }
       var colorMap = new Uint8Array(numComponentColors * 3);
       this.getRgbBuffer(allColors, 0, numComponentColors, colorMap, 0, bpc, 0);
       var destPos, rgbPos;
       if (!needsResizing) {
        destPos = 0;
        for (i = 0; i < count; ++i) {
         key = comps[i] * 3;
         dest[destPos++] = colorMap[key];
         dest[destPos++] = colorMap[key + 1];
         dest[destPos++] = colorMap[key + 2];
         destPos += alpha01;
        }
       } else {
        rgbBuf = new Uint8Array(count * 3);
        rgbPos = 0;
        for (i = 0; i < count; ++i) {
         key = comps[i] * 3;
         rgbBuf[rgbPos++] = colorMap[key];
         rgbBuf[rgbPos++] = colorMap[key + 1];
         rgbBuf[rgbPos++] = colorMap[key + 2];
        }
       }
      } else {
       if (!needsResizing) {
        this.getRgbBuffer(comps, 0, width * actualHeight, dest, 0, bpc, alpha01);
       } else {
        rgbBuf = new Uint8Array(count * 3);
        this.getRgbBuffer(comps, 0, count, rgbBuf, 0, bpc, 0);
       }
      }
      if (rgbBuf) {
       if (needsResizing) {
        resizeRgbImage(rgbBuf, bpc, originalWidth, originalHeight, width, height, alpha01, dest);
       } else {
        rgbPos = 0;
        destPos = 0;
        for (i = 0, ii = width * actualHeight; i < ii; i++) {
         dest[destPos++] = rgbBuf[rgbPos++];
         dest[destPos++] = rgbBuf[rgbPos++];
         dest[destPos++] = rgbBuf[rgbPos++];
         destPos += alpha01;
        }
       }
      }
     },
     usesZeroToOneRange: true
    };
    ColorSpace.parse = function ColorSpace_parse(cs, xref, res) {
     var IR = ColorSpace.parseToIR(cs, xref, res);
     if (IR instanceof AlternateCS) {
      return IR;
     }
     return ColorSpace.fromIR(IR);
    };
    ColorSpace.fromIR = function ColorSpace_fromIR(IR) {
     var name = isArray(IR) ? IR[0] : IR;
     var whitePoint, blackPoint, gamma;
     switch (name) {
     case 'DeviceGrayCS':
      return this.singletons.gray;
     case 'DeviceRgbCS':
      return this.singletons.rgb;
     case 'DeviceCmykCS':
      return this.singletons.cmyk;
     case 'CalGrayCS':
      whitePoint = IR[1];
      blackPoint = IR[2];
      gamma = IR[3];
      return new CalGrayCS(whitePoint, blackPoint, gamma);
     case 'CalRGBCS':
      whitePoint = IR[1];
      blackPoint = IR[2];
      gamma = IR[3];
      var matrix = IR[4];
      return new CalRGBCS(whitePoint, blackPoint, gamma, matrix);
     case 'PatternCS':
      var basePatternCS = IR[1];
      if (basePatternCS) {
       basePatternCS = ColorSpace.fromIR(basePatternCS);
      }
      return new PatternCS(basePatternCS);
     case 'IndexedCS':
      var baseIndexedCS = IR[1];
      var hiVal = IR[2];
      var lookup = IR[3];
      return new IndexedCS(ColorSpace.fromIR(baseIndexedCS), hiVal, lookup);
     case 'AlternateCS':
      var numComps = IR[1];
      var alt = IR[2];
      var tintFnIR = IR[3];
      return new AlternateCS(numComps, ColorSpace.fromIR(alt), PDFFunction.fromIR(tintFnIR));
     case 'LabCS':
      whitePoint = IR[1];
      blackPoint = IR[2];
      var range = IR[3];
      return new LabCS(whitePoint, blackPoint, range);
     default:
      error('Unknown name ' + name);
     }
     return null;
    };
    ColorSpace.parseToIR = function ColorSpace_parseToIR(cs, xref, res) {
     if (isName(cs)) {
      var colorSpaces = res.get('ColorSpace');
      if (isDict(colorSpaces)) {
       var refcs = colorSpaces.get(cs.name);
       if (refcs) {
        cs = refcs;
       }
      }
     }
     cs = xref.fetchIfRef(cs);
     if (isName(cs)) {
      switch (cs.name) {
      case 'DeviceGray':
      case 'G':
       return 'DeviceGrayCS';
      case 'DeviceRGB':
      case 'RGB':
       return 'DeviceRgbCS';
      case 'DeviceCMYK':
      case 'CMYK':
       return 'DeviceCmykCS';
      case 'Pattern':
       return [
        'PatternCS',
        null
       ];
      default:
       error('unrecognized colorspace ' + cs.name);
      }
     } else if (isArray(cs)) {
      var mode = xref.fetchIfRef(cs[0]).name;
      var numComps, params, alt, whitePoint, blackPoint, gamma;
      switch (mode) {
      case 'DeviceGray':
      case 'G':
       return 'DeviceGrayCS';
      case 'DeviceRGB':
      case 'RGB':
       return 'DeviceRgbCS';
      case 'DeviceCMYK':
      case 'CMYK':
       return 'DeviceCmykCS';
      case 'CalGray':
       params = xref.fetchIfRef(cs[1]);
       whitePoint = params.getArray('WhitePoint');
       blackPoint = params.getArray('BlackPoint');
       gamma = params.get('Gamma');
       return [
        'CalGrayCS',
        whitePoint,
        blackPoint,
        gamma
       ];
      case 'CalRGB':
       params = xref.fetchIfRef(cs[1]);
       whitePoint = params.getArray('WhitePoint');
       blackPoint = params.getArray('BlackPoint');
       gamma = params.getArray('Gamma');
       var matrix = params.getArray('Matrix');
       return [
        'CalRGBCS',
        whitePoint,
        blackPoint,
        gamma,
        matrix
       ];
      case 'ICCBased':
       var stream = xref.fetchIfRef(cs[1]);
       var dict = stream.dict;
       numComps = dict.get('N');
       alt = dict.get('Alternate');
       if (alt) {
        var altIR = ColorSpace.parseToIR(alt, xref, res);
        var altCS = ColorSpace.fromIR(altIR);
        if (altCS.numComps === numComps) {
         return altIR;
        }
        warn('ICCBased color space: Ignoring incorrect /Alternate entry.');
       }
       if (numComps === 1) {
        return 'DeviceGrayCS';
       } else if (numComps === 3) {
        return 'DeviceRgbCS';
       } else if (numComps === 4) {
        return 'DeviceCmykCS';
       }
       break;
      case 'Pattern':
       var basePatternCS = cs[1] || null;
       if (basePatternCS) {
        basePatternCS = ColorSpace.parseToIR(basePatternCS, xref, res);
       }
       return [
        'PatternCS',
        basePatternCS
       ];
      case 'Indexed':
      case 'I':
       var baseIndexedCS = ColorSpace.parseToIR(cs[1], xref, res);
       var hiVal = xref.fetchIfRef(cs[2]) + 1;
       var lookup = xref.fetchIfRef(cs[3]);
       if (isStream(lookup)) {
        lookup = lookup.getBytes();
       }
       return [
        'IndexedCS',
        baseIndexedCS,
        hiVal,
        lookup
       ];
      case 'Separation':
      case 'DeviceN':
       var name = xref.fetchIfRef(cs[1]);
       numComps = isArray(name) ? name.length : 1;
       alt = ColorSpace.parseToIR(cs[2], xref, res);
       var tintFnIR = PDFFunction.getIR(xref, xref.fetchIfRef(cs[3]));
       return [
        'AlternateCS',
        numComps,
        alt,
        tintFnIR
       ];
      case 'Lab':
       params = xref.fetchIfRef(cs[1]);
       whitePoint = params.getArray('WhitePoint');
       blackPoint = params.getArray('BlackPoint');
       var range = params.getArray('Range');
       return [
        'LabCS',
        whitePoint,
        blackPoint,
        range
       ];
      default:
       error('unimplemented color space object "' + mode + '"');
      }
     } else {
      error('unrecognized color space object: "' + cs + '"');
     }
     return null;
    };
    ColorSpace.isDefaultDecode = function ColorSpace_isDefaultDecode(decode, n) {
     if (!isArray(decode)) {
      return true;
     }
     if (n * 2 !== decode.length) {
      warn('The decode map is not the correct length');
      return true;
     }
     for (var i = 0, ii = decode.length; i < ii; i += 2) {
      if (decode[i] !== 0 || decode[i + 1] !== 1) {
       return false;
      }
     }
     return true;
    };
    ColorSpace.singletons = {
     get gray() {
      return shadow(this, 'gray', new DeviceGrayCS());
     },
     get rgb() {
      return shadow(this, 'rgb', new DeviceRgbCS());
     },
     get cmyk() {
      return shadow(this, 'cmyk', new DeviceCmykCS());
     }
    };
    return ColorSpace;
   }();
   var AlternateCS = function AlternateCSClosure() {
    function AlternateCS(numComps, base, tintFn) {
     this.name = 'Alternate';
     this.numComps = numComps;
     this.defaultColor = new Float32Array(numComps);
     for (var i = 0; i < numComps; ++i) {
      this.defaultColor[i] = 1;
     }
     this.base = base;
     this.tintFn = tintFn;
     this.tmpBuf = new Float32Array(base.numComps);
    }
    AlternateCS.prototype = {
     getRgb: ColorSpace.prototype.getRgb,
     getRgbItem: function AlternateCS_getRgbItem(src, srcOffset, dest, destOffset) {
      var tmpBuf = this.tmpBuf;
      this.tintFn(src, srcOffset, tmpBuf, 0);
      this.base.getRgbItem(tmpBuf, 0, dest, destOffset);
     },
     getRgbBuffer: function AlternateCS_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      var tintFn = this.tintFn;
      var base = this.base;
      var scale = 1 / ((1 << bits) - 1);
      var baseNumComps = base.numComps;
      var usesZeroToOneRange = base.usesZeroToOneRange;
      var isPassthrough = (base.isPassthrough(8) || !usesZeroToOneRange) && alpha01 === 0;
      var pos = isPassthrough ? destOffset : 0;
      var baseBuf = isPassthrough ? dest : new Uint8Array(baseNumComps * count);
      var numComps = this.numComps;
      var scaled = new Float32Array(numComps);
      var tinted = new Float32Array(baseNumComps);
      var i, j;
      for (i = 0; i < count; i++) {
       for (j = 0; j < numComps; j++) {
        scaled[j] = src[srcOffset++] * scale;
       }
       tintFn(scaled, 0, tinted, 0);
       if (usesZeroToOneRange) {
        for (j = 0; j < baseNumComps; j++) {
         baseBuf[pos++] = tinted[j] * 255;
        }
       } else {
        base.getRgbItem(tinted, 0, baseBuf, pos);
        pos += baseNumComps;
       }
      }
      if (!isPassthrough) {
       base.getRgbBuffer(baseBuf, 0, count, dest, destOffset, 8, alpha01);
      }
     },
     getOutputLength: function AlternateCS_getOutputLength(inputLength, alpha01) {
      return this.base.getOutputLength(inputLength * this.base.numComps / this.numComps, alpha01);
     },
     isPassthrough: ColorSpace.prototype.isPassthrough,
     fillRgb: ColorSpace.prototype.fillRgb,
     isDefaultDecode: function AlternateCS_isDefaultDecode(decodeMap) {
      return ColorSpace.isDefaultDecode(decodeMap, this.numComps);
     },
     usesZeroToOneRange: true
    };
    return AlternateCS;
   }();
   var PatternCS = function PatternCSClosure() {
    function PatternCS(baseCS) {
     this.name = 'Pattern';
     this.base = baseCS;
    }
    PatternCS.prototype = {};
    return PatternCS;
   }();
   var IndexedCS = function IndexedCSClosure() {
    function IndexedCS(base, highVal, lookup) {
     this.name = 'Indexed';
     this.numComps = 1;
     this.defaultColor = new Uint8Array(this.numComps);
     this.base = base;
     this.highVal = highVal;
     var baseNumComps = base.numComps;
     var length = baseNumComps * highVal;
     if (isStream(lookup)) {
      this.lookup = new Uint8Array(length);
      var bytes = lookup.getBytes(length);
      this.lookup.set(bytes);
     } else if (isString(lookup)) {
      this.lookup = new Uint8Array(length);
      for (var i = 0; i < length; ++i) {
       this.lookup[i] = lookup.charCodeAt(i);
      }
     } else if (lookup instanceof Uint8Array || lookup instanceof Array) {
      this.lookup = lookup;
     } else {
      error('Unrecognized lookup table: ' + lookup);
     }
    }
    IndexedCS.prototype = {
     getRgb: ColorSpace.prototype.getRgb,
     getRgbItem: function IndexedCS_getRgbItem(src, srcOffset, dest, destOffset) {
      var numComps = this.base.numComps;
      var start = src[srcOffset] * numComps;
      this.base.getRgbItem(this.lookup, start, dest, destOffset);
     },
     getRgbBuffer: function IndexedCS_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      var base = this.base;
      var numComps = base.numComps;
      var outputDelta = base.getOutputLength(numComps, alpha01);
      var lookup = this.lookup;
      for (var i = 0; i < count; ++i) {
       var lookupPos = src[srcOffset++] * numComps;
       base.getRgbBuffer(lookup, lookupPos, 1, dest, destOffset, 8, alpha01);
       destOffset += outputDelta;
      }
     },
     getOutputLength: function IndexedCS_getOutputLength(inputLength, alpha01) {
      return this.base.getOutputLength(inputLength * this.base.numComps, alpha01);
     },
     isPassthrough: ColorSpace.prototype.isPassthrough,
     fillRgb: ColorSpace.prototype.fillRgb,
     isDefaultDecode: function IndexedCS_isDefaultDecode(decodeMap) {
      return true;
     },
     usesZeroToOneRange: true
    };
    return IndexedCS;
   }();
   var DeviceGrayCS = function DeviceGrayCSClosure() {
    function DeviceGrayCS() {
     this.name = 'DeviceGray';
     this.numComps = 1;
     this.defaultColor = new Float32Array(this.numComps);
    }
    DeviceGrayCS.prototype = {
     getRgb: ColorSpace.prototype.getRgb,
     getRgbItem: function DeviceGrayCS_getRgbItem(src, srcOffset, dest, destOffset) {
      var c = src[srcOffset] * 255 | 0;
      c = c < 0 ? 0 : c > 255 ? 255 : c;
      dest[destOffset] = dest[destOffset + 1] = dest[destOffset + 2] = c;
     },
     getRgbBuffer: function DeviceGrayCS_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      var scale = 255 / ((1 << bits) - 1);
      var j = srcOffset, q = destOffset;
      for (var i = 0; i < count; ++i) {
       var c = scale * src[j++] | 0;
       dest[q++] = c;
       dest[q++] = c;
       dest[q++] = c;
       q += alpha01;
      }
     },
     getOutputLength: function DeviceGrayCS_getOutputLength(inputLength, alpha01) {
      return inputLength * (3 + alpha01);
     },
     isPassthrough: ColorSpace.prototype.isPassthrough,
     fillRgb: ColorSpace.prototype.fillRgb,
     isDefaultDecode: function DeviceGrayCS_isDefaultDecode(decodeMap) {
      return ColorSpace.isDefaultDecode(decodeMap, this.numComps);
     },
     usesZeroToOneRange: true
    };
    return DeviceGrayCS;
   }();
   var DeviceRgbCS = function DeviceRgbCSClosure() {
    function DeviceRgbCS() {
     this.name = 'DeviceRGB';
     this.numComps = 3;
     this.defaultColor = new Float32Array(this.numComps);
    }
    DeviceRgbCS.prototype = {
     getRgb: ColorSpace.prototype.getRgb,
     getRgbItem: function DeviceRgbCS_getRgbItem(src, srcOffset, dest, destOffset) {
      var r = src[srcOffset] * 255 | 0;
      var g = src[srcOffset + 1] * 255 | 0;
      var b = src[srcOffset + 2] * 255 | 0;
      dest[destOffset] = r < 0 ? 0 : r > 255 ? 255 : r;
      dest[destOffset + 1] = g < 0 ? 0 : g > 255 ? 255 : g;
      dest[destOffset + 2] = b < 0 ? 0 : b > 255 ? 255 : b;
     },
     getRgbBuffer: function DeviceRgbCS_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      if (bits === 8 && alpha01 === 0) {
       dest.set(src.subarray(srcOffset, srcOffset + count * 3), destOffset);
       return;
      }
      var scale = 255 / ((1 << bits) - 1);
      var j = srcOffset, q = destOffset;
      for (var i = 0; i < count; ++i) {
       dest[q++] = scale * src[j++] | 0;
       dest[q++] = scale * src[j++] | 0;
       dest[q++] = scale * src[j++] | 0;
       q += alpha01;
      }
     },
     getOutputLength: function DeviceRgbCS_getOutputLength(inputLength, alpha01) {
      return inputLength * (3 + alpha01) / 3 | 0;
     },
     isPassthrough: function DeviceRgbCS_isPassthrough(bits) {
      return bits === 8;
     },
     fillRgb: ColorSpace.prototype.fillRgb,
     isDefaultDecode: function DeviceRgbCS_isDefaultDecode(decodeMap) {
      return ColorSpace.isDefaultDecode(decodeMap, this.numComps);
     },
     usesZeroToOneRange: true
    };
    return DeviceRgbCS;
   }();
   var DeviceCmykCS = function DeviceCmykCSClosure() {
    function convertToRgb(src, srcOffset, srcScale, dest, destOffset) {
     var c = src[srcOffset + 0] * srcScale;
     var m = src[srcOffset + 1] * srcScale;
     var y = src[srcOffset + 2] * srcScale;
     var k = src[srcOffset + 3] * srcScale;
     var r = c * (-4.387332384609988 * c + 54.48615194189176 * m + 18.82290502165302 * y + 212.25662451639585 * k + -285.2331026137004) + m * (1.7149763477362134 * m - 5.6096736904047315 * y + -17.873870861415444 * k - 5.497006427196366) + y * (-2.5217340131683033 * y - 21.248923337353073 * k + 17.5119270841813) + k * (-21.86122147463605 * k - 189.48180835922747) + 255 | 0;
     var g = c * (8.841041422036149 * c + 60.118027045597366 * m + 6.871425592049007 * y + 31.159100130055922 * k + -79.2970844816548) + m * (-15.310361306967817 * m + 17.575251261109482 * y + 131.35250912493976 * k - 190.9453302588951) + y * (4.444339102852739 * y + 9.8632861493405 * k - 24.86741582555878) + k * (-20.737325471181034 * k - 187.80453709719578) + 255 | 0;
     var b = c * (0.8842522430003296 * c + 8.078677503112928 * m + 30.89978309703729 * y - 0.23883238689178934 * k + -14.183576799673286) + m * (10.49593273432072 * m + 63.02378494754052 * y + 50.606957656360734 * k - 112.23884253719248) + y * (0.03296041114873217 * y + 115.60384449646641 * k + -193.58209356861505) + k * (-22.33816807309886 * k - 180.12613974708367) + 255 | 0;
     dest[destOffset] = r > 255 ? 255 : r < 0 ? 0 : r;
     dest[destOffset + 1] = g > 255 ? 255 : g < 0 ? 0 : g;
     dest[destOffset + 2] = b > 255 ? 255 : b < 0 ? 0 : b;
    }
    function DeviceCmykCS() {
     this.name = 'DeviceCMYK';
     this.numComps = 4;
     this.defaultColor = new Float32Array(this.numComps);
     this.defaultColor[3] = 1;
    }
    DeviceCmykCS.prototype = {
     getRgb: ColorSpace.prototype.getRgb,
     getRgbItem: function DeviceCmykCS_getRgbItem(src, srcOffset, dest, destOffset) {
      convertToRgb(src, srcOffset, 1, dest, destOffset);
     },
     getRgbBuffer: function DeviceCmykCS_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      var scale = 1 / ((1 << bits) - 1);
      for (var i = 0; i < count; i++) {
       convertToRgb(src, srcOffset, scale, dest, destOffset);
       srcOffset += 4;
       destOffset += 3 + alpha01;
      }
     },
     getOutputLength: function DeviceCmykCS_getOutputLength(inputLength, alpha01) {
      return inputLength / 4 * (3 + alpha01) | 0;
     },
     isPassthrough: ColorSpace.prototype.isPassthrough,
     fillRgb: ColorSpace.prototype.fillRgb,
     isDefaultDecode: function DeviceCmykCS_isDefaultDecode(decodeMap) {
      return ColorSpace.isDefaultDecode(decodeMap, this.numComps);
     },
     usesZeroToOneRange: true
    };
    return DeviceCmykCS;
   }();
   var CalGrayCS = function CalGrayCSClosure() {
    function CalGrayCS(whitePoint, blackPoint, gamma) {
     this.name = 'CalGray';
     this.numComps = 1;
     this.defaultColor = new Float32Array(this.numComps);
     if (!whitePoint) {
      error('WhitePoint missing - required for color space CalGray');
     }
     blackPoint = blackPoint || [
      0,
      0,
      0
     ];
     gamma = gamma || 1;
     this.XW = whitePoint[0];
     this.YW = whitePoint[1];
     this.ZW = whitePoint[2];
     this.XB = blackPoint[0];
     this.YB = blackPoint[1];
     this.ZB = blackPoint[2];
     this.G = gamma;
     if (this.XW < 0 || this.ZW < 0 || this.YW !== 1) {
      error('Invalid WhitePoint components for ' + this.name + ', no fallback available');
     }
     if (this.XB < 0 || this.YB < 0 || this.ZB < 0) {
      info('Invalid BlackPoint for ' + this.name + ', falling back to default');
      this.XB = this.YB = this.ZB = 0;
     }
     if (this.XB !== 0 || this.YB !== 0 || this.ZB !== 0) {
      warn(this.name + ', BlackPoint: XB: ' + this.XB + ', YB: ' + this.YB + ', ZB: ' + this.ZB + ', only default values are supported.');
     }
     if (this.G < 1) {
      info('Invalid Gamma: ' + this.G + ' for ' + this.name + ', falling back to default');
      this.G = 1;
     }
    }
    function convertToRgb(cs, src, srcOffset, dest, destOffset, scale) {
     var A = src[srcOffset] * scale;
     var AG = Math.pow(A, cs.G);
     var L = cs.YW * AG;
     var val = Math.max(295.8 * Math.pow(L, 0.333333333333333333) - 40.8, 0) | 0;
     dest[destOffset] = val;
     dest[destOffset + 1] = val;
     dest[destOffset + 2] = val;
    }
    CalGrayCS.prototype = {
     getRgb: ColorSpace.prototype.getRgb,
     getRgbItem: function CalGrayCS_getRgbItem(src, srcOffset, dest, destOffset) {
      convertToRgb(this, src, srcOffset, dest, destOffset, 1);
     },
     getRgbBuffer: function CalGrayCS_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      var scale = 1 / ((1 << bits) - 1);
      for (var i = 0; i < count; ++i) {
       convertToRgb(this, src, srcOffset, dest, destOffset, scale);
       srcOffset += 1;
       destOffset += 3 + alpha01;
      }
     },
     getOutputLength: function CalGrayCS_getOutputLength(inputLength, alpha01) {
      return inputLength * (3 + alpha01);
     },
     isPassthrough: ColorSpace.prototype.isPassthrough,
     fillRgb: ColorSpace.prototype.fillRgb,
     isDefaultDecode: function CalGrayCS_isDefaultDecode(decodeMap) {
      return ColorSpace.isDefaultDecode(decodeMap, this.numComps);
     },
     usesZeroToOneRange: true
    };
    return CalGrayCS;
   }();
   var CalRGBCS = function CalRGBCSClosure() {
    var BRADFORD_SCALE_MATRIX = new Float32Array([
     0.8951,
     0.2664,
     -0.1614,
     -0.7502,
     1.7135,
     0.0367,
     0.0389,
     -0.0685,
     1.0296
    ]);
    var BRADFORD_SCALE_INVERSE_MATRIX = new Float32Array([
     0.9869929,
     -0.1470543,
     0.1599627,
     0.4323053,
     0.5183603,
     0.0492912,
     -0.0085287,
     0.0400428,
     0.9684867
    ]);
    var SRGB_D65_XYZ_TO_RGB_MATRIX = new Float32Array([
     3.2404542,
     -1.5371385,
     -0.4985314,
     -0.9692660,
     1.8760108,
     0.0415560,
     0.0556434,
     -0.2040259,
     1.0572252
    ]);
    var FLAT_WHITEPOINT_MATRIX = new Float32Array([
     1,
     1,
     1
    ]);
    var tempNormalizeMatrix = new Float32Array(3);
    var tempConvertMatrix1 = new Float32Array(3);
    var tempConvertMatrix2 = new Float32Array(3);
    var DECODE_L_CONSTANT = Math.pow((8 + 16) / 116, 3) / 8.0;
    function CalRGBCS(whitePoint, blackPoint, gamma, matrix) {
     this.name = 'CalRGB';
     this.numComps = 3;
     this.defaultColor = new Float32Array(this.numComps);
     if (!whitePoint) {
      error('WhitePoint missing - required for color space CalRGB');
     }
     blackPoint = blackPoint || new Float32Array(3);
     gamma = gamma || new Float32Array([
      1,
      1,
      1
     ]);
     matrix = matrix || new Float32Array([
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1
     ]);
     var XW = whitePoint[0];
     var YW = whitePoint[1];
     var ZW = whitePoint[2];
     this.whitePoint = whitePoint;
     var XB = blackPoint[0];
     var YB = blackPoint[1];
     var ZB = blackPoint[2];
     this.blackPoint = blackPoint;
     this.GR = gamma[0];
     this.GG = gamma[1];
     this.GB = gamma[2];
     this.MXA = matrix[0];
     this.MYA = matrix[1];
     this.MZA = matrix[2];
     this.MXB = matrix[3];
     this.MYB = matrix[4];
     this.MZB = matrix[5];
     this.MXC = matrix[6];
     this.MYC = matrix[7];
     this.MZC = matrix[8];
     if (XW < 0 || ZW < 0 || YW !== 1) {
      error('Invalid WhitePoint components for ' + this.name + ', no fallback available');
     }
     if (XB < 0 || YB < 0 || ZB < 0) {
      info('Invalid BlackPoint for ' + this.name + ' [' + XB + ', ' + YB + ', ' + ZB + '], falling back to default');
      this.blackPoint = new Float32Array(3);
     }
     if (this.GR < 0 || this.GG < 0 || this.GB < 0) {
      info('Invalid Gamma [' + this.GR + ', ' + this.GG + ', ' + this.GB + '] for ' + this.name + ', falling back to default');
      this.GR = this.GG = this.GB = 1;
     }
     if (this.MXA < 0 || this.MYA < 0 || this.MZA < 0 || this.MXB < 0 || this.MYB < 0 || this.MZB < 0 || this.MXC < 0 || this.MYC < 0 || this.MZC < 0) {
      info('Invalid Matrix for ' + this.name + ' [' + this.MXA + ', ' + this.MYA + ', ' + this.MZA + this.MXB + ', ' + this.MYB + ', ' + this.MZB + this.MXC + ', ' + this.MYC + ', ' + this.MZC + '], falling back to default');
      this.MXA = this.MYB = this.MZC = 1;
      this.MXB = this.MYA = this.MZA = this.MXC = this.MYC = this.MZB = 0;
     }
    }
    function matrixProduct(a, b, result) {
     result[0] = a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
     result[1] = a[3] * b[0] + a[4] * b[1] + a[5] * b[2];
     result[2] = a[6] * b[0] + a[7] * b[1] + a[8] * b[2];
    }
    function convertToFlat(sourceWhitePoint, LMS, result) {
     result[0] = LMS[0] * 1 / sourceWhitePoint[0];
     result[1] = LMS[1] * 1 / sourceWhitePoint[1];
     result[2] = LMS[2] * 1 / sourceWhitePoint[2];
    }
    function convertToD65(sourceWhitePoint, LMS, result) {
     var D65X = 0.95047;
     var D65Y = 1;
     var D65Z = 1.08883;
     result[0] = LMS[0] * D65X / sourceWhitePoint[0];
     result[1] = LMS[1] * D65Y / sourceWhitePoint[1];
     result[2] = LMS[2] * D65Z / sourceWhitePoint[2];
    }
    function sRGBTransferFunction(color) {
     if (color <= 0.0031308) {
      return adjustToRange(0, 1, 12.92 * color);
     }
     return adjustToRange(0, 1, (1 + 0.055) * Math.pow(color, 1 / 2.4) - 0.055);
    }
    function adjustToRange(min, max, value) {
     return Math.max(min, Math.min(max, value));
    }
    function decodeL(L) {
     if (L < 0) {
      return -decodeL(-L);
     }
     if (L > 8.0) {
      return Math.pow((L + 16) / 116, 3);
     }
     return L * DECODE_L_CONSTANT;
    }
    function compensateBlackPoint(sourceBlackPoint, XYZ_Flat, result) {
     if (sourceBlackPoint[0] === 0 && sourceBlackPoint[1] === 0 && sourceBlackPoint[2] === 0) {
      result[0] = XYZ_Flat[0];
      result[1] = XYZ_Flat[1];
      result[2] = XYZ_Flat[2];
      return;
     }
     var zeroDecodeL = decodeL(0);
     var X_DST = zeroDecodeL;
     var X_SRC = decodeL(sourceBlackPoint[0]);
     var Y_DST = zeroDecodeL;
     var Y_SRC = decodeL(sourceBlackPoint[1]);
     var Z_DST = zeroDecodeL;
     var Z_SRC = decodeL(sourceBlackPoint[2]);
     var X_Scale = (1 - X_DST) / (1 - X_SRC);
     var X_Offset = 1 - X_Scale;
     var Y_Scale = (1 - Y_DST) / (1 - Y_SRC);
     var Y_Offset = 1 - Y_Scale;
     var Z_Scale = (1 - Z_DST) / (1 - Z_SRC);
     var Z_Offset = 1 - Z_Scale;
     result[0] = XYZ_Flat[0] * X_Scale + X_Offset;
     result[1] = XYZ_Flat[1] * Y_Scale + Y_Offset;
     result[2] = XYZ_Flat[2] * Z_Scale + Z_Offset;
    }
    function normalizeWhitePointToFlat(sourceWhitePoint, XYZ_In, result) {
     if (sourceWhitePoint[0] === 1 && sourceWhitePoint[2] === 1) {
      result[0] = XYZ_In[0];
      result[1] = XYZ_In[1];
      result[2] = XYZ_In[2];
      return;
     }
     var LMS = result;
     matrixProduct(BRADFORD_SCALE_MATRIX, XYZ_In, LMS);
     var LMS_Flat = tempNormalizeMatrix;
     convertToFlat(sourceWhitePoint, LMS, LMS_Flat);
     matrixProduct(BRADFORD_SCALE_INVERSE_MATRIX, LMS_Flat, result);
    }
    function normalizeWhitePointToD65(sourceWhitePoint, XYZ_In, result) {
     var LMS = result;
     matrixProduct(BRADFORD_SCALE_MATRIX, XYZ_In, LMS);
     var LMS_D65 = tempNormalizeMatrix;
     convertToD65(sourceWhitePoint, LMS, LMS_D65);
     matrixProduct(BRADFORD_SCALE_INVERSE_MATRIX, LMS_D65, result);
    }
    function convertToRgb(cs, src, srcOffset, dest, destOffset, scale) {
     var A = adjustToRange(0, 1, src[srcOffset] * scale);
     var B = adjustToRange(0, 1, src[srcOffset + 1] * scale);
     var C = adjustToRange(0, 1, src[srcOffset + 2] * scale);
     var AGR = Math.pow(A, cs.GR);
     var BGG = Math.pow(B, cs.GG);
     var CGB = Math.pow(C, cs.GB);
     var X = cs.MXA * AGR + cs.MXB * BGG + cs.MXC * CGB;
     var Y = cs.MYA * AGR + cs.MYB * BGG + cs.MYC * CGB;
     var Z = cs.MZA * AGR + cs.MZB * BGG + cs.MZC * CGB;
     var XYZ = tempConvertMatrix1;
     XYZ[0] = X;
     XYZ[1] = Y;
     XYZ[2] = Z;
     var XYZ_Flat = tempConvertMatrix2;
     normalizeWhitePointToFlat(cs.whitePoint, XYZ, XYZ_Flat);
     var XYZ_Black = tempConvertMatrix1;
     compensateBlackPoint(cs.blackPoint, XYZ_Flat, XYZ_Black);
     var XYZ_D65 = tempConvertMatrix2;
     normalizeWhitePointToD65(FLAT_WHITEPOINT_MATRIX, XYZ_Black, XYZ_D65);
     var SRGB = tempConvertMatrix1;
     matrixProduct(SRGB_D65_XYZ_TO_RGB_MATRIX, XYZ_D65, SRGB);
     var sR = sRGBTransferFunction(SRGB[0]);
     var sG = sRGBTransferFunction(SRGB[1]);
     var sB = sRGBTransferFunction(SRGB[2]);
     dest[destOffset] = Math.round(sR * 255);
     dest[destOffset + 1] = Math.round(sG * 255);
     dest[destOffset + 2] = Math.round(sB * 255);
    }
    CalRGBCS.prototype = {
     getRgb: function CalRGBCS_getRgb(src, srcOffset) {
      var rgb = new Uint8Array(3);
      this.getRgbItem(src, srcOffset, rgb, 0);
      return rgb;
     },
     getRgbItem: function CalRGBCS_getRgbItem(src, srcOffset, dest, destOffset) {
      convertToRgb(this, src, srcOffset, dest, destOffset, 1);
     },
     getRgbBuffer: function CalRGBCS_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      var scale = 1 / ((1 << bits) - 1);
      for (var i = 0; i < count; ++i) {
       convertToRgb(this, src, srcOffset, dest, destOffset, scale);
       srcOffset += 3;
       destOffset += 3 + alpha01;
      }
     },
     getOutputLength: function CalRGBCS_getOutputLength(inputLength, alpha01) {
      return inputLength * (3 + alpha01) / 3 | 0;
     },
     isPassthrough: ColorSpace.prototype.isPassthrough,
     fillRgb: ColorSpace.prototype.fillRgb,
     isDefaultDecode: function CalRGBCS_isDefaultDecode(decodeMap) {
      return ColorSpace.isDefaultDecode(decodeMap, this.numComps);
     },
     usesZeroToOneRange: true
    };
    return CalRGBCS;
   }();
   var LabCS = function LabCSClosure() {
    function LabCS(whitePoint, blackPoint, range) {
     this.name = 'Lab';
     this.numComps = 3;
     this.defaultColor = new Float32Array(this.numComps);
     if (!whitePoint) {
      error('WhitePoint missing - required for color space Lab');
     }
     blackPoint = blackPoint || [
      0,
      0,
      0
     ];
     range = range || [
      -100,
      100,
      -100,
      100
     ];
     this.XW = whitePoint[0];
     this.YW = whitePoint[1];
     this.ZW = whitePoint[2];
     this.amin = range[0];
     this.amax = range[1];
     this.bmin = range[2];
     this.bmax = range[3];
     this.XB = blackPoint[0];
     this.YB = blackPoint[1];
     this.ZB = blackPoint[2];
     if (this.XW < 0 || this.ZW < 0 || this.YW !== 1) {
      error('Invalid WhitePoint components, no fallback available');
     }
     if (this.XB < 0 || this.YB < 0 || this.ZB < 0) {
      info('Invalid BlackPoint, falling back to default');
      this.XB = this.YB = this.ZB = 0;
     }
     if (this.amin > this.amax || this.bmin > this.bmax) {
      info('Invalid Range, falling back to defaults');
      this.amin = -100;
      this.amax = 100;
      this.bmin = -100;
      this.bmax = 100;
     }
    }
    function fn_g(x) {
     if (x >= 6 / 29) {
      return x * x * x;
     } else {
      return 108 / 841 * (x - 4 / 29);
     }
    }
    function decode(value, high1, low2, high2) {
     return low2 + value * (high2 - low2) / high1;
    }
    function convertToRgb(cs, src, srcOffset, maxVal, dest, destOffset) {
     var Ls = src[srcOffset];
     var as = src[srcOffset + 1];
     var bs = src[srcOffset + 2];
     if (maxVal !== false) {
      Ls = decode(Ls, maxVal, 0, 100);
      as = decode(as, maxVal, cs.amin, cs.amax);
      bs = decode(bs, maxVal, cs.bmin, cs.bmax);
     }
     as = as > cs.amax ? cs.amax : as < cs.amin ? cs.amin : as;
     bs = bs > cs.bmax ? cs.bmax : bs < cs.bmin ? cs.bmin : bs;
     var M = (Ls + 16) / 116;
     var L = M + as / 500;
     var N = M - bs / 200;
     var X = cs.XW * fn_g(L);
     var Y = cs.YW * fn_g(M);
     var Z = cs.ZW * fn_g(N);
     var r, g, b;
     if (cs.ZW < 1) {
      r = X * 3.1339 + Y * -1.6170 + Z * -0.4906;
      g = X * -0.9785 + Y * 1.9160 + Z * 0.0333;
      b = X * 0.0720 + Y * -0.2290 + Z * 1.4057;
     } else {
      r = X * 3.2406 + Y * -1.5372 + Z * -0.4986;
      g = X * -0.9689 + Y * 1.8758 + Z * 0.0415;
      b = X * 0.0557 + Y * -0.2040 + Z * 1.0570;
     }
     dest[destOffset] = r <= 0 ? 0 : r >= 1 ? 255 : Math.sqrt(r) * 255 | 0;
     dest[destOffset + 1] = g <= 0 ? 0 : g >= 1 ? 255 : Math.sqrt(g) * 255 | 0;
     dest[destOffset + 2] = b <= 0 ? 0 : b >= 1 ? 255 : Math.sqrt(b) * 255 | 0;
    }
    LabCS.prototype = {
     getRgb: ColorSpace.prototype.getRgb,
     getRgbItem: function LabCS_getRgbItem(src, srcOffset, dest, destOffset) {
      convertToRgb(this, src, srcOffset, false, dest, destOffset);
     },
     getRgbBuffer: function LabCS_getRgbBuffer(src, srcOffset, count, dest, destOffset, bits, alpha01) {
      var maxVal = (1 << bits) - 1;
      for (var i = 0; i < count; i++) {
       convertToRgb(this, src, srcOffset, maxVal, dest, destOffset);
       srcOffset += 3;
       destOffset += 3 + alpha01;
      }
     },
     getOutputLength: function LabCS_getOutputLength(inputLength, alpha01) {
      return inputLength * (3 + alpha01) / 3 | 0;
     },
     isPassthrough: ColorSpace.prototype.isPassthrough,
     fillRgb: ColorSpace.prototype.fillRgb,
     isDefaultDecode: function LabCS_isDefaultDecode(decodeMap) {
      return true;
     },
     usesZeroToOneRange: false
    };
    return LabCS;
   }();
   exports.ColorSpace = ColorSpace;
  }));
  (function (root, factory) {
   factory(root.pdfjsDisplayGlobal = {}, root.pdfjsSharedUtil, root.pdfjsDisplayDOMUtils, root.pdfjsDisplayAPI, root.pdfjsDisplayAnnotationLayer, root.pdfjsDisplayTextLayer, root.pdfjsDisplayMetadata, root.pdfjsDisplaySVG);
  }(this, function (exports, sharedUtil, displayDOMUtils, displayAPI, displayAnnotationLayer, displayTextLayer, displayMetadata, displaySVG) {
   var globalScope = sharedUtil.globalScope;
   var deprecated = sharedUtil.deprecated;
   var warn = sharedUtil.warn;
   var LinkTarget = displayDOMUtils.LinkTarget;
   var isWorker = typeof window === 'undefined';
   if (!globalScope.PDFJS) {
    globalScope.PDFJS = {};
   }
   var PDFJS = globalScope.PDFJS;
   if (typeof pdfjsVersion !== 'undefined') {
    PDFJS.version = pdfjsVersion;
   }
   if (typeof pdfjsBuild !== 'undefined') {
    PDFJS.build = pdfjsBuild;
   }
   PDFJS.pdfBug = false;
   if (PDFJS.verbosity !== undefined) {
    sharedUtil.setVerbosityLevel(PDFJS.verbosity);
   }
   delete PDFJS.verbosity;
   Object.defineProperty(PDFJS, 'verbosity', {
    get: function () {
     return sharedUtil.getVerbosityLevel();
    },
    set: function (level) {
     sharedUtil.setVerbosityLevel(level);
    },
    enumerable: true,
    configurable: true
   });
   PDFJS.VERBOSITY_LEVELS = sharedUtil.VERBOSITY_LEVELS;
   PDFJS.OPS = sharedUtil.OPS;
   PDFJS.UNSUPPORTED_FEATURES = sharedUtil.UNSUPPORTED_FEATURES;
   PDFJS.isValidUrl = displayDOMUtils.isValidUrl;
   PDFJS.shadow = sharedUtil.shadow;
   PDFJS.createBlob = sharedUtil.createBlob;
   PDFJS.createObjectURL = function PDFJS_createObjectURL(data, contentType) {
    return sharedUtil.createObjectURL(data, contentType, PDFJS.disableCreateObjectURL);
   };
   Object.defineProperty(PDFJS, 'isLittleEndian', {
    configurable: true,
    get: function PDFJS_isLittleEndian() {
     var value = sharedUtil.isLittleEndian();
     return sharedUtil.shadow(PDFJS, 'isLittleEndian', value);
    }
   });
   PDFJS.removeNullCharacters = sharedUtil.removeNullCharacters;
   PDFJS.PasswordResponses = sharedUtil.PasswordResponses;
   PDFJS.PasswordException = sharedUtil.PasswordException;
   PDFJS.UnknownErrorException = sharedUtil.UnknownErrorException;
   PDFJS.InvalidPDFException = sharedUtil.InvalidPDFException;
   PDFJS.MissingPDFException = sharedUtil.MissingPDFException;
   PDFJS.UnexpectedResponseException = sharedUtil.UnexpectedResponseException;
   PDFJS.Util = sharedUtil.Util;
   PDFJS.PageViewport = sharedUtil.PageViewport;
   PDFJS.createPromiseCapability = sharedUtil.createPromiseCapability;
   PDFJS.maxImageSize = PDFJS.maxImageSize === undefined ? -1 : PDFJS.maxImageSize;
   PDFJS.cMapUrl = PDFJS.cMapUrl === undefined ? null : PDFJS.cMapUrl;
   PDFJS.cMapPacked = PDFJS.cMapPacked === undefined ? false : PDFJS.cMapPacked;
   PDFJS.disableFontFace = PDFJS.disableFontFace === undefined ? false : PDFJS.disableFontFace;
   PDFJS.imageResourcesPath = PDFJS.imageResourcesPath === undefined ? '' : PDFJS.imageResourcesPath;
   PDFJS.disableWorker = PDFJS.disableWorker === undefined ? false : PDFJS.disableWorker;
   PDFJS.workerSrc = PDFJS.workerSrc === undefined ? null : PDFJS.workerSrc;
   PDFJS.disableRange = PDFJS.disableRange === undefined ? false : PDFJS.disableRange;
   PDFJS.disableStream = PDFJS.disableStream === undefined ? false : PDFJS.disableStream;
   PDFJS.disableAutoFetch = PDFJS.disableAutoFetch === undefined ? false : PDFJS.disableAutoFetch;
   PDFJS.pdfBug = PDFJS.pdfBug === undefined ? false : PDFJS.pdfBug;
   PDFJS.postMessageTransfers = PDFJS.postMessageTransfers === undefined ? true : PDFJS.postMessageTransfers;
   PDFJS.disableCreateObjectURL = PDFJS.disableCreateObjectURL === undefined ? false : PDFJS.disableCreateObjectURL;
   PDFJS.disableWebGL = PDFJS.disableWebGL === undefined ? true : PDFJS.disableWebGL;
   PDFJS.externalLinkTarget = PDFJS.externalLinkTarget === undefined ? LinkTarget.NONE : PDFJS.externalLinkTarget;
   PDFJS.externalLinkRel = PDFJS.externalLinkRel === undefined ? 'noreferrer' : PDFJS.externalLinkRel;
   PDFJS.isEvalSupported = PDFJS.isEvalSupported === undefined ? true : PDFJS.isEvalSupported;
   var savedOpenExternalLinksInNewWindow = PDFJS.openExternalLinksInNewWindow;
   delete PDFJS.openExternalLinksInNewWindow;
   Object.defineProperty(PDFJS, 'openExternalLinksInNewWindow', {
    get: function () {
     return PDFJS.externalLinkTarget === LinkTarget.BLANK;
    },
    set: function (value) {
     if (value) {
      deprecated('PDFJS.openExternalLinksInNewWindow, please use ' + '"PDFJS.externalLinkTarget = PDFJS.LinkTarget.BLANK" instead.');
     }
     if (PDFJS.externalLinkTarget !== LinkTarget.NONE) {
      warn('PDFJS.externalLinkTarget is already initialized');
      return;
     }
     PDFJS.externalLinkTarget = value ? LinkTarget.BLANK : LinkTarget.NONE;
    },
    enumerable: true,
    configurable: true
   });
   if (savedOpenExternalLinksInNewWindow) {
    PDFJS.openExternalLinksInNewWindow = savedOpenExternalLinksInNewWindow;
   }
   PDFJS.getDocument = displayAPI.getDocument;
   PDFJS.PDFDataRangeTransport = displayAPI.PDFDataRangeTransport;
   PDFJS.PDFWorker = displayAPI.PDFWorker;
   Object.defineProperty(PDFJS, 'hasCanvasTypedArrays', {
    configurable: true,
    get: function PDFJS_hasCanvasTypedArrays() {
     var value = displayDOMUtils.hasCanvasTypedArrays();
     return sharedUtil.shadow(PDFJS, 'hasCanvasTypedArrays', value);
    }
   });
   PDFJS.CustomStyle = displayDOMUtils.CustomStyle;
   PDFJS.LinkTarget = LinkTarget;
   PDFJS.addLinkAttributes = displayDOMUtils.addLinkAttributes;
   PDFJS.getFilenameFromUrl = displayDOMUtils.getFilenameFromUrl;
   PDFJS.isExternalLinkTargetSet = displayDOMUtils.isExternalLinkTargetSet;
   PDFJS.AnnotationLayer = displayAnnotationLayer.AnnotationLayer;
   PDFJS.renderTextLayer = displayTextLayer.renderTextLayer;
   PDFJS.Metadata = displayMetadata.Metadata;
   PDFJS.SVGGraphics = displaySVG.SVGGraphics;
   PDFJS.UnsupportedManager = displayAPI._UnsupportedManager;
   exports.globalScope = globalScope;
   exports.isWorker = isWorker;
   exports.PDFJS = globalScope.PDFJS;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreImage = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreColorSpace, root.pdfjsCoreStream, root.pdfjsCoreJpx);
  }(this, function (exports, sharedUtil, corePrimitives, coreColorSpace, coreStream, coreJpx) {
   var ImageKind = sharedUtil.ImageKind;
   var assert = sharedUtil.assert;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var warn = sharedUtil.warn;
   var Name = corePrimitives.Name;
   var isStream = corePrimitives.isStream;
   var ColorSpace = coreColorSpace.ColorSpace;
   var DecodeStream = coreStream.DecodeStream;
   var JpegStream = coreStream.JpegStream;
   var JpxImage = coreJpx.JpxImage;
   var PDFImage = function PDFImageClosure() {
    function handleImageData(image, nativeDecoder) {
     if (nativeDecoder && nativeDecoder.canDecode(image)) {
      return nativeDecoder.decode(image);
     } else {
      return Promise.resolve(image);
     }
    }
    function decodeAndClamp(value, addend, coefficient, max) {
     value = addend + value * coefficient;
     return value < 0 ? 0 : value > max ? max : value;
    }
    function resizeImageMask(src, bpc, w1, h1, w2, h2) {
     var length = w2 * h2;
     var dest = bpc <= 8 ? new Uint8Array(length) : bpc <= 16 ? new Uint16Array(length) : new Uint32Array(length);
     var xRatio = w1 / w2;
     var yRatio = h1 / h2;
     var i, j, py, newIndex = 0, oldIndex;
     var xScaled = new Uint16Array(w2);
     var w1Scanline = w1;
     for (i = 0; i < w2; i++) {
      xScaled[i] = Math.floor(i * xRatio);
     }
     for (i = 0; i < h2; i++) {
      py = Math.floor(i * yRatio) * w1Scanline;
      for (j = 0; j < w2; j++) {
       oldIndex = py + xScaled[j];
       dest[newIndex++] = src[oldIndex];
      }
     }
     return dest;
    }
    function PDFImage(xref, res, image, inline, smask, mask, isMask) {
     this.image = image;
     var dict = image.dict;
     if (dict.has('Filter')) {
      var filter = dict.get('Filter').name;
      if (filter === 'JPXDecode') {
       var jpxImage = new JpxImage();
       jpxImage.parseImageProperties(image.stream);
       image.stream.reset();
       image.bitsPerComponent = jpxImage.bitsPerComponent;
       image.numComps = jpxImage.componentsCount;
      } else if (filter === 'JBIG2Decode') {
       image.bitsPerComponent = 1;
       image.numComps = 1;
      }
     }
     this.width = dict.get('Width', 'W');
     this.height = dict.get('Height', 'H');
     if (this.width < 1 || this.height < 1) {
      error('Invalid image width: ' + this.width + ' or height: ' + this.height);
     }
     this.interpolate = dict.get('Interpolate', 'I') || false;
     this.imageMask = dict.get('ImageMask', 'IM') || false;
     this.matte = dict.get('Matte') || false;
     var bitsPerComponent = image.bitsPerComponent;
     if (!bitsPerComponent) {
      bitsPerComponent = dict.get('BitsPerComponent', 'BPC');
      if (!bitsPerComponent) {
       if (this.imageMask) {
        bitsPerComponent = 1;
       } else {
        error('Bits per component missing in image: ' + this.imageMask);
       }
      }
     }
     this.bpc = bitsPerComponent;
     if (!this.imageMask) {
      var colorSpace = dict.get('ColorSpace', 'CS');
      if (!colorSpace) {
       info('JPX images (which do not require color spaces)');
       switch (image.numComps) {
       case 1:
        colorSpace = Name.get('DeviceGray');
        break;
       case 3:
        colorSpace = Name.get('DeviceRGB');
        break;
       case 4:
        colorSpace = Name.get('DeviceCMYK');
        break;
       default:
        error('JPX images with ' + this.numComps + ' color components not supported.');
       }
      }
      this.colorSpace = ColorSpace.parse(colorSpace, xref, res);
      this.numComps = this.colorSpace.numComps;
     }
     this.decode = dict.getArray('Decode', 'D');
     this.needsDecode = false;
     if (this.decode && (this.colorSpace && !this.colorSpace.isDefaultDecode(this.decode) || isMask && !ColorSpace.isDefaultDecode(this.decode, 1))) {
      this.needsDecode = true;
      var max = (1 << bitsPerComponent) - 1;
      this.decodeCoefficients = [];
      this.decodeAddends = [];
      for (var i = 0, j = 0; i < this.decode.length; i += 2, ++j) {
       var dmin = this.decode[i];
       var dmax = this.decode[i + 1];
       this.decodeCoefficients[j] = dmax - dmin;
       this.decodeAddends[j] = max * dmin;
      }
     }
     if (smask) {
      this.smask = new PDFImage(xref, res, smask, false);
     } else if (mask) {
      if (isStream(mask)) {
       var maskDict = mask.dict, imageMask = maskDict.get('ImageMask', 'IM');
       if (!imageMask) {
        warn('Ignoring /Mask in image without /ImageMask.');
       } else {
        this.mask = new PDFImage(xref, res, mask, false, null, null, true);
       }
      } else {
       this.mask = mask;
      }
     }
    }
    PDFImage.buildImage = function PDFImage_buildImage(handler, xref, res, image, inline, nativeDecoder) {
     var imagePromise = handleImageData(image, nativeDecoder);
     var smaskPromise;
     var maskPromise;
     var smask = image.dict.get('SMask');
     var mask = image.dict.get('Mask');
     if (smask) {
      smaskPromise = handleImageData(smask, nativeDecoder);
      maskPromise = Promise.resolve(null);
     } else {
      smaskPromise = Promise.resolve(null);
      if (mask) {
       if (isStream(mask)) {
        maskPromise = handleImageData(mask, nativeDecoder);
       } else if (isArray(mask)) {
        maskPromise = Promise.resolve(mask);
       } else {
        warn('Unsupported mask format.');
        maskPromise = Promise.resolve(null);
       }
      } else {
       maskPromise = Promise.resolve(null);
      }
     }
     return Promise.all([
      imagePromise,
      smaskPromise,
      maskPromise
     ]).then(function (results) {
      var imageData = results[0];
      var smaskData = results[1];
      var maskData = results[2];
      return new PDFImage(xref, res, imageData, inline, smaskData, maskData);
     });
    };
    PDFImage.createMask = function PDFImage_createMask(imgArray, width, height, imageIsFromDecodeStream, inverseDecode) {
     var computedLength = (width + 7 >> 3) * height;
     var actualLength = imgArray.byteLength;
     var haveFullData = computedLength === actualLength;
     var data, i;
     if (imageIsFromDecodeStream && (!inverseDecode || haveFullData)) {
      data = imgArray;
     } else if (!inverseDecode) {
      data = new Uint8Array(actualLength);
      data.set(imgArray);
     } else {
      data = new Uint8Array(computedLength);
      data.set(imgArray);
      for (i = actualLength; i < computedLength; i++) {
       data[i] = 0xff;
      }
     }
     if (inverseDecode) {
      for (i = 0; i < actualLength; i++) {
       data[i] = ~data[i];
      }
     }
     return {
      data: data,
      width: width,
      height: height
     };
    };
    PDFImage.prototype = {
     get drawWidth() {
      return Math.max(this.width, this.smask && this.smask.width || 0, this.mask && this.mask.width || 0);
     },
     get drawHeight() {
      return Math.max(this.height, this.smask && this.smask.height || 0, this.mask && this.mask.height || 0);
     },
     decodeBuffer: function PDFImage_decodeBuffer(buffer) {
      var bpc = this.bpc;
      var numComps = this.numComps;
      var decodeAddends = this.decodeAddends;
      var decodeCoefficients = this.decodeCoefficients;
      var max = (1 << bpc) - 1;
      var i, ii;
      if (bpc === 1) {
       for (i = 0, ii = buffer.length; i < ii; i++) {
        buffer[i] = +!buffer[i];
       }
       return;
      }
      var index = 0;
      for (i = 0, ii = this.width * this.height; i < ii; i++) {
       for (var j = 0; j < numComps; j++) {
        buffer[index] = decodeAndClamp(buffer[index], decodeAddends[j], decodeCoefficients[j], max);
        index++;
       }
      }
     },
     getComponents: function PDFImage_getComponents(buffer) {
      var bpc = this.bpc;
      if (bpc === 8) {
       return buffer;
      }
      var width = this.width;
      var height = this.height;
      var numComps = this.numComps;
      var length = width * height * numComps;
      var bufferPos = 0;
      var output = bpc <= 8 ? new Uint8Array(length) : bpc <= 16 ? new Uint16Array(length) : new Uint32Array(length);
      var rowComps = width * numComps;
      var max = (1 << bpc) - 1;
      var i = 0, ii, buf;
      if (bpc === 1) {
       var mask, loop1End, loop2End;
       for (var j = 0; j < height; j++) {
        loop1End = i + (rowComps & ~7);
        loop2End = i + rowComps;
        while (i < loop1End) {
         buf = buffer[bufferPos++];
         output[i] = buf >> 7 & 1;
         output[i + 1] = buf >> 6 & 1;
         output[i + 2] = buf >> 5 & 1;
         output[i + 3] = buf >> 4 & 1;
         output[i + 4] = buf >> 3 & 1;
         output[i + 5] = buf >> 2 & 1;
         output[i + 6] = buf >> 1 & 1;
         output[i + 7] = buf & 1;
         i += 8;
        }
        if (i < loop2End) {
         buf = buffer[bufferPos++];
         mask = 128;
         while (i < loop2End) {
          output[i++] = +!!(buf & mask);
          mask >>= 1;
         }
        }
       }
      } else {
       var bits = 0;
       buf = 0;
       for (i = 0, ii = length; i < ii; ++i) {
        if (i % rowComps === 0) {
         buf = 0;
         bits = 0;
        }
        while (bits < bpc) {
         buf = buf << 8 | buffer[bufferPos++];
         bits += 8;
        }
        var remainingBits = bits - bpc;
        var value = buf >> remainingBits;
        output[i] = value < 0 ? 0 : value > max ? max : value;
        buf = buf & (1 << remainingBits) - 1;
        bits = remainingBits;
       }
      }
      return output;
     },
     fillOpacity: function PDFImage_fillOpacity(rgbaBuf, width, height, actualHeight, image) {
      var smask = this.smask;
      var mask = this.mask;
      var alphaBuf, sw, sh, i, ii, j;
      if (smask) {
       sw = smask.width;
       sh = smask.height;
       alphaBuf = new Uint8Array(sw * sh);
       smask.fillGrayBuffer(alphaBuf);
       if (sw !== width || sh !== height) {
        alphaBuf = resizeImageMask(alphaBuf, smask.bpc, sw, sh, width, height);
       }
      } else if (mask) {
       if (mask instanceof PDFImage) {
        sw = mask.width;
        sh = mask.height;
        alphaBuf = new Uint8Array(sw * sh);
        mask.numComps = 1;
        mask.fillGrayBuffer(alphaBuf);
        for (i = 0, ii = sw * sh; i < ii; ++i) {
         alphaBuf[i] = 255 - alphaBuf[i];
        }
        if (sw !== width || sh !== height) {
         alphaBuf = resizeImageMask(alphaBuf, mask.bpc, sw, sh, width, height);
        }
       } else if (isArray(mask)) {
        alphaBuf = new Uint8Array(width * height);
        var numComps = this.numComps;
        for (i = 0, ii = width * height; i < ii; ++i) {
         var opacity = 0;
         var imageOffset = i * numComps;
         for (j = 0; j < numComps; ++j) {
          var color = image[imageOffset + j];
          var maskOffset = j * 2;
          if (color < mask[maskOffset] || color > mask[maskOffset + 1]) {
           opacity = 255;
           break;
          }
         }
         alphaBuf[i] = opacity;
        }
       } else {
        error('Unknown mask format.');
       }
      }
      if (alphaBuf) {
       for (i = 0, j = 3, ii = width * actualHeight; i < ii; ++i, j += 4) {
        rgbaBuf[j] = alphaBuf[i];
       }
      } else {
       for (i = 0, j = 3, ii = width * actualHeight; i < ii; ++i, j += 4) {
        rgbaBuf[j] = 255;
       }
      }
     },
     undoPreblend: function PDFImage_undoPreblend(buffer, width, height) {
      var matte = this.smask && this.smask.matte;
      if (!matte) {
       return;
      }
      var matteRgb = this.colorSpace.getRgb(matte, 0);
      var matteR = matteRgb[0];
      var matteG = matteRgb[1];
      var matteB = matteRgb[2];
      var length = width * height * 4;
      var r, g, b;
      for (var i = 0; i < length; i += 4) {
       var alpha = buffer[i + 3];
       if (alpha === 0) {
        buffer[i] = 255;
        buffer[i + 1] = 255;
        buffer[i + 2] = 255;
        continue;
       }
       var k = 255 / alpha;
       r = (buffer[i] - matteR) * k + matteR;
       g = (buffer[i + 1] - matteG) * k + matteG;
       b = (buffer[i + 2] - matteB) * k + matteB;
       buffer[i] = r <= 0 ? 0 : r >= 255 ? 255 : r | 0;
       buffer[i + 1] = g <= 0 ? 0 : g >= 255 ? 255 : g | 0;
       buffer[i + 2] = b <= 0 ? 0 : b >= 255 ? 255 : b | 0;
      }
     },
     createImageData: function PDFImage_createImageData(forceRGBA) {
      var drawWidth = this.drawWidth;
      var drawHeight = this.drawHeight;
      var imgData = {
       width: drawWidth,
       height: drawHeight
      };
      var numComps = this.numComps;
      var originalWidth = this.width;
      var originalHeight = this.height;
      var bpc = this.bpc;
      var rowBytes = originalWidth * numComps * bpc + 7 >> 3;
      var imgArray;
      if (!forceRGBA) {
       var kind;
       if (this.colorSpace.name === 'DeviceGray' && bpc === 1) {
        kind = ImageKind.GRAYSCALE_1BPP;
       } else if (this.colorSpace.name === 'DeviceRGB' && bpc === 8 && !this.needsDecode) {
        kind = ImageKind.RGB_24BPP;
       }
       if (kind && !this.smask && !this.mask && drawWidth === originalWidth && drawHeight === originalHeight) {
        imgData.kind = kind;
        imgArray = this.getImageBytes(originalHeight * rowBytes);
        if (this.image instanceof DecodeStream) {
         imgData.data = imgArray;
        } else {
         var newArray = new Uint8Array(imgArray.length);
         newArray.set(imgArray);
         imgData.data = newArray;
        }
        if (this.needsDecode) {
         assert(kind === ImageKind.GRAYSCALE_1BPP);
         var buffer = imgData.data;
         for (var i = 0, ii = buffer.length; i < ii; i++) {
          buffer[i] ^= 0xff;
         }
        }
        return imgData;
       }
       if (this.image instanceof JpegStream && !this.smask && !this.mask && (this.colorSpace.name === 'DeviceGray' || this.colorSpace.name === 'DeviceRGB' || this.colorSpace.name === 'DeviceCMYK')) {
        imgData.kind = ImageKind.RGB_24BPP;
        imgData.data = this.getImageBytes(originalHeight * rowBytes, drawWidth, drawHeight, true);
        return imgData;
       }
      }
      imgArray = this.getImageBytes(originalHeight * rowBytes);
      var actualHeight = 0 | imgArray.length / rowBytes * drawHeight / originalHeight;
      var comps = this.getComponents(imgArray);
      var alpha01, maybeUndoPreblend;
      if (!forceRGBA && !this.smask && !this.mask) {
       imgData.kind = ImageKind.RGB_24BPP;
       imgData.data = new Uint8Array(drawWidth * drawHeight * 3);
       alpha01 = 0;
       maybeUndoPreblend = false;
      } else {
       imgData.kind = ImageKind.RGBA_32BPP;
       imgData.data = new Uint8Array(drawWidth * drawHeight * 4);
       alpha01 = 1;
       maybeUndoPreblend = true;
       this.fillOpacity(imgData.data, drawWidth, drawHeight, actualHeight, comps);
      }
      if (this.needsDecode) {
       this.decodeBuffer(comps);
      }
      this.colorSpace.fillRgb(imgData.data, originalWidth, originalHeight, drawWidth, drawHeight, actualHeight, bpc, comps, alpha01);
      if (maybeUndoPreblend) {
       this.undoPreblend(imgData.data, drawWidth, actualHeight);
      }
      return imgData;
     },
     fillGrayBuffer: function PDFImage_fillGrayBuffer(buffer) {
      var numComps = this.numComps;
      if (numComps !== 1) {
       error('Reading gray scale from a color image: ' + numComps);
      }
      var width = this.width;
      var height = this.height;
      var bpc = this.bpc;
      var rowBytes = width * numComps * bpc + 7 >> 3;
      var imgArray = this.getImageBytes(height * rowBytes);
      var comps = this.getComponents(imgArray);
      var i, length;
      if (bpc === 1) {
       length = width * height;
       if (this.needsDecode) {
        for (i = 0; i < length; ++i) {
         buffer[i] = comps[i] - 1 & 255;
        }
       } else {
        for (i = 0; i < length; ++i) {
         buffer[i] = -comps[i] & 255;
        }
       }
       return;
      }
      if (this.needsDecode) {
       this.decodeBuffer(comps);
      }
      length = width * height;
      var scale = 255 / ((1 << bpc) - 1);
      for (i = 0; i < length; ++i) {
       buffer[i] = scale * comps[i] | 0;
      }
     },
     getImageBytes: function PDFImage_getImageBytes(length, drawWidth, drawHeight, forceRGB) {
      this.image.reset();
      this.image.drawWidth = drawWidth || this.width;
      this.image.drawHeight = drawHeight || this.height;
      this.image.forceRGB = !!forceRGB;
      return this.image.getBytes(length);
     }
    };
    return PDFImage;
   }();
   exports.PDFImage = PDFImage;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreObj = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreCrypto, root.pdfjsCoreParser, root.pdfjsCoreChunkedStream, root.pdfjsCoreColorSpace);
  }(this, function (exports, sharedUtil, corePrimitives, coreCrypto, coreParser, coreChunkedStream, coreColorSpace) {
   var InvalidPDFException = sharedUtil.InvalidPDFException;
   var MissingDataException = sharedUtil.MissingDataException;
   var XRefParseException = sharedUtil.XRefParseException;
   var assert = sharedUtil.assert;
   var bytesToString = sharedUtil.bytesToString;
   var createPromiseCapability = sharedUtil.createPromiseCapability;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var isBool = sharedUtil.isBool;
   var isInt = sharedUtil.isInt;
   var isString = sharedUtil.isString;
   var shadow = sharedUtil.shadow;
   var stringToPDFString = sharedUtil.stringToPDFString;
   var stringToUTF8String = sharedUtil.stringToUTF8String;
   var warn = sharedUtil.warn;
   var createValidAbsoluteUrl = sharedUtil.createValidAbsoluteUrl;
   var Util = sharedUtil.Util;
   var Ref = corePrimitives.Ref;
   var RefSet = corePrimitives.RefSet;
   var RefSetCache = corePrimitives.RefSetCache;
   var isName = corePrimitives.isName;
   var isCmd = corePrimitives.isCmd;
   var isDict = corePrimitives.isDict;
   var isRef = corePrimitives.isRef;
   var isRefsEqual = corePrimitives.isRefsEqual;
   var isStream = corePrimitives.isStream;
   var CipherTransformFactory = coreCrypto.CipherTransformFactory;
   var Lexer = coreParser.Lexer;
   var Parser = coreParser.Parser;
   var ChunkedStream = coreChunkedStream.ChunkedStream;
   var ColorSpace = coreColorSpace.ColorSpace;
   var Catalog = function CatalogClosure() {
    function Catalog(pdfManager, xref, pageFactory) {
     this.pdfManager = pdfManager;
     this.xref = xref;
     this.catDict = xref.getCatalogObj();
     this.fontCache = new RefSetCache();
     assert(isDict(this.catDict), 'catalog object is not a dictionary');
     this.pageFactory = pageFactory;
     this.pagePromises = [];
    }
    Catalog.prototype = {
     get metadata() {
      var streamRef = this.catDict.getRaw('Metadata');
      if (!isRef(streamRef)) {
       return shadow(this, 'metadata', null);
      }
      var encryptMetadata = !this.xref.encrypt ? false : this.xref.encrypt.encryptMetadata;
      var stream = this.xref.fetch(streamRef, !encryptMetadata);
      var metadata;
      if (stream && isDict(stream.dict)) {
       var type = stream.dict.get('Type');
       var subtype = stream.dict.get('Subtype');
       if (isName(type, 'Metadata') && isName(subtype, 'XML')) {
        try {
         metadata = stringToUTF8String(bytesToString(stream.getBytes()));
        } catch (e) {
         info('Skipping invalid metadata.');
        }
       }
      }
      return shadow(this, 'metadata', metadata);
     },
     get toplevelPagesDict() {
      var pagesObj = this.catDict.get('Pages');
      assert(isDict(pagesObj), 'invalid top-level pages dictionary');
      return shadow(this, 'toplevelPagesDict', pagesObj);
     },
     get documentOutline() {
      var obj = null;
      try {
       obj = this.readDocumentOutline();
      } catch (ex) {
       if (ex instanceof MissingDataException) {
        throw ex;
       }
       warn('Unable to read document outline');
      }
      return shadow(this, 'documentOutline', obj);
     },
     readDocumentOutline: function Catalog_readDocumentOutline() {
      var obj = this.catDict.get('Outlines');
      if (!isDict(obj)) {
       return null;
      }
      obj = obj.getRaw('First');
      if (!isRef(obj)) {
       return null;
      }
      var root = { items: [] };
      var queue = [{
        obj: obj,
        parent: root
       }];
      var processed = new RefSet();
      processed.put(obj);
      var xref = this.xref, blackColor = new Uint8Array(3);
      while (queue.length > 0) {
       var i = queue.shift();
       var outlineDict = xref.fetchIfRef(i.obj);
       if (outlineDict === null) {
        continue;
       }
       assert(outlineDict.has('Title'), 'Invalid outline item');
       var data = {
        url: null,
        dest: null
       };
       Catalog.parseDestDictionary({
        destDict: outlineDict,
        resultObj: data,
        docBaseUrl: this.pdfManager.docBaseUrl
       });
       var title = outlineDict.get('Title');
       var flags = outlineDict.get('F') || 0;
       var color = outlineDict.getArray('C'), rgbColor = blackColor;
       if (isArray(color) && color.length === 3 && (color[0] !== 0 || color[1] !== 0 || color[2] !== 0)) {
        rgbColor = ColorSpace.singletons.rgb.getRgb(color, 0);
       }
       var outlineItem = {
        dest: data.dest,
        url: data.url,
        unsafeUrl: data.unsafeUrl,
        newWindow: data.newWindow,
        title: stringToPDFString(title),
        color: rgbColor,
        count: outlineDict.get('Count'),
        bold: !!(flags & 2),
        italic: !!(flags & 1),
        items: []
       };
       i.parent.items.push(outlineItem);
       obj = outlineDict.getRaw('First');
       if (isRef(obj) && !processed.has(obj)) {
        queue.push({
         obj: obj,
         parent: outlineItem
        });
        processed.put(obj);
       }
       obj = outlineDict.getRaw('Next');
       if (isRef(obj) && !processed.has(obj)) {
        queue.push({
         obj: obj,
         parent: i.parent
        });
        processed.put(obj);
       }
      }
      return root.items.length > 0 ? root.items : null;
     },
     get numPages() {
      var obj = this.toplevelPagesDict.get('Count');
      assert(isInt(obj), 'page count in top level pages object is not an integer');
      return shadow(this, 'num', obj);
     },
     get destinations() {
      function fetchDestination(dest) {
       return isDict(dest) ? dest.get('D') : dest;
      }
      var xref = this.xref;
      var dests = {}, nameTreeRef, nameDictionaryRef;
      var obj = this.catDict.get('Names');
      if (obj && obj.has('Dests')) {
       nameTreeRef = obj.getRaw('Dests');
      } else if (this.catDict.has('Dests')) {
       nameDictionaryRef = this.catDict.get('Dests');
      }
      if (nameDictionaryRef) {
       obj = nameDictionaryRef;
       obj.forEach(function catalogForEach(key, value) {
        if (!value) {
         return;
        }
        dests[key] = fetchDestination(value);
       });
      }
      if (nameTreeRef) {
       var nameTree = new NameTree(nameTreeRef, xref);
       var names = nameTree.getAll();
       for (var name in names) {
        dests[name] = fetchDestination(names[name]);
       }
      }
      return shadow(this, 'destinations', dests);
     },
     getDestination: function Catalog_getDestination(destinationId) {
      function fetchDestination(dest) {
       return isDict(dest) ? dest.get('D') : dest;
      }
      var xref = this.xref;
      var dest = null, nameTreeRef, nameDictionaryRef;
      var obj = this.catDict.get('Names');
      if (obj && obj.has('Dests')) {
       nameTreeRef = obj.getRaw('Dests');
      } else if (this.catDict.has('Dests')) {
       nameDictionaryRef = this.catDict.get('Dests');
      }
      if (nameDictionaryRef) {
       var value = nameDictionaryRef.get(destinationId);
       if (value) {
        dest = fetchDestination(value);
       }
      }
      if (nameTreeRef) {
       var nameTree = new NameTree(nameTreeRef, xref);
       dest = fetchDestination(nameTree.get(destinationId));
      }
      return dest;
     },
     get pageLabels() {
      var obj = null;
      try {
       obj = this.readPageLabels();
      } catch (ex) {
       if (ex instanceof MissingDataException) {
        throw ex;
       }
       warn('Unable to read page labels.');
      }
      return shadow(this, 'pageLabels', obj);
     },
     readPageLabels: function Catalog_readPageLabels() {
      var obj = this.catDict.getRaw('PageLabels');
      if (!obj) {
       return null;
      }
      var pageLabels = new Array(this.numPages);
      var style = null;
      var prefix = '';
      var numberTree = new NumberTree(obj, this.xref);
      var nums = numberTree.getAll();
      var currentLabel = '', currentIndex = 1;
      for (var i = 0, ii = this.numPages; i < ii; i++) {
       if (i in nums) {
        var labelDict = nums[i];
        assert(isDict(labelDict), 'The PageLabel is not a dictionary.');
        var type = labelDict.get('Type');
        assert(!type || isName(type, 'PageLabel'), 'Invalid type in PageLabel dictionary.');
        var s = labelDict.get('S');
        assert(!s || isName(s), 'Invalid style in PageLabel dictionary.');
        style = s ? s.name : null;
        var p = labelDict.get('P');
        assert(!p || isString(p), 'Invalid prefix in PageLabel dictionary.');
        prefix = p ? stringToPDFString(p) : '';
        var st = labelDict.get('St');
        assert(!st || isInt(st) && st >= 1, 'Invalid start in PageLabel dictionary.');
        currentIndex = st || 1;
       }
       switch (style) {
       case 'D':
        currentLabel = currentIndex;
        break;
       case 'R':
       case 'r':
        currentLabel = Util.toRoman(currentIndex, style === 'r');
        break;
       case 'A':
       case 'a':
        var LIMIT = 26;
        var A_UPPER_CASE = 0x41, A_LOWER_CASE = 0x61;
        var baseCharCode = style === 'a' ? A_LOWER_CASE : A_UPPER_CASE;
        var letterIndex = currentIndex - 1;
        var character = String.fromCharCode(baseCharCode + letterIndex % LIMIT);
        var charBuf = [];
        for (var j = 0, jj = letterIndex / LIMIT | 0; j <= jj; j++) {
         charBuf.push(character);
        }
        currentLabel = charBuf.join('');
        break;
       default:
        assert(!style, 'Invalid style "' + style + '" in PageLabel dictionary.');
       }
       pageLabels[i] = prefix + currentLabel;
       currentLabel = '';
       currentIndex++;
      }
      return pageLabels;
     },
     get attachments() {
      var xref = this.xref;
      var attachments = null, nameTreeRef;
      var obj = this.catDict.get('Names');
      if (obj) {
       nameTreeRef = obj.getRaw('EmbeddedFiles');
      }
      if (nameTreeRef) {
       var nameTree = new NameTree(nameTreeRef, xref);
       var names = nameTree.getAll();
       for (var name in names) {
        var fs = new FileSpec(names[name], xref);
        if (!attachments) {
         attachments = Object.create(null);
        }
        attachments[stringToPDFString(name)] = fs.serializable;
       }
      }
      return shadow(this, 'attachments', attachments);
     },
     get javaScript() {
      var xref = this.xref;
      var obj = this.catDict.get('Names');
      var javaScript = [];
      function appendIfJavaScriptDict(jsDict) {
       var type = jsDict.get('S');
       if (!isName(type, 'JavaScript')) {
        return;
       }
       var js = jsDict.get('JS');
       if (isStream(js)) {
        js = bytesToString(js.getBytes());
       } else if (!isString(js)) {
        return;
       }
       javaScript.push(stringToPDFString(js));
      }
      if (obj && obj.has('JavaScript')) {
       var nameTree = new NameTree(obj.getRaw('JavaScript'), xref);
       var names = nameTree.getAll();
       for (var name in names) {
        var jsDict = names[name];
        if (isDict(jsDict)) {
         appendIfJavaScriptDict(jsDict);
        }
       }
      }
      var openactionDict = this.catDict.get('OpenAction');
      if (isDict(openactionDict, 'Action')) {
       var actionType = openactionDict.get('S');
       if (isName(actionType, 'Named')) {
        var action = openactionDict.get('N');
        if (isName(action, 'Print')) {
         javaScript.push('print({});');
        }
       } else {
        appendIfJavaScriptDict(openactionDict);
       }
      }
      return shadow(this, 'javaScript', javaScript);
     },
     cleanup: function Catalog_cleanup() {
      var promises = [];
      this.fontCache.forEach(function (promise) {
       promises.push(promise);
      });
      return Promise.all(promises).then(function (translatedFonts) {
       for (var i = 0, ii = translatedFonts.length; i < ii; i++) {
        var font = translatedFonts[i].dict;
        delete font.translated;
       }
       this.fontCache.clear();
      }.bind(this));
     },
     getPage: function Catalog_getPage(pageIndex) {
      if (!(pageIndex in this.pagePromises)) {
       this.pagePromises[pageIndex] = this.getPageDict(pageIndex).then(function (a) {
        var dict = a[0];
        var ref = a[1];
        return this.pageFactory.createPage(pageIndex, dict, ref, this.fontCache);
       }.bind(this));
      }
      return this.pagePromises[pageIndex];
     },
     getPageDict: function Catalog_getPageDict(pageIndex) {
      var capability = createPromiseCapability();
      var nodesToVisit = [this.catDict.getRaw('Pages')];
      var currentPageIndex = 0;
      var xref = this.xref;
      var checkAllKids = false;
      function next() {
       while (nodesToVisit.length) {
        var currentNode = nodesToVisit.pop();
        if (isRef(currentNode)) {
         xref.fetchAsync(currentNode).then(function (obj) {
          if (isDict(obj, 'Page') || isDict(obj) && !obj.has('Kids')) {
           if (pageIndex === currentPageIndex) {
            capability.resolve([
             obj,
             currentNode
            ]);
           } else {
            currentPageIndex++;
            next();
           }
           return;
          }
          nodesToVisit.push(obj);
          next();
         }, capability.reject);
         return;
        }
        assert(isDict(currentNode), 'page dictionary kid reference points to wrong type of object');
        var count = currentNode.get('Count');
        if (count === 0) {
         checkAllKids = true;
        }
        if (currentPageIndex + count <= pageIndex) {
         currentPageIndex += count;
         continue;
        }
        var kids = currentNode.get('Kids');
        assert(isArray(kids), 'page dictionary kids object is not an array');
        if (!checkAllKids && count === kids.length) {
         nodesToVisit = [kids[pageIndex - currentPageIndex]];
         currentPageIndex = pageIndex;
         continue;
        } else {
         for (var last = kids.length - 1; last >= 0; last--) {
          nodesToVisit.push(kids[last]);
         }
        }
       }
       capability.reject('Page index ' + pageIndex + ' not found.');
      }
      next();
      return capability.promise;
     },
     getPageIndex: function Catalog_getPageIndex(pageRef) {
      var xref = this.xref;
      function pagesBeforeRef(kidRef) {
       var total = 0;
       var parentRef;
       return xref.fetchAsync(kidRef).then(function (node) {
        if (isRefsEqual(kidRef, pageRef) && !isDict(node, 'Page') && !(isDict(node) && !node.has('Type') && node.has('Contents'))) {
         throw new Error('The reference does not point to a /Page Dict.');
        }
        if (!node) {
         return null;
        }
        assert(isDict(node), 'node must be a Dict.');
        parentRef = node.getRaw('Parent');
        return node.getAsync('Parent');
       }).then(function (parent) {
        if (!parent) {
         return null;
        }
        assert(isDict(parent), 'parent must be a Dict.');
        return parent.getAsync('Kids');
       }).then(function (kids) {
        if (!kids) {
         return null;
        }
        var kidPromises = [];
        var found = false;
        for (var i = 0; i < kids.length; i++) {
         var kid = kids[i];
         assert(isRef(kid), 'kid must be a Ref.');
         if (kid.num === kidRef.num) {
          found = true;
          break;
         }
         kidPromises.push(xref.fetchAsync(kid).then(function (kid) {
          if (kid.has('Count')) {
           var count = kid.get('Count');
           total += count;
          } else {
           total++;
          }
         }));
        }
        if (!found) {
         error('kid ref not found in parents kids');
        }
        return Promise.all(kidPromises).then(function () {
         return [
          total,
          parentRef
         ];
        });
       });
      }
      var total = 0;
      function next(ref) {
       return pagesBeforeRef(ref).then(function (args) {
        if (!args) {
         return total;
        }
        var count = args[0];
        var parentRef = args[1];
        total += count;
        return next(parentRef);
       });
      }
      return next(pageRef);
     }
    };
    Catalog.parseDestDictionary = function Catalog_parseDestDictionary(params) {
     function addDefaultProtocolToUrl(url) {
      if (url.indexOf('www.') === 0) {
       return 'http://' + url;
      }
      return url;
     }
     function tryConvertUrlEncoding(url) {
      try {
       return stringToUTF8String(url);
      } catch (e) {
       return url;
      }
     }
     var destDict = params.destDict;
     if (!isDict(destDict)) {
      warn('Catalog_parseDestDictionary: "destDict" must be a dictionary.');
      return;
     }
     var resultObj = params.resultObj;
     if (typeof resultObj !== 'object') {
      warn('Catalog_parseDestDictionary: "resultObj" must be an object.');
      return;
     }
     var docBaseUrl = params.docBaseUrl || null;
     var action = destDict.get('A'), url, dest;
     if (isDict(action)) {
      var linkType = action.get('S').name;
      switch (linkType) {
      case 'URI':
       url = action.get('URI');
       if (isName(url)) {
        url = '/' + url.name;
       } else if (isString(url)) {
        url = addDefaultProtocolToUrl(url);
       }
       break;
      case 'GoTo':
       dest = action.get('D');
       break;
      case 'Launch':
      case 'GoToR':
       var urlDict = action.get('F');
       if (isDict(urlDict)) {
        url = urlDict.get('F') || null;
       } else if (isString(urlDict)) {
        url = urlDict;
       }
       var remoteDest = action.get('D');
       if (remoteDest) {
        if (isName(remoteDest)) {
         remoteDest = remoteDest.name;
        }
        if (isString(url)) {
         var baseUrl = url.split('#')[0];
         if (isString(remoteDest)) {
          url = baseUrl + '#' + (/^\d+$/.test(remoteDest) ? 'nameddest=' : '') + remoteDest;
         } else if (isArray(remoteDest)) {
          url = baseUrl + '#' + JSON.stringify(remoteDest);
         }
        }
       }
       var newWindow = action.get('NewWindow');
       if (isBool(newWindow)) {
        resultObj.newWindow = newWindow;
       }
       break;
      case 'Named':
       var namedAction = action.get('N');
       if (isName(namedAction)) {
        resultObj.action = namedAction.name;
       }
       break;
      case 'JavaScript':
       var jsAction = action.get('JS'), js;
       if (isStream(jsAction)) {
        js = bytesToString(jsAction.getBytes());
       } else if (isString(jsAction)) {
        js = jsAction;
       }
       if (js) {
        var URL_OPEN_METHODS = [
         'app.launchURL',
         'window.open'
        ];
        var regex = new RegExp('^(?:' + URL_OPEN_METHODS.join('|') + ')' + '\\((?:\'|\")(\\S+)(?:\'|\")(?:,|\\))');
        var jsUrl = regex.exec(stringToPDFString(js), 'i');
        if (jsUrl && jsUrl[1]) {
         url = jsUrl[1];
         break;
        }
       }
      default:
       warn('Catalog_parseDestDictionary: Unrecognized link type "' + linkType + '".');
       break;
      }
     } else if (destDict.has('Dest')) {
      dest = destDict.get('Dest');
     }
     if (isString(url)) {
      url = tryConvertUrlEncoding(url);
      var absoluteUrl = createValidAbsoluteUrl(url, docBaseUrl);
      if (absoluteUrl) {
       resultObj.url = absoluteUrl.href;
      }
      resultObj.unsafeUrl = url;
     }
     if (dest) {
      if (isName(dest)) {
       dest = dest.name;
      }
      if (isString(dest) || isArray(dest)) {
       resultObj.dest = dest;
      }
     }
    };
    return Catalog;
   }();
   var XRef = function XRefClosure() {
    function XRef(stream, pdfManager) {
     this.stream = stream;
     this.pdfManager = pdfManager;
     this.entries = [];
     this.xrefstms = Object.create(null);
     this.cache = [];
     this.stats = {
      streamTypes: [],
      fontTypes: []
     };
    }
    XRef.prototype = {
     setStartXRef: function XRef_setStartXRef(startXRef) {
      this.startXRefQueue = [startXRef];
     },
     parse: function XRef_parse(recoveryMode) {
      var trailerDict;
      if (!recoveryMode) {
       trailerDict = this.readXRef();
      } else {
       warn('Indexing all PDF objects');
       trailerDict = this.indexObjects();
      }
      trailerDict.assignXref(this);
      this.trailer = trailerDict;
      var encrypt = trailerDict.get('Encrypt');
      if (isDict(encrypt)) {
       var ids = trailerDict.get('ID');
       var fileId = ids && ids.length ? ids[0] : '';
       encrypt.suppressEncryption = true;
       this.encrypt = new CipherTransformFactory(encrypt, fileId, this.pdfManager.password);
      }
      if (!(this.root = trailerDict.get('Root'))) {
       error('Invalid root reference');
      }
     },
     processXRefTable: function XRef_processXRefTable(parser) {
      if (!('tableState' in this)) {
       this.tableState = {
        entryNum: 0,
        streamPos: parser.lexer.stream.pos,
        parserBuf1: parser.buf1,
        parserBuf2: parser.buf2
       };
      }
      var obj = this.readXRefTable(parser);
      if (!isCmd(obj, 'trailer')) {
       error('Invalid XRef table: could not find trailer dictionary');
      }
      var dict = parser.getObj();
      if (!isDict(dict) && dict.dict) {
       dict = dict.dict;
      }
      if (!isDict(dict)) {
       error('Invalid XRef table: could not parse trailer dictionary');
      }
      delete this.tableState;
      return dict;
     },
     readXRefTable: function XRef_readXRefTable(parser) {
      var stream = parser.lexer.stream;
      var tableState = this.tableState;
      stream.pos = tableState.streamPos;
      parser.buf1 = tableState.parserBuf1;
      parser.buf2 = tableState.parserBuf2;
      var obj;
      while (true) {
       if (!('firstEntryNum' in tableState) || !('entryCount' in tableState)) {
        if (isCmd(obj = parser.getObj(), 'trailer')) {
         break;
        }
        tableState.firstEntryNum = obj;
        tableState.entryCount = parser.getObj();
       }
       var first = tableState.firstEntryNum;
       var count = tableState.entryCount;
       if (!isInt(first) || !isInt(count)) {
        error('Invalid XRef table: wrong types in subsection header');
       }
       for (var i = tableState.entryNum; i < count; i++) {
        tableState.streamPos = stream.pos;
        tableState.entryNum = i;
        tableState.parserBuf1 = parser.buf1;
        tableState.parserBuf2 = parser.buf2;
        var entry = {};
        entry.offset = parser.getObj();
        entry.gen = parser.getObj();
        var type = parser.getObj();
        if (isCmd(type, 'f')) {
         entry.free = true;
        } else if (isCmd(type, 'n')) {
         entry.uncompressed = true;
        }
        if (!isInt(entry.offset) || !isInt(entry.gen) || !(entry.free || entry.uncompressed)) {
         error('Invalid entry in XRef subsection: ' + first + ', ' + count);
        }
        if (i === 0 && entry.free && first === 1) {
         first = 0;
        }
        if (!this.entries[i + first]) {
         this.entries[i + first] = entry;
        }
       }
       tableState.entryNum = 0;
       tableState.streamPos = stream.pos;
       tableState.parserBuf1 = parser.buf1;
       tableState.parserBuf2 = parser.buf2;
       delete tableState.firstEntryNum;
       delete tableState.entryCount;
      }
      if (this.entries[0] && !this.entries[0].free) {
       error('Invalid XRef table: unexpected first object');
      }
      return obj;
     },
     processXRefStream: function XRef_processXRefStream(stream) {
      if (!('streamState' in this)) {
       var streamParameters = stream.dict;
       var byteWidths = streamParameters.get('W');
       var range = streamParameters.get('Index');
       if (!range) {
        range = [
         0,
         streamParameters.get('Size')
        ];
       }
       this.streamState = {
        entryRanges: range,
        byteWidths: byteWidths,
        entryNum: 0,
        streamPos: stream.pos
       };
      }
      this.readXRefStream(stream);
      delete this.streamState;
      return stream.dict;
     },
     readXRefStream: function XRef_readXRefStream(stream) {
      var i, j;
      var streamState = this.streamState;
      stream.pos = streamState.streamPos;
      var byteWidths = streamState.byteWidths;
      var typeFieldWidth = byteWidths[0];
      var offsetFieldWidth = byteWidths[1];
      var generationFieldWidth = byteWidths[2];
      var entryRanges = streamState.entryRanges;
      while (entryRanges.length > 0) {
       var first = entryRanges[0];
       var n = entryRanges[1];
       if (!isInt(first) || !isInt(n)) {
        error('Invalid XRef range fields: ' + first + ', ' + n);
       }
       if (!isInt(typeFieldWidth) || !isInt(offsetFieldWidth) || !isInt(generationFieldWidth)) {
        error('Invalid XRef entry fields length: ' + first + ', ' + n);
       }
       for (i = streamState.entryNum; i < n; ++i) {
        streamState.entryNum = i;
        streamState.streamPos = stream.pos;
        var type = 0, offset = 0, generation = 0;
        for (j = 0; j < typeFieldWidth; ++j) {
         type = type << 8 | stream.getByte();
        }
        if (typeFieldWidth === 0) {
         type = 1;
        }
        for (j = 0; j < offsetFieldWidth; ++j) {
         offset = offset << 8 | stream.getByte();
        }
        for (j = 0; j < generationFieldWidth; ++j) {
         generation = generation << 8 | stream.getByte();
        }
        var entry = {};
        entry.offset = offset;
        entry.gen = generation;
        switch (type) {
        case 0:
         entry.free = true;
         break;
        case 1:
         entry.uncompressed = true;
         break;
        case 2:
         break;
        default:
         error('Invalid XRef entry type: ' + type);
        }
        if (!this.entries[first + i]) {
         this.entries[first + i] = entry;
        }
       }
       streamState.entryNum = 0;
       streamState.streamPos = stream.pos;
       entryRanges.splice(0, 2);
      }
     },
     indexObjects: function XRef_indexObjects() {
      var TAB = 0x9, LF = 0xA, CR = 0xD, SPACE = 0x20;
      var PERCENT = 0x25, LT = 0x3C;
      function readToken(data, offset) {
       var token = '', ch = data[offset];
       while (ch !== LF && ch !== CR && ch !== LT) {
        if (++offset >= data.length) {
         break;
        }
        token += String.fromCharCode(ch);
        ch = data[offset];
       }
       return token;
      }
      function skipUntil(data, offset, what) {
       var length = what.length, dataLength = data.length;
       var skipped = 0;
       while (offset < dataLength) {
        var i = 0;
        while (i < length && data[offset + i] === what[i]) {
         ++i;
        }
        if (i >= length) {
         break;
        }
        offset++;
        skipped++;
       }
       return skipped;
      }
      var objRegExp = /^(\d+)\s+(\d+)\s+obj\b/;
      var trailerBytes = new Uint8Array([
       116,
       114,
       97,
       105,
       108,
       101,
       114
      ]);
      var startxrefBytes = new Uint8Array([
       115,
       116,
       97,
       114,
       116,
       120,
       114,
       101,
       102
      ]);
      var endobjBytes = new Uint8Array([
       101,
       110,
       100,
       111,
       98,
       106
      ]);
      var xrefBytes = new Uint8Array([
       47,
       88,
       82,
       101,
       102
      ]);
      this.entries.length = 0;
      var stream = this.stream;
      stream.pos = 0;
      var buffer = stream.getBytes();
      var position = stream.start, length = buffer.length;
      var trailers = [], xrefStms = [];
      while (position < length) {
       var ch = buffer[position];
       if (ch === TAB || ch === LF || ch === CR || ch === SPACE) {
        ++position;
        continue;
       }
       if (ch === PERCENT) {
        do {
         ++position;
         if (position >= length) {
          break;
         }
         ch = buffer[position];
        } while (ch !== LF && ch !== CR);
        continue;
       }
       var token = readToken(buffer, position);
       var m;
       if (token.indexOf('xref') === 0 && (token.length === 4 || /\s/.test(token[4]))) {
        position += skipUntil(buffer, position, trailerBytes);
        trailers.push(position);
        position += skipUntil(buffer, position, startxrefBytes);
       } else if (m = objRegExp.exec(token)) {
        if (typeof this.entries[m[1]] === 'undefined') {
         this.entries[m[1]] = {
          offset: position - stream.start,
          gen: m[2] | 0,
          uncompressed: true
         };
        }
        var contentLength = skipUntil(buffer, position, endobjBytes) + 7;
        var content = buffer.subarray(position, position + contentLength);
        var xrefTagOffset = skipUntil(content, 0, xrefBytes);
        if (xrefTagOffset < contentLength && content[xrefTagOffset + 5] < 64) {
         xrefStms.push(position - stream.start);
         this.xrefstms[position - stream.start] = 1;
        }
        position += contentLength;
       } else if (token.indexOf('trailer') === 0 && (token.length === 7 || /\s/.test(token[7]))) {
        trailers.push(position);
        position += skipUntil(buffer, position, startxrefBytes);
       } else {
        position += token.length + 1;
       }
      }
      var i, ii;
      for (i = 0, ii = xrefStms.length; i < ii; ++i) {
       this.startXRefQueue.push(xrefStms[i]);
       this.readXRef(true);
      }
      var dict;
      for (i = 0, ii = trailers.length; i < ii; ++i) {
       stream.pos = trailers[i];
       var parser = new Parser(new Lexer(stream), true, this, true);
       var obj = parser.getObj();
       if (!isCmd(obj, 'trailer')) {
        continue;
       }
       dict = parser.getObj();
       if (!isDict(dict)) {
        continue;
       }
       if (dict.has('ID')) {
        return dict;
       }
      }
      if (dict) {
       return dict;
      }
      throw new InvalidPDFException('Invalid PDF structure');
     },
     readXRef: function XRef_readXRef(recoveryMode) {
      var stream = this.stream;
      try {
       while (this.startXRefQueue.length) {
        var startXRef = this.startXRefQueue[0];
        stream.pos = startXRef + stream.start;
        var parser = new Parser(new Lexer(stream), true, this);
        var obj = parser.getObj();
        var dict;
        if (isCmd(obj, 'xref')) {
         dict = this.processXRefTable(parser);
         if (!this.topDict) {
          this.topDict = dict;
         }
         obj = dict.get('XRefStm');
         if (isInt(obj)) {
          var pos = obj;
          if (!(pos in this.xrefstms)) {
           this.xrefstms[pos] = 1;
           this.startXRefQueue.push(pos);
          }
         }
        } else if (isInt(obj)) {
         if (!isInt(parser.getObj()) || !isCmd(parser.getObj(), 'obj') || !isStream(obj = parser.getObj())) {
          error('Invalid XRef stream');
         }
         dict = this.processXRefStream(obj);
         if (!this.topDict) {
          this.topDict = dict;
         }
         if (!dict) {
          error('Failed to read XRef stream');
         }
        } else {
         error('Invalid XRef stream header');
        }
        obj = dict.get('Prev');
        if (isInt(obj)) {
         this.startXRefQueue.push(obj);
        } else if (isRef(obj)) {
         this.startXRefQueue.push(obj.num);
        }
        this.startXRefQueue.shift();
       }
       return this.topDict;
      } catch (e) {
       if (e instanceof MissingDataException) {
        throw e;
       }
       info('(while reading XRef): ' + e);
      }
      if (recoveryMode) {
       return;
      }
      throw new XRefParseException();
     },
     getEntry: function XRef_getEntry(i) {
      var xrefEntry = this.entries[i];
      if (xrefEntry && !xrefEntry.free && xrefEntry.offset) {
       return xrefEntry;
      }
      return null;
     },
     fetchIfRef: function XRef_fetchIfRef(obj, suppressEncryption) {
      if (!isRef(obj)) {
       return obj;
      }
      return this.fetch(obj, suppressEncryption);
     },
     fetch: function XRef_fetch(ref, suppressEncryption) {
      assert(isRef(ref), 'ref object is not a reference');
      var num = ref.num;
      if (num in this.cache) {
       var cacheEntry = this.cache[num];
       return cacheEntry;
      }
      var xrefEntry = this.getEntry(num);
      if (xrefEntry === null) {
       return this.cache[num] = null;
      }
      if (xrefEntry.uncompressed) {
       xrefEntry = this.fetchUncompressed(ref, xrefEntry, suppressEncryption);
      } else {
       xrefEntry = this.fetchCompressed(xrefEntry, suppressEncryption);
      }
      if (isDict(xrefEntry)) {
       xrefEntry.objId = ref.toString();
      } else if (isStream(xrefEntry)) {
       xrefEntry.dict.objId = ref.toString();
      }
      return xrefEntry;
     },
     fetchUncompressed: function XRef_fetchUncompressed(ref, xrefEntry, suppressEncryption) {
      var gen = ref.gen;
      var num = ref.num;
      if (xrefEntry.gen !== gen) {
       error('inconsistent generation in XRef');
      }
      var stream = this.stream.makeSubStream(xrefEntry.offset + this.stream.start);
      var parser = new Parser(new Lexer(stream), true, this);
      var obj1 = parser.getObj();
      var obj2 = parser.getObj();
      var obj3 = parser.getObj();
      if (!isInt(obj1) || parseInt(obj1, 10) !== num || !isInt(obj2) || parseInt(obj2, 10) !== gen || !isCmd(obj3)) {
       error('bad XRef entry');
      }
      if (!isCmd(obj3, 'obj')) {
       if (obj3.cmd.indexOf('obj') === 0) {
        num = parseInt(obj3.cmd.substring(3), 10);
        if (!isNaN(num)) {
         return num;
        }
       }
       error('bad XRef entry');
      }
      if (this.encrypt && !suppressEncryption) {
       xrefEntry = parser.getObj(this.encrypt.createCipherTransform(num, gen));
      } else {
       xrefEntry = parser.getObj();
      }
      if (!isStream(xrefEntry)) {
       this.cache[num] = xrefEntry;
      }
      return xrefEntry;
     },
     fetchCompressed: function XRef_fetchCompressed(xrefEntry, suppressEncryption) {
      var tableOffset = xrefEntry.offset;
      var stream = this.fetch(new Ref(tableOffset, 0));
      if (!isStream(stream)) {
       error('bad ObjStm stream');
      }
      var first = stream.dict.get('First');
      var n = stream.dict.get('N');
      if (!isInt(first) || !isInt(n)) {
       error('invalid first and n parameters for ObjStm stream');
      }
      var parser = new Parser(new Lexer(stream), false, this);
      parser.allowStreams = true;
      var i, entries = [], num, nums = [];
      for (i = 0; i < n; ++i) {
       num = parser.getObj();
       if (!isInt(num)) {
        error('invalid object number in the ObjStm stream: ' + num);
       }
       nums.push(num);
       var offset = parser.getObj();
       if (!isInt(offset)) {
        error('invalid object offset in the ObjStm stream: ' + offset);
       }
      }
      for (i = 0; i < n; ++i) {
       entries.push(parser.getObj());
       if (isCmd(parser.buf1, 'endobj')) {
        parser.shift();
       }
       num = nums[i];
       var entry = this.entries[num];
       if (entry && entry.offset === tableOffset && entry.gen === i) {
        this.cache[num] = entries[i];
       }
      }
      xrefEntry = entries[xrefEntry.gen];
      if (xrefEntry === undefined) {
       error('bad XRef entry for compressed object');
      }
      return xrefEntry;
     },
     fetchIfRefAsync: function XRef_fetchIfRefAsync(obj, suppressEncryption) {
      if (!isRef(obj)) {
       return Promise.resolve(obj);
      }
      return this.fetchAsync(obj, suppressEncryption);
     },
     fetchAsync: function XRef_fetchAsync(ref, suppressEncryption) {
      var streamManager = this.stream.manager;
      var xref = this;
      return new Promise(function tryFetch(resolve, reject) {
       try {
        resolve(xref.fetch(ref, suppressEncryption));
       } catch (e) {
        if (e instanceof MissingDataException) {
         streamManager.requestRange(e.begin, e.end).then(function () {
          tryFetch(resolve, reject);
         }, reject);
         return;
        }
        reject(e);
       }
      });
     },
     getCatalogObj: function XRef_getCatalogObj() {
      return this.root;
     }
    };
    return XRef;
   }();
   var NameOrNumberTree = function NameOrNumberTreeClosure() {
    function NameOrNumberTree(root, xref) {
     throw new Error('Cannot initialize NameOrNumberTree.');
    }
    NameOrNumberTree.prototype = {
     getAll: function NameOrNumberTree_getAll() {
      var dict = Object.create(null);
      if (!this.root) {
       return dict;
      }
      var xref = this.xref;
      var processed = new RefSet();
      processed.put(this.root);
      var queue = [this.root];
      while (queue.length > 0) {
       var i, n;
       var obj = xref.fetchIfRef(queue.shift());
       if (!isDict(obj)) {
        continue;
       }
       if (obj.has('Kids')) {
        var kids = obj.get('Kids');
        for (i = 0, n = kids.length; i < n; i++) {
         var kid = kids[i];
         assert(!processed.has(kid), 'Duplicate entry in "' + this._type + '" tree.');
         queue.push(kid);
         processed.put(kid);
        }
        continue;
       }
       var entries = obj.get(this._type);
       if (isArray(entries)) {
        for (i = 0, n = entries.length; i < n; i += 2) {
         dict[xref.fetchIfRef(entries[i])] = xref.fetchIfRef(entries[i + 1]);
        }
       }
      }
      return dict;
     },
     get: function NameOrNumberTree_get(key) {
      if (!this.root) {
       return null;
      }
      var xref = this.xref;
      var kidsOrEntries = xref.fetchIfRef(this.root);
      var loopCount = 0;
      var MAX_LEVELS = 10;
      var l, r, m;
      while (kidsOrEntries.has('Kids')) {
       if (++loopCount > MAX_LEVELS) {
        warn('Search depth limit reached for "' + this._type + '" tree.');
        return null;
       }
       var kids = kidsOrEntries.get('Kids');
       if (!isArray(kids)) {
        return null;
       }
       l = 0;
       r = kids.length - 1;
       while (l <= r) {
        m = l + r >> 1;
        var kid = xref.fetchIfRef(kids[m]);
        var limits = kid.get('Limits');
        if (key < xref.fetchIfRef(limits[0])) {
         r = m - 1;
        } else if (key > xref.fetchIfRef(limits[1])) {
         l = m + 1;
        } else {
         kidsOrEntries = xref.fetchIfRef(kids[m]);
         break;
        }
       }
       if (l > r) {
        return null;
       }
      }
      var entries = kidsOrEntries.get(this._type);
      if (isArray(entries)) {
       l = 0;
       r = entries.length - 2;
       while (l <= r) {
        m = l + r & ~1;
        var currentKey = xref.fetchIfRef(entries[m]);
        if (key < currentKey) {
         r = m - 2;
        } else if (key > currentKey) {
         l = m + 2;
        } else {
         return xref.fetchIfRef(entries[m + 1]);
        }
       }
      }
      return null;
     }
    };
    return NameOrNumberTree;
   }();
   var NameTree = function NameTreeClosure() {
    function NameTree(root, xref) {
     this.root = root;
     this.xref = xref;
     this._type = 'Names';
    }
    Util.inherit(NameTree, NameOrNumberTree, {});
    return NameTree;
   }();
   var NumberTree = function NumberTreeClosure() {
    function NumberTree(root, xref) {
     this.root = root;
     this.xref = xref;
     this._type = 'Nums';
    }
    Util.inherit(NumberTree, NameOrNumberTree, {});
    return NumberTree;
   }();
   var FileSpec = function FileSpecClosure() {
    function FileSpec(root, xref) {
     if (!root || !isDict(root)) {
      return;
     }
     this.xref = xref;
     this.root = root;
     if (root.has('FS')) {
      this.fs = root.get('FS');
     }
     this.description = root.has('Desc') ? stringToPDFString(root.get('Desc')) : '';
     if (root.has('RF')) {
      warn('Related file specifications are not supported');
     }
     this.contentAvailable = true;
     if (!root.has('EF')) {
      this.contentAvailable = false;
      warn('Non-embedded file specifications are not supported');
     }
    }
    function pickPlatformItem(dict) {
     if (dict.has('UF')) {
      return dict.get('UF');
     } else if (dict.has('F')) {
      return dict.get('F');
     } else if (dict.has('Unix')) {
      return dict.get('Unix');
     } else if (dict.has('Mac')) {
      return dict.get('Mac');
     } else if (dict.has('DOS')) {
      return dict.get('DOS');
     } else {
      return null;
     }
    }
    FileSpec.prototype = {
     get filename() {
      if (!this._filename && this.root) {
       var filename = pickPlatformItem(this.root) || 'unnamed';
       this._filename = stringToPDFString(filename).replace(/\\\\/g, '\\').replace(/\\\//g, '/').replace(/\\/g, '/');
      }
      return this._filename;
     },
     get content() {
      if (!this.contentAvailable) {
       return null;
      }
      if (!this.contentRef && this.root) {
       this.contentRef = pickPlatformItem(this.root.get('EF'));
      }
      var content = null;
      if (this.contentRef) {
       var xref = this.xref;
       var fileObj = xref.fetchIfRef(this.contentRef);
       if (fileObj && isStream(fileObj)) {
        content = fileObj.getBytes();
       } else {
        warn('Embedded file specification points to non-existing/invalid ' + 'content');
       }
      } else {
       warn('Embedded file specification does not have a content');
      }
      return content;
     },
     get serializable() {
      return {
       filename: this.filename,
       content: this.content
      };
     }
    };
    return FileSpec;
   }();
   var ObjectLoader = function () {
    function mayHaveChildren(value) {
     return isRef(value) || isDict(value) || isArray(value) || isStream(value);
    }
    function addChildren(node, nodesToVisit) {
     var value;
     if (isDict(node) || isStream(node)) {
      var map;
      if (isDict(node)) {
       map = node.map;
      } else {
       map = node.dict.map;
      }
      for (var key in map) {
       value = map[key];
       if (mayHaveChildren(value)) {
        nodesToVisit.push(value);
       }
      }
     } else if (isArray(node)) {
      for (var i = 0, ii = node.length; i < ii; i++) {
       value = node[i];
       if (mayHaveChildren(value)) {
        nodesToVisit.push(value);
       }
      }
     }
    }
    function ObjectLoader(obj, keys, xref) {
     this.obj = obj;
     this.keys = keys;
     this.xref = xref;
     this.refSet = null;
     this.capability = null;
    }
    ObjectLoader.prototype = {
     load: function ObjectLoader_load() {
      var keys = this.keys;
      this.capability = createPromiseCapability();
      if (!(this.xref.stream instanceof ChunkedStream) || this.xref.stream.getMissingChunks().length === 0) {
       this.capability.resolve();
       return this.capability.promise;
      }
      this.refSet = new RefSet();
      var nodesToVisit = [];
      for (var i = 0; i < keys.length; i++) {
       nodesToVisit.push(this.obj[keys[i]]);
      }
      this._walk(nodesToVisit);
      return this.capability.promise;
     },
     _walk: function ObjectLoader_walk(nodesToVisit) {
      var nodesToRevisit = [];
      var pendingRequests = [];
      while (nodesToVisit.length) {
       var currentNode = nodesToVisit.pop();
       if (isRef(currentNode)) {
        if (this.refSet.has(currentNode)) {
         continue;
        }
        try {
         var ref = currentNode;
         this.refSet.put(ref);
         currentNode = this.xref.fetch(currentNode);
        } catch (e) {
         if (!(e instanceof MissingDataException)) {
          throw e;
         }
         nodesToRevisit.push(currentNode);
         pendingRequests.push({
          begin: e.begin,
          end: e.end
         });
        }
       }
       if (currentNode && currentNode.getBaseStreams) {
        var baseStreams = currentNode.getBaseStreams();
        var foundMissingData = false;
        for (var i = 0; i < baseStreams.length; i++) {
         var stream = baseStreams[i];
         if (stream.getMissingChunks && stream.getMissingChunks().length) {
          foundMissingData = true;
          pendingRequests.push({
           begin: stream.start,
           end: stream.end
          });
         }
        }
        if (foundMissingData) {
         nodesToRevisit.push(currentNode);
        }
       }
       addChildren(currentNode, nodesToVisit);
      }
      if (pendingRequests.length) {
       this.xref.stream.manager.requestRanges(pendingRequests).then(function pendingRequestCallback() {
        nodesToVisit = nodesToRevisit;
        for (var i = 0; i < nodesToRevisit.length; i++) {
         var node = nodesToRevisit[i];
         if (isRef(node)) {
          this.refSet.remove(node);
         }
        }
        this._walk(nodesToVisit);
       }.bind(this), this.capability.reject);
       return;
      }
      this.refSet = null;
      this.capability.resolve();
     }
    };
    return ObjectLoader;
   }();
   exports.Catalog = Catalog;
   exports.ObjectLoader = ObjectLoader;
   exports.XRef = XRef;
   exports.FileSpec = FileSpec;
  }));
  (function (root, factory) {
   factory(root.pdfjsCorePattern = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreFunction, root.pdfjsCoreColorSpace);
  }(this, function (exports, sharedUtil, corePrimitives, coreFunction, coreColorSpace) {
   var UNSUPPORTED_FEATURES = sharedUtil.UNSUPPORTED_FEATURES;
   var MissingDataException = sharedUtil.MissingDataException;
   var Util = sharedUtil.Util;
   var assert = sharedUtil.assert;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var warn = sharedUtil.warn;
   var isStream = corePrimitives.isStream;
   var PDFFunction = coreFunction.PDFFunction;
   var ColorSpace = coreColorSpace.ColorSpace;
   var ShadingType = {
    FUNCTION_BASED: 1,
    AXIAL: 2,
    RADIAL: 3,
    FREE_FORM_MESH: 4,
    LATTICE_FORM_MESH: 5,
    COONS_PATCH_MESH: 6,
    TENSOR_PATCH_MESH: 7
   };
   var Pattern = function PatternClosure() {
    function Pattern() {
     error('should not call Pattern constructor');
    }
    Pattern.prototype = {
     getPattern: function Pattern_getPattern(ctx) {
      error('Should not call Pattern.getStyle: ' + ctx);
     }
    };
    Pattern.parseShading = function Pattern_parseShading(shading, matrix, xref, res, handler) {
     var dict = isStream(shading) ? shading.dict : shading;
     var type = dict.get('ShadingType');
     try {
      switch (type) {
      case ShadingType.AXIAL:
      case ShadingType.RADIAL:
       return new Shadings.RadialAxial(dict, matrix, xref, res);
      case ShadingType.FREE_FORM_MESH:
      case ShadingType.LATTICE_FORM_MESH:
      case ShadingType.COONS_PATCH_MESH:
      case ShadingType.TENSOR_PATCH_MESH:
       return new Shadings.Mesh(shading, matrix, xref, res);
      default:
       throw new Error('Unsupported ShadingType: ' + type);
      }
     } catch (ex) {
      if (ex instanceof MissingDataException) {
       throw ex;
      }
      handler.send('UnsupportedFeature', { featureId: UNSUPPORTED_FEATURES.shadingPattern });
      warn(ex);
      return new Shadings.Dummy();
     }
    };
    return Pattern;
   }();
   var Shadings = {};
   Shadings.SMALL_NUMBER = 1e-6;
   Shadings.RadialAxial = function RadialAxialClosure() {
    function RadialAxial(dict, matrix, xref, res) {
     this.matrix = matrix;
     this.coordsArr = dict.getArray('Coords');
     this.shadingType = dict.get('ShadingType');
     this.type = 'Pattern';
     var cs = dict.get('ColorSpace', 'CS');
     cs = ColorSpace.parse(cs, xref, res);
     this.cs = cs;
     var t0 = 0.0, t1 = 1.0;
     if (dict.has('Domain')) {
      var domainArr = dict.getArray('Domain');
      t0 = domainArr[0];
      t1 = domainArr[1];
     }
     var extendStart = false, extendEnd = false;
     if (dict.has('Extend')) {
      var extendArr = dict.getArray('Extend');
      extendStart = extendArr[0];
      extendEnd = extendArr[1];
     }
     if (this.shadingType === ShadingType.RADIAL && (!extendStart || !extendEnd)) {
      var x1 = this.coordsArr[0];
      var y1 = this.coordsArr[1];
      var r1 = this.coordsArr[2];
      var x2 = this.coordsArr[3];
      var y2 = this.coordsArr[4];
      var r2 = this.coordsArr[5];
      var distance = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
      if (r1 <= r2 + distance && r2 <= r1 + distance) {
       warn('Unsupported radial gradient.');
      }
     }
     this.extendStart = extendStart;
     this.extendEnd = extendEnd;
     var fnObj = dict.get('Function');
     var fn = PDFFunction.parseArray(xref, fnObj);
     var diff = t1 - t0;
     var step = diff / 10;
     var colorStops = this.colorStops = [];
     if (t0 >= t1 || step <= 0) {
      info('Bad shading domain.');
      return;
     }
     var color = new Float32Array(cs.numComps), ratio = new Float32Array(1);
     var rgbColor;
     for (var i = t0; i <= t1; i += step) {
      ratio[0] = i;
      fn(ratio, 0, color, 0);
      rgbColor = cs.getRgb(color, 0);
      var cssColor = Util.makeCssRgb(rgbColor[0], rgbColor[1], rgbColor[2]);
      colorStops.push([
       (i - t0) / diff,
       cssColor
      ]);
     }
     var background = 'transparent';
     if (dict.has('Background')) {
      rgbColor = cs.getRgb(dict.get('Background'), 0);
      background = Util.makeCssRgb(rgbColor[0], rgbColor[1], rgbColor[2]);
     }
     if (!extendStart) {
      colorStops.unshift([
       0,
       background
      ]);
      colorStops[1][0] += Shadings.SMALL_NUMBER;
     }
     if (!extendEnd) {
      colorStops[colorStops.length - 1][0] -= Shadings.SMALL_NUMBER;
      colorStops.push([
       1,
       background
      ]);
     }
     this.colorStops = colorStops;
    }
    RadialAxial.prototype = {
     getIR: function RadialAxial_getIR() {
      var coordsArr = this.coordsArr;
      var shadingType = this.shadingType;
      var type, p0, p1, r0, r1;
      if (shadingType === ShadingType.AXIAL) {
       p0 = [
        coordsArr[0],
        coordsArr[1]
       ];
       p1 = [
        coordsArr[2],
        coordsArr[3]
       ];
       r0 = null;
       r1 = null;
       type = 'axial';
      } else if (shadingType === ShadingType.RADIAL) {
       p0 = [
        coordsArr[0],
        coordsArr[1]
       ];
       p1 = [
        coordsArr[3],
        coordsArr[4]
       ];
       r0 = coordsArr[2];
       r1 = coordsArr[5];
       type = 'radial';
      } else {
       error('getPattern type unknown: ' + shadingType);
      }
      var matrix = this.matrix;
      if (matrix) {
       p0 = Util.applyTransform(p0, matrix);
       p1 = Util.applyTransform(p1, matrix);
       if (shadingType === ShadingType.RADIAL) {
        var scale = Util.singularValueDecompose2dScale(matrix);
        r0 *= scale[0];
        r1 *= scale[1];
       }
      }
      return [
       'RadialAxial',
       type,
       this.colorStops,
       p0,
       p1,
       r0,
       r1
      ];
     }
    };
    return RadialAxial;
   }();
   Shadings.Mesh = function MeshClosure() {
    function MeshStreamReader(stream, context) {
     this.stream = stream;
     this.context = context;
     this.buffer = 0;
     this.bufferLength = 0;
     var numComps = context.numComps;
     this.tmpCompsBuf = new Float32Array(numComps);
     var csNumComps = context.colorSpace.numComps;
     this.tmpCsCompsBuf = context.colorFn ? new Float32Array(csNumComps) : this.tmpCompsBuf;
    }
    MeshStreamReader.prototype = {
     get hasData() {
      if (this.stream.end) {
       return this.stream.pos < this.stream.end;
      }
      if (this.bufferLength > 0) {
       return true;
      }
      var nextByte = this.stream.getByte();
      if (nextByte < 0) {
       return false;
      }
      this.buffer = nextByte;
      this.bufferLength = 8;
      return true;
     },
     readBits: function MeshStreamReader_readBits(n) {
      var buffer = this.buffer;
      var bufferLength = this.bufferLength;
      if (n === 32) {
       if (bufferLength === 0) {
        return (this.stream.getByte() << 24 | this.stream.getByte() << 16 | this.stream.getByte() << 8 | this.stream.getByte()) >>> 0;
       }
       buffer = buffer << 24 | this.stream.getByte() << 16 | this.stream.getByte() << 8 | this.stream.getByte();
       var nextByte = this.stream.getByte();
       this.buffer = nextByte & (1 << bufferLength) - 1;
       return (buffer << 8 - bufferLength | (nextByte & 0xFF) >> bufferLength) >>> 0;
      }
      if (n === 8 && bufferLength === 0) {
       return this.stream.getByte();
      }
      while (bufferLength < n) {
       buffer = buffer << 8 | this.stream.getByte();
       bufferLength += 8;
      }
      bufferLength -= n;
      this.bufferLength = bufferLength;
      this.buffer = buffer & (1 << bufferLength) - 1;
      return buffer >> bufferLength;
     },
     align: function MeshStreamReader_align() {
      this.buffer = 0;
      this.bufferLength = 0;
     },
     readFlag: function MeshStreamReader_readFlag() {
      return this.readBits(this.context.bitsPerFlag);
     },
     readCoordinate: function MeshStreamReader_readCoordinate() {
      var bitsPerCoordinate = this.context.bitsPerCoordinate;
      var xi = this.readBits(bitsPerCoordinate);
      var yi = this.readBits(bitsPerCoordinate);
      var decode = this.context.decode;
      var scale = bitsPerCoordinate < 32 ? 1 / ((1 << bitsPerCoordinate) - 1) : 2.3283064365386963e-10;
      return [
       xi * scale * (decode[1] - decode[0]) + decode[0],
       yi * scale * (decode[3] - decode[2]) + decode[2]
      ];
     },
     readComponents: function MeshStreamReader_readComponents() {
      var numComps = this.context.numComps;
      var bitsPerComponent = this.context.bitsPerComponent;
      var scale = bitsPerComponent < 32 ? 1 / ((1 << bitsPerComponent) - 1) : 2.3283064365386963e-10;
      var decode = this.context.decode;
      var components = this.tmpCompsBuf;
      for (var i = 0, j = 4; i < numComps; i++, j += 2) {
       var ci = this.readBits(bitsPerComponent);
       components[i] = ci * scale * (decode[j + 1] - decode[j]) + decode[j];
      }
      var color = this.tmpCsCompsBuf;
      if (this.context.colorFn) {
       this.context.colorFn(components, 0, color, 0);
      }
      return this.context.colorSpace.getRgb(color, 0);
     }
    };
    function decodeType4Shading(mesh, reader) {
     var coords = mesh.coords;
     var colors = mesh.colors;
     var operators = [];
     var ps = [];
     var verticesLeft = 0;
     while (reader.hasData) {
      var f = reader.readFlag();
      var coord = reader.readCoordinate();
      var color = reader.readComponents();
      if (verticesLeft === 0) {
       assert(0 <= f && f <= 2, 'Unknown type4 flag');
       switch (f) {
       case 0:
        verticesLeft = 3;
        break;
       case 1:
        ps.push(ps[ps.length - 2], ps[ps.length - 1]);
        verticesLeft = 1;
        break;
       case 2:
        ps.push(ps[ps.length - 3], ps[ps.length - 1]);
        verticesLeft = 1;
        break;
       }
       operators.push(f);
      }
      ps.push(coords.length);
      coords.push(coord);
      colors.push(color);
      verticesLeft--;
      reader.align();
     }
     mesh.figures.push({
      type: 'triangles',
      coords: new Int32Array(ps),
      colors: new Int32Array(ps)
     });
    }
    function decodeType5Shading(mesh, reader, verticesPerRow) {
     var coords = mesh.coords;
     var colors = mesh.colors;
     var ps = [];
     while (reader.hasData) {
      var coord = reader.readCoordinate();
      var color = reader.readComponents();
      ps.push(coords.length);
      coords.push(coord);
      colors.push(color);
     }
     mesh.figures.push({
      type: 'lattice',
      coords: new Int32Array(ps),
      colors: new Int32Array(ps),
      verticesPerRow: verticesPerRow
     });
    }
    var MIN_SPLIT_PATCH_CHUNKS_AMOUNT = 3;
    var MAX_SPLIT_PATCH_CHUNKS_AMOUNT = 20;
    var TRIANGLE_DENSITY = 20;
    var getB = function getBClosure() {
     function buildB(count) {
      var lut = [];
      for (var i = 0; i <= count; i++) {
       var t = i / count, t_ = 1 - t;
       lut.push(new Float32Array([
        t_ * t_ * t_,
        3 * t * t_ * t_,
        3 * t * t * t_,
        t * t * t
       ]));
      }
      return lut;
     }
     var cache = [];
     return function getB(count) {
      if (!cache[count]) {
       cache[count] = buildB(count);
      }
      return cache[count];
     };
    }();
    function buildFigureFromPatch(mesh, index) {
     var figure = mesh.figures[index];
     assert(figure.type === 'patch', 'Unexpected patch mesh figure');
     var coords = mesh.coords, colors = mesh.colors;
     var pi = figure.coords;
     var ci = figure.colors;
     var figureMinX = Math.min(coords[pi[0]][0], coords[pi[3]][0], coords[pi[12]][0], coords[pi[15]][0]);
     var figureMinY = Math.min(coords[pi[0]][1], coords[pi[3]][1], coords[pi[12]][1], coords[pi[15]][1]);
     var figureMaxX = Math.max(coords[pi[0]][0], coords[pi[3]][0], coords[pi[12]][0], coords[pi[15]][0]);
     var figureMaxY = Math.max(coords[pi[0]][1], coords[pi[3]][1], coords[pi[12]][1], coords[pi[15]][1]);
     var splitXBy = Math.ceil((figureMaxX - figureMinX) * TRIANGLE_DENSITY / (mesh.bounds[2] - mesh.bounds[0]));
     splitXBy = Math.max(MIN_SPLIT_PATCH_CHUNKS_AMOUNT, Math.min(MAX_SPLIT_PATCH_CHUNKS_AMOUNT, splitXBy));
     var splitYBy = Math.ceil((figureMaxY - figureMinY) * TRIANGLE_DENSITY / (mesh.bounds[3] - mesh.bounds[1]));
     splitYBy = Math.max(MIN_SPLIT_PATCH_CHUNKS_AMOUNT, Math.min(MAX_SPLIT_PATCH_CHUNKS_AMOUNT, splitYBy));
     var verticesPerRow = splitXBy + 1;
     var figureCoords = new Int32Array((splitYBy + 1) * verticesPerRow);
     var figureColors = new Int32Array((splitYBy + 1) * verticesPerRow);
     var k = 0;
     var cl = new Uint8Array(3), cr = new Uint8Array(3);
     var c0 = colors[ci[0]], c1 = colors[ci[1]], c2 = colors[ci[2]], c3 = colors[ci[3]];
     var bRow = getB(splitYBy), bCol = getB(splitXBy);
     for (var row = 0; row <= splitYBy; row++) {
      cl[0] = (c0[0] * (splitYBy - row) + c2[0] * row) / splitYBy | 0;
      cl[1] = (c0[1] * (splitYBy - row) + c2[1] * row) / splitYBy | 0;
      cl[2] = (c0[2] * (splitYBy - row) + c2[2] * row) / splitYBy | 0;
      cr[0] = (c1[0] * (splitYBy - row) + c3[0] * row) / splitYBy | 0;
      cr[1] = (c1[1] * (splitYBy - row) + c3[1] * row) / splitYBy | 0;
      cr[2] = (c1[2] * (splitYBy - row) + c3[2] * row) / splitYBy | 0;
      for (var col = 0; col <= splitXBy; col++, k++) {
       if ((row === 0 || row === splitYBy) && (col === 0 || col === splitXBy)) {
        continue;
       }
       var x = 0, y = 0;
       var q = 0;
       for (var i = 0; i <= 3; i++) {
        for (var j = 0; j <= 3; j++, q++) {
         var m = bRow[row][i] * bCol[col][j];
         x += coords[pi[q]][0] * m;
         y += coords[pi[q]][1] * m;
        }
       }
       figureCoords[k] = coords.length;
       coords.push([
        x,
        y
       ]);
       figureColors[k] = colors.length;
       var newColor = new Uint8Array(3);
       newColor[0] = (cl[0] * (splitXBy - col) + cr[0] * col) / splitXBy | 0;
       newColor[1] = (cl[1] * (splitXBy - col) + cr[1] * col) / splitXBy | 0;
       newColor[2] = (cl[2] * (splitXBy - col) + cr[2] * col) / splitXBy | 0;
       colors.push(newColor);
      }
     }
     figureCoords[0] = pi[0];
     figureColors[0] = ci[0];
     figureCoords[splitXBy] = pi[3];
     figureColors[splitXBy] = ci[1];
     figureCoords[verticesPerRow * splitYBy] = pi[12];
     figureColors[verticesPerRow * splitYBy] = ci[2];
     figureCoords[verticesPerRow * splitYBy + splitXBy] = pi[15];
     figureColors[verticesPerRow * splitYBy + splitXBy] = ci[3];
     mesh.figures[index] = {
      type: 'lattice',
      coords: figureCoords,
      colors: figureColors,
      verticesPerRow: verticesPerRow
     };
    }
    function decodeType6Shading(mesh, reader) {
     var coords = mesh.coords;
     var colors = mesh.colors;
     var ps = new Int32Array(16);
     var cs = new Int32Array(4);
     while (reader.hasData) {
      var f = reader.readFlag();
      assert(0 <= f && f <= 3, 'Unknown type6 flag');
      var i, ii;
      var pi = coords.length;
      for (i = 0, ii = f !== 0 ? 8 : 12; i < ii; i++) {
       coords.push(reader.readCoordinate());
      }
      var ci = colors.length;
      for (i = 0, ii = f !== 0 ? 2 : 4; i < ii; i++) {
       colors.push(reader.readComponents());
      }
      var tmp1, tmp2, tmp3, tmp4;
      switch (f) {
      case 0:
       ps[12] = pi + 3;
       ps[13] = pi + 4;
       ps[14] = pi + 5;
       ps[15] = pi + 6;
       ps[8] = pi + 2;
       ps[11] = pi + 7;
       ps[4] = pi + 1;
       ps[7] = pi + 8;
       ps[0] = pi;
       ps[1] = pi + 11;
       ps[2] = pi + 10;
       ps[3] = pi + 9;
       cs[2] = ci + 1;
       cs[3] = ci + 2;
       cs[0] = ci;
       cs[1] = ci + 3;
       break;
      case 1:
       tmp1 = ps[12];
       tmp2 = ps[13];
       tmp3 = ps[14];
       tmp4 = ps[15];
       ps[12] = tmp4;
       ps[13] = pi + 0;
       ps[14] = pi + 1;
       ps[15] = pi + 2;
       ps[8] = tmp3;
       ps[11] = pi + 3;
       ps[4] = tmp2;
       ps[7] = pi + 4;
       ps[0] = tmp1;
       ps[1] = pi + 7;
       ps[2] = pi + 6;
       ps[3] = pi + 5;
       tmp1 = cs[2];
       tmp2 = cs[3];
       cs[2] = tmp2;
       cs[3] = ci;
       cs[0] = tmp1;
       cs[1] = ci + 1;
       break;
      case 2:
       tmp1 = ps[15];
       tmp2 = ps[11];
       ps[12] = ps[3];
       ps[13] = pi + 0;
       ps[14] = pi + 1;
       ps[15] = pi + 2;
       ps[8] = ps[7];
       ps[11] = pi + 3;
       ps[4] = tmp2;
       ps[7] = pi + 4;
       ps[0] = tmp1;
       ps[1] = pi + 7;
       ps[2] = pi + 6;
       ps[3] = pi + 5;
       tmp1 = cs[3];
       cs[2] = cs[1];
       cs[3] = ci;
       cs[0] = tmp1;
       cs[1] = ci + 1;
       break;
      case 3:
       ps[12] = ps[0];
       ps[13] = pi + 0;
       ps[14] = pi + 1;
       ps[15] = pi + 2;
       ps[8] = ps[1];
       ps[11] = pi + 3;
       ps[4] = ps[2];
       ps[7] = pi + 4;
       ps[0] = ps[3];
       ps[1] = pi + 7;
       ps[2] = pi + 6;
       ps[3] = pi + 5;
       cs[2] = cs[0];
       cs[3] = ci;
       cs[0] = cs[1];
       cs[1] = ci + 1;
       break;
      }
      ps[5] = coords.length;
      coords.push([
       (-4 * coords[ps[0]][0] - coords[ps[15]][0] + 6 * (coords[ps[4]][0] + coords[ps[1]][0]) - 2 * (coords[ps[12]][0] + coords[ps[3]][0]) + 3 * (coords[ps[13]][0] + coords[ps[7]][0])) / 9,
       (-4 * coords[ps[0]][1] - coords[ps[15]][1] + 6 * (coords[ps[4]][1] + coords[ps[1]][1]) - 2 * (coords[ps[12]][1] + coords[ps[3]][1]) + 3 * (coords[ps[13]][1] + coords[ps[7]][1])) / 9
      ]);
      ps[6] = coords.length;
      coords.push([
       (-4 * coords[ps[3]][0] - coords[ps[12]][0] + 6 * (coords[ps[2]][0] + coords[ps[7]][0]) - 2 * (coords[ps[0]][0] + coords[ps[15]][0]) + 3 * (coords[ps[4]][0] + coords[ps[14]][0])) / 9,
       (-4 * coords[ps[3]][1] - coords[ps[12]][1] + 6 * (coords[ps[2]][1] + coords[ps[7]][1]) - 2 * (coords[ps[0]][1] + coords[ps[15]][1]) + 3 * (coords[ps[4]][1] + coords[ps[14]][1])) / 9
      ]);
      ps[9] = coords.length;
      coords.push([
       (-4 * coords[ps[12]][0] - coords[ps[3]][0] + 6 * (coords[ps[8]][0] + coords[ps[13]][0]) - 2 * (coords[ps[0]][0] + coords[ps[15]][0]) + 3 * (coords[ps[11]][0] + coords[ps[1]][0])) / 9,
       (-4 * coords[ps[12]][1] - coords[ps[3]][1] + 6 * (coords[ps[8]][1] + coords[ps[13]][1]) - 2 * (coords[ps[0]][1] + coords[ps[15]][1]) + 3 * (coords[ps[11]][1] + coords[ps[1]][1])) / 9
      ]);
      ps[10] = coords.length;
      coords.push([
       (-4 * coords[ps[15]][0] - coords[ps[0]][0] + 6 * (coords[ps[11]][0] + coords[ps[14]][0]) - 2 * (coords[ps[12]][0] + coords[ps[3]][0]) + 3 * (coords[ps[2]][0] + coords[ps[8]][0])) / 9,
       (-4 * coords[ps[15]][1] - coords[ps[0]][1] + 6 * (coords[ps[11]][1] + coords[ps[14]][1]) - 2 * (coords[ps[12]][1] + coords[ps[3]][1]) + 3 * (coords[ps[2]][1] + coords[ps[8]][1])) / 9
      ]);
      mesh.figures.push({
       type: 'patch',
       coords: new Int32Array(ps),
       colors: new Int32Array(cs)
      });
     }
    }
    function decodeType7Shading(mesh, reader) {
     var coords = mesh.coords;
     var colors = mesh.colors;
     var ps = new Int32Array(16);
     var cs = new Int32Array(4);
     while (reader.hasData) {
      var f = reader.readFlag();
      assert(0 <= f && f <= 3, 'Unknown type7 flag');
      var i, ii;
      var pi = coords.length;
      for (i = 0, ii = f !== 0 ? 12 : 16; i < ii; i++) {
       coords.push(reader.readCoordinate());
      }
      var ci = colors.length;
      for (i = 0, ii = f !== 0 ? 2 : 4; i < ii; i++) {
       colors.push(reader.readComponents());
      }
      var tmp1, tmp2, tmp3, tmp4;
      switch (f) {
      case 0:
       ps[12] = pi + 3;
       ps[13] = pi + 4;
       ps[14] = pi + 5;
       ps[15] = pi + 6;
       ps[8] = pi + 2;
       ps[9] = pi + 13;
       ps[10] = pi + 14;
       ps[11] = pi + 7;
       ps[4] = pi + 1;
       ps[5] = pi + 12;
       ps[6] = pi + 15;
       ps[7] = pi + 8;
       ps[0] = pi;
       ps[1] = pi + 11;
       ps[2] = pi + 10;
       ps[3] = pi + 9;
       cs[2] = ci + 1;
       cs[3] = ci + 2;
       cs[0] = ci;
       cs[1] = ci + 3;
       break;
      case 1:
       tmp1 = ps[12];
       tmp2 = ps[13];
       tmp3 = ps[14];
       tmp4 = ps[15];
       ps[12] = tmp4;
       ps[13] = pi + 0;
       ps[14] = pi + 1;
       ps[15] = pi + 2;
       ps[8] = tmp3;
       ps[9] = pi + 9;
       ps[10] = pi + 10;
       ps[11] = pi + 3;
       ps[4] = tmp2;
       ps[5] = pi + 8;
       ps[6] = pi + 11;
       ps[7] = pi + 4;
       ps[0] = tmp1;
       ps[1] = pi + 7;
       ps[2] = pi + 6;
       ps[3] = pi + 5;
       tmp1 = cs[2];
       tmp2 = cs[3];
       cs[2] = tmp2;
       cs[3] = ci;
       cs[0] = tmp1;
       cs[1] = ci + 1;
       break;
      case 2:
       tmp1 = ps[15];
       tmp2 = ps[11];
       ps[12] = ps[3];
       ps[13] = pi + 0;
       ps[14] = pi + 1;
       ps[15] = pi + 2;
       ps[8] = ps[7];
       ps[9] = pi + 9;
       ps[10] = pi + 10;
       ps[11] = pi + 3;
       ps[4] = tmp2;
       ps[5] = pi + 8;
       ps[6] = pi + 11;
       ps[7] = pi + 4;
       ps[0] = tmp1;
       ps[1] = pi + 7;
       ps[2] = pi + 6;
       ps[3] = pi + 5;
       tmp1 = cs[3];
       cs[2] = cs[1];
       cs[3] = ci;
       cs[0] = tmp1;
       cs[1] = ci + 1;
       break;
      case 3:
       ps[12] = ps[0];
       ps[13] = pi + 0;
       ps[14] = pi + 1;
       ps[15] = pi + 2;
       ps[8] = ps[1];
       ps[9] = pi + 9;
       ps[10] = pi + 10;
       ps[11] = pi + 3;
       ps[4] = ps[2];
       ps[5] = pi + 8;
       ps[6] = pi + 11;
       ps[7] = pi + 4;
       ps[0] = ps[3];
       ps[1] = pi + 7;
       ps[2] = pi + 6;
       ps[3] = pi + 5;
       cs[2] = cs[0];
       cs[3] = ci;
       cs[0] = cs[1];
       cs[1] = ci + 1;
       break;
      }
      mesh.figures.push({
       type: 'patch',
       coords: new Int32Array(ps),
       colors: new Int32Array(cs)
      });
     }
    }
    function updateBounds(mesh) {
     var minX = mesh.coords[0][0], minY = mesh.coords[0][1], maxX = minX, maxY = minY;
     for (var i = 1, ii = mesh.coords.length; i < ii; i++) {
      var x = mesh.coords[i][0], y = mesh.coords[i][1];
      minX = minX > x ? x : minX;
      minY = minY > y ? y : minY;
      maxX = maxX < x ? x : maxX;
      maxY = maxY < y ? y : maxY;
     }
     mesh.bounds = [
      minX,
      minY,
      maxX,
      maxY
     ];
    }
    function packData(mesh) {
     var i, ii, j, jj;
     var coords = mesh.coords;
     var coordsPacked = new Float32Array(coords.length * 2);
     for (i = 0, j = 0, ii = coords.length; i < ii; i++) {
      var xy = coords[i];
      coordsPacked[j++] = xy[0];
      coordsPacked[j++] = xy[1];
     }
     mesh.coords = coordsPacked;
     var colors = mesh.colors;
     var colorsPacked = new Uint8Array(colors.length * 3);
     for (i = 0, j = 0, ii = colors.length; i < ii; i++) {
      var c = colors[i];
      colorsPacked[j++] = c[0];
      colorsPacked[j++] = c[1];
      colorsPacked[j++] = c[2];
     }
     mesh.colors = colorsPacked;
     var figures = mesh.figures;
     for (i = 0, ii = figures.length; i < ii; i++) {
      var figure = figures[i], ps = figure.coords, cs = figure.colors;
      for (j = 0, jj = ps.length; j < jj; j++) {
       ps[j] *= 2;
       cs[j] *= 3;
      }
     }
    }
    function Mesh(stream, matrix, xref, res) {
     assert(isStream(stream), 'Mesh data is not a stream');
     var dict = stream.dict;
     this.matrix = matrix;
     this.shadingType = dict.get('ShadingType');
     this.type = 'Pattern';
     this.bbox = dict.getArray('BBox');
     var cs = dict.get('ColorSpace', 'CS');
     cs = ColorSpace.parse(cs, xref, res);
     this.cs = cs;
     this.background = dict.has('Background') ? cs.getRgb(dict.get('Background'), 0) : null;
     var fnObj = dict.get('Function');
     var fn = fnObj ? PDFFunction.parseArray(xref, fnObj) : null;
     this.coords = [];
     this.colors = [];
     this.figures = [];
     var decodeContext = {
      bitsPerCoordinate: dict.get('BitsPerCoordinate'),
      bitsPerComponent: dict.get('BitsPerComponent'),
      bitsPerFlag: dict.get('BitsPerFlag'),
      decode: dict.getArray('Decode'),
      colorFn: fn,
      colorSpace: cs,
      numComps: fn ? 1 : cs.numComps
     };
     var reader = new MeshStreamReader(stream, decodeContext);
     var patchMesh = false;
     switch (this.shadingType) {
     case ShadingType.FREE_FORM_MESH:
      decodeType4Shading(this, reader);
      break;
     case ShadingType.LATTICE_FORM_MESH:
      var verticesPerRow = dict.get('VerticesPerRow') | 0;
      assert(verticesPerRow >= 2, 'Invalid VerticesPerRow');
      decodeType5Shading(this, reader, verticesPerRow);
      break;
     case ShadingType.COONS_PATCH_MESH:
      decodeType6Shading(this, reader);
      patchMesh = true;
      break;
     case ShadingType.TENSOR_PATCH_MESH:
      decodeType7Shading(this, reader);
      patchMesh = true;
      break;
     default:
      error('Unsupported mesh type.');
      break;
     }
     if (patchMesh) {
      updateBounds(this);
      for (var i = 0, ii = this.figures.length; i < ii; i++) {
       buildFigureFromPatch(this, i);
      }
     }
     updateBounds(this);
     packData(this);
    }
    Mesh.prototype = {
     getIR: function Mesh_getIR() {
      return [
       'Mesh',
       this.shadingType,
       this.coords,
       this.colors,
       this.figures,
       this.bounds,
       this.matrix,
       this.bbox,
       this.background
      ];
     }
    };
    return Mesh;
   }();
   Shadings.Dummy = function DummyClosure() {
    function Dummy() {
     this.type = 'Pattern';
    }
    Dummy.prototype = {
     getIR: function Dummy_getIR() {
      return ['Dummy'];
     }
    };
    return Dummy;
   }();
   function getTilingPatternIR(operatorList, dict, args) {
    var matrix = dict.getArray('Matrix');
    var bbox = dict.getArray('BBox');
    var xstep = dict.get('XStep');
    var ystep = dict.get('YStep');
    var paintType = dict.get('PaintType');
    var tilingType = dict.get('TilingType');
    return [
     'TilingPattern',
     args,
     operatorList,
     matrix,
     bbox,
     xstep,
     ystep,
     paintType,
     tilingType
    ];
   }
   exports.Pattern = Pattern;
   exports.getTilingPatternIR = getTilingPatternIR;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreEvaluator = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreStream, root.pdfjsCoreParser, root.pdfjsCoreImage, root.pdfjsCoreColorSpace, root.pdfjsCoreMurmurHash3, root.pdfjsCoreFonts, root.pdfjsCoreFunction, root.pdfjsCorePattern, root.pdfjsCoreCMap, root.pdfjsCoreMetrics, root.pdfjsCoreBidi, root.pdfjsCoreEncodings, root.pdfjsCoreStandardFonts, root.pdfjsCoreUnicode, root.pdfjsCoreGlyphList);
  }(this, function (exports, sharedUtil, corePrimitives, coreStream, coreParser, coreImage, coreColorSpace, coreMurmurHash3, coreFonts, coreFunction, corePattern, coreCMap, coreMetrics, coreBidi, coreEncodings, coreStandardFonts, coreUnicode, coreGlyphList) {
   var FONT_IDENTITY_MATRIX = sharedUtil.FONT_IDENTITY_MATRIX;
   var IDENTITY_MATRIX = sharedUtil.IDENTITY_MATRIX;
   var UNSUPPORTED_FEATURES = sharedUtil.UNSUPPORTED_FEATURES;
   var ImageKind = sharedUtil.ImageKind;
   var OPS = sharedUtil.OPS;
   var TextRenderingMode = sharedUtil.TextRenderingMode;
   var Util = sharedUtil.Util;
   var assert = sharedUtil.assert;
   var createPromiseCapability = sharedUtil.createPromiseCapability;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var isNum = sharedUtil.isNum;
   var isString = sharedUtil.isString;
   var getLookupTableFactory = sharedUtil.getLookupTableFactory;
   var warn = sharedUtil.warn;
   var Dict = corePrimitives.Dict;
   var Name = corePrimitives.Name;
   var isCmd = corePrimitives.isCmd;
   var isDict = corePrimitives.isDict;
   var isName = corePrimitives.isName;
   var isRef = corePrimitives.isRef;
   var isStream = corePrimitives.isStream;
   var DecodeStream = coreStream.DecodeStream;
   var JpegStream = coreStream.JpegStream;
   var Stream = coreStream.Stream;
   var Lexer = coreParser.Lexer;
   var Parser = coreParser.Parser;
   var isEOF = coreParser.isEOF;
   var PDFImage = coreImage.PDFImage;
   var ColorSpace = coreColorSpace.ColorSpace;
   var MurmurHash3_64 = coreMurmurHash3.MurmurHash3_64;
   var ErrorFont = coreFonts.ErrorFont;
   var FontFlags = coreFonts.FontFlags;
   var Font = coreFonts.Font;
   var IdentityToUnicodeMap = coreFonts.IdentityToUnicodeMap;
   var ToUnicodeMap = coreFonts.ToUnicodeMap;
   var getFontType = coreFonts.getFontType;
   var isPDFFunction = coreFunction.isPDFFunction;
   var PDFFunction = coreFunction.PDFFunction;
   var Pattern = corePattern.Pattern;
   var getTilingPatternIR = corePattern.getTilingPatternIR;
   var CMapFactory = coreCMap.CMapFactory;
   var IdentityCMap = coreCMap.IdentityCMap;
   var getMetrics = coreMetrics.getMetrics;
   var bidi = coreBidi.bidi;
   var WinAnsiEncoding = coreEncodings.WinAnsiEncoding;
   var StandardEncoding = coreEncodings.StandardEncoding;
   var MacRomanEncoding = coreEncodings.MacRomanEncoding;
   var SymbolSetEncoding = coreEncodings.SymbolSetEncoding;
   var ZapfDingbatsEncoding = coreEncodings.ZapfDingbatsEncoding;
   var getEncoding = coreEncodings.getEncoding;
   var getStdFontMap = coreStandardFonts.getStdFontMap;
   var getSerifFonts = coreStandardFonts.getSerifFonts;
   var getSymbolsFonts = coreStandardFonts.getSymbolsFonts;
   var getNormalizedUnicodes = coreUnicode.getNormalizedUnicodes;
   var reverseIfRtl = coreUnicode.reverseIfRtl;
   var getUnicodeForGlyph = coreUnicode.getUnicodeForGlyph;
   var getGlyphsUnicode = coreGlyphList.getGlyphsUnicode;
   var PartialEvaluator = function PartialEvaluatorClosure() {
    var DefaultPartialEvaluatorOptions = {
     forceDataSchema: false,
     maxImageSize: -1,
     disableFontFace: false,
     cMapOptions: {
      url: null,
      packed: false
     }
    };
    function NativeImageDecoder(xref, resources, handler, forceDataSchema) {
     this.xref = xref;
     this.resources = resources;
     this.handler = handler;
     this.forceDataSchema = forceDataSchema;
    }
    NativeImageDecoder.prototype = {
     canDecode: function (image) {
      return image instanceof JpegStream && NativeImageDecoder.isDecodable(image, this.xref, this.resources);
     },
     decode: function (image) {
      var dict = image.dict;
      var colorSpace = dict.get('ColorSpace', 'CS');
      colorSpace = ColorSpace.parse(colorSpace, this.xref, this.resources);
      var numComps = colorSpace.numComps;
      var decodePromise = this.handler.sendWithPromise('JpegDecode', [
       image.getIR(this.forceDataSchema),
       numComps
      ]);
      return decodePromise.then(function (message) {
       var data = message.data;
       return new Stream(data, 0, data.length, image.dict);
      });
     }
    };
    NativeImageDecoder.isSupported = function NativeImageDecoder_isSupported(image, xref, res) {
     var dict = image.dict;
     if (dict.has('DecodeParms') || dict.has('DP')) {
      return false;
     }
     var cs = ColorSpace.parse(dict.get('ColorSpace', 'CS'), xref, res);
     return (cs.name === 'DeviceGray' || cs.name === 'DeviceRGB') && cs.isDefaultDecode(dict.getArray('Decode', 'D'));
    };
    NativeImageDecoder.isDecodable = function NativeImageDecoder_isDecodable(image, xref, res) {
     var dict = image.dict;
     if (dict.has('DecodeParms') || dict.has('DP')) {
      return false;
     }
     var cs = ColorSpace.parse(dict.get('ColorSpace', 'CS'), xref, res);
     return (cs.numComps === 1 || cs.numComps === 3) && cs.isDefaultDecode(dict.getArray('Decode', 'D'));
    };
    function PartialEvaluator(pdfManager, xref, handler, pageIndex, uniquePrefix, idCounters, fontCache, options) {
     this.pdfManager = pdfManager;
     this.xref = xref;
     this.handler = handler;
     this.pageIndex = pageIndex;
     this.uniquePrefix = uniquePrefix;
     this.idCounters = idCounters;
     this.fontCache = fontCache;
     this.options = options || DefaultPartialEvaluatorOptions;
    }
    var TIME_SLOT_DURATION_MS = 20;
    var CHECK_TIME_EVERY = 100;
    function TimeSlotManager() {
     this.reset();
    }
    TimeSlotManager.prototype = {
     check: function TimeSlotManager_check() {
      if (++this.checked < CHECK_TIME_EVERY) {
       return false;
      }
      this.checked = 0;
      return this.endTime <= Date.now();
     },
     reset: function TimeSlotManager_reset() {
      this.endTime = Date.now() + TIME_SLOT_DURATION_MS;
      this.checked = 0;
     }
    };
    var deferred = Promise.resolve();
    var TILING_PATTERN = 1, SHADING_PATTERN = 2;
    PartialEvaluator.prototype = {
     hasBlendModes: function PartialEvaluator_hasBlendModes(resources) {
      if (!isDict(resources)) {
       return false;
      }
      var processed = Object.create(null);
      if (resources.objId) {
       processed[resources.objId] = true;
      }
      var nodes = [resources], xref = this.xref;
      while (nodes.length) {
       var key, i, ii;
       var node = nodes.shift();
       var graphicStates = node.get('ExtGState');
       if (isDict(graphicStates)) {
        var graphicStatesKeys = graphicStates.getKeys();
        for (i = 0, ii = graphicStatesKeys.length; i < ii; i++) {
         key = graphicStatesKeys[i];
         var graphicState = graphicStates.get(key);
         var bm = graphicState.get('BM');
         if (isName(bm) && bm.name !== 'Normal') {
          return true;
         }
        }
       }
       var xObjects = node.get('XObject');
       if (!isDict(xObjects)) {
        continue;
       }
       var xObjectsKeys = xObjects.getKeys();
       for (i = 0, ii = xObjectsKeys.length; i < ii; i++) {
        key = xObjectsKeys[i];
        var xObject = xObjects.getRaw(key);
        if (isRef(xObject)) {
         if (processed[xObject.toString()]) {
          continue;
         }
         xObject = xref.fetch(xObject);
        }
        if (!isStream(xObject)) {
         continue;
        }
        if (xObject.dict.objId) {
         if (processed[xObject.dict.objId]) {
          continue;
         }
         processed[xObject.dict.objId] = true;
        }
        var xResources = xObject.dict.get('Resources');
        if (isDict(xResources) && (!xResources.objId || !processed[xResources.objId])) {
         nodes.push(xResources);
         if (xResources.objId) {
          processed[xResources.objId] = true;
         }
        }
       }
      }
      return false;
     },
     buildFormXObject: function PartialEvaluator_buildFormXObject(resources, xobj, smask, operatorList, task, initialState) {
      var matrix = xobj.dict.getArray('Matrix');
      var bbox = xobj.dict.getArray('BBox');
      var group = xobj.dict.get('Group');
      if (group) {
       var groupOptions = {
        matrix: matrix,
        bbox: bbox,
        smask: smask,
        isolated: false,
        knockout: false
       };
       var groupSubtype = group.get('S');
       var colorSpace;
       if (isName(groupSubtype, 'Transparency')) {
        groupOptions.isolated = group.get('I') || false;
        groupOptions.knockout = group.get('K') || false;
        colorSpace = group.has('CS') ? ColorSpace.parse(group.get('CS'), this.xref, resources) : null;
       }
       if (smask && smask.backdrop) {
        colorSpace = colorSpace || ColorSpace.singletons.rgb;
        smask.backdrop = colorSpace.getRgb(smask.backdrop, 0);
       }
       operatorList.addOp(OPS.beginGroup, [groupOptions]);
      }
      operatorList.addOp(OPS.paintFormXObjectBegin, [
       matrix,
       bbox
      ]);
      return this.getOperatorList(xobj, task, xobj.dict.get('Resources') || resources, operatorList, initialState).then(function () {
       operatorList.addOp(OPS.paintFormXObjectEnd, []);
       if (group) {
        operatorList.addOp(OPS.endGroup, [groupOptions]);
       }
      });
     },
     buildPaintImageXObject: function PartialEvaluator_buildPaintImageXObject(resources, image, inline, operatorList, cacheKey, imageCache) {
      var self = this;
      var dict = image.dict;
      var w = dict.get('Width', 'W');
      var h = dict.get('Height', 'H');
      if (!(w && isNum(w)) || !(h && isNum(h))) {
       warn('Image dimensions are missing, or not numbers.');
       return;
      }
      var maxImageSize = this.options.maxImageSize;
      if (maxImageSize !== -1 && w * h > maxImageSize) {
       warn('Image exceeded maximum allowed size and was removed.');
       return;
      }
      var imageMask = dict.get('ImageMask', 'IM') || false;
      var imgData, args;
      if (imageMask) {
       var width = dict.get('Width', 'W');
       var height = dict.get('Height', 'H');
       var bitStrideLength = width + 7 >> 3;
       var imgArray = image.getBytes(bitStrideLength * height);
       var decode = dict.getArray('Decode', 'D');
       var inverseDecode = !!decode && decode[0] > 0;
       imgData = PDFImage.createMask(imgArray, width, height, image instanceof DecodeStream, inverseDecode);
       imgData.cached = true;
       args = [imgData];
       operatorList.addOp(OPS.paintImageMaskXObject, args);
       if (cacheKey) {
        imageCache[cacheKey] = {
         fn: OPS.paintImageMaskXObject,
         args: args
        };
       }
       return;
      }
      var softMask = dict.get('SMask', 'SM') || false;
      var mask = dict.get('Mask') || false;
      var SMALL_IMAGE_DIMENSIONS = 200;
      if (inline && !softMask && !mask && !(image instanceof JpegStream) && w + h < SMALL_IMAGE_DIMENSIONS) {
       var imageObj = new PDFImage(this.xref, resources, image, inline, null, null);
       imgData = imageObj.createImageData(true);
       operatorList.addOp(OPS.paintInlineImageXObject, [imgData]);
       return;
      }
      var uniquePrefix = this.uniquePrefix || '';
      var objId = 'img_' + uniquePrefix + ++this.idCounters.obj;
      operatorList.addDependency(objId);
      args = [
       objId,
       w,
       h
      ];
      if (!softMask && !mask && image instanceof JpegStream && NativeImageDecoder.isSupported(image, this.xref, resources)) {
       operatorList.addOp(OPS.paintJpegXObject, args);
       this.handler.send('obj', [
        objId,
        this.pageIndex,
        'JpegStream',
        image.getIR(this.options.forceDataSchema)
       ]);
       return;
      }
      var nativeImageDecoder = null;
      if (image instanceof JpegStream || mask instanceof JpegStream || softMask instanceof JpegStream) {
       nativeImageDecoder = new NativeImageDecoder(self.xref, resources, self.handler, self.options.forceDataSchema);
      }
      PDFImage.buildImage(self.handler, self.xref, resources, image, inline, nativeImageDecoder).then(function (imageObj) {
       var imgData = imageObj.createImageData(false);
       self.handler.send('obj', [
        objId,
        self.pageIndex,
        'Image',
        imgData
       ], [imgData.data.buffer]);
      }).then(undefined, function (reason) {
       warn('Unable to decode image: ' + reason);
       self.handler.send('obj', [
        objId,
        self.pageIndex,
        'Image',
        null
       ]);
      });
      operatorList.addOp(OPS.paintImageXObject, args);
      if (cacheKey) {
       imageCache[cacheKey] = {
        fn: OPS.paintImageXObject,
        args: args
       };
      }
     },
     handleSMask: function PartialEvaluator_handleSmask(smask, resources, operatorList, task, stateManager) {
      var smaskContent = smask.get('G');
      var smaskOptions = {
       subtype: smask.get('S').name,
       backdrop: smask.get('BC')
      };
      var transferObj = smask.get('TR');
      if (isPDFFunction(transferObj)) {
       var transferFn = PDFFunction.parse(this.xref, transferObj);
       var transferMap = new Uint8Array(256);
       var tmp = new Float32Array(1);
       for (var i = 0; i < 256; i++) {
        tmp[0] = i / 255;
        transferFn(tmp, 0, tmp, 0);
        transferMap[i] = tmp[0] * 255 | 0;
       }
       smaskOptions.transferMap = transferMap;
      }
      return this.buildFormXObject(resources, smaskContent, smaskOptions, operatorList, task, stateManager.state.clone());
     },
     handleTilingType: function PartialEvaluator_handleTilingType(fn, args, resources, pattern, patternDict, operatorList, task) {
      var tilingOpList = new OperatorList();
      var resourcesArray = [
       patternDict.get('Resources'),
       resources
      ];
      var patternResources = Dict.merge(this.xref, resourcesArray);
      return this.getOperatorList(pattern, task, patternResources, tilingOpList).then(function () {
       operatorList.addDependencies(tilingOpList.dependencies);
       operatorList.addOp(fn, getTilingPatternIR({
        fnArray: tilingOpList.fnArray,
        argsArray: tilingOpList.argsArray
       }, patternDict, args));
      });
     },
     handleSetFont: function PartialEvaluator_handleSetFont(resources, fontArgs, fontRef, operatorList, task, state) {
      var fontName;
      if (fontArgs) {
       fontArgs = fontArgs.slice();
       fontName = fontArgs[0].name;
      }
      var self = this;
      return this.loadFont(fontName, fontRef, this.xref, resources).then(function (translated) {
       if (!translated.font.isType3Font) {
        return translated;
       }
       return translated.loadType3Data(self, resources, operatorList, task).then(function () {
        return translated;
       }, function (reason) {
        self.handler.send('UnsupportedFeature', { featureId: UNSUPPORTED_FEATURES.font });
        return new TranslatedFont('g_font_error', new ErrorFont('Type3 font load error: ' + reason), translated.font);
       });
      }).then(function (translated) {
       state.font = translated.font;
       translated.send(self.handler);
       return translated.loadedName;
      });
     },
     handleText: function PartialEvaluator_handleText(chars, state) {
      var font = state.font;
      var glyphs = font.charsToGlyphs(chars);
      var isAddToPathSet = !!(state.textRenderingMode & TextRenderingMode.ADD_TO_PATH_FLAG);
      if (font.data && (isAddToPathSet || this.options.disableFontFace)) {
       var buildPath = function (fontChar) {
        if (!font.renderer.hasBuiltPath(fontChar)) {
         var path = font.renderer.getPathJs(fontChar);
         this.handler.send('commonobj', [
          font.loadedName + '_path_' + fontChar,
          'FontPath',
          path
         ]);
        }
       }.bind(this);
       for (var i = 0, ii = glyphs.length; i < ii; i++) {
        var glyph = glyphs[i];
        buildPath(glyph.fontChar);
        var accent = glyph.accent;
        if (accent && accent.fontChar) {
         buildPath(accent.fontChar);
        }
       }
      }
      return glyphs;
     },
     setGState: function PartialEvaluator_setGState(resources, gState, operatorList, task, xref, stateManager) {
      var gStateObj = [];
      var gStateKeys = gState.getKeys();
      var self = this;
      var promise = Promise.resolve();
      for (var i = 0, ii = gStateKeys.length; i < ii; i++) {
       var key = gStateKeys[i];
       var value = gState.get(key);
       switch (key) {
       case 'Type':
        break;
       case 'LW':
       case 'LC':
       case 'LJ':
       case 'ML':
       case 'D':
       case 'RI':
       case 'FL':
       case 'CA':
       case 'ca':
        gStateObj.push([
         key,
         value
        ]);
        break;
       case 'Font':
        promise = promise.then(function () {
         return self.handleSetFont(resources, null, value[0], operatorList, task, stateManager.state).then(function (loadedName) {
          operatorList.addDependency(loadedName);
          gStateObj.push([
           key,
           [
            loadedName,
            value[1]
           ]
          ]);
         });
        });
        break;
       case 'BM':
        gStateObj.push([
         key,
         value
        ]);
        break;
       case 'SMask':
        if (isName(value, 'None')) {
         gStateObj.push([
          key,
          false
         ]);
         break;
        }
        if (isDict(value)) {
         promise = promise.then(function (dict) {
          return self.handleSMask(dict, resources, operatorList, task, stateManager);
         }.bind(this, value));
         gStateObj.push([
          key,
          true
         ]);
        } else {
         warn('Unsupported SMask type');
        }
        break;
       case 'OP':
       case 'op':
       case 'OPM':
       case 'BG':
       case 'BG2':
       case 'UCR':
       case 'UCR2':
       case 'TR':
       case 'TR2':
       case 'HT':
       case 'SM':
       case 'SA':
       case 'AIS':
       case 'TK':
        info('graphic state operator ' + key);
        break;
       default:
        info('Unknown graphic state operator ' + key);
        break;
       }
      }
      return promise.then(function () {
       if (gStateObj.length > 0) {
        operatorList.addOp(OPS.setGState, [gStateObj]);
       }
      });
     },
     loadFont: function PartialEvaluator_loadFont(fontName, font, xref, resources) {
      function errorFont() {
       return Promise.resolve(new TranslatedFont('g_font_error', new ErrorFont('Font ' + fontName + ' is not available'), font));
      }
      var fontRef;
      if (font) {
       assert(isRef(font));
       fontRef = font;
      } else {
       var fontRes = resources.get('Font');
       if (fontRes) {
        fontRef = fontRes.getRaw(fontName);
       } else {
        warn('fontRes not available');
        return errorFont();
       }
      }
      if (!fontRef) {
       warn('fontRef not available');
       return errorFont();
      }
      if (this.fontCache.has(fontRef)) {
       return this.fontCache.get(fontRef);
      }
      font = xref.fetchIfRef(fontRef);
      if (!isDict(font)) {
       return errorFont();
      }
      if (font.translated) {
       return font.translated;
      }
      var fontCapability = createPromiseCapability();
      var preEvaluatedFont = this.preEvaluateFont(font, xref);
      var descriptor = preEvaluatedFont.descriptor;
      var fontRefIsRef = isRef(fontRef), fontID;
      if (fontRefIsRef) {
       fontID = fontRef.toString();
      }
      if (isDict(descriptor)) {
       if (!descriptor.fontAliases) {
        descriptor.fontAliases = Object.create(null);
       }
       var fontAliases = descriptor.fontAliases;
       var hash = preEvaluatedFont.hash;
       if (fontAliases[hash]) {
        var aliasFontRef = fontAliases[hash].aliasRef;
        if (fontRefIsRef && aliasFontRef && this.fontCache.has(aliasFontRef)) {
         this.fontCache.putAlias(fontRef, aliasFontRef);
         return this.fontCache.get(fontRef);
        }
       } else {
        fontAliases[hash] = { fontID: Font.getFontID() };
       }
       if (fontRefIsRef) {
        fontAliases[hash].aliasRef = fontRef;
       }
       fontID = fontAliases[hash].fontID;
      }
      if (fontRefIsRef) {
       this.fontCache.put(fontRef, fontCapability.promise);
      } else {
       if (!fontID) {
        fontID = (this.uniquePrefix || 'F_') + ++this.idCounters.obj;
       }
       this.fontCache.put('id_' + fontID, fontCapability.promise);
      }
      assert(fontID, 'The "fontID" must be defined.');
      font.loadedName = 'g_' + this.pdfManager.docId + '_f' + fontID;
      font.translated = fontCapability.promise;
      var translatedPromise;
      try {
       translatedPromise = this.translateFont(preEvaluatedFont, xref);
      } catch (e) {
       translatedPromise = Promise.reject(e);
      }
      var self = this;
      translatedPromise.then(function (translatedFont) {
       if (translatedFont.fontType !== undefined) {
        var xrefFontStats = xref.stats.fontTypes;
        xrefFontStats[translatedFont.fontType] = true;
       }
       fontCapability.resolve(new TranslatedFont(font.loadedName, translatedFont, font));
      }, function (reason) {
       self.handler.send('UnsupportedFeature', { featureId: UNSUPPORTED_FEATURES.font });
       try {
        var descriptor = preEvaluatedFont.descriptor;
        var fontFile3 = descriptor && descriptor.get('FontFile3');
        var subtype = fontFile3 && fontFile3.get('Subtype');
        var fontType = getFontType(preEvaluatedFont.type, subtype && subtype.name);
        var xrefFontStats = xref.stats.fontTypes;
        xrefFontStats[fontType] = true;
       } catch (ex) {
       }
       fontCapability.resolve(new TranslatedFont(font.loadedName, new ErrorFont(reason instanceof Error ? reason.message : reason), font));
      });
      return fontCapability.promise;
     },
     buildPath: function PartialEvaluator_buildPath(operatorList, fn, args) {
      var lastIndex = operatorList.length - 1;
      if (!args) {
       args = [];
      }
      if (lastIndex < 0 || operatorList.fnArray[lastIndex] !== OPS.constructPath) {
       operatorList.addOp(OPS.constructPath, [
        [fn],
        args
       ]);
      } else {
       var opArgs = operatorList.argsArray[lastIndex];
       opArgs[0].push(fn);
       Array.prototype.push.apply(opArgs[1], args);
      }
     },
     handleColorN: function PartialEvaluator_handleColorN(operatorList, fn, args, cs, patterns, resources, task, xref) {
      var patternName = args[args.length - 1];
      var pattern;
      if (isName(patternName) && (pattern = patterns.get(patternName.name))) {
       var dict = isStream(pattern) ? pattern.dict : pattern;
       var typeNum = dict.get('PatternType');
       if (typeNum === TILING_PATTERN) {
        var color = cs.base ? cs.base.getRgb(args, 0) : null;
        return this.handleTilingType(fn, color, resources, pattern, dict, operatorList, task);
       } else if (typeNum === SHADING_PATTERN) {
        var shading = dict.get('Shading');
        var matrix = dict.getArray('Matrix');
        pattern = Pattern.parseShading(shading, matrix, xref, resources, this.handler);
        operatorList.addOp(fn, pattern.getIR());
        return Promise.resolve();
       } else {
        return Promise.reject('Unknown PatternType: ' + typeNum);
       }
      }
      operatorList.addOp(fn, args);
      return Promise.resolve();
     },
     getOperatorList: function PartialEvaluator_getOperatorList(stream, task, resources, operatorList, initialState) {
      var self = this;
      var xref = this.xref;
      var imageCache = Object.create(null);
      assert(operatorList);
      resources = resources || Dict.empty;
      var xobjs = resources.get('XObject') || Dict.empty;
      var patterns = resources.get('Pattern') || Dict.empty;
      var stateManager = new StateManager(initialState || new EvalState());
      var preprocessor = new EvaluatorPreprocessor(stream, xref, stateManager);
      var timeSlotManager = new TimeSlotManager();
      return new Promise(function promiseBody(resolve, reject) {
       var next = function (promise) {
        promise.then(function () {
         try {
          promiseBody(resolve, reject);
         } catch (ex) {
          reject(ex);
         }
        }, reject);
       };
       task.ensureNotTerminated();
       timeSlotManager.reset();
       var stop, operation = {}, i, ii, cs;
       while (!(stop = timeSlotManager.check())) {
        operation.args = null;
        if (!preprocessor.read(operation)) {
         break;
        }
        var args = operation.args;
        var fn = operation.fn;
        switch (fn | 0) {
        case OPS.paintXObject:
         if (args[0].code) {
          break;
         }
         var name = args[0].name;
         if (!name) {
          warn('XObject must be referred to by name.');
          continue;
         }
         if (imageCache[name] !== undefined) {
          operatorList.addOp(imageCache[name].fn, imageCache[name].args);
          args = null;
          continue;
         }
         var xobj = xobjs.get(name);
         if (xobj) {
          assert(isStream(xobj), 'XObject should be a stream');
          var type = xobj.dict.get('Subtype');
          assert(isName(type), 'XObject should have a Name subtype');
          if (type.name === 'Form') {
           stateManager.save();
           next(self.buildFormXObject(resources, xobj, null, operatorList, task, stateManager.state.clone()).then(function () {
            stateManager.restore();
           }));
           return;
          } else if (type.name === 'Image') {
           self.buildPaintImageXObject(resources, xobj, false, operatorList, name, imageCache);
           args = null;
           continue;
          } else if (type.name === 'PS') {
           info('Ignored XObject subtype PS');
           continue;
          } else {
           error('Unhandled XObject subtype ' + type.name);
          }
         }
         break;
        case OPS.setFont:
         var fontSize = args[1];
         next(self.handleSetFont(resources, args, null, operatorList, task, stateManager.state).then(function (loadedName) {
          operatorList.addDependency(loadedName);
          operatorList.addOp(OPS.setFont, [
           loadedName,
           fontSize
          ]);
         }));
         return;
        case OPS.endInlineImage:
         var cacheKey = args[0].cacheKey;
         if (cacheKey) {
          var cacheEntry = imageCache[cacheKey];
          if (cacheEntry !== undefined) {
           operatorList.addOp(cacheEntry.fn, cacheEntry.args);
           args = null;
           continue;
          }
         }
         self.buildPaintImageXObject(resources, args[0], true, operatorList, cacheKey, imageCache);
         args = null;
         continue;
        case OPS.showText:
         args[0] = self.handleText(args[0], stateManager.state);
         break;
        case OPS.showSpacedText:
         var arr = args[0];
         var combinedGlyphs = [];
         var arrLength = arr.length;
         var state = stateManager.state;
         for (i = 0; i < arrLength; ++i) {
          var arrItem = arr[i];
          if (isString(arrItem)) {
           Array.prototype.push.apply(combinedGlyphs, self.handleText(arrItem, state));
          } else if (isNum(arrItem)) {
           combinedGlyphs.push(arrItem);
          }
         }
         args[0] = combinedGlyphs;
         fn = OPS.showText;
         break;
        case OPS.nextLineShowText:
         operatorList.addOp(OPS.nextLine);
         args[0] = self.handleText(args[0], stateManager.state);
         fn = OPS.showText;
         break;
        case OPS.nextLineSetSpacingShowText:
         operatorList.addOp(OPS.nextLine);
         operatorList.addOp(OPS.setWordSpacing, [args.shift()]);
         operatorList.addOp(OPS.setCharSpacing, [args.shift()]);
         args[0] = self.handleText(args[0], stateManager.state);
         fn = OPS.showText;
         break;
        case OPS.setTextRenderingMode:
         stateManager.state.textRenderingMode = args[0];
         break;
        case OPS.setFillColorSpace:
         stateManager.state.fillColorSpace = ColorSpace.parse(args[0], xref, resources);
         continue;
        case OPS.setStrokeColorSpace:
         stateManager.state.strokeColorSpace = ColorSpace.parse(args[0], xref, resources);
         continue;
        case OPS.setFillColor:
         cs = stateManager.state.fillColorSpace;
         args = cs.getRgb(args, 0);
         fn = OPS.setFillRGBColor;
         break;
        case OPS.setStrokeColor:
         cs = stateManager.state.strokeColorSpace;
         args = cs.getRgb(args, 0);
         fn = OPS.setStrokeRGBColor;
         break;
        case OPS.setFillGray:
         stateManager.state.fillColorSpace = ColorSpace.singletons.gray;
         args = ColorSpace.singletons.gray.getRgb(args, 0);
         fn = OPS.setFillRGBColor;
         break;
        case OPS.setStrokeGray:
         stateManager.state.strokeColorSpace = ColorSpace.singletons.gray;
         args = ColorSpace.singletons.gray.getRgb(args, 0);
         fn = OPS.setStrokeRGBColor;
         break;
        case OPS.setFillCMYKColor:
         stateManager.state.fillColorSpace = ColorSpace.singletons.cmyk;
         args = ColorSpace.singletons.cmyk.getRgb(args, 0);
         fn = OPS.setFillRGBColor;
         break;
        case OPS.setStrokeCMYKColor:
         stateManager.state.strokeColorSpace = ColorSpace.singletons.cmyk;
         args = ColorSpace.singletons.cmyk.getRgb(args, 0);
         fn = OPS.setStrokeRGBColor;
         break;
        case OPS.setFillRGBColor:
         stateManager.state.fillColorSpace = ColorSpace.singletons.rgb;
         args = ColorSpace.singletons.rgb.getRgb(args, 0);
         break;
        case OPS.setStrokeRGBColor:
         stateManager.state.strokeColorSpace = ColorSpace.singletons.rgb;
         args = ColorSpace.singletons.rgb.getRgb(args, 0);
         break;
        case OPS.setFillColorN:
         cs = stateManager.state.fillColorSpace;
         if (cs.name === 'Pattern') {
          next(self.handleColorN(operatorList, OPS.setFillColorN, args, cs, patterns, resources, task, xref));
          return;
         }
         args = cs.getRgb(args, 0);
         fn = OPS.setFillRGBColor;
         break;
        case OPS.setStrokeColorN:
         cs = stateManager.state.strokeColorSpace;
         if (cs.name === 'Pattern') {
          next(self.handleColorN(operatorList, OPS.setStrokeColorN, args, cs, patterns, resources, task, xref));
          return;
         }
         args = cs.getRgb(args, 0);
         fn = OPS.setStrokeRGBColor;
         break;
        case OPS.shadingFill:
         var shadingRes = resources.get('Shading');
         if (!shadingRes) {
          error('No shading resource found');
         }
         var shading = shadingRes.get(args[0].name);
         if (!shading) {
          error('No shading object found');
         }
         var shadingFill = Pattern.parseShading(shading, null, xref, resources, self.handler);
         var patternIR = shadingFill.getIR();
         args = [patternIR];
         fn = OPS.shadingFill;
         break;
        case OPS.setGState:
         var dictName = args[0];
         var extGState = resources.get('ExtGState');
         if (!isDict(extGState) || !extGState.has(dictName.name)) {
          break;
         }
         var gState = extGState.get(dictName.name);
         next(self.setGState(resources, gState, operatorList, task, xref, stateManager));
         return;
        case OPS.moveTo:
        case OPS.lineTo:
        case OPS.curveTo:
        case OPS.curveTo2:
        case OPS.curveTo3:
        case OPS.closePath:
         self.buildPath(operatorList, fn, args);
         continue;
        case OPS.rectangle:
         self.buildPath(operatorList, fn, args);
         continue;
        case OPS.markPoint:
        case OPS.markPointProps:
        case OPS.beginMarkedContent:
        case OPS.beginMarkedContentProps:
        case OPS.endMarkedContent:
        case OPS.beginCompat:
        case OPS.endCompat:
         continue;
        default:
         if (args !== null) {
          for (i = 0, ii = args.length; i < ii; i++) {
           if (args[i] instanceof Dict) {
            break;
           }
          }
          if (i < ii) {
           warn('getOperatorList - ignoring operator: ' + fn);
           continue;
          }
         }
        }
        operatorList.addOp(fn, args);
       }
       if (stop) {
        next(deferred);
        return;
       }
       for (i = 0, ii = preprocessor.savedStatesDepth; i < ii; i++) {
        operatorList.addOp(OPS.restore, []);
       }
       resolve();
      });
     },
     getTextContent: function PartialEvaluator_getTextContent(stream, task, resources, stateManager, normalizeWhitespace, combineTextItems) {
      stateManager = stateManager || new StateManager(new TextState());
      var WhitespaceRegexp = /\s/g;
      var textContent = {
       items: [],
       styles: Object.create(null)
      };
      var textContentItem = {
       initialized: false,
       str: [],
       width: 0,
       height: 0,
       vertical: false,
       lastAdvanceWidth: 0,
       lastAdvanceHeight: 0,
       textAdvanceScale: 0,
       spaceWidth: 0,
       fakeSpaceMin: Infinity,
       fakeMultiSpaceMin: Infinity,
       fakeMultiSpaceMax: -0,
       textRunBreakAllowed: false,
       transform: null,
       fontName: null
      };
      var SPACE_FACTOR = 0.3;
      var MULTI_SPACE_FACTOR = 1.5;
      var MULTI_SPACE_FACTOR_MAX = 4;
      var self = this;
      var xref = this.xref;
      resources = xref.fetchIfRef(resources) || Dict.empty;
      var xobjs = null;
      var xobjsCache = Object.create(null);
      var preprocessor = new EvaluatorPreprocessor(stream, xref, stateManager);
      var textState;
      function ensureTextContentItem() {
       if (textContentItem.initialized) {
        return textContentItem;
       }
       var font = textState.font;
       if (!(font.loadedName in textContent.styles)) {
        textContent.styles[font.loadedName] = {
         fontFamily: font.fallbackName,
         ascent: font.ascent,
         descent: font.descent,
         vertical: font.vertical
        };
       }
       textContentItem.fontName = font.loadedName;
       var tsm = [
        textState.fontSize * textState.textHScale,
        0,
        0,
        textState.fontSize,
        0,
        textState.textRise
       ];
       if (font.isType3Font && textState.fontMatrix !== FONT_IDENTITY_MATRIX && textState.fontSize === 1) {
        var glyphHeight = font.bbox[3] - font.bbox[1];
        if (glyphHeight > 0) {
         glyphHeight = glyphHeight * textState.fontMatrix[3];
         tsm[3] *= glyphHeight;
        }
       }
       var trm = Util.transform(textState.ctm, Util.transform(textState.textMatrix, tsm));
       textContentItem.transform = trm;
       if (!font.vertical) {
        textContentItem.width = 0;
        textContentItem.height = Math.sqrt(trm[2] * trm[2] + trm[3] * trm[3]);
        textContentItem.vertical = false;
       } else {
        textContentItem.width = Math.sqrt(trm[0] * trm[0] + trm[1] * trm[1]);
        textContentItem.height = 0;
        textContentItem.vertical = true;
       }
       var a = textState.textLineMatrix[0];
       var b = textState.textLineMatrix[1];
       var scaleLineX = Math.sqrt(a * a + b * b);
       a = textState.ctm[0];
       b = textState.ctm[1];
       var scaleCtmX = Math.sqrt(a * a + b * b);
       textContentItem.textAdvanceScale = scaleCtmX * scaleLineX;
       textContentItem.lastAdvanceWidth = 0;
       textContentItem.lastAdvanceHeight = 0;
       var spaceWidth = font.spaceWidth / 1000 * textState.fontSize;
       if (spaceWidth) {
        textContentItem.spaceWidth = spaceWidth;
        textContentItem.fakeSpaceMin = spaceWidth * SPACE_FACTOR;
        textContentItem.fakeMultiSpaceMin = spaceWidth * MULTI_SPACE_FACTOR;
        textContentItem.fakeMultiSpaceMax = spaceWidth * MULTI_SPACE_FACTOR_MAX;
        textContentItem.textRunBreakAllowed = !font.isMonospace;
       } else {
        textContentItem.spaceWidth = 0;
        textContentItem.fakeSpaceMin = Infinity;
        textContentItem.fakeMultiSpaceMin = Infinity;
        textContentItem.fakeMultiSpaceMax = 0;
        textContentItem.textRunBreakAllowed = false;
       }
       textContentItem.initialized = true;
       return textContentItem;
      }
      function replaceWhitespace(str) {
       var i = 0, ii = str.length, code;
       while (i < ii && (code = str.charCodeAt(i)) >= 0x20 && code <= 0x7F) {
        i++;
       }
       return i < ii ? str.replace(WhitespaceRegexp, ' ') : str;
      }
      function runBidiTransform(textChunk) {
       var str = textChunk.str.join('');
       var bidiResult = bidi(str, -1, textChunk.vertical);
       return {
        str: normalizeWhitespace ? replaceWhitespace(bidiResult.str) : bidiResult.str,
        dir: bidiResult.dir,
        width: textChunk.width,
        height: textChunk.height,
        transform: textChunk.transform,
        fontName: textChunk.fontName
       };
      }
      function handleSetFont(fontName, fontRef) {
       return self.loadFont(fontName, fontRef, xref, resources).then(function (translated) {
        textState.font = translated.font;
        textState.fontMatrix = translated.font.fontMatrix || FONT_IDENTITY_MATRIX;
       });
      }
      function buildTextContentItem(chars) {
       var font = textState.font;
       var textChunk = ensureTextContentItem();
       var width = 0;
       var height = 0;
       var glyphs = font.charsToGlyphs(chars);
       var defaultVMetrics = font.defaultVMetrics;
       for (var i = 0; i < glyphs.length; i++) {
        var glyph = glyphs[i];
        var vMetricX = null;
        var vMetricY = null;
        var glyphWidth = null;
        if (font.vertical) {
         if (glyph.vmetric) {
          glyphWidth = glyph.vmetric[0];
          vMetricX = glyph.vmetric[1];
          vMetricY = glyph.vmetric[2];
         } else {
          glyphWidth = glyph.width;
          vMetricX = glyph.width * 0.5;
          vMetricY = defaultVMetrics[2];
         }
        } else {
         glyphWidth = glyph.width;
        }
        var glyphUnicode = glyph.unicode;
        var NormalizedUnicodes = getNormalizedUnicodes();
        if (NormalizedUnicodes[glyphUnicode] !== undefined) {
         glyphUnicode = NormalizedUnicodes[glyphUnicode];
        }
        glyphUnicode = reverseIfRtl(glyphUnicode);
        var charSpacing = textState.charSpacing;
        if (glyph.isSpace) {
         var wordSpacing = textState.wordSpacing;
         charSpacing += wordSpacing;
         if (wordSpacing > 0) {
          addFakeSpaces(wordSpacing, textChunk.str);
         }
        }
        var tx = 0;
        var ty = 0;
        if (!font.vertical) {
         var w0 = glyphWidth * textState.fontMatrix[0];
         tx = (w0 * textState.fontSize + charSpacing) * textState.textHScale;
         width += tx;
        } else {
         var w1 = glyphWidth * textState.fontMatrix[0];
         ty = w1 * textState.fontSize + charSpacing;
         height += ty;
        }
        textState.translateTextMatrix(tx, ty);
        textChunk.str.push(glyphUnicode);
       }
       if (!font.vertical) {
        textChunk.lastAdvanceWidth = width;
        textChunk.width += width;
       } else {
        textChunk.lastAdvanceHeight = height;
        textChunk.height += Math.abs(height);
       }
       return textChunk;
      }
      function addFakeSpaces(width, strBuf) {
       if (width < textContentItem.fakeSpaceMin) {
        return;
       }
       if (width < textContentItem.fakeMultiSpaceMin) {
        strBuf.push(' ');
        return;
       }
       var fakeSpaces = Math.round(width / textContentItem.spaceWidth);
       while (fakeSpaces-- > 0) {
        strBuf.push(' ');
       }
      }
      function flushTextContentItem() {
       if (!textContentItem.initialized) {
        return;
       }
       textContentItem.width *= textContentItem.textAdvanceScale;
       textContentItem.height *= textContentItem.textAdvanceScale;
       textContent.items.push(runBidiTransform(textContentItem));
       textContentItem.initialized = false;
       textContentItem.str.length = 0;
      }
      var timeSlotManager = new TimeSlotManager();
      return new Promise(function promiseBody(resolve, reject) {
       var next = function (promise) {
        promise.then(function () {
         try {
          promiseBody(resolve, reject);
         } catch (ex) {
          reject(ex);
         }
        }, reject);
       };
       task.ensureNotTerminated();
       timeSlotManager.reset();
       var stop, operation = {}, args = [];
       while (!(stop = timeSlotManager.check())) {
        args.length = 0;
        operation.args = args;
        if (!preprocessor.read(operation)) {
         break;
        }
        textState = stateManager.state;
        var fn = operation.fn;
        args = operation.args;
        var advance, diff;
        switch (fn | 0) {
        case OPS.setFont:
         var fontNameArg = args[0].name, fontSizeArg = args[1];
         if (textState.font && fontNameArg === textState.fontName && fontSizeArg === textState.fontSize) {
          break;
         }
         flushTextContentItem();
         textState.fontName = fontNameArg;
         textState.fontSize = fontSizeArg;
         next(handleSetFont(fontNameArg, null));
         return;
        case OPS.setTextRise:
         flushTextContentItem();
         textState.textRise = args[0];
         break;
        case OPS.setHScale:
         flushTextContentItem();
         textState.textHScale = args[0] / 100;
         break;
        case OPS.setLeading:
         flushTextContentItem();
         textState.leading = args[0];
         break;
        case OPS.moveText:
         var isSameTextLine = !textState.font ? false : (textState.font.vertical ? args[0] : args[1]) === 0;
         advance = args[0] - args[1];
         if (combineTextItems && isSameTextLine && textContentItem.initialized && advance > 0 && advance <= textContentItem.fakeMultiSpaceMax) {
          textState.translateTextLineMatrix(args[0], args[1]);
          textContentItem.width += args[0] - textContentItem.lastAdvanceWidth;
          textContentItem.height += args[1] - textContentItem.lastAdvanceHeight;
          diff = args[0] - textContentItem.lastAdvanceWidth - (args[1] - textContentItem.lastAdvanceHeight);
          addFakeSpaces(diff, textContentItem.str);
          break;
         }
         flushTextContentItem();
         textState.translateTextLineMatrix(args[0], args[1]);
         textState.textMatrix = textState.textLineMatrix.slice();
         break;
        case OPS.setLeadingMoveText:
         flushTextContentItem();
         textState.leading = -args[1];
         textState.translateTextLineMatrix(args[0], args[1]);
         textState.textMatrix = textState.textLineMatrix.slice();
         break;
        case OPS.nextLine:
         flushTextContentItem();
         textState.carriageReturn();
         break;
        case OPS.setTextMatrix:
         advance = textState.calcTextLineMatrixAdvance(args[0], args[1], args[2], args[3], args[4], args[5]);
         if (combineTextItems && advance !== null && textContentItem.initialized && advance.value > 0 && advance.value <= textContentItem.fakeMultiSpaceMax) {
          textState.translateTextLineMatrix(advance.width, advance.height);
          textContentItem.width += advance.width - textContentItem.lastAdvanceWidth;
          textContentItem.height += advance.height - textContentItem.lastAdvanceHeight;
          diff = advance.width - textContentItem.lastAdvanceWidth - (advance.height - textContentItem.lastAdvanceHeight);
          addFakeSpaces(diff, textContentItem.str);
          break;
         }
         flushTextContentItem();
         textState.setTextMatrix(args[0], args[1], args[2], args[3], args[4], args[5]);
         textState.setTextLineMatrix(args[0], args[1], args[2], args[3], args[4], args[5]);
         break;
        case OPS.setCharSpacing:
         textState.charSpacing = args[0];
         break;
        case OPS.setWordSpacing:
         textState.wordSpacing = args[0];
         break;
        case OPS.beginText:
         flushTextContentItem();
         textState.textMatrix = IDENTITY_MATRIX.slice();
         textState.textLineMatrix = IDENTITY_MATRIX.slice();
         break;
        case OPS.showSpacedText:
         var items = args[0];
         var offset;
         for (var j = 0, jj = items.length; j < jj; j++) {
          if (typeof items[j] === 'string') {
           buildTextContentItem(items[j]);
          } else if (isNum(items[j])) {
           ensureTextContentItem();
           advance = items[j] * textState.fontSize / 1000;
           var breakTextRun = false;
           if (textState.font.vertical) {
            offset = advance;
            textState.translateTextMatrix(0, offset);
            breakTextRun = textContentItem.textRunBreakAllowed && advance > textContentItem.fakeMultiSpaceMax;
            if (!breakTextRun) {
             textContentItem.height += offset;
            }
           } else {
            advance = -advance;
            offset = advance * textState.textHScale;
            textState.translateTextMatrix(offset, 0);
            breakTextRun = textContentItem.textRunBreakAllowed && advance > textContentItem.fakeMultiSpaceMax;
            if (!breakTextRun) {
             textContentItem.width += offset;
            }
           }
           if (breakTextRun) {
            flushTextContentItem();
           } else if (advance > 0) {
            addFakeSpaces(advance, textContentItem.str);
           }
          }
         }
         break;
        case OPS.showText:
         buildTextContentItem(args[0]);
         break;
        case OPS.nextLineShowText:
         flushTextContentItem();
         textState.carriageReturn();
         buildTextContentItem(args[0]);
         break;
        case OPS.nextLineSetSpacingShowText:
         flushTextContentItem();
         textState.wordSpacing = args[0];
         textState.charSpacing = args[1];
         textState.carriageReturn();
         buildTextContentItem(args[2]);
         break;
        case OPS.paintXObject:
         flushTextContentItem();
         if (args[0].code) {
          break;
         }
         if (!xobjs) {
          xobjs = resources.get('XObject') || Dict.empty;
         }
         var name = args[0].name;
         if (xobjsCache.key === name) {
          if (xobjsCache.texts) {
           Util.appendToArray(textContent.items, xobjsCache.texts.items);
           Util.extendObj(textContent.styles, xobjsCache.texts.styles);
          }
          break;
         }
         var xobj = xobjs.get(name);
         if (!xobj) {
          break;
         }
         assert(isStream(xobj), 'XObject should be a stream');
         var type = xobj.dict.get('Subtype');
         assert(isName(type), 'XObject should have a Name subtype');
         if ('Form' !== type.name) {
          xobjsCache.key = name;
          xobjsCache.texts = null;
          break;
         }
         stateManager.save();
         var matrix = xobj.dict.getArray('Matrix');
         if (isArray(matrix) && matrix.length === 6) {
          stateManager.transform(matrix);
         }
         next(self.getTextContent(xobj, task, xobj.dict.get('Resources') || resources, stateManager, normalizeWhitespace, combineTextItems).then(function (formTextContent) {
          Util.appendToArray(textContent.items, formTextContent.items);
          Util.extendObj(textContent.styles, formTextContent.styles);
          stateManager.restore();
          xobjsCache.key = name;
          xobjsCache.texts = formTextContent;
         }));
         return;
        case OPS.setGState:
         flushTextContentItem();
         var dictName = args[0];
         var extGState = resources.get('ExtGState');
         if (!isDict(extGState) || !isName(dictName)) {
          break;
         }
         var gState = extGState.get(dictName.name);
         if (!isDict(gState)) {
          break;
         }
         var gStateFont = gState.get('Font');
         if (gStateFont) {
          textState.fontName = null;
          textState.fontSize = gStateFont[1];
          next(handleSetFont(null, gStateFont[0]));
          return;
         }
         break;
        }
       }
       if (stop) {
        next(deferred);
        return;
       }
       flushTextContentItem();
       resolve(textContent);
      });
     },
     extractDataStructures: function PartialEvaluator_extractDataStructures(dict, baseDict, xref, properties) {
      var toUnicode = dict.get('ToUnicode') || baseDict.get('ToUnicode');
      var toUnicodePromise = toUnicode ? this.readToUnicode(toUnicode) : Promise.resolve(undefined);
      if (properties.composite) {
       var cidSystemInfo = dict.get('CIDSystemInfo');
       if (isDict(cidSystemInfo)) {
        properties.cidSystemInfo = {
         registry: cidSystemInfo.get('Registry'),
         ordering: cidSystemInfo.get('Ordering'),
         supplement: cidSystemInfo.get('Supplement')
        };
       }
       var cidToGidMap = dict.get('CIDToGIDMap');
       if (isStream(cidToGidMap)) {
        properties.cidToGidMap = this.readCidToGidMap(cidToGidMap);
       }
      }
      var differences = [];
      var baseEncodingName = null;
      var encoding;
      if (dict.has('Encoding')) {
       encoding = dict.get('Encoding');
       if (isDict(encoding)) {
        baseEncodingName = encoding.get('BaseEncoding');
        baseEncodingName = isName(baseEncodingName) ? baseEncodingName.name : null;
        if (encoding.has('Differences')) {
         var diffEncoding = encoding.get('Differences');
         var index = 0;
         for (var j = 0, jj = diffEncoding.length; j < jj; j++) {
          var data = xref.fetchIfRef(diffEncoding[j]);
          if (isNum(data)) {
           index = data;
          } else if (isName(data)) {
           differences[index++] = data.name;
          } else {
           error('Invalid entry in \'Differences\' array: ' + data);
          }
         }
        }
       } else if (isName(encoding)) {
        baseEncodingName = encoding.name;
       } else {
        error('Encoding is not a Name nor a Dict');
       }
       if (baseEncodingName !== 'MacRomanEncoding' && baseEncodingName !== 'MacExpertEncoding' && baseEncodingName !== 'WinAnsiEncoding') {
        baseEncodingName = null;
       }
      }
      if (baseEncodingName) {
       properties.defaultEncoding = getEncoding(baseEncodingName).slice();
      } else {
       var isSymbolicFont = !!(properties.flags & FontFlags.Symbolic);
       var isNonsymbolicFont = !!(properties.flags & FontFlags.Nonsymbolic);
       encoding = StandardEncoding;
       if (properties.type === 'TrueType' && !isNonsymbolicFont) {
        encoding = WinAnsiEncoding;
       }
       if (isSymbolicFont) {
        encoding = MacRomanEncoding;
        if (!properties.file) {
         if (/Symbol/i.test(properties.name)) {
          encoding = SymbolSetEncoding;
         } else if (/Dingbats/i.test(properties.name)) {
          encoding = ZapfDingbatsEncoding;
         }
        }
       }
       properties.defaultEncoding = encoding;
      }
      properties.differences = differences;
      properties.baseEncodingName = baseEncodingName;
      properties.hasEncoding = !!baseEncodingName || differences.length > 0;
      properties.dict = dict;
      return toUnicodePromise.then(function (toUnicode) {
       properties.toUnicode = toUnicode;
       return this.buildToUnicode(properties);
      }.bind(this)).then(function (toUnicode) {
       properties.toUnicode = toUnicode;
       return properties;
      });
     },
     buildToUnicode: function PartialEvaluator_buildToUnicode(properties) {
      properties.hasIncludedToUnicodeMap = !!properties.toUnicode && properties.toUnicode.length > 0;
      if (properties.hasIncludedToUnicodeMap) {
       return Promise.resolve(properties.toUnicode);
      }
      var toUnicode, charcode, glyphName;
      if (!properties.composite)
       {
        toUnicode = [];
        var encoding = properties.defaultEncoding.slice();
        var baseEncodingName = properties.baseEncodingName;
        var differences = properties.differences;
        for (charcode in differences) {
         glyphName = differences[charcode];
         if (glyphName === '.notdef') {
          continue;
         }
         encoding[charcode] = glyphName;
        }
        var glyphsUnicodeMap = getGlyphsUnicode();
        for (charcode in encoding) {
         glyphName = encoding[charcode];
         if (glyphName === '') {
          continue;
         } else if (glyphsUnicodeMap[glyphName] === undefined) {
          var code = 0;
          switch (glyphName[0]) {
          case 'G':
           if (glyphName.length === 3) {
            code = parseInt(glyphName.substr(1), 16);
           }
           break;
          case 'g':
           if (glyphName.length === 5) {
            code = parseInt(glyphName.substr(1), 16);
           }
           break;
          case 'C':
          case 'c':
           if (glyphName.length >= 3) {
            code = +glyphName.substr(1);
           }
           break;
          default:
           var unicode = getUnicodeForGlyph(glyphName, glyphsUnicodeMap);
           if (unicode !== -1) {
            code = unicode;
           }
          }
          if (code) {
           if (baseEncodingName && code === +charcode) {
            var baseEncoding = getEncoding(baseEncodingName);
            if (baseEncoding && (glyphName = baseEncoding[charcode])) {
             toUnicode[charcode] = String.fromCharCode(glyphsUnicodeMap[glyphName]);
             continue;
            }
           }
           toUnicode[charcode] = String.fromCharCode(code);
          }
          continue;
         }
         toUnicode[charcode] = String.fromCharCode(glyphsUnicodeMap[glyphName]);
        }
        return Promise.resolve(new ToUnicodeMap(toUnicode));
       }
      if (properties.composite && (properties.cMap.builtInCMap && !(properties.cMap instanceof IdentityCMap) || properties.cidSystemInfo.registry === 'Adobe' && (properties.cidSystemInfo.ordering === 'GB1' || properties.cidSystemInfo.ordering === 'CNS1' || properties.cidSystemInfo.ordering === 'Japan1' || properties.cidSystemInfo.ordering === 'Korea1'))) {
       var registry = properties.cidSystemInfo.registry;
       var ordering = properties.cidSystemInfo.ordering;
       var ucs2CMapName = Name.get(registry + '-' + ordering + '-UCS2');
       return CMapFactory.create(ucs2CMapName, this.options.cMapOptions, null).then(function (ucs2CMap) {
        var cMap = properties.cMap;
        toUnicode = [];
        cMap.forEach(function (charcode, cid) {
         assert(cid <= 0xffff, 'Max size of CID is 65,535');
         var ucs2 = ucs2CMap.lookup(cid);
         if (ucs2) {
          toUnicode[charcode] = String.fromCharCode((ucs2.charCodeAt(0) << 8) + ucs2.charCodeAt(1));
         }
        });
        return new ToUnicodeMap(toUnicode);
       });
      }
      return Promise.resolve(new IdentityToUnicodeMap(properties.firstChar, properties.lastChar));
     },
     readToUnicode: function PartialEvaluator_readToUnicode(toUnicode) {
      var cmapObj = toUnicode;
      if (isName(cmapObj)) {
       return CMapFactory.create(cmapObj, this.options.cMapOptions, null).then(function (cmap) {
        if (cmap instanceof IdentityCMap) {
         return new IdentityToUnicodeMap(0, 0xFFFF);
        }
        return new ToUnicodeMap(cmap.getMap());
       });
      } else if (isStream(cmapObj)) {
       return CMapFactory.create(cmapObj, this.options.cMapOptions, null).then(function (cmap) {
        if (cmap instanceof IdentityCMap) {
         return new IdentityToUnicodeMap(0, 0xFFFF);
        }
        var map = new Array(cmap.length);
        cmap.forEach(function (charCode, token) {
         var str = [];
         for (var k = 0; k < token.length; k += 2) {
          var w1 = token.charCodeAt(k) << 8 | token.charCodeAt(k + 1);
          if ((w1 & 0xF800) !== 0xD800) {
           str.push(w1);
           continue;
          }
          k += 2;
          var w2 = token.charCodeAt(k) << 8 | token.charCodeAt(k + 1);
          str.push(((w1 & 0x3ff) << 10) + (w2 & 0x3ff) + 0x10000);
         }
         map[charCode] = String.fromCharCode.apply(String, str);
        });
        return new ToUnicodeMap(map);
       });
      }
      return Promise.resolve(null);
     },
     readCidToGidMap: function PartialEvaluator_readCidToGidMap(cidToGidStream) {
      var glyphsData = cidToGidStream.getBytes();
      var result = [];
      for (var j = 0, jj = glyphsData.length; j < jj; j++) {
       var glyphID = glyphsData[j++] << 8 | glyphsData[j];
       if (glyphID === 0) {
        continue;
       }
       var code = j >> 1;
       result[code] = glyphID;
      }
      return result;
     },
     extractWidths: function PartialEvaluator_extractWidths(dict, xref, descriptor, properties) {
      var glyphsWidths = [];
      var defaultWidth = 0;
      var glyphsVMetrics = [];
      var defaultVMetrics;
      var i, ii, j, jj, start, code, widths;
      if (properties.composite) {
       defaultWidth = dict.get('DW') || 1000;
       widths = dict.get('W');
       if (widths) {
        for (i = 0, ii = widths.length; i < ii; i++) {
         start = xref.fetchIfRef(widths[i++]);
         code = xref.fetchIfRef(widths[i]);
         if (isArray(code)) {
          for (j = 0, jj = code.length; j < jj; j++) {
           glyphsWidths[start++] = xref.fetchIfRef(code[j]);
          }
         } else {
          var width = xref.fetchIfRef(widths[++i]);
          for (j = start; j <= code; j++) {
           glyphsWidths[j] = width;
          }
         }
        }
       }
       if (properties.vertical) {
        var vmetrics = dict.getArray('DW2') || [
         880,
         -1000
        ];
        defaultVMetrics = [
         vmetrics[1],
         defaultWidth * 0.5,
         vmetrics[0]
        ];
        vmetrics = dict.get('W2');
        if (vmetrics) {
         for (i = 0, ii = vmetrics.length; i < ii; i++) {
          start = xref.fetchIfRef(vmetrics[i++]);
          code = xref.fetchIfRef(vmetrics[i]);
          if (isArray(code)) {
           for (j = 0, jj = code.length; j < jj; j++) {
            glyphsVMetrics[start++] = [
             xref.fetchIfRef(code[j++]),
             xref.fetchIfRef(code[j++]),
             xref.fetchIfRef(code[j])
            ];
           }
          } else {
           var vmetric = [
            xref.fetchIfRef(vmetrics[++i]),
            xref.fetchIfRef(vmetrics[++i]),
            xref.fetchIfRef(vmetrics[++i])
           ];
           for (j = start; j <= code; j++) {
            glyphsVMetrics[j] = vmetric;
           }
          }
         }
        }
       }
      } else {
       var firstChar = properties.firstChar;
       widths = dict.get('Widths');
       if (widths) {
        j = firstChar;
        for (i = 0, ii = widths.length; i < ii; i++) {
         glyphsWidths[j++] = xref.fetchIfRef(widths[i]);
        }
        defaultWidth = parseFloat(descriptor.get('MissingWidth')) || 0;
       } else {
        var baseFontName = dict.get('BaseFont');
        if (isName(baseFontName)) {
         var metrics = this.getBaseFontMetrics(baseFontName.name);
         glyphsWidths = this.buildCharCodeToWidth(metrics.widths, properties);
         defaultWidth = metrics.defaultWidth;
        }
       }
      }
      var isMonospace = true;
      var firstWidth = defaultWidth;
      for (var glyph in glyphsWidths) {
       var glyphWidth = glyphsWidths[glyph];
       if (!glyphWidth) {
        continue;
       }
       if (!firstWidth) {
        firstWidth = glyphWidth;
        continue;
       }
       if (firstWidth !== glyphWidth) {
        isMonospace = false;
        break;
       }
      }
      if (isMonospace) {
       properties.flags |= FontFlags.FixedPitch;
      }
      properties.defaultWidth = defaultWidth;
      properties.widths = glyphsWidths;
      properties.defaultVMetrics = defaultVMetrics;
      properties.vmetrics = glyphsVMetrics;
     },
     isSerifFont: function PartialEvaluator_isSerifFont(baseFontName) {
      var fontNameWoStyle = baseFontName.split('-')[0];
      return fontNameWoStyle in getSerifFonts() || fontNameWoStyle.search(/serif/gi) !== -1;
     },
     getBaseFontMetrics: function PartialEvaluator_getBaseFontMetrics(name) {
      var defaultWidth = 0;
      var widths = [];
      var monospace = false;
      var stdFontMap = getStdFontMap();
      var lookupName = stdFontMap[name] || name;
      var Metrics = getMetrics();
      if (!(lookupName in Metrics)) {
       if (this.isSerifFont(name)) {
        lookupName = 'Times-Roman';
       } else {
        lookupName = 'Helvetica';
       }
      }
      var glyphWidths = Metrics[lookupName];
      if (isNum(glyphWidths)) {
       defaultWidth = glyphWidths;
       monospace = true;
      } else {
       widths = glyphWidths();
      }
      return {
       defaultWidth: defaultWidth,
       monospace: monospace,
       widths: widths
      };
     },
     buildCharCodeToWidth: function PartialEvaluator_bulildCharCodeToWidth(widthsByGlyphName, properties) {
      var widths = Object.create(null);
      var differences = properties.differences;
      var encoding = properties.defaultEncoding;
      for (var charCode = 0; charCode < 256; charCode++) {
       if (charCode in differences && widthsByGlyphName[differences[charCode]]) {
        widths[charCode] = widthsByGlyphName[differences[charCode]];
        continue;
       }
       if (charCode in encoding && widthsByGlyphName[encoding[charCode]]) {
        widths[charCode] = widthsByGlyphName[encoding[charCode]];
        continue;
       }
      }
      return widths;
     },
     preEvaluateFont: function PartialEvaluator_preEvaluateFont(dict, xref) {
      var baseDict = dict;
      var type = dict.get('Subtype');
      assert(isName(type), 'invalid font Subtype');
      var composite = false;
      var uint8array;
      if (type.name === 'Type0') {
       var df = dict.get('DescendantFonts');
       if (!df) {
        error('Descendant fonts are not specified');
       }
       dict = isArray(df) ? xref.fetchIfRef(df[0]) : df;
       type = dict.get('Subtype');
       assert(isName(type), 'invalid font Subtype');
       composite = true;
      }
      var descriptor = dict.get('FontDescriptor');
      if (descriptor) {
       var hash = new MurmurHash3_64();
       var encoding = baseDict.getRaw('Encoding');
       if (isName(encoding)) {
        hash.update(encoding.name);
       } else if (isRef(encoding)) {
        hash.update(encoding.toString());
       } else if (isDict(encoding)) {
        var keys = encoding.getKeys();
        for (var i = 0, ii = keys.length; i < ii; i++) {
         var entry = encoding.getRaw(keys[i]);
         if (isName(entry)) {
          hash.update(entry.name);
         } else if (isRef(entry)) {
          hash.update(entry.toString());
         } else if (isArray(entry)) {
          var diffLength = entry.length, diffBuf = new Array(diffLength);
          for (var j = 0; j < diffLength; j++) {
           var diffEntry = entry[j];
           if (isName(diffEntry)) {
            diffBuf[j] = diffEntry.name;
           } else if (isNum(diffEntry) || isRef(diffEntry)) {
            diffBuf[j] = diffEntry.toString();
           }
          }
          hash.update(diffBuf.join());
         }
        }
       }
       var toUnicode = dict.get('ToUnicode') || baseDict.get('ToUnicode');
       if (isStream(toUnicode)) {
        var stream = toUnicode.str || toUnicode;
        uint8array = stream.buffer ? new Uint8Array(stream.buffer.buffer, 0, stream.bufferLength) : new Uint8Array(stream.bytes.buffer, stream.start, stream.end - stream.start);
        hash.update(uint8array);
       } else if (isName(toUnicode)) {
        hash.update(toUnicode.name);
       }
       var widths = dict.get('Widths') || baseDict.get('Widths');
       if (widths) {
        uint8array = new Uint8Array(new Uint32Array(widths).buffer);
        hash.update(uint8array);
       }
      }
      return {
       descriptor: descriptor,
       dict: dict,
       baseDict: baseDict,
       composite: composite,
       type: type.name,
       hash: hash ? hash.hexdigest() : ''
      };
     },
     translateFont: function PartialEvaluator_translateFont(preEvaluatedFont, xref) {
      var baseDict = preEvaluatedFont.baseDict;
      var dict = preEvaluatedFont.dict;
      var composite = preEvaluatedFont.composite;
      var descriptor = preEvaluatedFont.descriptor;
      var type = preEvaluatedFont.type;
      var maxCharIndex = composite ? 0xFFFF : 0xFF;
      var cMapOptions = this.options.cMapOptions;
      var properties;
      if (!descriptor) {
       if (type === 'Type3') {
        descriptor = new Dict(null);
        descriptor.set('FontName', Name.get(type));
        descriptor.set('FontBBox', dict.getArray('FontBBox'));
       } else {
        var baseFontName = dict.get('BaseFont');
        if (!isName(baseFontName)) {
         error('Base font is not specified');
        }
        baseFontName = baseFontName.name.replace(/[,_]/g, '-');
        var metrics = this.getBaseFontMetrics(baseFontName);
        var fontNameWoStyle = baseFontName.split('-')[0];
        var flags = (this.isSerifFont(fontNameWoStyle) ? FontFlags.Serif : 0) | (metrics.monospace ? FontFlags.FixedPitch : 0) | (getSymbolsFonts()[fontNameWoStyle] ? FontFlags.Symbolic : FontFlags.Nonsymbolic);
        properties = {
         type: type,
         name: baseFontName,
         widths: metrics.widths,
         defaultWidth: metrics.defaultWidth,
         flags: flags,
         firstChar: 0,
         lastChar: maxCharIndex
        };
        return this.extractDataStructures(dict, dict, xref, properties).then(function (properties) {
         properties.widths = this.buildCharCodeToWidth(metrics.widths, properties);
         return new Font(baseFontName, null, properties);
        }.bind(this));
       }
      }
      var firstChar = dict.get('FirstChar') || 0;
      var lastChar = dict.get('LastChar') || maxCharIndex;
      var fontName = descriptor.get('FontName');
      var baseFont = dict.get('BaseFont');
      if (isString(fontName)) {
       fontName = Name.get(fontName);
      }
      if (isString(baseFont)) {
       baseFont = Name.get(baseFont);
      }
      if (type !== 'Type3') {
       var fontNameStr = fontName && fontName.name;
       var baseFontStr = baseFont && baseFont.name;
       if (fontNameStr !== baseFontStr) {
        info('The FontDescriptor\'s FontName is "' + fontNameStr + '" but should be the same as the Font\'s BaseFont "' + baseFontStr + '"');
        if (fontNameStr && baseFontStr && baseFontStr.indexOf(fontNameStr) === 0) {
         fontName = baseFont;
        }
       }
      }
      fontName = fontName || baseFont;
      assert(isName(fontName), 'invalid font name');
      var fontFile = descriptor.get('FontFile', 'FontFile2', 'FontFile3');
      if (fontFile) {
       if (fontFile.dict) {
        var subtype = fontFile.dict.get('Subtype');
        if (subtype) {
         subtype = subtype.name;
        }
        var length1 = fontFile.dict.get('Length1');
        var length2 = fontFile.dict.get('Length2');
        var length3 = fontFile.dict.get('Length3');
       }
      }
      properties = {
       type: type,
       name: fontName.name,
       subtype: subtype,
       file: fontFile,
       length1: length1,
       length2: length2,
       length3: length3,
       loadedName: baseDict.loadedName,
       composite: composite,
       wideChars: composite,
       fixedPitch: false,
       fontMatrix: dict.getArray('FontMatrix') || FONT_IDENTITY_MATRIX,
       firstChar: firstChar || 0,
       lastChar: lastChar || maxCharIndex,
       bbox: descriptor.getArray('FontBBox'),
       ascent: descriptor.get('Ascent'),
       descent: descriptor.get('Descent'),
       xHeight: descriptor.get('XHeight'),
       capHeight: descriptor.get('CapHeight'),
       flags: descriptor.get('Flags'),
       italicAngle: descriptor.get('ItalicAngle'),
       coded: false
      };
      var cMapPromise;
      if (composite) {
       var cidEncoding = baseDict.get('Encoding');
       if (isName(cidEncoding)) {
        properties.cidEncoding = cidEncoding.name;
       }
       cMapPromise = CMapFactory.create(cidEncoding, cMapOptions, null).then(function (cMap) {
        properties.cMap = cMap;
        properties.vertical = properties.cMap.vertical;
       });
      } else {
       cMapPromise = Promise.resolve(undefined);
      }
      return cMapPromise.then(function () {
       return this.extractDataStructures(dict, baseDict, xref, properties);
      }.bind(this)).then(function (properties) {
       this.extractWidths(dict, xref, descriptor, properties);
       if (type === 'Type3') {
        properties.isType3Font = true;
       }
       return new Font(fontName.name, fontFile, properties);
      }.bind(this));
     }
    };
    return PartialEvaluator;
   }();
   var TranslatedFont = function TranslatedFontClosure() {
    function TranslatedFont(loadedName, font, dict) {
     this.loadedName = loadedName;
     this.font = font;
     this.dict = dict;
     this.type3Loaded = null;
     this.sent = false;
    }
    TranslatedFont.prototype = {
     send: function (handler) {
      if (this.sent) {
       return;
      }
      var fontData = this.font.exportData();
      handler.send('commonobj', [
       this.loadedName,
       'Font',
       fontData
      ]);
      this.sent = true;
     },
     loadType3Data: function (evaluator, resources, parentOperatorList, task) {
      assert(this.font.isType3Font);
      if (this.type3Loaded) {
       return this.type3Loaded;
      }
      var translatedFont = this.font;
      var loadCharProcsPromise = Promise.resolve();
      var charProcs = this.dict.get('CharProcs');
      var fontResources = this.dict.get('Resources') || resources;
      var charProcKeys = charProcs.getKeys();
      var charProcOperatorList = Object.create(null);
      for (var i = 0, n = charProcKeys.length; i < n; ++i) {
       loadCharProcsPromise = loadCharProcsPromise.then(function (key) {
        var glyphStream = charProcs.get(key);
        var operatorList = new OperatorList();
        return evaluator.getOperatorList(glyphStream, task, fontResources, operatorList).then(function () {
         charProcOperatorList[key] = operatorList.getIR();
         parentOperatorList.addDependencies(operatorList.dependencies);
        }, function (reason) {
         warn('Type3 font resource \"' + key + '\" is not available');
         var operatorList = new OperatorList();
         charProcOperatorList[key] = operatorList.getIR();
        });
       }.bind(this, charProcKeys[i]));
      }
      this.type3Loaded = loadCharProcsPromise.then(function () {
       translatedFont.charProcOperatorList = charProcOperatorList;
      });
      return this.type3Loaded;
     }
    };
    return TranslatedFont;
   }();
   var OperatorList = function OperatorListClosure() {
    var CHUNK_SIZE = 1000;
    var CHUNK_SIZE_ABOUT = CHUNK_SIZE - 5;
    function getTransfers(queue) {
     var transfers = [];
     var fnArray = queue.fnArray, argsArray = queue.argsArray;
     for (var i = 0, ii = queue.length; i < ii; i++) {
      switch (fnArray[i]) {
      case OPS.paintInlineImageXObject:
      case OPS.paintInlineImageXObjectGroup:
      case OPS.paintImageMaskXObject:
       var arg = argsArray[i][0];
       if (!arg.cached) {
        transfers.push(arg.data.buffer);
       }
       break;
      }
     }
     return transfers;
    }
    function OperatorList(intent, messageHandler, pageIndex) {
     this.messageHandler = messageHandler;
     this.fnArray = [];
     this.argsArray = [];
     this.dependencies = Object.create(null);
     this._totalLength = 0;
     this.pageIndex = pageIndex;
     this.intent = intent;
    }
    OperatorList.prototype = {
     get length() {
      return this.argsArray.length;
     },
     get totalLength() {
      return this._totalLength + this.length;
     },
     addOp: function (fn, args) {
      this.fnArray.push(fn);
      this.argsArray.push(args);
      if (this.messageHandler) {
       if (this.fnArray.length >= CHUNK_SIZE) {
        this.flush();
       } else if (this.fnArray.length >= CHUNK_SIZE_ABOUT && (fn === OPS.restore || fn === OPS.endText)) {
        this.flush();
       }
      }
     },
     addDependency: function (dependency) {
      if (dependency in this.dependencies) {
       return;
      }
      this.dependencies[dependency] = true;
      this.addOp(OPS.dependency, [dependency]);
     },
     addDependencies: function (dependencies) {
      for (var key in dependencies) {
       this.addDependency(key);
      }
     },
     addOpList: function (opList) {
      Util.extendObj(this.dependencies, opList.dependencies);
      for (var i = 0, ii = opList.length; i < ii; i++) {
       this.addOp(opList.fnArray[i], opList.argsArray[i]);
      }
     },
     getIR: function () {
      return {
       fnArray: this.fnArray,
       argsArray: this.argsArray,
       length: this.length
      };
     },
     flush: function (lastChunk) {
      if (this.intent !== 'oplist') {
       new QueueOptimizer().optimize(this);
      }
      var transfers = getTransfers(this);
      var length = this.length;
      this._totalLength += length;
      this.messageHandler.send('RenderPageChunk', {
       operatorList: {
        fnArray: this.fnArray,
        argsArray: this.argsArray,
        lastChunk: lastChunk,
        length: length
       },
       pageIndex: this.pageIndex,
       intent: this.intent
      }, transfers);
      this.dependencies = Object.create(null);
      this.fnArray.length = 0;
      this.argsArray.length = 0;
     }
    };
    return OperatorList;
   }();
   var StateManager = function StateManagerClosure() {
    function StateManager(initialState) {
     this.state = initialState;
     this.stateStack = [];
    }
    StateManager.prototype = {
     save: function () {
      var old = this.state;
      this.stateStack.push(this.state);
      this.state = old.clone();
     },
     restore: function () {
      var prev = this.stateStack.pop();
      if (prev) {
       this.state = prev;
      }
     },
     transform: function (args) {
      this.state.ctm = Util.transform(this.state.ctm, args);
     }
    };
    return StateManager;
   }();
   var TextState = function TextStateClosure() {
    function TextState() {
     this.ctm = new Float32Array(IDENTITY_MATRIX);
     this.fontName = null;
     this.fontSize = 0;
     this.font = null;
     this.fontMatrix = FONT_IDENTITY_MATRIX;
     this.textMatrix = IDENTITY_MATRIX.slice();
     this.textLineMatrix = IDENTITY_MATRIX.slice();
     this.charSpacing = 0;
     this.wordSpacing = 0;
     this.leading = 0;
     this.textHScale = 1;
     this.textRise = 0;
    }
    TextState.prototype = {
     setTextMatrix: function TextState_setTextMatrix(a, b, c, d, e, f) {
      var m = this.textMatrix;
      m[0] = a;
      m[1] = b;
      m[2] = c;
      m[3] = d;
      m[4] = e;
      m[5] = f;
     },
     setTextLineMatrix: function TextState_setTextMatrix(a, b, c, d, e, f) {
      var m = this.textLineMatrix;
      m[0] = a;
      m[1] = b;
      m[2] = c;
      m[3] = d;
      m[4] = e;
      m[5] = f;
     },
     translateTextMatrix: function TextState_translateTextMatrix(x, y) {
      var m = this.textMatrix;
      m[4] = m[0] * x + m[2] * y + m[4];
      m[5] = m[1] * x + m[3] * y + m[5];
     },
     translateTextLineMatrix: function TextState_translateTextMatrix(x, y) {
      var m = this.textLineMatrix;
      m[4] = m[0] * x + m[2] * y + m[4];
      m[5] = m[1] * x + m[3] * y + m[5];
     },
     calcTextLineMatrixAdvance: function TextState_calcTextLineMatrixAdvance(a, b, c, d, e, f) {
      var font = this.font;
      if (!font) {
       return null;
      }
      var m = this.textLineMatrix;
      if (!(a === m[0] && b === m[1] && c === m[2] && d === m[3])) {
       return null;
      }
      var txDiff = e - m[4], tyDiff = f - m[5];
      if (font.vertical && txDiff !== 0 || !font.vertical && tyDiff !== 0) {
       return null;
      }
      var tx, ty, denominator = a * d - b * c;
      if (font.vertical) {
       tx = -tyDiff * c / denominator;
       ty = tyDiff * a / denominator;
      } else {
       tx = txDiff * d / denominator;
       ty = -txDiff * b / denominator;
      }
      return {
       width: tx,
       height: ty,
       value: font.vertical ? ty : tx
      };
     },
     calcRenderMatrix: function TextState_calcRendeMatrix(ctm) {
      var tsm = [
       this.fontSize * this.textHScale,
       0,
       0,
       this.fontSize,
       0,
       this.textRise
      ];
      return Util.transform(ctm, Util.transform(this.textMatrix, tsm));
     },
     carriageReturn: function TextState_carriageReturn() {
      this.translateTextLineMatrix(0, -this.leading);
      this.textMatrix = this.textLineMatrix.slice();
     },
     clone: function TextState_clone() {
      var clone = Object.create(this);
      clone.textMatrix = this.textMatrix.slice();
      clone.textLineMatrix = this.textLineMatrix.slice();
      clone.fontMatrix = this.fontMatrix.slice();
      return clone;
     }
    };
    return TextState;
   }();
   var EvalState = function EvalStateClosure() {
    function EvalState() {
     this.ctm = new Float32Array(IDENTITY_MATRIX);
     this.font = null;
     this.textRenderingMode = TextRenderingMode.FILL;
     this.fillColorSpace = ColorSpace.singletons.gray;
     this.strokeColorSpace = ColorSpace.singletons.gray;
    }
    EvalState.prototype = {
     clone: function CanvasExtraState_clone() {
      return Object.create(this);
     }
    };
    return EvalState;
   }();
   var EvaluatorPreprocessor = function EvaluatorPreprocessorClosure() {
    var getOPMap = getLookupTableFactory(function (t) {
     t['w'] = {
      id: OPS.setLineWidth,
      numArgs: 1,
      variableArgs: false
     };
     t['J'] = {
      id: OPS.setLineCap,
      numArgs: 1,
      variableArgs: false
     };
     t['j'] = {
      id: OPS.setLineJoin,
      numArgs: 1,
      variableArgs: false
     };
     t['M'] = {
      id: OPS.setMiterLimit,
      numArgs: 1,
      variableArgs: false
     };
     t['d'] = {
      id: OPS.setDash,
      numArgs: 2,
      variableArgs: false
     };
     t['ri'] = {
      id: OPS.setRenderingIntent,
      numArgs: 1,
      variableArgs: false
     };
     t['i'] = {
      id: OPS.setFlatness,
      numArgs: 1,
      variableArgs: false
     };
     t['gs'] = {
      id: OPS.setGState,
      numArgs: 1,
      variableArgs: false
     };
     t['q'] = {
      id: OPS.save,
      numArgs: 0,
      variableArgs: false
     };
     t['Q'] = {
      id: OPS.restore,
      numArgs: 0,
      variableArgs: false
     };
     t['cm'] = {
      id: OPS.transform,
      numArgs: 6,
      variableArgs: false
     };
     t['m'] = {
      id: OPS.moveTo,
      numArgs: 2,
      variableArgs: false
     };
     t['l'] = {
      id: OPS.lineTo,
      numArgs: 2,
      variableArgs: false
     };
     t['c'] = {
      id: OPS.curveTo,
      numArgs: 6,
      variableArgs: false
     };
     t['v'] = {
      id: OPS.curveTo2,
      numArgs: 4,
      variableArgs: false
     };
     t['y'] = {
      id: OPS.curveTo3,
      numArgs: 4,
      variableArgs: false
     };
     t['h'] = {
      id: OPS.closePath,
      numArgs: 0,
      variableArgs: false
     };
     t['re'] = {
      id: OPS.rectangle,
      numArgs: 4,
      variableArgs: false
     };
     t['S'] = {
      id: OPS.stroke,
      numArgs: 0,
      variableArgs: false
     };
     t['s'] = {
      id: OPS.closeStroke,
      numArgs: 0,
      variableArgs: false
     };
     t['f'] = {
      id: OPS.fill,
      numArgs: 0,
      variableArgs: false
     };
     t['F'] = {
      id: OPS.fill,
      numArgs: 0,
      variableArgs: false
     };
     t['f*'] = {
      id: OPS.eoFill,
      numArgs: 0,
      variableArgs: false
     };
     t['B'] = {
      id: OPS.fillStroke,
      numArgs: 0,
      variableArgs: false
     };
     t['B*'] = {
      id: OPS.eoFillStroke,
      numArgs: 0,
      variableArgs: false
     };
     t['b'] = {
      id: OPS.closeFillStroke,
      numArgs: 0,
      variableArgs: false
     };
     t['b*'] = {
      id: OPS.closeEOFillStroke,
      numArgs: 0,
      variableArgs: false
     };
     t['n'] = {
      id: OPS.endPath,
      numArgs: 0,
      variableArgs: false
     };
     t['W'] = {
      id: OPS.clip,
      numArgs: 0,
      variableArgs: false
     };
     t['W*'] = {
      id: OPS.eoClip,
      numArgs: 0,
      variableArgs: false
     };
     t['BT'] = {
      id: OPS.beginText,
      numArgs: 0,
      variableArgs: false
     };
     t['ET'] = {
      id: OPS.endText,
      numArgs: 0,
      variableArgs: false
     };
     t['Tc'] = {
      id: OPS.setCharSpacing,
      numArgs: 1,
      variableArgs: false
     };
     t['Tw'] = {
      id: OPS.setWordSpacing,
      numArgs: 1,
      variableArgs: false
     };
     t['Tz'] = {
      id: OPS.setHScale,
      numArgs: 1,
      variableArgs: false
     };
     t['TL'] = {
      id: OPS.setLeading,
      numArgs: 1,
      variableArgs: false
     };
     t['Tf'] = {
      id: OPS.setFont,
      numArgs: 2,
      variableArgs: false
     };
     t['Tr'] = {
      id: OPS.setTextRenderingMode,
      numArgs: 1,
      variableArgs: false
     };
     t['Ts'] = {
      id: OPS.setTextRise,
      numArgs: 1,
      variableArgs: false
     };
     t['Td'] = {
      id: OPS.moveText,
      numArgs: 2,
      variableArgs: false
     };
     t['TD'] = {
      id: OPS.setLeadingMoveText,
      numArgs: 2,
      variableArgs: false
     };
     t['Tm'] = {
      id: OPS.setTextMatrix,
      numArgs: 6,
      variableArgs: false
     };
     t['T*'] = {
      id: OPS.nextLine,
      numArgs: 0,
      variableArgs: false
     };
     t['Tj'] = {
      id: OPS.showText,
      numArgs: 1,
      variableArgs: false
     };
     t['TJ'] = {
      id: OPS.showSpacedText,
      numArgs: 1,
      variableArgs: false
     };
     t['\''] = {
      id: OPS.nextLineShowText,
      numArgs: 1,
      variableArgs: false
     };
     t['"'] = {
      id: OPS.nextLineSetSpacingShowText,
      numArgs: 3,
      variableArgs: false
     };
     t['d0'] = {
      id: OPS.setCharWidth,
      numArgs: 2,
      variableArgs: false
     };
     t['d1'] = {
      id: OPS.setCharWidthAndBounds,
      numArgs: 6,
      variableArgs: false
     };
     t['CS'] = {
      id: OPS.setStrokeColorSpace,
      numArgs: 1,
      variableArgs: false
     };
     t['cs'] = {
      id: OPS.setFillColorSpace,
      numArgs: 1,
      variableArgs: false
     };
     t['SC'] = {
      id: OPS.setStrokeColor,
      numArgs: 4,
      variableArgs: true
     };
     t['SCN'] = {
      id: OPS.setStrokeColorN,
      numArgs: 33,
      variableArgs: true
     };
     t['sc'] = {
      id: OPS.setFillColor,
      numArgs: 4,
      variableArgs: true
     };
     t['scn'] = {
      id: OPS.setFillColorN,
      numArgs: 33,
      variableArgs: true
     };
     t['G'] = {
      id: OPS.setStrokeGray,
      numArgs: 1,
      variableArgs: false
     };
     t['g'] = {
      id: OPS.setFillGray,
      numArgs: 1,
      variableArgs: false
     };
     t['RG'] = {
      id: OPS.setStrokeRGBColor,
      numArgs: 3,
      variableArgs: false
     };
     t['rg'] = {
      id: OPS.setFillRGBColor,
      numArgs: 3,
      variableArgs: false
     };
     t['K'] = {
      id: OPS.setStrokeCMYKColor,
      numArgs: 4,
      variableArgs: false
     };
     t['k'] = {
      id: OPS.setFillCMYKColor,
      numArgs: 4,
      variableArgs: false
     };
     t['sh'] = {
      id: OPS.shadingFill,
      numArgs: 1,
      variableArgs: false
     };
     t['BI'] = {
      id: OPS.beginInlineImage,
      numArgs: 0,
      variableArgs: false
     };
     t['ID'] = {
      id: OPS.beginImageData,
      numArgs: 0,
      variableArgs: false
     };
     t['EI'] = {
      id: OPS.endInlineImage,
      numArgs: 1,
      variableArgs: false
     };
     t['Do'] = {
      id: OPS.paintXObject,
      numArgs: 1,
      variableArgs: false
     };
     t['MP'] = {
      id: OPS.markPoint,
      numArgs: 1,
      variableArgs: false
     };
     t['DP'] = {
      id: OPS.markPointProps,
      numArgs: 2,
      variableArgs: false
     };
     t['BMC'] = {
      id: OPS.beginMarkedContent,
      numArgs: 1,
      variableArgs: false
     };
     t['BDC'] = {
      id: OPS.beginMarkedContentProps,
      numArgs: 2,
      variableArgs: false
     };
     t['EMC'] = {
      id: OPS.endMarkedContent,
      numArgs: 0,
      variableArgs: false
     };
     t['BX'] = {
      id: OPS.beginCompat,
      numArgs: 0,
      variableArgs: false
     };
     t['EX'] = {
      id: OPS.endCompat,
      numArgs: 0,
      variableArgs: false
     };
     t['BM'] = null;
     t['BD'] = null;
     t['true'] = null;
     t['fa'] = null;
     t['fal'] = null;
     t['fals'] = null;
     t['false'] = null;
     t['nu'] = null;
     t['nul'] = null;
     t['null'] = null;
    });
    function EvaluatorPreprocessor(stream, xref, stateManager) {
     this.opMap = getOPMap();
     this.parser = new Parser(new Lexer(stream, this.opMap), false, xref);
     this.stateManager = stateManager;
     this.nonProcessedArgs = [];
    }
    EvaluatorPreprocessor.prototype = {
     get savedStatesDepth() {
      return this.stateManager.stateStack.length;
     },
     read: function EvaluatorPreprocessor_read(operation) {
      var args = operation.args;
      while (true) {
       var obj = this.parser.getObj();
       if (isCmd(obj)) {
        var cmd = obj.cmd;
        var opSpec = this.opMap[cmd];
        if (!opSpec) {
         warn('Unknown command "' + cmd + '"');
         continue;
        }
        var fn = opSpec.id;
        var numArgs = opSpec.numArgs;
        var argsLength = args !== null ? args.length : 0;
        if (!opSpec.variableArgs) {
         if (argsLength !== numArgs) {
          var nonProcessedArgs = this.nonProcessedArgs;
          while (argsLength > numArgs) {
           nonProcessedArgs.push(args.shift());
           argsLength--;
          }
          while (argsLength < numArgs && nonProcessedArgs.length !== 0) {
           if (args === null) {
            args = [];
           }
           args.unshift(nonProcessedArgs.pop());
           argsLength++;
          }
         }
         if (argsLength < numArgs) {
          warn('Skipping command ' + fn + ': expected ' + numArgs + ' args, but received ' + argsLength + ' args.');
          if (args !== null) {
           args.length = 0;
          }
          continue;
         }
        } else if (argsLength > numArgs) {
         info('Command ' + fn + ': expected [0,' + numArgs + '] args, but received ' + argsLength + ' args.');
        }
        this.preprocessCommand(fn, args);
        operation.fn = fn;
        operation.args = args;
        return true;
       } else {
        if (isEOF(obj)) {
         return false;
        }
        if (obj !== null) {
         if (args === null) {
          args = [];
         }
         args.push(obj);
         assert(args.length <= 33, 'Too many arguments');
        }
       }
      }
     },
     preprocessCommand: function EvaluatorPreprocessor_preprocessCommand(fn, args) {
      switch (fn | 0) {
      case OPS.save:
       this.stateManager.save();
       break;
      case OPS.restore:
       this.stateManager.restore();
       break;
      case OPS.transform:
       this.stateManager.transform(args);
       break;
      }
     }
    };
    return EvaluatorPreprocessor;
   }();
   var QueueOptimizer = function QueueOptimizerClosure() {
    function addState(parentState, pattern, fn) {
     var state = parentState;
     for (var i = 0, ii = pattern.length - 1; i < ii; i++) {
      var item = pattern[i];
      state = state[item] || (state[item] = []);
     }
     state[pattern[pattern.length - 1]] = fn;
    }
    function handlePaintSolidColorImageMask(iFirstSave, count, fnArray, argsArray) {
     var iFirstPIMXO = iFirstSave + 2;
     for (var i = 0; i < count; i++) {
      var arg = argsArray[iFirstPIMXO + 4 * i];
      var imageMask = arg.length === 1 && arg[0];
      if (imageMask && imageMask.width === 1 && imageMask.height === 1 && (!imageMask.data.length || imageMask.data.length === 1 && imageMask.data[0] === 0)) {
       fnArray[iFirstPIMXO + 4 * i] = OPS.paintSolidColorImageMask;
       continue;
      }
      break;
     }
     return count - i;
    }
    var InitialState = [];
    addState(InitialState, [
     OPS.save,
     OPS.transform,
     OPS.paintInlineImageXObject,
     OPS.restore
    ], function foundInlineImageGroup(context) {
     var MIN_IMAGES_IN_INLINE_IMAGES_BLOCK = 10;
     var MAX_IMAGES_IN_INLINE_IMAGES_BLOCK = 200;
     var MAX_WIDTH = 1000;
     var IMAGE_PADDING = 1;
     var fnArray = context.fnArray, argsArray = context.argsArray;
     var curr = context.iCurr;
     var iFirstSave = curr - 3;
     var iFirstTransform = curr - 2;
     var iFirstPIIXO = curr - 1;
     var i = iFirstSave + 4;
     var ii = fnArray.length;
     while (i + 3 < ii) {
      if (fnArray[i] !== OPS.save || fnArray[i + 1] !== OPS.transform || fnArray[i + 2] !== OPS.paintInlineImageXObject || fnArray[i + 3] !== OPS.restore) {
       break;
      }
      i += 4;
     }
     var count = Math.min((i - iFirstSave) / 4, MAX_IMAGES_IN_INLINE_IMAGES_BLOCK);
     if (count < MIN_IMAGES_IN_INLINE_IMAGES_BLOCK) {
      return i;
     }
     var maxX = 0;
     var map = [], maxLineHeight = 0;
     var currentX = IMAGE_PADDING, currentY = IMAGE_PADDING;
     var q;
     for (q = 0; q < count; q++) {
      var transform = argsArray[iFirstTransform + (q << 2)];
      var img = argsArray[iFirstPIIXO + (q << 2)][0];
      if (currentX + img.width > MAX_WIDTH) {
       maxX = Math.max(maxX, currentX);
       currentY += maxLineHeight + 2 * IMAGE_PADDING;
       currentX = 0;
       maxLineHeight = 0;
      }
      map.push({
       transform: transform,
       x: currentX,
       y: currentY,
       w: img.width,
       h: img.height
      });
      currentX += img.width + 2 * IMAGE_PADDING;
      maxLineHeight = Math.max(maxLineHeight, img.height);
     }
     var imgWidth = Math.max(maxX, currentX) + IMAGE_PADDING;
     var imgHeight = currentY + maxLineHeight + IMAGE_PADDING;
     var imgData = new Uint8Array(imgWidth * imgHeight * 4);
     var imgRowSize = imgWidth << 2;
     for (q = 0; q < count; q++) {
      var data = argsArray[iFirstPIIXO + (q << 2)][0].data;
      var rowSize = map[q].w << 2;
      var dataOffset = 0;
      var offset = map[q].x + map[q].y * imgWidth << 2;
      imgData.set(data.subarray(0, rowSize), offset - imgRowSize);
      for (var k = 0, kk = map[q].h; k < kk; k++) {
       imgData.set(data.subarray(dataOffset, dataOffset + rowSize), offset);
       dataOffset += rowSize;
       offset += imgRowSize;
      }
      imgData.set(data.subarray(dataOffset - rowSize, dataOffset), offset);
      while (offset >= 0) {
       data[offset - 4] = data[offset];
       data[offset - 3] = data[offset + 1];
       data[offset - 2] = data[offset + 2];
       data[offset - 1] = data[offset + 3];
       data[offset + rowSize] = data[offset + rowSize - 4];
       data[offset + rowSize + 1] = data[offset + rowSize - 3];
       data[offset + rowSize + 2] = data[offset + rowSize - 2];
       data[offset + rowSize + 3] = data[offset + rowSize - 1];
       offset -= imgRowSize;
      }
     }
     fnArray.splice(iFirstSave, count * 4, OPS.paintInlineImageXObjectGroup);
     argsArray.splice(iFirstSave, count * 4, [
      {
       width: imgWidth,
       height: imgHeight,
       kind: ImageKind.RGBA_32BPP,
       data: imgData
      },
      map
     ]);
     return iFirstSave + 1;
    });
    addState(InitialState, [
     OPS.save,
     OPS.transform,
     OPS.paintImageMaskXObject,
     OPS.restore
    ], function foundImageMaskGroup(context) {
     var MIN_IMAGES_IN_MASKS_BLOCK = 10;
     var MAX_IMAGES_IN_MASKS_BLOCK = 100;
     var MAX_SAME_IMAGES_IN_MASKS_BLOCK = 1000;
     var fnArray = context.fnArray, argsArray = context.argsArray;
     var curr = context.iCurr;
     var iFirstSave = curr - 3;
     var iFirstTransform = curr - 2;
     var iFirstPIMXO = curr - 1;
     var i = iFirstSave + 4;
     var ii = fnArray.length;
     while (i + 3 < ii) {
      if (fnArray[i] !== OPS.save || fnArray[i + 1] !== OPS.transform || fnArray[i + 2] !== OPS.paintImageMaskXObject || fnArray[i + 3] !== OPS.restore) {
       break;
      }
      i += 4;
     }
     var count = (i - iFirstSave) / 4;
     count = handlePaintSolidColorImageMask(iFirstSave, count, fnArray, argsArray);
     if (count < MIN_IMAGES_IN_MASKS_BLOCK) {
      return i;
     }
     var q;
     var isSameImage = false;
     var iTransform, transformArgs;
     var firstPIMXOArg0 = argsArray[iFirstPIMXO][0];
     if (argsArray[iFirstTransform][1] === 0 && argsArray[iFirstTransform][2] === 0) {
      isSameImage = true;
      var firstTransformArg0 = argsArray[iFirstTransform][0];
      var firstTransformArg3 = argsArray[iFirstTransform][3];
      iTransform = iFirstTransform + 4;
      var iPIMXO = iFirstPIMXO + 4;
      for (q = 1; q < count; q++, iTransform += 4, iPIMXO += 4) {
       transformArgs = argsArray[iTransform];
       if (argsArray[iPIMXO][0] !== firstPIMXOArg0 || transformArgs[0] !== firstTransformArg0 || transformArgs[1] !== 0 || transformArgs[2] !== 0 || transformArgs[3] !== firstTransformArg3) {
        if (q < MIN_IMAGES_IN_MASKS_BLOCK) {
         isSameImage = false;
        } else {
         count = q;
        }
        break;
       }
      }
     }
     if (isSameImage) {
      count = Math.min(count, MAX_SAME_IMAGES_IN_MASKS_BLOCK);
      var positions = new Float32Array(count * 2);
      iTransform = iFirstTransform;
      for (q = 0; q < count; q++, iTransform += 4) {
       transformArgs = argsArray[iTransform];
       positions[q << 1] = transformArgs[4];
       positions[(q << 1) + 1] = transformArgs[5];
      }
      fnArray.splice(iFirstSave, count * 4, OPS.paintImageMaskXObjectRepeat);
      argsArray.splice(iFirstSave, count * 4, [
       firstPIMXOArg0,
       firstTransformArg0,
       firstTransformArg3,
       positions
      ]);
     } else {
      count = Math.min(count, MAX_IMAGES_IN_MASKS_BLOCK);
      var images = [];
      for (q = 0; q < count; q++) {
       transformArgs = argsArray[iFirstTransform + (q << 2)];
       var maskParams = argsArray[iFirstPIMXO + (q << 2)][0];
       images.push({
        data: maskParams.data,
        width: maskParams.width,
        height: maskParams.height,
        transform: transformArgs
       });
      }
      fnArray.splice(iFirstSave, count * 4, OPS.paintImageMaskXObjectGroup);
      argsArray.splice(iFirstSave, count * 4, [images]);
     }
     return iFirstSave + 1;
    });
    addState(InitialState, [
     OPS.save,
     OPS.transform,
     OPS.paintImageXObject,
     OPS.restore
    ], function (context) {
     var MIN_IMAGES_IN_BLOCK = 3;
     var MAX_IMAGES_IN_BLOCK = 1000;
     var fnArray = context.fnArray, argsArray = context.argsArray;
     var curr = context.iCurr;
     var iFirstSave = curr - 3;
     var iFirstTransform = curr - 2;
     var iFirstPIXO = curr - 1;
     var iFirstRestore = curr;
     if (argsArray[iFirstTransform][1] !== 0 || argsArray[iFirstTransform][2] !== 0) {
      return iFirstRestore + 1;
     }
     var firstPIXOArg0 = argsArray[iFirstPIXO][0];
     var firstTransformArg0 = argsArray[iFirstTransform][0];
     var firstTransformArg3 = argsArray[iFirstTransform][3];
     var i = iFirstSave + 4;
     var ii = fnArray.length;
     while (i + 3 < ii) {
      if (fnArray[i] !== OPS.save || fnArray[i + 1] !== OPS.transform || fnArray[i + 2] !== OPS.paintImageXObject || fnArray[i + 3] !== OPS.restore) {
       break;
      }
      if (argsArray[i + 1][0] !== firstTransformArg0 || argsArray[i + 1][1] !== 0 || argsArray[i + 1][2] !== 0 || argsArray[i + 1][3] !== firstTransformArg3) {
       break;
      }
      if (argsArray[i + 2][0] !== firstPIXOArg0) {
       break;
      }
      i += 4;
     }
     var count = Math.min((i - iFirstSave) / 4, MAX_IMAGES_IN_BLOCK);
     if (count < MIN_IMAGES_IN_BLOCK) {
      return i;
     }
     var positions = new Float32Array(count * 2);
     var iTransform = iFirstTransform;
     for (var q = 0; q < count; q++, iTransform += 4) {
      var transformArgs = argsArray[iTransform];
      positions[q << 1] = transformArgs[4];
      positions[(q << 1) + 1] = transformArgs[5];
     }
     var args = [
      firstPIXOArg0,
      firstTransformArg0,
      firstTransformArg3,
      positions
     ];
     fnArray.splice(iFirstSave, count * 4, OPS.paintImageXObjectRepeat);
     argsArray.splice(iFirstSave, count * 4, args);
     return iFirstSave + 1;
    });
    addState(InitialState, [
     OPS.beginText,
     OPS.setFont,
     OPS.setTextMatrix,
     OPS.showText,
     OPS.endText
    ], function (context) {
     var MIN_CHARS_IN_BLOCK = 3;
     var MAX_CHARS_IN_BLOCK = 1000;
     var fnArray = context.fnArray, argsArray = context.argsArray;
     var curr = context.iCurr;
     var iFirstBeginText = curr - 4;
     var iFirstSetFont = curr - 3;
     var iFirstSetTextMatrix = curr - 2;
     var iFirstShowText = curr - 1;
     var iFirstEndText = curr;
     var firstSetFontArg0 = argsArray[iFirstSetFont][0];
     var firstSetFontArg1 = argsArray[iFirstSetFont][1];
     var i = iFirstBeginText + 5;
     var ii = fnArray.length;
     while (i + 4 < ii) {
      if (fnArray[i] !== OPS.beginText || fnArray[i + 1] !== OPS.setFont || fnArray[i + 2] !== OPS.setTextMatrix || fnArray[i + 3] !== OPS.showText || fnArray[i + 4] !== OPS.endText) {
       break;
      }
      if (argsArray[i + 1][0] !== firstSetFontArg0 || argsArray[i + 1][1] !== firstSetFontArg1) {
       break;
      }
      i += 5;
     }
     var count = Math.min((i - iFirstBeginText) / 5, MAX_CHARS_IN_BLOCK);
     if (count < MIN_CHARS_IN_BLOCK) {
      return i;
     }
     var iFirst = iFirstBeginText;
     if (iFirstBeginText >= 4 && fnArray[iFirstBeginText - 4] === fnArray[iFirstSetFont] && fnArray[iFirstBeginText - 3] === fnArray[iFirstSetTextMatrix] && fnArray[iFirstBeginText - 2] === fnArray[iFirstShowText] && fnArray[iFirstBeginText - 1] === fnArray[iFirstEndText] && argsArray[iFirstBeginText - 4][0] === firstSetFontArg0 && argsArray[iFirstBeginText - 4][1] === firstSetFontArg1) {
      count++;
      iFirst -= 5;
     }
     var iEndText = iFirst + 4;
     for (var q = 1; q < count; q++) {
      fnArray.splice(iEndText, 3);
      argsArray.splice(iEndText, 3);
      iEndText += 2;
     }
     return iEndText + 1;
    });
    function QueueOptimizer() {
    }
    QueueOptimizer.prototype = {
     optimize: function QueueOptimizer_optimize(queue) {
      var fnArray = queue.fnArray, argsArray = queue.argsArray;
      var context = {
       iCurr: 0,
       fnArray: fnArray,
       argsArray: argsArray
      };
      var state;
      var i = 0, ii = fnArray.length;
      while (i < ii) {
       state = (state || InitialState)[fnArray[i]];
       if (typeof state === 'function') {
        context.iCurr = i;
        i = state(context);
        state = undefined;
        ii = context.fnArray.length;
       } else {
        i++;
       }
      }
     }
    };
    return QueueOptimizer;
   }();
   exports.OperatorList = OperatorList;
   exports.PartialEvaluator = PartialEvaluator;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreAnnotation = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreStream, root.pdfjsCoreColorSpace, root.pdfjsCoreObj, root.pdfjsCoreEvaluator);
  }(this, function (exports, sharedUtil, corePrimitives, coreStream, coreColorSpace, coreObj, coreEvaluator) {
   var AnnotationBorderStyleType = sharedUtil.AnnotationBorderStyleType;
   var AnnotationFieldFlag = sharedUtil.AnnotationFieldFlag;
   var AnnotationFlag = sharedUtil.AnnotationFlag;
   var AnnotationType = sharedUtil.AnnotationType;
   var OPS = sharedUtil.OPS;
   var Util = sharedUtil.Util;
   var isString = sharedUtil.isString;
   var isArray = sharedUtil.isArray;
   var isInt = sharedUtil.isInt;
   var stringToBytes = sharedUtil.stringToBytes;
   var stringToPDFString = sharedUtil.stringToPDFString;
   var warn = sharedUtil.warn;
   var Dict = corePrimitives.Dict;
   var isDict = corePrimitives.isDict;
   var isName = corePrimitives.isName;
   var isRef = corePrimitives.isRef;
   var Stream = coreStream.Stream;
   var ColorSpace = coreColorSpace.ColorSpace;
   var Catalog = coreObj.Catalog;
   var ObjectLoader = coreObj.ObjectLoader;
   var FileSpec = coreObj.FileSpec;
   var OperatorList = coreEvaluator.OperatorList;
   function AnnotationFactory() {
   }
   AnnotationFactory.prototype = {
    create: function AnnotationFactory_create(xref, ref, pdfManager, uniquePrefix, idCounters) {
     var dict = xref.fetchIfRef(ref);
     if (!isDict(dict)) {
      return;
     }
     var id = isRef(ref) ? ref.toString() : 'annot_' + (uniquePrefix || '') + ++idCounters.obj;
     var subtype = dict.get('Subtype');
     subtype = isName(subtype) ? subtype.name : null;
     var parameters = {
      xref: xref,
      dict: dict,
      ref: isRef(ref) ? ref : null,
      subtype: subtype,
      id: id,
      pdfManager: pdfManager
     };
     switch (subtype) {
     case 'Link':
      return new LinkAnnotation(parameters);
     case 'Text':
      return new TextAnnotation(parameters);
     case 'Widget':
      var fieldType = Util.getInheritableProperty(dict, 'FT');
      fieldType = isName(fieldType) ? fieldType.name : null;
      switch (fieldType) {
      case 'Tx':
       return new TextWidgetAnnotation(parameters);
      case 'Btn':
       return new ButtonWidgetAnnotation(parameters);
      case 'Ch':
       return new ChoiceWidgetAnnotation(parameters);
      }
      warn('Unimplemented widget field type "' + fieldType + '", ' + 'falling back to base field type.');
      return new WidgetAnnotation(parameters);
     case 'Popup':
      return new PopupAnnotation(parameters);
     case 'Highlight':
      return new HighlightAnnotation(parameters);
     case 'Underline':
      return new UnderlineAnnotation(parameters);
     case 'Squiggly':
      return new SquigglyAnnotation(parameters);
     case 'StrikeOut':
      return new StrikeOutAnnotation(parameters);
     case 'FileAttachment':
      return new FileAttachmentAnnotation(parameters);
     default:
      if (!subtype) {
       warn('Annotation is missing the required /Subtype.');
      } else {
       warn('Unimplemented annotation type "' + subtype + '", ' + 'falling back to base annotation.');
      }
      return new Annotation(parameters);
     }
    }
   };
   var Annotation = function AnnotationClosure() {
    function getTransformMatrix(rect, bbox, matrix) {
     var bounds = Util.getAxialAlignedBoundingBox(bbox, matrix);
     var minX = bounds[0];
     var minY = bounds[1];
     var maxX = bounds[2];
     var maxY = bounds[3];
     if (minX === maxX || minY === maxY) {
      return [
       1,
       0,
       0,
       1,
       rect[0],
       rect[1]
      ];
     }
     var xRatio = (rect[2] - rect[0]) / (maxX - minX);
     var yRatio = (rect[3] - rect[1]) / (maxY - minY);
     return [
      xRatio,
      0,
      0,
      yRatio,
      rect[0] - minX * xRatio,
      rect[1] - minY * yRatio
     ];
    }
    function getDefaultAppearance(dict) {
     var appearanceState = dict.get('AP');
     if (!isDict(appearanceState)) {
      return;
     }
     var appearance;
     var appearances = appearanceState.get('N');
     if (isDict(appearances)) {
      var as = dict.get('AS');
      if (as && appearances.has(as.name)) {
       appearance = appearances.get(as.name);
      }
     } else {
      appearance = appearances;
     }
     return appearance;
    }
    function Annotation(params) {
     var dict = params.dict;
     this.setFlags(dict.get('F'));
     this.setRectangle(dict.getArray('Rect'));
     this.setColor(dict.getArray('C'));
     this.setBorderStyle(dict);
     this.appearance = getDefaultAppearance(dict);
     this.data = {};
     this.data.id = params.id;
     this.data.subtype = params.subtype;
     this.data.annotationFlags = this.flags;
     this.data.rect = this.rectangle;
     this.data.color = this.color;
     this.data.borderStyle = this.borderStyle;
     this.data.hasAppearance = !!this.appearance;
    }
    Annotation.prototype = {
     _hasFlag: function Annotation_hasFlag(flags, flag) {
      return !!(flags & flag);
     },
     _isViewable: function Annotation_isViewable(flags) {
      return !this._hasFlag(flags, AnnotationFlag.INVISIBLE) && !this._hasFlag(flags, AnnotationFlag.HIDDEN) && !this._hasFlag(flags, AnnotationFlag.NOVIEW);
     },
     _isPrintable: function AnnotationFlag_isPrintable(flags) {
      return this._hasFlag(flags, AnnotationFlag.PRINT) && !this._hasFlag(flags, AnnotationFlag.INVISIBLE) && !this._hasFlag(flags, AnnotationFlag.HIDDEN);
     },
     get viewable() {
      if (this.flags === 0) {
       return true;
      }
      return this._isViewable(this.flags);
     },
     get printable() {
      if (this.flags === 0) {
       return false;
      }
      return this._isPrintable(this.flags);
     },
     setFlags: function Annotation_setFlags(flags) {
      this.flags = isInt(flags) && flags > 0 ? flags : 0;
     },
     hasFlag: function Annotation_hasFlag(flag) {
      return this._hasFlag(this.flags, flag);
     },
     setRectangle: function Annotation_setRectangle(rectangle) {
      if (isArray(rectangle) && rectangle.length === 4) {
       this.rectangle = Util.normalizeRect(rectangle);
      } else {
       this.rectangle = [
        0,
        0,
        0,
        0
       ];
      }
     },
     setColor: function Annotation_setColor(color) {
      var rgbColor = new Uint8Array(3);
      if (!isArray(color)) {
       this.color = rgbColor;
       return;
      }
      switch (color.length) {
      case 0:
       this.color = null;
       break;
      case 1:
       ColorSpace.singletons.gray.getRgbItem(color, 0, rgbColor, 0);
       this.color = rgbColor;
       break;
      case 3:
       ColorSpace.singletons.rgb.getRgbItem(color, 0, rgbColor, 0);
       this.color = rgbColor;
       break;
      case 4:
       ColorSpace.singletons.cmyk.getRgbItem(color, 0, rgbColor, 0);
       this.color = rgbColor;
       break;
      default:
       this.color = rgbColor;
       break;
      }
     },
     setBorderStyle: function Annotation_setBorderStyle(borderStyle) {
      this.borderStyle = new AnnotationBorderStyle();
      if (!isDict(borderStyle)) {
       return;
      }
      if (borderStyle.has('BS')) {
       var dict = borderStyle.get('BS');
       var dictType = dict.get('Type');
       if (!dictType || isName(dictType, 'Border')) {
        this.borderStyle.setWidth(dict.get('W'));
        this.borderStyle.setStyle(dict.get('S'));
        this.borderStyle.setDashArray(dict.getArray('D'));
       }
      } else if (borderStyle.has('Border')) {
       var array = borderStyle.getArray('Border');
       if (isArray(array) && array.length >= 3) {
        this.borderStyle.setHorizontalCornerRadius(array[0]);
        this.borderStyle.setVerticalCornerRadius(array[1]);
        this.borderStyle.setWidth(array[2]);
        if (array.length === 4) {
         this.borderStyle.setDashArray(array[3]);
        }
       }
      } else {
       this.borderStyle.setWidth(0);
      }
     },
     _preparePopup: function Annotation_preparePopup(dict) {
      if (!dict.has('C')) {
       this.data.color = null;
      }
      this.data.hasPopup = dict.has('Popup');
      this.data.title = stringToPDFString(dict.get('T') || '');
      this.data.contents = stringToPDFString(dict.get('Contents') || '');
     },
     loadResources: function Annotation_loadResources(keys) {
      return new Promise(function (resolve, reject) {
       this.appearance.dict.getAsync('Resources').then(function (resources) {
        if (!resources) {
         resolve();
         return;
        }
        var objectLoader = new ObjectLoader(resources.map, keys, resources.xref);
        objectLoader.load().then(function () {
         resolve(resources);
        }, reject);
       }, reject);
      }.bind(this));
     },
     getOperatorList: function Annotation_getOperatorList(evaluator, task, renderForms) {
      if (!this.appearance) {
       return Promise.resolve(new OperatorList());
      }
      var data = this.data;
      var appearanceDict = this.appearance.dict;
      var resourcesPromise = this.loadResources([
       'ExtGState',
       'ColorSpace',
       'Pattern',
       'Shading',
       'XObject',
       'Font'
      ]);
      var bbox = appearanceDict.getArray('BBox') || [
       0,
       0,
       1,
       1
      ];
      var matrix = appearanceDict.getArray('Matrix') || [
       1,
       0,
       0,
       1,
       0,
       0
      ];
      var transform = getTransformMatrix(data.rect, bbox, matrix);
      var self = this;
      return resourcesPromise.then(function (resources) {
       var opList = new OperatorList();
       opList.addOp(OPS.beginAnnotation, [
        data.rect,
        transform,
        matrix
       ]);
       return evaluator.getOperatorList(self.appearance, task, resources, opList).then(function () {
        opList.addOp(OPS.endAnnotation, []);
        self.appearance.reset();
        return opList;
       });
      });
     }
    };
    Annotation.appendToOperatorList = function Annotation_appendToOperatorList(annotations, opList, partialEvaluator, task, intent, renderForms) {
     var annotationPromises = [];
     for (var i = 0, n = annotations.length; i < n; ++i) {
      if (intent === 'display' && annotations[i].viewable || intent === 'print' && annotations[i].printable) {
       annotationPromises.push(annotations[i].getOperatorList(partialEvaluator, task, renderForms));
      }
     }
     return Promise.all(annotationPromises).then(function (operatorLists) {
      opList.addOp(OPS.beginAnnotations, []);
      for (var i = 0, n = operatorLists.length; i < n; ++i) {
       opList.addOpList(operatorLists[i]);
      }
      opList.addOp(OPS.endAnnotations, []);
     });
    };
    return Annotation;
   }();
   var AnnotationBorderStyle = function AnnotationBorderStyleClosure() {
    function AnnotationBorderStyle() {
     this.width = 1;
     this.style = AnnotationBorderStyleType.SOLID;
     this.dashArray = [3];
     this.horizontalCornerRadius = 0;
     this.verticalCornerRadius = 0;
    }
    AnnotationBorderStyle.prototype = {
     setWidth: function AnnotationBorderStyle_setWidth(width) {
      if (width === (width | 0)) {
       this.width = width;
      }
     },
     setStyle: function AnnotationBorderStyle_setStyle(style) {
      if (!style) {
       return;
      }
      switch (style.name) {
      case 'S':
       this.style = AnnotationBorderStyleType.SOLID;
       break;
      case 'D':
       this.style = AnnotationBorderStyleType.DASHED;
       break;
      case 'B':
       this.style = AnnotationBorderStyleType.BEVELED;
       break;
      case 'I':
       this.style = AnnotationBorderStyleType.INSET;
       break;
      case 'U':
       this.style = AnnotationBorderStyleType.UNDERLINE;
       break;
      default:
       break;
      }
     },
     setDashArray: function AnnotationBorderStyle_setDashArray(dashArray) {
      if (isArray(dashArray) && dashArray.length > 0) {
       var isValid = true;
       var allZeros = true;
       for (var i = 0, len = dashArray.length; i < len; i++) {
        var element = dashArray[i];
        var validNumber = +element >= 0;
        if (!validNumber) {
         isValid = false;
         break;
        } else if (element > 0) {
         allZeros = false;
        }
       }
       if (isValid && !allZeros) {
        this.dashArray = dashArray;
       } else {
        this.width = 0;
       }
      } else if (dashArray) {
       this.width = 0;
      }
     },
     setHorizontalCornerRadius: function AnnotationBorderStyle_setHorizontalCornerRadius(radius) {
      if (radius === (radius | 0)) {
       this.horizontalCornerRadius = radius;
      }
     },
     setVerticalCornerRadius: function AnnotationBorderStyle_setVerticalCornerRadius(radius) {
      if (radius === (radius | 0)) {
       this.verticalCornerRadius = radius;
      }
     }
    };
    return AnnotationBorderStyle;
   }();
   var WidgetAnnotation = function WidgetAnnotationClosure() {
    function WidgetAnnotation(params) {
     Annotation.call(this, params);
     var dict = params.dict;
     var data = this.data;
     data.annotationType = AnnotationType.WIDGET;
     data.fieldName = this._constructFieldName(dict);
     data.fieldValue = Util.getInheritableProperty(dict, 'V', true);
     data.alternativeText = stringToPDFString(dict.get('TU') || '');
     data.defaultAppearance = Util.getInheritableProperty(dict, 'DA') || '';
     var fieldType = Util.getInheritableProperty(dict, 'FT');
     data.fieldType = isName(fieldType) ? fieldType.name : null;
     this.fieldResources = Util.getInheritableProperty(dict, 'DR') || Dict.empty;
     data.fieldFlags = Util.getInheritableProperty(dict, 'Ff');
     if (!isInt(data.fieldFlags) || data.fieldFlags < 0) {
      data.fieldFlags = 0;
     }
     data.readOnly = this.hasFieldFlag(AnnotationFieldFlag.READONLY);
     if (data.fieldType === 'Sig') {
      this.setFlags(AnnotationFlag.HIDDEN);
     }
    }
    Util.inherit(WidgetAnnotation, Annotation, {
     _constructFieldName: function WidgetAnnotation_constructFieldName(dict) {
      if (!dict.has('T') && !dict.has('Parent')) {
       warn('Unknown field name, falling back to empty field name.');
       return '';
      }
      if (!dict.has('Parent')) {
       return stringToPDFString(dict.get('T'));
      }
      var fieldName = [];
      if (dict.has('T')) {
       fieldName.unshift(stringToPDFString(dict.get('T')));
      }
      var loopDict = dict;
      while (loopDict.has('Parent')) {
       loopDict = loopDict.get('Parent');
       if (loopDict.has('T')) {
        fieldName.unshift(stringToPDFString(loopDict.get('T')));
       }
      }
      return fieldName.join('.');
     },
     hasFieldFlag: function WidgetAnnotation_hasFieldFlag(flag) {
      return !!(this.data.fieldFlags & flag);
     }
    });
    return WidgetAnnotation;
   }();
   var TextWidgetAnnotation = function TextWidgetAnnotationClosure() {
    function TextWidgetAnnotation(params) {
     WidgetAnnotation.call(this, params);
     this.data.fieldValue = stringToPDFString(this.data.fieldValue || '');
     var alignment = Util.getInheritableProperty(params.dict, 'Q');
     if (!isInt(alignment) || alignment < 0 || alignment > 2) {
      alignment = null;
     }
     this.data.textAlignment = alignment;
     var maximumLength = Util.getInheritableProperty(params.dict, 'MaxLen');
     if (!isInt(maximumLength) || maximumLength < 0) {
      maximumLength = null;
     }
     this.data.maxLen = maximumLength;
     this.data.multiLine = this.hasFieldFlag(AnnotationFieldFlag.MULTILINE);
     this.data.comb = this.hasFieldFlag(AnnotationFieldFlag.COMB) && !this.hasFieldFlag(AnnotationFieldFlag.MULTILINE) && !this.hasFieldFlag(AnnotationFieldFlag.PASSWORD) && !this.hasFieldFlag(AnnotationFieldFlag.FILESELECT) && this.data.maxLen !== null;
    }
    Util.inherit(TextWidgetAnnotation, WidgetAnnotation, {
     getOperatorList: function TextWidgetAnnotation_getOperatorList(evaluator, task, renderForms) {
      var operatorList = new OperatorList();
      if (renderForms) {
       return Promise.resolve(operatorList);
      }
      if (this.appearance) {
       return Annotation.prototype.getOperatorList.call(this, evaluator, task, renderForms);
      }
      if (!this.data.defaultAppearance) {
       return Promise.resolve(operatorList);
      }
      var stream = new Stream(stringToBytes(this.data.defaultAppearance));
      return evaluator.getOperatorList(stream, task, this.fieldResources, operatorList).then(function () {
       return operatorList;
      });
     }
    });
    return TextWidgetAnnotation;
   }();
   var ButtonWidgetAnnotation = function ButtonWidgetAnnotationClosure() {
    function ButtonWidgetAnnotation(params) {
     WidgetAnnotation.call(this, params);
     this.data.checkBox = !this.hasFieldFlag(AnnotationFieldFlag.RADIO) && !this.hasFieldFlag(AnnotationFieldFlag.PUSHBUTTON);
     if (this.data.checkBox) {
      if (!isName(this.data.fieldValue)) {
       return;
      }
      this.data.fieldValue = this.data.fieldValue.name;
     }
     this.data.radioButton = this.hasFieldFlag(AnnotationFieldFlag.RADIO) && !this.hasFieldFlag(AnnotationFieldFlag.PUSHBUTTON);
     if (this.data.radioButton) {
      this.data.fieldValue = this.data.buttonValue = null;
      var fieldParent = params.dict.get('Parent');
      if (!isDict(fieldParent) || !fieldParent.has('V')) {
       return;
      }
      var fieldParentValue = fieldParent.get('V');
      if (!isName(fieldParentValue)) {
       return;
      }
      this.data.fieldValue = fieldParentValue.name;
      var appearanceStates = params.dict.get('AP');
      if (!isDict(appearanceStates)) {
       return;
      }
      var normalAppearanceState = appearanceStates.get('N');
      if (!isDict(normalAppearanceState)) {
       return;
      }
      var keys = normalAppearanceState.getKeys();
      for (var i = 0, ii = keys.length; i < ii; i++) {
       if (keys[i] !== 'Off') {
        this.data.buttonValue = keys[i];
        break;
       }
      }
     }
    }
    Util.inherit(ButtonWidgetAnnotation, WidgetAnnotation, {
     getOperatorList: function ButtonWidgetAnnotation_getOperatorList(evaluator, task, renderForms) {
      var operatorList = new OperatorList();
      if (renderForms) {
       return Promise.resolve(operatorList);
      }
      if (this.appearance) {
       return Annotation.prototype.getOperatorList.call(this, evaluator, task, renderForms);
      }
      return Promise.resolve(operatorList);
     }
    });
    return ButtonWidgetAnnotation;
   }();
   var ChoiceWidgetAnnotation = function ChoiceWidgetAnnotationClosure() {
    function ChoiceWidgetAnnotation(params) {
     WidgetAnnotation.call(this, params);
     this.data.options = [];
     var options = params.dict.get('Opt');
     if (isArray(options)) {
      var xref = params.xref;
      for (var i = 0, ii = options.length; i < ii; i++) {
       var option = xref.fetchIfRef(options[i]);
       var isOptionArray = isArray(option);
       this.data.options[i] = {
        exportValue: isOptionArray ? xref.fetchIfRef(option[0]) : option,
        displayValue: isOptionArray ? xref.fetchIfRef(option[1]) : option
       };
      }
     }
     if (!isArray(this.data.fieldValue)) {
      this.data.fieldValue = [this.data.fieldValue];
     }
     this.data.combo = this.hasFieldFlag(AnnotationFieldFlag.COMBO);
     this.data.multiSelect = this.hasFieldFlag(AnnotationFieldFlag.MULTISELECT);
    }
    Util.inherit(ChoiceWidgetAnnotation, WidgetAnnotation, {
     getOperatorList: function ChoiceWidgetAnnotation_getOperatorList(evaluator, task, renderForms) {
      var operatorList = new OperatorList();
      if (renderForms) {
       return Promise.resolve(operatorList);
      }
      return Annotation.prototype.getOperatorList.call(this, evaluator, task, renderForms);
     }
    });
    return ChoiceWidgetAnnotation;
   }();
   var TextAnnotation = function TextAnnotationClosure() {
    var DEFAULT_ICON_SIZE = 22;
    function TextAnnotation(parameters) {
     Annotation.call(this, parameters);
     this.data.annotationType = AnnotationType.TEXT;
     if (this.data.hasAppearance) {
      this.data.name = 'NoIcon';
     } else {
      this.data.rect[1] = this.data.rect[3] - DEFAULT_ICON_SIZE;
      this.data.rect[2] = this.data.rect[0] + DEFAULT_ICON_SIZE;
      this.data.name = parameters.dict.has('Name') ? parameters.dict.get('Name').name : 'Note';
     }
     this._preparePopup(parameters.dict);
    }
    Util.inherit(TextAnnotation, Annotation, {});
    return TextAnnotation;
   }();
   var LinkAnnotation = function LinkAnnotationClosure() {
    function LinkAnnotation(params) {
     Annotation.call(this, params);
     var data = this.data;
     data.annotationType = AnnotationType.LINK;
     Catalog.parseDestDictionary({
      destDict: params.dict,
      resultObj: data,
      docBaseUrl: params.pdfManager.docBaseUrl
     });
    }
    Util.inherit(LinkAnnotation, Annotation, {});
    return LinkAnnotation;
   }();
   var PopupAnnotation = function PopupAnnotationClosure() {
    function PopupAnnotation(parameters) {
     Annotation.call(this, parameters);
     this.data.annotationType = AnnotationType.POPUP;
     var dict = parameters.dict;
     var parentItem = dict.get('Parent');
     if (!parentItem) {
      warn('Popup annotation has a missing or invalid parent annotation.');
      return;
     }
     this.data.parentId = dict.getRaw('Parent').toString();
     this.data.title = stringToPDFString(parentItem.get('T') || '');
     this.data.contents = stringToPDFString(parentItem.get('Contents') || '');
     if (!parentItem.has('C')) {
      this.data.color = null;
     } else {
      this.setColor(parentItem.getArray('C'));
      this.data.color = this.color;
     }
     if (!this.viewable) {
      var parentFlags = parentItem.get('F');
      if (this._isViewable(parentFlags)) {
       this.setFlags(parentFlags);
      }
     }
    }
    Util.inherit(PopupAnnotation, Annotation, {});
    return PopupAnnotation;
   }();
   var HighlightAnnotation = function HighlightAnnotationClosure() {
    function HighlightAnnotation(parameters) {
     Annotation.call(this, parameters);
     this.data.annotationType = AnnotationType.HIGHLIGHT;
     this._preparePopup(parameters.dict);
     this.data.borderStyle.setWidth(0);
    }
    Util.inherit(HighlightAnnotation, Annotation, {});
    return HighlightAnnotation;
   }();
   var UnderlineAnnotation = function UnderlineAnnotationClosure() {
    function UnderlineAnnotation(parameters) {
     Annotation.call(this, parameters);
     this.data.annotationType = AnnotationType.UNDERLINE;
     this._preparePopup(parameters.dict);
     this.data.borderStyle.setWidth(0);
    }
    Util.inherit(UnderlineAnnotation, Annotation, {});
    return UnderlineAnnotation;
   }();
   var SquigglyAnnotation = function SquigglyAnnotationClosure() {
    function SquigglyAnnotation(parameters) {
     Annotation.call(this, parameters);
     this.data.annotationType = AnnotationType.SQUIGGLY;
     this._preparePopup(parameters.dict);
     this.data.borderStyle.setWidth(0);
    }
    Util.inherit(SquigglyAnnotation, Annotation, {});
    return SquigglyAnnotation;
   }();
   var StrikeOutAnnotation = function StrikeOutAnnotationClosure() {
    function StrikeOutAnnotation(parameters) {
     Annotation.call(this, parameters);
     this.data.annotationType = AnnotationType.STRIKEOUT;
     this._preparePopup(parameters.dict);
     this.data.borderStyle.setWidth(0);
    }
    Util.inherit(StrikeOutAnnotation, Annotation, {});
    return StrikeOutAnnotation;
   }();
   var FileAttachmentAnnotation = function FileAttachmentAnnotationClosure() {
    function FileAttachmentAnnotation(parameters) {
     Annotation.call(this, parameters);
     var file = new FileSpec(parameters.dict.get('FS'), parameters.xref);
     this.data.annotationType = AnnotationType.FILEATTACHMENT;
     this.data.file = file.serializable;
     this._preparePopup(parameters.dict);
    }
    Util.inherit(FileAttachmentAnnotation, Annotation, {});
    return FileAttachmentAnnotation;
   }();
   exports.Annotation = Annotation;
   exports.AnnotationBorderStyle = AnnotationBorderStyle;
   exports.AnnotationFactory = AnnotationFactory;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreDocument = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCoreStream, root.pdfjsCoreObj, root.pdfjsCoreParser, root.pdfjsCoreCrypto, root.pdfjsCoreEvaluator, root.pdfjsCoreAnnotation);
  }(this, function (exports, sharedUtil, corePrimitives, coreStream, coreObj, coreParser, coreCrypto, coreEvaluator, coreAnnotation) {
   var MissingDataException = sharedUtil.MissingDataException;
   var Util = sharedUtil.Util;
   var assert = sharedUtil.assert;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var isArray = sharedUtil.isArray;
   var isArrayBuffer = sharedUtil.isArrayBuffer;
   var isNum = sharedUtil.isNum;
   var isString = sharedUtil.isString;
   var shadow = sharedUtil.shadow;
   var stringToBytes = sharedUtil.stringToBytes;
   var stringToPDFString = sharedUtil.stringToPDFString;
   var warn = sharedUtil.warn;
   var isSpace = sharedUtil.isSpace;
   var Dict = corePrimitives.Dict;
   var isDict = corePrimitives.isDict;
   var isName = corePrimitives.isName;
   var isStream = corePrimitives.isStream;
   var NullStream = coreStream.NullStream;
   var Stream = coreStream.Stream;
   var StreamsSequenceStream = coreStream.StreamsSequenceStream;
   var Catalog = coreObj.Catalog;
   var ObjectLoader = coreObj.ObjectLoader;
   var XRef = coreObj.XRef;
   var Linearization = coreParser.Linearization;
   var calculateMD5 = coreCrypto.calculateMD5;
   var OperatorList = coreEvaluator.OperatorList;
   var PartialEvaluator = coreEvaluator.PartialEvaluator;
   var Annotation = coreAnnotation.Annotation;
   var AnnotationFactory = coreAnnotation.AnnotationFactory;
   var Page = function PageClosure() {
    var DEFAULT_USER_UNIT = 1.0;
    var LETTER_SIZE_MEDIABOX = [
     0,
     0,
     612,
     792
    ];
    function Page(pdfManager, xref, pageIndex, pageDict, ref, fontCache) {
     this.pdfManager = pdfManager;
     this.pageIndex = pageIndex;
     this.pageDict = pageDict;
     this.xref = xref;
     this.ref = ref;
     this.fontCache = fontCache;
     this.uniquePrefix = 'p' + this.pageIndex + '_';
     this.idCounters = { obj: 0 };
     this.evaluatorOptions = pdfManager.evaluatorOptions;
     this.resourcesPromise = null;
    }
    Page.prototype = {
     getPageProp: function Page_getPageProp(key) {
      return this.pageDict.get(key);
     },
     getInheritedPageProp: function Page_getInheritedPageProp(key, getArray) {
      var dict = this.pageDict, valueArray = null, loopCount = 0;
      var MAX_LOOP_COUNT = 100;
      getArray = getArray || false;
      while (dict) {
       var value = getArray ? dict.getArray(key) : dict.get(key);
       if (value) {
        if (!valueArray) {
         valueArray = [];
        }
        valueArray.push(value);
       }
       if (++loopCount > MAX_LOOP_COUNT) {
        warn('Page_getInheritedPageProp: maximum loop count exceeded.');
        break;
       }
       dict = dict.get('Parent');
      }
      if (!valueArray) {
       return Dict.empty;
      }
      if (valueArray.length === 1 || !isDict(valueArray[0]) || loopCount > MAX_LOOP_COUNT) {
       return valueArray[0];
      }
      return Dict.merge(this.xref, valueArray);
     },
     get content() {
      return this.getPageProp('Contents');
     },
     get resources() {
      return shadow(this, 'resources', this.getInheritedPageProp('Resources'));
     },
     get mediaBox() {
      var mediaBox = this.getInheritedPageProp('MediaBox', true);
      if (!isArray(mediaBox) || mediaBox.length !== 4) {
       return shadow(this, 'mediaBox', LETTER_SIZE_MEDIABOX);
      }
      return shadow(this, 'mediaBox', mediaBox);
     },
     get cropBox() {
      var cropBox = this.getInheritedPageProp('CropBox', true);
      if (!isArray(cropBox) || cropBox.length !== 4) {
       return shadow(this, 'cropBox', this.mediaBox);
      }
      return shadow(this, 'cropBox', cropBox);
     },
     get userUnit() {
      var obj = this.getPageProp('UserUnit');
      if (!isNum(obj) || obj <= 0) {
       obj = DEFAULT_USER_UNIT;
      }
      return shadow(this, 'userUnit', obj);
     },
     get view() {
      var mediaBox = this.mediaBox, cropBox = this.cropBox;
      if (mediaBox === cropBox) {
       return shadow(this, 'view', mediaBox);
      }
      var intersection = Util.intersect(cropBox, mediaBox);
      return shadow(this, 'view', intersection || mediaBox);
     },
     get rotate() {
      var rotate = this.getInheritedPageProp('Rotate') || 0;
      if (rotate % 90 !== 0) {
       rotate = 0;
      } else if (rotate >= 360) {
       rotate = rotate % 360;
      } else if (rotate < 0) {
       rotate = (rotate % 360 + 360) % 360;
      }
      return shadow(this, 'rotate', rotate);
     },
     getContentStream: function Page_getContentStream() {
      var content = this.content;
      var stream;
      if (isArray(content)) {
       var xref = this.xref;
       var i, n = content.length;
       var streams = [];
       for (i = 0; i < n; ++i) {
        streams.push(xref.fetchIfRef(content[i]));
       }
       stream = new StreamsSequenceStream(streams);
      } else if (isStream(content)) {
       stream = content;
      } else {
       stream = new NullStream();
      }
      return stream;
     },
     loadResources: function Page_loadResources(keys) {
      if (!this.resourcesPromise) {
       this.resourcesPromise = this.pdfManager.ensure(this, 'resources');
      }
      return this.resourcesPromise.then(function resourceSuccess() {
       var objectLoader = new ObjectLoader(this.resources.map, keys, this.xref);
       return objectLoader.load();
      }.bind(this));
     },
     getOperatorList: function Page_getOperatorList(handler, task, intent, renderInteractiveForms) {
      var self = this;
      var pdfManager = this.pdfManager;
      var contentStreamPromise = pdfManager.ensure(this, 'getContentStream', []);
      var resourcesPromise = this.loadResources([
       'ExtGState',
       'ColorSpace',
       'Pattern',
       'Shading',
       'XObject',
       'Font'
      ]);
      var partialEvaluator = new PartialEvaluator(pdfManager, this.xref, handler, this.pageIndex, this.uniquePrefix, this.idCounters, this.fontCache, this.evaluatorOptions);
      var dataPromises = Promise.all([
       contentStreamPromise,
       resourcesPromise
      ]);
      var pageListPromise = dataPromises.then(function (data) {
       var contentStream = data[0];
       var opList = new OperatorList(intent, handler, self.pageIndex);
       handler.send('StartRenderPage', {
        transparency: partialEvaluator.hasBlendModes(self.resources),
        pageIndex: self.pageIndex,
        intent: intent
       });
       return partialEvaluator.getOperatorList(contentStream, task, self.resources, opList).then(function () {
        return opList;
       });
      });
      var annotationsPromise = pdfManager.ensure(this, 'annotations');
      return Promise.all([
       pageListPromise,
       annotationsPromise
      ]).then(function (datas) {
       var pageOpList = datas[0];
       var annotations = datas[1];
       if (annotations.length === 0) {
        pageOpList.flush(true);
        return pageOpList;
       }
       var annotationsReadyPromise = Annotation.appendToOperatorList(annotations, pageOpList, partialEvaluator, task, intent, renderInteractiveForms);
       return annotationsReadyPromise.then(function () {
        pageOpList.flush(true);
        return pageOpList;
       });
      });
     },
     extractTextContent: function Page_extractTextContent(task, normalizeWhitespace, combineTextItems) {
      var handler = {
       on: function nullHandlerOn() {
       },
       send: function nullHandlerSend() {
       }
      };
      var self = this;
      var pdfManager = this.pdfManager;
      var contentStreamPromise = pdfManager.ensure(this, 'getContentStream', []);
      var resourcesPromise = this.loadResources([
       'ExtGState',
       'XObject',
       'Font'
      ]);
      var dataPromises = Promise.all([
       contentStreamPromise,
       resourcesPromise
      ]);
      return dataPromises.then(function (data) {
       var contentStream = data[0];
       var partialEvaluator = new PartialEvaluator(pdfManager, self.xref, handler, self.pageIndex, self.uniquePrefix, self.idCounters, self.fontCache, self.evaluatorOptions);
       return partialEvaluator.getTextContent(contentStream, task, self.resources, null, normalizeWhitespace, combineTextItems);
      });
     },
     getAnnotationsData: function Page_getAnnotationsData(intent) {
      var annotations = this.annotations;
      var annotationsData = [];
      for (var i = 0, n = annotations.length; i < n; ++i) {
       if (intent) {
        if (!(intent === 'display' && annotations[i].viewable) && !(intent === 'print' && annotations[i].printable)) {
         continue;
        }
       }
       annotationsData.push(annotations[i].data);
      }
      return annotationsData;
     },
     get annotations() {
      var annotations = [];
      var annotationRefs = this.getInheritedPageProp('Annots') || [];
      var annotationFactory = new AnnotationFactory();
      for (var i = 0, n = annotationRefs.length; i < n; ++i) {
       var annotationRef = annotationRefs[i];
       var annotation = annotationFactory.create(this.xref, annotationRef, this.pdfManager, this.uniquePrefix, this.idCounters);
       if (annotation) {
        annotations.push(annotation);
       }
      }
      return shadow(this, 'annotations', annotations);
     }
    };
    return Page;
   }();
   var PDFDocument = function PDFDocumentClosure() {
    var FINGERPRINT_FIRST_BYTES = 1024;
    var EMPTY_FINGERPRINT = '\x00\x00\x00\x00\x00\x00\x00' + '\x00\x00\x00\x00\x00\x00\x00\x00\x00';
    function PDFDocument(pdfManager, arg) {
     var stream;
     if (isStream(arg)) {
      stream = arg;
     } else if (isArrayBuffer(arg)) {
      stream = new Stream(arg);
     } else {
      error('PDFDocument: Unknown argument type');
     }
     assert(stream.length > 0, 'stream must have data');
     this.pdfManager = pdfManager;
     this.stream = stream;
     this.xref = new XRef(stream, pdfManager);
    }
    function find(stream, needle, limit, backwards) {
     var pos = stream.pos;
     var end = stream.end;
     var strBuf = [];
     if (pos + limit > end) {
      limit = end - pos;
     }
     for (var n = 0; n < limit; ++n) {
      strBuf.push(String.fromCharCode(stream.getByte()));
     }
     var str = strBuf.join('');
     stream.pos = pos;
     var index = backwards ? str.lastIndexOf(needle) : str.indexOf(needle);
     if (index === -1) {
      return false;
     }
     stream.pos += index;
     return true;
    }
    var DocumentInfoValidators = {
     get entries() {
      return shadow(this, 'entries', {
       Title: isString,
       Author: isString,
       Subject: isString,
       Keywords: isString,
       Creator: isString,
       Producer: isString,
       CreationDate: isString,
       ModDate: isString,
       Trapped: isName
      });
     }
    };
    PDFDocument.prototype = {
     parse: function PDFDocument_parse(recoveryMode) {
      this.setup(recoveryMode);
      var version = this.catalog.catDict.get('Version');
      if (isName(version)) {
       this.pdfFormatVersion = version.name;
      }
      try {
       this.acroForm = this.catalog.catDict.get('AcroForm');
       if (this.acroForm) {
        this.xfa = this.acroForm.get('XFA');
        var fields = this.acroForm.get('Fields');
        if ((!fields || !isArray(fields) || fields.length === 0) && !this.xfa) {
         this.acroForm = null;
        }
       }
      } catch (ex) {
       info('Something wrong with AcroForm entry');
       this.acroForm = null;
      }
     },
     get linearization() {
      var linearization = null;
      if (this.stream.length) {
       try {
        linearization = Linearization.create(this.stream);
       } catch (err) {
        if (err instanceof MissingDataException) {
         throw err;
        }
        info(err);
       }
      }
      return shadow(this, 'linearization', linearization);
     },
     get startXRef() {
      var stream = this.stream;
      var startXRef = 0;
      var linearization = this.linearization;
      if (linearization) {
       stream.reset();
       if (find(stream, 'endobj', 1024)) {
        startXRef = stream.pos + 6;
       }
      } else {
       var step = 1024;
       var found = false, pos = stream.end;
       while (!found && pos > 0) {
        pos -= step - 'startxref'.length;
        if (pos < 0) {
         pos = 0;
        }
        stream.pos = pos;
        found = find(stream, 'startxref', step, true);
       }
       if (found) {
        stream.skip(9);
        var ch;
        do {
         ch = stream.getByte();
        } while (isSpace(ch));
        var str = '';
        while (ch >= 0x20 && ch <= 0x39) {
         str += String.fromCharCode(ch);
         ch = stream.getByte();
        }
        startXRef = parseInt(str, 10);
        if (isNaN(startXRef)) {
         startXRef = 0;
        }
       }
      }
      return shadow(this, 'startXRef', startXRef);
     },
     get mainXRefEntriesOffset() {
      var mainXRefEntriesOffset = 0;
      var linearization = this.linearization;
      if (linearization) {
       mainXRefEntriesOffset = linearization.mainXRefEntriesOffset;
      }
      return shadow(this, 'mainXRefEntriesOffset', mainXRefEntriesOffset);
     },
     checkHeader: function PDFDocument_checkHeader() {
      var stream = this.stream;
      stream.reset();
      if (find(stream, '%PDF-', 1024)) {
       stream.moveStart();
       var MAX_VERSION_LENGTH = 12;
       var version = '', ch;
       while ((ch = stream.getByte()) > 0x20) {
        if (version.length >= MAX_VERSION_LENGTH) {
         break;
        }
        version += String.fromCharCode(ch);
       }
       if (!this.pdfFormatVersion) {
        this.pdfFormatVersion = version.substring(5);
       }
       return;
      }
     },
     parseStartXRef: function PDFDocument_parseStartXRef() {
      var startXRef = this.startXRef;
      this.xref.setStartXRef(startXRef);
     },
     setup: function PDFDocument_setup(recoveryMode) {
      this.xref.parse(recoveryMode);
      var self = this;
      var pageFactory = {
       createPage: function (pageIndex, dict, ref, fontCache) {
        return new Page(self.pdfManager, self.xref, pageIndex, dict, ref, fontCache);
       }
      };
      this.catalog = new Catalog(this.pdfManager, this.xref, pageFactory);
     },
     get numPages() {
      var linearization = this.linearization;
      var num = linearization ? linearization.numPages : this.catalog.numPages;
      return shadow(this, 'numPages', num);
     },
     get documentInfo() {
      var docInfo = {
       PDFFormatVersion: this.pdfFormatVersion,
       IsAcroFormPresent: !!this.acroForm,
       IsXFAPresent: !!this.xfa
      };
      var infoDict;
      try {
       infoDict = this.xref.trailer.get('Info');
      } catch (err) {
       info('The document information dictionary is invalid.');
      }
      if (infoDict) {
       var validEntries = DocumentInfoValidators.entries;
       for (var key in validEntries) {
        if (infoDict.has(key)) {
         var value = infoDict.get(key);
         if (validEntries[key](value)) {
          docInfo[key] = typeof value !== 'string' ? value : stringToPDFString(value);
         } else {
          info('Bad value in document info for "' + key + '"');
         }
        }
       }
      }
      return shadow(this, 'documentInfo', docInfo);
     },
     get fingerprint() {
      var xref = this.xref, hash, fileID = '';
      var idArray = xref.trailer.get('ID');
      if (idArray && isArray(idArray) && idArray[0] && isString(idArray[0]) && idArray[0] !== EMPTY_FINGERPRINT) {
       hash = stringToBytes(idArray[0]);
      } else {
       if (this.stream.ensureRange) {
        this.stream.ensureRange(0, Math.min(FINGERPRINT_FIRST_BYTES, this.stream.end));
       }
       hash = calculateMD5(this.stream.bytes.subarray(0, FINGERPRINT_FIRST_BYTES), 0, FINGERPRINT_FIRST_BYTES);
      }
      for (var i = 0, n = hash.length; i < n; i++) {
       var hex = hash[i].toString(16);
       fileID += hex.length === 1 ? '0' + hex : hex;
      }
      return shadow(this, 'fingerprint', fileID);
     },
     getPage: function PDFDocument_getPage(pageIndex) {
      return this.catalog.getPage(pageIndex);
     },
     cleanup: function PDFDocument_cleanup() {
      return this.catalog.cleanup();
     }
    };
    return PDFDocument;
   }();
   exports.Page = Page;
   exports.PDFDocument = PDFDocument;
  }));
  (function (root, factory) {
   factory(root.pdfjsCorePdfManager = {}, root.pdfjsSharedUtil, root.pdfjsCoreStream, root.pdfjsCoreChunkedStream, root.pdfjsCoreDocument);
  }(this, function (exports, sharedUtil, coreStream, coreChunkedStream, coreDocument) {
   var warn = sharedUtil.warn;
   var createValidAbsoluteUrl = sharedUtil.createValidAbsoluteUrl;
   var shadow = sharedUtil.shadow;
   var NotImplementedException = sharedUtil.NotImplementedException;
   var MissingDataException = sharedUtil.MissingDataException;
   var createPromiseCapability = sharedUtil.createPromiseCapability;
   var Util = sharedUtil.Util;
   var Stream = coreStream.Stream;
   var ChunkedStreamManager = coreChunkedStream.ChunkedStreamManager;
   var PDFDocument = coreDocument.PDFDocument;
   var BasePdfManager = function BasePdfManagerClosure() {
    function BasePdfManager() {
     throw new Error('Cannot initialize BaseManagerManager');
    }
    BasePdfManager.prototype = {
     get docId() {
      return this._docId;
     },
     get password() {
      return this._password;
     },
     get docBaseUrl() {
      var docBaseUrl = null;
      if (this._docBaseUrl) {
       var absoluteUrl = createValidAbsoluteUrl(this._docBaseUrl);
       if (absoluteUrl) {
        docBaseUrl = absoluteUrl.href;
       } else {
        warn('Invalid absolute docBaseUrl: "' + this._docBaseUrl + '".');
       }
      }
      return shadow(this, 'docBaseUrl', docBaseUrl);
     },
     onLoadedStream: function BasePdfManager_onLoadedStream() {
      throw new NotImplementedException();
     },
     ensureDoc: function BasePdfManager_ensureDoc(prop, args) {
      return this.ensure(this.pdfDocument, prop, args);
     },
     ensureXRef: function BasePdfManager_ensureXRef(prop, args) {
      return this.ensure(this.pdfDocument.xref, prop, args);
     },
     ensureCatalog: function BasePdfManager_ensureCatalog(prop, args) {
      return this.ensure(this.pdfDocument.catalog, prop, args);
     },
     getPage: function BasePdfManager_getPage(pageIndex) {
      return this.pdfDocument.getPage(pageIndex);
     },
     cleanup: function BasePdfManager_cleanup() {
      return this.pdfDocument.cleanup();
     },
     ensure: function BasePdfManager_ensure(obj, prop, args) {
      return new NotImplementedException();
     },
     requestRange: function BasePdfManager_requestRange(begin, end) {
      return new NotImplementedException();
     },
     requestLoadedStream: function BasePdfManager_requestLoadedStream() {
      return new NotImplementedException();
     },
     sendProgressiveData: function BasePdfManager_sendProgressiveData(chunk) {
      return new NotImplementedException();
     },
     updatePassword: function BasePdfManager_updatePassword(password) {
      this._password = password;
     },
     terminate: function BasePdfManager_terminate() {
      return new NotImplementedException();
     }
    };
    return BasePdfManager;
   }();
   var LocalPdfManager = function LocalPdfManagerClosure() {
    function LocalPdfManager(docId, data, password, evaluatorOptions, docBaseUrl) {
     this._docId = docId;
     this._password = password;
     this._docBaseUrl = docBaseUrl;
     this.evaluatorOptions = evaluatorOptions;
     var stream = new Stream(data);
     this.pdfDocument = new PDFDocument(this, stream);
     this._loadedStreamCapability = createPromiseCapability();
     this._loadedStreamCapability.resolve(stream);
    }
    Util.inherit(LocalPdfManager, BasePdfManager, {
     ensure: function LocalPdfManager_ensure(obj, prop, args) {
      return new Promise(function (resolve, reject) {
       try {
        var value = obj[prop];
        var result;
        if (typeof value === 'function') {
         result = value.apply(obj, args);
        } else {
         result = value;
        }
        resolve(result);
       } catch (e) {
        reject(e);
       }
      });
     },
     requestRange: function LocalPdfManager_requestRange(begin, end) {
      return Promise.resolve();
     },
     requestLoadedStream: function LocalPdfManager_requestLoadedStream() {
      return;
     },
     onLoadedStream: function LocalPdfManager_onLoadedStream() {
      return this._loadedStreamCapability.promise;
     },
     terminate: function LocalPdfManager_terminate() {
      return;
     }
    });
    return LocalPdfManager;
   }();
   var NetworkPdfManager = function NetworkPdfManagerClosure() {
    function NetworkPdfManager(docId, pdfNetworkStream, args, evaluatorOptions, docBaseUrl) {
     this._docId = docId;
     this._password = args.password;
     this._docBaseUrl = docBaseUrl;
     this.msgHandler = args.msgHandler;
     this.evaluatorOptions = evaluatorOptions;
     var params = {
      msgHandler: args.msgHandler,
      url: args.url,
      length: args.length,
      disableAutoFetch: args.disableAutoFetch,
      rangeChunkSize: args.rangeChunkSize
     };
     this.streamManager = new ChunkedStreamManager(pdfNetworkStream, params);
     this.pdfDocument = new PDFDocument(this, this.streamManager.getStream());
    }
    Util.inherit(NetworkPdfManager, BasePdfManager, {
     ensure: function NetworkPdfManager_ensure(obj, prop, args) {
      var pdfManager = this;
      return new Promise(function (resolve, reject) {
       function ensureHelper() {
        try {
         var result;
         var value = obj[prop];
         if (typeof value === 'function') {
          result = value.apply(obj, args);
         } else {
          result = value;
         }
         resolve(result);
        } catch (e) {
         if (!(e instanceof MissingDataException)) {
          reject(e);
          return;
         }
         pdfManager.streamManager.requestRange(e.begin, e.end).then(ensureHelper, reject);
        }
       }
       ensureHelper();
      });
     },
     requestRange: function NetworkPdfManager_requestRange(begin, end) {
      return this.streamManager.requestRange(begin, end);
     },
     requestLoadedStream: function NetworkPdfManager_requestLoadedStream() {
      this.streamManager.requestAllChunks();
     },
     sendProgressiveData: function NetworkPdfManager_sendProgressiveData(chunk) {
      this.streamManager.onReceiveData({ chunk: chunk });
     },
     onLoadedStream: function NetworkPdfManager_onLoadedStream() {
      return this.streamManager.onLoadedStream();
     },
     terminate: function NetworkPdfManager_terminate() {
      this.streamManager.abort();
     }
    });
    return NetworkPdfManager;
   }();
   exports.LocalPdfManager = LocalPdfManager;
   exports.NetworkPdfManager = NetworkPdfManager;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreWorker = {}, root.pdfjsSharedUtil, root.pdfjsCorePrimitives, root.pdfjsCorePdfManager);
  }(this, function (exports, sharedUtil, corePrimitives, corePdfManager) {
   var UNSUPPORTED_FEATURES = sharedUtil.UNSUPPORTED_FEATURES;
   var InvalidPDFException = sharedUtil.InvalidPDFException;
   var MessageHandler = sharedUtil.MessageHandler;
   var MissingPDFException = sharedUtil.MissingPDFException;
   var UnexpectedResponseException = sharedUtil.UnexpectedResponseException;
   var PasswordException = sharedUtil.PasswordException;
   var PasswordResponses = sharedUtil.PasswordResponses;
   var UnknownErrorException = sharedUtil.UnknownErrorException;
   var XRefParseException = sharedUtil.XRefParseException;
   var arrayByteLength = sharedUtil.arrayByteLength;
   var arraysToBytes = sharedUtil.arraysToBytes;
   var assert = sharedUtil.assert;
   var createPromiseCapability = sharedUtil.createPromiseCapability;
   var error = sharedUtil.error;
   var info = sharedUtil.info;
   var warn = sharedUtil.warn;
   var setVerbosityLevel = sharedUtil.setVerbosityLevel;
   var Ref = corePrimitives.Ref;
   var LocalPdfManager = corePdfManager.LocalPdfManager;
   var NetworkPdfManager = corePdfManager.NetworkPdfManager;
   var globalScope = sharedUtil.globalScope;
   var WorkerTask = function WorkerTaskClosure() {
    function WorkerTask(name) {
     this.name = name;
     this.terminated = false;
     this._capability = createPromiseCapability();
    }
    WorkerTask.prototype = {
     get finished() {
      return this._capability.promise;
     },
     finish: function () {
      this._capability.resolve();
     },
     terminate: function () {
      this.terminated = true;
     },
     ensureNotTerminated: function () {
      if (this.terminated) {
       throw new Error('Worker task was terminated');
      }
     }
    };
    return WorkerTask;
   }();
   var PDFWorkerStream = function PDFWorkerStreamClosure() {
    function PDFWorkerStream(params, msgHandler) {
     this._queuedChunks = [];
     var initialData = params.initialData;
     if (initialData && initialData.length > 0) {
      this._queuedChunks.push(initialData);
     }
     this._msgHandler = msgHandler;
     this._isRangeSupported = !params.disableRange;
     this._isStreamingSupported = !params.disableStream;
     this._contentLength = params.length;
     this._fullRequestReader = null;
     this._rangeReaders = [];
     msgHandler.on('OnDataRange', this._onReceiveData.bind(this));
     msgHandler.on('OnDataProgress', this._onProgress.bind(this));
    }
    PDFWorkerStream.prototype = {
     _onReceiveData: function PDFWorkerStream_onReceiveData(args) {
      if (args.begin === undefined) {
       if (this._fullRequestReader) {
        this._fullRequestReader._enqueue(args.chunk);
       } else {
        this._queuedChunks.push(args.chunk);
       }
      } else {
       var found = this._rangeReaders.some(function (rangeReader) {
        if (rangeReader._begin !== args.begin) {
         return false;
        }
        rangeReader._enqueue(args.chunk);
        return true;
       });
       assert(found);
      }
     },
     _onProgress: function PDFWorkerStream_onProgress(evt) {
      if (this._rangeReaders.length > 0) {
       var firstReader = this._rangeReaders[0];
       if (firstReader.onProgress) {
        firstReader.onProgress({ loaded: evt.loaded });
       }
      }
     },
     _removeRangeReader: function PDFWorkerStream_removeRangeReader(reader) {
      var i = this._rangeReaders.indexOf(reader);
      if (i >= 0) {
       this._rangeReaders.splice(i, 1);
      }
     },
     getFullReader: function PDFWorkerStream_getFullReader() {
      assert(!this._fullRequestReader);
      var queuedChunks = this._queuedChunks;
      this._queuedChunks = null;
      return new PDFWorkerStreamReader(this, queuedChunks);
     },
     getRangeReader: function PDFWorkerStream_getRangeReader(begin, end) {
      var reader = new PDFWorkerStreamRangeReader(this, begin, end);
      this._msgHandler.send('RequestDataRange', {
       begin: begin,
       end: end
      });
      this._rangeReaders.push(reader);
      return reader;
     },
     cancelAllRequests: function PDFWorkerStream_cancelAllRequests(reason) {
      if (this._fullRequestReader) {
       this._fullRequestReader.cancel(reason);
      }
      var readers = this._rangeReaders.slice(0);
      readers.forEach(function (rangeReader) {
       rangeReader.cancel(reason);
      });
     }
    };
    function PDFWorkerStreamReader(stream, queuedChunks) {
     this._stream = stream;
     this._done = false;
     this._queuedChunks = queuedChunks || [];
     this._requests = [];
     this._headersReady = Promise.resolve();
     stream._fullRequestReader = this;
     this.onProgress = null;
    }
    PDFWorkerStreamReader.prototype = {
     _enqueue: function PDFWorkerStreamReader_enqueue(chunk) {
      if (this._done) {
       return;
      }
      if (this._requests.length > 0) {
       var requestCapability = this._requests.shift();
       requestCapability.resolve({
        value: chunk,
        done: false
       });
       return;
      }
      this._queuedChunks.push(chunk);
     },
     get headersReady() {
      return this._headersReady;
     },
     get isRangeSupported() {
      return this._stream._isRangeSupported;
     },
     get isStreamingSupported() {
      return this._stream._isStreamingSupported;
     },
     get contentLength() {
      return this._stream._contentLength;
     },
     read: function PDFWorkerStreamReader_read() {
      if (this._queuedChunks.length > 0) {
       var chunk = this._queuedChunks.shift();
       return Promise.resolve({
        value: chunk,
        done: false
       });
      }
      if (this._done) {
       return Promise.resolve({
        value: undefined,
        done: true
       });
      }
      var requestCapability = createPromiseCapability();
      this._requests.push(requestCapability);
      return requestCapability.promise;
     },
     cancel: function PDFWorkerStreamReader_cancel(reason) {
      this._done = true;
      this._requests.forEach(function (requestCapability) {
       requestCapability.resolve({
        value: undefined,
        done: true
       });
      });
      this._requests = [];
     }
    };
    function PDFWorkerStreamRangeReader(stream, begin, end) {
     this._stream = stream;
     this._begin = begin;
     this._end = end;
     this._queuedChunk = null;
     this._requests = [];
     this._done = false;
     this.onProgress = null;
    }
    PDFWorkerStreamRangeReader.prototype = {
     _enqueue: function PDFWorkerStreamRangeReader_enqueue(chunk) {
      if (this._done) {
       return;
      }
      if (this._requests.length === 0) {
       this._queuedChunk = chunk;
      } else {
       var requestsCapability = this._requests.shift();
       requestsCapability.resolve({
        value: chunk,
        done: false
       });
       this._requests.forEach(function (requestCapability) {
        requestCapability.resolve({
         value: undefined,
         done: true
        });
       });
       this._requests = [];
      }
      this._done = true;
      this._stream._removeRangeReader(this);
     },
     get isStreamingSupported() {
      return false;
     },
     read: function PDFWorkerStreamRangeReader_read() {
      if (this._queuedChunk) {
       return Promise.resolve({
        value: this._queuedChunk,
        done: false
       });
      }
      if (this._done) {
       return Promise.resolve({
        value: undefined,
        done: true
       });
      }
      var requestCapability = createPromiseCapability();
      this._requests.push(requestCapability);
      return requestCapability.promise;
     },
     cancel: function PDFWorkerStreamRangeReader_cancel(reason) {
      this._done = true;
      this._requests.forEach(function (requestCapability) {
       requestCapability.resolve({
        value: undefined,
        done: true
       });
      });
      this._requests = [];
      this._stream._removeRangeReader(this);
     }
    };
    return PDFWorkerStream;
   }();
   var PDFNetworkStream;
   function setPDFNetworkStreamClass(cls) {
    PDFNetworkStream = cls;
   }
   var WorkerMessageHandler = {
    setup: function wphSetup(handler, port) {
     var testMessageProcessed = false;
     handler.on('test', function wphSetupTest(data) {
      if (testMessageProcessed) {
       return;
      }
      testMessageProcessed = true;
      if (!(data instanceof Uint8Array)) {
       handler.send('test', 'main', false);
       return;
      }
      var supportTransfers = data[0] === 255;
      handler.postMessageTransfers = supportTransfers;
      var xhr = new XMLHttpRequest();
      var responseExists = 'response' in xhr;
      try {
       var dummy = xhr.responseType;
      } catch (e) {
       responseExists = false;
      }
      if (!responseExists) {
       handler.send('test', false);
       return;
      }
      handler.send('test', {
       supportTypedArray: true,
       supportTransfers: supportTransfers
      });
     });
     handler.on('configure', function wphConfigure(data) {
      setVerbosityLevel(data.verbosity);
     });
     handler.on('GetDocRequest', function wphSetupDoc(data) {
      return WorkerMessageHandler.createDocumentHandler(data, port);
     });
    },
    createDocumentHandler: function wphCreateDocumentHandler(docParams, port) {
     var pdfManager;
     var terminated = false;
     var cancelXHRs = null;
     var WorkerTasks = [];
     var docId = docParams.docId;
     var docBaseUrl = docParams.docBaseUrl;
     var workerHandlerName = docParams.docId + '_worker';
     var handler = new MessageHandler(workerHandlerName, docId, port);
     handler.postMessageTransfers = docParams.postMessageTransfers;
     function ensureNotTerminated() {
      if (terminated) {
       throw new Error('Worker was terminated');
      }
     }
     function startWorkerTask(task) {
      WorkerTasks.push(task);
     }
     function finishWorkerTask(task) {
      task.finish();
      var i = WorkerTasks.indexOf(task);
      WorkerTasks.splice(i, 1);
     }
     function loadDocument(recoveryMode) {
      var loadDocumentCapability = createPromiseCapability();
      var parseSuccess = function parseSuccess() {
       var numPagesPromise = pdfManager.ensureDoc('numPages');
       var fingerprintPromise = pdfManager.ensureDoc('fingerprint');
       var encryptedPromise = pdfManager.ensureXRef('encrypt');
       Promise.all([
        numPagesPromise,
        fingerprintPromise,
        encryptedPromise
       ]).then(function onDocReady(results) {
        var doc = {
         numPages: results[0],
         fingerprint: results[1],
         encrypted: !!results[2]
        };
        loadDocumentCapability.resolve(doc);
       }, parseFailure);
      };
      var parseFailure = function parseFailure(e) {
       loadDocumentCapability.reject(e);
      };
      pdfManager.ensureDoc('checkHeader', []).then(function () {
       pdfManager.ensureDoc('parseStartXRef', []).then(function () {
        pdfManager.ensureDoc('parse', [recoveryMode]).then(parseSuccess, parseFailure);
       }, parseFailure);
      }, parseFailure);
      return loadDocumentCapability.promise;
     }
     function getPdfManager(data, evaluatorOptions) {
      var pdfManagerCapability = createPromiseCapability();
      var pdfManager;
      var source = data.source;
      if (source.data) {
       try {
        pdfManager = new LocalPdfManager(docId, source.data, source.password, evaluatorOptions, docBaseUrl);
        pdfManagerCapability.resolve(pdfManager);
       } catch (ex) {
        pdfManagerCapability.reject(ex);
       }
       return pdfManagerCapability.promise;
      }
      var pdfStream;
      try {
       if (source.chunkedViewerLoading) {
        pdfStream = new PDFWorkerStream(source, handler);
       } else {
        assert(PDFNetworkStream, 'pdfjs/core/network module is not loaded');
        pdfStream = new PDFNetworkStream(data);
       }
      } catch (ex) {
       pdfManagerCapability.reject(ex);
       return pdfManagerCapability.promise;
      }
      var fullRequest = pdfStream.getFullReader();
      fullRequest.headersReady.then(function () {
       if (!fullRequest.isStreamingSupported || !fullRequest.isRangeSupported) {
        fullRequest.onProgress = function (evt) {
         handler.send('DocProgress', {
          loaded: evt.loaded,
          total: evt.total
         });
        };
       }
       if (!fullRequest.isRangeSupported) {
        return;
       }
       var disableAutoFetch = source.disableAutoFetch || fullRequest.isStreamingSupported;
       pdfManager = new NetworkPdfManager(docId, pdfStream, {
        msgHandler: handler,
        url: source.url,
        password: source.password,
        length: fullRequest.contentLength,
        disableAutoFetch: disableAutoFetch,
        rangeChunkSize: source.rangeChunkSize
       }, evaluatorOptions, docBaseUrl);
       pdfManagerCapability.resolve(pdfManager);
       cancelXHRs = null;
      }).catch(function (reason) {
       pdfManagerCapability.reject(reason);
       cancelXHRs = null;
      });
      var cachedChunks = [], loaded = 0;
      var flushChunks = function () {
       var pdfFile = arraysToBytes(cachedChunks);
       if (source.length && pdfFile.length !== source.length) {
        warn('reported HTTP length is different from actual');
       }
       try {
        pdfManager = new LocalPdfManager(docId, pdfFile, source.password, evaluatorOptions, docBaseUrl);
        pdfManagerCapability.resolve(pdfManager);
       } catch (ex) {
        pdfManagerCapability.reject(ex);
       }
       cachedChunks = [];
      };
      var readPromise = new Promise(function (resolve, reject) {
       var readChunk = function (chunk) {
        try {
         ensureNotTerminated();
         if (chunk.done) {
          if (!pdfManager) {
           flushChunks();
          }
          cancelXHRs = null;
          return;
         }
         var data = chunk.value;
         loaded += arrayByteLength(data);
         if (!fullRequest.isStreamingSupported) {
          handler.send('DocProgress', {
           loaded: loaded,
           total: Math.max(loaded, fullRequest.contentLength || 0)
          });
         }
         if (pdfManager) {
          pdfManager.sendProgressiveData(data);
         } else {
          cachedChunks.push(data);
         }
         fullRequest.read().then(readChunk, reject);
        } catch (e) {
         reject(e);
        }
       };
       fullRequest.read().then(readChunk, reject);
      });
      readPromise.catch(function (e) {
       pdfManagerCapability.reject(e);
       cancelXHRs = null;
      });
      cancelXHRs = function () {
       pdfStream.cancelAllRequests('abort');
      };
      return pdfManagerCapability.promise;
     }
     function setupDoc(data) {
      function onSuccess(doc) {
       ensureNotTerminated();
       handler.send('GetDoc', { pdfInfo: doc });
      }
      function onFailure(e) {
       if (e instanceof PasswordException) {
        var task = new WorkerTask('PasswordException: response ' + e.code);
        startWorkerTask(task);
        handler.sendWithPromise('PasswordRequest', e).then(function (data) {
         finishWorkerTask(task);
         pdfManager.updatePassword(data.password);
         pdfManagerReady();
        }).catch(function (ex) {
         finishWorkerTask(task);
         handler.send('PasswordException', ex);
        }.bind(null, e));
       } else if (e instanceof InvalidPDFException) {
        handler.send('InvalidPDF', e);
       } else if (e instanceof MissingPDFException) {
        handler.send('MissingPDF', e);
       } else if (e instanceof UnexpectedResponseException) {
        handler.send('UnexpectedResponse', e);
       } else {
        handler.send('UnknownError', new UnknownErrorException(e.message, e.toString()));
       }
      }
      function pdfManagerReady() {
       ensureNotTerminated();
       loadDocument(false).then(onSuccess, function loadFailure(ex) {
        ensureNotTerminated();
        if (!(ex instanceof XRefParseException)) {
         onFailure(ex);
         return;
        }
        pdfManager.requestLoadedStream();
        pdfManager.onLoadedStream().then(function () {
         ensureNotTerminated();
         loadDocument(true).then(onSuccess, onFailure);
        });
       }, onFailure);
      }
      ensureNotTerminated();
      var cMapOptions = {
       url: data.cMapUrl === undefined ? null : data.cMapUrl,
       packed: data.cMapPacked === true
      };
      var evaluatorOptions = {
       forceDataSchema: data.disableCreateObjectURL,
       maxImageSize: data.maxImageSize === undefined ? -1 : data.maxImageSize,
       disableFontFace: data.disableFontFace,
       cMapOptions: cMapOptions
      };
      getPdfManager(data, evaluatorOptions).then(function (newPdfManager) {
       if (terminated) {
        newPdfManager.terminate();
        throw new Error('Worker was terminated');
       }
       pdfManager = newPdfManager;
       handler.send('PDFManagerReady', null);
       pdfManager.onLoadedStream().then(function (stream) {
        handler.send('DataLoaded', { length: stream.bytes.byteLength });
       });
      }).then(pdfManagerReady, onFailure);
     }
     handler.on('GetPage', function wphSetupGetPage(data) {
      return pdfManager.getPage(data.pageIndex).then(function (page) {
       var rotatePromise = pdfManager.ensure(page, 'rotate');
       var refPromise = pdfManager.ensure(page, 'ref');
       var userUnitPromise = pdfManager.ensure(page, 'userUnit');
       var viewPromise = pdfManager.ensure(page, 'view');
       return Promise.all([
        rotatePromise,
        refPromise,
        userUnitPromise,
        viewPromise
       ]).then(function (results) {
        return {
         rotate: results[0],
         ref: results[1],
         userUnit: results[2],
         view: results[3]
        };
       });
      });
     });
     handler.on('GetPageIndex', function wphSetupGetPageIndex(data) {
      var ref = new Ref(data.ref.num, data.ref.gen);
      var catalog = pdfManager.pdfDocument.catalog;
      return catalog.getPageIndex(ref);
     });
     handler.on('GetDestinations', function wphSetupGetDestinations(data) {
      return pdfManager.ensureCatalog('destinations');
     });
     handler.on('GetDestination', function wphSetupGetDestination(data) {
      return pdfManager.ensureCatalog('getDestination', [data.id]);
     });
     handler.on('GetPageLabels', function wphSetupGetPageLabels(data) {
      return pdfManager.ensureCatalog('pageLabels');
     });
     handler.on('GetAttachments', function wphSetupGetAttachments(data) {
      return pdfManager.ensureCatalog('attachments');
     });
     handler.on('GetJavaScript', function wphSetupGetJavaScript(data) {
      return pdfManager.ensureCatalog('javaScript');
     });
     handler.on('GetOutline', function wphSetupGetOutline(data) {
      return pdfManager.ensureCatalog('documentOutline');
     });
     handler.on('GetMetadata', function wphSetupGetMetadata(data) {
      return Promise.all([
       pdfManager.ensureDoc('documentInfo'),
       pdfManager.ensureCatalog('metadata')
      ]);
     });
     handler.on('GetData', function wphSetupGetData(data) {
      pdfManager.requestLoadedStream();
      return pdfManager.onLoadedStream().then(function (stream) {
       return stream.bytes;
      });
     });
     handler.on('GetStats', function wphSetupGetStats(data) {
      return pdfManager.pdfDocument.xref.stats;
     });
     handler.on('GetAnnotations', function wphSetupGetAnnotations(data) {
      return pdfManager.getPage(data.pageIndex).then(function (page) {
       return pdfManager.ensure(page, 'getAnnotationsData', [data.intent]);
      });
     });
     handler.on('RenderPageRequest', function wphSetupRenderPage(data) {
      var pageIndex = data.pageIndex;
      pdfManager.getPage(pageIndex).then(function (page) {
       var task = new WorkerTask('RenderPageRequest: page ' + pageIndex);
       startWorkerTask(task);
       var pageNum = pageIndex + 1;
       var start = Date.now();
       page.getOperatorList(handler, task, data.intent, data.renderInteractiveForms).then(function (operatorList) {
        finishWorkerTask(task);
        info('page=' + pageNum + ' - getOperatorList: time=' + (Date.now() - start) + 'ms, len=' + operatorList.totalLength);
       }, function (e) {
        finishWorkerTask(task);
        if (task.terminated) {
         return;
        }
        handler.send('UnsupportedFeature', { featureId: UNSUPPORTED_FEATURES.unknown });
        var minimumStackMessage = 'worker.js: while trying to getPage() and getOperatorList()';
        var wrappedException;
        if (typeof e === 'string') {
         wrappedException = {
          message: e,
          stack: minimumStackMessage
         };
        } else if (typeof e === 'object') {
         wrappedException = {
          message: e.message || e.toString(),
          stack: e.stack || minimumStackMessage
         };
        } else {
         wrappedException = {
          message: 'Unknown exception type: ' + typeof e,
          stack: minimumStackMessage
         };
        }
        handler.send('PageError', {
         pageNum: pageNum,
         error: wrappedException,
         intent: data.intent
        });
       });
      });
     }, this);
     handler.on('GetTextContent', function wphExtractText(data) {
      var pageIndex = data.pageIndex;
      var normalizeWhitespace = data.normalizeWhitespace;
      var combineTextItems = data.combineTextItems;
      return pdfManager.getPage(pageIndex).then(function (page) {
       var task = new WorkerTask('GetTextContent: page ' + pageIndex);
       startWorkerTask(task);
       var pageNum = pageIndex + 1;
       var start = Date.now();
       return page.extractTextContent(task, normalizeWhitespace, combineTextItems).then(function (textContent) {
        finishWorkerTask(task);
        info('text indexing: page=' + pageNum + ' - time=' + (Date.now() - start) + 'ms');
        return textContent;
       }, function (reason) {
        finishWorkerTask(task);
        if (task.terminated) {
         return;
        }
        throw reason;
       });
      });
     });
     handler.on('Cleanup', function wphCleanup(data) {
      return pdfManager.cleanup();
     });
     handler.on('Terminate', function wphTerminate(data) {
      terminated = true;
      if (pdfManager) {
       pdfManager.terminate();
       pdfManager = null;
      }
      if (cancelXHRs) {
       cancelXHRs();
      }
      var waitOn = [];
      WorkerTasks.forEach(function (task) {
       waitOn.push(task.finished);
       task.terminate();
      });
      return Promise.all(waitOn).then(function () {
       handler.destroy();
       handler = null;
      });
     });
     handler.on('Ready', function wphReady(data) {
      setupDoc(docParams);
      docParams = null;
     });
     return workerHandlerName;
    }
   };
   function initializeWorker() {
    if (!('console' in globalScope)) {
     var consoleTimer = {};
     var workerConsole = {
      log: function log() {
       var args = Array.prototype.slice.call(arguments);
       globalScope.postMessage({
        targetName: 'main',
        action: 'console_log',
        data: args
       });
      },
      error: function error() {
       var args = Array.prototype.slice.call(arguments);
       globalScope.postMessage({
        targetName: 'main',
        action: 'console_error',
        data: args
       });
       throw 'pdf.js execution error';
      },
      time: function time(name) {
       consoleTimer[name] = Date.now();
      },
      timeEnd: function timeEnd(name) {
       var time = consoleTimer[name];
       if (!time) {
        error('Unknown timer name ' + name);
       }
       this.log('Timer:', name, Date.now() - time);
      }
     };
     globalScope.console = workerConsole;
    }
    var handler = new MessageHandler('worker', 'main', self);
    WorkerMessageHandler.setup(handler, self);
    handler.send('ready', null);
   }
   if (typeof window === 'undefined' && !(typeof module !== 'undefined' && module.require)) {
    initializeWorker();
   }
   exports.setPDFNetworkStreamClass = setPDFNetworkStreamClass;
   exports.WorkerTask = WorkerTask;
   exports.WorkerMessageHandler = WorkerMessageHandler;
  }));
  (function (root, factory) {
   factory(root.pdfjsCoreNetwork = {}, root.pdfjsSharedUtil, root.pdfjsCoreWorker);
  }(this, function (exports, sharedUtil, coreWorker) {
   var OK_RESPONSE = 200;
   var PARTIAL_CONTENT_RESPONSE = 206;
   function NetworkManager(url, args) {
    this.url = url;
    args = args || {};
    this.isHttp = /^https?:/i.test(url);
    this.httpHeaders = this.isHttp && args.httpHeaders || {};
    this.withCredentials = args.withCredentials || false;
    this.getXhr = args.getXhr || function NetworkManager_getXhr() {
     return new XMLHttpRequest();
    };
    this.currXhrId = 0;
    this.pendingRequests = Object.create(null);
    this.loadedRequests = Object.create(null);
   }
   function getArrayBuffer(xhr) {
    var data = xhr.response;
    if (typeof data !== 'string') {
     return data;
    }
    var length = data.length;
    var array = new Uint8Array(length);
    for (var i = 0; i < length; i++) {
     array[i] = data.charCodeAt(i) & 0xFF;
    }
    return array.buffer;
   }
   var supportsMozChunked = function supportsMozChunkedClosure() {
    try {
     var x = new XMLHttpRequest();
     x.open('GET', 'https://example.com');
     x.responseType = 'moz-chunked-arraybuffer';
     return x.responseType === 'moz-chunked-arraybuffer';
    } catch (e) {
     return false;
    }
   }();
   NetworkManager.prototype = {
    requestRange: function NetworkManager_requestRange(begin, end, listeners) {
     var args = {
      begin: begin,
      end: end
     };
     for (var prop in listeners) {
      args[prop] = listeners[prop];
     }
     return this.request(args);
    },
    requestFull: function NetworkManager_requestFull(listeners) {
     return this.request(listeners);
    },
    request: function NetworkManager_request(args) {
     var xhr = this.getXhr();
     var xhrId = this.currXhrId++;
     var pendingRequest = this.pendingRequests[xhrId] = { xhr: xhr };
     xhr.open('GET', this.url);
     xhr.withCredentials = this.withCredentials;
     for (var property in this.httpHeaders) {
      var value = this.httpHeaders[property];
      if (typeof value === 'undefined') {
       continue;
      }
      xhr.setRequestHeader(property, value);
     }
     if (this.isHttp && 'begin' in args && 'end' in args) {
      var rangeStr = args.begin + '-' + (args.end - 1);
      xhr.setRequestHeader('Range', 'bytes=' + rangeStr);
      pendingRequest.expectedStatus = 206;
     } else {
      pendingRequest.expectedStatus = 200;
     }
     var useMozChunkedLoading = supportsMozChunked && !!args.onProgressiveData;
     if (useMozChunkedLoading) {
      xhr.responseType = 'moz-chunked-arraybuffer';
      pendingRequest.onProgressiveData = args.onProgressiveData;
      pendingRequest.mozChunked = true;
     } else {
      xhr.responseType = 'arraybuffer';
     }
     if (args.onError) {
      xhr.onerror = function (evt) {
       args.onError(xhr.status);
      };
     }
     xhr.onreadystatechange = this.onStateChange.bind(this, xhrId);
     xhr.onprogress = this.onProgress.bind(this, xhrId);
     pendingRequest.onHeadersReceived = args.onHeadersReceived;
     pendingRequest.onDone = args.onDone;
     pendingRequest.onError = args.onError;
     pendingRequest.onProgress = args.onProgress;
     xhr.send(null);
     return xhrId;
    },
    onProgress: function NetworkManager_onProgress(xhrId, evt) {
     var pendingRequest = this.pendingRequests[xhrId];
     if (!pendingRequest) {
      return;
     }
     if (pendingRequest.mozChunked) {
      var chunk = getArrayBuffer(pendingRequest.xhr);
      pendingRequest.onProgressiveData(chunk);
     }
     var onProgress = pendingRequest.onProgress;
     if (onProgress) {
      onProgress(evt);
     }
    },
    onStateChange: function NetworkManager_onStateChange(xhrId, evt) {
     var pendingRequest = this.pendingRequests[xhrId];
     if (!pendingRequest) {
      return;
     }
     var xhr = pendingRequest.xhr;
     if (xhr.readyState >= 2 && pendingRequest.onHeadersReceived) {
      pendingRequest.onHeadersReceived();
      delete pendingRequest.onHeadersReceived;
     }
     if (xhr.readyState !== 4) {
      return;
     }
     if (!(xhrId in this.pendingRequests)) {
      return;
     }
     delete this.pendingRequests[xhrId];
     if (xhr.status === 0 && this.isHttp) {
      if (pendingRequest.onError) {
       pendingRequest.onError(xhr.status);
      }
      return;
     }
     var xhrStatus = xhr.status || OK_RESPONSE;
     var ok_response_on_range_request = xhrStatus === OK_RESPONSE && pendingRequest.expectedStatus === PARTIAL_CONTENT_RESPONSE;
     if (!ok_response_on_range_request && xhrStatus !== pendingRequest.expectedStatus) {
      if (pendingRequest.onError) {
       pendingRequest.onError(xhr.status);
      }
      return;
     }
     this.loadedRequests[xhrId] = true;
     var chunk = getArrayBuffer(xhr);
     if (xhrStatus === PARTIAL_CONTENT_RESPONSE) {
      var rangeHeader = xhr.getResponseHeader('Content-Range');
      var matches = /bytes (\d+)-(\d+)\/(\d+)/.exec(rangeHeader);
      var begin = parseInt(matches[1], 10);
      pendingRequest.onDone({
       begin: begin,
       chunk: chunk
      });
     } else if (pendingRequest.onProgressiveData) {
      pendingRequest.onDone(null);
     } else if (chunk) {
      pendingRequest.onDone({
       begin: 0,
       chunk: chunk
      });
     } else if (pendingRequest.onError) {
      pendingRequest.onError(xhr.status);
     }
    },
    hasPendingRequests: function NetworkManager_hasPendingRequests() {
     for (var xhrId in this.pendingRequests) {
      return true;
     }
     return false;
    },
    getRequestXhr: function NetworkManager_getXhr(xhrId) {
     return this.pendingRequests[xhrId].xhr;
    },
    isStreamingRequest: function NetworkManager_isStreamingRequest(xhrId) {
     return !!this.pendingRequests[xhrId].onProgressiveData;
    },
    isPendingRequest: function NetworkManager_isPendingRequest(xhrId) {
     return xhrId in this.pendingRequests;
    },
    isLoadedRequest: function NetworkManager_isLoadedRequest(xhrId) {
     return xhrId in this.loadedRequests;
    },
    abortAllRequests: function NetworkManager_abortAllRequests() {
     for (var xhrId in this.pendingRequests) {
      this.abortRequest(xhrId | 0);
     }
    },
    abortRequest: function NetworkManager_abortRequest(xhrId) {
     var xhr = this.pendingRequests[xhrId].xhr;
     delete this.pendingRequests[xhrId];
     xhr.abort();
    }
   };
   var assert = sharedUtil.assert;
   var createPromiseCapability = sharedUtil.createPromiseCapability;
   var isInt = sharedUtil.isInt;
   var MissingPDFException = sharedUtil.MissingPDFException;
   var UnexpectedResponseException = sharedUtil.UnexpectedResponseException;
   function PDFNetworkStream(options) {
    this._options = options;
    var source = options.source;
    this._manager = new NetworkManager(source.url, {
     httpHeaders: source.httpHeaders,
     withCredentials: source.withCredentials
    });
    this._rangeChunkSize = source.rangeChunkSize;
    this._fullRequestReader = null;
    this._rangeRequestReaders = [];
   }
   PDFNetworkStream.prototype = {
    _onRangeRequestReaderClosed: function PDFNetworkStream_onRangeRequestReaderClosed(reader) {
     var i = this._rangeRequestReaders.indexOf(reader);
     if (i >= 0) {
      this._rangeRequestReaders.splice(i, 1);
     }
    },
    getFullReader: function PDFNetworkStream_getFullReader() {
     assert(!this._fullRequestReader);
     this._fullRequestReader = new PDFNetworkStreamFullRequestReader(this._manager, this._options);
     return this._fullRequestReader;
    },
    getRangeReader: function PDFNetworkStream_getRangeReader(begin, end) {
     var reader = new PDFNetworkStreamRangeRequestReader(this._manager, begin, end);
     reader.onClosed = this._onRangeRequestReaderClosed.bind(this);
     this._rangeRequestReaders.push(reader);
     return reader;
    },
    cancelAllRequests: function PDFNetworkStream_cancelAllRequests(reason) {
     if (this._fullRequestReader) {
      this._fullRequestReader.cancel(reason);
     }
     var readers = this._rangeRequestReaders.slice(0);
     readers.forEach(function (reader) {
      reader.cancel(reason);
     });
    }
   };
   function PDFNetworkStreamFullRequestReader(manager, options) {
    this._manager = manager;
    var source = options.source;
    var args = {
     onHeadersReceived: this._onHeadersReceived.bind(this),
     onProgressiveData: source.disableStream ? null : this._onProgressiveData.bind(this),
     onDone: this._onDone.bind(this),
     onError: this._onError.bind(this),
     onProgress: this._onProgress.bind(this)
    };
    this._url = source.url;
    this._fullRequestId = manager.requestFull(args);
    this._headersReceivedCapability = createPromiseCapability();
    this._disableRange = options.disableRange || false;
    this._contentLength = source.length;
    this._rangeChunkSize = source.rangeChunkSize;
    if (!this._rangeChunkSize && !this._disableRange) {
     this._disableRange = true;
    }
    this._isStreamingSupported = false;
    this._isRangeSupported = false;
    this._cachedChunks = [];
    this._requests = [];
    this._done = false;
    this._storedError = undefined;
    this.onProgress = null;
   }
   PDFNetworkStreamFullRequestReader.prototype = {
    _validateRangeRequestCapabilities: function PDFNetworkStreamFullRequestReader_validateRangeRequestCapabilities() {
     if (this._disableRange) {
      return false;
     }
     var networkManager = this._manager;
     if (!networkManager.isHttp) {
      return false;
     }
     var fullRequestXhrId = this._fullRequestId;
     var fullRequestXhr = networkManager.getRequestXhr(fullRequestXhrId);
     if (fullRequestXhr.getResponseHeader('Accept-Ranges') !== 'bytes') {
      return false;
     }
     var contentEncoding = fullRequestXhr.getResponseHeader('Content-Encoding') || 'identity';
     if (contentEncoding !== 'identity') {
      return false;
     }
     var length = fullRequestXhr.getResponseHeader('Content-Length');
     length = parseInt(length, 10);
     if (!isInt(length)) {
      return false;
     }
     this._contentLength = length;
     if (length <= 2 * this._rangeChunkSize) {
      return false;
     }
     return true;
    },
    _onHeadersReceived: function PDFNetworkStreamFullRequestReader_onHeadersReceived() {
     if (this._validateRangeRequestCapabilities()) {
      this._isRangeSupported = true;
     }
     var networkManager = this._manager;
     var fullRequestXhrId = this._fullRequestId;
     if (networkManager.isStreamingRequest(fullRequestXhrId)) {
      this._isStreamingSupported = true;
     } else if (this._isRangeSupported) {
      networkManager.abortRequest(fullRequestXhrId);
     }
     this._headersReceivedCapability.resolve();
    },
    _onProgressiveData: function PDFNetworkStreamFullRequestReader_onProgressiveData(chunk) {
     if (this._requests.length > 0) {
      var requestCapability = this._requests.shift();
      requestCapability.resolve({
       value: chunk,
       done: false
      });
     } else {
      this._cachedChunks.push(chunk);
     }
    },
    _onDone: function PDFNetworkStreamFullRequestReader_onDone(args) {
     if (args) {
      this._onProgressiveData(args.chunk);
     }
     this._done = true;
     if (this._cachedChunks.length > 0) {
      return;
     }
     this._requests.forEach(function (requestCapability) {
      requestCapability.resolve({
       value: undefined,
       done: true
      });
     });
     this._requests = [];
    },
    _onError: function PDFNetworkStreamFullRequestReader_onError(status) {
     var url = this._url;
     var exception;
     if (status === 404 || status === 0 && /^file:/.test(url)) {
      exception = new MissingPDFException('Missing PDF "' + url + '".');
     } else {
      exception = new UnexpectedResponseException('Unexpected server response (' + status + ') while retrieving PDF "' + url + '".', status);
     }
     this._storedError = exception;
     this._headersReceivedCapability.reject(exception);
     this._requests.forEach(function (requestCapability) {
      requestCapability.reject(exception);
     });
     this._requests = [];
     this._cachedChunks = [];
    },
    _onProgress: function PDFNetworkStreamFullRequestReader_onProgress(data) {
     if (this.onProgress) {
      this.onProgress({
       loaded: data.loaded,
       total: data.lengthComputable ? data.total : this._contentLength
      });
     }
    },
    get isRangeSupported() {
     return this._isRangeSupported;
    },
    get isStreamingSupported() {
     return this._isStreamingSupported;
    },
    get contentLength() {
     return this._contentLength;
    },
    get headersReady() {
     return this._headersReceivedCapability.promise;
    },
    read: function PDFNetworkStreamFullRequestReader_read() {
     if (this._storedError) {
      return Promise.reject(this._storedError);
     }
     if (this._cachedChunks.length > 0) {
      var chunk = this._cachedChunks.shift();
      return Promise.resolve(chunk);
     }
     if (this._done) {
      return Promise.resolve({
       value: undefined,
       done: true
      });
     }
     var requestCapability = createPromiseCapability();
     this._requests.push(requestCapability);
     return requestCapability.promise;
    },
    cancel: function PDFNetworkStreamFullRequestReader_cancel(reason) {
     this._done = true;
     this._headersReceivedCapability.reject(reason);
     this._requests.forEach(function (requestCapability) {
      requestCapability.resolve({
       value: undefined,
       done: true
      });
     });
     this._requests = [];
     if (this._manager.isPendingRequest(this._fullRequestId)) {
      this._manager.abortRequest(this._fullRequestId);
     }
     this._fullRequestReader = null;
    }
   };
   function PDFNetworkStreamRangeRequestReader(manager, begin, end) {
    this._manager = manager;
    var args = {
     onDone: this._onDone.bind(this),
     onProgress: this._onProgress.bind(this)
    };
    this._requestId = manager.requestRange(begin, end, args);
    this._requests = [];
    this._queuedChunk = null;
    this._done = false;
    this.onProgress = null;
    this.onClosed = null;
   }
   PDFNetworkStreamRangeRequestReader.prototype = {
    _close: function PDFNetworkStreamRangeRequestReader_close() {
     if (this.onClosed) {
      this.onClosed(this);
     }
    },
    _onDone: function PDFNetworkStreamRangeRequestReader_onDone(data) {
     var chunk = data.chunk;
     if (this._requests.length > 0) {
      var requestCapability = this._requests.shift();
      requestCapability.resolve({
       value: chunk,
       done: false
      });
     } else {
      this._queuedChunk = chunk;
     }
     this._done = true;
     this._requests.forEach(function (requestCapability) {
      requestCapability.resolve({
       value: undefined,
       done: true
      });
     });
     this._requests = [];
     this._close();
    },
    _onProgress: function PDFNetworkStreamRangeRequestReader_onProgress(evt) {
     if (!this.isStreamingSupported && this.onProgress) {
      this.onProgress({ loaded: evt.loaded });
     }
    },
    get isStreamingSupported() {
     return false;
    },
    read: function PDFNetworkStreamRangeRequestReader_read() {
     if (this._queuedChunk !== null) {
      var chunk = this._queuedChunk;
      this._queuedChunk = null;
      return Promise.resolve({
       value: chunk,
       done: false
      });
     }
     if (this._done) {
      return Promise.resolve({
       value: undefined,
       done: true
      });
     }
     var requestCapability = createPromiseCapability();
     this._requests.push(requestCapability);
     return requestCapability.promise;
    },
    cancel: function PDFNetworkStreamRangeRequestReader_cancel(reason) {
     this._done = true;
     this._requests.forEach(function (requestCapability) {
      requestCapability.resolve({
       value: undefined,
       done: true
      });
     });
     this._requests = [];
     if (this._manager.isPendingRequest(this._requestId)) {
      this._manager.abortRequest(this._requestId);
     }
     this._close();
    }
   };
   coreWorker.setPDFNetworkStreamClass(PDFNetworkStream);
   exports.PDFNetworkStream = PDFNetworkStream;
   exports.NetworkManager = NetworkManager;
  }));
 }.call(pdfjsLibs));
 exports.PDFJS = pdfjsLibs.pdfjsDisplayGlobal.PDFJS;
 exports.build = pdfjsLibs.pdfjsDisplayAPI.build;
 exports.version = pdfjsLibs.pdfjsDisplayAPI.version;
 exports.getDocument = pdfjsLibs.pdfjsDisplayAPI.getDocument;
 exports.PDFDataRangeTransport = pdfjsLibs.pdfjsDisplayAPI.PDFDataRangeTransport;
 exports.PDFWorker = pdfjsLibs.pdfjsDisplayAPI.PDFWorker;
 exports.renderTextLayer = pdfjsLibs.pdfjsDisplayTextLayer.renderTextLayer;
 exports.AnnotationLayer = pdfjsLibs.pdfjsDisplayAnnotationLayer.AnnotationLayer;
 exports.CustomStyle = pdfjsLibs.pdfjsDisplayDOMUtils.CustomStyle;
 exports.PasswordResponses = pdfjsLibs.pdfjsSharedUtil.PasswordResponses;
 exports.InvalidPDFException = pdfjsLibs.pdfjsSharedUtil.InvalidPDFException;
 exports.MissingPDFException = pdfjsLibs.pdfjsSharedUtil.MissingPDFException;
 exports.SVGGraphics = pdfjsLibs.pdfjsDisplaySVG.SVGGraphics;
 exports.UnexpectedResponseException = pdfjsLibs.pdfjsSharedUtil.UnexpectedResponseException;
 exports.OPS = pdfjsLibs.pdfjsSharedUtil.OPS;
 exports.UNSUPPORTED_FEATURES = pdfjsLibs.pdfjsSharedUtil.UNSUPPORTED_FEATURES;
 exports.isValidUrl = pdfjsLibs.pdfjsDisplayDOMUtils.isValidUrl;
 exports.createValidAbsoluteUrl = pdfjsLibs.pdfjsSharedUtil.createValidAbsoluteUrl;
 exports.createObjectURL = pdfjsLibs.pdfjsSharedUtil.createObjectURL;
 exports.removeNullCharacters = pdfjsLibs.pdfjsSharedUtil.removeNullCharacters;
 exports.shadow = pdfjsLibs.pdfjsSharedUtil.shadow;
 exports.createBlob = pdfjsLibs.pdfjsSharedUtil.createBlob;
 exports.getFilenameFromUrl = pdfjsLibs.pdfjsDisplayDOMUtils.getFilenameFromUrl;
 exports.addLinkAttributes = pdfjsLibs.pdfjsDisplayDOMUtils.addLinkAttributes;
}));