/// <reference path="knockout-3.3.0.debug.js"/>
/// <reference path="knockout.validation.js"/>

// Tekis Components v0.1

var tekis = tekis || {};
var Promise = Promise;

tekis.components = (function () {

    if (typeof ko === "undefined")
        throw new Error("Tekis Components requires Knockout");
    if (typeof ko.validation === "undefined")
        throw new Error("Tekis Components requires Knockout Validation");
    if (typeof Promise === "undefined")
        throw new Error("Tekis Components requires ES6 Promise or polyfill");

    var
        componentLoader = {
            getConfig: function (name, callback) {
                var viewModelConfig = components[name];
                var templateConfig = { element: name };
                callback({ viewModel: viewModelConfig, template: templateConfig });
            }
        },

        registerComponents = function () {
            for (var name in components) {
                if (components.hasOwnProperty(name)) {
                    if (!ko.components.isRegistered(name)) {
                        ko.components.register(name, {});
                    }
                }
            }
        },

        evalConditionToTrue = function (condition, data, editing) {
            // TODO: Optimize
            // TODO: Nested props
            if (typeof condition !== "string") {
                return typeof condition === "undefined" || condition;
            }

            var d = ko.unwrap(data);

            var conditionRegExp = new RegExp(/(\()?(!)?(\()?([a-zA-Z0-9]+)(\.?[^&^\|]+)?(\))?(&&?|\|\|?)?/g),
                isValidRegExp = new RegExp(/\.isValid(\(\))?\s*/),
                editingRegExp = new RegExp(/editing(\(\))?\s*/),
                parsedCondition = "";

            var match = conditionRegExp.exec(condition);
            while (match != null) {
                var dataProp = "d['" + match[4] + "']",
                    prop,
                    check = "";

                if (typeof editing !== "undefined" && editingRegExp.test(match[4])) {
                    // Show/hide depending on if in edit mode or not
                    dataProp = "editing";
                }

                if (isValidRegExp.test(match[5])) {
                    // Knockout Validation's isValid so we can show/hide things depending on validation rules of other properties
                    prop = ".isValid()" + match[5].replace(isValidRegExp, "");
                    check = dataProp + ".isValid&&";
                } else if (match[5] != undefined && match[5].trim() !== "") {
                    prop = "()" + match[5];
                    check = dataProp + "()&&";
                } else {
                    prop = "()";
                }

                parsedCondition += (match[1] || "") + (match[2] || "") + (match[3] || "") + "(" + check + dataProp + prop + ")" + (match[6] || "") + (match[7] || "");

                match = conditionRegExp.exec(condition);
            }

            return eval(parsedCondition);
        },

        valueFor = function (property, data) {
            if (typeof property !== "string") {
                // Property is not a string, use as is (and as observable if it isn't already)
                return ko.isObservable(property) ? property : ko.observable(property);
            }

            var propRegExp = new RegExp(/([^\[^\.]+)(?:\[(\d+)\])?(?:\.?)/g),
                result = data;

            var match = propRegExp.exec(property);
            while (match != null) {
                result = ko.unwrap(result)[match[1]];
                if (match[2])
                    result = ko.unwrap(result)[match[2]];

                match = propRegExp.exec(property);
            }

            return result;
        },

        // Type of view model surrounding the components
        parentVmType = ko.observable(),

        componentId = 0,
        componentTemplateFile = ko.observable("Templates/tekis.components.html"),
        componentTemplatesLoaded = false,
        init = function () {
            return new Promise(function (resolve, reject) {
                if (!componentTemplatesLoaded) {
                    var request = new XMLHttpRequest;
                    request.onreadystatechange = function () {
                        if (request.readyState === 4 && request.status !== 200) {
                            reject("Could not load Tekis Component Templates: " + componentTemplateFile());
                        } else if (request.readyState === 4) {
                            componentTemplatesLoaded = true;
                            document.body.insertAdjacentHTML("beforeend", request.responseText);
                            resolve();
                        }
                    }
                    request.open("GET", componentTemplateFile(), true);
                    request.send();
                } else {
                    resolve();
                }
            });
        };

    // Formatting functions
    var formatting = {};

    formatting["dashIfEmpty"] = function (value, valueIfEmpty) {
        return value || valueIfEmpty || "-";
    }

    formatting["toUpperCase"] = function (value) {
        if (!value) return "";
        if (typeof value !== "string") return value;
        return value.toUpperCase();
    }

    formatting["toLowerCase"] = function (value) {
        if (!value) return "";
        if (typeof value !== "string") return value;
        return value.toLowerCase();
    }

    formatting["personalNumber"] = function (value) {
        if (!value) return "";
        if (typeof value !== "string") return value;
        var match = value.match(/^(\d+)-?(\d{4})$/);
        if (!match) return value;
        return match[1] + "-" + match[2];
    }

    formatting["zipCode"] = function (value) {
        if (!value) return "";
        if (typeof value !== "string") return value;
        var match = value.match(/^(\d{3}) ?(\d{2})$/);
        if (!match) return value;
        return match[1] + " " + match[2];
    }

    formatting["length"] = function (value) {
        if (!value || typeof value.length === "undefined") return "";
        return value.length;
    }

    // truncate string to param chars, adds ellipsis
    formatting["maxlength"] = function (value, maxLength) {
        if (!value) return "";
        if (typeof value !== "string") return value;
        return value.length > maxLength ? value.substring(0, maxLength) + "..." : value;
    }

    formatting["boolean"] = function (value, params) {
        var texts = params && params.split(","),
            trueText = texts && texts[0] || "Ja",
            falseText = texts && texts[1] || "Nej";
        return value ? trueText : falseText;
    }

    // params[0]: separator chars
    // params[1]: property of value to concatenate, if undefined value's elements are concatenated
    formatting["concat"] = function (value, params) {
        if (!value) return "";
        if (!Array.isArray(value)) return value;
        var paramsArr = params ? params.split(/\s*,\s*/) : [];
        var arr = value;
        if (paramsArr[1]) {
            arr = ko.utils.arrayMap(value, function (item) {
                return ko.unwrap(item[paramsArr[1]]);
            });
        }
        return arr.join(paramsArr[0] || ", ");
    }

    formatting.format = function (format, value) {
        var formatUnwrapped = ko.unwrap(format);
        return !formatUnwrapped ? value : ko.pureComputed(function () {
            var unwrapped = ko.unwrap(value);
            unwrapped = unwrapped !== "\u0000" ? unwrapped : "";
            if (!Array.isArray(formatUnwrapped) && typeof formatUnwrapped !== "object")
                formatUnwrapped = [formatUnwrapped];
            for (var i in formatUnwrapped) {
                if (formatUnwrapped.hasOwnProperty(i)) {
                    var f = formatUnwrapped[i];
                    var fmatch = f.match(/^(\w+)\((.*)\)$/); // If formatting formatter it can have parameters
                    if (typeof f === "function")
                        unwrapped = f(unwrapped);
                    else if (formatting[(fmatch && fmatch[1]) || f])
                        unwrapped = formatting[(fmatch && fmatch[1]) || f](unwrapped, fmatch && fmatch[2]);
                    else
                        unwrapped = f.replace("{0}", unwrapped);
                }
            }
            return unwrapped;
        });
    }

    // The components
    var components = {};

    var componentViewModel = function (viewModel) {
        var findVm = function (bindingContext) {
            var context = bindingContext.$data;
            var i = 0;
            while (!(context instanceof parentVmType()) && i < 10) {
                context = bindingContext.$parents[i];
                i++;
            }
            return context;
        }

        return {
            createViewModel: function (params, componentInfo) {
                var element = componentInfo.element;
                var bindingContext = ko.contextFor(element);

                params.id = ++componentId;
                params.element = element;
                params.bindingContext = bindingContext;
                params.vm = params.vm || findVm(bindingContext);
                params.data = params.data || params.bindingContext.$data;

                params.value = params.property ? valueFor(params.property, params.data) : (ko.isObservable(params.value) ? params.value : ko.observable(params.value));
                params.title = typeof params.title === "boolean" && !params.title ? "" : params.title || params.value.title;
                params.editing = params.editable ? (typeof params.editing !== "undefined" ? (ko.isObservable(params.editing) ? params.editing : ko.observable(params.editing)) : params.vm.editing) : ko.observable(false);
                params.labelwidth = ko.unwrap(params.horizontal) === false || ko.unwrap(params.vertical) === true ? 12 : params.labelwidth;
                params.labelwidthsm = ko.unwrap(params.horizontal) === false || ko.unwrap(params.vertical) === true ? 12 : params.labelwidthsm;
                params.disabled = typeof params.disabled !== "undefined" ? (ko.isObservable(params.disabled) ? params.disabled : ko.observable(params.disabled)) : ko.observable(false);
                params.enabled = typeof params.enabled !== "undefined" ? (ko.isObservable(params.enabled) ? params.enabled : ko.observable(params.enabled)) : ko.observable(true);
                params.validationMessage = typeof params.validationMessage !== "undefined" ? params.validationMessage : true;

                if (params.visible) {
                    ko.applyBindingAccessorsToNode(componentInfo.element, { visible: function () { return evalConditionToTrue(params.visible, params.data, params.editing); } });
                }

                //// Observe properties with type validation rules (int, number) and convert values from string to correct type
                //if (params.value.rules) {
                //    var integerRule = ko.utils.arrayFirst(params.value.rules(), function (rule) { return rule.rule === "integer"; });
                //    var numberRule = ko.utils.arrayFirst(params.value.rules(), function (rule) { return rule.rule === "number"; });
                //    if (integerRule || numberRule) {
                //        if (params.value.intsub) { params.value.intsub.dispose(); }
                //        var func = function (val) {
                //            params.value.intsub.dispose();
                //            if (params.value.isValid()) {
                //                params.value(integerRule ? Number.parseInt(params.value()) : Number.parseFloat(params.value()));
                //            }
                //            params.value.intsub = params.value.subscribe(func);
                //        }
                //        params.value.intsub = params.value.subscribe(func);
                //    }
                //}

                var vm = viewModel ? new viewModel(params) : {};
                vm.value = params.value;
                vm.title = params.title;
                vm.editing = params.editing;
                vm.disabled = params.disabled;
                vm.enabled = params.enabled;
                vm.validationMessage = params.validationMessage;
                vm.vm = params.vm;
                return vm;
            }
        }
    }

    // Panel
    components["tekis-panel"] = componentViewModel(function (params) {
        this.title = params.title;
        this.type = ko.isObservable(params.type) ? params.type : ko.observable(params.type);
        this.expandable = params.expandable;
        var expandableUnwrapped = ko.unwrap(params.expandable),
            name = ko.unwrap(params.name),
            saveState = typeof params.saveState !== "undefined" ? params.saveState : true;

        if (expandableUnwrapped && !name) throw Error("tekis-panel requires 'name' parameter for expandable panels");

        if (expandableUnwrapped && components["tekis-panel"].expandstore[name]) {
            this.expanded = components["tekis-panel"].expandstore[name].expanded;
        } else if (expandableUnwrapped) {
            this.expanded = ko.isObservable(params.expanded) && !ko.isComputed(params.expanded) ? params.expanded : ko.observable(ko.unwrap(params.expanded));
        } else {
            this.expanded = ko.observable(true);
        }

        if (ko.isComputed(params.expanded)) {
            params.expanded.subscribe(function (val) {
                this.expanded(val);
            }, this);
        }

        if (expandableUnwrapped && typeof components["tekis-panel"].expandstore[name] !== "object" && saveState) {
            components["tekis-panel"].expandstore[name] = {};
            components["tekis-panel"].expandstore[name].group = params.group;
            components["tekis-panel"].expandstore[name].expanded = this.expanded;
        }

        this.toggleExpanded = function () {
            if (!ko.unwrap(this.expandable)) return;
            this.expanded(!this.expanded());
            if (params.group && this.expanded()) {
                for (var i in components["tekis-panel"].expandstore) {
                    if (components["tekis-panel"].expandstore[i].group === params.group && i !== name) {
                        components["tekis-panel"].expandstore[i].expanded(false);
                    }
                }
            }
        }
    });
    components["tekis-panel"].expandstore = {};

    // Value
    components["tekis-value"] = componentViewModel(function (params) {
        this.displayValue = formatting.format(params.format, params.value);
    });

    // Hr
    components["tekis-hr"] = componentViewModel();

    // Textbox
    components["tekis-textbox"] = componentViewModel(function (params) {
        this.displayValue = formatting.format(params.format, params.value);
        this.placeholder = params.placeholder;
        this.type = params.type || "text";
        this.labelwidth = params.labelwidth || 3;
        this.labelwidthsm = params.labelwidthsm || 3;
    });

    // Textarea
    components["tekis-textarea"] = componentViewModel(function (params) {
        this.rows = ko.pureComputed(function () {
            if (params.rows) return params.rows;

            var unwrapped = ko.unwrap(this.valueWithFormat);
            var minRows = params.minRows || (this.editing() ? 3 : 1);
            var newlines = typeof unwrapped === "string" && unwrapped.match(/\n/g);
            newlines = newlines ? newlines.length + 1 : 1;
            newlines = newlines < minRows ? minRows : newlines;
            return params.maxRows && newlines > params.maxRows ? params.maxRows : newlines;
        }, this);

        this.minHeight = ko.pureComputed(function () {
            var rows = this.rows(),
                minRows = params.minRows || (this.editing() ? 3 : 1);
            return rows < minRows ? minRows : rows;
        }, this);
        this.maxHeight = ko.pureComputed(function () {
            return params.maxRows || this.rows();
        }, this);

        this.valueWithFormat = ko.pureComputed({
            read: function () {
                return this.editing() ? params.value() : formatting.format(params.format, params.value)();
            },
            write: function (value) {
                this.value(value);
            }
        }, this);

        this.placeholder = params.placeholder;
        this.fit = typeof params.fit !== "undefined" ? (ko.isObservable(params.fit) ? params.fit : ko.observable(params.fit)) : ko.observable(false);
        this.labelwidth = params.labelwidth || 3;
        this.labelwidthsm = params.labelwidthsm || 3;
    });

    // Checkbox
    components["tekis-checkbox"] = componentViewModel(function (params) {
        if (typeof params.optionsText === "string")
            this.optionsText = params.optionsText;
        else if (ko.isObservable(params.optionsText))
            this.optionsText = ko.unwrap(params.optionsText);
        else
            this.optionsText = params.value.title && !params.title ? params.value.title : "";

        this.optionsValue = ko.unwrap(params.optionsValue);
    });

    // Radio
    components["tekis-radio"] = componentViewModel(function (params) {
        if (typeof params.optionsText === "string")
            this.optionsText = params.optionsText;
        else if (ko.isObservable(params.optionsText))
            this.optionsText = ko.unwrap(params.optionsText);
        else
            this.optionsText = params.value.title && !params.title ? params.value.title : "";

        this.optionsValue = ko.unwrap(params.optionsValue);
        this.name = params.name;
    });

    // YesNo
    components["tekis-yesno"] = componentViewModel(function (params) {
        var self = this;
        self.checkedText = params.checkedText || "Ja";
        self.uncheckedText = params.uncheckedText || "Nej";
        self.name = "yesno" + params.id;
        self.buttons = ko.unwrap(params.buttons) || false;
        self.buttons = self.buttons && { cssChecked: self.buttons.cssChecked || "btn-primary", cssUnchecked: self.buttons.cssUnchecked || "btn-danger" };
        self.labelwidth = params.labelwidth || 3;
        self.labelwidthsm = params.labelwidthsm || 3;

        self.yesno = ko.computed({
            read: function () {
                var value = ko.unwrap(params.value);
                return typeof value !== "undefined" && value ? "true" : "false";
            },
            write: function (value) {
                params.value(value === "true" || value === true);
            },
            disposeWhenNodeIsRemoved: params.element
        });
    });

    // Multiple booleans
    components["tekis-multibool"] = componentViewModel(function (params) {
        var self = this;
        self.valueTexts = params.valueTexts || [];
        self.values = [];
        self.labelwidth = params.labelwidth || 3;
        self.labelwidthsm = params.labelwidthsm || 3;

        if (params.value) {
            // One property, treat as flags value
            params.flags = params.flags || params.value.flags;
            if (!params.flags) throw new Error("tekis-multibool with property/value param requires flags extend on the observable or flags param");
            params.flags.forEach(function (val, index) {
                var obs = ko.observable((params.value() & Math.pow(2, index)) === Math.pow(2, index)).extend({ title: val });
                obs.subscribe(function (obsVal) {
                    var newVal = obsVal ? params.value() | Math.pow(2, index) : params.value() ^ Math.pow(2, index);
                    params.value(newVal || undefined);
                });
                self.values.push(obs);
                if (!params.valueTexts) {
                    self.valueTexts.push(obs.title);
                }
            });
        } else {
            params.properties.forEach(function (item) {
                var prop = valueFor(item, params.data);
                self.values.push(prop);
                if (!params.valueTexts) {
                    self.valueTexts.push(prop.title);
                }
            });
        }

        self.displayValue = ko.pureComputed(function () {
            var result = "";
            self.values.forEach(function (item) {
                if (item()) {
                    if (result.length !== 0)
                        result += ", ";
                    result += item.title;
                }
            });
            return result.length ? result : params.noValueText || "Ingen";
        });
    });

    // Multi/single select as dropdown
    components["tekis-select"] = componentViewModel(function (params) {
        var self = this;
        self.multiple = ko.unwrap(params.multiple);
        self.options = valueFor(params.options, params.data);
        self.optionsText = ko.unwrap(params.optionsText);
        self.optionsValue = ko.unwrap(params.optionsValue);
        self.optionsCaption = typeof params.optionsCaption === "undefined" ? "" : params.optionsCaption || null;
        self.chosenOptions = params.chosenOptions || {};
        self.chosenOptions.placeholder_text_single = self.chosenOptions.placeholder_text_single || params.placeholder;
        self.chosenOptions.placeholder_text_multiple = self.chosenOptions.placeholder_text_multiple || params.placeholder;
        self.chosenOptions.disable_search = self.chosenOptions.disable_search || !params.searchable;
        self.labelwidth = params.labelwidth || 3;
        self.labelwidthsm = params.labelwidthsm || 3;

        if (self.multiple) { // Multiple select
            self.displayValue = ko.pureComputed(function () {
                var result = ko.utils.arrayMap(params.value(), function (item) {
                    // Options text in property
                    if (item.hasOwnProperty(self.optionsText))
                        return item[self.optionsText]();
                    // Options text not in property, check in options
                    if (self.optionsValue) {
                        var value = ko.utils.arrayFirst(ko.unwrap(self.options), function (option) {
                            var unwrapped = ko.unwrap(option);
                            return unwrapped.hasOwnProperty(params.optionsValue) && ko.unwrap(unwrapped[params.optionsValue]()) === item;
                        });
                        if (ko.unwrap(value))
                            return ko.unwrap(value)[self.optionsText]();
                    }
                    // Options text not found, just return value
                    return item;
                });
                return result.length ? result : [params.noValueText || "Inga"];
            });

            self.selectedOptions = ko.computed({
                read: function () {
                    var selectedOptions = [];
                    params.value().forEach(function (value) {
                        if (self.optionsValue || typeof value === "object") {
                            ko.unwrap(self.options).forEach(function (option) {
                                if (self.optionsValue && value === option[self.optionsValue]() || ko.toJSON(value) === ko.toJSON(option)) {
                                    selectedOptions.push(option);
                                }
                            });
                        } else {
                            selectedOptions.push(value);
                        }
                    });
                    return selectedOptions;
                },
                write: function (selected) {
                    var selectedOptions = [];
                    selected.forEach(function (value) {
                        if (self.optionsValue) {
                            selectedOptions.push(ko.unwrap(value[self.optionsValue]));
                        } else if (typeof value === "object") {
                            ko.unwrap(self.options).forEach(function (option) {
                                if (ko.toJSON(value) === ko.toJSON(option)) {
                                    selectedOptions.push(option);
                                }
                            });
                        } else {
                            selectedOptions.push(value);
                        }
                    });
                    self.value(selectedOptions);
                },
                disposeWhenNodeIsRemoved: params.element
            });
        } else { // Single select
            self.displayValue = ko.pureComputed(function () {
                // Nothing selected
                if (!params.value())
                    return params.noValueText || "Inget valt";
                // Options text in property
                if (params.value().hasOwnProperty(self.optionsText))
                    return ko.unwrap(params.value()[self.optionsText]);
                // Options text not in property, check in options
                if (params.optionsValue) {
                    var value = ko.utils.arrayFirst(ko.unwrap(self.options), function (item) {
                        var unwrapped = ko.unwrap(item);
                        return unwrapped.hasOwnProperty(params.optionsValue) && ko.unwrap(unwrapped[params.optionsValue]()) === ko.unwrap(params.value);
                    })
                    if (ko.unwrap(value))
                        return ko.unwrap(ko.unwrap(value)[self.optionsText]);
                }
                // Options text not found, just return value
                return params.value();
            });
        }
    });

    // Table
    components["tekis-table"] = componentViewModel(function (params) {
        var self = this;
        self.columns = params.columns;

        // No columns signals array with plain values, convert to array of object
        if (!self.columns) {
            self.columns = ["value"];
            self.plainArrayValues = params.value;
            // Convert each value to an observable that updates the original value and notifies
            var toObs = function (v, i) {
                var observedValue = ko.observable(v).extend({ title: undefined });
                observedValue.subscribe(function (val) {
                    self.plainArrayValues()[i] = val;
                    self.plainArrayValues.valueHasMutated();
                });
                return { value: observedValue };
            }
            var newValue = self.plainArrayValues().map(function (value, index) {
                return toObs(value, index);
            });
            params.value = ko.observableArray(newValue);
            // Subscribe to self.plainArrayValues to update table with items added/removed from the outside
            self.plainArrayValues.subscribe(function (changes) {
                changes.forEach(function (change) {
                    if (change.status === "added") {
                        params.value.splice(change.index, 0, toObs(change.value, change.index));
                    } else if (change.status === "deleted") {
                        params.value.splice(change.index, 1);
                    }
                });
            }, null, "arrayChange");
        }

        self.showHeader = typeof params.showHeader === "undefined" || params.showHeader;
        self.columnHeaderTexts = params.columnHeaderTexts || [];
        self.columnTypes = params.columnTypes || [];
        self.wrap = Array.isArray(params.wrap) ? params.wrap : [];
        self.format = Array.isArray(params.format) ? params.format : [];
        self.align = Array.isArray(params.align) ? params.align : [];
        self.verticalalign = Array.isArray(params.verticalalign) ? params.verticalalign : [];
        self.columnsEditable = Array.isArray(params.columnsEditable) ? params.columnsEditable : [];
        self.buttons = params.button ? [params.button] : params.buttons;
        self.hasSmallButtons = self.buttons && self.buttons.some(function (button) { return button.size === "sm"; });
        self.newRowButtonText = params.newRowButtonText === false ? "" : (" " + (params.newRowButtonText || "Lägg till ny"));
        self.newRowButtonVisible = typeof params.newRowButtonVisible === "undefined" ? true : params.newRowButtonVisible;
        self.onrowadded = params.onrowadded;
        self.onrowremoved = params.onrowremoved;

        self.sortable = typeof params.sortable === "object" ? params.sortable.enabled : params.sortable;
        self.sorting = ko.observable(self.sortable ? {
            index: params.sortable.column ? self.columns.indexOf(params.sortable.column) : 0,
            order: typeof params.sortable.order !== "undefined" ? params.sortable.order : (params.sortable.column ? 1 : 0) // none=0/asc=1/desc=2
        } : { index: 0, order: 0 });
        self.sort = self.sortable ? function (data, element) {
            if (self.editing()) return;
            var context = ko.contextFor(element.target);
            var index = context.$index();
            var sorting = self.sorting();
            sorting.order = sorting.index === index ? (sorting.order + 1) % 3 : 1;
            sorting.index = index;
            self.sorting(sorting);
        } : undefined;

        self.lastValueSorted = ko.observableArray();
        self.valueSorted = ko.pureComputed(function () {
            if (!self.sortable || self.sorting().order === 0) return self.value();
            if (self.editing()) return self.lastValueSorted() || self.value();

            var sortFunc = function (prop) {
                return function (left, right) {
                    var leftVal = ko.unwrap(left[prop]);
                    var rightVal = ko.unwrap(right[prop]);
                    if (typeof leftVal === "string")
                        leftVal = leftVal.toLowerCase();
                    if (typeof rightVal === "string")
                        rightVal = rightVal.toLowerCase();
                    return leftVal === rightVal ? 0 : (leftVal < rightVal ? -1 : 1);
                }
            }

            var value = self.value().slice();
            var column = self.columns[self.sorting().index];
            value.sort(sortFunc(column));
            if (self.sorting().order === 2)
                value.reverse();
            self.lastValueSorted(value);
            return value;
        });

        if (self.columns) {
            var hasButtonsType = params.columnTypes && params.columnTypes.some(function (ct) { return ct && ct.type === "buttons"; });
            var properties = params.value.type ? new params.value.type : undefined;
            ko.utils.arrayForEach(self.columns, function (item, index) {
                if (!params.columnHeaderTexts || params.columnHeaderTexts[index] === undefined) {
                    if (typeof item === "string" && properties && properties[item] && properties[item].title) {
                        self.columnHeaderTexts[index] = properties[item].title;
                    } else if (item && item.title) {
                        self.columnHeaderTexts[index] = item.title;
                    } else {
                        self.columnHeaderTexts[index] = "-";
                    }
                }
                //if (!params.columnTypes || params.columnTypes[index] === undefined || typeof params.columnTypes[index] === "string") {

                if (params.columnTypes && typeof params.columnTypes[index] === "string") {
                    self.columnTypes[index] = { type: params.columnTypes[index] };
                } else if (params.columnTypes && params.columnTypes[index] !== undefined) {
                    self.columnTypes[index] = params.columnTypes[index];
                } else {
                    self.columnTypes[index] = { type: properties && properties[item] && properties[item].bool ? "bool" : "text" };
                }
                if (self.columnTypes[index].type === "select") {
                    //                        self.columnTypes[index].options = valueFor(self.columnTypes[index].options, params.value);
                }
                //}
                if (!params.wrap || self.wrap[index] === undefined) {
                    self.wrap[index] = !Array.isArray(params.wrap) && params.wrap || "word";
                }
                if (!params.format || self.format[index] === undefined) {
                    self.format[index] = !Array.isArray(params.format) && params.format || "dashIfEmpty";
                }
                if (!params.align || self.align[index] === undefined) {
                    self.align[index] = !Array.isArray(params.align) && params.align || "left";
                }
                if (!params.verticalalign || self.verticalalign[index] === undefined) {
                    self.verticalalign[index] = !Array.isArray(params.verticalalign) && params.verticalalign || (self.buttons || hasButtonsType ? "middle" : "top");
                }
                if (!params.columnsEditable || self.columnsEditable[index] === undefined) {
                    self.columnsEditable[index] = params.editable || !Array.isArray(params.columnsEditable) && params.columnsEditable || false;
                }
            });
            if (!Array.isArray(params.align) && self.buttons)
                self.align.push(params.align || "right");
            if (!Array.isArray(params.verticalalign) && self.buttons)
                self.verticalalign.push(params.verticalalign || "middle");
        }

        self.selectable = params.selectable || {};
        self.selectable.data = self.selectable.data || ko.observable();
        self.selectable.isequal = function (item) {
            var i = self.plainArrayValues ? item.value() : item;
            return self.selectable.data() === i;
        };
        self.select = function (item) {
            var i = self.plainArrayValues ? item.value() : item;
            if (self.selectable.data() !== i)
                self.selectable.data(i);
            else if (self.selectable.deselectable !== false)
                self.selectable.data(null);
            if (typeof self.selectable.onselect === "function")
                self.selectable.onselect(self.selectable.data());
        };

        self.removeRow = function (item) {
            var index = self.value.indexOf(item);
            if (self.plainArrayValues) {
                item = item.value();
                self.plainArrayValues.splice(index, 1);
            } else {
                self.value.remove(item);
                self.lastValueSorted.remove(item);
            }
            if (typeof self.onrowremoved === "function")
                self.onrowremoved(item, index);
        }

        self.addRow = function () {
            var newRow;
            if (self.plainArrayValues) {
                newRow = new (self.plainArrayValues.type);
                self.plainArrayValues.push(newRow);
            } else {
                newRow = new (self.value.type);
                self.value.push(newRow);
                self.lastValueSorted.push(newRow);
            }
            if (params.data.errors && params.data.errors.showAllMessages) {
                params.data.errors.showAllMessages();
            }
            if (typeof self.onrowadded === "function")
                self.onrowadded(newRow);
        }

        self.movable = params.movable && !self.sortable;
        self.moveUp = function (item) {
            var index = self.value.indexOf(item);
            if (index === 0 || !self.movable) return;

            var value = ko.unwrap(self.value);
            var newValue = [];
            for (var i = 0; i < value.length; i++) {
                if (i === index - 1) {
                    newValue.push(item);
                }
                if (i !== index) {
                    newValue.push(value[i]);
                }
            }

            self.value(newValue);

            if (typeof self.onrowremoved === "function")
                self.onrowremoved(item, index);
            if (typeof self.onrowadded === "function")
                self.onrowadded(item, index - 1);
        };
        self.moveDown = function (item) {
            var index = self.value.indexOf(item);
            var value = ko.unwrap(self.value);
            if (index === value.length - 1 || !self.movable) return;

            var newValue = [];
            for (var i = value.length - 1; i >= 0; i--) {
                if (i === index + 1) {
                    newValue.unshift(item);
                }
                if (i !== index) {
                    newValue.unshift(value[i]);
                }
            }

            self.value(newValue);

            if (typeof self.onrowremoved === "function")
                self.onrowremoved(item, index);
            if (typeof self.onrowadded === "function")
                self.onrowadded(item, index + 1);
        };
    });

    // Date Time
    components["tekis-datetime"] = componentViewModel(function (params) {
        this.displayValue = formatting.format(params.format, params.value);
        this.placeholder = params.placeholder;
        this.labelwidth = params.labelwidth || 3;
        this.labelwidthsm = params.labelwidthsm || 3;
        this.dateFormat = params.dateFormat || "YYYY-MM-DD HH:mm:ss";
        if (params.dateMin === "now") {
            this.dateMin = new Date();
            this.dateMin.setHours(0, 0, 0, 0);
        } else {
            this.dateMin = params.dateMin || false;
        }
        if (params.dateMax === "now") {
            this.dateMax = new Date();
            this.dateMax.setHours(0, 0, 0, 0);
        } else {
            this.dateMax = params.dateMax || false;
        }
    });

    // Initial initialization
    ko.components.loaders.unshift(componentLoader);
    registerComponents();

    return {
        componentTemplatesFile: componentTemplateFile,
        components: components,
        registerComponents: registerComponents,
        parentVmType: parentVmType,
        init: init
    };

})();

// Knockout stuff

// Sets nbsp as value for empty values
ko.bindingHandlers["text2"] = {
    init: ko.bindingHandlers.text.init,
    update: function (element, valueAccessor) {
        var value = ko.unwrap(valueAccessor());
        if (!value) {
            ko.utils.setHtml(element, "&nbsp;");
        } else {
            ko.bindingHandlers.text.update(element, valueAccessor);
        }
    }
};

// Bootstrap date time picker
ko.bindingHandlers.dateTimePicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var options = allBindingsAccessor().dateTimePickerOptions || {};

        var value = valueAccessor();
        var originalValue = ko.unwrap(value);

        var startDate = moment(originalValue, options.format || "YYYY-M-D H:mm:ss");
        options.defaultDate = startDate.isValid() ? startDate : false;
        // Adjust min date to default date if default date is before min date, else default date will be invalid
        options.minDate = options.defaultDate && options.minDate && options.defaultDate.isBefore(options.minDate) ? options.defaultDate : options.minDate;

        $(element).datetimepicker(options);

        // When a user changes the date, update the view model
        var handler = function (event) {
            if (ko.isObservable(value)) {
                var newDate = event.date;
                var isValid = newDate && newDate.isValid();
                if (!isValid) {
                    value(originalValue ? null : originalValue);
                } else {
                    value(newDate.format(options.format));
                }
            }
        };
        ko.utils.registerEventHandler(element, "dp.change", handler);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            var picker = $(element).data("DateTimePicker");
            if (picker) {
                picker.destroy();
            }
        });
    },
    update: function (element, valueAccessor) {
        // When the view model is updated, update the picker
        var picker = $(element).data("DateTimePicker");
        if (picker) {
            var format = picker.options().format;
            var value = ko.unwrap(valueAccessor());
            var date = moment(value, format);
            picker.date(date.isValid() ? date : null);
        }
    }
};

// Selected value for single select
if (!ko.bindingHandlers.selectedValue) {
    ko.bindingHandlers.selectedValue = ko.bindingHandlers.value;
}

// Descendants of this element will have this elements parent as binding context
ko.bindingHandlers.descendantsUseParentContext = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var newcontext = bindingContext.$parentContext;
        newcontext.$originalData = bindingContext.$data;
        // In case this is used inside a component, new context also gets original contexts template nodes
        newcontext.$componentTemplateNodes = bindingContext.$componentTemplateNodes;
        ko.applyBindingsToDescendants(newcontext, element);
        return { controlsDescendantBindings: true };
    }
}

ko.bindingHandlers.clickSetValue = {
    init: function (element, valueAccessor) {
        var value = valueAccessor(),
            first = true;
        ko.applyBindingAccessorsToNode(element, {
            click: function () {
                !first ? value.value(value.param) : first = false;
            }
        });
    }
};

// Validation extenders

ko.validation.rules["minExclusive"] = {
    validator: function (val, otherVal) {
        return ko.validation.utils.isEmptyVal(val) || (!isNaN(val) && parseFloat(val) > parseFloat(otherVal));
    },
    message: "Fyll i ett värde som är större än {0}" // "Please enter a value greater than {0}."
};

ko.validation.rules["maxExclusive"] = {
    validator: function (val, otherVal) {
        return ko.validation.utils.isEmptyVal(val) || (!isNaN(val) && parseFloat(val) < parseFloat(otherVal));
    },
    message: "Fyll i ett värde som är mindre än {0}" // "Please enter a value less than {0}."
};

ko.validation.rules["integer"] = {
    validator: function (val) {
        return ko.validation.utils.isEmptyVal(val) || /^[-+]?[0-9]+$/.test(val);
    },
    message: "Fyll i ett heltal" // "Please enter a number/integer."
};

ko.validation.registerExtenders();

// Observable extends

ko.extenders.title = ko.extenders.title || function (target, title) {
    target.title = title;
}

ko.extenders.readonly = ko.extenders.readonly || function (target, readonly) {
    target.readonly = readonly;
}

ko.extenders.type = ko.extenders.type || function (target, type) {
    target.type = type;
}

ko.extenders.defaultValue = ko.extenders.defaultValue || function (target, value) {
    target.defaultValue = value;
    target(value);
}

ko.extenders.bool = ko.extenders.bool || function (target, bool) {
    target.bool = bool;
}

ko.extenders.flags = ko.extenders.flags || function (target, flags) {
    target.flags = flags;
}